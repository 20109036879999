import React from 'react'

import { redirectToCheckout, saveOnTracker, saveTrackersProSubscription } from '../../utils';
import { ReactComponent as IconHomeAssistant } from "../../assets/icon-home-assistant.svg"

import "../../components/cardProAdvertising/CardProAdvertising.scss"
const CardAssistant = (props) => {

    const {
        clinic,
        pro,
        history
    } = props

    return (
        <div className="vsbox card-pro-advertising">
            <div className="vsbox-body">
                <div className="row g-3">
                    <div className="col-12 d-flex align-items-start justify-content-between">
                        <IconHomeAssistant style={{borderRadius: 10}} />
                        <div className="new-tag">Novo</div>
                    </div>
                    <div className="col-12">
                        <div className="vsbox-title mb-1">Assistente Vetsmart <span className="va-beta-text">Beta</span></div>
                        { !pro?.trialEndsAt ? (
                            <div className="mb-2">Experimente o PRO e aproveite para testar a nossa mais nova ferramenta. O assistente digital que dará um apoio extra no seu dia-a-dia. Disponível até o dia <b>20/10</b>, aproveite!</div>
                        ) : (
                            <div className="mb-2">Assine o PRO e aproveite para testar a nossa mais nova ferramenta. O assistente digital que dará um apoio extra no seu dia-a-dia. Disponível até o dia <b>20/10</b>, aproveite!</div>
                        )}
                        <div className="mt-3">
                            <a 
                                style={{ 
                                    cursor: "pointer",
                                    fontSize: "14px",
                                    fontWeight: 600
                                }} 
                                onClick={() => {
                                    saveTrackersProSubscription(pro)
                                    redirectToCheckout(clinic)
                                }}
                            >
                                { !pro?.trialEndsAt ? "Experimentar o PRO 15 dias grátis" : "Assinar o Prontuário PRO" }
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
  
export default CardAssistant;