import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react"
import PropTypes from 'prop-types';

import { ReactComponent as IconFilter } from "../../assets/icon-filter.svg"
import { ReactComponent as IconFilterDisabled } from "../../assets/icon-filter-disabled-2.svg"

import VSDrawer from '../vsDrawer/VSDrawer';
import VSRadio from "../vsRadio/VSRadio";
import VSMultipleSelectDrawer from "../vsMultipleSelect/VSMultipleSelectDrawer";
import VSCheckbox from "../vsCheckbox/VSCheckbox";

import "./VSFilter.scss"
const VSFilter = forwardRef((props, ref) => {

    const {
        title,
        onClear,
        onFilter,
        filters,
        totalResults
    } = props

    const [ isOpen, setIsOpen ] = useState(false)

    const totalFilters = filters
        ?.map(filter => JSON.stringify(filter.defaultValue) != JSON.stringify(filter.value) ? 1 : 0)
        .reduce((a, b) => a + b, 0)

    useImperativeHandle(ref, () => ({
        getTotalFiltersApplied: () => totalFilters || 0
    }))

    return (
        <div id="vsfilter">
            <div className={`btn-filter-main btn-link text-center cursor-pointer ps-2 ps-md-3 ${totalFilters > 0 ? 'has-filters' : ''}`} onClick={() => setIsOpen(true)}>
                {totalFilters > 0 ?
                <IconFilterDisabled width={24} height={24} fill="var(--vsprontuario-primary-color)" /> : 
                <IconFilter width={24} height={24} fill="var(--vsprontuario-primary-color)" />}
                FILTRAR RESULTADO { totalFilters > 0 && (
                    <><span className="filter-point mx-3"></span>{totalFilters}</>
                )}
            </div>
            <VSDrawer
                id="filter-form" 
                open={isOpen}
                width="40%"
                timeout={0}
                title={title}
                cancel={"Cancelar"}
                submit={"Aplicar"}
                onSubmit={() => {
                    onFilter && onFilter()
                    return Promise.resolve()
                }}
                onClose={() => {
                    onFilter && onFilter()
                    setIsOpen(false)
                }}
                onCancel={() => {
                    onClear()
                    setIsOpen(false)
                }}
                onAfterSave={_ => {
                    setIsOpen(false)
                }}
            >
                <div className="row align-items-center">
                    <div className="col">
                        {totalResults > 0 ? 
                            totalResults + ' RESULTADO' + (totalResults > 1 ? 'S' : '') : 
                            'NENHUM RESULTADO'}
                    </div>
                    <div className="col-auto">
                        <div className="btn-link text-center cursor-pointer" onClick={() => onClear && onClear()}>
                            Limpar filtros
                        </div>
                    </div>
                </div>
                <hr className="mt-2 mb-4" />
                { filters && filters.map((filter, index) => (
                    <div className="row align-items-center" key={index}>
                        { filter.multiple ? (
                            filter.onSearch ? (
                                <div className="col-12 mb-3">
                                    <VSMultipleSelectDrawer
                                        title={filter.title}
                                        text={filter.multiple.text}
                                        value={filter.value}
                                        hideAllOption={filter.multiple.hideAllOption}
                                        searchTitle={filter.multiple.title}
                                        searchText={filter.multiple.placeholder}
                                        addText={filter.multiple.button}
                                        FormComponent={filter.multiple.FormComponent}
                                        canCreate={filter.multiple.canCreate}
                                        canEdit={filter.multiple.canEdit}
                                        canDelete={filter.multiple.canDelete}
                                        onDelete={filter.multiple.onDelete}
                                        onSearch={filter.onSearch}
                                        onClose={() => {
                                            onFilter && onFilter()
                                            setIsOpen(false)
                                        }}
                                        onChange={(value) => {
                                            filter.onChange(value)
                                        }}
                                        {...filter.componentProps}
                                    />
                                </div>
                            ) : (
                                <div id="multiple-select-drawer">
                                    <div className="row align-items-center">
                                        <div className="col-12">
                                            <div className="input-title input-height">
                                                { filter?.title }
                                            </div>
                                        </div>
                                        { filter?.text && (
                                            <div className="col-12">
                                                <p className="input-text">{ filter.text }</p>
                                            </div>
                                        )}
                                    </div>
                                    <div className="multiple-options-list">
                                        { filter?.options.map((option, index) => (
                                            <VSCheckbox
                                                key={index}
                                                label={option.label}
                                                position="end"
                                                checked={filter?.value?.length ? filter?.value?.map(opt => opt.value).indexOf(option.value) != -1 : false}
                                                onChange={(checked) => {
                                                    let newOptionsSelected = [...filter?.value]
                                                    if (checked) {
                                                        newOptionsSelected.push(option)
                                                    } else {
                                                        let index = newOptionsSelected.map(opt => opt.value).indexOf(option.value)
                                                        newOptionsSelected.splice(index, 1)
                                                    }
                                                    filter.onChange(newOptionsSelected, option)
                                                }}
                                                className="multiple-options-item mb-2"
                                            />
                                        ))}
                                    </div>
                                </div>
                            )
                        ) : (
                            <div className="col-12 mb-3">
                                <div className="row align-items-center">
                                    <div className="col-12">
                                        <div className="input-title input-height">
                                            { filter.title }
                                        </div>
                                    </div>
                                    { filter?.text && (
                                        <div className="col-12">
                                            <p className="input-text">{ filter.text }</p>
                                        </div>
                                    )}
                                    <div className="col-12">
                                        <VSRadio
                                            horizontal={filter.horizontal}
                                            options={filter.options}
                                            value={filter.value}
                                            onChange={(_, value) => {
                                                filter.onChange(value)
                                            }}
                                            {...filter.componentProps}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                        { index != filters.length - 1 && (
                            <div className="col-12 mb-3">
                                <hr />
                            </div>
                        )}
                    </div>
                )) }
            </VSDrawer>
        </div>
    )
})

VSFilter.propTypes = {
    title: PropTypes.string,
    onClear: PropTypes.func,
    onFilter: PropTypes.func,
    filters: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            defaultValue: PropTypes.any,
            value: PropTypes.any,
            horizontal: PropTypes.bool,
            onChange: PropTypes.func,
            onSearch: PropTypes.func,
            options: PropTypes.arrayOf(
                PropTypes.shape({
                    label: PropTypes.string,
                    value: PropTypes.any
                })
            ),
            multiple: PropTypes.oneOfType([
                PropTypes.bool,
                PropTypes.shape({
                    title: PropTypes.string,
                    placeholder: PropTypes.string,
                    button: PropTypes.string,
                    empty: PropTypes.shape({
                        title: PropTypes.string,
                        text: PropTypes.string
                    }),
                    notFound: PropTypes.shape({
                        title: PropTypes.string,
                        text: PropTypes.string
                    })
                })
            ])
        }),
    )
};

VSFilter.defaultProps = {
    title: null,
    onClear: () => null,
    onFilter: () => null,
    filters: null
}

export default VSFilter