import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import axios from 'axios';

import './SettingPreview.scss'

import { CircularProgress, SwipeableDrawer } from '@mui/material';
import { userToObject, useDidMountEffect } from '../../utils';

import { ReactComponent as IconPrimaryCheck } from "../../assets/icon-primary-check.svg"
import { ReactComponent as IconHistory } from "../../assets/icon-history.svg"
import { ReactComponent as IconArrowRight } from "../../assets/icon-arrow-right.svg"
import { colors } from '../../utils';

const SettingPreview = props => {
    const {
        user,
        clinic,
        location,
        onSave,
        isConfigSaved,
        hidePrint
    } = props

    const selectedClinic = clinic?.object

    const defaultDisplay = {
        prescription: true,
        examRequest: true,
        term: true,
        vaccine: true
    }

    const [ clinicUser, setClinicUser ] = useState(null)
    const [ saveLoading, setSaveLoading ] = useState(false)
    const [ seePreviewMobile, setSeePreviewMobile ] = useState(false)

    const [ loadingPreview, setLoadingPreview ] = useState(true)
    const [ preview, setPreview ] = useState(null)
    const [ previewType, setPreviewType ] = useState(hidePrint ? 0 : 1)
    
    const findPreview = () => {
        if (clinic && clinic.object) {
            setLoadingPreview(true)
            setPreview(null)

            axios.post(
                `${process.env.REACT_APP_PRESCRIPTION}/preview`, 
                {
                    user: userToObject(user.object),
                    clinic: {
                        addressComplement: selectedClinic.get("addressComplement") || "",
                        addressNumber: selectedClinic.get("addressNumber") || "",
                        addressReference: selectedClinic.get("addressReference") || "",
                        addressStreet: selectedClinic.get("addressStreet") || "",
                        city: selectedClinic.get("city") || "",
                        cityId: null,
                        cnpj: selectedClinic.get("cnpj") || "",
                        color: selectedClinic.get("color") || colors[0],
                        companyName: selectedClinic.get("companyName") || "",
                        crmv: selectedClinic.get("crmv") || "",
                        crmvStateId: selectedClinic.get("crmvStateId") || "",
                        displayAppointment: selectedClinic.get("displayAppointment"),
                        displayLogoIn: selectedClinic.get("displayLogoIn") || {},
                        displayNotesIn: selectedClinic.get("displayNotesIn") || {},
                        displayStore: selectedClinic.get("displayStore"),
                        email: selectedClinic.get("email") || "",
                        facebook: selectedClinic.get("facebook") || "",
                        instagram: selectedClinic.get("instagram") || "",
                        isDeleted: false,
                        isHomeCare: false,
                        isPhysicalClinic: false,
                        logo: selectedClinic.get("logo") ? selectedClinic.get("logo")._url : undefined,
                        municipalRegistrationId: selectedClinic.get("municipalRegistrationId") || "",
                        name: selectedClinic.get("name") || "",
                        neighborhood: selectedClinic.get("neighborhood") || "",
                        notes: selectedClinic.get("notes") || "",
                        objectId: null,
                        phone: selectedClinic.get("phone") || "",
                        printTemplate: selectedClinic.get("printTemplate") || "10",
                        registrationMAPA: selectedClinic.get("registrationMAPA") || "",
                        site: selectedClinic.get("site") || "",
                        stateId: selectedClinic.get("stateId") || "",
                        stateRegistrationId: selectedClinic.get("stateRegistrationId") || "",
                        twitter: selectedClinic.get("twitter") || "",
                        zipCode: selectedClinic.get("zipCode") || ""
                    }
                }
            )
            .then(response => {
                setPreview(response.data)
                setLoadingPreview(false)
            })
        }
    }

    const getClinicUser = () => {
        var query = new Parse.Query("ClinicUser");
        query.equalTo("clinic", clinic.object);
        query.equalTo("user", user.object);
        query.equalTo("isDeleted", false);
        query.include("user");
        query.include("clinic");
        query.include("profile");
        query.find()
        .then(_clinicUser => {
            setClinicUser(_clinicUser[0])
        })
        .catch(error => {
            console.error(error)
            Swal.fire(
                'Desculpe',
                'Ocorreu algum erro ao tentar buscar as configurações',
                'error'
            )
        })
    }

    useEffect(() => {
        getClinicUser()
    }, [ clinic, location ])

    useEffect(() => {
        findPreview()
    }, [ clinicUser ])

    useDidMountEffect(() => {
        if(isConfigSaved){
            findPreview()
        }
    }, [ isConfigSaved ])

    const saveButtonContent = saveLoading ? (
        <div style={{ textAlign: "center", padding: '36px' }}>
            <CircularProgress size={28} thickness={2} color="primary"/>
        </div>
    ) : isConfigSaved ? (
        <>
            <IconPrimaryCheck id="add-icon" width="24" height="24" fill="#FFFFFF" /> Configurações Salvas
        </>
    ) : (
        <>
            <IconHistory id="add-icon" width="18" height="18" fill="#FFFFFF" /> Salvar Alterações
        </>
    )

    const documentPreview = (
        <>
            <div className="row align-items-center justify-content-between">
                <div className="col-12 col-md-7 pe-3 pe-md-0 mb-3">
                    <div className="btn-tabs align-items-center" style={{overflow:'hidden'}}>
                        {!hidePrint &&
                        <div
                            className={`btn-tab ${previewType == 1 ? 'active' : ''}`}
                            onClick={() => setPreviewType(1)}
                        >
                            PRÉVIA IMPRESSÃO
                        </div>}
                        <div
                            className={`btn-tab ${previewType == 0 ? 'active' : ''}`}
                            onClick={() => setPreviewType(0)}
                        >
                            PRÉVIA DIGITAL
                        </div>
                    </div>
                </div>
                <div className="col-auto d-none d-md-block mb-3">
                    <button
                        className="btn-main-mobile btn-submit btn-square"
                        disabled={saveLoading || isConfigSaved}
                        onClick={() => !saveLoading && !isConfigSaved && onSave && onSave()}
                    >
                        {saveButtonContent}
                    </button>
                </div>
            </div>
            <div className="preview-area">
                <div className="preview">
                    { loadingPreview ? (
                        <div style={{ textAlign: "center", padding: '36px' }}>
                            <CircularProgress size={28} thickness={2} color="primary"/>
                        </div>
                    ) : (
                        <div
                            dangerouslySetInnerHTML={{__html: preview}}
                            className={previewType == 0 ? 'document-digital' : 'document-print'}
                        >
                        </div>
                    )}
                </div>
            </div>
        </>
    )

    return (
        <div id="setting-preview">
            <div className="row">
                <div className="col">
                    <div className="d-block d-md-none mb-3">
                        <button
                            className="vsbox-btn vsbox-btn-primary mt-3"
                            onClick={() => setSeePreviewMobile(true)}
                        >
                            Ver Prévia
                        </button>
                        <button
                            className="btn-main-mobile btn-submit btn-square w-100"
                            onClick={() => !saveLoading && !isConfigSaved && onSave && onSave()}
                            disabled={saveLoading || isConfigSaved}
                        >
                            {saveButtonContent}
                        </button>
                    </div>
                    <div className="d-none d-md-block">
                        {documentPreview}
                    </div>
                </div>
            </div>
            <SwipeableDrawer
                anchor="bottom"
                open={seePreviewMobile}
                className="vsdrawer-bottomsheet"
                onOpen={() => null}
                onClose={() => setSeePreviewMobile(false)}
                PaperProps={{
                    style: {
                        width: "100%"
                    }
                }}
            >
                <div className="vsdrawer-info-header" onClick={() => setSeePreviewMobile(false)}>
                    <div className="row g-3 align-items-center">
                        <div className="col-auto pe-1">
                            <IconArrowRight width={18} height={18} style={{ transform: "rotate(180deg)" }} />
                        </div>
                        <div className="col">
                            Voltar
                        </div>
                    </div>
                </div>
                <div className="vsdrawer-info-body">
                    {documentPreview}
                </div>
            </SwipeableDrawer>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        user: state.user,
        clinic: state.clinic
    };
};
  
export default connect(mapStateToProps)(SettingPreview);