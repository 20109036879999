import React, { useEffect, useState } from "react"
import { connect } from "react-redux"

import { CircularProgress, MenuItem, TextField } from "@mui/material"

import { colors, mandatoryField } from "../../utils";

import VSTooltip from "../../components/vsTooltip/VSTooltip";

import "./TagForm.scss"
const TagForm = (props) => {

    const {
        object,
        defaultName = "",
        clinic,
        onSave,
        onCancel
    } = props

    const update = object?.id ? true : false

    const [ saveLoading, setSaveLoading ] = useState(false)
    const [ hasSubmited, setHasSubmited ] = useState(false)
    const [ showTooltip, setShowTooltip ] = useState(false)

    const [ name, setName ] = useState(object?.get("name") || defaultName)
    const [ errorName, setErrorName ] = useState(false)
    const [ helperName, setHelperName ] = useState(mandatoryField)

    const [ color, setColor ] = useState(object?.get("color") || "#00C4B4")
    const [ errorColor, setErrorColor ] = useState(false)
    const [ helperColor, setHelperColor ] = useState("")

    const validateOnTouch = () => {
        if (hasSubmited) {
            validate()
        }
    }

    const validate = () => {
        let isValid = true

        if (!name) {
            isValid = false
            setErrorName(true)
            setHelperName("O nome da tag é obrigatório")
        } else {
            setErrorName(false)
            setHelperName(mandatoryField)
        }

        if (!color) {
            isValid = false
            setErrorColor(true)
            setHelperColor("A cor da tag é obrigatória")
        } else {
            setErrorColor(false)
            setHelperColor("")
        }

        return isValid
    }

    const fieldsWithErrorText = () => {
        let errors = []
        if (saveLoading) {
            errors.push("Salvando a tag")
        }
        if (errorName) {
            errors.push("Nome")
        }
        if (errorColor) {
            errors.push("Cor")
        }
        return errors
    }

    const save = () => {
        setHasSubmited(true)
        if (validate()) {
            setSaveLoading(true)
            let tag = object
            if (!tag?.id) {
                tag = new Parse.Object("MRTag")
                tag.set("clinic", clinic.object)
                tag.set("isDeleted", false)
            }
            tag.set("name", name)
            tag.set("color", color)
            tag.save()
            .then(_tag => {
                setSaveLoading(false)
                onSave(_tag, _tag.get("name"))
            })
            .catch(error => {
                setSaveLoading(false)
                console.error(error)
                Swal.fire(
                    'Desculpe',
                    `Ocorreu algum erro ao tentar ${object?.id ? "editar" : "cadastrar"} a tag`,
                    'error'
                )
                update && object.revert()
            })
        }
    }

    useEffect(() => {
        validateOnTouch()
    }, [ name, color, hasSubmited ])

    const errors = fieldsWithErrorText()
    const isValid = errors.length == 0
    const isSingular = errors.length == 1

    return (
        <div className="vsbox border">
            <div className="vsbox-body mb-0">
                <div className="row g-3">
                    <div className="col-12">
                        <div className="vsbox-title">{ object?.id ? "Editar Tag" : "Nova Tag" }</div>
                    </div>
                    <div className="col-7 col-sm-8">
                        <TextField
                            placeholder="Ex: Consulta"
                            label="Título da Tag"
                            value={name}
                            onChange={({ target: { value } }) => setName(value)}
                            error={errorName}
                            helperText={helperName}
                            size="small"
                            variant="outlined"
                            fullWidth
                        />
                    </div>
                    <div className="col-5 col-sm-4">
                        <TextField
                            select
                            label="Cor da Tag"
                            value={color}
                            onChange={({ target: { value } }) => setColor(value)}
                            error={errorColor}
                            helperText={helperColor}
                            size="small"
                            variant="outlined"
                            fullWidth
                        >
                            { colors.map((color) => (
                                <MenuItem key={color} value={color}>
                                    <span style={{
                                        width: "18px",
                                        height: "18px",
                                        marginRight: "8px",
                                        backgroundColor: color,
                                        borderRadius: "50%",
                                        display: "block"
                                    }}
                                    ></span>
                                </MenuItem>
                            ))}
                        </TextField>
                    </div>
                    <div className="col">
                        <button className="vsbox-btn vsbox-btn-alert w-100" onClick={() => onCancel()}>
                            Cancelar
                        </button>
                    </div>
                    <VSTooltip
                        placement="top-start"
                        open={showTooltip}
                        onOpen={() => {
                            if (!isValid) {
                                setShowTooltip(true)
                            }
                        }}
                        onClose={() => setShowTooltip(false)}
                        title={
                            <div className="vsdrawer-error">
                                O{ isSingular ? "" : "s" } campo{ isSingular ? "" : "s" } abaixo 
                                est{ isSingular ? "á" : "ão" } com erro no preenchimento:
                                <ul>
                                    { errors.map((error, index) => (
                                        <li key={index}>{ error }</li>
                                    ))}
                                </ul>
                            </div>
                        }
                    >
                        <div className="col">
                            <button className="btn-submit btn-square w-100" disabled={!isValid} onClick={() => isValid && save()}>
                                { saveLoading ? (
                                    <CircularProgress size="20px" />
                                ) : (
                                    "Salvar"
                                )}
                            </button>
                        </div>
                    </VSTooltip>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        clinic: state.clinic
    }
}

export default connect(mapStateToProps)(TagForm)