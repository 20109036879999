import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';

import TextField from '@mui/material/TextField';
import { CircularProgress } from '@mui/material';

import { ReactComponent as IconAddO } from "../../../../assets/icon-add-o.svg"
import { ReactComponent as IconCloseO } from "../../../../assets/icon-close-o.svg"

import {  addEvent, dayjs, mandatoryField, optionalField, saveOnTracker, showArrayIndex } from '../../../../utils'
import { getPatient } from '../../Patient'
import { defaultSurgeryWithValidation, getSurgery } from './Surgery';
import { ACL, APP_DEVICE, APP_NAME, APP_VERSION } from '../../../../constants';

import DateInput from '../../../../components/dateInput/DateInput';
import VSDrawer from '../../../../components/vsDrawer/VSDrawer';
import VSMedicalRecordsAudit from '../../../../components/vsMedicalRecordsAudit/VSMedicalRecordsAudit';
import VSError from '../../../../components/vsError/VSError';

import './SurgeryForm.scss'
const SurgeryForm = (props) => {

    const {
        id,
        id_cirurgia
    } = useParams()

    const {
        history,
        clinic,
        permissions
    } = props

    const update = id_cirurgia !== "novo"

    const [ loading, setLoading ] = useState(false)
    const [ error, setError ] = useState(null)
    const [ hasSubmited, setHasSubmited ] = useState(false)
    const [ patient, setPatient ] = useState(null)
    const [ surgery, setSurgery ] = useState(null)
    
    const [ surgeries, setSurgeries ] = useState([{ ...defaultSurgeryWithValidation }])

    const defaultDate = dayjs()
    const [ documentDate, setDocumentDate ] = useState(defaultDate.toDate())
    const [ documentDateText, setDocumentDateText ] = useState(defaultDate.format("DD/MM/YYYY"))
    const [ errorDocumentDate, setErrorDocumentDate ] = useState(false)
    const [ helperDocumentDate, setHelperDocumentDate ] = useState(<span>Campo <b>Obrigatório</b></span>)
    const [ notes, setNotes ] = useState('')

    const handleDocumentDate = (_date) => {
        setDocumentDateText(_date)

        if (_date.match(/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/g)) {
            let newDate = dayjs(_date, "DD/MM/YYYY")
            if (newDate.isValid()) {
                setDocumentDate(newDate.toDate())
                return
            }
        }
        setDocumentDate(null)
    }
    
    const setSurgeryArray = (index, field, value) => {
        let newSurgeries = [...surgeries]
        newSurgeries[index][field] = value
        setSurgeries(newSurgeries)
    }

    const removeSurgeryArray = (index) => {
        let newSurgeries = [...surgeries]
        newSurgeries.splice(index, 1)
        setSurgeries(newSurgeries)
    }

    const validateOnTouch = () => {
        validate()
    }

    const validate = () => {
        let isValid = true
        if (loading) {
            isValid = false
        }

        if (error?.message) {
            isValid = false
        }

        const libDocumentDate = dayjs(documentDateText, "DD/MM/YYYY")
        if (libDocumentDate.isValid()) {
            if (libDocumentDate.format("YYYY") < 1971) {
                isValid = false
                setErrorDocumentDate(true)
                setHelperDocumentDate("A data não pode ser menor que 1971")
            } else if (libDocumentDate.diff(dayjs(), "years") > 5) {
                isValid = false
                setErrorDocumentDate(true)
                setHelperDocumentDate("A data não pode exceder 5 anos")
            } else {
                setErrorDocumentDate(false)
                setHelperDocumentDate(<span>Campo <b>Obrigatório</b></span>)
            }
        } else {
            isValid = false
            setErrorDocumentDate(true)
            setHelperDocumentDate("A data informada não é válida")
        }

        surgeries && surgeries.map((surgery, index) => {
            if (surgery.surgery) {
                setSurgeryArray(index, "errorSurgery", false)
                setSurgeryArray(index, "helperSurgery", mandatoryField)
            } else {
                isValid = false
                setSurgeryArray(index, "errorSurgery", true)
                setSurgeryArray(index, "helperSurgery", `A cirurgia ${showArrayIndex(index + 1)} não foi preenchida`)
            }
        })

        return isValid
    }

    const isValid = () => !loading && !errorDocumentDate && surgeries.filter(surgery => surgery.errorSurgery).length == 0

    const fieldsWithErrorText = () => {
        let errors = []
        if (loading) {
            errors.push("Estamos procurando a cirurgia")
        }
        if (error?.message) {
            errors.push(error.message)
        }
        if (errorDocumentDate) {
            errors.push("Data da cirurgia")
        }
        surgeries.map((surgery, index) => {
            if (surgery.errorSurgery) {
                errors.push(`A cirurgia ${showArrayIndex(index + 1)} não foi preenchida`)
            }
        })
        if (!permissions?.check(ACL.SURGERY, ACL.Rule.EDIT, surgery)) {
            errors.push("Acesso negado")
        }
        return errors
    }

    const getPatientOrError = () => {
        setLoading(true)
        setError(null)

        getPatient(id, clinic.object)
        .then(_patient => {
            setPatient(_patient)
        })
        .catch(error => {
            Swal.fire(
                'Desculpe',
                error,
                'error'
            )
            .then(_ => {
                setLoading(false)
                setError({
                    message: error,
                    function: "getPatientOrError"
                })
            })
        })
    }

    const getSurgeryOrError = () => {
        if (permissions?.check(ACL.SURGERY, ACL.Rule.VIEW)) {
            saveOnTracker("Iniciou", "Consulta", update ? id_cirurgia : "")
            if (update) {
                setLoading(true)
                setError(null)

                getSurgery(id_cirurgia, patient)
                .then(_surgery => {
                    setSurgery(_surgery)
                    setDocumentDate(_surgery.get("documentDate"))
                    setDocumentDateText(dayjs(_surgery.get("documentDate")).format("DD/MM/YYYY"))
                    setSurgeries(_surgery.get("surgeries"))
                    setNotes(_surgery.get("notes"))
                    setLoading(false)
                })
                .catch(error => {
                    Swal.fire(
                        'Desculpe',
                        error,
                        'error'
                    )
                    .then(_ => {
                        setLoading(false)
                        setError({
                            message: error,
                            function: "getSurgeryOrError"
                        })
                    })
                })
            } else {
                let newSurgery = new Parse.Object("Surgery")
                newSurgery.setACL(clinic.ACL)
                newSurgery.set("clinic", clinic.object)
                newSurgery.set("createdBy", Parse.User.current())
                newSurgery.set("createdApp", APP_NAME)
                newSurgery.set("createdDevice", APP_DEVICE)
                newSurgery.set("createdAppVersion", APP_VERSION)
                newSurgery.set("isDeleted", false)
                newSurgery.set("patient", patient)
                setSurgery(newSurgery)
                setLoading(false)
            }
        }
    }

    const save = () => {
        return new Promise((resolve, reject) => {
            setHasSubmited(true)
            if (validate()) {
                setLoading(true)
                surgery.set('documentDate', documentDate);
                surgery.set('surgeries', surgeries.map(surgery => ({
                    surgery: surgery.surgery,
                    materials: surgery.materials,
                    complications: surgery.complications
                })));
                surgery.set('notes', notes);
                surgery.set("changedAt", new Date())
                surgery.set("changedBy", Parse.User.current())
                surgery.set("changedApp", APP_NAME)
                surgery.set("changedDevice", APP_DEVICE)
                surgery.set("changedAppVersion", APP_VERSION)
                surgery.save()
                .then(_surgery => {
                    saveOnTracker("Concluiu", "Cirurgia", _surgery.id)
                    resolve(_surgery)
                })
                .catch(error => {
                    setLoading(false)
                    console.error(error)
                    Swal.fire(
                        'Desculpe',
                        `Ocorreu algum erro ao tentar ${surgery.id ? "editar" : "cadastrar"} a cirurgia do animal`,
                        'error'
                    )
                    update && surgery.revert()
                    reject()
                })
            } else {
                reject()
            }
        })
    }

    useEffect(() => {
        validateOnTouch()
    }, [ 
        documentDate, documentDateText, notes,
        JSON.stringify(surgeries.map(surgery => ({ surgery: surgery.surgery, materials: surgery.materials, complications: surgery.complications })))
    ])

    useEffect(() => {
        if (surgery && !permissions?.check(ACL.SURGERY, ACL.Rule.EDIT, surgery)) {
            permissions?.error()
        }
    }, [ surgery ])

    useEffect(() => {
        if (patient) {
            getSurgeryOrError()
        }
    }, [ patient ])

    useEffect(() => {
        if (id) {
            getPatientOrError()
        }
    }, [])

    const errors = fieldsWithErrorText()
    const isSingular = surgeries.length == 1

    return (
        <VSDrawer
            id="surgery-form"
            width="50%"
            title={update ? "Editar Registro de Cirurgia Realizada" : "Novo Registro de Cirurgia Realizada"}
            cancel={update ? <span>Cancelar Registro<span className="d-none d-md-inline"> de Cirurgia</span></span> : <span>Cancelar Registro<span className="d-none d-md-inline"> de Nova Cirurgia</span></span>}
            submit={isValid ? <span>Salvar Registro<span className="d-none d-md-inline"> de Cirurgia</span></span> : <span>Preencha os campos<span className="d-none d-md-inline"> necessários</span></span>}
            errors={errors}
            onSubmit={save}
            onCancel={() => history.goBack()}
            onAfterSave={(object) => {
                history.replace(`/animal/${patient.id}/cirurgia`)
                addEvent("Surgery__getList", {})
            }}
            VSDrawerSuccessProps={{
                title: "Registro de Cirurgia salvo com Sucesso",
                text: ""
            }}
            VSDrawerCancelProps={{
                title: "Tem certeza que deseja cancelar o registro de cirurgia?",
                confirm: "Cancelar Registro de Cirurgia",
                cancel: "Voltar para Edição"
            }}
        >
            { loading ? (
                <div className="row data-loading">
                    <div className="col">
                        <CircularProgress />
                    </div>
                </div>
            ) : error?.message ? (
                <VSError
                    message={error.message}
                    onClose={() => {
                        switch(error.function) {
                            case "getPatientOrError":
                                getPatientOrError()
                                break
                            case "getSurgeryOrError":
                                getSurgeryOrError()
                                break
                            default:
                        }
                    }}
                />
            ) : (
                <div className="row">
                    <div className="col">
                        <div className="row align-items-center mb-3">
                            <div className="col-auto">
                                <div className="input-title">
                                    Data da realização
                                </div>
                            </div>
                            <div className="col-auto" style={{ width: '200px' }}>
                                <DateInput
                                    className="m-0"
                                    placeholder="00/00/0000"
                                    value={documentDateText || null}
                                    error={errorDocumentDate}
                                    helperText={null}
                                    onChange={(value) => handleDocumentDate(value)}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                />
                            </div>
                            { errorDocumentDate && (
                                <div className="col-12">
                                    <small className="text-danger">{ helperDocumentDate }</small>
                                </div>
                            )}
                        </div>
                        <div className="row mb-2">
                            <div className="col-12">
                                <div className="input-title input-height">
                                    Dados da{ isSingular ? "" : "s" } Cirurgia{ isSingular ? "" : "s" } Realizada{ isSingular ? "" : "s" }
                                </div>
                            </div>
                        </div>
                        { surgeries.map((surgery, index) => (
                            <React.Fragment key={index}>
                                <div className="row align-items-center mb-3">
                                    <div className="col">
                                        <div className="input-array py-1">
                                            Cirurgia { showArrayIndex(index + 1) }
                                        </div>
                                    </div>
                                    { surgeries.length > 1 && (
                                        <div className="col-auto">
                                            <button
                                                className="btn-array px-0"
                                                onClick={() => removeSurgeryArray(index)}
                                            >
                                                <IconCloseO width="24" height="24" />
                                                Remover Cirurgia
                                            </button>
                                        </div>
                                    )}
                                </div>
                                <div className="row align-items-center mb-3">
                                    <div className="col-12">
                                        <div className="input-subtitle mb-1">Nome da Cirurgia { showArrayIndex(index + 1) }</div>
                                        <TextField
                                            placeholder="Digite o nome da cirurgia"
                                            value={surgery.surgery}
                                            onChange={({ target: { value } }) => setSurgeryArray(index, "surgery", value)}
                                            error={surgery.errorSurgery}
                                            helperText={surgery.helperSurgery}
                                            size="small"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </div>
                                </div>
                                <div className="row align-items-center mb-3">
                                    <div className="col-12">
                                        <div className="input-subtitle mb-1">Materiais e Implantes</div>
                                        <TextField
                                            placeholder="Digite os materias e implantes utilizados aqui"
                                            value={surgery.materials}
                                            onChange={({ target: { value } }) => setSurgeryArray(index, "materials", value)}
                                            helperText={optionalField}
                                            size="small"
                                            variant="outlined"
                                            fullWidth
                                            multiline
                                        />
                                    </div>
                                </div>
                                <div className="row align-items-center mb-3">
                                    <div className="col-12">
                                        <div className="input-subtitle mb-1">Complicações intra-operatórias</div>
                                        <TextField
                                            placeholder="Digite as complicações aqui"
                                            value={surgery.complications}
                                            onChange={({ target: { value } }) => setSurgeryArray(index, "complications", value)}
                                            helperText={optionalField}
                                            size="small"
                                            variant="outlined"
                                            fullWidth
                                            multiline
                                        />
                                    </div>
                                </div>
                            </React.Fragment>
                        ))}
                        <div className="row align-items-center mb-3">
                            <div className="col-12">
                                <button
                                    className="btn-add" 
                                    onClick={() => setSurgeries(surgeries.concat({ ...defaultSurgeryWithValidation }))}
                                >
                                    <IconAddO width="20" height="20" />
                                    Adicionar Cirurgia { showArrayIndex(surgeries.length + 1) }
                                </button>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="col-12">
                                <div className="input-title input-height">
                                    Anotações (Opcional)
                                </div>
                            </div>
                            <div className="col-12">
                                <TextField
                                    value={notes}
                                    placeholder="Escreva suas anotações sobre a aplicação aqui"
                                    onChange={({ target: { value } }) => setNotes(value)}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                />
                            </div>
                        </div>
                        <VSMedicalRecordsAudit parseObject={surgery} />
                    </div>
                </div>
            )}
        </VSDrawer>
    )
}

const mapStateToProps = state => {
    return {
        clinic: state.clinic,
        permissions: state.permissions
    };
};
  
export default connect(mapStateToProps)(SurgeryForm)