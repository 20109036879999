import VSModal from "../../components/vsModal/VSModal"
import { APP_DEVICE, APP_NAME, APP_VERSION, Filter_Limit } from "../../constants"
import { diacriticSensitive, firstName } from "../../utils"
import { clinicIsNotDeleted } from "../clinic/Clinic"

export const ownerIsNotDeleted = (clinicId) => {
    const queryClinic = clinicIsNotDeleted(clinicId)
    const queryOwner = new Parse.Query("Owner");
    queryOwner.equalTo("isDeleted", false);
    queryOwner.matchesQuery("clinic", queryClinic);
    return queryOwner
}

export const deleteOwner = (owner, callback) => {
    const ownerFirstName = firstName(owner.get("name"), "Tutor")
    Swal.fire({
        // allowOutsideClick: false,
        showConfirmButton: false,
        customClass: {
            popup: 'p-0',
            content: 'p-0',
        },
        html: (
            <VSModal
                title="Tem certeza que deseja excluir este cadastro de Tutor(a)?"
                text="Todos os registros, históricos e animais vinculados a este(a) tutor(a) serão excluídos. Os dados não poderão ser recuperados e esta operação não poderá ser desfeita"
                textConfirm={<>Digite o <b>primeiro nome</b> do(a) tutor(a) para prosseguir com a exclusão do cadastro</>}
                placeholderConfirm={`Digite o Nome do Tutor: ${ownerFirstName}`}
                compareTo={ownerFirstName}
                options={[
                    {
                        type: "cancel",
                        text: "Cancelar Operação",
                        action: () => {
                            Swal.close()
                        }
                    },
                    {
                        type: "confirm",
                        text: "Excluir Cadastro",
                        action: () => {
                            const clinic = owner.get("clinic")
                            Parse.Cloud.run("saveOwnerForClinic", {
                                data: {
                                    objectId: owner.id,
                                    isDeleted: true
                                },
                                clinicId: clinic.id || clinic.objectId,
                                appVersionName: APP_VERSION,
                                deviceType: APP_DEVICE,
                                appContentType: APP_NAME
                            })
                            .then(_ => {
                                Swal.fire(
                                    'Excluído!',
                                    'O(A) tutor(a) foi excluído(a) com sucesso.',
                                    'success'
                                )
                                .then(_ => {
                                    callback && callback()
                                })
                            })
                            .catch(error => {
                                console.error(error)
                                Swal.fire(
                                    'Desculpe',
                                    'Ocorreu algum erro ao tentar excluir o(a) tutor(a)',
                                    'error'
                                )
                            })
                        }
                    }
                ]}
            />
        )
    })
}

var searchOwnersWithDelay = null;
export const searchOwners = (name, clinic) => {
    return new Promise((resolve, reject) => {
        clearInterval(searchOwnersWithDelay);
        searchOwnersWithDelay = setTimeout(() => {
            const queryOwner = new Parse.Query("Owner")
            queryOwner.equalTo("clinic", clinic)
            queryOwner.equalTo("isDeleted", false)
            if (name) {
                queryOwner.matches("name", diacriticSensitive(name), "im");
            }
            queryOwner.ascending("name")
            queryOwner.limit(Filter_Limit)
            queryOwner.withCount()
            queryOwner.find()
            .then(_owners => {
                resolve(_owners)
            })
            .catch(error => {
                console.error(error);
                reject("Ocorreu algum erro ao tentar buscar o(a) tutor(a)")
            })
        }, 300);
    })
}

export const getOwner = (id, clinic, includes = ["patients"]) => {
    return new Promise((resolve, reject) => {
        var query = new Parse.Query("Owner");
        query.equalTo("clinic", clinic);
        query.equalTo("isDeleted", false);
        includes.map(className => {
            if (className !== "patients") {
                query.include(className)
            }
        })
        query.get(id)
        .then(_owner => {
            if (includes.indexOf("patients") != -1) {
                var queryPatient = new Parse.Query("Patient");
                queryPatient.equalTo("owner", _owner);
                queryPatient.equalTo("clinic", clinic);
                queryPatient.equalTo("isDeleted", false);
                queryPatient.find()
                .then(_patients => {
                    _owner.patients = _patients
                    resolve(_owner)
                }) 
                .catch(error => {
                    console.error(error)
                    reject('Ocorreu algum erro ao tentar buscar o(s) animal(is) do(a) tutor(a)')
                })
            } else {
                resolve(_owner)
            }
        })
        .catch(error => {
            console.error(error)
            reject('Ocorreu algum erro ao tentar buscar o(a) tutor(a)')
        })
    })
}

export const saveOwnerContactInfo = (owner, phoneOwner, emailOwner, cpfOwner) => {
    let hasChanged = false
    if (phoneOwner != owner.get("phone")) {
        hasChanged = true
    }
    if (emailOwner != owner.get("email")) {
        hasChanged = true
    }
    if (cpfOwner != owner.get("cpf")) {
        hasChanged = true
    }
    if (hasChanged) {
        return new Promise((resolve, reject) => {
            const clinic = owner.get("clinic")
            Parse.Cloud.run("saveOwnerForClinic", {
                data: {
                    objectId: owner.id,
                    phone: phoneOwner,
                    email: emailOwner,
                    cpf: cpfOwner
                },
                clinicId: clinic.id || clinic.objectId,
                appVersionName: APP_VERSION,
                deviceType: APP_DEVICE,
                appContentType: APP_NAME
            })
            // owner.save()
            .then(_owner => {
                resolve(_owner)
            })
            .catch(error => {
                console.error(error)
                Swal.fire(
                    'Desculpe',
                    `Ocorreu algum erro ao tentar salvar as informações do tutor`,
                    'error'
                )
                reject()
            })
        })
    } else {
        return Promise.resolve(owner)
    }
}

export const formattedAddress = ({ address, number, complement, neighborhood, city, state, zipCode }) => {
    // Nome da Rua, 00 (Complemento) - Bairro, Cidade - UF, 00000-000
    let formattedAddress = []
    if (address) {
        formattedAddress.push(`${address}${number ? "," : ""}`)
    }
    if (number) {
        formattedAddress.push(number)
    }
    if (complement) {
        formattedAddress.push(`(${complement})`)
    }
    if (neighborhood) {
        formattedAddress.push(`${address?.length > 0 ? "- " : ""}${neighborhood}${city ? "," : ""}`)
    }
    if (city) {
        formattedAddress.push(city)
    }
    if (state) {
        formattedAddress.push(`${address?.length > 0 ? "- " : ""}${state}${zipCode ? "," : ""}`)
    }
    if (zipCode) {
        formattedAddress.push(zipCode)
    }
    return formattedAddress.join(" ")
}

export const formattedAddressFromOwner = (owner) => {
    if (owner && owner.get) {
        return formattedAddress({
            address: owner.get("addressStreet"),
            number: owner.get("addressNumber"),
            complement: owner.get("addressComplement"),
            neighborhood: owner.get("neighborhood"),
            city: owner.get("city"),
            state: owner.get("stateId"),
            zipCode: owner.get("zipCode")
        })
    }
    return ""
}