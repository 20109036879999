import React, { useState, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { useParams } from 'react-router';
import { connect } from 'react-redux';

import VSDrawer from '../../components/vsDrawer/VSDrawer';
import PatientSelectQuery from './PatientSelectQuery';

import './PatientSelectForm.scss'
const PatientSelectForm = (props) => {

    const {
        proxima
    } = useParams()

    const {
        state
    } = useLocation()

    const {
        text,
        clinic,
        history
    } = props

    const getNextRoute = (patient) => {
        const patientId = patient?.id
        switch (proxima) {
            case "agenda":
                return history.replace(
                    `/agenda/novo?selecionado`, 
                    {
                        ...state,
                        id: patientId
                    }
                )
            case "vacina":
                return history.replace(`/animal/${patientId}/vacina/novo?selecionado`)
            case "lembrete":
                return history.replace(`/animal/${patientId}/vacina/novo?selecionado`)
            case "consulta":
                return history.replace(`/animal/${patientId}/consulta/novo?selecionado`)
            case "prescricao":
                return history.replace(`/prescricao/${patientId}/novo?selecionado`)
            case "venda":
                return history.replace(`/venda/${patientId}/novo?selecionado`, state)
            case "orcamento":
                return history.replace(`/orcamento/${patientId}/novo?selecionado`, state)
            default:
                return history.replace(`/animal/${patientId}?selecionado`)
        }
    }

    const getTitle = () => {
        switch (proxima) {
            case "agenda":
                return "Novo Evento"
            case "vacina":
                return "Nova Vacina"
            case "lembrete":
                return "Novo Vacina"
            case "consulta":
                return "Nova Consulta"
            case "prescricao":
                return "Nova Prescrição"
            case "venda":
                return "Nova Venda"
            case "orcamento":
                return "Novo Orçamento"
            default:
                return "Selecione o Animal"
        }
    }

    return (
        <VSDrawer
            id="patient-query"
            width="80%"
            title={getTitle()}
            onCancel={() => history.goBack()}
            onClose={() => history.goBack()}
            VSDrawerFooterProps={{
                show: false
            }}
        >
            <PatientSelectQuery 
                getNextRoute={getNextRoute}
                proxima={proxima}
                {...props}
            />
        </VSDrawer>
    )
}

const mapStateToProps = state => {
    return {
        clinic: state.clinic,
        onboarding: state.onboarding
    };
};
  
export default connect(mapStateToProps)(PatientSelectForm);