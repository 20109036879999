import React, { useState, useEffect, useRef } from 'react'
import { TextField } from '@mui/material';

import VSRadio from '../../../../../components/vsRadio/VSRadio';
import VSTooltip from '../../../../../components/vsTooltip/VSTooltip';
import DateInput from '../../../../../components/dateInput/DateInput';
import { dayjs, validateDate } from '../../../../../utils';

const DateStatusField = (props) => {

    const {
        setDocumentDate,
        documentDateText,
        setDocumentDateText,
        errorDocumentDate,
        helperDocumentDate,
        isReminder,
        setIsReminder,
        changeVaccineToApplied
    } = props

    const [ showReminderTooltip, setShowReminderTooltip ] = useState(false)

    const libDocumentDate = dayjs(documentDateText, "DD/MM/YYYY")
    const isReminderOptions = [
        {
            value: "0",
            label: "Aplicada",
        },
        {
            value: "1",
            label: "Programada",
            disabled: libDocumentDate.add(0, 'days').isBefore()
        }
    ]

    const handleDocumentDate = (date) => {
        const newDocumentDate = dayjs(date, "DD/MM/YYYY")
        if(date && newDocumentDate.isValid()){
            if(newDocumentDate.add(0, 'days').isBefore()){
                setIsReminder("0")
            } else {
                setIsReminder("1")
            }
        }
        setDocumentDate(validateDate(date))
        if(setDocumentDateText){
            setDocumentDateText(date)
        }
    }

    var tooltipInterval = 0

    return (
        <div className="row align-items-center">
            <div className="col-12 col-md-6 pt-3">
                <DateInput
                    className="w-auto"
                    placeholder="00/00/0000"
                    label="Data da vacinação"
                    value={documentDateText || null}
                    error={errorDocumentDate}
                    helperText={helperDocumentDate}
                    onChange={(value) => handleDocumentDate(value)}
                    size="small"
                    variant="outlined"
                    fullWidth
                />
            </div>
            <div className="col-12 col-md-6 mt-3 mt-md-0">
                {!changeVaccineToApplied && (
                <div className="row align-items-center">
                    <div className="col-12">
                        <div className="input-title input-height pb-0">
                            STATUS DA VACINA (OBRIGATÓRIO)
                        </div>
                    </div>
                    <VSTooltip
                        PopperProps={{ className: "MuiTooltip-popper mb-4" }}
                        placement="top-end"
                        open={showReminderTooltip}
                        onOpen={() => setShowReminderTooltip(true)}
                        onClose={() => {
                            tooltipInterval = setTimeout(() => {
                                setShowReminderTooltip(false)
                            }, 100);
                        }}
                        leaveTouchDelay={0}
                        title={
                            isReminder != "1" && libDocumentDate.add(0, 'days').isBefore() ?
                            <p>Insira uma data futura para programar a aplicação</p> : 
                            <p>Você pode registrar uma aplicação de Vacina ou programar uma próxima dose.</p>
                        }
                    >
                        <div 
                            onClick={() => {
                                clearInterval(tooltipInterval);
                                setShowReminderTooltip(!showReminderTooltip)
                            }} 
                            className="col-12">
                                <VSRadio
                                    horizontal={true}
                                    options={isReminderOptions}
                                    value={isReminder}
                                    onChange={(_, value) => {
                                        setIsReminder(value)
                                    }}
                                />
                            {/* <p className="text-warning">Não é possível alterar a recorrência de um único lançamento</p> */}
                        </div>
                    </VSTooltip>
                </div>)}
            </div>
        </div>
    )
}

export default DateStatusField