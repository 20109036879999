import { TextField } from "@mui/material"
import { Autocomplete } from "@mui/lab"
import { vaccineTypeOptions } from "../Vaccine"

const VaccineTypeField = (props) => {

    const {
        vaccineType,
        setVaccineType,
        errorVaccineType,
        helperVaccineType
    } = props

    return (
        <div className="row">
            <div className="col-12">
                <div className="input-subtitle mb-1">
                    Tipo da Vacina
                </div>
            </div>
            <div className="col-12">
                <Autocomplete
                    onChange={(_, newValue) => setVaccineType(newValue || "")}
                    onInputChange={(event, newInputValue) => event && setVaccineType(newInputValue || "")}
                    inputValue={vaccineType}
                    options={vaccineTypeOptions}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            placeholder="Selecione o tipo da vacina (V8, V10, Raiva, etc.)"
                            error={errorVaccineType}
                            helperText={helperVaccineType}
                            size="small"
                            variant="outlined"
                            fullWidth
                        />
                    )}
                    openOnFocus
                    freeSolo
                />
            </div>
        </div>
    )
}

export default VaccineTypeField