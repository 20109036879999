import React, { useState } from 'react'

import { ReactComponent as IconArrowLeft } from "../../assets/icon-arrow-left.svg"

import { Collapse } from '@mui/material'
import { useWindowSize } from '../../utils'

import './VSTimeline.scss'
const VSTimeline = (props) => {

    const {
        day,
        month,
        year,
        className,
        children
    } = props

    const {
        width
    } = useWindowSize()
    
    const [ open, setOpen ] = useState(true)

    return (
        <div id="vstimeline">
            <div className={`vstimeline-head ${className || ""}`} onClick={() => setOpen(!open)}>
                <div className="vstimeline-day">{ day }</div>
                <div className="vstimeline-month">{ month }</div>
                <div className="vstimeline-year">{ year }</div>
                <div className="d-inline-block ms-2">
                    <IconArrowLeft
                        width="10px"
                        height="10px"
                        fill="var(--vsprontuario-gray-color-dark)"
                        style={{ 
                            transform: `rotate(${open ? "90deg" : "270deg"})` 
                        }}
                    />
                </div>
            </div>
            <Collapse
                in={open}
                timeout="auto"
            >
                <div className="vstimeline-body">
                    { children }
                </div>
            </Collapse>
        </div>
    )
}
  
export default VSTimeline