import React, { useState } from 'react';
import Button from '@mui/material/Button';

import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';

import { ReactComponent as IconMenu } from "../../assets/icon-menu.svg"

import "./ContextMenu.scss"

const ContextMenu = (props) => {

    const {
        options
    } = props

    const anchorRef = React.useRef(null);

    const [ open, setOpen ] = useState(false)

    return (
        <div className="context-menu">
            <Button
                className="context-menu-button"
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={() => setOpen(true)}
            >
                <IconMenu fill="#212121" width="24" height="24" />
            </Button>
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={() => setOpen(false)}>
                            <MenuList autoFocusItem={open} className="menu-list">
                                { 
                                    options && options.map((option, index) => 
                                        <MenuItem
                                            key={index}
                                            onClick={() => option.onClick && option.onClick()}
                                            className="menu-option"
                                        >
                                            <div>
                                                <div className="menu-data">
                                                    { option.menu }
                                                </div>
                                            </div>
                                        </MenuItem>
                                    )
                                }
                            </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>
    )
}

export default ContextMenu;