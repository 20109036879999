import { formattedCurrency } from "../../../../utils"
import { APP_DEVICE, APP_NAME, APP_VERSION } from "../../../../constants"
import VSModal from "../../../../components/vsModal/VSModal"
import { getConvertedQuantity, getSupplyTransactionObject, getUnits } from "../../../supply/Supply"

export const SALE = "sale"
export const ESTIMATE = "estimate"

export const PAYMENT_BOLETO = "boleto"
export const PAYMENT_CASH = "cash"
export const PAYMENT_CREDIT = "credit"
export const PAYMENT_DEBIT = "debit"
export const PAYMENT_TRANSFER = "transfer"
export const PAYMENT_OTHER = "other"

export const paymentOptions = [
    { value: PAYMENT_CASH,     label: "Dinheiro"},
    { value: PAYMENT_CREDIT,   label: "Cartão de Crédito"},
    { value: PAYMENT_DEBIT,    label: "Cartão de Débito"},
    { value: PAYMENT_TRANSFER, label: "PIX ou Transferência bancária"},
    { value: PAYMENT_OTHER,    label: "Outras"}
]

export const brandOptions = [
    "Visa",
    "Master Card",
    "American Express",
    "Elo",
    "Hiper Card",
]

export const isQuantityGreaterThanOne = (service) => {
    return service?.quantity > 1
}

export const getPaymentMethod = (method) => {
    switch(method) {
        case PAYMENT_CASH:
            return "Dinheiro"
        case PAYMENT_CREDIT:
            return "Cartão de Crédito"
        case PAYMENT_DEBIT:
            return "Cartão de Débito"
        case PAYMENT_TRANSFER:
            return "PIX ou Transferência Bancária"
        case PAYMENT_BOLETO:
            return "Boleto"
        default:
            return "Outros"
    }
}

export const getInstallmentsByValue = (value) => {
    if (value == 0)
        return []

    var total = [...Array(12).keys()]
    var installments = []
    total.forEach(index => {
        const installment = index + 1
        const installmentValue = value / installment
        if (installmentValue == value) {
            installments.push({
                value: installment,
                label: `À vista de ${ formattedCurrency(value) }`
            })
        }
        else if (installmentValue >= 0.01) {
            installments.push({
                value: installment,
                label: `${ installment }x de ${ formattedCurrency(installmentValue) }`
            })
        }
    })
    return installments
}

export const getSale = (id, patient) => {
    return new Promise((resolve, reject) => {
        var query = new Parse.Query("MRSale");
        query.equalTo("clinic", patient.get("clinic"));
        query.equalTo("isDeleted", false);
        query.equalTo("patient", patient)
        query.include("services.object")
        query.include("services.supplies.object")
        query.get(id)
        .then(_object => {
            if (_object) {
                resolve(_object)
            } else {
                reject(`Não encontramos o documento`)
            }
        }).catch(error => {
            console.error(error)
            reject(`Ocorreu algum erro ao tentar buscar o documento`)
        })
    })
}

export const deleteSale = (sale, callback) => {
    if(!sale)
        return
        
    const isSale = sale.get("documentType") == SALE

    Swal.fire({
        showConfirmButton: false,
        customClass: {
            popup: 'p-0',
            content: 'p-0',
        },
        html: (
            <VSModal
                title={`Tem certeza que deseja excluir ${isSale ? 'esta venda' : 'este orçamento'}?`}
                text={`Todos os dados vinculados a ${isSale ? 'esta venda' : 'este orçamento'} serão excluídos. Os dados não poderão ser recuperados e esta operação não poderá ser desfeita`}
                options={[
                    {
                        type: "cancel",
                        text: "Cancelar Operação",
                        action: () => {
                            Swal.close()
                        }
                    },
                    {
                        type: "confirm",
                        text: "Excluir Cadastro",
                        action: async () => {
                            const units = await getUnits()
                            const toSave = []
                            if (isSale) {
                                sale.get("services")?.map(service => {
                                    service?.supplies?.map(supply => {
                                        let quantity = parseFloat(supply.quantity) * parseInt(service.quantity)
                                        quantity = getConvertedQuantity(quantity, units, supply.unit, supply.object.get("unit"))
                                        if (quantity != 0) {
                                            const supplyTransaction = getSupplyTransactionObject(sale, supply.object, quantity, service.serviceName, service.quantity)
                                            toSave.push(supplyTransaction)
                                        }
                                    })
                                })
                            }

                            sale.set("isDeleted", true)
                            sale.set("changedAt", new Date())
                            sale.set("changedBy", Parse.User.current())
                            sale.set("changedApp", APP_NAME)
                            sale.set("changedDevice", APP_DEVICE)
                            sale.set("changedAppVersion", APP_VERSION)
                            toSave.push(sale)

                            Parse.Object.saveAll(toSave)
                            .then(_ => {
                                Swal.fire(
                                    `Excluíd${isSale ? 'a' : 'o'}!`,
                                    `${isSale ? 'A venda' : 'O orçamento'} foi ${isSale ? 'excluída' : 'excluído'} com sucesso.`,
                                    'success'
                                )
                                .then(_ => {
                                    callback && callback()
                                })
                            })
                            .catch(error => {
                                console.error(error)
                                Swal.fire(
                                    'Desculpe',
                                    `Ocorreu algum erro ao tentar excluir ${isSale ? 'a venda' : 'a orçamento'}`,
                                    'error'
                                )
                                sale.revert()
                            })
                        }
                    }
                ]}
            />
        )
    })
}

export const deletePaymentOfSale = (sale, indexOfPayment, callback) => {
    if(!sale)
        return

    const payments = sale.get("payments")
    if (!payments || !payments[indexOfPayment])
        return

    Swal.fire({
        showConfirmButton: false,
        customClass: {
            popup: 'p-0',
            content: 'p-0',
        },
        html: (
            <VSModal
                title={`Tem certeza que deseja excluir esse pagamento?`}
                text={`O valor referente a esse pagamento será excluído e impactará nos relatórios de Entrada de Caixa. Os dados não poderão ser recuperados e esta operação não poderá ser desfeita`}
                options={[
                    {
                        type: "cancel",
                        text: "Cancelar Operação",
                        action: () => {
                            Swal.close()
                        }
                    },
                    {
                        type: "confirm",
                        text: "Excluir Cadastro",
                        action: () => {
                            const newPayments = [...payments]
                            newPayments.splice(indexOfPayment, 1)

                            sale.set("payments", newPayments)
                            sale.set("changedAt", new Date())
                            sale.set("changedBy", Parse.User.current())
                            sale.set("changedApp", APP_NAME)
                            sale.set("changedDevice", APP_DEVICE)
                            sale.set("changedAppVersion", APP_VERSION)
                            sale.save()
                            .then(_ => {
                                Swal.fire(
                                    `Pagamento excluído!`,
                                    `O pagamento foi excluído com sucesso.`,
                                    'success'
                                )
                                .then(_ => {
                                    callback && callback()
                                })
                            })
                            .catch(error => {
                                console.error(error)
                                Swal.fire(
                                    'Desculpe',
                                    `Ocorreu algum erro ao tentar excluir o pagamento`,
                                    'error'
                                )
                                sale.revert()
                            })
                        }
                    }
                ]}
            />
        )
    })
}