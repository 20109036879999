import { useState } from "react"

import { ReactComponent as IconPlus } from "../../assets/icon-plus.svg"
import { ReactComponent as IconMinus } from "../../assets/icon-minus.svg"

import { InputAdornment, TextField } from "@mui/material"
import VSTooltip from "../vsTooltip/VSTooltip"

import "./VSTextFieldCounter.scss"
const VSTextFieldCounter = (props) => {

    const {
        value,
        onChange,
        ...anotherProps
    } = props

    const [ showAlert, setShowAlert ] = useState(false)

    return (
        <div id="vstextfield-counter">
            <VSTooltip
                open={showAlert}
                title="O valor informado não é numérico, alteramos a quantidade para 1 (um)."
            >
                <TextField
                    {...anotherProps}
                    value={value}
                    onChange={({ target }) => {
                        let _value = target?.value
                        if (_value) {
                            _value.replace(/[^0-9]/g, '')
                        }

                        _value = parseInt(_value)
                        if (isNaN(_value) || _value < 1) {
                            _value = 1
                            if (value != 1) {
                                setShowAlert(true)
                                setTimeout(() => {
                                    setShowAlert(false)
                                }, 3000)
                            }
                        }
                        
                        onChange(_value)
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start" onClick={() => value > 1 ? onChange(value - 1) : null}>
                                <IconMinus fill={value == 1 ? "var(--vsprontuario-gray-color)" : "var(--vsprontuario-primary-color)"} width={24} height={24} />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end" onClick={() => onChange(value + 1)}>
                                <IconPlus width={24} height={24} />
                            </InputAdornment>
                        )
                    }}
                />
            </VSTooltip>
        </div>
    )
}

export default VSTextFieldCounter