import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import { Chip, CircularProgress } from '@mui/material';

import { ReactComponent as IconPrimaryEdit } from "../../assets/icon-primary-edit.svg"
import { ReactComponent as IconPrimaryTrash } from "../../assets/icon-primary-trash.svg"
import { ReactComponent as IconAttachment } from "../../assets/icon-patient-attachment.svg"

import { addEvent, dayjs, formattedCurrency } from '../../utils';
import { getBrandIcon, STATUS_PAID, STATUS_CANCELED, STATUS_PENDING, deleteSubscription } from './Payment';

import VSAccordionSimple from '../../components/vsAccordion/VSAccordionSimple';
import VSPagination from '../../components/vsPagination/VSPagination';
import VSMenu from '../../components/vsMenu/VSMenu';

import './SubscriptionBillView.scss'
const SubscriptionBillView = (props) => {

    const {
        subscription,
        history,
        clinic
    } = props

    const rowsPerPage = 25

    const [ searched, setSearched ] = useState(false)
    const [ loading, setLoading ] = useState(false)
    const [ bills, setBills ] = useState([])
    const [ total, setTotal ] = useState(0)
    const [ page, setPage ] = useState(1)

    const getBills = () => {
        setLoading(true)
        Parse.Cloud.run("getVindiBills", {
            subscriptionId: subscription.id,
            clinicId: clinic.objectId,
            page: page
        })
        .then(response => {
            setSearched(true)
            setBills(response.bills)
            setTotal(response.total)
            setLoading(false)
        })
        .catch(error => {
            setLoading(false)
            console.error(error)
            Swal.fire(
                'Desculpe',
                'Ocorreu algum erro ao buscar os seus pagamentos, tente novamente mais tarde',
                'error'
            )
        })
    }

    const getCharge = (bill) => {
        return bill.charges.find(charge => charge.paid_at)
    }

    const showStatus = (status, amount) => {
        switch(status) {
            case STATUS_PAID:
                return (
                    <Chip label={ amount == 0 ? "Período de Teste" : "Pago" } color="primary" />
                )
            case STATUS_CANCELED:
                return (
                    <Chip label="Cancelado" color="error" />
                )
            case STATUS_PENDING:
                return (
                    <Chip label="Pendente" color="default" />
                )
            default:
                return (
                    <Chip label="-" color="warning" />
                )
        }
    }

    const showPaidAt = (bill) => {
        if (bill.status == STATUS_PAID) {
            const paid = getCharge(bill)
            if (paid)
                return dayjs(paid.paid_at).format("DD/MM/YYYY [às] HH:mm:ss")
            return dayjs(bill.created_at).format("DD/MM/YYYY [às] HH:mm:ss")
        }
        return "-"
    }

    useEffect(() => {
        if (searched) {
            getBills()
        }
    }, [ page ])

    const payment = subscription?.payment_profile

    return (
        <div id="subscription-bill-view">
            <VSAccordionSimple
                header={(expanded) => (
                    `${ expanded ? "Ocultar" : "Ver" } Informações de Pagamento`
                )}
                onOpen={() => { 
                    if (!searched) {
                        getBills()
                    }
                }}
            >
                { payment ? (
                    <div className="vsbox mb-3">
                        <div className="vsbox-body">
                            <div className="row align-items-center g-3">
                                <div className="col-auto">
                                    { getBrandIcon(payment?.payment_company?.code, { width: 64, height: 38 }) }
                                </div>
                                <div className="col-auto">
                                    <label className="vsbox-info">Número do cartão</label>
                                    <div className="vsbox-info-text">
                                        ●●●● ●●●● ●●●● { payment?.card_number_last_four }
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <label className="vsbox-info">Nome impresso</label>
                                    <div className="vsbox-info-text">
                                        { payment?.holder_name }  
                                    </div>
                                </div>
                                <div className="col-12 col-md">
                                    <label className="vsbox-info">Validade</label>
                                    <div className="vsbox-info-text">
                                        { dayjs(payment?.card_expiration).format("MM/YYYY") }
                                    </div>
                                </div>
                                { subscription.status != STATUS_CANCELED && (
                                    <div className="col-12 col-lg-auto">
                                        <div className="row align-items-center justify-content-center">
                                            <div className="col-auto w-100">
                                                <button
                                                    className={`vsbox-btn vsbox-btn-dark`}
                                                    onClick={() => history.push(`/configuracao-clinica/plano-e-cobranca/pagamento`, {
                                                        subscription: subscription
                                                    })}
                                                >
                                                    <IconPrimaryEdit width="24" height="24" fill={"var(--vsprontuario-primary-color-dark)"} />
                                                    Editar Assinatura
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                ) : subscription.status != STATUS_CANCELED && (
                    <div className="row align-items-center mb-3">
                        <div className="col text-end">
                            <button
                                className={`vsbox-btn vsbox-btn-dark`}
                                onClick={() => history.push(`/configuracao-clinica/plano-e-cobranca/pagamento`, {
                                    subscription: subscription
                                })}
                            >
                                <IconPrimaryEdit width="24" height="24" fill={"var(--vsprontuario-primary-color-dark)"} />
                                Adicionar Meio de Pagamento
                            </button>
                        </div>
                    </div>
                )}
                <div className="row g-3">
                    <div className="col-12 overflow-auto pb-1">
                        <table id="pro-payments">
                            <thead>
                                <tr className="table-header">
                                    <td style={{ width: "120px" }}>#</td>
                                    <td>Valor</td>
                                    <td style={{ width: "194px" }}>Pagamento</td>
                                    <td>Situação</td>
                                    <td style={{ width: "84px" }}>NFe</td>
                                </tr>
                            </thead>
                            <tbody>
                                { loading ? (
                                    <tr>
                                        <td colSpan={4}>
                                            <div className="data-loading">
                                                <CircularProgress />
                                            </div>
                                        </td>
                                    </tr>
                                ) : (
                                    bills.length > 0 ? (
                                        bills.map((bill, index) => {
                                            const charge = getCharge(bill)
                                            return (
                                                <tr key={index}>
                                                    <td>
                                                        { bill.id }
                                                    </td>
                                                    <td>
                                                        <VSMenu
                                                            id="menu-patient"
                                                            placement="bottom-start"
                                                            openOnHover
                                                            options={[
                                                                {
                                                                    menu: (
                                                                        <div className="d-block w-100 p-3">
                                                                            <div className="row g-3 align-items-center">
                                                                                <div className="col-auto">
                                                                                    { getBrandIcon(bill?.payment_profile?.payment_company?.code, { width: 36, height: 24 }) }
                                                                                </div>
                                                                                <div className="col">
                                                                                    <b>●●●● ●●●● ●●●● { bill?.payment_profile?.card_number_last_four }</b><br/>
                                                                                    <b>Validade:</b> { dayjs(bill?.payment_profile?.card_expiration).format("MM/YY") }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                }
                                                            ]}
                                                        >
                                                            <div className="patient-name text-truncate">
                                                                { formattedCurrency(parseFloat(bill.amount)) }
                                                            </div>
                                                            { charge && (
                                                                <div className="owner-name text-truncate">
                                                                    { charge?.payment_method.name || "-" }
                                                                </div>
                                                            )}
                                                        </VSMenu>
                                                    </td>
                                                    <td>
                                                        { showPaidAt(bill) }
                                                    </td>
                                                    <td
                                                        className="text-truncate"
                                                    >
                                                        { showStatus(bill.status, bill.amount) }
                                                    </td>
                                                    <td>
                                                        { bill.invoices?.map((invoice, index) => (
                                                            <a href={invoice} key={index} target="_blank">
                                                                <IconAttachment width={32} height={32} />
                                                            </a>
                                                        )) }
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    ) : (
                                        <tr>
                                            <td colSpan={5}>
                                                Não encontramos nenhum pagamento para a assinatura
                                            </td>
                                        </tr>
                                    )
                                )}
                            </tbody>
                        </table>
                    </div>
                    { bills.length > rowsPerPage && (
                        <div className="col-12 mb-3">
                                <VSPagination
                                    currentPage={page}
                                    rowsPerPage={rowsPerPage}
                                    totalRows={total}
                                    onChange={(_currentPage) => {
                                        setPage(_currentPage)
                                    }}
                                />
                        </div>
                    )}
                    { subscription.status != STATUS_CANCELED && (
                        <div id="pro-cancel" className="col-12 mb-3">
                            <b>Cancelar assinatura</b>
                            <div className="row mt-1    ">
                                <div className="col">
                                    Ao cancelar sua assinatura, você perderá acesso as funcionalidades do Prontuário Vet Smart PRO e o acesso aos registros a partir delas.
                                </div>
                                <div className="col-auto">
                                    <button
                                        className={`vsbox-btn vsbox-btn-link px-0`}
                                        style={{ color: "var(--vsprontuario-negative-color)" }}
                                        onClick={() => {
                                            deleteSubscription(clinic.object, subscription, () => {
                                                addEvent("Subscription__getList", {})
                                            })
                                        }}
                                    >
                                        <IconPrimaryTrash width="24" height="24" fill={"var(--vsprontuario-negative-color)"} />
                                        Cancelar Assinatura
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </VSAccordionSimple>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        clinic: state.clinic,
        permissions: state.permissions
    };
};
  
export default connect(mapStateToProps)(SubscriptionBillView);