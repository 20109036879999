import { CircularProgress, TextField } from "@mui/material"
import { forwardRef, useEffect, useImperativeHandle, useState } from "react"
import DateInput from "../../../../../components/dateInput/DateInput"
import VSAccordion from "../../../../../components/vsAccordion/VSAccordion"
import VSCheckbox from "../../../../../components/vsCheckbox/VSCheckbox"
import TextFieldMask from "../../../../../components/textFieldMask/TextFieldMask"

import { ReactComponent as IconArrowLeft } from "../../../../../assets/icon-arrow-left.svg"
import { ReactComponent as VetsmartLogo } from "../../../../../assets/vetsmart-logo.svg"
import { ReactComponent as IconText } from "../../../../../assets/icon-text.svg"
import { ReactComponent as IconSchedule } from "../../../../../assets/icon-schedule.svg"
import { ReactComponent as IconContact } from "../../../../../assets/icon-contact-owner.svg"
import Certified from "../../../../../assets/certified.png"

import "./AutomaticReminderForm.scss"
import { saveOwnerContactInfo } from "../../../../owner/Owner"
import { dayjs, getEmailPreview, getOrCreateMessage, getSmsPreview, getWhatsappPreview, preparePromiseForScheduledMessageCRUD, validateDate, validateEmail, validatePhone } from "../../../../../utils"
import { Skeleton } from "@mui/lab"

var preventMultipleRendersOfPreviews = 0

const AutomaticReminderFormOld = forwardRef((props, ref) => {

    const {
        hasSubmited,
        vaccine,
        patient,
        vaccineType,
        reminderDate,
        sendAutomaticReminderIsActive,
        setSendAutomaticReminderIsActive,
        onChange
    } = props

    const owner = patient?.get("owner")

    const [ reminders, setReminders ] = useState(null)
    const [ loadingReminders, setLoadingReminders ] = useState(true)

    const [ errorSendAutomaticReminderIsActive, setErrorSendAutomaticReminderIsActive ] = useState(false)
    const [ helperSendAutomaticReminderIsActive, setHelperSendAutomaticReminderIsActive ] = useState(<span>Campo Opcional</span>)

    const [ sendAutomaticReminderDate, setSendAutomaticReminderDate ] = useState(null)
    const [ sendAutomaticReminderDateText, setSendAutomaticReminderDateText ] = useState("")
    const [ errorSendAutomaticReminderDate, setErrorSendAutomaticReminderDate ] = useState(false)
    const [ helperSendAutomaticReminderDate, setHelperSendAutomaticReminderDate ] = useState(<span>Campo Opcional</span>)

    const [ sendAutomaticReminderByWhatsapp, setSendAutomaticReminderByWhatsapp ] = useState(false)
    const [ sendAutomaticReminderBySMS, setSendAutomaticReminderBySMS ] = useState(false)
    const [ sendAutomaticReminderByEmail, setSendAutomaticReminderByEmail ] = useState(false)

    const [ phoneOwner, setPhoneOwner ] = useState("")
    const [ errorPhoneOwner, setErrorPhoneOwner ] = useState(false)
    const [ helperPhoneOwner, setHelperPhoneOwner ] = useState(<span>Campo Opcional</span>)

    const [ emailOwner, setEmailOwner ] = useState("")
    const [ errorEmailOwner, setErrorEmailOwner ] = useState(false)
    const [ helperEmailOwner, setHelperEmailOwner ] = useState(<span>Campo Opcional</span>)

    const defaultDate = dayjs(reminderDate)
    const threeDaysBefore    = defaultDate.clone().subtract(3, 'days').format("DD/MM/YYYY")
    const fiveDaysBefore     = defaultDate.clone().subtract(5, 'days').format("DD/MM/YYYY")
    const sevenDaysBefore    = defaultDate.clone().subtract(7, 'days').format("DD/MM/YYYY")
    const fiveteenDaysBefore = defaultDate.clone().subtract(15, 'days').format("DD/MM/YYYY")

    const [ previewWhatsapp, setPreviewWhatsapp ] = useState(null)
    const [ showPreviewWhatsapp, setShowPreviewWhatsapp ] = useState(false)
    const [ previewSMS, setPreviewSMS ] = useState(null)
    const [ showPreviewSMS, setShowPreviewSMS ] = useState(false)
    const [ previewEmail, setPreviewEmail ] = useState(null)
    const [ showPreviewEmail, setShowPreviewEmail ] = useState(false)

    const [ savingOwner, setSavingOwner ] = useState(false)

    const [ editPhone, setEditPhone ] = useState(false)
    const [ editEmail, setEditEmail ] = useState(false)

    useImperativeHandle(ref, () => ({
        validate: validate,
        save: onSave
     }));

    const handleSendAutomaticReminderDate = (date) => {
        setSendAutomaticReminderDate(validateDate(date))
        setSendAutomaticReminderDateText(date)
    }

    const getQueryParamsToPreview = (params) => {
        return Object.assign(
            params,
            {
                'createdFromObject[vaccineType]': vaccineType,
                'createdFromObject[reminderDate]': reminderDate && dayjs(reminderDate).format("YYYY-MM-DD HH:mm:ss")
            }
        )
    }

    const validate = (errors) => {
        if (loadingReminders) {
            errors.push("Procurando o lembrete")
        }

        if (sendAutomaticReminderIsActive) {
            if (
                sendAutomaticReminderByWhatsapp || sendAutomaticReminderBySMS || sendAutomaticReminderByEmail
            ) {
                setErrorSendAutomaticReminderIsActive(false)
                setHelperSendAutomaticReminderIsActive(<span>Campo Opcional</span>)
            } else {
                errors.push("Canal(is) de envio")
                setErrorSendAutomaticReminderIsActive(true)
                setHelperSendAutomaticReminderIsActive("Nenhum canal de envio foi selecionado para o envio do lembrete automático")
            }

            const libDate = dayjs(sendAutomaticReminderDate, "DD/MM/YYYY").hour(0).minute(0).second(0)
            if (libDate.isValid()) {
                if (libDate.diff(dayjs().hour(0).minute(0).second(0), "hours") < 1) {
                    errors.push("Data de envio do lembrete automático")
                    setErrorSendAutomaticReminderDate(true)
                    setHelperSendAutomaticReminderDate("A data do envio do lembrete automático precisa ser maior que a data de hoje")
                } else if (libDate.diff(defaultDate.hour(0).minute(0).second(0), "hours") > 0) {
                    errors.push("Data de envio do lembrete automático")
                    setErrorSendAutomaticReminderDate(true)
                    setHelperSendAutomaticReminderDate("A data do envio do lembrete automático não pode ser maior que a data do lembrete de vacina")
                } else {
                    setErrorSendAutomaticReminderDate(false)
                    setHelperSendAutomaticReminderDate(<span>Campo Opcional</span>)
                }
            } else {
                errors.push("Data de envio do lembrete automático")
                setErrorSendAutomaticReminderDate(true)
                setHelperSendAutomaticReminderDate("A data de envio automático do lembrete precisa ser preenchida")
            }

            if (sendAutomaticReminderByWhatsapp || sendAutomaticReminderBySMS) {
                if (validatePhone(phoneOwner)) {
                    setErrorPhoneOwner(false)
                    setHelperPhoneOwner(<span>Campo Opcional</span>)
                } else {               
                    errors.push("Número de celular")
                    setErrorPhoneOwner(true)
                    setHelperPhoneOwner("Para envio por WhatsApp/SMS o telefone do tutor precisa ser preenchido")
                }
            }
            if (sendAutomaticReminderByEmail) {
                if (validateEmail(emailOwner)) {
                    setErrorEmailOwner(false)
                    setHelperEmailOwner(<span>Campo Opcional</span>)
                } else {
                    errors.push("E-mail")
                    setErrorEmailOwner(true)
                    setHelperEmailOwner("Para envio por E-mail, o e-mail do tutor precisa ser preenchido")
                }
            }
        } else {
            setErrorSendAutomaticReminderIsActive(false)
            setHelperSendAutomaticReminderIsActive(<span>Campo Opcional</span>)
            setErrorSendAutomaticReminderDate(false)
            setHelperSendAutomaticReminderDate(<span>Campo Opcional</span>)
            setErrorPhoneOwner(false)
            setHelperPhoneOwner(<span>Campo Opcional</span>)
            setErrorEmailOwner(false)
            setHelperEmailOwner(<span>Campo Opcional</span>)
        }

        return errors
    }

    const onSaveOwner = () => {
        setSavingOwner(true)

        saveOwnerContactInfo(owner, phoneOwner, emailOwner, owner.get("cpf"))
        .then(_ => {
            setSavingOwner(false)
            setEditPhone(false)
            setEditEmail(false)
        })
        .catch(_ => {
            setSavingOwner(false)
        })
    }

    const onSave = (_reminderId) => {
        return new Promise((resolve, reject) => {
            if (owner) {
                saveOwnerContactInfo(owner, phoneOwner, emailOwner, owner.get("cpf"))
                .then(_ => {
                    const reminderId = _reminderId || vaccine.id
                    let promises = []
                    if (sendAutomaticReminderDate) {
                        promises.push(
                            preparePromiseForScheduledMessageCRUD(
                                reminderId,
                                sendAutomaticReminderDate,
                                reminders.whatsappReminder,
                                sendAutomaticReminderByWhatsapp
                            )
                        )
                        promises.push(
                            preparePromiseForScheduledMessageCRUD(
                                reminderId,
                                sendAutomaticReminderDate,
                                reminders.smsReminder,
                                sendAutomaticReminderBySMS
                            )
                        )
                        promises.push(
                            preparePromiseForScheduledMessageCRUD(
                                reminderId,
                                sendAutomaticReminderDate,
                                reminders.emailReminder,
                                sendAutomaticReminderByEmail
                            )
                        )
                    } else {
                        if (reminders) {
                            Object.keys(reminders).map(reminderType => {
                                let automaticReminder = reminders[reminderType]
                                if (automaticReminder.objectId) {
                                    // Excluir todos
                                    promises.push(
                                        new Promise((resolve, reject) => {
                                            axios.delete(`${ process.env.REACT_APP_API_V2 }/schedule/${automaticReminder.objectId}`)
                                            .then(({ data }) => {
                                                resolve(data)
                                            })
                                            .catch(error => {
                                                console.error(error)
                                                reject(error)
                                            })
                                        })
                                    )
                                } else {
                                    promises.push(Promise.resolve())
                                }
                            })
                        }
                    }
                    return resolve(Promise.all(promises))
                })
                .catch(error => {
                    console.error(error)
                    reject(error)
                })
            } else {
                reject("Não encontramos o tutor para atualizar as informações de contato")
            }
        })
    }

    useEffect(() => {
        if (reminders) {
            const whatsappReminder  = getQueryParamsToPreview(reminders.whatsappReminder)
            const smsReminder       = getQueryParamsToPreview(reminders.smsReminder)
            const emailReminder     = getQueryParamsToPreview(reminders.emailReminder)

            if (vaccineType && reminderDate) {
                preventMultipleRendersOfPreviews++
                var isLastRender = preventMultipleRendersOfPreviews
                setTimeout(() => {
                    if (isLastRender == preventMultipleRendersOfPreviews) {
                        if (showPreviewWhatsapp) {
                            setPreviewWhatsapp(null)
                            getWhatsappPreview(whatsappReminder, "WHATSAPP_VACCINE_REMINDER")
                            .then(message => setPreviewWhatsapp(message))
                        }
    
                        if (showPreviewSMS) {
                            setPreviewSMS(null)
                            getSmsPreview(smsReminder, "SMS_VACCINE_REMINDER")
                            .then(message => setPreviewSMS(message))
                        }
    
                        if (showPreviewEmail) {
                            setPreviewEmail(null)
                            getEmailPreview(emailReminder, "EMAIL_VACCINE_REMINDER")
                            .then(message => setPreviewEmail(message))
                        }
                    }
                }, 1000)
            }
        }
    }, [ reminders, vaccineType, reminderDate, showPreviewWhatsapp, showPreviewSMS, showPreviewEmail ])

    useEffect(() => {
        onChange && onChange()
    }, [ 
        sendAutomaticReminderIsActive, errorSendAutomaticReminderIsActive, 
        sendAutomaticReminderByWhatsapp, sendAutomaticReminderBySMS, sendAutomaticReminderByEmail,
        sendAutomaticReminderDateText, sendAutomaticReminderDate, errorSendAutomaticReminderDate, 
        phoneOwner, errorPhoneOwner, 
        emailOwner, errorEmailOwner
    ])

    useEffect(() => {
        if (vaccine) {
            const owner = vaccine.get("patient")?.get("owner")
            setPhoneOwner(owner?.get("phone") || "")
            setEmailOwner(owner?.get("email") || "")

            setLoadingReminders(true)

            getOrCreateMessage(vaccine, {
                "SMS": "SMS_VACCINE_REMINDER",
                "WHATSAPP": "WHATSAPP_VACCINE_REMINDER",
                "EMAIL": "EMAIL_VACCINE_REMINDER",
            })
            .then(_automaticReminders => {
                setReminders(_automaticReminders)
                const automaticRemindersEnabled = Object.keys(_automaticReminders).filter(key => _automaticReminders[key].scheduledAt)
                if (automaticRemindersEnabled.length) {
                    let key = automaticRemindersEnabled[0]
                    handleSendAutomaticReminderDate(_automaticReminders[key].scheduledAt)
                    setSendAutomaticReminderIsActive(true)
                    setSendAutomaticReminderByWhatsapp(automaticRemindersEnabled.indexOf("whatsappReminder") != -1)
                    setSendAutomaticReminderBySMS(automaticRemindersEnabled.indexOf("smsReminder") != -1)
                    setSendAutomaticReminderByEmail(automaticRemindersEnabled.indexOf("emailReminder") != -1)
                }
                setLoadingReminders(false)
            })
            .catch(error => {
                console.error(error)
                setLoadingReminders(false)
            })
        }
    }, [ vaccine ])

    return vaccineType && reminderDate && (
        <div className="row align-items-center mb-2">
            <div className="col-12">
                <div className="row mb-3">
                    <div className="col pe-1">
                        <div className="vaccine-info h-100">
                            <div className="vaccine-info-title">
                                <IconText width="20px" height="20px" />
                                Textos Enviados
                            </div>
                            O texto dos lembretes que serão enviados tanto por 
                            WhatsApp, como E-mail e SMS para o(a) tutor(a) não 
                            podem ser editados
                        </div>
                    </div>
                    <div className="col px-1">
                        <div className="vaccine-info h-100">
                            <div className="vaccine-info-title">
                                <IconSchedule width="20px" height="20px" />
                                Horário do Envio
                            </div>
                            As mensagens serão enviadas 
                            automaticamente <b>no período da manhã</b> do 
                            dia escolhido para envio do lembrete
                        </div>
                    </div>
                    <div className="col ps-1">
                        <div className="vaccine-info h-100">
                            <div className="vaccine-info-title">
                                <IconContact width="20px" height="20px" />
                                Dados para Envio
                            </div>
                            As mensagens serão enviadas para os dados 
                            de contato que estiverem no cadastro do(a) 
                            Tutor(a) no dia do envio
                        </div>
                    </div>
                </div>
                <div className="row align-items-center mb-2">
                    <div className="col-12">
                        <div className="input-title">
                            Data de envio do Lembrete Automático
                        </div>
                    </div>
                </div>
                <div className="row align-items-center mb-3">
                    <div className="col-auto pe-0" style={{ width: "150px" }}>
                        { loadingReminders ? (
                            <Skeleton variant="text" height={40} />
                        ) : (
                            <DateInput
                                placeholder="00/00/0000"
                                value={sendAutomaticReminderDateText || null}
                                error={errorSendAutomaticReminderDate}
                                onChange={(value) => handleSendAutomaticReminderDate(value)}
                                disabled={!reminderDate}
                                size="small"
                                variant="outlined"
                                fullWidth
                            />
                        )}
                    </div>
                    <div className="col-auto vaccine-text">
                        OU
                    </div>
                    <div className="col ps-0">
                        <div className="row">
                            <div className="col pe-1">
                                { loadingReminders ? (
                                    <Skeleton variant="text" height={52} />
                                ) : (
                                    <button
                                        className={`btn-outline-submit btn-select ${!reminderDate && "btn-disabled"} ${sendAutomaticReminderDateText == threeDaysBefore ? "selected" : ""}`}
                                        onClick={() => reminderDate && handleSendAutomaticReminderDate(threeDaysBefore)}
                                    >
                                        3 dias<br/> antes
                                    </button>
                                )}
                            </div>
                            <div className="col px-1">
                                { loadingReminders ? (
                                    <Skeleton variant="text" height={52} />
                                ) : (
                                    <button
                                        className={`btn-outline-submit btn-select ${!reminderDate && "btn-disabled"} ${sendAutomaticReminderDateText == fiveDaysBefore ? "selected" : ""}`}
                                        onClick={() => reminderDate && handleSendAutomaticReminderDate(fiveDaysBefore)}
                                    >
                                        5 dias<br/> antes
                                    </button>
                                )}
                            </div>
                            <div className="col px-1">
                                { loadingReminders ? (
                                    <Skeleton variant="text" height={52} />
                                ) : (
                                    <button
                                        className={`btn-outline-submit btn-select ${!reminderDate && "btn-disabled"} ${sendAutomaticReminderDateText == sevenDaysBefore ? "selected" : ""}`}
                                        onClick={() => reminderDate && handleSendAutomaticReminderDate(sevenDaysBefore)}
                                    >
                                        7 dias<br/> antes
                                    </button>
                                )}
                            </div>
                            <div className="col ps-1">
                                { loadingReminders ? (
                                    <Skeleton variant="text" height={52} />
                                ) : (
                                    <button
                                        className={`btn-outline-submit btn-select ${!reminderDate && "btn-disabled"} ${sendAutomaticReminderDateText == fiveteenDaysBefore ? "selected" : ""}`}
                                        onClick={() => reminderDate && handleSendAutomaticReminderDate(fiveteenDaysBefore)}
                                    >
                                        15 dias<br/> antes
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                    { errorSendAutomaticReminderDate && (
                        <div className="col-12">
                            <small className="text-danger">{ helperSendAutomaticReminderDate }</small>
                        </div>
                    )}
                </div>
                <div className="row align-items-center mb-2">
                    <div className="col-12">
                        <div className="input-title mb-2">
                            Dados de Contato do(a) Tutor(a)
                        </div>
                        <p className="text-secondary">
                            Lembre-se de sempre manter os dados de contato 
                            do(a) tutor(a) atualizados
                        </p>
                    </div>
                </div>
                <div className="row align-items-center mb-2">
                    <div className="col-12">
                        <div className="input-title text-normal">
                            Celular/WhatsApp:
                        </div>
                    </div>
                    { editPhone ? (
                        <>
                            <div className="col">
                                <TextFieldMask
                                    type="tel"
                                    mask={phoneOwner?.length <= 14 ? '(00) 0000-0000[0]' : '(00) 00000-0000'}
                                    value={phoneOwner}
                                    onAccept={(value) => setPhoneOwner(value)}
                                    placeholder="(00) 00000-0000"
                                    error={errorPhoneOwner}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                />
                            </div>
                            <div className="col-auto">
                                <button
                                    className="btn-submit btn-square"
                                    disabled={savingOwner}
                                    onClick={() => onSaveOwner()}
                                >
                                    { savingOwner ? (
                                        <CircularProgress size="12px" />
                                    ) : (
                                        "Salvar"
                                    )}
                                </button>
                            </div>
                            <div className="col-auto ps-0">
                                <button
                                    className="vsbox-btn vsbox-btn-primary"
                                    onClick={() => setEditPhone(false)}
                                >
                                    Cancelar
                                </button>
                            </div>
                        </>
                    ) : (
                        loadingReminders ? (
                            <div className="col-12">
                                <Skeleton variant="text" height={32} />
                            </div>
                        ) : (
                            <>
                                <div className="col-auto">
                                    { phoneOwner || "Não informado" }
                                </div>
                                <div className="col">
                                    <div
                                        className="input-title text-primary cursor-pointer"
                                        onClick={() => setEditPhone(true)}
                                    >
                                        EDITAR
                                    </div>
                                </div>
                            </>
                        )
                    )}
                    { errorPhoneOwner && (
                        <div className="col-12">
                            <small className="text-danger">{ helperPhoneOwner }</small>
                        </div>
                    )}
                </div>
                <div className="row align-items-center mb-2">
                    <div className="col-12">
                        <div className="input-title text-normal">
                            E-mail:
                        </div>
                    </div>
                    { editEmail ? (
                        <>
                            <div className="col">
                                <TextField
                                    type="email"
                                    placeholder="exemplo@email.com"
                                    value={emailOwner}
                                    onChange={({ target: { value } }) => setEmailOwner(value)}
                                    error={errorEmailOwner}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                />
                            </div>
                            <div className="col-auto">
                                <button
                                    className="btn-submit btn-square"
                                    disabled={savingOwner}
                                    onClick={() => onSaveOwner()}
                                >
                                    { savingOwner ? (
                                        <CircularProgress size="12px" />
                                    ) : (
                                        "Salvar"
                                    )}
                                </button>
                            </div>
                            <div className="col-auto ps-0">
                                <button
                                    className="vsbox-btn vsbox-btn-primary"
                                    onClick={() => setEditEmail(false)}
                                >
                                    Cancelar
                                </button>
                            </div>
                        </>
                    ) : (
                        loadingReminders ? (
                            <div className="col-12">
                                <Skeleton variant="text" height={32} />
                            </div>
                        ) : (
                            <>
                                <div className="col-auto">
                                    { emailOwner || "Não informado" }
                                </div>
                                <div className="col">
                                    <div
                                        className="input-title text-primary cursor-pointer"
                                        onClick={() => setEditEmail(true)}
                                    >
                                        EDITAR
                                    </div>
                                </div>
                            </>
                        )
                    )}
                    { errorEmailOwner && (
                        <div className="col-12">
                            <small className="text-danger">{ helperEmailOwner }</small>
                        </div>
                    )}
                </div>
                <div className="row align-items-center mb-2">
                    <div className="col-12">
                        <div className="input-title">
                            Canais de Envio
                        </div>
                    </div>
                    { errorSendAutomaticReminderIsActive && (
                        <div className="col-12">
                            <small className="text-danger">{ helperSendAutomaticReminderIsActive }</small>
                        </div>
                    )}
                </div>
                <div className="row">
                    <div className="col">
                        { loadingReminders ? (
                            <Skeleton variant="text" height={44} />
                        ) : (
                            <div id="document-sent-preview">
                                <VSAccordion
                                    expanded={showPreviewWhatsapp}
                                    isControlled
                                    header={(expanded) => (
                                        <div className="col px-3">
                                            <div className="row align-items-center py-2">
                                                <div className="col px-0 sent-title">
                                                    <VSCheckbox
                                                        label="Enviar lembrete por WhatsApp"
                                                        checked={sendAutomaticReminderByWhatsapp}
                                                        onChange={(checked) => setSendAutomaticReminderByWhatsapp(checked)}
                                                    />
                                                </div>
                                                <div className="col-auto" onClick={() => setShowPreviewWhatsapp(!showPreviewWhatsapp)}>
                                                    <div className="row">
                                                        <div className="col text-right px-0 sent-title">
                                                            { expanded ? "Fechar" : "Ver" } mensagem que será enviada
                                                        </div>
                                                        <div className="col-auto pe-0" >
                                                            <IconArrowLeft className={`sent-icon ${ expanded ? "sent-opened" : "" }`} width="10" height="16" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                >
                                    <div className="box-info mt-2">
                                        <div className="box-vetsmart">
                                            <VetsmartLogo width="35px" height="35px" style={{marginRight: '10px'}} />
                                            Vetsmart <img src={Certified} width="13" height="13" />
                                        </div>
                                        { previewWhatsapp ? (
                                            <div dangerouslySetInnerHTML={{
                                                __html: previewWhatsapp
                                            }} />
                                        ) : (
                                            <div className="data-loading">
                                                <CircularProgress color="primary" size={20} />
                                            </div>
                                        )}
                                    </div>
                                </VSAccordion>
                            </div>
                        )}
                    </div>
                </div>
                <hr className="my-2" />
                <div className="row">
                    <div className="col">
                        { loadingReminders ? (
                            <Skeleton variant="text" height={44} />
                        ) : (
                            <div id="document-sent-preview">
                                <VSAccordion
                                    expanded={showPreviewSMS}
                                    isControlled
                                    header={(expanded) => (
                                        <div className="col px-3">
                                            <div className="row align-items-center py-2">
                                                <div className="col px-0 sent-title">
                                                    <VSCheckbox
                                                        label="Enviar lembrete por SMS"
                                                        checked={sendAutomaticReminderBySMS}
                                                        onChange={(checked) => setSendAutomaticReminderBySMS(checked)}
                                                    />
                                                </div>
                                                <div className="col-auto" onClick={() => setShowPreviewSMS(!showPreviewSMS)}>
                                                    <div className="row">
                                                        <div className="col text-right px-0 sent-title">
                                                            { expanded ? "Fechar" : "Ver" } mensagem que será enviada
                                                        </div>
                                                        <div className="col-auto pe-0">
                                                            <IconArrowLeft className={`sent-icon ${ expanded ? "sent-opened" : "" }`} width="10" height="16" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                >
                                    <div className="box-info mt-2">
                                        { previewSMS ? (
                                            <div dangerouslySetInnerHTML={{
                                                __html: previewSMS
                                            }} />
                                        ) : (
                                            <div className="data-loading">
                                                <CircularProgress color="primary" size={20} />
                                            </div>
                                        )}
                                    </div>
                                </VSAccordion>
                            </div>
                        )}
                    </div>
                </div>
                <hr className="my-2" />
                <div className="row">
                    <div className="col">
                        { loadingReminders ? (
                            <Skeleton variant="text" height={44} />
                        ) : (
                            <div id="document-sent-preview">
                                <VSAccordion
                                    expanded={showPreviewEmail}
                                    isControlled
                                    header={(expanded) => (
                                        <div className="col px-3">
                                            <div className="row align-items-center py-2">
                                                <div className="col px-0 sent-title">
                                                    <VSCheckbox
                                                        label="Enviar lembrete por E-mail"
                                                        checked={sendAutomaticReminderByEmail}
                                                        onChange={(checked) => setSendAutomaticReminderByEmail(checked)}
                                                    />
                                                </div>
                                                <div className="col-auto" onClick={() => setShowPreviewEmail(!showPreviewEmail)}>
                                                    <div className="row">
                                                        <div className="col text-right px-0 sent-title">
                                                            { expanded ? "Fechar" : "Ver" } mensagem que será enviada
                                                        </div>
                                                        <div className="col-auto pe-0">
                                                            <IconArrowLeft className={`sent-icon ${ expanded ? "sent-opened" : "" }`} width="10" height="16" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                >
                                    <div className="box-info mt-2">
                                        <b>Enviado por:</b> vetsmart@vetsmart.com.br
                                        <br/><br/>
                                        { previewEmail ? (
                                            <div dangerouslySetInnerHTML={{
                                                __html: previewEmail
                                            }} />
                                        ) : (
                                            <div className="data-loading">
                                                <CircularProgress color="primary" size={20} />
                                            </div>
                                        )}
                                    </div>
                                </VSAccordion>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
})

export default AutomaticReminderFormOld;