import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom'

import {
    APP_NAME,
    APP_DEVICE,
    APP_VERSION,
    ACL
} from '../../../constants'
import { saveOnTracker, addEvent, getSelectedOptionLabel } from '../../../utils';
import { getProtocolVaccine, vaccineProtocolTotalShotsOptions } from './VaccineProtocol';
import { getSpecies } from '../../patient/Patient';
import { vaccineTypeOptions } from '../../patient/tabs/vaccine/Vaccine';

import { CircularProgress, Switch, TextField } from '@mui/material';
import Autocomplete from '@mui/lab/Autocomplete';

import { ReactComponent as IconWarning } from "../../../assets/icon-warning-o.svg"

import VSAccordionSimple from '../../../components/vsAccordion/VSAccordionSimple';
import VSDrawer from '../../../components/vsDrawer/VSDrawer';
import VSError from '../../../components/vsError/VSError';
import IntervalInput from '../../../components/intervalInput/IntervalInput';

import "./VaccineProtocolForm.scss"
import VSRadio from '../../../components/vsRadio/VSRadio';
const VaccineProtocolForm = props => {
    const {
        id_protocolo
    } = useParams()

    const {
        clinic,
        history,
        permissions
    } = props

    const update = id_protocolo != "novo"

    const [ loading, setLoading ] = useState(false)
    const [ error, setError ] = useState(null)
    const [ saveLoading, setSaveLoading ] = useState(false)
    const [ protocol, setProtocol ] = useState(null)

    const [ name, setName ] = useState('')
    const [ errorName, setErrorName ] = useState(false)
    const [ helperName, setHelperName ] = useState(<span>Campo <b>Obrigatório</b></span>)

    const [ specie, setSpecie ] = useState('')
    const [ species, setSpecies ] = useState([])
    const [ errorSpecie, setErrorSpecie ] = useState(false)
    const [ helperSpecie, setHelperSpecie ] = useState(<span>Campo <b>Obrigatório</b></span>)

    const [ vaccineType, setVaccineType ] = useState("")
    const [ errorVaccineType, setErrorVaccineType ] = useState(false)
    const [ helperVaccineType, setHelperVaccineType ] = useState(<span>Campo <b>Obrigatório</b></span>)

    const [ totalShots, setTotalShots ] = useState(null)
    const [ errorTotalShots, setErrorTotalShots ] = useState(false)
    const [ helperTotalShots, setHelperTotalShots ] = useState(<span>Campo <b>Obrigatório</b></span>)

    const [ shotInterval, setShotInterval ] = useState(null)
    const [ shotIntervalUnit, setShotIntervalUnit ] = useState("")
    const [ errorShotInterval, setErrorShotInterval ] = useState(false)
    const [ helperShotInterval, setHelperShotInterval ] = useState(<span>Campo <b>Obrigatório</b></span>)

    const [ hasNextShot, setHasNextShot ] = useState("")

    const [ nextShotInterval, setNextShotInterval ] = useState(null)
    const [ nextShotIntervalUnit, setNextShotIntervalUnit ] = useState("")
    const [ errorNextShotInterval, setErrorNextShotInterval ] = useState(false)
    const [ helperNextShotInterval, setHelperNextShotInterval ] = useState(<span>Campo <b>Obrigatório</b></span>)
    

    const [ notes, setNotes ] = useState('')
    const [ active, setActive ] = useState(true)

    const handleSpecie = (selected) => {
        const specie = selected
        setSpecie(specie)
    }

    const validateOnTouch = () => {
        if (name || specie || vaccineType || totalShots || shotInterval || shotIntervalUnit || nextShotInterval || nextShotIntervalUnit || hasNextShot || notes) {
            validate()
        }
    }

    const validate = () => {
        let isValid = true
        if (loading) {
            isValid = false
        }
        if (saveLoading) {
            isValid = false
        }
        if (error?.message) {
            isValid = false
        }

        if (!name) {
            isValid = false
            setErrorName(true)
            setHelperName("O nome do protocolo é obrigatório")
        } else {
            setErrorName(false)
            setHelperName(<span>Campo <b>Obrigatório</b></span>)
        }

        if (!specie) {
            isValid = false
            setErrorSpecie(true)
            setHelperSpecie("A espécie do protocolo é obrigatória")
        } else {
            setErrorSpecie(false)
            setHelperSpecie(<span>Campo <b>Obrigatório</b></span>)
        }

        if (!vaccineType) {
            isValid = false
            setErrorVaccineType(true)
            setHelperVaccineType("O tipo da vacina não é válido")
        } else {
            setErrorVaccineType(false)
            setHelperVaccineType(<span>Campo <b>Obrigatório</b></span>)
        }

        if (!totalShots || totalShots > 5) {
            isValid = false
            setErrorTotalShots(true)
            setHelperTotalShots("O tipo da vacina não é válido")
        } else {
            setErrorTotalShots(false)
            setHelperTotalShots(<span>Campo <b>Obrigatório</b></span>)
        }

        if (totalShots && totalShots > 1 && (!shotInterval || !shotIntervalUnit)) {
            isValid = false
            setErrorShotInterval(true)
            setHelperShotInterval("O intervalo de aplicação não é válido")
        } else {
            setErrorShotInterval(false)
            setHelperShotInterval(<span>Campo <b>Obrigatório</b></span>)
        }

        if(!hasNextShot){
            isValid = false
        }

        if (hasNextShot === "1" && (!nextShotInterval || !nextShotIntervalUnit)) {
            isValid = false
            setErrorNextShotInterval(true)
            setHelperNextShotInterval("O intervalo de reforço não é válido")
        } else {
            setErrorNextShotInterval(false)
            setHelperNextShotInterval(<span>Campo <b>Obrigatório</b></span>)
        }

        return isValid
    }

    const isValid = () => !loading && !saveLoading && !errorName && !errorSpecie && !errorVaccineType && !errorTotalShots && !errorShotInterval && !errorNextShotInterval && !hasNextShot

    const fieldsWithErrorText = () => {
        let errors = []
        if (loading) {
            errors.push("Estamos procurando o protocolo")
        }
        if (saveLoading) {
            errors.push("Salvando o protocolo")
        }
        if (error?.message) {
            errors.push(error.message)
        }
        if (errorName) {
            errors.push("Nome do protocolo")
        }
        if (errorSpecie) {
            errors.push("Espécie")
        }
        if (errorVaccineType) {
            errors.push("Tipo de Vacina")
        }
        if (errorTotalShots) {
            errors.push("Quantidade de doses")
        }
        if (errorShotInterval) {
            errors.push("Intervalo de Aplicação")
        }
        if (errorNextShotInterval) {
            errors.push("Intervalo de Reforço")
        }
        if (!hasNextShot){
            errors.push("Reforço")
        }
        if (!permissions?.check(ACL.VACCINE, ACL.Rule.EDIT, protocol)) {
            errors.push("Acesso negado")
        }
        return errors
    }

    const save = () => {
        return new Promise((resolve, reject) => {
            if (validate()) {
                setSaveLoading(true)
                return Promise.resolve().then(() => {
                    protocol.set("name", name);
                    // protocol.set("protocolId", protocolId);
                    // protocol.set("value", parseFloat(value.toFixed(2)));
                    // if (categoryToSave) {
                    //     protocol.set("category", categoryToSave);
                    // } else {
                    //     protocol.unset("category");
                    // }
                    // if (durationInMinutes > 0) {
                    //     protocol.set("durationInMinutes", durationInMinutes)
                    // } else {
                    //     protocol.unset("durationInMinutes")
                    // }
                    protocol.setACL(clinic.ACL);
                    protocol.set("specie", specie);
                    protocol.set("vaccineType", vaccineType);
                    protocol.set("totalShots", totalShots);
                    protocol.set("shotInterval", shotInterval);
                    protocol.set("shotIntervalUnit", shotIntervalUnit);
                    if(hasNextShot === "1"){
                        protocol.set("nextShotInterval", nextShotInterval);
                        protocol.set("nextShotIntervalUnit", nextShotIntervalUnit);
                    } else {
                        protocol.unset("nextShotInterval");
                        protocol.unset("nextShotIntervalUnit");
                    }
                    protocol.set("notes", notes);
                    protocol.set("active", active);
                    protocol.set("isDeleted", false);
                    protocol.set("changedAt", new Date())
                    protocol.set("changedBy", Parse.User.current())
                    protocol.set("changedApp", APP_NAME)
                    protocol.set("changedDevice", APP_DEVICE)
                    protocol.set("changedAppVersion", APP_VERSION)
                    protocol.save()
                    .then(_protocol => {
                        saveOnTracker("Concluiu", "Protocolo de Vacina", _protocol.id)
                        setSaveLoading(false)
                        resolve(_protocol)
                    })
                    .catch(error => {
                        setSaveLoading(false)
                        console.error(error)
                        Swal.fire(
                            'Desculpe',
                            `Ocorreu algum erro ao tentar ${protocol.id ? "editar" : "cadastrar"} o protocolo de vacina`,
                            'error'
                        )
                        update && protocol.revert()
                        reject()
                    })
                });
            } else {
                reject()
            }
        })
    }

    const getProtocolOrError = () => {
        if (permissions?.check(ACL.VACCINE, ACL.Rule.VIEW)) {
            setLoading(true)
            setError(null)

            if (update) {
                getProtocolVaccine(id_protocolo, clinic.object)
                .then(_protocol => {
                    // const _value = _protocol.get("value") ? _protocol.get("value") : null
                    setProtocol(_protocol)
                    setName(_protocol.get("name"))
                    handleSpecie(_protocol.get("specie"))
                    setVaccineType(_protocol.get("vaccineType"))
                    setTotalShots(_protocol.get("totalShots"))
                    setShotInterval(_protocol.get("shotInterval"))
                    setShotIntervalUnit(_protocol.get("shotIntervalUnit"))
                    setNextShotInterval(_protocol.get("nextShotInterval"))
                    setNextShotIntervalUnit(_protocol.get("nextShotIntervalUnit"))
                    if(_protocol.get("nextShotInterval")){
                        setHasNextShot("1")
                    } else {
                        setHasNextShot("0")
                    }
                    // setProtocolId(_protocol.get("protocolId"));
                    // setValue(_value);
                    // setDefaultValue(_value);
                    // if(_protocol.get("category")){
                    //     setCategory(_protocol.get("category").get("categoryName"));
                    // }
                    // if (_protocol.get("durationInMinutes")) {
                    //     setDurationInMinutes(_protocol.get("durationInMinutes"))
                    // }
                    setNotes(_protocol.get("notes"))
                    setActive(_protocol.get("active"))
                    setLoading(false)
                })
                .catch(error => {
                    Swal.fire(
                        'Desculpe',
                        error,
                        'error'
                    )
                    .then(_ => {
                        setLoading(false)
                        setError({
                            message: error,
                            function: "getProtocolOrError"
                        })
                    })
                })
            } else {
                let newProtocol = new Parse.Object("MRProtocolVaccine")
                // newProtocol.setACL(clinic.ACL)
                newProtocol.set("clinic", clinic.object)
                newProtocol.set("createdBy", Parse.User.current())
                newProtocol.set("createdApp", APP_NAME)
                newProtocol.set("createdDevice", APP_DEVICE)
                newProtocol.set("createdAppVersion", APP_VERSION)
                newProtocol.set("active", true)
                // newProtocol.set("isDeleted", false) // default is false on parse
                setProtocol(newProtocol)
                setLoading(false)
            }
        }
    }

    useEffect(() => {
        validateOnTouch()
    }, [ name, specie, vaccineType, totalShots, shotInterval, shotIntervalUnit, hasNextShot, nextShotInterval, nextShotIntervalUnit ])

    useEffect(() => {
        if (clinic && clinic.isLoaded && clinic.object) {
            saveOnTracker("Iniciou", "Protocolo de Vacina", id_protocolo !== "novo" ? id_protocolo : "")
            // getCategories(clinic.object).then(_categories => setCategoryOptions(_categories));
            if (id_protocolo != "selecionar") {
                getProtocolOrError();
            }

            getSpecies().then(_species => setSpecies(_species))
        }
    }, [ clinic ])

    useEffect(() => {
        if (protocol && !permissions?.check(ACL.VACCINE, ACL.Rule.EDIT, protocol)) {
            permissions?.error()
        }
    }, [ protocol ])

    const errors = fieldsWithErrorText()

    return (
        <VSDrawer
            title={update ? "Editar Cadastro de Protocolo" : "Novo Protocolo de Vacina"}
            cancel={update ? "Cancelar Alterações" : <span>Cancelar<span className="d-none d-md-inline"> Novo</span> Cadastro</span>}
            submit={isValid ? ( update ? "Salvar Alterações" : "Salvar Cadastro" ) : <span>Preencha os campos<span className="d-none d-md-inline"> necessários</span></span>}
            errors={errors}
            onSubmit={save}
            onCancel={() => history.goBack()}
            onAfterSave={(object) => {
                history.replace(`/protocolo/vacina`)
                addEvent("ProtocolVaccine__getList", {})
            }}
            VSDrawerSuccessProps={{
                title: update ? "Alterações salvas com sucesso!" : "Cadastro realizado com sucesso!" ,
                text: "",
                VSDrawerFooterProps: {
                    children: (
                        <div className="row">
                            <div className="col pe-2">
                                <button
                                    className="btn btn-success w-100"
                                    onClick={() => history.replace(`/protocolo/vacina`)}
                                >
                                    Ir para Protocolos de Vacina
                                </button>
                            </div>
                            <div className="col ps-2">
                                <button
                                    className="btn btn-save w-100"
                                    onClick={() => history.replace(`/protocolo/vacina/novo`)}
                                >
                                    Cadastrar outro Protocolo de Vacina
                                </button>
                            </div>
                        </div>
                    )
                }
            }} 
            VSDrawerCancelProps={{
                title: update ? "Tem certeza que deseja cancelar as alterações no cadastro do protocolo? " : "Tem certeza que deseja cancelar o novo cadastro de protocolo?",
                confirm: update ? "Cancelar  Alterações" : "Cancelar Novo Cadastro",
                cancel: update ? "Voltar para Edição do Cadastro" : "Voltar para Edição do Novo Cadastro"
            }}
        >
            { loading ? (
                <div className="row data-loading">
                    <div className="col">
                        <CircularProgress />
                    </div>
                </div>
            ) : error?.message ? (
                <VSError
                    message={error.message}
                    onClose={() => {
                        switch(error.function) {
                            case "getProtocolOrError":
                                getProtocolOrError()
                                break
                            default:
                        }
                    }}
                />
            ) : (
                <>
                    { protocol && (
                        <>
                            <div className="row align-items-center">
                                <div className="col">
                                    <p className="my-3" style={{ 
                                        textTransform: "uppercase",
                                        fontWeight: 600
                                    }}>
                                        PROTOCOLO ESTA ATIVO?
                                    </p>
                                </div>
                                <div className="col-auto">
                                    <Switch
                                        checked={active}
                                        onChange={({ target: { checked } }) => setActive(checked)}
                                        color="primary"
                                    />
                                </div>
                            </div>
                            { !active && active != protocol?.get("active") && (
                                <div className="mb-3 vsalert">
                                    <div className="row align-items-center">
                                        <div className="col-auto">
                                            <IconWarning width="24px" height="24px" fill="var(--vsprontuario-secondary-color)" />
                                        </div>
                                        <div className="col ps-0">
                                            <h4 className="vsalert-title">
                                                Você definiu que o protocolo não esta ativo.
                                            </h4>
                                            <p className="vsalert-text">
                                                Ao salvar essa alteração, este protocolo ficará oculto para seleção em outras funcionalidades até você reativa-lo.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <hr/>
                            <div className="row mt-2">
                                <div className="col">
                                    <VSAccordionSimple
                                        expanded={true}
                                        header={(_) => (
                                            "Identificação do protocolo"
                                        )}
                                    >
                                        <div className="row g-3 align-items-center">
                                            <div className="col-12 col-md-7 mb-2">
                                                <TextField
                                                    placeholder="Nome do Protocolo"
                                                    label="Nome do Protocolo"
                                                    value={name}
                                                    onChange={({ target: { value } }) => setName(value)}
                                                    error={errorName}
                                                    helperText={helperName}
                                                    size="small"
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                            </div>
                                            <div className="col-12 col-md-5 mb-2">
                                                <Autocomplete
                                                    inputValue={specie}
                                                    options={species}
                                                    onChange={(_, selected) => handleSpecie(selected || "")}
                                                    onInputChange={(event, selected) => {
                                                        if (event){
                                                            handleSpecie(selected)
                                                        }
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Espécie do animal"
                                                            placeholder="Espécie"
                                                            error={errorSpecie}
                                                            helperText={helperSpecie}
                                                            size="small"
                                                            variant="outlined"
                                                            fullWidth
                                                        />
                                                    )}
                                                    openOnFocus
                                                    freeSolo
                                                />
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <Autocomplete
                                                    onChange={(_, newValue) => setVaccineType(newValue || "")}
                                                    onInputChange={(event, newInputValue) => event && setVaccineType(newInputValue || "")}
                                                    inputValue={vaccineType}
                                                    options={vaccineTypeOptions}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Tipo de Vacina"
                                                            placeholder="Tipo de Vacina"
                                                            error={errorVaccineType}
                                                            helperText={helperVaccineType}
                                                            size="small"
                                                            variant="outlined"
                                                            fullWidth
                                                        />
                                                    )}
                                                    openOnFocus
                                                    freeSolo
                                                />
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <Autocomplete
                                                    value={totalShots}
                                                    options={vaccineProtocolTotalShotsOptions}
                                                    getOptionLabel={(option) => getSelectedOptionLabel(option, vaccineProtocolTotalShotsOptions, totalShots)}
                                                    getOptionSelected={(option) => option.value === totalShots}
                                                    onChange={(_, selected) => setTotalShots(Number(selected?.value) || null)}
                                                    onInputChange={(event, newInputValue) => event && setTotalShots(Number(newInputValue) || null)}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            placeholder="Doses"
                                                            label="Quantidade de doses"
                                                            error={errorTotalShots}
                                                            helperText={helperTotalShots}
                                                            size="small"
                                                            variant="outlined"
                                                            fullWidth
                                                        />
                                                    )}
                                                    openOnFocus
                                                    freeSolo
                                                />
                                            </div>
                                            {totalShots && totalShots > 1 &&
                                            <IntervalInput
                                                label="Intervalo de aplicação"
                                                interval={shotInterval}
                                                setInterval={setShotInterval}
                                                intervalUnit={shotIntervalUnit}
                                                setIntervalUnit={setShotIntervalUnit}
                                                errorInterval={errorShotInterval}
                                                helperInterval={helperShotInterval}
                                            />}
                                            <div className="col-12">
                                                <label className="input-label">ESTE PROTOCOLO POSSUI REFORÇO? (OBRIGATÓRIO)</label>
                                                <VSRadio
                                                    horizontal={true}
                                                    options={[
                                                        {
                                                            label: "Sim",
                                                            value: "1"
                                                        },
                                                        {
                                                            label: "Não",
                                                            value: "0"
                                                        }
                                                    ]}
                                                    value={hasNextShot}
                                                    onChange={(_, value) => setHasNextShot(value)}
                                                />
                                            </div>
                                            {hasNextShot === "1" && 
                                            <>
                                                <div className="col-12 pre-input-info mt-0">Informe depois de quanto tempo este reforço deve acontecer.</div>
                                                <IntervalInput
                                                    label="Intervalo de reforço"
                                                    className="mt-2"
                                                    interval={nextShotInterval}
                                                    setInterval={setNextShotInterval}
                                                    intervalUnit={nextShotIntervalUnit}
                                                    setIntervalUnit={setNextShotIntervalUnit}
                                                    errorInterval={errorNextShotInterval}
                                                    helperInterval={helperNextShotInterval}
                                                />
                                            </>
                                            }
                                        </div>
                                    </VSAccordionSimple>
                                </div>
                            </div>
                            <hr className="my-2" />
                            <div className="row">
                                <div className="col">
                                    <VSAccordionSimple
                                        expanded={true}
                                        header={(_) => (
                                            "Observações do protocolo"
                                        )}
                                    >
                                        <div className="row">
                                            <div className="col-12">
                                                <TextField
                                                    placeholder="Digite as observações sobre o protocolo"
                                                    label="Observações do protocolo"
                                                    value={notes}
                                                    onChange={({ target: { value } }) => setNotes(value)}
                                                    size="small"
                                                    variant="outlined"
                                                    multiline
                                                    fullWidth
                                                />
                                            </div>
                                            <div className="col input-info">
                                                Campo Opcional. As observações são anotações particulares. <b>Elas nunca serão compartilhadas com o tutor.</b>
                                            </div>
                                        </div>
                                    </VSAccordionSimple>
                                </div>
                            </div>
                        </>
                    )}
                </>
            )}
        </VSDrawer>
    )
}

const mapStateToProps = state => {
    return {
        clinic: state.clinic,
        permissions: state.permissions
    };
};

export default connect(mapStateToProps)(VaccineProtocolForm);