import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux';

import { CircularProgress } from "@mui/material";

import './VirtualAssistantDictateAnamnesis.scss';
const VirtualAssistantDictateAnamnesis = props => {
    const {
        user,
        clinic,
        permissions
    } = props

    const [ loading, setLoading ] = useState(false)

    return (
        <div id="virtual-assistant-dictate-anamnesis">
            virtual-assistant-dictate-anamnesis
        </div>
    )
}

const mapStateToProps = state => {
    return {
        user: state.user,
        clinic: state.clinic,
        permissions: state.permissions
    }
}

export default connect(mapStateToProps)(VirtualAssistantDictateAnamnesis)