import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux';

import './ClinicSettingDocumentInfo.scss'
import VSAccordionSetting from "../../VSAccordionSetting";
import AppointmentInfo from '../../../clinic/AppointmentInfo';

import { CircularProgress, Checkbox, FormControlLabel } from "@mui/material";
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';

import { optionalField, saveOnTracker, setClinic, useDidMountEffect, addEvent, validatePhone } from "../../../../utils";
import { ACL, APP_DEVICE, APP_NAME, APP_VERSION } from "../../../../constants";
import { getSelectedClinicUser } from "../../../../store/actions";
import { getClinicUser } from "../../../vet/Vet";
import TextFieldMask from "../../../../components/textFieldMask/TextFieldMask";
import VSAccordionSimple from "../../../../components/vsAccordion/VSAccordionSimple";
import ColorPicker from "../../../../components/colorPicker/ColorPicker";
import ImageUpload from "../../../../components/imageUpload/ImageUpload";
import { colors } from '../../../../utils';

import SettingPreview from '../../SettingPreview'

import { ReactComponent as IconAdd } from "../../../../assets/icon-add.svg"
import { ReactComponent as IconArrowLeft } from "../../../../assets/icon-arrow-left.svg"
import { ReactComponent as PrescriptionTheme1 } from "../../../../assets/prescription-theme1.svg"
import { ReactComponent as PrescriptionTheme2 } from "../../../../assets/prescription-theme2.svg"
import { ReactComponent as PrescriptionTheme3 } from "../../../../assets/prescription-theme3.svg"

const VSInnerAccordion = ({ expanded, title, children }) => (
    <VSAccordionSimple
        hideIcon
        expanded={expanded}
        header={_expanded => (
            <div className="row">
                <div className="col-auto flex-shrink-0">
                    <IconArrowLeft
                        width="8px"
                        height="12px"
                        fill="var(--vsprontuario-secondary-color)"
                        style={{
                            width: 12,
                            position: 'relative',
                            bottom: '1px',
                            transition: 'all .3s',
                            transform: `rotate(${ _expanded ?  "90deg" : "270deg" })`
                        }}
                    />
                </div>
                <div className="col ps-1">
                    {title}
                </div>
            </div>
        )}
    >
        {children}
    </VSAccordionSimple>
)

const ClinicSettingDocumentInfo = props => {
    const {
        user,
        clinic,
        permissions,
        history
    } = props

    const contactNumber = useRef(null)

    const defaultFinancialRecords = {
        estimate: true,
        sale: true
    }

    const defaultMedicalRecords = {
        prescription: true,
        examRequest: true,
        term: true,
        vaccine: true
    }

    const defaultRecords = {
        ...defaultMedicalRecords,
        ...defaultFinancialRecords
    }

    const [ loading, setLoading ] = useState(false)
    const [ isFormChanged, setIsFormChanged ] = useState(false)

    // Estilo e Visual
    const [ color, setColor ] = useState(colors[0])
    const [ printTemplate, setPrintTemplate ] = useState("10")

    const [ logo, setLogo ] = useState(null)
    const [ displayLogoIn, setDisplayLogoIn ] = useState(defaultRecords)

    // Documentos Médicos
    const [ displayAppointment, setDisplayAppointment ] = useState(true)

    const [ displayPhone, setDisplayPhone ] = useState(false)

    const [ phoneWhatsApp, setPhoneWhatsApp ] = useState("")
    const [ errorPhoneWhatsApp, setErrorPhoneWhatsApp ] = useState(false)
    const [ helperPhoneWhatsApp, setHelperPhoneWhatsApp ] = useState(optionalField)

    const [ notes, setNotes ] = useState("")
    const [ displayNotesIn, setDisplayNotesIn ] = useState(defaultMedicalRecords)

    const [ financialNotes, setFinancialNotes ] = useState("")
    const [ displayFinancialNotesIn, setDisplayFinancialNotesIn ] = useState(defaultFinancialRecords)

    const validate = () => {
        let isValid = true
        if (loading) {
            isValid = false
        }

        if (validatePhone(phoneWhatsApp)) {
            setErrorPhoneWhatsApp(false)
            setHelperPhoneWhatsApp(optionalField)
        } else {
            if (displayPhone) {
                isValid = false
                setErrorPhoneWhatsApp(true)
                setHelperPhoneWhatsApp("O número de telefone não é válido")
            } else {
                setErrorPhoneWhatsApp(false)
                setHelperPhoneWhatsApp(optionalField)
            }
        }

        return isValid
    }

    const isValid = () => true

    const save = () => {
        if(validate()){
            setLoading(true)

            const saveUser = Parse.User.current()

            let saveClinic = clinic.object
            saveClinic.set("changedApp", APP_NAME)
            saveClinic.set("changedAppVersion", APP_VERSION)
            saveClinic.set("changedAt", new Date())
            saveClinic.set("changedBy", saveUser)
            saveClinic.set("changedDevice", APP_DEVICE)
            saveClinic.set("color", color);
            saveClinic.set("displayAppointment", displayAppointment);
            saveClinic.set("displayLogoIn", displayLogoIn);
            saveClinic.set("displayNotesIn", displayNotesIn);
            saveClinic.set("displayFinancialNotesIn", displayFinancialNotesIn);
            saveClinic.set("logo", logo);
            saveClinic.set("notes", notes);
            saveClinic.set("financialNotes", financialNotes);
            saveClinic.set("printTemplate", printTemplate);
            saveClinic.set("phoneWhatsApp", displayPhone ? phoneWhatsApp : "");
            saveClinic.save()
            .then(_clinic => {
                setIsFormChanged(false)
                afterSave(_clinic)
            })
            .catch(error => {
                console.error(error)
                Swal.fire(
                    'Oops...',
                    `Não foi possível editar a clínica, por favor, tente novamente`,
                    'error'
                )
                setLoading(false)
                saveClinic.revert()
            })
        }
    }

    const afterSave = (_clinic) => {
        getClinicUser(_clinic)
        .then(clinicUser => {
            saveOnTracker("Concluiu", "Clínica", _clinic.id)
            addEvent("TourSettings__onChangeSettings", {})
            setClinic(_clinic)
            props.dispatch(getSelectedClinicUser(clinicUser))
            setLoading(false)
        })
        .catch(error => {
            console.error(error)
            Swal.fire(
                'Oops...',
                error,
                'error'
            )
            setLoading(false)
        })
    }

    const useAfterClinicSetEffect = (func, deps) => {
        const ready = useRef(false)

        useDidMountEffect(() => {
            if (ready.current) func();
            else ready.current = true;
        }, deps)
    }
    useAfterClinicSetEffect(() => {
        !isFormChanged &&
        setIsFormChanged(true)
    }, [ 
        color, printTemplate, logo, displayLogoIn,
        displayAppointment, notes, displayNotesIn,
        financialNotes, displayFinancialNotesIn,
        displayPhone, phoneWhatsApp
    ])

    useEffect(() => {
        validate()
    }, [
        displayPhone, phoneWhatsApp
    ])

    useEffect(() => {
        if (clinic.object) {
            if (!permissions?.check(ACL.SETTING, ACL.Rule.EDIT, clinic.object)) {
                permissions?.error()
                return
            }

            const selectedClinic = clinic.object
            setColor(selectedClinic.get("color") || colors[0])
            setPrintTemplate(selectedClinic.get("printTemplate") || "10")
            setLogo(selectedClinic.get("logo"))
            setDisplayLogoIn(Object.assign(defaultRecords, selectedClinic.get("displayLogoIn") || {}))
            setDisplayAppointment(selectedClinic.get("displayAppointment"))
            setNotes(selectedClinic.get("notes") || "")
            setDisplayNotesIn(Object.assign(defaultMedicalRecords, selectedClinic.get("displayNotesIn") || {}))
            setFinancialNotes(selectedClinic.get("financialNotes") || "")
            setDisplayFinancialNotesIn(Object.assign(defaultFinancialRecords, selectedClinic.get("displayFinancialNotesIn") || {}))
            if (validatePhone(selectedClinic.get("phoneWhatsApp"))) {
                setPhoneWhatsApp(selectedClinic.get("phoneWhatsApp"))
                setDisplayPhone(true)
            }
        }
    }, [ clinic ])

    useEffect(() => {
        if(history?.location?.hash && contactNumber?.current?.getBoundingClientRect){
            window.scrollTo(0, contactNumber.current.getBoundingClientRect().top)
        }
    }, [ history?.location?.hash, contactNumber ])

    return (
        <div id="clinic-setting-document-info">
            <div className="row setting-content">
                <div className="col-12 col-md-5 col-xl-4" style={{ maxWidth: "450px" }}>
                    <VSAccordionSetting
                        title="Estilo e Visual">

                        { loading ? (
                            <div className="row loading justify-content-center mt-3">
                                <div className="col-auto">
                                    <CircularProgress />
                                </div>
                            </div>
                        ) : (
                            <div className="row">
                                <div className="col">
                                    <VSInnerAccordion
                                        expanded={true}
                                        title="Cor Padrão"
                                    >
                                        <div className="row">
                                            <div className="col-12 mb-3">
                                                <div className="vs-accordion-text-secondary">
                                                    A escolha de uma cor padrão é obrigatória e será utilizada nos documentos gerados, como prescrições, solicitações de exames, termos e atestados.
                                                </div>
                                            </div>
                                            <ColorPicker
                                                colors={colors}
                                                color={color}
                                                onChange={(_color) => setColor(_color)}
                                                className="mb-3"
                                            />
                                        </div>
                                    </VSInnerAccordion>
                                    <hr className="my-0" />
                                    <VSInnerAccordion
                                        expanded={true}
                                        title="Tema de Documentos Impressos"
                                    >
                                        <div className="row">
                                            <div className="col-12 mb-3">
                                                <div className="vs-accordion-text-secondary">
                                                    O Tema escolhido será <b>aplicado para todos os documentos gerados no prontuário</b> como Prescrições, Solicitações de Exames, Atestados e Termos
                                                </div>
                                                <div className="row mt-3">
                                                    <div className="col">
                                                        <PrescriptionTheme1
                                                            className={`w-100 h-auto prescription-theme ${printTemplate == "10" ? "selected" : ""}`}
                                                            onClick={() => setPrintTemplate("10")}
                                                        />
                                                    </div>
                                                    <div className="col">
                                                        <PrescriptionTheme2
                                                            className={`w-100 h-auto prescription-theme ${printTemplate == "11" ? "selected" : ""}`}
                                                            onClick={() => setPrintTemplate("11")}
                                                        />
                                                    </div>
                                                    <div className="col">
                                                        <PrescriptionTheme3
                                                            className={`w-100 h-auto prescription-theme ${printTemplate == "12" ? "selected" : ""}`}
                                                            onClick={() => setPrintTemplate("12")}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </VSInnerAccordion>
                                    <hr className="my-0" />
                                    <VSInnerAccordion
                                        expanded={true}
                                        title="Logo (Opcional)"
                                    >
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="vs-accordion-text-secondary">
                                                O Logo é opcional. Caso você não tenha um logo, o <b>Nome da Clínica aparecerá em destaque</b>
                                                </div>
                                                <div className="row my-3">
                                                    <div className="col">
                                                        <ImageUpload
                                                            getImage={(image) => setLogo(image)}
                                                            image={logo}
                                                            style={{
                                                                image: {
                                                                    width: '148px',
                                                                    height: '148px',
                                                                    borderRadius: '50%'
                                                                },
                                                                buttons: {
                                                                    width: 'calc(100% - 168px)',
                                                                    float: 'right',
                                                                    height: '148px',
                                                                    alignItems: 'center',
                                                                    changeButton: {
                                                                        paddingLeft: 0
                                                                    },
                                                                    deleteButton: {
                                                                        paddingLeft: 0
                                                                    }
                                                                }
                                                            }}
                                                        >
                                                            <IconAdd width="24" height="24" />
                                                            Adicionar logo
                                                        </ImageUpload>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="checkbox-title mb-2">Aplicar Logo em:</div>
                                                        <FormControlLabel
                                                            label="Prescrição"
                                                            className="d-block mb-0"
                                                            control={
                                                                <Checkbox
                                                                    checked={displayLogoIn.prescription}
                                                                    onChange={({ target: { checked } }) => setDisplayLogoIn({
                                                                        ...displayLogoIn,
                                                                        prescription: checked
                                                                    })}
                                                                    color="primary"
                                                                    className="p-1"
                                                                />
                                                            }
                                                        />
                                                        <FormControlLabel
                                                            label="Solicitações de exame"
                                                            className="d-block mb-0"
                                                            control={
                                                                <Checkbox
                                                                    checked={displayLogoIn.examRequest}
                                                                    onChange={({ target: { checked } }) => setDisplayLogoIn({
                                                                        ...displayLogoIn,
                                                                        examRequest: checked
                                                                    })}
                                                                    color="primary"
                                                                    className="p-1"
                                                                />
                                                            }
                                                        />
                                                        <FormControlLabel
                                                            label="Atestados e termos"
                                                            className="d-block mb-0"
                                                            control={
                                                                <Checkbox
                                                                    checked={displayLogoIn.term}
                                                                    onChange={({ target: { checked } }) => setDisplayLogoIn({
                                                                        ...displayLogoIn,
                                                                        term: checked
                                                                    })}
                                                                    color="primary"
                                                                    className="p-1"
                                                                />
                                                            }
                                                        />
                                                        <FormControlLabel
                                                            label="Vacinas"
                                                            className="d-block mb-0"
                                                            control={
                                                                <Checkbox
                                                                    checked={displayLogoIn.vaccine}
                                                                    onChange={({ target: { checked } }) => setDisplayLogoIn({
                                                                        ...displayLogoIn,
                                                                        vaccine: checked
                                                                    })}
                                                                    color="primary"
                                                                    className="p-1"
                                                                />
                                                            }
                                                        />
                                                        <FormControlLabel
                                                            label="Orçamentos"
                                                            className="d-block mb-0"
                                                            control={
                                                                <Checkbox
                                                                    checked={displayLogoIn.estimate}
                                                                    onChange={({ target: { checked } }) => setDisplayLogoIn({
                                                                        ...displayLogoIn,
                                                                        estimate: checked
                                                                    })}
                                                                    color="primary"
                                                                    className="p-1"
                                                                />
                                                            }
                                                        />
                                                        <FormControlLabel
                                                            label="Recibos de Venda"
                                                            className="d-block mb-0"
                                                            control={
                                                                <Checkbox
                                                                    checked={displayLogoIn.sale}
                                                                    onChange={({ target: { checked } }) => setDisplayLogoIn({
                                                                        ...displayLogoIn,
                                                                        sale: checked
                                                                    })}
                                                                    color="primary"
                                                                    className="p-1"
                                                                />
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </VSInnerAccordion>
                                </div>
                            </div>
                        )}
                    </VSAccordionSetting>
                    <VSAccordionSetting
                        title="Documentos Médicos"
                        className="mt-3">

                        { loading ? (
                            <div className="row loading justify-content-center mt-3">
                                <div className="col-auto">
                                    <CircularProgress />
                                </div>
                            </div>
                        ) : (
                            <div className="row">
                                <div className="col">
                                    <VSInnerAccordion
                                        expanded={true}
                                        title="Incluir Consulta"
                                    >
                                        <div className="row">
                                            <div className="col-12 mb-3">
                                                <div className="row align-items-center">
                                                    <div className="col vs-accordion-inner-title">
                                                        INCLUIR CONSULTA
                                                    </div>
                                                    <div className="col-auto">
                                                        <Switch
                                                            checked={displayAppointment}
                                                            onChange={({ target: { checked } }) => setDisplayAppointment(checked)}
                                                            color="primary"
                                                        />
                                                    </div>
                                                </div>
                                                <hr className="my-3" />
                                                <div className="vs-accordion-text-secondary mb-3"><b>Como funciona:</b> imagine que você registrou uma consulta para um animal no dia 20/10 às 17h00 e preencheu o campo Anamnese / Motivo</div>
                                                <div className="vs-accordion-text-secondary">Com a opção <b>Incluir Consulta Ativada</b>, todas as versões digitais das prescrições geradas para esse mesmo animal no dia 20/10 a partir das 17h00 incluirão no rodapé os dados da consulta (horário e Anamnese / Motivo)</div>
                                                <div className="row mt-3">
                                                    <div className="col-12">
                                                        <AppointmentInfo />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </VSInnerAccordion>
                                    <hr className="my-0" />
                                    <VSInnerAccordion
                                        expanded={true}
                                        title="Observações Gerais (Opcional)"
                                    >
                                        <div className="row mb-3">
                                            <div className="col-12">
                                                <div className="vs-accordion-text-secondary">As Observações gerais ficarão no rodapé dos seus documentos. Aqui você pode colocar informações gerais como regras de retorno, horários de atendimento, política de internação e visitas, etc.</div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col mb-3">
                                                <TextField
                                                    className="w-100"
                                                    placeholder="Escreva suas Observações Gerais"
                                                    label="Observações Gerais"
                                                    value={notes}
                                                    onChange={({ target: { value } }) => setNotes(value)}
                                                    helperText={optionalField}
                                                    variant="standard"
                                                    multiline
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <div className="checkbox-title mb-2">Aplicar Observações Gerais em:</div>
                                                <FormControlLabel
                                                    label="Prescrição"
                                                    className="d-block mb-0"
                                                    control={
                                                        <Checkbox
                                                            checked={displayNotesIn.prescription}
                                                            onChange={({ target: { checked } }) => setDisplayNotesIn({
                                                                ...displayNotesIn,
                                                                prescription: checked
                                                            })}
                                                            color="primary"
                                                            className="p-1"
                                                        />
                                                    }
                                                />
                                                <FormControlLabel
                                                    label="Solicitações de exame"
                                                    className="d-block mb-0"
                                                    control={
                                                        <Checkbox
                                                            checked={displayNotesIn.examRequest}
                                                            onChange={({ target: { checked } }) => setDisplayNotesIn({
                                                                ...displayNotesIn,
                                                                examRequest: checked
                                                            })}
                                                            color="primary"
                                                            className="p-1"
                                                        />
                                                    }
                                                />
                                                <FormControlLabel
                                                    label="Atestados e termos"
                                                    className="d-block mb-0"
                                                    control={
                                                        <Checkbox
                                                            checked={displayNotesIn.term}
                                                            onChange={({ target: { checked } }) => setDisplayNotesIn({
                                                                ...displayNotesIn,
                                                                term: checked
                                                            })}
                                                            color="primary"
                                                            className="p-1"
                                                        />
                                                    }
                                                />
                                                <FormControlLabel
                                                    label="Vacinas"
                                                    className="d-block mb-0"
                                                    control={
                                                        <Checkbox
                                                            checked={displayNotesIn.vaccine}
                                                            onChange={({ target: { checked } }) => setDisplayNotesIn({
                                                                ...displayNotesIn,
                                                                vaccine: checked
                                                            })}
                                                            color="primary"
                                                            className="p-1"
                                                        />
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </VSInnerAccordion>
                                </div>
                            </div>
                        )}
                    </VSAccordionSetting>
                    <VSAccordionSetting
                        title="Número de contato"
                        className="mt-3">

                        { loading ? (
                            <div className="row loading justify-content-center mt-3">
                                <div className="col-auto">
                                    <CircularProgress />
                                </div>
                            </div>
                        ) : (
                            <div className="row" ref={contactNumber}>
                                <div className="col">
                                    <div className="row align-items-center">
                                        <div className="col vs-accordion-inner-title">
                                            INCLUIR NÚMERO DE CONTATO
                                        </div>
                                        <div className="col-auto">
                                            <Switch
                                                checked={displayPhone}
                                                onChange={({ target: { checked } }) => setDisplayPhone(checked)}
                                                color="primary"
                                            />
                                        </div>
                                        { validatePhone(phoneWhatsApp) && !displayPhone && (
                                            <div className="col-12">
                                                <small className="text-secondary">
                                                    Ao salvar com essa opção desmarcada, o número informado no campo "WhatsApp da Clínica" será apagado
                                                </small>
                                            </div>
                                        )}
                                    </div>
                                    <hr className="my-3" />
                                    <div className="vs-accordion-text-secondary">
                                        Com a opção <b>Incluir Número de Contato</b>, todas as mensagens enviadas via WhatsApp terão 
                                        um botão para que o tutor entre contato com a clínica.
                                    </div>
                                    <hr className="my-3" />
                                    <div className="row">
                                        <div className="col">
                                            <TextFieldMask
                                                type="tel"
                                                mask={phoneWhatsApp && phoneWhatsApp.length <= 14 ? '(00) 0000-0000[0]' : '(00) 00000-0000'}
                                                value={phoneWhatsApp}
                                                onAccept={(value) => setPhoneWhatsApp(value)}
                                                className="w-100"
                                                placeholder="Informe o WhatsApp de contato da clínica"
                                                label="WhatsApp da Clínica"
                                                error={errorPhoneWhatsApp}
                                                helperText={helperPhoneWhatsApp}
                                                variant="standard"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </VSAccordionSetting>
                    <VSAccordionSetting
                        title="Documentos Financeiros"
                        className="mt-3">
                        { loading ? (
                            <div className="row loading justify-content-center mt-3">
                                <div className="col-auto">
                                    <CircularProgress />
                                </div>
                            </div>
                        ) : (
                            <div className="row ">
                                <div className="col">
                                    <VSInnerAccordion
                                        expanded={true}
                                        title="Observações Financeiras (Opcional)"
                                    >
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="vs-accordion-text-secondary mb-3">Estas informações ficarão no rodapé dos seus Orçamentos e Recibo de Vendas. Aqui você pode colocar informações como por ex. forma e condições de pagamento, bem como a validade de um orçamento, etc.</div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col mb-3">
                                                <TextField
                                                    className="w-100"
                                                    placeholder="Escreva suas observações aqui"
                                                    label="Observações Financeiras"
                                                    value={financialNotes}
                                                    onChange={({ target: { value } }) => setFinancialNotes(value)}
                                                    helperText={optionalField}
                                                    variant="standard"
                                                    multiline
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <div className="checkbox-title mb-2">Aplicar Observações Financeiras em:</div>
                                                <FormControlLabel
                                                    label="Orçamentos"
                                                    className="d-block mb-0"
                                                    control={
                                                        <Checkbox
                                                            checked={displayFinancialNotesIn.estimate}
                                                            onChange={({ target: { checked } }) => setDisplayFinancialNotesIn({
                                                                ...displayFinancialNotesIn,
                                                                estimate: checked
                                                            })}
                                                            color="primary"
                                                            className="p-1"
                                                        />
                                                    }
                                                />
                                                <FormControlLabel
                                                    label="Recibos de Venda"
                                                    className="d-block mb-0"
                                                    control={
                                                        <Checkbox
                                                            checked={displayFinancialNotesIn.sale}
                                                            onChange={({ target: { checked } }) => setDisplayFinancialNotesIn({
                                                                ...displayFinancialNotesIn,
                                                                sale: checked
                                                            })}
                                                            color="primary"
                                                            className="p-1"
                                                        />
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </VSInnerAccordion>
                                </div>
                            </div>
                        )}
                    </VSAccordionSetting>
                </div>
                <div className="col-12 col-md">
                    <SettingPreview 
                        isConfigSaved={loading || !isValid() || !isFormChanged}
                        onSave={save}
                    />
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        user: state.user,
        clinic: state.clinic,
        permissions: state.permissions
    }
}

export default connect(mapStateToProps)(ClinicSettingDocumentInfo)