import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

import { 
    getStateList,
    stringifyArray
} from '../../utils'

import './SettingSignatureForm.scss'
import { CircularProgress, SwipeableDrawer } from '@mui/material';
import VSTooltip from '../../components/vsTooltip/VSTooltip';
import { getUser } from '../../store/actions';

const SettingSignatureForm = (props) => {

    const {
        user,
        history
    } = props

    const [ loading, setLoading ] = useState(false)

    const [ signature, setSignature ] = useState(user.signature)

    const [ crmv, setCrmv ] = useState(user.crmv)
    const [ errorCrmv, setErrorCrmv ] = useState(false)
    const [ helperCrmv, setHelperCrmv ] = useState(<span>Campo <b>Obrigatório</b></span>)

    const [ crmvStateId, setCrmvStateId ] = useState(user.crmvStateId)
    const [ errorCrmvStateId, setErrorCrmvStateId ] = useState(false)
    const [ helperCrmvStateId, setHelperCrmvStateId ] = useState(<span>Campo <b>Obrigatório</b></span>)

    const [ registrationMAPA, setRegistrationMAPA ] = useState(user.registrationMAPA)

    const validateOnTouch = () => {
        if (signature || crmv || crmvStateId) {
            validate()
        }
    }

    const validate = () => {
        let isValid = true
        if (loading) {
            isValid = false
        }

        if (!crmv || crmv.length > 3) {
            setErrorCrmv(false)
            setHelperCrmv(<span>Campo Opcional</span>)
        } else {
            isValid = false
            setErrorCrmv(true)
            setHelperCrmv("O CRMV informado não é válido")
        }
        
        if (crmv && !crmvStateId) {
            isValid = false
            setErrorCrmvStateId(true)
            setHelperCrmvStateId("O Estado do CRMV é obrigatório quando o CRMV é preenchido")
        } else {
            setErrorCrmvStateId(false)
            setHelperCrmvStateId(<span>Campo Opcional</span>)
        }
        return isValid
    }

    const isValid = () => !loading && !errorCrmv && !errorCrmvStateId

    const fieldsWithErrorText = () => {
        let errors = []
        if (errorCrmv) {
            errors.push("CRMV")
        }
        if (errorCrmvStateId) {
            errors.push("Estado do CRMV")
        }
        if (loading) {
            errors.push("Salvando a assinatura")
        }
        return stringifyArray(errors)
    }

    const save = () => {
        if (validate()) {
            setLoading(true)
            const userParse = user.object
            userParse.set('signature', signature)
            userParse.set('crmv', crmv)
            userParse.set('crmvStateId', crmvStateId)
            userParse.set('registrationMAPA', registrationMAPA)
            userParse.save()
            .then(_ => {
				props.dispatch(getUser(Parse.User.current()))
                history.goBack()
            })
            .catch(error => {
                console.error(error)
                Swal.fire(
                    'Desculpe',
                    `Ocorreu algum erro ao tentar salvar a assinatura`,
                    'error'
                )
                userParse.revert()
            })
        }
    }

    useEffect(() => {
        validateOnTouch()
    }, [ signature, crmv, crmvStateId ])

    return (
        <div id="signature-form" className="row">
            <div className="col-12">
                <div className="row header">
                    <div className="col">
                        Configurar assinatura e CRMV
                    </div>
                </div>
                { loading ? (
                    <div className="row loading">
                        <div className="col">
                            <CircularProgress />
                        </div>
                    </div>
                ) : (
                    <div className="row body">
                        <div className="col">
                            <div className="row align-items-center mb-3">
                                <div className="col">
                                    <TextField
                                        className="w-100"
                                        placeholder={`Dr(a) ${user.fullName}`}
                                        label="Assinatura do(a) médico(a) veterinário(a)"
                                        value={signature}
                                        onChange={({ target: { value } }) => setSignature(value)}
                                        helperText={<span>Campo Opcional</span>}
                                    />
                                </div>
                            </div>
                            <div className="row align-items-center mb-3">
                                <div className="col">
                                    <TextField
                                        type="tel"
                                        className="w-100"
                                        placeholder="00000"
                                        label="CRMV"
                                        value={crmv}
                                        onChange={({ target: { value } }) => setCrmv(value)}
                                        error={errorCrmv}
                                        helperText={helperCrmv}
                                    />
                                </div>
                            </div>
                            <div className="row align-items-center mb-3">
                                <div className="col">
                                    <TextField
                                        className="w-100"
                                        placeholder="São Paulo"
                                        label="Estado"
                                        value={crmvStateId}
                                        onChange={({ target: { value } }) => setCrmvStateId(value)}
                                        error={errorCrmvStateId}
                                        helperText={helperCrmvStateId}
                                        select
                                    >
                                        { getStateList().map(stateItem => (
                                            <MenuItem key={stateItem.value} value={stateItem.value}>
                                                { stateItem.text }
                                            </MenuItem>
                                        )) }
                                    </TextField>
                                </div>
                            </div>
                            <div className="row align-items-center mb-3">
                                <div className="col">
                                    <TextField
                                        className="w-100"
                                        placeholder={`12345678/2021`}
                                        label="Número de registro MAPA"
                                        value={registrationMAPA}
                                        onChange={({ target: { value } }) => setRegistrationMAPA(value)}
                                        helperText={<span>Campo Opcional</span>}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <div className="row footer">
                    <div className="col-auto">
                        <button className="btn-primary" onClick={() => history.goBack()}>
                            Cancelar
                        </button>
                    </div>
                    <VSTooltip
                        placement="top-start"
                        open={!isValid()}
                        title={
                            <React.Fragment>
                                <div>O(s) campo(s): [<b>{ fieldsWithErrorText() }</b>] está(ão) com erro no preenchimento</div>
                            </React.Fragment>
                        }
                    >
                        <div className="col">
                            <button className="btn-primary-rounded w-100" onClick={() => save()} disabled={!isValid()}>
                                Salvar alterações
                            </button>
                        </div>
                    </VSTooltip>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        user: state.user
    };
};

const unconnectedDrawerSignatureForm = (props) => (
    <SwipeableDrawer
        anchor="right"
        className="signature-form-wrap"
        open={true}
        onOpen={() => null}
        onClose={() => props.history.goBack()}
    >
        <SettingSignatureForm {...props} onClose={() => props.history.goBack()} />
    </SwipeableDrawer>
)

export const DrawerSignatureForm = connect(mapStateToProps)(unconnectedDrawerSignatureForm)
  
export default connect(mapStateToProps)(SettingSignatureForm);