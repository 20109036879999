import React from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { connect } from 'react-redux'
import { ACL } from '../../constants'

import { CALENDAR_BLANK, CALENDAR_BLOCK, CALENDAR_CANCELED, CALENDAR_CANCELED_MESSAGE, CALENDAR_CONFIRMED, CALENDAR_DONE, CALENDAR_NOSHOW, CALENDAR_OPEN, allStatusOptions, isDefaultCalendar, statusOptions } from './Calendar'
import { stringifyArray } from '../../utils'

import { ReactComponent as IconAdd } from "../../assets/icon-primary-add-o.svg"
import { ReactComponent as IconCalendar } from "../../assets/icon-calendar.svg"
import { ReactComponent as IconCalendarX } from "../../assets/icon-calendar-x.svg"
import { ReactComponent as IconCategory } from "../../assets/icon-category.svg"
import { ReactComponent as IconHealth } from "../../assets/icon-health.svg"
import { ReactComponent as IconPaw } from "../../assets/icon-paw.svg"
import { ReactComponent as IconPawMenu } from "../../assets/icon-paw-menu.svg"
import { ReactComponent as IconUser } from "../../assets/icon-user.svg"
import { ReactComponent as IconUserO } from "../../assets/icon-user-o.svg"

import VSTimeline from '../../components/vsTimeline/VSTimeline'
import VSEmpty from '../../components/vsEmpty/VSEmpty'

import "./CalendarAgendaView.scss"
const CalendarAgendaView = (props) => {

    const {
        notFoundData,
        selectedEvent,
        permissions,
        accessors,
        resources,
        events,
        onSelectEvent
    } = props

    const history = useHistory()

    const eventsByDate = {}

    const getStyleByStatus = (status, color) => {
        switch(status) {
            case CALENDAR_OPEN:
                return {
                    backgroundColor: "#FFFFFF",
                    borderColor: color,
                    color: color
                }
            case CALENDAR_CONFIRMED:
                return {
                    backgroundColor: color,
                    borderColor: color,
                    color: "#FFFFFF"
                }
            case CALENDAR_DONE:
                return {
                    backgroundColor: `${color}80`,
                    borderColor: color,
                    color: "#FFFFFF"
                }
            case CALENDAR_NOSHOW:
                return {
                    backgroundColor: `${color}0F`,
                    borderColor: color,
                    color: color
                }
            case CALENDAR_CANCELED:
                return {
                    backgroundColor: `#C40000`,
                    borderColor: `#C40000`,
                    color: "#FFFFFF"
                }
            case CALENDAR_CANCELED_MESSAGE:
                return {
                    backgroundColor: `#C400000f`,
                    borderColor: `#C40000`,
                    color: "#C40000"
                }
            case CALENDAR_BLOCK:
                return {
                    backgroundColor: "#FF8A00",
                    borderColor: "#FF8A00",
                    color: "#FFFFFF"
                }
            case CALENDAR_BLANK:
                return {
                    background: "#0778D4",
                    borderColor: "#7EA5C9",
                    color: "#FFFFFF"
                }
        }
    }

    events
        .sort((a, b) => +accessors.start(a) - +accessors.start(b))
        .map(event => {
            const eventDate = dayjs(event.start).startOf("day").toDate()
            if (eventsByDate[eventDate]) {
                eventsByDate[eventDate].push(event)
            } else {
                eventsByDate[eventDate] = [event]
            }
        })

    const eventsByDateArray = Object.keys(eventsByDate)

    const today = dayjs().startOf("day")

    return (
        <div id="calendar-agenda-view">
            { eventsByDateArray.length > 0 ? (
                eventsByDateArray.map((date) => {
                    const datejs = dayjs(date)
                    return (
                        <VSTimeline
                            key={date}
                            day={datejs.format("DD")}
                            month={datejs.format("MMM")}
                            year={datejs.format("YYYY")}
                            className={datejs.isSame(today) ? "vstimeline-today" : ""}
                        >
                            { eventsByDate[date].map((event, index) => {
                                const object = event.object
                                const isDefault = isDefaultCalendar(object.get("status"))
                                const patient = object?.get("patient")
                                const owner = patient?.get("owner")
                                const professional = resources?.find(user => user.id == event.resourceId)
                                const color = object?.get("tags")?.[0]?.get("color") || "#00C4B4"
                                return (
                                    <div key={index} className={`vstimeline-item vstimeline-active w-100`}>
                                        <div
                                            className="vsbox mb-3"
                                            onClick={(e) => {
                                                onSelectEvent(event, e)
                                            }}
                                        >
                                            <div className={`vsbox-body mb-0 ${object?.id} ${ object?.id == selectedEvent?.id ? "vsbox-selected" : "" }`}>
                                                <div className="row mb-2">
                                                    <div className="col-12 calendar-services">
                                                        <span className="calendar-bullet" style={getStyleByStatus(object?.get("status"), color)}></span>
                                                        { dayjs(event.start).format("HH:mm") } até { dayjs(event.end).format("HH:mm") } - { object.get("title") || "Sem título" }
                                                    </div>
                                                </div>
                                                { isDefault ? (
                                                    <>
                                                        <div className="row g-2">
                                                            <div className="col-12">
                                                                <div className="row g-2 calendar-info">
                                                                    <div className="col-12 col-md-auto">
                                                                        <IconPawMenu width={16} height={16} fill="#7A7A7A" className="me-2" />
                                                                        <b>Animal:</b> { patient?.get("name") }
                                                                    </div>
                                                                    <div className="col-12 col-md-auto">
                                                                        <IconUser width={16} height={16} fill="#7A7A7A" className="me-2" />
                                                                        <b>Tutor(a):</b> { owner?.get("name") }
                                                                    </div>
                                                                    <div className="col-12">
                                                                        <IconHealth width={16} height={16} fill="#7A7A7A" className="me-2" />
                                                                        { stringifyArray(object?.get("services")?.map(service => service.serviceName)) || "Nenhum serviço informado" }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        <div className="row g-2">
                                                            { object.get("status") == CALENDAR_BLOCK && (
                                                                <div className="col-12 calendar-info">
                                                                    <IconCategory width={16} height={16} fill="#7A7A7A" className="me-2" />
                                                                    <b>Categoria:</b> { object?.get("category") || "Não informado" }
                                                                </div>
                                                            )}
                                                        </div>
                                                    </>
                                                )}
                                                <hr/>
                                                <div className="row">
                                                    <div className="col-12 calendar-info">
                                                        <IconUserO width={16} height={16} fill="#7A7A7A" className="me-2" />
                                                        <b>Responsável:</b> { professional?.name || "Todos os profissionais" }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }) }
                        </VSTimeline>
                    )
                })
            ) : (
                <VSEmpty
                    icon={<IconPaw width="32px" height="32px" fill="var(--vsprontuario-primary-color)" />}
                    title={notFoundData.title}
                    text={notFoundData.text}
                >
                    { permissions?.check(ACL.CALENDAR, ACL.Rule.EDIT) && (
                        <button
                            id="new-calendar-button"
                            className="vsbox-btn vsbox-btn-primary w-auto"
                            onClick={() => history.push(
                                "/animal/selecionar/agenda", 
                                { date: new Date() }
                            )}
                        >
                            <IconAdd id="add-icon" width="14" height="14" fill="var(--vsprontuario-primary-color)" />
                            Novo evento
                        </button>
                    )}
                </VSEmpty>
            )}
        </div>
    )
}

CalendarAgendaView.title = () => {
  return ""
}

CalendarAgendaView.propTypes = {
    events: PropTypes.array,
    date: PropTypes.instanceOf(Date),
    length: PropTypes.number,
    selected: PropTypes.object,
    accessors: PropTypes.object.isRequired,
    components: PropTypes.object.isRequired,
    getters: PropTypes.object.isRequired,
    localizer: PropTypes.object.isRequired,
}

const mapStateToProps = state => {
    return {
        permissions: state.permissions
    }
}

export default connect(mapStateToProps)(CalendarAgendaView)