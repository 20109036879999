import React, { forwardRef, useImperativeHandle, useState } from "react"
import PropTypes from 'prop-types';

import VSDrawer from '../vsDrawer/VSDrawer';

import "./VSFilterSelect.scss"
const VSFilterSelect = forwardRef((props, ref) => {

    const {
        text,
        title,
        options,
        onSelect,
    } = props

    const [ isOpen, setIsOpen ] = useState(false)

    useImperativeHandle(ref, () => ({
        onOpen: setIsOpen,
     }));

    return (
        <div id="vsfilter-select">
            <div className="btn-link" onClick={() => setIsOpen(true)}>
                { text }
            </div>
            <VSDrawer
                id="filter-select" 
                open={isOpen}
                width="40%"
                timeout={0}
                title={title}
                onClose={() => {
                    setIsOpen(false)
                }}
                VSDrawerFooterProps={{
                    show: false 
                }}
            >
                { options?.map((option, index) => (
                    <div
                        key={index}
                        className="filter-option"
                        onClick={() => {
                            onSelect(option.value)
                            setIsOpen(false)
                        }}
                    >
                        { option.label }
                    </div>
                )) }
            </VSDrawer>
        </div>
    )
})

VSFilterSelect.propTypes = {
    text: PropTypes.any,
    title: PropTypes.string,
    onSelect: PropTypes.func,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.any,
            value: PropTypes.any
        }),
    )
};

VSFilterSelect.defaultProps = {
    text: null,
    title: null,
    onSelect: () => null,
    options: null
}

export default VSFilterSelect