import VSAccordionSimple from "./VSAccordionSimple"

import "./VSAccordionCard.scss"
const VSAccordionCard = (props) => {

    const {
        expanded,
        icon,
        title,
        text,
        onChange
    } = props

    return (
        <div id="vsaccordion-card">
            <div className="row g-3">
                <div className="col-12">
                    { icon }
                </div>
                <div className="col-12 mt-1">
                    <VSAccordionSimple
                        expanded={expanded}
                        header={() => title }
                        onChange={(expanded) => onChange && onChange(expanded)}
                    >
                        <div className="row">
                            <div className="col-12">
                                { text }
                            </div>
                        </div>
                    </VSAccordionSimple>
                </div>
            </div>
        </div>
    )
}

export default VSAccordionCard