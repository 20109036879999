import React, { useRef, useState, useEffect } from "react"
import { connect } from "react-redux"

import { ReactComponent as IconAdd } from "../../assets/icon-primary-add-o.svg"
import { ReactComponent as IconPatientBalance } from '../../assets/icon-patient-weight.svg'
import { ReactComponent as IconSquareMinus } from "../../assets/icon-square-minus.svg"
import { ReactComponent as IconSquarePlus } from "../../assets/icon-square-plus.svg"

import { ACL, OrderBy_Oldest, OrderBy_Newest } from "../../constants"
import { paymentOptions } from "../patient/tabs/sale/Sale"
import { dayjs, diacriticSensitive } from "../../utils"

import VSEmpty from "../../components/vsEmpty/VSEmpty"
import VSList from "../../components/vsList/VSList"
import VSAccordionSimple from "../../components/vsAccordion/VSAccordionSimple"
import VSTimeline from "../../components/vsTimeline/VSTimeline"
import CardFeedback from "../../components/cardFeedback/CardFeedback"
import CashOutflowView from "./CashOutflowView"
import CardFinancialView from "./CardFinancialView"

import "./CashOutflowList.scss"
const CashOutflowList = (props) => {

    const {
        clinic,
        history,
        permissions
    } = props
    
    const rowsPerPage = 5

    const filter = useRef(null)
    const isMounted = useRef(null)

    const [ paymentsByDate, setPaymentsByDate ] = useState({})

    const [ term, setTerm ] = useState("")
    const [ dateFrom, setDateFrom ] = useState(null)
    const [ dateTo, setDateTo ] = useState(null)
    const [ statusExpense, setStatusExpense ] = useState("0")
    const [ paymentTypes, setPaymentTypes ] = useState([])

    const getExpense = (searchTerm, dateFrom, dateTo, orderBy, page = 1) => {
        return new Promise((resolve, reject) => {
            const filters = {
                isDeleted: false,
                paymentsValue: {
                    $gt: 0
                },
                name: { 
                    $regex: diacriticSensitive(searchTerm),
                    $options: 'im'
                }
            }
            if (paymentTypes.length > 0) {
                filters.payment_item_type = {
                    $in: paymentTypes.map(payment => payment.value)
                }
            }
            if (statusExpense == "1") {
                filters.recurrenceActive = true
            } else if (statusExpense == "2") {
                filters.recurrenceActive = false
            }
            Parse.Cloud.run("getPeriodForClass", {
                clinicId: clinic.objectId,
                className: "VW_MRExpense_payments",
                filters: filters,
                dateFrom: dateFrom,
                dateTo: dateTo,
                orderBy: "payment_item_date",
                order: orderBy == OrderBy_Newest ? "newest" : "oldest",
                page: page
            })
            .then(({ total, from, to }) => {
                var query = new Parse.Query("VW_MRExpense_payments")
                query.greaterThan("paymentsValue", 0);
                query.equalTo("clinic", clinic.object);
                query.equalTo("isDeleted", false);
                if (searchTerm) {
                    query.matches("name", diacriticSensitive(searchTerm), "im")
                }
                if (paymentTypes.length > 0) {
                    const queryPaymentTypes = paymentTypes.map(payment => payment.value)
                    query.containedIn("payment_item_type", queryPaymentTypes)
                }
                if (statusExpense == "1") {
                    query.equalTo("recurrenceActive", true)
                } else if (statusExpense == "2") {
                    query.equalTo("recurrenceActive", false)
                }
                query.greaterThanOrEqualTo("payment_item_date", from)
                query.lessThanOrEqualTo("payment_item_date", to)
                switch (orderBy) {
                    case OrderBy_Oldest:
                        query.ascending("payment_item_date");
                        break;
                    default:
                        query.descending("payment_item_date");
                }
                query.include("parentObject")
                query.include("originalMRExpense")
                query.include("createdBy")
                query.include("changedBy")
                query.find()
                .then(results => {
                    if (results && isMounted.current) {
                        let paymentsByDate = []
                        let dates = []
                        results.map(payment => {
                            const date = dayjs(payment.get("payment_item_date")).format("DD/MM/YYYY")
                            const expense = payment.get("originalMRExpense")
                            if (!paymentsByDate[date]) {
                                dates.push(date)
                                paymentsByDate[date] = []
                            }
                            if (!paymentsByDate[date][expense.id]) {
                                paymentsByDate[date][expense.id] = {
                                    expense: expense,
                                    payments: [payment]
                                }
                            } else {
                                paymentsByDate[date][expense.id].payments.push(payment)
                            }
                        })
                        setPaymentsByDate(paymentsByDate)
                        resolve({
                            items: dates,
                            total: total
                        })
                    }
                })
                .catch(error => {
                    console.error(error)
                    return Promise.reject(error)
                })
            })
        })
    }

    const onChange = () => {
        if (filter.current) {
            filter.current.onChange()
        }
    }

    useEffect(() => {
        if (permissions && !permissions.check(ACL.OUTFLOW, ACL.Rule.VIEW)) {
            permissions.error()
        }
    }, [ permissions ])

    useEffect(() => {
        isMounted.current = true
        return () => { 
            isMounted.current = false
        }
    }, [])

    return (
        <>
            <div id="cash-outflow-list" className="flex-grow-1">
                <div className="col-12">
                    <VSList
                        ref={filter}
                        title={{
                            text: "Saída de Caixa"
                        }}
                        button={permissions?.check(ACL.OUTFLOW, ACL.Rule.EDIT) && {
                            text: "Criar Nova Despesa",
                            onClick: () => history.push(`/despesa/novo`)
                        }}
                        search={{
                            text: "Buscar por Nome da Despesa",
                            value: ""
                        }}
                        interval={{
                            start: {
                                text: "De"
                            },
                            end: {
                                text: "Até"
                            }
                        }}
                        order={{
                            options: [
                                OrderBy_Newest,
                                OrderBy_Oldest
                            ],
                            value: OrderBy_Newest
                        }}
                        VSFilterProps={{
                            title: `Filtrar Resultados`,
                            onClear: () => {
                                
                            },
                            onFilter: () => {
                                onChange()
                            },
                            filters: [
                                {
                                    title: "Recorrência Ativada",
                                    defaultValue: "0",
                                    value: statusExpense,
                                    onChange: (value) => {
                                        setStatusExpense(value)
                                    },
                                    horizontal: true,
                                    options: [
                                        { value: "0", label: "Todos" },
                                        { value: "1", label: "Sim" },
                                        { value: "2", label: "Não" },
                                    ]
                                },
                                {
                                    title: "Formas de pagamento",
                                    defaultValue: [],
                                    value: paymentTypes,
                                    onChange: (values) => {
                                        setPaymentTypes(values)
                                    },
                                    options: paymentOptions,
                                    multiple: true
                                }
                            ].filter(val => val)
                        }}
                        appendFilter={(
                            <div className="mt-3">
                                <CardFinancialView 
                                    clinic={clinic}
                                    searchTerm={term}
                                    dateFrom={dateFrom}
                                    dateTo={dateTo}
                                    status={statusExpense}
                                    paymentTypes={paymentTypes}
                                    services={[]}
                                />
                            </div>
                        )}
                        pagination={{
                            rowsPerPage: rowsPerPage
                        }}
                        onChange={({ _searchBy, _start, _end, _orderBy, _page }) => {
                            setTerm(_searchBy)
                            setDateFrom(_start)
                            setDateTo(_end)
                            return getExpense(_searchBy, _start, _end, _orderBy, _page)
                        }}
                        renderItem={(item, expanded) => {
                            const date = dayjs(item, "DD/MM/YYYY")
                            return (
                                <VSTimeline
                                    day={ date.format("DD") }
                                    month={ date.format("MMM") }
                                    year={ date.format("YYYY") }
                                >
                                    { paymentsByDate && paymentsByDate[item] && Object.keys(paymentsByDate[item]).map((key, index) => {
                                        const data = paymentsByDate[item][key]
                                        const expense = data.expense
                                        const payments = data.payments
                                        return (
                                            <React.Fragment key={index}>
                                                <VSAccordionSimple
                                                    expanded={true}
                                                    className="custom-accordion"
                                                    hideIcon
                                                    header={(expanded) => (
                                                        <div className={`vstimeline-item ${expense.get("isDeleted") ? "vstimeline-inactive" : "vstimeline-active"} w-100`}>
                                                            <div  className="vsbox mt-2">
                                                                <div className="vsbox-body p-2 mb-0">
                                                                    <div className="row align-items-center mx-0">
                                                                        <div className="col">
                                                                            <div className="row align-items-center">
                                                                                <div className="col">
                                                                                    <div className="vsbox-title">
                                                                                        { expense.get("name") }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12 col-md-auto text-end vsbox-info">
                                                                            <div className="vsbox-separator d-block d-md-none"></div>
                                                                            <div className="vsbox-info-action">
                                                                                { expanded ? "Ocultar" : "Ver" } Registro{ payments.length == 1 ? "" : "s" } ({ payments.length })
                                                                                { expanded ? (
                                                                                    <IconSquareMinus width="24px" height="24px" className="ms-2" fill="var(--vsprontuario-secondary-color)" />
                                                                                ) : (
                                                                                    <IconSquarePlus width="24px" height="24px" className="ms-2" fill="var(--vsprontuario-secondary-color)" />
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                >
                                                    { payments && payments.map((data, index) => {
                                                        return (
                                                            <div key={index} className={`${data.get("isDeleted") ? "vstimeline-item vstimeline-inactive" : ""}`}>
                                                                <CashOutflowView
                                                                    object={data}
                                                                    expanded={expanded}
                                                                    history={history}
                                                                    updateList={onChange}
                                                                    noDocumentDate
                                                                    noAudit
                                                                />
                                                            </div>
                                                        )
                                                    }) }
                                                </VSAccordionSimple>
                                            </React.Fragment>
                                        )
                                    }) }
                                </VSTimeline>
                            )
                        }}
                        renderEmpty={() => (
                            <VSEmpty
                                icon={<IconPatientBalance width="32px" height="32px" />}
                                title={`Você ainda não possui nenhuma despesa`}
                                text={`Cadastre a primeira despesa para visulizá-lo aqui`}
                            >
                                <button
                                    id="new-expense-link"
                                    className="vsbox-btn vsbox-btn-primary"
                                    onClick={() => history.push(`/despesa/novo`)}
                                >
                                    <IconAdd width="14" height="14" className="me-2" />
                                    Cadastrar nova despesa
                                </button>
                            </VSEmpty>
                        )}
                        renderNotFound={() => (
                            <VSEmpty
                                icon={<IconPatientBalance width="32px" height="32px" />}
                                title="Não encontramos nenhum resultado para a sua busca"
                                text="Tente refazer sua busca utilizando outro período ou crie um novo cadastro utilizando o botão abaixo"
                            >
                                <button
                                    id="new-expense-link"
                                    className="vsbox-btn vsbox-btn-primary"
                                    onClick={() => history.push(`/despesa/novo`)}
                                >
                                    <IconAdd width="14" height="14" className="me-2" />
                                    Cadastrar nova despesa
                                </button>
                            </VSEmpty>
                        )}
                    />
                </div>
            </div>
            {paymentsByDate && Object.keys(paymentsByDate).length > 0 && (
                <CardFeedback contentDetail={history.location?.pathname || "CashOutflowList"} />
            )}
        </>
    )
}

const mapStateToProps = state => {
    return {
        clinic: state.clinic,
        permissions: state.permissions
    }
}
  
export default connect(mapStateToProps)(CashOutflowList)