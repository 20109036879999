import React, { useState, useEffect, useRef } from 'react'
import { TextField } from '@mui/material';

import VSAccordionSimple from '../../../../../components/vsAccordion/VSAccordionSimple';
import CompanyNameField from './CompanyNameField';
import ManufacturingBatchPartField from './ManufacturingBatchPartField';
import ManufacturingDateField from './ManufacturingDateField';
import ExpirationDateField from './ExpirationDateField';
import VSFileUpload from '../../../../../components/vsFileUpload/VSFileUpload';
import { validateDate } from '../../../../../utils';

const ComplementaryDataFields = (props) => {
    
    const {
        companyName,
        setCompanyName,
        manufacturingBatchPart,
        setManufacturingBatchPart,
        manufacturingDate,
        setManufacturingDate,
        manufacturingDateText,
        setManufacturingDateText,
        errorManufacturingDate,
        helperManufacturingDate,
        expirationDate,
        setExpirationDate,
        expirationDateText,
        setExpirationDateText,
        errorExpirationDate,
        helperExpirationDate,
        setAttachments,
        attachments
    } = props

    const handleManufacturingDate = (date) => {
        setManufacturingDate(validateDate("01/" + date))
        setManufacturingDateText(date)
    }

    const handleExpirationDate = (date) => {
        setExpirationDate(validateDate("01/" + date))
        setExpirationDateText(date)
    }

    return (
        <VSAccordionSimple
            expanded={false}
            header={(expanded) => (
                `Dados Complementares`
            )}
        >
            <div className="row align-items-center mb-3">
                <div className="col-12">
                    <p className="black mb-0">
                        Estes dados são utilizados para preencher automaticamente 
                        campos do Atestado de Vacinação
                    </p>
                </div>
            </div>
            <div className="row align-items-center mb-md-3">
                <div className="col-12 col-md-6 mb-3 mb-md-0">
                    <CompanyNameField
                        companyName={companyName}
                        setCompanyName={setCompanyName}
                    />
                </div>
                <div className="col-12 col-md-6 mb-3 mb-md-0">
                    <ManufacturingBatchPartField
                        manufacturingBatchPart={manufacturingBatchPart}
                        setManufacturingBatchPart={setManufacturingBatchPart}
                    />
                </div>
            </div>
            <div className="row align-items-center mb-3">
                <div className="col-12 col-md-6 mb-3 mb-md-0">
                    <ManufacturingDateField
                        manufacturingDate={manufacturingDate}
                        manufacturingDateText={manufacturingDateText}
                        handleManufacturingDate={handleManufacturingDate}
                        errorManufacturingDate={errorManufacturingDate}
                        helperManufacturingDate={helperManufacturingDate}
                    />
                </div>
                <div className="col-12 col-md-6 mb-3 mb-md-0">
                    <ExpirationDateField
                        expirationDate={expirationDate}
                        expirationDateText={expirationDateText}
                        handleExpirationDate={handleExpirationDate}
                        errorExpirationDate={errorExpirationDate}
                        helperExpirationDate={helperExpirationDate}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-photo">
                    <VSFileUpload
                        onChange={(attachments) => setAttachments(attachments)}
                        value={attachments}
                    >
                        Adicionar foto(s) da<br/>
                        etiqueta da vacina
                    </VSFileUpload>
                </div>
            </div>
        </VSAccordionSimple>
    )
}

export default ComplementaryDataFields