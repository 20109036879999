import React, { useEffect, useRef, useState } from "react"
import { matchSorter } from 'match-sorter';
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { useParams } from 'react-router-dom';
import { connect } from "react-redux"
import { RRule } from "rrule";

import { Chip, CircularProgress, InputAdornment, TextField } from "@mui/material"
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

import { ReactComponent as IconAddO } from "../../assets/icon-primary-add-o.svg"
import { ReactComponent as IconCalendarCheck } from "../../assets/icon-calendar-check.svg"
import { ReactComponent as IconClock } from "../../assets/icon-clock.svg"
import { ReactComponent as IconClose } from "../../assets/icon-close.svg"
import { ReactComponent as IconContact } from "../../assets/icon-contact-owner.svg"
import { ReactComponent as IconEdit } from "../../assets/icon-primary-edit.svg"
import { ReactComponent as IconQuestionT } from "../../assets/icon-question-t.svg"
import { ReactComponent as IconTrash } from "../../assets/icon-primary-trash.svg"
import { ReactComponent as IconText } from "../../assets/icon-text.svg"
import { ReactComponent as IconSchedule } from "../../assets/icon-schedule.svg"
import { ReactComponent as IconSearch } from "../../assets/icon-search.svg"

import { getAlertFromProfessionalAgenda, getCalendar, getDefaultTagFromTags, getProfessionals, getServices, getTags, getTimeDescriptionByMinutes, statusOptions, timeOptions } from "./Calendar";
import { addEvent, dayjs, formattedCurrency, getAmericaFortalezaDate, getSelectedOptionLabel, mandatoryField, mandatoryFields, optionalField, saveOnTracker, setAmericaFortalezaTimezone, showArrayIndex, validateDate, validateTime } from "../../utils";
import { ACL, APP_DEVICE, APP_NAME, APP_VERSION } from "../../constants";
import { showMinutesAsTimeFormatted } from "../service/Service";
import { getPatient } from "../patient/Patient";
import { RECURRENCE_THIS } from "../recurrence/Recurrence";
import { STATUS_FUTURE } from "../pro/Payment";

import VSTextFieldCurrency from "../../components/vsTextFieldCurrency/VSTextFieldCurrency";
import { PatientQueryToggle } from "../patient/PatientWideInfo";
import VSAccordionSimple from "../../components/vsAccordion/VSAccordionSimple"
import TextFieldMask from "../../components/textFieldMask/TextFieldMask";
import VSTooltip from "../../components/vsTooltip/VSTooltip";
import DateInput from "../../components/dateInput/DateInput";
import VSDrawer from "../../components/vsDrawer/VSDrawer"
import VSError from "../../components/vsError/VSError";
import BoxDropdown from "../../components/boxDropdown/BoxDropdown";
import VSMessage from "../../components/vsMessage/VSMessage";
import VSRadio from "../../components/vsRadio/VSRadio";
import TagForm from "./TagForm";
import VSAccordionCard from "../../components/vsAccordion/VSAccordionCard";
import RecurrenceForm from "../recurrence/RecurrenceForm";
import VSCheckbox from "../../components/vsCheckbox/VSCheckbox";

const filter = createFilterOptions();

import "./CalendarForm.scss"
const CalendarForm = (props) => {

    const {
        id_agendamento
    } = useParams()

    const {
        state
    } = useLocation()

    const {
        pro,
        clinic,
        history,
        permissions
    } = props

    const id = state?.id
    const date = dayjs(state?.date || new Date()).hour(0).minute(0).second(0)

    const update = id_agendamento != "novo"
    const isPro = pro?.access || false

    const recurrenceForm = useRef(null)
    const messageForm = useRef(null)

    const [ loading, setLoading ] = useState(true)
    const [ error, setError ] = useState(null)
    const [ saveLoading, setSaveLoading ] = useState(false)
    const [ patient, setPatient ] = useState(null)
    const [ calendar, setCalendar ] = useState(null)

    const [ title, setTitle ] = useState("")
    const [ errorTitle, setErrorTitle ] = useState(false)
    const [ helperTitle, setHelperTitle ] = useState(mandatoryField)

    const [ tagsOptions, setTagsOptions ] = useState([])
    const [ servicesOptions, setServicesOptions ] = useState([])
    const [ professionalsOptions, setProfessionalsOptions ] = useState([])

    const [ status, setStatus ] = useState("open")

    const [ tags, setTags ] = useState([])
    const [ showTags, setShowTags ] = useState(true)
    const [ errorTags, setErrorTags ] = useState(false)
    const [ helperTags, setHelperTags ] = useState(mandatoryField)

    const [ startDate, setStartDate ] = useState(date.toDate())
    const [ startDateText, setStartDateText ] = useState(date.format("DD/MM/YYYY"))
    const [ endDate, setEndDate ] = useState(date.toDate())
    const [ startTime, setStartTime ] = useState(state?.start || "")
    const [ endTime, setEndTime ] = useState(state?.end || "")
    const [ fullTime, setFullTime ] = useState(false)
    const [ backupStartTime, setBackupStartTime ] = useState(state?.start || "")
    const [ backupEndTime, setBackupEndTime ] = useState(state?.end || "")
    const [ errorPeriod, setErrorPeriod ] = useState(false)
    const [ helperPeriod, setHelperPeriod ] = useState(mandatoryField)

    const [ professional, setProfessional ] = useState("")
    const [ errorProfessional, setErrorProfessional ] = useState(false)
    const [ helperProfessional, setHelperProfessional ] = useState(null)
    const [ alertProfessional, setAlertProfessional ] = useState("")

    const [ service, setService ] = useState(null)
    const [ services, setServices ] = useState([])
    const [ errorServices, setErrorServices ] = useState(false)
    const [ helperServices, setHelperServices ] = useState(mandatoryFields)

    const [ notes, setNotes ] = useState("")

    const [ activeRecurrence, setActiveRecurrence ] = useState(false)
    const [ errorRecurrence, setErrorRecurrence ] = useState(false)
    const [ helpersRecurrence, setHelpersRecurrence ] = useState([])

    const [ recurrenceType, setRecurrenceType ] = useState(RECURRENCE_THIS)

    const [ rrule, setRrule ] = useState({})
    const [ singleUpdate, setSingleUpdate ] = useState("1")

    const [ activeMessages, setActiveMessages ] = useState(false)
    const [ messages, setMessages ] = useState([])
    const [ errorMessages, setErrorMessages ] = useState(false)
    const [ helpersMessages, setHelpersMessages ] = useState([])

    // https://petlove.slack.com/archives/C0664B149T5/p1701455753631139
    const [ hasSubmited, setHasSubmited ] = useState(true)

    const [ hideServiceAlert, setHideServiceAlert ] = useState(false)

    const [ expandedInfo, setExpandedInfo ] = useState(false)

    const [ newTag, setNewTag ] = useState(null)

    const owner = patient?.get("owner")

    const total = services?.reduce((accumulator, object) => {
        return accumulator + parseFloat(object.value);
    }, 0);

    const validateOnTouch = () => {
        if (hasSubmited) {
            validate()
        }
    }

    const validateService = () => {
        let isValid = true
        if (service) {
            const newService = {...service}
            if (service.serviceName) {
                newService.errorServiceName = false
                newService.helperServiceName = mandatoryField
            } else {
                isValid = false
                newService.errorServiceName = true
                newService.helperServiceName = "O serviço é obrigatório"
            }
            if (service.value > 0) {
                newService.errorValue = false
                newService.helperValue = mandatoryField
            } else {
                isValid = false
                newService.errorValue = true
                newService.helperValue = "O valor é obrigatório"
            }
            setService(newService)
        }
        return isValid
    }

    const validate = () => {
        let isValid = true

        const startDocumentDate = dayjs(startDate)
        const endDocumentDate = dayjs(endDate)
        const nextFiveYears = dayjs().add(5, 'years')
        const previousFiveYears = dayjs().subtract(5, 'years')
        if (!startDocumentDate.isValid()) {
            isValid = false
            if (validateTime(startTime)) {
                setErrorPeriod(true)
                setHelperPeriod("A data inicial não é válida")
            } else {
                setErrorPeriod(true)
                setHelperPeriod("O horário de início não é válido")
            }
        } else if (!endDocumentDate.isValid()) {
            isValid = false
            setErrorPeriod(true)
            setHelperPeriod("O horário de término não é válido")
        } else if (startDocumentDate < previousFiveYears) {
            isValid = false
            setErrorPeriod(true)
            setHelperPeriod("A data inicial não pode ser menor que 5 anos")
        } else if (startDocumentDate > nextFiveYears) {
            isValid = false
            setErrorPeriod(true)
            setHelperPeriod("A data inicial não pode ser maior que 5 anos")
        } else if (startDocumentDate > endDocumentDate) {
            isValid = false
            setErrorPeriod(true)
            setHelperPeriod("A data inicial não pode ser maior que a data final")
        } else {
            setErrorPeriod(false)
            setHelperPeriod(mandatoryField)
        }

        if (title) {
            setErrorTitle(false)
            setHelperTitle(mandatoryField)
        } else {
            isValid = false
            setErrorTitle(true)
            setHelperTitle("O título é obrigatório")
        }

        if (professional?.id) {
            setErrorProfessional(false)
            setHelperProfessional(mandatoryField)
        } else {
            isValid = false
            setErrorProfessional(true)
            setHelperProfessional("É necessário um profissional para o evento")
        }

        if (tags?.length > 0) {
            setErrorTags(false)
            setHelperTags(mandatoryField)
        } else {
            isValid = false
            setErrorTags(true)
            setHelperTags("Ao menos uma tag deve ser informada")
        }

        if (service) {
            if (validateService()) {
                isValid = false
                setErrorServices(true)
                setHelperServices(`É necessário salvar a ${ service.edit ? "edição" : "inclusão" } do serviço`)
            } else {
                isValid = false
                setErrorServices(true)
                setHelperServices(`Corrija os dados e salve a ${ service.edit ? "edição" : "inclusão" } do serviço`)
            }
        } else {
            setErrorServices(false)
            setHelperServices(mandatoryFields)
        }

        const recurrenceFormErrors = recurrenceForm.current?.validate([])
        if (activeRecurrence && recurrenceFormErrors?.length > 0) {
            isValid = false
            setErrorRecurrence(true)
            setHelpersRecurrence(recurrenceFormErrors)
        } else {
            setErrorRecurrence(false)
            setHelpersRecurrence([])
        }

        const messageFormErrors = messageForm.current?.validate([])
        if (activeMessages && messageFormErrors?.length > 0) {
            isValid = false
            setErrorMessages(true)
            setHelpersMessages(messageFormErrors)
        } else {
            setErrorMessages(false)
            setHelpersMessages([])
        }

        return isValid
    }

    const isValid = () => !loading && !saveLoading && !errorTitle && !errorPeriod && !errorProfessional && !errorTags && !errorServices && !errorRecurrence && !errorMessages

    const fieldsWithErrorText = () => {
        let errors = []
        if (loading) {
            errors.push("Estamos procurando o evento")
        }
        if (saveLoading) {
            errors.push("Salvando o evento")
        }
        if (error?.message) {
            errors.push(error.message)
        }
        if (errorPeriod) {
            errors.push("Data do evento")
        }
        if (errorTitle) {
            errors.push("Título do evento")
        }
        if (errorProfessional) {
            errors.push("Profissional responsável")
        }
        if (errorTags) {
            errors.push("Tags")
        }
        if (errorServices) {
            errors.push("Serviço")
        }
        if (errorRecurrence) {
            errors.push("Recorrência do evento")
        }
        if (errorMessages) {
            errors.push("Envio automático de lembrete")
        }
        if (!permissions?.check(ACL.CALENDAR, ACL.Rule.EDIT, calendar)) {
            errors.push("Acesso negado")
        }
        return errors
    }

    const save = () => {
        setHasSubmited(true)
        return new Promise((resolve, reject) => {
            if (validate()) {
                setSaveLoading(true)
                Parse.Cloud.run("saveCalendarForClinic", {
                    data: {
                        objectId: update ? calendar.id : null,
                        title: title,
                        status: status,
                        tagsId: tags.map(tag => tag.id),
                        startDate: getAmericaFortalezaDate(startDate),
                        endDate: getAmericaFortalezaDate(endDate),
                        recurrence: activeRecurrence ? recurrenceForm.current?.rrule() : undefined,
                        services: services.map(service => ({
                            objectId: service.objectId,
                            serviceId: service.serviceId,
                            serviceName: service.serviceName,
                            ...(service.categoryName ? { categoryName: service.categoryName } : {}),
                            value: service.value,
                            notes: service.notes
                        })),
                        notes: notes,
                        userId: professional.id,
                        messages: activeMessages ? messageForm.current?.schedule() || [] : undefined,
                    },
                    recurrenceType: recurrenceType,
                    clinicId: clinic.objectId,
                    patientId: patient.id,
                    appVersionName: APP_VERSION,
                    deviceType: APP_DEVICE,
                    appContentType: APP_NAME
                })
                .then(calendars => {
                    setCalendar(calendars[0])
                    addEvent("Calendar__getList")
                    resolve(calendars)
                })
                .catch(error => {
                    setSaveLoading(false)
                    console.error(error)
                    Swal.fire(
                        'Desculpe',
                        `Ocorreu algum erro ao tentar ${update ? "editar" : "cadastrar"} o evento`,
                        'error'
                    )
                    reject()
                })
            } else {
                reject()
            }
        })
    }

    const getCalendarOrError = () => {
        if (permissions?.check(ACL.CALENDAR, ACL.Rule.VIEW)) {
            if (update) {
                setLoading(true)
                setError(null)

                getCalendar(id_agendamento, patient)
                .then(_calendar => {
                    setCalendar(_calendar)
                    setTitle(_calendar.get("title"))
                    setStartDateText(dayjs(setAmericaFortalezaTimezone(_calendar.get("startDate"))).format("DD/MM/YYYY"))
                    setStartTime(dayjs(setAmericaFortalezaTimezone(_calendar.get("startDate"))).format("HH:mm"))
                    setEndTime(dayjs(setAmericaFortalezaTimezone(_calendar.get("endDate"))).format("HH:mm"))
                    setNotes(_calendar.get("notes"))
                    setProfessional(_calendar.get("user"))
                    setStatus(_calendar.get("status"))
                    setTags(_calendar.get("tags"))
                    setServices(_calendar.get("services"))
                    if (_calendar.get("recurrence")) {
                        try {
                            var activeRecurrence = true
                            const rule = RRule.fromString(_calendar.get("recurrence"))
                            const allDates = rule.all((_, i) => i <= 100)
                            if (rule?.options?.count) {
                                const currentCount = allDates.findIndex(date => date?.getTime() == _calendar.get("startDate")?.getTime())
                                if (currentCount != -1) {
                                    rule.options.count -= currentCount
                                }
                                if (rule.options.count == 1)
                                    activeRecurrence = false // não exibe recorrência com 0 execuções
                            }
                            if (activeRecurrence) {
                                setRrule(rule?.options)
                                setActiveRecurrence(true)
                            }
                        } catch(error){
                            console.error(error)
                        }
                    }
                    setLoading(false)
                })
                .catch(error => {
                    Swal.fire(
                        'Desculpe',
                        error,
                        'error'
                    )
                    .then(_ => {
                        setLoading(false)
                        setError({
                            message: error,
                            function: "getCalendarOrError"
                        })
                    })
                })
            } else {
                let newCalendar = new Parse.Object("MRCalendar")
                newCalendar.setACL(clinic.ACL)
                newCalendar.set("clinic", clinic.object)
                newCalendar.set("createdBy", Parse.User.current())
                newCalendar.set("createdApp", APP_NAME)
                newCalendar.set("createdDevice", APP_DEVICE)
                newCalendar.set("createdAppVersion", APP_VERSION)
                newCalendar.set("isDeleted", false)
                newCalendar.set("patient", patient)
                const owner = patient?.get("owner")
                setTitle(owner?.id ? `${owner?.get("name")} (${patient?.get("name")})` : "")
                setCalendar(newCalendar)
                setLoading(false)
            }
        }
    }

    useEffect(() => {
        validateOnTouch()
    }, [
        title, status, startDate, startTime, endDate, endTime, professional,
        JSON.stringify(tags.map(tag => ({ objectId: tag.id }))),
        JSON.stringify(services.map(service => ({ serviceName: service.serviceName, value: service.value, notes: service.notes }))),
        JSON.stringify(service || {})
    ])

    useEffect(() => {
        validateService()
    }, [
        JSON.stringify(service || {})
    ])

    useEffect(() => {
        if (calendar && !permissions?.check(ACL.CALENDAR, ACL.Rule.EDIT, calendar)) {
            permissions?.error()
        }
    }, [ calendar ])

    useEffect(() => {
        if (patient) {
            getCalendarOrError()
        }
    }, [ patient ])

    const initializeOrError = () => {
        setLoading(true)

        Promise.all([
            getPatient(id, clinic.object, ["owner"]),
            getTags(clinic.object),
            getProfessionals(clinic.object),
            getServices(clinic.object)
        ])
        .then(([
            _patient,
            _tags,
            _professionals,
            _services
        ]) => {
            setPatient(_patient)
            setTagsOptions(_tags)
            const selectedTag = getDefaultTagFromTags(_tags) || _tags[0]
            if (selectedTag) {
                setTags([selectedTag])
            }
            setProfessionalsOptions(_professionals)
            setServicesOptions(_services)

            const _professional = _professionals.filter(_user => _user.id == state?.professionalId)?.[0] || ""
            setProfessional(_professional)
        })
        .catch(error => {
            console.error(error)
            Swal.fire(
                'Desculpe',
                error,
                'error'
            )
            .then(_ => {
                setLoading(false)
                setError({
                    message: error,
                    function: "initializeOrError"
                })
            })
        })
    }

    useEffect(() => {
        if (calendar) {
            const messageTemplate = "CALENDAR_REMINDER"
            const messageTemplates = [
                {
                    title: "Enviar Lembrete por WhatsApp",
                    messageTypeId: "WHATSAPP",
                    messageTemplateId: `WHATSAPP_${messageTemplate}`,
                    withSeparator: true
                },
                {
                    title: "Enviar Lembrete por SMS",
                    messageTypeId: "SMS",
                    messageTemplateId: `SMS_${messageTemplate}`,
                    withSeparator: true
                },
                {
                    title: "Enviar Lembrete por E-mail",
                    messageTypeId: "EMAIL",
                    messageTemplateId: `EMAIL_${messageTemplate}`,
                    withSeparator: false
                }
            ]

            const _messages = messageTemplates.map(template => {
                let message = calendar.get("messages")?.filter(message => message.get("messageTemplateId") == template.messageTemplateId)?.[0]
                const messageParams = {
                    clinic: message?.get("clinic") || clinic.objectId,
                    owner: message?.get("owner") || owner?.id,
                    patient: message?.get("patient") || patient?.id,
                    createdFromClass: message?.get("createdFromClass") || "MRCalendar",
                    createdFromId: message?.get("createdFromId"),
                }
                return {
                    ...messageParams,
                    ...template,
                    checked: message?.id ? true : false,
                    user: Parse.User.current().id,
                    createdFromObject: {
                        startDate: dayjs(startDate).utc().format("YYYY-MM-DD HH:mm:ss")
                    },
                    toHTML: 1
                }
            })
            
            setMessages([
                {
                    clinic: clinic.objectId,
                    owner: owner?.id,
                    patient: patient?.id,
                    createdFromClass: "MRCalendar",
                    createdFromId: calendar?.id,
                    title: "Enviar Cópia Digital",
                    checked: true,
                    messageTypeId: "SEND",
                    messages: _messages
                }
            ])
            if (calendar.get("messages")?.length > 0) {
                setActiveMessages(true)
            }
        }
    }, [ calendar, startDate ])

    useEffect(() => {
        getAlertFromProfessionalAgenda(professional, clinic.object, startDate, endDate, calendar?.id)
        .then(message => setAlertProfessional(message))
    }, [ professional, startDate, endDate ])

    useEffect(() => {
        if (clinic && clinic.isLoaded && clinic.object) {
            saveOnTracker("Iniciou", "Agendamento", update ? id_agendamento : "")

            initializeOrError()
        }
    }, [ clinic ])

    useEffect(() => {
        setStartDate(validateDate(startDateText))
        setEndDate(validateDate(startDateText))
    }, [ startDateText ])

    useEffect(() => {
        if (validateTime(startTime)) {
            const [
                hours,
                minutes
            ] = startTime.split(":")
            setStartDate(dayjs(validateDate(startDateText)).hour(hours).minute(minutes).second(0).toDate())
        } else {
            setStartDate(null)
        }
    }, [ startTime ])

    useEffect(() => {
        if (validateTime(endTime)) {
            const [
                hours,
                minutes
            ] = endTime.split(":")
            setEndDate(dayjs(validateDate(startDateText)).hour(hours).minute(minutes).second(0).toDate())
        } else {
            setEndDate(null)
        }
    }, [ endTime ])

    useEffect(() => {
        if (fullTime) {
            setBackupStartTime(startTime)
            setBackupEndTime(endTime)
            setStartTime("00:00")
            setEndTime("23:59")
        } else {
            setStartTime(backupStartTime)
            setEndTime(backupEndTime)
        }
    }, [ fullTime ])

    const errors = fieldsWithErrorText()

    const durationOfCalendar = dayjs(endTime, "HH:mm").diff(dayjs(startTime, "HH:mm"), "minutes")
    const durationOfServices = services?.reduce((total, object) => {
        return total + parseFloat(object.durationInMinutes || 0);
    }, 0);
    const servicesWithDuration = services?.filter(service => service.durationInMinutes)
    const allServicesHasDuration = servicesWithDuration?.length == services?.length
    const durationOfServicesBasedOn = allServicesHasDuration ? "" : ` com base em ${servicesWithDuration?.length} dos ${services?.length} serviços selecionados`
    const documentDateByServices = dayjs(startDate).add(durationOfServices, "minutes")

    const hasEstimateOrSale = calendar?.get("sale")
    const documentType = hasEstimateOrSale?.get("documentType") == "sale" ? "venda" : "orcamento"

    return (
        <VSDrawer
            id="calendar-form"
            width="50%"
            title={update ? "Editar Evento" : "Novo Evento"}
            errors={errors}
            onSubmit={save}
            onCancel={() => history.goBack()}
            VSDrawerSuccessProps={{
                title: update ? "Alterações salvas com sucesso!" : "Cadastro realizado com sucesso!",
                text: "Escolha uma das opções abaixo para prosseguir",
                children: (
                    <div className="calendar-actions">
                        <div className="row g-3">
                            <div className="col-12">
                                <button
                                    className="btn-outline-submit btn-square w-100 d-block"
                                    onClick={() => history.replace(`/agenda`)}
                                >
                                    Ir para a agenda
                                </button>
                            </div>
                            { isPro && (
                                <>
                                    <div className="col-12">
                                        <button
                                            className="btn-link text-center cursor-pointer w-100"
                                            onClick={() => history.replace(hasEstimateOrSale ? `/compartilhar/${hasEstimateOrSale?.id}` : `/${documentType}/${ patient.id }/novo/agenda/${calendar.id}`, {
                                                redirectTo: "/agenda"
                                            })}
                                        >
                                            { hasEstimateOrSale ? (
                                                `Ver ${hasEstimateOrSale == "sale" ? "venda" : "orçamento"}`
                                            ) : (
                                                "Gerar orçamento"
                                            )}
                                        </button>
                                    </div>
                                    <div className="col-12">
                                        OU
                                    </div>
                                </>
                            )}
                            <div className="col-12">
                                <button
                                    className="btn-submit btn-square w-100"
                                    onClick={() => history.replace(`/animal/selecionar/agenda`)}
                                >
                                    Cadastrar um novo evento
                                </button>
                            </div>
                        </div>
                    </div>
                )
            }}
        >
            <PatientQueryToggle
                selected={patient?.id}
                patient={patient}
                onClick={() => history.push(`/animal/selecionar/agenda`)}
            />
            {loading ? (
                <div className="row data-loading">
                    <div className="col">
                        <CircularProgress />
                    </div>
                </div>
            ) : error?.message ? (
                <VSError
                    message={error.message}
                    onClose={() => {
                        switch (error.function) {
                            case "initializeOrError":
                                initializeOrError()
                                break
                            case "getCalendarOrError":
                                getCalendarOrError()
                                break
                            default:
                        }
                    }}
                />
            ) : (
                <>
                    <div className="row g-3 mt-2">
                        <div className="col">
                            <VSAccordionSimple
                                expanded={true}
                                header={(_) => (
                                    "Dados do evento"
                                )}
                            >
                                <div className="row g-3 mb-3">
                                    <div className="col-12">
                                        <TextField
                                            placeholder="Ex: Banho e Tosa da Luna"
                                            label="Título do evento"
                                            value={title}
                                            error={errorTitle}
                                            helperText={helperTitle}
                                            onChange={({ target: { value }}) => setTitle(value)}
                                            size="small"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </div>
                                    <div className="col-12 col-lg-6">
                                        <Autocomplete
                                            value={professional}
                                            options={professionalsOptions}
                                            getOptionLabel={(option) => option && option.id ? option.get("fullName") : ""}
                                            isOptionEqualToValue={(option) => option.id === professional?.id}
                                            onChange={(_, selected) => setProfessional(selected)}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    placeholder="Ex: João da Silva"
                                                    label="Profissional responsável"
                                                    error={errorProfessional}
                                                    helperText={!errorProfessional && alertProfessional ? <span className="text-warning">{ alertProfessional }</span> : helperProfessional}
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                />
                                            )}
                                            openOnFocus
                                        />
                                    </div>
                                    <div className="col-12 col-lg-6">
                                        <a target="_blank" href={`/agenda?user=${professional?.id}&date=${dayjs(startDateText, "DD/MM/YYYY").format("YYYY-MM-DD")}`} className={`btn-outline-submit btn-submit btn-square${!professional?.id || !dayjs(startDateText, "DD/MM/YYYY").isValid() ? " btn-disabled" : ""}`}>
                                            <IconCalendarCheck width={24} height={24} />
                                            Ver agenda do responsável
                                        </a>
                                    </div>
                                </div>
                                <div className="row g-3 mb-3">
                                    <div className="col-12 col-lg-4 order-1">
                                        <DateInput
                                            placeholder="00/00/0000"
                                            label="Data do evento"
                                            value={startDateText}
                                            error={errorPeriod}
                                            helperText={!errorPeriod && helperPeriod}
                                            onChange={(value) => setStartDateText(value)}
                                            size="small"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </div>
                                    <div className="col-6 col-lg-4 order-2">
                                        <Autocomplete
                                            value={startTime}
                                            options={timeOptions}
                                            onChange={(_, selected) => setStartTime(selected)}
                                            onInputChange={(event) => setStartTime(event?.target?.value || startTime)}
                                            isOptionEqualToValue={(option) => option == startTime}
                                            renderInput={(params) => (
                                                <TextFieldMask
                                                    {...params}
                                                    type="tel"
                                                    mask={"00:00"}
                                                    placeholder="00:00"
                                                    label="Início às"
                                                    error={errorPeriod}
                                                    helperText={!errorPeriod && helperPeriod}
                                                    size="small"
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                            )}
                                            disableClearable
                                            disabled={fullTime}
                                            openOnFocus
                                            freeSolo
                                        />
                                    </div>
                                    <div className="col-6 col-lg-4 order-3">
                                        <Autocomplete
                                            value={endTime}
                                            options={timeOptions}
                                            onChange={(_, selected) => setEndTime(selected)}
                                            onInputChange={(event) => setEndTime(event?.target?.value || endTime)}
                                            isOptionEqualToValue={(option) => option == endTime}
                                            renderInput={(params) => (
                                                <TextFieldMask
                                                    {...params}
                                                    type="tel"
                                                    mask={"00:00"}
                                                    placeholder="00:00"
                                                    label="Termina às"
                                                    error={errorPeriod}
                                                    helperText={!errorPeriod && helperPeriod}
                                                    size="small"
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                            )}
                                            disableClearable
                                            disabled={fullTime}
                                            openOnFocus
                                            freeSolo
                                        />
                                    </div>
                                    { errorPeriod && (
                                        <div className="col-12 mt-0 order-4 order-lg-5">
                                            <p className="text-error">
                                                { helperPeriod }
                                            </p>
                                        </div>
                                    )}
                                    <div className="col-12 col-lg-4 mt-1 mt-lg-3 order-5 order-lg-4">
                                        <VSCheckbox
                                            label="Dia inteiro"
                                            position="end"
                                            checked={fullTime}
                                            onChange={(checked) => {
                                                setFullTime(checked)
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="row g-3">
                                    <div className="col-12 col-md-4">
                                        <Autocomplete
                                            value={status}
                                            options={statusOptions}
                                            getOptionLabel={(option) => getSelectedOptionLabel(option, statusOptions, status)}
                                            getOptionDisabled={(option) => option?.disabled || false}
                                            isOptionEqualToValue={(option, value) => option.value == status}
                                            onChange={(_, selected) => setStatus(selected?.value || '')}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    placeholder="Status do evento"
                                                    helperText={mandatoryField}
                                                    label="Status"
                                                    size="small"
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                            )}
                                            disableClearable
                                            openOnFocus
                                        />
                                    </div>
                                    <div className="col-12 col-md-8">
                                        <Autocomplete
                                            value={tags}
                                            options={tagsOptions}
                                            getOptionLabel={(option) => option && option.id ? option.get("name") : ""}
                                            isOptionEqualToValue={(option) => tags.map(tag => tag.id).indexOf(option?.id)  != -1}
                                            filterOptions={(options, { inputValue }) => {
                                                const filtered = matchSorter(options, inputValue);

                                                // Suggest the creation of a new value
                                                if (inputValue !== '') {
                                                    filtered.push(inputValue);
                                                }

                                                return filtered;
                                            }}
                                            renderOption={(props, option) => (
                                                <li {...props}>
                                                    { option.id ? (
                                                        <>
                                                            <span style={{
                                                                width: "16px",
                                                                height: "16px",
                                                                marginRight: "8px",
                                                                backgroundColor: option.get("color"),
                                                                borderRadius: "50%"
                                                            }}
                                                            ></span>
                                                            { option.get("name") }
                                                        </>
                                                    ) : (
                                                        `Criar "${option}"`
                                                    )}
                                                </li>
                                            )}
                                            renderTags={() => null}
                                            onChange={(_, selected) => {
                                                const currentValue = selected[selected.length - 1]
                                                if (currentValue.id) {
                                                    setTags(selected)
                                                } else {
                                                    setNewTag(currentValue)
                                                }
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    placeholder="Busque e selecione uma tag"
                                                    label={`Tag${ tags.length > 1 ? "s" : "" }`}
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <IconSearch width="15px" height="20px" fill="#DDDDDD" />
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                    error={errorTags}
                                                    helperText={helperTags}
                                                    size="small"
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                            )}
                                            noOptionsText="Nenhuma opção"
                                            disableClearable
                                            openOnFocus
                                            multiple
                                        />
                                    </div>
                                    { newTag && (
                                        <div className="col-12">
                                            <TagForm
                                                defaultName={newTag}
                                                onSave={(object) => {
                                                    const newTags = [...tags]
                                                    newTags.push(object)
                                                    setTags(newTags)
                                                    setNewTag(null)

                                                    const newTagOptions = [...tagsOptions]
                                                    newTagOptions.push(object)
                                                    setTagsOptions(newTagOptions)
                                                }}
                                                onCancel={() => setNewTag(null)}
                                            />
                                        </div>
                                    )}
                                </div>
                            </VSAccordionSimple>
                        </div>
                        <div className="col-12 calendar-tags">
                            <BoxDropdown
                                title={`${showTags ? "Ocultar" : "Ver"} Tags`}
                                checked={showTags}
                                onToggle={(checked) => setShowTags(checked)}
                            >
                                <div className="row g-2">
                                    { tags?.length > 0 ? (
                                        tags.map((tag, index) => (
                                            <div className="col-auto" key={index}>
                                                <Chip
                                                    color="primary"
                                                    size="small"
                                                    className="px-1"
                                                    label={tag?.get("name")}
                                                    onDelete={() => {
                                                        const newTags = [...tags]
                                                        newTags.splice(index, 1)
                                                        setTags(newTags)
                                                    }}
                                                    style={{
                                                        backgroundColor: tag?.get("color")
                                                    }}
                                                />
                                            </div>
                                        ))
                                    ) : (
                                        "Nenhuma tag selecionada"
                                    )}
                                </div>
                            </BoxDropdown>
                        </div>
                    </div>
                    <hr className="my-3" />
                    <div className="row">
                        <div className="col">
                            <VSAccordionSimple
                                expanded={true}
                                header={(_) => (
                                    "Serviços a serem realizados"
                                )}
                            >
                                <div className="row g-3 mb-3">
                                    { servicesWithDuration?.length > 0 && durationOfServices != durationOfCalendar && !hideServiceAlert && (
                                        <div className="col-12">
                                            <div className="calendar-alert">
                                                <div className="row g-3 align-items-center">
                                                    <div className="col-auto">
                                                        <IconClock width={16} height={16} fill="#CC6E00" />
                                                    </div>
                                                    <div className="col px-0">
                                                        O tempo recomendado para este atendimento{durationOfServicesBasedOn} é de {showMinutesAsTimeFormatted(durationOfServices)}.
                                                        { allServicesHasDuration && documentDateByServices.format("YYYYMMDD") == dayjs(startDate).format("YYYYMMDD") && (
                                                            <div
                                                                className="calendar-alert-link"
                                                                onClick={() => {
                                                                    setEndTime(documentDateByServices.format("HH:mm"))
                                                                }}
                                                            >
                                                                Alterar horário
                                                            </div>
                                                        ) }
                                                    </div>
                                                    <div className="col-auto">
                                                        <IconClose
                                                            width={24}
                                                            height={24}
                                                            fill="#666666"
                                                            className="cursor-pointer"
                                                            onClick={() => {
                                                                setHideServiceAlert(true)
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col">
                                                <div className="input-title">
                                                    Serviços a Serem Realizados
                                                </div>
                                            </div>
                                            { services.length > 0 && (
                                                <div className="col-auto">
                                                    <div className="row">
                                                        <div className="col">
                                                            <span className="total-services">
                                                                { services.length } serviço{ services.length > 1 ? "s" : ""}
                                                            </span>
                                                        </div>
                                                        <div className="col-auto">
                                                            Valor Total: { formattedCurrency(total) }
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    { service ? (
                                        <div className="col-12">
                                            <div className="vsbox-item">
                                                <div className="row g-3 mb-3 align-items-center">
                                                    <div className="col">
                                                        <h4 className="mb-0">
                                                            Serviço {showArrayIndex(service.index + 1)}
                                                        </h4>
                                                    </div>
                                                </div>
                                                <div className="row g-3 align-items-center">
                                                    <div className="col-12 col-lg-6">
                                                        <div className="row">
                                                            <div className="col-12 mb-1">
                                                                <div className="input-subtitle">
                                                                    Serviço {showArrayIndex(service.index + 1)}
                                                                </div>
                                                            </div>
                                                            <div className="col-12">
                                                                {"objectId" in service ? (
                                                                    <Autocomplete
                                                                        value={service.serviceName}
                                                                        options={servicesOptions}
                                                                        getOptionLabel={(option) => option && option.id ? option.get("serviceName") : option}
                                                                        isOptionEqualToValue={(option) => option.id === service?.objectId}
                                                                        onChange={(_, selected) => setService({
                                                                            ...service,
                                                                            objectId: selected.id,
                                                                            serviceId: selected.get("serviceId"),
                                                                            serviceName: selected.get("serviceName"),
                                                                            categoryName: selected.get("category")?.get("categoryName"),
                                                                            value: selected.get("value"),
                                                                            durationInMinutes: selected.get("durationInMinutes")
                                                                        })}
                                                                        renderInput={(params) => (
                                                                            <TextField
                                                                                {...params}
                                                                                placeholder="Ex: Banho e Tosa"
                                                                                error={service.errorServiceName}
                                                                                helperText={service.helperServiceName}
                                                                                size="small"
                                                                                variant="outlined"
                                                                                fullWidth
                                                                            />
                                                                        )}
                                                                        disableClearable
                                                                        openOnFocus
                                                                    />
                                                                ) : (
                                                                    <TextField
                                                                        value={service.serviceName}
                                                                        onChange={({ target: { value } }) => setService({
                                                                            ...service,
                                                                            serviceName: value
                                                                        })}
                                                                        placeholder="Ex: Banho e Tosa"
                                                                        error={service.errorServiceName}
                                                                        helperText={service.helperServiceName}
                                                                        size="small"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                    />
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-lg-3">
                                                        <div className="row">
                                                            <div className="col-12 mb-1">
                                                                <div className="input-subtitle">
                                                                    Valor do Serviço {showArrayIndex(service.index + 1)}
                                                                </div>
                                                            </div>
                                                            <div className="col-12">
                                                                <VSTextFieldCurrency
                                                                    value={parseFloat(service.value).toFixed(2)}
                                                                    onChangeValue={(event, originalValue, maskedValue) => setService({
                                                                        ...service,
                                                                        value: originalValue
                                                                    })}
                                                                    InputElement={(
                                                                        <TextField
                                                                            InputProps={{
                                                                                inputProps: { min: 0 }
                                                                            }}
                                                                            placeholder="R$ 0,00"
                                                                            error={service.errorValue}
                                                                            helperText={service.helperValue}
                                                                            size="small"
                                                                            variant="outlined"
                                                                            fullWidth
                                                                        />
                                                                    )}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-lg-3">
                                                        <div className="row">
                                                            <div className="col-12 mb-1">
                                                                <div className="input-subtitle">
                                                                    Tempo de Duração
                                                                </div>
                                                            </div>
                                                            <div className="col-12">
                                                                <p style={{ color: "#666666", margin: "12px 0", height: "39px" }}>
                                                                    { getTimeDescriptionByMinutes(service.durationInMinutes) }
                                                                    <VSTooltip
                                                                        placement="top-end"
                                                                        PopperProps={{ className: "MuiTooltip-popper vaccine-tooltip" }}
                                                                        title="Você pode adicionar ou editar o tempo do serviço no seu cadastro."
                                                                    >
                                                                        <IconQuestionT className="ms-1" width="16" height="16" fill="var(--vsprontuario-gray-color)" />
                                                                    </VSTooltip>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="row">
                                                            <div className="col-12 mb-1">
                                                                <div className="input-subtitle">
                                                                    Observações do Serviço {showArrayIndex(service.index + 1)}
                                                                </div>
                                                            </div>
                                                            <div className="col-12">
                                                                <TextField
                                                                    placeholder="Escreva aqui observações gerais sobre o serviço"
                                                                    value={service.notes}
                                                                    onChange={({ target: { value } }) => setService({
                                                                        ...service,
                                                                        notes: value
                                                                    })}
                                                                    helperText={optionalField}
                                                                    size="small"
                                                                    variant="outlined"
                                                                    multiline
                                                                    fullWidth
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="row g-3 justify-content-end">
                                                            <div className="col-auto">
                                                                <button
                                                                    className="vsbox-btn vsbox-btn-primary d-inline-block"
                                                                    onClick={() => {
                                                                        setService(null)
                                                                    }}
                                                                >
                                                                    Cancelar
                                                                </button>
                                                            </div>
                                                            <div className="col-auto">
                                                                <button
                                                                    className="btn-submit btn-square d-inline-block"
                                                                    onClick={() => {
                                                                        if (validateService()) {
                                                                            const newServices = [...services]
                                                                            delete service.edit
                                                                            newServices[service.index] = service
                                                                            setServices(newServices)
                                                                            setService(null)
                                                                        }
                                                                    }}
                                                                >
                                                                    Salvar
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <>
                                            { services.map((service, index) => (
                                                <div className="col-12" key={index}>
                                                    <div className="vsbox-item">
                                                        <div className="row g-3 mb-3 align-items-center">
                                                            <div className="col">
                                                                <h4 className="mb-0">{ service.serviceName }</h4>
                                                                <div>Valor do Serviço: { formattedCurrency(service.value) }</div>
                                                            </div>
                                                            <div className="col-auto">
                                                                <div className="row align-items-center justify-content-center">
                                                                    <div className="col col-md-auto">
                                                                        <button
                                                                            className={`vsbox-btn vsbox-btn-dark`}
                                                                            onClick={() => setService({
                                                                                ...service,
                                                                                edit: true,
                                                                                index: index
                                                                            })}
                                                                        >
                                                                            <IconEdit width="24" height="24" fill={"var(--vsprontuario-primary-color-dark)"} />
                                                                        </button>
                                                                    </div>
                                                                    <div className="col col-md-auto ps-0">
                                                                        <button
                                                                            className={`vsbox-btn vsbox-btn-alert`}
                                                                            onClick={() => {
                                                                                const newServices = [...services]
                                                                                newServices.splice(index, 1)
                                                                                setServices(newServices)
                                                                            }}
                                                                        >
                                                                            <IconTrash width="24" height="24" fill={"var(--vsprontuario-primary-color)"} />
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="vsbox-separator"></div>
                                                        <div className="row">
                                                            <div className="col-12 vsbox--info">
                                                                <VSAccordionSimple
                                                                    expanded={true}
                                                                    header={(expanded) => (
                                                                        `Ver ${ expanded ? "menos" : "mais" } informações`
                                                                    )}
                                                                >
                                                                    <div className="vsbox-item" key={index}>
                                                                        <div className="row vsbox-item-text">
                                                                            <div className="col-12">
                                                                                <b>Tempo de duração do serviço:</b> { getTimeDescriptionByMinutes(service.durationInMinutes) }
                                                                            </div>
                                                                        </div>
                                                                        <div className="vsbox-separator my-2"></div>
                                                                        <div className="row vsbox-item-text">
                                                                            <div className="col-12">
                                                                                <b>Observações:</b> { service.notes || "Não informado" }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </VSAccordionSimple>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )) }
                                            <div className="col-12">
                                                <div className="row g-3 align-items-center">
                                                    <div className="col-12 col-lg">
                                                        <button
                                                            className="vsbox-btn vsbox-btn-primary w-100"
                                                            onClick={() => {
                                                                setService({
                                                                    index: services.length,
                                                                    objectId: "",
                                                                    serviceId: "",
                                                                    serviceName: "",
                                                                    categoryName: "",
                                                                    quantity: 1,
                                                                    value: "",
                                                                    durationInMinutes: null,
                                                                    notes: ""
                                                                })
                                                            }}
                                                        >
                                                            <IconAddO width="14" height="14" className="me-2" />
                                                            Adicionar Serviços Cadastrados
                                                        </button>
                                                    </div>
                                                    <div className="col-12 col-lg">
                                                        <button
                                                            className="btn-submit btn-square w-100"
                                                            onClick={() => {
                                                                setService({
                                                                    index: services.length,
                                                                    serviceName: "",
                                                                    quantity: 1,
                                                                    value: "",
                                                                    notes: ""
                                                                })
                                                            }}
                                                        >
                                                            <IconAddO width="14" height="14" className="me-2" fill="#FFFFFF" />
                                                            Adicionar Cadastro Manual
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    { errorServices && (
                                        <p className="text-error">
                                            { helperServices }
                                        </p>
                                    )}
                                </div>
                            </VSAccordionSimple>
                        </div>
                    </div>
                    <hr className="my-2" />
                    <div className="row">
                        <div className="col">
                            <VSAccordionSimple
                                expanded={true}
                                header={(_) => (
                                    "Dados do lembrete"
                                )}
                            >
                                <div className="row align-items-center mb-2">
                                    <div className="col-12 mb-2">
                                        <b>Deseja lembrar o tutor perto da data? <span className="optional">(Opcional)</span></b>
                                    </div>
                                    <div className="col-12">
                                        <p className="mb-4">
                                            Configure um lembrete que será enviado 
                                            automaticamente para o(a) tutor(a) para 
                                            lembrá-lo(a) deste evento.
                                        </p>
                                    </div>
                                    <div className="col-12 mb-3">
                                        <BoxDropdown
                                            title="Ativar envio automático de lembrete"
                                            checked={activeMessages}
                                            onChecked={(checked) => setActiveMessages(checked)}
                                        >
                                            <div className="row align-items-center mb-2">
                                                <div className="col-12">
                                                    <div className="row mb-3">
                                                        <div className="col-12 col-md pe-md-1">
                                                            <VSAccordionCard
                                                                expanded={expandedInfo}
                                                                onChange={(expanded) => setExpandedInfo(expanded)}
                                                                icon={<IconText width="20px" height="20px" />}
                                                                title="Textos Enviados"
                                                                text={<>O texto dos lembretes que serão enviados tanto por 
                                                                    WhatsApp, como E-mail e SMS para o(a) tutor(a) não 
                                                                    podem ser editados</>}
                                                            />
                                                        </div>
                                                        <div className="col-12 col-md px-md-1 mt-3 mt-md-0">
                                                            <VSAccordionCard
                                                                expanded={expandedInfo}
                                                                onChange={(expanded) => setExpandedInfo(expanded)}
                                                                icon={<IconSchedule width="20px" height="20px" />}
                                                                title="Horários do Envio"
                                                                text={<>As mensagens serão enviadas automaticamente <b>no período 
                                                                    da tarde do dia anterior do evento</b> para envio 
                                                                    do lembrete</>}
                                                            />
                                                        </div>
                                                        <div className="col-12 col-md ps-md-1 mt-3 mt-md-0">
                                                            <VSAccordionCard
                                                                expanded={expandedInfo}
                                                                onChange={(expanded) => setExpandedInfo(expanded)}
                                                                icon={<IconContact width="20px" height="20px" />}
                                                                title="Dados para Envio"
                                                                text={<>As mensagens serão enviadas para os dados 
                                                                    de contato que estiverem no cadastro do(a) 
                                                                    Tutor(a) no dia do envio</>}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div id="vsmessage" className="mb-3">
                                                        <div className="row align-items-center mb-2">
                                                            <div className="col-12">
                                                                <div className="color-title text-uppercase">
                                                                    Informações do Lembrete
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row g-2 align-items-center mb-2">
                                                            <div className="col-12">
                                                                <div className="color-title mb-0">
                                                                    Data de envio
                                                                </div>
                                                            </div>
                                                            <div className="col text-black">
                                                                { dayjs().add(1, "day").add(10, "minutes").isAfter(startDate) ? "Nos próximos minutos" : dayjs(startDate).subtract(1, "day").format("DD/MM/YYYY") + " no período da tarde" }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <VSMessage
                                                        ref={messageForm}
                                                        clinic={clinic.object}
                                                        owner={owner}
                                                        ownerSeparator={false}
                                                        showPersonalWhatsAppButton={false}
                                                        onChange={() => {
                                                            validateOnTouch()
                                                        }}
                                                        onResend={() => {
                                                            // setSendLoading(false)
                                                        }}
                                                        onClose={() => {
                                                            history.goBack()
                                                        }}
                                                        messages={messages}
                                                    />
                                                </div>
                                            </div>
                                        </BoxDropdown>
                                    </div>
                                    { errorMessages && (
                                        <p className="text-error">
                                            { helpersMessages.map((helper, index) => <span key={index}>{ index !== 0 ? <br/> : "" }{ helper }</span>) }
                                        </p>
                                    )}
                                </div>
                            </VSAccordionSimple>
                        </div>
                    </div>
                    <hr className="my-2" />
                    <div className="row mb-2">
                        <div className="col-12">
                            <VSAccordionSimple
                                expanded={true}
                                header={(expanded) => "Dados da recorrência"}
                            >
                                <div className="row align-items-center mb-2">
                                    <div className="col-12 mb-2">
                                        <b>Esse evento tem recorrência? <span className="optional">(Opcional)</span></b>
                                    </div>
                                    <div className="col-12">
                                        <p className="mb-4">
                                        Configure uma recorrência para esse evento. Ele será lançado automaticamente em sua agenda de acordo com a frequência que você escolher.
                                        </p>
                                    </div>
                                    { rrule.freq >= 0 && (
                                        <>
                                            <div className="col-12">
                                                <div className="input-title input-height">
                                                    Esse evento possui lançamentos futuros
                                                </div>
                                            </div>
                                            <div className="col-12 mb-3">
                                                <VSRadio
                                                    value={recurrenceType}
                                                    onChange={(_, value) => {
                                                        setRecurrenceType(value)
                                                    }}
                                                    options={[
                                                        {
                                                            label: "Desejo alterar apenas esse lançamento",
                                                            value: RECURRENCE_THIS
                                                        },
                                                        {
                                                            label: "Desejo alterar esse e os lançamentos futuros",
                                                            value: STATUS_FUTURE
                                                        }
                                                    ]}
                                                />
                                            </div>
                                        </>
                                    )}
                                    { rrule.freq >= 0 && recurrenceType == RECURRENCE_THIS ? (
                                        <div className="col-12">
                                            <p className="text-warning">Não é possível alterar a recorrência de um único lançamento</p>
                                        </div>
                                    ) : (
                                        <div className="col-12 mb-3">
                                            <VSRadio
                                                className="radio-options"
                                                horizontal
                                                options={[
                                                    {
                                                        label: "Sim",
                                                        value: true
                                                    },
                                                    {
                                                        label: "Não",
                                                        value: false
                                                    }
                                                ]}
                                                value={activeRecurrence}
                                                onChange={(_, value) => {
                                                    setActiveRecurrence(value == "true")
                                                }}
                                            />
                                            { activeRecurrence && (
                                                <div className="mt-2">
                                                    <RecurrenceForm
                                                        ref={recurrenceForm}
                                                        documentDate={startDate}
                                                        finishDate={calendar.get("finishDate")}
                                                        rrule={rrule}
                                                        onChange={validateOnTouch}
                                                    />
                                                    { errorRecurrence && (
                                                        <p className="text-error">
                                                            { helpersRecurrence.map((helper, index) => <span key={index}>{ index !== 0 ? <br/> : "" }{ helper }</span>) }
                                                        </p>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </VSAccordionSimple>
                        </div>
                    </div>
                    <hr className="my-2" />
                    <div className="row">
                        <div className="col">
                            <VSAccordionSimple
                                expanded={true}
                                header={(_) => (
                                    "Observações do Evento"
                                )}
                            >
                                <div className="row mb-3">
                                    <div className="col-12">
                                        <TextField
                                            placeholder="Escreva aqui observações gerais sobre o evento."
                                            value={notes}
                                            onChange={({ target: { value } }) => setNotes(value)}
                                            helperText={<small>Campo Opcional. As observações são anotações particulares. <b>Elas nunca serão compartilhadas com o tutor</b></small>}
                                            size="small"
                                            variant="outlined"
                                            multiline
                                            fullWidth
                                        />
                                    </div>
                                </div>
                            </VSAccordionSimple>
                        </div>
                    </div>
                </>
            )}
        </VSDrawer>
    )
}

const mapStateToProps = state => {
    return {
        pro: state.featuresPro,
        clinic: state.clinic,
        permissions: state.permissions
    }
}

export default connect(mapStateToProps)(CalendarForm)