import { APP_DEVICE, APP_NAME, APP_VERSION } from "../../../../constants"
import { dayjs, stringifyArray } from "../../../../utils"

export const deleteReturn = (objReturn, callback) => {
    Swal.fire({
        title: 'Tem certeza?',
        text: "Você tem certeza que deseja excluir esse retorno? Essa ação não poderá ser desfeita.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, quero excluir!',
        cancelButtonText: 'Não, manter retorno'
    }).then((result) => {
        if (result.isConfirmed) {
            objReturn.set("isDeleted", true)
            objReturn.set("changedAt", new Date())
            objReturn.set("changedBy", Parse.User.current())
            objReturn.set("changedApp", APP_NAME)
            objReturn.set("changedDevice", APP_DEVICE)
            objReturn.set("changedAppVersion", APP_VERSION)
            objReturn.save()
            .then(_ => {
                Swal.fire(
                    'Excluído!',
                    'O retorno foi excluído com sucesso.',
                    'success'
                )
                .then(_ => {
                    callback && callback()
                })
            })
            .catch(error => {
                console.error(error)
                Swal.fire(
                    'Desculpe',
                    'Ocorreu algum erro ao tentar excluir o retorno',
                    'error'
                )
                objReturn.revert()
            })
        }
    })
}

export const getReturn = (id, patient) => {
    return new Promise((resolve, reject) => {
        var query = new Parse.Query("Return");
        query.equalTo("clinic", patient.get("clinic"));
        query.equalTo("isDeleted", false);
        query.equalTo("patient", patient)
        query.get(id)
        .then(_object => {
            if (_object) {
                resolve(_object)
            } else {
                reject('Não encontramos o retorno informado')
            }
        }).catch(error => {
            console.error(error)
            reject('Ocorreu algum erro ao tentar buscar o retorno')
        })
    })
}

export const showReturn = (_return) => {
    return `Retorno para ${ dayjs(_return.get("returnDate")).format("DD/MM/YYYY") }`
}

export const showReturnDetails = (_return) => {
    let details = []
    if (_return.get("appointment")) {
        details.push("Consulta")
    }
    if (_return.get("vaccine")) {
        details.push("Vacina")
    }
    if (_return.get("appointmentDrug")) {
        details.push("Aplicação de Medicamento")
    }
    if (_return.get("examRequest")) {
        details.push("Resultado de Exame")
    }
    if (_return.get("surgery")) {
        details.push("Pós-cirúrgico")
    }
    if (_return.get("prescription")) {
        details.push("Entrega/Renovação de Prescrição")
    }
    if (_return.get("term")) {
        details.push("Entrega/Renovação de Termo ou Atestado")
    }
    return stringifyArray(details)
}