import React from 'react'
import { connect } from 'react-redux'

import { ReactComponent as IconEdit } from "../../../../assets/icon-primary-edit.svg"
import { ReactComponent as IconTrash } from "../../../../assets/icon-primary-trash.svg"
import { ReactComponent as IconWeight } from '../../../../assets/icon-patient-weight.svg'

import { deleteWeight, weightScoreOptions } from './Weight'
import { addEvent, dayjs, getSelectedOptionLabel, showRegisteredBy } from '../../../../utils'
import { ACL } from '../../../../constants'

import VSAccordionSimple from '../../../../components/vsAccordion/VSAccordionSimple'
import VSMedicalRecordsAudit from '../../../../components/vsMedicalRecordsAudit/VSMedicalRecordsAudit'

import './WeightView.scss'
const WeightView = (props) => {

    const {
        object,
        history,
        noDocumentDate,
        noAudit,
        permissions
    } = props

    const patient = object.get("patient")
    const isDeleted = object.get("isDeleted")

    if (!permissions?.check(ACL.WEIGHT, ACL.Rule.VIEW)) {
        permissions?.error()
    }

    return (
        <div id="patient-weight-view" className={`vsbox mb-3 ${isDeleted ? "vsbox-disabled" : ""}`}>
            <div className="vsbox-body pb-0">
                <div className="row align-items-center justify-content-md-end g-3 mb-3">
                    { !noDocumentDate && (
                        <div className="col-auto pe-0">
                            <div className="vsbox-chip">
                                { dayjs(object.get("documentDate")).format("DD/MM/YYYY") }
                            </div>
                        </div>
                    )}
                    <div className="col-12 col-md">
                        <div className="row align-items-center g-3 flex-nowrap">
                            <div className="col-auto">
                                <IconWeight width="24" height="24" fill="var(--vsprontuario-gray-color)" />
                            </div>
                            <div className="col ps-0">
                                <label className="vsbox-info text-nowrap">Tipo de Registro</label>
                                <div className="vsbox-info-text">
                                    Peso
                                </div>
                            </div>
                        </div>
                    </div>
                    { !isDeleted && (
                        <>
                            <div className="col-6 col-md-auto">
                                <label className="vsbox-info">Peso Registrado</label>
                                <div className="vsbox-info-text">{ object.get("weight") } kg</div>
                            </div>
                            <div className="col-6 col-md-auto">
                                <label className="vsbox-info">Escore Corporal</label>
                                <div className="vsbox-info-text">
                                    { object.get("score") ? `(${ object.get("score") }) ${ getSelectedOptionLabel(null, weightScoreOptions, object.get("score"))}` : "Não preenchido" }
                                </div>
                            </div>
                            <div className="col-12 col-md-auto">
                                <div className="row g-3 align-items-center justify-content-center">
                                    { permissions?.check(ACL.WEIGHT, ACL.Rule.EDIT, object) && (
                                        <div className="col col-md-auto">
                                            <button
                                                className={`vsbox-btn ${isDeleted ? "vsbox-btn-disabled" : "vsbox-btn-dark"}`}
                                                onClick={() => history.push(`/animal/${ patient.id }/peso/${ object.id }`)}
                                                disabled={isDeleted}
                                            >
                                                <IconEdit width="24" height="24" fill={isDeleted ? "var(--vsprontuario-gray-color)" : "var(--vsprontuario-primary-color-dark)"} />
                                            </button>
                                        </div>
                                    )}
                                    { permissions?.check(ACL.WEIGHT, ACL.Rule.DELETE, object) && (
                                        <div className="col col-md-auto">
                                            <button
                                                className={`vsbox-btn ${isDeleted ? "vsbox-btn-disabled" : "vsbox-btn-alert"}`}
                                                onClick={() => deleteWeight(object, () => addEvent(["Weight__getList", "History__getList"], {}))}
                                                disabled={isDeleted}
                                            >
                                                <IconTrash width="24" height="24" fill={isDeleted ? "var(--vsprontuario-gray-color)" : "var(--vsprontuario-primary-color)"} />
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </>
                    )}
                </div>
                { showRegisteredBy(object) }
                { !isDeleted && (
                    <>
                        <div className="vsbox-separator"></div>
                        <div className="row">
                            <div className="col-12 vsbox--info">
                                <VSAccordionSimple
                                    expanded={true}
                                    header={(expanded) => (
                                        `Ver ${ expanded ? "menos" : "mais" } informações`
                                    )}
                                >
                                    <div className="row">
                                        <div className="col vsbox-title">
                                            Anotações
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col mb-3 pre-line">
                                            { object.get("notes") || "Não informado" }
                                        </div>
                                    </div>
                                </VSAccordionSimple>
                            </div>
                        </div>
                    </>
                )}
                { !noAudit && (
                    <VSMedicalRecordsAudit parseObject={object} />
                )}
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        permissions: state.permissions
    };
};
  
export default connect(mapStateToProps)(WeightView)