import { useEffect, useRef, useState } from "react"
import { connect } from "react-redux"

import { CircularProgress } from "@mui/material"

import { APP_DEVICE, APP_NAME, APP_VERSION } from "../../../../constants"
import { getClinicUser } from "../../../vet/Vet"
import { getSelectedClinicUser } from "../../../../store/actions"
import { saveOnTracker } from "../../../../utils"

import { ReactComponent as IconPrimaryCheck } from "../../../../assets/icon-primary-check.svg"
import { ReactComponent as IconHistory } from "../../../../assets/icon-history.svg"

import WeekHourField from "../../../vet/fields/WeekHourField"
import VSTooltip from "../../../../components/vsTooltip/VSTooltip"

import "./ClinicSettingWorkHour.scss"
const ClinicSettingWorkHour = (props) => {

    const {
        clinic
    } = props

    const timeRef = useRef(null)

    const [ saveLoading, setSaveLoading ] = useState(false)
    const [ hasSubmited, setHasSubmited ] = useState(false)
    const [ hasSaved, setHasSaved ] = useState(false)
    const [ errors, setErrors ] = useState([])

    const validate = () => {
        return timeRef.current?.validate(true)
    }

    const fieldsWithErrorText = () => {
        setErrors(timeRef.current?.fieldsWithErrorText([]) || [])
    }

    const save = () => {
        setHasSubmited(true)
        if (validate()) {
            setSaveLoading(true)

            const object = clinic.object
            object.set("workHours", timeRef.current?.getTimes());
            object.set("changedAt", new Date())
            object.set("changedBy", Parse.User.current())
            object.set("changedApp", APP_NAME)
            object.set("changedAppVersion", APP_VERSION)
            object.set("changedDevice", APP_DEVICE)
            object.save()
            .then(_clinic => {
                afterSave(_clinic)
            })
            .catch(error => {
                console.error(error)
                Swal.fire(
                    'Oops...',
                    `Não foi possível editar a clínica, por favor, tente novamente`,
                    'error'
                )
                setSaveLoading(false)
                object.revert()
            })
        }
    }

    const afterSave = (_clinic) => {
        getClinicUser(_clinic)
        .then(clinicUser => {
            saveOnTracker("Concluiu", "Clínica", _clinic.id)
            props.dispatch(getSelectedClinicUser(clinicUser))
            setSaveLoading(false)
        })
        .catch(error => {
            console.error(error)
            Swal.fire(
                'Oops...',
                error,
                'error'
            )
            setSaveLoading(false)
        })
    }

    const validateOnTouch = () => {
        if (hasSubmited) {
            validate()
            fieldsWithErrorText()
        }
        setHasSaved(JSON.stringify(timeRef.current?.getTimes()) == JSON.stringify(clinic.object?.get("workHours") || []))
    }

    const isSingular = errors.length == 1

    return (
        <div id="clinic-setting-work-hour">
            <div className="row g-3 align-items-center justify-content-end">
                <VSTooltip
                    placement="top-start"
                    open={errors.length > 0}
                    title={
                        <div className="vsdrawer-error">
                            O{ isSingular ? "" : "s" } campo{ isSingular ? "" : "s" } abaixo 
                            est{ isSingular ? "á" : "ão" } com erro no preenchimento:
                            <ul>
                                { errors.map((error, index) => (
                                    <li key={index}>{ error }</li>
                                ))}
                            </ul>
                        </div>
                    }
                >
                    <div className="col-auto">
                        <button
                            className="btn-main-mobile btn-submit btn-square"
                            disabled={saveLoading || hasSaved || errors.length > 0}
                            onClick={() => !saveLoading && !hasSaved && save()}
                        >
                            { saveLoading ? (
                                <div style={{ textAlign: "center", padding: '36px' }}>
                                    <CircularProgress size={24} thickness={2} />
                                </div>
                            ) : hasSaved ? (
                                <>
                                    <IconPrimaryCheck id="add-icon" width="24" height="24" fill="#FFFFFF" /> Configurações Salvas
                                </>
                            ) : (
                                <>
                                    <IconHistory id="add-icon" width="18" height="18" fill="#FFFFFF" /> Salvar Alterações
                                </>
                            )}
                        </button>
                    </div>
                </VSTooltip>
                <div className="col-12">
                    <div className="vsbox">
                        <div className="vsbox-body">
                            <WeekHourField
                                ref={timeRef}
                                workHours={clinic.object?.get("workHours")}
                                onChange={validateOnTouch}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        clinic: state.clinic
    }
}

export default connect(mapStateToProps)(ClinicSettingWorkHour)