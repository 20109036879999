import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react'
import { useParams, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';

import TextField from '@mui/material/TextField';
import { CircularProgress } from '@mui/material';
import { ReactComponent as IconHeaderIA } from "../../../../assets/icon-header-ia.svg"
import { ReactComponent as IconIA } from "../../../../assets/icon-ia.svg"
import { ReactComponent as IconIAGenerate } from "../../../../assets/icon-ia-generate.svg"
import { ReactComponent as IconClose } from "../../../../assets/icon-close.svg"
import { ReactComponent as IconSimpleWarning } from "../../../../assets/icon-simple-warning.svg"
import { ReactComponent as IconPatientAppointment } from '../../../../assets/icon-patient-appointment.svg'
import { ReactComponent as IconVirtualAssistantSelect } from "../../../../assets/virtual-assistant-select.svg"
import { ReactComponent as IconVirtualAssistantDiagnosis } from "../../../../assets/icon-virtual-assistant-diagnosis.svg"

import { addEvent, dayjs, saveOnTracker, useQuery } from '../../../../utils'
import { getPatient } from '../../Patient'
import { ACL, APP_DEVICE, APP_NAME, APP_VERSION } from '../../../../constants';
import { appointmentTypeOptions, getAppointment, CONSULTA_DETALHADA, CONSULTA_PADRAO } from './Appointment';

import VSRadio from '../../../../components/vsRadio/VSRadio';
import DateInput from '../../../../components/dateInput/DateInput';
import VSDrawer from '../../../../components/vsDrawer/VSDrawer';
import VSMedicalRecordsAudit from '../../../../components/vsMedicalRecordsAudit/VSMedicalRecordsAudit';
import { PatientQueryToggle } from '../../PatientWideInfo';
import VSError from '../../../../components/vsError/VSError';
import VSEmpty from '../../../../components/vsEmpty/VSEmpty';
import VSDrawerInfo from '../../../../components/vsDrawer/VSDrawerInfo';
import VSTooltip from '../../../../components/vsTooltip/VSTooltip';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import './AppointmentForm.scss'

const PARAM_AI_DIFFERENTIAL_DIAGNOSIS = "diagnosticoDiferencial"

const AppointmentForm = (props) => {

    const {
        id,
        id_consulta
    } = useParams()

    const params = useQuery()

    const {
        history,
        clinic,
        permissions,
        pro
    } = props

    const update = id_consulta !== "novo"
    const drawerInfoRef = useRef(null)
    const AppointmentFormVirtualAssistantPreviewRef = useRef(null)
    const [ cachedRef, setCachedRef ] = useState(null)

    const [ loading, setLoading ] = useState(false)
    const [ error, setError ] = useState(null)
    const [ hasSubmited, setHasSubmited ] = useState(false)
    const [ patient, setPatient ] = useState(null)
    const [ appointment, setAppointment ] = useState(null)

    const [ appointmentType, setAppointmentType ] = useState(CONSULTA_PADRAO)

    const [ _return, set_Return ] = useState('0')
    const [ anamnesis, setAnamnesis ] = useState('')
    const [ physicalExam, setPhysicalExam ] = useState('')
    const [ diagnosis, setDiagnosis ] = useState('')
    const [ treatment, setTreatment ] = useState('')
    const [ nextStep, setNextStep ] = useState('')

    const defaultDate = dayjs()
    const [ documentDate, setDocumentDate ] = useState(defaultDate.toDate())
    const [ documentDateText, setDocumentDateText ] = useState(defaultDate.format("DD/MM/YYYY"))
    const [ errorDocumentDate, setErrorDocumentDate ] = useState(false)
    const [ helperDocumentDate, setHelperDocumentDate ] = useState(<span>Campo <b>Obrigatório</b></span>)
    const [ notes, setNotes ] = useState('')

    const [ differentialDiagnosis, setDifferentialDiagnosis ] = useState([])
    const [ hasDrawerInfoForAI, setHasDrawerInfoForAI ] = useState(pro?.access && params.get(PARAM_AI_DIFFERENTIAL_DIAGNOSIS) !== null)
    const [ AIButtonOnHover, setAIButtonOnHover ] = useState(false)
    const [ hasGeneratedAI, setHasGeneratedAI ] = useState(false)
    
    const handleDocumentDate = (_date) => {
        setDocumentDateText(_date)

        if (_date.match(/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/g)) {
            let newDate = dayjs(_date, "DD/MM/YYYY")
            if (newDate.isValid()) {
                setDocumentDate(newDate.toDate())
                return
            }
        }
        setDocumentDate(null)
    }

    const validateOnTouch = () => {
        if (hasSubmited && (documentDate || documentDateText || notes)) {
            validate()
        }
    }

    const validate = () => {
        let isValid = true
        if (loading) {
            isValid = false
        }

        if (error?.message) {
            isValid = false
        }

        const _documentDate = dayjs(documentDateText, "DD/MM/YYYY")
        if (_documentDate.isValid()) {
            if (_documentDate.format("YYYY") < 1971) {
                isValid = false
                setErrorDocumentDate(true)
                setHelperDocumentDate("A data não pode ser menor que 1971")
            } else if (_documentDate.diff(dayjs(), "years") > 5) {
                isValid = false
                setErrorDocumentDate(true)
                setHelperDocumentDate("A data não pode exceder 5 anos")
            } else {
                setErrorDocumentDate(false)
                setHelperDocumentDate(<span>Campo <b>Obrigatório</b></span>)
            }
        } else {
            isValid = false
            setErrorDocumentDate(true)
            setHelperDocumentDate("A data informada não é válida")
        }

        return isValid
    }

    const isValid = () => !loading && !errorDocumentDate

    const fieldsWithErrorText = () => {
        let errors = []
        if (loading) {
            errors.push("Salvando a consulta")
        }
        if (error?.message) {
            errors.push(error.message)
        }
        if (errorDocumentDate) {
            errors.push("Data da consulta")
        }
        if (!permissions?.check(ACL.APPOINTMENT, ACL.Rule.EDIT, appointment)) {
            errors.push("Acesso negado")
        }
        return errors
    }

    const getPatientOrError = () => {
        setLoading(true)
        setError(null)

        getPatient(id, clinic.object)
        .then(_patient => {
            setPatient(_patient)
        })
        .catch(error => {
            Swal.fire(
                'Desculpe',
                error,
                'error'
            )
            .then(_ => {
                setLoading(false)
                setError({
                    message: error,
                    function: "getPatientOrError"
                })
            })
        })
    }

    const getAppointmentOrError = () => {
        if (permissions?.check(ACL.APPOINTMENT, ACL.Rule.VIEW)) {
            saveOnTracker("Iniciou", "Consulta", update ? id_consulta : "")
            if (update) {
                setLoading(true)
                setError(null)
    
                getAppointment(id_consulta, patient, true)
                .then(_appointment => {
                    setAppointment(_appointment)
                    setDocumentDate(_appointment.get("documentDate"))
                    setDocumentDateText(dayjs(_appointment.get("documentDate")).format("DD/MM/YYYY"))
                    setAppointmentType(_appointment.get("appointmentType") ? _appointment.get("appointmentType") : CONSULTA_PADRAO)
                    setAnamnesis(_appointment.get("anamnesis"))
                    set_Return(_appointment.get("return") ? "1" : "0")
                    setPhysicalExam(_appointment.get("physicalExam"))
                    setDiagnosis(_appointment.get("diagnosis"))
                    setTreatment(_appointment.get("treatment"))
                    setNextStep(_appointment.get("nextStep"))
                    setNotes(_appointment.get("notes"))
                    setDifferentialDiagnosis(_appointment.get("differentialDiagnosis") || [])
                    setLoading(false)

                    if(pro?.access && _appointment.get("differentialDiagnosis") && _appointment.get("differentialDiagnosis").length > 0){
                        setHasDrawerInfoForAI(true)
                    }
                })
                .catch(error => {
                    console.log(error)
                    Swal.fire(
                        'Desculpe',
                        error,
                        'error'
                    )
                    .then(_ => {
                        setLoading(false)
                        setError({
                            message: error,
                            function: "getAppointmentOrError"
                        })
                    })
                })
            } else {
                let newAppointment = new Parse.Object("Appointment")
                newAppointment.setACL(clinic.ACL)
                newAppointment.set("clinic", clinic.object)
                newAppointment.set("createdBy", Parse.User.current())
                newAppointment.set("createdApp", APP_NAME)
                newAppointment.set("createdDevice", APP_DEVICE)
                newAppointment.set("createdAppVersion", APP_VERSION)
                newAppointment.set("isDeleted", false)
                newAppointment.set("patient", patient)
                setAppointment(newAppointment)
                setLoading(false)
            }
        }
    }

    const save = () => {
        return new Promise((resolve, reject) => {
            setHasSubmited(true)
            if (validate()) {
                let promise = Promise.resolve()
                if(pro?.access){
                    promise = promise.then(() => {
                        return AppointmentFormVirtualAssistantPreviewRef?.current?.onSave().then(() => {
                            setLoading(true)
                        })
                    })
                }
                
                promise.then(() => {
                    appointment.set('documentDate', documentDate);
                    appointment.set('appointmentType', appointmentType);
                    appointment.set('anamnesis', anamnesis);
                    appointment.set('return', _return == "1");
                    appointment.set('physicalExam', physicalExam);
                    appointment.set('diagnosis', diagnosis);
                    appointment.set('treatment', treatment);
                    appointment.set('nextStep', nextStep);
                    appointment.set('notes', notes);
                    if(differentialDiagnosis && differentialDiagnosis.length > 0){
                        appointment.set('differentialDiagnosis', differentialDiagnosis);
                    }
                    appointment.set("changedAt", new Date())
                    appointment.set("changedBy", Parse.User.current())
                    appointment.set("changedApp", APP_NAME)
                    appointment.set("changedDevice", APP_DEVICE)
                    appointment.set("changedAppVersion", APP_VERSION)
                    appointment.save()
                    .then(_appointment => {
                        saveOnTracker("Concluiu", "Consulta", _appointment.id)
                        addEvent("TourAppointment__registerYourFirstAppointment")
                        resolve(_appointment)
                    })
                    .catch(error => {
                        setLoading(false)
                        console.error(error)
                        Swal.fire(
                            'Desculpe',
                            `Ocorreu algum erro ao tentar ${appointment.id ? "editar" : "cadastrar"} a consulta do animal`,
                            'error'
                        )
                        update && appointment.revert()
                        reject()
                    })
                })
            } else {
                reject()
            }
        })
    }

    useEffect(() => {
        validateOnTouch()
    }, [ documentDate, documentDateText, appointmentType, anamnesis, _return, physicalExam, diagnosis, treatment, nextStep, notes ])

    useEffect(() => {
        if (appointment && !permissions?.check(ACL.APPOINTMENT, ACL.Rule.EDIT, appointment)) {
            permissions?.error()
        }
    }, [ appointment ])
    
    useEffect(() => {
        if (patient) {
            getAppointmentOrError()
        }
    }, [ patient ])

    useEffect(() => {
        drawerInfoRef?.current?.onOpen(hasDrawerInfoForAI)
    }, [ hasDrawerInfoForAI ])

    useEffect(() => {
        if (id) {
            getPatientOrError()
        }
    }, [])

    const errors = fieldsWithErrorText()
    
    return (
        <VSDrawer
            id="appointment-form" 
            width={pro?.access && hasDrawerInfoForAI ? "80%" : "60%"}
            title={update ? "Editar Consulta" : "Nova Consulta"}
            cancel={update ? <span>Cancelar Registro<span className="d-none d-md-inline"> de Consulta</span></span> : <span>Cancelar Registro<span className="d-none d-md-inline"> de Nova Consulta</span></span>}
            submit={isValid ? (update ? <span>Salvar Alterações<span className="d-none d-md-inline"> na Consulta</span></span> : <span>Salvar Registro<span className="d-none d-md-inline"> de Nova Consulta</span></span>) : <span>Preencha os campos<span className="d-none d-md-inline"> necessários</span></span>}
            errors={errors}
            onSubmit={save}
            onCancel={() => history.replace(`/animal/${patient.id}/consulta`)}
            withVSDrawerInfo={pro?.access && hasDrawerInfoForAI}
            onAfterSave={(object) => {
                history.replace(`/animal/${patient.id}/consulta`)
                addEvent("Appointment__getList", {})
            }}
            VSDrawerSuccessProps={{
                title: "Consulta salva com Sucesso",
                text: ""
            }}
            VSDrawerCancelProps={{
                title: "Tem certeza que deseja cancelar o registro de consulta?",
                confirm: "Cancelar Registro de Consulta",
                cancel: "Voltar para Edição"
            }}
        >
            <PatientQueryToggle
                patient={patient}
                onClick={() => history.push(`/animal/selecionar/consulta`)}
            />
            { loading ? (
                <div className="row data-loading">
                    <div className="col">
                        <CircularProgress />
                    </div>
                </div>
            ) : error?.message ? (
                <VSError
                    message={error.message}
                    onClose={() => {
                        switch(error.function) {
                            case "getPatientOrError":
                                getPatientOrError()
                                break
                            case "getAppointmentOrError":
                                getAppointmentOrError()
                                break
                            default:
                        }
                    }}
                />
            ) : (
                <div className="row">
                    <div className="col">
                        <div className="row align-items-center mb-3">
                            <div className="col-12 col-xl">
                                <div className="row align-items-center">
                                    <div className="col-12">
                                        <div className="input-title input-height">
                                            Tipo de Consulta
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <VSRadio
                                            horizontal={true}
                                            options={appointmentTypeOptions}
                                            value={appointmentType}
                                            onChange={(_, value) => {
                                                setAppointmentType(value)
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-xl">
                                <div className="row align-items-center">
                                    <div className="col-12">
                                        <div className="input-title input-height">
                                            É retorno?
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <VSRadio
                                            horizontal={true}
                                            options={[
                                                {
                                                    label: "Sim",
                                                    value: "1"
                                                },
                                                {
                                                    label: "Não",
                                                    value: "0"
                                                }
                                            ]}
                                            value={_return}
                                            onChange={(_, value) => {
                                                set_Return(value)
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-auto-xl" style={{ width: '200px' }}>
                                <div className="row align-items-center">
                                    <div className="col-12">
                                        <div className="input-title input-height">
                                            Data
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <DateInput
                                            className="m-0"
                                            placeholder="00/00/0000"
                                            value={documentDateText || null}
                                            error={errorDocumentDate}
                                            helperText={null}
                                            onChange={(value) => handleDocumentDate(value)}
                                            size="small"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </div>
                                </div>
                            </div>
                            { errorDocumentDate && (
                                <div className="col-12 text-right">
                                    <small className="text-danger">{ helperDocumentDate }</small>
                                </div>
                            )}
                        </div>
                        <div className="row mb-4">
                            <div className="col-12">
                                <div className="input-title input-height">
                                    Motivo/Anamnese
                                </div>
                            </div>
                            <div className="col-12">
                                <TextField
                                    value={anamnesis}
                                    placeholder="Escreva o motivo/anamnese aqui"
                                    onChange={({ target: { value } }) => setAnamnesis(value)}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                />
                            </div>
                        </div>
                        { appointmentType == CONSULTA_DETALHADA && (
                            <>
                                <div className="row mb-4">
                                    <div className="col-12">
                                        <div className="input-title input-height">
                                            Exame Físico
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <TextField
                                            value={physicalExam}
                                            placeholder="Escreva os detalhes do exame físico aqui"
                                            onChange={({ target: { value } }) => setPhysicalExam(value)}
                                            size="small"
                                            variant="outlined"
                                            fullWidth
                                            multiline
                                        />
                                    </div>
                                </div>
                                <div className="row mb-4">
                                    <div className="col-12">
                                        <div className="input-title input-height">
                                            Diagnóstico
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <TextField
                                            value={diagnosis}
                                            placeholder="Escreva o diagnóstico aqui"
                                            onChange={({ target: { value } }) => setDiagnosis(value)}
                                            size="small"
                                            variant="outlined"
                                            fullWidth
                                            multiline
                                        />
                                    </div>
                                </div>
                                <div className="row mb-4">
                                    <div className="col-12">
                                        <div className="input-title input-height">
                                            Tratamento
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <TextField
                                            value={treatment}
                                            placeholder="Escreva o tratamento aqui"
                                            onChange={({ target: { value } }) => setTreatment(value)}
                                            size="small"
                                            variant="outlined"
                                            fullWidth
                                            multiline
                                        />
                                    </div>
                                </div>
                                <div className="row mb-4">
                                    <div className="col-12">
                                        <div className="input-title input-height">
                                            Próximos Passos
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <TextField
                                            value={nextStep}
                                            placeholder="Escreva os próximos passos aqui aqui"
                                            onChange={({ target: { value } }) => setNextStep(value)}
                                            size="small"
                                            variant="outlined"
                                            fullWidth
                                            multiline
                                        />
                                    </div>
                                </div>
                            </>
                        )}
                        <div className="row">
                            <div className="col-12">
                                <div className="input-title input-height">
                                    Observações Internas
                                </div>
                            </div>
                            <div className="col-12">
                                <TextField
                                    value={notes}
                                    placeholder="Escreva suas observações aqui"
                                    onChange={({ target: { value } }) => setNotes(value)}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col input-info">
                                As observações são anotações particulares. <b>Elas nunca serão compartilhadas com o tutor e também não são usadas para gerar um Diagnóstico Diferencial</b>
                            </div>
                        </div>
                        {pro?.access &&
                        <button
                            className={`btn-differential-diagnosis mb-3 ${hasGeneratedAI ? "active" : ""}`}
                            onClick={_ => {
                                setHasDrawerInfoForAI(!hasDrawerInfoForAI)
                                if(window.innerWidth > 768 || !hasDrawerInfoForAI){
                                    saveOnTracker("Clicou", "Assistente", "Assistente Vetsmart", hasDrawerInfoForAI ? "Fechou" : "Abriu", "Consulta", null, null, {
                                        appointmentId: appointment?.id ? appointment?.id : 'novo',
                                        patientId: patient?.id || patient?.objectId
                                    })
                                }
                            }}
                            onMouseEnter={() => setAIButtonOnHover(true)}
                            onMouseLeave={() => setAIButtonOnHover(false)}
                        >
                            <IconHeaderIA width="24px" height="24px" fill="var(--vsprontuario-primary-color)" />
                            <span className={AIButtonOnHover ? `ms-2` : `ms-0`}>Assistente Vetsmart</span>
                        </button>}
                        <VSMedicalRecordsAudit parseObject={appointment} />
                    </div>
                    {pro?.access &&
                    <AppointmentFormVirtualAssistantPreview 
                        ref={AppointmentFormVirtualAssistantPreviewRef}
                        clinic={clinic}
                        patient={patient}
                        appointment={appointment}
                        documentDate={documentDate}
                        anamnesis={anamnesis}
                        appointmentType={appointmentType}
                        physicalExam={physicalExam}
                        diagnosis={diagnosis}
                        treatment={treatment}
                        nextStep={nextStep}
                        hasDrawerInfoForAI={hasDrawerInfoForAI}
                        setHasDrawerInfoForAI={_hasDrawerInfoForAI => {
                            setHasDrawerInfoForAI(_hasDrawerInfoForAI)
                            history.replace({
                                search: "" // remove openwith from url when close
                            })
                        }}
                        hasGeneratedAI={hasGeneratedAI}
                        setHasGeneratedAI={setHasGeneratedAI}
                        differentialDiagnosisList={differentialDiagnosis}
                        setDifferentialDiagnosisList={setDifferentialDiagnosis}
                        openWith={
                            params.get(PARAM_AI_DIFFERENTIAL_DIAGNOSIS) !== null || differentialDiagnosis && differentialDiagnosis.length > 0 ? 
                            PARAM_AI_DIFFERENTIAL_DIAGNOSIS : 
                            null
                        }
                        drawerInfoRef={drawerInfoRef}
                    />}
                </div>
            )}
        </VSDrawer>
    )
}

const AppointmentFormVirtualAssistantPreview = forwardRef((props, ref) => {

    const { state } = useLocation()

    const {
        clinic,
        patient,
        appointment,
        documentDate,
        anamnesis,
        appointmentType,
        physicalExam,
        diagnosis,
        treatment,
        nextStep,
        differentialDiagnosisList,
        setDifferentialDiagnosisList,
        hasDrawerInfoForAI,
        setHasDrawerInfoForAI,
        hasGeneratedAI,
        setHasGeneratedAI,
        openWith,
        drawerInfoRef
    } = props

    const [ currentAISelected, setCurrentAISelected ] = useState(openWith)
    const [ currentAICache, setCurrentAICache ] = useState(null)
    const [ loadingAssistantPreview, setLoadingAssistantPreview ] = useState(false)
    const [ showDifferentialDiagnosisTooltip, setShowDifferentialDiagnosisTooltip ] = useState(false)
    const [ hasDifferentialDiagnosisChanged, setHasDifferentialDiagnosisChanged ] = useState(false)

    const [ trackerSource, setTrackerSource ] = useState(state?.trackerSource ? state?.trackerSource : "")
    const [ trackerSourceDetail, setTrackerSourceDetail ] = useState(state?.trackerSourceDetail ? state?.trackerSourceDetail : "")

    const isDifferentialDiagnosisEnabled = (
        appointmentType == CONSULTA_DETALHADA ? 
        (anamnesis || physicalExam || diagnosis || treatment || nextStep) :
        anamnesis
    )

    useImperativeHandle(ref, () => ({
        onSave: getDifferentialDiagnosisIfNeeded,
    }))
    
    const getDifferentialDiagnosisIfNeeded = () => {
        if(differentialDiagnosisList && differentialDiagnosisList.length > 0 && isDifferentialDiagnosisEnabled && hasDifferentialDiagnosisChanged){
            return getDifferentialDiagnosis()
        }
        return Promise.resolve()
    }

    const setDifferentialDiagnosis = (index, value) => {
        let newDifferensetDifferentialDiagnosisList = [...differentialDiagnosisList]
        newDifferensetDifferentialDiagnosisList.splice(index, 0, value)
        setDifferentialDiagnosisList(newDifferensetDifferentialDiagnosisList)
    }

    const getDifferentialDiagnosis = () => {
        setLoadingAssistantPreview(true)
        return Parse.Cloud.run("aiDifferentialDiagnosis", {
            clinicId: clinic.id || clinic.objectId,
            patientId: patient.id,
            appointment: {
                documentDate: documentDate,
                anamnesis: anamnesis,
                physicalExam: appointmentType == CONSULTA_DETALHADA ? physicalExam : undefined,
                diagnosis: appointmentType == CONSULTA_DETALHADA ? diagnosis : undefined,
                treatment: appointmentType == CONSULTA_DETALHADA ? treatment : undefined,
                nextStep: appointmentType == CONSULTA_DETALHADA ? nextStep : undefined
            },
            appVersionName: APP_VERSION,
            deviceType: APP_DEVICE,
            appContentType: APP_NAME
        }).then(aiCache => {
            setDifferentialDiagnosis(0, aiCache)
            setLoadingAssistantPreview(false)
            setHasDifferentialDiagnosisChanged(false)
            setHasGeneratedAI(true)

            saveOnTracker("Concluiu", "Assistente", "Diagnóstico Diferencial", null, trackerSource ? trackerSource : "Consulta", null, trackerSourceDetail, JSON.stringify({
                patientId: patient?.id || patient?.objectId,
                appointmentId: appointment?.id ? appointment?.id : 'novo',
                aiCacheId: aiCache?.id,
                cached: aiCache?.get("cached")
            }))
        })
    }

    useEffect(() => {
        if(differentialDiagnosisList && differentialDiagnosisList.length > 0){
            setCurrentAICache(differentialDiagnosisList[0])
        }
    }, [ differentialDiagnosisList ])

    useEffect(() => {
        if(currentAISelected == PARAM_AI_DIFFERENTIAL_DIAGNOSIS){
            getDifferentialDiagnosis()
        }
    }, [ currentAISelected ])

    useEffect(() => {
        setHasDifferentialDiagnosisChanged(true)
    }, [ anamnesis, physicalExam, diagnosis, treatment, nextStep ])

    if(!hasDrawerInfoForAI){
        return <></>
    }

    const title = currentAISelected == PARAM_AI_DIFFERENTIAL_DIAGNOSIS ?  "Diagnóstico Diferencial" : 
                    <>Assistente Vetsmart <span className="va-beta-text">Beta</span></>

    const onClose = _ => {
        setCurrentAISelected(null)
        setHasDrawerInfoForAI(false)
    }

    return (
        <VSDrawerInfo close={title} ref={drawerInfoRef} onClose={() => setHasDrawerInfoForAI(false)}>
            <div className="vsdrawer-info-header d-none d-md-inline">
                {title}
                {!loadingAssistantPreview && 
                <button className="vsdrawer-info-close d-none d-md-inline" onClick={onClose}>
                    <IconClose fill="rgba(0, 0, 0, 0.8)" width="20" height="20" />
                </button>}
            </div>
            {currentAISelected && !loadingAssistantPreview && (
                <div className="row flex-between gx-0">
                    <div className="vsdrawer-info-subheader-button col-auto d-flex p-3" onClick={() => {
                        setCurrentAISelected(null)
                        saveOnTracker("Clicou", "Assistente", "Voltar para opções", null, "Consulta", currentAISelected == PARAM_AI_DIFFERENTIAL_DIAGNOSIS ?  "Diagnóstico Diferencial" : null, null, {
                            appointmentId: appointment?.id ? appointment?.id : 'novo',
                            patientId: patient?.id || patient?.objectId
                        })
                    }}>
                        <IconHeaderIA className="me-2" width="24" height="24" fill="var(--vsprontuario-primary-color)" />
                        <span>Voltar para opções</span>
                    </div>
                    {/* <div className="col-6">

                    </div> */}
                </div>
            )}
            {currentAISelected == PARAM_AI_DIFFERENTIAL_DIAGNOSIS ? (
                loadingAssistantPreview ? (
                    <div className="row data-loading pt-5 w-auto">
                        <div className="col-12">
                            <CircularProgress />
                        </div>
                        <div className="col-12 px-4">
                            <div className="row">
                                <div className="loading-title col-12">
                                    Gerando diagnóstico diferencial
                                </div>
                                <div className="loading-subtitle col-12">
                                    Por favor, aguarde alguns segundos enquanto analisamos todos os registros.
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <>
                        <AppointmentFormDifferentialDiagnosisPreview 
                            aiCache={currentAICache} 
                            hasDifferentialDiagnosisChanged={hasDifferentialDiagnosisChanged} 
                            getDifferentialDiagnosis={getDifferentialDiagnosis}
                        />
                        <div className="vsdrawer-footer px-3 py-3 pb-md-0">
                            <p><b>Não encontrou a condição que esperava?</b> Nos ajude a melhorar as nossas respostas. <a href="https://petlove-vetsmart-vetus.typeform.com/to/qLaJ9vR9" target="_blank">Enviar feedback</a></p>
                        </div>
                    </>
                )
            ) : (
                <>
                    <div className="row flex-grow-1">
                        <div className="col-12">
                            <div className="p-3">
                                <div className="text-center mx-auto mb-4">
                                    <IconVirtualAssistantSelect className="mb-4" />
                                    <p className="virtual-assistant-intro-text">Agora a Vetsmart possui um assistente digital que pode te ajudar a encontrar diagnósticos e tratamentos para os seus pacientes.</p>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <h3 className="virtual-assistant-select-title">Selecione o que deseja fazer:</h3>
                                        <VSTooltip
                                            placement="bottom-end"
                                            open={showDifferentialDiagnosisTooltip}
                                            onOpen={() => {
                                                if (!isDifferentialDiagnosisEnabled) {
                                                    setShowDifferentialDiagnosisTooltip(true)
                                                }
                                            }}
                                            onClose={() => setShowDifferentialDiagnosisTooltip(false)}
                                            title="Preencha mais informações sobre a consulta para gerar um Diagnóstico Diferencial."
                                        >
                                            <button className={`vsbox-btn vsbox-btn-primary w-100 mt-2 text-normal text-start ${isDifferentialDiagnosisEnabled ? '' : 'disabled'}`} onClick={() => {
                                                if(!isDifferentialDiagnosisEnabled){
                                                    return;
                                                }
                                                setCurrentAISelected(PARAM_AI_DIFFERENTIAL_DIAGNOSIS)
                                                saveOnTracker("Clicou", "Assistente", "Diagnóstico Diferencial", null, "Consulta", null, null, {
                                                    appointmentId: appointment?.id ? appointment?.id : 'novo',
                                                    patientId: patient?.id || patient?.objectId
                                                })
                                            }}>
                                                <IconVirtualAssistantDiagnosis className="me-2" width="24" height="24" fill="var(--vsprontuario-primary-color)" />
                                                Diagnóstico diferencial
                                            </button>
                                        </VSTooltip>
                                        <div className="soon-text mt-3">
                                            <IconIA className="me-2" width="24" height="24" fill="#FF8A00" />
                                            Em breve novas funcionalidades
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="vsdrawer-footer flex-shrink-0 p-3">
                        <div className="row">
                            <div className="col-auto">
                                <IconSimpleWarning width="16" height="16" fill="#666666" />
                            </div>
                            <div className="col ps-0">
                                <p className="virtual-assistant-footer-text mb-0">
                                    O Assistente Vetsmart é uma ferramenta de suporte para seus atendimentos clínicos e não deve ser usada como diagnóstico definitivo.
                                </p>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </VSDrawerInfo>
    )
})

const AppointmentFormDifferentialDiagnosisPreview = (props) => {

    const {
        aiCache,
        hasDifferentialDiagnosisChanged,
        getDifferentialDiagnosis
    } = props

    const [ tab, setTab ] = useState("clinical")
    const response = aiCache?.get("response")
    if(!response || Object.keys(response).length <= 0){
        return (
            <VSEmpty
                icon={<IconPatientAppointment width="32px" height="32px" />}
                title="Esta consulta ainda não tem nenhuma informação para o diagnóstico diferencial"
                text="Escreva alguma informação sobre a consulta e tente novamente"
                className="differential-diagnosis-preview"
            >
            </VSEmpty>
        )
    }

    return (
        <div className="differential-diagnosis-preview row flex-column align-items-end mb-3 gx-0">
            <div className="col-12">
                <Tabs
                    className="vstabs px-3"
                    value={tab}
                    // onChange={(_, selected) => null}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    allowScrollButtonsMobile
                >
                    <Tab
                        value="clinical"
                        label="Manifestações Clínicas"
                        onClick={_ => setTab("clinical")}
                    />
                    <Tab
                        value="exams"
                        label="Exames Recomendados"
                        onClick={_ => setTab("exams")}
                    />
                    <Tab
                        value="treatment"
                        label="Tratamento e Manejo"
                        onClick={_ => setTab("treatment")}
                    />
                    <Tab
                        value="notes"
                        label="Observações Adicionais"
                        onClick={_ => setTab("notes")}
                    />
                    {/* <Tab
                        value="bibliography"
                        label="Fontes bibliográficas"
                        onClick={_ => setTab("bibliography")}
                    /> */}
                </Tabs>
            </div>
            <div className="col-12 ia-warning-text px-3 py-2">
                <IconSimpleWarning className="me-2" width="16" height="16" fill="#666666" />
                A IA pode produzir resultados imprecisos. <a className="link" href="https://pl-vetsmart.zendesk.com/hc/pt-br/articles/29652456409883-Assistente-Vetsmart" target="_blank">Saiba mais</a>.
            </div>
            <div className={`col-12 px-3 flex-grow-1 differential-diagnosis-wrap ${hasDifferentialDiagnosisChanged ? 'blur-elements' : ''}`}>
                {hasDifferentialDiagnosisChanged && (
                    <div className="differential-diagnosis-changed-box">
                        <div>Novas informações foram incluídas na Anamnese</div>
                        <button className={`vsbox-btn vsbox-btn-primary w-100 mt-2 text-normal text-start`} onClick={() => {
                            getDifferentialDiagnosis()
                        }}>
                            <IconIAGenerate className="me-2" width="24" height="24" fill="var(--vsprontuario-primary-color)" />
                            Gerar Novo Resultado
                        </button>
                    </div>
                )}
                {tab == "clinical" ? (
                    response?.clinical?.map((element, index) => element && (
                        <div className="differential-diagnosis-element" key={index}>
                            <div className="differential-diagnosis-title">{element?.title}</div>
                            <div className="differential-diagnosis-text">{element?.text}</div>
                            <div className="differential-diagnosis-subtitle">Sintomas informados compatíveis:</div>
                            <div className="differential-diagnosis-text">{element?.symptoms}</div>
                            <div className="differential-diagnosis-subtitle">Outros sintomas não informados:</div>
                            <div className="differential-diagnosis-text">{element?.otherSymptoms}</div>
                        </div>
                    ))
                ) : tab == "exams" ? (
                    response?.exams?.map((element, index) => element && (
                        <div className="differential-diagnosis-element" key={index}>
                            <div className="differential-diagnosis-title">{element?.exam}</div>
                            <div className="differential-diagnosis-text">{element?.reason}</div>
                        </div>
                    ))
                ) : tab == "treatment" ? (
                    response?.treatment?.map((element, index) => element && (
                        <div className="differential-diagnosis-element" key={index}>
                            <div className="differential-diagnosis-title">{element?.treatment}</div>
                            <div className="differential-diagnosis-text">{element?.reason}</div>
                        </div>
                    ))
                ) : tab == "notes" ? (
                    response?.notes?.map((element, index) => element && (
                        <div className="differential-diagnosis-element" key={index}>
                            <div className="differential-diagnosis-title">{element?.note}</div>
                            <div className="differential-diagnosis-text">{element?.reason}</div>
                        </div>
                    ))
                )
                //  : tab == "bibliography" ? (
                //     response?.bibliography?.map((element, index) => element && (
                //         <div className="differential-diagnosis-element" key={index}>
                //             <div className="differential-diagnosis-text">{element?.name}</div>
                //             <a className="differential-diagnosis-link" target="_blank" href={element?.link}>{element?.link}</a>
                //         </div>
                //     ))
                // ) 
                : null}
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        clinic: state.clinic,
        permissions: state.permissions,
        pro: state.featuresPro
    };
};
  
export default connect(mapStateToProps)(AppointmentForm)