import React, { useEffect, useState } from "react"
import { useLocation } from "react-router-dom/cjs/react-router-dom"
import { useParams } from "react-router"
import { connect } from "react-redux"

import { InputAdornment, TextField } from "@mui/material"
import { Autocomplete } from "@mui/lab"

import { ReactComponent as IconArrowLeft } from "../../../../assets/icon-arrow-left.svg"
import { ReactComponent as IconAddO } from "../../../../assets/icon-add-o.svg"
import { ReactComponent as IconEdit } from "../../../../assets/icon-primary-edit.svg"
import { ReactComponent as IconDangerExclamationO } from "../../../../assets/icon-danger-exclamation-o.svg"
import { ReactComponent as IconCloseO } from "../../../../assets/icon-close-o.svg"

import { APP_NAME, APP_DEVICE, APP_VERSION, ACL } from '../../../../constants'
import { PAYMENT_CASH, PAYMENT_CREDIT, PAYMENT_DEBIT, PAYMENT_OTHER, PAYMENT_TRANSFER, SALE, brandOptions, getInstallmentsByValue, getPaymentMethod } from "./Sale"
import { dayjs, formattedCurrency, getSelectedOptionLabel, saveOnTracker, showArrayIndex } from "../../../../utils"

import VSError from "../../../../components/vsError/VSError"
import VSTextFieldCurrency from '../../../../components/vsTextFieldCurrency/VSTextFieldCurrency';
import BoxDropdown from "../../../../components/boxDropdown/BoxDropdown"
import DateInput from "../../../../components/dateInput/DateInput"
import VSPage from "../../../../components/vsPage/VSPage"

import "./SalePaymentForm.scss"
const SalePaymentForm = (props) => {

    const {
        id_documento
    } = useParams()

	const { 
		state
	} = useLocation();

    const {
        user,
        clinic,
        history,
        permissions
    } = props

    const documentType = SALE

    const [ loading, setLoading ] = useState(false)
    const [ loadingSave, setLoadingSave ] = useState(false)
    const [ hasSubmited, setHasSubmited ] = useState(false)
    const [ sale, setSale ] = useState(null)
    const [ patient, setPatient ] = useState(null)
    const [ errorPayment, setErrorPayment ] = useState(false)
    const [ errorDate, setErrorDate ] = useState(false)

    const [ percentDiscount, setPercentDiscount ] = useState("")
    const [ valueDiscount, setValueDiscount ] = useState("")

    const date = dayjs()

    const documentName = sale?.get("documentType") == SALE ? "venda" : "orcamento"

    const redirectTo = state?.redirectTo || `/animal/${patient.id}/${documentName}`

    const defaultDate = {
        date: date.toDate(),
        dateText: date.format("DD/MM/YYYY"),
        dateError: false,
        dateHelper: ""
    }

    const defaultCash = {
        ...defaultDate,
        value: ""
    }
    const [ cash, setCash ] = useState(false)
    const [ cashValues, setCashValues ] = useState([defaultCash])

    const defaultCredit = {
        ...defaultDate,
        value: "",
        installments: "",
        options: [],
        brand: ""
    }
    const [ credit, setCredit ] = useState(false)
    const [ creditValues, setCreditValues ] = useState([defaultCredit])

    const defaultDebit = {
        ...defaultDate,
        value: "",
        brand: ""
    }
    const [ debit, setDebit ] = useState(false)
    const [ debitValues, setDebitValues ] = useState([defaultDebit])

    const defaultTransfer = {
        ...defaultDate,
        value: ""
    }
    const [ transfer, setTransfer ] = useState(false)
    const [ transferValues, setTransferValues ] = useState([defaultTransfer])

    const defaultOther = {
        ...defaultDate,
        value: "",
        notes: ""
    }
    const [ other, setOther ] = useState(false)
    const [ otherValues, setOtherValues ] = useState([defaultOther])

    const [ error, setError ] = useState(null)

    const toFixedFloat = number => number ? parseFloat(Number(number).toFixed(2)) : 0

    const total = toFixedFloat(sale?.get("servicesValue") || 0)

    const discount = toFixedFloat((valueDiscount || 0))

    const pay = toFixedFloat((total - discount))

    const getFloat = (value) => {
        return value ? (parseFloat(value) || 0) : 0
    }

    const paid = toFixedFloat((cash && cashValues ? cashValues.reduce((total, object) => total + getFloat(object.value), 0) : 0) +
        (credit && creditValues ? creditValues.reduce((total, object) => total + getFloat(object.value), 0) : 0) +
        (debit && debitValues ? debitValues.reduce((total, object) => total + getFloat(object.value), 0) : 0) +
        (transfer && transferValues ? transferValues.reduce((total, object) => total + getFloat(object.value), 0) : 0) +
        (other && otherValues ? otherValues.reduce((total, object) => total + getFloat(object.value), 0) : 0))

    const pending = toFixedFloat(pay - paid)

    const validate = () => {
        let isValid = true
        if (loading) {
            isValid = false
        }

        if (error?.message) {
            isValid = false
        }

        if (errorDate) {
            isValid = false
        }

        if (paid > pay) {
            isValid = false
            setErrorPayment(true)
        } else {
            setErrorPayment(false)
        }

        return isValid
    }

    const isValid = () => !loading && !loadingSave && !errorPayment && !errorDate

    const fieldsWithErrorText = () => {
        let errors = []
        if (loading) {
            errors.push(`Buscando a venda...`)
        }
        if (loadingSave) {
            errors.push(`Salvando a venda...`)
        }
        if (error?.message) {
            errors.push(error.message)
        }
        if (errorPayment) {
            errors.push("O valor pago é maior que o total a pagar")
        }
        if (errorDate) {
            errors.push("Por favor, verifique a data do pagamento")
        }
        if (!permissions?.check(ACL.SALE, ACL.Rule.EDIT, sale)) {
            errors.push("Acesso negado")
        }
        return errors
    }

    const save = () => {
        setHasSubmited(true)
        if (validate()) {
            setLoadingSave(true)

            var payments = []
            if (cash && cashValues) {
                cashValues.forEach(cash => {
                    if (cash.value) {
                        payments.push({
                            type: PAYMENT_CASH,
                            date: cash.date,
                            value: parseFloat(cash.value.toFixed(2))
                        })
                    }
                })
            }

            if (credit && creditValues) {
                creditValues.forEach(credit => {
                    if (credit.value) {
                        payments.push({
                            type: PAYMENT_CREDIT,
                            date: credit.date,
                            value: parseFloat(credit.value.toFixed(2)),
                            installments: credit.installments,
                            brand: credit.brand
                        })
                    }
                })
            }

            if (debit && debitValues) {
                debitValues.forEach(debit => {
                    if (debit.value) {
                        payments.push({
                            type: PAYMENT_DEBIT,
                            date: debit.date,
                            value: parseFloat(debit.value.toFixed(2)),
                            brand: debit.brand
                        })
                    }
                })
            }

            if (transfer && transferValues) {
                transferValues.forEach(transfer => {
                    if (transfer.value) {
                        payments.push({
                            type: PAYMENT_TRANSFER,
                            date: transfer.date,
                            value: parseFloat(transfer.value.toFixed(2))
                        })
                    }
                })
            }

            if (other && otherValues) {
                otherValues.forEach(other => {
                    if (other.value) {
                        payments.push({
                            type: PAYMENT_OTHER,
                            date: other.date,
                            value: parseFloat(other.value.toFixed(2)),
                            notes: other.notes
                        })
                    }
                })
            }
            sale.set("payments", payments)
            sale.set("discount", parseFloat(valueDiscount.toFixed(2)) || 0)
            sale.set("documentType", documentType)
            sale.set("changedBy", user.object)
            sale.set("changedAt", new Date())
            sale.set("changedApp", APP_NAME)
            sale.set("changedDevice", APP_DEVICE)
            sale.set("changedAppVersion", APP_VERSION)
            sale.save()
            .then(object => {
                history.replace(`/compartilhar/${ object.id }`, { redirectTo: redirectTo })
                setLoadingSave(false)
            })
            .catch(error => {
                setLoadingSave(false)
                console.error(error)
                Swal.fire(
                    'Desculpe',
                    `Ocorreu algum erro ao tentar ${sale.id ? "editar" : "cadastrar"} o pagamento`,
                    'error'
                )
                sale.revert()
            })
        }
    }

    const changeDate = (value, callback, index) => {
        if (value && value.length == 10) {
            const _date = dayjs(`${value} 00:00:00`, "DD/MM/YYYY HH:mm:ss")
            if (_date.isValid()) {
                const minDate = dayjs().subtract(10, 'years')
                const maxDate = dayjs().add(10, 'years')
                if (_date < minDate) {
                    callback(index, "date", null)
                    callback(index, "dateText", value)
                    callback(index, "dateError", true)
                    callback(index, "dateHelper", "A data não pode ser há mais de 10 anos")
                    setErrorDate(true)
                } else if (_date > maxDate) {
                    callback(index, "date", null)
                    callback(index, "dateText", value)
                    callback(index, "dateError", true)
                    callback(index, "dateHelper", "A data não pode ser a mais de 10 anos")
                    setErrorDate(true)
                } else {
                    callback(index, "date", _date.toDate())
                    callback(index, "dateText", value)
                    callback(index, "dateError", false)
                    callback(index, "dateHelper", "")
                    setErrorDate(false)
                }
                return
            }
        }
        callback(index, "date", null)
        callback(index, "dateText", value)
        callback(index, "dateError", true)
        callback(index, "dateHelper", "A data informada não é válida")
        setErrorDate(true)
    }

    const setCashValue = (index, name, value) => {
        var newCashValues = [...cashValues]
        newCashValues[index][name] = value
        if (name === "value") { // if change value, reset installments
            newCashValues[index].installments = ""
        }
        setCashValues(newCashValues)
    }

    const unsetCashValue = (index) => {
        var newCashValues = [...cashValues]
        newCashValues.splice(index, 1)
        setCashValues(newCashValues)
    }

    const setCreditValue = (index, name, value) => {
        var newCreditValues = [...creditValues]
        newCreditValues[index][name] = value
        if (name === "value") { // if change value, reset installments
            newCreditValues[index].installments = ""
        }
        setCreditValues(newCreditValues)
    }

    const unsetCreditValue = (index) => {
        var newCreditValues = [...creditValues]
        newCreditValues.splice(index, 1)
        setCreditValues(newCreditValues)
    }

    const setDebitValue = (index, name, value) => {
        var newDebitValues = [...debitValues]
        newDebitValues[index][name] = value
        setDebitValues(newDebitValues)
    }

    const unsetDebitValue = (index) => {
        var newDebitValues = [...debitValues]
        newDebitValues.splice(index, 1)
        setDebitValues(newDebitValues)
    }

    const setTransferValue = (index, name, value) => {
        var newTransferValues = [...transferValues]
        newTransferValues[index][name] = value
        setTransferValues(newTransferValues)
    }

    const unsetTransferValue = (index) => {
        var newTransferValues = [...transferValues]
        newTransferValues.splice(index, 1)
        setTransferValues(newTransferValues)
    }

    const setOtherValue = (index, name, value) => {
        var newOtherValues = [...otherValues]
        newOtherValues[index][name] = value
        setOtherValues(newOtherValues)
    }

    const unsetOtherValue = (index) => {
        var newOtherValues = [...otherValues]
        newOtherValues.splice(index, 1)
        setOtherValues(newOtherValues)
    }

    const getSaleOrError = () => {
        if (permissions?.check(ACL.SALE, ACL.Rule.VIEW)) {
            saveOnTracker("Iniciou", "Venda", id_documento)
            setLoading(true)
            setError(null)

            var query = new Parse.Query("MRSale");
            query.equalTo("clinic", clinic.object);
            query.equalTo("isDeleted", false);
            query.equalTo("documentType", documentType)
            query.include("patient")
            query.get(id_documento)
            .then(_object => {
                if (_object) {
                    setSale(_object)
                    setPatient(_object.get("patient"))
                    setValueDiscount(_object.get("discount"))

                    var cashs = []
                    var credits = []
                    var debits = []
                    var transfers = []
                    var others = []
                    _object.get("payments")?.forEach(payment => {
                        const date = dayjs(payment.date)
                        const dateObject = {
                            date: date.toDate(),
                            dateText: date.format("DD/MM/YYYY"),
                            dateError:false,
                            dateHelper: ""
                        }
                        switch (payment.type) {
                            case PAYMENT_CASH:
                                cashs.push({
                                    ...dateObject,
                                    value: parseFloat(payment.value.toFixed(2))
                                })
                                break;
                            case PAYMENT_CREDIT:
                                credits.push({
                                    ...dateObject,
                                    value: parseFloat(payment.value.toFixed(2)),
                                    installments: payment.installments,
                                    options: [],
                                    brand: payment.brand
                                })
                                break;
                            case PAYMENT_DEBIT:
                                debits.push({
                                    ...dateObject,
                                    value: parseFloat(payment.value.toFixed(2)),
                                    brand: payment.brand
                                })
                                break;
                            case PAYMENT_TRANSFER:
                                transfers.push({
                                    ...dateObject,
                                    value: parseFloat(payment.value.toFixed(2))
                                })
                                break;
                            case PAYMENT_OTHER:
                                others.push({
                                    ...dateObject,
                                    value: parseFloat(payment.value.toFixed(2)),
                                    notes: payment.notes
                                })
                                break;
                        }
                    })

                    if (cashs.length > 0) {
                        setCash(true)
                        setCashValues(cashs)
                    }

                    if (credits.length > 0) {
                        setCredit(true)
                        setCreditValues(credits)
                    }

                    if (debits.length > 0) {
                        setDebit(true)
                        setDebitValues(debits)
                    }

                    if (transfers.length > 0) {
                        setTransfer(true)
                        setTransferValues(transfers)
                    }

                    if (others.length > 0) {
                        setOther(true)
                        setOtherValues(others)
                    }
                    setLoading(false)
                } else {
                    const error = `Não encontramos a venda informada`
                    Swal.fire(
                        'Desculpe',
                        error,
                        'error'
                    )
                    .then(_ => {
                        setLoading(false)
                        setError({
                            message: error,
                            function: "getSaleOrError"
                        })
                    })
                }
            }).catch(err => {
                console.error(err)
                const error = `Ocorreu algum erro ao tentar buscar a venda`
                Swal.fire(
                    'Desculpe',
                    error,
                    'error'
                )
                .then(_ => {
                    setLoading(false)
                    setError({
                        message: error,
                        function: "getSaleOrError"
                    })
                })
            })
        }
    }

    useEffect(() => {
        if (sale?.get("servicesValue")) {
            setValueDiscount(parseFloat(parseFloat(sale.get("servicesValue")) * parseFloat(percentDiscount) / 100))
        }
    }, [ percentDiscount ])

    useEffect(() => {
        if (sale?.get("servicesValue")) {
            setPercentDiscount(parseFloat(parseFloat(valueDiscount) * 100 / parseFloat(sale.get("servicesValue"))))
        }
    }, [ valueDiscount ])

    useEffect(() => {
        if (credit && creditValues) {
            creditValues.map((credit, index) => {
                setCreditValue(index, "options", getInstallmentsByValue(credit.value))
            })
        }
    }, [ JSON.stringify(creditValues.map(credit => ({ value: credit.value, options: credit.options || [] }))) ])

    useEffect(() => {
        validate()
    }, [ cash, cashValues, credit, creditValues, debit, debitValues, transfer, transferValues, other, otherValues, percentDiscount, valueDiscount ])

    // autofill pending value on check
    var _pending = pending;
    const getDefaultPendingValue = (valueObj) => {
        if(!valueObj || valueObj.value || !_pending){
            return valueObj
        }

        const _valueObj = Object.assign(valueObj, {
            value: _pending
        });
        _pending = 0

        return _valueObj
    }
    
    useEffect(() => {
        if(!loading && _pending > 0 && cash && cashValues && cashValues.length > 0){
            setCashValues(cashValues.map(cashValue => getDefaultPendingValue(cashValue)))
        }
    }, [ cash ])

    useEffect(() => {
        if(!loading && _pending > 0 && credit && creditValues && creditValues.length > 0){
            setCreditValues(creditValues.map(creditValue => getDefaultPendingValue(creditValue)))
        }
    }, [ credit ])

    useEffect(() => {
        if(!loading && _pending > 0 && debit && debitValues && debitValues.length > 0){
            setDebitValues(debitValues.map(debitValue => getDefaultPendingValue(debitValue)))
        }
    }, [ debit ])

    useEffect(() => {
        if(!loading && _pending > 0 && transfer && transferValues && transferValues.length > 0){
            setTransferValues(transferValues.map(transferValue => getDefaultPendingValue(transferValue)))
        }
    }, [ transfer ])

    useEffect(() => {
        if(!loading && _pending > 0 && other && otherValues && otherValues.length > 0){
            setOtherValues(otherValues.map(otherValue => getDefaultPendingValue(otherValue)))
        }
    }, [ other ])

    useEffect(() => {
        if (sale && !permissions?.check(ACL.SALE, ACL.Rule.EDIT, sale)) {
            permissions?.error()
        }
    }, [ sale ])

    useEffect(() => {
        if (clinic && clinic.object && id_documento) {
            getSaleOrError()
        }
    }, [ clinic, id_documento ])

    const errors = fieldsWithErrorText()

    return (
        <div id="sale-payment-form">
            <VSPage
                loading={loading}
                title="Venda"
                subtitle={(
                    <div className="row align-items-center">
                        <div className="col-auto px-0">
                            <IconArrowLeft width="8px" height="12px" className="title-separator" />
                        </div>
                        <div className="col-auto">
                            <div className="title">Pagamento</div>
                        </div>
                        <div className="col-auto px-0">
                            <IconArrowLeft width="8px" height="12px" className="title-separator" />
                        </div>
                        <div className="col-auto">
                            <div className="subtitle">Visualizar e Emitir</div>
                        </div>
                    </div>
                )}
                cancel="Cancelar"
                onCancel={() => history.replace(redirectTo)}
                disabled={!isValid()}
                errors={errors}
                submit={<div>Salvar e Visualizar <span className="d-none d-md-inline">Informações</span></div>}
                onSubmit={() => save()}
            >
                { error?.message ? (
                    <VSError
                        message={error.message}
                        onClose={() => {
                            switch(error.function) {
                                case "getSaleOrError":
                                    getSaleOrError()
                                    break
                                default:
                            }
                        }}
                    />
                ) : (
                    <>
                        <div className="row align-items-center mb-3">
                            <div className="col-12 col-sm">
                                <h1 className="title">
                                    Selecione a forma de pagamento
                                </h1>
                            </div>
                            <div className="col-12 col-sm-auto">
                                <button className="btn-link" onClick={() => history.push(`/${ documentName }/${ patient?.id }/${id_documento}`, { redirectTo: redirectTo })}>
                                    <IconEdit width="18px" height="18px" fill="var(--vsprontuario-primary-color)" />
                                    Editar Venda
                                </button>
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-12">
                                <h2 className="content-title">Formas de Pagamento</h2>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 mb-2">
                                <h2 className="content-text">Selecione uma ou mais formas de pagamentos.</h2>
                            </div>
                        </div>
                        <div className="row g-3 mb-3 align-items-center">
                            <div className="col-12">
                                <BoxDropdown
                                    title={getPaymentMethod(PAYMENT_CASH)}
                                    checked={cash}
                                    onChecked={(checked) => setCash(checked)}
                                >
                                    { cashValues.map((cash, index) => (
                                        <div className="row g-3 align-items-center mb-3" key={index}>
                                            <div className="col-auto">
                                                <div className="row align-items-center">
                                                    <div className="col-auto">
                                                        <DateInput
                                                            placeholder="00/00/0000"
                                                            value={cash.dateText || null}
                                                            error={cash.dateError}
                                                            onChange={(value) => {
                                                                changeDate(value, setCashValue, index)
                                                            }}
                                                            size="small"
                                                            variant="outlined"
                                                            fullWidth
                                                        />
                                                    </div>
                                                    { cash.dateError && (
                                                        <div className="col-12">
                                                            <small className="text-danger">{ cash.dateHelper }</small>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-auto col-lg-2">
                                                <VSTextFieldCurrency
                                                    value={cash.value}
                                                    onChangeValue={(event, originalValue, maskedValue) => {
                                                        setCashValue(index, "value", originalValue)
                                                    }}
                                                    InputElement={(
                                                        <TextField
                                                            placeholder="R$ 0,00"
                                                            label="Valor Pago"
                                                            size="small"
                                                            variant="outlined"
                                                            fullWidth
                                                        />
                                                    )}
                                                />
                                            </div>
                                            { cashValues.length > 1 && (
                                                <div className="col-12 col-md">
                                                    <button
                                                        className="btn-array text-start text-md-center px-0"
                                                        onClick={() => unsetCashValue(index)}
                                                    >
                                                        <IconCloseO width="24" height="24" />
                                                        Remover Dinheiro { showArrayIndex(index + 1) }
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                    <div className="row align-items-center">
                                        <div className="col-12">
                                            <button className="btn-add" onClick={() => setCashValues([...cashValues, defaultCash])}>
                                                <IconAddO width="20" height="20" />
                                                Adicionar Dinheiro { showArrayIndex(cashValues.length + 1) }
                                            </button>
                                        </div>
                                    </div>
                                </BoxDropdown>
                            </div>
                            <div className="col-12">
                                <BoxDropdown
                                    title={getPaymentMethod(PAYMENT_CREDIT)}
                                    checked={credit}
                                    onChecked={(checked) => setCredit(checked)}
                                >
                                    { creditValues.map((credit, index) => (
                                        <div className="row g-3 align-items-center mb-3" key={index}>
                                            <div className="col-auto col-sm-4 col-md-auto">
                                                <div className="row align-items-center">
                                                    <div className="col-12">
                                                        <DateInput
                                                            placeholder="00/00/0000"
                                                            value={credit.dateText || null}
                                                            error={credit.dateError}
                                                            onChange={(value) => {
                                                                changeDate(value, setCreditValue, index)
                                                            }}
                                                            size="small"
                                                            variant="outlined"
                                                            fullWidth
                                                        />
                                                    </div>
                                                    { credit.dateError && (
                                                        <div className="col-12">
                                                            <small className="text-danger">{ credit.dateHelper }</small>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-8 col-md-4 col-lg-2">
                                                <VSTextFieldCurrency
                                                    value={credit.value}
                                                    onChangeValue={(event, originalValue, maskedValue) => {
                                                        setCreditValue(index, "value", originalValue) 
                                                    }}
                                                    InputElement={(
                                                        <TextField
                                                            placeholder="R$ 0,00"
                                                            label="Valor Pago"
                                                            size="small"
                                                            variant="outlined"
                                                            fullWidth
                                                        />
                                                    )}
                                                />
                                            </div>
                                            <div className="col-12 col-sm-6 col-md col-lg col-xl-3">
                                                <Autocomplete
                                                    value={credit.installments}
                                                    options={credit.options}
                                                    getOptionLabel={(option) => getSelectedOptionLabel(option, credit.options, credit.installments)}
                                                    getOptionSelected={(option) => option.value === credit.installments}
                                                    onChange={(_, selected) => setCreditValue(index, "installments", selected?.value || '')}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            placeholder="Ex: À vista de R$ 19,90"
                                                            label="Quantidade de parcelas"
                                                            size="small"
                                                            variant="outlined"
                                                            fullWidth
                                                        />
                                                    )}
                                                    openOnFocus
                                                />
                                            </div>
                                            <div className="col-12 col-sm-6 col-md-12 col-lg-3 col-xl-3">
                                                <Autocomplete
                                                    value={credit.brand}
                                                    options={brandOptions}
                                                    onChange={(_, selected) => setCreditValue(index, "brand", selected || '')}
                                                    onInputChange={(_, selected) => setCreditValue(index, "brand", selected || '')}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            placeholder="Ex: Master Card"
                                                            label="Bandeira"
                                                            size="small"
                                                            variant="outlined"
                                                            fullWidth
                                                        />
                                                    )}
                                                    openOnFocus
                                                    freeSolo
                                                />
                                            </div>
                                            { creditValues.length > 1 && (
                                                <div className="col-12 col-xl">
                                                    <button
                                                        className="btn-array text-start text-md-center px-0"
                                                        onClick={() => unsetCreditValue(index)}
                                                    >
                                                        <IconCloseO width="24" height="24" />
                                                        Remover Cartão de Crédito { showArrayIndex(index + 1) }
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    )) }
                                    <div className="row align-items-center">
                                        <div className="col-12">
                                            <button className="btn-add" onClick={() => setCreditValues([...creditValues, defaultCredit])}>
                                                <IconAddO width="20" height="20" />
                                                Adicionar Cartão de Crédito { showArrayIndex(creditValues.length + 1) }
                                            </button>
                                        </div>
                                    </div>
                                </BoxDropdown>
                            </div>
                            <div className="col-12">
                                <BoxDropdown
                                    title={getPaymentMethod(PAYMENT_DEBIT)}
                                    checked={debit}
                                    onChecked={(checked) => setDebit(checked)}
                                >
                                    { debitValues.map((debit, index) => (
                                        <div className="row g-3 align-items-center mb-3" key={index}>
                                            <div className="col-auto">
                                                <div className="row align-items-center">
                                                    <div className="col-auto">
                                                        <DateInput
                                                            placeholder="00/00/0000"
                                                            value={debit.dateText || null}
                                                            error={debit.dateError}
                                                            onChange={(value) => {
                                                                changeDate(value, setDebitValue, index)
                                                            }}
                                                            size="small"
                                                            variant="outlined"
                                                            fullWidth
                                                        />
                                                    </div>
                                                    { debit.dateError && (
                                                        <div className="col-12">
                                                            <small className="text-danger">{ debit.dateHelper }</small>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm col-md-4 col-lg-2">
                                                <VSTextFieldCurrency
                                                    value={debit.value}
                                                    onChangeValue={(event, originalValue, maskedValue) => {
                                                        setDebitValue(index, "value", originalValue) 
                                                    }}
                                                    InputElement={(
                                                        <TextField
                                                            placeholder="R$ 0,00"
                                                            label="Valor Pago"
                                                            size="small"
                                                            variant="outlined"
                                                            fullWidth
                                                        />
                                                    )}
                                                />
                                            </div>
                                            <div className="col-12 col-sm-12 col-md">
                                                <Autocomplete
                                                    value={debit.brand}
                                                    options={brandOptions}
                                                    onChange={(_, selected) => setDebitValue(index, "brand", selected || '')}
                                                    onInputChange={(_, selected) => setDebitValue(index, "brand", selected || '')}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            placeholder="Ex: Master Card"
                                                            label="Bandeira"
                                                            size="small"
                                                            variant="outlined"
                                                            fullWidth
                                                        />
                                                    )}
                                                    openOnFocus
                                                    freeSolo
                                                />
                                            </div>
                                            { debitValues.length > 1 && (
                                                <div className="col-12 col-lg">
                                                    <button
                                                        className="btn-array text-start text-md-center px-0"
                                                        onClick={() => unsetDebitValue(index)}
                                                    >
                                                        <IconCloseO width="24" height="24" />
                                                        Remover Cartão de Débito { showArrayIndex(index + 1) }
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    )) }
                                    <div className="row align-items-center">
                                        <div className="col-12">
                                            <button className="btn-add" onClick={() => setDebitValues([...debitValues, defaultDebit])}>
                                                <IconAddO width="20" height="20" />
                                                Adicionar Cartão de Débito { showArrayIndex(debitValues.length + 1) }
                                            </button>
                                        </div>
                                    </div>
                                </BoxDropdown>
                            </div>
                            <div className="col-12">
                                <BoxDropdown
                                    title={getPaymentMethod(PAYMENT_TRANSFER)}
                                    checked={transfer}
                                    onChecked={(checked) => setTransfer(checked)}
                                >
                                    { transferValues.map((transfer, index) => (
                                        <div className="row g-3 align-items-center mb-3" key={index}>
                                            <div className="col-auto">
                                                <div className="row align-items-center">
                                                    <div className="col-auto">
                                                        <DateInput
                                                            placeholder="00/00/0000"
                                                            value={transfer.dateText || null}
                                                            error={transfer.dateError}
                                                            onChange={(value) => {
                                                                changeDate(value, setTransferValue, index)
                                                            }}
                                                            size="small"
                                                            variant="outlined"
                                                            fullWidth
                                                        />
                                                    </div>
                                                    { transfer.dateError && (
                                                        <div className="col-12">
                                                            <small className="text-danger">{ transfer.dateHelper }</small>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm col-md-3 col-lg-2">
                                                <VSTextFieldCurrency
                                                    value={transfer.value}
                                                    onChangeValue={(event, originalValue, maskedValue) => {
                                                        setTransferValue(index, "value", originalValue) 
                                                    }}
                                                    InputElement={(
                                                        <TextField
                                                            placeholder="R$ 0,00"
                                                            label="Valor Pago"
                                                            size="small"
                                                            variant="outlined"
                                                            fullWidth
                                                        />
                                                    )}
                                                />
                                            </div>
                                            { transferValues.length > 1 && (
                                                <div className="col-12 col-md">
                                                    <button
                                                        className="btn-array text-start text-md-center px-0"
                                                        onClick={() => unsetTransferValue(index)}
                                                    >
                                                        <IconCloseO width="24" height="24" />
                                                        Remover PIX or Transferência Bancária { showArrayIndex(index + 1) }
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    )) }
                                    <div className="row align-items-center">
                                        <div className="col-12">
                                            <button className="btn-add" onClick={() => setTransferValues([...transferValues, defaultTransfer])}>
                                                <IconAddO width="20" height="20" />
                                                Adicionar PIX ou Transferência Bancária { showArrayIndex(transferValues.length + 1) }
                                            </button>
                                        </div>
                                    </div>
                                </BoxDropdown>
                            </div>
                            <div className="col-12">
                                <BoxDropdown
                                    title={getPaymentMethod(PAYMENT_OTHER)}
                                    checked={other}
                                    onChecked={(checked) => setOther(checked)}
                                >
                                    { otherValues.map((other, index) => (
                                        <div className="row g-3 align-items-center mb-3" key={index}>
                                            <div className="col-auto">
                                                <div className="row align-items-center">
                                                    <div className="col-auto">
                                                        <DateInput
                                                            placeholder="00/00/0000"
                                                            value={other.dateText || null}
                                                            error={other.dateError}
                                                            onChange={(value) => {
                                                                changeDate(value, setOtherValue, index)
                                                            }}
                                                            size="small"
                                                            variant="outlined"
                                                            fullWidth
                                                        />
                                                    </div>
                                                    { other.dateError && (
                                                        <div className="col-12">
                                                            <small className="text-danger">{ other.dateHelper }</small>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm col-md-4 col-lg-2">
                                                <VSTextFieldCurrency
                                                    value={other.value}
                                                    onChangeValue={(event, originalValue, maskedValue) => {
                                                        setOtherValue(index, "value", originalValue) 
                                                    }}
                                                    InputElement={(
                                                        <TextField
                                                            placeholder="R$ 0,00"
                                                            label="Valor Pago"
                                                            size="small"
                                                            variant="outlined"
                                                            fullWidth
                                                        />
                                                    )}
                                                />
                                            </div>
                                            <div className="col-12 col-sm-12 col-md">
                                                <TextField
                                                    placeholder="Ex: Pagou com prestação de serviço"
                                                    label="Tipo de Pagamento"
                                                    value={other.notes}
                                                    onChange={({ target: { value } }) => setOtherValue(index, "notes", value)}
                                                    size="small"
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                            </div>
                                            { otherValues.length > 1 && (
                                                <div className="col-12 col-lg-auto">
                                                    <button
                                                        className="btn-array text-start text-md-center px-0"
                                                        onClick={() => unsetOtherValue(index)}
                                                    >
                                                        <IconCloseO width="24" height="24" />
                                                        Remover Outra Forma de Pagamento { showArrayIndex(index + 1) }
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    )) }
                                    <div className="row align-items-center">
                                        <div className="col-12">
                                            <button className="btn-add" onClick={() => setOtherValues([...otherValues, defaultOther])}>
                                                <IconAddO width="20" height="20" />
                                                Adicionar Outra Forma de Pagamento { showArrayIndex(otherValues.length + 1) }
                                            </button>
                                        </div>
                                    </div>
                                </BoxDropdown>
                            </div>
                            { errorPayment && (
                                <div className="col-12">
                                    <div className="alert alert-danger mb-1">
                                        <IconDangerExclamationO width="22" height="22" className="me-2" /> O valor pago é maior que o total a pagar, <b>por favor preencha um valor igual ou menor</b>.
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="custom-box">
                                    <div className="custom-content">
                                        <div className="content-action">
                                            <div className="row g-3">
                                                <div className="col-12 col-sm-6 col-md-4 col-lg-auto">
                                                    <div className="input-title border-0">
                                                        Valor Total
                                                    </div>
                                                    <div className="content-value mt-2">
                                                        { formattedCurrency(total) }
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-8 col-lg">
                                                    <div className="input-title border-0">
                                                        Percentual ou Valor de Desconto
                                                    </div>
                                                    <div className="row align-items-center">
                                                        <div className="col">
                                                            <VSTextFieldCurrency
                                                                value={percentDiscount}
                                                                hideSymbol={true}
                                                                onChangeValue={(event, originalValue, maskedValue) => {
                                                                    setPercentDiscount(originalValue) 
                                                                }}
                                                                InputElement={(
                                                                    <TextField
                                                                        placeholder="0,00"
                                                                        size="small"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        InputProps={{
                                                                            endAdornment: (
                                                                                <InputAdornment position="end">%</InputAdornment>
                                                                            )
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                        </div>
                                                        <div className="col">
                                                            <VSTextFieldCurrency
                                                                value={valueDiscount}
                                                                hideSymbol={true}
                                                                onChangeValue={(event, originalValue, maskedValue) => {
                                                                    setValueDiscount(originalValue) 
                                                                }}
                                                                InputElement={(
                                                                    <TextField
                                                                        placeholder="0,00"
                                                                        size="small"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        InputProps={{
                                                                            startAdornment: (
                                                                                <InputAdornment position="start">R$</InputAdornment>
                                                                            )
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6 col-md col-lg-2">
                                                    <div className="input-title border-0">
                                                        Total a Pagar
                                                    </div>
                                                    <div className="content-value mt-2">
                                                        { formattedCurrency(pay) }
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6 col-md col-lg-2">
                                                    <div className="input-title border-0">
                                                        Valor Pago
                                                    </div>
                                                    <div className={`content-value mt-2 ${ errorPayment ? "text-danger" : "" }`}>
                                                        { formattedCurrency(paid) }
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6 col-md col-lg-2">
                                                    <div className="input-title border-0">
                                                        Valor Restante
                                                    </div>
                                                    <div className="content-value mt-2">
                                                        { formattedCurrency(pending > 0 ? pending : 0) }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </VSPage>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        user: state.user,
        clinic: state.clinic,
        permissions: state.permissions,
    };
};

export default connect(mapStateToProps)(SalePaymentForm)