import React from 'react'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import { dayjs } from '../../utils';

import './DateInput.scss'
const DateInput = (props) => {

    const {
        variant,
        value,
        onChange,
        className,
        error,
        helperText,
        ...otherProps
    } = props

    const valueDate = value && typeof value === "string" && value.match(/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/g) ? dayjs(value, "DD/MM/YYYY") : value

    return (
        <div className={`vsdate ${ !otherProps.label && "vsdate-no-label" } ${ !helperText && "vsdate-no-helper" } ${className}`}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
                <DatePicker
                    format="DD/MM/YYYY"
                    value={valueDate ? dayjs(valueDate) : null}
                    onChange={(value, { validationError }) => {
                        onChange && onChange(validationError ? "" : value.format("DD/MM/YYYY"))
                    }}
                    slotProps={{
                        textField: {
                            type: "tel",
                            size: "small",
                            variant: "outlined",
                            placeholder: "00/00/0000",
                            helperText: helperText,
                            error: error,
                            InputLabelProps: {
                                shrink: true
                            }
                        }
                    }}
                    {...otherProps}
                />
            </LocalizationProvider>
        </div>
    )
}

export default DateInput
