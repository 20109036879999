import { ReactComponent as IconAppointment } from '../../../../assets/icon-patient-appointment.svg'
import { ReactComponent as IconAttachment } from '../../../../assets/icon-patient-attachment.svg'
import { ReactComponent as IconVaccine } from '../../../../assets/icon-patient-vaccine.svg'
import { ReactComponent as IconDrug } from '../../../../assets/icon-patient-drug.svg'
import { ReactComponent as IconPrescription } from '../../../../assets/icon-patient-prescription.svg'
import { ReactComponent as IconExam } from '../../../../assets/icon-patient-exam.svg'
import { ReactComponent as IconTerm } from '../../../../assets/icon-patient-term.svg'
import { ReactComponent as IconSurgery } from '../../../../assets/icon-patient-surgery.svg'
import { ReactComponent as IconReturn } from '../../../../assets/icon-return.svg'
import { ReactComponent as IconWeight } from '../../../../assets/icon-patient-weight.svg'

import { deleteAppointment, showAppointment } from '../appointment/Appointment'
import { deleteAttachment, showAttachment } from '../attachment/Attachment'
import { deleteAppointmentDrug } from '../appointmentDrug/AppointmentDrug'
import { deletePrescription, showPrescription } from '../prescription/Prescription'
import { deleteExam } from '../exam/Exam'
import { deleteTerm, showTerm } from '../term/Term'
import { deleteSurgery, showSurgery } from '../surgery/Surgery'
import { deleteReturn, showReturn } from '../return/Return'
import { deleteWeight, showWeight } from '../weight/Weight'
import { deleteVaccine, getType } from '../vaccine/Vaccine'
import { SALE, deleteSale } from '../sale/Sale'
import AppointmentView from '../appointment/AppointmentView'
import AttachmentView from '../attachment/AttachmentView'
import AppointmentDrugView from '../appointmentDrug/AppointmentDrugView'
import PrescriptionView from '../prescription/PrescriptionView'
import ExamView from '../exam/ExamView'
import TermView from '../term/TermView'
import SurgeryView from '../surgery/SurgeryView'
import ReturnView from '../return/ReturnView'
import WeightView from '../weight/WeightView'
import VaccineListView from '../vaccine/VaccineListView'
import SaleView from '../sale/SaleView'
import { addEvent } from '../../../../utils'

export const includeDocuments = (query) => {
    query.include("patient");
    query.include("patient.owner");
    query.include("attachment");
    query.include("appointment");
    query.include("vaccine");
    query.include("vaccine.attachment");
    query.include("appointmentDrug");
    query.include("examRequest");
    query.include("prescription");
    query.include("term");
    query.include("surgery");
    query.include("return");
    query.include("weight");
    query.include("sale");
    query.include("sale.services.object");
    query.include("sale.services.supplies.object");
    query.include("createdBy");
    query.include("changedBy");
    return query
}

export const historyDocument = (history) => {
    const patientOfHistory = history.get("patient")
    if (history.has('appointment') || history.className == "Appointment") {
        const appointment = history.get("appointment") || history
        return {
            object: appointment,
            icon: (color = "var(--vsprontuario-gray-color)") => <IconAppointment width="24px" height="24px" fill={color} />,
            document: showAppointment(appointment),
            male: false,
            view: AppointmentView,
            edit: `/animal/${patientOfHistory.id}/consulta/${appointment.id}`,
            delete: object => deleteAppointment(object, () => addEvent("History__getList", {}))
        }
    } else if (history.has('attachment') || history.className == "Attachment") {
        const attachment = history.get("attachment") || history
        return {
            object: attachment,
            icon: (color = "var(--vsprontuario-gray-color)") => <IconAttachment width="24px" height="24px" fill={color} />,
            document: `Anexo de ${ showAttachment(attachment) }`,
            male: true,
            view: AttachmentView,
            edit: `/animal/${patientOfHistory.id}/anexo/${attachment.id}`,
            delete: object => deleteAttachment(object, () => addEvent("History__getList", {}))
        }
    } else if (history.has('vaccine') || history.className == "Vaccine") {
        const vaccine = history.get("vaccine") || history
        return {
            object: vaccine,
            icon: (color = "var(--vsprontuario-gray-color)") => <IconVaccine width="24px" height="24px" fill={color} />,
            document: `Visualizar ${ getType(vaccine) }`,
            male: false,
            view: VaccineListView,
            edit: `/animal/${patientOfHistory.id}/vacina/${vaccine.id}`,
            delete: object => deleteVaccine(object, () => addEvent("History__getList", {}))
        }
    } else if (history.has('appointmentDrug') || history.className == "AppointmentDrug") {
        const appointmentDrug = history.get("appointmentDrug") || history
        return {
            object: appointmentDrug,
            icon: (color = "var(--vsprontuario-gray-color)") => <IconDrug width="24px" height="24px" fill={color} />,
            document: "Aplicação de Medicamento",
            male: true,
            view: AppointmentDrugView,
            edit: `/animal/${patientOfHistory.id}/medicamento/${appointmentDrug.id}`,
            delete: object => deleteAppointmentDrug(object, () => addEvent("History__getList", {}))
        }
    } else if (history.has('prescription') || history.className == "Prescription") {
        const prescription = history.get("prescription") || history
        return {
            object: prescription,
            icon: (color = "var(--vsprontuario-gray-color)") => <IconPrescription width="24px" height="24px" fill={color} />,
            document: showPrescription(prescription),
            male: false,
            view: PrescriptionView,
            edit: `/prescricao/${patientOfHistory.id}/${prescription.id}`,
            share: `/prescricao/${prescription.id}`,
            delete: object => deletePrescription(object, () => addEvent("History__getList", {}))
        }
    } else if (history.has('examRequest') || history.className == "ExamRequest") {
        const examRequest = history.get("examRequest") || history
        return {
            object: examRequest,
            icon: (color = "var(--vsprontuario-gray-color)") => <IconExam width="24px" height="24px" fill={color} />,
            document: "Solicitação de Exame",
            male: false,
            view: ExamView,
            edit: `/animal/${patientOfHistory.id}/solicitacao/${examRequest.id}`,
            share: `/documento/exame/${examRequest.id}`,
            delete: object => deleteExam(object, () => addEvent("History__getList", {}))
        }
    }  else if (history.has('term') || history.className == "Term") {
        const term = history.get("term") || history
        return {
            object: term,
            icon: (color = "var(--vsprontuario-gray-color)") => <IconTerm width="24px" height="24px" fill={color} />,
            document: showTerm(term),
            male: true,
            view: TermView,
            edit: `/animal/${patientOfHistory.id}/atestado_termo/${term.id}`,
            share: `/documento/atestado_termo/${term.id}`,
            delete: object => deleteTerm(object, () => addEvent("History__getList", {}))
        }
    } else if (history.has('surgery') || history.className == "Surgery") {
        const surgery = history.get("surgery") || history
        return {
            object: surgery,
            icon: (color = "var(--vsprontuario-gray-color)") => <IconSurgery width="24px" height="24px" fill={color} />,
            document: showSurgery(surgery),
            male: false,
            view: SurgeryView,
            edit: `/animal/${patientOfHistory.id}/cirurgia/${surgery.id}`,
            delete: object => deleteSurgery(object, () => addEvent("History__getList", {}))
        }
    } else if (history.has('return') || history.className == "Return") {
        const _return = history.get("return") || history
        return {
            object: _return,
            icon: (color = "var(--vsprontuario-gray-color)") => <IconReturn width="24px" height="24px" fill={color} />,
            document: showReturn(_return),
            male: true,
            view: ReturnView,
            edit: `/animal/${patientOfHistory.id}/retorno/${_return.id}`,
            delete: object => deleteReturn(object, () => addEvent("History__getList", {}))
        }
    } else if (history.has('weight') || history.className == "Weight") {
        const weight = history.get("weight") || history
        return {
            object: weight,
            icon: (color = "var(--vsprontuario-gray-color)") => <IconWeight width="24px" height="24px" fill={color} />,
            document: showWeight(weight),
            male: true,
            view: WeightView,
            edit: `/animal/${patientOfHistory.id}/peso/${weight.id}`,
            delete: object => deleteWeight(object, () => addEvent("History__getList", {}))
        }
    } else if (history.has('sale') || history.className == "MRSale") {
        const sale = history.get("sale") || history
        const type = sale.get("documentType") == SALE ? "venda" : "orcamento"
        return {
            object: sale,
            icon: (color = "var(--vsprontuario-gray-color)") => <IconWeight width="24px" height="24px" fill={color} />,
            document: sale.get("documentType") == SALE ? "Venda" : "Orçamento",
            male: true,
            view: SaleView,
            edit: `/${ type }/${patientOfHistory.id}/${sale.id}`,
            share: `/compartilhar/${sale.id}`,
            delete: object => deleteSale(object, () => addEvent("History__getList", {}))
        }
    }
}