import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react'
import { connect } from 'react-redux';
import Autocomplete from '@mui/lab/Autocomplete';

import {
    showArrayIndex,
    validateDate,
    mandatoryField,
    optionalField,
    dayjs
} from '../../../../utils'

import { ReactComponent as IconAddO } from "../../../../assets/icon-add-o.svg"
import { ReactComponent as IconCloseO } from "../../../../assets/icon-close-o.svg"

import { Checkbox, CircularProgress, FormControlLabel, TextField } from '@mui/material'
import DateInput from '../../../../components/dateInput/DateInput';
import { getVaccineProducts, vaccineTypeOptions } from '../vaccine/Vaccine';
import { defaultVaccine } from './Term';
import TextFieldMask from '../../../../components/textFieldMask/TextFieldMask';

const TermVaccineForm = forwardRef((props, ref) => {

    const {
        patient,
        setIncludeVaccines,
        vaccineNotes,
        setVaccineNotes,
        vaccines,
        setVaccines,
        clinic,
        healthTerm // Atestado Sanitário
    } = props

    const defaultVaccineWithValidations = Object.assign(defaultVaccine, {
        errorVaccineType: false,
        helperVaccineType: mandatoryField,
        errorManufacturingBatchPart: false,
        helperManufacturingBatchPart: mandatoryField,
        manufacturingDateText: "",
        errorManufacturingDate: false,
        helperManufacturingDate: mandatoryField,
        expirationDateText: "",
        errorExpirationDate: false,
        helperExpirationDate: mandatoryField,
        errorDocumentDate: false,
        helperDocumentDate: mandatoryField,
    })

    const [ vaccineOptions, setVaccineOptions ] = useState([])

    const [ vaccineTab, setVaccineTab ] = useState("manual")

    const [ vaccinesLoading, setVaccinesLoading ] = useState(false)
    const [ vaccinesLoaded, setVaccinesLoaded ] = useState(false)

    const [ vaccinesManual, setVaccinesManual ] = useState([{ ...defaultVaccineWithValidations }])
    const [ errorVaccinesManual, setErrorVaccinesManual ] = useState(false)

    const [ vaccinesImport, setVaccinesImport ] = useState([{ ...defaultVaccineWithValidations }])
    const [ errorVaccinesImport, setErrorVaccinesImport ] = useState(false)

    const setVaccineManual = (index, field, value) => {
        let newVaccines = [...vaccinesManual]
        newVaccines[index][field] = value
        setVaccinesManual(newVaccines)
    }

    const removeVaccineManual = (index) => {
        let newVaccines = [...vaccinesManual]
        newVaccines.splice(index, 1)
        setVaccinesManual(newVaccines)
    }

    const setVaccineImport = (index, field, value) => {
        let newVaccines = [...vaccinesImport]
        newVaccines[index][field] = value
        setVaccinesImport(newVaccines)
    }

    useImperativeHandle(ref, () => ({
        fieldsWithErrorText(errors) {
            if (vaccineTab == "manual") {
                if (errorVaccinesManual) {
                    errors.push("Nenhuma vacina foi adicionada")
                } else {
                    vaccinesManual.map((vaccine, index) => {
                        if (vaccine.errorVaccineType || vaccine.errorManufacturingBatchPart || vaccine.errorManufacturingDate || vaccine.errorExpirationDate || vaccine.errorDocumentDate) {
                            errors.push(`A vacina ${showArrayIndex(index + 1)} possui campos com erro de preenchimento`)
                        }
                    })
                }
            } else {
                if (errorVaccinesImport) {
                    errors.push("Nenhuma vacina foi adicionada")
                } else {        
                    vaccinesImport.map((vaccine, index) => {
                        if (vaccine.checked) {
                            if (vaccine.errorManufacturingBatchPart || vaccine.errorManufacturingDate || vaccine.errorExpirationDate || vaccine.errorDocumentDate) {
                                errors.push(`A vacina ${showArrayIndex(index + 1)} possui campos com erro de preenchimento`)
                            }
                        }
                    })
                }
            }
            return errors
        },
        isValid() {
            if (vaccineTab == "manual") {
                return !errorVaccinesManual && vaccinesManual.filter(vaccine => vaccine.errorVaccineType || vaccine.errorManufacturingBatchPart || vaccine.errorManufacturingDate || vaccine.errorExpirationDate || vaccine.errorDocumentDate).length == 0
            } else {
                return !errorVaccinesImport && vaccinesImport.filter(vaccine => vaccine.errorManufacturingBatchPart || vaccine.errorManufacturingDate || vaccine.errorExpirationDate || vaccine.errorDocumentDate).length == 0
            }
        },
        validate(isValid) {
            if (vaccineTab == "manual") {
                if (vaccinesManual.length == 0) {
                    isValid = false
                    setErrorVaccinesManual(true)
                } else {
                    vaccinesManual.map((vaccine, index) => {
                        if (vaccine.vaccineType && vaccine.vaccineType.length >= 3) {
                            setVaccineManual(index, "errorVaccineType", false)
                            setVaccineManual(index, "helperVaccineType", mandatoryField)
                        } else {
                            isValid = false
                            setVaccineManual(index, "errorVaccineType", true)
                            setVaccineManual(index, "helperVaccineType", "Digite mais que 3 caracteres")
                        }

                        if (vaccine.manufacturingBatchPart && vaccine.manufacturingBatchPart.length >= 3) {
                            setVaccineManual(index, "errorManufacturingBatchPart", false)
                            setVaccineManual(index, "helperManufacturingBatchPart", mandatoryField)
                        } else {
                            isValid = false
                            setVaccineManual(index, "errorManufacturingBatchPart", true)
                            setVaccineManual(index, "helperManufacturingBatchPart", "Digite mais que 3 caracteres")
                        }

                        if (vaccine.manufacturingDate) {
                            const isFuture = dayjs(vaccine.manufacturingDate).diff(dayjs().startOf('month'), "month") > 0
                            const isTooPast = dayjs(vaccine.manufacturingDate).diff(dayjs().startOf('month'), "years") <= -25
                            if (isFuture) {
                                isValid = false
                                setVaccineManual(index, "errorManufacturingDate", true)
                                setVaccineManual(index, "helperManufacturingDate", "A data de fabricação precisa ser menor que hoje")
                            } else if (isTooPast) {
                                isValid = false
                                setVaccineManual(index, "errorManufacturingDate", true)
                                setVaccineManual(index, "helperManufacturingDate", "A data de fabricação não pode ser há mais de 25 anos")
                            } else {
                                setVaccineManual(index, "errorManufacturingDate", false)
                                setVaccineManual(index, "helperManufacturingDate", mandatoryField)
                            }
                        } else {
                            isValid = false
                            setVaccineManual(index, "errorManufacturingDate", true)
                            setVaccineManual(index, "helperManufacturingDate", "A data de fabricação da vacina é obrigatória")
                        }

                        if (vaccine.expirationDate) {
                            const isTooFuture = dayjs(vaccine.expirationDate).diff(dayjs().startOf('month'), "years") >= 25
                            const isTooPast   = dayjs(vaccine.expirationDate).diff(dayjs().startOf('month'), "years") <= -25
                            if (isTooFuture) {
                                isValid = false
                                setVaccineManual(index, "errorExpirationDate", true)
                                setVaccineManual(index, "helperExpirationDate", "A data de validade não pode exceder 25 anos")
                            } else if (isTooPast) {
                                isValid = false
                                setVaccineManual(index, "errorExpirationDate", true)
                                setVaccineManual(index, "helperExpirationDate", "A data de validade não pode ser há mais de 25 anos")
                            } else {
                                setVaccineManual(index, "errorExpirationDate", false)
                                setVaccineManual(index, "helperExpirationDate", mandatoryField)
                            }
                        } else {
                            isValid = false
                            setVaccineManual(index, "errorExpirationDate", true)
                            setVaccineManual(index, "helperExpirationDate", "A data de validade da vacina é obrigatória")
                        }

                        if (vaccine.documentDate) {
                            setVaccineManual(index, "errorDocumentDate", false)
                            setVaccineManual(index, "helperDocumentDate", mandatoryField)
                        } else {
                            isValid = false
                            setVaccineManual(index, "errorDocumentDate", true)
                            setVaccineManual(index, "helperDocumentDate", "A data de aplicação da vacina é obrigatória")
                        }
                    })
                }
            } else {
                if (vaccinesImport.length == 0) {
                    isValid = false
                    setErrorVaccinesImport(true)
                } else {
                    vaccinesImport.map((vaccine, index) => {
                        if (!vaccine.checked) {
                            setVaccineImport(index, "errorManufacturingBatchPart", false)
                            setVaccineImport(index, "helperManufacturingBatchPart", mandatoryField)
                            setVaccineImport(index, "errorManufacturingDate", false)
                            setVaccineImport(index, "helperManufacturingDate", mandatoryField)
                            setVaccineImport(index, "errorExpirationDate", false)
                            setVaccineImport(index, "helperExpirationDate", mandatoryField)
                            setVaccineImport(index, "errorDocumentDate", false)
                            setVaccineImport(index, "helperDocumentDate", mandatoryField)
                        } else {
                            if (vaccine.manufacturingBatchPart && vaccine.manufacturingBatchPart.length >= 3) {
                                setVaccineImport(index, "errorManufacturingBatchPart", false)
                                setVaccineImport(index, "helperManufacturingBatchPart", mandatoryField)
                            } else {
                                isValid = false
                                setVaccineImport(index, "errorManufacturingBatchPart", true)
                                setVaccineImport(index, "helperManufacturingBatchPart", "O número da partida não pode ser menor que 3 caracteres")
                            }
    
                            if (vaccine.manufacturingDate) {
                                const isPast = dayjs(vaccine.manufacturingDate).diff(dayjs(), "days") < 0
                                if (isPast) {
                                    setVaccineImport(index, "errorManufacturingDate", false)
                                    setVaccineImport(index, "helperManufacturingDate", mandatoryField)
                                } else {
                                    isValid = false
                                    setVaccineImport(index, "errorManufacturingDate", true)
                                    setVaccineImport(index, "helperManufacturingDate", "A data de fabricação precisa ser menor que hoje")
                                }
                            } else {
                                isValid = false
                                setVaccineImport(index, "errorManufacturingDate", true)
                                setVaccineImport(index, "helperManufacturingDate", "A data de fabricação da vacina é obrigatória")
                            }

                            if (vaccine.expirationDate) {
                                setVaccineImport(index, "errorExpirationDate", false)
                                setVaccineImport(index, "helperExpirationDate", mandatoryField)
                            } else {
                                isValid = false
                                setVaccineImport(index, "errorExpirationDate", true)
                                setVaccineImport(index, "helperExpirationDate", "A data de validade da vacina é obrigatória")
                            }

                            if (vaccine.documentDate) {
                                setVaccineImport(index, "errorDocumentDate", false)
                                setVaccineImport(index, "helperDocumentDate", mandatoryField)
                            } else {
                                isValid = false
                                setVaccineImport(index, "errorDocumentDate", true)
                                setVaccineImport(index, "helperDocumentDate", "A data de aplicação da vacina é obrigatória")
                            }
                        }
                    })
                }
            }
    
            return isValid
        }
    }))

    useEffect(() => {
        if (vaccines && vaccines.length > 0 && vaccines.filter(v => v.id).length == 0) {
            setVaccinesManual(
                vaccines.map(vaccine => Object.assign(
                    {},
                    { ...defaultVaccineWithValidations },
                    {
                        id: null,
                        vaccineType: vaccine.vaccineType,
                        vaccine: vaccine.vaccine,
                        companyName: vaccine.companyName,
                        manufacturingBatchPart: vaccine.manufacturingBatchPart,
                        manufacturingDate: vaccine.manufacturingDate,
                        manufacturingDateText: dayjs(vaccine.manufacturingDate).format("MM/YYYY"),
                        expirationDate: vaccine.expirationDate,
                        expirationDateText: dayjs(vaccine.expirationDate).format("MM/YYYY"),
                        documentDate: vaccine.documentDate,
                        documentDateText: dayjs(vaccine.documentDate).format("DD/MM/YYYY")
                    }
                ))
            )
        }
        if (!vaccinesLoaded) {
            setVaccinesLoading(true)
            
            var query = new Parse.Query("Vaccine")
            query.equalTo("clinic", clinic.object);
            query.equalTo("isDeleted", false);
            query.equalTo("patient", patient);
            query.equalTo("isReminder", false);
            query.descending("documentDate");
            query.limit(1000)
            query.find()
            .then(_vaccines => {
                var hasImport = false
                const _vaccinesImport = _vaccines.map(vaccine => {
                    const vaccineEdit = vaccines.filter(_vaccine => vaccine.id == _vaccine.id).length == 1
                    const manufacturingDate = vaccine.get("manufacturingDate")
                    const expirationDate = vaccine.get("expirationDate")
                    const documentDate = vaccine.get("documentDate")
                    if (vaccineEdit) {
                        hasImport = true
                    }
                    return Object.assign(
                        {},
                        defaultVaccineWithValidations, 
                        {
                            checked: vaccineEdit,
                            id: vaccine.id,
                            documentDate: vaccine.get("documentDate"),
                            vaccineType: vaccine.get("vaccineType"),
                            vaccineId: vaccine.get("vaccineId"),
                            vaccine: vaccine.get("vaccine"),
                            companyName: vaccine.get("companyName"),
                            manufacturingBatchPart: vaccine.get("manufacturingBatchPart"),
                            manufacturingDate: manufacturingDate,
                            manufacturingDateText: manufacturingDate ? dayjs(manufacturingDate).format("MM/YYYY") : "",
                            expirationDate: expirationDate,
                            expirationDateText: expirationDate ? dayjs(expirationDate).format("MM/YYYY") : "",
                            documentDate: documentDate,
                            documentDateText: documentDate ? dayjs(documentDate).format("DD/MM/YYYY") : "",
                        }
                    )
                })
                setVaccinesImport(_vaccinesImport)
                setVaccinesLoading(false)
                setVaccinesLoaded(true)
                if (hasImport) {
                    setVaccineTab("import")
                }
            })
            .catch(error => {
                console.error(error)
                setVaccinesLoading(false)
                setVaccinesLoaded(true)
            })
        }

        setIncludeVaccines(true)
        return () => {
            setIncludeVaccines(false)
        }
    }, [])

    useEffect(() => {
        getVaccineProducts()
        .then(_products => {
            setVaccineOptions(_products)
        })
        .catch(error => {
            console.error(error)
        })
    }, [])

    useEffect(() => {
        const _vaccines = vaccineTab == "manual" ? vaccinesManual : vaccinesImport.filter(vaccine => vaccine.checked)
        setVaccines(
            _vaccines.map(vaccine => ({
                id: vaccine.id,
                vaccineType: vaccine.vaccineType,
                vaccineId: vaccine.vaccineId,
                vaccine: vaccine.vaccine,
                companyName: vaccine.companyName,
                manufacturingBatchPart: vaccine.manufacturingBatchPart,
                manufacturingDate: vaccine.manufacturingDate,
                expirationDate: vaccine.expirationDate,
                documentDate: vaccine.documentDate,
            }))
        )
    }, [ 
        vaccineTab,
        JSON.stringify(vaccinesImport.map(v => ({ checked: v.checked, vaccineType: v.vaccineType, errorVaccineType: v.errorVaccineType, vaccine: v.vaccine, vaccineOptions: v.vaccineOptions, companyName: v.companyName, manufacturingBatchPart: v.manufacturingBatchPart, errorManufacturingBatchPart: v.errorManufacturingBatchPart, manufacturingDate: v.manufacturingDate, errorManufacturingDate: v.errorManufacturingDate, expirationDate: v.expirationDate, errorExpirationDate: v.errorExpirationDate, documentDate: v.documentDate, errorDocumentDate: v.errorDocumentDate }))),
        JSON.stringify(vaccinesManual.map(v => ({                     vaccineType: v.vaccineType, errorVaccineType: v.errorVaccineType, vaccine: v.vaccine, vaccineOptions: v.vaccineOptions, companyName: v.companyName, manufacturingBatchPart: v.manufacturingBatchPart, errorManufacturingBatchPart: v.errorManufacturingBatchPart, manufacturingDate: v.manufacturingDate, errorManufacturingDate: v.errorManufacturingDate, expirationDate: v.expirationDate, errorExpirationDate: v.errorExpirationDate, documentDate: v.documentDate, errorDocumentDate: v.errorDocumentDate }))) 
    ])

    return (
        <div className="row">
            <div className="col">
                { !healthTerm && (
                    <div className="row pt-4 mb-1">
                        <div className="col-12">
                            <div className="input-title input-height">
                                Vacinas aplicadas
                            </div>
                        </div>
                    </div>
                )}
                { 
                    vaccinesLoading ? (
                        <div className="data-loading">
                            <CircularProgress />
                        </div>
                    ) : (
                        <div>
                            <div className="row mb-1">
                                <div className="col-12">
                                    <div className="btn-tabs mb-3">
                                        <div
                                            className={`btn-tab ${vaccineTab == "manual" ? 'active' : ''}`}
                                            onClick={() => setVaccineTab("manual")}
                                        >
                                            Cadastro Manual
                                        </div>
                                        <div
                                            className={`btn-tab ${vaccineTab == "import" ? 'active' : ''}`}
                                            onClick={() => setVaccineTab("import")}
                                        >
                                            Usar Registros do Prontuário
                                        </div>
                                    </div>
                                </div>
                            </div>
                            { vaccineTab == "manual" ? (
                                <>
                                    { vaccinesManual.map((vaccine, index) => (
                                        <React.Fragment key={index}>
                                            <div className="row align-items-center mb-3">
                                                <div className="col">
                                                    <div className="input-array py-1">
                                                        Vacina { showArrayIndex(index + 1) }
                                                    </div>
                                                </div>
                                                { vaccinesManual.length > 1 && (
                                                    <div className="col-auto px-0">
                                                        <button
                                                            className="btn-array px-0"
                                                            onClick={() => removeVaccineManual(index)}
                                                        >
                                                            <IconCloseO width="24" height="24" />
                                                            Remover Vacina
                                                        </button>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="row align-items-center mb-4">
                                                <div className="col-12 col-lg-6">
                                                    <Autocomplete
                                                        onChange={(_, newValue) => setVaccineManual(index, "vaccineType", newValue || "")}
                                                        onInputChange={(event, newInputValue) => event && setVaccineManual(index, "vaccineType", newInputValue || "")}
                                                        inputValue={vaccine.vaccineType}
                                                        options={vaccineTypeOptions}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                size="small"
                                                                variant="outlined"
                                                                fullWidth
                                                                placeholder="Selecione o tipo da vacina (V8, V10, Raica, etc.)"
                                                                label={`Tipo da Vacina ${ showArrayIndex(index + 1) }`}
                                                                error={vaccine.errorVaccineType}
                                                                helperText={vaccine.helperVaccineType}
                                                            />
                                                        )}
                                                        openOnFocus
                                                        freeSolo
                                                    />
                                                </div>
                                                <div className="col-12 col-lg-6">
                                                    <Autocomplete
                                                        onChange={(_, newValue) => {
                                                            setVaccineManual(index, "vaccineId", (newValue && newValue.itemId) || "")
                                                            setVaccineManual(index, "vaccine", (newValue && newValue.itemName) || "")
                                                        }}
                                                        onInputChange={(event, newInputValue) => {
                                                            if (event) {
                                                                setVaccineManual(index, "vaccineId", "")
                                                                setVaccineManual(index, "vaccine", newInputValue || "")
                                                            }
                                                        }}
                                                        getOptionLabel={(option) => (option && option.itemName) || option}
                                                        inputValue={vaccine.vaccine || ""}
                                                        options={vaccineOptions}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                size="small"
                                                                variant="outlined"
                                                                fullWidth
                                                                placeholder="Selecione a vacina"
                                                                label={`Nome do Produto ${ showArrayIndex(index + 1) }`}
                                                                helperText={optionalField}
                                                            />
                                                        )}
                                                        openOnFocus
                                                        freeSolo
                                                    />
                                                </div>
                                            </div>
                                            <div className="row align-items-center mb-4">
                                                <div className="col-12 col-lg-6">
                                                    <TextField
                                                        label="Nome do Fabricante"
                                                        value={vaccine.companyName}
                                                        onChange={({ target: { value } }) => setVaccineManual(index, "companyName", value)}
                                                        helperText={optionalField}
                                                        size="small"
                                                        variant="outlined"
                                                        fullWidth
                                                    />
                                                </div>
                                                <div className="col-12 col-lg-6">
                                                    <TextField
                                                        label="Número da Partida"
                                                        value={vaccine.manufacturingBatchPart}
                                                        onChange={({ target: { value } }) => setVaccineManual(index, "manufacturingBatchPart", value)}
                                                        error={vaccine.errorManufacturingBatchPart}
                                                        helperText={vaccine.helperManufacturingBatchPart}
                                                        size="small"
                                                        variant="outlined"
                                                        fullWidth
                                                    />
                                                </div>
                                            </div>
                                            <div className="row align-items-center mb-4">
                                                <div className="col-12 col-lg-6">
                                                    <TextFieldMask
                                                        type="tel"
                                                        className="w-100 my-0"
                                                        mask="00/0000"
                                                        placeholder="00/0000"
                                                        label="Data de Fabricação"
                                                        value={vaccine.manufacturingDateText}
                                                        error={vaccine.errorManufacturingDate}
                                                        helperText={vaccine.helperManufacturingDate}
                                                        onAccept={(value) => {
                                                            setVaccineManual(index, "manufacturingDate", validateDate("01/" + value))
                                                            setVaccineManual(index, "manufacturingDateText", value)
                                                        }}
                                                        size="small"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputLabelProps={{ shrink: true }}
                                                    />
                                                </div>
                                                <div className="col-12 col-lg-6">
                                                    <TextFieldMask
                                                        type="tel"
                                                        className="w-100 my-0"
                                                        mask="00/0000"
                                                        placeholder="00/0000"
                                                        label="Data de Validade"
                                                        value={vaccine.expirationDateText}
                                                        error={vaccine.errorExpirationDate}
                                                        helperText={vaccine.helperExpirationDate}
                                                        onAccept={(value) => {
                                                            setVaccineManual(index, "expirationDate", validateDate("01/" + value))
                                                            setVaccineManual(index, "expirationDateText", value)
                                                        }}
                                                        size="small"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputLabelProps={{ shrink: true }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row align-items-center mb-4">
                                                <div className="col-12 col-lg-6">
                                                    <DateInput
                                                        className="w-100 my-0"
                                                        placeholder="00/00/0000"
                                                        label="Data de Aplicação"
                                                        size="small"
                                                        variant="outlined"
                                                        value={vaccine.documentDateText || null}
                                                        error={vaccine.errorDocumentDate}
                                                        helperText={vaccine.helperDocumentDate}
                                                        onChange={(value) => {
                                                            setVaccineManual(index, "documentDate", validateDate(value))
                                                            setVaccineManual(index, "documentDateText", value)
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    ))}
                                    <div className="row align-items-center mb-4">
                                        <div className="col-12">
                                            <button
                                                className="btn-add" 
                                                onClick={() => setVaccinesManual(vaccinesManual.concat({ ...defaultVaccineWithValidations }))}
                                            >
                                                <IconAddO width="20" height="20" />
                                                Adicionar Vacina { showArrayIndex(vaccinesManual.length + 1) }
                                            </button>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                vaccinesImport.length > 0 ? (
                                    vaccinesImport.map((vaccine, index) => (
                                        <div className="row" key={index}>
                                            <div className="col">
                                                <div className="row">
                                                    <div className="col">
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={vaccine.checked}
                                                                    onChange={({ target: { checked }}) => {
                                                                        const newVaccines = [...vaccinesImport]
                                                                        newVaccines[index].checked = checked
                                                                        setVaccinesImport(newVaccines)
                                                                    }}
                                                                    color="primary"
                                                                />
                                                            }
                                                            label={`${ vaccine.vaccineType || vaccine.vaccine } (registrado em ${ dayjs(vaccine.documentDate).format("DD/MM/YYYY") })`}
                                                        />
                                                    </div>
                                                </div>
                                                { vaccine.checked && (
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="row align-items-center mb-4">
                                                                <div className="col-12">
                                                                    <Autocomplete
                                                                        onChange={(_, newValue) => {
                                                                            setVaccineImport(index, "vaccineId", (newValue && newValue.itemId) || "")
                                                                            setVaccineImport(index, "vaccine", (newValue && newValue.itemName) || "")
                                                                        }}
                                                                        onInputChange={(event, newInputValue) => {
                                                                            if (event) {
                                                                                setVaccineImport(index, "vaccineId", "")
                                                                                setVaccineImport(index, "vaccine", newInputValue || "")
                                                                            }
                                                                        }}
                                                                        getOptionLabel={(option) => (option && option.itemName) || option}
                                                                        inputValue={vaccine.vaccine || ""}
                                                                        options={vaccineOptions}
                                                                        renderInput={(params) => (
                                                                            <TextField
                                                                                {...params}
                                                                                size="small"
                                                                                variant="outlined"
                                                                                fullWidth
                                                                                placeholder="Selecione a vacina"
                                                                                label={`Nome do Produto ${ showArrayIndex(index + 1) }`}
                                                                                helperText={optionalField}
                                                                            />
                                                                        )}
                                                                        openOnFocus
                                                                        freeSolo
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row align-items-center mb-4">
                                                                <div className="col-12 col-lg-6">
                                                                    <TextField
                                                                        label="Nome do Fabricante"
                                                                        value={vaccine.companyName}
                                                                        onChange={({ target: { value } }) => setVaccineImport(index, "companyName", value)}
                                                                        helperText={optionalField}
                                                                        size="small"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                    />
                                                                </div>
                                                                <div className="col-12 col-lg-6">
                                                                    <TextField
                                                                        label="Número da Partida"
                                                                        value={vaccine.manufacturingBatchPart}
                                                                        onChange={({ target: { value } }) => setVaccineImport(index, "manufacturingBatchPart", value)}
                                                                        error={vaccine.errorManufacturingBatchPart}
                                                                        helperText={vaccine.helperManufacturingBatchPart}
                                                                        size="small"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row align-items-center mb-4">
                                                                <div className="col-12 col-lg-6">
                                                                    <TextFieldMask
                                                                        type="tel"
                                                                        className="w-100 my-0"
                                                                        mask="00/0000"
                                                                        placeholder="00/0000"
                                                                        label="Data de Fabricação"
                                                                        value={vaccine.manufacturingDateText}
                                                                        error={vaccine.errorManufacturingDate}
                                                                        helperText={vaccine.helperManufacturingDate}
                                                                        onAccept={(value) => {
                                                                            setVaccineImport(index, "manufacturingDate", validateDate("01/" + value))
                                                                            setVaccineImport(index, "manufacturingDateText", value)
                                                                        }}
                                                                        size="small"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        InputLabelProps={{ shrink: true }}
                                                                    />
                                                                </div>
                                                                <div className="col-12 col-lg-6">
                                                                    <TextFieldMask
                                                                        type="tel"
                                                                        className="w-100 my-0"
                                                                        mask="00/0000"
                                                                        placeholder="00/0000"
                                                                        label="Data de Validade"
                                                                        value={vaccine.expirationDateText}
                                                                        error={vaccine.errorExpirationDate}
                                                                        helperText={vaccine.helperExpirationDate}
                                                                        onAccept={(value) => {
                                                                            setVaccineImport(index, "expirationDate", validateDate("01/" + value))
                                                                            setVaccineImport(index, "expirationDateText", value)
                                                                        }}
                                                                        size="small"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        InputLabelProps={{ shrink: true }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row align-items-center mb-4">
                                                                <div className="col-12 col-lg-6">
                                                                    <DateInput
                                                                        type="tel"
                                                                        className="w-100 my-0"
                                                                        placeholder="00/00/0000"
                                                                        label="Data de Aplicação"
                                                                        size="small"
                                                                        variant="outlined"
                                                                        value={vaccine.documentDateText || null}
                                                                        error={vaccine.errorDocumentDate}
                                                                        helperText={vaccine.helperDocumentDate}
                                                                        onChange={(value) => {
                                                                            setVaccineImport(index, "documentDate", validateDate(value))
                                                                            setVaccineImport(index, "documentDateText", value)
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <p className="mb-3">Nenhuma vacina registrada para este animal</p>
                                )
                            )}
                        </div>
                    )
                }
                <div className="row mb-4">
                    <div className="col-12">
                        <div className={`${ healthTerm ? "input-array" : "input-title" } input-height`}>
                            Observações { healthTerm ? "Sobre a Vacinação" : "Gerais" } (opcional)
                        </div>
                    </div>
                    <div className="col-12">
                        <TextField
                            value={vaccineNotes}
                            placeholder={`Escreva aqui as observações ${ !healthTerm ? "gerais" : "" } sobre ${ healthTerm ? "a vacinação" : "o atestado"}`}
                            onChange={({ target: { value } }) => setVaccineNotes(value)}
                            size="small"
                            variant="outlined"
                            fullWidth
                            multiline
                        />
                    </div>
                </div>
            </div>
        </div>
    )
})

const mapStateToProps = state => {
    return {
        clinic: state.clinic
    };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(TermVaccineForm);