import React, { useRef, useState } from 'react'
import { connect } from 'react-redux'
import { Chip, CircularProgress, InputAdornment, Popover, TextField } from '@mui/material'

import { Autocomplete } from '@mui/lab'
import { addEvent, dayjs, getSelectedOptionLabel, setAmericaFortalezaTimezone } from '../../utils'
import { CALENDAR_BLANK, CALENDAR_BLOCK, deleteCalendar, isDefaultCalendar, statusOptions } from './Calendar'
import { ACL } from '../../constants'

import { ReactComponent as IconArrowLeft } from '../../assets/icon-arrow-left.svg'
import { ReactComponent as IconAttachment } from '../../assets/icon-patient-attachment.svg'
import { ReactComponent as IconCalendarCheck } from '../../assets/icon-calendar-check.svg'
import { ReactComponent as IconCalendarX } from '../../assets/icon-calendar-x.svg'
import { ReactComponent as IconCategory } from '../../assets/icon-category.svg'
import { ReactComponent as IconClose } from '../../assets/icon-close.svg'
import { ReactComponent as IconHealth } from '../../assets/icon-health.svg'
import { ReactComponent as IconEdit } from "../../assets/icon-primary-edit.svg"
import { ReactComponent as IconMessage } from "../../assets/icon-share.svg"
import { ReactComponent as IconPaw } from '../../assets/icon-paw.svg'
import { ReactComponent as IconRecurrence } from '../../assets/icon-resend.svg'
import { ReactComponent as IconTrash } from "../../assets/icon-primary-trash.svg"
import { ReactComponent as IconTag } from '../../assets/icon-tag.svg'
import { ReactComponent as IconTextAlign } from '../../assets/icon-text-align.svg'
import { ReactComponent as IconUser } from '../../assets/icon-user.svg'
import { ReactComponent as IconUserO } from '../../assets/icon-user-o.svg'

import VSAccordion from '../../components/vsAccordion/VSAccordion'

import "./CalendarView.scss"
const CalendarView = (props, ref) => {

    const {
        history,
        object,
        onClose,
        getAnchorPosition,
        permissions,
        anchorOrigin = {
            vertical: 'center',
            horizontal: 'right',
        },
        transformOrigin = {
            vertical: 'center',
            horizontal: 'right',
        }
    } = props

    const popoverRef = useRef()

    const open = Boolean(object)

    const user = object?.get("user")
    const tags = object?.get("tags")
    const sale = object?.get("sale")
    const services = object?.get("services")
    const patient = object?.get("patient")
    const owner = patient?.get("owner")

    const viewRef = useRef(null)

    const [ savingStatus, setSavingStatus ] = useState(false)
    const [ status, setStatus ] = useState(object.get("status") || "")

    const onStatusChange = (_, { value }) => {
        if (value) {
            setSavingStatus(true)

            Parse.Cloud.run("updateCalendarForClinic", {
                data: {
                    status: value
                },
                objectId: object.id,
                clinicId: object.get("clinic").id,
                patientId: object.get("patient").id
            })
            .then(_calendar => {
                setStatus(value)
                setSavingStatus(false)
                addEvent("Calendar__getList")
            })
            .catch(error => {
                setSavingStatus(false)
                console.error(error)
                Swal.fire(
                    'Desculpe',
                    `Ocorreu algum erro ao tentar editar o status do evento`,
                    'error'
                )
            })
        }
    }

    const updatePopoverPosition = () => {
        const updatePositionWhileAccordionToggle = setInterval(() => {
            if (popoverRef.current) {
                popoverRef.current.updatePosition()
            } else {
                clearInterval(updatePositionWhileAccordionToggle)
            }
        }, 1)
        setTimeout(() => {
            clearInterval(updatePositionWhileAccordionToggle)
        }, 2000)
    }

    const isDefault = isDefaultCalendar(object.get("status"))
    
    return (
        <Popover
            id="calendar-element-view"
            action={popoverRef}
            open={open}
            onClose={() => onClose(object)}
            anchorReference="anchorPosition"
            anchorPosition={getAnchorPosition()}
            anchorOrigin={anchorOrigin}
            transformOrigin={transformOrigin}
        >
            <div className="element-header">
                <div className="row g-3 align-items-center">
                    <div className="col">
                        Detalhes do Evento
                    </div>
                    <div className="col-auto">
                        <div className="icon-close">
                            <IconClose width={24} height={24} fill="#808080" className="cursor-pointer" onClick={() => onClose(object)} />
                        </div>
                    </div>
                </div>
            </div>
            <div ref={viewRef} className="element-body">
                <div className="row g-3">
                    <div className="col-12">
                        <div className="row g-3 align-items-center">
                            <div className="col text-title text-ellipsis">
                                { object.get("title") || "Sem título" }
                            </div>
                        </div>
                    </div>
                    { object.get("category") && (
                        <div className="col-12">
                            <div className="row g-3 align-items-center">
                                <div className="col-auto">
                                    <IconCategory width={16} height={16} fill="#666666" />
                                </div>
                                <div className="col text-ellipsis">
                                    { object.get("category") }
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="col-12">
                        <div className="row g-3 align-items-center">
                            <div className="col-auto">
                                <IconCalendarCheck width={16} height={16} fill="#7A7A7A" />
                            </div>
                            <div className="col">
                                { dayjs(setAmericaFortalezaTimezone(object.get("startDate"))).format("DD/MM/YYYY [às] HH:mm") }{ dayjs(setAmericaFortalezaTimezone(object.get("endDate"))).format(" - HH:mm") }
                            </div>
                        </div>
                    </div>
                    { isDefault && (
                        <>
                            <div className="col-12">
                                <div className="row g-3 align-items-center">
                                    <div className="col-auto">
                                        <IconUser width={16} height={16} fill="#7A7A7A" />
                                    </div>
                                    <div className="col text-ellipsis">
                                        Tutor: <span
                                            className="element-link"
                                            onClick={() => {
                                                history.push(`/tutor/${owner.id}`)
                                                onClose()
                                            }}
                                        >
                                            { owner?.get("name") }
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="row g-3 align-items-center">
                                    <div className="col-auto">
                                        <IconPaw width={16} height={16} fill="#7A7A7A" />
                                    </div>
                                    <div className="col text-ellipsis">
                                        Animal: <span
                                            className="element-link"
                                            onClick={() => {
                                                history.push(`/animal/${patient.id}`)
                                                onClose()
                                            }}
                                        >
                                            { patient?.get("name") }
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="row g-3">
                                    <div className="col-auto">
                                        <IconHealth width={16} height={16} fill="#7A7A7A" />
                                    </div>
                                    <div className="col">
                                        { services?.length > 0 ? (
                                            <VSAccordion
                                                toggleExpanded={updatePopoverPosition}
                                                header={(expanded) => (
                                                    <>{ services.length } serviço{ services.length > 1 ? "s" : "" } <IconArrowLeft width={16} height={12} fill="#B3B3B3" className="ms-1" style={{ transform: `rotate(${ expanded ?  "90deg" : "270deg" })` }}/></>
                                                )}
                                            >
                                                <div className="row g-3">
                                                    { services.map((service, index) => (
                                                        <div key={index} className="col-12">
                                                            <b>{ service.serviceName }</b>
                                                        </div>
                                                    )) }
                                                </div>
                                            </VSAccordion>
                                        ) : (
                                            "Serviços não informados"
                                        )}
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                    <div className="col-12">
                        <div className="row g-3 align-items-center">
                            <div className="col-auto">
                                <IconUserO width={16} height={16} fill="#7A7A7A" />
                            </div>
                            <div className="col text-ellipsis">
                                Responsável: { user?.get("fullName") || "Todos os profissionais" }
                            </div>
                        </div>
                    </div>
                    { isDefault && (
                        <>
                            <div className="col-12">
                                <div className="row g-3">
                                    <div className="col-auto">
                                        <IconTag width={16} height={16} fill="#7A7A7A" />
                                    </div>
                                    <div className="col">
                                        { tags?.length > 0 ? (
                                            <VSAccordion
                                                toggleExpanded={updatePopoverPosition}
                                                header={(expanded) => (
                                                    <>{ tags.length } Tag{ tags.length > 1 ? "s" : "" } <IconArrowLeft width={16} height={12} fill="#B3B3B3" className="ms-1" style={{ transform: `rotate(${ expanded ?  "90deg" : "270deg" })` }}/></>
                                                )}
                                            >
                                                <div className="row g-3">
                                                    { tags.map((tag, index) => (
                                                        <div key={index} className="col-auto">
                                                            <Chip
                                                                color="primary"
                                                                size="small"
                                                                className="px-1"
                                                                label={tag.get("name")}
                                                                style={{
                                                                    backgroundColor: tag.get("color")
                                                                }}
                                                            />
                                                        </div>
                                                    )) }
                                                </div>
                                            </VSAccordion>
                                        ) : (
                                            "Tags não informadas"
                                        )}
                                    </div>
                                </div>
                            </div>
                            { sale && (
                                <div className="col-12">
                                    <div className="row g-3">
                                        <div className="col-auto">
                                            <IconAttachment width={16} height={16} fill="#7A7A7A" />
                                        </div>
                                        <div className="col">
                                            <span
                                                className="element-link"
                                                onClick={() => {
                                                    history.push(`/compartilhar/${sale.id}`)
                                                    onClose()
                                                }}
                                            >
                                                Ver { sale?.get("documentType") == "sale" ? "venda" : "orçamento" }
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                    <div className="col-12">
                        <div className="row g-3">
                            <div className="col-auto">
                                <IconRecurrence width={16} height={16} fill="#7A7A7A" />
                            </div>
                            <div className="col">
                                Recorrência: { object.get("recurrence") ? "Sim" : "Não" }
                            </div>
                        </div>
                    </div>
                    { isDefault && (
                        <div className="col-12">
                            <div className="row g-3">
                                <div className="col-auto">
                                    <IconMessage width={16} height={16} fill="#7A7A7A" />
                                </div>
                                <div className="col">
                                    Envio de lembrete: { object.get("messages")?.length > 0 ? "Sim" : "Não" }
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="col-12">
                        <div className="row g-3">
                            <div className="col-auto">
                                <IconTextAlign width={16} height={16} fill="#7A7A7A" />
                            </div>
                            <div className="col">
                                { object?.get("notes") ? (
                                    <VSAccordion
                                        toggleExpanded={updatePopoverPosition}
                                        header={(expanded) => (
                                            <>Observações <IconArrowLeft width={16} height={12} fill="#B3B3B3" className="ms-1" style={{ transform: `rotate(${ expanded ?  "90deg" : "270deg" })` }}/></>
                                        )}
                                    >
                                        <div className="row g-3">
                                            <div className="col-12">
                                                <b>{ object.get("notes") }</b>
                                            </div>
                                        </div>
                                    </VSAccordion>
                                ) : (
                                    "Observações não informadas"
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            { (permissions?.check(ACL.CALENDAR, ACL.Rule.EDIT, object) || permissions?.check(ACL.CALENDAR, ACL.Rule.DELETE, object)) && (
                <div className="element-footer">
                    <div className="row g-3 align-items-center justify-content-end">
                        { isDefault && permissions?.check(ACL.CALENDAR, ACL.Rule.EDIT, object) && (
                            <div className="col">
                                <Autocomplete
                                    value={status}
                                    options={statusOptions}
                                    getOptionLabel={(option) => getSelectedOptionLabel(option, statusOptions, status)}
                                    getOptionSelected={(option) => option.value === status}
                                    getOptionDisabled={(option) => option?.disabled || false}
                                    disabled={savingStatus}
                                    onChange={onStatusChange}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder="Status do evento"
                                            size="small"
                                            variant="outlined"
                                            fullWidth
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: savingStatus ? (
                                                    <InputAdornment position="end">
                                                        <CircularProgress size={16} />
                                                    </InputAdornment>
                                                ) : params.InputProps.endAdornment
                                            }}
                                        />
                                    )}
                                    disableClearable
                                    openOnFocus
                                />
                            </div>
                        )}
                        <div className="col-auto">
                            <div className="row g-3">
                                { permissions?.check(ACL.CALENDAR, ACL.Rule.EDIT, object) && (
                                    <div className="col col-md-auto">
                                        <button
                                            className={`vsbox-btn vsbox-btn-dark`}
                                            onClick={() => {
                                                var status
                                                switch (object.get("status")) {
                                                    case CALENDAR_BLANK:
                                                        status = "/branco"
                                                        break
                                                    case CALENDAR_BLOCK:
                                                        status = "/bloqueio"
                                                        break
                                                    default:
                                                        status = ""
                                                }
                                                history.push(`/agenda/${object.id}${status}`, {
                                                    id: object.get("patient")?.id,
                                                    date: object.get("startDate")
                                                })
                                                onClose()
                                            }}
                                        >
                                            <IconEdit width="24" height="24" fill={"var(--vsprontuario-primary-color-dark)"} />
                                        </button>
                                    </div>
                                )}
                                { permissions?.check(ACL.CALENDAR, ACL.Rule.DELETE, object) && (
                                    <div className="col col-md-auto">
                                        <button
                                            className={`vsbox-btn vsbox-btn-alert`}
                                            onClick={() => {
                                                deleteCalendar(object, () => {
                                                    addEvent("Calendar__getList")
                                                    onClose()
                                                })
                                            }}
                                        >
                                            <IconTrash width="24" height="24" fill={"var(--vsprontuario-primary-color)"} />
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </Popover>
    )
}

const mapStateToProps = state => {
    return {
        permissions: state.permissions
    };
};
  
export default connect(mapStateToProps)(CalendarView)