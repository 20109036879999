import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { CircularProgress } from '@mui/material';
import TextField from '@mui/material/TextField';

import { 
    addEvent,
    dayjs,
    saveOnTracker
} from '../../../../utils'
import {
    getPatient
} from '../../Patient'

import { ACL, APP_DEVICE, APP_NAME, APP_VERSION } from '../../../../constants';
import { getWeight, weightScoreOptions } from './Weight';
import { isCanine, isFeline } from '../../Patient';

import DateInput from '../../../../components/dateInput/DateInput';
import VSAccordionSimple from '../../../../components/vsAccordion/VSAccordionSimple';
import VSMedicalRecordsAudit from '../../../../components/vsMedicalRecordsAudit/VSMedicalRecordsAudit';
import VSError from '../../../../components/vsError/VSError';
import VSDrawer from '../../../../components/vsDrawer/VSDrawer';
import VSDrawerInfo from '../../../../components/vsDrawer/VSDrawerInfo';

import './WeightForm.scss'
import VSTextFieldCurrency from '../../../../components/vsTextFieldCurrency/VSTextFieldCurrency';
const WeightForm = (props) => {

    const {
        id,
        id_peso
    } = useParams()

    const {
        clinic,
        history,
        permissions
    } = props

    const update = id_peso !== "novo"
    
    const [ objWeight, setObjWeight ] = useState(null)
    const [ patient, setPatient ] = useState(null)

    const [ loading, setLoading ] = useState(false)
    const [ error, setError ] = useState(null)
    const [ hasSubmited, setHasSubmited ] = useState(false)

    const [ weight, setWeight ] = useState('')
    const [ errorWeight, setErrorWeight ] = useState(false)
    const [ helperWeight, setHelperWeight ] = useState(<span>Campo <b>Obrigatório</b></span>)
    
    const [ visibleScore, setVisibleScore ] = useState(4)
    const [ score, setScore ] = useState(undefined)

    const defaultDate = dayjs()
    const [ documentDate, setDocumentDate ] = useState(defaultDate.toDate())
    const [ documentDateText, setDocumentDateText ] = useState(defaultDate.format("DD/MM/YYYY"))
    const [ errorDocumentDate, setErrorDocumentDate ] = useState(false)
    const [ helperDocumentDate, setHelperDocumentDate ] = useState(<span>Campo <b>Obrigatório</b></span>)
    const [ notes, setNotes ] = useState('')
    
    const _isCanine = isCanine(patient?.get("specie"))
    const _isFeline = isFeline(patient?.get("specie"))

    const handleDocumentDate = (_date) => {
        setDocumentDateText(_date)

        if (_date.match(/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/g)) {
            let newDate = dayjs(_date, "DD/MM/YYYY")
            if (newDate.isValid()) {
                setDocumentDate(newDate.toDate())
                return
            }
        }
        setDocumentDate(null)
    }

    const validateOnTouch = () => {
        if (hasSubmited && (documentDate || documentDateText || weight || notes)) {
            validate()
        }
    }

    const validate = () => {
        let isValid = true
        if (loading) {
            isValid = false
        }

        if (error?.message) {
            isValid = false
        }

        const libDocumentDate = dayjs(documentDateText, "DD/MM/YYYY")
        if (libDocumentDate.isValid()) {
            if (libDocumentDate.format("YYYY") < 1971) {
                isValid = false
                setErrorDocumentDate(true)
                setHelperDocumentDate("A data não pode ser menor que 1971")
            } else if (libDocumentDate.diff(dayjs(), "years") > 5) {
                isValid = false
                setErrorDocumentDate(true)
                setHelperDocumentDate("A data não pode exceder 5 anos")
            } else {
                setErrorDocumentDate(false)
                setHelperDocumentDate(<span>Campo <b>Obrigatório</b></span>)
            }
        } else {
            isValid = false
            setErrorDocumentDate(true)
            setHelperDocumentDate("A data informada não é válida")
        }

        if (parseFloat(weight) <= 0) {
            isValid = false
            setErrorWeight(true)
            setHelperWeight("O peso do animal é obrigatório")
        } else {
            setErrorWeight(false)
            setHelperWeight(<span>Campo <b>Obrigatório</b></span>)
        }

        return isValid
    }

    const isValid = () => !loading && !errorDocumentDate && !errorWeight

    const fieldsWithErrorText = () => {
        let errors = []
        if (loading) {
            errors.push("Salvando a pesagem")
        }
        if (error?.message) {
            errors.push(error.message)
        }
        if (errorDocumentDate) {
            errors.push("Data da pesagem")
        }
        if (errorWeight) {
            errors.push("Peso")
        }
        if (!permissions?.check(ACL.WEIGHT, ACL.Rule.EDIT, objWeight)) {
            errors.push("Acesso negado")
        }
        return errors
    }

    const getPatientOrError = () => {
        setLoading(true)
        setError(null)

        getPatient(id, clinic.object)
        .then(_patient => {
            setPatient(_patient)
        })
        .catch(error => {
            Swal.fire(
                'Desculpe',
                error,
                'error'
            )
            .then(_ => {
                setLoading(false)
                setError({
                    message: error,
                    function: "getPatientOrError"
                })
            })
        })
    }

    const getWeightOrError = () => {
        if (permissions?.check(ACL.WEIGHT, ACL.Rule.VIEW)) {
            saveOnTracker("Iniciou", "Peso", update ? id_peso : "")
            if (update) {
                setLoading(true)
                setError(null)
    
                getWeight(id_peso, patient)
                .then(_weight => {
                    setObjWeight(_weight)
                    setDocumentDate(_weight.get("documentDate"))
                    setDocumentDateText(dayjs(_weight.get("documentDate")).format("DD/MM/YYYY"))
                    setWeight(_weight.get("weight"))
                    setScore(_weight.get("score"))
                    setNotes(_weight.get("notes"))
                    setLoading(false)
                })
                .catch(error => {
                    Swal.fire(
                        'Desculpe',
                        error,
                        'error'
                    )
                    .then(_ => {
                        setLoading(false)
                        setError({
                            message: error,
                            function: "getWeightOrError"
                        })
                    })
                })
            } else {
                let newWeight = new Parse.Object("Weight")
                newWeight.setACL(clinic.ACL)
                newWeight.set("clinic", clinic.object)
                newWeight.set("createdBy", Parse.User.current())
                newWeight.set("createdApp", APP_NAME)
                newWeight.set("createdDevice", APP_DEVICE)
                newWeight.set("createdAppVersion", APP_VERSION)
                newWeight.set("isDeleted", false)
                newWeight.set("patient", patient)
                setObjWeight(newWeight)
                setLoading(false)
            }
        }
    }

    const save = () => {
        return new Promise((resolve, reject) => {
            setHasSubmited(true)
            if (validate()) {
                setLoading(true)
                objWeight.set('weight', weight);
                objWeight.set('documentDate', documentDate);
                objWeight.set('score', score);
                objWeight.set('notes', notes);
                objWeight.set("changedAt", new Date())
                objWeight.set("changedBy", Parse.User.current())
                objWeight.set("changedApp", APP_NAME)
                objWeight.set("changedDevice", APP_DEVICE)
                objWeight.set("changedAppVersion", APP_VERSION)
                objWeight.save()
                .then(_objWeight => {
                    saveOnTracker("Concluiu", "Peso", _objWeight.id)
                    resolve(_objWeight)
                })
                .catch(error => {
                    setLoading(false)
                    console.error(error)
                    Swal.fire(
                        'Desculpe',
                        `Ocorreu algum erro ao tentar ${objWeight.id ? "editar" : "cadastrar"} o peso do animal`,
                        'error'
                    )
                    update && objWeight.revert()
                    reject()
                })
            } else {
                reject()
            }
        })
    }
    
    const renderPreview = () => {
        return (
            <div id="weight-form-preview" className="col-12">
                <div className="row mb-3">
                    <div className="col text-center">
                        <h4 className="">
                            Entenda o Escore de Condição Corporal
                            { _isCanine && " de Cães" }
                            { _isFeline && " de Gatos" }
                        </h4>
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-12">
                        <div className="vsbox-pagination">
                            <div className="row align-items-center justify-content-center">
                                { weightScoreOptions.map((option, index) => (
                                    <div
                                        key={index}
                                        className={`col-auto vsbox-page ${ visibleScore == index ? "vsbox-page-active" : "" }`}
                                        onClick={() => setVisibleScore(index)}
                                    >
                                        { index + 1 }
                                    </div>
                                )) }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <Carousel
                            className="score-carousel"
                            centerMode
                            autoPlay={false}
                            autoFocus={false}
                            showArrows={false}
                            showThumbs={false}
                            showStatus={false}
                            stopOnHover={true}
                            emulateTouch={true}
                            infiniteLoop={true}
                            showIndicators={false}
                            selectedItem={visibleScore}
                            interval={24 * 60 * 60 * 1000}
                            onChange={(index) => setVisibleScore(index)}
                            preventMovementUntilSwipeScrollTolerance
                            swipeScrollTolerance={10}
                        >
                            { weightScoreOptions.map((option, index) => (
                                <div className="score-box" key={index}>
                                    <div className="score-header" style={{ backgroundColor: option.color }}>
                                        { option.label }
                                    </div>
                                    <div className="score-body">
                                        <img src={option[_isFeline ? "feline" : "canine"].image} width="50%" height="auto" />
                                    </div>
                                    <hr />
                                    <div className="score-footer">
                                        { option[_isFeline ? "feline" : "canine"].info }
                                    </div>
                                </div>
                            )) }
                        </Carousel>
                    </div>
                </div>
                <hr className="my-3" />
                <div className="row">
                    <div className="col-12">
                        <VSAccordionSimple
                            header={(expanded) => (
                                `${ expanded ? "Ocultar" : "Ver" } Referências Bibliográficas`
                            )}
                        >
                            <div className="row">
                                <div className="col-12 mb-3">
                                    <ul className="score-biography">
                                        <li>Association for Pet Obesity Prevention (APOP), pesquisa, outubro/11. Disponível em http://www.petobesityprevention.org/.</li>
                                        <li>BISSOT, T et al. Novel dietary strategies can improve the outcome of weight loss programmes in obese client-owned cats. J Feline Med Surg, 12, 104-12, 2010.</li>
                                        <li>CASE, L. P. et al. Canine and feline nutrition. 3 ed. Mosby Elsevier: Missouri. p. 313 - 342, 2011.</li>
                                        <li>GERMAN, A. & MARTIN, L. Feline obesity: epidemiology, pathophysiology and management.</li>
                                        <li>Encyclopedia of feline clinical nutrition. Royal Canin, p. 3 – 49, 2007. Disponível em http://ivis.org/advances/rcfeline/toc.asp.</li>
                                        <li>GERMAN, A. The growing problem of obesity in dogs and cats. Journal of Nutrition, v. 136, p. 1940S–1946S, 2006.</li>
                                        <li>GERMAN, A.; MARTIN, L. Feline obesity: epidemiology, pathophysiology and management.</li>
                                        <li>Encyclopedia of feline clinical nutrition. Royal Canin, p. 3 – 49, 2007. Disponível em: http://ivis.org/advances/rcfeline/toc.asp.</li>
                                        <li>HERNOT, D. C.; DUMON, H. J.; BIOURGE, V. C. et al. Am J Vet Res, v. 67, p. 342-347, 2006.</li>
                                        <li>LAFLAMME D. P. Development and validation of a body condition score system for cats: a clinical tool. Feline practice volume 25 N° 5-6, 1997. </li>
                                        <li>LAFLAMME, D. P. Development and validation of a body condition score system for dogs: a clinical tool. Canine Practice, Santa Barbara, v. 22, n. 3, p. 10- 15, 1997.</li>
                                        <li>LUND, E. M. Prevalence and risk factors for obesity in adult cats from private US veterinary practices. Intern J Appl Res Vet Med, v. 3, p. 88-96, 2005.</li>
                                        <li>SCARLETT, J. M.; DONOGHUE, S.; SAIDLA, J.; WILLS, J. Overweight cats: prevalence and risk factors. Int J Obes Relat Metab Disord, v.18, p. S22–8, 1994.</li>
                                        <li>WEBER, M. et al. Effect of size on electrolyte apparent absorption rates and fermentative activity in dogs. J Anim Physiol a Anim Nutr, v. 88, p. 1-10, 2004.</li>
                                        <li>WEBER, M. P. et al. Influence of age and body size on intestinal permeability and absorption in healthy dogs. Am J Vet Res, v. 63, n. 9, p. 1323-1328, 2002.</li>
                                        <li>WSAVA Nutritional assessment guidelines. Journal of Small Animal Practice, v.52, cap 7, p. 385 - 396, 2011.</li>
                                    </ul>
                                </div>
                            </div>
                        </VSAccordionSimple>
                    </div>
                </div>
            </div>
        )
    }

    useEffect(() => {
        validateOnTouch()
    }, [ documentDate, documentDateText, weight ])

    useEffect(() => {
        if (objWeight && !permissions?.check(ACL.WEIGHT, ACL.Rule.EDIT, objWeight)) {
            permissions?.error()
        }
    }, [ objWeight ])

    useEffect(() => {
        if (patient) {
            getWeightOrError()
        }
    }, [ patient ])
    
    useEffect(() => {
        if (id) {
            getPatientOrError()
        }
    }, [])

    const errors = fieldsWithErrorText()

    return (
        <VSDrawer
            id="weight-form"
            width={_isCanine || _isFeline ? "80%" : "60%"}
            title={update ? "Editar Registro de Peso" : "Novo Registro de Peso"}
            cancel={update ? <span>Cancelar Registro<span className="d-none d-xl-inline"> de Peso</span></span> : <span>Cancelar Novo Registro<span className="d-none d-xl-inline"> de Peso</span></span>}
            submit={isValid ? <span>Salvar Registro<span className="d-none d-xl-inline"> de Peso</span></span> : <span>Preencha os campos<span className="d-none d-md-inline"> necessários</span></span>}
            withVSDrawerInfo
            errors={errors} 
            onSubmit={save}
            onCancel={() => history.goBack()}
            onAfterSave={(object) => {
                history.replace(`/animal/${patient.id}/peso`)
                addEvent("Weight__getList", {})
            }}
            VSDrawerSuccessProps={{
                title: "Registro de Peso Salvo com Sucesso",
                text: ""
            }}
            VSDrawerCancelProps={{
                title: "Tem certeza que deseja cancelar o registro de peso do animal?",
                confirm: update ? "Cancelar Registro de Peso" : "Cancelar Novo Registro de Peso",
                cancel: "Voltar para Edição"
            }}
        >
            { loading ? (
                <div className="row data-loading">
                    <div className="col">
                        <CircularProgress />
                    </div>
                </div>
            ) : error?.message ? (
                <VSError
                    message={error.message}
                    onClose={() => {
                        switch(error.function) {
                            case "getPatientOrError":
                                getPatientOrError()
                                break
                            case "getWeightOrError":
                                getWeightOrError()
                                break
                            default:
                        }
                    }}
                />
            ) : (
                <div className="row">
                    <div className="col">
                        <div className="row align-items-center mb-4">
                            <div className="col-12 col-md">
                                <div className="row">
                                    <div className="col-5 col-sm-4 col-md-auto pe-0">
                                        <div className="input-title input-height">
                                            Peso do animal
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        <VSTextFieldCurrency
                                            value={weight}
                                            hideSymbol
                                            onChangeValue={(event, originalValue, maskedValue) => {
                                                setWeight(originalValue)
                                            }}
                                            style={{ width: "120px" }}
                                            InputElement={(
                                                <TextField
                                                    type="tel"
                                                    placeholder="0,00"
                                                    label="Kg"
                                                    size="small"
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                            )}
                                        />
                                    </div>
                                    { errorWeight && (
                                        <div className="col-12">
                                            <small className="text-danger">{ helperWeight }</small>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="col-12 col-xl-auto mt-3 mt-xl-0">
                                <div className="row align-items-center">
                                    <div className="col-5 col-sm-4 col-xl-auto pe-0">
                                        <div className="input-title input-height">
                                            Data Do registro
                                        </div>
                                    </div>
                                    <div className="col-auto me-1">
                                        <DateInput
                                            className="m-0"
                                            placeholder="00/00/0000"
                                            value={documentDateText || null}
                                            error={errorDocumentDate}
                                            helperText={null}
                                            onChange={(value) => handleDocumentDate(value)}
                                            size="small"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </div>
                                    { errorDocumentDate && (
                                        <div className="col-12">
                                            <small className="text-danger">{ helperDocumentDate }</small>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 mb-2">
                                <div className="input-title input-height">
                                    Escore de condição corporal (opcional)
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="row px-1">
                                    { weightScoreOptions.map((option, index) => (
                                        <div className="col-6 col-md-4 mb-3 px-2" key={index}>
                                            <button
                                                className={`h-100 btn-score btn-score-${ option.value } ${ option.value == score ? "selected" : "" }`}
                                                onClick={() => setScore(option.value)}
                                            >
                                                { option.value } ({ option.label })
                                            </button>
                                        </div>
                                    )) }
                                </div>
                            </div>
                        </div>
                        <div className="row my-3">
                            {(_isCanine || _isFeline) &&
                            <VSDrawerInfo
                                title="Entenda o Escore de Condição Corporal"
                            >
                                { renderPreview() }
                            </VSDrawerInfo>}
                        </div>
                        <div className="row mb-3">
                            <div className="col-12">
                                <div className="input-title input-height">
                                    Anotações (Opcional)
                                </div>
                            </div>
                            <div className="col-12">
                                <TextField
                                    value={notes}
                                    placeholder="Escreva suas anotações sobre o peso aqui"
                                    onChange={({ target: { value } }) => setNotes(value)}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                />
                            </div>
                        </div>
                        <VSMedicalRecordsAudit parseObject={objWeight} />
                    </div>
                </div>
            )}
        </VSDrawer>
    )
}

const mapStateToProps = state => {
    return {
        clinic: state.clinic,
        permissions: state.permissions
    };
};
  
export default connect(mapStateToProps)(WeightForm)