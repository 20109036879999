import ICAL from "ical.js"
import { v4 as uuidv4 } from "uuid"
import { dayjs, firstName } from "../../utils"

import VSModal from "../../components/vsModal/VSModal"
import { APP_DEVICE, APP_NAME, APP_VERSION } from "../../constants"
import { RECURRENCE_ALL, RECURRENCE_FUTURE, RECURRENCE_THIS } from "../recurrence/Recurrence"

export const RRULE_DAILY = "DAILY"
export const RRULE_WEEKLY = "WEEKLY"
export const RRULE_MONTHLY = "MONTHLY"
export const RRULE_YEARLY = "YEARLY"

export const deleteExpense = (expense, callback) => {
	const hasRecurrence = expense.get("recurrence")
    Swal.fire({
        // allowOutsideClick: false,
        showConfirmButton: false,
        customClass: {
            popup: 'p-0',
            content: 'p-0',
        },
        html: (
            <VSModal
                title="Tem certeza que deseja excluir esta despesa?"
                text="Os dados não poderão ser recuperados e esta operação não poderá ser desfeita"
                selectText={hasRecurrence && <>Selecione o que você deseja deletar</>}
                selectOptions={hasRecurrence && [
                    {
						label: "Esse evento",
						value: RECURRENCE_THIS
                    },
                    {
						label: "Esse e os eventos seguintes",
						value: RECURRENCE_FUTURE
                    },
                    {
						label: "Todos os eventos",
						value: RECURRENCE_ALL
                    }
				]}
                options={[
                    {
                        type: "cancel",
                        text: "Cancelar Operação",
                        action: () => {
                            Swal.close()
                        }
                    },
                    {
                        type: "confirm",
                        text: "Excluir despesa",
                        action: (selected) => {
							Parse.Cloud.run("deleteExpenseForClinic", {
								objectId: expense.id,
								recurrenceType: selected || RECURRENCE_THIS,
								clinicId: expense.get("clinic")?.id
							})
							.then(_expenses => {
								Swal.fire(
									'Excluído!',
									_expenses?.length == 1 ? 'A despesa foi excluída com sucesso.' : 'As despesas foram excluídas com sucesso.',
									'success'
								)
								.then(_ => {
									callback && callback()
								})
							})
							.catch(error => {
								console.error(error)
								Swal.fire(
									'Desculpe',
									'Ocorreu algum erro ao tentar excluir a(s) despesa(s)',
									'error'
								)
							})
                        }
                    }
                ]}
            />
        )
    })
}

export const deleteExpenseRecurrency = (expense, date, index, callback) => {
    const expenseFirstName = firstName(expense.get("name"))
    Swal.fire({
        // allowOutsideClick: false,
        showConfirmButton: false,
        customClass: {
            popup: 'p-0',
            content: 'p-0',
        },
        html: (
            <VSModal
                title="Tem certeza que deseja excluir este cadastro de Despesa?"
                text="Os dados não poderão ser recuperados e esta operação não poderá ser desfeita"
                textConfirm={<>Digite o <b>primeiro nome</b> do despesa para prosseguir com a exclusão do cadastro</>}
                placeholderConfirm={`Digite o Primeiro Nome do Despesa: ${expenseFirstName}`}
                compareTo={expenseFirstName}
                options={[
                    {
                        type: "cancel",
                        text: "Cancelar Operação",
                        action: () => {
                            Swal.close()
                        }
                    },
                    {
                        type: "intermediate",
                        text: "Excluir Apenas Esse",
                        action: () => {
                            deleteExpenseICalEvent(expense, date)
                            expense.save()
                            .then(_ => {
                                Swal.fire(
                                    'Excluído!',
                                    'A despesa foi excluído com sucesso.',
                                    'success'
                                )
                                .then(_ => {
                                    callback && callback()
                                })
                            })
                            .catch(error => {
                                console.error(error)
                                Swal.fire(
                                    'Desculpe',
                                    'Ocorreu algum erro ao tentar excluir a despesa',
                                    'error'
                                )
                                expense.revert()
                            })
                        }
                    },
                    {
                        type: "confirm",
                        text: "Excluir Esse e Futuros",
                        action: () => {
                            deleteExpenseICal(expense, date, index)
                            expense.save()
                            .then(_ => {
                                Swal.fire(
                                    'Excluído!',
                                    'As despesas foram excluídos com sucesso.',
                                    'success'
                                )
                                .then(_ => {
                                    callback && callback()
                                })
                            })
                            .catch(error => {
                                console.error(error)
                                Swal.fire(
                                    'Desculpe',
                                    'Ocorreu algum erro ao tentar excluir as despesas',
                                    'error'
                                )
                                expense.revert()
                            })
                        }
                    }
                ]}
            />
        )
    })
}

export const deletePaymentOfExpense = (expense, indexOfPayment, callback) => {
    if(!expense)
        return

    const payments = expense.get("payments")
    if (!payments || !payments[indexOfPayment])
        return

    Swal.fire({
        showConfirmButton: false,
        customClass: {
            popup: 'p-0',
            content: 'p-0',
        },
        html: (
            <VSModal
                title={`Tem certeza que deseja excluir esse pagamento?`}
                text={`O valor referente a esse pagamento será excluído e impactará nos relatórios de Saída de Caixa. Os dados não poderão ser recuperados e esta operação não poderá ser desfeita`}
                options={[
                    {
                        type: "cancel",
                        text: "Cancelar Operação",
                        action: () => {
                            Swal.close()
                        }
                    },
                    {
                        type: "confirm",
                        text: "Excluir Cadastro",
                        action: () => {
                            const newPayments = [...payments]
                            newPayments.splice(indexOfPayment, 1)

                            expense.set("payments", newPayments)
                            expense.set("changedAt", new Date())
                            expense.set("changedBy", Parse.User.current())
                            expense.set("changedApp", APP_NAME)
                            expense.set("changedDevice", APP_DEVICE)
                            expense.set("changedAppVersion", APP_VERSION)
                            expense.save()
                            .then(_ => {
                                Swal.fire(
                                    `Pagamento excluído!`,
                                    `O pagamento foi excluído com sucesso.`,
                                    'success'
                                )
                                .then(_ => {
                                    callback && callback()
                                })
                            })
                            .catch(error => {
                                console.error(error)
                                Swal.fire(
                                    'Desculpe',
                                    `Ocorreu algum erro ao tentar excluir o pagamento`,
                                    'error'
                                )
                                expense.revert()
                            })
                        }
                    }
                ]}
            />
        )
    })
}

export const frequencyDescription = (frequency, interval) => {
    if (interval < 1) {
        interval = 1
    }
    switch (frequency) {
        case RRULE_DAILY:
            return `${interval} dia${interval == 1 ? "" : "s"}`
        case RRULE_WEEKLY:
            return `${interval} semana${interval == 1 ? "" : "s"}`
        case RRULE_MONTHLY:
            return `${interval} ${interval == 1 ? "mês" : "meses"}`
        case RRULE_YEARLY:
            return `${interval} ano${interval == 1 ? "" : "s"}`
    }
}

export const frequencyOptions = [
    { value: "YEARLY", text: "Anualmente" },
    { value: "MONTHLY", text: "Mensalmente" },
    { value: "WEEKLY", text: "Semanalmente" },
    { value: "DAILY", text: "Diariamente" }
]

export const monthOptions = [
    { value: "1", text: "Janeiro" },
    { value: "2", text: "Fevereiro" },
    { value: "3", text: "Março" },
    { value: "4", text: "Abril" },
    { value: "5", text: "Maio" },
    { value: "6", text: "Junho" },
    { value: "7", text: "Julho" },
    { value: "8", text: "Agosto" },
    { value: "9", text: "Setembro" },
    { value: "10", text: "Outubro" },
    { value: "11", text: "Novembro" },
    { value: "12", text: "Dezembro" },
]

export const dayOptions = [
    { value: "1", text: "01" },
    { value: "2", text: "02" },
    { value: "3", text: "03" },
    { value: "4", text: "04" },
    { value: "5", text: "05" },
    { value: "6", text: "06" },
    { value: "7", text: "07" },
    { value: "8", text: "08" },
    { value: "9", text: "09" },
    { value: "10", text: "10" },
    { value: "11", text: "11" },
    { value: "12", text: "12" },
    { value: "13", text: "13" },
    { value: "14", text: "14" },
    { value: "15", text: "15" },
    { value: "16", text: "16" },
    { value: "17", text: "17" },
    { value: "18", text: "18" },
    { value: "19", text: "19" },
    { value: "20", text: "20" },
    { value: "21", text: "21" },
    { value: "22", text: "22" },
    { value: "23", text: "23" },
    { value: "24", text: "24" },
    { value: "25", text: "25" },
    { value: "26", text: "26" },
    { value: "27", text: "27" },
    { value: "28", text: "28" },
    { value: "29", text: "29" },
    { value: "30", text: "30" },
    { value: "31", text: "31" }
]

export const weekPositionOptions = [
    { value: "1", text: "Primeiro(a)" },
    { value: "2", text: "Segundo(a)" },
    { value: "3", text: "Terceiro(a)" },
    { value: "4", text: "Quarto(a)" },
    { value: "-1", text: "Último(a)" }
]

export const weekOptions = [
    { value: "SU", text: "Domingo" },
    { value: "MO", text: "Segunda-feira" },
    { value: "TU", text: "Terça-feira" },
    { value: "WE", text: "Quarta-feira" },
    { value: "TH", text: "Quinta-feira" },
    { value: "FR", text: "Sexta-feira" },
    { value: "SA", text: "Sábado" },
    { value: "MO,TU,WE,TH,FR,SA,SU", text: "Dia" },
    { value: "MO,TU,WE,TH,FR", text: "Dia útil" },
    { value: "SA,SU", text: "Final de semana" }
]

export const getExpense = (objectId, clinicObject, includes = ["clinic"]) => {
    return new Promise((resolve, reject) => {
        var queryExpense = new Parse.Query("MRExpense");
        queryExpense.equalTo("clinic", clinicObject);
        queryExpense.equalTo("isDeleted", false);
        includes.map(className => {
            queryExpense.include(className)
        })
        queryExpense.get(objectId)
        .then(_expense => {
            resolve(_expense)
        })
        .catch(error => {
            console.error(error)
            reject('Ocorreu algum erro ao tentar buscar a despesa')
        })
    })
}

export const getExpenseICalRRule = (expense, index = 0) => {
    if (expense.get("recurrency")) {
        const iCal = ICAL.parse(expense.get("recurrency"));
        const vcalendar = new ICAL.Component(iCal)
        const vevents = vcalendar.getAllSubcomponents('vevent')
    
        var vevent
        if (vevents.length < index) {
            vevent = vevents[vevents.length - 1]
        } else {
            vevent = vevents[index]
        }
        if (vevent) {
            const rrule = vevent.getFirstPropertyValue('rrule');
            if (rrule) {
                var rules = {}
                if (rrule.count) {
                    rules.count = rrule.count
                    rules.ends = "after"
                } else if (rrule.until) {
                    const until = dayjs(rrule.until.toJSDate())
                    rules.until = until.toDate()
                    rules.untilText = until.format("DD/MM/YYYY")
                    rules.ends = "onDate"
                } else {
                    rules.ends = "never"
                }
    
                var byDay = rrule.parts?.BYDAY?.join(",") || ""
    
                rules.freq = rrule.freq
                rules.interval = rrule.interval
                rules.byDay = byDay
                rules.byDayCustom = byDay
                rules.byMonth = rrule.parts?.BYMONTH
                if (rrule.parts?.BYMONTHDAY) {
                    rules.byMonthDay = rrule.parts?.BYMONTHDAY[0]
                    rules.monthOrMonthDay = "month"
                } else if (rrule.parts?.BYSETPOS) {
                    rules.bySetPos = rrule.parts?.BYSETPOS[0]
                    rules.monthOrMonthDay = "monthDay"
                }
                return rules
            }
        }
    }
    return null
} 

export const replaceBreakLineToN = (text) => {
    if (text)
        return text.replace(/(?:\r\n|\r|\n)/g, '\\n')
    return text
}

export const createExpenseICal = (expense, rrule) => {
    if (rrule) {
        const iCal = new ICAL.Component(['vexpense', [], []]);
        const vevent = new ICAL.Component('vevent')
        const event = new ICAL.Event(vevent);
        event.startDate = ICAL.Time.fromJSDate(expense.get("documentDate"));
        event.sequence = 0;
        vevent.addProperty(ICAL.Property.fromString(rrule));
        vevent.addPropertyWithValue("exdate", event.startDate)
        vevent.addPropertyWithValue('uid', uuidv4());
        vevent.addPropertyWithValue('created', ICAL.Time.now());
        vevent.addPropertyWithValue('last-modified', ICAL.Time.now());
        vevent.addPropertyWithValue('x-name', expense.get("name"));
        vevent.addPropertyWithValue('x-value', expense.get("value"));
        vevent.addPropertyWithValue('x-notes', replaceBreakLineToN(expense.get("notes")));
        iCal.addSubcomponent(vevent)
    
        expense.set("recurrency", iCal.toString())
    }
}

export const updateExpenseICalEvent = ({
    index,
    object,
    recurrenceId,
    documentDate,
    name,
    value,
    notes
}) => {
    const iCal = new ICAL.Component(ICAL.parse(object.get("recurrency")));
    const vevents = iCal.getAllSubcomponents('vevent');

    var veventOriginal
    if (vevents.length < index) {
        veventOriginal = vevents[vevents.length - 1]
    } else {
        veventOriginal = vevents[index]
    }

    var uidOriginal = veventOriginal.getFirstPropertyValue("uid") || uuidv4()
    var seqOriginal = veventOriginal.getFirstPropertyValue("sequence") + 1

    const vevent = new ICAL.Component('vevent')
    const event = new ICAL.Event(vevent);
    event.startDate = ICAL.Time.fromJSDate(documentDate);
    event.recurrenceId = ICAL.Time.fromJSDate(recurrenceId);
    event.sequence = seqOriginal;
    vevent.addPropertyWithValue('uid', uidOriginal);
    vevent.addPropertyWithValue('created', ICAL.Time.now());
    vevent.addPropertyWithValue('last-modified', ICAL.Time.now());
    vevent.addPropertyWithValue('x-name', name);
    vevent.addPropertyWithValue('x-value', value);
    vevent.addPropertyWithValue('x-notes', replaceBreakLineToN(notes));
    iCal.addSubcomponent(vevent);
    
    object.set("recurrency", iCal.toString())
}

export const updateExpenseICal = ({
    index,
    object,
    rrule,
    documentDate,
    name,
    value,
    notes
}) => {
    const iCal = new ICAL.Component(ICAL.parse(object.get("recurrency")));
    const vevents = iCal.getAllSubcomponents('vevent');

    var veventOriginal
    if (vevents.length < index) {
        veventOriginal = vevents[vevents.length - 1]
    } else {
        veventOriginal = vevents[index]
    }

    var newSequence = veventOriginal.getFirstPropertyValue("sequence") + 1
    var rruleOriginal = veventOriginal.getFirstPropertyValue("rrule")
    rruleOriginal.until = ICAL.Time.fromJSDate(documentDate).adjust(-1, 0, 0, 0)

    veventOriginal.updatePropertyWithValue('rrule', rruleOriginal)
    veventOriginal.updatePropertyWithValue('last-modified', ICAL.Time.now())

    const vevent = new ICAL.Component('vevent')
    const event = new ICAL.Event(vevent);
    event.startDate = ICAL.Time.fromJSDate(documentDate);
    event.sequence = newSequence;
    vevent.addProperty(ICAL.Property.fromString(rrule));
    vevent.addPropertyWithValue('uid', uuidv4());
    vevent.addPropertyWithValue('created', ICAL.Time.now());
    vevent.addPropertyWithValue('last-modified', ICAL.Time.now());
    vevent.addPropertyWithValue('x-name', name);
    vevent.addPropertyWithValue('x-value', value);
    vevent.addPropertyWithValue('x-notes', notes);
    iCal.addSubcomponent(vevent);
    
    object.set("recurrency", iCal.toString())
}

export const deleteExpenseICalEvent = (expense, date, index = 0) => {
    const iCal = new ICAL.Component(ICAL.parse(expense.get("recurrency")));
    const vevents = iCal.getAllSubcomponents('vevent');

    var veventOriginal
    if (vevents.length < index) {
        veventOriginal = vevents[vevents.length - 1]
    } else {
        veventOriginal = vevents[index]
    }

    var exdate = veventOriginal.getFirstProperty("exdate")
    if (exdate) {
        var exdates = exdate.getValues()
        exdates.push(ICAL.Time.fromJSDate(date))
        exdate.setValues(exdates)
    } else {
        veventOriginal.addPropertyWithValue("exdate", ICAL.Time.fromJSDate(date))
    }

    veventOriginal.updatePropertyWithValue('last-modified', ICAL.Time.now())

    expense.set("recurrency", iCal.toString())
}

export const deleteExpenseICal = (expense, date, index = 0) => {
    const iCal = new ICAL.Component(ICAL.parse(expense.get("recurrency")));
    const vevents = iCal.getAllSubcomponents('vevent');

    var veventOriginal
    if (vevents.length < index) {
        veventOriginal = vevents[vevents.length - 1]
    } else {
        veventOriginal = vevents[index]
    }

    var rrule = veventOriginal.getFirstPropertyValue("rrule")
    rrule.until = ICAL.Time.fromJSDate(date).adjust(-1, 0, 0, 0)

    veventOriginal.updatePropertyWithValue('rrule', rrule)
    veventOriginal.updatePropertyWithValue('last-modified', ICAL.Time.now())

    expense.set("recurrency", iCal.toString())
}

export const getExpenseICalEvents = (expense, dateFrom, dateTo) => {
    const iCal = ICAL.parse(expense.get("recurrency"));
    const vcalendar = new ICAL.Component(iCal)
    const vevents = vcalendar.getAllSubcomponents('vevent')

    var results = []
    vevents.map((vevent, index) => {
        const event = new ICAL.Event(vevent)
        const dtStart = vevent.getFirstPropertyValue('dtstart');

        const exdates = []
        vevent.getAllProperties('exdate')?.map(exdateProp => {
            exdateProp.getValues()?.map(exdate => {
                exdates.push(exdate)
            })
        })

        if (event.isRecurring()) {
            const iterator = new ICAL.RecurExpansion({
                component: vevent,
                dtstart: dtStart
            });

            let next;
            while ((next = iterator.next())) {
                const occurrence = event.getOccurrenceDetails(next);
                const isExDate = exdates.find(exdate => occurrence.startDate.compare(exdate) == 0)
                if (isExDate)
                    continue

                const currentDateIsAfterDateTo = occurrence.startDate.compare(ICAL.Time.fromJSDate(dateTo)) > 0
                if (currentDateIsAfterDateTo) {
                    break;
                }

                const currentDateIsBeforeDateFrom = occurrence.startDate.compare(ICAL.Time.fromJSDate(dateFrom)) < 0
                results.push({
                    late: currentDateIsBeforeDateFrom,
                    index: index,
                    documentDate: occurrence.startDate.toJSDate(),
                    name: occurrence.item.component.getFirstPropertyValue("x-name"),
                    value: occurrence.item.component.getFirstPropertyValue("x-value"),
                    paymentsPending: occurrence.item.component.getFirstPropertyValue("x-value"),
                    paymentsValue: 0,
                    notes: occurrence.item.component.getFirstPropertyValue("x-notes"),
                    recurrence: getExpenseICalNextEvent(expense, occurrence.startDate.toJSDate()),
                    parent: expense
                })

                const preventsInfiniteLoop = results.length >= 1000
                if (preventsInfiniteLoop)
                    break
            }
        }
    })

    return results
}

export const getExpenseICalNextEvent = (expense, currentDate) => {
    if (expense.get("recurrency")) {
        const iCal = ICAL.parse(expense.get("recurrency"));
        const vcalendar = new ICAL.Component(iCal)
        const vevents = vcalendar.getAllSubcomponents('vevent')
    
        let result = null
        vevents.map((vevent, index) => {
            if (!result) {
                const event = new ICAL.Event(vevent)
                const rrule   = vevent.getFirstPropertyValue('rrule');
        
                if (event.isRecurring()) {
                    const iterator = new ICAL.RecurExpansion({
                        component: vevent,
                        dtstart: ICAL.Time.fromJSDate(currentDate)
                    });
        
                    const next = iterator.next()
                    if (next) {
                        const occurrence = event.getOccurrenceDetails(next);
                        result = {
                            description: frequencyDescription(rrule.freq, rrule.interval),
                            finish: rrule.until ? dayjs(rrule.until.toJSDate()).format("DD/MM/YYYY") : (rrule.count ? `Após ${rrule.count} execuções` : "Nunca"),
                            next: dayjs(occurrence.startDate.toJSDate()).format("DD/MM/YYYY")
                        }
                    }
                }
            }
        })
        return result
    }
    return null
}