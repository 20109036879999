import React, { useEffect, useState } from "react"
import { useParams, Switch, Route } from 'react-router-dom'
import { connect } from "react-redux"

import { redirectToCheckout, saveOnTracker, saveTrackersProSubscription, useQuery } from "../../utils";
import { ACL, APP_DEVICE, APP_NAME, APP_VERSION } from "../../constants";

import { CircularProgress } from "@mui/material";
import VSDrawer from "../../components/vsDrawer/VSDrawer";
import { getClinicUser } from "../vet/Vet";
import { getSelectedClinicUser } from "../../store/actions";

import VirtualAssistantClinicalHistorySummary from './assistants/clinicalhistorysummary/VirtualAssistantClinicalHistorySummary'
import VirtualAssistantDifferentialDiagnosis from './assistants/differentialdiagnosis/VirtualAssistantDifferentialDiagnosis'
import VirtualAssistantDictateAnamnesis from './assistants/dictateanamnesis/VirtualAssistantDictateAnamnesis'

import { ReactComponent as IconVirtualAssistantSelect } from "../../assets/virtual-assistant-select.svg"
import { ReactComponent as IconVirtualAssistantAnamnesis } from "../../assets/icon-virtual-assistant-anamnesis.svg"
import { ReactComponent as IconVirtualAssistantDiagnosis } from "../../assets/icon-virtual-assistant-diagnosis.svg"
import { ReactComponent as IconVirtualAssistantHistoric } from "../../assets/icon-virtual-assistant-historic.svg"
import { ReactComponent as IconIA } from "../../assets/icon-ia.svg"
import { ReactComponent as IconSimpleWarning } from "../../assets/icon-simple-warning.svg"

import './VirtualAssistantFreeOverlay.scss'
const VirtualAssistantFreeOverlay = (props) => {

    const {
        pro,
        history,
        clinic
    } = props

    // const {
    //     assistant
    // } = useParams()

    const [ errors, setErrors ] = useState([])

    return (
        <VSDrawer
            id="virtual-assistant-free-overlay"
            // width="40%"
            width="385px"
            title={
                <div className="row">
                    <div className="col-12">
                        Assistente Vetsmart <span className="va-beta-text">Beta</span>
                    </div>
                    {/* <div className="col-12 vs-header-subtitle">
                        Disponível para você até o dia 20/10
                    </div> */}
                </div>
            }
            errors={errors}
            onCancel={() => history.goBack()}
            onClose={() => history.goBack()}
            VSDrawerFooterProps={{
                show: true,
                children: (
                    <div className="row">
                        <div className="col-12">
                            <div className="va-footer-text">Disponível até o dia <b>20/10</b>, aproveite!</div>
                        </div>
                        <div className="col-12">
                            <button class="btn-submit btn-square text-normal mt-2 w-100" onClick={() => {
                                saveTrackersProSubscription(pro)
                                redirectToCheckout(clinic)
                            }}>
                                { !pro?.trialEndsAt ? "Experimentar o PRO 15 dias grátis" : "Assinar o Prontuário PRO" }
                            </button>
                        </div>
                    </div>
                )
            }}
        >
            <div className="text-center mx-auto mb-4">
                <IconVirtualAssistantSelect className="mb-4" />
                <h3 className="virtual-assistant-free-overlay-title">Assistente Vetsmart <span className="va-beta-text">Beta</span></h3>
                <p className="virtual-assistant-intro-text">Experimente o PRO e aproveite para testar a nossa mais nova ferramenta. O assistente digital que dará um apoio extra no seu dia-a-dia.</p>
            </div>
            <div className="row justify-content-center">
                <div className="col-auto">
                    <button className="btn-link text-normal text-start d-block no-action">
                        <IconVirtualAssistantHistoric className="me-2" width="24" height="24" fill="var(--vsprontuario-primary-color)" />
                        Resumo do histórico clínico
                    </button>
                    <button className="btn-link text-normal text-start d-block no-action">
                        <IconVirtualAssistantDiagnosis className="me-2" width="24" height="24" fill="var(--vsprontuario-primary-color)" />
                        Diagnóstico diferencial
                    </button>
                    <div className="soon-text px-3">
                        <IconIA className="me-2" width="24" height="24" fill="#FF8A00" />
                        Em breve novas funcionalidades
                    </div>
                </div>
            </div>
        </VSDrawer>
    )
}

const mapStateToProps = state => {
    return {
        pro: state.featuresPro,
        clinic: state.clinic,
        permissions: state.permissions
    }
}

export default connect(mapStateToProps)(VirtualAssistantFreeOverlay)