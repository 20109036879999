import React, { forwardRef, useState, useEffect } from 'react'
import { connect } from 'react-redux'

import { TableCell, TableRow } from '@mui/material'
import VSMenu from '../../components/vsMenu/VSMenu'
import { ReactComponent as IconPaw } from "../../assets/icon-paw-menu.svg"
import { ReactComponent as IconMenu } from "../../assets/icon-menu-2.svg"
import { ReactComponent as IconUser } from "../../assets/icon-user-menu.svg"
import { ReactComponent as IconReminder } from "../../assets/icon-reminder.svg"

import { ACL } from '../../constants'
import HomeData from '../home/HomeData'
import { historyDocument, includeDocuments } from '../patient/tabs/history/History'
import { dayjs } from '../../utils'

import './OwnerViewReminder.scss'
const OwnerViewReminder = props => {

    const {
        clinic,
        owner,
        expanded,
        history,
        permissions,
        patientsData,
        getPatients
    } = props

    const [ loading, setLoading ] = useState(true)
    const [ data, setData ] = useState([])

    const rowsPerPage = 5

    const getReminders = () => {
        const query = new Parse.Query("Message")
        query.equalTo("clinic", clinic?.object?.id)
        query.equalTo("owner", owner?.id)
        query.containedIn("createdFromClass", ['Vaccine', 'MRCalendar', 'Return'])
        query.equalTo("sentFrom", "Vet Smart")
        query.equalTo("isDeleted", false)
        query.descending("scheduledAt")
        query.addAscending("objectId")
        // query.withCount()
        query.limit(100)
        return query.find()
        .then(results => {
            const distinctResults = results?.reduce((carry, object) => 
                carry
                ?.map(el => el?.get('createdFromId'))
                ?.includes(object?.get('createdFromId')) ? carry : carry.concat([object])
            , [])

            setData({
                count: distinctResults?.length || 0,
                results: distinctResults || []
            })
            setLoading(false)
        })
        .catch(error => {
            console.error(error)
            setLoading(false)
        })
    }

    useEffect(() => {
        if(patientsData && loading){
            getReminders()
        }
    }, [ patientsData, loading ])

    return (
        <div className="col-12 mb-3">
            <HomeData
                className="mb-3"
                expanded={expanded}
                headerIcon={<IconReminder width="24px" height="24px" fill="#B3B3B3" />}
                title="Lembretes para o Tutor"
                emptyText="Este(a) Tutor(a) ainda não possui lembretes"
                loading={loading}
                data={data}
                rowsPerPage={rowsPerPage}
                onChange={(page) => {
                    if (clinic && clinic.isLoaded && clinic.object) {
                        setData([])
                        setLoading(true)
                        if(!patientsData){
                            getPatients()
                        }
                    }
                }}
                renderItem={(reminder, index) => {
                    const patient = patientsData?.results?.find(patient => patient?.id == reminder?.get('patient'))
                    return (
                        <TableRow
                            key={index}
                        >
                            {/* <TableCell
                                style={{ width: '24px' }}
                            >
                                { document?.icon("#B3B3B3") }
                            </TableCell> */}
                            <TableCell
                                style={{ width: '86px' }}
                            >
                                <div className="text-truncate">
                                    { dayjs(reminder?.get("scheduledAt")).format("DD/MM/YYYY") }
                                </div>
                            </TableCell>
                            <TableCell className="px-2">
                                <div className="text-link d-inline" onClick={() => history.push(
                                    reminder?.get("createdFromClass") == 'Vaccine' ? 
                                    `/animal/${patient?.id}/lembrete_automatico/${reminder?.get('createdFromId')}` :
                                    reminder?.get("createdFromClass") == 'MRCalendar' ? 
                                    `/agenda/${reminder?.get('createdFromId')}` :
                                    reminder?.get("createdFromClass") == 'Return' ? 
                                    `-` : '-'
                                , reminder?.get("createdFromClass") == 'MRCalendar' ? {
                                    id: patient?.id
                                } : undefined)}>
                                    Lembrete { reminder?.get("sentAt") ? 'enviado' : 'programado' }
                                </div>
                                &nbsp;de&nbsp;
                                <div className="d-inline" 
                                    // onClick={() => history.push(`/animal/${patient?.id}`)}
                                >
                                    { reminder?.get("createdFromClass") == 'Vaccine' ? 'Aplicação de Vacina' :
                                    reminder?.get("createdFromClass") == 'MRCalendar' ? 'Agendamento' :
                                    reminder?.get("createdFromClass") == 'Return' ? 'Retorno' : '-' }
                                </div>
                                { patient && (
                                    <>
                                        &nbsp;para&nbsp;
                                        <div className="d-inline" 
                                            // onClick={() => history.push(`/animal/${patient?.id}`)}
                                        >
                                            { patient?.get("name") }
                                        </div>
                                    </>
                                )}
                            </TableCell>
                            <TableCell style={{ width: '24px' }} className="ms-2 flex-shrink-0">
                                <VSMenu
                                    placement="left-start"
                                    openOnHover
                                    style={{background: "#fff"}}
                                    options={[
                                        {
                                            menu: (
                                                <div className="row align-items-center">
                                                    <div className="col-auto">
                                                        <IconPaw width="24" height="24" fill="var(--vsprontuario-primary-color)" />
                                                    </div>
                                                    <div className="col ps-0">
                                                        Ver Animal
                                                    </div>
                                                </div>
                                            ),
                                            onClick: () => history.push(`/animal/${patient?.id}`)
                                        },
                                        // {
                                        //     menu: (
                                        //         <div className="row align-items-center">
                                        //             <div className="col-auto">
                                        //                 <IconUser width="24" height="24" fill="var(--vsprontuario-primary-color)" />
                                        //             </div>
                                        //             <div className="col ps-0">
                                        //                 Ver Tutor
                                        //             </div>
                                        //         </div>
                                        //     ),
                                        //     onClick: () => history.push(`/tutor/${patient?.get('owner')?.id}`)
                                        // }
                                    ]}
                                >
                                    <IconMenu width="24" height="24" fill="var(--vsprontuario-primary-color)" />
                                </VSMenu>
                            </TableCell>
                        </TableRow>
                    )
                }}
            />
        </div>
    )
}

const mapStateToProps = state => {
    return {
        permissions: state.permissions
    };
};
  
export default connect(mapStateToProps)(OwnerViewReminder);