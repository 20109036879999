import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react'
import Autocomplete from '@mui/lab/Autocomplete';

import {
    showArrayIndex,
    optionalField
} from '../../../../utils'

import { ReactComponent as IconAddO } from "../../../../assets/icon-add-o.svg"
import { ReactComponent as IconCloseO } from "../../../../assets/icon-close-o.svg"

import { TextField } from '@mui/material'
import VSRadio from '../../../../components/vsRadio/VSRadio';
import { defaultExam, defaultProcedure } from './Term';
import { getExams } from '../exam/Exam';

const TermForwardingForm = forwardRef((props, ref) => {

    const {
        documentSubType,
        setDocumentSubType,
        otherInfo,
        setOtherInfo,
        procedures,
        setProcedures,
        exams,
        setExams,
        disease,
        setDisease,
        eventDetail,
        setEventDetail,
        eventNotes,
        setEventNotes
    } = props

    const [ examOptions, setExamOptions ] = useState([])

    const setProcedure = (index, field, value) => {
        let newProcedures = [...procedures]
        newProcedures[index][field] = value
        setProcedures(newProcedures)
    }

    const removeProcedure = (index) => {
        let newProcedures = [...procedures]
        newProcedures.splice(index, 1)
        setProcedures(newProcedures)
    }

    const setExam = (index, field, value) => {
        let newExams = [...exams]
        newExams[index][field] = value
        setExams(newExams)
    }

    const removeExam = (index) => {
        let newExams = [...exams]
        newExams.splice(index, 1)
        setExams(newExams)
    }

    useImperativeHandle(ref, () => ({
        fieldsWithErrorText(errors) {
            return errors
        },
        isValid() {
            return true
        },
        validate(isValid) {
            return isValid
        }
    }))

    useEffect(() => {
        getExams()
        .then(data => {
            setExamOptions(data && data.length > 0 ? data : [])
        })
    }, [])

    return (
        <div className="row pt-4">
            <div className="col">
                <div className="row mb-1">
                    <div className="col">
                        <div className="input-title input-height">
                            Tipo de carta de encaminhamento
                        </div>
                    </div>
                    <div className="col-auto">
                        <VSRadio
                            horizontal={true}
                            options={[
                                {
                                    label: "Simples",
                                    value: 0
                                },
                                {
                                    label: "Detalhada",
                                    value: 1
                                }
                            ]}
                            value={documentSubType}
                            onChange={(_, value) => {
                                setDocumentSubType(parseInt(value))
                            }}
                        />
                    </div>
                </div>
                { documentSubType == 1 && (
                    <>
                        <div className="row mb-4">
                            <div className="col-12">
                                <div className="input-title input-height">
                                    Histórico/Sinais Clínicos
                                </div>
                            </div>
                            <div className="col-12">
                                <TextField
                                    value={otherInfo}
                                    placeholder="Escreva os histórico/sinais clínicos do animal aqui"
                                    onChange={({ target: { value } }) => setOtherInfo(value)}
                                    helperText={optionalField}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                />
                            </div>
                        </div>
                        <div className="row mb-1">
                            <div className="col-12">
                                <div className="input-title input-height">
                                    Procedimentos/medicações aplicadas
                                </div>
                            </div>
                        </div>
                        { procedures.map((procedure, index) => (
                            <React.Fragment key={index}>
                                <div className="row align-items-center mb-4">
                                    <div className="col">
                                        <div className="input-array py-1">
                                            Procedimento/Medicação { showArrayIndex(index + 1) }
                                        </div>
                                    </div>
                                    { procedures.length > 1 && (
                                        <div className="col-auto ps-0">
                                            <button
                                                className="btn-array px-0"
                                                onClick={() => removeProcedure(index)}
                                            >
                                                <IconCloseO width="24" height="24" />
                                                Remover Procedimento/Medicação
                                            </button>
                                        </div>
                                    )}
                                </div>
                                <div className="row align-items-center mb-4">
                                    <div className="col-12">
                                        <TextField
                                            label={`Nome do Procedimento/Medicação ${ showArrayIndex(index + 1) }`}
                                            value={procedure.procedure}
                                            onChange={({ target: { value } }) => setProcedure(index, "procedure", value)}
                                            helperText={optionalField}
                                            size="small"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </div>
                                </div>
                                <div className="row align-items-center mb-4">
                                    <div className="col-12">
                                        <TextField
                                            label={`Observações do Procedimento/Medicação ${ showArrayIndex(index + 1) }`}
                                            value={procedure.notes}
                                            onChange={({ target: { value } }) => setProcedure(index, "notes", value)}
                                            helperText={optionalField}
                                            size="small"
                                            variant="outlined"
                                            fullWidth
                                            multiline
                                        />
                                    </div>
                                </div>
                            </React.Fragment>
                        ))}
                        <div className="row align-items-center mb-4">
                            <div className="col-12">
                                <button
                                    className="btn-add" 
                                    onClick={() => setProcedures(procedures.concat({ ...defaultProcedure }))}
                                >
                                    <IconAddO width="20" height="20" />
                                    Adicionar Procedimento/Medicação { showArrayIndex(procedures.length + 1) }
                                </button>
                            </div>
                        </div>
                        <div className="row mb-1">
                            <div className="col-12">
                                <div className="input-title input-height">
                                    Exames Realizados
                                </div>
                            </div>
                        </div>
                        { exams.map((exam, index) => (
                            <React.Fragment key={index}>
                                <div className="row align-items-center mb-4">
                                    <div className="col">
                                        <div className="input-array py-1">
                                            Exame Realizado { showArrayIndex(index + 1) }
                                        </div>
                                    </div>
                                    { exams.length > 1 && (
                                        <div className="col-auto ps-0">
                                            <button
                                                className="btn-array px-0"
                                                onClick={() => removeExam(index)}
                                            >
                                                <IconCloseO width="24" height="24" />
                                                Remover Exame Realizado
                                            </button>
                                        </div>
                                    )}
                                </div>
                                <div className="row align-items-center mb-4">
                                    <div className="col-12">
                                        <Autocomplete
                                            inputValue={exam.exam}
                                            options={examOptions}
                                            getOptionLabel={(option) => option.examName || exam.exam}
                                            getOptionSelected={(option) => option.examName == exam.exam}
                                            onChange={(_, selected) => {
                                                setExam(index, "exam", (selected && selected.examName) || "")
                                                setExam(index, "examId", (selected && selected.examId) || "")
                                            }}
                                            onInputChange={(event, selected) => {
                                                if (event) {
                                                    setExam(index, "exam", selected || "")
                                                    setExam(index, "examId", "")
                                                }
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    className="w-100"
                                                    placeholder={`Digite ou selecione o exame ${ showArrayIndex(index + 1) }`}
                                                    helperText={optionalField}
                                                    size="small"
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                            )}
                                            openOnFocus
                                            freeSolo
                                        />
                                    </div>
                                </div>
                                <div className="row align-items-center mb-4">
                                    <div className="col-12">
                                        <TextField
                                            label={`Resultados e Observações do Exame ${ showArrayIndex(index + 1) }`}
                                            value={exam.notes}
                                            onChange={({ target: { value } }) => setExam(index, "notes", value)}
                                            helperText={optionalField}
                                            size="small"
                                            variant="outlined"
                                            fullWidth
                                            multiline
                                        />
                                    </div>
                                </div>
                            </React.Fragment>
                        ))}
                        <div className="row align-items-center mb-4">
                            <div className="col-12">
                                <button
                                    className="btn-add" 
                                    onClick={() => setExams(exams.concat({ ...defaultExam }))}
                                >
                                    <IconAddO width="20" height="20" />
                                    Adicionar Exame Realizado { showArrayIndex(exams.length + 1) }
                                </button>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="col-12">
                                <div className="input-title input-height">
                                    Suspeitas
                                </div>
                            </div>
                            <div className="col-12">
                                <TextField
                                    value={disease}
                                    placeholder="Escreva as suspeitas aqui"
                                    onChange={({ target: { value } }) => setDisease(value)}
                                    helperText={optionalField}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                />
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="col-12">
                                <div className="input-title input-height">
                                    Motivo de Encaminhamento
                                </div>
                            </div>
                            <div className="col-12">
                                <TextField
                                    value={eventDetail}
                                    placeholder="Escreva o motivo do encaminhamento aqui"
                                    onChange={({ target: { value } }) => setEventDetail(value)}
                                    helperText={optionalField}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                />
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="col-12">
                                <div className="input-title input-height">
                                    Sugestões
                                </div>
                            </div>
                            <div className="col-12">
                                <TextField
                                    value={eventNotes}
                                    placeholder="Escreva sugestões aqui"
                                    onChange={({ target: { value } }) => setEventNotes(value)}
                                    helperText={optionalField}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                />
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    )
})

export default TermForwardingForm;