import { useEffect, useState } from "react"

import { TextField, ThemeProvider } from "@mui/material"
import VSCheckbox from "../../components/vsCheckbox/VSCheckbox"

import { reasonsOptions } from "./Payment"
import { theme } from "../../App"
import { mandatoryField, optionalField } from "../../utils"

import VSModal from "../../components/vsModal/VSModal"

import "./SubscriptionCancelForm.scss"
const SubscriptionCancelForm = (props) => {

    const {
        onConfirm
    } = props

    const [ notes, setNotes ] = useState("")
    const [ reasons, setReasons ] = useState(reasonsOptions.map(r => false))
    const [ reasonsText, setReasonsText ] = useState(reasonsOptions.map(r => ""))

    const [ error, setError ] = useState("")

    const getReasons = () => {
        let _reasons = []
        reasons.map((reason, index) => {
            if (reason) {
                if (reasonsOptions[index].field) {
                    _reasons.push(`${reasonsOptions[index].text.trim()}: ${reasonsText[index].trim()}`)
                } else {
                    _reasons.push(reasonsOptions[index].text.trim())
                }
            }
        })
        if (notes.trim().length > 0) {
            _reasons.push(`Comentários e/ou Sugestões: ${notes.trim()}`)
        }
        return _reasons
    }

    const validate = () => {
        const selected = reasons.filter(reason => reason == true)
        const texts = reasons.filter((reason, index) => {
            if (reason && reasonsOptions[index].field && reasonsText[index].length < 2)
                return true
            return false
        })
        if (!selected.length) {
            setError("Ao menos uma opção precisa ser selecionada")
        } else if (texts.length) {
            setError("Por favor, preencha o campo adicional do motivo de cancelamento")
        } else {
            setError("")
        }
    }

    useEffect(() => {
        validate()
    }, [ reasons, reasonsText ])

    return (
        <ThemeProvider theme={theme}>
            <VSModal
                id="vsmodal-cancel-subscription"
                title="quais são os motivos para você cancelar sua assinatura?"
                text="Para que possamos melhorar, selecione abaixo os motivos do seu cancelamento do Prontuário Pro."
                body={(
                    <div className="row g-2 mt-3">
                        { reasonsOptions.map((reason, index) => (
                            <div className="col-12" key={index}>
                                <div className="border rounded p-2">
                                    <VSCheckbox
                                        label={reason.text}
                                        checked={reasons[index]}
                                        onChange={(checked) => {
                                            const newReasons = [...reasons]
                                            newReasons[index] = checked
                                            setReasons(newReasons)
                                        }}
                                    />
                                    { reasons[index] && reason.field && (
                                        <>
                                            <hr/>
                                            <TextField
                                                placeholder={ reason.field.placeholder }
                                                label={ reason.field.label }
                                                color="primary"
                                                value={reasonsText[index]}
                                                onChange={({ target: { value } }) => {
                                                    const newReasonsText = [...reasonsText]
                                                    newReasonsText[index] = value
                                                    setReasonsText(newReasonsText)
                                                }}
                                                error={reasonsText[index].length < 2}
                                                helperText={reasonsText[index].length > 1 ? mandatoryField : "Por favor, preencha um valor com pelo menos 2 caracteres"}
                                                size="small"
                                                variant="outlined"
                                                fullWidth
                                            />
                                        </>
                                    )}
                                </div>
                            </div>
                        )) }
                        <div className="col-12 mt-3">
                            <TextField
                                placeholder="Comentários e/ou Sugestões"
                                label="Comentários e/ou Sugestões"
                                color="primary"
                                value={notes}
                                onChange={({ target: { value } }) => setNotes(value)}
                                helperText={optionalField}
                                size="small"
                                variant="outlined"
                                fullWidth
                            />
                        </div>
                        { error && (
                            <div className="col-12 text-start">
                                <small className="text-danger">
                                    { error }
                                </small>
                            </div>
                        )}
                    </div>
                )}
                options={[
                    {
                        type: "cancel",
                        text: "Cancelar Operação",
                        action: () => {
                            Swal.close()
                        }
                    },
                    {
                        type: "confirm",
                        text: "Cancelar Assinatura",
                        disabled: error.length > 0,
                        action: () => {
                            if (!error) {
                                const _reasons = getReasons()
                                onConfirm(_reasons)
                            }
                        }
                    }
                ]}
            />
        </ThemeProvider>
    )
}

export default SubscriptionCancelForm