import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { addEvent } from '../../../../utils';
import { ACL } from '../../../../constants';

import { CircularProgress } from '@mui/material';

import VSDrawer from '../../../../components/vsDrawer/VSDrawer';
import VSError from '../../../../components/vsError/VSError';
import AutomaticReminderFormOld from './fields/AutomaticReminderFormOld';

import './VaccineAutomaticReminderForm.scss'
const VaccineAutomaticReminderFormOld = (props) => {

    const {
        id_lembrete
    } = useParams()

    const {
        clinic,
        history,
        permissions
    } = props

    const AutomaticReminderFormRef = useRef(null)

    const [ loading, setLoading ] = useState(true)
    const [ error, setError ] = useState(null)
    const [ reminder, setReminder ] = useState(null)

    // Form Automatic Reminder
    const [ hasSubmited, setHasSubmited ] = useState(false)
    const [ errors, setErrors ] = useState([])

    if (!permissions?.check(ACL.VACCINE, ACL.Rule.EDIT, reminder)) {
        permissions?.error()
        return <div></div>
    }

    const validate = () => {
        let _errors = []
        if (loading) {
            _errors.push("Salvando a vacina")
        }

        if (AutomaticReminderFormRef.current) {
            _errors = AutomaticReminderFormRef.current.validate(_errors)
        }

        if (!permissions?.check(ACL.VACCINE, ACL.Rule.EDIT, reminder)) {
            _errors.push("Acesso negado")
        }

        setErrors(_errors)
        return _errors
    }
    
    const saveAutomaticReminder = () => {
        return new Promise((resolve, reject) => {
            setHasSubmited(true)
            if (validate().length == 0) {
                setLoading(true)

                resolve(AutomaticReminderFormRef.current?.save())
            } else {
                reject("O formulário não está válido")
            }
        })
    }

    const getReminderOrError = () => {
        if (permissions?.check(ACL.VACCINE, ACL.Rule.VIEW)) {
            setReminder(null)
            setLoading(true)
            setError(null)

            const query = new Parse.Query("Vaccine");
            query.equalTo("clinic", clinic.object);
            query.equalTo("isDeleted", false);
            query.equalTo("isReminder", true)
            query.get(id_lembrete)
            .then(_reminder => {
                setReminder(_reminder)
                setLoading(false)
            }).catch(error => {
                Swal.fire(
                    'Desculpe',
                    error,
                    'error'
                )
                .then(_ => {
                    setLoading(false)
                    getReminderOrError({
                        message: error,
                        function: "getReminderOrError"
                    })
                })
            })
        }
    }

    useEffect(() => {
        if (reminder && !permissions?.check(ACL.VACCINE, ACL.Rule.EDIT, reminder)) {
            permissions?.error()
        }
    }, [ reminder ])

    useEffect(() => {
        if (clinic && clinic.isLoaded && clinic.object) {
            getReminderOrError()
        }
    }, [ clinic ])

    return (
        <VSDrawer
            id="vaccine-form"
            width="50%"
            title="Lembretes automáticos de vacina"
            cancel={<span>Cancelar Lembrete<span className="d-none d-md-inline"> de Vacina</span></span>}
            submit={errors.length == 0 ? <span>Salvar Lembrete<span className="d-none d-md-inline"> de Vacina</span></span> : <span>Preencha os campos<span className="d-none d-md-inline"> necessários</span></span>}
            errors={errors}
            onSubmit={saveAutomaticReminder}
            onAfterSave={() => {
                history.goBack()
                addEvent("Vaccine__getList", {})
                addEvent("Vaccine__showVaccineSavedOnList", { vaccines: [ reminder ] })
            }}
            onCancel={() => history.goBack()}
            onClose={() => history.goBack()}
            VSDrawerSuccessProps={{
                title: "Lembrete Automático criado com Sucesso",
                text: ""
            }}
            VSDrawerCancelProps={{
                title: "Tem certeza que deseja cancelar o Lembrete Automático?",
                confirm: "Cancelar Lembrete Automático",
                cancel: "Voltar para Edição"
            }}
        >
            { error?.message ? (
                <VSError
                    message={error.message}
                    onClose={() => {
                        switch(error.function) {
                            case "getReminderOrError":
                                getReminderOrError()
                                break
                            default:
                        }
                    }}
                />
            ) : loading ? (
                <div className="data-loading">
                    <CircularProgress />
                </div>
            ) : (
                <AutomaticReminderFormOld
                    ref={AutomaticReminderFormRef}
                    hasSubmited={hasSubmited}
                    vaccine={reminder}
                    patient={reminder?.get("patient")}
                    vaccineType={reminder?.get("vaccineType")}
                    reminderDate={reminder?.get("documentDate")}
                    sendAutomaticReminderIsActive={true}
                    setSendAutomaticReminderIsActive={() => null}
                    onChange={validate}
                />
            )}
        </VSDrawer>
    )
}

const mapStateToProps = state => {
    return {
        user: state.user,
        clinic: state.clinic,
        permissions: state.permissions
    };
};

export default connect(mapStateToProps)(VaccineAutomaticReminderFormOld);