import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { CircularProgress, MenuItem, TextField } from '@mui/material';

import { ReactComponent as IconClose } from '../../assets/icon-close.svg';
import { ReactComponent as IconCheck } from '../../assets/icon-primary-check.svg';
import { ReactComponent as IconCheckO } from '../../assets/icon-check-o.svg';

import VSCheckbox from '../../components/vsCheckbox/VSCheckbox';
import VSAccordionSimple from '../../components/vsAccordion/VSAccordionSimple';
import Home from '../home/Home';

import { tables } from './Import';
import { toBase64, useQuery } from '../../utils';
import { APP_CONTENT, APP_DEVICE, APP_NAME, APP_VERSION } from '../../constants';

import "./ImportData.scss"
const ImportData = (props) => {

  const {
    clinic
  } = props

  const STEP_SELECT_IMPORT_TYPE = 0
  const STEP_SELECT_IMPORT_FILE = 1
  const STEP_WAIT_IMPORT        = 2

  const accessKeyForImportPageAccess = "SQh651332f79d94a"
  if (useQuery().get("chave-acesso") !== accessKeyForImportPageAccess)
    return <Home {...props} />

  const inputFile = useRef(null)

  const [ loading, setLoading ] = useState(true)
  const [ loadingAction, setLoadingAction ] = useState(false)
  const [ loadingImport, setLoadingImport ] = useState(false)

  const [ options, setOptions ] = useState([])

  const [ activeStep, setActiveStep ] = useState(STEP_SELECT_IMPORT_TYPE)
  const [ selected, setSelected ] = useState(null)
  const [ notes, setNotes ] = useState("")
  const [ file, setFile ] = useState(null)
  const [ showExamples, setShowExamples ] = useState(false)

  const isValid = file && notes

  const [ pendingImports, setPendingImports ] = useState([])

  const type = tables[selected]?.className

  const startImport = () => {
    setLoading(true)

    toBase64(file)
    .then(base64 => {
      axios.post(
        `${process.env.REACT_APP_API_V2}/import/${type}/csv/background`,
        {
          clinicId: clinic.objectId,
          sessionToken: Parse.User.current().getSessionToken(),
          file: base64,
          notes: notes,
          deviceType: APP_DEVICE,
          appContentType: APP_NAME,
          appVersionName: APP_VERSION
        }
      )
      .then(async ({ data }) => {
        if (data?.importId) {
          waitUntilTheImportEnds(data.importId)
        } else {
          Swal.fire(
            'Oops...',
            "Não foi possível iniciar a importação",
            'error'
          )
          .then(_ => {
            getImports()
          })
        }
      })
      .catch(error => {
        setLoading(false)
        console.error(error)
        Swal.fire(
          'Oops...',
          "Ocorreu algum erro ao tentar iniciar a importação",
          'error'
        )
        .then(_ => {
          getImports()
        })
      })
    })
  }

  const waitUntilTheImportEnds = async (objectId) => {
    setActiveStep(STEP_WAIT_IMPORT)
    setLoading(false)
    setLoadingImport(true)

    const query = new Parse.Query('MRImport')
    query.equalTo("objectId", objectId)
    query.subscribe()
    .then(subscription => {
      subscription.on('update', object => {
        if (object.get("status")) {
          getImports()
        } else {
          console.error("importação realizada com erro: ", object.get("error"))
          Swal.fire(
            'Oops...',
            "Ocorreu algum erro durante a importação",
            'error'
          )
          .then(_ => {
            getImports()
          })
        }
      })
    })
    .catch(error => {
      console.error(error)
    })
  }

  const waitUntilTheImportAction = ({ owners, patients }) => {
    let imports = []
    if (owners) {
      imports.push({
        type: "owner",
        title: `Há ${owners} tutores pendentes de aprovação`,
        approve: `approveAllOwnerDraftFromClinic`,
        reject: `rejectAllOwnerDraftFromClinic`
      })
    }
    if (patients) {
      imports.push({
        type: "patient",
        title: `Há ${patients} animais pendentes de aprovação`,
        approve: `approveAllPatientDraftFromClinic`,
        reject: `rejectAllPatientDraftFromClinic`
      })
    }
    setPendingImports(imports)
    setLoading(false)
    setLoadingImport(false)
  }

  const newImport = async () => {
    setLoading(true)
    setSelected(null)
    setFile(null)
    setNotes("")

    let newOptions = JSON.parse(JSON.stringify(tables))
    await Promise.all(
      newOptions.map(async (option, index) => {
        await Promise.all(
          option.depends.map(async dependency => {
            if (dependency && !newOptions[index].disabled) {
              var query = new Parse.Query(dependency)
              query.equalTo("clinic", clinic.object)
              query.equalTo("editStatus", "draft")
              query.equalTo("isDeleted", true)
              const externalIds = await query.count()
              if (externalIds === 0) {
                newOptions[index].disabled = true
              }
            }
          })
        )
      })
    )

    setOptions(newOptions)
    setActiveStep(STEP_SELECT_IMPORT_TYPE)
    setLoading(false)
  }

  const getImports = () => {
    setLoading(true)
    setPendingImports([])

    let promises = []

    const query = new Parse.Query("MRImport")
    query.equalTo("clinic", clinic.object)
    query.doesNotExist("status")
    promises.push(query.first())

    const queryOwner = new Parse.Query("Owner")
    queryOwner.equalTo("clinic", clinic.object)
    queryOwner.equalTo("isDeleted", true)
    queryOwner.equalTo("editStatus", "draft")
    promises.push(queryOwner.count())

    const queryPatient = new Parse.Query("Patient")
    queryPatient.equalTo("clinic", clinic.object)
    queryPatient.equalTo("isDeleted", true)
    queryPatient.equalTo("editStatus", "draft")
    promises.push(queryPatient.count())

    Promise.all(promises)
    .then(([ importInProgress, owners, patients ]) => {
      const importEnds = owners || patients
      if (importInProgress) {
        waitUntilTheImportEnds(importInProgress.id)
      } else if (importEnds) {
        newImport()
        waitUntilTheImportAction({
          owners: owners,
          patients: patients
        })
      } else {
        newImport()
      }
    })
    .catch(error => {
      console.error(error)
    })
  }

  const approveImport = (data) => {
    setLoadingAction(true)
    Parse.Cloud.run(data.approve, {
      clinicId: clinic.objectId
    })
    .then(results => {
      setLoadingAction(false)
      Swal.fire({
        title: 'Importação Concluída!',
        icon: 'success',
        confirmButtonText: 'Fechar',
      })
      .then(_ => {
        getImports()
      })
    })
    .catch(error => {
      setLoadingAction(false)
      console.error(error)
      Swal.fire(
        'Oops...',
        "Ocorreu algum erro ao tentar aprovar a importação",
        'error'
      )
      .then(_ => {
        getImports()
      })
    })
  }

  const rejectImport = (data) => {
    setLoadingAction(true)
    Parse.Cloud.run(data.reject, {
      clinicId: clinic.objectId
    })
    .then(results => {
      setLoadingAction(false)
      Swal.fire({
        title: 'Importação Cancelada!',
        icon: 'success',
        confirmButtonText: 'Fechar',
      })
      .then(_ => {
        getImports()
      })
    })
    .catch(error => {
      setLoadingAction(false)
      console.error(error)
      Swal.fire(
        'Oops...',
        "Ocorreu algum erro ao tentar rejeitar a importação",
        'error'
      )
      .then(_ => {
        getImports()
      })
    })
  }

  useEffect(() => {
    if (clinic && clinic.isLoaded && clinic.object) {
      getImports()
    }
  }, [ clinic ])

  return (
    <div id="import-data">
      <div className="page-content">
        <div className="row g-3 align-items-center">
          <div className="col-12">
            <div className="page-title">
              Importar Dados
            </div>
          </div>
          { loading ? (
            <div className="row data-loading">
              <div className="col">
                <CircularProgress />
              </div>
            </div>
          ) : (
            <>
              <div className="col-12">
                <Stepper className="mb-3" activeStep={activeStep} orientation="vertical">
                  <Step key={0}>
                    <StepLabel>Selecione o tipo dos dados que serão importados</StepLabel>
                    <StepContent>
                      <div className="row g-3 align-items-center">
                        <div className="col">
                          <TextField
                            label="Tipo de dados"
                            placeholder="Selecione o tipo dos dados que serão importadas"
                            value={selected}
                            onChange={({ target: { value } }) => {
                              setSelected(value)
                              setActiveStep(STEP_SELECT_IMPORT_FILE)
                            }}
                            helperText="O tipo dos dados que estarão no arquivo importado (CSV)"
                            select
                            size="small"
                            variant="outlined"
                            fullWidth
                          >
                            { options.map((table, index) => (
                              <MenuItem key={index} value={index} disabled={table.disabled}>
                                <div className="row align-items-center">
                                  <div className="col">{ table.name }</div>
                                  { table.disabled && (
                                    <div className="col-12 lh-1">
                                      <small className="fw-light">{ table.error }</small>
                                    </div>
                                  )}
                                </div>
                              </MenuItem>
                            )) }
                          </TextField>
                        </div>
                      </div>
                    </StepContent>
                  </Step>
                  <Step key={1}>
                    <StepLabel>Selecione o arquivo CSV com os dados que serão importados</StepLabel>
                    <StepContent>
                      <div className="row g-3 align-items-center">
                        <input
                          ref={inputFile}
                          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" 
                          id="contained-button-file"
                          type="file"
                          className="d-none"
                          onChange={(e) => setFile(e.target.files[0])}
                        />
                        { file ? (
                          <>
                            <div className="col-auto">
                              Arquivo selecionado: <b>{ file.name }</b>
                            </div>
                            <div className="col">
                              <Button variant="contained" color="primary" onClick={() => inputFile.current?.click()}>
                                Editar
                              </Button>
                            </div>
                          </>
                        ) : (
                          <div className="col-12">
                            <Button variant="contained" color="primary" onClick={() => inputFile.current?.click()}>
                              selecione o arquivo
                            </Button>
                          </div>
                        )}
                        { !file && <small className="d-block text-danger">É obrigatório um arquivo .CSV para iniciar a importação</small>}
                        <div className="col-12">
                          <TextField
                            placeholder="Ex: Importação feita por João da Silva (link para o chamado no Slack)"
                            label="Observações"
                            value={notes}
                            onChange={({ target: { value } }) => setNotes(value)}
                            error={notes.length == 0}
                            helperText={notes ? <span>Informe seu nome e o motivo da importação</span> : <span>Por favor, preencha as observações para iniciar a importação</span>}
                            size="small"
                            variant="outlined"
                            fullWidth
                            multiline
                          />
                        </div>
                        <div className="col-auto">
                          <div className="row g-3 align-items-center">
                            <div className="col">
                              <button
                                className="vsbox-btn vsbox-btn-alert"
                                onClick={() => {
                                  newImport()
                                }}
                                >
                                Voltar
                              </button>
                            </div>
                            <div className="col">
                              <button className={`vsbox-btn vsbox-btn-dark ${isValid ? "" : "vsbox-btn-disabled"}`} disabled={!isValid} onClick={() => isValid && startImport()}>
                                Importar
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <VSAccordionSimple
                              expanded={false}
                              header={(expanded) => (
                                <>
                                  { expanded ? "Ocultar" : "Ver" } informações sobre o arquivo
                                </>
                              )}
                          >
                            <div className="row g-3 align-items-center">
                              <div className="col-12">
                                <VSCheckbox
                                  label={`${ showExamples ? "Desmarque" : "Marque" } essa opção para ${ showExamples ? "ocultar" : "exibir" } exemplos de valores para cada uma das colunas`}
                                  checked={showExamples}
                                  onChange={(checked) => setShowExamples(checked)}
                                />
                              </div>
                              <div className="col-12">
                                <table className="table table-sm table-striped table-bordered table-hover">
                                  <thead>
                                    <tr>
                                      <th>Coluna</th>
                                      <th>Descrição</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    { tables[selected]?.columns?.map((column, index) => (
                                      <tr key={index}>
                                        <td>{ column.name }{ column.mandatory ? <b className="text-danger">*</b> : "" }</td>
                                        <td>
                                          { column.description }
                                          { showExamples && (
                                            <small className="d-block text-muted">
                                              Exemplo: <span style={{ fontWeight: 300 }}>{ column.example }</span>
                                            </small>
                                          )}
                                        </td>
                                      </tr>
                                    )) }
                                  </tbody>
                                </table>
                                <b className="text-danger">*</b> <small>Coluna obrigatória</small>
                              </div>
                            </div>
                          </VSAccordionSimple>
                        </div>
                      </div>
                    </StepContent>
                  </Step>
                  <Step key={2}>
                    <StepLabel>Aguarde até o término da importação</StepLabel>
                    <StepContent>
                      <div className="row g-3 align-items-center">
                        <div className="col-auto text-center">
                          <CircularProgress size={18} className="my-3" />
                          <p>Importação em andamento...</p>
                          <p>Você pode usar o Prontuário normalmente.</p>
                        </div>
                      </div>
                    </StepContent>
                  </Step>
                </Stepper>
              </div>
              { pendingImports.length > 0 && pendingImports.map((pending, index) => (
                <div className="col-12" key={index}>
                  <Paper className="p-3" square elevation={0}>
                    <div className="row g-3 align-items-center">
                      <div className="col">
                        { pending.title }
                      </div>
                      { loadingAction ? (
                          <div className="col-auto">
                            <CircularProgress size={24} />
                          </div>
                      ) : (
                        <>
                          <div className="col-auto">
                            <button className="vsbox-btn vsbox-btn-primary" onClick={() => approveImport(pending)}>
                              <IconCheck width={24} height={24} className="me-1" />
                              Aprovar
                            </button>
                          </div>
                          <div className="col-auto">
                            <button className="vsbox-btn vsbox-btn-alert" onClick={() => rejectImport(pending)}>
                              <IconClose width={24} height={24} className="me-1" />
                              Rejeitar
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                  </Paper>
                </div>
              ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    clinic: state.clinic
  };
};

export default connect(mapStateToProps)(ImportData)