

export const getProtocolVaccine = (protocolVaccineId, clinicObject) => {
    return new Promise((resolve, reject) => {
        var queryProtocolVaccine = new Parse.Query("MRProtocolVaccine");
        queryProtocolVaccine.equalTo("clinic", clinicObject);
        queryProtocolVaccine.equalTo("isDeleted", false);
        queryProtocolVaccine.get(protocolVaccineId)
        .then(_protocolVaccine => {
            resolve(_protocolVaccine)
        })
        .catch(error => {
            console.error(error);
            reject("Ocorreu algum erro ao tentar buscar o serviço")
        })
    })
}

export const vaccineProtocolActiveOptions = [
    {
        value: true,
        label: "Ativo",
        long: "Protocolo Ativo"
    },
    {
        value: false,
        label: "Inativo",
        long: "Protocolo Inativo"
    }
]

export const vaccineProtocolTotalShotsOptions = [
    {
        label: "Apenas 1 dose",
        value: 1
    },
    {
        label: "Apenas 2 doses",
        value: 2
    },
    {
        label: "Apenas 3 doses",
        value: 3
    },
    {
        label: "Apenas 4 doses",
        value: 4
    },
    {
        label: "Apenas 5 doses",
        value: 5
    }
]