import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import { CircularProgress, TableCell, TableRow } from '@mui/material'

import { ReactComponent as IconNotification } from "../../assets/icon-notification.svg"
import { ReactComponent as IconNotificationOn } from "../../assets/icon-notification-on.svg"
import { ReactComponent as IconNotificationOff } from "../../assets/icon-notification-off.svg"
import { ReactComponent as IconResend } from "../../assets/icon-resend.svg"

import { getFirstScheduledAtFromArray, getFirstSentAtFromArray } from '../patient/tabs/vaccine/VaccineListView'
import { showVaccine } from '../patient/tabs/vaccine/Vaccine'
import HomeData from './HomeData'
import { dayjs } from '../../utils'

import './HomeVaccineReminders.scss'
const HomeVaccineReminders = (props) => {

    const {
        clinic,
        expanded,
        history
    } = props

    const [ loading, setLoading ] = useState(true)
    const [ reminders, setReminders ] = useState([])

    const rowsPerPage = 5

    return (
        <div className="col-12 mb-3">
            <HomeData
                expanded={expanded}
                title="Vacinas Programadas"
                emptyText="Você ainda não possui nenhuma vacina programada registrada"
                loading={loading}
                data={reminders}
                rowsPerPage={rowsPerPage}
                onChange={(page) => {
                    if (clinic && clinic.isLoaded && clinic.object) {
                        setReminders([])
                        setLoading(true)

                        const query = new Parse.Query("Vaccine");
                        query.equalTo("isDeleted", false)
                        query.equalTo("isReminder", true)
                        query.equalTo("clinic", clinic.object)
                        query.descending("createdAt")
                        query.include("patient")
                        query.include("messages")
                        query.limit(15)
                        query.find()
                        .then(_reminders => {
                            setReminders({
                                count: _reminders?.length || 0,
                                results: _reminders || []
                            })
                            setLoading(false)
                        })
                        .catch(error => {
                            console.error(error)
                            setLoading(false)
                        })
                    }
                }}
                renderItem={(reminder, index) => {
                    const patient = reminder?.get("patient")
                    return (
                        <TableRow
                            key={index}
                        >
                            <TableCell className="ps-0 pe-2">
                                <div className="text-link d-inline" onClick={() => history.push(`/animal/${patient?.id}/vacina/${reminder?.id}`)}>
                                    { showVaccine(reminder) }
                                </div>
                                &nbsp;para&nbsp;
                                <div className="text-link d-inline" onClick={() => history.push(`/animal/${patient?.id}`)}>
                                    { patient?.get("name") }
                                </div>
                            </TableCell>
                            <TableCell
                                style={{ width: '86px' }}
                            >
                                <div className="text-truncate">
                                    { dayjs(reminder?.get("documentDate")).format("DD/MM/YYYY") }
                                </div>
                            </TableCell>
                            <TableCell
                                className="ps-2"
                                style={{ width: '32px' }}
                            >
                                { loading ? (
                                    <CircularProgress size={20} />
                                ) : getFirstSentAtFromArray(reminder?.get('messages')) ? (
                                    <button
                                        className="btn-icon toggle-icon"
                                        onClick={() => history.push(`/animal/${patient?.id}/lembrete_automatico/${reminder?.id}`)}
                                    >
                                        <IconNotificationOn width="24px" height="24px" fill="var(--vsprontuario-primary-color)" />
                                    </button>
                                ) : getFirstScheduledAtFromArray(reminder?.get('messages')) ? (
                                    <button
                                        className="btn-icon toggle-icon"
                                        onClick={() => history.push(`/animal/${patient?.id}/lembrete_automatico/${reminder?.id}`)}
                                    >
                                        <IconNotification width="24px" height="24px" fill="var(--vsprontuario-primary-color)" />
                                    </button>
                                ) : (
                                    <button
                                        className="btn-icon toggle-icon"
                                        onClick={() => history.push(`/animal/${patient?.id}/lembrete_automatico/${reminder?.id}`)}
                                    >
                                        <IconNotificationOff width="24px" height="24px" fill="var(--vsprontuario-primary-color)" />
                                    </button>
                                ) }
                            </TableCell>
                        </TableRow>
                    )
                }}
                footer={(
                    <div className="col-12 col-md ps-md-0 mt-3 mt-md-0">
                        <div onClick={() => history.push('/vacina')} className="btn-outline-submit btn-square cursor-pointer">
                            Ver Vacinas Programadas
                        </div>
                    </div>
                )}
            />
        </div>
    )
}

const mapStateToProps = state => {
    return {
        clinic: state.clinic
    };
};
  
export default connect(mapStateToProps)(HomeVaccineReminders);