import React from 'react'
import { connect } from 'react-redux'

import { ReactComponent as IconEdit } from "../../../../assets/icon-primary-edit.svg"
import { ReactComponent as IconTrash } from "../../../../assets/icon-primary-trash.svg"
import { ReactComponent as IconPrescription } from "../../../../assets/icon-patient-prescription.svg"

import { deletePrescription, showPrescription, showPrescriptionType, showQuantities } from './Prescription'
import { addEvent, dayjs, showRegisteredBy } from '../../../../utils'
import { ACL } from '../../../../constants'

import VSAccordionSimple from '../../../../components/vsAccordion/VSAccordionSimple'
import VSMedicalRecordsAudit from '../../../../components/vsMedicalRecordsAudit/VSMedicalRecordsAudit'

import './PrescriptionView.scss'
const PrescriptionView = (props) => {

    const {
        object,
        history,
        noDocumentDate,
        noAudit,
        permissions
    } = props

    const patient = object.get("patient")

    const isDeleted = object.get("isDeleted")

    const drugs = object?.get("drugs") || []

    const iconSigned = (
        <svg className="me-1 align-bottom" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.99996 1.6665C5.39996 1.6665 1.66663 5.39984 1.66663 9.99984C1.66663 14.5998 5.39996 18.3332 9.99996 18.3332C14.6 18.3332 18.3333 14.5998 18.3333 9.99984C18.3333 5.39984 14.6 1.6665 9.99996 1.6665ZM9.99996 16.6665C6.32496 16.6665 3.33329 13.6748 3.33329 9.99984C3.33329 6.32484 6.32496 3.33317 9.99996 3.33317C13.675 3.33317 16.6666 6.32484 16.6666 9.99984C16.6666 13.6748 13.675 16.6665 9.99996 16.6665ZM13.825 6.3165L8.33329 11.8082L6.17496 9.65817L4.99996 10.8332L8.33329 14.1665L15 7.49984L13.825 6.3165Z" fill="#6AA951"/>
        </svg>
    )

    const docSigned = object.get("signed") ? (
        <div className="col mb-3 vertical-separator">
            { iconSigned }
            <strong style={{ fontWeoght: 600 }}>Receita assinada digitalmente</strong>
        </div>
    ) : null

    return (
        <div id="patient-prescription-view" className={`vsbox mb-3 ${isDeleted ? "vsbox-disabled" : ""}`}>
            <div className="vsbox-body pb-0">
                <div className="row g-3 mb-3 align-items-center">
                    { !noDocumentDate && (
                        <div className="col-auto pe-0">
                            <div className="vsbox-chip">
                                { dayjs(object.get("documentDate")).format("DD/MM/YYYY") }
                            </div>
                        </div>
                    )}
                    <div className="col-12 col-lg">
                        <div className="row align-items-center g-3">
                            <div className="col-auto">
                                <IconPrescription width="24" height="24" fill="var(--vsprontuario-gray-color)" />
                            </div>
                            <div className="col ps-0">
                                <label className="vsbox-info">Tipo de Registro</label>
                                <div className="vsbox-info-text">
                                    { showPrescription(object) }
                                </div>
                            </div>
                        </div>
                    </div>
                    { !isDeleted && (
                        <div className="col-12 col-md-auto">
                            <div className="row g-3 align-items-center justify-content-center">
                                <div className="col-12 col-md-auto">
                                    <button
                                        className={`vsbox-btn ${isDeleted ? "vsbox-btn-disabled" : "vsbox-btn-primary"}`}
                                        onClick={() => history.push(`/prescricao/${ object.id }`)}
                                        disabled={isDeleted}
                                    >
                                        Reemitir Prescrição
                                    </button>
                                </div>
                                { permissions?.check(ACL.PRESCRIPTION, ACL.Rule.EDIT, object) && (
                                    <div className="col col-md-auto">
                                        <button
                                            className={`vsbox-btn ${isDeleted ? "vsbox-btn-disabled" : "vsbox-btn-dark"}`}
                                            onClick={() => history.push(`/prescricao/${ patient.id }/${ object.id }`)}
                                            disabled={isDeleted}
                                        >
                                            <IconEdit width="24" height="24" fill={isDeleted ? "var(--vsprontuario-gray-color)" : "var(--vsprontuario-primary-color-dark)"} />
                                        </button>
                                    </div>
                                )}
                                { permissions?.check(ACL.PRESCRIPTION, ACL.Rule.DELETE, object) && (
                                    <div className="col col-md-auto">
                                        <button
                                            className={`vsbox-btn ${isDeleted ? "vsbox-btn-disabled" : "vsbox-btn-alert"}`}
                                            onClick={() => deletePrescription(object, () => addEvent(["Prescription__getList", "History__getList"], {}))}
                                            disabled={isDeleted}
                                        >
                                            <IconTrash width="24" height="24" fill={isDeleted ? "var(--vsprontuario-gray-color)" : "var(--vsprontuario-primary-color)"} />
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
                { showRegisteredBy(object, docSigned) }
                { !isDeleted && (
                    <>
                        <div className="vsbox-separator"></div>
                        <div className="row">
                            <div className="col-12 vsbox--info">
                                <VSAccordionSimple
                                    expanded={true}
                                    header={(expanded) => (
                                        `Ver ${ expanded ? "menos" : "mais" } informações`
                                    )}
                                >
                                    { drugs.length > 0 ? (
                                        <div className="row">
                                            <div className="col-12">
                                                { drugs.map((drug, index) => (
                                                    <div className="vsbox-item mb-3" key={index}>
                                                        <div className="row mb-2">
                                                            <div className="col-12">
                                                                { drug.compounds && drug.compounds.length > 0 ? (
                                                                    <>
                                                                        <div className="vsbox-title">{ drug.compounds.map(compound => `${compound.compoundName} (${compound.quantity} ${compound.unit})`).join(", ") }</div>
                                                                        <div className="vsbox-info-text">{ `${drug.excipientType} q.s.p ${drug.excipientQuantity} ${drug.excipientUnit}` }</div>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <div className="vsbox-title">
                                                                            { drug.drug } { drug.dosageForm }
                                                                        </div>
                                                                        <div className="vsbox-info-text">
                                                                            { drug.formattedClassifications }
                                                                        </div>
                                                                    </>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="vsbox-separator my-1"></div>
                                                        <div className="row vsbox-item-text my-2">
                                                            <div className="col-12 col-md-auto">
                                                                <b>Tipo de Receita:</b> { showPrescriptionType(drug) }
                                                            </div>
                                                            <div className="col-12 col-md-auto">
                                                                <b>Farmácia:</b> { drug.drugType == "1" && "Manipulação" } { drug.humanOrVet || "Não informado" }
                                                            </div>
                                                            <div className="col-12 col-md-auto">
                                                                <b>Via:</b> { drug.usage || "Não informado" }
                                                            </div>
                                                            <div className="col-12 col-md-auto">
                                                                <b>Quantidade:</b> { showQuantities(drug) }
                                                            </div>
                                                        </div>
                                                        <div className="vsbox-separator my-1"></div>
                                                        <div className="row vsbox-item-text mt-2">
                                                            <div className="col-auto pre-line">
                                                                <b>Posologia:</b> { drug.dosage || "Não informado" }
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="vsbox-item mb-3">
                                                    Nenhum produto prescrito
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    <div className="row">
                                        <div className="col vsbox-title">
                                            Observações
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col mb-3 pre-line">
                                            { object.get("notes") || "Não informado" }
                                        </div>
                                    </div>
                                </VSAccordionSimple>
                            </div>
                        </div>
                    </>
                )}
                { !noAudit && (
                    <VSMedicalRecordsAudit parseObject={object} />
                )}
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        permissions: state.permissions
    };
};
  
export default connect(mapStateToProps)(PrescriptionView)