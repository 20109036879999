import { connect } from 'react-redux'
import { ReactComponent as IconClose } from "../../assets/icon-close.svg"
import React, { useEffect, useState } from 'react';

import "./Invite.scss"
import { getSelectedClinicUser } from '../../store/actions';
import { APP_DEVICE, APP_NAME, APP_VERSION } from '../../constants';
import { createClinic } from '../clinic/Clinic';
import { CircularProgress } from '@mui/material';
import { addEvent } from '../../utils';

const Invite = (props) => {
	const {
		user,
        clinic
	} = props

	const [ invites, setInvites ] = useState([])
	const [ totalClinics, setTotalClinics ] = useState(0)
	const [ loading, setLoading ] = useState(false)

	const getInvites = () => {
		var query = new Parse.Query("ClinicUser");
		query.equalTo("email", user.email);
		query.equalTo("isDeleted", false);
		query.doesNotExist("accepted");
		query.include("clinic");
		query.include("profile");
		query.include("user");
		query.include("createdBy");
		query.find()
		.then(_invites => {
			setInvites(_invites)
		})
		.catch(error => {
			console.error(error)
		})
	}

    const onHide = (index) => {
        const _invites = [...invites]
        _invites.splice(index, 1);
        setInvites(_invites)
    }

	const onAccept = (clinicUser) => {
        setLoading(true)

		clinicUser.set("email", user.email)
		clinicUser.set("name", user.fullName)
		clinicUser.set("user", user.object)
		clinicUser.set("accepted", true)
		clinicUser.save()
		.then(_ => {
            Swal.fire({
                title: 'Convite Aceito!',
                icon: 'success',
                confirmButtonText: 'Fechar',
            })
            .then(_ => {
                // Se for a primeira ou segunda clínica do usuário
                if (totalClinics <= 1) {
                    addEvent("Tour__onboardingForFirstClinic", {})
                }
            })

            setLoading(false)
			props.dispatch(getSelectedClinicUser(clinicUser))
		})
		.catch(error => {
            console.error(error)
            Swal.fire(
                'Oops...',
                `Não foi possível aceitar o convite`,
                'error'
            )
            clinicUser.revert()
		})
	}

	const onDecline = (clinicUser) => {
        const isFirstClinic = totalClinics == 0

        Swal.fire({
            title: "Tem certeza que deseja recusar o convite?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Recusar Convite!',
            cancelButtonText: 'Fechar'
        }).then((result) => {
            if (result.isConfirmed) {
                setLoading(true)

                clinicUser.set("email", user.email)
                clinicUser.set("name", user.fullName)
                clinicUser.set("user", user.object)
                clinicUser.set("accepted", false)
                clinicUser.save()
                .then(object => {
                    if (isFirstClinic) {
                        const saveUser = Parse.User.current()

                        const params =  {
                            "user": saveUser.id,
                            "createdApp": APP_NAME,
                            "createdAppVersion": APP_VERSION,
                            "createdDevice": APP_DEVICE,
                            "withSampleData": true,
                            "clinic": {
                                "name": saveUser.get("fullName") || "Minha Clínica"
                            }
                        }
                        createClinic(params)
                        .then(_clinic => {
                            props.dispatch(getSelectedClinicUser(object))
                            setLoading(false)
                        })
                        .catch(message => {
                            Swal.fire(
                                'Oops...',
                                message,
                                'error'
                            )
                            setLoading(false)
                        })
                    } else {
                        getInvites()
                        setLoading(false)
                    }
                })
                .catch(error => {
                    console.error(error)
                    Swal.fire(
                        'Oops...',
                        `Não foi possível recusar o convite`,
                        'error'
                    )
                    setLoading(false)
                    clinicUser.revert()
                })
            } else {
                setLoading(false)
            }
        })
	}

	useEffect(() => {
		if (user && user.object) {
            getInvites()
		}
	}, [ clinic ])

    return (
        <React.Fragment>
            { loading ? (
                <div className="vsprompt mb-3">
                    <div className="data-loading">
                        <CircularProgress color="secondary" />
                    </div>
                </div>
            ) : (
                invites && invites.length > 0 && invites.map((invite, index) => (
                    <div className="vsprompt mb-3" key={index}>
                        <div className="vsprompt-text">
                            Você foi convidado(a) para participar da clínica <b>{ invite?.get("clinic")?.get("name") }</b> por <b>{ invite?.get("createdBy")?.get("fullName") }</b>.
                        </div>
                        <div className="vsprompt-close" onClick={() => onHide(index)}>
                            <IconClose width="24" height="24" />
                        </div>
                        <div className="vsprompt-actions">
                            <button className="btn-accept" onClick={() => onAccept(invite)}>
                                Aceitar Convite
                            </button>
                            <button className="btn-decline" onClick={() => onDecline(invite)}>
                                Recusar Convite
                            </button>
                        </div>
                    </div>
                ))
            )}
        </React.Fragment>
    )
}

const mapStateToProps = state => {
    return {
        user: state.user,
        clinic: state.clinic
    };
};
  
export default connect(mapStateToProps)(Invite);