import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { useParams } from "react-router-dom"
import { makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import Drawer from '@mui/material/Drawer';
import InputAdornment from '@mui/material/InputAdornment';
import { Checkbox, FormControlLabel } from '@mui/material'
import Switch from '@mui/material/Switch';

import "./DisplayStoreForm.scss"
import { addEvent, getStateList, mandatoryField, optionalField, saveOnTracker, setClinic, stringifyArray, userToObject, validateCNPJ, validateEmail } from "../../utils";
import { MenuItem } from "@mui/material";
import { ACL, APP_DEVICE, APP_NAME, APP_VERSION } from "../../constants";
import BoxDropdown from "../../components/boxDropdown/BoxDropdown";
import ColorPicker from "../../components/colorPicker/ColorPicker";
import ImageUpload from "../../components/imageUpload/ImageUpload";

import { ReactComponent as IconAdd } from "../../assets/icon-add.svg"
import { ReactComponent as PrescriptionTheme1 } from "../../assets/prescription-theme1.svg"
import { ReactComponent as PrescriptionTheme2 } from "../../assets/prescription-theme2.svg"
import { ReactComponent as PrescriptionTheme3 } from "../../assets/prescription-theme3.svg"
import StoreInfo from "./StoreInfo";
import AppointmentInfo from "./AppointmentInfo";
import TextFieldMask from "../../components/textFieldMask/TextFieldMask";
import { CircularProgress } from "@mui/material";
import axios from "axios";
import VSTooltip from "../../components/vsTooltip/VSTooltip";
import DisplayStoreField from "./fields/DisplayStoreField";
import { createClinic } from "./Clinic";
import VSDrawer from "../../components/vsDrawer/VSDrawer";
import { getClinicUser } from "../vet/Vet";
import { getSelectedClinicUser } from "../../store/actions";

const drawerWidth = 394;

const DisplayStoreForm = (props) => {

    const {
        clinic,
        history
    } = props

    const [ loading, setLoading ] = useState(false)

    const [ displayStore, setDisplayStore ] = useState(true)

    const save = (_displayStore) => {
        if (loading)
            return

        if (!permissions.check(ACL.SETTING, ACL.Rule.EDIT))
            return
        
        setLoading(true)
        setDisplayStore(_displayStore)

        let saveClinic = clinic.object
        saveClinic.set("displayStore", _displayStore);
        saveClinic.save()
        .then(_clinic => {
            afterSave(_clinic)
        })
        .catch(error => {
            console.error(error)
            Swal.fire(
                'Oops...',
                `Não foi possível salvar a opção de compra, por favor, tente novamente`,
                'error'
            )
            setLoading(false)
            saveClinic.revert()
        })
    }

    const afterSave = (_clinic) => {
        getClinicUser(_clinic)
        .then(clinicUser => {
            saveOnTracker("Concluiu", "Clínica", _clinic.id)
            addEvent("TourSettings__onChangeSettings", {})
            props.dispatch(getSelectedClinicUser(clinicUser))
    
            setLoading(false)
        })
        .catch(error => {
            console.error(error)
            Swal.fire(
                'Oops...',
                `Não foi possível salvar a opção de compra, por favor, tente novamente`,
                'error'
            )
            setLoading(false)
        })
    }

    useEffect(() => {
        if (permissions && !permissions.check(ACL.SETTING, ACL.Rule.EDIT)) {
            permissions.error()
        }
    }, [ permissions ])

    useEffect(() => {
        if (clinic.object) {
            const selectedClinic = clinic.object
            setDisplayStore(selectedClinic.get("displayStore") !== false)
        }
    }, [ clinic ])

    return (
        <VSDrawer
            id="display-store-form"
            width="428px"
            title={"Sobre Opção de Compra"}
            onClose={() => history.push('/')}
            onCancel={() => history.push('/')}
            VSDrawerFooterProps={{
                show: false
            }}
        >
            { loading ? (
                <div className="row data-loading">
                    <div className="col-12 mb-3">
                        <CircularProgress />
                    </div>
                    <div className="col-12">
                        Salvando a Opção de Compra...
                    </div>
                </div>
            ) : (
                <DisplayStoreField
                    value={displayStore}
                    onChange={(newValue) => save(newValue)}
                />
            )}
        </VSDrawer>
    )
}

const mapStateToProps = state => {
    return {
        user: state.user,
        clinic: state.clinic,
        permissions: state.permissions
    };
};

export default connect(mapStateToProps)(DisplayStoreForm)