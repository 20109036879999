import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'

import { CircularProgress } from '@mui/material'

import { ReactComponent as IconEdit } from "../../../../assets/icon-primary-edit.svg"
import { ReactComponent as IconTrash } from "../../../../assets/icon-primary-trash.svg"
import { ReactComponent as IconAttachment } from "../../../../assets/icon-patient-attachment.svg"
import { ReactComponent as IconPdf } from "../../../../assets/pdf.svg"
import { ReactComponent as IconFile } from "../../../../assets/file.svg"

import { ACL } from '../../../../constants'
import { addEvent, dayjs, getAttachments, showRegisteredBy } from '../../../../utils'
import { deleteAttachment, showAttachment } from './Attachment'
import { getFileType, isImage, isPdf } from '../../../../components/vsFileUpload/VSFileUpload'

import VSAccordionSimple from '../../../../components/vsAccordion/VSAccordionSimple'
import VSMedicalRecordsAudit from '../../../../components/vsMedicalRecordsAudit/VSMedicalRecordsAudit'

import './AttachmentView.scss'
const AttachmentView = (props) => {

    const {
        object,
        history,
        hideNotes,
        noDocumentDate,
        noAudit,
        permissions
    } = props

    const isMounted = useRef(null)

    const [ files, setFiles ] = useState([])
    const [ filesLoading, setFilesLoading ] = useState(false)

    const patient = object.get("patient")
    const isDeleted = object.get("isDeleted")

    useEffect(() => {
        isMounted.current = true
        setFilesLoading(true)

        getAttachments(object.get("attachments"))
        .then(_files => {
            if (isMounted.current) {
                setFiles(_files)
                setFilesLoading(false)
            }
        })
        .catch(error => {
            if (isMounted.current) {
                console.error(error)
                setFilesLoading(false)
            }
        })
        return () => {
            isMounted.current = false
        }
    }, [])

    return (
        <div id="patient-attachment-view" className={`vsbox mb-3 ${isDeleted ? "vsbox-disabled" : ""}`}>
            <div className="vsbox-body pb-0">
                <div className="row g-3 mb-3 align-items-center">
                    { !noDocumentDate && (
                        <div className="col-auto pe-0">
                            <div className="vsbox-chip">
                                { dayjs(object.get("documentDate")).format("DD/MM/YYYY") }
                            </div>
                        </div>
                    )}
                    <div className="col-12 col-md-auto">
                        <div className="row g-3 align-items-center">
                            <div className="col-auto">
                                <IconAttachment width="24" height="24" fill="var(--vsprontuario-gray-color)" />
                            </div>
                            <div className="col ps-0">
                                <label className="vsbox-info">Tipo de Registro</label>
                                <div className="vsbox-info-text">
                                    Anexo de { showAttachment(object) }
                                </div>
                            </div>
                        </div>
                    </div>
                    { !isDeleted && (
                        <>
                            <div className="col-12 col-md">
                                <label className="vsbox-info text-nowrap">Nome do Anexo</label>
                                <div className="vsbox-info-text">
                                    { object.get("description") || "Não informado" }
                                </div>
                            </div>
                            <div className="col-12 col-md-auto">
                                <div className="row align-items-center justify-content-center">
                                    { permissions?.check(ACL.ATTACHMENT, ACL.Rule.EDIT, object) && (
                                        <div className="col col-md-auto">
                                            <button
                                                className={`vsbox-btn ${isDeleted ? "vsbox-btn-disabled" : "vsbox-btn-dark"}`}
                                                onClick={() => history.push(`/animal/${ patient.id }/anexo/${ object.id }`)}
                                                disabled={isDeleted}
                                            >
                                                <IconEdit width="24" height="24" fill={isDeleted ? "var(--vsprontuario-gray-color)" : "var(--vsprontuario-primary-color-dark)"} />
                                            </button>
                                        </div>
                                    )}
                                    { permissions?.check(ACL.ATTACHMENT, ACL.Rule.DELETE, object) && (
                                        <div className="col col-md-auto ps-0">
                                            <button
                                                className={`vsbox-btn ${isDeleted ? "vsbox-btn-disabled" : "vsbox-btn-alert"}`}
                                                onClick={() => deleteAttachment(object, () => addEvent(["Attachment__getList", "History__getList"], {}))}
                                                disabled={isDeleted}
                                            >
                                                <IconTrash width="24" height="24" fill={isDeleted ? "var(--vsprontuario-gray-color)" : "var(--vsprontuario-primary-color)"} />
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </>
                    )}
                </div>
                { showRegisteredBy(object) }
                { !isDeleted && (
                    <>
                        <div className="vsbox-separator"></div>
                        <div className="row">
                            <div className="col-12 vsbox--info">
                                <VSAccordionSimple
                                    expanded={true}
                                    header={(expanded) => `${ expanded ? "Ocultar" : "Ver" } Arquivos Anexados`}
                                >
                                    { filesLoading ? (
                                        <div className="data-loading">
                                            <CircularProgress color="secondary" size="16px" />
                                        </div>
                                    ) : (
                                        <div className="row align-items-center">
                                            { object.get("attachments") && object.get("attachments").length ? object.get("attachments").map((file, index) => {
                                                const type = getFileType(file.name())
                                                return (
                                                    <div className="col col-md-auto" key={index}>
                                                        <div className="attachment-preview mx-auto mx-md-0 mb-2">
                                                            { isImage(type) ? (
                                                                <img 
                                                                    src={file.url()} 
                                                                    height="100px" 
                                                                    width="auto" 
                                                                    style={{ 
                                                                        display: "block",
                                                                        maxWidth: "90%",
                                                                        padding: "8px 0"
                                                                    }} 
                                                                />
                                                            ) : isPdf(type) ? (
                                                                <IconPdf />
                                                            ) : (
                                                                <IconFile />
                                                            )}
                                                        </div>
                                                        <div className="row mb-3">
                                                            { (isImage(type) || isPdf(type)) && (
                                                                <div className="col pe-0">
                                                                    <a
                                                                        className="d-block vsbox-btn vsbox-btn-save"
                                                                        href={file.url()}
                                                                        target="_blank"
                                                                    >
                                                                        Visualizar
                                                                    </a>
                                                                </div>
                                                            )}
                                                            <div className="col">
                                                                <a
                                                                    className="d-block vsbox-btn vsbox-btn-primary w-100"
                                                                    href={files[index]}
                                                                    target="_blank"
                                                                    download={file.name()}
                                                                >
                                                                    Baixar
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }) : (
                                                <div className="col-auto mb-3">
                                                    Nenhum arquivo anexado
                                                </div>
                                            )}
                                        </div>
                                    )}
                                    <div className="row">
                                        <div className="col vsbox-title">
                                            Observações Internas
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col mb-3 pre-line">
                                            { object.get("notes") || "Não informado" }
                                        </div>
                                    </div>
                                </VSAccordionSimple>
                            </div>
                        </div>
                    </>
                )}
                { !noAudit && (
                    <VSMedicalRecordsAudit parseObject={object} />
                )}
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        permissions: state.permissions
    };
};
  
export default connect(mapStateToProps)(AttachmentView)