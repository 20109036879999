import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux';

import './ClinicSettingClinicInfo.scss'
import VSAccordionSetting from "../../VSAccordionSetting";

import { CircularProgress } from "@mui/material";
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';

import { getStateList, mandatoryField, optionalField, saveOnTracker, setClinic, stringifyArray, validateCNPJ, validateEmail, getAddressByZipCode, useDidMountEffect, addEvent } from "../../../../utils";
import { ACL, APP_DEVICE, APP_NAME, APP_VERSION } from "../../../../constants";
import { getSelectedClinicUser } from "../../../../store/actions";
import { getClinicUser } from "../../../vet/Vet";
import { MenuItem } from "@mui/material";
import TextFieldMask from "../../../../components/textFieldMask/TextFieldMask";

import SettingPreview from '../../SettingPreview'

import { ReactComponent as IconPrimaryTrash } from "../../../../assets/icon-primary-trash.svg"
import { ReactComponent as IconTransaction } from "../../../../assets/icon-transaction.svg"

const ClinicSettingClinicInfo = props => {
    const {
        user,
        clinic,
        permissions
    } = props

    const [ loading, setLoading ] = useState(false)
    const [ isFormChanged, setIsFormChanged ] = useState(false)

    // Dados básicos
    const [ name, setName ] = useState("")
    const [ errorName, setErrorName ] = useState(false)
    const [ helperName, setHelperName ] = useState(mandatoryField)

    const [ crmv, setCrmv ] = useState("")
    const [ errorCrmv, setErrorCrmv ] = useState(false)
    const [ helperCrmv, setHelperCrmv ] = useState(mandatoryField)

    const [ crmvStateId, setCrmvStateId ] = useState("")
    const [ errorCrmvStateId, setErrorCrmvStateId ] = useState(false)
    const [ helperCrmvStateId, setHelperCrmvStateId ] = useState(mandatoryField)
    
    const [ registrationMAPA, setRegistrationMAPA ] = useState("")

    const [ cnpj, setCnpj ] = useState("")
    const [ errorCnpj, setErrorCnpj ] = useState(false)
    const [ helperCnpj, setHelperCnpj ] = useState(optionalField)

    // Dados complementares
    const [ companyName, setCompanyName ] = useState("")
    const [ errorCompanyName, setErrorCompanyName ] = useState(false)
    const [ helperCompanyName, setHelperCompanyName ] = useState(optionalField)

    const [ municipalRegistrationId, setMunicipalRegistrationId ] = useState("")

    const [ stateRegistrationId, setStateRegistrationId ] = useState("")

    // Endereço
    const [ loadingZipCode, setLoadingZipCode ] = useState(false)
    const [ zipCode, setZipCode ] = useState("")
    const [ errorZipCode, setErrorZipCode ] = useState(false)
    const [ helperZipCode, setHelperZipCode ] = useState(optionalField)

    const [ addressStreet, setAddressStreet ] = useState("")
    const [ errorAddressStreet, setErrorAddressStreet ] = useState(false)
    const [ helperAddressStreet, setHelperAddressStreet ] = useState(optionalField)

    const [ addressNumber, setAddressNumber ] = useState("")
    const [ addressComplement, setAddressComplement ] = useState("")
    const [ neighborhood, setNeighborhood ] = useState("")
    const [ errorNeighborhood, setErrorNeighborhood ] = useState(false)
    const [ helperNeighborhood, setHelperNeighborhood ] = useState(optionalField)

    const [ cityId, setCityId ] = useState("")
    const [ city, setCity ] = useState("")
    const [ errorCity, setErrorCity ] = useState(false)
    const [ helperCity, setHelperCity ] = useState(optionalField)

    const [ stateId, setStateId ] = useState("")

    // Informações de contato
    const [ phone, setPhone ] = useState("")
    const [ errorPhone, setErrorPhone ] = useState(false)
    const [ helperPhone, setHelperPhone ] = useState(optionalField)

    const [ email, setEmail ] = useState("")
    const [ errorEmail, setErrorEmail ] = useState(false)
    const [ helperEmail, setHelperEmail ] = useState(optionalField)

    // Informações de divulgação
    const [ site, setSite ] = useState("")
    const [ facebook, setFacebook ] = useState("")
    const [ instagram, setInstagram ] = useState("")
    const [ twitter, setTwitter ] = useState("")

    const findAddress = () => {
        setLoadingZipCode(true)
        getAddressByZipCode(zipCode)
        .then(data => {
            setLoadingZipCode(false)
            setAddressStreet(data.address)
            setNeighborhood(data.neighborhood)
            setCity(data.city)
            setCityId(data.cityId)
            setStateId(data.state_id)
        })
        .catch(_ => {
            setLoadingZipCode(false)
        })
        validateOnTouch()
    }

    const validateOnTouch = () => {
        if (name || crmv || crmvStateId || cnpj || registrationMAPA || companyName || municipalRegistrationId || stateRegistrationId || zipCode || addressStreet || neighborhood || city || phone || email) {
            validate()
        }
    }

    const validate = () => {
        let isValid = true
        if (loading) {
            isValid = false
        }

        if (!name) {
            isValid = false
            setErrorName(true)
            setHelperName("O nome da clínica é obrigatório")
        } else if (name.length < 3) {
            isValid = false
            setErrorName(true)
            setHelperName("O nome da clínica não pode ser menor que 3 caracteres")
        } else {
            setErrorName(false)
            setHelperName(mandatoryField)
        }

        if (!crmv) {
            isValid = false
            setErrorCrmv(true)
            setHelperCrmv("O CRMV da clínica é obrigatório")
        } else if (crmv.length < 3) {
            isValid = false
            setErrorCrmv(true)
            setHelperCrmv("O CRMV da clínica não pode ser menor que 3 caracteres")
        } else {
            setErrorCrmv(false)
            setHelperCrmv(mandatoryField)
        }

        if (!crmvStateId) {
            isValid = false
            setErrorCrmvStateId(true)
            setHelperCrmvStateId("O estado do CRMV da clínica é obrigatório")
        } else {
            setErrorCrmvStateId(false)
            setHelperCrmvStateId(mandatoryField)
        }

        if (!companyName || companyName.length >= 3) {
            setErrorCompanyName(false)
            setHelperCompanyName(optionalField)
        } else {
            isValid = false
            setErrorCompanyName(true)
            setHelperCompanyName("A razão social não pode ser menor que 3 caracteres")
        }

        let unmaskedCnpj = cnpj && cnpj.replace(/[^0-9]/g, '')
        if (!unmaskedCnpj || validateCNPJ(unmaskedCnpj)) {
            setErrorCnpj(false)
            setHelperCnpj(optionalField)
        } else {
            isValid = false
            setErrorCnpj(true)
            setHelperCnpj("O CNPJ informado não é válido")
        }

        let unmaskedPhone = phone && phone.replace(/[^0-9]/g, '')
        if(unmaskedPhone.length == 0 || unmaskedPhone.length == 10 || unmaskedPhone.length == 11){
            setErrorPhone(false)
            setHelperPhone(optionalField)
        } else {
            isValid = false
            setErrorPhone(true)
            setHelperPhone("O número de telefone informado não é válido")
        }

        if(email.length == 0 || validateEmail(email)) {
            setErrorEmail(false)
            setHelperEmail(optionalField)
        } else {
            isValid = false
            setErrorEmail(true)
            setHelperEmail("O e-mail informado não é válido")
        }

        if(zipCode.length > 0 && zipCode.length < 9){
            isValid = false
            setErrorZipCode(true)
            setHelperZipCode("O CEP da clínica não é válido")
        } else  {
            setErrorZipCode(false)
            setHelperZipCode(optionalField)
        }

        if(addressStreet.length > 0 && addressStreet.length < 3){
            isValid = false
            setErrorAddressStreet(true)
            setHelperAddressStreet("O endereço não pode ser menor que 3 caracteres")
        } else {
            setErrorAddressStreet(false)
            setHelperAddressStreet(optionalField)
        }

        if(neighborhood.length > 0 && neighborhood.length < 3){
            isValid = false
            setErrorNeighborhood(true)
            setHelperNeighborhood("O bairro não pode ser menor que 3 caracteres")
        } else {
            setErrorNeighborhood(false)
            setHelperNeighborhood(optionalField)
        }

        if(city.length > 0 && city.length < 3){
            isValid = false
            setErrorCity(true)
            setHelperCity("A cidade não pode ser menor que 3 caracteres")
        } else {
            setErrorCity(false)
            setHelperCity(optionalField)
        }

        return isValid
    }

    const isValid = () => !loading && !errorName && !errorCrmv && !errorCrmvStateId && !errorCnpj && !errorZipCode && !errorAddressStreet && !errorNeighborhood && !errorCity && !errorPhone && !errorEmail && permissions?.check(ACL.SETTING, ACL.Rule.EDIT, clinic.object)

    const save = () => {
        if (validate()) {
            setLoading(true)
            setIsFormChanged(false)

            const saveUser = Parse.User.current()

            let saveClinic = clinic.object
            saveClinic.set("addressComplement", addressComplement);
            saveClinic.set("addressNumber", addressNumber);
            saveClinic.set("addressStreet", addressStreet);
            saveClinic.set("changedApp", APP_NAME)
            saveClinic.set("changedAppVersion", APP_VERSION)
            saveClinic.set("changedAt", new Date())
            saveClinic.set("changedBy", saveUser)
            saveClinic.set("changedDevice", APP_DEVICE)
            saveClinic.set("city", city);
            saveClinic.set("cityId", cityId);
            saveClinic.set("cnpj", cnpj);
            saveClinic.set("companyName", companyName);
            saveClinic.set("crmv", crmv);
            saveClinic.set("crmvStateId", crmvStateId);
            saveClinic.set("email", email);
            saveClinic.set("facebook", facebook);
            saveClinic.set("instagram", instagram);
            saveClinic.set("municipalRegistrationId", municipalRegistrationId);
            saveClinic.set("name", name);
            saveClinic.set("neighborhood", neighborhood);
            saveClinic.set("phone", phone);
            saveClinic.set("registrationMAPA", registrationMAPA);
            saveClinic.set("site", site);
            saveClinic.set("stateId", stateId);
            saveClinic.set("stateRegistrationId", stateRegistrationId);
            saveClinic.set("twitter", twitter);
            saveClinic.set("zipCode", zipCode);
            saveClinic.save()
            .then(_clinic => {
                afterSave(_clinic)
            })
            .catch(error => {
                console.error(error)
                Swal.fire(
                    'Oops...',
                    `Não foi possível editar a clínica, por favor, tente novamente`,
                    'error'
                )
                setLoading(false)
                saveClinic.revert()
            })
        }
    }

    const afterSave = (_clinic) => {
        getClinicUser(_clinic)
        .then(clinicUser => {
            saveOnTracker("Concluiu", "Clínica", _clinic.id)
            addEvent("TourSettings__onChangeSettings", {})
            setClinic(_clinic)
            props.dispatch(getSelectedClinicUser(clinicUser))
            setLoading(false)
        })
        .catch(error => {
            console.error(error)
            Swal.fire(
                'Oops...',
                error,
                'error'
            )
            setLoading(false)
        })
    }

    useEffect(() => {
        validateOnTouch()
    }, [
        name, crmv, crmvStateId, registrationMAPA, cnpj, 
        companyName, municipalRegistrationId, stateRegistrationId, 
        addressStreet, neighborhood, city, 
        phone, email
    ])

    const useAfterClinicSetEffect = (func, deps) => {
        const ready = useRef(false)

        useDidMountEffect(() => {
            if (ready.current) func();
            else ready.current = true;
        }, deps)
    }
    useAfterClinicSetEffect(() => {
        !isFormChanged &&
        setIsFormChanged(true)
    }, [ 
        name, crmv, crmvStateId, registrationMAPA, cnpj, 
        companyName, municipalRegistrationId, stateRegistrationId, 
        addressComplement, addressNumber, addressStreet, neighborhood, city, stateId, zipCode,
        phone, email,
        site, facebook, instagram, twitter
    ])

    useEffect(() => {
        findAddress()
    }, [ zipCode ])

    useEffect(() => {
        if (clinic.object) {
            if (!permissions?.check(ACL.SETTING, ACL.Rule.EDIT, clinic.object)) {
                permissions?.error()
                return
            }

            const selectedClinic = clinic.object
            setName(selectedClinic.get("name") || "")
            setCrmv(selectedClinic.get("crmv") || "")
            setCrmvStateId(selectedClinic.get("crmvStateId") || "")
            setCompanyName(selectedClinic.get("companyName") || "")
            setCnpj(selectedClinic.get("cnpj") || "")
            setRegistrationMAPA(selectedClinic.get("registrationMAPA") || "")
            setMunicipalRegistrationId(selectedClinic.get("municipalRegistrationId") || "")
            setStateRegistrationId(selectedClinic.get("stateRegistrationId") || "")
            setPhone(selectedClinic.get("phone") || "")
            setEmail(selectedClinic.get("email") || "")
            setSite(selectedClinic.get("site") || "")
            setFacebook(selectedClinic.get("facebook") || "")
            setInstagram(selectedClinic.get("instagram") || "")
            setTwitter(selectedClinic.get("twitter") || "")
            setZipCode(selectedClinic.get("zipCode") || "")
            setAddressStreet(selectedClinic.get("addressStreet") || "")
            setAddressNumber(selectedClinic.get("addressNumber") || "")
            setAddressComplement(selectedClinic.get("addressComplement") || "")
            setNeighborhood(selectedClinic.get("neighborhood") || "")
            setCity(selectedClinic.get("city") || "")
            setCityId(selectedClinic.get("cityId") || "")
            setStateId(selectedClinic.get("stateId") || "")
        }
    }, [ clinic ])

    return (
        <div id="clinic-setting-info">
            <div className="row setting-content">
                <div className="col-12 col-md-5 col-xl-4 mb-3 mb-md-0">
                    <VSAccordionSetting
                        title="Dados Básicos">

                        <div className="vs-accordion-text-primary">
                            Estas informações aparecerão em todos os documentos gerados no prontuário como por ex. Prescrições, Solicitações de Exames, Atestados e Termos, Comprovante de Venda, Orçamento e Históricos de Vacinação e do Animal.
                        </div>
                        { loading ? (
                            <div className="row loading justify-content-center mt-3">
                                <div className="col-auto">
                                    <CircularProgress />
                                </div>
                            </div>
                        ) : (
                            <div className="row mt-3">
                                <div className="col">
                                    <div className="row mb-2">
                                        <div className="col">
                                            <TextField
                                                className="w-100"
                                                placeholder="Nome da clínica"
                                                label="Nome da clínica"
                                                value={name}
                                                onChange={({ target: { value } }) => setName(value)}
                                                error={errorName}
                                                helperText={helperName}
                                                variant="standard"
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col input-info">
                                            Caso você não tenha um CRMV para sua Clínica, 
                                            utilize o seu CRMV pessoal
                                        </div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col">
                                            <TextField
                                                type="tel"
                                                className="w-100"
                                                placeholder="Informe o CRMV da Clínica"
                                                label="CRMV da clínica"
                                                value={crmv}
                                                onChange={({ target: { value } }) => setCrmv(value ? value.replace(/[^0-9]/g, '') : '')}
                                                error={errorCrmv}
                                                helperText={helperCrmv}
                                                variant="standard"
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col">
                                            <TextField
                                                className="w-100"
                                                placeholder="São Paulo"
                                                label="Estado"
                                                value={crmvStateId}
                                                onChange={({ target: { value } }) => setCrmvStateId(value)}
                                                error={errorCrmvStateId}
                                                helperText={helperCrmvStateId}
                                                variant="standard"
                                                select
                                            >
                                                { getStateList().map(stateItem => (
                                                    <MenuItem key={stateItem.value} value={stateItem.value}>
                                                        { stateItem.text }
                                                    </MenuItem>
                                                )) }
                                            </TextField>
                                        </div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col">
                                            <TextField
                                                placeholder={`Informe o número registro no MAPA`}
                                                label="Nº registro no MAPA da Clínica"
                                                value={registrationMAPA}
                                                onChange={({ target: { value } }) => setRegistrationMAPA(value)}
                                                helperText={<span>Campo Opcional</span>}
                                                variant="standard"
                                                fullWidth
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <TextFieldMask
                                                type="tel"
                                                mask="00.000.000/0000-00"
                                                value={cnpj}
                                                onAccept={(value) => setCnpj(value)}
                                                className="w-100"
                                                placeholder="00.000.000/0000-00"
                                                label="CNPJ"
                                                error={errorCnpj}
                                                helperText={helperCnpj}
                                                variant="standard"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </VSAccordionSetting>
                    <VSAccordionSetting
                        title="Dados Complementares"
                        className="mt-3">

                        <div className="vs-accordion-text-primary">
                            Estas informações aparecerão em todos os Atestados e Termos.
                        </div>
                        { loading ? (
                            <div className="row loading justify-content-center mt-3">
                                <div className="col-auto">
                                    <CircularProgress />
                                </div>
                            </div>
                        ) : (
                            <div className="row mt-3">
                                <div className="col">
                                    <div className="row mb-2">
                                        <div className="col">
                                            <TextField
                                                className="w-100"
                                                placeholder="Razão social"
                                                label="Razão social"
                                                value={companyName}
                                                onChange={({ target: { value } }) => setCompanyName(value)}
                                                error={errorCompanyName}
                                                helperText={helperCompanyName}
                                                variant="standard"
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col">
                                            <TextField
                                                placeholder="Informe a Inscrição Municipal"
                                                label="Inscrição Municipal"
                                                value={municipalRegistrationId}
                                                onChange={({ target: { value } }) => setMunicipalRegistrationId(value)}
                                                helperText={<span>Campo Opcional</span>}
                                                variant="standard"
                                                fullWidth
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <TextField
                                                placeholder="Informe a Inscrição Estadual"
                                                label="Inscrição Estadual"
                                                value={stateRegistrationId}
                                                onChange={({ target: { value } }) => setStateRegistrationId(value)}
                                                helperText={<span>Campo Opcional</span>}
                                                variant="standard"
                                                fullWidth
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </VSAccordionSetting>
                    <VSAccordionSetting
                        title="Endereço"
                        className="mt-3">

                        <div className="vs-accordion-text-primary">
                            O Endereço da Clínica aparecerá em todos os documentos gerados no prontuário como por ex. Prescrições, Solicitações de Exames, Atestados e Termos, Comprovante de Venda, Orçamento e Históricos de Vacinação e do Animal.
                        </div>
                        { loading ? (
                            <div className="row loading justify-content-center mt-3">
                                <div className="col-auto">
                                    <CircularProgress />
                                </div>
                            </div>
                        ) : (
                            <div className="row mt-3">
                                <div className="col">
                                    <div className="row align-items-center mb-2">
                                        <div className="col">
                                            <TextFieldMask
                                                type="tel"
                                                mask="00000-000"
                                                value={zipCode}
                                                onAccept={(value) => setZipCode(value)}
                                                className="w-100"
                                                placeholder="00000-000"
                                                label="CEP"
                                                error={errorZipCode}
                                                helperText={helperZipCode}
                                                variant="standard"
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            { loadingZipCode && (
                                                                <CircularProgress size={16} />
                                                            )}
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="row align-items-center mb-2">
                                        <div className="col">
                                            <TextField
                                                className="w-100"
                                                placeholder="Rua, avenida, alameda, etc."
                                                label="Endereço"
                                                value={addressStreet}
                                                onChange={({ target: { value } }) => setAddressStreet(value)}
                                                error={errorAddressStreet}
                                                helperText={helperAddressStreet}
                                                variant="standard"
                                            />
                                        </div>
                                    </div>
                                    <div className="row align-items-center mb-2">
                                        <div className="col">
                                            <TextField
                                                className="w-100"
                                                placeholder="000"
                                                label="Número"
                                                value={addressNumber}
                                                onChange={({ target: { value } }) => setAddressNumber(value)}
                                                helperText={optionalField}
                                                variant="standard"
                                            />
                                        </div>
                                    </div>
                                    <div className="row align-items-center mb-2">
                                        <div className="col">
                                            <TextField
                                                className="w-100"
                                                placeholder="Informe o complemento"
                                                label="Complemento"
                                                value={addressComplement}
                                                onChange={({ target: { value } }) => setAddressComplement(value)}
                                                helperText={optionalField}
                                                variant="standard"
                                            />
                                        </div>
                                    </div>
                                    <div className="row align-items-center mb-2">
                                        <div className="col">
                                            <TextField
                                                className="w-100"
                                                placeholder="São Paulo"
                                                label="Estado"
                                                value={stateId}
                                                onChange={({ target: { value } }) => setStateId(value)}
                                                helperText={optionalField}
                                                variant="standard"
                                                select
                                            >
                                                { getStateList().map(stateItem => (
                                                    <MenuItem key={stateItem.value} value={stateItem.value}>
                                                        { stateItem.text }
                                                    </MenuItem>
                                                )) }
                                            </TextField>
                                        </div>
                                    </div>
                                    <div className="row align-items-center mb-2">
                                        <div className="col">
                                            <TextField
                                                className="w-100"
                                                placeholder="São Paulo"
                                                label="Cidade"
                                                value={city}
                                                onChange={({ target: { value } }) => setCity(value)}
                                                error={errorCity}
                                                helperText={helperCity}
                                                variant="standard"
                                            />
                                        </div>
                                    </div>
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <TextField
                                                className="w-100"
                                                placeholder="Jardim das Flores"
                                                label="Bairro"
                                                value={neighborhood}
                                                onChange={({ target: { value } }) => setNeighborhood(value)}
                                                error={errorNeighborhood}
                                                helperText={helperNeighborhood}
                                                variant="standard"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </VSAccordionSetting>
                    <VSAccordionSetting
                        title="Informações de Contato"
                        className="mt-3">

                        <div className="vs-accordion-text-primary">
                            As informações de contato da clínica aparecerão em todos os documentos gerados no prontuário como por ex. Prescrições, Solicitações de Exames, Atestados e Termos, Comprovante de Venda, Orçamento e Históricos de Vacinação e do Animal.
                        </div>
                        { loading ? (
                            <div className="row loading justify-content-center mt-3">
                                <div className="col-auto">
                                    <CircularProgress />
                                </div>
                            </div>
                        ) : (
                            <div className="row mt-3">
                                <div className="col">
                                    <div className="row mb-2">
                                        <div className="col input-info">
                                            Número de telefone que o tutor pode usar para entrar em 
                                            contato com você ou sua clínica. 
                                            Pode ser telefone fixo ou WhatsApp
                                        </div>
                                    </div>
                                    <div className="row align-items-center mb-2">
                                        <div className="col">
                                            <TextFieldMask
                                                type="tel"
                                                mask={phone && phone.length <= 14 ? '(00) 0000-0000[0]' : '(00) 00000-0000'}
                                                value={phone}
                                                onAccept={(value) => setPhone(value)}
                                                className="w-100"
                                                placeholder="Informe o telefone de contato"
                                                label="Telefone de Contato"
                                                error={errorPhone}
                                                helperText={helperPhone}
                                                variant="standard"
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col input-info">
                                            E-mail que o tutor pode usar para entrar em 
                                            contato com você ou sua clínica
                                        </div>
                                    </div>
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <TextField
                                                type="email"
                                                className="w-100"
                                                placeholder="exemplo@email.com"
                                                label="E-mail"
                                                value={email}
                                                onChange={({ target: { value } }) => setEmail(value)}
                                                error={errorEmail}
                                                helperText={helperEmail}
                                                variant="standard"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </VSAccordionSetting>
                    <VSAccordionSetting
                        title="Informações de Divulgação"
                        className="mt-3">

                        <div className="vs-accordion-text-primary">
                            As informações de divulgação da clínica aparecerão em todos os documentos gerados no prontuário na versão digital como por ex. Prescrições, Solicitações de Exames, Atestados e Termos, Comprovante de Venda, Orçamento e Históricos de Vacinação e do Animal. O Site aparecerá também nas versões para impressão.
                        </div>
                        { loading ? (
                            <div className="row loading justify-content-center mt-3">
                                <div className="col-auto">
                                    <CircularProgress />
                                </div>
                            </div>
                        ) : (
                            <div className="row mt-3">
                                <div className="col">
                                    <div className="row align-items-center mb-2">
                                        <div className="col">
                                            <TextField
                                                type="url"
                                                className="w-100"
                                                placeholder="Informe o site da clínica"
                                                label="Site"
                                                value={site}
                                                onChange={({ target: { value } }) => setSite(value)}
                                                helperText={optionalField}
                                                variant="standard"
                                            />
                                        </div>
                                    </div>
                                    <div className="row align-items-center mb-2">
                                        <div className="col">
                                            <TextField
                                                type="url"
                                                className="w-100"
                                                placeholder="Informe o Facebook da clínica"
                                                label="Facebook"
                                                value={facebook}
                                                onChange={({ target: { value } }) => setFacebook(value)}
                                                helperText={optionalField}
                                                variant="standard"
                                            />
                                        </div>
                                    </div>
                                    <div className="row align-items-center mb-2">
                                        <div className="col">
                                            <TextField
                                                type="url"
                                                className="w-100"
                                                placeholder="Informe o Instagram da clínica"
                                                label="Instagram"
                                                value={instagram}
                                                onChange={({ target: { value } }) => setInstagram(value)}
                                                helperText={optionalField}
                                                variant="standard"
                                            />
                                        </div>
                                    </div>
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <TextField
                                                type="url"
                                                className="w-100"
                                                placeholder="Informe o Twitter da clínica"
                                                label="Twitter"
                                                value={twitter}
                                                onChange={({ target: { value } }) => setTwitter(value)}
                                                helperText={optionalField}
                                                variant="standard"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </VSAccordionSetting>
                </div>
                <div className="col-12 col-md-7 col-xl-8">
                    <SettingPreview 
                        isConfigSaved={loading || !isValid() || !isFormChanged}
                        onSave={save} />

                    <div className="row mt-4 justify-content-end">
                        <div className="col-auto">
                            <a
                                href="https://pl-vetsmart.zendesk.com/hc/pt-br/articles/4926456276507"
                                target="_blank"
                                className="btn-link d-inline-block text-md-end"
                            >
                                <IconTransaction fill="var(--vsprontuario-primary-color)" width="24px" height="24px" />
                                Solicitar Transferência da Clínica
                            </a>
                        </div>
                        <div className="col-auto">
                            <a
                                href="https://pl-vetsmart.zendesk.com/hc/pt-br/articles/4926371691419"
                                target="_blank"
                                className="btn-link d-inline-block text-md-end"
                                style={{ color: "var(--vsprontuario-negative-color)" }}
                            >
                                <IconPrimaryTrash width="24px" height="24px" fill="var(--vsprontuario-negative-color)" />
                                Solicitar Exclusão da Clínica
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        user: state.user,
        clinic: state.clinic,
        permissions: state.permissions
    }
}

export default connect(mapStateToProps)(ClinicSettingClinicInfo)