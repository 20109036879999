import React from "react"

import "./CardViewSwitch.scss"
import { Switch } from "@mui/material"

const CardViewSwitch = props => {

    const {
        className,
        expand,
        setExpand
    } = props

    return (
        <div className={`card-view-switch box box-table ${className}`}>
            <div className="row box-header">
                <div className="col pe-0 d-flex align-items-center">
                    <b>Visualização de Cards:</b>
                </div>
                <div className="col-auto text-right">
                    <div className="switch-text cursor-pointer p-0" onClick={() => setExpand(!expand)}>
                        { expand ? "Recolher tudo" : "Expandir tudo"}
                        <Switch checked={expand} color="primary" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CardViewSwitch;