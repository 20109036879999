import React, { useEffect, useState } from "react"
import PropTypes from 'prop-types';
import { CircularProgress, MenuItem, TextField } from "@mui/material";
import { replaceAccents } from "../../utils";

import './VSModal.scss'
const VSModal = (props) => {

    const {
        id,
        title,
        text,
        body,
        textConfirm,
        placeholderConfirm,
        compareTo,
        selectText,
        selectOptions,
        options
    } = props

    const [ loading, setLoading ] = useState(false)
    const [ confirm, setConfirm ] = useState('')
    const [ select, setSelect ] = useState('')

    const confirmSanitized = compareTo && replaceAccents(confirm).toLowerCase().replace(/\s/g, "")
    const compareToSanitized = compareTo && replaceAccents(compareTo).toLowerCase().replace(/\s/g, "")

    useEffect(() => {
        if (Array.isArray(selectOptions) && selectOptions?.length > 0) {
            setSelect(selectOptions[0].value)
        }
    }, [ selectOptions ])

    return (
        <div id={id} className="vsmodal">
            <div className="vsmodal--header">
                <div className="row">
                    <div className="col text-center">
                        { title }
                    </div>
                </div>
            </div>
            <div className="vsmodal--body">
                <div className="row">
                    <div className="col">
                        { text && (
                            <div className={`vsmodal--text ${!compareTo && "mb-0"}`}>
                                { text }
                            </div>
                        )}
                        { body }
                        { compareTo && (
                            <>
                                { textConfirm && (
                                    <p className="vsmodal--confirm">
                                        { textConfirm }
                                    </p>
                                )}
                                <TextField
                                    placeholder={placeholderConfirm}
                                    value={confirm}
                                    onChange={({ target: { value } }) => setConfirm(value)}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                />
                            </>
                        )}
                        { selectOptions?.length > 0 && (
                            <>
                                { selectText && (
                                    <p className="vsmodal--confirm mt-3">
                                        { selectText }
                                    </p>
                                )}
                                <TextField
                                    value={select}
                                    onChange={({ target: { value } }) => setSelect(value)}
                                    helperText={null}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    select
                                >
                                    { selectOptions.map((option, index) => (
                                        <MenuItem key={index} value={option.value}>
                                            { option.label }
                                        </MenuItem>
                                    )) }
                                </TextField>
                            </>
                        )}
                    </div>
                </div>
            </div>
            <div className={`vsmodal--footer ${(compareTo || selectOptions?.length > 0) && "vsmodal--separator"}`}>
                <div className="row gx-3">
                    { options.map((option, index) => (
                        <div className="col" key={index}>
                            <button
                                onClick={() => {
                                    switch(option.type) {
                                        case "cancel":
                                            break;
                                        case "intermediate":
                                        case "confirm":
                                            setLoading(true)
                                            break;
                                    }
                                    option.action && option.action(select)
                                }}
                                className={`vsmodal--btn vsmodal--btn-${option.type}`}
                                disabled={option.type != "cancel" ? (loading || confirmSanitized != compareToSanitized || option.disabled) : false}
                            >
                                { loading && option.type != "cancel" ? (
                                    <CircularProgress size="20px" />
                                ) : (
                                    option.text
                                )}
                            </button>
                        </div>
                    )) }
                </div>
            </div>
        </div>
    )
}

VSModal.propTypes = {
    title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.any
    ]),
    text: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.any
    ]),
    textConfirm: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.any
    ]),
    buttonConfirm: PropTypes.string,
    placeholderConfirm: PropTypes.string,
    compareTo: PropTypes.string,
    buttonCancel: PropTypes.string,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func,
    options: PropTypes.arrayOf([
        PropTypes.shape({
            type: PropTypes.oneOf(["cancel", "intermediate", "confirm"]),
            text: PropTypes.string,
            action: PropTypes.func
        })
    ])
};

VSModal.defaultProps = {
    title: "Título",
    text: "",
    textConfirm: "",
    placeholderConfirm: "",
    compareTo: null,
    options: null
}

export default VSModal