import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';

import { Autocomplete, CircularProgress, TextField } from '@mui/material';

import { addEvent, canUserEditVeterinaryDocuments, saveOnTracker, validateDate, saveFilesOnParse, dayjs, showArrayIndex, redirectToCheckout } from '../../../../utils'
import { getPatient } from '../../Patient'
import { createVaccine, getOrCreateReminder, getVaccine, defaultProtocolVaccine, getVaccineProtocols, defaultVaccine } from './Vaccine';
import { ACL, APP_DEVICE, APP_NAME, APP_VERSION } from '../../../../constants';

import VSDrawer from '../../../../components/vsDrawer/VSDrawer';
import VSAccordionSimple from '../../../../components/vsAccordion/VSAccordionSimple';
import VaccineTypeField from "./fields/VaccineTypeField"
import VaccineProductField from './fields/VaccineProductField';
import ShotsField from './fields/ShotsField';
import ReminderField from './fields/ReminderField';
import NotesField from './fields/NotesField';
import ComplementaryDataFields from './fields/ComplementaryDataFields';
import DateStatusField from './fields/DateStatusField';
import BoxDropdown from '../../../../components/boxDropdown/BoxDropdown';
import { PatientQueryToggle } from '../../PatientWideInfo';
import AutomaticReminderForm from './fields/AutomaticReminderForm';
import VSMedicalRecordsAudit from '../../../../components/vsMedicalRecordsAudit/VSMedicalRecordsAudit';
import VSRadio from '../../../../components/vsRadio/VSRadio';
import VSError from '../../../../components/vsError/VSError';
import Blocked from '../../../blocked/Blocked';
import VSModal from '../../../../components/vsModal/VSModal';
import VSEmpty from '../../../../components/vsEmpty/VSEmpty';

import { ReactComponent as IconPaw } from "../../../../assets/icon-paw.svg"
import { ReactComponent as IconAddO } from "../../../../assets/icon-add-o.svg"
import { ReactComponent as IconCloseO } from "../../../../assets/icon-close-o.svg"

const DEFAULT_FORM = "1"
const PROTOCOL_FORM = "2"

import './VaccineForm.scss'
const VaccineForm = (props) => {

    const {
        id,
        id_vacina
    } = useParams()

    const {
        state
    } = useLocation()

    const {
        user,
        clinic,
        history,
        permissions,
        pro
    } = props

    const update = id_vacina != "novo"

    const AutomaticReminderFormRef = useRef(null)
    const protocolVaccinesReminderRefs = useRef([])

    const canEdit = canUserEditVeterinaryDocuments()

    const [ patient, setPatient ] = useState(null)
    const [ vaccine, setVaccine ] = useState(null)
    const [ attachment, setAttachment ] = useState(null)
    const [ reminder, setReminder ] = useState(null)

    const [ loading, setLoading ] = useState(false)
    const [ error, setError ] = useState(null)
    const [ hasSubmited, setHasSubmited ] = useState(false)
    const [ currentForm, setCurrentForm ] = useState(DEFAULT_FORM)
    const [ errors, setErrors ] = useState([])

    const [ protocolVaccines, setProtocolVaccines ] = useState([{...defaultProtocolVaccine}])
    const [ mrProtocolVaccines, setMRProtocolVaccines ] = useState([])
    const [ protocolFormLoading, setProtocolFormLoading ] = useState(true)

    // Vaccine
    const defaultDate = dayjs()
    const [ documentDate, setDocumentDate ] = useState(defaultDate.toDate())
    const [ documentDateText, setDocumentDateText ] = useState(defaultDate.format("DD/MM/YYYY"))
    const [ errorDocumentDate, setErrorDocumentDate ] = useState(false)
    const [ helperDocumentDate, setHelperDocumentDate ] = useState(<span>Campo <b>Obrigatório</b></span>)

    const [isReminder, setIsReminder] = useState('0')

    const [ vaccineType, setVaccineType ] = useState("")
    const [ errorVaccineType, setErrorVaccineType ] = useState(false)
    const [ helperVaccineType, setHelperVaccineType ] = useState(<span>Campo <b>Obrigatório</b></span>)

    const [ vaccineProduct, setVaccineProduct ] = useState("")
    const [ vaccineId, setVaccineId ] = useState("")

    const [ currentShot, setCurrentShot ] = useState(undefined)
    const [ totalShots, setTotalShots ] = useState(undefined)
    const [ errorTotalShots, setErrorTotalShots ] = useState(undefined)
    const [ helperTotalShots, setHelperTotalShots ] = useState(<span>Campo Opcional</span>)

    const [ reminderDate, setReminderDate ] = useState(null)
    const [ reminderDateText, setReminderDateText ] = useState("")
    const [ errorReminderDate, setErrorReminderDate ] = useState(false)
    const [ helperReminderDate, setHelperReminderDate ] = useState(isReminder == "1" ? <span>Campo <b>Obrigatório</b></span> : <span>Campo Opcional</span>)
    
    const [ notes, setNotes ] = useState('')

    const [ hasNextDose, setHasNextDose ] = useState("0")

    // Automatic Reminder
    const [ sendAutomaticReminderIsActive, setSendAutomaticReminderIsActive ] = useState(false)

    // Additional Data
    const [ attachments, setAttachments ] = useState([])

    const [ companyName, setCompanyName ] = useState("")
    const [ manufacturingBatchPart, setManufacturingBatchPart ] = useState("")

    const [ manufacturingDate, setManufacturingDate ] = useState(null)
    const [ manufacturingDateText, setManufacturingDateText ] = useState("")
    const [ errorManufacturingDate, setErrorManufacturingDate ] = useState(false)
    const [ helperManufacturingDate, setHelperManufacturingDate ] = useState(<span>Campo Opcional</span>)

    const [ expirationDate, setExpirationDate ] = useState(null)
    const [ expirationDateText, setExpirationDateText ] = useState("")
    const [ errorExpirationDate, setErrorExpirationDate ] = useState(false)
    const [ helperExpirationDate, setHelperExpirationDate ] = useState(<span>Campo Opcional</span>)

    const limit = AutomaticReminderFormRef.current?.limit()

    const libDocumentDate = dayjs(documentDateText, "DD/MM/YYYY")

    const handleDocumentDate = (date) => {
        const newDocumentDate = dayjs(date, "DD/MM/YYYY")
        if(date && newDocumentDate.isValid()){
            if(newDocumentDate.add(0, 'days').isBefore()){
                setIsReminder("0")
            } else {
                setIsReminder("1")
            }
        }
        setDocumentDate(validateDate(date))
        setDocumentDateText(date)
    }

    const handleReminderDate = (date) => {
        setReminderDate(validateDate(date))
        setReminderDateText(date)
    }

    const setProtocolVaccine = (index, field, value) => {
        let newProtocolVaccines = [...protocolVaccines]
        newProtocolVaccines[index][field] = value
        setProtocolVaccines(newProtocolVaccines)
    }

    const removeProtocolVaccine = (index) => {
        let newProtocolVaccines = [...protocolVaccines]
        newProtocolVaccines.splice(index, 1)
        setProtocolVaccines(newProtocolVaccines)
    }

    const setProtocolVaccineElement = (index, index2, field, value) => {
        let newProtocolVaccines = [...protocolVaccines]
        if(newProtocolVaccines[index]?.vaccines){
            newProtocolVaccines[index].vaccines[index2][field] = value
        }
        setProtocolVaccines(newProtocolVaccines)
    }

    const removeProtocolVaccineElement = (index, index2) => {
        let newProtocolVaccines = [...protocolVaccines]
        if(newProtocolVaccines[index]?.vaccines){
            newProtocolVaccines[index].vaccines.splice(index2, 1)
        }
        setProtocolVaccines(newProtocolVaccines)
    }

    const getProtocolVaccineReminderIndex = (index, index2) => {
        var absoluteIndex = 0
        for (let i = 0; i < protocolVaccines.length; i++) {
            const protocolVaccine = protocolVaccines[i];
            for (let j = 0; j < protocolVaccine?.vaccines.length; j++) {
                if(i == index && j == index2){
                    return absoluteIndex
                }
                absoluteIndex++
            }
        }
    }

    const validate = () => {
        let _errors = []
        // if (loading) {
        //     _errors.push("Salvando a vacina")
        // }

        if (error?.message) {
            _errors.push(error?.message)
        }

        if(currentForm == PROTOCOL_FORM){
            protocolVaccines.forEach((protocolVaccine, index) => {
                if (protocolVaccine?.id && protocolVaccine?.name) {
                    setProtocolVaccine(index, "nameError", false)
                    setProtocolVaccine(index, "nameHelper", <span>Campo <b>Obrigatório</b></span>)
                } else {
                    _errors.push(`Protocolo de vacina ${index + 1}`)
                    setProtocolVaccine(index, "nameError", true)
                    setProtocolVaccine(index, "nameHelper", `O protocolo de vacina não é válido`)
                }
                protocolVaccine?.id && 
                protocolVaccine?.vaccines.forEach((vaccine, index2) => {
                    const vaccineDocumentDateText = dayjs(vaccine?.documentDate)?.format("DD/MM/YYYY")
                    const libReminderDate = dayjs(vaccineDocumentDateText, "DD/MM/YYYY")
                    if (vaccine?.isReminder == "1") {
                        if (!libReminderDate.isValid()) {
                            _errors.push(`Data da vacinação (Protocolo ${index + 1} - Dose ${index2 + 1})`)
                            setProtocolVaccineElement(index, index2, "errorDocumentDate", true)
                            setProtocolVaccineElement(index, index2, "helperDocumentDate", "A data informada não é válida")
                        } else if (libReminderDate.add(0, 'days').isBefore()) {
                            _errors.push(`Data da vacinação (Protocolo ${index + 1} - Dose ${index2 + 1})`)
                            setProtocolVaccineElement(index, index2, "errorDocumentDate", true)
                            setProtocolVaccineElement(index, index2, "helperDocumentDate", "A data da vacinação não pode ser menor que a data atual")
                        } else if (index2 > 0 && vaccine?.documentDate && protocolVaccine?.vaccines[index2 - 1]?.documentDate && vaccine?.documentDate < protocolVaccine?.vaccines[index2 - 1]?.documentDate) {
                            _errors.push(`Data da vacinação (Protocolo ${index + 1} - Dose ${index2 + 1})`)
                            setProtocolVaccineElement(index, index2, "errorDocumentDate", true)
                            setProtocolVaccineElement(index, index2, "helperDocumentDate", "A data desta dose não pode ser menor que a anterior")
                        } else {
                            setProtocolVaccineElement(index, index2, "errorDocumentDate", false)
                            setProtocolVaccineElement(index, index2, "helperDocumentDate", <span>Campo <b>Obrigatório</b></span>)
                        }

                        if (vaccine?.sendAutomaticReminderIsActive && protocolVaccinesReminderRefs.current[getProtocolVaccineReminderIndex(index, index2)]) {
                            _errors = protocolVaccinesReminderRefs.current[getProtocolVaccineReminderIndex(index, index2)]?.validate(_errors)
                        }
                    } else {
                        if (libDocumentDate.isValid()) {
                            if (libDocumentDate.format("YYYY") < 1971) {
                                _errors.push(`Data da vacinação (Protocolo ${index + 1} - Dose ${index2 + 1})`)
                                setProtocolVaccineElement(index, index2, "errorDocumentDate", true)
                                setProtocolVaccineElement(index, index2, "helperDocumentDate", "A data não pode ser menor que 1971")
                            } else if (libDocumentDate.diff(dayjs(), "years") > 5) {
                                _errors.push(`Data da vacinação (Protocolo ${index + 1} - Dose ${index2 + 1})`)
                                setProtocolVaccineElement(index, index2, "errorDocumentDate", true)
                                setProtocolVaccineElement(index, index2, "helperDocumentDate", "A data não pode exceder 5 anos")
                            } else {
                                setProtocolVaccineElement(index, index2, "errorDocumentDate", false)
                                setProtocolVaccineElement(index, index2, "helperDocumentDate", <span>Campo <b>Obrigatório</b></span>)
                            }
                        } else {
                            _errors.push(`Data da vacinação (Protocolo ${index + 1} - Dose ${index2 + 1})`)
                            setProtocolVaccineElement(index, index2, "errorDocumentDate", true)
                            setProtocolVaccineElement(index, index2, "helperDocumentDate", "A data informada não é válida")
                        }
    
                        if (vaccine?.manufacturingDateText) {
                            if (vaccine?.manufacturingDate) {
                                const isFuture = dayjs(vaccine?.manufacturingDate).diff(dayjs().startOf('month'), "month") > 0
                                const isTooPast = dayjs(vaccine?.manufacturingDate).diff(dayjs().startOf('month'), "years") <= -25
                                if (isFuture) {
                                    _errors.push(`Data de fabricação (Protocolo ${index + 1} - Dose ${index2 + 1})`)
                                    setProtocolVaccineElement(index, index2, "errorManufacturingDate", true)
                                    setProtocolVaccineElement(index, index2, "helperManufacturingDate", "A data de fabricação não pode ser maior que o mês atual")
                                } else if (isTooPast) {
                                    _errors.push(`Data de fabricação (Protocolo ${index + 1} - Dose ${index2 + 1})`)
                                    setProtocolVaccineElement(index, index2, "errorManufacturingDate", true)
                                    setProtocolVaccineElement(index, index2, "helperManufacturingDate", "A data de fabricação não pode ser há mais de 25 anos")
                                } else {
                                    setProtocolVaccineElement(index, index2, "errorManufacturingDate", false)
                                    setProtocolVaccineElement(index, index2, "helperManufacturingDate", <span>Campo Opcional</span>)
                                }
                            } else {
                                _errors.push(`Data de fabricação (Protocolo ${index + 1} - Dose ${index2 + 1})`)
                                setProtocolVaccineElement(index, index2, "errorManufacturingDate", true)
                                setProtocolVaccineElement(index, index2, "helperManufacturingDate", "A data de fabricação não é válida")
                            }
                        }
                
                        if (vaccine?.expirationDateText) {
                            if (vaccine?.expirationDate) {
                                const isTooFuture = dayjs(vaccine?.expirationDate).diff(dayjs().startOf('month'), "years") >= 25
                                const isTooPast   = dayjs(vaccine?.expirationDate).diff(dayjs().startOf('month'), "years") <= -25
                                if (isTooFuture) {
                                    _errors.push(`Data de validade (Protocolo ${index + 1} - Dose ${index2 + 1})`)
                                    setProtocolVaccineElement(index, index2, "errorExpirationDate", true)
                                    setProtocolVaccineElement(index, index2, "helperExpirationDate", "A data de validade não pode exceder 25 anos")
                                } else if (isTooPast) {
                                    _errors.push(`Data de validade (Protocolo ${index + 1} - Dose ${index2 + 1})`)
                                    setProtocolVaccineElement(index, index2, "errorExpirationDate", true)
                                    setProtocolVaccineElement(index, index2, "helperExpirationDate", "A data de validade não pode ser há mais de 25 anos")
                                } else {
                                    setErrorExpirationDate(false)
                                    setHelperExpirationDate(<span>Campo Opcional</span>)
                                    setProtocolVaccineElement(index, index2, "errorExpirationDate", false)
                                    setProtocolVaccineElement(index, index2, "helperExpirationDate", <span>Campo Opcional</span>)
                                }
                            } else {
                                _errors.push(`Data de validade (Protocolo ${index + 1} - Dose ${index2 + 1})`)
                                setProtocolVaccineElement(index, index2, "errorExpirationDate", true)
                                setProtocolVaccineElement(index, index2, "helperExpirationDate", "A data de validade não é válida")
                            }
                        }
                    }
                })
            })
        } else {
            if (isReminder == "1") {
                const libReminderDate = dayjs(documentDateText, "DD/MM/YYYY")
                if (!libReminderDate.isValid()) {
                    _errors.push("Data da programação")
                    setErrorReminderDate(true)
                    setHelperReminderDate("A data informada não é válida")
                } else if (libReminderDate.add(0, 'days').isBefore()) {
                    _errors.push("Data da programação")
                    setErrorReminderDate(true)
                    setHelperReminderDate("A data da programação não pode ser menor que a data atual")
                } else {
                    setErrorReminderDate(false)
                    setHelperReminderDate(<span>Campo <b>Obrigatório</b></span>)
                }

            } else {
                const libDocumentDate = dayjs(documentDateText, "DD/MM/YYYY")
                if (libDocumentDate.isValid()) {
                    if (libDocumentDate.format("YYYY") < 1971) {
                        _errors.push("Data da vacinação")
                        setErrorDocumentDate(true)
                        setHelperDocumentDate("A data não pode ser menor que 1971")
                    } else if (libDocumentDate.diff(dayjs(), "years") > 5) {
                        _errors.push("Data da vacinação")
                        setErrorDocumentDate(true)
                        setHelperDocumentDate("A data não pode exceder 5 anos")
                    } else {
                        setErrorDocumentDate(false)
                        setHelperDocumentDate(<span>Campo <b>Obrigatório</b></span>)
                    }
                } else {
                    _errors.push("Data da vacinação")
                    setErrorDocumentDate(true)
                    setHelperDocumentDate("A data informada não é válida")
                }

                const libReminderDate = dayjs(reminderDateText, "DD/MM/YYYY")
                if (!reminderDate || libReminderDate.isValid()) {
                    setErrorReminderDate(false)
                    setHelperReminderDate(<span>Campo Opcional</span>)
                } else if (libReminderDate.add(0, 'days').isBefore()) {
                    _errors.push("Data da programação")
                    setErrorReminderDate(true)
                    setHelperReminderDate("A data da programação não pode ser menor que a data atual")
                } else {
                    _errors.push("Data da programação")
                    setErrorReminderDate(true)
                    setHelperReminderDate("A data informada não é válida")
                }

                if (manufacturingDateText) {
                    if (manufacturingDate) {
                        const isFuture = dayjs(manufacturingDate).diff(dayjs().startOf('month'), "month") > 0
                        const isTooPast = dayjs(manufacturingDate).diff(dayjs().startOf('month'), "years") <= -25
                        if (isFuture) {
                            _errors.push("Data de fabricação")
                            setErrorManufacturingDate(true)
                            setHelperManufacturingDate("A data de fabricação não pode ser maior que o mês atual")
                        } else if (isTooPast) {
                            _errors.push("Data de fabricação")
                            setErrorManufacturingDate(true)
                            setHelperManufacturingDate("A data de fabricação não pode ser há mais de 25 anos")
                        } else {
                            setErrorManufacturingDate(false)
                            setHelperManufacturingDate(<span>Campo Opcional</span>)
                        }
                    } else {
                        _errors.push("Data de fabricação")
                        setErrorManufacturingDate(true)
                        setHelperManufacturingDate("A data de fabricação não é válida")
                    }
                }
        
                if (expirationDateText) {
                    if (expirationDate) {
                        const isTooFuture = dayjs(expirationDate).diff(dayjs().startOf('month'), "years") >= 25
                        const isTooPast   = dayjs(expirationDate).diff(dayjs().startOf('month'), "years") <= -25
                        if (isTooFuture) {
                            _errors.push("Data de validade")
                            setErrorExpirationDate(true)
                            setHelperExpirationDate("A data de validade não pode exceder 25 anos")
                        } else if (isTooPast) {
                            _errors.push("Data de validade")
                            setErrorExpirationDate(true)
                            setHelperExpirationDate("A data de validade não pode ser há mais de 25 anos")
                        } else {
                            setErrorExpirationDate(false)
                            setHelperExpirationDate(<span>Campo Opcional</span>)
                        }
                    } else {
                        _errors.push("Data de validade")
                        setErrorExpirationDate(true)
                        setHelperExpirationDate("A data de validade não é válida")
                    }
                }

            }

            if (vaccineType) {
                setErrorVaccineType(false)
                setHelperVaccineType(<span>Campo <b>Obrigatório</b></span>)
            } else {
                _errors.push("Tipo da vacina")
                setErrorVaccineType(true)
                setHelperVaccineType("O tipo da vacina não é válido")
            }

            let current = parseInt(currentShot) || 0
            let total = parseInt(totalShots) || 0
            if (total < current) {
                _errors.push("Doses totais")
                setErrorTotalShots(true)
                setHelperTotalShots("O total de doses não pode ser menor que a dose atual")
            } else {
                setErrorTotalShots(false)
                setHelperTotalShots(<span>Campo Opcional</span>)
            }

            if ((isReminder == "1" ? documentDate : reminderDate) && sendAutomaticReminderIsActive && AutomaticReminderFormRef.current) {
                _errors = AutomaticReminderFormRef.current.validate(_errors)
            }
        }

        if (!permissions?.check(ACL.VACCINE, ACL.Rule.EDIT, vaccine)) {
            _errors.push("Acesso negado")
        }

        if (!canEdit) {
            _errors = ["Somente Médicos Veterinários com CRMV podem registrar vacinas."]
        }

        setErrors(_errors)
        return _errors
    }

    const getVaccineData = (nextDose = false) => {
        const current = parseInt(currentShot) || undefined
        const total = parseInt(totalShots) || undefined

        let nextShot = undefined
        if (current > 0 && total > 0) {
            nextShot = current + 1 >= total ? total : current + 1
        }

        var vaccineData = {
            documentDate: nextDose ? reminderDate : documentDate,
            isReminder: nextDose || isReminder == "1" ? true : false,
            vaccineType: vaccineType,
            vaccineId: vaccineId ? String(vaccineId) : vaccineId,
            vaccine: vaccineProduct,
            currentShot: nextDose || isReminder == "1" ? nextShot : currentShot,
            totalShots: totalShots
        }

        if(update){
            if(nextDose){
                if(reminder?.id){
                    vaccineData.objectId = reminder?.id
                }
            } else {
                vaccineData.objectId = id_vacina
            }
        }

        if (nextDose) {
            if (nextDose.id || nextDose.objectId) {
                vaccineData.parentVaccine = {
                    __type: "Pointer",
                    className: "Vaccine",
                    objectId: nextDose.id || nextDose.objectId,
                }
            }
            vaccineData.attachment = undefined
        } else {
            vaccineData.notes = notes
        }

        if (!nextDose && isReminder == "0") {
            vaccineData = Object.assign({
                companyName: companyName,
                manufacturingBatchPart: manufacturingBatchPart,
                manufacturingDate: manufacturingDate,
                expirationDate: expirationDate
            }, vaccineData)
        }

        return vaccineData
    }

    const getPatientOrError = () => {
        setLoading(true)
        setError(null)

        getPatient(id, clinic.object)
        .then(_patient => {
            setPatient(_patient)
        })
        .catch(error => {
            Swal.fire(
                'Desculpe',
                error,
                'error'
            )
            .then(_ => {
                setLoading(false)
                setError({
                    message: error,
                    function: "getPatientOrError"
                })
            })
        })
    }

    const getVaccineOrError = () => {
        if (permissions?.check(ACL.VACCINE, ACL.Rule.VIEW)) {
            saveOnTracker("Iniciou", "Vacina", update ? id_vacina : "")
            if (update) {
                setLoading(true)
                setError(null)

                getVaccine(id_vacina, patient)
                .then(_vaccine => {
                    setVaccine(_vaccine)
                    handleDocumentDate(
                        state?.changeVaccineToApplied ? 
                        dayjs().format("DD/MM/YYYY") :
                        dayjs(_vaccine.get("documentDate")).format("DD/MM/YYYY")
                    )
                    setIsReminder(!state?.changeVaccineToApplied && _vaccine.get("isReminder") ? "1" : "0")
                    setVaccineType(_vaccine.get("vaccineType"))
                    setVaccineId(_vaccine.get("vaccineId"))
                    setVaccineProduct(_vaccine.get("vaccine"))
                    setCurrentShot(_vaccine.get("currentShot"))
                    setTotalShots(_vaccine.get("totalShots"))
                    setNotes(_vaccine.get("notes"))
                    setCompanyName(_vaccine.get("companyName"))
                    setManufacturingBatchPart(_vaccine.get("manufacturingBatchPart"))
                    setManufacturingDate(_vaccine.get("manufacturingDate"))
                    setExpirationDate(_vaccine.get("expirationDate"))
                })
                .catch(error => {
                    Swal.fire(
                        'Desculpe',
                        error,
                        'error'
                    )
                    .then(_ => {
                        setLoading(false)
                        setError({
                            message: error,
                            function: "getVaccineOrError"
                        })
                    })
                })
            } else {
                let _vaccine = createVaccine(patient)
                setVaccine(_vaccine)

                if(pro?.access){
                    setProtocolFormLoading(true)
                    getVaccineProtocols(clinic.object).then(protocols => {
                        setMRProtocolVaccines(protocols)
                        setProtocolFormLoading(false)
                    })
                } else {
                    setProtocolFormLoading(false)
                }
            }
        }
    }

    const getReminderOrError = () => {
        if (isReminder == "1") {
            setReminder(vaccine)
            if (vaccine.get("documentDate")) {
                handleReminderDate(dayjs(vaccine.get("documentDate")).format("DD/MM/YYYY"))
            }
        } else {
            setLoading(true)
            setError(null)

            getOrCreateReminder(vaccine, clinic)
            .then(_reminder => {
                setReminder(_reminder)
                if (_reminder.get("documentDate")) {
                    handleReminderDate(dayjs(_reminder.get("documentDate")).format("DD/MM/YYYY"))
                    setHasNextDose("1")
                }
            })
            .catch(error => {
                Swal.fire(
                    'Desculpe',
                    error,
                    'error'
                )
                .then(_ => {
                    setLoading(false)
                    setError({
                        message: error,
                        function: "getReminderOrError"
                    })
                })
            })
        }
    }

    const deleteReminderModal = (callbackSuccess, callbackError) => {
        Swal.fire({
            showConfirmButton: false,
            customClass: {
                popup: 'p-0',
                content: 'p-0',
            },
            html: (
                <VSModal
                    title="Ao prosseguir com essa alteração, a próxima dose ou reforço será excluída. Deseja continuar?"
                    text="Todo o cadastro da próxima dose ou reforço será excluída. Os dados não poderão ser recuperados e esta operação não poderá ser desfeita."
                    options={[
                        {
                            type: "cancel",
                            text: "Não",
                            action: () => {
                                Swal.close()
                                callbackError && 
                                callbackError()
                            }
                        },
                        {
                            type: "confirm",
                            text: "Sim",
                            action: () => {
                                Swal.close()
                                callbackSuccess &&
                                callbackSuccess()
                            }
                        }
                    ]}
                />
            )
        })
    }

    const onSubmitReminder = (currentVaccine) => {
        if(loading){
            return Promise.reject("Carregando...")
        }

        const isDelete = currentVaccine && hasNextDose != '1' && reminder?.id
        return new Promise((resolve, reject) => {
            setHasSubmited(true)
            if (validate().length == 0) {
                setLoading(true)

                const reminderData = isDelete ? { objectId: reminder?.id, isDeleted: true } : getVaccineData(currentVaccine)

                var _messages = []
                if(!isDelete && AutomaticReminderFormRef.current){
                    _messages = AutomaticReminderFormRef.current.save(currentVaccine ? reminder?.id : vaccine?.id)
                }

                if(!isDelete && _messages){
                    reminderData.messages = !sendAutomaticReminderIsActive ? [] : _messages
                }
                return Parse.Cloud.run("saveVaccineForPatient", {
                    data: reminderData,
                    clinicId: clinic.id || clinic.objectId,
                    patientId: patient.id || patient.objectId,
                    appVersionName: APP_VERSION,
                    deviceType: APP_DEVICE,
                    appContentType: APP_NAME
                })
                .then(() => {
                    setLoading(false)
                    resolve()
                })
                .catch(error => {
                    setLoading(false)
                    console.error(error)
                    reject(error)
                })
            } else {
                reject("Por favor, preencha todos os campos obrigatórios")
            }
        })
    }

    const onSubmitVaccine = () => {
        if(loading){
            return Promise.reject("Carregando...")
        }

        const isDeleteReminder = hasNextDose != '1' && reminder?.id

        var promise = Promise.resolve()
        if(isDeleteReminder){
            promise = (new Promise((resolve, reject) => {
                deleteReminderModal(() => {
                    resolve()
                }, () => {
                    reject('ignore-error')
                })
            }))
        }

        promise = promise.then(() => (
            new Promise((resolve, reject) => {
                setHasSubmited(true)
                if (validate().length == 0) {
                    setLoading(true)

                    const vaccineData = getVaccineData()

                    var saveFilesPromise = Promise.resolve()
                    if(attachments && attachments.length > 0){
                        saveFilesPromise = saveFilesOnParse(attachments).then(_attachments => {
                            vaccineData.attachments = _attachments
                        })
                    } else if(attachment){
                        vaccineData.attachment = attachment
                    }
                    
                    saveFilesPromise.then(() => 
                        Parse.Cloud.run("saveVaccineForPatient", {
                            data: vaccineData,
                            clinicId: clinic.id || clinic.objectId,
                            patientId: patient.id || patient.objectId,
                            appVersionName: APP_VERSION,
                            deviceType: APP_DEVICE,
                            appContentType: APP_NAME
                        })
                    )
                    .then(data => {
                        addEvent("TourVaccine__registerYourFirstVaccine", { patient: patient.id || patient.objectId })
                        if(data){
                            setVaccine(data)
                        }
                        if(!reminder || !reminderDate){
                            return
                        }

                        return onSubmitReminder(data)
                    })
                    .then(_reminder => {
                        // setReminder(_reminder)
                        setLoading(false)
                        resolve()
                    })
                    .catch(error => {
                        setLoading(false)
                        console.error(error)
                        reject(error)
                    })
                } else {
                    reject("Por favor, preencha todos os campos obrigatórios")
                }
            })
        ))

        return promise
    }

    const onSubmitProtocol = () => {
        if(loading || protocolFormLoading){
            return Promise.reject("Carregando...")
        }

        setHasSubmited(true)
        if (validate().length == 0) {
            setLoading(true)
            setProtocolFormLoading(true)
            const allProtocolsPromises = protocolVaccines?.map((protocolVaccine, index) => {
                const protocolData = {
                    id: protocolVaccine.id,
                    vaccines: []
                }
                const protocolVaccinesPromises = protocolVaccine?.vaccines.map((vaccine, index2) => {
                    const vaccineData = {
                        documentDate: vaccine?.documentDate,
                        isReminder: vaccine?.isReminder == "1" ? true : false,
                        companyName: vaccine?.companyName,
                        manufacturingBatchPart: vaccine?.manufacturingBatchPart,
                        manufacturingDate: vaccine?.manufacturingDate,
                        expirationDate: vaccine?.expirationDate,
                        vaccine: vaccine?.vaccine,
                        vaccineId: vaccine?.vaccineId ? String(vaccine?.vaccineId) : vaccine?.vaccineId,
                        notes: vaccine?.notes,
                        isNextShot: vaccine?.isNextShot
                    }
                    
                    var promise = Promise.resolve()
                    if(vaccine?.isReminder == "1"){
                        var _messages = []
                        const reminderFormRef = protocolVaccinesReminderRefs.current[getProtocolVaccineReminderIndex(index, index2)]
                        if(reminderFormRef){
                            _messages = reminderFormRef.save(null)
                        }
        
                        if(_messages){
                            vaccineData.messages = !vaccine?.sendAutomaticReminderIsActive ? [] : _messages
                        }
                    } else if (vaccine?.attachments && vaccine?.attachments.length > 0) {
                        promise = saveFilesOnParse(vaccine?.attachments).then(_attachments => {
                            vaccineData.attachments = _attachments
                        })
                    }

                    return promise.then(() => vaccineData)
                })

                return Promise.all(protocolVaccinesPromises).then(vaccineDatas => {
                    protocolData.vaccines = vaccineDatas

                    return Parse.Cloud.run("saveAllProtocolVaccinesForPatient", {
                        data: protocolData,
                        clinicId: clinic.id || clinic.objectId,
                        patientId: patient.id || patient.objectId,
                        appVersionName: APP_VERSION,
                        deviceType: APP_DEVICE,
                        appContentType: APP_NAME
                    })
                })
            })

            return Promise.all(allProtocolsPromises)
            .then(() => {
                setLoading(false)
                setProtocolFormLoading(false)
                return Promise.resolve()
            })
            .catch(error => {
                setLoading(false)
                setProtocolFormLoading(false)
                console.error(error)
                return Promise.reject(error)
            })
        } else {
            return Promise.reject("Por favor, preencha todos os campos obrigatórios")
        }
    }

    const getTitle = () => {
        if(state?.changeVaccineToApplied){
            return "Aplicação de Vacina"
        }
        if (isReminder == "1") {
            return update ? "Editar Programação de Vacina" : "Nova Programação de Vacina"
        }
        return update ? "Editar Aplicação de Vacina" : "Nova Aplicação de Vacina"
    }

    const getCancel = () => {
        if (isReminder == "1") {
            return update ? <span>Cancelar Programação<span className="d-none d-md-inline"> de Vacina</span></span> : <span>Cancelar Nova Programação<span className="d-none d-md-inline"> de Vacina</span></span>
        }
        return update ? <span>Cancelar Aplicação<span className="d-none d-md-inline"> de Vacina</span></span> : <span>Cancelar Nova Aplicação<span className="d-none d-md-inline"> de Vacina</span></span>
    }

    var fieldsToValidate = [ 
        hasSubmited, isReminder, documentDate, 
        vaccineType, vaccineProduct, currentShot, totalShots, reminderDate, notes,
        manufacturingDate, expirationDate, error
    ]
    var allProtocolsToValidate = []
    protocolVaccines?.forEach(protocolVaccine => {
        allProtocolsToValidate = allProtocolsToValidate.concat([
            protocolVaccine?.id,
            protocolVaccine?.name
        ])
        protocolVaccine?.vaccines.forEach(vaccine => {
            allProtocolsToValidate = allProtocolsToValidate.concat([
                vaccine?.documentDate,
                vaccine?.isReminder,
                vaccine?.vaccine,
                vaccine?.manufacturingDate,
                vaccine?.expirationDate,
                vaccine?.notes
            ])
        })
    })
    fieldsToValidate = fieldsToValidate.concat(allProtocolsToValidate)
    const validateOnTouch = () => {
        if (fieldsToValidate.find(val => val)) {
            validate()
        }
    }
    useEffect(() => {
        if(!loading && !protocolFormLoading){
            validateOnTouch()
        }
    }, fieldsToValidate)

    useEffect(() => {
        if (reminder) {
            setLoading(false)
        }
    }, [ reminder ])

    useEffect(() => {
        if (vaccine) {
            if (!permissions?.check(ACL.VACCINE, ACL.Rule.EDIT, vaccine)) {
                permissions?.error()
            }

            let _attachment = vaccine.get("attachment")
            setAttachment(_attachment)
            setAttachments(_attachment?.get("attachments") || [])

            getReminderOrError()
        }
    }, [ vaccine ])

    useEffect(() => {
        if (patient) {
            getVaccineOrError()
        }
    }, [ patient ])

    useEffect(() => {
        if (limit) {
            setSendAutomaticReminderIsActive(false)
        }
    }, [ limit ])

    useEffect(() => {
        if (id) {
            getPatientOrError()
        }
    }, [])

    useEffect(() => {
        protocolVaccinesReminderRefs.current = protocolVaccinesReminderRefs.current.slice(0, protocolVaccines?.length);
        protocolVaccines?.forEach((protocolVaccine, index) => {
            const mrProtocolVaccine = mrProtocolVaccines.find(mrProtocolVaccine => mrProtocolVaccine.id && mrProtocolVaccine.id == protocolVaccine?.id)
            if(!mrProtocolVaccine){
                return
            }
            
            let _vaccines = []
            for (let i = 0; i < (mrProtocolVaccine.get("totalShots") || 1); i++) {
                const vaccine = {...defaultVaccine(patient)};
                vaccine.object.set("vaccineType", mrProtocolVaccine.get("vaccineType"))
                if(i > 0 && mrProtocolVaccine.get("shotInterval") && mrProtocolVaccine.get("shotIntervalUnit")){
                    vaccine.documentDate = dayjs(_vaccines[i - 1].documentDate).clone().add(mrProtocolVaccine.get("shotInterval"), mrProtocolVaccine.get("shotIntervalUnit")).toDate()
                    vaccine.isReminder = "1"
                }
                _vaccines.push(vaccine)
            }
            if (mrProtocolVaccine.get("nextShotInterval") && mrProtocolVaccine.get("nextShotIntervalUnit")){
                const vaccine = {...defaultVaccine(patient)};
                vaccine.object.set("vaccineType", mrProtocolVaccine.get("vaccineType"))
                vaccine.documentDate = dayjs(_vaccines[_vaccines.length - 1].documentDate).clone().add(mrProtocolVaccine.get("nextShotInterval"), mrProtocolVaccine.get("nextShotIntervalUnit")).toDate()
                vaccine.isReminder = "1"
                vaccine.isNextShot = true
                _vaccines.push(vaccine)
            }
            setProtocolVaccine(index, "vaccines", _vaccines)
        })
    }, protocolVaccines?.map(protocolVaccine => protocolVaccine?.id))

    return (
        <VSDrawer
            id="vaccine-form"
            width="50%"
            title={getTitle()}
            cancel={getCancel()}
            submit={errors?.length == 0 ? (isReminder == "1" ? <span>Salvar Programação<span className="d-none d-md-inline"> de Vacina</span></span> : <span>Salvar Aplicação<span className="d-none d-md-inline"> de Vacina</span></span>) : <span>Preencha os campos<span className="d-none d-md-inline"> necessários</span></span>}
            errors={errors} 
            onSubmit={currentForm == PROTOCOL_FORM ? onSubmitProtocol : isReminder == "1" ? onSubmitReminder : onSubmitVaccine}
            onAfterSave={() => {
                history.replace(`/animal/${patient.id}/vacina`)
                addEvent("Vaccine__getList", {})
                addEvent("Vaccine__showVaccineSavedOnList", { vaccines: [ vaccine, reminder ] })
            }}
            onCancel={() => history.goBack()}
            onError={(error) => {
                if(error == 'ignore-error'){
                    return
                }
                console.error(error)
                Swal.fire(
                    'Desculpe',
                    error?.response?.data?.message || (typeof error === "string" ? error : "Ocorreu algum erro ao salvar"),
                    'error'
                )
            }}
            VSDrawerSuccessProps={{
                title: isReminder == "1" ? "Programação de Vacina salvo com Sucesso" : "Aplicação de Vacina salva com Sucesso",
                text: ""
            }}
            VSDrawerCancelProps={{
                title: isReminder == "1" ? "Tem certeza que deseja cancelar a programação de vacina?" : "Tem certeza que deseja cancelar a aplicação de vacina?",
                confirm: update ? (isReminder == "1" ? "Cancelar Edição de Programação" : "Cancelar Edição de Aplicação") : (isReminder == "1" ? "Cancelar Nova Programação" : "Cancelar Nova Aplicação"),
                cancel: "Voltar para Edição"
            }}
        >
            { error?.message ? (
                <VSError
                    message={error.message}
                    onClose={() => {
                        switch(error.function) {
                            case "getPatientOrError":
                                getPatientOrError()
                                break
                            case "getVaccineOrError":
                                getVaccineOrError()
                                break
                            case "getReminderOrError":
                                getReminderOrError()
                                break
                            default:
                        }
                    }}
                />
            ) : canEdit ? (
                <>
                    {/* The AutomaticReminderForm component CANNOT be hide or the ref doesnt work */}
                    { loading && (currentForm == DEFAULT_FORM || !protocolFormLoading) && (
                        <div className="vaccine-loading">
                            <div className="row p-5 text-center">
                                <div className="col">
                                    <CircularProgress />
                                </div>
                            </div>
                        </div>
                    )}
                    <PatientQueryToggle
                        patient={patient}
                        onClick={() => history.push(`/animal/selecionar/vacina`)}
                    />
                    <div className="row">
                        {!update && 
                        <div className="col-12">
                            <div className="btn-tabs mb-3">
                                <div className={`btn-tab ${currentForm == DEFAULT_FORM ? 'active' : ''}`}
                                    onClick={() => setCurrentForm(DEFAULT_FORM)}>
                                    Cadastro padrão
                                </div>
                                <div className={`btn-tab ${currentForm == PROTOCOL_FORM ? 'active' : ''}`}
                                    onClick={() => setCurrentForm(PROTOCOL_FORM)}>
                                    Cadastro por protocolos
                                </div>
                            </div>
                        </div>}
                        {currentForm == DEFAULT_FORM &&
                        <div className="col">
                            <DateStatusField 
                                setDocumentDate={setDocumentDate}
                                documentDateText={documentDateText}
                                setDocumentDateText={setDocumentDateText}
                                errorDocumentDate={errorDocumentDate}
                                helperDocumentDate={helperDocumentDate}
                                isReminder={isReminder}
                                setIsReminder={setIsReminder}
                                changeVaccineToApplied={state?.changeVaccineToApplied}
                            />
                            <div className="row mt-4 mb-1">
                                <div className="col">
                                    <div className="vsbox-separator"></div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <VSAccordionSimple
                                        expanded={true}
                                        header={(expanded) => (
                                            `Dados da Vacina`
                                        )}
                                    >
                                        <div className="row align-items-start mb-3">
                                            <div className="col-12 mb-3">
                                                <VaccineTypeField
                                                    vaccineType={vaccineType}
                                                    setVaccineType={setVaccineType}
                                                    errorVaccineType={errorVaccineType}
                                                    helperVaccineType={helperVaccineType}
                                                />
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <VaccineProductField
                                                    setVaccineId={setVaccineId}
                                                    vaccineProduct={vaccineProduct}
                                                    setVaccineProduct={setVaccineProduct}
                                                />
                                            </div>
                                            <div className="col-12 col-md-6 mt-3 mt-md-0">
                                                <ShotsField
                                                    currentShot={currentShot}
                                                    setCurrentShot={setCurrentShot}
                                                    totalShots={totalShots}
                                                    setTotalShots={setTotalShots}
                                                    errorTotalShots={errorTotalShots}
                                                    helperTotalShots={helperTotalShots}
                                                />
                                            </div>
                                        </div>

                                        <NotesField
                                            notes={notes}
                                            setNotes={setNotes}
                                        />

                                        {isReminder == "1" && 
                                        <div className="row align-items-center mt-5 mb-2">
                                            <div className="col-12">
                                                <h4>Deseja lembrar o Tutor perto da data? (Opcional)</h4>
                                                <p className="black mb-4">
                                                    Configure um lembrete que será enviado automaticamente para o(a) tutor(a) para lembrá-lo(a) desta vacina.
                                                </p>
                                            </div>
                                            <div className="col-12 mb-3">
                                                <BoxDropdown
                                                    title="Ativar envio automático de lembrete"
                                                    checked={vaccineType && documentDate && sendAutomaticReminderIsActive}
                                                    disabled={!vaccineType || !documentDate || limit}
                                                    onChecked={(checked) => setSendAutomaticReminderIsActive(checked)}
                                                >
                                                    <AutomaticReminderForm
                                                        ref={AutomaticReminderFormRef}
                                                        hasSubmited={hasSubmited}
                                                        vaccine={vaccine}
                                                        history={history}
                                                        patient={patient}
                                                        vaccineType={vaccineType}
                                                        reminderDate={documentDate}
                                                        sendAutomaticReminderIsActive={sendAutomaticReminderIsActive}
                                                        setSendAutomaticReminderIsActive={setSendAutomaticReminderIsActive}
                                                        onChange={validate}
                                                    />
                                                </BoxDropdown>
                                                { !vaccineType ? (
                                                    <p className="text-secondary mt-3 mb-0">
                                                        É necessário informar o tipo da vacina para configurar 
                                                        um lembrete automático
                                                    </p>
                                                ) : !documentDate ? (
                                                    <p className="text-secondary mt-3 mb-0">
                                                        É necessário programar a data desta vacinação para configurar 
                                                        um lembrete automático
                                                    </p>
                                                ) : limit && (
                                                    <div className="mt-3">
                                                        { limit }
                                                    </div>
                                                )}
                                            </div>
                                        </div>}
                                    </VSAccordionSimple>
                                </div>
                            </div>
                            { isReminder != "1" && (
                                <>
                                    <hr className="my-2" />
                                    <div className="row mb-2">
                                        <div className="col-12">
                                            <ComplementaryDataFields 
                                                companyName={companyName}
                                                manufacturingBatchPart={manufacturingBatchPart}
                                                manufacturingDate={manufacturingDate}
                                                manufacturingDateText={manufacturingDateText}
                                                errorManufacturingDate={errorManufacturingDate}
                                                helperManufacturingDate={helperManufacturingDate}
                                                expirationDate={expirationDate}
                                                expirationDateText={expirationDateText}
                                                errorExpirationDate={errorExpirationDate}
                                                helperExpirationDate={helperExpirationDate}
                                                attachments={attachments}
                                                setManufacturingDate={setManufacturingDate}
                                                setManufacturingDateText={setManufacturingDateText}
                                                setExpirationDate={setExpirationDate}
                                                setExpirationDateText={setExpirationDateText}
                                                setCompanyName={setCompanyName}
                                                setManufacturingBatchPart={setManufacturingBatchPart}
                                                setAttachments={setAttachments}
                                            />
                                        </div>
                                    </div>
                                </>
                            )}
                            {isReminder != "1" && 
                            <>
                                <hr className="my-2" />
                                <div className="row align-items-center">
                                    <div className="col-12">
                                        <div className="input-title input-height pb-0">
                                            TERÁ PRÓXIMA DOSE OU REFORÇO? ({reminder?.id && hasNextDose != '1' ? 'de Sim, salvo anteriormente, para Não' : 'OPCIONAL'})
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <VSRadio
                                            horizontal={true}
                                            options={[
                                                {
                                                    value: "1",
                                                    label: "Sim",
                                                },
                                                {
                                                    value: "0",
                                                    label: "Não"
                                                }
                                            ]}
                                            value={hasNextDose}
                                            onChange={(_, value) => {
                                                setHasNextDose(value)
                                            }}
                                        />
                                    </div>
                                    {hasNextDose == "1" && 
                                    <>
                                        <div className="col-12">
                                            <ReminderField
                                                isReminder={isReminder == "1"}
                                                documentDate={documentDate}
                                                reminderDate={reminderDate}
                                                reminderDateText={reminderDateText}
                                                handleReminderDate={handleReminderDate}
                                                errorReminderDate={errorReminderDate}
                                                helperReminderDate={helperReminderDate}
                                            />
                                        </div>
                                        <div className="col-12">
                                            <h4>Deseja lembrar o Tutor da próxima dose ou reforço? (Opcional)</h4>
                                            <p className="black mb-4">
                                                Configure um lembrete que será enviado automaticamente para o(a) tutor(a) para lembrá-lo(a) da próxima dose ou reforço.
                                            </p>
                                        </div>
                                        <div className="col-12 mb-3">
                                            <BoxDropdown
                                                title="Ativar envio automático de lembrete"
                                                checked={vaccineType && reminderDate && sendAutomaticReminderIsActive}
                                                disabled={!vaccineType || !reminderDate || limit}
                                                onChecked={(checked) => setSendAutomaticReminderIsActive(checked)}
                                            >
                                                <AutomaticReminderForm
                                                    ref={AutomaticReminderFormRef}
                                                    hasSubmited={hasSubmited}
                                                    vaccine={reminder}
                                                    patient={patient}
                                                    history={history}
                                                    vaccineType={vaccineType}
                                                    reminderDate={reminderDate}
                                                    sendAutomaticReminderIsActive={sendAutomaticReminderIsActive}
                                                    setSendAutomaticReminderIsActive={setSendAutomaticReminderIsActive}
                                                    onChange={validate}
                                                />
                                            </BoxDropdown>
                                            { !vaccineType ? (
                                                <p className="text-secondary mt-3 mb-0">
                                                    É necessário informar o tipo da vacina para configurar 
                                                    um lembrete automático
                                                </p>
                                            ) : !reminderDate ? (
                                                <p className="text-secondary mt-3 mb-0">
                                                    É necessário programar a data da próxima vacinação para configurar 
                                                    um lembrete automático
                                                </p>
                                            ) : limit && (
                                                <div className="mt-3">
                                                    { limit }
                                                </div>
                                            )}
                                        </div>
                                    </>}
                                </div>
                            </>}
                            <VSMedicalRecordsAudit parseObject={vaccine} />
                        </div>}
                        {currentForm == PROTOCOL_FORM &&
                        <div className="vaccine-by-protocol-form col">
                            { protocolFormLoading ?
                                <div className="vaccine-loading">
                                    <div className="row p-5 text-center">
                                        <div className="col">
                                            <CircularProgress />
                                        </div>
                                    </div>
                                </div>
                            : !pro?.access ? 
                                <div className="text-center p-3 mx-auto">
                                    <svg className="mb-4" width="170" height="170" viewBox="0 0 170 170" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <mask id="mask0_218_16047" style={{maskType:"alpha"}} maskUnits="userSpaceOnUse" x="0" y="0" width="170" height="170">
                                            <rect width="170" height="170" rx="85" fill="#D8ECFE"/>
                                        </mask>
                                        <g mask="url(#mask0_218_16047)">
                                            <rect width="211.351" height="211.351" transform="matrix(-1 0 0 1 193.148 -18.2988)" fill="#F5F5F5"/>
                                            <path d="M78.8984 94.4995L72.2868 92.8889L72.4634 92.1437C73.0815 89.5363 71.3185 87.1377 69.115 86.6005L50.7491 82.1267C48.1776 81.5 45.8122 83.2877 45.2824 85.5221L45.1058 86.2672L38.4943 84.6566C34.0863 83.5831 29.898 86.502 28.9277 90.5988L16.2207 144.233C15.1622 148.702 18.0406 152.949 22.0807 153.933L62.4848 163.776C66.8929 164.85 71.0811 161.931 72.0514 157.834L84.7584 104.2C85.818 99.7314 83.3064 95.574 78.8984 94.4995ZM49.4119 86.1349L68.5116 90.7878L66.8349 97.8641L47.7351 93.2112L49.4119 86.1349ZM68.0113 156.851C67.4815 159.085 65.2927 160.128 63.4561 159.68L23.052 149.837C20.8485 149.3 19.8203 147.081 20.2618 145.218L32.9689 91.5846C33.4986 89.3502 35.6874 88.3077 37.524 88.7553L44.1356 90.366L43.8707 91.4832C43.2526 94.0906 45.0156 96.4892 47.2191 97.0264L65.5851 101.5C68.1565 102.127 70.5219 100.339 71.0517 98.1048L71.3165 96.9876L77.9281 98.5983C80.1316 99.1355 81.1598 101.355 80.7183 103.217L68.0113 156.851Z" fill="#E8E8E8"/>
                                            <path d="M66.5082 112.361L37.8575 105.381C36.7558 105.112 36.1975 104.188 36.4624 103.071L36.5507 102.699C36.8156 101.582 37.7271 101.016 38.8288 101.284L67.4794 108.264C68.5812 108.533 69.1395 109.457 68.8746 110.574L68.7863 110.946C68.5214 112.063 67.6099 112.629 66.5082 112.361Z" fill="#E8E8E8"/>
                                            <path d="M68.3282 121.078L36.0054 113.204C34.9036 112.935 34.3454 112.011 34.6103 110.893L34.6986 110.521C34.9635 109.404 35.874 108.838 36.9767 109.107L68.9325 116.891C70.0343 117.16 70.5925 118.084 70.3276 119.201L70.2394 119.573C69.9745 120.691 69.063 121.257 68.3282 121.078Z" fill="#E8E8E8"/>
                                            <path d="M54.0749 125.485L34.2403 120.653C33.1386 120.384 32.5803 119.46 32.8452 118.343L32.9335 117.971C33.1984 116.854 34.1089 116.288 35.2116 116.556L55.0462 121.388C56.148 121.657 56.7062 122.581 56.4413 123.698L56.353 124.07C56.0881 125.187 55.1767 125.753 54.0749 125.485Z" fill="#E8E8E8"/>
                                            <path d="M43.5781 150.11L40.112 141.781L38.4814 143.747L28.9305 141.421L29.8135 137.696L37.1599 139.486L41.3334 134.988L44.4317 143.227L54.5879 131.519L55.4365 144.332L62.0481 145.943L61.166 149.667L51.6162 147.341L51.5583 141.024L43.5781 150.11Z" fill="#E8E8E8"/>
                                            <path d="M75.4083 131.951C75.4083 131.951 79.3105 102.973 75.4083 73.3142C71.729 45.3465 73.1419 40.1659 83.0326 29.2418C92.9239 18.3185 130.181 23.2045 134.919 32.6206C139.039 40.8071 154.079 60.5422 155.554 91.7595C157.028 122.977 155.107 125.245 155.107 125.245L75.4083 131.951Z" fill="#081647"/>
                                            <path d="M128.352 76.7163C128.352 76.7163 139.007 103.125 131.318 113.012L121.41 103.554L119.145 87.8619L128.352 76.7163Z" fill="#000E2D"/>
                                            <path d="M91.0527 83.915C91.0527 83.915 87.2644 114.133 99.5585 119.743L109.005 105.528L105.508 89.9925L91.0527 83.915Z" fill="#000E2D"/>
                                            <path d="M99.1973 108.082L103.475 91.7594L124.173 81.7329C124.173 81.7329 123.101 92.926 128.353 108.082H99.1973Z" fill="#AA67B7"/>
                                            <path d="M92.0737 110.963L100.546 104.731L131.383 102.817L133.747 106.906L123.365 169.801L130.245 238.466L128.229 238.804C127.211 238.975 126.188 239.107 125.161 239.2L78.6504 243.447L92.0737 110.963Z" fill="#00C4B4"/>
                                            <path d="M98.9193 111.644L97.9815 104.036C97.8162 102.696 98.9534 101.499 100.506 101.378L128.429 99.1971C129.186 99.1382 129.843 99.6415 129.866 100.297L130.18 109.349L122.095 121.207L113.783 123.492H103.621L97.7754 118.098L98.9193 111.644Z" fill="#00C4B4"/>
                                            <path d="M78.8633 116.535C78.8633 116.535 68.6634 123.168 65.9834 135.146C63.8764 144.562 61.2868 194.518 61.2868 194.518L48 213.401L52.9082 227.051H54.5145C60.167 227.439 65.8636 227.468 70.4505 224.238L74.8915 221.11L83.0332 209.383L78.8633 116.535Z" fill="#D6E5F8"/>
                                            <path d="M96.1563 107.752L78.8622 116.535L74.7814 129.556L71.1443 167.294L77.9908 211.537L67.3887 244.265L86.8272 242.783L87.184 227.026L89.2515 156.687L91.2836 111.881L96.1563 107.752Z" fill="#D6E5F8"/>
                                            <path d="M155.487 114.379L143 109.426L129.835 101.938L129.752 104.128C131.364 106.808 131.92 109.965 131.318 113.013L120.363 168.496L122.848 209.031L126.702 239.061L160.808 233.348L149.367 207.212L155.786 151.23L155.487 114.379Z" fill="#D6E5F8"/>
                                            <path opacity="0.5" d="M120.363 168.495C120.363 168.495 125.3 167.036 131.129 155.156C131.129 155.156 135.689 144.342 133.961 133.876H136.583C136.583 133.876 138.754 118.135 135.019 108.244L128.787 136.958L120.363 168.495Z" fill="#9EC7F3"/>
                                            <path d="M158.361 115.459C165.749 118.237 171.229 124.394 172.981 131.894C178.636 156.106 188.318 188.31 188.47 198.7C188.664 211.933 182.703 220.991 174.923 222.662C168.77 223.982 112.997 221.019 112.997 221.019L110.389 200.846L155.552 194.391L149.368 160.843L155.488 114.379L158.361 115.459Z" fill="#D6E5F8"/>
                                            <path d="M120.363 168.495C120.363 168.495 137.345 152.586 132.228 132.66L135.417 132.593C135.417 132.593 139.691 104.419 129.852 101.938C129.852 101.938 125.509 146.377 120.363 168.495Z" fill="#FFF5F6"/>
                                            <path d="M89.0001 168.69C89.0001 168.69 79.1402 156.71 82.0987 135.174H85.2726L81.5451 130.867C81.5451 130.867 81.6054 121.62 87.5215 111.168C87.5215 111.168 92.1807 108.525 96.1572 107.753C96.1565 107.752 88.8508 135.542 89.0001 168.69Z" fill="#FFF5F6"/>
                                            <path d="M127.093 106.202C127.087 106.226 126.679 106.167 125.937 106.105C125.196 106.046 124.118 105.998 122.79 106.062C121.462 106.124 119.889 106.305 118.166 106.609C116.441 106.906 114.566 107.323 112.58 107.72C110.594 108.116 108.673 108.439 106.893 108.551C105.116 108.669 103.49 108.564 102.179 108.289C100.863 108.026 99.8725 107.615 99.2373 107.285C98.9207 107.117 98.6865 106.973 98.5345 106.868C98.3839 106.763 98.309 106.704 98.317 106.696C98.3364 106.675 98.6624 106.886 99.3096 107.186C99.9575 107.481 100.943 107.853 102.238 108.086C103.528 108.328 105.119 108.41 106.866 108.279C108.616 108.153 110.516 107.827 112.495 107.432C114.475 107.037 116.358 106.626 118.099 106.341C119.838 106.05 121.433 105.888 122.778 105.852C124.124 105.814 125.213 105.896 125.955 105.991C126.326 106.041 126.613 106.087 126.804 106.128C126.996 106.167 127.095 106.192 127.093 106.202Z" fill="#00786E"/>
                                            <g opacity="0.35">
                                                <g opacity="0.35">
                                                    <path opacity="0.35" d="M99.7117 107.377C98.9386 109.122 98.9165 111.285 100.485 112.624C101.765 113.715 103.817 113.987 105.626 113.691C107.434 113.394 109.062 112.613 110.646 111.839C114.622 109.896 118.661 107.884 123.178 107.06C123.467 107.007 123.776 106.952 123.989 106.782C124.202 106.612 124.255 106.283 124.015 106.14C123.908 106.076 123.772 106.062 123.641 106.052C119.646 105.723 115.681 106.65 111.764 107.372C107.847 108.094 103.665 108.605 99.8891 107.487" fill="#00786E"/>
                                                </g>
                                            </g>
                                            <path d="M105.228 100.98C105.257 101.023 104.666 101.137 104.24 101.756C103.784 102.357 103.809 103.378 103.845 104.481C103.919 106.695 104.364 108.464 104.259 108.482C104.212 108.49 104.042 108.058 103.868 107.338C103.692 106.619 103.526 105.611 103.485 104.49C103.47 103.929 103.463 103.399 103.524 102.904C103.587 102.412 103.757 101.964 104.024 101.645C104.285 101.325 104.601 101.134 104.839 101.049C105.076 100.963 105.224 100.964 105.228 100.98Z" fill="#00786E"/>
                                            <path d="M112.86 107.481C112.747 107.472 113.006 105.841 112.653 103.861C112.321 101.878 111.521 100.37 111.627 100.335C111.668 100.32 111.921 100.677 112.218 101.299C112.515 101.918 112.835 102.809 113.011 103.819C113.184 104.829 113.178 105.757 113.103 106.42C113.027 107.086 112.904 107.487 112.86 107.481Z" fill="#00786E"/>
                                            <path d="M122.156 99.7091C122.192 99.693 122.455 100.009 122.707 100.589C122.959 101.166 123.159 102.018 123.085 102.961C123.007 103.904 122.671 104.727 122.328 105.272C121.985 105.819 121.673 106.104 121.64 106.084C121.535 106.044 122.597 104.768 122.725 102.942C122.892 101.117 122.045 99.7385 122.156 99.7091Z" fill="#00786E"/>
                                            <path d="M90.0997 39.1553C90.0997 39.1553 92.2242 48.7869 106.708 56.1006C121.193 63.4143 120.607 69.1103 120.607 69.1103C120.607 69.1103 123.233 62.676 129.216 65.7295C129.216 65.7295 133.777 69.4489 129.373 76.7158C124.969 83.9827 120.365 83.6159 120.365 83.6159C120.365 83.6159 112.847 95.9997 103.904 96.3217C94.2489 96.6691 87.8434 92.6913 83.2478 80.0972C78.6523 67.5032 79.4133 51.5324 90.0997 39.1553Z" fill="#AA67B7"/>
                                            <path d="M90.1914 39.1431L91.2041 41.8338C93.3961 47.6576 97.5326 52.5912 102.994 55.5723C103.188 55.6788 103.386 55.7832 103.586 55.8856C106.039 57.1432 109.224 58.7054 111.615 60.0789C115.235 62.1592 119.563 66.0901 120.604 69.1094C120.604 69.1094 120.615 62.9429 107.979 56.7289C94.3359 50.0203 90.1914 39.1431 90.1914 39.1431Z" fill="#723B7D"/>
                                            <path d="M90.1929 39.1431C90.1929 39.1431 82.8818 49.855 80.6623 67.7487C80.6623 67.7487 78.0493 53.2143 90.1929 39.1431Z" fill="#723B7D"/>
                                            <path d="M124.172 82.3628C124.172 82.3628 123.119 83.6693 120.363 83.6164C120.363 83.6164 115.741 95.0726 102.272 96.343L101.889 97.8061C101.889 97.8061 114.829 97.4641 120.604 84.6178C120.605 84.6171 123.126 83.9719 124.172 82.3628Z" fill="#723B7D"/>
                                            <path opacity="0.25" d="M96.1573 107.752C96.1573 107.752 90.5804 137.586 89.8234 193.95L88.1602 193.849C88.1602 193.849 89.4111 158.006 89.4406 155.412C89.47 152.817 91.7504 118.351 96.1573 107.752Z" fill="#00786E"/>
                                        </g>
                                        <path d="M36 64C51.464 64 64 51.464 64 36C64 20.536 51.464 8 36 8C20.536 8 8 20.536 8 36C8 51.464 20.536 64 36 64Z" fill="#1993F7"/>
                                        <path d="M45.5886 30.3588H44.0765V27.3344C44.0765 23.1607 40.6891 19.7734 36.5155 19.7734C32.3418 19.7734 28.9545 23.1607 28.9545 27.3344V30.3588H27.4424C25.7789 30.3588 24.418 31.7197 24.418 33.3832V48.5051C24.418 50.1685 25.7789 51.5294 27.4424 51.5294H45.5886C47.2521 51.5294 48.613 50.1685 48.613 48.5051V33.3832C48.613 31.7197 47.2521 30.3588 45.5886 30.3588ZM31.9789 27.3344C31.9789 24.8242 34.0053 22.7978 36.5155 22.7978C39.0257 22.7978 41.0521 24.8242 41.0521 27.3344V30.3588H31.9789V27.3344ZM45.5886 48.5051H27.4424V33.3832H45.5886V48.5051ZM36.5155 43.9685C38.1789 43.9685 39.5399 42.6075 39.5399 40.9441C39.5399 39.2807 38.1789 37.9197 36.5155 37.9197C34.8521 37.9197 33.4911 39.2807 33.4911 40.9441C33.4911 42.6075 34.8521 43.9685 36.5155 43.9685Z" fill="white"/>
                                    </svg>
                                    <h3 className="mb-2">Cadastre protocolos de Vacina</h3>
                                    { !pro?.trialEndsAt ? (
                                        <>
                                            <p>Experimente o Prontuário PRO e facilite o atendimento dos seus pacientes com a funcionalidade protocolos de Vacina.</p>
                                        </>
                                    ) : (
                                        <>
                                            <p>Assine o Prontuário PRO e facilite o atendimento dos seus pacientes com a funcionalidade protocolos de Vacina.</p>
                                        </>
                                    )}
                                    <button 
                                        className="btn btn-submit btn-square m-auto"
                                        onClick={() => {
                                            redirectToCheckout(clinic)
                                        }}
                                    >{!pro?.trialEndsAt ? "Experimentar 15 dias grátis" : "Assinar o Prontuário PRO"}</button>
                                </div>
                            : !mrProtocolVaccines || mrProtocolVaccines.length <= 0 ? 
                                <VSEmpty
                                    icon={<IconPaw width="32px" height="32px" fill="var(--vsprontuario-primary-color)" />}
                                    title="Não encontramos nenhum protocolo"
                                    text="Primeiro é necessário que cadastre um novo protocolo de vacina"
                                    className="p-3"
                                >
                                    { permissions?.check(ACL.VACCINE, ACL.Rule.EDIT) && (
                                        <button
                                            className="vsbox-btn vsbox-btn-primary d-flex align-items-center m-auto w-auto px-4"
                                            onClick={() => history.push(`/protocolo/vacina/novo`)}
                                        >
                                            <IconAddO width="14" height="14" className="me-2" />
                                            Novo protocolo
                                        </button>
                                    )}
                                </VSEmpty>
                            : protocolVaccines && protocolVaccines.length > 0 && (
                                <>
                                    {protocolVaccines.map((protocolVaccine, index) => (
                                        <React.Fragment key={index}>
                                            <div className="row align-items-center mb-4">
                                                <div className="col">
                                                    <div className="input-array-main py-1">
                                                        PROTOCOLO { showArrayIndex(index + 1) }
                                                    </div>
                                                </div>
                                                { protocolVaccines.length > 1 && (
                                                    <div className="col-auto ps-0">
                                                        <button
                                                            className="btn-array px-0"
                                                            onClick={() => removeProtocolVaccine(index)}
                                                            >
                                                            <IconCloseO width="24" height="24" />
                                                            Remover Protocolo
                                                        </button>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="row align-items-center mb-4">
                                                <div className="col-12">
                                                    <Autocomplete
                                                        value={protocolVaccine?.name}
                                                        options={(mrProtocolVaccines?.map(mrProtocolVaccine => ({
                                                            specie: mrProtocolVaccine.get("specie"),
                                                            label: `[${mrProtocolVaccine.get("specie")}] ${mrProtocolVaccine.get("name")}`,
                                                            value: mrProtocolVaccine.id
                                                        }) || []).sort((a, b) => {
                                                            // return 0
                                                            if(a.specie.localeCompare(b.specie, 'en', { sensitivity: 'base' }) === 0){
                                                                return a.label.localeCompare(b.label, 'en', { sensitivity: 'base' })
                                                            }
                                                            if(a.specie.localeCompare(patient?.get('specie'), 'en', { sensitivity: 'base' }) === 0){
                                                                return -1
                                                            }
                                                            if(b.specie.localeCompare(patient?.get('specie'), 'en', { sensitivity: 'base' }) === 0){
                                                                return 1
                                                            }
                                                            return a.label.localeCompare(b.label, 'en', { sensitivity: 'base' })
                                                        }) || [])}
                                                        onChange={(_, selected) => {
                                                            setProtocolVaccine(index, "id", selected?.value || null)
                                                            setProtocolVaccine(index, "name", selected?.label || "")
                                                        }}
                                                        onInputChange={(event, selected) => {
                                                            if(event){
                                                                setProtocolVaccine(index, "id", selected?.value || null)
                                                                setProtocolVaccine(index, "name", selected?.label || "")
                                                            }
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label="Protocolo de vacina"
                                                                placeholder="Selecione um Protocolo de vacina"
                                                                error={protocolVaccine.nameError}
                                                                helperText={protocolVaccine.nameHelper}
                                                                size="small"
                                                                variant="outlined"
                                                                fullWidth
                                                            />
                                                        )}
                                                        openOnFocus
                                                        freeSolo
                                                    />
                                                </div>
                                                {protocolVaccine?.id && 
                                                protocolVaccine?.vaccines.map((vaccine, index2) => {
                                                    const vaccineLimit = protocolVaccinesReminderRefs.current[getProtocolVaccineReminderIndex(index, index2)]?.limit()
                                                    return (
                                                        <React.Fragment key={String(index) + String(index2)}>
                                                            {vaccine?.isNextShot ? 
                                                            <div className="input-array-main mt-3 py-1">
                                                                DADOS DO REFORÇO
                                                            </div> : 
                                                            <div className="input-array text-normal mt-3 py-1">
                                                                Dados da Dose { showArrayIndex(index2 + 1) }
                                                            </div>}
                                                            <DateStatusField
                                                                setDocumentDate={(value) => setProtocolVaccineElement(index, index2, "documentDate", value)}
                                                                documentDateText={dayjs(vaccine?.documentDate)?.format("DD/MM/YYYY")}
                                                                errorDocumentDate={vaccine?.errorDocumentDate}
                                                                helperDocumentDate={vaccine?.helperDocumentDate}
                                                                isReminder={vaccine?.isReminder}
                                                                setIsReminder={(value) => setProtocolVaccineElement(index, index2, "isReminder", value)}
                                                                changeVaccineToApplied={state?.changeVaccineToApplied}
                                                            />

                                                            <div className="col-12">
                                                                <hr className="mb-2 mt-4" />
                                                            </div>

                                                            <VSAccordionSimple
                                                                expanded={false}
                                                                header={(expanded) => (
                                                                    `Dados da Vacina`
                                                                )}
                                                            >
                                                                <div className="row mb-3">
                                                                    <div className="col">
                                                                        <VaccineProductField
                                                                            setVaccineId={(value) => setProtocolVaccineElement(index, index2, "vaccineId", value)}
                                                                            vaccineProduct={vaccine?.vaccine}
                                                                            setVaccineProduct={(value) => setProtocolVaccineElement(index, index2, "vaccine", value)}
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <NotesField
                                                                    notes={vaccine?.notes}
                                                                    setNotes={(value) => setProtocolVaccineElement(index, index2, "notes", value)}
                                                                />
                                                            </VSAccordionSimple>

                                                            <div className="col-12">
                                                                <hr className="my-2" />
                                                            </div>

                                                            {vaccine?.isReminder === "0" ?
                                                            <div className="col-12">
                                                                <ComplementaryDataFields 
                                                                    companyName={vaccine?.companyName}
                                                                    manufacturingBatchPart={vaccine?.manufacturingBatchPart}
                                                                    manufacturingDate={vaccine?.manufacturingDate}
                                                                    manufacturingDateText={vaccine?.manufacturingDateText}
                                                                    errorManufacturingDate={vaccine?.errorManufacturingDate}
                                                                    helperManufacturingDate={vaccine?.helperManufacturingDate}
                                                                    expirationDate={vaccine?.expirationDate}
                                                                    expirationDateText={vaccine?.expirationDateText}
                                                                    errorExpirationDate={vaccine?.errorExpirationDate}
                                                                    helperExpirationDate={vaccine?.helperExpirationDate}
                                                                    attachments={vaccine?.attachments}
                                                                    setManufacturingDate={(value) => setProtocolVaccineElement(index, index2, "manufacturingDate", value)}
                                                                    setExpirationDate={(value) => setProtocolVaccineElement(index, index2, "expirationDate", value)}
                                                                    setManufacturingDateText={(value) => setProtocolVaccineElement(index, index2, "manufacturingDateText", value)}
                                                                    setExpirationDateText={(value) => setProtocolVaccineElement(index, index2, "expirationDateText", value)}
                                                                    setCompanyName={(value) => setProtocolVaccineElement(index, index2, "companyName", value)}
                                                                    setManufacturingBatchPart={(value) => setProtocolVaccineElement(index, index2, "manufacturingBatchPart", value)}
                                                                    setAttachments={(value) => setProtocolVaccineElement(index, index2, "attachments", value)}
                                                                />
                                                            </div> : 
                                                            vaccine?.isReminder === "1" ?
                                                            <>
                                                                <div className="col-12 mt-2">
                                                                    <h4>Deseja lembrar o Tutor perto da data? (Opcional)</h4>
                                                                    <p className="black mb-4">
                                                                        Configure um lembrete que será enviado automaticamente para o(a) tutor(a) para lembrá-lo(a) desta vacina.
                                                                    </p>
                                                                </div>
                                                                <div className="col-12 mb-3">
                                                                    <BoxDropdown
                                                                        title="Ativar envio automático de lembrete"
                                                                        checked={vaccine?.documentDate && vaccine?.sendAutomaticReminderIsActive}
                                                                        disabled={!vaccine?.documentDate || vaccineLimit}
                                                                        onChecked={(checked) => setProtocolVaccineElement(index, index2, "sendAutomaticReminderIsActive", checked)}
                                                                    >
                                                                        <AutomaticReminderForm
                                                                            ref={el => protocolVaccinesReminderRefs.current[getProtocolVaccineReminderIndex(index, index2)] = el}
                                                                            hasSubmited={hasSubmited}
                                                                            vaccine={vaccine?.object}
                                                                            patient={vaccine?.object?.get("patient")}
                                                                            history={history}
                                                                            vaccineType={vaccine?.object?.get("vaccineType")}
                                                                            reminderDate={vaccine?.documentDate}
                                                                            sendAutomaticReminderIsActive={vaccine?.sendAutomaticReminderIsActive}
                                                                            setSendAutomaticReminderIsActive={(value) => setProtocolVaccineElement(index, index2, "sendAutomaticReminderIsActive", value)}
                                                                            onChange={validate}
                                                                        />
                                                                    </BoxDropdown>
                                                                    { !reminderDate ? (
                                                                        <p className="text-secondary mt-3 mb-0">
                                                                            É necessário programar a data da próxima vacinação para configurar 
                                                                            um lembrete automático
                                                                        </p>
                                                                    ) : vaccineLimit && (
                                                                        <div className="mt-3">
                                                                            { vaccineLimit }
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </> : undefined}
                                                        </React.Fragment>
                                                    )
                                                })}
                                            </div>
                                        </React.Fragment>
                                    ))}
                                    <div className="row align-items-center mb-4">
                                        <div className="col-12">
                                            <button
                                                className="btn-add" 
                                                onClick={() => setProtocolVaccines(protocolVaccines ? protocolVaccines.concat({ ...defaultProtocolVaccine }) : [{...defaultProtocolVaccine}])}
                                            >
                                                <IconAddO width="20" height="20" />
                                                ADICIONAR PROTOCOLO { showArrayIndex(protocolVaccines ? protocolVaccines.length + 1 : 1) }
                                            </button>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>}
                    </div>
                </>
            ) : (
                <Blocked />
            )}
        </VSDrawer>
    )
}

const mapStateToProps = state => {
    return {
        user: state.user,
        clinic: state.clinic,
        permissions: state.permissions,
        pro: state.featuresPro
    };
};

export default connect(mapStateToProps)(VaccineForm);