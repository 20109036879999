import React, { useEffect, useState } from "react"

import './VSSend.scss'
import { ReactComponent as IconCircleCheck } from '../../assets/icon-check-o.svg'
import { ReactComponent as IconCircleWarning } from '../../assets/icon-warning-o.svg'

import {
    CircularProgress
 } from '@mui/material';
import { getPDF, sendDownload, sendEmail, sendSMS, sendWhatsapp } from "../../utils"
import { connect } from "react-redux"
import VSDrawer from "../vsDrawer/VSDrawer"
import printJS from "print-js";
import { APP_DEVICE, APP_NAME, APP_VERSION } from "../../constants";

const statuses = {
    PRINT: {
        "title": `Imprimir Documento`,
        [null]: {
            className: 'card-inactive',
            icon: <i className="card-icon"></i>,
            title: 'Preparando impressão',
            text: ''
        },
        [true]: {
            className: 'card-progress',
            icon: <CircularProgress size={24} thickness={2} color="secondary" className="card-icon" />,
            title: 'Imprimindo',
            text: ''
        },
        [false]: {
            className: 'card-active',
            icon: <IconCircleCheck width="24px" height="24px" className="card-icon" />,
            title: 'Impressão concluída',
            text: <span>Para imprimir novamente, <a className="card-link">clique aqui</a></span>
        },
        "error": {
            className: 'card-error',
            icon: <IconCircleWarning width="24px" height="24px" className="card-icon" />,
            title: 'Problema na Impressão',
            text: <span><a href="mailto:contato@vetsmart.com.br">Entre em contato conosco</a> para verificarmos o ocorrido</span>
        }
    },
    SMS: {
        "title": "Enviar Cópia Digital por SMS",
        [null]: {
            className: 'card-inactive',
            icon: <i className="card-icon"></i>,
            title: 'Preparando SMS',
            text: 'Dependendo da operadora, o tutor pode levar até 10 minutos para receber o SMS'
        },
        [true]: {
            className: 'card-progress',
            icon: <CircularProgress size={24} thickness={2} color="secondary" className="card-icon" />,
            title: 'Enviando SMS',
            text: 'Dependendo da operadora, o tutor pode levar até 10 minutos para receber o SMS'
        },
        [false]: {
            className: 'card-active',
            icon: <IconCircleCheck width="24px" height="24px" className="card-icon" />,
            title: 'SMS Enviado',
            text: 'Dependendo da operadora, o tutor pode levar até 10 minutos para receber o SMS'
        },
        "error": {
            className: 'card-error',
            icon: <IconCircleWarning width="24px" height="24px" className="card-icon" />,
            title: 'Problema no Envio do SMS',
            text: <span>Confira o número de telefone digitado ou <a href="mailto:contato@vetsmart.com.br">entre em contato conosco</a> para suporte</span>
        }
    },
    EMAIL: {
        "title": "Enviar Cópia Digital por E-mail",
        [null]: {
            className: 'card-inactive',
            icon: <i className="card-icon"></i>,
            title: 'Preparando E-mail',
            text: 'Não se esqueça de pedir que o tutor olhe a sua caixa de Spam'
        },
        [true]: {
            className: 'card-progress',
            icon: <CircularProgress size={24} thickness={2} color="secondary" className="card-icon" />,
            title: 'Enviando E-mail',
            text: 'Não se esqueça de pedir que o tutor olhe a sua caixa de Spam'
        },
        [false]: {
            className: 'card-active',
            icon: <IconCircleCheck width="24px" height="24px" className="card-icon" />,
            title: 'E-mail Enviado',
            text: 'Não se esqueça de pedir que o tutor olhe a sua caixa de Spam'
        },
        "error": {
            className: 'card-error',
            icon: <IconCircleWarning width="24px" height="24px" className="card-icon" />,
            title: 'Problema no Envio do E-mail',
            text: <span>Confira o email digitado ou <a href="mailto:contato@vetsmart.com.br">entre em contato conosco</a> para suporte</span>
        }
    },
    DOWNLOAD: {
        "title": `Baixar Documento em PDF`,
        [null]: {
            className: 'card-inactive',
            icon: <i className="card-icon"></i>,
            title: 'Iniciando o PDF',
            text: ''
        },
        [true]: {
            className: 'card-progress',
            icon: <CircularProgress size={24} thickness={2} color="secondary" className="card-icon" />,
            title: "Gerando o PDF",
            text: ''
        },
        [false]: {
            className: 'card-active',
            icon: <IconCircleCheck width="24px" height="24px" className="card-icon" />,
            title: "PDF baixado com sucesso",
            text: ''
        },
        "error": {
            className: 'card-error',
            icon: <IconCircleWarning width="24px" height="24px" className="card-icon" />,
            title: `Erro ao baixar o PDF`,
            text: <span>Tente atualizar a página ou <a href="mailto:contato@vetsmart.com.br">entre em contato conosco</a> para suporte</span>
        }
    },
    WHATSAPP: {
        "title": "Enviar Cópia Digital por WhatsApp",
        [null]: {
            className: 'card-inactive',
            icon: <i className="card-icon"></i>,
            title: 'Preparando envio por WhatsApp',
            text: ''
        },
        [true]: {
            className: 'card-progress',
            icon: <CircularProgress size={24} thickness={2} color="secondary" className="card-icon" />,
            title: 'Carregando WhatsApp',
            text: ''
        },
        [false]: {
            className: 'card-active',
            icon: <IconCircleCheck width="24px" height="24px" className="card-icon" />,
            title: 'WhatsApp Enviado',
            text: ''
        },
        "error": {
            className: 'card-error',
            icon: <IconCircleWarning width="24px" height="24px" className="card-icon" />,
            title: 'Problema no Envio do WhatsApp',
            text: <span>Confira o número de telefone digitado ou <a href="mailto:contato@vetsmart.com.br">entre em contato conosco</a> para suporte</span>
        }
    }
}

const VSSend = (props) => {

    const {
        emailAddress,
        phoneNumber,
        link: {
            urlToPDF
        },
        messages,
        onClose,
        onResend,
        onSent
    } = props

    var pdf = null

    const [ progress, setProgress ] = useState([])

    const initializeProgress = () => {
        let _progress = []
        messages.map((message, index) => {
            if (message.checked) {
                if (message.messages) {
                    message.messages.map(_message => {
                        if (_message.checked) {
                            _message.status = null
                            _progress.push(_message)
                        }
                    })
                } else {
                    message.status = null
                    _progress.push(message)
                }
            }
        })
        setProgress(_progress)
    }

    const sendMessages = () => {
        let allPromises = []
        progress.map((message, index) => {
            handleStatus(index, true)
            let promiseToStatus = null
            switch(message.messageTypeId) {
                case "PRINT":
                    promiseToStatus = toPrint(message)
                    break;
                case "WHATSAPP":
                    promiseToStatus = toWhatsapp(message)
                    break;
                case "SMS":
                    promiseToStatus = toSMS(message)
                    break;
                case "EMAIL":
                    promiseToStatus = toEmail(message)
                    break;
                case "DOWNLOAD":
                    promiseToStatus = toDownload(message)
                    break;
            }
            allPromises.push(
                new Promise((resolve, reject) => {
                    promiseToStatus
                    .then(sent => {
                        handleStatus(index, false)
                        resolve(sent)
                    })
                    .catch(error => {
                        handleStatus(index, "error")
                        reject(error)
                    })
                })
            )
        })

        Promise.all(allPromises)
        .then(result => {
            console.info(result)
            onSent && onSent(result)
        })
        .catch(error => {
            console.error(error)
        })
    }

    const handleStatus = (index, newStatus) => {
        let _progress = [...progress]
        _progress[index].status = newStatus
        setProgress(_progress)
    }

    const getFirstPDF = (isGrayscale) => {
        return new Promise((resolve, reject) => {
            if (pdf === null) {
                getPDF(`${urlToPDF}${isGrayscale ? "?grayscale" : ""}`)
                .then(response => {
                    pdf = response
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
            } else {
                resolve(pdf)
            }
        })
    }

    const toPrint = (message) => {
        return new Promise((resolve, reject) => {
            getFirstPDF(message.value)
            .then(pdfObject => {
                let printable = URL.createObjectURL(pdfObject.blob, { type: 'application/pdf' })
                printJS({
                    printable: printable,
                    type: "pdf",
                    onLoadingEnd: () => {
                        resolve(message)
                    }
                })
            })
            .catch(error => {
                reject(error)
            })
        })
    }

    const toDownload = (message) => {
        return new Promise((resolve, reject) => {
            getFirstPDF()
            .then(pdfObject => {
                sendDownload(pdfObject.blob, pdfObject.filename)
                resolve(message)
            })
            .catch(error => {
                reject(error)
            })
        })
    }

    const toWhatsapp = (message) => {
        return new Promise((resolve, reject) => {
            sendWhatsapp(phoneNumber, message)
            .then(sent => {
                resolve(sent)
            })
            .catch(error => {
                reject(error)
            })
        })
    }

    const toSMS = (message) => {
        return new Promise((resolve, reject) => {
            sendSMS(phoneNumber, message)
            .then(sent => {
                resolve(sent)
            })
            .catch(error => {
                reject(error)
            })
        })
    }

    const toEmail = (message) => {
        return new Promise((resolve, reject) => {
            sendEmail(emailAddress, message)
            .then(sent => {
                resolve(sent)
            })
            .catch(error => {
                reject(error)
            })
        })
    }

    const showItem = (index) => {
        const message = progress[index]
        const item = statuses[message.messageTypeId][message.status]
        return message.checked && item && (
            <div className="card">
                <div className="card-header">
                    { item.title }
                </div>
                <div className="card-body">
                    <div className={ item.className }>
                        { item.icon }
                        <h3>{ item.title }</h3>
                        { item.text && (
                            <p>{ item.text }</p>
                        )}
                    </div>
                </div>
            </div>
        )
    }
    
    useEffect(() => {
        if (messages && progress.length == 0) {
            initializeProgress()
        }
    }, [ messages ])
    
    useEffect(() => {
        if (progress.length > 0 && progress.filter(message => message.status !== null).length == 0) {
            sendMessages()
        }
    }, [ progress ])

    return (
        <VSDrawer
            id="vssend"
            width="50%"
            title="Emitir Documento"
            onCancel={() => onClose()}
            VSDrawerFooterProps={{
                children: (
                    <button
                        className="btn-submit w-100"
                        disabled={progress.filter(message => message.status !== false && message.status !== "error").length > 0}
                        onClick={() => onResend()}
                    >
                        Realizar Nova Emissão
                    </button>
                )
            }}
        >
            <div className="vssend-body">
                { progress.length > 0 && progress?.map((message, index) => (
                    <React.Fragment key={index}>
                        { showItem(index) }
                    </React.Fragment>
                )) }
            </div>
        </VSDrawer>
    )
}

const mapStateToProps = state => {
    return {
        user: state.user
    };
};

export default connect(mapStateToProps)(VSSend)