import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux';

import './UserSettingUserSignature.scss'
import VSAccordionSetting from "../../VSAccordionSetting";

import { CircularProgress } from '@mui/material';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

import { 
    getStateList,
    optionalField,
    stringifyArray,
    useDidMountEffect,
    validateCPF
} from '../../../../utils'
import { getUser } from '../../../../store/actions';

import SettingPreview from '../../SettingPreview'
import TextFieldMask from '../../../../components/textFieldMask/TextFieldMask';

const UserSettingUserSignature = props => {
    const {
        user,
        clinic
    } = props

    const [ loading, setLoading ] = useState(false)
    const [ signature, setSignature ] = useState(user.signature)
    const [ isFormChanged, setIsFormChanged ] = useState(false)

    const [ crmv, setCrmv ] = useState(user.crmv)
    const [ errorCrmv, setErrorCrmv ] = useState(false)
    const [ helperCrmv, setHelperCrmv ] = useState(<span>Campo <b>Obrigatório</b></span>)

    const [ crmvStateId, setCrmvStateId ] = useState(user.crmvStateId)
    const [ errorCrmvStateId, setErrorCrmvStateId ] = useState(false)
    const [ helperCrmvStateId, setHelperCrmvStateId ] = useState(<span>Campo <b>Obrigatório</b></span>)

    const [ cpf, setCpf ] = useState(user.cpf || "")
    const [ errorCpf, setErrorCpf ] = useState(false)
    const [ helperCpf, setHelperCpf ] = useState(optionalField)

    const [ registrationMAPA, setRegistrationMAPA ] = useState(user.registrationMAPA)

    const validateOnTouch = () => {
        if (signature || crmv || crmvStateId || cpf) {
            validate()
        }
    }

    const validate = () => {
        let isValid = true
        if (loading) {
            isValid = false
        }

        if (!crmv || crmv.length > 3) {
            setErrorCrmv(false)
            setHelperCrmv(<span>Campo Opcional</span>)
        } else {
            isValid = false
            setErrorCrmv(true)
            setHelperCrmv("O CRMV informado não é válido")
        }
        
        if (crmv && !crmvStateId) {
            isValid = false
            setErrorCrmvStateId(true)
            setHelperCrmvStateId("O Estado do CRMV é obrigatório quando o CRMV é preenchido")
        } else {
            setErrorCrmvStateId(false)
            setHelperCrmvStateId(<span>Campo Opcional</span>)
        }

        if (cpf && !validateCPF(cpf)) {
            isValid = false
            setErrorCpf(true)
            setHelperCpf("O CPF informado não é válido")
        } else {
            setErrorCpf(false)
            setHelperCpf(optionalField)
        }

        return isValid
    }

    const isValid = () => !loading && !errorCrmv && !errorCrmvStateId && !errorCpf

    const save = () => {
        if (validate()) {
            setLoading(true)
            setIsFormChanged(false)
            const userParse = user.object
            userParse.set('signature', signature)
            userParse.set('crmv', crmv)
            userParse.set('crmvStateId', crmvStateId)
            userParse.set('registrationMAPA', registrationMAPA)
            userParse.set('cpf', cpf)
            return userParse.save()
            .then(_ => {
				props.dispatch(getUser(Parse.User.current()))
                setLoading(false)
            })
            .catch(error => {
                console.error(error)
                Swal.fire(
                    'Desculpe',
                    `Ocorreu algum erro ao tentar salvar a assinatura`,
                    'error'
                )
                userParse.revert()
            })
        }
        return Promise.reject();
    }

    useEffect(() => {
        validateOnTouch()
    }, [ signature, crmv, crmvStateId, cpf ])

    useDidMountEffect(() => {
        !isFormChanged &&
        setIsFormChanged(true)
    }, [ signature, crmv, crmvStateId, registrationMAPA, cpf ])

    return (
        <div id="user-setting-signature">
            <div className="row setting-content">
                <div className="col-12 col-md-5 col-xl-4">
                    <VSAccordionSetting
                        title="Assinatura e CRMV">

                        <div className="vs-accordion-text-primary">
                            Essas informações aparecerão nos documentos gerados no prontuário são esses: Prescrições Simples e Controlada, Solicitações de Exames, Atestados e Termos, Comprovante de Venda e Orçamento.
                        </div>
                        { loading ? (
                            <div className="row loading justify-content-center mt-3">
                                <div className="col-auto">
                                    <CircularProgress />
                                </div>
                            </div>
                        ) : (
                            <div className="row mt-3">
                                <div className="col">
                                    <div className="row align-items-center mb-3">
                                        <div className="col">
                                            <TextField
                                                className="w-100"
                                                placeholder={`Dr(a) ${user.fullName}`}
                                                label="Assinatura do(a) médico(a) veterinário(a)"
                                                value={signature}
                                                onChange={({ target: { value } }) => setSignature(value)}
                                                helperText={<span>Campo Opcional</span>}
                                            />
                                        </div>
                                    </div>
                                    <div className="row align-items-center mb-3">
                                        <div className="col">
                                            <TextField
                                                type="tel"
                                                className="w-100"
                                                placeholder="00000"
                                                label="CRMV"
                                                value={crmv}
                                                onChange={({ target: { value } }) => setCrmv(value)}
                                                error={errorCrmv}
                                                helperText={helperCrmv}
                                            />
                                        </div>
                                    </div>
                                    <div className="row align-items-center mb-3">
                                        <div className="col">
                                            <TextField
                                                className="w-100"
                                                placeholder="São Paulo"
                                                label="Estado"
                                                value={crmvStateId}
                                                onChange={({ target: { value } }) => setCrmvStateId(value)}
                                                error={errorCrmvStateId}
                                                helperText={helperCrmvStateId}
                                                select
                                            >
                                                { getStateList().map(stateItem => (
                                                    <MenuItem key={stateItem.value} value={stateItem.value}>
                                                        { stateItem.text }
                                                    </MenuItem>
                                                )) }
                                            </TextField>
                                        </div>
                                    </div>
                                    <div className="row align-items-center mb-3">
                                        <div className="col">
                                            <TextFieldMask
                                                type="tel"
                                                mask='000.000.000-00'
                                                value={cpf}
                                                onAccept={(value) => setCpf(value)}
                                                placeholder="000.000.000-00"
                                                label="CPF"
                                                error={errorCpf}
                                                helperText={helperCpf}
                                                size="small"
                                                variant="outlined"
                                                fullWidth
                                            />
                                        </div>
                                    </div>
                                    <div className="row align-items-center mb-3">
                                        <div className="col">
                                            <TextField
                                                className="w-100"
                                                placeholder={`Adicione o registro do MAPA`}
                                                label="Número de registro MAPA (Opcional)"
                                                value={registrationMAPA}
                                                onChange={({ target: { value } }) => setRegistrationMAPA(value)}
                                                helperText={<span>Campo Opcional</span>}
                                            />
                                        </div>
                                    </div>
                                    <div className="box-alert p-3">
                                        <div className="vs-accordion-text-secondary">
                                            <b>Sobre Registro no MAPA:</b><br/>
                                            Médicos Veterinários que necessitam prescrever ou adquirir produtos de uso veterinário sujeitos a controle especial, registrados no Ministério da Agricultura, Pecuária e Abastecimento (MAPA), devem seguir os procedimentos estabelecidos pela <b>Instituição Normativa nº 35, de 11 de Setembro de 2017 e da Instituição Normativa nº 55, de 04 de Dezembro de 2018.</b>
                                        </div>
                                        <div className="vs-accordion-text-secondary mt-3">
                                            A prescrição e aquisição de produtos de uso veterinário sujeitos a controle especial só pode ser realizada por médico-veterinário devidamente cadastrado no Sistema Integrado de Produtos e Estabelecimentos Agropecuários (SIPEAGRO)
                                        </div>
                                        <div className="vs-accordion-text-secondary mt-3">
                                            Saiba mais orientações sobre cadastro de médico veterinário e para emissão de notificações de produtos de controle especial no <a href="https://www.gov.br/agricultura/pt-br/assuntos/insumos-agropecuarios/insumos-pecuarios/produtos-veterinarios/cadastro-de-medicos-veterinarios" target="_blank">site do Gov.br</a>.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </VSAccordionSetting>
                </div>
                <div className="col-12 col-md-7 col-xl-8">
                    <SettingPreview 
                        isConfigSaved={loading || !isValid() || !isFormChanged}
                        onSave={save} />
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        user: state.user,
        clinic: state.clinic
    }
}

export default connect(mapStateToProps)(UserSettingUserSignature)