
import { useEffect, useRef, useState } from "react"
import PropTypes from 'prop-types';

import { ReactComponent as IconArrowLeft } from "../../assets/icon-arrow-left.svg"

import "./VSPagination.scss"
import { showArrayIndex } from "../../utils";

export const showPagination = (page, rowsPerPage, totalRows) => {
    const limit = page * rowsPerPage
    const rowsStart = showArrayIndex((page - 1) * rowsPerPage || 1)
    const rowsEnd   = showArrayIndex(limit > totalRows ? totalRows : limit)
    const rowsTotal = showArrayIndex(totalRows)
    if (totalRows == 0) {
        return `Nenhum resultado para ser exibido`
    }
    if (rowsPerPage >= totalRows) {
        return `Mostrando ${ totalRows } registro${totalRows == 1 ? "" : "s"} (todos)`
    }
    return `Mostrando ${ rowsStart } - ${ rowsEnd } de ${ rowsTotal }`
}

const VSPagination = (props) => {

    const {
        align,
        className,
        currentPage,
        totalRows,
        rowsPerPage,
        onChange
    } = props

    const _isMounted = useRef()
    useEffect(() => {
        _isMounted.current = true
        return () => {
            _isMounted.current = false
        }
    }, [])
    
    const pagination = useRef(null)

    const [ page, setPage ] = useState(currentPage)
    const [ totalVisiblePages, setTotalVisiblePages ] = useState(0)

    const totalPagesByRows = Math.ceil(totalRows / rowsPerPage) || 1
    const totalPages = totalPagesByRows > totalVisiblePages ? totalVisiblePages : totalPagesByRows
    const halfPages = Math.floor(totalPages / 2)
    const firstPage = page - halfPages < 1 ? 1 : (page + halfPages > totalPagesByRows ? totalPagesByRows + 1 - totalPages : page - halfPages)
    const pages = totalPages > 0 ? [...Array(totalPages).keys()] : []

    const disabledPrev = (page - 1) <= 0
    const disabledNext = (page + 1) > totalPagesByRows

    const calcTotalVisiblePages = () => {
        if (pagination.current) {
            setTotalVisiblePages(Math.floor((pagination.current.offsetWidth - 110) / 54))
        }
    }
    
    useEffect(() => {
        calcTotalVisiblePages()
        addEventListener("resize", calcTotalVisiblePages)
        return () => {
            removeEventListener("resize", calcTotalVisiblePages)
        }
    }, [])

    useEffect(() => {
        onChange && onChange(page)
    }, [ page ])

    if(totalPagesByRows <= 1){
        return (
            <div id="#vspagination"></div>
        )
    }
    
    return (
        <div id="vspagination" className={className}>
            <div className="vsbox-pagination" ref={pagination}>
                <div className={`row align-items-center justify-content-${align}`}>
                    <div
                        className={`col-auto vsbox-page ${ disabledPrev ? "vsbox-page-disable" : "" }`}
                        onClick={() => !disabledPrev && setPage(page - 1)}
                    >
                        <IconArrowLeft width="12px" height="12px" fill="var(--vsprontuario-secondary-color)" />
                    </div>
                    { pages.map(index => (
                        <div
                            key={index}
                            className={`col-auto vsbox-page ${ firstPage + index == page ? "vsbox-page-active" : "" }`}
                            onClick={() => firstPage + index !== page && setPage(firstPage + index)}
                        >
                            { firstPage + index }
                        </div>    
                    )) }
                    <div
                        className={`col-auto vsbox-page ${ disabledNext ? "vsbox-page-disable" : "" }`}
                        onClick={() => !disabledNext && setPage(page + 1)}
                    >
                        <IconArrowLeft width="12px" height="12px" fill="var(--vsprontuario-secondary-color)" />
                    </div>
                </div>
            </div>
        </div>
    )
}

VSPagination.propTypes = {
    align: PropTypes.oneOf(['start', 'center', 'end']),
    currentPage: PropTypes.number,
    totalRows: PropTypes.number,
    rowsPerPage: PropTypes.number,
    onChange: PropTypes.func
};

VSPagination.defaultProps = {
    align: 'center',
    currentPage: 1,
    totalRows: 0,
    rowsPerPage: 5,
    onChange: () => null
}

export default VSPagination