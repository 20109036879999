
import { connect } from "react-redux"
import CardProAdvertising from "../../components/cardProAdvertising/CardProAdvertising"

import "./VetLimitations.scss"
const VetLimitations = (props) => {

    const {
        pro,
        clinic,
        history
    } = props

    return (
        <div id="vet-limitations" className="row page-content">
            <div className="col-12">
                <div className="page-content">
                    <div className="row align-items-start align-items-md-center justify-content-end mb-3 flex-column flex-md-row">
                        <div className="col mb-2 mb-md-0">
                            <div className="page-title text-nowrap">
                                Prontuário Gratuito
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12 col-md-8">
                <div className="vsbox vsbox-limitations">
                    <div className="vsbox-body">
                        <h3>Algumas mudanças estão chegando</h3>
                        <p>
                            Em 2023, comemoramos nosso 11º aniversário.<br/>
                            São 11 anos ajudando no cuidado com os pets, oferecendo a melhor experiência e 
                            empoderando médicos veterinários de todo o Brasil.<br/><br/>

                            <b>Chegamos muito longe e estamos muito animados com o que vem a seguir.</b><br/><br/>

                            Uma coisa que nunca vamos mudar no Vet Smart é continuar apoiando o Médico 
                            Veterinário com conteúdos exclusivos e gratuitos em nossa plataforma. Vamos 
                            continuar oferecendo todas as informações que temos hoje e que auxiliam na 
                            rotina de atendimentos clínicos do dia-a-dia dos nossos usuários, com um 
                            bulário completo de produtos, palestras, podcasts e um prontuário para 
                            gerenciamento e otimização dos atendimentos.<br/><br/>

                            Em 2023, lançamos o nosso Prontuário PRO, onde você encontra mais ferramentas 
                            para atendimentos, gestão e acompanhamento da sua clínica; Essa foi a primeira 
                            de muitas melhorias que estão por vir. <br/><br/>

                            Vamos continuar melhorando a experiência da nossa plataforma, trazendo novas 
                            funcionalidades e ferramentas para todos os nossos usuários, <b>fique de olho e 
                            descubra nos próximos meses.</b><br/><br/>

                            Para janeiro de 2024, faremos <b>algumas mudanças</b> na nossa plataforma, mas você 
                            continuará tendo acesso ao Prontuário gratuito com todas as funcionalidades que 
                            você tanto ama.<br/><br/>

                            <b>A partir de janeiro</b> os usuários do Prontuário Gratuito passarão a ter limitações 
                            nos envios de cópias digitais, lembretes automáticos de vacinas para o tutor e 
                            convites para usuários acessarem a clínica.<br/><br/>
                            
                            Veja quais serão as mudanças:
                        </p>
                        <div className="limitations">
                            <div className="limitations-title">
                                Prontuário Gratuito
                            </div>
                            <div className="limitations-item">
                                <div className="row">
                                    <div className="col-12 col-md-7">
                                        <div className="limitations-text">
                                            Envio de Prescrições Digitais
                                        </div>
                                        <div className="limitations-info">
                                            Cópias digitais de Prescrições enviadas por WhatsApp, 
                                            SMS ou e-mail diretamente da plataforma. 
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-5 text-center text-md-end">
                                        <div className="limitations-value">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clip-path="url(#clip0_99_15250)">
                                                    <path d="M9.00004 16.17L4.83004 12L3.41504 13.415L9.00004 19L21 6.99996L19.585 5.58496L9.00004 16.17Z" fill="#00C4B4"/>
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_99_15250">
                                                        <rect width="24" height="24" fill="white"/>
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                            5 envios/mês
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="limitations-item">
                                <div className="row">
                                    <div className="col-12 col-md-7">
                                        <div className="limitations-text">
                                            Envio de Solicitações de Exames Digitais
                                        </div>
                                        <div className="limitations-info">
                                            Cópias digitais de Solicitações de Exames enviadas por WhatsApp, 
                                            SMS ou e-mail diretamente da plataforma. 
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-5 text-center text-md-end">
                                        <div className="limitations-value">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clip-path="url(#clip0_99_15250)">
                                                    <path d="M9.00004 16.17L4.83004 12L3.41504 13.415L9.00004 19L21 6.99996L19.585 5.58496L9.00004 16.17Z" fill="#00C4B4"/>
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_99_15250">
                                                        <rect width="24" height="24" fill="white"/>
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                            5 envios/mês
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="limitations-item">
                                <div className="row">
                                    <div className="col-12 col-md-7">
                                        <div className="limitations-text">
                                            Lembrete Automático de Vacina
                                        </div>
                                        <div className="limitations-info">
                                            Envio de lembretes de próximas vacinas para o Tutor por mensagens via WhatsApp, 
                                            SMS ou e-mail diretamente da plataforma.  
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-5 text-center text-md-end">
                                        <div className="limitations-value">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clip-path="url(#clip0_99_15250)">
                                                    <path d="M9.00004 16.17L4.83004 12L3.41504 13.415L9.00004 19L21 6.99996L19.585 5.58496L9.00004 16.17Z" fill="#00C4B4"/>
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_99_15250">
                                                        <rect width="24" height="24" fill="white"/>
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                            5 envios/mês
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="limitations-item">
                                <div className="row">
                                    <div className="col-12 col-md-7">
                                        <div className="limitations-text">
                                            Convites de Usuários
                                        </div>
                                        <div className="limitations-info">
                                            Envio de convites para outros usuários participarem da sua clínica. <b>Importante:</b> Se 
                                            você está atualmente acima desse novo limite, você não será impactado com essa mudança, 
                                            apenas não poderá convidar novos usuários. 
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-5 text-center text-md-end">
                                        <div className="limitations-value">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clip-path="url(#clip0_99_15260)">
                                                    <path d="M19 6.415L17.585 5L12 10.585L6.415 5L5 6.415L10.585 12L5 17.585L6.415 19L12 13.415L17.585 19L19 17.585L13.415 12L19 6.415Z" fill="#666666"/>
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_99_15260">
                                                        <rect width="24" height="24" fill="white"/>
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                            Apenas PRO
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p className="mt-4 mb-0">
                            Como você pode perceber, estamos abraçando completamente um novo capítulo de amadurecimento.<br/>
                            <b>Esperamos que você goste.</b>
                        </p>
                    </div>
                </div>
            </div>
            <div className="col-12 col-md-4">
                <CardProAdvertising clinic={clinic} pro={pro} history={history} />
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        pro: state.featuresPro,
        clinic: state.clinic
    };
};

export default connect(mapStateToProps)(VetLimitations)