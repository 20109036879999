import React from "react"
import TextField from '@mui/material/TextField';
import { IMaskInput, IMaskMixin } from 'react-imask';

const TextFieldMask = IMaskMixin(({inputRef, ...props}) => (
    <TextField
      {...props}
      inputRef={inputRef}  // bind internal input (if you use styled-components V4, use "ref" instead "innerRef")
    />
));

export default TextFieldMask