import React, { useState } from 'react';
import Button from '@mui/material/Button';

import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';

import { ReactComponent as IconMenu } from "../../assets/icon-menu.svg"

import "./VSMenu.scss"

const VSMenu = (props) => {

    const {
        id,
        placement,
        children,
        options,
        openOnHover
    } = props

    const anchorRef = React.useRef(null);

    const [ open, setOpen ] = useState(false)

    return (
        <div
            id={id}
            className="context-menu"
            onMouseEnter={() => {
                openOnHover && setOpen(true)
            }}
            onMouseLeave={(e) => {
                const el = e.toElement || e.relatedTarget
                if (!el.classList?.contains("context-menu")) {
                    openOnHover && setOpen(false)
                }
            }}
        >
            <div
                ref={anchorRef}
                className="cursor-pointer"
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={() => setOpen(true)}
            >
                { children }
            </div>
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                className="menu-popper"
                placement={placement}
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        className={placement.includes('top') ? 'menu-top' : 'menu-bottom'}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={() => setOpen(false)}>
                            <MenuList autoFocusItem={open} className="menu-list">
                                { 
                                    options && options.map((option, index) => 
                                        <MenuItem
                                            key={index}
                                            onClick={() => {
                                                setOpen(false)
                                                option.onClick && option.onClick()
                                            }}
                                            className="menu-option"
                                        >
                                            { option.menu }
                                        </MenuItem>
                                    )
                                }
                            </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>
    )
}

export default VSMenu;