import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';

import { CircularProgress, TextField } from '@mui/material';

import { addEvent, dayjs, saveOnTracker } from '../../../../utils'
import { getPatient } from '../../Patient'
import { ACL, APP_DEVICE, APP_NAME, APP_VERSION } from '../../../../constants';
import { getReturn } from './Return';

import DateInput from '../../../../components/dateInput/DateInput';
import VSDrawer from '../../../../components/vsDrawer/VSDrawer';
import VSCheckbox from '../../../../components/vsCheckbox/VSCheckbox';
import VSMedicalRecordsAudit from '../../../../components/vsMedicalRecordsAudit/VSMedicalRecordsAudit';
import VSError from '../../../../components/vsError/VSError';

import './ReturnForm.scss'
const ReturnForm = (props) => {

    const {
        id,
        id_retorno
    } = useParams()

    const {
        clinic,
        history,
        permissions
    } = props

    const update = id_retorno !== "novo"

    const [ loading, setLoading ] = useState(false)
    const [ error, setError ] = useState(null)
    const [ hasSubmited, setHasSubmited ] = useState(false)
    const [ patient, setPatient ] = useState(null)
    const [ _return, setReturn ] = useState(null)

    const [ appointment, setAppointment ] = useState(false)
    const [ vaccine, setVaccine ] = useState(false)
    const [ appointmentDrug, setAppointmentDrug ] = useState(false)
    const [ examRequest, setExamRequest ] = useState(false)
    const [ surgery, setSurgery ] = useState(false)
    const [ prescription, setPrescription ] = useState(false)
    const [ term, setTerm ] = useState(false)

    const defaultDate = dayjs()
    const [ returnDate, setReturnDate ] = useState(defaultDate.toDate())
    const [ returnDateText, setReturnDateText ] = useState(defaultDate.format("DD/MM/YYYY"))
    const [ errorReturnDate, setErrorReturnDate ] = useState(false)
    const [ helperReturnDate, setHelperReturnDate ] = useState(<span>Campo <b>Obrigatório</b></span>)
    const [ notes, setNotes ] = useState('')

    const handleReturnDate = (_date) => {
        setReturnDateText(_date)

        if (_date.match(/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/g)) {
            let newDate = dayjs(_date, "DD/MM/YYYY")
            if (newDate.isValid()) {
                setReturnDate(newDate.toDate())
                return
            }
        }
        setReturnDate(null)
    }

    const validateOnTouch = () => {
        if (hasSubmited && (returnDate || returnDateText || notes)) {
            validate()
        }
    }

    const validate = () => {
        let isValid = true
        if (loading) {
            isValid = false
        }

        if (error?.message) {
            isValid = false
        }

        const _returnDate = dayjs(returnDateText, "DD/MM/YYYY")
        if (_returnDate.isValid()) {
            if (_returnDate.format("YYYY") < 1971) {
                isValid = false
                setErrorReturnDate(true)
                setHelperReturnDate("A data não pode ser menor que 1971")
            } else if (_returnDate.diff(dayjs(), "years") > 5) {
                isValid = false
                setErrorReturnDate(true)
                setHelperReturnDate("A data não pode exceder 5 anos")
            } else {
                setErrorReturnDate(false)
                setHelperReturnDate(<span>Campo <b>Obrigatório</b></span>)
            }
        } else {
            isValid = false
            setErrorReturnDate(true)
            setHelperReturnDate("A data informada não é válida")
        }

        return isValid
    }

    const isValid = () => !loading && !errorReturnDate

    const fieldsWithErrorText = () => {
        let errors = []
        if (loading) {
            errors.push("Salvando o retorno")
        }
        if (error?.message) {
            errors.push(error.message)
        }
        if (errorReturnDate) {
            errors.push("Data do retorno")
        }
        if (!permissions?.check(ACL.RETURN, ACL.Rule.EDIT, _return)) {
            errors.push("Acesso negado")
        }
        return errors
    }

    const getPatientOrError = () => {
        setLoading(true)
        setError(null)

        getPatient(id, clinic.object)
        .then(_patient => {
            setPatient(_patient)
        })
        .catch(error => {
            Swal.fire(
                'Desculpe',
                error,
                'error'
            )
            .then(_ => {
                setLoading(false)
                setError({
                    message: error,
                    function: "getPatientOrError"
                })
            })
        })
    }

    const getReturnOrError = () => {
        if (permissions?.check(ACL.RETURN, ACL.Rule.VIEW)) {
            saveOnTracker("Iniciou", "Retorno", update ? id_retorno : "")
            if (update) {
                setLoading(true)
                setError(null)

                getReturn(id_retorno, patient)
                .then(_return => {
                    setReturn(_return)
                    setReturnDate(_return.get("returnDate"))
                    setReturnDateText(dayjs(_return.get("returnDate")).format("DD/MM/YYYY"))
                    setAppointment(_return.get("appointment"))
                    setVaccine(_return.get("vaccine"))
                    setAppointmentDrug(_return.get("appointmentDrug"))
                    setExamRequest(_return.get("examRequest"))
                    setSurgery(_return.get("surgery"))
                    setPrescription(_return.get("prescription"))
                    setTerm(_return.get("term"))
                    setNotes(_return.get("notes"))
                    setLoading(false)
                })
                .catch(error => {
                    Swal.fire(
                        'Desculpe',
                        error,
                        'error'
                    )
                    .then(_ => {
                        setLoading(false)
                        setError({
                            message: error,
                            function: "getReturnOrError"
                        })
                    })
                })
            } else {
                let newReturn = new Parse.Object("Return")
                newReturn.setACL(clinic.ACL)
                newReturn.set("clinic", clinic.object)
                newReturn.set("createdBy", Parse.User.current())
                newReturn.set("createdApp", APP_NAME)
                newReturn.set("createdDevice", APP_DEVICE)
                newReturn.set("createdAppVersion", APP_VERSION)
                newReturn.set("isDeleted", false)
                newReturn.set("patient", patient)
                setReturn(newReturn)
                setLoading(false)
            }
        }
    }

    const save = () => {
        return new Promise((resolve, reject) => {
            setHasSubmited(true)
            if (validate()) {
                setLoading(true)
                _return.set('returnDate', returnDate);
                if (!_return.id) {
                    // Registrar o documentDate apenas no cadastro
                    _return.set('documentDate', defaultDate.toDate());
                }
                _return.set('appointment', appointment);
                _return.set('vaccine', vaccine);
                _return.set('appointmentDrug', appointmentDrug);
                _return.set('examRequest', examRequest);
                _return.set('surgery', surgery);
                _return.set('prescription', prescription);
                _return.set('term', term);
                _return.set('notes', notes);
                _return.set("changedAt", new Date())
                _return.set("changedBy", Parse.User.current())
                _return.set("changedApp", APP_NAME)
                _return.set("changedDevice", APP_DEVICE)
                _return.set("changedAppVersion", APP_VERSION)
                _return.save()
                .then(_newReturn => {
                    saveOnTracker("Concluiu", "Retorno", _newReturn.id)
                    resolve(_newReturn)
                })
                .catch(error => {
                    setLoading(false)
                    console.error(error)
                    Swal.fire(
                        'Desculpe',
                        `Ocorreu algum erro ao tentar ${_return.id ? "editar" : "cadastrar"} o retorno do animal`,
                        'error'
                    )
                    update && _return.revert()
                    reject()
                })
            } else {
                reject()
            }
        })
    }

    useEffect(() => {
        validateOnTouch()
    }, [ returnDate, returnDateText, appointment, vaccine, appointmentDrug, examRequest, surgery, prescription, term, notes ])

    useEffect(() => {
        if (_return && !permissions?.check(ACL.RETURN, ACL.Rule.EDIT, _return)) {
            permissions?.error()
        }
    }, [ _return ])

    useEffect(() => {
        if (patient) {
            getReturnOrError()
        }
    }, [ patient ])

    useEffect(() => {
        if (id) {
            getPatientOrError()
        }
    }, [])

    const errors = fieldsWithErrorText()

    return (
        <VSDrawer
            id="return-form"
            width="50%"
            title={update ? "Editar Lembrete de Retorno" : "Novo Lembrete de Retorno"}
            cancel={update ? <span>Cancelar Lembrete<span className="d-none d-md-inline"> de Retorno</span></span> : <span>Cancelar Lembrete<span className="d-none d-md-inline"> de Retorno</span></span>}
            submit={isValid ? <span>Salvar Lembrete<span className="d-none d-md-inline"> de Retorno</span></span> : <span>Preencha os campos<span className="d-none d-md-inline"> necessários</span></span>}
            errors={errors}
            onSubmit={save}
            onCancel={() => history.goBack()}
            onAfterSave={(object) => {
                history.replace(`/animal/${patient.id}/retorno`)
                addEvent("Return__getList", {})
            }}
            VSDrawerSuccessProps={{
                title: "Lembrete de Retorno salvo com Sucesso",
                text: ""
            }}
            VSDrawerCancelProps={{
                title: "Tem certeza que deseja cancelar o lembrete de retorno?",
                confirm: "Cancelar Lembrete de Retorno",
                cancel: "Voltar para Edição"
            }}
        >
            { loading ? (
                <div className="row data-loading">
                    <div className="col">
                        <CircularProgress />
                    </div>
                </div>
            ) : error?.message ? (
                <VSError
                    message={error.message}
                    onClose={() => {
                        switch(error.function) {
                            case "getPatientOrError":
                                getPatientOrError()
                                break
                            case "getReturnOrError":
                                getReturnOrError()
                                break
                            default:
                        }
                    }}
                />
            ) : (
                <div className="row">
                    <div className="col">
                        <div className="row align-items-center mb-3">
                            <div className="col-auto">
                                <div className="input-title">
                                    Data do Retorno
                                </div>
                            </div>
                            <div className="col-auto" style={{ width: '200px' }}>
                                <DateInput
                                    className="m-0"
                                    placeholder="00/00/0000"
                                    value={returnDateText || null}
                                    error={errorReturnDate}
                                    helperText={null}
                                    onChange={(value) => handleReturnDate(value)}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                />
                            </div>
                            { errorReturnDate && (
                                <div className="col-12">
                                    <small className="text-danger">{ helperReturnDate }</small>
                                </div>
                            )}
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="input-title input-height">Tipo(s)/Motivo(s) do Retorno (Selecione quantos quiser)</div>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-12 mb-2">
                                <VSCheckbox
                                    label="Consulta"
                                    checked={appointment}
                                    onChange={(checked) => setAppointment(checked)}
                                />
                            </div>
                            <div className="col-12 mb-2">
                                <VSCheckbox
                                    label="Vacina"
                                    checked={vaccine}
                                    onChange={(checked) => setVaccine(checked)}
                                />
                            </div>
                            <div className="col-12 mb-2">
                                <VSCheckbox
                                    label="Aplicação de Medicamento"
                                    checked={appointmentDrug}
                                    onChange={(checked) => setAppointmentDrug(checked)}
                                />
                            </div>
                            <div className="col-12 mb-2">
                                <VSCheckbox
                                    label="Resultado de Exame"
                                    checked={examRequest}
                                    onChange={(checked) => setExamRequest(checked)}
                                />
                            </div>
                            <div className="col-12 mb-2">
                                <VSCheckbox
                                    label="Pós-Cirúrgico"
                                    checked={surgery}
                                    onChange={(checked) => setSurgery(checked)}
                                />
                            </div>
                            <div className="col-12 mb-2">
                                <VSCheckbox
                                    label="Entrega/Renovação de Prescrição"
                                    checked={prescription}
                                    onChange={(checked) => setPrescription(checked)}
                                />
                            </div>
                            <div className="col-12 mb-3">
                                <VSCheckbox
                                    label="Entrega/Renovação de Termo ou Atestado"
                                    checked={term}
                                    onChange={(checked) => setTerm(checked)}
                                />
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="col-12">
                                <div className="input-title input-height">
                                    Anotações (Opcional)
                                </div>
                            </div>
                            <div className="col-12">
                                <TextField
                                    value={notes}
                                    placeholder="Escreva suas anotações sobre este retorno aqui"
                                    onChange={({ target: { value } }) => setNotes(value)}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                />
                            </div>
                        </div>
                        <VSMedicalRecordsAudit parseObject={_return} />
                    </div>
                </div>
            )}
        </VSDrawer>
    )
}

const mapStateToProps = state => {
    return {
        clinic: state.clinic,
        permissions: state.permissions
    };
};
  
export default connect(mapStateToProps)(ReturnForm);