import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux';

import { ReactComponent as IconAddO } from "../../../assets/icon-primary-add-o.svg"
import { ReactComponent as IconPaw } from "../../../assets/icon-paw.svg"

import { diacriticSensitive } from '../../../utils';
import VSList from "../../../components/vsList/VSList"
import VSEmpty from "../../../components/vsEmpty/VSEmpty"
import CardFeedback from '../../../components/cardFeedback/CardFeedback';
import { ACL } from '../../../constants';
import { getSpecies } from '../../patient/Patient';

import VaccineProtocolView from './VaccineProtocolView';

import "./VaccineProtocolList.scss"
import { vaccineTypeOptions } from '../../patient/tabs/vaccine/Vaccine';
import { vaccineProtocolTotalShotsOptions } from './VaccineProtocol';
import { defaultIntervals } from '../../../components/intervalInput/IntervalInput';

const VACCINE_PROTOCOL_SORT_RECENT       = "recent"
const VACCINE_PROTOCOL_SORT_OLD          = "old"
const VACCINE_PROTOCOL_SORT_ALPHABETICAL = "alphabetical"

const vaccineProtocolSortOptions = [
    { value: VACCINE_PROTOCOL_SORT_RECENT,       label: "Mais recentes"},
    { value: VACCINE_PROTOCOL_SORT_OLD,          label: "Mais antigos"},
    { value: VACCINE_PROTOCOL_SORT_ALPHABETICAL, label: "Ordem alfabética"},
]

const VaccineProtocolList = props => {
    
    const {
        clinic,
        history,
        permissions
    } = props

    const rowsPerPage = 5

    const filter = useRef(null)
    const _vaccineTypeOptions = vaccineTypeOptions.map(option => ({ label: option, value: option }))
    const _vaccineProtocolTotalShotsOptions = [{
        label: "Mostrar todos",
        value: null
    }].concat(vaccineProtocolTotalShotsOptions)
    const _vaccineProtocolShotIntervalOptions = [{
        label: "Mostrar todos",
        value: null
    }].concat(defaultIntervals)

    const [ items, setItems ] = useState([])
    const [ vaccineProtocolSort, setVaccineProtocolSort ] = useState(VACCINE_PROTOCOL_SORT_RECENT)
    const [ vaccineProtocolTotalShots, setVaccineProtocolTotalShots ] = useState(null)
    const [ vaccineProtocolShotInterval, setVaccineProtocolShotInterval ] = useState(null)
    const [ vaccineTypes, setVaccineTypes ] = useState([])

    const [ species, setSpecies ] = useState([])
    const [ speciesToFilter, setSpeciesToFilter ] = useState([])

    const getMRProtocolVaccines = (searchBy, dateFrom, dateTo, orderBy, page) => {
        return new Promise((resolve, reject) => {
            const searchByVaccine = new Parse.Query("MRProtocolVaccine")
            if (searchBy) {
                searchByVaccine.matches("vaccineType", diacriticSensitive(searchBy), "im")
            }

            const searchByName = new Parse.Query("MRProtocolVaccine")
            if (searchBy) {
                searchByName.matches("name", diacriticSensitive(searchBy), "im")
            }

            const query = Parse.Query.or(searchByName, searchByVaccine)
            query.equalTo("clinic", clinic.object);
            query.equalTo("isDeleted", false);
            if(vaccineTypes && vaccineTypes.length > 0){
                query.containedIn("vaccineType", vaccineTypes.map(vaccineType => vaccineType.value))
            }
            if(speciesToFilter && speciesToFilter.length > 0){
                query.containedIn("specie", speciesToFilter.map(specie => specie.value))
            }
            if(vaccineProtocolTotalShots){
                query.equalTo("totalShots", Number(vaccineProtocolTotalShots))
            }
            if(vaccineProtocolShotInterval){
                let _numberValue = Number(vaccineProtocolShotInterval.split(" ")[0])
                let _stringValue = vaccineProtocolShotInterval.split(" ")[1]
                query.equalTo("shotInterval", _numberValue)
                query.equalTo("shotIntervalUnit", _stringValue)
            }
            switch (vaccineProtocolSort) {
                case VACCINE_PROTOCOL_SORT_ALPHABETICAL:
                    query.ascending("name");
                    break;
                case VACCINE_PROTOCOL_SORT_OLD:
                    query.ascending("createdAt");
                    break;
                default:
                    query.descending("createdAt");
            }
            query.addAscending("objectId")
            query.withCount()
            // query.include("createdBy")
            // query.include("changedBy")
            query.skip((page - 1) * rowsPerPage)
            query.limit(rowsPerPage)
            query.find()
            .then(_protocolVaccines => {
                setItems(_protocolVaccines?.results)
                resolve({
                    items: _protocolVaccines?.results,
                    total: _protocolVaccines?.count
                })
            })
            .catch(error => {
                console.error(error)
                reject(error)
            })
        })
    }

    const onClearFilter = () => {
        setVaccineProtocolSort(VACCINE_PROTOCOL_SORT_RECENT)
        setVaccineTypes([])
        setSpeciesToFilter([])
        setVaccineProtocolTotalShots(null)
        setVaccineProtocolShotInterval(null)
    }

    const onChange = () => {
        if (filter.current) {
            filter.current.onChange()
        }
    }

    useEffect(() => {
        if (permissions && !permissions.check(ACL.VACCINE, ACL.Rule.VIEW)) {
            permissions.error()
        }
    }, [ permissions ])

    useEffect(() => {
        if (clinic && clinic.isLoaded && clinic.object) {
            getSpecies().then(_species => setSpecies(_species.map(specie => ({ label: specie, value: specie }))))
        }
    }, [ clinic ])

    useEffect(() => {
        document.addEventListener("ProtocolVaccine__getList", onChange)
        return () => { 
            document.removeEventListener("ProtocolVaccine__getList", onChange)
        }
    }, [])

    return (
        <>
            <div id="supply-list" className="row flex-grow-1">
                <div className="col-12">
                    <div className="page-content">
                        <VSList
                            ref={filter}
                            title={{
                                text: "Protocolos de Vacinas"
                            }}
                            button={permissions?.check(ACL.VACCINE, ACL.Rule.EDIT) && {
                                id: "new-protocolvaccine-link",
                                text: "NOVO PROTOCOLO",
                                onClick: () => history.push(`/protocolo/vacina/novo`)
                            }}
                            search={{
                                text: "Buscar por nome da vacina ou protocolo"
                            }}
                            VSFilterProps={{
                                title: "Filtro de Protocolo de Vacinas",
                                onClear: onClearFilter,
                                onFilter: () => {
                                    onChange()
                                },
                                filters: [
                                    {
                                        title: "Ordenar por",
                                        defaultValue: VACCINE_PROTOCOL_SORT_RECENT,
                                        value: vaccineProtocolSort,
                                        onChange: (value) => {
                                            setVaccineProtocolSort(value)
                                        },
                                        options: vaccineProtocolSortOptions
                                    },
                                    {
                                        title: "Tipo de Vacina",
                                        defaultValue: [],
                                        value: vaccineTypes,
                                        onChange: (selectedOptions) => setVaccineTypes(selectedOptions),
                                        onSearch: (term) => {
                                            const _options = _vaccineTypeOptions.filter(vaccineTypeOption => vaccineTypeOption.value.toUpperCase().includes(term.toUpperCase()))
                                            return Promise.resolve({
                                                options: _options,
                                                count: _options.length
                                            })
                                        },
                                        options: _vaccineTypeOptions,
                                        multiple: {
                                            title: "Tipo de Vacina",
                                            button: "Selecione um tipo de vacina",
                                            placeholder: "Procure pelo tipo da vacina"
                                        }
                                    },
                                    {
                                        title: "Espécie",
                                        defaultValue: [],
                                        value: speciesToFilter,
                                        onChange: (selectedOptions) => setSpeciesToFilter(selectedOptions),
                                        onSearch: (term) => {
                                            const _options = species?.filter(specie => specie.value.toUpperCase().includes(term.toUpperCase()))
                                            return Promise.resolve({
                                                options: _options,
                                                count: _options.length
                                            })
                                        },
                                        options: species,
                                        multiple: {
                                            title: "Espécie",
                                            button: "Selecione uma espécie",
                                            placeholder: "Procure pela espécie"
                                        }
                                    },
                                    {
                                        title: "Quantidade de doses",
                                        defaultValue: null,
                                        value: vaccineProtocolTotalShots,
                                        onChange: (value) => {
                                            setVaccineProtocolTotalShots(value)
                                        },
                                        options: _vaccineProtocolTotalShotsOptions
                                    },
                                    {
                                        title: "Intervalo de aplicação",
                                        defaultValue: null,
                                        value: vaccineProtocolShotInterval,
                                        onChange: (value) => {
                                            setVaccineProtocolShotInterval(value)
                                        },
                                        options: _vaccineProtocolShotIntervalOptions
                                    }
                                ]
                            }}
                            pagination={{
                                rowsPerPage: rowsPerPage,
                                expand: true
                            }}
                            onChange={({ _searchBy, _start, _end, _orderBy, _page }) => {
                                return getMRProtocolVaccines(_searchBy, _start, _end, _orderBy, _page)
                            }}
                            // customFilter={
                            //     <div className="row gx-3">
                            //     </div>
                            // }
                            renderItem={(item, expanded) => (
                                <VaccineProtocolView object={item} expanded={expanded} {...props} />
                            )}
                            renderEmpty={() => (
                                <VSEmpty
                                    icon={<IconPaw width="32px" height="32px" fill="var(--vsprontuario-primary-color)" />}
                                    title="Nenhum protocolo de vacina cadastrado"
                                    text="Todos os seus protocolos de vacina aparecerão aqui, cadastre um novo protocolo para programar as próximas vacinas dos seus pacientes."
                                >
                                    { permissions?.check(ACL.VACCINE, ACL.Rule.EDIT) && (
                                        <button
                                            className="vsbox-btn vsbox-btn-primary w-auto"
                                            onClick={() => history.push(`/protocolo/vacina/novo`)}
                                        >
                                            <IconAddO width="14" height="14" className="me-2" />
                                            Novo protocolo
                                        </button>
                                    )}
                                </VSEmpty>
                            )}
                            renderNotFound={() => (
                                <VSEmpty
                                    icon={<IconPaw width="32px" height="32px" fill="var(--vsprontuario-primary-color)" />}
                                    title="Não encontramos nenhum resultado para a sua busca"
                                    text="Tente refazer sua busca utilizando mais caracteres ou crie um novo protocolo de vacina utilizando o botão abaixo"
                                >
                                    { permissions?.check(ACL.VACCINE, ACL.Rule.EDIT) && (
                                        <button
                                            className="vsbox-btn vsbox-btn-primary w-auto"
                                            onClick={() => history.push(`/protocolo/vacina/novo`)}
                                        >
                                            <IconAddO width="14" height="14" className="me-2" />
                                            Novo protocolo
                                        </button>
                                    )}
                                </VSEmpty>
                            )}
                        />
                    </div>
                </div>
            </div>
            {items && items.length > 0 && (
                <CardFeedback contentDetail={history.location?.pathname || "SupplyList"} />
            )}
        </>
    )
}


const mapStateToProps = state => {
    return {
        clinic: state.clinic,
        permissions: state.permissions
    };
};

export default connect(mapStateToProps)(VaccineProtocolList);