import { useEffect } from "react"
import TextFieldMask from "../../../../../components/textFieldMask/TextFieldMask"
import dayjs from "dayjs"

const ManufacturingDateField = (props) => {

    const {
        manufacturingDate,
        manufacturingDateText,
        handleManufacturingDate,
        errorManufacturingDate,
        helperManufacturingDate
    } = props

    useEffect(() => {
        if (manufacturingDate && !manufacturingDateText) {
            handleManufacturingDate(dayjs(manufacturingDate).format("MM/YYYY"))
        }
    }, [ manufacturingDate ])

    return (
        <div className="row">
            <div className="col-12">
                <div className="input-subtitle mb-1">
                    Data de Fabricação
                </div>
            </div>
            <div className="col-12">
                <TextFieldMask
                    type="tel"
                    className="my-0"
                    mask="00/0000"
                    placeholder="00/0000"
                    value={manufacturingDateText}
                    error={errorManufacturingDate}
                    helperText={helperManufacturingDate}
                    onAccept={(value) => handleManufacturingDate(value)}
                    size="small"
                    variant="outlined"
                    fullWidth
                />
            </div>
        </div>
    )
}

export default ManufacturingDateField