import React, { useState, useEffect, useRef } from 'react';
import Autocomplete from '@mui/lab/Autocomplete';
import { makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';

import "./SearchByOwnerOrPatient.scss"
import { diacriticSensitive } from '../../utils';
import CardPatient from '../cardPatient/CardPatient';
import CardOwner from '../cardOwner/CardOwner';

import { ReactComponent as IconArrowLeft } from "../../assets/icon-arrow-left.svg"
import { ReactComponent as IconCloseO } from "../../assets/icon-close-o.svg"
import { ReactComponent as IconSearch } from "../../assets/icon-search.svg"
import { InputAdornment } from '@mui/material';

const useStyles = makeStyles({
    listbox: {
        padding: 0
    },
    option: {
        backgroundColor: 'transparent',
        padding: 0,
        "& > div:hover": {
            backgroundColor: "#EBEBEB !important"
        }
    },
    popupIndicatorOpen: {
        transform: 'rotate(0)'
    }
});

const SearchByOwnerOrPatient = (props) => {

    const {
        clinic,
        disabled,
        onSelect,
        showMoreResults,
        onlyOwners,
        onClick,
        onClose
    } = props

    const classes = useStyles();

    const searchWithDelay = useRef(null)

    const cardStyles = {
        root: {
            cursor: 'pointer',
            width: '100%',
            margin: 0,
            borderBottom: '1px solid #DDDDDD',
            borderRadius: 0,
            marginBottom: 0,
            boxShadow: 'none'
        }
    }

    const componentRef = useRef(null)

    const [ search, setSearch ] = useState('')
    const [ options, setOptions ] = useState([])

    const getPatients = () => {
        return new Promise(resolve => {
            if (onlyOwners || !search) {
                return resolve([])
            }
            var query = new Parse.Query("Patient")
            query.matches("name", diacriticSensitive(search), "im") // field, regex, options

            if(!isNaN(search)){
                var queryId = new Parse.Query("Patient")
                queryId.equalTo('patientNumberId', Number(search))
                
                query = Parse.Query.or(query, queryId)
            }
            query.equalTo("clinic", clinic.object)
            query.equalTo("isDeleted", false)
            
            query.limit(100)
            query.descending("updatedAt")
            query.include("owner")
            query.find()
            .then(patients => {
                if (patients) {
                    const patientOptions = patients.map(patient => {
                        return {
                            patient: patient,
                            owner: patient.get("owner")
                        }
                    })
                    return resolve(patientOptions)
                }
            })
            .catch(error => {
                console.error(error)
                return resolve([])
            })
        })
    }

    const getOwners = () => {
        return new Promise(resolve => {
            if (!search) {
                return resolve([])
            }
            var query = new Parse.Query("Owner")
            query.equalTo("clinic", clinic.object)
            query.equalTo("isDeleted", false)
            query.matches("name", diacriticSensitive(search), "im") // field, regex, options
            query.limit(100)
            query.descending("updatedAt")
            query.find()
            .then(owners => {
                if (owners) {
                    const ownerOptions = owners.map(owner => {
                        return {
                            owner: owner,
                            patient: null
                        }
                    })
                    return resolve(ownerOptions)
                }
                return resolve([])
            })
            .catch(error => {
                console.error(error)
                return resolve([])
            })
        })
    }

    useEffect(() => {
        searchWithDelay.current = setTimeout(() => {
            setOptions([])
            const patients = getPatients()
            const owners = getOwners()
            Promise.all([patients, owners]).then(resultsSeparated => {
                const results = resultsSeparated[0].concat(resultsSeparated[1])
                if (showMoreResults) {
                    results.push(null)
                }
                setOptions(results)
            })
            .catch(error => {
                console.error(error)
            })
        }, 300)
        return () => {
            clearInterval(searchWithDelay.current)
        }
    }, [ search ])

    return (
        <div ref={componentRef} id="search-by">
            <Autocomplete
                value={search}
                options={options}
                disabled={disabled}
                disableClearable={true}
                freeSolo
                getOptionLabel={_ => search}
                onInput={({ target: { value } }) => setSearch(value)}
                onChange={() => null}
                renderOption={(opt) => {
                    const option = options[opt["data-option-index"]]
                    return (
                    <React.Fragment>
                        { option ? (
                            <div onClick={() => onSelect(option, setSearch)}>
                                { option.patient ? (
                                    <CardPatient
                                        styles={cardStyles}
                                        patient={option.patient}
                                    />
                                ) : option.owner ? (
                                    <CardOwner
                                        styles={cardStyles}
                                        owner={option.owner}
                                    />
                                ) : null }
                            </div>
                        ) : (
                            search && (
                                <div className="search-more" onClick={() => onSelect(search, setSearch)}>
                                    Ver todos os resultados para <b>"{ search }"</b>
                                    <IconArrowLeft width="12" height="16" />
                                </div>
                            )
                        )}
                    </React.Fragment>
                )}}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        placeholder={onlyOwners ? "Buscar tutor(a)" : "Buscar tutor, animal ou ID"}
                        size="small"
                        variant="outlined"
                        fullWidth
                        onClick={_ => {
                            onClick && onClick(componentRef.current)
                        }}
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password', // disable autocomplete and autofill
                        }}
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                                <InputAdornment position="start">
                                    <IconSearch width="15px" height="20px" fill="#DDDDDD" />
                                </InputAdornment>
                            ),
                            endAdornment: onClose && (
                                <InputAdornment position="end" onClick={onClose}>
                                    <IconCloseO width="20px" height="20px" fill="#DDDDDD" />
                                </InputAdornment>
                            )
                        }}
                    />
                )}
                classes={{
                    listbox: classes.listbox,
                    option: classes.option,
                    popupIndicatorOpen: classes.popupIndicatorOpen,
                }}
            />
        </div>
    )
}

export default SearchByOwnerOrPatient