import React from 'react'
import PropTypes from 'prop-types';

import './VSDrawerFooter.scss'

const VSDrawerFooter = (props) => {

    const {
        show,
        children
    } = props

    return show && (
        <div className="vsdrawer-footer">
            { children }
        </div>
    )
}

VSDrawerFooter.propTypes = {
    show: PropTypes.bool,
    children: PropTypes.node
};

VSDrawerFooter.defaultProps = {
    show: true,
    children: <div></div>
}

export default VSDrawerFooter;