import React, { useEffect, useRef, useState } from "react"
import { useParams } from "react-router"
import { connect } from "react-redux"
import axios from "axios"

import { CircularProgress, Switch } from "@mui/material"

import { ReactComponent as IconArrowLeft } from "../../../../assets/icon-arrow-left.svg"
import { ReactComponent as IconEdit } from "../../../../assets/icon-primary-edit.svg"
import { ReactComponent as IconCalendar } from "../../../../assets/icon-calendar.svg"
import { ReactComponent as IconDocument } from "../../../../assets/icon-document.svg"
import { ReactComponent as IconCalendarCheck } from "../../../../assets/icon-calendar-check.svg"
import { ReactComponent as IconDisease } from "../../../../assets/icon-disease.svg"
import { ReactComponent as IconExam } from "../../../../assets/icon-exam.svg"

import { canUserEditVeterinaryDocuments, dayjs, saveOnTracker, stringifyArray } from "../../../../utils"
import { ACL } from "../../../../constants"

import VSMessage, { saveOnTrackerMessage } from "../../../../components/vsMessage/VSMessage";
import VSShare from "../../../../components/vsShare/VSShare"

import "./ExamShare.scss"
const ExamShare = (props) => {

    const {
        id_documento
    } = useParams()

    const {
        pro,
        user,
        clinic,
        history,
        location,
        permissions
    } = props

    const MessageForm = useRef(null)

    const canEdit = canUserEditVeterinaryDocuments()

    const [ prefs, setPrefs ] = useState(null)

    const [ hasSubmited, setHasSubmited ] = useState(false)
    const [ errors, setErrors ] = useState([])

    const [ loading, setLoading ] = useState(false)
    const [ loadingToggle, setLoadingToggle ] = useState(false)
    const [ loadingPreview, setLoadingPreview ] = useState(true)
    const [ document, setDocument ] = useState(null)
    const [ preview, setPreview ] = useState(null)
    const [ previewType, setPreviewType ] = useState(1)

    const link = `${process.env.REACT_APP_PRESCRIPTION}/e/${document?.id}`
    const link_pdf = `${process.env.REACT_APP_PDF}/api/v2/e/${document?.id}`

    if (!permissions?.check(ACL.TERM, ACL.Rule.VIEW)) {
        permissions?.error()
        return <div></div>
    }

    const validate = () => {
        let _errors = []
        if (MessageForm.current) {
            _errors = MessageForm.current?.validate()
        }
        setErrors(_errors)
        return _errors
    }

    const send = () => {
        return new Promise((resolve, reject) => {
            setHasSubmited(true)
            if (errors.length == 0) {
                const values = MessageForm.current?.save()

                saveOnTrackerMessage("Solicitação de Exame", document.id, values)
            } else {
                reject()
            }
        })
    }

    const toggleDisplayDate = (checked) => {
        setLoadingToggle(true)
        document.set("displayDate", checked)
        document.save()
        .then(object => {
            setDocument(object)
            setLoadingToggle(false)
        })
        .catch(error => {
            console.error(error)
            setLoadingToggle(false)
        })
    }

    const submitText = () => {
        let text = []
        if (!prefs) {
            text.push("Buscando informações")
        } else {
            if (prefs.get("printEnabled")) {
                text.push("Imprimir")
            }
            if (prefs.get("downloadEnabled")) {
                text.push("Baixar")
            }
            if (prefs.get("whatsappEnabled") || prefs.get("smsEnabled") || prefs.get("emailEnabled")) {
                text.push("Enviar")
            }
        }
        if (text.length == 0) {
            return "Escolha pelo menos uma opção"
        }
        return stringifyArray(text)
    }

    const getPreview = () => {
        setLoadingPreview(true)
        setPreview(null)

        axios.get(`${link}?donttrack&onlyContent`)
        .then(response => {
            setPreview(response.data)
            setLoadingPreview(false)
        })
    }

    const getMedicalRecordPrefs = () => {
        const query = new Parse.Query("MedicalRecordsPrefs");
        query.equalTo("clinic", clinic.object);
        query.equalTo("user", user.object);
        query.first()
        .then(_prefs => {
            if (_prefs) {
                setPrefs(_prefs)
            } else {
                const newPrefs = new Parse.Object("MedicalRecordsPrefs")
                newPrefs.setACL(clinic.ACL)
                newPrefs.set("clinic", clinic.object)
                newPrefs.set("user", user.object)
                newPrefs.set("printEnabled", false)
                newPrefs.set("printTermEnabled", false)
                newPrefs.set("sendToSMSEnabled", false)
                newPrefs.set("sendToWhatsappEnabled", false)
                newPrefs.set("sendToEmailEnabled", false)
                newPrefs.set("sendToDownloadEnabled", false)
                newPrefs.set("downloadEnabled", false)
                newPrefs.set("downloadTermEnabled", false)
                newPrefs.set("printColorType", 0)
                newPrefs.set("displayOld", false)
                newPrefs.set("disableToggle", false)
                newPrefs.save()
                .then(_prefs => {
                    setPrefs(_prefs)
                })
                .catch(error => {
                    console.error(error)
                    Swal.fire(
                        'Desculpe',
                        `Ocorreu algum erro ao tentar cadastrar suas preferências de exibição e envio`,
                        'error'
                    )
                    newPrefs.revert()
                })
            }
        })
        .catch(error => {
            console.error(error)
            Swal.fire(
                'Desculpe',
                `Ocorreu um erro ao gravar suas preferências, por favor tente novamente.`,
                'error'
            )
        })
    }

    useEffect(() => {
        if (clinic && clinic.object && user && user.object && id_documento) {
            setLoading(true)

            getMedicalRecordPrefs()

            var query = new Parse.Query("ExamRequest");
            query.equalTo("clinic", clinic.object);
            query.equalTo("isDeleted", false);
            query.include("patient");
            query.include("patient.owner");
            query.get(id_documento)
            .then(_document => {
                if (_document) {
                    setDocument(_document)
                } else {
                    console.error(_document)
                    Swal.fire(
                        'Desculpe',
                        `Não encontramos o documento informado`,
                        'error'
                    )
                }
                setLoading(false)
            }).catch(error => {
                console.error(error)
                Swal.fire(
                    'Desculpe',
                    `Ocorreu algum erro ao tentar buscar o documento`,
                    'error'
                )
                setLoading(false)
            })
        }
    }, [ clinic ])

    useEffect(() => {
        if (document) {
            getPreview()
        }
    }, [ document, location ])

    const exams = document?.get("exams") || []
    const diseases = document?.get("diseases") || []
    const patient = document?.get("patient")
    const owner = patient?.get("owner")

    const messageParams = {
        user: Parse.User.current().id,
        clinic: clinic?.objectId,
        owner: owner?.id,
        patient: patient?.id,
        createdFromClass: "ExamRequest",
        createdFromId: document?.id,
        createdFromObject: {
            id: document?.id
        }
    }

    return (
        <div id="exam-share">
            <VSShare
                loading={loading}
                blocked={!canEdit}
                tag={MessageForm.current?.tag()}
                title={`Nova Solicitação de Exame`}
                subtitle={(
                    <div className="row align-items-center">
                        <div className="col-auto px-0">
                            <IconArrowLeft width="8px" height="12px" className="title-separator" />
                        </div>
                        <div className="col-auto">
                            <div className="title">Salvar e Visualizar Solicitação de Exame</div>
                        </div>
                    </div>
                )}
                close="Fechar"
                onClose={() => history.goBack()}
                summary={(
                    <>
                        <div className="row g-3 mb-3 align-items-center">
                            <div className="col-12">
                                <button className="btn-link text-start" onClick={() => history.push(`/animal/${patient.id}`)}>
                                    <IconArrowLeft width="8px" height="12px" />
                                    Voltar para o perfil do animal
                                </button>
                            </div>
                            <div className="col-12">
                                <h2 className="title">
                                    1 - Configure e Visualize seu Documento
                                </h2>
                            </div>
                        </div>
                        <div className="row g-3 mb-3 align-items-center">
                            <div className="col">
                                <h4 className="subtitle">Resumo da Solicitação de Exame</h4>
                            </div>
                            <div className="col-auto">
                                <button className="btn-link" onClick={() => history.push(`/animal/${patient.id}/solicitacao/${id_documento}`)}>
                                    <IconEdit width="18px" height="18px" />
                                    Editar <span className="d-none d-lg-inline-block">Solicitação de Exame</span>
                                </button>
                            </div>
                        </div>
                        <div className="row g-3 mb-3 align-items-center">
                            <div className="col-12 col-lg-auto">
                                <div className="label_icon">
                                    <IconExam height="24" width="24" />
                                    <span>
                                        { exams.length } exame{ exams.length == 1 ? "" : "s" } solicitado{ exams.length == 1 ? "" : "s" }
                                    </span>
                                </div>
                            </div>
                            <div className="col text-lg-center">
                                <div className="label_icon">
                                    <IconDisease height="24" width="24" />
                                    <span>
                                        { diseases.length } motivo{ diseases.length == 1 ? "" : "s" }/suspeita{ diseases.length == 1 ? "" : "s" }
                                    </span>
                                </div>
                            </div>
                            <div className="col-auto">
                                <div className="label_icon">
                                    <IconCalendar height="24" width="24" />
                                    <span>{ dayjs(document && document.get("documentDate")).format("DD/MM/YYYY") }</span>
                                </div>
                            </div>
                        </div>
                        <hr className="my-3" />
                        <div className="row g-3 align-items-center">
                            <div className="col-12 col-lg">
                                <h4 className="subtitle">Dados exibidos na Solicitação de Exame</h4>
                            </div>
                            <div className="col-12 col-lg-auto">
                                <div className="label_icon">
                                    <div className="row align-items-center">
                                        <div className="col-auto pe-lg-0">
                                            <IconCalendarCheck fill="var(--vsprontuario-primary-color)" height="24" width="24" />
                                            <span>Mostrar Data</span>
                                        </div>
                                        <div className="col ps-lg-2 pe-1 text-right">
                                            { loadingToggle ? (
                                                <CircularProgress size={24} style={{ animation: "none" }} className="py-1" />
                                            ) : (
                                                <Switch
                                                    checked={Boolean(document?.get("displayDate"))}
                                                    onChange={({ target: { checked } }) => toggleDisplayDate(checked)}
                                                    color="primary"
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div> 
                            </div>
                        </div>
                        <hr className="my-3" />
                        <div className="row g-3 align-items-center">
                            <div className="col">
                                <h4 className="subtitle">Configurações e layout</h4>
                            </div>
                            <div className="col-auto">
                                <button className="btn-link p-0" onClick={() => {
                                    history.push(`/clinica`)
                                    saveOnTracker("Acessou", "Clínica", "", "", "Atestado ou Termo", document.id, "", "")
                                }}>
                                    <IconDocument width="24" height="24" />
                                    Configurar
                                </button>
                            </div>
                        </div>
                        <hr className="my-3 d-none d-md-block" />
                        <div className="row g-3 align-items-center">
                            <h4 className="subtitle mb-3 d-none d-md-block">
                                Prévia do documento
                            </h4>
                        </div>
                    </>
                )}
                preview={(
                    <>
                        <div className="row">
                            <div className="col">
                                <div className="btn-tabs">
                                    <div
                                        className={`btn-tab ${previewType == 1 ? 'active' : ''}`}
                                        onClick={() => {
                                            setPreviewType(1)
                                            saveOnTracker("Selecionou", "Prévia Impressa", document.id, "", "Solicitação de Exame", document.id, "", "")
                                        }}
                                    >
                                        Prévia Impressão
                                    </div>
                                    <div
                                        className={`btn-tab ${previewType == 0 ? 'active' : ''}`}
                                        onClick={() => {
                                            setPreviewType(0)
                                            saveOnTracker("Selecionou", "Prévia Digital", document.id, "", "Solicitação de Exame", document.id, "", "")
                                        }}
                                    >
                                        Prévia Digital
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="preview">
                                    { loadingPreview ? (
                                        <div style={{ textAlign: "center", padding: '36px' }}>
                                            <CircularProgress size={28} thickness={2} color="primary"/>
                                        </div>
                                    ) : (
                                        <div
                                            dangerouslySetInnerHTML={{__html: preview}}
                                            className={previewType == 0 ? 'document-digital' : 'document-print'}
                                        >
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </>
                )}
                message={(
                    <>
                        { prefs && (
                            <VSMessage
                                ref={MessageForm}
                                pro={pro}
                                clinic={clinic.object}
                                history={history}
                                onMessageLimitError={(limit, isBanner) => <>Você chegou ao limite de <b>{ limit } Solicitações de Exames</b> enviadas digitalmente via WhatsApp, SMS e E-mail por mês{ isBanner ? "" : " do Prontuário Gratuito" }.</>}
                                onMessageLimitAdvice={(limit, isBanner) => <>Você tem direito a enviar <b>{ limit } Solicitações de Exames</b> via WhatsApp, SMS ou E-mail por mês{ isBanner ? "" : " do Prontuário Gratuito" }.</>}
                                title="2 - Selecione o que você deseja fazer ao emitir o documento"
                                name="Solicitações de Exame"
                                link={{
                                    text: "Link da Cópia Digital para envio manual:",
                                    url: link,
                                    urlToPDF: link_pdf,
                                    onClick: () => {
                                        saveOnTracker("Acessou", "Link Solicitação de Exame", document.id, "", "Solicitação de Exame", document.id, "", "")
                                    },
                                    onCopy: () => {
                                        saveOnTracker("Copiou", "Link Solicitação de Exame", document.id, "", "Solicitação de Exame", document.id, "", "")
                                    }
                                }}
                                onShare={() => {
                                    saveOnTracker("Compartilhou", "Solicitação de Exame", document.id, "", "Solicitação de Exame", document.id, "", "WhatsApp Web")
                                }}
                                onChange={() => {
                                    validate()
                                }}
                                owner={owner}
                                messages={[
                                    {
                                        title: "Imprimir Documento",
                                        checked: prefs?.get("printEnabled") || false,
                                        value: prefs?.get("printColorType") || 0,
                                        messageTypeId: "PRINT",
                                        onChange: (printColorType) => {
                                            prefs?.set("printColorType", printColorType)
                                            prefs?.save()
                                            .catch(error => {
                                                console.error(error)
                                                prefs?.revert()
                                            })
                                        },
                                        onChecked: (checked) => {
                                            prefs?.set("printEnabled", checked)
                                            prefs?.save()
                                            .catch(error => {
                                                console.error(error)
                                                prefs?.revert()
                                            })
                                        },
                                    },
                                    {
                                        title: "Baixar Documento em PDF",
                                        checked: prefs?.get("downloadEnabled") || false,
                                        messageTypeId: "DOWNLOAD",
                                        onChecked: (checked) => {
                                            prefs?.set("downloadEnabled", checked)
                                            prefs?.save()
                                            .catch(error => {
                                                console.error(error)
                                                prefs?.revert()
                                            })
                                        }
                                    },
                                    {
                                        ...messageParams,
                                        title: "Enviar Cópia Digital",
                                        checked: prefs?.get("whatsappEnabled") || prefs?.get("smsEnabled") || prefs?.get("emailEnabled") || false,
                                        messageTypeId: "SEND",
                                        messages: [
                                            {
                                                ...messageParams,
                                                title: "Enviar Solicitação de Exame por WhatsApp",
                                                checked: prefs?.get("whatsappEnabled") || false,
                                                hideTextField: true,
                                                messageTypeId: "WHATSAPP",
                                                messageTemplateId: "WHATSAPP_EXAM_REQUEST",
                                                withSeparator: true,
                                                onChecked: (checked) => {
                                                    prefs?.set("whatsappEnabled", checked)
                                                    prefs?.save()
                                                    .catch(error => {
                                                        console.error(error)
                                                        prefs?.revert()
                                                    })
                                                },
                                            },
                                            {
                                                ...messageParams,
                                                title: "Enviar Solicitação de Exame por SMS",
                                                checked: prefs?.get("smsEnabled") || false,
                                                messageTypeId: "SMS",
                                                messageTemplateId: "SMS_EXAM_REQUEST",
                                                withSeparator: true,
                                                onChecked: (checked) => {
                                                    prefs?.set("smsEnabled", checked)
                                                    prefs?.save()
                                                    .catch(error => {
                                                        console.error(error)
                                                        prefs?.revert()
                                                    })
                                                },
                                            },
                                            {
                                                ...messageParams,
                                                title: "Enviar Solicitação de Exame por E-mail",
                                                checked: prefs?.get("emailEnabled") || false,
                                                messageTypeId: "EMAIL",
                                                messageTemplateId: "EMAIL_EXAM_REQUEST",
                                                withSeparator: false,
                                                onChecked: (checked) => {
                                                    prefs?.set("emailEnabled", checked)
                                                    prefs?.save()
                                                    .catch(error => {
                                                        console.error(error)
                                                        prefs?.revert()
                                                    })
                                                },
                                            }
                                        ]
                                    }
                                ]}
                                appendMessages={MessageForm.current?.limit()}
                                submitButton={(
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="btn-sticky">
                                                <button 
                                                    className="btn-submit w-100"
                                                    disabled={errors.length != 0}
                                                    onClick={() => send()}
                                                >
                                                    { submitText() }
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            />
                        )}
                    </>
                )}
            />
        </div>
    )
}

const mapStateToProps = state => {
    return {
        pro: state.featuresPro,
        user: state.user,
        clinic: state.clinic,
        permissions: state.permissions
    };
};

export default connect(mapStateToProps)(ExamShare)