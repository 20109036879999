import React, { useState } from 'react'
import { connect } from 'react-redux'

import { TableCell, TableRow } from '@mui/material'

import HomeData from './HomeData'
import { BoxOwnerPatientAnniversary } from '../owner/BoxOwnerPatient'

import { dayjs } from '../../utils'
import { ReactComponent as IconCake } from "../../assets/icon-cake.svg"

import './HomeBirthdate.scss'
const HomeBirthdate = (props) => {

    const {
        clinic,
        expanded
    } = props

    const [ loading, setLoading ] = useState(true)
    const [ patients, setPatients ] = useState([])

    const birthMonth = dayjs().format("MM")
    const birthday = dayjs().format("MMDD")

    const rowsPerPage = 5

    return (
        <div id="home-birthdate-data" className="col-12 mb-3">
            <HomeData
                expanded={expanded}
                headerIcon={<IconCake width="24px" height="24px" fill="#B3B3B3" />}
                title="Aniversariantes do mês"
                emptyText="Nenhum animal está fazendo aniversário este mês"
                loading={loading}
                data={patients}
                rowsPerPage={rowsPerPage}
                onChange={_ => {
                    if (clinic && clinic.isLoaded && clinic.object) {
                        setPatients([])
                        setLoading(true)

                        const query = new Parse.Query("Patient");
                        query.equalTo("isDeleted", false)
                        query.equalTo("clinic", clinic.object)
                        query.startsWith("birthday", birthMonth)
                        query.ascending("birthday")
                        query.addAscending("name")
                        query.addAscending("objectId")
                        query.withCount()
                        query.skip(0)
                        query.find()
                        .then(_patients => {
                            _patients.results.sort((a, b) => {
                                if (a.get('birthday') == birthday) {
                                    if(a.get('birthday') == b.get('birthday')){
                                        return a.get('name').localeCompare(b.get('name'))
                                    }
                                    return -1
                                }
                                
                                return 0;
                            })
                            setPatients(_patients)
                            setLoading(false)
                        })
                        .catch(error => {
                            console.error(error)
                            setLoading(false)
                        })
                    }
                }}
                renderItem={(patient, index) => {
                    return (
                        <TableRow
                            key={index}
                        >
                            <TableCell className={patient.get('birthday') == birthday ? 'highlight' : ''}>
                                {patient.get('birthday') == birthday && index == 0 &&
                                <div className="highlight-title">Fazem aniversário hoje:</div>}
                                <BoxOwnerPatientAnniversary 
                                    patient={patient} 
                                    {...props} 
                                />
                            </TableCell>
                        </TableRow>
                    )
                }}
            />
        </div>
    )
}

const mapStateToProps = state => {
    return {
        clinic: state.clinic
    };
};
  
export default connect(mapStateToProps)(HomeBirthdate);