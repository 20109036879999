import { APP_DEVICE, APP_NAME, APP_VERSION } from "../../../../constants"
import { getSelectedOptionLabel } from "../../../../utils"

export const TERMO_OBITO = "0"
export const ATESTADO_SANITARIO = "1"
export const TERMO_AUTORIZACAO_EXAMES = "2"
export const TERMO_INTERNACAO = "3"
export const TERMO_AUTORIZACAO_PROCEDIMENTO_CIRURGICO = "4"
export const TERMO_AUTORIZACAO_PROCEDIMENTO_TERAPEUTICO = "5"
export const TERMO_AUTORIZACAO_EUTANASIA = "6"
export const TERMO_LIBERACAO = "7"
export const TERMO_AUTORIZACAO_PROCEDIMENTO_ANESTESICO = "8"
export const ATESTADO_VACINACAO = "9"
export const CARTA_ENCAMINHAMENTO = "10"
export const TERMO_CONSENTIMENTO_TELEMEDICINA = "11"
export const TERMO_BRANCO = "12"

export const deleteTerm = (term, callback) => {
    Swal.fire({
        title: 'Tem certeza?',
        text: "Você tem certeza que deseja excluir esse atestado ou termo? Essa ação não poderá ser desfeita.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, quero excluir!',
        cancelButtonText: 'Não, manter documento'
    }).then((result) => {
        if (result.isConfirmed) {
            term.set("isDeleted", true)
            term.set("changedAt", new Date())
            term.set("changedBy", Parse.User.current())
            term.set("changedApp", APP_NAME)
            term.set("changedDevice", APP_DEVICE)
            term.set("changedAppVersion", APP_VERSION)
            term.save()
            .then(_ => {
                Swal.fire(
                    'Excluído!',
                    'O atestado ou termo foi excluída com sucesso.',
                    'success'
                )
                .then(_ => {
                    callback && callback()
                })
            })
            .catch(error => {
                console.error(error)
                Swal.fire(
                    'Desculpe',
                    'Ocorreu algum erro ao tentar excluir o atestado ou termo',
                    'error'
                )
                term.revert()
            })
        }
    })
}

export const getTerm = (id, patient) => {
    return new Promise((resolve, reject) => {
        var query = new Parse.Query("Term");
        query.equalTo("clinic", patient.get("clinic"));
        query.equalTo("isDeleted", false);
        query.equalTo("patient", patient)
        query.get(id)
        .then(_object => {
            if (_object) {
                resolve(_object)
            } else {
                reject('Não encontramos o atestado ou termo informado')
            }
        }).catch(error => {
            console.error(error)
            reject('Ocorreu algum erro ao tentar buscar o atestado ou termo')
        })
    })
}

export const showTerm = (term) => {
    let termText = getSelectedOptionLabel({ value: term.get("documentType") }, documentTypeOptions, term.get("documentType"))
    if (term.get("documentType") == CARTA_ENCAMINHAMENTO) {
        termText += ` ${term.get("documentSubType") == 1 ? "Detalhada" : "Simples"}`
    }
    if (term.get("documentType") == TERMO_BRANCO) {
        termText = term.get("documentName")
    }
    return termText
}

export const documentTypeOptions = [
    {
        value: "0",
        label: "Termo de Óbito"
    },
    {
        value: "1",
        label: "Atestado Sanitário"
    },
    {
        value: "9",
        label: "Atestado de Vacinação"
    },
    {
        value: "10",
        label: "Carta de Encaminhamento"
    },
    {
        value: "6",
        label: "Termo para Realização de Eutanásia"
    },
    {
        value: "2",
        label: "Termo para Realização de Exames"
    },
    {
        value: "3",
        label: "Termo de Internação e Tratamento Clínico ou Pós-Cirúrgico"
    },
    {
        value: "8",
        label: "Termo para Realização de Procedimentos Anestésicos"
    },
    {
        value: "4",
        label: "Termo para Realização de Procedimento Cirúrgicos"
    },
    {
        value: "5",
        label: "Termo para Realização de Procedimento Terapêuticos de Risco"
    },
    {
        value: "7",
        label: "Termo para Retirada de Animal do Serviço Veterinário sem Alta Médica"
    },
    {
        value: "11",
        label: "Termo de Consentimento Livre e Esclarecido para Telemedicina Veterinária"
    }
]

export const defaultProcedure = {
    procedure: "",
    notes: ""
}

export const defaultExam = {
    exam: "",
    notes: ""
}

export const defaultDeworming = {
    productName: "",
    documentDate: null
}

export const defaultVaccine = {
    checked: false,
    id: null,
    vaccineType: "",
    vaccine: "",
    companyName: "",
    manufacturingBatchPart: "",
    manufacturingDate: null,
    expirationDate: null,
    documentDate: null
}

export const defaultItems = {
    title: "",
    description: ""
}