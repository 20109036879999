import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom'

import { CircularProgress, TextField } from '@mui/material';
import Autocomplete from '@mui/lab/Autocomplete';

import {
    APP_NAME,
    APP_DEVICE,
    APP_VERSION,
    ACL
} from '../../constants'
import { 
    addEvent,
    saveOnTracker
} from '../../utils'
import { getSupplies } from './SupplyTransaction';
import { getStockSupply, getUnits } from './Supply';

import VSAccordionSimple from '../../components/vsAccordion/VSAccordionSimple';
import VSDrawer from '../../components/vsDrawer/VSDrawer';
import VSError from '../../components/vsError/VSError';
import VSRadio from '../../components/vsRadio/VSRadio';
import VSTextFieldCurrency from '../../components/vsTextFieldCurrency/VSTextFieldCurrency';

// Note: id_insumo = objectId, supplyId = user setted field supplyId
import "./SupplyTransactionForm.scss"
const SupplyTransactionForm = (props) => {

    const {
        id_insumo,
        tipo_transacao
    } = useParams()

    const {
        clinic,
        history,
        permissions
    } = props

    const hasDefaultSupply = id_insumo !== 'movimentacao'

    const [ units, setUnits ] = useState([])
    const [ loading, setLoading ] = useState(false)
    const [ error, setError ] = useState(null)
    const [ saveLoading, setSaveLoading ] = useState(false)
    const [ supplyTransaction, setSupplyTransaction ] = useState(null)

    const [ supply, setSupply ] = useState(null)
    const [ errorSupply, setErrorSupply ] = useState(false)
    const [ helperSupply, setHelperSupply ] = useState(<span>Campo <b>Obrigatório</b></span>)

    const [ quantity, setQuantity ] = useState(null)
    const [ errorQuantity, setErrorQuantity ] = useState(false)
    const [ helperQuantity, setHelperQuantity ] = useState(<span>Campo <b>Obrigatório</b></span>)

    const [ notes, setNotes ] = useState('')

    const [ supplyOptions, setSupplyOptions ] = useState([]);

    const supplyValueForAutocomplete = supply => ((supply?.get('supplyId') ? supply?.get('supplyId') + ' - ' : '') + supply?.get('supplyName')) || ""

    const handleSupply = (selected) => {
        const supply = selected
        setSupply(supply)
    }

    const validateOnTouch = () => {
        if (notes || supply || quantity) {
            validate()
        }
    }

    const validate = () => {
        let isValid = true
        if (loading) {
            isValid = false
        }
        if (saveLoading) {
            isValid = false
        }
        if (error?.message) {
            isValid = false
        }

        if (!supply?.id) {
            isValid = false
            setErrorSupply(true)
            setHelperSupply("O insumo é obrigatório")
        } else {
            setErrorSupply(false)
            setHelperSupply(<span>Campo <b>Obrigatório</b></span>)
        }

        if (!quantity) {
            isValid = false
            setErrorQuantity(true)
            setHelperQuantity("A quantidade é obrigatória")
        } else if (Number(quantity) <= 0) {
            isValid = false
            setErrorQuantity(true)
            setHelperQuantity("A quantidade precisa ser maior que 0 (zero)")
        } else {
            setErrorQuantity(false)
            setHelperQuantity(<span>Campo <b>Obrigatório</b></span>)
        }

        return isValid
    }

    const isValid = () => !loading && !saveLoading && !errorSupply && !errorQuantity

    const fieldsWithErrorText = () => {
        let errors = []
        if (loading) {
            errors.push("Estamos procurando a " + (tipo_transacao == 'entrada' ? "entrada" : "saída"))
        }
        if (saveLoading) {
            errors.push("Salvando a " + (tipo_transacao == 'entrada' ? "entrada" : "saída"))
        }
        if (error?.message) {
            errors.push(error.message)
        }
        if (errorSupply) {
            errors.push("Insumo")
        }
        if (errorQuantity) {
            errors.push("Quantidade")
        }
        if (!permissions?.check(ACL.SUPPLY_TRANSACTION, ACL.Rule.EDIT, supplyTransaction)) {
            errors.push("Acesso negado")
        }
        return errors
    }

    const save = () => {
        return new Promise((resolve, reject) => {
            if (validate()) {
                setSaveLoading(true)
                const parsedQuantity = Math.round(parseFloat(quantity) * 100) / 100
                supplyTransaction.set("supply", supply);
                supplyTransaction.set("quantity", tipo_transacao == "entrada" ? parsedQuantity : parsedQuantity * -1);
                supplyTransaction.set("notes", notes);
                supplyTransaction.set("changedAt", new Date())
                supplyTransaction.set("changedBy", Parse.User.current())
                supplyTransaction.set("changedApp", APP_NAME)
                supplyTransaction.set("changedDevice", APP_DEVICE)
                supplyTransaction.set("changedAppVersion", APP_VERSION)
                supplyTransaction.save()
                .then(_supplyTransaction => {
                    saveOnTracker("Concluiu", (tipo_transacao == 'entrada' ? "Entrada" : "Saída") + "de Insumo", _supplyTransaction.id)
                    setSaveLoading(false)
                    resolve(_supplyTransaction)
                })
                .catch(error => {
                    setSaveLoading(false)
                    console.error(error)
                    Swal.fire(
                        'Desculpe',
                        `Ocorreu algum erro ao tentar cadastrar a ${tipo_transacao == 'entrada' ? "entrada" : "saída"} do insumo`,
                        'error'
                    )
                    supplyTransaction.revert()
                    reject()
                });
            } else {
                reject()
            }
        })
    }

    const getSupplyTransactionOrError = () => {
        if (permissions?.check(ACL.SUPPLY_TRANSACTION, ACL.Rule.VIEW)) {
            setLoading(true)
            setError(null)
            
            let newSupplyTransaction = new Parse.Object("MRSupplyTransaction")
            newSupplyTransaction.set("clinic", clinic.object)
            newSupplyTransaction.set("transactionDate", new Date())
            newSupplyTransaction.set("createdBy", Parse.User.current())
            newSupplyTransaction.set("createdApp", APP_NAME)
            newSupplyTransaction.set("createdDevice", APP_DEVICE)
            newSupplyTransaction.set("createdAppVersion", APP_VERSION)
            if(hasDefaultSupply){
                setLoading(true)
                setError(null)
                getStockSupply(id_insumo, clinic.object)
                .then(_supply => {
                    setSupply(_supply)
                    setSupplyTransaction(newSupplyTransaction)
                    setLoading(false)
                })
            } else {
                setSupplyTransaction(newSupplyTransaction)
                setLoading(false)
            }
        }
    }

    useEffect(() => {
        validateOnTouch()
    }, [ supply, quantity ])

    useEffect(() => {
        if (supplyTransaction && !permissions?.check(ACL.SUPPLY_TRANSACTION, ACL.Rule.EDIT, supplyTransaction)) {
            permissions?.error()
        }
    }, [ supplyTransaction ])

    useEffect(() => {
        if (clinic && clinic.isLoaded && clinic.object) {
            saveOnTracker("Iniciou", "Insumo", hasDefaultSupply ? id_insumo : "")
            getUnits().then(_units => setUnits(_units))
            getSupplies(clinic.object).then(_supplies => setSupplyOptions(_supplies))
            if (id_insumo != "selecionar") {
                getSupplyTransactionOrError();
            }
        }
    }, [ clinic ])

    const errors = fieldsWithErrorText()

    return (
        <VSDrawer
            title={tipo_transacao == "entrada" ? "Entrada de Insumo" : "Saída de Insumo"}
            cancel="Cancelar"
            submit={isValid ? ( tipo_transacao == "entrada" ? "Adicionar insumo" : "Retirar insumo" ) : <span>Preencha os campos<span className="d-none d-md-inline"> necessários</span></span>}
            errors={errors}
            onSubmit={save}
            onCancel={() => history.goBack()}
            onAfterSave={(object) => {
                history.replace(`/insumo`)
                addEvent("Supply__getList", {})
            }}
            VSDrawerSuccessProps={{
                title: (tipo_transacao == "entrada" ? "Entrada" : "Saída") + " do insumo realizado com sucesso!" ,
                text: ""
            }} 
            VSDrawerCancelProps={{
                title: tipo_transacao == "entrada" ? "Tem certeza que deseja cancelar a nova entrada de insumo?" : "Tem certeza que deseja cancelar a nova saída de insumo?",
                confirm: tipo_transacao == "entrada" ? "Cancelar entrada" : "Cancelar saída",
                cancel: tipo_transacao == "entrada" ? "Voltar para Edição da Entrada" : "Voltar para Edição da Saída"
            }}
            VSDrawerFooterProps={{
                show: true
            }}
        >
            { loading ? (
                <div className="row data-loading">
                    <div className="col">
                        <CircularProgress />
                    </div>
                </div>
            ) : error?.message ? (
                <VSError
                    message={error.message}
                    onClose={() => {
                        switch(error.function) {
                            case "getSupplyTransactionOrError":
                                getSupplyTransactionOrError()
                                break
                            default:
                        }
                    }}
                />
            ) : (
                <>
                    { supplyTransaction && (
                        <>
                            <div className="row mt-2">
                                <div className="col">
                                    <div className="row">
                                        <div className="col-12 mb-3">
                                            <Autocomplete
                                                value={supply?.get("supplyName")}
                                                options={supplyOptions}
                                                getOptionLabel={(option) => option?.id ? option.get("supplyName") : supplyOptions?.find(s => s.id == supply.id)?.get("supplyName") || ""}
                                                isOptionEqualToValue={(option, value) => option.id == value.id || option.id == supply?.id}
                                                onChange={(_, selected) => handleSupply(selected)}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        placeholder="Selecione ou Digite o ID ou o Nome do Insumo"
                                                        label="Selecione ou Digite o ID ou o Nome do Insumo"
                                                        error={errorSupply}
                                                        helperText={helperSupply}
                                                        size="small"
                                                        variant="outlined"
                                                        fullWidth
                                                    />
                                                )}
                                                openOnFocus
                                            />
                                        </div>
                                        <div className="col-6 col-md-4 mb-3">
                                            <div className="row align-items-center mb-3">
                                                <div className="col-12">
                                                    <div className="input-subtitle mb-1">
                                                        Quantidade de {tipo_transacao == "entrada" ? "Entrada" : "Saída"}
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <VSTextFieldCurrency
                                                        value={quantity}
                                                        hideSymbol
                                                        onChangeValue={(event, originalValue, maskedValue) => {
                                                            setQuantity(originalValue)
                                                        }}
                                                        InputElement={(
                                                            <TextField
                                                                type="tel"
                                                                placeholder="0,00"
                                                                label=""
                                                                error={errorQuantity}
                                                                helperText={helperQuantity}
                                                                size="small"
                                                                variant="outlined"
                                                                fullWidth
                                                            />
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        { supply?.id && (
                                            <div className="col">
                                                <div className="row align-items-center mb-3">
                                                    <div className="col-12">
                                                        <div className="input-subtitle mb-1">
                                                            Unidade de Medida:
                                                        </div>
                                                    </div>
                                                    <div className="col" style={{ paddingTop: "8px" }}>
                                                        { units.find(u => u.id == supply?.get("unit"))?.name || "Não informado" }
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <hr className="my-2" />
                            <div className="row">
                                <div className="col">
                                    <VSAccordionSimple
                                        expanded={true}
                                        header={(_) => (
                                            `Descrição da ${tipo_transacao == 'entrada' ? 'Entrada' : 'Saída'} do Insumo`
                                        )}
                                    >
                                        <div className="row">
                                            <div className="col-12">
                                                <TextField
                                                    placeholder={`Digite uma descrição sobre a ${tipo_transacao == 'entrada' ? 'entrada' : 'saída'} desse insumo do estoque`}
                                                    label={`Descrição da ${tipo_transacao == 'entrada' ? 'entrada' : 'saída'}`}
                                                    value={notes}
                                                    onChange={({ target: { value } }) => setNotes(value)}
                                                    helperText={<span>Campo Opcional<br/></span>}
                                                    size="small"
                                                    variant="outlined"
                                                    multiline
                                                    fullWidth
                                                />
                                            </div>
                                        </div>
                                    </VSAccordionSimple>
                                </div>
                            </div>
                        </>
                    )}
                </>
            )}
        </VSDrawer>
    )
}

const mapStateToProps = state => {
    return {
        clinic: state.clinic,
        permissions: state.permissions
    };
};

export default connect(mapStateToProps)(SupplyTransactionForm);