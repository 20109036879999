import React, { useEffect, useRef, useState } from "react"
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { useParams } from 'react-router-dom';
import { connect } from "react-redux"
import { RRule } from "rrule";

import { CircularProgress, TextField } from "@mui/material"
import Autocomplete from '@mui/material/Autocomplete';

import { CALENDAR_BLANK, CALENDAR_BLOCK, getAlertFromProfessionalAgenda, getCalendar, getProfessionals, timeOptions } from "./Calendar";
import { addEvent, dayjs, getAmericaFortalezaDate, mandatoryField, optionalField, saveOnTracker, setAmericaFortalezaTimezone, validateDate, validateTime } from "../../utils";
import { ACL, APP_DEVICE, APP_NAME, APP_VERSION } from "../../constants";
import { RECURRENCE_THIS } from "../recurrence/Recurrence";
import { STATUS_FUTURE } from "../pro/Payment";

import { ReactComponent as IconCalendarCheck } from "../../assets/icon-calendar-check.svg"

import VSAccordionSimple from "../../components/vsAccordion/VSAccordionSimple"
import TextFieldMask from "../../components/textFieldMask/TextFieldMask";
import DateInput from "../../components/dateInput/DateInput";
import VSDrawer from "../../components/vsDrawer/VSDrawer"
import VSError from "../../components/vsError/VSError";
import RecurrenceForm from "../recurrence/RecurrenceForm";
import VSCheckbox from "../../components/vsCheckbox/VSCheckbox";
import VSRadio from "../../components/vsRadio/VSRadio";

import "./CalendarEventForm.scss"
const CalendarEventForm = (props) => {

    const {
        id_agendamento,
        tipo
    } = useParams()

    const {
        state
    } = useLocation()

    const {
        pro,
        clinic,
        history,
        permissions
    } = props

    const type = tipo == "branco" ? CALENDAR_BLANK : CALENDAR_BLOCK

    const id = state?.id
    const date = dayjs(state?.date || new Date()).hour(0).minute(0).second(0)

    const update = id_agendamento != "novo"
    const isPro = pro?.access || false

    const recurrenceForm = useRef(null)

    const [ loading, setLoading ] = useState(true)
    const [ error, setError ] = useState(null)
    const [ saveLoading, setSaveLoading ] = useState(false)
    const [ calendar, setCalendar ] = useState(null)

    const [ professionalsOptions, setProfessionalsOptions ] = useState([])

    const [ startDate, setStartDate ] = useState(date.toDate())
    const [ startDateText, setStartDateText ] = useState(date.format("DD/MM/YYYY"))
    const [ startTime, setStartTime ] = useState(state?.start || "")
    const [ endDate, setEndDate ] = useState(date.toDate())
    const [ endDateText, setEndDateText ] = useState(date.format("DD/MM/YYYY"))
    const [ endTime, setEndTime ] = useState(state?.end || "")
    const [ fullTime, setFullTime ] = useState(false)
    const [ backupStartTime, setBackupStartTime ] = useState(state?.start || "")
    const [ backupEndTime, setBackupEndTime ] = useState(state?.end || "")
    const [ errorPeriod, setErrorPeriod ] = useState(false)
    const [ helperPeriod, setHelperPeriod ] = useState(mandatoryField)

    const [ title, setTitle ] = useState("")
    const [ errorTitle, setErrorTitle ] = useState(false)
    const [ helperTitle, setHelperTitle ] = useState(mandatoryField)

    const [ category, setCategory ] = useState("")

    const [ professional, setProfessional ] = useState("")
    const [ errorProfessional, setErrorProfessional ] = useState(false)
    const [ helperProfessional, setHelperProfessional ] = useState(mandatoryField)
    const [ alertProfessional, setAlertProfessional ] = useState("")

    const [ notes, setNotes ] = useState("")

    const [ activeRecurrence, setActiveRecurrence ] = useState(false)
    const [ errorRecurrence, setErrorRecurrence ] = useState(false)
    const [ helpersRecurrence, setHelpersRecurrence ] = useState([])

    const [ recurrenceType, setRecurrenceType ] = useState(RECURRENCE_THIS)

    const [ rrule, setRrule ] = useState({})
    const [ singleUpdate, setSingleUpdate ] = useState("1")

    // https://petlove.slack.com/archives/C0664B149T5/p1701455753631139
    const [ hasSubmited, setHasSubmited ] = useState(true)

    const allProfessionals = new Parse.Object("_User")
    allProfessionals.id = 1
    allProfessionals.set("fullName", "Todos os profissionais")

    const options = [
        "Agendamento",
        "Folga",
        "Almoço",
        "Férias",
        "Afastamento",
        "Feriado",
        "Aniversário",
        "Outro"
    ]

    const validateOnTouch = () => {
        if (hasSubmited) {
            validate()
        }
    }

    const validate = () => {
        let isValid = true

        const startDocumentDate = dayjs(startDate)
        const endDocumentDate = dayjs(endDate)
        const nextFiveYears = dayjs().add(5, 'years')
        const previousFiveYears = dayjs().subtract(5, 'years')
        if (!startDocumentDate.isValid()) {
            isValid = false
            if (validateTime(startTime)) {
                setErrorPeriod(true)
                setHelperPeriod("A data inicial não é válida")
            } else {
                setErrorPeriod(true)
                setHelperPeriod("O horário de início não é válido")
            }
        } else if (!endDocumentDate.isValid()) {
            isValid = false
            if (validateTime(endTime)) {
                setErrorPeriod(true)
                setHelperPeriod("A data final não é válida")
            } else {
                setErrorPeriod(true)
                setHelperPeriod("O horário de término não é válido")
            }
        } else if (startDocumentDate < previousFiveYears) {
            isValid = false
            setErrorPeriod(true)
            setHelperPeriod("A data inicial não pode ser menor que 5 anos")
        } else if (startDocumentDate > nextFiveYears) {
            isValid = false
            setErrorPeriod(true)
            setHelperPeriod("A data inicial não pode ser maior que 5 anos")
        } else if (endDocumentDate < previousFiveYears) {
            isValid = false
            setErrorPeriod(true)
            setHelperPeriod("A data final não pode ser menor que 5 anos")
        } else if (endDocumentDate > nextFiveYears) {
            isValid = false
            setErrorPeriod(true)
            setHelperPeriod("A data final não pode ser maior que 5 anos")
        } else if (startDocumentDate > endDocumentDate) {
            isValid = false
            setErrorPeriod(true)
            setHelperPeriod("A data inicial não pode ser maior que a data final")
        } else {
            setErrorPeriod(false)
            setHelperPeriod(mandatoryField)
        }

        if (title) {
            setErrorTitle(false)
            setHelperTitle(mandatoryField)
        } else {
            isValid = false
            setErrorTitle(true)
            setHelperTitle("O título é obrigatório")
        }

        if (professional?.id) {
            setErrorProfessional(false)
            setHelperProfessional(mandatoryField)
        } else {
            isValid = false
            setErrorProfessional(true)
            setHelperProfessional("É necessário um profissional para o evento")
        }

        const recurrenceFormErrors = recurrenceForm.current?.validate([])
        if (activeRecurrence && recurrenceFormErrors?.length > 0) {
            isValid = false
            setErrorRecurrence(true)
            setHelpersRecurrence(recurrenceFormErrors)
        } else {
            setErrorRecurrence(false)
            setHelpersRecurrence([])
        }

        return isValid
    }

    const isValid = () => !loading && !saveLoading && !errorPeriod && !errorTitle && !errorProfessional && !errorRecurrence

    const fieldsWithErrorText = () => {
        let errors = []
        if (loading) {
            errors.push("Estamos procurando o evento")
        }
        if (saveLoading) {
            errors.push("Salvando o evento")
        }
        if (error?.message) {
            errors.push(error.message)
        }
        if (errorTitle) {
            errors.push("Título do evento")
        }
        if (errorPeriod) {
            errors.push("Data/hora do evento")
        }
        if (errorProfessional) {
            errors.push("Profissional responsável")
        }
        if (errorRecurrence) {
            errors.push("Recorrência do evento")
        }
        if (!permissions?.check(ACL.CALENDAR, ACL.Rule.EDIT, calendar)) {
            errors.push("Acesso negado")
        }
        return errors
    }

    const save = () => {
        setHasSubmited(true)
        return new Promise((resolve, reject) => {
            if (validate()) {
                Parse.Cloud.run("saveCalendarForClinic", {
                    data: {
                        objectId: update ? calendar.id : null,
                        status: type,
                        title: title,
                        category: category,
                        startDate: getAmericaFortalezaDate(startDate),
                        endDate: getAmericaFortalezaDate(endDate),
                        recurrence: activeRecurrence ? recurrenceForm.current?.rrule() : undefined,
                        notes: notes,
                        userId: professional.id == 1 ? undefined : professional.id,
                    },
                    recurrenceType: recurrenceType,
                    clinicId: clinic.objectId,
                    appVersionName: APP_VERSION,
                    deviceType: APP_DEVICE,
                    appContentType: APP_NAME
                })
                .then(calendars => {
                    setCalendar(calendars[0])
                    addEvent("Calendar__getList")
                    resolve(calendars)
                })
                .catch(error => {
                    setSaveLoading(false)
                    console.error(error)
                    Swal.fire(
                        'Desculpe',
                        `Ocorreu algum erro ao tentar ${update ? "editar" : "cadastrar"} o evento`,
                        'error'
                    )
                    reject()
                })
            } else {
                reject()
            }
        })
    }

    const getCalendarOrError = () => {
        if (permissions?.check(ACL.CALENDAR, ACL.Rule.VIEW)) {
            if (update) {
                setLoading(true)
                setError(null)

                getCalendar(id_agendamento, clinic.object)
                .then(_calendar => {
                    setCalendar(_calendar)
                    setTitle(_calendar.get("title"))
                    setCategory(_calendar.get("category"))
                    setStartDateText(dayjs(setAmericaFortalezaTimezone(_calendar.get("startDate"))).format("DD/MM/YYYY"))
                    setStartTime(dayjs(setAmericaFortalezaTimezone(_calendar.get("startDate"))).format("HH:mm"))
                    setEndTime(dayjs(setAmericaFortalezaTimezone(_calendar.get("endDate"))).format("HH:mm"))
                    setNotes(_calendar.get("notes"))
                    if (dayjs(_calendar.get("startDate")).format("HHmm") == "0000" && dayjs(_calendar.get("endDate")).format("HHmm") == "2359") {
                        setFullTime(true)
                    }
                    setProfessional(_calendar.get("user") || allProfessionals)
                    if (_calendar.get("recurrence")) {
                        try {
                            var activeRecurrence = true
                            const rule = RRule.fromString(_calendar.get("recurrence"))
                            const allDates = rule.all((_, i) => i <= 100)
                            if (rule?.options?.count) {
                                const currentCount = allDates.findIndex(date => date?.getTime() == _calendar.get("startDate")?.getTime())
                                if (currentCount != -1) {
                                    rule.options.count -= currentCount
                                }
                                if (rule.options.count == 1)
                                    activeRecurrence = false // não exibe recorrência com 0 execuções
                            }
                            if (activeRecurrence) {
                                setRrule(rule?.options)
                                setActiveRecurrence(true)
                            }
                        } catch(error){
                            console.error(error)
                        }
                    }
                    setLoading(false)
                })
                .catch(error => {
                    Swal.fire(
                        'Desculpe',
                        error,
                        'error'
                    )
                    .then(_ => {
                        setLoading(false)
                        setError({
                            message: error,
                            function: "initializeOrError"
                        })
                    })
                })
            } else {
                let newCalendar = new Parse.Object("MRCalendar")
                newCalendar.setACL(clinic.ACL)
                newCalendar.set("clinic", clinic.object)
                newCalendar.set("createdBy", Parse.User.current())
                newCalendar.set("createdApp", APP_NAME)
                newCalendar.set("createdDevice", APP_DEVICE)
                newCalendar.set("createdAppVersion", APP_VERSION)
                newCalendar.set("isDeleted", false)
                setCalendar(newCalendar)
                setLoading(false)
            }
        }
    }

    useEffect(() => {
        validateOnTouch()
    }, [
        startDate, endDate, title, professional
    ])

    useEffect(() => {
        if (calendar && !permissions?.check(ACL.CALENDAR, ACL.Rule.EDIT, calendar)) {
            permissions?.error()
        }
    }, [ calendar ])

    const initializeOrError = () => {
        setLoading(true)

        Promise.all([
            getProfessionals(clinic.object),
            getCalendarOrError()
        ])
        .then(([
            _professionals,
            _calendar
        ]) => {
            if (type == CALENDAR_BLOCK) {
                _professionals.push(allProfessionals)
            }

            setProfessionalsOptions(_professionals)
            setCalendar(_calendar)

            const _professional = _professionals.filter(_user => _user.id == state?.professionalId)?.[0]
            if (professional) {
                setProfessional(_professional)
            }
        })
        .catch(error => {
            console.error(error)
            Swal.fire(
                'Desculpe',
                error,
                'error'
            )
            .then(_ => {
                setLoading(false)
                setError({
                    message: error,
                    function: "initializeOrError"
                })
            })
        })
    }

    useEffect(() => {
        getAlertFromProfessionalAgenda(professional, clinic.object, startDate, endDate, calendar?.id)
        .then(message => setAlertProfessional(message))
    }, [ professional, startDate, endDate ])

    useEffect(() => {
        if (clinic && clinic.isLoaded && clinic.object) {
            saveOnTracker("Iniciou", "Agendamento", update ? id_agendamento : "")

            initializeOrError()
        }
    }, [ clinic ])

    useEffect(() => {
        setStartDate(validateDate(startDateText))
    }, [ startDateText ])

    useEffect(() => {
        setEndDate(validateDate(endDateText))
    }, [ endDateText ])

    useEffect(() => {
        if (validateTime(startTime)) {
            const [
                hours,
                minutes
            ] = startTime.split(":")
            setStartDate(dayjs(validateDate(startDateText)).hour(hours).minute(minutes).second(0).toDate())
        } else {
            setStartDate(null)
        }
    }, [ startTime ])

    useEffect(() => {
        if (validateTime(endTime)) {
            const [
                hours,
                minutes
            ] = endTime.split(":")
            setEndDate(dayjs(validateDate(endDateText)).hour(hours).minute(minutes).second(0).toDate())
        } else {
            setEndDate(null)
        }
    }, [ endTime ])

    useEffect(() => {
        if (fullTime) {
            setBackupStartTime(startTime)
            setBackupEndTime(endTime)
            setStartTime("00:00")
            setEndTime("23:59")
        } else {
            setStartTime(backupStartTime)
            setEndTime(backupEndTime)
        }
    }, [ fullTime ])

    const errors = fieldsWithErrorText()

    const textType = type == CALENDAR_BLANK ? "Evento em Branco" : "Bloqueio de Agenda"

    return (
        <VSDrawer
            id="calendar-event-form"
            width="50%"
            title={update ? `Editar ${textType}` : `Novo ${textType}`}
            errors={errors}
            onSubmit={save}
            onCancel={() => history.goBack()}
            VSDrawerSuccessProps={{
                title: update ? "Alterações salvas com sucesso!" : "Cadastro realizado com sucesso!",
                text: "Escolha uma das opções abaixo para prosseguir",
                children: (
                    <div className="calendar-actions">
                        <div className="row g-3">
                            <div className="col-12">
                                <button
                                    className="btn-outline-submit btn-square w-100 d-block"
                                    onClick={() => history.replace(`/agenda`)}
                                >
                                    Ir para a agenda
                                </button>
                            </div>
                            <div className="col-12">
                                <button
                                    className="btn-submit btn-square w-100"
                                    onClick={() => history.replace(`/animal/selecionar/agenda`)}
                                >
                                    Cadastrar um novo evento
                                </button>
                            </div>
                        </div>
                    </div>
                )
            }}
        >
            {loading ? (
                <div className="row data-loading">
                    <div className="col">
                        <CircularProgress />
                    </div>
                </div>
            ) : error?.message ? (
                <VSError
                    message={error.message}
                    onClose={() => {
                        switch (error.function) {
                            case "initializeOrError":
                                initializeOrError()
                                break
                            default:
                        }
                    }}
                />
            ) : (
                <>
                    <div className="row mt-2">
                        <div className="col">
                            <VSAccordionSimple
                                expanded={true}
                                header={(_) => (
                                    "Dados do evento"
                                )}
                            >
                                <div className="row g-3 mb-3">
                                    <div className="col-12">
                                        <Autocomplete
                                            inputValue={title}
                                            options={type == CALENDAR_BLOCK ? options : []}
                                            onChange={(_, selected) => {
                                                setTitle(selected || "")
                                                if (selected) {
                                                    setCategory(selected)
                                                }
                                            }}
                                            onInputChange={(event, selected) => {
                                                if (event){
                                                    setTitle(selected)
                                                }
                                            }}
                                            isOptionEqualToValue={(option) => option == title}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    placeholder="Ex: Happy hour com equipe"
                                                    label="Título do evento"
                                                    error={errorTitle}
                                                    helperText={helperTitle}
                                                    size="small"
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                            )}
                                            openOnFocus
                                            freeSolo
                                        />
                                    </div>
                                    { type == CALENDAR_BLOCK && (
                                        <div className="col-12">
                                            <Autocomplete
                                                inputValue={category}
                                                options={options}
                                                onChange={(_, selected) => setCategory(selected || "")}
                                                isOptionEqualToValue={(option) => option == category}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        placeholder="Selecione uma categoria"
                                                        label="Categoria"
                                                        helperText={optionalField}
                                                        size="small"
                                                        variant="outlined"
                                                        fullWidth
                                                    />
                                                )}
                                                openOnFocus
                                            />
                                        </div>
                                    )}
                                    <div className="col-12 col-xl-6">
                                        <Autocomplete
                                            value={professional}
                                            options={professionalsOptions}
                                            getOptionLabel={(option) => option && option.id ? option.get("fullName") : ""}
                                            isOptionEqualToValue={(option) => option.id === professional?.id}
                                            onChange={(_, selected) => setProfessional(selected)}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    placeholder="Ex: João da Silva"
                                                    label="Profissional responsável"
                                                    error={errorProfessional}
                                                    helperText={!errorProfessional && alertProfessional ? <span className="text-warning">{ alertProfessional }</span> : helperProfessional}
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                />
                                            )}
                                            openOnFocus
                                        />
                                    </div>
                                    <div className="col-12 col-xl-6">
                                        <a target="_blank" href={`/agenda?user=${professional?.id}&date=${dayjs(startDateText, "DD/MM/YYYY").format("YYYY-MM-DD")}`} className={`btn-outline-submit btn-submit btn-square${!professional?.id || !dayjs(startDateText, "DD/MM/YYYY").isValid() ? " btn-disabled" : ""}`}>
                                            <IconCalendarCheck width={24} height={24} />
                                            Ver agenda do responsável
                                        </a>
                                    </div>
                                    <div className="col-12 col-xl-6">
                                        <div className="row g-3">
                                            <div className="col-auto">
                                                <DateInput
                                                    placeholder="00/00/0000"
                                                    label="Data inicial do evento"
                                                    value={startDateText}
                                                    error={errorPeriod}
                                                    helperText={!errorPeriod && helperPeriod}
                                                    onChange={(value) => setStartDateText(value)}
                                                    size="small"
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                            </div>
                                            <div className="col">
                                                <Autocomplete
                                                    value={startTime}
                                                    options={timeOptions}
                                                    onChange={(_, selected) => setStartTime(selected)}
                                                    onInputChange={(event) => setStartTime(validateTime(event?.target?.value || "") || startTime)}
                                                    isOptionEqualToValue={(option) => option == startTime}
                                                    renderInput={(params) => (
                                                        <TextFieldMask
                                                            {...params}
                                                            type="tel"
                                                            mask={"00:00"}
                                                            placeholder="00:00"
                                                            label="Início às"
                                                            error={errorPeriod}
                                                            helperText={!errorPeriod && helperPeriod}
                                                            size="small"
                                                            variant="outlined"
                                                            fullWidth
                                                        />
                                                    )}
                                                    disableClearable
                                                    disabled={fullTime}
                                                    openOnFocus
                                                    freeSolo
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-xl-6">
                                        <div className="row g-3">
                                            <div className="col-auto">
                                                <DateInput
                                                    placeholder="00/00/0000"
                                                    label="Data final do evento"
                                                    value={endDateText}
                                                    error={errorPeriod}
                                                    helperText={!errorPeriod && helperPeriod}
                                                    onChange={(value) => setEndDateText(value)}
                                                    size="small"
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                            </div>
                                            <div className="col">
                                                <Autocomplete
                                                    value={endTime}
                                                    options={timeOptions}
                                                    onChange={(_, selected) => setEndTime(selected)}
                                                    onInputChange={(event) => setEndTime(validateTime(event?.target?.value || "") || endTime)}
                                                    isOptionEqualToValue={(option) => option == endTime}
                                                    renderInput={(params) => (
                                                        <TextFieldMask
                                                            {...params}
                                                            type="tel"
                                                            mask={"00:00"}
                                                            placeholder="00:00"
                                                            label="Termina às"
                                                            error={errorPeriod}
                                                            helperText={!errorPeriod && helperPeriod}
                                                            size="small"
                                                            variant="outlined"
                                                            fullWidth
                                                        />
                                                    )}
                                                    disableClearable
                                                    disabled={fullTime}
                                                    openOnFocus
                                                    freeSolo
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    { errorPeriod && (
                                        <div className="col-12 mt-0">
                                            <p className="text-error">
                                                { helperPeriod }
                                            </p>
                                        </div>
                                    )}
                                    <div className="col-12 mt-1">
                                        <VSCheckbox
                                            label="Dia inteiro"
                                            position="end"
                                            checked={fullTime}
                                            onChange={(checked) => {
                                                setFullTime(checked)
                                            }}
                                        />
                                    </div>
                                </div>
                            </VSAccordionSimple>
                        </div>
                    </div>
                    <hr className="my-2" />
                    <div className="row mb-2">
                        <div className="col-12">
                            <VSAccordionSimple
                                expanded={true}
                                header={(expanded) => "Dados da recorrência"}
                            >
                                <div className="row align-items-center mb-2">
                                    <div className="col-12 mb-2">
                                        <b>Esse evento tem recorrência? <span className="optional">(Opcional)</span></b>
                                    </div>
                                    <div className="col-12">
                                        <p className="mb-4">
                                            Configure uma recorrência para esse evento. 
                                            Ele será lançado automaticamente em sua agenda de acordo com a frequência que você escolher.
                                        </p>
                                    </div>
                                    { rrule.freq >= 0 && (
                                        <>
                                            <div className="col-12">
                                                <div className="input-title input-height">
                                                    Esse evento possui lançamentos futuros
                                                </div>
                                            </div>
                                            <div className="col-12 mb-3">
                                                <VSRadio
                                                    value={recurrenceType}
                                                    onChange={(_, value) => {
                                                        setRecurrenceType(value)
                                                    }}
                                                    options={[
                                                        {
                                                            label: "Desejo alterar apenas esse lançamento",
                                                            value: RECURRENCE_THIS
                                                        },
                                                        {
                                                            label: "Desejo alterar esse e os lançamentos futuros",
                                                            value: STATUS_FUTURE
                                                        }
                                                    ]}
                                                />
                                            </div>
                                        </>
                                    )}
                                    { rrule.freq >= 0 && recurrenceType == RECURRENCE_THIS ? (
                                        <div className="col-12">
                                            <p className="text-warning">Não é possível alterar a recorrência de um único lançamento</p>
                                        </div>
                                    ) : (
                                        <div className="col-12 mb-3">
                                            <VSRadio
                                                className="radio-options"
                                                horizontal
                                                options={[
                                                    {
                                                        label: "Sim",
                                                        value: true
                                                    },
                                                    {
                                                        label: "Não",
                                                        value: false
                                                    }
                                                ]}
                                                value={activeRecurrence}
                                                onChange={(_, value) => {
                                                    setActiveRecurrence(value == "true")
                                                }}
                                            />
                                            { activeRecurrence && (
                                                <div className="mt-2">
                                                    <RecurrenceForm
                                                        ref={recurrenceForm}
                                                        documentDate={startDate}
                                                        finishDate={calendar?.get("finishDate")}
                                                        rrule={rrule}
                                                        onChange={validateOnTouch}
                                                    />
                                                    { errorRecurrence && (
                                                        <p className="text-error">
                                                            { helpersRecurrence.map((helper, index) => <span key={index}>{ index !== 0 ? <br/> : "" }{ helper }</span>) }
                                                        </p>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </VSAccordionSimple>
                        </div>
                    </div>
                    <hr className="my-2" />
                    <div className="row">
                        <div className="col">
                            <VSAccordionSimple
                                expanded={true}
                                header={(_) => (
                                    "Observações do Evento"
                                )}
                            >
                                <div className="row mb-3">
                                    <div className="col-12">
                                        <TextField
                                            placeholder="Escreva aqui observações gerais sobre o evento."
                                            value={notes}
                                            onChange={({ target: { value } }) => setNotes(value)}
                                            helperText={<small>Campo Opcional. As observações são anotações particulares. <b>Elas nunca serão compartilhadas com o tutor</b></small>}
                                            size="small"
                                            variant="outlined"
                                            multiline
                                            fullWidth
                                        />
                                    </div>
                                </div>
                            </VSAccordionSimple>
                        </div>
                    </div>
                </>
            )}
        </VSDrawer>
    )
}

const mapStateToProps = state => {
    return {
        pro: state.featuresPro,
        clinic: state.clinic,
        permissions: state.permissions
    }
}

export default connect(mapStateToProps)(CalendarEventForm)