import React, { forwardRef, useImperativeHandle } from 'react'

import { TextField } from '@mui/material';

const TermHospitalizationForm = forwardRef((props, ref) => {

    const {
        maxDaysAfterDischarge,
        setMaxDaysAfterDischarge,
        errorMaxDaysAfterDischarge,
        setErrorMaxDaysAfterDischarge,
        helperMaxDaysAfterDischarge,
        setHelperMaxDaysAfterDischarge,
        hospitalizationDays,
        setHospitalizationDays,
        hospitalizationServices,
        setHospitalizationServices,
        errorHospitalizationServices,
        setErrorHospitalizationServices,
        helperHospitalizationServices,
        setHelperHospitalizationServices,
        notes,
        setNotes
    } = props

    useImperativeHandle(ref, () => ({
        fieldsWithErrorText(errors) {
            if (errorMaxDaysAfterDischarge) {
                errors.push("Máx. de dias para retirada do animal")
            }
            if (errorHospitalizationServices) {
                errors.push("Produtos/Serviços/Materiais/Procedimentos Inclusos é um campo obrigatório")
            }
            return errors
        },
        isValid() {
            return !errorMaxDaysAfterDischarge && !errorHospitalizationServices
        },
        validate(isValid) {
            if (!isNaN(parseInt(maxDaysAfterDischarge))) {
                setErrorMaxDaysAfterDischarge(false)
                setHelperMaxDaysAfterDischarge("")
            }  else {
                isValid = false
                setErrorMaxDaysAfterDischarge(true)
                setHelperMaxDaysAfterDischarge("A quantidade de dias para retirada é obrigatório")
            }

            if (hospitalizationServices) {
                setErrorHospitalizationServices(false)
                setHelperHospitalizationServices("")
            } else {
                isValid = false
                setErrorHospitalizationServices(true)
                setHelperHospitalizationServices("Os produtos/serviços/materiais/procedimentos são obrigatórios")
            }
            return isValid
        }
    }))

    return (
        <div className="row pt-4">
            <div className="col">
                <div className="row mb-4">
                    <div className="col-12 col-md">
                        <div className="input-title input-height">
                            Máx. de dias para retirada do animal após alta (obrigatório)
                        </div>
                    </div>
                    <div className="col-auto" style={{ width: '140px' }}>
                        <TextField
                            type="tel"
                            value={maxDaysAfterDischarge}
                            placeholder="Digite aqui"
                            onChange={({ target: { value } }) => setMaxDaysAfterDischarge(value)}
                            error={errorMaxDaysAfterDischarge}
                            size="small"
                            variant="outlined"
                            fullWidth
                        />
                    </div>
                    { errorMaxDaysAfterDischarge && helperMaxDaysAfterDischarge && (
                        <div className="col-12">
                            <small className="text-danger">{ helperMaxDaysAfterDischarge }</small>
                        </div>
                    )}
                </div>
                <div className="row mb-4">
                    <div className="col-12 col-md">
                        <div className="input-title input-height">
                            Quantidade de Dias de Internação/Tratamento (Opcional)
                        </div>
                    </div>
                    <div className="col-auto" style={{ width: '140px' }}>
                        <TextField
                            type="tel"
                            value={hospitalizationDays}
                            placeholder="Digite aqui"
                            onChange={({ target: { value } }) => setHospitalizationDays(value)}
                            size="small"
                            variant="outlined"
                            fullWidth
                        />
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-12">
                        <div className="input-title input-height">
                            Produtos/Serviços/Materiais/Procedimentos Inclusos (Obrigatório)
                        </div>
                    </div>
                    <div className="col-12">
                        <TextField
                            value={hospitalizationServices}
                            placeholder="Escreva aqui todos os produtos, serviços, materiais, procedimentos, etc. que estão inclusos co m a internação"
                            onChange={({ target: { value } }) => setHospitalizationServices(value)}
                            error={errorHospitalizationServices}
                            helperText={helperHospitalizationServices}
                            size="small"
                            variant="outlined"
                            fullWidth
                            multiline
                        />
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-12">
                        <div className="input-title input-height">
                            Observações gerais (opcional)
                        </div>
                    </div>
                    <div className="col-12">
                        <TextField
                            value={notes}
                            placeholder="Escreva aqui as observações gerais sobre o atestado"
                            onChange={({ target: { value } }) => setNotes(value)}
                            size="small"
                            variant="outlined"
                            fullWidth
                            multiline
                        />
                    </div>
                </div>
            </div>
        </div>
    )
})

export default TermHospitalizationForm;