import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import { ReactComponent as IconClose } from "../../assets/icon-close.svg"
import { ReactComponent as IconArrowLeft } from "../../assets/icon-arrow-left.svg"
import { ReactComponent as ImageClubeDescontoModal } from "../../assets/image-clube-desconto-modal.svg"

import "./ClubeDescontoModal.scss"
import { APP_ID, APP_NAME, APP_DEVICE, APP_VERSION } from '../../constants';
import { LocalStoragePrefix, saveOnTracker } from '../../utils';

export const LocalStorageClubeDescontoModalKey = `${LocalStoragePrefix()}showClubeDescontoModal`

const ClubeDescontoModal = (props) => {
    const {
        user,
        history
    } = props

    const [ showModal, setShowModal ] = useState(false)
    const [ currentStep, setCurrentStep ] = useState(1)

    useEffect(() => {
        setShowModal(false)
        const hasShowModal = localStorage.getItem(LocalStorageClubeDescontoModalKey)
        if(!hasShowModal){
            localStorage.setItem(LocalStorageClubeDescontoModalKey, true)
        }
        setShowModal(hasShowModal === 'false' ? false : true)
        if(hasShowModal !== 'false'){
            saveOnTracker("Visualizou", "Clube de Desconto (Modal)")
        }
    }, [])

    const hideModal = () => {
        localStorage.setItem(LocalStorageClubeDescontoModalKey, false)
        setShowModal(false)
    }

    if(!showModal){
        return <></>
    }

    return (
        <div id="clube-desconto-modal">
            <div className="clube-desconto-modal-popup">
                <div className="row flex-column flex-md-row gx-0">
                    <div className="col-auto flex-shrink-0 clube-desconto-modal-select-part d-flex">
                        <div className="row gx-0">
                            <div className="col p-0 d-flex align-items-end">
                                <ImageClubeDescontoModal />
                            </div>
                        </div>
                    </div>
                    <div className="col flex-grow-1 clube-desconto-explanation-part d-flex flex-column align-items-start justify-content-between">
                        <button
                            className="clube-desconto-modal-close"
                            onClick={() => {
                                hideModal()
                                saveOnTracker("Clicou", "Clube de Desconto (Modal)", "Fechar")
                            }}
                        >
                            <IconClose fill="var(--vsprontuario-primary-color)" width="20" height="20" />
                            <span>FECHAR</span>
                        </button>
                        <div className="row clube-desconto-modal-step flex-no-wrap gx-0 mb-3">
                            <div className={`col-auto clube-desconto-modal-step-element ${currentStep == 1 ? 'checked' : 'read'}`}>
                                1
                            </div>
                            <div className={`col-auto clube-desconto-modal-step-element ${currentStep == 1 ? '' : 'checked'}`}>
                                2
                            </div>
                        </div>
                        {currentStep == 1 ? (
                            <>
                                <h2>Você ganhou acesso ao Clube de Descontos da Petlove!</h2>
                                <p>A Vetsmart preparou um benefício surpresa para te ajudar ainda mais.</p>
                                <p>Agora você assinante do <b>Prontuário PRO</b>, tem acesso exclusivo ao <b>Clube de Desconto da Petlove</b>.</p>
                                <p>Com ele você ganha frete grátis nas suas compras e 15% a 25% em descontos, sem precisar de cupons, em todo site ou app e lojas físicas Petlove.</p>
                            </>
                        ) : (
                            <>
                                <h2>Como usar o clube?</h2>
                                <p><b>Passo 1:</b> Crie uma conta no site ou app da Petlove com o email <b><i>{user?.email}</i></b>, cadastrado no seu Prontuário PRO da Vetsmart.</p>
                                <p><b>Passo 2:</b> Faça o login e pronto!</p>
                                <p>Todas as compras já estarão com o maior desconto possível aplicado pelo Clube de Descontos da Petlove, Válido para lojas físicas e site/app.</p>
                            </>
                        )}
                        <div className="row mt-4 w-100 gx-0">
                            <div className="col">
                                {currentStep == 2 &&
                                <button className="btn-outline-submit btn-square text-normal py-2 px-3" onClick={() => {
                                    setCurrentStep(1)
                                    saveOnTracker("Clicou", "Clube de Desconto (Modal)", "Voltar")
                                }}>
                                    <IconArrowLeft fill="var(--vsprontuario-primary-color)" className="me-3" />
                                    Voltar
                                </button>}
                            </div>
                            <div className="col-auto">
                                {currentStep == 1 ? (
                                    <button className="btn-submit btn-square text-normal py-2 px-3" onClick={() => {
                                        setCurrentStep(2)
                                        saveOnTracker("Clicou", "Clube de Desconto (Modal)", "Avançar")
                                    }}>
                                        Avançar
                                        <IconArrowLeft fill="#ffffff" className="ms-3" style={{ transform: `rotate(180deg)` }} />
                                    </button>
                                ) : (
                                    <a 
                                        className="btn-submit btn-square text-normal p-2" 
                                        href="https://www.petlove.com.br/clube-de-descontos" 
                                        target="_blank"
                                        onClick={() => {
                                            hideModal()
                                            saveOnTracker("Clicou", "Clube de Desconto (Modal)", "Ir para Petlove")
                                        }}>
                                        Ir para Petlove
                                    </a>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ClubeDescontoModal