import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { connect } from 'react-redux'

import { ReactComponent as IconAdd } from "../../assets/icon-add.svg"
import { ReactComponent as IconPaw } from "../../assets/icon-paw-menu.svg"
import { ReactComponent as IconPrimaryTrash } from "../../assets/icon-primary-trash.svg"

import { ACL } from '../../constants'
import HomeData from '../home/HomeData'
import PatientPhoto from '../../components/patientPhoto/PatientPhoto'
import { deletePatient, getAgeFromPatient } from '../patient/Patient'

import './OwnerViewPatient.scss'
const OwnerViewPatient = props => {

    const {
        owner,
        clinic,
        expanded,
        history,
        permissions,
        patientsData,
        getPatients
    } = props

    const [ loading, setLoading ] = useState(false)
    
    const rowsPerPage = 2

    useEffect(() => {
        if(patientsData && loading){
            setLoading(false)
        }
    }, [ patientsData, loading ])

    return (
        <div className="col-12 mb-3">
            <HomeData
                className="mb-3"
                expanded={expanded}
                headerIcon={<IconPaw width="24px" height="24px" fill="#B3B3B3" />}
                title="Animais"
                emptyText="Este(a) Tutor(a) ainda não possui animais cadastrados"
                loading={loading}
                data={patientsData}
                rowsPerPage={rowsPerPage}
                onChange={(page) => {
                    setLoading(true)
                    if(!patientsData){
                        getPatients()
                    }
                }}
                renderItem={(element, index) => {
                    return (
                        <React.Fragment key={index}>
                            {index > 0 &&
                            <div className="vsbox-separator"></div>}
                            <div className={`vsbox-profile ${index > 0 ? "py-3" : "pb-3"}`}>
                                <div className="row align-items-center">
                                    <div className="col-auto">
                                        <PatientPhoto patient={element} withBorder />
                                    </div>
                                    <div className="col px-0">
                                        <div className="vsbox-name">
                                            { element.get("name") }
                                        </div>
                                        <div className="vsbox-data">
                                            <b>Raça:</b> { element.get("breed") || "Não informado" }
                                        </div>
                                        <div className="vsbox-data">
                                            <b>Idade:</b> { getAgeFromPatient(element, true) || "Não informado" }
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-auto mt-3 mt-sm-0">
                                        <div className="row">
                                            { permissions?.check(ACL.PATIENT, ACL.Rule.DELETE, element) && (
                                                <div className="col-6 order-sm-0 order-1 col-sm-auto ps-1 ps-sm-3">
                                                    <button
                                                        className="vsbox-btn vsbox-btn-alert"
                                                        onClick={() => deletePatient(element, () => init())}
                                                    >
                                                        <IconPrimaryTrash width="24px" height="24px" />
                                                    </button>
                                                </div>
                                            )}
                                            <div className="col-6 order-sm-0 order-2 col-sm-auto ps-sm-0 pe-1 pe-sm-3">
                                                <button
                                                    className="vsbox-btn vsbox-btn-primary"
                                                    onClick={() => history.push(`/animal/${element.id}`)}
                                                >
                                                    Ir para Perfil
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    )
                }}
                footer={(
                    <div className="col">
                        <div 
                            id="new-patient-link" 
                            className="btn-dashed d-flex align-items-center justify-content-center"
                            onClick={() => history.push(`/tutor/${owner.id}/animal/novo`)} 
                        >
                            <IconAdd width="20px" height="20px" className="m-0 me-2" />
                            Cadastrar Novo Animal
                        </div>
                    </div>
                )}
            />
        </div>
    )
}

const mapStateToProps = state => {
    return {
        permissions: state.permissions
    };
};
  
export default connect(mapStateToProps)(OwnerViewPatient);