import React from "react"

import { showRegisteredBy } from "../../utils"

import VSAccordionSimple from "../../components/vsAccordion/VSAccordionSimple"
import SupplyUnitView from "./SupplyUnitView"

import "./SupplyTransactionView.scss"
const SupplyTransactionView = (props) => {

    const {
        object
    } = props

    const transactionType = object.get("quantity") >= 0 ? "input" : "output"
    const transactionTypeDescription = transactionType == "input" ? "Entrada" : "Saída"
    
    return (
        <div id="supply-transaction-view">
            <div id="vsbox-view">
                <div className={`vsbox mb-3`}>
                    <div className="vsbox-body mb-0 pb-0">
                        <div className="row align-items-center">
                            <div className="col-12 col-md mb-3">
                                <label className="vsbox-info">Evento</label>
                                <div className={`vsbox-info-text ${object.get("quantity") < 0 ? "text-danger" : "text-success"}`}>
                                    { transactionTypeDescription }
                                </div>
                            </div>
                            <div className="col-12 col-md mb-3">
                                <label className="vsbox-info">Qtd. Anterior</label>
                                <div className="vsbox-info-text">
                                    <SupplyUnitView unit={object.get("unit")} quantity={object.get("lastTotal")} />
                                </div>
                            </div>
                            <div className="col-12 col-md mb-3">
                                <label className="vsbox-info">Qtd. Movimentada</label>
                                <div className="vsbox-info-text">
                                    <SupplyUnitView unit={object.get("unit")} quantity={object.get("quantity")} />
                                </div>
                            </div>
                            <div className="col-12 col-md mb-3">
                                <label className="vsbox-info">Origem da { transactionTypeDescription }</label>
                                <div className="vsbox-info-text">
                                    { object.get("sale") ? "Venda" : "Ajuste de estoque" }
                                </div>
                            </div>
                        </div>
                        { showRegisteredBy(object) }
                        { object.get("notes") && (
                            <>
                                <div className="vsbox-separator"></div>
                                <VSAccordionSimple
                                    header={(expanded) => `Ver ${expanded ? "menos" : "mais"} informações`}
                                >
                                    <div className="row">
                                        <div className="col-12 vsbox--info">
                                            <div className="row">
                                                <div className="col vsbox-info">
                                                    Observações
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col mb-3 pre-line">
                                                    { object.get("notes") || "Não informado" }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </VSAccordionSimple>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SupplyTransactionView