import React from "react"
import { connect } from "react-redux"

import { ReactComponent as IconEdit } from "../../assets/icon-primary-edit.svg"
import { ReactComponent as IconHealth } from "../../assets/icon-health.svg"

import { formattedCurrency, showRegisteredBy } from "../../utils"
import { serviceActiveOptions } from "./Service"
import { ACL } from "../../constants"

import VSAccordionSimple from "../../components/vsAccordion/VSAccordionSimple"
import SupplyUnitView from "../supply/SupplyUnitView"

import "./ServiceView.scss"
const ServiceView = (props) => {

    const {
        object,
        expanded,
        history,
        permissions
    } = props

    const category = object.get("category")
    const isDeleted = object.get("isDeleted")
    const isActive = object.get("active")
    const currentServiceActiveOption = serviceActiveOptions.find(serviceOption => serviceOption.value == isActive)

    return (
        <div id="service-view" className="mb-3">
            <div className={`vsbox d-block w-100 ${ isActive ? "vsbox-active" : "vsbox-inactive" }`}>
                { !isActive && (
                    <div className="vsbox-status">
                        {currentServiceActiveOption.long}
                    </div>
                )}
                <div className="vsbox-body pb-1">
                    <div className="row g-3 mb-3 align-items-center">
                        <div className="col-auto order-0">
                            <div className="icon-round">
                                <IconHealth width="24" height="24" fill="var(--vsprontuario-primary-color)" />
                            </div>
                        </div>
                        <div className="col order-1">
                            <label className="vsbox-info mb-0">
                                { object.get("serviceId") && (
                                    <b>ID { object.get("serviceId") } - </b>
                                )}
                                { object.get("serviceName") }
                            </label>
                            <div className="vsbox-info-text">
                                <b>Categoria:</b> { category?.get("categoryName") || "Não informada" }
                            </div>
                        </div>
                        <div className="col-12 text-left text-md-right col-md-auto order-3 order-md-2">
                            <label className="vsbox-value">Valor do serviço</label>
                            <div className="vsbox-value">
                                { formattedCurrency(object.get("value")) }
                            </div>
                        </div>
                        <div className="col-auto text-right order-2 order-md-3">
                            { !isDeleted && permissions?.check(ACL.SERVICE, ACL.Rule.EDIT, object) && (
                                <div className="col-12 col-md-auto">
                                    <div className="row align-items-center justify-content-center">
                                        <div className="col-12 col-md-auto">
                                            <button
                                                className={`vsbox-btn ${isDeleted ? "vsbox-btn-disabled" : "vsbox-btn-dark"}`}
                                                onClick={() => history.push(`/servico/${ object.id }`)}
                                                disabled={isDeleted}
                                            >
                                                <IconEdit width="24" height="24" fill={isDeleted ? "var(--vsprontuario-gray-color)" : "var(--vsprontuario-primary-color-dark)"} />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    { showRegisteredBy(object) }
                    <div className="vsbox-separator"></div>
                    <div className="row">
                        <div className="col-12">
                            <VSAccordionSimple
                                expanded={expanded}
                                header={(expanded) => (
                                    `${ expanded ? "Ocultar" : "Ver" } mais informações`
                                )}
                            >
                                { object.get("supplies")?.length > 0 && (
                                    <div className="row">
                                        <div className="col-12 mb-2">
                                            Insumos a serem utilizados:
                                        </div>
                                        <div className="col-12">
                                            <div className="vsbox-item mb-3">
                                                { object.get("supplies").map((data, index) => data.object?.id && (
                                                    <React.Fragment key={index}>
                                                        { index != 0 && (
                                                            <div className="vsbox-separator my-3"></div>
                                                        )}
                                                        <div className="row vsbox-item-text">
                                                            <div className="col-12">
                                                                <b>ID { data.object.get("supplyId") } - { data.object.get("supplyName") }</b>
                                                            </div>
                                                            <div className="col-12">
                                                                <b>Quantidade utilizada:</b> <SupplyUnitView unit={data.unit} quantity={data.quantity} />
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                )) }
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div className="row">
                                    <div className="col vsbox-title">
                                        Observações:
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col mb-3 pre-line">
                                        { object.get("notes") || "Não informado" }
                                    </div>
                                </div>
                            </VSAccordionSimple>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        permissions: state.permissions
    }
}

export default connect(mapStateToProps)(ServiceView)