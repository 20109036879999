
import { useEffect, useState } from "react"
import { connect } from "react-redux"

import "./CalendarSurvey.scss"
const CalendarSurvey = (props) => {

    const {
        clinic,
        showingTag,
        onChange
    } = props

    const surveyName = "calendar-survey-v1"

    const [ show, setShow ] = useState(false)

    // useEffect(() => {
    //     if (!showingTag && localStorage.getItem(surveyName) !== "true") {
    //         setShow(true)
    //         onChange(true)
    //     }
    // }, [ showingTag, clinic ])

    // useEffect(() => {
    //     onChange(show)
    // }, [ show ])

    return show && (
        <div id="calendar-survey">
            <div className="row g-3 align-items-center">
                <div className="col-auto">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20 2H4C2.9 2 2 2.9 2 4V22L6 18H20C21.1 18 22 17.1 22 16V4C22 2.9 21.1 2 20 2ZM20 16H6L4 18V4H20V16ZM7 9H9V11H7V9ZM11 9H13V11H11V9ZM15 9H17V11H15V9Z" fill="#934CA1"/>
                    </svg>
                </div>
                <div className="col">
                    Queremos saber sua opinião sobre as nossas novas funcionalidades. Conte-nos o que você achando da nossa <b>Agenda</b>.
                </div>
                <div className="col-12 col-md-auto">
                    <a
                        target="_blank"
                        href="https://petlove-vetsmart-vetus.typeform.com/to/Bo7fW849"
                        className="btn-outline-submit btn-square d-block w-100"
                        onClick={() => {
                            localStorage.setItem(surveyName, true)
                            setShow(false)
                        }}
                    >
                        Conte-nos o que achou da Agenda
                    </a>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        showingTag: state.showingTag,
        clinic: state.clinic,
    };
};

export default connect(mapStateToProps)(CalendarSurvey)