import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { saveOnTracker } from '../../utils';

import { InputAdornment, TextField } from '@mui/material';

import { ReactComponent as IconPaw } from "../../assets/icon-paw.svg"
import { ReactComponent as IconPdf } from "../../assets/pdf.svg"
import { ReactComponent as IconSearch } from "../../assets/icon-search.svg"

import VSAccordionSetting from '../setting/VSAccordionSetting';
import CardProAdvertising from '../../components/cardProAdvertising/CardProAdvertising';

import './VetDocuments.scss'
import VSEmpty from '../../components/vsEmpty/VSEmpty';
const VetDocuments = props => {

    const {
        pro,
        user,
        clinic,
        history,
        permissions
    } = props

    const showAdvertising = !pro?.access && clinic?.isOwner

    const documents = [
        {
            title: "Receituários Veterinários",
            documents: [
                {
                    name: "Receita Simples",
                    link: "receita-simples"
                },
                {
                    name: "Receita de Controle Especial",
                    link: "receita-controle-especial"
                }
            ]
        },
        {
            title: "Termos e Atestados",
            documents: [
                {
                    name: "Termo de consentimento livre e esclarecido para realização de procedimentos anestésicos",
                    link: "termo-anestesia"
                },
                {
                    name: "Termo de consentimento livre e esclarecido para realização de eutanásia",
                    link: "termo-eutanasia"
                },
                {
                    name: "Termo de esclarecimento para a retirada de animal do serviço veterinário sem alta médica",
                    link: "termo-retirada-sem-alta-medica"
                },
                {
                    name: "Termo de consentimento livre e esclarecido para realização de internação e tratamento clínico ou pós-cirúrgico",
                    link: "termo-autorizacao-internacao"
                },
                {
                    name: "Atestado de Óbito",
                    link: "atestado-obito"
                },
                {
                    name: "Atestado sanitário para o trânsito de cães e gatos",
                    link: "atestado-viagem"
                },
                {
                    name: "Atestado Sanitário",
                    link: "atestado-sanitario"
                },
                {
                    name: "Atestado de vacinação",
                    link: "atestado-vacinacao"
                },
                // {
                //     name: "Termo de Internação e Tratamento Clínico ou Pós-Cirúrgico",
                //     link: "termo-internacao"
                // },
                {
                    name: "Termo de consentimento livre e esclarecido para realização de exames",
                    link: "termo-realizacao-exames"
                },
                {
                    name: "Termo de consentimento livre e esclarecido para realização de procedimento cirúrgico",
                    link: "termo-realizacao-cirurgia"
                },
                {
                    name: "Termo de consentimento livre e esclarecido para realização de procedimento terapêutico de risco",
                    link: "termo-realizacao-procedimento-risco"
                },
                {
                    name: "Termo de Consentimento Livre e Esclarecido para Telemedicina Veterinária",
                    link: "termo-telemedicina"
                },
                {
                    name: "Termo de consentimento livre e esclarecido para retirada de corpo de animal em óbito",
                    link: "termo-retirada-corpo"
                },
                {
                    name: "Termo de consentimento livre e esclarecido de doação de corpo de animal para fins de ensino e pesquisa",
                    link: "termo-doacao-corpo"
                }
            ]
        },
        {
            title: "Fichas Clínicas",
            documents: [
                {
                    name: "Anamnese Veterinária",
                    link: "ficha-anamnese"
                },
                {
                    name: "Ficha Clínica Dermatológica",
                    link: "ficha-dermatologica"
                },
                {
                    name: "Ficha Anestésica Veterinária",
                    link: "ficha-anestesica"
                },
                {
                    name: "Ficha de Internação Veterinária",
                    link: "ficha-internacao"
                },
                {
                    name: "Orientações Pré-cirúrgicas",
                    link: "orientacoes-cirurgia"
                }
            ]
        },
        {
            title: "Papelarias Essenciais",
            documents: [
                {
                    name: "Recibo",
                    link: "recibo"
                },
                {
                    name: "Orçamento",
                    link: "orcamento"
                },
                {
                    name: "Comprovante de Pagamento",
                    link: "comprovante-pagamento"
                },
                {
                    name: "Carta de Encaminhamento",
                    link: "carta-encaminhamento"
                },
                {
                    name: "Requisição de Exames",
                    link: "requisicao-exames"
                },
                {
                    name: "Cartão de Vacinação e Controle de Parasitas (Formato A4)",
                    link: "cartao-vacinacao-parasitas"
                },
                {
                    name: "Carteira de Vacinação e Controle de Parasitas (Formato Cartão A5)",
                    link: "carteira-vacinacao-parasitas"
                },
                {
                    name: "Certificado de Coragem",
                    link: "certificado-coragem"
                },
                {
                    name: "Papel Timbrado",
                    link: "papel-timbrado"
                }
            ]
        },
    ]

    const [ search, setSearch ] = useState("")
    const [ filteredDocuments, setFilteredDocuments ] = useState(documents)

    useEffect(() => {
        var filtered = [...documents]
        filtered = filtered.filter((types, index) => {
            filtered[index].documents = types.documents.filter(document => {
                return document.name?.toLowerCase()?.includes(search?.toLowerCase())
            })
            return filtered[index].documents?.length > 0
        })
        setFilteredDocuments(filtered)
    }, [ search ])

    useEffect(() => {
        saveOnTracker("Acessou", "Impressos Veterinários")
    }, [])

    return (
        <div id="vet-documents">
            <div className="page-content">
                <div className="row g-3">
                    <div className="col-12">
                        <div className="page-title">
                            Impressos Veterinários
                        </div>
                    </div>
                    <div className="col-12">
                        <TextField
                            placeholder="Buscar por nome do documento"
                            variant="outlined"
                            size="small"
                            value={search}
                            onChange={({ target: { value} }) => setSearch(value)}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    <IconSearch width="18px" height="18px" fill="var(--vsprontuario-gray-color)" />
                                </InputAdornment>,
                            }}
                            fullWidth
                        />
                    </div>
                    {(!clinic?.object?.get("name") || !clinic?.object?.get("addressStreet") || !clinic?.object?.get("cnpj") || !clinic?.object?.get("crmv") || !clinic?.object?.get("crmvStateId")) &&
                    <div className="col-12 mt-3">
                        <div className="page-warning">Confira a Identificação do Médico(a) Veterinário(a) (nome, endereço completo, inscrição no CRMV, e-mail, telefone) e, se for o caso, identificação do estabelecimento (nome, endereço completo, CNPJ, Inscrição Estadual e Registro no CRMV). <a onClick={() => history.push(`/configuracao-clinica`)}>Clique aqui para atualizar</a>.</div>
                    </div>}
                    <div className={`col-12 ${showAdvertising ? "col-md-8" : ""}`}>
                        <div className="row g-3 mb-3">
                            <div className="col-12">
                                <div className="vsbox">
                                    <div className="vsbox-body">
                                        <div className="title">Documentos em branco disponíveis para você imprimir</div>
                                        <div>Modelos de documentos da Clinica Veterinária: Resolução CFMV n° 1.321/2020;</div>
                                        <div>Modelos de receituário de medicamentos controlados: Portaria 344/98 (ANVISA) e IN n° 35/2017 (MAPA).</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        { filteredDocuments?.length > 0 ? (
                            filteredDocuments.map((type, index) => (
                                <div className="row g-3 mb-3" key={index}>
                                    <div className="col-12">
                                        <VSAccordionSetting title={type.title}>
                                            <div className="row row-eq-height g-3">
                                                { type.documents.map(document => (
                                                    <div key={document.link} className="col-6 col-sm-4 col-lg-3">
                                                        <div className="doc">
                                                            <div className="doc-content">
                                                                <IconPdf width={48} height={48} fill="var(--vsprontuario-primary-color)" />
                                                                { document.name }
                                                                <a
                                                                    target="_blank"
                                                                    href={`${process.env.REACT_APP_PDF}/api/v2/${document.link}/${clinic?.objectId}/${user?.objectId}`}
                                                                    className="doc-download"
                                                                    onClick={() => {
                                                                        saveOnTracker("Clicou", "Impressos Veterinários", document.link)
                                                                    }}
                                                                >
                                                                    Baixar
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )) }
                                            </div>
                                        </VSAccordionSetting>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <VSEmpty
                                icon={<IconPaw width="32px" height="32px" fill="var(--vsprontuario-primary-color)" />}
                                title="Não encontramos nenhum resultado"
                                text="Tente refazer sua busca utilizando os filtros a cima"
                            />
                        )}
                    </div>
                    { showAdvertising && (
                        <div className="col-12 col-md-4">
                            <CardProAdvertising clinic={clinic} pro={pro} history={history} />
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        pro: state.featuresPro,
        user: state.user,
        clinic: state.clinic,
        permissions: state.permissions
    }
}

export default connect(mapStateToProps)(VetDocuments)