import React from "react";

import Step1 from "../../../assets/browsers/safari-step1.png"
import Step2 from "../../../assets/browsers/safari-step2.png"
import Step3 from "../../../assets/browsers/safari-step3.png"

const BrowserSafari = (props) => {
    return (
        <div id="safari">
            <h4>Passo a passo - Safari</h4>
            <div className="row g-3 mb-3">
                <div className="col-12">
                    <b>Passo 1:</b> Abra o Prontuário do Vet Smart no Safari e 
                    clique no ícone de compartilhamento, localizado no menu do 
                    rodapé da tela.
                </div>
                <div className="col-12">
                    <img src={Step1} width="100%" />
                </div>
                <div className="col-12">
                    <b>Passo 2:</b> Na janela de opções de compartilhamento que vai 
                    se abrir na tela, selecione a opção "Adicionar à Tela de Início".
                </div>
                <div className="col-12">
                    <img src={Step2} width="100%" />
                </div>
                <div className="col-12">
                    <b>Passo 3:</b> Na tela seguinte, defina o nome que você deseja 
                    utilizar em sua tela inicial. Depois, clique em "Adicionar", 
                    no topo direito da tela.
                </div>
                <div className="col-12">
                    <img src={Step3} width="100%" />
                </div>
            </div>
        </div>
    )
}

export default BrowserSafari