import { CircularProgress, TextField } from "@mui/material"
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react"
import DateInput from "../../../../../components/dateInput/DateInput"
import VSAccordion from "../../../../../components/vsAccordion/VSAccordion"
import VSCheckbox from "../../../../../components/vsCheckbox/VSCheckbox"
import TextFieldMask from "../../../../../components/textFieldMask/TextFieldMask"

import { ReactComponent as IconArrowLeft } from "../../../../../assets/icon-arrow-left.svg"
import { ReactComponent as VetsmartLogo } from "../../../../../assets/vetsmart-logo.svg"
import { ReactComponent as IconText } from "../../../../../assets/icon-text.svg"
import { ReactComponent as IconSchedule } from "../../../../../assets/icon-schedule.svg"
import { ReactComponent as IconContact } from "../../../../../assets/icon-contact-owner.svg"
import Certified from "../../../../../assets/certified.png"

import "./AutomaticReminderForm.scss"
import { saveOwnerContactInfo } from "../../../../owner/Owner"
import { dayjs, getEmailPreview, getOrCreateMessage, getSmsPreview, getWhatsappPreview, validateDate, validateEmail, validatePhone } from "../../../../../utils"
import { Skeleton } from "@mui/lab"
import { getFirstScheduledAtFromArray } from "../VaccineListView"
import VSMessage from "../../../../../components/vsMessage/VSMessage"
import VSAccordionCard from "../../../../../components/vsAccordion/VSAccordionCard"

var preventMultipleRendersOfPreviews = 0

const messageTemplateIds = {
    "SMS": "SMS_VACCINE_REMINDER",
    "WHATSAPP": "WHATSAPP_VACCINE_REMINDER",
    "EMAIL": "EMAIL_VACCINE_REMINDER",
}

const AutomaticReminderForm = forwardRef((props, ref) => {

    const {
        hasSubmited,
        history,
        vaccine,
        patient,
        vaccineType,
        reminderDate,
        sendAutomaticReminderIsActive,
        setSendAutomaticReminderIsActive,
        onChange
    } = props

    const MessageForm = useRef()

    const clinic = vaccine.get("clinic")
    const owner  = patient?.get("owner")

    const [ reminders, setReminders ] = useState(null)
    const [ loadingReminders, setLoadingReminders ] = useState(true)

    const [ errorSendAutomaticReminderIsActive, setErrorSendAutomaticReminderIsActive ] = useState(false)
    const [ helperSendAutomaticReminderIsActive, setHelperSendAutomaticReminderIsActive ] = useState(<span>Campo Opcional</span>)

    const [ sendAutomaticReminderDate, setSendAutomaticReminderDate ] = useState(null)
    const [ sendAutomaticReminderDateText, setSendAutomaticReminderDateText ] = useState("")
    const [ errorSendAutomaticReminderDate, setErrorSendAutomaticReminderDate ] = useState(false)
    const [ helperSendAutomaticReminderDate, setHelperSendAutomaticReminderDate ] = useState(<span>Campo Opcional</span>)

    const [ sendAutomaticReminderByWhatsapp, setSendAutomaticReminderByWhatsapp ] = useState(false)
    const [ sendAutomaticReminderBySMS, setSendAutomaticReminderBySMS ] = useState(false)
    const [ sendAutomaticReminderByEmail, setSendAutomaticReminderByEmail ] = useState(false)

    const [ phoneOwner, setPhoneOwner ] = useState("")
    const [ errorPhoneOwner, setErrorPhoneOwner ] = useState(false)
    const [ helperPhoneOwner, setHelperPhoneOwner ] = useState(<span>Campo Opcional</span>)

    const [ emailOwner, setEmailOwner ] = useState("")
    const [ errorEmailOwner, setErrorEmailOwner ] = useState(false)
    const [ helperEmailOwner, setHelperEmailOwner ] = useState(<span>Campo Opcional</span>)

    const defaultDate = dayjs(reminderDate)
    const threeDaysBefore    = defaultDate.clone().subtract(3, 'days').format("DD/MM/YYYY")
    const fiveDaysBefore     = defaultDate.clone().subtract(5, 'days').format("DD/MM/YYYY")
    const sevenDaysBefore    = defaultDate.clone().subtract(7, 'days').format("DD/MM/YYYY")
    const fiveteenDaysBefore = defaultDate.clone().subtract(15, 'days').format("DD/MM/YYYY")

    const [ previewWhatsapp, setPreviewWhatsapp ] = useState(null)
    const [ showPreviewWhatsapp, setShowPreviewWhatsapp ] = useState(false)
    const [ previewSMS, setPreviewSMS ] = useState(null)
    const [ showPreviewSMS, setShowPreviewSMS ] = useState(false)
    const [ previewEmail, setPreviewEmail ] = useState(null)
    const [ showPreviewEmail, setShowPreviewEmail ] = useState(false)

    const [ savingOwner, setSavingOwner ] = useState(false)

    const [ editPhone, setEditPhone ] = useState(false)
    const [ editEmail, setEditEmail ] = useState(false)

    const [ expandedInfo, setExpandedInfo ] = useState(false)

    useImperativeHandle(ref, () => ({
        validate: validate,
        save: onSave,
        limit: getLimit
     }));

    const handleSendAutomaticReminderDate = (date) => {
        setSendAutomaticReminderDate(validateDate(date, "10:00:00"))
        setSendAutomaticReminderDateText(date)
    }

    const getQueryParamsToPreview = (params) => {
        return Object.assign(
            params ? params : {},
            {
                'createdFromObject[vaccineType]': vaccineType,
                'createdFromObject[reminderDate]': reminderDate && dayjs(reminderDate).format("YYYY-MM-DD HH:mm:ss")
            }
        )
    }

    const validate = (errors = []) => {
        if (loadingReminders) {
            errors.push("Procurando o lembrete")
        }

        if (sendAutomaticReminderIsActive) {
            errors = MessageForm.current?.validate(errors)

            const libDate = dayjs(sendAutomaticReminderDateText, "DD/MM/YYYY").hour(0).minute(0).second(0)
            if (libDate.isValid()) {
                if (libDate.diff(dayjs().hour(0).minute(0).second(0), "hours") < 1) {
                    errors.push("Data de envio do lembrete automático")
                    setErrorSendAutomaticReminderDate(true)
                    setHelperSendAutomaticReminderDate("A data do envio do lembrete automático precisa ser maior que a data de hoje")
                } else if (libDate.diff(defaultDate.hour(0).minute(0).second(0), "hours") > 0) {
                    errors.push("Data de envio do lembrete automático")
                    setErrorSendAutomaticReminderDate(true)
                    setHelperSendAutomaticReminderDate("A data do envio do lembrete automático não pode ser maior que a data do lembrete de vacina")
                } else {
                    setErrorSendAutomaticReminderDate(false)
                    setHelperSendAutomaticReminderDate(<span>Campo Opcional</span>)
                }
            } else {
                errors.push("Data de envio do lembrete automático")
                setErrorSendAutomaticReminderDate(true)
                setHelperSendAutomaticReminderDate("A data de envio automático do lembrete precisa ser preenchida")
            }
        } else {
            setErrorSendAutomaticReminderIsActive(false)
            setHelperSendAutomaticReminderIsActive(<span>Campo Opcional</span>)
            setErrorSendAutomaticReminderDate(false)
            setHelperSendAutomaticReminderDate(<span>Campo Opcional</span>)
        }

        return errors
    }

    const onSave = (vaccineId) => {
        if (sendAutomaticReminderDate) {
            return MessageForm.current?.schedule()?.map(message => {
                return {
                    user: message.user,
                    clinic: message.clinic,
                    owner: message.owner,
                    patient: message.patient,
                    createdFromClass: "Vaccine",
                    createdFromId: vaccineId,
                    scheduledAt: sendAutomaticReminderDate,
                    messageTemplateId: message.messageTemplateId,
                    messageTypeId: message.messageTypeId,
                    sentTo: message.sentTo,
                }
            }) || []
        }
        return []
    }

    const getLimit = () => {
        return MessageForm.current?.limit()
    }
    
    const getAdviceForLimit = () => {
        return MessageForm.current?.adviceForLimit()
    }

    useEffect(() => {
        if (reminders) {
            const whatsappReminder  = getQueryParamsToPreview(reminders?.whatsappReminder)
            const smsReminder       = getQueryParamsToPreview(reminders?.smsReminder)
            const emailReminder     = getQueryParamsToPreview(reminders?.emailReminder)

            if (vaccineType && reminderDate) {
                preventMultipleRendersOfPreviews++
                var isLastRender = preventMultipleRendersOfPreviews
                setTimeout(() => {
                    if (isLastRender == preventMultipleRendersOfPreviews) {
                        if (showPreviewWhatsapp) {
                            setPreviewWhatsapp(null)
                            getWhatsappPreview(whatsappReminder, "WHATSAPP_VACCINE_REMINDER")
                            .then(message => setPreviewWhatsapp(message))
                        }
    
                        if (showPreviewSMS) {
                            setPreviewSMS(null)
                            getSmsPreview(smsReminder, "SMS_VACCINE_REMINDER")
                            .then(message => setPreviewSMS(message))
                        }
    
                        if (showPreviewEmail) {
                            setPreviewEmail(null)
                            getEmailPreview(emailReminder, "EMAIL_VACCINE_REMINDER")
                            .then(message => setPreviewEmail(message))
                        }
                    }
                }, 1000)
            }
        }
    }, [ reminders, vaccineType, reminderDate, showPreviewWhatsapp, showPreviewSMS, showPreviewEmail ])

    useEffect(() => {
        onChange && onChange()
    }, [ 
        sendAutomaticReminderIsActive, errorSendAutomaticReminderIsActive, 
        sendAutomaticReminderByWhatsapp, sendAutomaticReminderBySMS, sendAutomaticReminderByEmail,
        sendAutomaticReminderDateText, sendAutomaticReminderDate, errorSendAutomaticReminderDate, 
        phoneOwner, errorPhoneOwner, 
        emailOwner, errorEmailOwner
    ])

    useEffect(() => {
        if (vaccine) {
            const owner = vaccine.get("patient")?.get("owner")
            setPhoneOwner(owner?.get("phone") || "")
            setEmailOwner(owner?.get("email") || "")

            const defaultReminder = {
                "user": Parse.User.current().id,
                "clinic": clinic.id,
                "owner": owner.id,
                "patient": patient.id,
                "createdFromClass": vaccine.className,
                "createdFromId": vaccine.id,
                "scheduledAt": null,
            }
            
            const defaultSmsReminder = Object.assign(
                { ...defaultReminder },
                {
                    "messageTemplateId": messageTemplateIds["SMS"],
                    "messageTypeId": "SMS",
                    "sentTo": "phoneOwner"
                }
            )
            
            const defaultWhatsappReminder = Object.assign(
                { ...defaultReminder },
                {
                    "messageTemplateId": messageTemplateIds["WHATSAPP"],
                    "messageTypeId": "WHATSAPP",
                    "sentTo": "phoneOwner"
                }
            )
            
            const defaultEmailReminder = Object.assign(
                { ...defaultReminder },
                {
                    "messageTemplateId": messageTemplateIds["EMAIL"],
                    "messageTypeId": "EMAIL",
                    "sentTo": "emailOwner"
                }
            )
            
            var _reminders = {}
            if (vaccine.get('messages') && vaccine.get('messages').length > 0) {
                vaccine.get('messages').forEach(message => {
                    if(!message){
                        return
                    }

                    const key = message.get('messageTypeId').toLowerCase() + 'Reminder'
                    _reminders[key] = {
                        objectId: message.id,
                        scheduledAt: message.get('scheduledAt'),
                        patient: message.get('patient'),
                        createdFromClass: message.get('createdFromClass'),
                        clinic: message.get('clinic'),
                        user: message.get('user'),
                        createdFromId: message.get('createdFromId'),
                        sentTo: message.get('sentTo'),
                        owner: message.get('owner'),
                        messageTypeId: message.get('messageTypeId'),
                        messageTemplateId: message.get('messageTemplateId'),
                        checked: true
                    }
                })
            }

            const firstScheduledAt = getFirstScheduledAtFromArray(vaccine.get('messages'));
            if(firstScheduledAt){
                handleSendAutomaticReminderDate(dayjs(firstScheduledAt).format("DD/MM/YYYY"))
                setSendAutomaticReminderIsActive(true)
            }

            if(!_reminders.smsReminder){
                _reminders.smsReminder = defaultSmsReminder
            }
            if(!_reminders.whatsappReminder){
                _reminders.whatsappReminder = defaultWhatsappReminder
            }
            if(!_reminders.emailReminder){
                _reminders.emailReminder = defaultEmailReminder
            }

            setReminders(_reminders)
            setLoadingReminders(false)
        }
    }, [ vaccine ])

    return vaccineType && reminderDate && (
        <div className="row align-items-center mb-2">
            {getAdviceForLimit()}
            <div className="col-12">
                <div className="row mb-3">
                    <div className="col-12 col-md pe-md-1">
                        <VSAccordionCard
                            expanded={expandedInfo}
                            onChange={(expanded) => setExpandedInfo(expanded)}
                            icon={<IconText width="20px" height="20px" />}
                            title="Textos Enviados"
                            text={<>O texto dos lembretes que serão enviados tanto por 
                                WhatsApp, como E-mail e SMS para o(a) tutor(a) não 
                                podem ser editados</>}
                        />
                    </div>
                    <div className="col-12 col-md px-md-1 mt-3 mt-md-0">
                        <VSAccordionCard
                            expanded={expandedInfo}
                            onChange={(expanded) => setExpandedInfo(expanded)}
                            icon={<IconSchedule width="20px" height="20px" />}
                            title="Horário do Envio"
                            text={<>As mensagens serão enviadas 
                                automaticamente <b>no período da manhã</b> do 
                                dia escolhido para envio do lembrete</>}
                        />
                    </div>
                    <div className="col-12 col-md ps-md-1 mt-3 mt-md-0">
                        <VSAccordionCard
                            expanded={expandedInfo}
                            onChange={(expanded) => setExpandedInfo(expanded)}
                            icon={<IconContact width="20px" height="20px" />}
                            title="Dados para Envio"
                            text={<>As mensagens serão enviadas para os dados 
                                de contato que estiverem no cadastro do(a) 
                                Tutor(a) no dia do envio</>}
                        />
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className="col-12">
                        <div className="input-title">
                            Data de envio do Lembrete Automático
                        </div>
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className="col-12 col-sm pe-sm-0 pt-3">
                        <div className="row">
                            <div className="col pe-1 mb-3">
                                { loadingReminders ? (
                                    <Skeleton variant="text" height={52} />
                                ) : (
                                    <button
                                        className={`btn-outline-submit btn-select ${!reminderDate && "btn-disabled"} ${sendAutomaticReminderDateText == threeDaysBefore ? "selected" : ""}`}
                                        onClick={() => reminderDate && handleSendAutomaticReminderDate(threeDaysBefore)}
                                    >
                                        3 dias<br/> antes
                                    </button>
                                )}
                            </div>
                            <div className="col px-1 mb-3">
                                { loadingReminders ? (
                                    <Skeleton variant="text" height={52} />
                                ) : (
                                    <button
                                        className={`btn-outline-submit btn-select ${!reminderDate && "btn-disabled"} ${sendAutomaticReminderDateText == fiveDaysBefore ? "selected" : ""}`}
                                        onClick={() => reminderDate && handleSendAutomaticReminderDate(fiveDaysBefore)}
                                    >
                                        5 dias<br/> antes
                                    </button>
                                )}
                            </div>
                            <div className="col px-1 mb-3">
                                { loadingReminders ? (
                                    <Skeleton variant="text" height={52} />
                                ) : (
                                    <button
                                        className={`btn-outline-submit btn-select ${!reminderDate && "btn-disabled"} ${sendAutomaticReminderDateText == sevenDaysBefore ? "selected" : ""}`}
                                        onClick={() => reminderDate && handleSendAutomaticReminderDate(sevenDaysBefore)}
                                    >
                                        7 dias<br/> antes
                                    </button>
                                )}
                            </div>
                            <div className="col ps-1 mb-3">
                                { loadingReminders ? (
                                    <Skeleton variant="text" height={52} />
                                ) : (
                                    <button
                                        className={`btn-outline-submit btn-select ${!reminderDate && "btn-disabled"} ${sendAutomaticReminderDateText == fiveteenDaysBefore ? "selected" : ""}`}
                                        onClick={() => reminderDate && handleSendAutomaticReminderDate(fiveteenDaysBefore)}
                                    >
                                        15 dias<br/> antes
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-auto vaccine-text mb-3 mb-sm-0">
                        OU
                    </div>
                    <div className="col-auto ps-0 mb-3 mb-sm-0" style={{ width: "150px" }}>
                        { loadingReminders ? (
                            <Skeleton variant="text" height={40} />
                        ) : (
                            <DateInput
                                placeholder="00/00/0000"
                                value={sendAutomaticReminderDateText || null}
                                error={errorSendAutomaticReminderDate}
                                onChange={(value) => handleSendAutomaticReminderDate(value)}
                                disabled={!reminderDate}
                                size="small"
                                variant="outlined"
                                fullWidth
                            />
                        )}
                    </div>
                    { errorSendAutomaticReminderDate && (
                        <div className="col-12">
                            <small className="text-danger">{ helperSendAutomaticReminderDate }</small>
                        </div>
                    )}
                </div>
                <VSMessage
                    ref={MessageForm}
                    history={history}
                    clinic={clinic}
                    owner={owner}
                    ownerSeparator={false}
                    showPersonalWhatsAppButton={false}
                    onMessageLimitError={(limit) => <>Você chegou ao limite de cadastrar <b>{ limit } Lembretes para Tutores</b> via WhatsApp, SMS e E-mail por mês.</>}
                    onMessageLimitAdvice={(limit) => <>Você tem direito de cadastrar <b>{ limit } Lembretes para Tutores</b> via WhatsApp, SMS ou E-mail por mês.</>}
                    onChange={() => {
                        onChange && onChange()
                    }}
                    messages={[
                        {
                            clinic: clinic.id,
                            owner: owner?.id,
                            patient: patient?.id,
                            createdFromClass: "Vaccine",
                            createdFromId: vaccine?.id,
                            title: "Enviar Cópia Digital",
                            checked: true,
                            messageTypeId: "SEND",
                            messages: [
                                {
                                    ...reminders?.whatsappReminder,
                                    title: "Enviar Lembrete por WhatsApp",
                                    withSeparator: true
                                },
                                {
                                    ...reminders?.smsReminder,
                                    title: "Enviar Lembrete por SMS",
                                    withSeparator: true
                                },
                                {
                                    ...reminders?.emailReminder,
                                    title: "Enviar Lembrete por E-mail",
                                    withSeparator: false
                                }
                            ]
                        }
                    ]}
                />
            </div>
        </div>
    )
})

export default AutomaticReminderForm;