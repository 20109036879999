import { forwardRef, useEffect, useImperativeHandle, useRef } from "react"

import WeekHourField from "../fields/WeekHourField"

import "./VetTimeForm.scss"
const VetTimeForm = forwardRef((props, ref) => {

    const {
        clinicUser,
        onChange
    } = props

    const timeRef = useRef(null)
    
    useImperativeHandle(ref, () => ({
        validate: validate,
        fieldsWithErrorText: fieldsWithErrorText,
        getTimes: getTimes
     }));

    const validate = (isValid) => {
        return timeRef.current?.validate(isValid) || isValid
    }

    const fieldsWithErrorText = (errors) => {
        return timeRef.current?.fieldsWithErrorText(errors) || errors
    }

    const getTimes = () => {
        return timeRef.current?.getTimes()
    }

    return (
        <>
            <div className="row g-3 align-items-center">
                <div className="col-12">
                    <div className="input-title">Horário de trabalho</div>
                </div>
                <div className="col-12">
                    <WeekHourField
                        ref={timeRef}
                        workHours={clinicUser?.get("workHours")}
                        onChange={onChange}
                    />
                </div>
            </div>
        </>
    )
})

export default VetTimeForm