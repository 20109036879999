import React, { useRef, useState, useEffect } from "react"
import { connect } from "react-redux"

import { ReactComponent as IconAdd } from "../../assets/icon-primary-add-o.svg"
import { ReactComponent as IconPatientBalance } from '../../assets/icon-patient-weight.svg'

import { ACL, OrderBy_Oldest, OrderBy_Newest } from "../../constants"
import { SALE, paymentOptions } from "../patient/tabs/sale/Sale"
import { queryForSearchByPatientAndOwner } from "../patient/Patient"
import { searchServices } from "../service/Service"
import { dayjs, diacriticSensitive } from "../../utils"

import AccountReceivableView from "./AccountReceivableView"
import VSEmpty from "../../components/vsEmpty/VSEmpty"
import VSList from "../../components/vsList/VSList"
import VSAccordionSimple from "../../components/vsAccordion/VSAccordionSimple"
import VSTimeline from "../../components/vsTimeline/VSTimeline"
import VSBoxPatient from "../../components/cardPatient/VSBoxPatient"
import CardFeedback from "../../components/cardFeedback/CardFeedback"

import "./AccountsReceivableList.scss"
const AccountsReceivableList = (props) => {

    const {
        clinic,
        history,
        permissions
    } = props
    
    const rowsPerPage = 5

    const filter = useRef(null)
    const isMounted = useRef(null)

    const [ salesByPatient, setSalesByPatient ] = useState({})
    
    const [ paymentTypes, setPaymentTypes ] = useState([])
    const [ services, setServices ] = useState([])

    const getSale = (searchTerm, dateFrom, dateTo, orderBy, page = 1) => {
        return new Promise((resolve, reject) => {
            const filters = {}
            if (services.length > 0) {
                filters.services = {
                    objectId: {
                        $in: services.map(service => service.value)
                    }
                }
            }
            if (paymentTypes.length > 0) {
                filters.payments = {
                    type: {
                        $in: paymentTypes.map(payment => payment.value)
                    }
                }
            }
            Parse.Cloud.run("getPeriodForClass", {
                clinicId: clinic.objectId,
                className: "VW_MRSale_payments_pending",
                filters: filters,
                searchByPatientAndOwner: diacriticSensitive(searchTerm),
                dateFrom: dateFrom,
                dateTo: dateTo,
                order: orderBy == OrderBy_Newest ? "newest" : "oldest",
                page: page
            })
            .then(({ total, from, to }) => {
                const query = new Parse.Query("VW_MRSale_payments_pending")
                query.equalTo("clinic", clinic.object);
                const queryPatient = queryForSearchByPatientAndOwner(searchTerm, clinic.object)
                if (queryPatient) {
                    query.matchesQuery("patient", queryPatient)
                }
                if (services.length > 0) {
                    const queryServices = services.map(service => service.value)
                    query.containedIn("services.objectId", queryServices)
                }
                if (paymentTypes.length > 0) {
                    const queryPaymentTypes = paymentTypes.map(payment => payment.value)
                    query.containedIn("payments.type", queryPaymentTypes)
                }
                query.greaterThanOrEqualTo("documentDate", from)
                query.lessThanOrEqualTo("documentDate", to)
                switch (orderBy) {
                    case OrderBy_Oldest:
                        query.ascending("documentDate");
                        break;
                    default:
                        query.descending("documentDate");
                }
                query.include("patient")
                query.include("patient.owner")
                query.include("createdBy")
                query.include("changedBy")
                query.find()
                .then(results => {
                    let salesByDate = {}
                    let dates = []
                    results.map(sale => {
                        const date = dayjs(sale.get("documentDate")).format("DD/MM/YYYY")
                        const patient = sale.get("patient")
                        if (!salesByDate[date]) {
                            dates.push(date)
                            salesByDate[date] = []
                        }
                        if (!salesByDate[date][patient.id]) {
                            salesByDate[date][patient.id] = {
                                patient: patient,
                                sales: [sale]
                            }
                        } else {
                            salesByDate[date][patient.id].sales.push(sale)
                        }
                    })
                    setSalesByPatient(salesByDate)
                    resolve({
                        items: dates,
                        total: total
                    })
                })
                .catch(error => {
                    console.error(error)
                    return Promise.reject(error)
                })
            })
        })
    }

    const onChange = () => {
        if (filter.current) {
            filter.current.onChange()
        }
    }

    useEffect(() => {
        if (permissions && !permissions.check(ACL.INFLOW, ACL.Rule.VIEW)) {
            permissions.error()
        }
    }, [ permissions ])

    useEffect(() => {
        isMounted.current = true
        return () => { 
            isMounted.current = false
        }
    }, [])

    return (
        <>
            <div id="accounts-receivable-list" className="flex-grow-1">
                <div className="col-12">
                    <VSList
                        ref={filter}
                        title={{
                            text: "Contas a Receber"
                        }}
                        button={permissions?.check(ACL.SALE, ACL.Rule.EDIT) && {
                            text: "Criar Nova Venda",
                            onClick: () => history.push(`/animal/selecionar/venda`, { redirectTo: history.location?.pathname })
                        }}
                        search={{
                            text: "Buscar por Nome do Animal ou Tutor(a)",
                            value: ""
                        }}
                        interval={{
                            start: {
                                text: "De"
                            },
                            end: {
                                text: "Até"
                            }
                        }}
                        order={{
                            options: [
                                OrderBy_Newest,
                                OrderBy_Oldest
                            ],
                            value: OrderBy_Newest
                        }}
                        VSFilterProps={{
                            title: `Filtrar Resultados`,
                            onClear: () => {
                                
                            },
                            onFilter: () => {
                                onChange()
                            },
                            filters: [
                                {
                                    title: "Serviços",
                                    defaultValue: [],
                                    value: services,
                                    onChange: (values) => {
                                        setServices(values)
                                    },
                                    onSearch: (term) => {
                                        return new Promise((resolve, reject) => {
                                            searchServices(term, clinic.object)
                                            .then(({ results, count }) => {
                                                if (results) {
                                                    const options = results.map(service => {
                                                        const id = service.get("serviceId") ? `ID ${ service.get("serviceId") } - ` : ""
                                                        return {
                                                            value: service.id,
                                                            label: `${ id }${service.get("serviceName")}`
                                                        }
                                                    })
                                                    return resolve({
                                                        count: count,
                                                        options: options
                                                    })
                                                }
                                                return resolve([])
                                            })
                                            .catch(error => {
                                                console.error(error)
                                                return resolve([])
                                            })
                                        })
                                    },
                                    options: [],
                                    multiple: {
                                        title: "Serviços",
                                        button: "Selecione um serviço",
                                        placeholder: "Procure pelo serviço"
                                    }
                                },
                                {
                                    title: "Formas de pagamento",
                                    defaultValue: [],
                                    value: paymentTypes,
                                    onChange: (values) => {
                                        setPaymentTypes(values)
                                    },
                                    options: paymentOptions,
                                    multiple: true
                                }
                            ].filter(val => val)
                        }}
                        pagination={{
                            rowsPerPage: rowsPerPage
                        }}
                        onChange={({ _searchBy, _start, _end, _orderBy, _page }) => {
                            return getSale(_searchBy, _start, _end, _orderBy, _page)
                        }}
                        renderItem={(item, expanded) => {
                            const date = dayjs(item, "DD/MM/YYYY")
                            return (
                                <VSTimeline
                                    day={ date.format("DD") }
                                    month={ date.format("MMM") }
                                    year={ date.format("YYYY") }
                                >
                                    { salesByPatient && salesByPatient[item] && Object.keys(salesByPatient[item]).map((key, index) => {
                                        const data = salesByPatient[item][key]
                                        const patient = data.patient
                                        const sales = data.sales
                                        return (
                                            <React.Fragment key={index}>
                                                <VSAccordionSimple
                                                    expanded={true}
                                                    className="custom-accordion"
                                                    hideIcon
                                                    header={(expanded) => (
                                                        <div className={`vstimeline-item vstimeline-active w-100`}>
                                                            <VSBoxPatient
                                                                count={sales.length}
                                                                patient={patient}
                                                                expanded={expanded}
                                                            />
                                                        </div>
                                                    )}
                                                >
                                                    { sales && sales.map((data, index) => {
                                                        return (
                                                            <div key={index} className={`${data.get("isDeleted") ? "vstimeline-item vstimeline-inactive" : ""}`}>
                                                                <AccountReceivableView
                                                                    object={data}
                                                                    expanded={expanded}
                                                                    history={history}
                                                                    updateList={onChange}
                                                                    noDocumentDate
                                                                    noDocumentType
                                                                    noAudit
                                                                />
                                                            </div>
                                                        )
                                                    }) }
                                                </VSAccordionSimple>
                                            </React.Fragment>
                                        )
                                    }) }
                                </VSTimeline>
                            )
                        }}
                        renderEmpty={() => (
                            <VSEmpty
                                icon={<IconPatientBalance width="32px" height="32px" />}
                                title={`Você ainda não possui nenhuma venda`}
                                text={`Cadastre a primeira venda para visulizá-la aqui`}
                            >
                                <button
                                    id="new-sale-link"
                                    className="vsbox-btn vsbox-btn-primary"
                                    onClick={() => {
                                        history.push(`/animal/selecionar/venda`, { redirectTo: history.location?.pathname })
                                    }}
                                >
                                    <IconAdd width="14" height="14" className="me-2" />
                                    Cadastrar nova venda
                                </button>
                            </VSEmpty>
                        )}
                        renderNotFound={() => (
                            <VSEmpty
                                icon={<IconPatientBalance width="32px" height="32px" />}
                                title="Não encontramos nenhum resultado para a sua busca"
                                text="Tente refazer sua busca utilizando outro período ou crie um novo cadastro utilizando o botão abaixo"
                            >
                                <button
                                    id="new-sale-link"
                                    className="vsbox-btn vsbox-btn-primary"
                                    onClick={() => {
                                        history.push(`/animal/selecionar/venda`, { redirectTo: history.location?.pathname })
                                    }}
                                >
                                    <IconAdd width="14" height="14" className="me-2" />
                                    Cadastrar nova venda
                                </button>
                            </VSEmpty>
                        )}
                    />
                </div>
            </div>
            {salesByPatient && Object.keys(salesByPatient).length > 0 && (
                <CardFeedback contentDetail={history.location?.pathname || "AccountsReceivableList"} />
            )}
        </>
    )
}

const mapStateToProps = state => {
    return {
        clinic: state.clinic,
        permissions: state.permissions
    };
};
  
export default connect(mapStateToProps)(AccountsReceivableList)