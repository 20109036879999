import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';

import { MenuItem, TextField } from '@mui/material';

import { ReactComponent as IconExclamationO } from "../../assets/icon-danger-exclamation-o.svg"

import { addEvent, dayjs, formattedCurrency, mandatoryField, saveOnTracker } from '../../utils';
import { getProntuarioPro } from '../../store/actions';
import { BRAND_EMPTY, PRO_MONTHLY, PRO_MONTHLY_VALUE_BY_MONTH, PRO_YEARLY, PRO_YEARLY_BY_YEAR, brandOptions, getBrand, getInstallmentsOfProntuarioProYearly } from './Payment';

import VSDrawer from '../../components/vsDrawer/VSDrawer';
import TextFieldMask from '../../components/textFieldMask/TextFieldMask';
import VSAccordionSimple from '../../components/vsAccordion/VSAccordionSimple';

import './ProPaymentForm.scss'
const ProPaymentForm = (props) => {

    const {
        pro,
        user,
        clinic,
        history
    } = props

    const location = useLocation()

    const [ product, setProduct ] = useState(null)
    const [ newProduct, setNewProduct ] = useState(null)

    const [ subscription, setSubscription ] = useState(null)

    const [ saveLoading, setSaveLoading ] = useState(false)
    const [ hasSubmited, setHasSubmited ] = useState(false)

    const [ brand, setBrand ] = useState(BRAND_EMPTY)

    const [ cardNumber, setCardNumber ] = useState("")
    const [ errorCardNumber, setErrorCardNumber ] = useState(false)
    const [ helperCardNumber, setHelperCardNumber ] = useState(mandatoryField)

    const cardNumberDigits = cardNumber.replace(/[^0-9]/g, "")

    const [ cardName, setCardName ] = useState("")
    const [ errorCardName, setErrorCardName ] = useState(false)
    const [ helperCardName, setHelperCardName ] = useState(mandatoryField)

    const [ cardExpires, setCardExpires ] = useState("")
    const [ errorCardExpires, setErrorCardExpires ] = useState(false)
    const [ helperCardExpires, setHelperCardExpires ] = useState(mandatoryField)

    const cardExpiresDate = dayjs(`01/${cardExpires}`, "DD/MM/YY").endOf("month")

    const [ cardCVC, setCardCVC ] = useState("")
    const [ errorCardCVC, setErrorCardCVC ] = useState(false)
    const [ helperCardCVC, setHelperCardCVC ] = useState(mandatoryField)

    const [ installments, setInstallments ] = useState("1")
    const [ errorInstallments, setErrorInstallments ] = useState(false)
    const [ helperInstallments, setHelperInstallments ] = useState(mandatoryField)

    const getNextBillingAt = () => {
        if (dayjs(subscription?.start_at).isAfter(dayjs(), "day")) {
            return dayjs(subscription?.start_at).format("DD/MM/YYYY")
        } else if (dayjs(subscription?.next_billing_at).isAfter(dayjs(), "day")) {
            return dayjs(subscription?.next_billing_at).format("DD/MM/YYYY")
        } else {
            return dayjs().add(1, "day").format("DD/MM/YYYY")
        }
    }

    const validateOnTouch = () => {
        if (hasSubmited) {
            validate()
        }
    }

    const validate = () => {
        let isValid = true
        if (saveLoading) {
            isValid = false
        }

        if (cardNumber.length < 13) {
            isValid = false
            setErrorCardNumber(true)
            setHelperCardNumber(`O número do cartão ${cardNumber.length ? "não é válido" : "é obrigatório"}`)
        } else if (brand == BRAND_EMPTY) {
            isValid = false
            setErrorCardNumber(true)
            setHelperCardNumber(`Não conseguimos identificar a bandeira do cartão informado, por favor entre em contato com o nosso suporte`)
        } else {
            setErrorCardNumber(false)
            setHelperCardNumber(mandatoryField)
        }

        if (cardName.length < 5) {
            isValid = false
            setErrorCardName(true)
            setHelperCardName(`O nome impresso no cartão ${cardName.length ? "não pode ser menor que 5 caracteres" : "é obrigatório"}`)
        } else {
            setErrorCardName(false)
            setHelperCardName(mandatoryField)
        }

        if (cardExpires.length < 5) {
            isValid = false
            setErrorCardExpires(true)
            setHelperCardExpires(`A validade do cartão ${cardExpires.length ? "não é válida" : "é obrigatória"}`)
        } else {
            const now = dayjs().endOf("day")

            if (cardExpiresDate.diff(now, "month", true) < 0) {
                isValid = false
                setErrorCardExpires(true)
                setHelperCardExpires(`A validade do cartão não pode ser menor que o mês atual`)
            } else if (cardExpiresDate.diff(now, "years", true) > 20) {
                isValid = false
                setErrorCardExpires(true)
                setHelperCardExpires(`A validade do cartão não pode ser maior que 20 anos`)
            } else {
                setErrorCardExpires(false)
                setHelperCardExpires(mandatoryField)
            }
        }

        if (cardCVC.length < 3) {
            isValid = false
            setErrorCardCVC(true)
            setHelperCardCVC(`O código de segurança do cartão ${cardCVC.length ? "não pode ser menor que 3 caracteres" : "é obrigatório"}`)
        } else {
            setErrorCardCVC(false)
            setHelperCardCVC(mandatoryField)
        }

        if (parseInt(installments) >= 1 && parseInt(installments) <= 12) {
            setErrorInstallments(false)
            setHelperInstallments(mandatoryField)
        } else {
            setErrorInstallments(true)
            setHelperInstallments("A quantidade de parcelas é obrigatória")
        }

        return isValid
    }

    const isValid = () => !errorCardNumber && !errorCardName && !errorCardExpires && !errorCardCVC && !errorInstallments

    const fieldsWithErrorText = () => {
        let errors = []
        if (saveLoading) {
            errors.push("Salvando a contratação")
        }
        if (errorCardNumber) {
            errors.push("Número do cartão de crédito")
        }
        if (errorCardName) {
            errors.push("Nome impresso no cartão")
        }
        if (errorCardExpires) {
            errors.push("Validade do cartão")
        }
        if (errorCardCVC) {
            errors.push("Código de segurança do cartão")
        }
        if (errorInstallments) {
            errors.push("Quantidade de parcelas")
        }
        return errors
    }

    const save = () => {
        setHasSubmited(true)
        return new Promise((resolve, reject) => {
            if (validate()) {
                if (!saveLoading) {
                    setSaveLoading(true)
                    Parse.Cloud.run("updateVindiPayment", {
                        planCode: newProduct || product,
                        productCode: newProduct || product,
                        clinicId: clinic.objectId,
                        subscriptionId: subscription?.id,
                        paymentId: subscription?.payment_profile?.id,
                        installments: installments,
                        cardBrand: brand,
                        cardNumber: cardNumberDigits,
                        cardName: cardName,
                        cardExpires: cardExpiresDate.format("MM/YYYY"),
                        cardCVC: cardCVC
                    })
                    .then(clinic => {
                        if (newProduct) {
                            saveOnTracker("Concluiu", "Assinatura", String(subscription?.id), "Alteração de plano", null, null, null, newProduct)
                        }
                        setSaveLoading(false)
                        props.dispatch(getProntuarioPro(clinic))

                        addEvent("Subscription__getList", {})

                        return resolve(clinic)
                    })
                    .catch(error => {
                        setSaveLoading(false)
                        console.error(error)
                        Swal.fire({
                            icon: "error",
                            title: 'Desculpe',
                            text: error.code == 141 ? error.message : 'Não foi possível efetuar o pagamento, por favor, tente novamente',
                            footer: '<a href="https://pl-vetsmart.zendesk.com/hc/pt-br/categories/4410355279771-Prontu%C3%A1rio" target="_blank">Fale conosco</a>'
                        })
                        return reject()
                    })
                }
            } else {
                reject()
            }
        })
    }

    useEffect(() => {
        validateOnTouch()
    }, [ 
        brand, cardNumber, cardName, cardExpires, cardCVC, installments
    ])

    useEffect(() => {
        setBrand(getBrand(cardNumberDigits))
    }, [ cardNumber ])

    useEffect(() => {
        if (location.state?.subscription) {
            setSubscription(location.state.subscription)
        } else {
            Swal.fire(
                'Desculpe',
                'Não foi possível obter as informações do meio de pagamento',
                'error'
            )
            .then(_ => {
                history.push("/configuracao-clinica/plano-e-cobranca")
            })
        }
    }, [ location ])

    useEffect(() => {
        if (subscription) {
            if ((newProduct && newProduct == PRO_MONTHLY) || (!newProduct && product == PRO_MONTHLY)) {
                setInstallments("1")
            } else {
                setInstallments(subscription.installments || "1")
            }
        }
    }, [ product, newProduct ])

    useEffect(() => {
        if (subscription) {
            const productCode = subscription?.product_items?.[0]?.product?.code
            setProduct(productCode == PRO_YEARLY ? PRO_YEARLY : PRO_MONTHLY)
        }
    }, [ subscription ])

    const errors = fieldsWithErrorText()

    return (
        <VSDrawer
            id="vspro-payment"
            width="50%"
            title="Editar Assinatura"
            cancel="Cancelar"
            submit={isValid ? "Salvar Alterações" : <span>Preencha os campos<span className="d-none d-md-inline"> necessários</span></span>}
            errors={errors}
            onSubmit={save}
            timeout={3000}
            onCancel={() => history.goBack()}
            onAfterSave={(object) => history.push("/configuracao-clinica/plano-e-cobranca")}
            VSDrawerSuccessProps={{
                title: "Alteração realizada com sucesso!"
            }} 
            VSDrawerCancelProps={{
                title: "Tem certeza que deseja cancelar as alterações? ",
                confirm: "Cancelar Alterações",
                cancel: "Voltar para Edição do Cadastro"
            }}
        >
            <div className="row">
                <div className="col-12">
                    <div className="row g-3">
                        <div className="col-12 col-md">
                            <div
                                className={`vsproduct${product == PRO_MONTHLY ? " vsproduct-current" : ""}${newProduct == PRO_MONTHLY ? " vsproduct-selected" : ""}`}
                                onClick={() => product != PRO_MONTHLY && setNewProduct(newProduct ? null : PRO_MONTHLY)}
                            >
                                <div className="vsproduct-badge">
                                    <div>Mensal</div>
                                </div>
                                <div className="vsproduct-body">
                                    <div className="vsproduct-discount"></div>
                                    <div className="vsproduct-pricing">
                                        R$ <b>49,90</b>
                                    </div>
                                    mensal no Cartão
                                </div>
                                <div className="vsproduct-footer"></div>
                            </div>
                        </div>
                        <div className="col-12 col-md">
                            <div
                                className={`vsproduct${product == PRO_YEARLY ? " vsproduct-current" : ""}${newProduct == PRO_YEARLY ? " vsproduct-selected" : ""}`}
                                onClick={() => product != PRO_YEARLY && setNewProduct(newProduct ? null : PRO_YEARLY)}
                            >
                                <div className="vsproduct-badge">
                                    <div>Anual</div>
                                    <span className="px-2">Ganhe 20% de desconto</span>
                                </div>
                                <div className="vsproduct-body text-start">
                                    <div className="vsproduct-discount">
                                        R$ 598,80
                                    </div>
                                    <div className="vsproduct-pricing">
                                        12x de R$ <b>39,90</b>
                                    </div>
                                    ou R$ 478,80 à vista
                                </div>
                                <div className="vsproduct-footer"></div>
                            </div>
                        </div>
                    </div>
                    { newProduct && (
                        <div className="col-12 mt-3">
                            <div className="subscription-change">
                                <div className="row">
                                    <div className="col-auto pe-0">
                                        <IconExclamationO width={24} height={24} />
                                    </div>
                                    <div className="col">
                                        Ao salvar as alterações do seu plano, você pagará <b>{ newProduct == PRO_MONTHLY ? formattedCurrency(PRO_MONTHLY_VALUE_BY_MONTH) : formattedCurrency(PRO_YEARLY_BY_YEAR) }</b> e a primeira cobrança será feita a partir de <b>{ getNextBillingAt() }</b>.
                                    </div>   
                                </div>
                            </div>
                        </div>
                    )}
                    <hr className="mt-4 mb-2" />
                </div>
                <div className="col-12">
                    <VSAccordionSimple
                        expanded={true}
                        header={(_) => (
                            "Dados de Pagamento"
                        )}
                    >
                        <div className="row g-3">
                            <div className="col-auto">
                                <TextField
                                    placeholder="ex: Mastercard"
                                    label="Bandeira"
                                    value={brand}
                                    onChange={({ target: { value } }) => {
                                        setBrand(value)
                                    }}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    select
                                >
                                    { brandOptions.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            { option.label }
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>
                            <div className="col">
                                <TextFieldMask
                                    type="tel"
                                    mask={"0000 0000 0000 00000"}
                                    value={cardNumber}
                                    onAccept={(value) => setCardNumber(value)}
                                    placeholder="1234 1234 1234 1234"
                                    label="Número do Cartão"
                                    error={errorCardNumber}
                                    helperText={helperCardNumber}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                />
                            </div>
                            <div className="col-12">
                                <TextField
                                    placeholder="ex: JOAO DA SILVA"
                                    label="Nome Impresso no Cartão"
                                    value={cardName}
                                    onChange={({ target: { value } }) => setCardName(value)}
                                    error={errorCardName}
                                    helperText={helperCardName}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                />
                            </div>
                            <div className="col-12 col-lg-6">
                                <TextFieldMask
                                    type="tel"
                                    mask="00/00"
                                    value={cardExpires}
                                    onAccept={(value) => setCardExpires(value)}
                                    placeholder="00/00"
                                    label="Validade do Cartão"
                                    error={errorCardExpires}
                                    helperText={helperCardExpires}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                />
                            </div>
                            <div className="col-12 col-lg-6">
                                <TextFieldMask
                                    type="tel"
                                    mask="0000"
                                    value={cardCVC}
                                    onAccept={(value) => setCardCVC(value)}
                                    placeholder="000"
                                    label="Código de Segurança"
                                    error={errorCardCVC}
                                    helperText={helperCardCVC}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                />
                            </div>
                            { ((!newProduct && product == PRO_YEARLY) || (newProduct && newProduct == PRO_YEARLY)) && (
                                <div className="col-12">
                                    <TextField
                                        placeholder="Selecione a quantidade de parcelas"
                                        label="Número de Parcelas"
                                        value={installments}
                                        onChange={({ target: { value } }) => setInstallments(value)}
                                        error={errorInstallments}
                                        helperText={helperInstallments}
                                        select
                                        size="small"
                                        variant="outlined"
                                        fullWidth
                                    >
                                        { getInstallmentsOfProntuarioProYearly().map(_installment => (
                                            <MenuItem key={_installment.value} value={_installment.value}>
                                                { _installment.label }
                                            </MenuItem>
                                        )) }
                                    </TextField>
                                </div>
                            )}
                        </div>
                    </VSAccordionSimple>
                </div>
            </div>
        </VSDrawer>
    )
}

const mapStateToProps = state => {
    return {
        user: state.user,
        clinic: state.clinic,
        pro: state.featuresPro
    };
};

export default connect(mapStateToProps)(ProPaymentForm)