import React, { useEffect, useState } from 'react';
import { withStyles } from '@mui/styles';
import {
    Checkbox,
    FormGroup,
    FormControlLabel 
} from '@mui/material';
import { ReactComponent as IconCheckboxUnchecked } from '../../assets/icon-checkbox-unchecked.svg'
import { ReactComponent as IconCheckboxChecked } from '../../assets/icon-checkbox-checked.svg'

const VSCheckboxInput = withStyles((theme) => ({
    root: {
        padding: "4px 9px",
        color: theme.palette.primary.main,
        '&$checked': {
            color: theme.palette.primary.main,
        },
    },
    checked: {},
}))((props) => (
    <Checkbox
        icon={<IconCheckboxUnchecked width="20px" height="20px" />}
        checkedIcon={<IconCheckboxChecked width="20px" height="20px" fill={props.disabled ? "var(--vsprontuario-gray-color-light)" : "var(--vsprontuario-primary-color)"} /> }
        {...props}
    />
));

const VSCheckboxLabel = withStyles((theme) => ({
    root: {
        marginBottom: 0
    },
    label: {
        fontFamily: 'Open Sans',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '19px',
        color: 'var(--vsprontuario-gray-color-dark)',
    },
}))(FormControlLabel);

const VSCheckbox = (props) => {

    const {
        name,
        label,
        position,
        onChange,
        className,
        disabled,
        checked
    } = props

    return (
        <FormGroup row className={className}>
            <VSCheckboxLabel
                control={
                    <VSCheckboxInput
                        disabled={disabled}
                        name={name}
                        checked={checked || false}
                        onChange={({ target: { checked } }) => onChange?.(checked)}
                    />
                }
                label={label}
                labelPlacement={position || "end"}
                className="w-100"
            />
        </FormGroup>
    )
}

export default VSCheckbox