import React, { useEffect, useState } from "react";
import { useParams, useLocation } from 'react-router-dom';
import { connect } from "react-redux";

import { CircularProgress } from '@mui/material'

import { validateDate, showDate, showRegisteredBy, getAttachments } from "../../../../utils";
import { getPatient } from "../../Patient";
import { createVaccine, getVaccine, isReminderLate, deleteVaccine } from "./Vaccine";
import { getFirstScheduledAtFromArray, getFirstSentAtFromArray } from "./VaccineListView";
import { ACL } from "../../../../constants";

import { ReactComponent as IconEdit } from "../../../../assets/icon-primary-edit.svg"
import { ReactComponent as IconTrash } from "../../../../assets/icon-primary-trash.svg"
import { ReactComponent as IconEye } from "../../../../assets/icon-primary-eye.svg"
import { ReactComponent as IconResend } from "../../../../assets/icon-resend.svg"
import { ReactComponent as IconNotification } from "../../../../assets/icon-notification.svg"
import { ReactComponent as IconNotificationOn } from "../../../../assets/icon-notification-on.svg"
import { ReactComponent as IconNotificationOff } from "../../../../assets/icon-notification-off.svg"

import VSAccordionSimple from '../../../../components/vsAccordion/VSAccordionSimple'
import VSDrawer from '../../../../components/vsDrawer/VSDrawer'
import VSMedicalRecordsAudit from "../../../../components/vsMedicalRecordsAudit/VSMedicalRecordsAudit";
import VSError from "../../../../components/vsError/VSError";
import { getFileType, isImage, isPdf } from '../../../../components/vsFileUpload/VSFileUpload'
import { PatientInfoLink } from "../../PatientWideInfo";
import BoxVaccineForView from './BoxVaccineForView'
import BoxVaccine from "./BoxVaccine";

import './VaccineView.scss'
const VaccineView = props => {

    const {
        id,
        id_vacina
    } = useParams()

    const {
        state
    } = useLocation()

    const {
        clinic,
        user,
        history,
        permissions
    } = props

    const [ patient, setPatient ] = useState(null)
    const [ vaccine, setVaccine ] = useState(null)
    const [ attachment, setAttachment ] = useState(null)

    const [ loading, setLoading ] = useState(true)
    const [ error, setError ] = useState([])

    const [ files, setFiles ] = useState([])
    const [ filesLoading, setFilesLoading ] = useState(null)

    const dateToShow = showDate(vaccine?.get("documentDate")) || "-"

    const renderOtherDoses = () => {
        const allOtherDoses = []
        var currentParent = vaccine.get('parentVaccine')
        // var currentIndex = 0
        while(currentParent){
            ((currentParent) => {
                allOtherDoses.push((
                    <BoxVaccine 
                        vaccine={currentParent}
                        className="mb-3"
                        buttons={
                            <div className="col-auto">
                                <div 
                                    className="vsbox-btn vsbox-btn-dark btn-menu cursor-pointer"
                                    onClick={() => history.push(`/animal/${patient.id}/vacina/${currentParent.id}/ver`, { fromPatient: state?.fromPatient })}>
                                    <IconEye width="24" height="24" fill="var(--vsprontuario-primary-color-dark)" />
                                </div>
                            </div>
                        }
                    />
                ))
            })(currentParent)
            currentParent = currentParent.get('parentVaccine')
            // currentIndex++
        }

        return allOtherDoses
    }

    const getDownloadLink = () => {
        if (attachment) {
            setFilesLoading(true)
            getAttachments(attachment.get("attachments"))
            .then(_files => {
                setFiles(_files)
                setFilesLoading(false)
            })
            .catch(error => {
                console.error(error)
                setFilesLoading(false)
            })
        }
    }

    const getPatientOrError = () => {
        setLoading(true)
        setError(null)

        getPatient(id, clinic.object)
        .then(_patient => {
            setPatient(_patient)
        })
        .catch(error => {
            Swal.fire(
                'Desculpe',
                error,
                'error'
            )
            .then(_ => {
                setLoading(false)
                setError({
                    message: error,
                    function: "getPatientOrError"
                })
            })
        })
    }

    const getVaccineOrError = () => {
        if (permissions?.check(ACL.VACCINE, ACL.Rule.VIEW)) {
            setLoading(true)
            setError(null)

            getVaccine(id_vacina, patient, true)
            .then(_vaccine => {
                setVaccine(_vaccine)
                setLoading(false)
            })
            .catch(error => {
                Swal.fire(
                    'Desculpe',
                    error,
                    'error'
                )
                .then(_ => {
                    setLoading(false)
                    setError({
                        message: error,
                        function: "getVaccineOrError"
                    })
                })
            })
        }
    }

    useEffect(() => {
        if (vaccine) {
            if (!permissions?.check(ACL.VACCINE, ACL.Rule.VIEW, vaccine)) {
                permissions?.error()
            }

            let _attachment = vaccine.get("attachment")
            setAttachment(_attachment)
        }
    }, [ vaccine ])

    useEffect(() => {
        if (patient) {
            getVaccineOrError()
        }
    }, [ patient ])

    useEffect(() => {
        if (id) {
            getPatientOrError()
        }
    }, [ id, id_vacina ])

    return (
        <VSDrawer
            id="vaccine-view"
            width="75%"
            title={
                loading ? undefined :
                "Visualizar Vacina"
            }
            onCancel={() => history.goBack()}
            onClose={() => history.goBack()}
            VSDrawerFooterProps={{
                show: false
            }}
            onError={(error) => {
                console.error(error)
                Swal.fire(
                    'Desculpe',
                    error?.response?.data?.message || (typeof error === "string" ? error : "Ocorreu algum erro ao carregar"),
                    'error'
                )
            }}
        >
            { error?.message ? (
                <VSError
                    message={error.message}
                    onClose={() => {
                        switch(error.function) {
                            case "getPatientOrError":
                                getPatientOrError()
                                break
                            case "getVaccineOrError":
                                getVaccineOrError()
                                break
                            default:
                        }
                    }}
                />
            ) : loading ? (
                <div className="row data-loading">
                    <div className="col">
                        <CircularProgress />
                    </div>
                </div>
            ) : (
                <div className="row">
                    <div className="col-12">
                        {!state?.fromPatient &&
                        <PatientInfoLink
                            patient={patient}
                            onClick={() => history.push(`/animal/${patient?.id}`)}
                        />}
                        <div className="row align-items-center">
                            <div className="col-12 col-sm-auto mb-3 pe-0">
                                <div className={`vaccine-view-chip ${vaccine.get("isReminder") && isReminderLate(vaccine) ? 'late' : ''}`}>
                                    Data: { dateToShow }
                                </div>
                            </div>
                            <div className="col mb-3">
                                <label className="vaccine-view-info">Estado da Vacina:</label>
                                <div 
                                    className={`vaccine-view-info-text ${
                                        vaccine.get("isReminder") ? (
                                            isReminderLate(vaccine) ? "late" : "scheduled"
                                        ) : 'applied'
                                    }`} 
                                    // style={{
                                    //     color: (
                                    //         vaccine.get("isReminder") ? (
                                    //             getFirstSentAtFromArray(vaccine.get('messages')) ? (
                                    //                 "var(--vsprontuario-primary-color)"
                                    //             ) : getFirstScheduledAtFromArray(vaccine.get('messages')) ? (
                                    //                 "var(--vsprontuario-secondary-color)"
                                    //             ) : (
                                    //                 "var(--vsprontuario-gray-color-dark)"
                                    //             )
                                    //         ) : "var(--vsprontuario-primary-color-dark)"
                                    //     )
                                    // }}
                                >{
                                    vaccine.get("isReminder") ? (
                                        isReminderLate(vaccine) ? "Atrasada" : "Programada"
                                    ) : <>Aplicada</>
                                }</div>
                            </div>
                            <div className="col-auto align-self-stretch align-self-md-center">
                                <div className="row align-items-center">
                                    { permissions?.check(ACL.VACCINE, ACL.Rule.EDIT, vaccine) && (
                                        <div className="col-sm-auto col-6 ps-2 ps-sm-0 order-2 order-sm-0">
                                            <button className="vsbox-btn vsbox-btn-dark w-100" onClick={() => history.push(`/animal/${patient.id}/vacina/${vaccine.id}`)}>
                                                <IconEdit width="24" height="24" />
                                            </button>
                                        </div>
                                    )}
                                    { permissions?.check(ACL.VACCINE, ACL.Rule.DELETE, vaccine) && (
                                        <div className="col-sm-auto col-6 ps-sm-0 order-1 order-sm-0 pe-1 pe-sm-3">
                                            <button className="vsbox-btn vsbox-btn-alert w-100" onClick={() => deleteVaccine(vaccine, () => updateList())}>
                                                <IconTrash width="24" height="24" />
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                { showRegisteredBy(vaccine) }
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="vsbox-separator"></div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <VSAccordionSimple
                                    expanded={true}
                                    header={() => `Dados da Vacina`}
                                >
                                    <BoxVaccineForView 
                                        vaccine={vaccine}
                                        history={history}
                                        className="mb-2"
                                    />
                                    <div className="row align-items-start align-items-md-center flex-column flex-md-row vsbox-footer mt-3 mb-3 gx-0 px-3 rounded">
                                        <div className={`col-auto pt-2 pb-sm-2 ${vaccine.get("isReminder") ? '' : 'pb-2'}`}>
                                            <div className="row align-items-start align-items-sm-center flex-column flex-sm-row">
                                                <div className="col-auto vsbox-item-text pe-2">
                                                    <b>Lembrete para o Tutor:</b>
                                                </div>
                                                <div 
                                                    className="col-auto px-sm-0 mt-2 mt-sm-0"
                                                    style={!getFirstSentAtFromArray(vaccine.get('messages')) && 
                                                    getFirstScheduledAtFromArray(vaccine.get('messages')) ? {
                                                        color: "var(--vsprontuario-secondary-color)"
                                                    } : undefined}
                                                >{
                                                    getFirstSentAtFromArray(vaccine.get('messages')) ? (
                                                        <>
                                                            <IconNotificationOn className="me-1" width="20" height="20" fill="var(--vsprontuario-primary-color)" />
                                                            Enviado dia { showDate(getFirstSentAtFromArray(vaccine.get('messages'))) }
                                                        </>
                                                    ) : getFirstScheduledAtFromArray(vaccine.get('messages')) ? (
                                                        <>
                                                            <IconNotification className="me-1" width="20" height="20" fill="var(--vsprontuario-secondary-color)" />
                                                            Ativado (Envio programado para { showDate(getFirstScheduledAtFromArray(vaccine.get('messages'))) })
                                                        </>
                                                    ) : (
                                                        <>
                                                            <IconNotificationOff className="me-1" width="20" height="20" fill="var(--vsprontuario-gray-color-dark)" />
                                                            Desativado
                                                        </>
                                                    )
                                                }</div>
                                            </div>
                                        </div>
                                        {vaccine.get("isReminder") &&
                                        <div className="col">
                                            <div className="row align-items-center justify-content-md-end">
                                                <div className="col-auto ps-0 ps-md-3">
                                                    {!vaccine.get('messages') || !vaccine.get('messages').length ? (
                                                        <button
                                                            className="btn-link vaccine-view-button"
                                                            onClick={() => history.push(`/animal/${vaccine.get('patient').id}/lembrete_automatico/${vaccine.id}`)}
                                                        >
                                                            Ativar Lembrete
                                                        </button>
                                                    ) : (
                                                        <button
                                                            className="btn-link vaccine-view-button"
                                                            onClick={() => history.push(`/animal/${vaccine.get('patient').id}/lembrete_automatico/${vaccine.id}`)}
                                                        >
                                                            Editar Lembrete
                                                        </button>
                                                    )}
                                                </div>
                                            </div>
                                        </div>}
                                    </div>
                                    {vaccine?.get("messages") && vaccine?.get("messages").length > 0 &&
                                    <div className="row align-items-center gx-0">
                                        <div className="col-12 vaccine-view-info pe-2 mb-2">
                                            <b>Lembrete via</b>
                                        </div>
                                        {vaccine?.get("messages").map(message => (
                                            <div className="col-auto vaccine-reminder-chip mb-3 me-2">
                                                {message?.get("messageTypeId") ? (
                                                    message.get("messageTypeId") == 'WHATSAPP' ? 'WhatsApp' :
                                                    message.get("messageTypeId") == 'SMS' ? 'SMS' :
                                                    message.get("messageTypeId") == 'EMAIL' ? 'E-mail' : 'Outros'
                                                ) : 'Outros'}
                                            </div>
                                        ))}
                                    </div>}
                                </VSAccordionSimple>
                            </div>
                        </div>
                        {/* {vaccine?.get("messages") && vaccine?.get("messages").length > 0 && (
                            <div className="row mb-3">
                                <div className="col">
                                    <div className="vaccine-field-view-title mb-1">
                                        Lembrete via
                                    </div>
                                    <div className="row">
                                        {vaccine?.get("messages").map(message => message?.get("messageTypeId") && (
                                            <div className="col-auto pe-0">
                                                <div className="vaccine-reminder-chip">
                                                    {message.get("messageTypeId") == 'WHATSAPP' ? 'WhatsApp' :
                                                    message.get("messageTypeId") == 'SMS' ? 'SMS' :
                                                    message.get("messageTypeId") == 'EMAIL' ? 'E-mail' : ''}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        )} */}
                        <div className="row">
                            <div className="col">
                                <div className="vsbox-separator"></div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 vsbox--info">
                                <VSAccordionSimple
                                    expanded={true}
                                    header={(expanded) => "Dados Complementares"}
                                >
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="vsbox-item mb-3">
                                                <div className="row vsbox-item-text my-2">
                                                    <div className="col-auto">
                                                        <b>Nome do Fabricante:</b> { vaccine.get("companyName") || "Não informado" }
                                                    </div>
                                                    <div className="col-auto">
                                                        <b>Número de Partida:</b> { vaccine.get("manufacturingBatchPart") || "Não informado" }
                                                    </div>
                                                    <div className="col-auto">
                                                        <b>Data de Fabricação:</b> { showDate(vaccine.get("manufacturingDate"), "MM/YYYY") || "Não informado" }
                                                    </div>
                                                    <div className="col-auto">
                                                        <b>Data de Validade:</b> { showDate(vaccine.get("expirationDate"), "MM/YYYY") || "Não informado" }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </VSAccordionSimple>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="vsbox-separator"></div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 vsbox--info">
                                <VSAccordionSimple
                                    expanded={false}
                                    header={(expanded) => {
                                        if (filesLoading === null) {
                                            getDownloadLink()
                                        }
                                        return "Foto(s) da Etiqueta"
                                    }}
                                >
                                    { filesLoading ? (
                                        <div className="data-loading">
                                            <CircularProgress color="secondary" size="16px" />
                                        </div>
                                    ) : (
                                        <div className="row align-items-center">
                                            { attachment?.get("attachments") && attachment?.get("attachments").length ? attachment.get("attachments").map((file, index) => {
                                                const type = getFileType(file.name())
                                                return (
                                                        <div className="col-auto" key={index}>
                                                            <div className="attachment-preview mb-2">
                                                                { isImage(type) ? (
                                                                    <img 
                                                                        src={file.url()} 
                                                                        height="100px" 
                                                                        width="auto" 
                                                                        style={{ 
                                                                            display: "block",
                                                                            maxWidth: "90%",
                                                                            padding: "8px 0"
                                                                        }} 
                                                                    />
                                                                ) : isPdf(type) ? (
                                                                    <IconPdf />
                                                                ) : (
                                                                    <IconFile />
                                                                )}
                                                            </div>
                                                            <div className="row mb-3">
                                                                { (isImage(type) || isPdf(type)) && (
                                                                    <div className="col pe-0">
                                                                        <button
                                                                            className="vsbox-btn vsbox-btn-save"
                                                                            href={file.url()}
                                                                            target="_blank"
                                                                        >
                                                                            Visualizar
                                                                        </button>
                                                                    </div>
                                                                )}
                                                                <div className="col">
                                                                    <button
                                                                        className="vsbox-btn vsbox-btn-primary w-100"
                                                                        href={files[index]}
                                                                        target="_blank"
                                                                        download={file.name()}
                                                                    >
                                                                        Baixar
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                )
                                            }) : (
                                                <div className="col-auto mb-3">
                                                    Nenhum arquivo anexado
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </VSAccordionSimple>
                            </div>
                        </div>
                        {(vaccine.get('parentVaccine') || vaccine.get("notes")) &&
                        <>
                            <div className="vsbox-separator"></div>
                            <VSAccordionSimple
                                expanded={false}
                                header={expanded => (
                                    `${ expanded ? "Ocultar" : "Mostrar" } mais informações`
                                )}
                            >
                                <div className="row mb-3">
                                    <div className="col">
                                        Outras doses da vacina:
                                    </div>
                                </div>
                                {renderOtherDoses()}
                                <div className="row">
                                    <div className="col vsbox-title">
                                        Observações:
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col mb-3 pre-line">
                                        { vaccine.get("notes") || "Não informado" }
                                    </div>
                                </div>
                            </VSAccordionSimple>
                        </>}
                        <VSMedicalRecordsAudit parseObject={vaccine} />
                    </div>
                </div>
            )}
        </VSDrawer>
    )
}

const mapStateToProps = state => {
    return {
        user: state.user,
        clinic: state.clinic,
        permissions: state.permissions
    };
};

export default connect(mapStateToProps)(VaccineView)