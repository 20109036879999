import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import { ReactComponent as IconClose } from "../../assets/icon-close.svg"

import "./Survey.scss"
import { APP_ID, APP_NAME, APP_DEVICE, APP_VERSION } from '../../constants';

const Survey = (props) => {
	const {
		user
	} = props

	const [ surveys, setSurveys ] = useState([])
    const [ localSurveys, setLocalSurveys ] = useState(null);

    const LOCAL_SURVEYS_KEY = "vetsmart_v3/local_surveys_v1"
    const LOCAL_SURVEYS_ANSWERED_KEY = "vetsmart_v3/local_surveys_answered_v1"
    var local_surveys_answered = JSON.parse(localStorage.getItem(LOCAL_SURVEYS_ANSWERED_KEY) || "[]")

    const hasSurveyInteraction = (_survey) => {
        return new Promise((resolve, reject) => {
            var query = new Parse.Query("SurveyInteraction");
            query.equalTo("survey", _survey.object)
            query.equalTo("user", user.object)
            query.first()
            .then(answered => {
                if (answered) {
                    local_surveys_answered.push(_survey.objectId)
                    localStorage.setItem(LOCAL_SURVEYS_ANSWERED_KEY, JSON.stringify(local_surveys_answered))
                    resolve()
                } else {
                    resolve(_survey)
                }
            })
            .catch(error => {
                console.error(error)
                reject(error)
            })
        })
    }

	const getSurveys = () => {
        setSurveys([])

		Parse.Cloud.run("currentSurveys", {
            production: process.env.NODE_ENV !== "development",
            appName: APP_ID,
            appVersion: APP_VERSION,
            appDC: true,
            appBE: false
        })
		.then(_surveys => {
            const oneHour = 60 * 60 * 1000
            const localStorageSurveys = {
                expires: new Date().getTime() + oneHour,
                surveys: _surveys
            }
            localStorage.setItem(LOCAL_SURVEYS_KEY, JSON.stringify(localStorageSurveys))
            setLocalSurveys(JSON.parse(JSON.stringify(_surveys)))
		})
		.catch(error => {
			console.error(error)
		})
	}

	const onSurveyInteraction = () => {
        const survey = surveys[0]

        let newInteraction = new Parse.Object("SurveyInteraction")
        newInteraction.set("survey", survey.object)
        newInteraction.set("user", user.object)
        newInteraction.set("createdApp", APP_NAME);
        newInteraction.set("createdDevice", APP_DEVICE);
        newInteraction.set("createdAppVersion", APP_VERSION);
		newInteraction.save()
		.then(_ => {
            local_surveys_answered.push(survey.objectId)
            localStorage.setItem(LOCAL_SURVEYS_ANSWERED_KEY, JSON.stringify(local_surveys_answered))

            const newSurveys = [...surveys]
            newSurveys.shift()
            setSurveys(newSurveys)
		})
		.catch(error => {
            console.error(error)
            newInteraction.revert()
		})
	}

    useEffect(() => {
        const _surveys = localSurveys
        if (_surveys && _surveys.length) {
            let promises = []
            _surveys.map(_survey => {
                const objectId = _survey.objectId || _survey.id
                _survey.object = {
                    __type: "Pointer",
                    className: "Survey",
                    objectId: objectId
                }
                if (local_surveys_answered.indexOf(objectId) == -1) {
                    promises.push(
                        hasSurveyInteraction(_survey)
                    )
                }
            })

            Promise.all(promises)
            .then(newSurveys => {
                if (newSurveys) {
                    setSurveys(newSurveys)
                }
            })
        }
    }, [ localSurveys ])

    useEffect(() => {
        setSurveys([])

        const currentDate = new Date().getTime()

        var local_surveys = JSON.parse(localStorage.getItem(LOCAL_SURVEYS_KEY) || "{}")
        if (local_surveys && local_surveys.expires > currentDate) {
            setLocalSurveys(local_surveys.surveys)
        } else {
            getSurveys()
        }
    }, [])

    const currentSurvey = surveys && surveys[0]

    return (
        <React.Fragment>
            { currentSurvey && (
                <div className="col-12 px-2 mb-3">
                    <div className="vsprompt">
                        <div className="vsprompt-text">
                            { currentSurvey.title && (
                                <b>{ currentSurvey.title }</b>
                            )}
                            { currentSurvey.subtitle && (
                                <p className="mb-0">{ currentSurvey.subtitle }</p>
                            )}
                            { currentSurvey.message && (
                                <span>{ currentSurvey.message }</span>
                            )}
                        </div>
                        <div className="vsprompt-close" onClick={onSurveyInteraction}>
                            <IconClose width="24" height="24" />
                        </div>
                        <div className="vsprompt-actions">
                            <a className="btn-accept d-inline-block" href={currentSurvey.surveyURL} target="_blank" onClick={onSurveyInteraction}>
                                { currentSurvey.action }
                            </a>
                        </div>
                    </div>
                </div>
            )}
        </React.Fragment>
    )
}

const mapStateToProps = state => {
    return {
        user: state.user,
    };
};
  
export default connect(mapStateToProps)(Survey);