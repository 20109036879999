import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { CircularProgress, InputAdornment, TextField } from '@mui/material';
import Autocomplete from '@mui/lab/Autocomplete';

import { ReactComponent as IconExpand } from "../../assets/icon-expand.svg"
import { ReactComponent as IconRetract } from "../../assets/icon-retract.svg"
import { ReactComponent as IconSearch } from "../../assets/icon-search.svg"
import { ReactComponent as IconAdd } from "../../assets/icon-primary-add-o.svg"

import { dayjs, mandatoryField, validateDate } from '../../utils';
import DateInput from '../dateInput/DateInput';
import VSPagination, { showPagination } from '../vsPagination/VSPagination';
import VSFilter from './VSFilter';

var searchByCurrent = ""

import './VSList.scss'
const VSList = forwardRef((props, ref) => {

    const {
        clinic,
        title,
        search,
        interval,
        button,
        customFilter,
        appendFilter,
        VSFilterProps,
        order,
        pagination,
        onChange,
        renderItem,
        renderItemStyle,
        renderEmpty,
        renderNotFound,
        topPagination
    } = props

    const _topPagination = topPagination ?? (VSFilterProps ? { showExpand: true } : topPagination);
    const searchWithDelay = useRef(null)

    const [ searchBy, setSearchBy ] = useState(search?.value)

    const [ start, setStart ] = useState(interval?.start?.value)
    const [ startText, setStartText ] = useState(interval?.start?.value ? dayjs(interval.start.value).format("DD/MM/YYYY") : "")
    const [ errorStart, setErrorStart ] = useState(false)
    const [ helperStart, setHelperStart ] = useState(mandatoryField)

    const [ end, setEnd ] = useState(interval?.end?.value)
    const [ endText, setEndText ] = useState(interval?.end?.value ? dayjs(interval.end.value).format("DD/MM/YYYY") : "")
    const [ errorEnd, setErrorEnd ] = useState(false)
    const [ helperEnd, setHelperEnd ] = useState(mandatoryField)

    const [ orderBy, setOrderBy ] = useState(order?.value)

    const [ page, setPage ] = useState(1)

    const [ loading, setLoading ] = useState(true)
    const [ items, setItems ] = useState([])
    const [ total, setTotal ] = useState(0)

    const [ expand, setExpand ] = useState(false)

    var noUpdate = false

    useImperativeHandle(ref, () => ({
        setItems (newItems) {
            setItems(newItems)
            setTotal(newItems.length)
        },
        onChange() {
            noUpdate = true
            setSearchBy(search?.value)
            handleDate(interval?.start?.value ? dayjs(interval?.start?.value).format("DD/MM/YYYY") : "", "start")
            handleDate(interval?.end?.value ? dayjs(interval?.end?.value).format("DD/MM/YYYY") : "", "end")
            setOrderBy(order?.value)
            setPage(1)
            update()
        }
    }))

    const handleDate = (_date, _field) => {
        const numbers = _date.replace(/[^0-9]/g, '')
        const hasDate = !numbers || numbers.length == 8
        switch (_field) {
            case "end":
                setEndText(_date)
                if (hasDate) {
                    const date = validateDate(_date, "23:59:59")
                    setEnd(date)
                }
                break;
            default: // start
                setStartText(_date)
                if (hasDate) {
                    const date = validateDate(_date, "00:00:00")
                    setStart(date)
                }
        }
    }

    const showItems = () => {
        return (
            items.map((item, index) => (
                <React.Fragment key={index}>
                    { renderItem(item, expand, index) }
                </React.Fragment>
            ))
        )
    }

    const validate = () => {
        if (startText && startText.length == "10" && !start) {
            setErrorStart(true)
            setHelperStart("A data informada não é válida")
        } else {
            setErrorStart(false)
            setHelperStart("")
        }
        if (endText && endText.length == "10" && !end) {
            setErrorEnd(true)
            setHelperEnd("A data informada não é válida")
        } else {
            setErrorEnd(false)
            setHelperEnd("")
        }
        if (start && end && dayjs(end).isBefore(start)) {
            setErrorEnd(true)
            setHelperEnd("A data de término não pode ser menor que a data de início")
        } else {
            setErrorEnd(false)
            setHelperEnd("")
        }
    }

    const update = () => {
        const startDateIsAfterEndDateNotTriggerOnChangeEvent = dayjs(start).isAfter(end)
        if (startDateIsAfterEndDateNotTriggerOnChangeEvent) {
            validate()
            return
        }

        noUpdate = false
        searchByCurrent = searchBy

        setLoading(true)
        onChange && onChange({
            _searchBy: searchBy,
            _start: start,
            _end: end,
            _orderBy: orderBy,
            _page: page
        })
        .then(response => {
            if (response) {
                const { items, total } = response
                if (searchByCurrent == searchBy) {
                    setItems(items)
                    setTotal(total)
                    setLoading(false)
                }
            }
        })
        validate()
    }

    useEffect(() => {
        if (expand) {
            setExpand(undefined)
        }
    }, [ expand ])

    useEffect(() => {
        setPage(1)
    }, [ searchBy, orderBy, start, end ])

    useEffect(() => {
        if (clinic && clinic.isLoaded) {
            searchWithDelay.current = setTimeout(() => {
                if (!noUpdate) {
                    update()
                }
            }, 300)
        }
        return () => {
            clearInterval(searchWithDelay.current)
        }
    }, [ clinic, searchBy, orderBy, start, end, page ])

    const isEmpty = total == 0

    const filterApplied = searchBy || VSFilterProps?.filters?.filter(filter => JSON.stringify(filter.value) != JSON.stringify(filter.defaultValue))?.length > 0
    
    return (
        <div id="vslist">
            <div className="row align-items-center mb-3">
                <div className="col-12">
                    <div className="row mb-3 g-3 align-items-center">
                        { title && title.text && (
                            <div className={`page-title-wrap ${ search && search.text ? "col-auto" : "col-12 col-md" } pe-md-0`}>
                                <div className="page-title">
                                    { title.text }
                                </div>
                            </div>
                        )}
                        { search && (
                            search.text ? (
                                <div className="col-12 col-md" style={{ minWidth: 200 }}>
                                    <TextField
                                        placeholder={ search.text }
                                        variant="outlined"
                                        size="small"
                                        value={searchBy}
                                        onChange={({ target: { value} }) => setSearchBy(value)}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">
                                                <IconSearch width="18px" height="18px" fill="var(--vsprontuario-gray-color)" />
                                            </InputAdornment>,
                                        }}
                                        fullWidth
                                    />
                                </div>
                            ) : (
                                search
                            )
                        )}
                        { interval && (
                            <>
                                { interval.start && (
                                    interval.start.text ? (
                                        <div className="col-6 col-md-auto">
                                            <div className="row align-items-center">
                                                <div className="col">
                                                    <DateInput
                                                        className="m-0"
                                                        placeholder="00/00/0000"
                                                        label={interval.start.text}
                                                        value={startText || null}
                                                        error={errorStart}
                                                        helperText={null}
                                                        onChange={(value) => handleDate(value, "start")}
                                                        size="small"
                                                        variant="outlined"
                                                        fullWidth
                                                    />
                                                </div>
                                                { errorStart && (
                                                    <div className="col-12">
                                                        <small className="text-danger">{ helperStart }</small>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    ) : (
                                        interval.start
                                    )
                                )}
                                { interval.end && (
                                    interval.end.text ? (
                                        <div className="col-6 col-md-auto">
                                            <div className="row align-items-center">
                                                <div className="col">
                                                    <DateInput
                                                        className="m-0"
                                                        placeholder="00/00/0000"
                                                        label={interval.end.text}
                                                        value={endText || null}
                                                        error={errorEnd}
                                                        helperText={null}
                                                        onChange={(value) => handleDate(value, "end")}
                                                        size="small"
                                                        variant="outlined"
                                                        fullWidth
                                                    />
                                                </div>
                                                { errorEnd && (
                                                    <div className="col-12">
                                                        <small className="text-danger">{ helperEnd }</small>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    ) : (
                                        interval.end
                                    )
                                )}
                                { !interval.start && !interval.end && interval }
                            </>
                        )}
                        { order && (
                            order.options?.length > 0 ? (
                                <div className="col-12 col-md-auto" style={{ minWidth: '170px' }}>
                                    <Autocomplete
                                        value={orderBy}
                                        options={order.options}
                                        onChange={(_, selected) => setOrderBy(selected || '')}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                size="small"
                                                placeholder="Ordenar por"
                                                label="Ordenar por"
                                                fullWidth
                                            />
                                        )}
                                        openOnFocus
                                        disableClearable
                                    />
                                </div>
                            ) : (
                                order
                            )
                        )}
                        { button && (
                            button.text ? (
                                <div className="col-12 col-md-auto mt-0 mt-md-3">
                                    <button
                                        id={button.id}
                                        className="btn-main-mobile btn-submit btn-square w-100"
                                        onClick={() => button.onClick && button.onClick()}
                                    >
                                        { button.icon || <IconAdd id="add-icon" width="14" height="14" fill="#FFFFFF" /> }
                                        { button.text }
                                    </button>
                                </div>
                            ) : (
                                button
                            )
                        )}
                        { customFilter && (
                            <div className={title || search || interval || order || button ? "col-12 col-md-auto" : "col-12 col-md"}>
                                { customFilter }
                            </div>
                        )}
                    </div>
                    { pagination && (
                        <div className="row g-3 align-items-center">
                            <div className="col">
                                <div className="vstop-pagination">
                                    <div className="row flex-column flex-md-row align-items-start align-items-md-center ms-0">
                                        { pagination?.rowsPerPage > 0 && (
                                            <div className="col py-2">
                                                { showPagination(page, pagination.rowsPerPage, total) }
                                            </div>
                                        )}
                                        { VSFilterProps && (
                                            <div className="col-auto ps-0">
                                                <VSFilter {...VSFilterProps} totalResults={total} />
                                            </div>
                                        )}
                                        { pagination?.expand && (
                                            <div className="col-auto ps-0">
                                                {expand === false ? (
                                                    <div className="btn-link text-center cursor-pointer" onClick={() => setExpand(true)}>
                                                        <IconExpand width={24} height={24} fill="var(--vsprontuario-primary-color)" />
                                                        EXPANDIR TUDO
                                                    </div>
                                                ) : (
                                                    <div className="btn-link text-center cursor-pointer" onClick={() => setExpand(false)}>
                                                        <IconRetract width={24} height={24} fill="var(--vsprontuario-primary-color)" />
                                                        RECOLHER TUDO
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                        { pagination?.customActions }
                                    </div>
                                </div>
                            </div>
                            { pagination.append && (
                                <div className="col-auto">
                                    { pagination.append }
                                </div>
                            )}
                        </div>
                    )}
                    { appendFilter }
                </div>
            </div>
            { loading ? (
                <div className="data-loading">
                    <CircularProgress />
                </div>
            ) : !isEmpty ? (
                <>
                    <div className="row g-3">
                        { renderItemStyle == "row" ? (
                            <div className="col-12">
                                { showItems() }
                            </div>
                        ) : (
                            showItems()
                        )}
                        { pagination?.rowsPerPage < total && total > 0 && (
                            <div className="col-12">
                                <VSPagination
                                    currentPage={page}
                                    rowsPerPage={pagination.rowsPerPage}
                                    totalRows={total}
                                    onChange={(_currentPage) => {
                                        setPage(_currentPage)
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </>
            ) : filterApplied ? (
                renderNotFound()
            ) : (
                renderEmpty()
            )}
        </div>
    )
})

VSList.propTypes = {
    title: PropTypes.shape({
        text: PropTypes.string
    }),
    search: PropTypes.oneOfType([
        PropTypes.shape({
            text: PropTypes.string,
            value: PropTypes.string
        }),
        PropTypes.any
    ]),
    interval: PropTypes.oneOfType([
        PropTypes.shape({
            start: PropTypes.oneOfType([
                PropTypes.shape({
                    text: PropTypes.string,
                    value: PropTypes.instanceOf(Date)
                }),
                PropTypes.any
            ]),
            end: PropTypes.oneOfType([
                PropTypes.shape({
                    text: PropTypes.string,
                    value: PropTypes.instanceOf(Date)
                }),
                PropTypes.any
            ])
        }),
        PropTypes.any
    ]),
    button: PropTypes.oneOfType([
        PropTypes.shape({
            id: PropTypes.string,
            icon: PropTypes.any,
            text: PropTypes.string,
            onClick: PropTypes.func
        }),
        PropTypes.any
    ]),
    order: PropTypes.oneOfType([
        PropTypes.shape({
            options: PropTypes.arrayOf(PropTypes.string),
            value: PropTypes.string
        }),
        PropTypes.any
    ]),
    customFilter: PropTypes.any,
    pagination: PropTypes.shape({
        rowsPerPage: PropTypes.number,
        expand: PropTypes.bool
    }),
    onChange: PropTypes.func,
    renderItem: PropTypes.func,
    renderItemStyle: PropTypes.oneOf(["row", "col"]),
    renderEmpty: PropTypes.func,
    renderNotFound: PropTypes.func
};

VSList.defaultProps = {
    title: null,
    search: null,
    interval: null,
    button: null,
    order: null,
    customFilter: null,
    pagination: null,
    onChange: ({ search, start, end, orderBy, page }) => console.info(search, start, end, orderBy, page),
    renderItem: (item) => console.info(item),
    renderItemStyle: "row",
    renderEmpty: () => null,
    renderNotFound: () => null
}

const mapStateToProps = state => {
    return {
        clinic: state.clinic
    };
};
  
export default connect(mapStateToProps, null, null, { forwardRef: true })(VSList);