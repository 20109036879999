import React, { useEffect, useRef, useState } from "react"
import { useLocation } from "react-router-dom/cjs/react-router-dom"
import { useParams } from "react-router"
import { connect } from "react-redux"
import axios from "axios"

import { CircularProgress } from "@mui/material"

import { ReactComponent as IconArrowLeft } from "../../../../assets/icon-arrow-left.svg"
import { ReactComponent as IconEdit } from "../../../../assets/icon-primary-edit.svg"
import { ReactComponent as IconExclamationO } from "../../../../assets/icon-exclamation-o.svg"

import { dayjs, formattedCurrency, saveOnTracker, stringifyArray } from "../../../../utils"
import { SALE } from "./Sale"
import { ACL } from "../../../../constants"

import VSMessage, { saveOnTrackerMessage } from "../../../../components/vsMessage/VSMessage";
import VSShare from "../../../../components/vsShare/VSShare"

import "./SaleShare.scss"
const SaleShare = (props) => {

    const {
        id_documento
    } = useParams()

    const {
        pro,
        user,
        clinic,
        history,
        location,
        permissions
    } = props

	const { 
		state
	} = useLocation()

    const MessageForm = useRef(null)

    const [ prefs, setPrefs ] = useState(null)

    const [ hasSubmited, setHasSubmited ] = useState(false)
    const [ errors, setErrors ] = useState([])

    const [ loading, setLoading ] = useState(false)
    const [ loadingPreview, setLoadingPreview ] = useState(true)
    const [ document, setDocument ] = useState(null)
    const [ preview, setPreview ] = useState(null)
    const [ previewType, setPreviewType ] = useState(1)

    const isSale = document?.get("documentType") == SALE
    const link = `${process.env.REACT_APP_PRESCRIPTION}/s/${document?.id}`
    const link_pdf = `${process.env.REACT_APP_PDF}/api/v2/s/${document?.id}`

    const services = document?.get("services")
    const patient = document?.get("patient")
    const owner = patient?.get("owner")

    const messageParams = {
        user: Parse.User.current().id,
        clinic: clinic?.objectId,
        owner: owner?.id,
        patient: patient?.id,
        createdFromClass: "MRSale",
        createdFromId: document?.id
    }

    const redirectTo = state?.redirectTo || `/animal/${patient?.id}/${isSale ? 'venda' : 'orcamento'}`

    if (!permissions?.check(ACL.SALE, ACL.Rule.VIEW)) {
        permissions?.error()
        return <div></div>
    }

    const documentType = () => isSale ? "SALE" : "ESTIMATE"

    const documentName = (withPrefix = false) => {
        if (isSale)
            return `${withPrefix ? "a " : ""}Venda`
        return `${withPrefix ? "o " : ""}Orçamento`
    }

    const validate = () => {
        let _errors = []
        if (MessageForm.current) {
            _errors = MessageForm.current?.validate()
        }
        setErrors(_errors)
        return _errors
    }

    const send = () => {
        return new Promise((resolve, reject) => {
            setHasSubmited(true)
            if (errors.length == 0) {
                const values = MessageForm.current?.save()

                saveOnTrackerMessage(documentName(), document.id, values)
            } else {
                reject()
            }
        })
    }

    const submitText = () => {
        let text = []
        if (!prefs) {
            text.push("Buscando informações")
        } else {
            if (prefs.get("printEnabled")) {
                text.push("Imprimir")
            }
            if (prefs.get("downloadEnabled")) {
                text.push("Baixar")
            }
            if (prefs.get("whatsappEnabled") || prefs.get("smsEnabled") || prefs.get("emailEnabled")) {
                text.push("Enviar")
            }
        }
        if (text.length == 0) {
            return "Escolha pelo menos uma opção"
        }
        return stringifyArray(text)
    }

    const getPreview = () => {
        setLoadingPreview(true)
        setPreview(null)

        axios.get(`${link}?donttrack&onlyContent`)
        .then(response => {
            setPreview(response.data)
            setLoadingPreview(false)
        })
    }

    const getMedicalRecordPrefs = () => {
        const query = new Parse.Query("MedicalRecordsPrefs");
        query.equalTo("clinic", clinic.object);
        query.equalTo("user", user.object);
        query.first()
        .then(_prefs => {
            if (_prefs) {
                setPrefs(_prefs)
            } else {
                const newPrefs = new Parse.Object("MedicalRecordsPrefs")
                newPrefs.setACL(clinic.ACL)
                newPrefs.set("clinic", clinic.object)
                newPrefs.set("user", user.object)
                newPrefs.set("printEnabled", false)
                newPrefs.set("printTermEnabled", false)
                newPrefs.set("sendToSMSEnabled", false)
                newPrefs.set("sendToWhatsappEnabled", false)
                newPrefs.set("sendToEmailEnabled", false)
                newPrefs.set("sendToDownloadEnabled", false)
                newPrefs.set("downloadEnabled", false)
                newPrefs.set("downloadTermEnabled", false)
                newPrefs.set("printColorType", 0)
                newPrefs.set("displayOld", false)
                newPrefs.set("disableToggle", false)
                newPrefs.save()
                .then(_prefs => {
                    setPrefs(_prefs)
                })
                .catch(error => {
                    console.error(error)
                    Swal.fire(
                        'Desculpe',
                        `Ocorreu algum erro ao tentar cadastrar suas preferências de exibição e envio`,
                        'error'
                    )
                    newPrefs.revert()
                })
            }
        })
        .catch(error => {
            console.error(error)
            Swal.fire(
                'Desculpe',
                `Ocorreu um erro ao gravar suas preferências, por favor tente novamente.`,
                'error'
            )
        })
    }

    useEffect(() => {
        if (clinic && clinic.object && user && user.object && id_documento) {
            setLoading(true)

            getMedicalRecordPrefs()

            var query = new Parse.Query("MRSale");
            query.equalTo("clinic", clinic.object);
            query.equalTo("isDeleted", false);
            query.include("patient");
            query.include("patient.owner");
            query.get(id_documento)
            .then(_document => {
                if (_document) {
                    setDocument(_document)
                } else {
                    console.error(_document)
                    Swal.fire(
                        'Desculpe',
                        `Não encontramos o documento informado`,
                        'error'
                    )
                }
                setLoading(false)
            }).catch(error => {
                console.error(error)
                Swal.fire(
                    'Desculpe',
                    `Ocorreu algum erro ao tentar buscar o documento`,
                    'error'
                )
                setLoading(false)
            })
        }
    }, [ clinic ])

    useEffect(() => {
        if (document) {
            getPreview()
        }
    }, [ document, location ])

    const servicesQuantity = services?.reduce((total, object) => total + (object?.quantity ? object?.quantity : 1), 0)

    return (
        <div id="sale-share">
            <VSShare
                loading={loading}
                title={`Nov${ documentName(true) }`}
                subtitle={(
                    <div className="row align-items-center">
                        <div className="col-auto px-0">
                            <IconArrowLeft width="8px" height="12px" className="title-separator" />
                        </div>
                        { isSale && (
                            <>
                                <div className="col-auto">
                                    <div className="subtitle">Pagamento</div>
                                </div>
                                <div className="col-auto px-0">
                                    <IconArrowLeft width="8px" height="12px" className="title-separator" />
                                </div>
                            </>
                        )}
                        <div className="col-auto">
                            <div className="title">Visualizar e Emitir</div>
                        </div>
                    </div>
                )}
                close="Fechar"
                onClose={() => history.replace(redirectTo)}
                summary={(
                    <>
                        <div className="row g-3 align-items-center">
                            <div className="col-12 mb-md-3">
                                <h2 className="title">
                                    1 - Visualize o Documento
                                </h2>
                            </div>
                        </div>
                        <div className="row g-3 align-items-center">
                            <div className="col">
                                <h4 className="subtitle">Resumo d{ documentName(true) }</h4>
                            </div>
                            <div className="col-auto">
                                <button className="btn-link" onClick={() => history.push(`/${ isSale ? "venda" : "orcamento" }/${patient.id}/${id_documento}`, { redirectTo: redirectTo })}>
                                    <IconEdit width="18px" height="18px" />
                                    Editar <span className="d-none d-lg-inline-block">{ documentName() }</span>
                                </button>
                            </div>
                        </div>
                        <div className="row g-3 align-items-center">
                            <div className="col-12 col-xl-auto">
                                <div className="label_icon">
                                    Qtd. de Serviços: <b>{ servicesQuantity } serviço{ servicesQuantity == 1 ? "" : "s" } realizado{ servicesQuantity == 1 ? "" : "s" }</b>
                                </div>
                            </div>
                            <div className="col-12 col-sm-auto col-xl text-xl-center">
                                <div className="label_icon">
                                    Valor total: <b>{ formattedCurrency(document?.get("servicesValue")) }</b>
                                </div>
                            </div>
                            <div className="col-12 col-sm col-xl-auto text-start text-sm-end">
                                <div className="label_icon">
                                    Data d{ documentName(true) }: <b>{ dayjs(document?.get("documentDate")).format("DD/MM/YYYY") }</b>
                                </div>
                            </div>
                        </div>
                        { isSale && (
                            <>
                                <hr className="my-3" />
                                <div className="row g-3 align-items-center mb-2 pb-1">
                                    <div className="col">
                                        <h4 className="subtitle">Resumo do Pagamento</h4>
                                    </div>
                                    <div className="col-auto">
                                        <button className="btn-link" onClick={() => history.push(`/pagamento/venda/${id_documento}`, { redirectTo: redirectTo })}>
                                            <IconEdit width="18px" height="18px" />
                                            Editar <span className="d-none d-md-inline">Pagamento</span>
                                        </button>
                                    </div>
                                </div>
                                <div className="row g-3 align-items-center">
                                    <div className="col-12 col-sm-5 col-xl-auto">
                                        <div className="label_icon">
                                            Desconto: <b>{ formattedCurrency(document?.get("discount")) }</b>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-7 col-xl text-start text-sm-end text-xl-center">
                                        <div className="label_icon">
                                            Valor da Venda: <b>{ formattedCurrency(document?.get("finalValue")) }</b>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6 col-xl-auto text-xl-center">
                                        <div className="label_icon">
                                            Valor Pago: <b>{ formattedCurrency(document?.get("paymentsValue")) }</b>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6 col-xl-auto text-start text-sm-end">
                                        <div className="label_icon">
                                            Total a Pagar: <b>{ formattedCurrency(document?.get("paymentsPending")) }</b>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                        <hr className="my-3 d-none d-md-block" />
                        <h4 className="subtitle mb-3 d-none d-md-block">
                            Prévia do documento
                        </h4>
                        { isSale && (
                            <div className="message-info my-3">
                                <div className="row g-3 align-items-center">
                                    <div className="col-auto pr-0">
                                        <IconExclamationO  width="24" height="24" fill="var(--vsprontuario-secondary-color)" stroke="var(--vsprontuario-secondary-color)" />
                                    </div>
                                    <div className="col pl-2">
                                        <div className="row">
                                            <div className="col-12 mb-1">
                                                <b>Este documento não serve como nota fiscal</b>
                                            </div>
                                            <div className="col-12">
                                                Este documento é gerado como um comprovante de venda, para certificar o recebimento do valor referente à transação realizada durante a contratação do seu serviço.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </>
                )}
                preview={(
                    <>
                        <div className="row g-3 align-items-center">
                            <div className="col-12">
                                <div className="btn-tabs mb-3 mb-md-4">
                                    <div
                                        className={`btn-tab ${previewType == 1 ? 'active' : ''}`}
                                        onClick={() => {
                                            setPreviewType(1)
                                            saveOnTracker("Selecionou", "Prévia Impressa", document.id, "", "Solicitação de Exame", document.id, "", "")
                                        }}
                                    >
                                        Prévia Impressão
                                    </div>
                                    <div
                                        className={`btn-tab ${previewType == 0 ? 'active' : ''}`}
                                        onClick={() => {
                                            setPreviewType(0)
                                            saveOnTracker("Selecionou", "Prévia Digital", document.id, "", "Solicitação de Exame", document.id, "", "")
                                        }}
                                    >
                                        Prévia Digital
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="preview">
                                    { loadingPreview ? (
                                        <div style={{ textAlign: "center", padding: '36px' }}>
                                            <CircularProgress size={28} thickness={2} color="primary"/>
                                        </div>
                                    ) : (
                                        <div
                                            dangerouslySetInnerHTML={{__html: preview}}
                                            className={previewType == 0 ? 'document-digital' : 'document-print'}
                                        >
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </>
                )}
                message={(
                    <>
                        { prefs && (
                            <VSMessage
                                ref={MessageForm}
                                pro={pro}
                                clinic={clinic.object}
                                history={history}
                                title="2 - Selecione a forma de emissão do documento"
                                name={documentName()}
                                link={{
                                    text: "Link da Cópia Digital para envio manual:",
                                    url: link,
                                    urlToPDF: link_pdf,
                                    onClick: () => {
                                        saveOnTracker("Acessou", documentName(), document.id, "", documentName(), document.id, "", "")
                                    },
                                    onCopy: () => {
                                        saveOnTracker("Copiou", documentName(), document.id, "", documentName(), document.id, "", "")
                                    }
                                }}
                                onShare={() => {
                                    saveOnTracker("Compartilhou", documentName(), document.id, "", documentName(), document.id, "", "WhatsApp Web")
                                }}
                                onChange={() => {
                                    validate()
                                }}
                                owner={owner}
                                messages={[
                                    {
                                        title: "Imprimir Documento",
                                        checked: prefs?.get("printEnabled") || false,
                                        value: prefs?.get("printColorType") || 0,
                                        messageTypeId: "PRINT",
                                        onChange: (printColorType) => {
                                            prefs?.set("printColorType", printColorType)
                                            prefs?.save()
                                            .catch(error => {
                                                console.error(error)
                                                prefs?.revert()
                                            })
                                        },
                                        onChecked: (checked) => {
                                            prefs?.set("printEnabled", checked)
                                            prefs?.save()
                                            .catch(error => {
                                                console.error(error)
                                                prefs?.revert()
                                            })
                                        },
                                    },
                                    {
                                        title: "Baixar Documento em PDF",
                                        checked: prefs?.get("downloadEnabled") || false,
                                        messageTypeId: "DOWNLOAD",
                                        onChecked: (checked) => {
                                            prefs?.set("downloadEnabled", checked)
                                            prefs?.save()
                                            .catch(error => {
                                                console.error(error)
                                                prefs?.revert()
                                            })
                                        }
                                    },
                                    {
                                        ...messageParams,
                                        title: "Enviar Cópia Digital",
                                        checked: prefs?.get("whatsappEnabled") || prefs?.get("smsEnabled") || prefs?.get("emailEnabled") || false,
                                        messageTypeId: "SEND",
                                        messages: [
                                            {
                                                ...messageParams,
                                                title: `Enviar ${documentName()} por WhatsApp`,
                                                checked: prefs?.get("whatsappEnabled") || false,
                                                hideTextField: true,
                                                messageTypeId: "WHATSAPP",
                                                messageTemplateId: `WHATSAPP_${documentType()}`,
                                                withSeparator: true,
                                                onChecked: (checked) => {
                                                    prefs?.set("whatsappEnabled", checked)
                                                    prefs?.save()
                                                    .catch(error => {
                                                        console.error(error)
                                                        prefs?.revert()
                                                    })
                                                },
                                            },
                                            {
                                                ...messageParams,
                                                title: `Enviar ${documentName()} por SMS`,
                                                checked: prefs?.get("smsEnabled") || false,
                                                messageTypeId: "SMS",
                                                messageTemplateId: `SMS_${documentType()}`,
                                                withSeparator: true,
                                                onChecked: (checked) => {
                                                    prefs?.set("smsEnabled", checked)
                                                    prefs?.save()
                                                    .catch(error => {
                                                        console.error(error)
                                                        prefs?.revert()
                                                    })
                                                },
                                            },
                                            {
                                                ...messageParams,
                                                title: `Enviar ${documentName()} por E-mail`,
                                                checked: prefs?.get("emailEnabled") || false,
                                                messageTypeId: "EMAIL",
                                                messageTemplateId: `EMAIL_${documentType()}`,
                                                withSeparator: false,
                                                onChecked: (checked) => {
                                                    prefs?.set("emailEnabled", checked)
                                                    prefs?.save()
                                                    .catch(error => {
                                                        console.error(error)
                                                        prefs?.revert()
                                                    })
                                                },
                                            }
                                        ]
                                    }
                                ]}
                                submitButton={(
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="btn-sticky">
                                                <button 
                                                    className="btn-submit w-100"
                                                    disabled={errors.length != 0}
                                                    onClick={() => send()}
                                                >
                                                    { submitText() }
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            />
                        )}
                    </>
                )}
            />
        </div>
    )
}

const mapStateToProps = state => {
    return {
        pro: state.featuresPro,
        user: state.user,
        clinic: state.clinic,
        permissions: state.permissions
    };
};

export default connect(mapStateToProps)(SaleShare)