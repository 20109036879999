import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import Autocomplete from '@mui/lab/Autocomplete';

import { TextField } from '@mui/material';
import DateInput from '../../../../components/dateInput/DateInput';
import { years, months, getBreeds, getGenders } from '../../Patient';
import VSAccordionSimple from '../../../../components/vsAccordion/VSAccordionSimple';
import { dayjs, mandatoryField } from '../../../../utils';

const TermPatientForm = forwardRef((props, ref) => {

    const {
        patient, 
        breed, 
        setBreed,
        errorBreed, 
        setErrorBreed,
        helperBreed, 
        setHelperBreed,
        gender, 
        setGender,
        errorGender, 
        setErrorGender,
        helperGender, 
        setHelperGender,
        birthdate, 
        setBirthdate,
        errorBirthdate, 
        setErrorBirthdate,
        helperBirthdate, 
        setHelperBirthdate,
        birthdateText, 
        setBirthdateText,
        ageYear, 
        setAgeYear,
        ageMonth, 
        setAgeMonth,
    } = props

    // Patient
    const [ showPatient, setShowPatient ] = useState(false)

    const [ breeds, setBreeds ] = useState([])
    const [ genders, setGenders ] = useState([])

    const handleBirthdate = (_date) => {
        setBirthdateText(_date)

        if (_date.match(/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/g)) {
            let newDate = dayjs(_date, "DD/MM/YYYY")
            if (newDate.isValid()) {
                setBirthdate(newDate.toDate())
                const age = dayjs.duration(dayjs().diff(newDate))
                if (age.years() > 0 || age.months() > 0) {
                    setAgeYear(String(age.years()))
                    setAgeMonth(String(age.months()))
                }
                return
            }
        }
        setBirthdate(null)
        setAgeMonth('')
        setAgeYear('')
    }

    const handleAge = (_years, _months) => {
        const years = parseInt(_years) || 0
        const months = parseInt(_months) || 0
        const date = dayjs().subtract(years, 'years').subtract(months, 'months')
        setAgeYear(String(years))
        setAgeMonth(String(months))
        if (date.isValid()) {
            setBirthdate(date.toDate())
            setBirthdateText(date.format("DD/MM/YYYY"))
        } else {
            setBirthdate(null)
            setBirthdateText("")
        }
    }

    useImperativeHandle(ref, () => ({
        fieldsWithErrorText(errors) {
            if (errorBreed) {
                errors.push("Raça")
            }
            if (errorGender) {
                errors.push("Sexo")
            }
            if (errorBirthdate) {
                errors.push("Data de nascimento")
            }
            return errors
        },
        isValid() {
            return !errorBreed && !errorGender && !errorBirthdate
        },
        validate(isValid) {
            if (breed) {
                setErrorBreed(false)
                setHelperBreed(mandatoryField)
            } else {
                isValid = false
                setErrorBreed(true)
                setHelperBreed("A raça do animal é obrigatória")
            }

            if (gender) {
                setErrorGender(false)
                setHelperGender(mandatoryField)
            } else {
                isValid = false
                setErrorGender(true)
                setHelperGender("O sexo do animal é obrigatório")
            }

            const libBirthdate = dayjs(birthdate)
            if (birthdateText.replace(/[^0-9]/g, '').length > 0 || ageYear.length > 0 || ageMonth.length > 0) {
                if (libBirthdate.isValid()) {
                    setErrorBirthdate(false)
                    setHelperBirthdate(mandatoryField)
                } else {
                    isValid = false
                    setErrorBirthdate(true)
                    setHelperBirthdate("A data informada não é válida")
                }
            } else {
                isValid = false
                setErrorBirthdate(true)
                setHelperBirthdate("A data de nascimento do animal é obrigatória")
            }

            return isValid
        }
    }))

    useEffect(() => {
        if (patient && patient.get) {
            setBreed(patient.get("breed") || '')
            setGender(patient.get("gender") || '')
            if (patient.get("birthdate")) {
                const birthdate = patient.get("birthdate")
                handleBirthdate(dayjs(birthdate).format("DD/MM/YYYY"))
            }
            if (patient.get("specie")) {
                getBreeds(patient.get("specie")).then(_breeds => setBreeds(_breeds))
            }
            getGenders().then(_genders => setGenders(_genders))
        }
    }, [ patient ])

    return patient && (
        <div className="row">
            <div className="col">
                <VSAccordionSimple
                    expanded={showPatient}
                    header={(_) => (
                        "Dados do Animal"
                    )}
                    onOpen={() => setShowPatient(true)}
                    onClose={() => setShowPatient(true)}
                >
                    <div className="row">
                        <div className="col">
                            <h4 className="mb-0">{ patient.get("name") }</h4>
                            <p>Espécie: { patient.get("specie") }</p>
                            <div className="row mb-4">
                                <div className="col-8">
                                    <Autocomplete
                                        inputValue={breed}
                                        options={breeds}
                                        onChange={(_, selected) => setBreed(selected || '')}
                                        onInputChange={(event, selected) => event && setBreed(selected || '')}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                className="w-100"
                                                placeholder="Raça do animal"
                                                label="Raça"
                                                size="small"
                                                variant="outlined"
                                                error={errorBreed}
                                                helperText={helperBreed}
                                            />
                                        )}
                                        openOnFocus
                                        freeSolo
                                    />
                                </div>
                                <div className="col-4">
                                    <Autocomplete
                                        value={gender || null}
                                        options={genders}
                                        onChange={(_, selected) => setGender(selected || '')}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                className="w-100"
                                                placeholder="Sexo do animal"
                                                label="Sexo"
                                                size="small"
                                                variant="outlined"
                                                error={errorGender}
                                                helperText={helperGender}
                                            />
                                        )}
                                        openOnFocus
                                    />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col">
                                    <DateInput
                                        className="w-100 my-0"
                                        placeholder="00/00/0000"
                                        label="Data de nascimento"
                                        size="small"
                                        variant="outlined"
                                        value={birthdateText || null}
                                        error={errorBirthdate}
                                        helperText={helperBirthdate}
                                        onChange={(value) => handleBirthdate(value)}
                                    />
                                </div>
                                <div className="col-auto px-0">
                                    <div className="separator-text">OU</div>
                                </div>
                                <div className="col-3 pe-1">
                                    <Autocomplete
                                        value={ageYear || null}
                                        options={years}
                                        onChange={(_, selected) => handleAge(selected, ageMonth)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                size="small"
                                                variant="outlined"
                                                className="w-100"
                                                placeholder="0"
                                                label="Anos"
                                                error={errorBirthdate}
                                            />
                                        )}
                                        openOnFocus
                                    />
                                </div>
                                <div className="col-3 ps-1">
                                    <Autocomplete
                                        value={ageMonth || null}
                                        options={months}
                                        onChange={(_, selected) => handleAge(ageYear, selected)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                size="small"
                                                variant="outlined"
                                                className="w-100"
                                                placeholder="0"
                                                label="Meses"
                                                error={errorBirthdate}
                                            />
                                        )}
                                        openOnFocus
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </VSAccordionSimple>
            </div>
        </div>
    )
})

export default TermPatientForm;