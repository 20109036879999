import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react'
import { connect } from 'react-redux';

import {
    showArrayIndex,
    mandatoryField,
    getStateList,
    optionalField,
    validateEmail,
    validatePhone,
    validatePostalCode,
    getAddressByZipCode
} from '../../../../utils'

import { ReactComponent as IconAddO } from "../../../../assets/icon-add-o.svg"
import { ReactComponent as IconCloseO } from "../../../../assets/icon-close-o.svg"
import { ReactComponent as IconPrimaryInfoO } from "../../../../assets/icon-primary-info-o.svg"
import { ReactComponent as IconDangerExclamationO } from "../../../../assets/icon-danger-exclamation-o.svg"

import { CircularProgress, InputAdornment, MenuItem, TextField } from '@mui/material'
import TextFieldMask from '../../../../components/textFieldMask/TextFieldMask';

let initialUserData = {}
let initialClinicData = {}

const TermTelemedicineForm = forwardRef((props, ref) => {

    const {
        clinic,
        userName,
        setUserName,
        errorUserName,
        setErrorUserName,
        helperUserName,
        setHelperUserName,
        userCrmv,
        setUserCrmv,
        errorUserCrmv,
        setErrorUserCrmv,
        helperUserCrmv,
        setHelperUserCrmv,
        userCrmvStateId,
        setUserCrmvStateId,
        errorUserCrmvStateId,
        setErrorUserCrmvStateId,
        helperUserCrmvStateId,
        setHelperUserCrmvStateId,
        userContactEmail,
        setUserContactEmail,
        errorUserContactEmail,
        setErrorUserContactEmail,
        helperUserContactEmail,
        setHelperUserContactEmail,
        userContactPhone,
        setUserContactPhone,
        errorUserContactPhone,
        setErrorUserContactPhone,
        helperUserContactPhone,
        setHelperUserContactPhone,
        userLoadingZipCode,
        setUserLoadingZipCode,
        userZipCode,
        setUserZipCode,
        errorUserZipCode,
        setErrorUserZipCode,
        helperUserZipCode,
        setHelperUserZipCode,
        userAddressStreet,
        setUserAddressStreet,
        errorUserAddressStreet,
        setErrorUserAddressStreet,
        helperUserAddressStreet,
        setHelperUserAddressStreet,
        userAddressNumber,
        setUserAddressNumber,
        errorUserAddressNumber,
        setErrorUserAddressNumber,
        helperUserAddressNumber,
        setHelperUserAddressNumber,
        userAddressComplement,
        setUserAddressComplement,
        userNeighborhood,
        setUserNeighborhood,
        errorUserNeighborhood,
        setErrorUserNeighborhood,
        helperUserNeighborhood,
        setHelperUserNeighborhood,
        userCity,
        setUserCity,
        errorUserCity,
        setErrorUserCity,
        helperUserCity,
        setHelperUserCity,
        userStateId,
        setUserStateId,
        errorUserStateId,
        setErrorUserStateId,
        helperUserStateId,
        setHelperUserStateId,
        clinicCompanyName,
        setClinicCompanyName,
        errorClinicCompanyName,
        setErrorClinicCompanyName,
        helperClinicCompanyName,
        setHelperClinicCompanyName,
        clinicCnpj,
        setClinicCnpj,
        errorClinicCnpj,
        setErrorClinicCnpj,
        helperClinicCnpj,
        setHelperClinicCnpj,
        clinicCrmv,
        setClinicCrmv,
        errorClinicCrmv,
        setErrorClinicCrmv,
        helperClinicCrmv,
        setHelperClinicCrmv,
        clinicCrmvStateId,
        setClinicCrmvStateId,
        errorClinicCrmvStateId,
        setErrorClinicCrmvStateId,
        helperClinicCrmvStateId,
        setHelperClinicCrmvStateId,
        clinicRegistrationMapa,
        setClinicRegistrationMapa,
        errorClinicRegistrationMapa,
        setErrorClinicRegistrationMapa,
        helperClinicRegistrationMapa,
        setHelperClinicRegistrationMapa,
        clinicStateRegistrationId,
        setClinicStateRegistrationId,
        clinicMunicipalRegistrationId,
        setClinicMunicipalRegistrationId,
        clinicLoadingZipCode,
        setClinicLoadingZipCode,
        clinicZipCode,
        setClinicZipCode,
        errorClinicZipCode,
        setErrorClinicZipCode,
        helperClinicZipCode,
        setHelperClinicZipCode,
        clinicAddressStreet,
        setClinicAddressStreet,
        errorClinicAddressStreet,
        setErrorClinicAddressStreet,
        helperClinicAddressStreet,
        setHelperClinicAddressStreet,
        clinicAddressNumber,
        setClinicAddressNumber,
        errorClinicAddressNumber,
        setErrorClinicAddressNumber,
        helperClinicAddressNumber,
        setHelperClinicAddressNumber,
        clinicAddressComplement,
        setClinicAddressComplement,
        clinicNeighborhood,
        setClinicNeighborhood,
        errorClinicNeighborhood,
        setErrorClinicNeighborhood,
        helperClinicNeighborhood,
        setHelperClinicNeighborhood,
        clinicCity,
        setClinicCity,
        errorClinicCity,
        setErrorClinicCity,
        helperClinicCity,
        setHelperClinicCity,
        clinicStateId,
        setClinicStateId,
        errorClinicStateId,
        setErrorClinicStateId,
        helperClinicStateId,
        setHelperClinicStateId,
        documentSubType,
        setDocumentSubType,
        vets,
        setVets,
        notes,
        setNotes
    } = props

    const defaultVetWithValidation = {
        name: '',
        errorName: false,
        helperName: mandatoryField,
        crmv: '',
        errorCrmv: false,
        helperCrmv: mandatoryField,
        crmvStateId: '',
        errorCrmvStateId: false,
        helperCrmvStateId: mandatoryField
    }

    const [ vetsRequests, setVetsRequests ] = useState([])

    const setVet = (index, field, value) => {
        let newVets = [...vetsRequests]
        newVets[index][field] = value
        setVetsRequests(newVets)
    }

    const removeVet = (index) => {
        let newVets = [...vetsRequests]
        newVets.splice(index, 1)
        setVetsRequests(newVets)
    }

    const getUserData = () => {
        return {
            userName: userName,
            userCrmv: userCrmv,
            userCrmvStateId: userCrmvStateId,
            userContactEmail: userContactEmail,
            userContactPhone: userContactPhone,
            userLoadingZipCode: userLoadingZipCode,
            userZipCode: userZipCode,
            userAddressStreet: userAddressStreet,
            userAddressNumber: userAddressNumber,
            userAddressComplement: userAddressComplement,
            userNeighborhood: userNeighborhood,
            userCity: userCity,
            userStateId: userStateId
        }
    }

    const getClinicData = () => {
        return {
            clinicCompanyName: clinicCompanyName,
            clinicCnpj: clinicCnpj,
            clinicCrmv: clinicCrmv,
            clinicCrmvStateId: clinicCrmvStateId,
            clinicRegistrationMapa: clinicRegistrationMapa,
            clinicStateRegistrationId: clinicStateRegistrationId,
            clinicMunicipalRegistrationId: clinicMunicipalRegistrationId,
            clinicLoadingZipCode: clinicLoadingZipCode,
            clinicZipCode: clinicZipCode,
            clinicAddressStreet: clinicAddressStreet,
            clinicAddressNumber: clinicAddressNumber,
            clinicAddressComplement: clinicAddressComplement,
            clinicNeighborhood: clinicNeighborhood,
            clinicCity: clinicCity,
            clinicStateId: clinicStateId
        }
    }

    useImperativeHandle(ref, () => ({
        fieldsWithErrorText(errors) {
            if (errorUserName) {
                errors.push("Nome do profissional")
            }
            if (errorUserCrmv) {
                errors.push("CRMV do profissional")
            }
            if (errorUserCrmvStateId) {
                errors.push("Estado do CRMV")
            }
            if (errorUserContactEmail) {
                errors.push("E-mail do profissional")
            }
            if (errorUserContactPhone) {
                errors.push("Telefone do profissional")
            }
            if (errorUserZipCode) {
                errors.push("Código postal (CEP) do profissional")
            }
            if (errorUserAddressStreet) {
                errors.push("Endereço do profissional")
            }
            if (errorUserAddressNumber) {
                errors.push("Número do endereço do profissional")
            }
            if (errorUserNeighborhood) {
                errors.push("Bairro do profissional")
            }
            if (errorUserCity) {
                errors.push("Cidade do profissional")
            }
            if (errorUserStateId) {
                errors.push("Estado do profissional")
            }
            if (errorClinicCompanyName) {
                errors.push("Razão social do estabelecimento")
            }
            if (errorClinicCnpj) {
                errors.push("CNPJ do estabelecimento")
            }
            if (errorClinicCrmv) {
                errors.push("CRMV do estabelecimento")
            }
            if (errorClinicCrmvStateId) {
                errors.push("Estado do CRMV do estabelecimento")
            }
            if (errorClinicRegistrationMapa) {
                errors.push("Registro MAPA do estabelecimento")
            }
            if (errorClinicZipCode) {
                errors.push("Código postal (CEP) do estabelecimento")
            }
            if (errorClinicAddressStreet) {
                errors.push("Endereço do estabelecimento")
            }
            if (errorClinicAddressNumber) {
                errors.push("Número do endereço do estabelecimento")
            }
            if (errorClinicNeighborhood) {
                errors.push("Bairro do estabelecimento")
            }
            if (errorClinicCity) {
                errors.push("Cidade do estabelecimento")
            }
            if (errorClinicStateId) {
                errors.push("Estado do estabelecimento")
            }
            vetsRequests.map((vet, index) => {
                if (vet.errorName) {
                    errors.push(`O nome ${showArrayIndex(index + 1)} não foi preenchido`)
                }
                if (vet.errorCrmv) {
                    errors.push(`O CRMV ${showArrayIndex(index + 1)} não foi preenchido`)
                }
                if (vet.errorCrmvStateId) {
                    errors.push(`O estado do CRMV ${showArrayIndex(index + 1)} não foi preenchido`)
                }
            })
            return errors
        },
        isValid() {
            return !errorUserName && !errorUserCrmv && !errorUserCrmvStateId && !errorUserContactEmail && !errorUserContactPhone && !errorUserZipCode && !errorUserAddressStreet && !errorUserAddressNumber && !errorUserNeighborhood && !errorUserCity && !errorUserStateId && !errorClinicCompanyName && !errorClinicCnpj && !errorClinicCrmv && !errorClinicCrmvStateId && !errorClinicRegistrationMapa && !errorClinicZipCode && !errorClinicAddressStreet && !errorClinicAddressNumber && !errorClinicNeighborhood && !errorClinicCity && !errorClinicStateId
        },
        validate(isValid) {
            if (userName) {
                setErrorUserName(false)
                setHelperUserName(mandatoryField)
            } else {
                isValid = false
                setErrorUserName(true)
                setHelperUserName("O nome do profissional é obrigatório")
            }
            
            if (userCrmv) {
                setErrorUserCrmv(false)
                setHelperUserCrmv(mandatoryField)
            } else {
                isValid = false
                setErrorUserCrmv(true)
                setHelperUserCrmv("O CRMV do profissional é obrigatório")
            }
            
            if (userCrmvStateId) {
                setErrorUserCrmvStateId(false)
                setHelperUserCrmvStateId(mandatoryField)
            } else {
                isValid = false
                setErrorUserCrmvStateId(true)
                setHelperUserCrmvStateId("O estado do CRMV é obrigatório")
            }
            
            if (validateEmail(userContactEmail)) {
                setErrorUserContactEmail(false)
                setHelperUserContactEmail(mandatoryField)
            } else {
                isValid = false
                setErrorUserContactEmail(true)
                setHelperUserContactEmail("O e-mail do profissional é obrigatório")
            }
            
            if (validatePhone(userContactPhone)) {
                setErrorUserContactPhone(false)
                setHelperUserContactPhone(mandatoryField)
            } else {
                isValid = false
                setErrorUserContactPhone(true)
                setHelperUserContactPhone("O telefone do profissional é obrigatório")
            }

            if (documentSubType == 0) {
                if (validatePostalCode(userZipCode)) {
                    setErrorUserZipCode(false)
                    setHelperUserZipCode(mandatoryField)
                } else {
                    isValid = false
                    setErrorUserZipCode(true)
                    setHelperUserZipCode("O código postal (CEP) do profissional é obrigatório")
                }
                
                if (userAddressStreet) {
                    setErrorUserAddressStreet(false)
                    setHelperUserAddressStreet(mandatoryField)
                } else {
                    isValid = false
                    setErrorUserAddressStreet(true)
                    setHelperUserAddressStreet("O endereço do profissional é obrigatório")
                }
                
                if (userAddressNumber) {
                    setErrorUserAddressNumber(false)
                    setHelperUserAddressNumber(mandatoryField)
                } else {
                    isValid = false
                    setErrorUserAddressNumber(true)
                    setHelperUserAddressNumber("O número do endereço do profissional é obrigatório")
                }
                
                if (userNeighborhood) {
                    setErrorUserNeighborhood(false)
                    setHelperUserNeighborhood(mandatoryField)
                } else {
                    isValid = false
                    setErrorUserNeighborhood(true)
                    setHelperUserNeighborhood("O bairro do profissional é obrigatório")
                }
                
                if (userCity) {
                    setErrorUserCity(false)
                    setHelperUserCity(mandatoryField)
                } else {
                    isValid = false
                    setErrorUserCity(true)
                    setHelperUserCity("A cidade do profissional é obrigatória")
                }
                
                if (userStateId) {
                    setErrorUserStateId(false)
                    setHelperUserStateId(mandatoryField)
                } else {
                    isValid = false
                    setErrorUserStateId(true)
                    setHelperUserStateId("O estado do profissional é obrigatório")
                }
                
                setErrorClinicCompanyName(false)
                setHelperClinicCompanyName(mandatoryField)
                setErrorClinicCnpj(false)
                setHelperClinicCnpj(mandatoryField)
                setErrorClinicCrmv(false)
                setHelperClinicCrmv(mandatoryField)
                setErrorClinicCrmvStateId(false)
                setHelperClinicCrmvStateId(mandatoryField)
                setErrorClinicRegistrationMapa(false)
                setHelperClinicRegistrationMapa(mandatoryField)
                setErrorClinicZipCode(false)
                setHelperClinicZipCode(mandatoryField)
                setErrorClinicAddressStreet(false)
                setHelperClinicAddressStreet(mandatoryField)
                setErrorClinicAddressNumber(false)
                setHelperClinicAddressNumber(mandatoryField)
                setErrorClinicNeighborhood(false)
                setHelperClinicNeighborhood(mandatoryField)
                setErrorClinicCity(false)
                setHelperClinicCity(mandatoryField)
                setErrorClinicStateId(false)
                setHelperClinicStateId(mandatoryField)
            } else {
                if (clinicCompanyName) {
                    setErrorClinicCompanyName(false)
                    setHelperClinicCompanyName(mandatoryField)
                } else {
                    isValid = false
                    setErrorClinicCompanyName(true)
                    setHelperClinicCompanyName("A razão social do estabelecimento é obrigatória")
                }
                
                if (clinicCnpj) {
                    setErrorClinicCnpj(false)
                    setHelperClinicCnpj(mandatoryField)
                } else {
                    isValid = false
                    setErrorClinicCnpj(true)
                    setHelperClinicCnpj("O CNPJ do estabelecimento é obrigatório")
                }
                
                if (clinicCrmv) {
                    setErrorClinicCrmv(false)
                    setHelperClinicCrmv(mandatoryField)
                } else {
                    isValid = false
                    setErrorClinicCrmv(true)
                    setHelperClinicCrmv("O CRMV do estabelecimento é obrigatório")
                }
                
                if (clinicCrmvStateId) {
                    setErrorClinicCrmvStateId(false)
                    setHelperClinicCrmvStateId(mandatoryField)
                } else {
                    isValid = false
                    setErrorClinicCrmvStateId(true)
                    setHelperClinicCrmvStateId("O estado do CRMV do estabelecimento é obrigatório")
                }
                
                if (clinicRegistrationMapa) {
                    setErrorClinicRegistrationMapa(false)
                    setHelperClinicRegistrationMapa(mandatoryField)
                } else {
                    isValid = false
                    setErrorClinicRegistrationMapa(true)
                    setHelperClinicRegistrationMapa("O registro MAPA do estabelecimento é obrigatório")
                }
                
                if (clinicZipCode) {
                    setErrorClinicZipCode(false)
                    setHelperClinicZipCode(mandatoryField)
                } else {
                    isValid = false
                    setErrorClinicZipCode(true)
                    setHelperClinicZipCode("O código postal (CEP) do estabelecimento é obrigatório")
                }
                
                if (clinicAddressStreet) {
                    setErrorClinicAddressStreet(false)
                    setHelperClinicAddressStreet(mandatoryField)
                } else {
                    isValid = false
                    setErrorClinicAddressStreet(true)
                    setHelperClinicAddressStreet("O endereço do estabelecimento é obrigatório")
                }
                
                if (clinicAddressNumber) {
                    setErrorClinicAddressNumber(false)
                    setHelperClinicAddressNumber(mandatoryField)
                } else {
                    isValid = false
                    setErrorClinicAddressNumber(true)
                    setHelperClinicAddressNumber("O número do endereço do estabelecimento é obrigatório")
                }
                
                if (clinicNeighborhood) {
                    setErrorClinicNeighborhood(false)
                    setHelperClinicNeighborhood(mandatoryField)
                } else {
                    isValid = false
                    setErrorClinicNeighborhood(true)
                    setHelperClinicNeighborhood("O bairro do estabelecimento é obrigatório")
                }
                
                if (clinicCity) {
                    setErrorClinicCity(false)
                    setHelperClinicCity(mandatoryField)
                } else {
                    isValid = false
                    setErrorClinicCity(true)
                    setHelperClinicCity("A cidade do estabelecimento é obrigatória")
                }
                
                if (clinicStateId) {
                    setErrorClinicStateId(false)
                    setHelperClinicStateId(mandatoryField)
                } else {
                    isValid = false
                    setErrorClinicStateId(true)
                    setHelperClinicStateId("O estado do estabelecimento é obrigatório")
                }
                
                setErrorUserZipCode(false)
                setHelperUserZipCode(mandatoryField)
                setErrorUserAddressStreet(false)
                setHelperUserAddressStreet(mandatoryField)
                setErrorUserAddressNumber(false)
                setHelperUserAddressNumber(mandatoryField)
                setErrorUserNeighborhood(false)
                setHelperUserNeighborhood(mandatoryField)
                setErrorUserCity(false)
                setHelperUserCity(mandatoryField)
                setErrorUserStateId(false)
                setHelperUserStateId(mandatoryField)
            }

            if (vetsRequests.length == 0) {
                isValid = false
            } else {
                vetsRequests.map((vet, index) => {
                    if (vet.name) {
                        setVet(index, "errorName", false)
                        setVet(index, "helperName", mandatoryField)
                    } else {
                        isValid = false
                        setVet(index, "errorName", true)
                        setVet(index, "helperName", "O nome do profissional é obrigatório")
                    }

                    if (vet.crmv) {
                        setVet(index, "errorCrmv", false)
                        setVet(index, "helperCrmv", mandatoryField)
                    } else {
                        isValid = false
                        setVet(index, "errorCrmv", true)
                        setVet(index, "helperCrmv", "O CRMV do profissional é obrigatório")
                    }

                    if (vet.crmvStateId) {
                        setVet(index, "errorCrmvStateId", false)
                        setVet(index, "helperCrmvStateId", mandatoryField)
                    } else {
                        isValid = false
                        setVet(index, "errorCrmvStateId", true)
                        setVet(index, "helperCrmvStateId", "O estado do CRMV do profissional é obrigatório")
                    }
                })
            }
            return isValid
        }
    }))

    useEffect(() => {
        setUserLoadingZipCode(true)
        getAddressByZipCode(userZipCode)
        .then(data => {
            setUserLoadingZipCode(false)
            setUserAddressStreet(data.address)
            setUserNeighborhood(data.neighborhood)
            setUserCity(data.city)
            setUserStateId(data.state_id)
        })
        .catch(_ => {
            setUserLoadingZipCode(false)
        })
    }, [ userZipCode ])

    useEffect(() => {
        setClinicLoadingZipCode(true)
        getAddressByZipCode(clinicZipCode)
        .then(data => {
            setClinicLoadingZipCode(false)
            setClinicAddressStreet(data.address)
            setClinicNeighborhood(data.neighborhood)
            setClinicCity(data.city)
            setClinicStateId(data.state_id)
        })
        .catch(_ => {
            setClinicLoadingZipCode(false)
        })
    }, [ clinicZipCode ])

    useEffect(() => {
        setVetsRequests(vets.map(vet => Object.assign({}, { ...defaultVetWithValidation }, vet)))
    }, [])

    useEffect(() => {
        setVets(vetsRequests.map(vet => ({
            name: vet.name,
            crmv: vet.crmv,
            crmvStateId: vet.crmvStateId
        })))
    }, [ JSON.stringify(vetsRequests.map(vet => ({ name: vet.name, crmv: vet.crmv, crmvStateId: vet.crmvStateId }))) ])

    if (!initialUserData.userName) {
        initialUserData = getUserData()
    }
    if (!initialClinicData.clinicCompanyName) {
        initialClinicData = getClinicData()
    }

    return (
        <div className="row">
            <div className="col">
                <div className="row pt-2 mb-2">
                    <div className="col-12">
                        <div className="input-title input-height">
                            Identificação do Emitente:
                        </div>
                    </div>
                </div>
                <div className="row">
                    { JSON.stringify(initialUserData) != JSON.stringify(getUserData()) && (
                        <div className="col-12 mb-3">
                            <div className="alert alert-secondary mb-1">
                                <IconPrimaryInfoO width="24" height="24" /> As informações da sua conta Vet Smart também serão atualizadas.
                            </div>
                        </div>
                    )}
                    <div className="col-12 col-xl-5 mb-3">
                        <TextField
                            placeholder="Digite o nome do Profissional"
                            label="Nome do Profissional"
                            onChange={({ target: { value } }) => setUserName(value)}
                            value={userName}
                            error={errorUserName}
                            helperText={helperUserName}
                            size="small"
                            variant="outlined"
                            fullWidth
                        />
                    </div>
                    <div className="col-6 col-xl-4 mb-3">
                        <TextField
                            type="tel"
                            placeholder="Digite o CRMV do Profissional"
                            label="Número CRMV"
                            onChange={({ target: { value } }) => setUserCrmv(value)}
                            value={userCrmv}
                            error={errorUserCrmv}
                            helperText={helperUserCrmv}
                            size="small"
                            variant="outlined"
                            fullWidth
                        />
                    </div>
                    <div className="col-6 col-xl-3 mb-3">
                        <TextField
                            placeholder="Selecione o Estado do CRMV"
                            label="Estado CRMV"
                            onChange={({ target: { value } }) => setUserCrmvStateId(value)}
                            value={userCrmvStateId}
                            error={errorUserCrmvStateId}
                            helperText={helperUserCrmvStateId}
                            size="small"
                            variant="outlined"
                            select
                            fullWidth
                        >
                            { getStateList().map(stateItem => (
                                <MenuItem key={stateItem.value} value={stateItem.value}>
                                    { stateItem.text }
                                </MenuItem>
                            )) }
                        </TextField>
                    </div>
                    <div className="col-6 col-xl-8 mb-3">
                        <TextField
                            type="email"
                            placeholder="exemplo@email.com"
                            label="E-mail"
                            onChange={({ target: { value } }) => setUserContactEmail(value)}
                            value={userContactEmail}
                            error={errorUserContactEmail}
                            helperText={helperUserContactEmail}
                            size="small"
                            variant="outlined"
                            fullWidth
                        />
                    </div>
                    <div className="col-6 col-xl-4 mb-3">
                        <TextFieldMask
                            type="tel"
                            placeholder="(00) 00000-0000"
                            label="Celular"
                            mask={userContactPhone?.length <= 14 ? '(00) 0000-0000[0]' : '(00) 00000-0000'}
                            onAccept={(value) => setUserContactPhone(value)}
                            value={userContactPhone}
                            error={errorUserContactPhone}
                            helperText={helperUserContactPhone}
                            size="small"
                            variant="outlined"
                            fullWidth
                        />
                    </div>
                </div>
                <div className="row mb-1">
                    <div className="col-12">
                        <div className="input-title input-height">
                            Emitir com informações de:
                        </div>
                    </div>
                </div>
                <div>
                    <div className="row mb-1">
                        <div className="col-12">
                            <div className="btn-tabs mb-3">
                                <div
                                    className={`btn-tab ${documentSubType == "0" ? 'active' : ''}`}
                                    onClick={() => setDocumentSubType("0")}
                                >
                                    Médico(a) Veterinário(a)
                                </div>
                                <div
                                    className={`btn-tab ${documentSubType == "1" ? 'active' : ''}`}
                                    onClick={() => setDocumentSubType("1")}
                                >
                                    Estabelecimento
                                </div>
                            </div>
                        </div>
                    </div>
                    { documentSubType == "0" ? (
                        <div className="row">
                            <div className="col-6 col-xl-3 mb-3">
                                <TextFieldMask
                                    type="tel"
                                    mask="00000-000"
                                    placeholder="00000-000"
                                    label="CEP"
                                    onAccept={(value) => setUserZipCode(value)}
                                    value={userZipCode}
                                    error={errorUserZipCode}
                                    helperText={helperUserZipCode}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                { userLoadingZipCode && (
                                                    <CircularProgress size={16} />
                                                )}
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </div>
                            <div className="col-7 col-xl-6 mb-3">
                                <TextField
                                    placeholder="Rua, avenida, alameda, etc."
                                    label="Endereço"
                                    onChange={({ target: { value } }) => setUserAddressStreet(value)}
                                    value={userAddressStreet}
                                    error={errorUserAddressStreet}
                                    helperText={helperUserAddressStreet}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                />
                            </div>
                            <div className="col-5 col-xl-3 mb-3">
                                <TextField
                                    placeholder="000"
                                    label="Número"
                                    onChange={({ target: { value } }) => setUserAddressNumber(value)}
                                    value={userAddressNumber}
                                    error={errorUserAddressNumber}
                                    helperText={helperUserAddressNumber}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                />
                            </div>
                            <div className="col-6 mb-3">
                                <TextField
                                    placeholder="Torre Oceania, Apartamento 12"
                                    label="Complemento"
                                    onChange={({ target: { value } }) => setUserAddressComplement(value)}
                                    value={userAddressComplement}
                                    helperText={optionalField}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                />
                            </div>
                            <div className="col-6 mb-3">
                                <TextField
                                    placeholder="Jardim das Flores"
                                    label="Bairro"
                                    onChange={({ target: { value } }) => setUserNeighborhood(value)}
                                    value={userNeighborhood}
                                    error={errorUserNeighborhood}
                                    helperText={helperUserNeighborhood}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                />
                            </div>
                            <div className="col-7 col-xl-7 mb-3">
                                <TextField
                                    placeholder="São Paulo"
                                    label="Cidade"
                                    onChange={({ target: { value } }) => setUserCity(value)}
                                    value={userCity}
                                    error={errorUserCity}
                                    helperText={helperUserCity}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                />
                            </div>
                            <div className="col-5 col-xl-5 mb-3">
                                <TextField
                                    placeholder="São Paulo"
                                    label="Estado"
                                    onChange={({ target: { value } }) => setUserStateId(value)}
                                    value={userStateId}
                                    error={errorUserStateId}
                                    helperText={helperUserStateId}
                                    select
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                >
                                    { getStateList().map(stateItem => (
                                        <MenuItem key={stateItem.value} value={stateItem.value}>
                                            { stateItem.text }
                                        </MenuItem>
                                    )) }
                                </TextField>
                            </div>
                        </div>
                    ) : (
                        <div className="row">
                            { !clinic.isOwner ? (
                                <div className="col-12 mb-3">
                                    <div className="alert alert-danger mb-1">
                                        <IconDangerExclamationO width="22" height="22" className="me-2" /> Somente o dono da clínica pode alterar as informações abaixo.
                                    </div>
                                </div>
                            ) : JSON.stringify(initialClinicData) != JSON.stringify(getClinicData()) && (
                                <div className="col-12 mb-3">
                                    <div className="alert alert-secondary mb-1">
                                        <IconPrimaryInfoO width="24" height="24" /> As informações da Clínica também serão atualizadas.
                                    </div>
                                </div>
                            )}
                            <div className="col-12 col-xl-7 mb-3">
                                <TextField
                                    placeholder="Razão social"
                                    label="Razão social"
                                    onChange={({ target: { value } }) => setClinicCompanyName(value)}
                                    value={clinicCompanyName}
                                    error={errorClinicCompanyName}
                                    helperText={helperClinicCompanyName}
                                    disabled={!clinic.isOwner}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                />
                            </div>
                            <div className="col-12 col-xl-5 mb-3">
                                <TextFieldMask
                                    type="tel"
                                    mask="00.000.000/0000-00"
                                    placeholder="00.000.000/0000-00"
                                    label="CNPJ"
                                    onChange={({ target: { value } }) => setClinicCnpj(value)}
                                    value={clinicCnpj}
                                    error={errorClinicCnpj}
                                    helperText={helperClinicCnpj}
                                    disabled={!clinic.isOwner}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                />
                            </div>
                            <div className="col-6 col-xl-4 mb-3">
                                <TextField
                                    type="tel"
                                    placeholder="00000"
                                    label="CRMV da clínica"
                                    onChange={({ target: { value } }) => setClinicCrmv(value)}
                                    value={clinicCrmv}
                                    error={errorClinicCrmv}
                                    helperText={helperClinicCrmv}
                                    disabled={!clinic.isOwner}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                />
                            </div>
                            <div className="col-6 col-xl-4 mb-3">
                                <TextField
                                    placeholder="Estado do CRMV"
                                    label="Estado do CRMV"
                                    onChange={({ target: { value } }) => setClinicCrmvStateId(value)}
                                    value={clinicCrmvStateId}
                                    error={errorClinicCrmvStateId}
                                    helperText={helperClinicCrmvStateId}
                                    disabled={!clinic.isOwner}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    select
                                >
                                    { getStateList().map(stateItem => (
                                        <MenuItem key={stateItem.value} value={stateItem.value}>
                                            { stateItem.text }
                                        </MenuItem>
                                    )) }
                                </TextField>
                            </div>
                            <div className="col-12 col-xl-4 mb-3">
                                <TextField
                                    placeholder="Ex: 12345678/2021"
                                    label="Registro MAPA"
                                    onChange={({ target: { value } }) => setClinicRegistrationMapa(value)}
                                    value={clinicRegistrationMapa}
                                    error={errorClinicRegistrationMapa}
                                    helperText={helperClinicRegistrationMapa}
                                    disabled={!clinic.isOwner}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                />
                            </div>
                            <div className="col-6 mb-3">
                                <TextField
                                    placeholder="Ex: 0001/00"
                                    label="Inscrição Estadual"
                                    onChange={({ target: { value } }) => setClinicStateRegistrationId(value)}
                                    value={clinicStateRegistrationId}
                                    helperText={optionalField}
                                    disabled={!clinic.isOwner}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                />
                            </div>
                            <div className="col-6 mb-3">
                                <TextField
                                    placeholder="Ex: 0001/00"
                                    label="Inscrição Municipal"
                                    onChange={({ target: { value } }) => setClinicMunicipalRegistrationId(value)}
                                    value={clinicMunicipalRegistrationId}
                                    helperText={optionalField}
                                    disabled={!clinic.isOwner}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                />
                            </div>
                            <div className="col-6 col-xl-3 mb-3">
                                <TextFieldMask
                                    type="tel"
                                    mask="00000-000"
                                    placeholder="00000-000"
                                    label="CEP"
                                    onChange={({ target: { value } }) => setClinicZipCode(value)}
                                    value={clinicZipCode}
                                    error={errorClinicZipCode}
                                    helperText={helperClinicZipCode}
                                    disabled={!clinic.isOwner}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                { clinicLoadingZipCode && (
                                                    <CircularProgress size={16} />
                                                )}
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </div>
                            <div className="col-7 col-xl-6 mb-3">
                                <TextField
                                    placeholder="Rua, avenida, alameda, etc."
                                    label="Endereço"
                                    onChange={({ target: { value } }) => setClinicAddressStreet(value)}
                                    value={clinicAddressStreet}
                                    error={errorClinicAddressStreet}
                                    helperText={helperClinicAddressStreet}
                                    disabled={!clinic.isOwner}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                />
                            </div>
                            <div className="col-5 col-xl-3 mb-3">
                                <TextField
                                    placeholder="000"
                                    label="Número"
                                    onChange={({ target: { value } }) => setClinicAddressNumber(value)}
                                    value={clinicAddressNumber}
                                    error={errorClinicAddressNumber}
                                    helperText={helperClinicAddressNumber}
                                    disabled={!clinic.isOwner}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                />
                            </div>
                            <div className="col-6 mb-3">
                                <TextField
                                    placeholder="Torre Oceania, Apartamento 12"
                                    label="Complemento"
                                    onChange={({ target: { value } }) => setClinicAddressComplement(value)}
                                    value={clinicAddressComplement}
                                    helperText={optionalField}
                                    disabled={!clinic.isOwner}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                />
                            </div>
                            <div className="col-6 mb-3">
                                <TextField
                                    placeholder="Jardim das Flores"
                                    label="Bairro"
                                    onChange={({ target: { value } }) => setClinicNeighborhood(value)}
                                    value={clinicNeighborhood}
                                    error={errorClinicNeighborhood}
                                    helperText={helperClinicNeighborhood}
                                    disabled={!clinic.isOwner}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                />
                            </div>
                            <div className="col-7 col-xl-7 mb-3">
                                <TextField
                                    placeholder="São Paulo"
                                    label="Cidade"
                                    onChange={({ target: { value } }) => setClinicCity(value)}
                                    value={clinicCity}
                                    error={errorClinicCity}
                                    helperText={helperClinicCity}
                                    disabled={!clinic.isOwner}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                />
                            </div>
                            <div className="col-5 col-xl-5 mb-3">
                                <TextField
                                    placeholder="São Paulo"
                                    label="Estado"
                                    onChange={({ target: { value } }) => setClinicStateId(value)}
                                    value={clinicStateId}
                                    error={errorClinicStateId}
                                    helperText={helperClinicStateId}
                                    disabled={!clinic.isOwner}
                                    select
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                >
                                    { getStateList().map(stateItem => (
                                        <MenuItem key={stateItem.value} value={stateItem.value}>
                                            { stateItem.text }
                                        </MenuItem>
                                    )) }
                                </TextField>
                            </div>
                        </div>
                    )}
                </div>
                <div className="row mb-1">
                    <div className="col-12">
                        <div className="input-title input-height">
                            Médic{ vets.length == 1 ? "o(a)" : "os(as)" } Veterinári{ vets.length == 1 ? "o(a)" : "os(as)" } que realizar{ vets.length == 1 ? "á" : "ão" } o teleatendimento
                        </div>
                    </div>
                </div>
                { vetsRequests.map((vet, index) => (
                    <React.Fragment key={index}>
                        <div className="row align-items-center mb-3">
                            <div className="col">
                                <div className="input-array py-1">
                                    Profissional { showArrayIndex(index + 1) }
                                </div>
                            </div>
                            { vets.length > 1 && (
                                <div className="col-auto">
                                    <button
                                        className="btn-array px-0"
                                        onClick={() => removeVet(index)}
                                    >
                                        <IconCloseO width="24" height="24" />
                                        Remover Profissional
                                    </button>
                                </div>
                            )}
                        </div>
                        <div className="row mb-4">
                            <div className="col-12">
                                <TextField
                                    placeholder={`Digite o nome do Profissional ${ showArrayIndex(index + 1) }`}
                                    label={`Nome ${ showArrayIndex(index + 1) }`}
                                    onChange={({ target: { value } }) => setVet(index, "name", value)}
                                    value={vet.name}
                                    error={vet.errorName}
                                    helperText={vet.helperName}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                />
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="col-6">
                                <TextField
                                    type="tel"
                                    placeholder={`Digite o CRMV do Profissional ${ showArrayIndex(index + 1) }`}
                                    label={`CRMV ${ showArrayIndex(index + 1) }`}
                                    onChange={({ target: { value } }) => setVet(index, "crmv", value)}
                                    value={vet.crmv}
                                    error={vet.errorCrmv}
                                    helperText={vet.helperCrmv}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                />
                            </div>
                            <div className="col-6">
                                <TextField
                                    placeholder={`Selecione o Estado do CRMV ${ showArrayIndex(index + 1) }`}
                                    label={`Estado ${ showArrayIndex(index + 1) }`}
                                    onChange={({ target: { value } }) => setVet(index, "crmvStateId", value)}
                                    value={vet.crmvStateId}
                                    error={vet.errorCrmvStateId}
                                    helperText={vet.helperCrmvStateId}
                                    size="small"
                                    variant="outlined"
                                    select
                                    fullWidth
                                >
                                    { getStateList().map(stateItem => (
                                        <MenuItem key={stateItem.value} value={stateItem.value}>
                                            { stateItem.text }
                                        </MenuItem>
                                    )) }
                                </TextField>
                            </div>
                        </div>
                    </React.Fragment>
                ))}
                <div className="row align-items-center mb-4">
                    <div className="col-12">
                        <button
                            className="btn-add" 
                            onClick={() => setVetsRequests(vetsRequests.concat({ ...defaultVetWithValidation }))}
                        >
                            <IconAddO width="20" height="20" />
                            Adicionar Profissional { showArrayIndex(vets.length + 1) }
                        </button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="input-title input-height">
                            Observações Gerais (opcional)
                        </div>
                    </div>
                    <div className="col-12">
                        <TextField
                            value={notes}
                            placeholder={`Escreva aqui as observações gerais sobre o atestado`}
                            onChange={({ target: { value } }) => setNotes(value)}
                            size="small"
                            variant="outlined"
                            fullWidth
                            multiline
                        />
                    </div>
                </div>
            </div>
        </div>
    )
})

const mapStateToProps = state => {
    return {
        clinic: state.clinic
    };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(TermTelemedicineForm);