import React, { useEffect, useState } from "react"

import { getUnits } from "./Supply"
import { CircularProgress } from "@mui/material"
import { formatNumber } from "../../utils"

import "./SupplyUnitView.scss"
const SupplyUnitView = (props) => {

    const {
        unit,
        quantity
    } = props

    const [ loading, setLoading ] = useState(true)
    const [ unitObject, setUnitObject ] = useState(null)

    useEffect(() => {
        setLoading(true)
        getUnits().then(units => {
            const _unit = units.find(u => u.id == unit)
            setUnitObject(_unit)
            setLoading(false)
        })
    }, [ unit ])

    if (loading)
        return <CircularProgress />

    if (!unitObject)
        return <>{ quantity || "Não informado" }</>

    const finalQuantity = Math.round(Math.abs(quantity) * 100) / 100

    const text = []
    if (quantity != null) {
        text.push(formatNumber(finalQuantity))
    }
    const unitName = finalQuantity == 1 ? unitObject?.singular : unitObject?.name
    /**
     * // Desenhado pelo Henrique
    text.push(quantity != null ? unitName?.toLowerCase() : unitName)
    if (unitObject?.unit) {
        text.push(`(${ quantity != null ? `${finalQuantity} ` : "" }${ unitObject?.unit })`)
    }
     */
    /**
     * // Solicitado pela Rafaela [https://petlove.slack.com/archives/C012U6QJ4MU/p1711053756131589?thread_ts=1711040774.265969&cid=C012U6QJ4MU]
     */
    text.push(quantity != null ? unitObject?.unit || unitName?.toLowerCase() : unitName)
    return text.join(" ")
}

export default SupplyUnitView