import React, { forwardRef, useEffect, useState } from 'react'
import { Route, Switch, useParams } from 'react-router-dom';
import { connect } from 'react-redux'

import { TableCell, TableRow } from '@mui/material'
import VSMenu from '../../components/vsMenu/VSMenu'
import { ReactComponent as IconPaw } from "../../assets/icon-paw-menu.svg"
import { ReactComponent as IconMenu } from "../../assets/icon-menu-2.svg"
import { ReactComponent as IconUser } from "../../assets/icon-user-menu.svg"
import { ReactComponent as IconHistory } from "../../assets/icon-history-menu.svg"

import { ACL } from '../../constants'
import HomeData from '../home/HomeData'
import { historyDocument, includeDocuments } from '../patient/tabs/history/History'
import { dayjs } from '../../utils'
import PatientShareHistory from '../patient/PatientShareHistory';

import './OwnerViewHistory.scss'
const OwnerViewHistory = props => {

    const {
        clinic,
        owner,
        expanded,
        history,
        permissions,
        patientsData,
        getPatients
    } = props

    const [ loading, setLoading ] = useState(false)
    const [ data, setData ] = useState([])

    const rowsPerPage = 5

    const getHistory = () => {
        var query = new Parse.Query("History")
        query.equalTo("clinic", clinic.object)
        query.equalTo("isDeleted", false)
        query.doesNotExist("examResult")
        query.containedIn("patient", patientsData?.results);
        query = includeDocuments(query)
        query.descending("createdAt")
        query.addAscending("objectId")
        query.withCount()
        query.limit(100)
        return query.find()
        .then(({ count, results }) => {
            setData({
                count: count || 0,
                results: results || []
            })
            setLoading(false)
        })
        .catch(error => {
            console.error(error)
            setLoading(false)
        })
    }
    
    useEffect(() => {
        if(patientsData && loading){
            getHistory()
        }
    }, [ patientsData, loading ])

    return (
        <div className="col-12 mb-3">
            <HomeData
                className="mb-3"
                expanded={expanded}
                headerIcon={<IconHistory width="24px" height="24px" fill="#B3B3B3" />}
                title="Históricos de Registros"
                emptyText="Este(a) Tutor(a) ainda não possui nenhum registro cadastrado"
                loading={loading}
                data={data}
                rowsPerPage={rowsPerPage}
                onChange={(page) => {
                    setData([])
                    setLoading(true)
                    if(!patientsData){
                        getPatients()
                    }
                }}
                renderItem={(_history, index) => {
                    const document = historyDocument(_history)

                    return (
                        <TableRow
                            key={index}
                        >
                            {/* <TableCell
                                style={{ width: '24px' }}
                            >
                                { document?.icon("#B3B3B3") }
                            </TableCell> */}
                            <TableCell
                                style={{ width: '86px' }}
                            >
                                <div className="text-truncate">
                                    { dayjs(_history?.get("createdAt")).format("DD/MM/YYYY") }
                                </div>
                            </TableCell>
                            <TableCell className="px-2">
                                <div className="text-link d-inline" onClick={() => history.push(document?.edit)}>
                                    { document?.document }
                                </div>
                                { _history?.get("patient") && (
                                    <>
                                        &nbsp;para&nbsp;
                                        <div className="d-inline" 
                                            // onClick={() => history.push(`/animal/${_history?.get("patient")?.id}`)}
                                        >
                                            { _history?.get("patient")?.get("name") }
                                        </div>
                                    </>
                                )}
                            </TableCell>
                            <TableCell style={{ width: '24px' }} className="ms-2 flex-shrink-0">
                                <VSMenu
                                    placement="left-start"
                                    openOnHover
                                    style={{background: "#fff"}}
                                    options={[
                                        {
                                            menu: (
                                                <div className="row align-items-center">
                                                    <div className="col-auto">
                                                        <IconPaw width="24" height="24" fill="var(--vsprontuario-primary-color)" />
                                                    </div>
                                                    <div className="col ps-0">
                                                        Ver Animal
                                                    </div>
                                                </div>
                                            ),
                                            onClick: () => history.push(`/animal/${_history?.get("patient")?.id}`)
                                        },
                                        // {
                                        //     menu: (
                                        //         <div className="row align-items-center">
                                        //             <div className="col-auto">
                                        //                 <IconUser width="24" height="24" fill="var(--vsprontuario-primary-color)" />
                                        //             </div>
                                        //             <div className="col ps-0">
                                        //                 Ver Tutor
                                        //             </div>
                                        //         </div>
                                        //     ),
                                        //     onClick: () => history.push(`/tutor/${_history?.get("patient")?.get('owner')?.id}`)
                                        // }
                                    ]}
                                >
                                    <IconMenu width="24" height="24" fill="var(--vsprontuario-primary-color)" />
                                </VSMenu>
                            </TableCell>
                        </TableRow>
                    )
                }}
                footer={(
                    <div className="col">
                        <div onClick={() => history.push(`/tutor/${owner.id}/compartilhar`)} className="btn-outline-submit btn-square cursor-pointer">
                            Compartilhar Histórico
                        </div>
                    </div>
                )}
            />
            <Switch {...props}>
                <Route exact path="/tutor/:id/compartilhar" render={(routerProps) => (
                    <PatientShareHistory owner={owner} {...props} {...routerProps} />
                )} />
            </Switch>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        permissions: state.permissions
    };
};
  
export default connect(mapStateToProps)(OwnerViewHistory);