import React from "react";

import { isReminderLate } from "./Vaccine";
import { getShots, getTitleForVaccine } from "./VaccineListView";
import { showDate } from "../../../../utils";

import './BoxVaccine.scss'

const BoxVaccine = props => {
    const {
        vaccine,
        buttons,
        className
    } = props

    const dateToShow = showDate(vaccine.get("documentDate")) || "-"

    return (
        <div className={`vsbox-vaccine vsbox-item ${className}`}>
            <div className="row align-items-center flex-nowrap">
                <div className="col" style={{minWidth:0}}>
                    <div className="row vsbox-item-text">
                        <div className="col">
                            <div className="vsbox-title vstext-ellipsis">
                                <div className={`vsbox-item-chip me-2 ${
                                    vaccine.get("isReminder") ? (
                                        isReminderLate(vaccine) ? "late" : "scheduled"
                                    ) : 'applied'
                                }`}>
                                    {vaccine.get("isReminder") ? (
                                        isReminderLate(vaccine) ? "Atrasada" : "Programada"
                                    ) : 'Aplicada'}
                                </div>
                                {getTitleForVaccine(vaccine)}
                            </div>
                        </div>
                    </div>
                    <div className="row vsbox-item-text mt-1">
                        <div className="col">
                            {getShots(vaccine) && <span className="me-3"><span className="me-3">{getShots(vaccine)}</span>•</span>}
                            <span>{vaccine.get("isReminder") ? "Programada para o " : "Aplicada "} dia {dateToShow}</span>
                        </div>
                    </div>
                </div>
                {buttons}
            </div>
        </div>
    )
}

export default BoxVaccine