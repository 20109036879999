import React from 'react'
import { connect } from 'react-redux'

import { ReactComponent as IconEdit } from "../../../../assets/icon-primary-edit.svg"
import { ReactComponent as IconTrash } from "../../../../assets/icon-primary-trash.svg"
import { ReactComponent as IconTerm } from "../../../../assets/icon-patient-term.svg"

import { showTerm, deleteTerm } from './Term'
import { addEvent, dayjs, showRegisteredBy } from '../../../../utils'
import { ACL } from '../../../../constants'

import VSMedicalRecordsAudit from '../../../../components/vsMedicalRecordsAudit/VSMedicalRecordsAudit'

import './TermView.scss'
const TermView = (props) => {

    const {
        object,
        history,
        noDocumentDate,
        noAudit,
        permissions
    } = props

    const patient = object.get("patient")
    const isDeleted = object.get("isDeleted")

    return (
        <div id="patient-term-view" className={`vsbox mb-3 ${isDeleted ? "vsbox-disabled" : ""}`}>
            <div className="vsbox-body pb-0">
                <div className="row g-3 mb-3 align-items-center">
                    { !noDocumentDate && (
                        <div className="col-auto pe-0">
                            <div className="vsbox-chip">
                                { dayjs(object.get("documentDate")).format("DD/MM/YYYY") }
                            </div>
                        </div>
                    )}
                    <div className="col-12 col-md">
                        <div className="row g-3 align-items-center">
                            <div className="col-auto">
                                <IconTerm width="24" height="24" fill="var(--vsprontuario-gray-color)" />
                            </div>
                            <div className="col ps-0">
                                <label className="vsbox-info">Tipo de Registro</label>
                                <div className="vsbox-info-text">
                                    Atestados/Termos
                                </div>
                            </div>
                        </div>
                    </div>
                    { !isDeleted && (
                        <div className="col-12 col-md-auto">
                            <div className="row g-3 align-items-center justify-content-center">
                                <div className="col-12 col-md-auto">
                                    <button
                                        className={`vsbox-btn ${isDeleted ? "vsbox-btn-disabled" : "vsbox-btn-primary"}`}
                                        onClick={() => history.push(`/documento/atestado_termo/${ object.id }`)}
                                        disabled={isDeleted}
                                    >
                                        Visualizar
                                    </button>
                                </div>
                                { permissions?.check(ACL.TERM, ACL.Rule.EDIT, object) && (
                                    <div className="col col-md-auto">
                                        <button
                                            className={`vsbox-btn ${isDeleted ? "vsbox-btn-disabled" : "vsbox-btn-dark"}`}
                                            onClick={() => history.push(`/animal/${ patient.id }/atestado_termo/${ object.id }`)}
                                            disabled={isDeleted}
                                        >
                                            <IconEdit width="24" height="24" fill={isDeleted ? "var(--vsprontuario-gray-color)" : "var(--vsprontuario-primary-color-dark)"} />
                                        </button>
                                    </div>
                                )}
                                { permissions?.check(ACL.TERM, ACL.Rule.DELETE, object) && (
                                    <div className="col col-md-auto">
                                        <button
                                            className={`vsbox-btn ${isDeleted ? "vsbox-btn-disabled" : "vsbox-btn-alert"}`}
                                            onClick={() => deleteTerm(object, () => addEvent(["Term__getList", "History__getList"], {}))}
                                            disabled={isDeleted}
                                        >
                                            <IconTrash width="24" height="24" fill={isDeleted ? "var(--vsprontuario-gray-color)" : "var(--vsprontuario-primary-color)"} />
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
                { showRegisteredBy(object) }
                { !isDeleted && (
                    <>
                        <div className="vsbox-separator mb-2"></div>
                        <div className="row vsbox-item-text pb-3">
                            <div className="col-12">
                                <b>Tipo de Atestado/Termo:</b> { showTerm(object) }
                            </div>
                        </div>
                        { !noAudit && (
                            <VSMedicalRecordsAudit parseObject={object} />
                        )}
                    </>
                )}
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        permissions: state.permissions
    }
}
  
export default connect(mapStateToProps)(TermView)