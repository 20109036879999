import React, { useEffect, useRef } from 'react'
import { connect } from 'react-redux'

import { ReactComponent as IconPatientBalance } from '../../../../assets/icon-patient-weight.svg'
import { ReactComponent as IconAdd } from "../../../../assets/icon-primary-add-o.svg"

import { ACL, OrderBy_Oldest, OrderBy_Newest } from '../../../../constants'

import WeightView from './WeightView'
import VSList from '../../../../components/vsList/VSList'
import VSEmpty from '../../../../components/vsEmpty/VSEmpty'

import './WeightList.scss'
const WeightList = (props) => {

    const {
        clinic,
        patient,
        history,
        permissions
    } = props

    const rowsPerPage = 5

    const filter = useRef(null)
    const isMounted = useRef(null)

    if (!permissions?.check(ACL.WEIGHT, ACL.Rule.VIEW)) {
        permissions?.error()
    }

    const getWeight = (_, dateFrom, dateTo, orderBy, page = 1) => {
        return new Promise((resolve, reject) => {
            var query = new Parse.Query("Weight")
            query.equalTo("clinic", clinic.object);
            query.equalTo("isDeleted", false);
            query.equalTo("patient", patient);
            if (dateFrom) {
                query.greaterThanOrEqualTo("documentDate", dateFrom)
            }
            if (dateTo) {
                query.lessThanOrEqualTo("documentDate", dateTo)
            }
            switch (orderBy) {
                case OrderBy_Oldest:
                    query.ascending("documentDate");
                    break;
                default:
                    query.descending("documentDate");
            }
            query.addAscending("objectId")
            query.withCount()
            query.skip((page - 1) * rowsPerPage)
            query.limit(rowsPerPage)
            query.include("createdBy")
            query.include("changedBy")
            query.find()
            .then(data => {
                if (isMounted.current) {
                    resolve({
                        items: data?.results,
                        total: data?.count
                    })
                }
            })
            .catch(error => {
                console.error(error)
                reject(error)
            })
        })
    }

    const onChange = () => {
        if (filter.current) {
            filter.current.onChange()
        }
    }

    useEffect(() => {
        if (permissions && !permissions.check(ACL.WEIGHT, ACL.Rule.VIEW)) {
            permissions.error()
        }
    }, [ permissions ])

    useEffect(() => {
        isMounted.current = true
        document.addEventListener("Weight__getList", onChange)
        return () => { 
            isMounted.current = false
            document.removeEventListener("Weight__getList", onChange)
        }
    }, [])

    return (
        <div id="patient-weight">
            <div className="col-12">
                <VSList
                    ref={filter}
                    title={{
                        text: "Registros de Peso"
                    }}
                    button={permissions?.check(ACL.WEIGHT, ACL.Rule.EDIT) && {
                        text: "Registrar novo Peso",
                        onClick: () => history.push(`/animal/${ patient.id }/peso/novo`)
                    }}
                    interval={{
                        start: {
                            text: "Início",
                            value: null
                        },
                        end: {
                            text: "Término",
                            value: null
                        }
                    }}
                    order={{
                        options: [
                            OrderBy_Newest,
                            OrderBy_Oldest
                        ],
                        value: OrderBy_Newest
                    }}
                    pagination={{
                        rowsPerPage: rowsPerPage
                    }}
                    onChange={({ _, _start, _end, _orderBy, _page }) => {
                        return getWeight(null, _start, _end, _orderBy, _page)
                    }}
                    renderItem={(item) => (
                        <WeightView
                            object={item}
                            history={history}
                        />
                    )}
                    renderEmpty={() => (
                        <VSEmpty
                            icon={<IconPatientBalance width="32px" height="32px" />}
                            title="Você ainda não possui nenhum registro de peso para este animal"
                            text="Cadastre o primeiro peso e comece a acompanhar de perto a evolução do seu paciente"
                        >
                            { permissions?.check(ACL.WEIGHT, ACL.Rule.EDIT) && (
                                <button
                                    id="new-weight-link"
                                    className="vsbox-btn vsbox-btn-primary"
                                    onClick={() => history.push(`/animal/${ patient.id }/peso/novo`)}
                                >
                                    <IconAdd width="14" height="14" className="me-2" />
                                    Cadastrar novo peso
                                </button>
                            )}
                        </VSEmpty>
                    )}
                    renderNotFound={() => (
                        <VSEmpty
                            icon={<IconPatientBalance width="32px" height="32px" />}
                            title="Não encontramos nenhum resultado para a sua busca"
                            text="Tente refazer sua busca utilizando outro período ou crie um novo cadastro utilizando o botão abaixo"
                        >
                            { permissions?.check(ACL.WEIGHT, ACL.Rule.EDIT) && (
                                <button
                                    id="new-weight-link"
                                    className="vsbox-btn vsbox-btn-primary"
                                    onClick={() => history.push(`/animal/${ patient.id }/peso/novo`)}
                                >
                                    <IconAdd width="14" height="14" className="me-2" />
                                    Cadastrar novo peso
                                </button>
                            )}
                        </VSEmpty>
                    )}
                />
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        clinic: state.clinic,
        permissions: state.permissions
    }
}
  
export default connect(mapStateToProps)(WeightList)