
import PatientPhoto from '../../components/patientPhoto/PatientPhoto';
import { useQuery } from '../../utils';

import { ReactComponent as IconToggle } from "../../assets/icon-toggle.svg"

import './PatientWideInfo.scss'

export const PatientInfoLink = props => {

    const {
        onClick
    } = props

    return (
        <PatientWideInfo
            className="patient-info-link"
            actions={
                <div className="col-auto mt-3 mt-md-0">
                    <button
                        className="btn-outline-submit btn-submit btn-square vstext-ellipsis w-100 justify-content-center"
                        onClick={onClick}
                    >
                        Ver Cadastro Completo
                    </button>
                </div>
            }

            {...props}
        />
    )
}

export const PatientQueryToggle = props => {

    const {
        selected,
        onClick
    } = props

    const queryParams = useQuery()
    const hasSelected = selected || queryParams?.get("selecionado") !== null

    return hasSelected && (
        <PatientWideInfo
            className="patient-query-toggle"
            actions={
                <div className="col-auto mt-3 mt-md-0">
                    <button
                        className="vssearch-toggle"
                        onClick={() => onClick && onClick()}
                    >
                        <IconToggle width="24px" height="24px" />
                        Trocar Animal
                    </button>
                </div>
            }

            {...props}
        />
    )
}

const PatientWideInfo = props => {

    const {
        patient,
        actions,
        className
    } = props

    const owner = patient?.get("owner")

    return patient && (
        <div className={`vssearch mb-4 patient-wide-info ${className}`}>
            <div className="vssearch-area">
                <div className="row align-items-center flex-column flex-md-row">
                    <div className="col">
                        <div className="row align-items-center">
                            <div className="col-auto">
                                <PatientPhoto patient={patient} withBorder />
                            </div>
                            <div className="col px-0">
                                <div className="vssearch-text mb-0">
                                    { patient.get("name") }
                                </div>
                                <div className="vssearch-result">
                                    Tutor(a): { owner.get("name") }<br/>
                                    Raça: { patient.get("breed") || "Não informado" }
                                </div>
                            </div>
                        </div>
                    </div>
                    {actions}
                </div>
            </div>
        </div>
    )
}

export default PatientWideInfo