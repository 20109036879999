import React from "react"
import VSAccordion from "../vsAccordion/VSAccordion"

import "./VSPreview.scss"

import { ReactComponent as IconArrowLeft } from "../../assets/icon-arrow-left.svg"
import { ReactComponent as IconChat } from "../../assets/icon-chat.svg"

const VSPreview = (props) => {

    const {
        children,
        openedTitle,
        closedTitle,
        className,
        onChange
    } = props

    return (
        <div id="document-sent-preview" className={className}>
            <VSAccordion
                header={(expanded) => {
                    onChange && onChange(expanded)
                    return (
                        <div className="col px-3">
                            <div className="row align-items-center py-2">
                                <div className="col px-0 sent-title">
                                    <IconChat className="me-2" width="24" height="24" />
                                    { expanded ? openedTitle : closedTitle }
                                </div>
                                <div className="col-auto text-right pe-0">
                                    <IconArrowLeft className={`sent-icon ${ expanded ? "sent-opened" : "" }`} width="10" height="16" />
                                </div>
                            </div>
                        </div>
                    )
                }}
            >
                <div className="box-info">
                    { children }
                </div>
            </VSAccordion>
        </div>
    )
}

export default VSPreview