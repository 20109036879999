import { parseState } from ".";
import * as constants from "../constants"
import { setClinic, LocalStoragePrefix, dayjs } from "../utils";

var accessDenied = false

const reducers = (state, action) => {
    switch (action.type) {
        case constants.API_LOGIN:
            const user = action.payload
            return {
                user: {
                    isLoaded: true,
                    object: user,
                    objectId: user.id,
                    fullName: user.get("fullName"),
                    email: user.get("email"),
                    username: user.get("username"),
                    countryId: user.get("countryId"),
                    stateId: user.get("stateId"),
                    cityId: user.get("cityId"),
                    cityName: user.get("cityName"),
                    occupationId: user.get("occupationId"),
                    crmv: user.get("crmv"),
                    crmvStateId: user.get("crmvStateId"),
                    cpf: user.get("cpf"),
                    signature: user.get("signature"),
                    contactPhone: user.get("contactPhone"),
                    registrationMAPA: user.get("registrationMAPA"),
                    isVeterinaryProfile: false,
                    profileName: "-"
                },
                permissions: null,
                clinic: {
                    object: null,
                    objectId: null,
                    name: null,
                    displayStore: false,
                    createdBy: null,
                    owner: null,
                    isOwner: false,
                    city: null,
                    ACL: null,
                    trialUsed: false,
                    useMessageWithoutDiscount: false,
                    store: {
                        url: null,
                        name: null,
                        petlove: false
                    }
                },
                onboarding: {
                    hasSetupClinicPrescription: false,
                    hasCreatedOwner: false,
                    hasCreatedPatient: false,
                    hasCreatedAppointment: false,
                    hasCreatedVaccine: false,
                    hasCreatedPrescription: false,
                    hasCreatedPrescriptionShare: false,
                    hasKnowMoreAboutDigitalSignature: false
                }
            };
        case constants.API_LOGOUT:
            localStorage.removeItem(`${LocalStoragePrefix()}selectedClinic`);
            User.logOut()
            return parseState;
        case constants.API_USER:
            const newUser = action.payload
            return {
                ...state,
                user: {
                    ...state.user,
                    object: newUser,
                    objectId: newUser.id,
                    fullName: newUser.get("fullName"),
                    email: newUser.get("email"),
                    username: newUser.get("username"),
                    countryId: newUser.get("countryId"),
                    stateId: newUser.get("stateId"),
                    cityId: newUser.get("cityId"),
                    cityName: newUser.get("cityName"),
                    occupationId: newUser.get("occupationId"),
                    crmv: newUser.get("crmv"),
                    crmvStateId: newUser.get("crmvStateId"),
                    cpf: newUser.get("cpf"),
                    signature: newUser.get("signature"),
                    contactPhone: newUser.get("contactPhone"),
                    registrationMAPA: newUser.get("registrationMAPA")
                }
            };
        case constants.API_CLINIC_USER:
            const clinicUser = action.payload
            if(!clinicUser){
                return {
                    ...state,
                    clinic: parseState.clinic
                }
            }

            const store = clinicUser?.store || {}
            const profile = clinicUser?.get("profile")
            const clinic = clinicUser?.get("clinic")
            if (!clinic) {
                return {
                    ...state,
                    clinic: {
                        ...state.clinic,
                        isLoaded: true
                    }
                };
            }

            setClinic(clinic)
            const permissions = clinicUser.get("permissions")
            if (permissions) {
                permissions.error = (message = "Você não tem permissões para acessar essa página, contate o administrador da clínica.") => {
                    if (!accessDenied) {
                        accessDenied = true
                        Swal.fire({
                            title: 'Acesso negado!',
                            text: message,
                            icon: 'error',
                            timer: 3000,
                            timerProgressBar: true,
                        })
                        .then(_ => {
                            accessDenied = false
                            location.href = "/"
                        })
                    }
                    return
                }
                permissions.check = (pages, type = null, object = null) => {
                    if (!permissions)
                        return false

                    if (permissions[constants.ACL.ADMIN])
                        return true

                    if (!Array.isArray(pages))
                        pages = [pages]

                    const canAccess = pages.filter(page => {
                        if (page === constants.ACL.ADMIN)
                            return false

                        if (!permissions[page]) {
                            if (type == constants.ACL.Rule.VIEW) {
                                return true
                            }
                            return false
                        }
    
                        if (!permissions[page][type])
                            return false
    
                        if (permissions[page][type] == constants.ACL.Value.NONE)
                            return false
                        
                        if (permissions[page][type] == constants.ACL.Value.SELF && object) {
                            if (object.get("createdBy") && object.get("createdBy").id == Parse.User.current().id)
                                return true
                            else 
                                return false
                        }
    
                        return true
                    })

                    return canAccess.length > 0
                }
            }

            return {
                ...state,
                permissions: permissions,
                user: {
                    ...state.user,
                    isVeterinaryProfile: profile?.get("isVeterinary") || state.user.isVeterinaryProfile,
                    profileName: profile?.get("name") || state.user.profileName
                },
                clinic: {
                    isLoaded: true,
                    object: clinic,
                    objectId: clinic.id,
                    name: clinic.get("name"),
                    displayStore: clinic.get("displayStore") !== false,
                    notes: clinic.get("notes"),
                    createdBy: clinic.get("createdBy"),
                    owner: clinic.get("createdBy").id,
                    isOwner: clinic.get("createdBy").id == state.user.objectId,
                    city: clinic.get("city"),
                    ACL: clinic.get("ACL"),
                    trialUsed: clinic.get("subscription")?.trialEndsAt ? true : false,
                    useMessageWithoutDiscount: clinic.get("useMessageWithoutDiscount") || false,
                    store: {
                        url: store?.url,
                        name: store?.name,
                        petlove: store?.petlove
                    }
                }
            };
        case constants.API_ONBOARDING:
            const onboarding = action.payload
            return {
                ...state,
                onboarding: onboarding
            };
        case constants.API_PRO:
            const pro = action.payload

            const isPro = pro.get("isPro") || false
            const subscription = pro.get("subscription")
            const vindi = pro.get("subscriptionVindi")

            let tag
            const trialEndsAtIsValid = subscription?.trialEndsAt && dayjs().isSameOrBefore(subscription?.trialEndsAt)
            const isSubscriptionMonthly = vindi?.interval == "months" && vindi?.interval_count == 1
            if (isPro && subscription?.type == "trial" && trialEndsAtIsValid) {
                tag = "trial_active"
            } else if (vindi?.overdue_since) {
                tag = "payment_denied"
            } else if (!isPro && subscription?.type == "paid" && subscription?.canceledAt) {
                tag = "subscription_canceled"
            } else if (isPro && isSubscriptionMonthly) {
                tag = "subscription_monthly"
            }
            
            return {
                ...state,
                featuresPro: {
                    object: pro,
                    access: isPro,
                    vindi: vindi,
                    type: subscription?.type,
                    activeSince: subscription?.activeSince,
                    trialEndsAt: subscription?.trialEndsAt || null,
                    tag: tag
                }
            };
        case constants.API_TAG:
            return {
                ...state,
                showingTag: action.payload
            };
        default:
            return state;
    }
};

export default reducers