import React, { useEffect, useState, useRef } from "react"

import { CircularProgress } from '@mui/material'
import { Table, TableBody, TableContainer, TableHead, TableCell, TableRow } from '@mui/material'
import VSMenu from "../vsMenu/VSMenu"

import { ReactComponent as IconMenu } from "../../assets/icon-menu-2.svg"
import { ReactComponent as IconUser } from "../../assets/icon-user-menu.svg"
import { ReactComponent as IconPaw } from "../../assets/icon-paw-menu.svg"

import VSPagination, { showPagination } from '../vsPagination/VSPagination';

import CardEmptyDashboard from "../cardEmptyDashboard/CardEmptyDashboard"

import "./CardDashboardThreeRows.scss"
const CardDashboardThreeRows = (props) => {


    const {
        title,
        headerIcon,
        isLoading,
        perPage = 5,
        totalPages = 0,
        changePage,
        datesFormatted,
        tableHead1,
        tableHead2,
        tableHead3,
        colorValue = "rgba(0, 0, 0, 0.8)",
        onItemClick,
        history,
        footer
    } = props

    const [page, setPage] = useState(1)

    const canShowPagination = totalPages > 0 && perPage > 0 && totalPages > perPage
    const showFooter = totalPages > 0 && footer

    function layoutCard() {
        const hasbudgets = props.currentData && props.currentData.length > 0
        if (hasbudgets) {
            const valueStyle = { color: props.colorValue, fontWeight:600 }
            return (
                <div className="show-card-dashboard">
                    <TableContainer className="table-budgets" elevation={0}>
                        <Table aria-label="simple table">
                            {(tableHead1 || tableHead2 || tableHead3) && 
                            <TableHead>
                                <TableRow className="tableRowheader">
                                    <TableCell className="tableHead">{tableHead1}</TableCell>
                                    <TableCell className="tableHead">{tableHead2}</TableCell>
                                    <TableCell className="tableHead d-none d-md-table-cell">{tableHead3}</TableCell>
                                </TableRow>
                            </TableHead>}
                            <TableBody>
                                { props.currentData.map((row, index) => (
                                    <TableRow
                                        key={index}
                                        sx={{ 'td, th': { border: 0 } }}
                                        // style={{ cursor: "pointer" }}
                                    >
                                        <TableCell className="tableCell flex-shrink-0 pe-0" component="th" scope="row">
                                            { row.row1 }
                                        </TableCell>
                                        <TableCell className="tableCell vstext-ellipsis flex-grow-1"><div className="text-link d-inline" onClick={() => onItemClick && onItemClick(row)}>{ row.row3 }</div> { row.row2 }</TableCell>
                                        {(row.patient || row.owner) && 
                                        <TableCell style={{ width: '24px' }} className="tableCell ms-2 flex-shrink-0">
                                            <VSMenu
                                                placement="left-start"
                                                openOnHover
                                                style={{background: "#fff"}}
                                                options={[
                                                    row.patient && {
                                                        menu: (
                                                            <div className="row align-items-center">
                                                                <div className="col-auto">
                                                                    <IconPaw width="24" height="24" fill="var(--vsprontuario-primary-color)" />
                                                                </div>
                                                                <div className="col ps-0">
                                                                    Ver Animal
                                                                </div>
                                                            </div>
                                                        ),
                                                        onClick: () => history.push(`/animal/${row.patient?.id}`)
                                                    },
                                                    row.owner && {
                                                        menu: (
                                                            <div className="row align-items-center">
                                                                <div className="col-auto">
                                                                    <IconUser width="24" height="24" fill="var(--vsprontuario-primary-color)" />
                                                                </div>
                                                                <div className="col ps-0">
                                                                    Ver Tutor
                                                                </div>
                                                            </div>
                                                        ),
                                                        onClick: () => history.push(`/tutor/${row.owner?.id}`)
                                                    }
                                                ].filter(val => val)}
                                            >
                                                <IconMenu width="24" height="24" fill="var(--vsprontuario-primary-color)" />
                                            </VSMenu>
                                        </TableCell>}
                                        {/* <TableCell className="tableCell" style={{ width: "22px" }}>
                                            <IconArrowLeft width={14} height={14} fill="#B3B3B3" style={{ transform: "rotate(180deg)" }} />
                                        </TableCell> */}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    { !isLoading && (canShowPagination || showFooter) && (
                        <div className="row gx-3 align-items-center">
                            <div className="col-12">
                                <hr/>
                            </div>
                            <div className="col-12">
                                <div className="row gx-3 align-items-center">
                                    {canShowPagination && (
                                        <div className="col pe-0">
                                            <VSPagination
                                                align="start"
                                                currentPage={page}
                                                rowsPerPage={perPage}
                                                totalRows={totalPages}
                                                onChange={(_currentPage) => {
                                                    if (page != _currentPage) {
                                                        setPage(_currentPage)
                                                        changePage(_currentPage - 1)
                                                    }
                                                }}
                                                />
                                        </div>
                                    )}
                                    { footer }
                                </div>
                            </div>
                            <div className="show-pagination-dashboard col-12 mt-2">
                                { showPagination(page, perPage, totalPages) }
                            </div>
                        </div>
                    )}
                </div>
            )
        } else {
            return <CardEmptyDashboard
                title="Você ainda não possui movimentações financeiras"
                description="Utilize o seu prontuário para que os dados sejam disponibilizados." />
        }
    }

    function layoutLoading() {
        return <div className="container"><div className="d-flex justify-content-center mb-3"><CircularProgress /></div></div>
    }
    return (
        <div id="card-dashboard-three-rows" className="card-dashboard-element">
            <div className="row align-items-center">
                <div className="col mb-2 mb-md-0">
                    <div className="row align-items-center">
                        {headerIcon && 
                        <div className="col-auto pe-0">
                            {headerIcon}
                        </div>}
                        <div className={`col ${headerIcon ? 'ps-2' : ''}`}>
                            <div className="title">{ title }</div>
                        </div>
                    </div>
                </div>
                <div className="col-12 mt-3">
                    <div className="dates">{ datesFormatted }</div>
                </div>
                <div className="col-12">
                    {isLoading ? (
                        layoutLoading()
                    ) : (
                        layoutCard()
                    )}
                </div>
            </div>
        </div >
    )
}

export default CardDashboardThreeRows;