import React from "react";

import Step1 from "../../../assets/browsers/firefox-step1.png"
import Step2 from "../../../assets/browsers/firefox-step2.png"
import Step3 from "../../../assets/browsers/firefox-step3.png"

const BrowserFirefox = (props) => {
    return (
        <div id="firefox">
            <h4>Passo a passo - Firefox</h4>
            <div className="row g-3 mb-3">
                <div className="col-12">
                    <b>Passo 1:</b> Abra o Prontuário do Vet Smart no Firefox e 
                    toque no ícone de três pontos no canto superior direito da tela, 
                    para usuários de Android.<br/>
                    No caso de usuários de iOS, clique no ícone ao lado direito do 
                    link do site e clique em “Compartilhar”.
                </div>
                <div className="col-12">
                    <img src={Step1} width="100%" />
                </div>
                <div className="col-12">
                    <b>Passo 2:</b> No menu que abre, clique em “Instalar” ou 
                    “Adicionar à Tela de Início”.
                </div>
                <div className="col-12">
                    <img src={Step2} width="100%" />
                </div>
                <div className="col-12">
                    <b>Passo 3:</b> Na tela seguinte, clique em “Adicionar”.
                </div>
                <div className="col-12">
                    <img src={Step3} width="100%" />
                </div>
            </div>
        </div>
    )
}

export default BrowserFirefox