import { TextField } from "@mui/material"

const CompanyNameField = (props) => {

    const {
        companyName,
        setCompanyName
    } = props

    return (
        <div className="row">
            <div className="col-12">
                <div className="input-subtitle mb-1">
                    Nome do Fabricante
                </div>
            </div>
            <div className="col-12">
                <TextField
                    placeholder="Nome do Fabricante"
                    value={companyName}
                    onChange={({ target: { value } }) => setCompanyName(value || "")}
                    helperText={<span>Campo Opcional</span>}
                    size="small"
                    variant="outlined"
                    fullWidth
                />
            </div>
        </div>
    )
}

export default CompanyNameField