import React from 'react';

import "./PatientPhoto.scss"

import { ReactComponent as IconAddImage } from '../../assets/icon-add-image.svg'

import ImgCanine from '../../assets/specie-canine.png'
import ImgFeline from '../../assets/specie-feline.png'
import ImgSpecie from '../../assets/specie.png'

const PatientPhoto = (props) => {

    const {
        patient,
        withBorder,
        height,
        width,
        className,
        onAction
    } = props

    const _width = width || "55px"
    const _height = height || "55px"

    const specie = (patient.get("specie") || "").toLowerCase()

    return (
        <div id="patient-photo" className={className} style={{ width: _width, height: _height }}>
            <div
                onClick={() => onAction && onAction()}
                className={`patient-photo ${onAction ? "patient-action" : ""} ${withBorder ? `patient-border ${specie}` : ""}`}
            >
                { patient.get("image") ? (
                    <div style={{
                        backgroundImage: `url("${patient.get("image")._url}")`
                    }}></div>
                ) : (
                    specie.match(/(canino)/ig) ? (
                        <img src={ImgCanine} />
                    ) : (
                        specie.match(/(felino)/ig) ? (
                            <img src={ImgFeline} />
                        ) : (
                            <img src={ImgSpecie} />
                        )
                    )
                )}
                { onAction && (
                    <button className="patient-add-photo">
                        <IconAddImage width="20" height="18" />
                    </button>
                )}
            </div>
        </div>
    )
}

export default PatientPhoto;