import React, { forwardRef, useImperativeHandle } from 'react'

import { Autocomplete, TextField } from '@mui/material';
import { getSelectedOptionLabel, mandatoryField, optionalField, showArrayIndex } from '../../../../utils';
import { defaultItems } from './Term';

import { ReactComponent as IconAddO } from "../../../../assets/icon-add-o.svg"
import { ReactComponent as IconCloseO } from "../../../../assets/icon-close-o.svg"

const TermBlankForm = forwardRef((props, ref) => {

    const {
        user,
        notes,
        setNotes,
        documentName,
        setDocumentName,
        errorDocumentName,
        setErrorDocumentName,
        helperDocumentName,
        setHelperDocumentName,
        signedBy,
        setSignedBy,
        errorSignedBy,
        setErrorSignedBy,
        helperSignedBy,
        setHelperSignedBy,
        items,
        setItems
    } = props

    const setItem = (index, field, value) => {
        let newItems = [...items]
        newItems[index][field] = value
        setItems(newItems)
    }

    const removeItem = (index) => {
        let newItems = [...items]
        newItems.splice(index, 1)
        setItems(newItems)
    }

    const signedByOptions =  [
        {
            value: "owner",
            label: "Assinatura do Tutor do animal"
        },
        {
            value: "user",
            label: `Assinatura do Médico Veterinário (${user.fullName})`
        }
    ]

    useImperativeHandle(ref, () => ({
        fieldsWithErrorText(errors) {
            if (errorDocumentName) {
                errors.push("Nome do Atestado ou termo")
            }
            if (errorSignedBy) {
                errors.push("Assinatura do Atestado ou termo")
            }
            return errors
        },
        isValid() {
            return !errorDocumentName && !errorSignedBy
        },
        validate(isValid) {
            if (documentName) {
                setErrorDocumentName(false)
                setHelperDocumentName(mandatoryField)
            } else {
                isValid = false
                setErrorDocumentName(true)
                setHelperDocumentName("O nome do atestado ou termo não é válido")
            }

            if (signedBy) {
                setErrorSignedBy(false)
                setHelperSignedBy(mandatoryField)
            } else {
                isValid = false
                setErrorSignedBy(true)
                setHelperSignedBy("A assinatura do atestado ou termo não é válido")
            }

            return isValid
        }
    }))

    return (
        <div className="row pt-4">
            {/* <div className="col-12">
                <div className="input-title input-height">
                    Observações Gerais (opcional)
                </div>
            </div> */}
            <div className="col-12">
                <TextField
                    value={documentName}
                    placeholder={`Nome do Atestado ou termo`}
                    onChange={({ target: { value } }) => setDocumentName(value)}
                    size="small"
                    variant="outlined"
                    fullWidth
                    error={errorDocumentName}
                    helperText={helperDocumentName}
                />
            </div>
            <div className="col-12 mt-3">
                <TextField
                    value={notes}
                    placeholder={`Escreva aqui as informações necessárias`}
                    onChange={({ target: { value } }) => setNotes(value)}
                    rows={5}
                    variant="outlined"
                    fullWidth
                    multiline
                />
            </div>
            <div className="col-12 mt-3">
                { items && items.map((item, index) => (
                    <React.Fragment key={index}>
                        <div className="row align-items-center mb-4">
                            <div className="col">
                                <div className="input-array py-1">
                                    Informação Adicional { showArrayIndex(index + 1) } (Opcional)
                                </div>
                            </div>
                            { items.length > 0 && (
                                <div className="col-auto ps-0">
                                    <button
                                        className="btn-array px-0"
                                        onClick={() => removeItem(index)}
                                    >
                                        <IconCloseO width="24" height="24" />
                                        Remover Informação
                                    </button>
                                </div>
                            )}
                        </div>
                        <div className="row align-items-center mb-4">
                            <div className="col-12">
                                <TextField
                                    label={`Título da informação`}
                                    value={item.title}
                                    onChange={({ target: { value } }) => setItem(index, "title", value)}
                                    helperText={optionalField}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                />
                            </div>
                        </div>
                        <div className="row align-items-center mb-4">
                            <div className="col-12">
                                <TextField
                                    label={`Escreva aqui as informações necessárias`}
                                    value={item.description}
                                    onChange={({ target: { value } }) => setItem(index, "description", value)}
                                    helperText={optionalField}
                                    rows={3}
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                />
                            </div>
                        </div>
                    </React.Fragment>
                ))}
                <div className="row align-items-center mb-4">
                    <div className="col-12">
                        <button
                            className="btn-add" 
                            onClick={() => setItems(items ? items.concat({ ...defaultItems }) : [{...defaultItems}])}
                        >
                            <IconAddO width="20" height="20" />
                            Adicionar Informações Adicionais { showArrayIndex(items ? items.length + 1 : 1) }
                        </button>
                    </div>
                </div>
            </div>
            <div className="col-12 my-3">
                <Autocomplete
                    value={signedBy || null}
                    options={signedByOptions}
                    getOptionLabel={(option) => getSelectedOptionLabel(option, signedByOptions, signedBy)}
                    getOptionSelected={(option) => option.value === signedBy}
                    onChange={(_, selected) => setSignedBy(selected.value || '')}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            className="w-100"
                            placeholder="Selecione quem deve assinar o documento"
                            label="Assinatura do documento"
                            size="small"
                            variant="outlined"
                            error={errorSignedBy}
                            helperText={helperSignedBy}
                        />
                    )}
                    disableClearable
                    openOnFocus
                />
            </div>
        </div>
    )
})

export default TermBlankForm;