import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom'

import { CircularProgress, Switch, TextField } from '@mui/material';
import Autocomplete from '@mui/lab/Autocomplete';

import { ReactComponent as IconAddO } from "../../assets/icon-add-o.svg"
import { ReactComponent as IconCloseO } from "../../assets/icon-close-o.svg"
import { ReactComponent as IconWarning } from "../../assets/icon-warning-o.svg"

import {
    APP_NAME,
    APP_DEVICE,
    APP_VERSION,
    ACL
} from '../../constants'
import { 
    addEvent,
    getSelectedOptionLabel,
    mandatoryField,
    optionalField,
    saveOnTracker,
    showArrayIndex
} from '../../utils'
import { getCategories, addCategory, getService, convertMinutesInHoursWithStartPad, minutesWithStartPad } from './Service';
import { defaultTimes } from '../calendar/Calendar';
import { getSupplies } from '../supply/SupplyTransaction';
import { getUnits } from '../supply/Supply';

import VSAccordionSimple from '../../components/vsAccordion/VSAccordionSimple';
import VSDrawer from '../../components/vsDrawer/VSDrawer';
import VSError from '../../components/vsError/VSError';
import VSTextFieldCurrency from '../../components/vsTextFieldCurrency/VSTextFieldCurrency';
import { VSSimpleTooltip } from '../../components/vsTooltip/VSTooltip';

// Note: id_servico = objectId, serviceId = user setted field serviceId
import "./ServiceForm.scss"
const ServiceForm = (props) => {

    const {
        id_servico
    } = useParams()

    const {
        clinic,
        history,
        permissions
    } = props

    const update = id_servico != "novo"

    const [ loading, setLoading ] = useState(false)
    const [ error, setError ] = useState(null)
    const [ saveLoading, setSaveLoading ] = useState(false)
    const [ service, setService ] = useState(null)

    const [ serviceName, setServiceName ] = useState('')
    const [ errorServiceName, setErrorServiceName ] = useState(false)
    const [ helperServiceName, setHelperServiceName ] = useState(<span>Campo <b>Obrigatório</b></span>)

    const [ defaultValue, setDefaultValue ] = useState(null)
    const [ value, setValue ] = useState(null)
    const [ errorValue, setErrorValue ] = useState(false)
    const [ helperValue, setHelperValue ] = useState(<span>Campo <b>Obrigatório</b></span>)

    const [ notes, setNotes ] = useState('')
    const [ serviceId, setServiceId ] = useState('')
    const [ category, setCategory ] = useState('')
    const [ active, setActive ] = useState(true)

    const [ units, setUnits ] = useState([])

    const [ durationInMinutes, setDurationInMinutes ] = useState(0)
    const [ timeText, setTimeText ] = useState(null)
    const [ hours, setHours ] = useState("")
    const [ minutes, setMinutes ] = useState("")

    const [ serviceIds, setServiceIds ] = useState({})
    const [ serviceIdsLoading, setServiceIdsLoading ] = useState(false)

    const [ categoryOptions, setCategoryOptions ] = useState([]);

    const defaultSupply = {}
    const [ supplyOptions, setSupplyOptions ] = useState([])
    const [ supplies, setSupplies ] = useState([defaultSupply])
    const [ errorSupplies, setErrorSupplies ] = useState(false)

    const isValueValid = () => value || typeof value === 'number'

    const setSupply = (index, value, field = null) => {
        let newSupplies = [...supplies]
        if (field) {
            newSupplies[index][field] = value
        } else {
            // selected supply, set defaults
            const unit = value?.id && value?.get("unit")
            if (unit) {
                value.unitOptions = units.filter(u => u.conversions?.[unit] || u.id == unit)
                value.quantity = 0
                value.unit = units.find(u => u.id == unit)?.id
            } else {
                value.unitOptions = []
            }
            value.supply = value?.get("supplyName") || ""
            newSupplies[index] = value
        }
        setSupplies(newSupplies)
    }

    const removeSupply = (index) => {
        let newSupplies = [...supplies]
        newSupplies.splice(index, 1)
        setSupplies(newSupplies)
    }

    const handleCategory = (selected) => {
        const category = selected
        setCategory(category)
    }

    const validateOnTouch = () => {
        if (serviceName || isValueValid() || serviceId || notes || category || supplies.find(supply => Boolean(supply?.id))) {
            validate()
        }
    }

    const validate = () => {
        let isValid = true
        if (loading) {
            isValid = false
        }
        if (saveLoading) {
            isValid = false
        }
        if (error?.message) {
            isValid = false
        }

        if (!serviceName) {
            isValid = false
            setErrorServiceName(true)
            setHelperServiceName("O nome do serviço é obrigatório")
        } else {
            setErrorServiceName(false)
            setHelperServiceName(<span>Campo <b>Obrigatório</b></span>)
        }

        if (!isValueValid()) {
            isValid = false
            setErrorValue(true)
            setHelperValue("O valor do serviço é obrigatório")
        } else {
            setErrorValue(false)
            setHelperValue(<span>Campo <b>Obrigatório</b></span>)
        }

        const suppliesAfterValidation = [...supplies]
        var _errorSupplies = false
        supplies.map((supply, index) => {
            if (supply?.id) {
                if (supply.quantity > 0) {
                    suppliesAfterValidation[index].errorQuantity = false
                    suppliesAfterValidation[index].helperQuantity = mandatoryField
                } else {
                    suppliesAfterValidation[index].errorQuantity = true
                    suppliesAfterValidation[index].helperQuantity = "A quantidade utilizada do insumo precisa ser maior que 0 (zero)"
                    _errorSupplies = true
                }
                
                if (supply.unit) {
                    suppliesAfterValidation[index].errorUnit = false
                    suppliesAfterValidation[index].helperUnit = mandatoryField
                } else {
                    suppliesAfterValidation[index].errorUnit = true
                    if (!supply.unitOptions || supply.unitOptions.length == 0) {
                        suppliesAfterValidation[index].helperUnit = "O insumo não possui uma unidade de medida cadastrada, por favor, atualize o cadastro do insumo informando a unidade de medida"
                    } else {
                        suppliesAfterValidation[index].helperUnit = "A medida utilizada do insumo precisa ser informada"
                    }
                    _errorSupplies = true
                }
            }
        })
        setSupplies(suppliesAfterValidation)
        setErrorSupplies(_errorSupplies)
        if (_errorSupplies) {
            isValid = false
        }

        return isValid
    }

    const isValid = () => !loading && !saveLoading && !errorServiceName && !errorValue && !errorSupplies

    const fieldsWithErrorText = () => {
        let errors = []
        if (loading) {
            errors.push("Estamos procurando o serviço")
        }
        if (saveLoading) {
            errors.push("Salvando o serviço")
        }
        if (error?.message) {
            errors.push(error.message)
        }
        if (errorServiceName) {
            errors.push("Nome do serviço")
        }
        if (errorValue) {
            errors.push("Valor do serviço")
        }
        if (errorSupplies) {
            errors.push("Insumos a serem utilizados")
        }
        if (!permissions?.check(ACL.SERVICE, ACL.Rule.EDIT, service)) {
            errors.push("Acesso negado")
        }
        return errors
    }

    const save = () => {
        return new Promise((resolve, reject) => {
            if (validate()) {
                const suppliesToSave = supplies
                    .filter(supply => Boolean(supply?.id))
                    .map(supply => ({
                        object: {
                            __type: "Pointer",
                            className: "MRSupply",
                            objectId: supply.id,
                        },
                        objectId: supply.id,
                        quantity: supply.quantity,
                        unit: supply.unit,
                    }))

                setSaveLoading(true)
                return Promise.resolve().then(() => {
                    let categoryToSave = categoryOptions.find(_category => _category?.get('categoryName') == category);
                    if(categoryToSave){
                        return categoryToSave
                    } else if (category) {
                        let newCategory = new Parse.Object("MRCategory")
                        newCategory.setACL(clinic.ACL)
                        newCategory.set("isDeleted", false)
                        newCategory.set("clinic", clinic.object)
                        newCategory.set("createdBy", Parse.User.current())
                        newCategory.set("createdApp", APP_NAME)
                        newCategory.set("createdDevice", APP_DEVICE)
                        newCategory.set("createdAppVersion", APP_VERSION)
                        newCategory.set("categoryName", category)
                        newCategory.set("changedAt", new Date())
                        newCategory.set("changedBy", Parse.User.current())
                        newCategory.set("changedApp", APP_NAME)
                        newCategory.set("changedDevice", APP_DEVICE)
                        newCategory.set("changedAppVersion", APP_VERSION)

                        return newCategory.save()
                    } else {
                        return Promise.resolve()
                    }
                }).then(categoryToSave => {
                    addCategory(categoryToSave)
                    service.set("serviceName", serviceName);
                    service.set("serviceId", serviceId);
                    service.set("value", parseFloat(value.toFixed(2)));
                    if (categoryToSave) {
                        service.set("category", categoryToSave);
                    } else {
                        service.unset("category");
                    }
                    if (durationInMinutes > 0) {
                        service.set("durationInMinutes", durationInMinutes)
                    } else {
                        service.unset("durationInMinutes")
                    }
                    service.set("notes", notes);
                    service.set("supplies", suppliesToSave);
                    service.set("active", active);
                    service.set("changedAt", new Date())
                    service.set("changedBy", Parse.User.current())
                    service.set("changedApp", APP_NAME)
                    service.set("changedDevice", APP_DEVICE)
                    service.set("changedAppVersion", APP_VERSION)
                    service.save()
                    .then(_service => {
                        saveOnTracker("Concluiu", "Serviço", _service.id)
                        setSaveLoading(false)
                        resolve(_service)
                    })
                    .catch(error => {
                        setSaveLoading(false)
                        console.error(error)
                        Swal.fire(
                            'Desculpe',
                            `Ocorreu algum erro ao tentar ${service.id ? "editar" : "cadastrar"} o serviço`,
                            'error'
                        )
                        update && service.revert()
                        reject()
                    })
                });
            } else {
                reject()
            }
        })
    }

    const getServiceOrError = () => {
        if (permissions?.check(ACL.SERVICE, ACL.Rule.VIEW)) {
            setLoading(true)
            setError(null)

            if (update) {
                getService(id_servico, clinic.object)
                .then(_service => {
                    const _value = _service.get("value") ? _service.get("value") : null
                    setService(_service)
                    setServiceName(_service.get("serviceName"))
                    setServiceId(_service.get("serviceId"));
                    setValue(_value);
                    setDefaultValue(_value);
                    if(_service.get("category")){
                        setCategory(_service.get("category").get("categoryName"));
                    }
                    if (_service.get("durationInMinutes")) {
                        setDurationInMinutes(_service.get("durationInMinutes"))
                    }
                    if (_service.get("supplies")) {
                        const _supplies = _service.get("supplies").map((supply, index) => {
                            const data = supply.object
                            data.supply = supply.object.get("supplyName")
                            data.quantity = supply.quantity
                            data.unit = supply.unit
                            data.unitOptions = units.filter(u => u.conversions?.[supply.unit] || u.id == supply.unit)
                            return data
                        })
                        setSupplies(_supplies)
                    }
                    setNotes(_service.get("notes"))
                    setActive(_service.get("active"))
                    setLoading(false)
                })
                .catch(error => {
                    Swal.fire(
                        'Desculpe',
                        error,
                        'error'
                    )
                    .then(_ => {
                        setLoading(false)
                        setError({
                            message: error,
                            function: "getServiceOrError"
                        })
                    })
                })
            } else {
                let newService = new Parse.Object("MRService")
                // newService.setACL(clinic.ACL)
                newService.set("clinic", clinic.object)
                newService.set("createdBy", Parse.User.current())
                newService.set("createdApp", APP_NAME)
                newService.set("createdDevice", APP_DEVICE)
                newService.set("createdAppVersion", APP_VERSION)
                newService.set("active", true)
                // newService.set("isDeleted", false) // default is false on parse
                setService(newService)
                setLoading(false)
            }
        }
    }

    const getPluralOrSingularNameForOptions = (unit, quantity) => {
        return quantity == 1 ? unit?.singular : unit?.name 
    }

    useEffect(() => {
        if (durationInMinutes != parseInt(timeText)) {
            const total = String(durationInMinutes)
            const findByTotal = defaultTimes.filter(time => time.value == total)?.[0]
            if (findByTotal) {
                setTimeText(total)
            } else {
                setTimeText(null)
            }
        }
        const _hours = convertMinutesInHoursWithStartPad(durationInMinutes)
        if (parseInt(hours) != parseInt(_hours)) {
            setHours(_hours)
        }
        const _minutes = minutesWithStartPad(durationInMinutes)
        if (parseInt(minutes) != parseInt(_minutes)) {
            setMinutes(_minutes)
        }
    }, [ durationInMinutes ])

    useEffect(() => {
        if (hours || minutes) {
            const intHours = parseInt(hours) || 0
            const intMinutes = parseInt(minutes) || 0
            const total = intHours * 60 + intMinutes
            setDurationInMinutes(total)
        }
    }, [ hours, minutes ])

    useEffect(() => {
        if (timeText) {
            const time = parseInt(timeText)
            if (time > 0) {
                setDurationInMinutes(time)
            }
        }
    }, [ timeText ])

    useEffect(() => {
        let preventMultipleRequests
        if (serviceId && serviceIds[serviceId] == undefined) {
            setServiceIdsLoading(true)
            preventMultipleRequests = setTimeout(() => {
                const query = new Parse.Query("MRService")
                query.equalTo("clinic", clinic.object)
                if (service?.id) {
                    query.notEqualTo("objectId", service.id)
                }
                query.equalTo("serviceId", serviceId)
                query.equalTo("isDeleted", false)
                query.find()
                .then(result => {
                    const newServiceIds = {...serviceIds}
                    newServiceIds[serviceId] = result.length > 0 ? result : null
                    setServiceIds(newServiceIds)
                    setServiceIdsLoading(false)
                })
                .catch(error => {
                    console.error(error)
                    setServiceIdsLoading(false)
                })
            }, 300)
        }
        return () => {
            clearTimeout(preventMultipleRequests)
        }
    }, [ serviceId ])

    useEffect(() => {
        validateOnTouch()
    }, [ serviceName, value, JSON.stringify(supplies.map(supply => ({ id: supply?.id, supply: supply?.supply, quantity: supply?.quantity, unit: supply?.unit }))) ])

    useEffect(() => {
        if (units.length > 0 && id_servico != "selecionar") {
            getServiceOrError();
        }
    }, [ units ])

    useEffect(async () => {
        if (clinic && clinic.isLoaded && clinic.object) {
            await getSupplies(clinic.object).then(supplies => setSupplyOptions(supplies))
            getUnits().then(_units => setUnits(_units)) 
            saveOnTracker("Iniciou", "Serviço", id_servico !== "novo" ? id_servico : "")
            getCategories(clinic.object).then(_categories => setCategoryOptions(_categories));
        }
    }, [ clinic ])

    useEffect(() => {
        if (service && !permissions?.check(ACL.SERVICE, ACL.Rule.EDIT, service)) {
            permissions?.error()
        }
    }, [ service ])

    const errors = fieldsWithErrorText()

    const usage = serviceIds[serviceId]

    return (
        <VSDrawer
            title={update ? "Editar Cadastro de Serviço" : "Novo Cadastro de Serviço"}
            cancel={update ? "Cancelar Alterações" : <span>Cancelar<span className="d-none d-md-inline"> Novo</span> Cadastro</span>}
            submit={isValid ? ( update ? "Salvar Alterações" : "Salvar Cadastro" ) : <span>Preencha os campos<span className="d-none d-md-inline"> necessários</span></span>}
            errors={errors}
            onSubmit={save}
            onCancel={() => history.goBack()}
            onAfterSave={(object) => {
                history.replace(`/servico`)
                addEvent("Service__getList", {})
            }}
            VSDrawerSuccessProps={{
                title: update ? "Alterações salvas com sucesso!" : "Cadastro realizado com sucesso!" ,
                text: ""
            }} 
            VSDrawerCancelProps={{
                title: update ? "Tem certeza que deseja cancelar as alterações no cadastro do serviço? " : "Tem certeza que deseja cancelar o novo cadastro de serviço?",
                confirm: update ? "Cancelar  Alterações" : "Cancelar Novo Cadastro",
                cancel: update ? "Voltar para Edição do Cadastro" : "Voltar para Edição do Novo Cadastro"
            }}
        >
            { loading ? (
                <div className="row data-loading">
                    <div className="col">
                        <CircularProgress />
                    </div>
                </div>
            ) : error?.message ? (
                <VSError
                    message={error.message}
                    onClose={() => {
                        switch(error.function) {
                            case "getServiceOrError":
                                getServiceOrError()
                                break
                            default:
                        }
                    }}
                />
            ) : (
                <>
                    { service && (
                        <>
                            <div className="row align-items-center">
                                <div className="col">
                                    <p className="my-3" style={{ 
                                        textTransform: "uppercase",
                                        fontWeight: 600
                                    }}>
                                        Serviço Está Ativo?
                                    </p>
                                </div>
                                <div className="col-auto">
                                    <Switch
                                        checked={active}
                                        onChange={({ target: { checked } }) => setActive(checked)}
                                        color="primary"
                                    />
                                </div>
                            </div>
                            { !active && active != service?.get("active") && (
                                <div className="mb-3 vsalert">
                                    <div className="row align-items-center">
                                        <div className="col-auto">
                                            <IconWarning width="24px" height="24px" fill="var(--vsprontuario-secondary-color)" />
                                        </div>
                                        <div className="col ps-0">
                                            <h4 className="vsalert-title">
                                                Você definiu que o serviço não está ativo.
                                            </h4>
                                            <p className="vsalert-text">
                                                Ao salvar essa alteração, este serviço ficará oculto para seleção em outras funcionalidades até você reativa-lo.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <hr/>
                            <div className="row mt-2">
                                <div className="col">
                                    <VSAccordionSimple
                                        expanded={true}
                                        header={(_) => (
                                            "Identificação do Serviço"
                                        )}
                                    >
                                        <div className="row g-3 align-items-center">
                                            <div className="col-6 col-md-3">
                                                <TextField
                                                    placeholder="ID"
                                                    label="ID"
                                                    value={serviceId}
                                                    onChange={({ target: { value } }) => setServiceId(value)}
                                                    helperText={(
                                                        <>
                                                        { serviceIdsLoading ? (
                                                            <CircularProgress size={14} className="me-1" />
                                                        ) : usage && (
                                                                <VSSimpleTooltip
                                                                    title={
                                                                        <div className="p-3">
                                                                            <p className="mb-1">O ID está em uso no{usage.length == 1 ? "" : "s"} seguinte{usage.length == 1 ? "" : "s"} serviço{usage.length == 1 ? "" : "s"}:</p>
                                                                            <table className="table table-borderless mb-0">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th>#</th>
                                                                                        <th>Nome</th>
                                                                                        <th>Estado</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    { usage.map((service, index) => (
                                                                                        <tr key={index}>
                                                                                            <td>{ service.get("serviceId") }</td>
                                                                                            <td>{ service.get("serviceName") }</td>
                                                                                            <td className={service.get("active") ? "text-success" : "text-danger"}>{ service.get("active") ? "Ativo" : "Inativo" }</td>
                                                                                        </tr>
                                                                                    )) }
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    }
                                                                >
                                                                    <IconWarning width={16} height={16} className="me-1" />
                                                                </VSSimpleTooltip>
                                                            )}
                                                            Campo Opcional
                                                        </>
                                                    )}
                                                    size="small"
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                            </div>
                                            <div className="col-12 col-md-5">
                                                <TextField
                                                    placeholder="Nome do Serviço"
                                                    label="Nome do Serviço"
                                                    value={serviceName}
                                                    onChange={({ target: { value } }) => setServiceName(value)}
                                                    error={errorServiceName}
                                                    helperText={helperServiceName}
                                                    size="small"
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                            </div>
                                            <div className="col-12 col-md-4">
                                                <Autocomplete
                                                    inputValue={category}
                                                    options={categoryOptions}
                                                    getOptionLabel={(option) => option?.id ? option.get('categoryName') : category}
                                                    isOptionEqualToValue={(option, value) => option?.id && value.id}
                                                    onChange={(_, selected) => handleCategory(selected?.id ? selected.get('categoryName') : category)}
                                                    onInputChange={(event, selected) => {
                                                        if (event){
                                                            handleCategory(selected)
                                                        }
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            placeholder="Categoria"
                                                            label="Categoria do serviço"
                                                            helperText={<span>Campo Opcional</span>}
                                                            size="small"
                                                            variant="outlined"
                                                            fullWidth
                                                        />
                                                    )}
                                                    openOnFocus
                                                    freeSolo
                                                />
                                            </div>
                                            <div className="col-12 col-md-3">
                                                <VSTextFieldCurrency
                                                    value={parseFloat(defaultValue).toFixed(2)}
                                                    onChangeValue={(event, originalValue, maskedValue) => {
                                                        // parseInt prevents 1.00000001 bug
                                                        setValue(parseFloat(originalValue)) 
                                                    }}
                                                    InputElement={(
                                                        <TextField
                                                            InputProps={{
                                                                inputProps: { min: 0 }
                                                            }}
                                                            placeholder="R$ 0,00"
                                                            label="Valor do serviço"
                                                            error={errorValue}
                                                            helperText={helperValue}
                                                            size="small"
                                                            variant="outlined"
                                                            fullWidth
                                                        />
                                                    )}
                                                />
                                            </div>
                                            <div className="col-12 col-md-9">
                                                <div className="row g-3">
                                                    <div className="col col-sm">
                                                        <Autocomplete
                                                            value={timeText}
                                                            options={defaultTimes}
                                                            getOptionLabel={(option) => getSelectedOptionLabel(option, defaultTimes, timeText)}
                                                            isOptionEqualToValue={(option, value) => option.value == value}
                                                            onChange={(_, selected) => setTimeText(selected?.value || null)}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    placeholder="Tempo de duração do serviço"
                                                                    label="Tempo de duração do serviço"
                                                                    helperText={optionalField}
                                                                    size="small"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                />
                                                            )}
                                                            openOnFocus
                                                        />
                                                    </div>
                                                    <div className="col-auto">
                                                        <div className="separator-text">
                                                            OU
                                                        </div>
                                                    </div>
                                                    <div className="col-6 col-sm-3 col-md-3 col-xxl-2">
                                                        <TextField
                                                            placeholder="Horas"
                                                            label="Horas"
                                                            value={hours}
                                                            onChange={({ target: { value } }) => setHours(String(value).replace(/[^0-9]/g, ""))}
                                                            helperText={optionalField}
                                                            size="small"
                                                            variant="outlined"
                                                            fullWidth
                                                        />
                                                    </div>
                                                    <div className="col-6 col-sm-3 col-md-3 col-xxl-2">
                                                        <TextField
                                                            placeholder="Minutos"
                                                            label="Minutos"
                                                            value={minutes}
                                                            onChange={({ target: { value } }) => {
                                                                const _minutes = String(value).replace(/[^0-9]/g, "")
                                                                if (_minutes.length < 3) {
                                                                    if (parseInt(_minutes) > 59) {
                                                                        setMinutes(`00`)
                                                                    } else if (_minutes.length == 1 && parseInt(_minutes) > 5) {
                                                                        setMinutes(`0${_minutes}`)
                                                                    } else {
                                                                        setMinutes(_minutes)
                                                                    }
                                                                }
                                                            }}
                                                            helperText={optionalField}
                                                            size="small"
                                                            variant="outlined"
                                                            fullWidth
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </VSAccordionSimple>
                                </div>
                            </div>
                            <hr className="my-2" />
                            <div className="row mb-3">
                                <div className="col">
                                    <VSAccordionSimple
                                        expanded={true}
                                        header={(_) => (
                                            "Insumos a serem utilizados"
                                        )}
                                    >
                                        <div className="row g-3">
                                            { supplies.map((supply, index) => (
                                                <React.Fragment key={index}>
                                                    <div className="col-12">
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="input-array py-1">
                                                                    Dados do Insumo { showArrayIndex(index + 1) }
                                                                </div>
                                                            </div>
                                                            <div className="col-auto">
                                                                <button className="btn-array" onClick={() => removeSupply(index)}>
                                                                    <IconCloseO width="24" height="24" />
                                                                    Remover insumo
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="row g-3">
                                                            <div className="col-12 mb-1">
                                                                <div className="input-subtitle">
                                                                    Nome do Insumo { showArrayIndex(index + 1) }
                                                                </div>
                                                            </div>
                                                            <div className="col-12 mt-0">
                                                                <Autocomplete
                                                                    inputValue={supply?.supply}
                                                                    options={supplyOptions}
                                                                    getOptionLabel={(option) => option.id ? option.get("supplyName") : supply?.get("supplyName")}
                                                                    getOptionDisabled={(option) => Boolean(supplies?.find(supply => supply?.id == option?.id))}
                                                                    onChange={(_, selected) => {
                                                                        setSupply(index, selected)
                                                                    }}
                                                                    onBlur={() => setSupply(index, supply?.id ? supply.get("supplyName") : "", "supply")}
                                                                    onInputChange={({ target: { value }}) => setSupply(index, value || "", "supply")}
                                                                    renderInput={(params) => (
                                                                        <TextField
                                                                            {...params}
                                                                            className="w-100"
                                                                            placeholder={`Selecione o insumo ${ showArrayIndex(index + 1) }`}
                                                                            size="small"
                                                                            variant="outlined"
                                                                            helperText={optionalField}
                                                                            fullWidth
                                                                        />
                                                                    )}
                                                                    disableClearable
                                                                    openOnFocus
                                                                    freeSolo
                                                                />
                                                            </div>
                                                            { supply?.id && (
                                                                <>
                                                                    <div className="col-auto" style={{ width: "160px" }}>
                                                                        <div className="row">
                                                                            <div className="col-12 mb-1">
                                                                                <div className="input-subtitle">
                                                                                    Qtd. utilizada { showArrayIndex(index + 1) }
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-12">
                                                                                <VSTextFieldCurrency
                                                                                    value={supply.quantity}
                                                                                    hideSymbol
                                                                                    disabled={supply.unitOptions?.length == 0}
                                                                                    onChangeValue={(event, originalValue, maskedValue) => {
                                                                                        setSupply(index, originalValue, "quantity")
                                                                                    }}
                                                                                    InputElement={(
                                                                                        <TextField
                                                                                            type="tel"
                                                                                            placeholder="0,00"
                                                                                            label=""
                                                                                            error={supply.errorQuantity}
                                                                                            helperText={supply.errorQuantity ? null : mandatoryField}
                                                                                            size="small"
                                                                                            variant="outlined"
                                                                                            fullWidth
                                                                                        />
                                                                                    )}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col">
                                                                        <div className="row">
                                                                            <div className="col-12 mb-1">
                                                                                <div className="input-subtitle">
                                                                                    Medida utilizada { showArrayIndex(index + 1) }
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-12">
                                                                                <Autocomplete
                                                                                    value={supply.unit}
                                                                                    options={supply.unitOptions || []}
                                                                                    getOptionLabel={(option) => getPluralOrSingularNameForOptions(option?.name ? option : supply.unitOptions?.find(u => u.id == supply.unit), supply.quantity) || ""}
                                                                                    isOptionEqualToValue={(option, value) => option.id == value.id || option.id == supply.unit}
                                                                                    onChange={(_, selected) => setSupply(index, selected?.id || "", "unit")}
                                                                                    disabled={supply.unitOptions?.length == 0}
                                                                                    renderInput={(params) => (
                                                                                        <TextField
                                                                                            {...params}
                                                                                            placeholder="Escolha uma medida"
                                                                                            error={supply.errorUnit}
                                                                                            helperText={supply.helperUnit}
                                                                                            size="small"
                                                                                            variant="outlined"
                                                                                            fullWidth
                                                                                        />
                                                                                    )}
                                                                                    openOnFocus
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    { supply.errorQuantity && !supply.errorUnit && (
                                                                        <div className="col-12 mt-0">
                                                                            <p className="text-error mb-0">
                                                                                { supply.helperQuantity }
                                                                            </p>
                                                                        </div>
                                                                    )}
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            )) }
                                            { supplies.length < supplyOptions.length ? (
                                                <div className="col-12">
                                                    <button className="btn-add" onClick={() => setSupplies(supplies.concat(defaultSupply))}>
                                                        <IconAddO width="20" height="20" />
                                                        Adicionar Insumo { showArrayIndex(supplies.length + 1) }
                                                    </button>
                                                </div>
                                            ) : (
                                                <div className="col-12">
                                                    <div className="vsalert">
                                                        <div className="row align-items-center">
                                                            <div className="col-auto">
                                                                <IconWarning width="24px" height="24px" fill="var(--vsprontuario-secondary-color)" />
                                                            </div>
                                                            <div className="col ps-0">
                                                                <h4 className="vsalert-title">
                                                                    Não é possível adicionar mais insumos.
                                                                </h4>
                                                                <p className="vsalert-text">
                                                                    Você possui <b>{ supplyOptions.length }</b> insumo(s) cadastrado(s). Para adicionar mais insumos será necessário cadastrar novos insumos, pois não é possível repetir os insumos já selecionados.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </VSAccordionSimple>
                                </div>
                            </div>
                            { supplies?.length > 0 && (
                                <div className="vsalert vsalert-warning mb-3">
                                    <div className="row align-items-center">
                                        <div className="col-auto">
                                            <IconWarning width="24px" height="24px" />
                                        </div>
                                        <div className="col ps-0">
                                            <p className="mb-0" style={{ color: "#444444" }}>
                                                Não esqueça de sempre verificar a validade dos seus insumos
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <hr className="my-2" />
                            <div className="row">
                                <div className="col">
                                    <VSAccordionSimple
                                        expanded={true}
                                        header={(_) => (
                                            "Observações do serviço"
                                        )}
                                    >
                                        <div className="row">
                                            <div className="col-12">
                                                <TextField
                                                    placeholder="Digite as observações sobre o serviço"
                                                    label="Observações do serviço"
                                                    value={notes}
                                                    onChange={({ target: { value } }) => setNotes(value)}
                                                    helperText={<span>Campo Opcional<br/></span>}
                                                    size="small"
                                                    variant="outlined"
                                                    multiline
                                                    fullWidth
                                                />
                                            </div>
                                            <div className="col input-info">
                                                As observações são anotações particulares. <b>Elas nunca serão compartilhadas com o tutor.</b>
                                            </div>
                                        </div>
                                    </VSAccordionSimple>
                                </div>
                            </div>
                        </>
                    )}
                </>
            )}
        </VSDrawer>
    )
}

const mapStateToProps = state => {
    return {
        clinic: state.clinic,
        permissions: state.permissions
    };
};

export default connect(mapStateToProps)(ServiceForm);