import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/lab/Autocomplete';
import { CircularProgress } from '@mui/material';

import { ReactComponent as IconAddO } from "../../../../assets/icon-add-o.svg"
import { ReactComponent as IconCloseO } from "../../../../assets/icon-close-o.svg"

import { defaultDrugWithValidation, getAppointmentDrug, searchDrug } from './AppointmentDrug';
import { addEvent, dayjs, mandatoryField, saveOnTracker, showArrayIndex} from '../../../../utils'
import { ACL, APP_DEVICE, APP_NAME, APP_VERSION } from '../../../../constants';
import { getPatient} from '../../Patient'

import DateInput from '../../../../components/dateInput/DateInput';
import VSDrawer from '../../../../components/vsDrawer/VSDrawer';
import VSMedicalRecordsAudit from '../../../../components/vsMedicalRecordsAudit/VSMedicalRecordsAudit';
import VSError from '../../../../components/vsError/VSError';

import './AppointmentDrugForm.scss'
const AppointmentDrugForm = (props) => {

    const {
        id,
        id_medicamento
    } = useParams()

    const {
        clinic,
        history,
        permissions
    } = props

    const update = id_medicamento !== "novo"

    const [ loading, setLoading ] = useState(false)
    const [ error, setError ] = useState(null)
    const [ hasSubmited, setHasSubmited ] = useState(false)
    const [ appointmentDrug, setAppointmentDrug ] = useState(null)
    const [ patient, setPatient ] = useState(null)
    
    const [ drugs, setDrugs ] = useState([{ ...defaultDrugWithValidation }])

    const defaultDate = dayjs()
    const [ documentDate, setDocumentDate ] = useState(defaultDate.toDate())
    const [ documentDateText, setDocumentDateText ] = useState(defaultDate.format("DD/MM/YYYY"))
    const [ errorDocumentDate, setErrorDocumentDate ] = useState(false)
    const [ helperDocumentDate, setHelperDocumentDate ] = useState(<span>Campo <b>Obrigatório</b></span>)
    const [ notes, setNotes ] = useState('')

    const handleDocumentDate = (_date) => {
        setDocumentDateText(_date)

        if (_date.match(/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/g)) {
            let newDate = dayjs(_date, "DD/MM/YYYY")
            if (newDate.isValid()) {
                setDocumentDate(newDate.toDate())
                return
            }
        }
        setDocumentDate(null)
    }
    
    const setDrug = (index, field, value) => {
        let newDrugs = [...drugs]
        newDrugs[index][field] = value
        setDrugs(newDrugs)
    }

    const removeDrug = (index) => {
        let newDrugs = [...drugs]
        newDrugs.splice(index, 1)
        setDrugs(newDrugs)
    }

    const validateOnTouch = () => {
        validate()
    }

    const validate = () => {
        let isValid = true
        if (loading) {
            isValid = false
        }

        if (error?.message) {
            isValid = false
        }

        const _documentDate = dayjs(documentDateText, "DD/MM/YYYY")
        if (_documentDate.isValid()) {
            if (_documentDate.format("YYYY") < 1971) {
                isValid = false
                setErrorDocumentDate(true)
                setHelperDocumentDate("A data não pode ser menor que 1971")
            } else if (_documentDate.diff(dayjs(), "years") > 5) {
                isValid = false
                setErrorDocumentDate(true)
                setHelperDocumentDate("A data não pode exceder 5 anos")
            } else {
                setErrorDocumentDate(false)
                setHelperDocumentDate(<span>Campo <b>Obrigatório</b></span>)
            }
        } else {
            isValid = false
            setErrorDocumentDate(true)
            setHelperDocumentDate("A data informada não é válida")
        }
        
        drugs && drugs.map((drug, index) => {
            if (drug.drug) {
                setDrug(index, "errorDrug", false)
                setDrug(index, "helperDrug", mandatoryField)
            } else {
                isValid = false
                setDrug(index, "errorDrug", true)
                setDrug(index, "helperDrug", `O nome do medicamento ${showArrayIndex(index + 1)} não foi preenchido`)
            }
        })

        return isValid
    }

    const isValid = () => !loading && !errorDocumentDate && drugs.filter(drug => drug.errorDrug).length == 0

    const fieldsWithErrorText = () => {
        let errors = []
        if (loading) {
            errors.push("Salvando a medicação")
        }
        if (error?.message) {
            errors.push(error.message)
        }
        if (errorDocumentDate) {
            errors.push("Data da medicação")
        }
        drugs.map((drug, index) => {
            if (drug.errorDrug) {
                errors.push(`O nome do medicamento ${showArrayIndex(index + 1)} não foi preenchido`)
            }
        })
        if (!permissions?.check(ACL.APPOINTMENT_DRUG, ACL.Rule.EDIT, appointmentDrug)) {
            errors.push("Acesso negado")
        }
        return errors
    }

    const getPatientOrError = () => {
        setLoading(true)
        setError(null)

        getPatient(id, clinic.object)
        .then(_patient => {
            setPatient(_patient)
        })
        .catch(error => {
            Swal.fire(
                'Desculpe',
                error,
                'error'
            )
            .then(_ => {
                setLoading(false)
                setError({
                    message: error,
                    function: "getPatientOrError"
                })
            })
        })
    }

    const getAppointmentDrugOrError = () => {
        if (permissions?.check(ACL.APPOINTMENT_DRUG, ACL.Rule.VIEW)) {
            saveOnTracker("Iniciou", "Medicamento", update ? id_medicamento : "")
            if (update) {
                setLoading(true)
                setError(null)
    
                getAppointmentDrug(id_medicamento, patient)
                .then(_drug => {
                    setAppointmentDrug(_drug)
                    setDocumentDate(_drug.get("documentDate"))
                    setDocumentDateText(dayjs(_drug.get("documentDate")).format("DD/MM/YYYY"))
                    setDrugs(_drug.get("drugs"))
                    setNotes(_drug.get("notes"))
                    setLoading(false)
                })
                .catch(error => {
                    Swal.fire(
                        'Desculpe',
                        error,
                        'error'
                    )
                    .then(_ => {
                        setLoading(false)
                        setError({
                            message: error,
                            function: "getAppointmentDrugOrError"
                        })
                    })
                })
            } else {
                let newDrug = new Parse.Object("AppointmentDrug")
                newDrug.setACL(clinic.ACL)
                newDrug.set("clinic", clinic.object)
                newDrug.set("createdBy", Parse.User.current())
                newDrug.set("createdApp", APP_NAME)
                newDrug.set("createdDevice", APP_DEVICE)
                newDrug.set("createdAppVersion", APP_VERSION)
                newDrug.set("isDeleted", false)
                newDrug.set("patient", patient)
                setAppointmentDrug(newDrug)
                setLoading(false)
            }
        }
    }

    const save = () => {
        return new Promise((resolve, reject) => {
            setHasSubmited(true)
            if (validate()) {
                setLoading(true)
                appointmentDrug.set('documentDate', documentDate);
                appointmentDrug.set('drugs', drugs.map(drug => ({
                    drugId: drug.drugId,
                    drug: drug.drug,
                    dosage: drug.dosage,
                    type: drug.type
                })));
                appointmentDrug.set('notes', notes);
                appointmentDrug.set("changedAt", new Date())
                appointmentDrug.set("changedBy", Parse.User.current())
                appointmentDrug.set("changedApp", APP_NAME)
                appointmentDrug.set("changedDevice", APP_DEVICE)
                appointmentDrug.set("changedAppVersion", APP_VERSION)
                appointmentDrug.save()
                .then(_appointmentDrug => {
                    saveOnTracker("Concluiu", "Medicamento", _appointmentDrug.id)
                    resolve(_appointmentDrug)
                })
                .catch(error => {
                    setLoading(false)
                    console.error(error)
                    Swal.fire(
                        'Desculpe',
                        `Ocorreu algum erro ao tentar ${appointmentDrug.id ? "editar" : "cadastrar"} a medicação do animal`,
                        'error'
                    )
                    update && appointmentDrug.revert()
                    reject()
                })
            } else {
                reject()
            }
        })
    }

    const findDrug = (index, value, selected) => {
        if (selected) {
            setDrug(index, "drugId", selected.itemId)
            setDrug(index, "drug", selected.presentationFull)
        } else {
            setDrug(index, "drugId", "")
            setDrug(index, "drug", value)
            if (value) {
                searchDrug(value)
                .then(_options => {
                    setDrug(index, "drugOptions", _options)
                })
                .catch(_ => {
                    setDrug(index, "drugOptions", [])
                })
            }
        }
	}

    useEffect(() => {
        validateOnTouch()
    }, [ 
        documentDate, documentDateText,
        JSON.stringify(drugs.map(drug => ({ drugId: drug.drugId, drug: drug.drug, dosage: drug.dosage, type: drug.type })))
    ])

    useEffect(() => {
        if (appointmentDrug && !permissions?.check(ACL.APPOINTMENT_DRUG, ACL.Rule.EDIT, appointmentDrug)) {
            permissions?.error()
        }
    }, [ appointmentDrug ])

    useEffect(() => {
        if (patient) {
            getAppointmentDrugOrError()
        }
    }, [ patient ])
    
    useEffect(() => {
        if (id) {
            getPatientOrError()
        }
    }, [])

    const errors = fieldsWithErrorText()
    const isSingular = drugs.length == 1

    return (
        <VSDrawer
            id="appointmentDrug-form"
            width="50%"
            title={update ? "Editar Aplicação de Medicamento" : "Nova Aplicação de Medicamento"}
            cancel={<span>Cancelar Aplicação<span className="d-none d-md-inline"> de Medicamento</span></span>}
            submit={isValid ? <span>Salvar Aplicação<span className="d-none d-md-inline"> de Medicamento</span></span> : <span>Preencha os campos<span className="d-none d-md-inline"> necessários</span></span>}
            errors={errors}
            onSubmit={save}
            onCancel={() => history.goBack()}
            onAfterSave={(object) => {
                history.replace(`/animal/${patient.id}/medicamento`)
                addEvent("AppointmentDrug__getList", {})
            }}
            VSDrawerSuccessProps={{
                title: "Aplicação de Medicamento salva com Sucesso",
                text: ""
            }}
            VSDrawerCancelProps={{
                title: "Tem certeza que deseja cancelar a aplicação de medicamento?",
                confirm: "Cancelar Aplicação de Medicamento",
                cancel: "Voltar para Edição"
            }}
        >
            { loading ? (
                <div className="row data-loading">
                    <div className="col">
                        <CircularProgress />
                    </div>
                </div>
            ) : error?.message ? (
                <VSError
                    message={error.message}
                    onClose={() => {
                        switch(error.function) {
                            case "getPatientOrError":
                                getPatientOrError()
                                break
                            case "getAppointmentDrugOrError":
                                getAppointmentDrugOrError()
                                break
                            default:
                        }
                    }}
                />
            ) : (
                <div className="row">
                    <div className="col">
                        <div className="row align-items-center mb-3">
                            <div className="col-auto">
                                <div className="input-title">
                                    Data da Aplicação
                                </div>
                            </div>
                            <div className="col-auto" style={{ width: '200px' }}>
                                <DateInput
                                    className="m-0"
                                    placeholder="00/00/0000"
                                    value={documentDateText || null}
                                    error={errorDocumentDate}
                                    helperText={null}
                                    onChange={(value) => handleDocumentDate(value)}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                />
                            </div>
                            { errorDocumentDate && (
                                <div className="col-12">
                                    <small className="text-danger">{ helperDocumentDate }</small>
                                </div>
                            )}
                        </div>
                        <div className="row mb-1">
                            <div className="col-12">
                                <div className="input-title input-height">
                                    Medicamento{ isSingular ? "" : "s" } aplicado{ isSingular ? "" : "s" }
                                </div>
                            </div>
                        </div>
                        { drugs.map((drug, index) => (
                            <React.Fragment key={index}>
                                <div className="row align-items-center mb-3">
                                    <div className="col">
                                        <div className="input-array py-1">
                                            Medicamento { showArrayIndex(index + 1) }
                                        </div>
                                    </div>
                                    { drugs.length > 1 && (
                                        <div className="col-auto">
                                            <button
                                                className="btn-array px-0"
                                                onClick={() => removeDrug(index)}
                                            >
                                                <IconCloseO width="24" height="24" />
                                                Remover Medicamento
                                            </button>
                                        </div>
                                    )}
                                </div>
                                <div className="row align-items-center mb-3">
                                    <div className="col-12">
                                        <div className="input-subtitle mb-1">Nome do Medicamento { showArrayIndex(index + 1) }</div>
                                        <Autocomplete
                                            value={drug.drug}
                                            options={drug.drugOptions || []}
                                            getOptionLabel={(option) => option.presentationFull || (drug ? drug.drug : "")}
                                            getOptionSelected={(option) => option.presentationFull == (drug ? drug.drug : "")}
                                            onChange={({ target: { value } }, selected) => findDrug(index, value, selected)}
                                            onInput={({ target: { value } }, selected) => findDrug(index, value, selected)}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    placeholder="Digite ou selecione o medicamento"
                                                    error={drug.errorDrug}
                                                    helperText={drug.helperDrug}
                                                    size="small"
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                            )}
                                            openOnFocus
                                            freeSolo
                                        />
                                    </div>
                                </div>
                                <div className="row align-items-center mb-4">
                                    <div className="col-4 col-md-2">
                                        <div className="input-subtitle mb-1">Dosagem</div>
                                        <Autocomplete
                                            value={drug.dosage}
                                            onChange={(_, selected) => setDrug(index, "dosage", selected)}
                                            onInput={({ target: { value } }) => setDrug(index, "dosage", value)}
                                            options={[
                                                "1/4",
                                                "1/2",
                                                "1",
                                                "2",
                                                "3",
                                                "4",
                                                "5",
                                            ]}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    placeholder="Ex: 1"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                />
                                            )}
                                            disableClearable
                                            openOnFocus
                                            freeSolo
                                        />
                                    </div>
                                    <div className="col-8 col-md-4">
                                        <div className="input-subtitle mb-1">Medida</div>
                                        <Autocomplete
                                            value={drug.type}
                                            onChange={(_, selected) => setDrug(index, "type", selected)}
                                            onInput={({ target: { value } }) => setDrug(index, "type", value)}
                                            options={[
                                                "Cápsula",
                                                "Comprimido",
                                                "Drágea",
                                                "Dose",
                                                "Grama",
                                                "Gota",
                                                "Miligrama",
                                                "Mililitro",
                                            ]}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    placeholder="Ex: Comprimido"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                />
                                            )}
                                            disableClearable
                                            openOnFocus
                                            freeSolo
                                        />
                                    </div>
                                </div>
                            </React.Fragment>
                        ))}
                        <div className="row align-items-center mb-4">
                            <div className="col-12">
                                <button
                                    className="btn-add" 
                                    onClick={() => setDrugs(drugs.concat({ ...defaultDrugWithValidation }))}
                                >
                                    <IconAddO width="20" height="20" />
                                    Adicionar Medicamento { showArrayIndex(drugs.length + 1) }
                                </button>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="col-12">
                                <div className="input-title input-height">
                                    Anotações (Opcional)
                                </div>
                            </div>
                            <div className="col-12">
                                <TextField
                                    value={notes}
                                    placeholder="Escreva suas anotações sobre a aplicação aqui"
                                    onChange={({ target: { value } }) => setNotes(value)}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                />
                            </div>
                        </div>
                        <VSMedicalRecordsAudit parseObject={appointmentDrug} />
                    </div>
                </div>
            )}
        </VSDrawer>
    )
}

const mapStateToProps = state => {
    return {
        clinic: state.clinic,
        permissions: state.permissions
    };
};
  
export default connect(mapStateToProps)(AppointmentDrugForm);