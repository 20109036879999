import React, { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { connect } from 'react-redux'

import { ReactComponent as IconPatientBalance } from '../../../../assets/icon-patient-weight.svg'
import { ReactComponent as IconAdd } from "../../../../assets/icon-primary-add-o.svg"

import { ESTIMATE, SALE, paymentOptions } from './Sale'
import { ACL, OrderBy_Oldest, OrderBy_Newest } from '../../../../constants'

import SaleView from './SaleView'
import VSList from '../../../../components/vsList/VSList'
import VSEmpty from '../../../../components/vsEmpty/VSEmpty'

import './SaleList.scss'
const SaleList = (props) => {

    const {
        tipo
    } = useParams()

    const {
        clinic,
        patient,
        history,
        permissions
    } = props

    const isSale = tipo == "venda"
    const [ stateFilter, setStateFilter ] = useState("0")
    const [ paymentTypes, setPaymentTypes ] = useState([])
    
    const rowsPerPage = 5

    const filter = useRef(null)
    const isMounted = useRef(null)

    const getSale = (_, dateFrom, dateTo, orderBy, page = 1) => {
        var query = new Parse.Query("MRSale")
        query.equalTo("clinic", clinic.object);
        query.equalTo("isDeleted", false);
        query.equalTo("patient", patient);
        query.equalTo("documentType", isSale ? SALE : ESTIMATE);
        if (dateFrom) {
            query.greaterThanOrEqualTo("documentDate", dateFrom)
        }
        if (dateTo) {
            query.lessThanOrEqualTo("documentDate", dateTo)
        }
        if (stateFilter == "1") {
            if (isSale) {
                query.equalTo("paymentsPending", 0)
            } else {
                query.exists("childSale")
            }
        } else if (stateFilter == "2") {
            if (isSale) {
                query.notEqualTo("paymentsPending", 0)
            } else {
                query.doesNotExist("childSale")
            }
        }
        if (paymentTypes.length > 0) {
            const queryPaymentTypes = paymentTypes.map(payment => payment.value)
            query.containedIn("payments.type", queryPaymentTypes)
        }
        switch (orderBy) {
            case OrderBy_Oldest:
                query.ascending("documentDate", "updatedAt");
                break;
            default:
                query.descending("documentDate", "updatedAt");
        }
        query.addAscending("objectId")
        query.withCount()
        query.skip((page - 1) * rowsPerPage)
        query.limit(rowsPerPage)
        query.include("patient")
        query.include("services.object")
        query.include("services.supplies.object")
        query.include("createdBy")
        query.include("changedBy")
        return query.find()
        .then(data => {
            if (isMounted.current) {
                const {
                    count,
                    results
                } = data
                return {
                    items: results,
                    total: count
                }
            }
        })
        .catch(error => {
            console.error(error)
            return Promise.reject(error)
        })
    }

    const onChange = () => {
        if (filter.current) {
            filter.current.onChange()
        }
    }

    useEffect(() => {
        onChange()
    }, [ tipo ])

    useEffect(() => {
        if (permissions && !permissions.check(ACL.SALE, ACL.Rule.VIEW)) {
            permissions.error()
        }
    }, [ permissions ])

    useEffect(() => {
        isMounted.current = true
        document.addEventListener("Sale__getList", onChange)
        return () => { 
            isMounted.current = false
            document.removeEventListener("Sale__getList", onChange)
        }
    }, [])

    return (
        <div id="patient-sale">
            <div className="col-12">
                <VSList
                    ref={filter}
                    title={{
                        text: isSale ? "Vendas" : "Orçamentos"
                    }}
                    button={permissions?.check(ACL.SALE, ACL.Rule.EDIT) && {
                        text: isSale ? "Criar Nova Venda" : "Criar Novo Orçamento",
                        onClick: () => history.push(`/${ isSale ? "venda" : "orcamento" }/${ patient.id }/novo`)
                    }}
                    interval={{
                        start: {
                            text: "Início",
                            value: null
                        },
                        end: {
                            text: "Término",
                            value: null
                        }
                    }}
                    order={{
                        options: [
                            OrderBy_Newest,
                            OrderBy_Oldest
                        ],
                        value: OrderBy_Newest
                    }}
                    VSFilterProps={{
                        title: `Filtro de ${isSale ? "Vendas" : "Orçamentos"}`,
                        onClear: () => {
                            setStateFilter("0")
                            setPaymentTypes([])
                        },
                        onFilter: () => {
                            onChange()
                        },
                        filters: [
                            {
                                title: `Estado ${isSale ? 'da venda': 'do orçamento'}`,
                                defaultValue: "0",
                                value: stateFilter,
                                onChange: (value) => {
                                    setStateFilter(value)
                                },
                                options: [
                                    { value: "0", label: "Todos" },
                                    { value: "1", label: isSale ? "Faturado" : "Realizado"},
                                    { value: "2", label: isSale ? "Pendente" : "Não Realizado"}
                                ]
                            },
                            isSale ? {
                                title: "Formas de pagamento",
                                defaultValue: [],
                                value: paymentTypes,
                                componentProps: {
                                    horizontal: false
                                },
                                onChange: (value) => {
                                    setPaymentTypes(value)
                                },
                                multiple: true,
                                options: paymentOptions
                            } : undefined
                        ].filter(val => val)
                    }}
                    pagination={{
                        rowsPerPage: rowsPerPage
                    }}
                    onChange={({ _, _start, _end, _orderBy, _page }) => {
                        return getSale(null, _start, _end, _orderBy, _page)
                    }}
                    renderItem={(item, expanded) => (
                        <SaleView
                            object={item}
                            expanded={expanded}
                            history={history}
                            updateList={onChange}
                        />
                    )}
                    renderEmpty={() => (
                        <VSEmpty
                            icon={<IconPatientBalance width="32px" height="32px" />}
                            title={`Você ainda não possui ${ isSale ? "nenhuma venda" : "nenhum orçamento"} para este animal`}
                            text={`Cadastre ${ isSale ? "a primeira venda" : "o primeiro orçamento" } e comece a acompanhar de perto a evolução do seu paciente`}
                        >
                            { permissions?.check(ACL.SALE, ACL.Rule.EDIT) && (
                                <button
                                    id="new-sale-link"
                                    className="vsbox-btn vsbox-btn-primary"
                                    onClick={() => history.push(`/${ isSale ? "venda" : "orcamento" }/${ patient.id }/novo`)}
                                >
                                    <IconAdd width="14" height="14" className="me-2" />
                                    Cadastrar { isSale ? "nova venda" : "novo orçamento" }
                                </button>
                            )}
                        </VSEmpty>
                    )}
                    renderNotFound={() => (
                        <VSEmpty
                            icon={<IconPatientBalance width="32px" height="32px" />}
                            title="Não encontramos nenhum resultado para a sua busca"
                            text="Tente refazer sua busca utilizando outro período ou crie um novo cadastro utilizando o botão abaixo"
                        >
                            { permissions?.check(ACL.SALE, ACL.Rule.EDIT) && (
                                <button
                                    id="new-sale-link"
                                    className="vsbox-btn vsbox-btn-primary"
                                    onClick={() => history.push(`/${ isSale ? "venda" : "orcamento" }/${ patient.id }/novo`)}
                                >
                                    <IconAdd width="14" height="14" className="me-2" />
                                    Cadastrar { isSale ? "nova venda" : "novo orçamento" }
                                </button>
                            )}
                        </VSEmpty>
                    )}
                />
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        clinic: state.clinic,
        permissions: state.permissions
    };
};
  
export default connect(mapStateToProps)(SaleList)