import axios from "axios"
import { APP_DEVICE, APP_NAME, APP_VERSION } from "../../../../constants"

export const deleteExam = (exam, callback) => {
    Swal.fire({
        title: 'Tem certeza?',
        text: "Você tem certeza que deseja excluir essa solicitação de exame? Essa ação não poderá ser desfeita.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, quero excluir!',
        cancelButtonText: 'Não, manter a solicitação'
    }).then((result) => {
        if (result.isConfirmed) {
            exam.set("isDeleted", true)
            exam.set("changedAt", new Date())
            exam.set("changedBy", Parse.User.current())
            exam.set("changedApp", APP_NAME)
            exam.set("changedDevice", APP_DEVICE)
            exam.set("changedAppVersion", APP_VERSION)
            exam.save()
            .then(_ => {
                Swal.fire(
                    'Excluído!',
                    'A solicitação de exame foi excluída com sucesso.',
                    'success'
                )
                .then(_ => {
                    callback && callback()
                })
            })
            .catch(error => {
                console.error(error)
                Swal.fire(
                    'Desculpe',
                    'Ocorreu algum erro ao tentar excluir a solicitação de exame',
                    'error'
                )
                exam.revert()
            })
        }
    })
}

export const getExamRequest = (id, patient) => {
    return new Promise((resolve, reject) => {
        var query = new Parse.Query("ExamRequest");
        query.equalTo("clinic", patient.get("clinic"));
        query.equalTo("isDeleted", false);
        query.equalTo("patient", patient)
        query.get(id)
        .then(_object => {
            if (_object) {
                resolve(_object)
            } else {
                reject('Não encontramos a solicitação de exame informada')
            }
        }).catch(error => {
            console.error(error)
            reject('Ocorreu algum erro ao tentar buscar a solicitação de exame')
        })
    })
}

export const getExams = () => {
    return new Promise(resolve => {
        axios.get(`${process.env.REACT_APP_API_V2}/exams`)
        .then(({ data }) => {
            resolve(data.length > 0 ? data : [])
        })
    })
}

export const getDiseases = () => {
    return new Promise(resolve => {
        axios.get(`${process.env.REACT_APP_API_V2}/diseases`)
        .then(({ data }) => {
            resolve(data.length > 0 ? data : [])
        })
    })
}