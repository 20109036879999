import React from "react"

import { ReactComponent as IconSquareMinus } from "../../assets/icon-square-minus.svg"
import { ReactComponent as IconSquarePlus } from "../../assets/icon-square-plus.svg"

import PatientPhoto from "../patientPhoto/PatientPhoto"

import "./VSBoxPatient.scss"
const VSBoxPatient = (props) => {

    const {
        count,
        patient,
        expanded,
        disabled
    } = props

    return (
        <div id="vsbox-patient" className={`vsbox ${disabled ? "vsbox-disabled" : ""}`}>
            <div className="vsbox-body p-2 mb-0">
                <div className="row align-items-center">
                    <div className="col-auto">
                        <PatientPhoto patient={patient} width="48px" height="48px" withBorder />
                    </div>
                    <div className="col ps-0">
                        <div className="vsbox-title">
                            { patient.get("name") }
                        </div>
                        <div className="vsbox-text">
                            Tutor(a): { patient.get("owner").get("name") }
                        </div>
                    </div>
                    <div className="col-12 col-md-auto text-end vsbox-info">
                        <div className="vsbox-separator d-block d-md-none"></div>
                        <div className="vsbox-info-action">
                            { expanded ? "Ocultar" : "Ver" } Registro{ count == 1 ? "" : "s" } ({ count })
                            { expanded ? (
                                <IconSquareMinus width="24px" height="24px" className="ms-2" fill="var(--vsprontuario-secondary-color)" />
                            ) : (
                                <IconSquarePlus width="24px" height="24px" className="ms-2" fill="var(--vsprontuario-secondary-color)" />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VSBoxPatient