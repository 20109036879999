import React from 'react';

import "./CardDashboardAnniversary.scss"
const CardDashboardAnniversary = (props) => {

    const {
        displayYearCard,
        displayMonthCard,
        totalMonths,
        displayYearValue,
        dateFormatted
    } = props

    return (
        <div id="card-dashboard-anniversary">
            { displayMonthCard ? (
                <div className="months">
                    <div className="row">
                        <div className="col-auto">
                            <div className="number">{ totalMonths.toLocaleString('pt-BR', {
                                minimumIntegerDigits: 2,
                                useGrouping: false
                            })}</div>
                            <div>{ totalMonths == 1 ? "MÊS" : "MESES"}</div> 
                        </div>
                        <div className="col d-flex align-items-center">
                            <div className="description">
                                Sua clínica está no Prontuário Vet Smart desde <span>{ dateFormatted}</span>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div></div>
            )}
            { displayYearCard ? (
                <div className="years">
                    <div className="row">
                        <div className="number">OBRIGADO pela parceria!</div>
                        <div className="description">
                            <span>{ displayYearValue.toLocaleString('pt-BR', {
                                minimumIntegerDigits: 2,
                                useGrouping: false
                            })} { totalMonths == 1 ? "ANO" : "ANOS"}</span> da sua clínica no Prontuário Vet Smart
                        </div>

                    </div>
                </div>
            ) : (
                <div></div>
            )}

        </div>
    )
}

export default CardDashboardAnniversary;