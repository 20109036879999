import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import { CircularProgress } from '@mui/material';

import { canUserEditVeterinaryDocuments, dayjs, mandatoryField, mandatoryFields, optionalField, saveOnTracker, stringifyArray, validateCPF, validateEmail, validatePhone, validatePostalCode } from '../../../../utils'
import { getPatient } from '../../Patient'
import { ACL, APP_DEVICE, APP_NAME, APP_VERSION } from '../../../../constants';
import { getAge } from '../../Patient';
import {  ATESTADO_SANITARIO, ATESTADO_VACINACAO, CARTA_ENCAMINHAMENTO, defaultDeworming, defaultExam, defaultProcedure, defaultVaccine, defaultItems, getTerm, TERMO_AUTORIZACAO_EUTANASIA, TERMO_AUTORIZACAO_EXAMES, TERMO_AUTORIZACAO_PROCEDIMENTO_ANESTESICO, TERMO_AUTORIZACAO_PROCEDIMENTO_CIRURGICO, TERMO_AUTORIZACAO_PROCEDIMENTO_TERAPEUTICO, TERMO_BRANCO, TERMO_CONSENTIMENTO_TELEMEDICINA, TERMO_INTERNACAO, TERMO_LIBERACAO, TERMO_OBITO } from './Term';
import { formattedAddress } from '../../../owner/Owner';

import VSDrawer from '../../../../components/vsDrawer/VSDrawer';
import TermVaccineForm from './TermVaccineForm';
import TermPatientForm from './TermPatientForm';
import TermPatientInfoForm from './TermPatientInfoForm';
import TermOwnerForm from './TermOwnerForm';
import TermDeathForm from './TermDeathForm';
import TermHealthForm from './TermHealthForm';
import TermDocumentForm from './TermDocumentForm';
import TermForwardingForm from './TermForwardingForm';
import TermEuthanasiaForm from './TermEuthanasiaForm';
import TermExamForm from './TermExamForm';
import TermHospitalizationForm from './TermHospitalizationForm';
import TermAnesthesiaForm from './TermAnesthesiaForm';
import TermSurgeryForm from './TermSurgeryForm';
import TermRiskForm from './TermRiskForm';
import TermLeaveForm from './TermLeaveForm';
import TermTelemedicineForm from './TermTelemedicineForm';
import TermBlankForm from './TermBlankForm';
import VSMedicalRecordsAudit from '../../../../components/vsMedicalRecordsAudit/VSMedicalRecordsAudit';
import VSError from '../../../../components/vsError/VSError';
import Blocked from '../../../blocked/Blocked';

import './TermForm.scss'
import VSDrawerInfo from '../../../../components/vsDrawer/VSDrawerInfo';
const TermForm = (props) => {

    const {
        id,
        id_termo,
        id_documento
    } = useParams()

    const {
        user,
        clinic,
        history,
        permissions
    } = props

    const parseUser = user.object
    const parseClinic = clinic.object

    const update = !(id_termo == "novo" || id_documento == "novo")
    const date = dayjs()

    const canEdit = canUserEditVeterinaryDocuments()

    // Term
    const documentTermForm = useRef(null)
    const vaccineTermForm = useRef(null)
    const deathTermForm = useRef(null)
    const healthTermForm = useRef(null)
    const forwardingTermForm = useRef(null)
    const euthanasiaTermForm = useRef(null)
    const examTermForm = useRef(null)
    const hospitalizationTermForm = useRef(null)
    const anesthesiaTermForm = useRef(null)
    const surgeryTermForm = useRef(null)
    const riskTermForm = useRef(null)
    const leaveTermForm = useRef(null)
    const telemedicineTermForm = useRef(null)
    const blankTermForm = useRef(null)

    const [ loading, setLoading ] = useState(true)
    const [ error, setError ] = useState(null)
    const [ term, setTerm ] = useState(null)
    const [ hasSubmited, setHasSubmited ] = useState(false)

    const [ isValid, setIsValid ] = useState(false)
    const [ errorMsg, setErrorMsg ] = useState([])

    const [ city, setCity ] = useState(clinic.city || "")
    const [ errorCity, setErrorCity ] = useState(false)
    const [ helperCity, setHelperCity ] = useState(mandatoryField)

    const [ documentType, setDocumentType ] = useState("")
    const [ errorDocumentType, setErrorDocumentType ] = useState(false)
    const [ helperDocumentType, setHelperDocumentType ] = useState(mandatoryField)

    const defaultDate = dayjs()
    const [ documentDay, setDocumentDay ] = useState(date.format("DD"))
    const [ documentMonth, setDocumentMonth ] = useState(date.format("MM"))
    const [ documentYear, setDocumentYear ] = useState(date.format("YYYY"))
    const [ documentDate, setDocumentDate ] = useState(defaultDate.toDate())
    const [ errorDocumentDate, setErrorDocumentDate ] = useState(false)
    const [ helperDocumentDate, setHelperDocumentDate ] = useState(mandatoryField)

    const [ authNecropsy, setAuthNecropsy ] = useState(false)
    const [ authStudy, setAuthStudy ] = useState(false)
    const [ authRemoveByMe, setAuthRemoveByMe ] = useState(false)
    const [ authRemoveByMeDays, setAuthRemoveByMeDays ] = useState('')
    const [ authRemoveByOther, setAuthRemoveByOther ] = useState(false)
    const [ authRemoveByOtherDays, setAuthRemoveByOtherDays ] = useState('')
    const [ authRemoveByCityHall, setAuthRemoveByCityHall ] = useState(false)
    const [ authRemoveByMeImmediately, setAuthRemoveByMeImmediately ] = useState(false)
    const [ authRemoveByOtherImmediately, setAuthRemoveByOtherImmediately ] = useState(false)
    const [ authName, setAuthName ] = useState('')
    const [ errorAuthName, setErrorAuthName ] = useState(false)
    const [ helperAuthName, setHelperAuthName ] = useState(mandatoryField)
    const [ authCpf, setAuthCpf ] = useState('')
    const [ errorAuthCpf, setErrorAuthCpf ] = useState(false)
    const [ helperAuthCpf, setHelperAuthCpf ] = useState(mandatoryField)

    const [ eventLocation, setEventLocation ] = useState(clinic.city || "")
    const [ errorEventLocation, setErrorEventLocation ] = useState(false)
    const [ helperEventLocation, setHelperEventLocation ] = useState(mandatoryField)

    const [ eventDate, setEventDate ] = useState(defaultDate.format("DD/MM/YYYY"))
    const [ errorEventDate, setErrorEventDate ] = useState(false)
    const [ helperEventDate, setHelperEventDate ] = useState(mandatoryFields)

    const [ eventTime, setEventTime ] = useState(defaultDate.format("HH:mm"))
    const [ eventHour, setEventHour ] = useState(defaultDate.format("HH"))
    const [ eventMinute, setEventMinute ] = useState(defaultDate.format("mm"))

    const [ documentSubType, setDocumentSubType ] = useState(0)
    const [ otherInfo, setOtherInfo ] = useState('')
    const [ procedures, setProcedures ] = useState([{ ...defaultProcedure }])
    const [ exams, setExams ] = useState([{ ...defaultExam }])
    const [ disease, setDisease ] = useState('')

    const [ eventDetail, setEventDetail ] = useState('')
    const [ eventNotes, setEventNotes ] = useState('')

    const [ notes, setNotes ] = useState('')

    const [ documentName, setDocumentName ] = useState('')
    const [ errorDocumentName, setErrorDocumentName ] = useState(false)
    const [ helperDocumentName, setHelperDocumentName ] = useState(mandatoryField)

    const [ signedBy, setSignedBy ] = useState('')
    const [ errorSignedBy, setErrorSignedBy ] = useState(false)
    const [ helperSignedBy, setHelperSignedBy ] = useState(mandatoryField)

    const [ vets, setVets ] = useState([
        {
            name: user.fullName,
            crmv: user.crmv,
            crmvStateId: user.crmvStateId
        }
    ])
    const [ vetName, setVetName ] = useState(user.fullName)
    const [ vetCrmv, setVetCrmv ] = useState(user.crmv)
    const [ vetCrmvStateId, setVetCrmvStateId ] = useState(user.crmvStateId)

    const [ hospitalizationDays, setHospitalizationDays ] = useState('')
    const [ hospitalizationServices, setHospitalizationServices ] = useState('')
    const [ errorHospitalizationServices, setErrorHospitalizationServices ] = useState(false)
    const [ helperHospitalizationServices, setHelperHospitalizationServices ] = useState('')

    const [ procedure, setProcedure ] = useState('')

    const [ maxDaysAfterDischarge, setMaxDaysAfterDischarge ] = useState('')
    const [ errorMaxDaysAfterDischarge, setErrorMaxDaysAfterDischarge ] = useState(false)
    const [ helperMaxDaysAfterDischarge, setHelperMaxDaysAfterDischarge ] = useState("")

    const [ vaccines, setVaccines ] = useState([{...defaultVaccine}])
    const [ vaccineNotes, setVaccineNotes ] = useState('')
    const [ includeVaccines, setIncludeVaccines ] = useState(false)

    const [ items, setItems ] = useState(null)

    const [ dewormings, setDewormings ] = useState([{...defaultDeworming}])
    const [ dewormingNotes, setDewormingNotes ] = useState('')
    const [ includeDewormings, setIncludeDewormings ] = useState(false)

    // Patient
    const patientForm = useRef(null)
    const [ patient, setPatient ] = useState(null)

    const [ breed, setBreed ] = useState('')
    const [ errorBreed, setErrorBreed ] = useState(false)
    const [ helperBreed, setHelperBreed ] = useState(mandatoryField)

    const [ gender, setGender ] = useState('')
    const [ errorGender, setErrorGender ] = useState(false)
    const [ helperGender, setHelperGender ] = useState(mandatoryField)

    const [ birthdate, setBirthdate ] = useState(null)
    const [ errorBirthdate, setErrorBirthdate ] = useState(false)
    const [ helperBirthdate, setHelperBirthdate ] = useState(mandatoryField)
    const [ birthdateText, setBirthdateText ] = useState('')
    const [ ageYear, setAgeYear ] = useState('')
    const [ ageMonth, setAgeMonth ] = useState('')

    // Patient Info
    const patientInfoForm = useRef(null)
    const [ pelage, setPelage ] = useState('')
    const [ pelages, setPelages ] = useState([])

    const [ size, setSize ] = useState('')
    const [ rga, setRga ] = useState('')
    const [ microchip, setMicrochip ] = useState('')

    const [ patientOtherInfo, setPatientOtherInfo ] = useState('')

    // Owner
    const ownerForm = useRef(null)
    const [ owner, setOwner ] = useState(null)

    const [ cpf, setCpf ] = useState('')
    const [ errorCpf, setErrorCpf ] = useState(false)
    const [ helperCpf, setHelperCpf ] = useState(mandatoryField)

    const [ loadingZipCode, setLoadingZipCode ] = useState(false)

    const [ zipCode, setZipCode ] = useState('')
    const [ errorZipCode, setErrorZipCode ] = useState(false)
    const [ helperZipCode, setHelperZipCode ] = useState(mandatoryField)

    const [ address, setAddress ] = useState('')
    const [ errorAddress, setErrorAddress ] = useState(false)
    const [ helperAddress, setHelperAddress ] = useState(mandatoryField)

    const [ addressNumber, setAddressNumber ] = useState('')
    const [ errorAddressNumber, setErrorAddressNumber ] = useState(false)
    const [ helperAddressNumber, setHelperAddressNumber ] = useState(mandatoryField)

    const [ addressComplement, setAddressComplement ] = useState('')

    const [ neighborhood, setNeighborhood ] = useState('')
    const [ errorNeighborhood, setErrorNeighborhood ] = useState(false)
    const [ helperNeighborhood, setHelperNeighborhood ] = useState(mandatoryField)

    const [ cityId, setCityId ] = useState('')
    const [ ownerCity, setOwnerCity ] = useState('')
    const [ errorOwnerCity, setErrorOwnerCity ] = useState(false)
    const [ helperOwnerCity, setHelperOwnerCity ] = useState(mandatoryField)

    const [ state, setState ] = useState('')
    const [ errorState, setErrorState ] = useState(false)
    const [ helperState, setHelperState ] = useState(mandatoryField)

    const [ ownerEmail, setOwnerEmail ] = useState('')
    const [ errorOwnerEmail, setErrorOwnerEmail ] = useState(false)
    const [ helperOwnerEmail, setHelperOwnerEmail ] = useState(documentType == TERMO_CONSENTIMENTO_TELEMEDICINA ? mandatoryField : optionalField)

    const [ ownerPhone, setOwnerPhone ] = useState('')
    const [ errorOwnerPhone, setErrorOwnerPhone ] = useState(false)
    const [ helperOwnerPhone, setHelperOwnerPhone ] = useState(documentType == TERMO_CONSENTIMENTO_TELEMEDICINA ? mandatoryField : optionalField)

    // User

    const [ userName, setUserName ] = useState(parseUser.get("fullName") || '')
    const [ errorUserName, setErrorUserName ] = useState(false)
    const [ helperUserName, setHelperUserName ] = useState(mandatoryField)

    const [ userCrmv, setUserCrmv ] = useState(parseUser.get("crmv") || '')
    const [ errorUserCrmv, setErrorUserCrmv ] = useState(false)
    const [ helperUserCrmv, setHelperUserCrmv ] = useState(mandatoryField)

    const [ userCrmvStateId, setUserCrmvStateId ] = useState(parseUser.get("crmvStateId") || '')
    const [ errorUserCrmvStateId, setErrorUserCrmvStateId ] = useState(false)
    const [ helperUserCrmvStateId, setHelperUserCrmvStateId ] = useState(mandatoryField)

    const [ userContactEmail, setUserContactEmail ] = useState(parseUser.get("contactEmail") || parseUser.get("email") || '')
    const [ errorUserContactEmail, setErrorUserContactEmail ] = useState(false)
    const [ helperUserContactEmail, setHelperUserContactEmail ] = useState(mandatoryField)

    const [ userContactPhone, setUserContactPhone ] = useState(parseUser.get("contactPhone") || '')
    const [ errorUserContactPhone, setErrorUserContactPhone ] = useState(false)
    const [ helperUserContactPhone, setHelperUserContactPhone ] = useState(mandatoryField)

    const [ userLoadingZipCode, setUserLoadingZipCode ] = useState(false)

    const [ userZipCode, setUserZipCode ] = useState(parseUser.get("businessZipCode") || '')
    const [ errorUserZipCode, setErrorUserZipCode ] = useState(false)
    const [ helperUserZipCode, setHelperUserZipCode ] = useState(mandatoryField)

    const [ userAddressStreet, setUserAddressStreet ] = useState(parseUser.get("businessAddressStreet") || '')
    const [ errorUserAddressStreet, setErrorUserAddressStreet ] = useState(false)
    const [ helperUserAddressStreet, setHelperUserAddressStreet ] = useState(mandatoryField)

    const [ userAddressNumber, setUserAddressNumber ] = useState(parseUser.get("businessAddressNumber") || '')
    const [ errorUserAddressNumber, setErrorUserAddressNumber ] = useState(false)
    const [ helperUserAddressNumber, setHelperUserAddressNumber ] = useState(mandatoryField)

    const [ userAddressComplement, setUserAddressComplement ] = useState(parseUser.get("businessAddressComplement") || '')

    const [ userNeighborhood, setUserNeighborhood ] = useState(parseUser.get("businessNeighborhood") || '')
    const [ errorUserNeighborhood, setErrorUserNeighborhood ] = useState(false)
    const [ helperUserNeighborhood, setHelperUserNeighborhood ] = useState(mandatoryField)

    const [ userCity, setUserCity ] = useState(parseUser.get("businessCity") || parseUser.get("city") || '')
    const [ errorUserCity, setErrorUserCity ] = useState(false)
    const [ helperUserCity, setHelperUserCity ] = useState(mandatoryField)

    const [ userStateId, setUserStateId ] = useState(parseUser.get("businessStateId") || parseUser.get("stateId") || '')
    const [ errorUserStateId, setErrorUserStateId ] = useState(false)
    const [ helperUserStateId, setHelperUserStateId ] = useState(mandatoryField)

    // Clinic

    const [ clinicCompanyName, setClinicCompanyName ] = useState(parseClinic.get("companyName") || '')
    const [ errorClinicCompanyName, setErrorClinicCompanyName ] = useState(false)
    const [ helperClinicCompanyName, setHelperClinicCompanyName ] = useState(mandatoryField)

    const [ clinicCnpj, setClinicCnpj ] = useState(parseClinic.get("cnpj") || '')
    const [ errorClinicCnpj, setErrorClinicCnpj ] = useState(false)
    const [ helperClinicCnpj, setHelperClinicCnpj ] = useState(mandatoryField)

    const [ clinicCrmv, setClinicCrmv ] = useState(parseClinic.get("crmv") || '')
    const [ errorClinicCrmv, setErrorClinicCrmv ] = useState(false)
    const [ helperClinicCrmv, setHelperClinicCrmv ] = useState(mandatoryField)

    const [ clinicCrmvStateId, setClinicCrmvStateId ] = useState(parseClinic.get("crmvStateId") || '')
    const [ errorClinicCrmvStateId, setErrorClinicCrmvStateId ] = useState(false)
    const [ helperClinicCrmvStateId, setHelperClinicCrmvStateId ] = useState(mandatoryField)

    const [ clinicRegistrationMapa, setClinicRegistrationMapa ] = useState(parseClinic.get("registrationMAPA") || '')
    const [ errorClinicRegistrationMapa, setErrorClinicRegistrationMapa ] = useState(false)
    const [ helperClinicRegistrationMapa, setHelperClinicRegistrationMapa ] = useState(mandatoryField)

    const [ clinicStateRegistrationId, setClinicStateRegistrationId ] = useState(parseClinic.get("stateRegistrationId") || '')

    const [ clinicMunicipalRegistrationId, setClinicMunicipalRegistrationId ] = useState(parseClinic.get("municipalRegistrationId") || '')

    const [ clinicLoadingZipCode, setClinicLoadingZipCode ] = useState(false)

    const [ clinicZipCode, setClinicZipCode ] = useState(parseClinic.get("zipCode") || '')
    const [ errorClinicZipCode, setErrorClinicZipCode ] = useState(false)
    const [ helperClinicZipCode, setHelperClinicZipCode ] = useState(mandatoryField)

    const [ clinicAddressStreet, setClinicAddressStreet ] = useState(parseClinic.get("addressStreet") || '')
    const [ errorClinicAddressStreet, setErrorClinicAddressStreet ] = useState(false)
    const [ helperClinicAddressStreet, setHelperClinicAddressStreet ] = useState(mandatoryField)

    const [ clinicAddressNumber, setClinicAddressNumber ] = useState(parseClinic.get("addressNumber") || '')
    const [ errorClinicAddressNumber, setErrorClinicAddressNumber ] = useState(false)
    const [ helperClinicAddressNumber, setHelperClinicAddressNumber ] = useState(mandatoryField)

    const [ clinicAddressComplement, setClinicAddressComplement ] = useState(parseClinic.get("addressComplement") || '')

    const [ clinicNeighborhood, setClinicNeighborhood ] = useState(parseClinic.get("neighborhood") || '')
    const [ errorClinicNeighborhood, setErrorClinicNeighborhood ] = useState(false)
    const [ helperClinicNeighborhood, setHelperClinicNeighborhood ] = useState(mandatoryField)

    const [ clinicCity, setClinicCity ] = useState(parseClinic.get("city") || '')
    const [ errorClinicCity, setErrorClinicCity ] = useState(false)
    const [ helperClinicCity, setHelperClinicCity ] = useState(mandatoryField)

    const [ clinicStateId, setClinicStateId ] = useState(parseClinic.get("stateId") || '')
    const [ errorClinicStateId, setErrorClinicStateId ] = useState(false)
    const [ helperClinicStateId, setHelperClinicStateId ] = useState(mandatoryField)

    const isTelemedicineTerm = documentType === TERMO_CONSENTIMENTO_TELEMEDICINA

    const validateOnTouch = () => {
        validate()
    }

    const validate = () => {
        let isValid = true
        if (loading) {
            isValid = false
        }

        if (error?.message) {
            isValid = false
        }

        isValid = documentTermForm.current?.validate(isValid)

        isValid = patientForm.current?.validate(isValid)

        isValid = ownerForm.current?.validate(isValid)

        if (documentType) {
            switch (documentType) {
                case TERMO_OBITO:
                    isValid = deathTermForm.current?.validate(isValid)
                    break;
                case ATESTADO_SANITARIO:
                    isValid = healthTermForm.current?.validate(isValid)
                    break;
                case ATESTADO_VACINACAO:
                    isValid = vaccineTermForm.current?.validate(isValid)
                    break;
                case TERMO_AUTORIZACAO_EUTANASIA:
                    isValid = euthanasiaTermForm.current?.validate(isValid)
                    break;
                case TERMO_AUTORIZACAO_EXAMES:
                    isValid = examTermForm.current?.validate(isValid)
                    break;
                case TERMO_INTERNACAO:
                    isValid = hospitalizationTermForm.current?.validate(isValid)
                    break;
                case TERMO_AUTORIZACAO_PROCEDIMENTO_ANESTESICO:
                    isValid = anesthesiaTermForm.current?.validate(isValid)
                    break;
                case TERMO_AUTORIZACAO_PROCEDIMENTO_CIRURGICO:
                    isValid = surgeryTermForm.current?.validate(isValid)
                    break;
                case TERMO_AUTORIZACAO_PROCEDIMENTO_TERAPEUTICO:
                    isValid = riskTermForm.current?.validate(isValid)
                    break;
                case TERMO_LIBERACAO:
                    isValid = leaveTermForm.current?.validate(isValid)
                    break;
                case TERMO_CONSENTIMENTO_TELEMEDICINA:
                    isValid = telemedicineTermForm.current?.validate(isValid)
                    break;
                case TERMO_BRANCO:
                    isValid = blankTermForm.current?.validate(isValid)
                    break;
                default:

            }
        }

        return isValid
    }

    const isDocumentValid = () => {
        switch (documentType) {
            case TERMO_OBITO:
                return deathTermForm.current ? deathTermForm.current.isValid() : true
            case ATESTADO_SANITARIO:
                return healthTermForm.current ? healthTermForm.current.isValid() : true
            case ATESTADO_VACINACAO:
                return vaccineTermForm.current ? vaccineTermForm.current.isValid() : true
            case TERMO_AUTORIZACAO_EUTANASIA:
                return euthanasiaTermForm.current ? euthanasiaTermForm.current.isValid() : true
            case TERMO_AUTORIZACAO_EXAMES:
                return examTermForm.current ? examTermForm.current.isValid() : true
            case TERMO_INTERNACAO:
                return hospitalizationTermForm.current ? hospitalizationTermForm.current.isValid() : true
            case TERMO_AUTORIZACAO_PROCEDIMENTO_ANESTESICO:
                return anesthesiaTermForm.current ? anesthesiaTermForm.current.isValid() : true
            case TERMO_AUTORIZACAO_PROCEDIMENTO_CIRURGICO:
                return surgeryTermForm.current ? surgeryTermForm.current.isValid() : true
            case TERMO_AUTORIZACAO_PROCEDIMENTO_TERAPEUTICO:
                return riskTermForm.current ? riskTermForm.current.isValid() : true
            case TERMO_LIBERACAO:
                return leaveTermForm.current ? leaveTermForm.current.isValid() : true
            case TERMO_CONSENTIMENTO_TELEMEDICINA:
                return telemedicineTermForm.current ? telemedicineTermForm.current.isValid() : true
            case TERMO_BRANCO:
                return blankTermForm.current ? blankTermForm.current.isValid() : true
            default: 
                return true
        }
    }

    const checkIsValid = () => !loading 
        && (documentTermForm.current ? documentTermForm.current.isValid() : true) 
        && (patientForm.current ? patientForm.current.isValid() : true)
        && (ownerForm.current ? ownerForm.current.isValid() : true) 
        && isDocumentValid()

    const fieldsWithErrorText = () => {
        let errors = []
        if (loading) {
            errors.push("Salvando o atestado/termo")
        }
        if (error?.message) {
            errors.push(error.message)
        }
        errors = documentTermForm.current ? documentTermForm.current.fieldsWithErrorText(errors) : errors
        errors = patientForm.current ? patientForm.current.fieldsWithErrorText(errors) : errors
        errors = ownerForm.current ? ownerForm.current.fieldsWithErrorText(errors) : errors
        errors = deathTermForm.current ? deathTermForm.current.fieldsWithErrorText(errors) : errors
        errors = healthTermForm.current ? healthTermForm.current.fieldsWithErrorText(errors) : errors
        errors = vaccineTermForm.current ? vaccineTermForm.current.fieldsWithErrorText(errors) : errors
        errors = euthanasiaTermForm.current ? euthanasiaTermForm.current.fieldsWithErrorText(errors) : errors
        errors = examTermForm.current ? examTermForm.current.fieldsWithErrorText(errors) : errors
        errors = hospitalizationTermForm.current ? hospitalizationTermForm.current.fieldsWithErrorText(errors) : errors
        errors = anesthesiaTermForm.current ? anesthesiaTermForm.current.fieldsWithErrorText(errors) : errors
        errors = surgeryTermForm.current ? surgeryTermForm.current.fieldsWithErrorText(errors) : errors
        errors = riskTermForm.current ? riskTermForm.current.fieldsWithErrorText(errors) : errors
        errors = leaveTermForm.current ? leaveTermForm.current.fieldsWithErrorText(errors) : errors
        errors = telemedicineTermForm.current ? telemedicineTermForm.current.fieldsWithErrorText(errors) : errors
        errors = blankTermForm.current ? blankTermForm.current.fieldsWithErrorText(errors) : errors
        if (!permissions?.check(ACL.TERM, ACL.Rule.EDIT, term)) {
            errors.push("Acesso negado")
        }
        if (!canEdit) {
            errors = ["Somente Médicos Veterinários com CRMV podem registrar atestados e termos."]
        }
        if (errors && errors.length) {
            return errors
        }
    }

    const getPatientOrError = () => {
        setLoading(true)
        setError(null)

        getPatient(id, clinic.object, ["clinic", "owner"])
        .then(_patient => {
            setPatient(_patient)
            setOwner(_patient.get("owner"))
        })
        .catch(error => {
            Swal.fire(
                'Desculpe',
                error,
                'error'
            )
            .then(_ => {
                setLoading(false)
                setError({
                    message: error,
                    function: "getPatientOrError"
                })
            })
        })
    }

    const getTermOrError = () => {
        if (permissions?.check(ACL.TERM, ACL.Rule.VIEW)) {
            saveOnTracker("Iniciou", "Atestado ou Termo", update ? (id_termo || id_documento) : "")
            if (update) {
                setLoading(true)
                setError(null)

                getTerm(id_termo || id_documento, patient)
                .then(_term => {
                    setTerm(_term)
                    setCity(_term.get("city"))
                    setDocumentDate(_term.get("documentDate"))
                    setDocumentType(_term.get("documentType"))
                    setEventLocation(_term.get("eventLocation") || eventLocation)
                    setEventDate(_term.get("eventDate") || eventDate)
                    setEventTime(_term.get("eventTime") || eventTime)
                    setEventDetail(_term.get("eventDetail"))
                    setEventNotes(_term.get("eventNotes"))
                    setAuthNecropsy(_term.get("authNecropsy"))
                    setAuthStudy(_term.get("authStudy"))
                    setAuthRemoveByCityHall(_term.get("authRemoveByCityHall"))
                    setAuthRemoveByMeImmediately(_term.get("authRemoveByMeImmediately"))
                    setAuthRemoveByMe(_term.get("authRemoveByMe"))
                    setAuthRemoveByMeDays(_term.get("authRemoveByMeDays"))
                    setAuthRemoveByOtherImmediately(_term.get("authRemoveByOtherImmediately"))
                    setAuthRemoveByOther(_term.get("authRemoveByOther"))
                    setAuthRemoveByOtherDays(_term.get("authRemoveByOtherDays"))
                    setAuthName(_term.get("authName"))
                    setAuthCpf(_term.get("authCpf"))
                    setDocumentSubType(_term.get("documentSubType") || documentSubType)
                    setOtherInfo(_term.get("otherInfo"))
                    setVaccines(_term.get("vaccines") || vaccines)
                    setItems(_term.get("items") || items)
                    setVaccineNotes(_term.get("vaccineNotes"))
                    setDewormings(_term.get("dewormings") || dewormings)
                    setDewormingNotes(_term.get("dewormingNotes"))
                    setProcedures(_term.get("procedures") || procedures)
                    setExams(_term.get("exams") || exams)
                    setDisease(_term.get("disease"))
                    setNotes(_term.get("notes"))
                    setDocumentName(_term.get("documentName"))
                    setSignedBy(_term.get("signedBy"))
                    setVets(_term.get("vets"))
                    setVetName(_term.get("vetName"))
                    setVetCrmv(_term.get("vetCrmv"))
                    setVetCrmvStateId(_term.get("vetCrmvStateId"))
                    setHospitalizationDays(_term.get("hospitalizationDays"))
                    setHospitalizationServices(_term.get("hospitalizationServices"))
                    setProcedure(_term.get("procedure"))
                    setMaxDaysAfterDischarge(_term.get("maxDaysAfterDischarge"))
                    setLoading(false)
                })
                .catch(error => {
                    Swal.fire(
                        'Desculpe',
                        error,
                        'error'
                    )
                    .then(_ => {
                        setLoading(false)
                        setError({
                            message: error,
                            function: "getTermOrError"
                        })
                    })
                })
            } else {
                let newTerm = new Parse.Object("Term")
                newTerm.setACL(clinic.ACL)
                newTerm.set("clinic", clinic.object)
                newTerm.set("createdBy", Parse.User.current())
                newTerm.set("createdApp", APP_NAME)
                newTerm.set("createdDevice", APP_DEVICE)
                newTerm.set("createdAppVersion", APP_VERSION)
                newTerm.set("isDeleted", false)
                newTerm.set("patient", patient)
                setTerm(newTerm)
                setLoading(false)
            }
        }
    }

    const savePatient = () => {
        return new Promise((resolve, reject) => {
            let hasChanged = false
            if (patient.get("breed") != breed ||
                patient.get("gender") != gender ||
                JSON.stringify(patient.get("birthdate")) != JSON.stringify(birthdate) ||
                patient.get("pelage") != pelage ||
                patient.get("size") != size ||
                patient.get("rga") != rga ||
                patient.get("microchip") != microchip ||
                patient.get("otherInfo") != patientOtherInfo) {
                    
                hasChanged = true
            }
            if (hasChanged) {
                Parse.Cloud.run("savePatientForOwner", {
                    data: {
                        objectId: patient.id,
                        breed: breed,
                        gender: gender,
                        birthdate: birthdate,
                        pelage: pelage,
                        size: size,
                        rga: rga,
                        microchip: microchip,
                        otherInfo: patientOtherInfo
                    },
                    clinicId: clinic.objectId,
                    ownerId: owner.id,
                    appVersionName: APP_VERSION,
                    deviceType: APP_DEVICE,
                    appContentType: APP_NAME
                })
                // patient.save()
                .then(_ => {
                    resolve(patient)
                })
                .catch(error => {
                    console.error(error)
                    reject("Ocorreu algum erro ao tentar editar as informações do animal")
                })
            } else {
                resolve(patient)
            }
        })
    }

    const saveOwner = () => {
        return new Promise((resolve, reject) => {
            let hasChanged = false
            if (owner.get("cpf") != cpf || 
                owner.get("zipCode") != zipCode ||
                owner.get("addressStreet") != address ||
                owner.get("addressNumber") != addressNumber ||
                owner.get("addressComplement") != addressComplement ||
                owner.get("neighborhood") != neighborhood ||
                owner.get("city") != ownerCity ||
                owner.get("stateId") != state ||
                owner.get("email") != ownerEmail ||
                owner.get("phone") != ownerPhone) {

                hasChanged = true
            }

            if (hasChanged) {
                Parse.Cloud.run("saveOwnerForClinic", {
                    data: {
                        objectId: owner.id,
                        cpf: cpf,
                        zipCode: zipCode,
                        addressStreet: address,
                        addressNumber: addressNumber,
                        addressComplement: addressComplement,
                        neighborhood: neighborhood,
                        city: ownerCity,
                        stateId: state,
                        email: ownerEmail,
                        phone: ownerPhone
                    },
                    clinicId: clinic.id || clinic.objectId,
                    appVersionName: APP_VERSION,
                    deviceType: APP_DEVICE,
                    appContentType: APP_NAME
                })
                // owner.save()
                .then(_ => {
                    resolve(owner)
                })
                .catch(error => {
                    console.error(error)
                    reject("Ocorreu algum erro ao tentar editar as informações do(a) tutor(a)")
                })
            } else {
                resolve(owner)
            }
        })
    }

    const saveVaccinesIfNecessary = () => {
        return new Promise((resolve, reject) => {
            const isVaccines = [ ATESTADO_SANITARIO, ATESTADO_VACINACAO ].indexOf(documentType) != -1 && includeVaccines && vaccines.filter(v => v.documentDate).length > 0
            let vaccinesSaved = []
            if (isVaccines) {
                vaccines.map(vaccine => {
                    vaccinesSaved.push(
                        new Promise((resolve, reject) => {
                            if (vaccine.id) {
                                let queryVaccine = new Parse.Query("Vaccine");
                                queryVaccine.equalTo("clinic", clinic.object);
                                queryVaccine.equalTo("isDeleted", false);
                                queryVaccine.equalTo("patient", patient)
                                queryVaccine.get(vaccine.id)
                                .then(objVaccine => {
                                    if (objVaccine) {
                                        objVaccine.set("vaccineType", vaccine.vaccineType)
                                        objVaccine.set("vaccineId", vaccine.vaccineId)
                                        objVaccine.set("vaccine", vaccine.vaccine)
                                        objVaccine.set("companyName", vaccine.companyName)
                                        objVaccine.set("manufacturingBatchPart", vaccine.manufacturingBatchPart)
                                        objVaccine.set("manufacturingDate", vaccine.manufacturingDate)
                                        objVaccine.set("expirationDate", vaccine.expirationDate)
                                        objVaccine.set("documentDate", vaccine.documentDate)
                                        objVaccine.set("changedAt", new Date())
                                        objVaccine.set("changedBy", Parse.User.current())
                                        objVaccine.set("changedApp", APP_NAME)
                                        objVaccine.set("changedDevice", APP_DEVICE)
                                        objVaccine.set("changedAppVersion", APP_VERSION)
                                        objVaccine.save()
                                        .then(_ => {
                                            return resolve(vaccine)
                                        })
                                        .catch(error => {
                                            console.error(error)
                                            objVaccine.revert()
                                            return reject("Ocorreu algum erro ao tentar editar o registro de aplicação de vacina")
                                        })
                                    } else {
                                        console.error(objVaccine)
                                        return reject("Registro de aplicação de vacina não encontrado")
                                    }
                                })
                                .catch(error => {
                                    console.error(error)
                                    return reject("Não foi possível encontrar o registro de aplicação de vacina")
                                })
                            } else {
                                let vaccineToSave = new Parse.Object("Vaccine")
                                vaccineToSave.setACL(clinic.ACL)
                                vaccineToSave.set("clinic", clinic.object)
                                vaccineToSave.set("createdBy", Parse.User.current())
                                vaccineToSave.set("createdApp", APP_NAME)
                                vaccineToSave.set("createdDevice", APP_DEVICE)
                                vaccineToSave.set("createdAppVersion", APP_VERSION)
                                vaccineToSave.set("isDeleted", false)
                                vaccineToSave.set("patient", patient)
                                vaccineToSave.set("vaccineType", vaccine.vaccineType)
                                vaccineToSave.set("vaccineId", vaccine.vaccineId)
                                vaccineToSave.set("vaccine", vaccine.vaccine)
                                vaccineToSave.set("companyName", vaccine.companyName)
                                vaccineToSave.set("manufacturingBatchPart", vaccine.manufacturingBatchPart)
                                vaccineToSave.set("manufacturingDate", vaccine.manufacturingDate)
                                vaccineToSave.set("expirationDate", vaccine.expirationDate)
                                vaccineToSave.set("documentDate", vaccine.documentDate)
                                vaccineToSave.set("changedAt", new Date())
                                vaccineToSave.set("changedBy", Parse.User.current())
                                vaccineToSave.set("changedApp", APP_NAME)
                                vaccineToSave.set("changedDevice", APP_DEVICE)
                                vaccineToSave.set("changedAppVersion", APP_VERSION)
                                vaccineToSave.save()
                                .then(objVaccine => {
                                    let _vaccine = Object.assign(
                                        {},
                                        { ...vaccine },
                                        { id: objVaccine.id }
                                    )
                                    return resolve(_vaccine)
                                })
                                .catch(error => {
                                    console.error(error)
                                    vaccineToSave.revert()
                                    return reject("Ocorreu algum erro ao tentar cadastrar a aplicação de vacina")
                                })
                            }
                        })
                    )
                })
                
                return Promise.all(vaccinesSaved)
                .then(_vaccines => {
                    resolve(_vaccines)
                })
                .catch(error => {
                    console.error(error)
                    reject(error)
                })
            } else {
                resolve()
            }
        })
    }

    const saveClinicIfNecessary = () => {
        return new Promise((resolve, reject) => {
            const useClinicData = "1"
            if (clinic.isOwner && documentSubType === useClinicData) {
                parseClinic.set("companyName", clinicCompanyName)
                parseClinic.set("cnpj", clinicCnpj)
                parseClinic.set("crmv", clinicCrmv)
                parseClinic.set("crmvStateId", clinicCrmvStateId)
                parseClinic.set("registrationMAPA", clinicRegistrationMapa)
                parseClinic.set("stateRegistrationId", clinicStateRegistrationId)
                parseClinic.set("municipalRegistrationId", clinicMunicipalRegistrationId)
                parseClinic.set("zipCode", clinicZipCode)
                parseClinic.set("addressStreet", clinicAddressStreet)
                parseClinic.set("addressNumber", clinicAddressNumber)
                parseClinic.set("addressComplement", clinicAddressComplement)
                parseClinic.set("neighborhood", clinicNeighborhood)
                parseClinic.set("city", clinicCity)
                parseClinic.set("stateId", clinicStateId)
                parseClinic.set("changedAt", new Date())
                parseClinic.set("changedBy", Parse.User.current())
                parseClinic.set("changedApp", APP_NAME)
                parseClinic.set("changedDevice", APP_DEVICE)
                parseClinic.set("changedAppVersion", APP_VERSION)
                parseClinic.save()
                .then(_clinic => {
                    resolve(_clinic)
                })
                .catch(error => {
                    console.error(error)
                    parseClinic.revert()
                    reject("Ocorreu algum erro ao tentar editar as informações da clínica")
                })
            } else {
                resolve()
            }
        })
    }

    const saveUserAndClinicIfNecessary = () => {
        return new Promise((resolve, reject) => {
            if (isTelemedicineTerm) {
                saveClinicIfNecessary()
                .then(_ => {
                    parseUser.set("fullName", userName)
                    parseUser.set("crmv", userCrmv)
                    parseUser.set("crmvStateId", userCrmvStateId)
                    parseUser.set("contactEmail", userContactEmail)
                    parseUser.set("contactPhone", userContactPhone)
                    const useUserData = "0"
                    if (documentSubType == useUserData) {
                        parseUser.set("businessZipCode", userZipCode)
                        parseUser.set("businessAddressStreet", userAddressStreet)
                        parseUser.set("businessAddressNumber", userAddressNumber)
                        parseUser.set("businessAddressComplement", userAddressComplement)
                        parseUser.set("businessNeighborhood", userNeighborhood)
                        parseUser.set("businessCity", userCity)
                        parseUser.set("businessStateId", userStateId)
                    }
                    parseUser.save()
                    .then(_user => {
                        resolve(_user)
                    })
                    .catch(error => {
                        console.error(error)
                        reject("Ocorreu algum erro ao tentar editar as suas informações da conta Vet Smart")
                    })
                })
                .catch(error => {
                    reject(error)
                })
            } else {
                resolve()
            }
        })
    }

    const save = () => {
        return new Promise((resolve, reject) => {
            setHasSubmited(true)
            if (validate()) {
                setLoading(true)

                savePatient()
                .then(_patient => {
                    saveOwner()
                    .then(_owner => {
                        saveVaccinesIfNecessary()
                        .then(_vaccines => {
                            saveUserAndClinicIfNecessary()
                            .then(_user => {
                                const isVaccines = _vaccines && _vaccines[0] !== undefined ? _vaccines : undefined
                                const isItems = [ TERMO_BRANCO ].indexOf(documentType) != -1 && items && items.filter(i => i && (i.title || i.description)).length > 0
                                const isDewormings = [ ATESTADO_SANITARIO ].indexOf(documentType) != -1 && includeDewormings && dewormings.filter(d => d.documentDate).length > 0
                                const isExams = [ CARTA_ENCAMINHAMENTO, TERMO_AUTORIZACAO_EXAMES ].indexOf(documentType) != -1 && exams.filter(e => e.exam).length > 0
                                const isProcedures = [ CARTA_ENCAMINHAMENTO, TERMO_AUTORIZACAO_PROCEDIMENTO_CIRURGICO, TERMO_AUTORIZACAO_PROCEDIMENTO_TERAPEUTICO ].indexOf(documentType) != -1 && procedures.filter(p => p.procedure || p.surgery).length > 0
                                const isVets = vets && vets.filter(v => v.name && v.crmv && v.crmvStateId).length > 0
                                term.set("city", city)
                                term.set("documentDate", documentDate)
                                term.set("documentType", documentType)
                                term.set("changedAt", new Date())
                                term.set("changedBy", Parse.User.current())
                                term.set("changedApp", APP_NAME)
                                term.set("changedDevice", APP_DEVICE)
                                term.set("changedAppVersion", APP_VERSION)
                                if (!isNaN(parseInt(documentSubType))) {
                                    term.set("documentSubType", parseInt(documentSubType))
                                } else {
                                    term.unset("documentSubType")
                                }
                                if (eventLocation) {
                                    term.set("eventLocation", eventLocation)
                                } else {
                                    term.unset("eventLocation")
                                }
                                if (eventDate) {
                                    term.set("eventDate", eventDate)
                                } else {
                                    term.unset("eventDate")
                                }
                                if (eventTime) {
                                    term.set("eventTime", eventTime)
                                } else {
                                    term.unset("eventTime")
                                }
                                if (eventDetail) {
                                    term.set("eventDetail", eventDetail)
                                } else {
                                    term.unset("eventDetail")
                                }
                                if (eventNotes) {
                                    term.set("eventNotes", eventNotes)
                                } else {
                                    term.unset("eventNotes")
                                }
                                if (authNecropsy) {
                                    term.set("authNecropsy", authNecropsy)
                                } else {
                                    term.unset("authNecropsy")
                                }
                                if (authStudy) {
                                    term.set("authStudy", authStudy)
                                } else {
                                    term.unset("authStudy")
                                }
                                if (authRemoveByCityHall) {
                                    term.set("authRemoveByCityHall", authRemoveByCityHall)
                                } else {
                                    term.unset("authRemoveByCityHall")
                                }
                                if (authRemoveByMeImmediately) {
                                    term.set("authRemoveByMeImmediately", authRemoveByMeImmediately)
                                } else {
                                    term.unset("authRemoveByMeImmediately")
                                }
                                if (authRemoveByMe) {
                                    term.set("authRemoveByMe", authRemoveByMe)
                                } else {
                                    term.unset("authRemoveByMe")
                                }
                                if (!isNaN(parseInt(authRemoveByMeDays))) {
                                    term.set("authRemoveByMeDays", parseInt(authRemoveByMeDays))
                                } else {
                                    term.unset("authRemoveByMeDays")
                                }
                                if (authRemoveByOtherImmediately) {
                                    term.set("authRemoveByOtherImmediately", authRemoveByOtherImmediately)
                                } else {
                                    term.unset("authRemoveByOtherImmediately")
                                }
                                if (authRemoveByOther) {
                                    term.set("authRemoveByOther", authRemoveByOther)
                                } else {
                                    term.unset("authRemoveByOther")
                                }
                                if (!isNaN(parseInt(authRemoveByOtherDays))) {
                                    term.set("authRemoveByOtherDays", parseInt(authRemoveByOtherDays))
                                } else {
                                    term.unset("authRemoveByOtherDays")
                                }
                                if (authName) {
                                    term.set("authName", authName)
                                } else {
                                    term.unset("authName")
                                }
                                if (authCpf) {
                                    term.set("authCpf", authCpf)
                                } else {
                                    term.unset("authCpf")
                                }
                                if (otherInfo) {
                                    term.set("otherInfo", otherInfo)
                                } else {
                                    term.unset("otherInfo")
                                }
                                if (isVaccines && vaccineNotes) {
                                    term.set("vaccineNotes", vaccineNotes)
                                } else {
                                    term.unset("vaccineNotes")
                                }
                                if (isVaccines) {
                                    term.set("vaccines", isVaccines)
                                } else {
                                    term.unset("vaccines")
                                }
                                if (isItems) {
                                    term.set("items", items)
                                } else {
                                    term.unset("items")
                                }
                                if (isDewormings && dewormingNotes) {
                                    term.set("dewormingNotes", dewormingNotes)
                                } else {
                                    term.unset("dewormingNotes")
                                }
                                if (isDewormings) {
                                    term.set("dewormings", dewormings)
                                } else {
                                    term.unset("dewormings")
                                }
                                if (isProcedures) {
                                    term.set("procedures", procedures)
                                } else {
                                    term.unset("procedures")
                                }
                                if (isExams) {
                                    term.set("exams", exams)
                                } else {
                                    term.unset("exams")
                                }
                                if (disease) {
                                    term.set("disease", disease)
                                } else {
                                    term.unset("disease")
                                }
                                if (notes) {
                                    term.set("notes", notes)
                                } else {
                                    term.unset("notes")
                                }
                                if (documentName) {
                                    term.set("documentName", documentName)
                                } else {
                                    term.unset("documentName")
                                }
                                if (signedBy) {
                                    term.set("signedBy", signedBy)
                                } else {
                                    term.unset("signedBy")
                                }
                                if (isVets) {
                                    term.set("vets", vets)
                                } else {
                                    term.unset("vets")
                                }
                                if (vetName) {
                                    term.set("vetName", vetName)
                                } else {
                                    term.unset("vetName")
                                }
                                if (vetCrmv) {
                                    term.set("vetCrmv", vetCrmv)
                                } else {
                                    term.unset("vetCrmv")
                                }
                                if (vetCrmvStateId) {
                                    term.set("vetCrmvStateId", vetCrmvStateId)
                                } else {
                                    term.unset("vetCrmvStateId")
                                }
                                if (hospitalizationDays) {
                                    term.set("hospitalizationDays", hospitalizationDays)
                                } else {
                                    term.unset("hospitalizationDays")
                                }
                                if (hospitalizationServices) {
                                    term.set("hospitalizationServices", hospitalizationServices)
                                } else {
                                    term.unset("hospitalizationServices")
                                }
                                if (procedure) {
                                    term.set("procedure", procedure)
                                } else {
                                    term.unset("procedure")
                                }
                                if (!isNaN(parseInt(maxDaysAfterDischarge))) {
                                    term.set("maxDaysAfterDischarge", parseInt(maxDaysAfterDischarge))
                                } else {
                                    term.unset("maxDaysAfterDischarge")
                                }
                                term.save()
                                .then(_term => {
                                    saveOnTracker("Concluiu", "Atestado ou Termo", _term.id)
                                    setLoading(false)
                                    // history.push(`/documento/atestado_termo/${_term.id}`)
                                    resolve(_term)
                                })
                                .catch(error => {
                                    setLoading(false)
                                    console.error(error)
                                    Swal.fire(
                                        'Desculpe',
                                        `Ocorreu algum erro ao tentar ${term.id ? "editar" : "cadastrar"} o atestado/termo do animal`,
                                        'error'
                                    )
                                    update && term.revert()
                                    reject()
                                })
                            })
                            .catch(error => {
                                setLoading(false)
                                console.error(error)
                                Swal.fire(
                                    'Desculpe',
                                    error,
                                    'error'
                                )
                            })
                        })
                        .catch(error => {
                            setLoading(false)
                            console.error(error)
                            Swal.fire(
                                'Desculpe',
                                error,
                                'error'
                            )
                        })
                    })
                    .catch(error => {
                        setLoading(false)
                        console.error(error)
                        Swal.fire(
                            'Desculpe',
                            error,
                            'error'
                        )
                    })
                })
                .catch(error => {
                    setLoading(false)
                    console.error(error)
                    Swal.fire(
                        'Desculpe',
                        error,
                        'error'
                    )
                })
            } else {
                reject()
            }
        })
    }

    useEffect(() => {
        validateOnTouch()
        setIsValid(checkIsValid())
        setErrorMsg(fieldsWithErrorText())
    }, [ 
        hasSubmited, error,
        documentDate, errorDocumentDate, city, errorCity, documentType, errorDocumentType, 
        breed, errorBreed, gender, errorGender, birthdate, errorBirthdate, 
        size, rga, microchip, gender, patientOtherInfo,
        cpf, errorCpf, zipCode, errorZipCode, address, errorAddress, addressNumber, errorAddressNumber, neighborhood, errorNeighborhood, ownerCity, errorOwnerCity, state, errorState, ownerEmail, errorOwnerEmail, ownerPhone, errorOwnerPhone,
        userName, errorUserName, userCrmv, errorUserCrmv, userCrmvStateId, errorUserCrmvStateId, userContactEmail, errorUserContactEmail, userContactPhone, errorUserContactPhone, 
        userZipCode, errorUserZipCode, userAddressStreet, errorUserAddressStreet, userAddressNumber, errorUserAddressNumber, userNeighborhood, errorUserNeighborhood, userCity, errorUserCity, userStateId, errorUserStateId,
        clinicCompanyName, errorClinicCompanyName, clinicCnpj, errorClinicCnpj, clinicCrmv, errorClinicCrmv, clinicCrmvStateId, errorClinicCrmvStateId, clinicRegistrationMapa, errorClinicRegistrationMapa, clinicStateRegistrationId, clinicMunicipalRegistrationId, 
        clinicZipCode, errorClinicZipCode, clinicAddressStreet, errorClinicAddressStreet, clinicAddressNumber, errorClinicAddressNumber, clinicNeighborhood, errorClinicNeighborhood, clinicCity, errorClinicCity, clinicStateId, errorClinicStateId,
        eventLocation, errorEventLocation, eventDate, errorEventDate, eventDetail, eventNotes, authNecropsy, authStudy, authRemoveByCityHall, authRemoveByMeImmediately, authRemoveByMe, authRemoveByMeDays, authRemoveByOtherImmediately, authRemoveByOther, authRemoveByOtherDays, authCpf, errorAuthCpf, authName, errorAuthName,
        notes, documentName, errorDocumentName, signedBy, errorSignedBy, vaccineNotes, dewormingNotes, includeVaccines, includeDewormings,
        documentSubType, otherInfo, disease,
        vetName, vetCrmv, vetCrmvStateId,
        maxDaysAfterDischarge, errorMaxDaysAfterDischarge, hospitalizationDays, hospitalizationServices, errorHospitalizationServices,
        procedure,
        JSON.stringify(vaccines && vaccines.length && vaccines.map(vaccine => ({ vaccineType: vaccine.vaccineType, manufacturingBatchPart: vaccine.manufacturingBatchPart, manufacturingDate: vaccine.manufacturingDate, expirationDate: vaccine.expirationDate, documentDate: vaccine.documentDate }))),
        JSON.stringify(items && items.length && items.map(item => ({ title: item.title, description: item.description }))),
        JSON.stringify(dewormings && dewormings.length && dewormings.map(deworming => ({ productName: deworming.productName, documentDate: deworming.documentDate }))),
        JSON.stringify(procedures && procedures.length && procedures.map(procedure => ({ procedure: procedure.procedure, surgery: procedure.surgery, notes: procedure.notes }))),
        JSON.stringify(exams && exams.length && exams.map(exam => ({ exam: exam.exam, notes: exam.notes }))),
        JSON.stringify(vets && vets.length && vets.map(vet => ({ name: vet.name, crmv: vet.crmv, crmvStateId: vet.crmvStateId })))
    ])

    useEffect(() => {
        if (term && !permissions?.check(ACL.TERM, ACL.Rule.EDIT, term)) {
            permissions?.error()
        }
    }, [ term ])

    useEffect(() => {
        if (patient) {
            getTermOrError()
        }
    }, [ patient ])

    useEffect(() => {
        if (id) {
            getPatientOrError()
        }
    }, [])

    const showDestinationText = () => {
        let destinationText = []
        if (authNecropsy) {
            destinationText.push("Necropsia")
        }
        if (authStudy) {
            destinationText.push("Doação para fins de ensino e pesquisa")
        }
        if (authRemoveByCityHall) {
            destinationText.push("Remoção pela prefeitura")
        }
        if (authRemoveByMeImmediately) {
            destinationText.push("Remoção imediata pela minha pessoa")
        }
        if (authRemoveByMe && authRemoveByMeDays) {
            destinationText.push(`Remoção pela minha pessoa em ${ authRemoveByMeDays } dia${ authRemoveByMeDays == 1 ? "" : "s" }`)
        }
        if (authRemoveByOtherImmediately) {
            destinationText.push("Remoção imediata por pessoa de minha indicação")
        }
        if (authRemoveByOther && authRemoveByOtherDays) {
            destinationText.push(`Remoção pela pessoa de minha indicação em ${ authRemoveByOtherDays } dia${ authRemoveByOtherDays == 1 ? "" : "s" }`)
        }
        if (destinationText.length) {
            return stringifyArray(destinationText)
        } else {
            return ""
        }
    }

    const showMoreInfosAboutPatient = () => {
        let moreInfos = []
        if (pelage) {
            moreInfos.push(`Pelagem ${pelage}`)
        }
        if (size) {
            moreInfos.push(`Porte ${size}`)
        }
        if (rga) {
            moreInfos.push(`RGA ${rga}`)
        }
        if (microchip) {
            moreInfos.push(`Microchip ${microchip}`)
        }
        if (moreInfos.length) {
            return `${ stringifyArray(moreInfos) }${ patientOtherInfo ? `. ${patientOtherInfo}` : '' }`
        } else {
            return patientOtherInfo || ""
        }
    }

    const renderPreview = () => {
        return (
            <div className="col-12">
                <div className="row">
                    <div className="col">
                        <h4 className="mb-2">Prévia do Documento</h4>
                    </div>
                </div>
                <div className="info mb-3">
                    Todos os documentos seguem os padrões estipulados pelo 
                    CFMV na Resolução 1321 de 24 de Abril de 2020
                </div>
                { patient && (
                    <>
                        <div className="row">
                            <div className="col">
                                <h5 className="mb-2">Dados do Animal</h5>
                            </div>
                        </div>
                        <p className="mb-4">
                            <b>Nome:</b> { patient.get("name") }<br />
                            <b>Espécie:</b> { patient.get("specie") }<br />
                            <b>Raça:</b> { breed || (<span className="text-red">Não informado (dado obrigatório, preencha ao lado)</span>) }<br />
                            <b>Sexo:</b> { gender || (<span className="text-red">Não informado (dado obrigatório, preencha ao lado)</span>) }<br />
                            <b>Idade:</b> { getAge(birthdate, true) || (<span className="text-red">Não informado (dado obrigatório, preencha ao lado)</span>) }
                        </p>
                    </>
                )}
                { (pelage || size || rga || microchip || patientOtherInfo) && (
                    <>
                        <div className="row">
                            <div className="col">
                                <h5 className="mb-2">Identificação Complementar do Animal</h5>
                            </div>
                        </div>
                        <p className="mb-4">
                            { showMoreInfosAboutPatient() }
                            { patientOtherInfo }
                        </p>
                    </>
                ) }
                { owner && (
                    <>
                        <div className="row">
                            <div className="col">
                                <h5 className="mb-2">Identificação do(a) Tutor(a)</h5>
                            </div>
                        </div>
                        <p className="mb-4">
                            <b>Nome:</b> { owner.get("name") }<br />
                            <b>CPF:</b> { validateCPF(cpf) ? cpf : (<span className="text-red">Não informado (dado obrigatório, preencha ao lado)</span>) }<br />
                            { (isTelemedicineTerm || ownerEmail) && (
                                <>
                                    <b>E-mail:</b> { validateEmail(ownerEmail) ? ownerEmail : (<span className="text-red">Não informado (dado obrigatório, preencha ao lado)</span>) }<br />
                                </>
                            )}
                            { (isTelemedicineTerm || ownerPhone) && (
                                <>
                                    <b>Celular:</b> { validatePhone(ownerPhone) ? ownerPhone : (<span className="text-red">Não informado (dado obrigatório, preencha ao lado)</span>) }<br />
                                </>
                            )}
                            <b>Endereço:</b>&nbsp;
                            { address && addressNumber && neighborhood && ownerCity && state && validatePostalCode(zipCode) ? (
                                formattedAddress({
                                    address: address,
                                    number: addressNumber,
                                    complement: addressComplement,
                                    neighborhood: neighborhood,
                                    city: ownerCity,
                                    state: state,
                                    zipCode: zipCode
                                })
                            ) : (
                                <span className="text-red">Não informado (dados obrigatórios, preencha ao lado)</span>
                            )}
                        </p>
                    </>
                )}
                { documentType && (
                    <>
                        { documentType == TERMO_OBITO && (
                            <>
                                <p className="term">
                                    Atesto para os devidos fins que o animal identificado 
                                    neste documento veio a óbito em <b>{ eventLocation }</b>, 
                                    às <b>{ eventTime ? eventTime.replace(":", "h") : "" }</b> do 
                                    dia <b>{ eventDate }</b>
                                    { eventDetail && (
                                        <>, sendo a provável causa mortis <b>{ eventDetail }</b></>
                                    )}.
                                </p>
                                { eventNotes && (
                                    <>
                                        <div className="row">
                                            <div className="col">
                                                <h5 className="mb-2">Observações e informações complementares sobre a causa mortis</h5>
                                            </div>
                                        </div>
                                        <p className="mb-4">
                                            { eventNotes }
                                        </p>
                                    </>
                                )}
                                { (authNecropsy || authStudy || authRemoveByCityHall || authRemoveByMeImmediately || (authRemoveByMe && authRemoveByMeDays) || authRemoveByOtherImmediately || (authRemoveByOther && authRemoveByOtherDays)) && (
                                    <>
                                        <div className="row">
                                            <div className="col">
                                                <h5 className="mb-2">Orientações para destinação do corpo do animal</h5>
                                            </div>
                                        </div>
                                        <p className="mb-4">
                                            { showDestinationText() } 
                                        </p>
                                    </>
                                )}
                                { (authRemoveByCityHall || authRemoveByMeImmediately || (authRemoveByMe && authRemoveByMeDays) || authRemoveByOtherImmediately || (authRemoveByOther && authRemoveByOtherDays)) && (
                                    <>
                                        <div className="row">
                                            <div className="col">
                                                <h5 className="mb-2">Responsável pela remoção do corpo do animal</h5>
                                            </div>
                                        </div>
                                        <p className="mb-4">
                                            { authRemoveByCityHall ? (
                                                "Prefeitura"
                                            ) : (
                                                (authRemoveByMeImmediately || (authRemoveByMe && authRemoveByMeDays)) ? (
                                                    `${owner.get("name")} - ${owner.get("cpf")}`
                                                ) : (
                                                    (authRemoveByOtherImmediately || (authRemoveByOther && authRemoveByOtherDays)) && (
                                                        `${ authName || "Nome do responsável" } - ${ authCpf || "CPF do responsável" }`
                                                    )
                                                )
                                            )}
                                        </p>
                                    </>
                                )}
                            </>
                        )}
                        { documentType == ATESTADO_SANITARIO && (
                            <>
                                <p className="term">
                                    Atesto para os devidos fins que foi por mim examinado nesta data o 
                                    animal identificado neste documento, o qual apresentou bom estado 
                                    geral de saúde durante o exame clínico, e que se encontram atendidas 
                                    as medidas sanitárias definidas pelo(s) Serviço(s) Médico-Veterinário(s) 
                                    Oficial(is)
                                </p>
                                { includeVaccines && vaccines && vaccines.length > 0 && (
                                    <>
                                        <div className="row">
                                            <div className="col">
                                                <h5 className="mb-2">{ vaccines.length == 1 ? "Vacinação" : "Vacinações" }</h5>
                                            </div>
                                        </div>
                                        <p className="mb-3">
                                            { vaccines.map((vaccine, index) => (
                                                <span className="mb-3 d-block" key={index}>
                                                    { vaccine.vaccineType && (<><b>{ vaccine.vaccineType }</b><br/></>)}
                                                    { vaccine.vaccine && (<><b>Nome do Produto:</b> { vaccine.vaccine }<br/></>)}
                                                    { vaccine.companyName && (<><b>Fabricante:</b> { vaccine.companyName }<br/></>)}
                                                    <b>Número da Partida:</b> { vaccine.manufacturingBatchPart?.length >= 3 ? vaccine.manufacturingBatchPart : (<span className="text-red">Não informado (dado obrigatório, preencha ao lado)</span>) }<br/>
                                                    <b>Data de Fabricação:</b> { vaccine.manufacturingDate ? dayjs(vaccine.manufacturingDate).format("MM/YYYY") : (<span className="text-red">Não informado (dado obrigatório, preencha ao lado)</span>) }<br/>
                                                    <b>Data de Validade:</b> { vaccine.expirationDate ? dayjs(vaccine.expirationDate).format("MM/YYYY") : (<span className="text-red">Não informado (dado obrigatório, preencha ao lado)</span>) }<br/>
                                                    <b>Data de Validade:</b> { vaccine.documentDate ? dayjs(vaccine.documentDate).format("DD/MM/YYYY") : (<span className="text-red">Não informado (dado obrigatório, preencha ao lado)</span>) }
                                                </span>
                                            )) }
                                        </p>
                                        { vaccineNotes && (
                                            <p className="mb-3">
                                                { vaccineNotes }
                                            </p>
                                        )}
                                    </>
                                )}
                                { includeDewormings && dewormings && dewormings.length > 0 && (
                                    <>
                                        <div className="row">
                                            <div className="col">
                                                <h5 className="mb-2">{ dewormings.length == 1 ? "Everminação" : "Everminações" }</h5>
                                            </div>
                                        </div>
                                        <p className="mb-3">
                                            { dewormings.map((deworming, index) => (
                                                <span className="mb-3 d-block" key={index}>
                                                    { deworming.productName ? (<b>{ deworming.productName }</b>) : (<span className="text-red">Nome do produto não informado (dado obrigatório, preencha ao lado)</span>)}<br/>
                                                    <b>Data de Validade:</b> { deworming.documentDate ? dayjs(deworming.documentDate).format("DD/MM/YYYY") : (<span className="text-red">Não informado (dado obrigatório, preencha ao lado)</span>) }
                                                </span>
                                            )) }
                                        </p>
                                        { dewormingNotes && (
                                            <p className="mb-3">
                                                { dewormingNotes }
                                            </p>
                                        )}
                                    </>
                                )}
                                { otherInfo && (
                                    <>
                                        <div className="row">
                                            <div className="col">
                                                <h5 className="mb-2">Informações clínico-preventivas</h5>
                                            </div>
                                        </div>
                                        <p className="mb-4">
                                            { otherInfo }
                                        </p>
                                    </>
                                )}
                                { notes && (
                                    <>
                                        <div className="row">
                                            <div className="col">
                                                <h5 className="mb-2">Observações Gerais</h5>
                                            </div>
                                        </div>
                                        <p className="mb-4">
                                            { notes }
                                        </p>
                                    </>
                                )}
                            </>
                        )}
                        { documentType == ATESTADO_VACINACAO && (
                            <>
                                <p className="term">
                                    Atesto para os devidos fins, que o animal identificado foi 
                                    vacinado por mim nesta data, conforme informações abaixo:
                                </p>
                                { vaccines && vaccines.length > 0 && (
                                    <>
                                        <div className="row">
                                            <div className="col">
                                                <h5 className="mb-2">Vacinas Aplicadas</h5>
                                            </div>
                                        </div>
                                        <p className="mb-4">
                                            { vaccines.map((vaccine, index) => (
                                                <span className="mb-3 d-block" key={index}>
                                                    { vaccine.vaccineType && (<><b>{ vaccine.vaccineType }</b><br/></>)}
                                                    { vaccine.vaccine && (<><b>Nome do Produto:</b> { vaccine.vaccine }<br/></>)}
                                                    { vaccine.companyName && (<><b>Fabricante:</b> { vaccine.companyName }<br/></>)}
                                                    <b>Número da Partida:</b> { vaccine.manufacturingBatchPart?.length >= 3 ? vaccine.manufacturingBatchPart : (<span className="text-red">Não informado (dado obrigatório, preencha ao lado)</span>) }<br/>
                                                    <b>Data de Fabricação:</b> { vaccine.manufacturingDate ? dayjs(vaccine.manufacturingDate).format("MM/YYYY") : (<span className="text-red">Não informado (dado obrigatório, preencha ao lado)</span>) }<br/>
                                                    <b>Data de Validade:</b> { vaccine.expirationDate ? dayjs(vaccine.expirationDate).format("MM/YYYY") : (<span className="text-red">Não informado (dado obrigatório, preencha ao lado)</span>) }
                                                </span>
                                            )) }
                                        </p>
                                    </>
                                )}
                                { vaccineNotes && (
                                    <>
                                        <div className="row">
                                            <div className="col">
                                                <h5 className="mb-2">Observações Gerais</h5>
                                            </div>
                                        </div>
                                        <p className="mb-4">
                                            { vaccineNotes }
                                        </p>
                                    </>
                                )}
                            </>
                        )}
                        { documentType == CARTA_ENCAMINHAMENTO && (
                            <>
                                <p className="term">
                                    Encaminho o animal identificado neste documento, que foi 
                                    por mim consultado e observada a necessidade de utilizar 
                                    equipamentos, técnicas e locais específicos, sendo necessário 
                                    encaminhamento a centros e profissionais especializados(as)
                                </p>
                                { otherInfo && (
                                    <>
                                        <div className="row">
                                            <div className="col">
                                                <h5 className="mb-2">Histórico/Sinais Clínicos</h5>
                                            </div>
                                        </div>
                                        <p className="mb-4">
                                            { otherInfo }
                                        </p>
                                    </>
                                )}
                                { procedures && procedures.length > 0 && procedures.filter(procedure => procedure.procedure).length > 0 && (
                                    <>
                                        <div className="row">
                                            <div className="col">
                                                <h5 className="mb-2">Procedimentos/Medicações Aplicadas</h5>
                                            </div>
                                        </div>
                                        <p className="mb-4">
                                            { procedures.map((procedure, index) => (
                                                <React.Fragment key={index}>
                                                    { procedure.procedure && (<><b>{ procedure.procedure }{ procedure.notes && ":" }</b><br/></>)}
                                                    { procedure.notes && (<>{ procedure.notes }<br/></>)}
                                                </React.Fragment>
                                            )) }
                                        </p>
                                    </>
                                )}
                                { exams && exams.length > 0 && exams.filter(exam => exam.exam).length > 0 && (
                                    <>
                                        <div className="row">
                                            <div className="col">
                                                <h5 className="mb-2">Exames Realizados</h5>
                                            </div>
                                        </div>
                                        <p className="mb-4">
                                            { exams.map((exam, index) => (
                                                <React.Fragment key={index}>
                                                    { exam.exam && (<><b>{ exam.exam }{ exam.notes && ":" }</b>&nbsp;</>)}
                                                    { exam.notes && (<>{ exam.notes }<br/></>)}
                                                </React.Fragment>
                                            )) }
                                        </p>
                                    </>
                                )}
                                { disease && (
                                    <>
                                        <div className="row">
                                            <div className="col">
                                                <h5 className="mb-2">Suspeitas</h5>
                                            </div>
                                        </div>
                                        <p className="mb-4">
                                            { disease }
                                        </p>
                                    </>
                                )}
                                { eventDetail && (
                                    <>
                                        <div className="row">
                                            <div className="col">
                                                <h5 className="mb-2">Motivo do Encaminhamento</h5>
                                            </div>
                                        </div>
                                        <p className="mb-4">
                                            { eventDetail }
                                        </p>
                                    </>
                                )}
                                { eventNotes && (
                                    <>
                                        <div className="row">
                                            <div className="col">
                                                <h5 className="mb-2">Sugestões</h5>
                                            </div>
                                        </div>
                                        <p className="mb-4">
                                            { eventNotes }
                                        </p>
                                    </>
                                )}
                            </>
                        )}
                        { documentType == TERMO_AUTORIZACAO_EUTANASIA && (
                            <>
                                <p className="term">
                                    Declaro estar ciente dos motivos que levam à necessidade de 
                                    realização da eutanásia, que reconheço que esta é a opção 
                                    escolhida por mim para cessar definitivamente o sofrimento 
                                    do animal e, portanto, declaro o livre consentimento para a 
                                    realização da eutanásia do animal identificado neste documento
                                </p>
                                <p className="term">
                                    Declaro, ainda, que fui devidamente esclarecido(a) do método 
                                    que será utilizado, assim como de que este é um processo 
                                    irreversível.
                                </p>
                                { (vetName || (vetCrmv && vetCrmvStateId)) && (
                                    <>
                                        <div className="row">
                                            <div className="col">
                                                <h5 className="mb-2">Profissional que realizará o procedimento</h5>
                                            </div>
                                        </div>
                                        <p className="mb-4">
                                            { vetName && (<><b>Nome:</b> { vetName }<br/></>)}
                                            { vetCrmv && vetCrmvStateId && (<><b>CRMV:</b> { vetCrmv }/{vetCrmvStateId}</>)}
                                        </p>
                                    </>
                                )}
                                { notes && (
                                    <>
                                        <div className="row">
                                            <div className="col">
                                                <h5 className="mb-2">Observações Gerais</h5>
                                            </div>
                                        </div>
                                        <p className="mb-4">
                                            { notes }
                                        </p>
                                    </>
                                )}
                            </>
                        )}
                        { documentType == TERMO_AUTORIZACAO_EXAMES && (
                            <>
                                <p className="term">
                                    Declaro o livre consentimento para a realização do(s) 
                                    exame(s) solicitados no animal identificado neste 
                                    documento
                                </p>
                                <p className="term">
                                    Declaro ainda, ter sido esclarecido(a) acerca dos 
                                    possíveis riscos inerentes ao procedimento, durante 
                                    ou após a realização do(s) citado(s) exame(s), estando 
                                    o(a) referido(a) profissional isento(a) de quaisquer 
                                    responsabilidades decorrentes de tais riscos
                                </p>
                                { exams && exams.length > 0 && (
                                    <>
                                        <div className="row">
                                            <div className="col">
                                                <h5 className="mb-2">Exame{ exams.length == 1 ? "" : "s" } Solicitado{ exams.length == 1 ? "" : "s" }</h5>
                                            </div>
                                        </div>
                                        <p className="mb-4">
                                            { exams.map(exam => (
                                                exam.exam ? (
                                                    <>
                                                        <b>{ exam.exam }{ exam.notes ? ":" : "" }</b> { exam.notes }<br/>
                                                    </>
                                                ) : (
                                                    <span className="text-red">Não informado (dado obrigatório, preencha ao lado)<br/></span>
                                                )
                                            )) }
                                        </p>
                                    </>
                                )}
                                { (vetName || (vetCrmv && vetCrmvStateId)) && (
                                    <>
                                        <div className="row">
                                            <div className="col">
                                                <h5 className="mb-2">Profissional que realizará o{ exams.length == 1 ? "" : "s" } procedimento{ exams.length == 1 ? "" : "s" }</h5>
                                            </div>
                                        </div>
                                        <p className="mb-4">
                                            { vetName && (<><b>Nome:</b> { vetName }<br/></>)}
                                            { vetCrmv && vetCrmvStateId && (<><b>CRMV:</b> { vetCrmv }/{vetCrmvStateId}</>)}
                                        </p>
                                    </>
                                )}
                                { notes && (
                                    <>
                                        <div className="row">
                                            <div className="col">
                                                <h5 className="mb-2">Observações Gerais</h5>
                                            </div>
                                        </div>
                                        <p className="mb-4">
                                            { notes }
                                        </p>
                                    </>
                                )}
                            </>
                        )}
                        { documentType == TERMO_INTERNACAO && (
                            <>
                                <p className="term">
                                    Declaro o livre consentimento para a realização de internação 
                                    e tratamento(s) necessário(s) no animal identificado neste 
                                    documento { hospitalizationDays && (<b>por { hospitalizationDays } dias</b>)} 
                                </p>
                                <p className="term">
                                    Declaro, ainda, ter sido esclarecido(a) acerca dos possíveis 
                                    riscos inerentes à situação clínica do animal, bem como do(s) 
                                    tratamento(s) proposto(s), estando o(a) referido(a) profissional 
                                    isento(a) de quaisquer responsabilidades decorrentes de tais 
                                    riscos.
                                </p>
                                <div className="row">
                                    <div className="col">
                                        <h5 className="mb-2">Incluso nas Diárias</h5>
                                    </div>
                                </div>
                                <p className="mb-3">
                                    { hospitalizationServices || (<span className="text-red">Não informado (dado obrigatório, preencha ao lado)</span>) }
                                </p>
                                <p className="term">
                                    Outros procedimentos que, porventura, necessitem ser adotados 
                                    para o tratamento do animal devem ser informados à minha 
                                    pessoa.
                                </p>
                                <p className="term">
                                    Em caso de emergência, dou autonomia para o(a) Médico(a) 
                                    Veterinário(a) adotar todos os procedimentos destinados a 
                                    restabelecer o quadro clínico do animal e assumo total 
                                    responsabilidade financeira por todas as despesas.
                                </p>
                                <p className="term">
                                    Após o animal ter recebido alta médica, e estando eu avisado, 
                                    se transcorridos o máximo de { maxDaysAfterDischarge ? (<b>{ maxDaysAfterDischarge } dias</b>) : "dias"} para retirada do animal 
                                    e o mesmo não for retirado, será considerado abandono e o 
                                    animal será encaminhado para adoção, não me isentando das 
                                    penas legais cabíveis.
                                </p>
                                { notes && (
                                    <>
                                        <div className="row">
                                            <div className="col">
                                                <h5 className="mb-2">Observações Gerais</h5>
                                            </div>
                                        </div>
                                        <p className="mb-4">
                                            { notes }
                                        </p>
                                    </>
                                )}
                            </>
                        )}
                        { documentType == TERMO_AUTORIZACAO_PROCEDIMENTO_ANESTESICO && (
                            <>
                                <p className="term">
                                    Declaro o livre consentimento para a realização do(s) 
                                    procedimento(s) anestésico(s) necessário(s) no animal 
                                    identificado neste documento
                                </p>
                                <p className="term">
                                    Declaro, ainda, ter sido esclarecido(a) acerca dos 
                                    possíveis riscos, inerentes ao(s) procedimento(s) 
                                    proposto(s), estando o(a) referido(a) profissional 
                                    isento(a) de quaisquer responsabilidades decorrentes 
                                    de tais riscos.
                                </p>
                                { procedure && (
                                    <>
                                        <div className="row">
                                            <div className="col">
                                                <h5 className="mb-2">Tipo de procedimento anestésico indicado</h5>
                                            </div>
                                        </div>
                                        <p className="mb-4">
                                            { procedure }
                                        </p>
                                    </>
                                )}
                                { notes && (
                                    <>
                                        <div className="row">
                                            <div className="col">
                                                <h5 className="mb-2">Observações Gerais</h5>
                                            </div>
                                        </div>
                                        <p className="mb-4">
                                            { notes }
                                        </p>
                                    </>
                                )}
                            </>
                        )}
                        { documentType == TERMO_AUTORIZACAO_PROCEDIMENTO_CIRURGICO && (
                            <>
                                <p className="term">
                                    Declaro o livre consentimento para a realização do(s) 
                                    procedimento(s) cirúrgico(s) solicitados no animal 
                                    identificado neste documento
                                </p>
                                <p className="term">
                                    Declaro, ainda, ter sido esclarecido(a) acerca dos 
                                    riscos inerentes, durante ou após a realização do 
                                    procedimento cirúrgico citado, estando o(a) referido(a) 
                                    profissional isento(a) de quaisquer responsabilidades 
                                    decorrentes de tais riscos.
                                </p>
                                { procedures && procedures.length > 0 && (
                                    <>
                                        <div className="row">
                                            <div className="col">
                                                <h5 className="mb-2">Cirurgia{ procedures.length == 1 ? "" : "s" } a ser{ procedures.length == 1 ? "" : "em" } Realizada{ procedures.length == 1 ? "" : "s" }</h5>
                                            </div>
                                        </div>
                                        <p className="mb-4">
                                            { procedures.map(procedure => (
                                                procedure.surgery ? (
                                                    <>
                                                        <b>{ procedure.surgery }{ procedure.notes ? ":" : "" }</b> { procedure.notes }<br/>
                                                    </>
                                                ) : (
                                                    <span className="text-red">Não informado (dado obrigatório, preencha ao lado)<br/></span>
                                                )
                                            )) }
                                        </p>
                                    </>
                                )}
                                { (vetName || (vetCrmv && vetCrmvStateId)) && (
                                    <>
                                        <div className="row">
                                            <div className="col">
                                                <h5 className="mb-2">Profissional que realizará a{ procedures.length == 1 ? "" : "s" } cirurgia{ procedures.length == 1 ? "" : "s" }</h5>
                                            </div>
                                        </div>
                                        <p className="mb-4">
                                            { vetName && (<><b>Nome:</b> { vetName }<br/></>)}
                                            { vetCrmv && vetCrmvStateId && (<><b>CRMV:</b> { vetCrmv }/{vetCrmvStateId}</>)}
                                        </p>
                                    </>
                                )}
                                { notes && (
                                    <>
                                        <div className="row">
                                            <div className="col">
                                                <h5 className="mb-2">Observações Gerais</h5>
                                            </div>
                                        </div>
                                        <p className="mb-4">
                                            { notes }
                                        </p>
                                    </>
                                )}
                            </>
                        )}
                        { documentType == TERMO_AUTORIZACAO_PROCEDIMENTO_TERAPEUTICO && (
                            <>
                                <p className="term">
                                    Declaro o livre consentimento para a realização do(s) 
                                    procedimento(s) terapêutos(s) solicitados no animal 
                                    identificado neste documento
                                </p>
                                <p className="term">
                                    Declaro, ainda, ter sido esclarecido(a) acerca dos 
                                    possíveis riscos inerentes, durante ou após a 
                                    realização do(s) procedimento(s) terapêutico(s), 
                                    estando o referido o(a) profissional isento(a) de 
                                    quaisquer responsabilidades decorrentes de tais 
                                    riscos.
                                </p>
                                { procedures && procedures.length > 0 && (
                                    <>
                                        <div className="row">
                                            <div className="col">
                                                <h5 className="mb-2">Procedimento{ procedures.length == 1 ? "" : "s" } a ser{ procedures.length == 1 ? "" : "em" } Realizado{ procedures.length == 1 ? "" : "s" }</h5>
                                            </div>
                                        </div>
                                        <p className="mb-4">
                                            { procedures.map(procedure => (
                                                procedure.procedure ? (
                                                    <>
                                                        <b>{ procedure.procedure }{ procedure.notes ? ":" : "" }</b> { procedure.notes }<br/>
                                                    </>
                                                ) : (
                                                    <span className="text-red">Não informado (dado obrigatório, preencha ao lado)<br/></span>
                                                )
                                            )) }
                                        </p>
                                    </>
                                )}
                                { (vetName || (vetCrmv && vetCrmvStateId)) && (
                                    <>
                                        <div className="row">
                                            <div className="col">
                                                <h5 className="mb-2">Profissional que realizará o{ procedures.length == 1 ? "" : "s" } procedimento{ procedures.length == 1 ? "" : "s" }</h5>
                                            </div>
                                        </div>
                                        <p className="mb-4">
                                            { vetName && (<><b>Nome:</b> { vetName }<br/></>)}
                                            { vetCrmv && vetCrmvStateId && (<><b>CRMV:</b> { vetCrmv }/{vetCrmvStateId}</>)}
                                        </p>
                                    </>
                                )}
                                { notes && (
                                    <>
                                        <div className="row">
                                            <div className="col">
                                                <h5 className="mb-2">Observações Gerais</h5>
                                            </div>
                                        </div>
                                        <p className="mb-4">
                                            { notes }
                                        </p>
                                    </>
                                )}
                            </>
                        )}
                        { documentType == TERMO_LIBERACAO && (
                            <>
                                <p className="term">
                                    Declaro que foi esclarecido que o animal abaixo 
                                    identificado neste documento não obteve alta médica 
                                    e que há recomendação para manter o animal em 
                                    internação em estabelecimento médico veterinário 
                                    apropriado
                                </p>
                                <p className="term">
                                    Declaro ainda que estou ciente de que há riscos de 
                                    agravamento da doença, inclusive morte, e que assumo 
                                    inteira responsabilidade por esse ato
                                </p>
                                { notes && (
                                    <>
                                        <div className="row">
                                            <div className="col">
                                                <h5 className="mb-2">Observações Gerais</h5>
                                            </div>
                                        </div>
                                        <p className="mb-4">
                                            { notes }
                                        </p>
                                    </>
                                )}
                            </>
                        )}
                        { documentType == TERMO_CONSENTIMENTO_TELEMEDICINA && (
                            <>
                                <div className="row">
                                    <div className="col">
                                        <h5 className="mb-2">Identificação do Emitente</h5>
                                    </div>
                                </div>
                                <p className="mb-4">
                                    <b>Nome do Profissional:</b> { userName || (<span className="text-red">Não informado (dado obrigatório, preencha ao lado)</span>) }<br />
                                    <b>CRMV - UF do Profissional:</b> { userCrmv && userCrmvStateId ? `${userCrmv} - ${userCrmvStateId}` : (<span className="text-red">Não informado (dado obrigatório, preencha ao lado)</span>) }<br />
                                    <b>E-mail:</b> { userContactEmail || (<span className="text-red">Não informado (dado obrigatório, preencha ao lado)</span>) }<br />
                                    <b>Telefone:</b> { userContactPhone || (<span className="text-red">Não informado (dado obrigatório, preencha ao lado)</span>) }<br />
                                    { documentSubType == 0 ? (
                                        <>
                                            <b>Endereço:</b>&nbsp;
                                            { userAddressStreet && userAddressNumber && userNeighborhood && userCity && userStateId && validatePostalCode(userZipCode) ? (
                                                formattedAddress({
                                                    address: userAddressStreet,
                                                    number: userAddressNumber,
                                                    complement: userAddressComplement,
                                                    neighborhood: userNeighborhood,
                                                    city: userCity,
                                                    state: userStateId,
                                                    zipCode: userZipCode
                                                })
                                            ) : (
                                                <span className="text-red">Não informado (dados obrigatórios, preencha ao lado)</span>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            <b>Razão Social:</b> { clinicCompanyName || (<span className="text-red">Não informado (dado obrigatório, preencha ao lado)</span>) }<br />
                                            <b>CNPJ:</b> { clinicCnpj || (<span className="text-red">Não informado (dado obrigatório, preencha ao lado)</span>) }<br />
                                            <b>CRMV - UF:</b> { clinicCrmv && clinicCrmvStateId ? `${clinicCrmv} - ${clinicCrmvStateId}` : (<span className="text-red">Não informado (dado obrigatório, preencha ao lado)</span>) }<br />
                                            <b>Registro MAPA:</b> { clinicRegistrationMapa || (<span className="text-red">Não informado (dado obrigatório, preencha ao lado)</span>) }<br />
                                            { clinicStateRegistrationId && <><b>Inscrição Estadual:</b> { clinicStateRegistrationId }<br /></> }
                                            { clinicMunicipalRegistrationId && <><b>Inscrição Municipal:</b> { clinicMunicipalRegistrationId }<br /></> }
                                            <b>Endereço:</b>&nbsp;
                                            { clinicAddressStreet && clinicAddressNumber && clinicNeighborhood && clinicCity && clinicStateId && validatePostalCode(clinicZipCode) ? (
                                                formattedAddress({
                                                    address: clinicAddressStreet,
                                                    number: clinicAddressNumber,
                                                    complement: clinicAddressComplement,
                                                    neighborhood: clinicNeighborhood,
                                                    city: clinicCity,
                                                    state: clinicStateId,
                                                    zipCode: clinicZipCode
                                                })
                                            ) : (
                                                <span className="text-red">Não informado (dados obrigatórios, preencha ao lado)</span>
                                            )}
                                        </>
                                    )}
                                </p>
                                <p className="term">
                                    Declaro o livre consentimento para o compartilhamento 
                                    dos meus dados pessoais acima indicados, indispensáveis 
                                    para utilização de Telemedicina veterinária, nas 
                                    modalidades de Teleinterconsulta e Telediagnóstico, e 
                                    atendimento do animal acima identificado, a ser 
                                    realizado entre o(a):  
                                </p>
                                <div className="row">
                                    <div className="col">
                                        <h5 className="mb-2">Médic{ vets && vets.length == 1 ? "o(a)" : "os(as)" } Veterinári{ vets.length == 1 ? "o(a)" : "os(as)" } que realizará o teleatendimento</h5>
                                    </div>
                                </div>
                                { vets && vets.map((vet, index) => (
                                    <p className="mb-3" key={index}>
                                        <b>Nome:</b> { vet.name || (<span className="text-red">Não informado (dado obrigatório, preencha ao lado)</span>) }<br />
                                        <b>CRMV - UF:</b> { vet.crmv && vet.crmvStateId ? `${vet.crmv} - ${vet.crmvStateId}` : (<span className="text-red">Não informado (dado obrigatório, preencha ao lado)</span>) }<br />
                                    </p>
                                )) }
                                <p className="term">
                                    O compartilhamento se faz necessário para permitir que os 
                                    médicos veterinários acima indicados possam me contatar e 
                                    obter informações ou documentos complementares.
                                </p>
                                <p className="term">
                                    Declaro, ainda, ter sido esclarecido(a) acerca da limitação 
                                    do uso dos dados acima, observados os princípios e as 
                                    garantias estabelecidas pela Lei nº 13.709, de 2018.
                                </p>
                                { notes && (
                                    <>
                                        <div className="row">
                                            <div className="col">
                                                <h5 className="mb-2">Observações Gerais</h5>
                                            </div>
                                        </div>
                                        <p className="mb-4">
                                            { notes }
                                        </p>
                                    </>
                                )}
                            </>
                        )}
                        { documentType == TERMO_BRANCO && (
                            <>
                                { notes && (
                                    <>
                                        <h5 className="mb-2">{documentName}</h5>
                                        <p className="term">
                                            { notes }
                                        </p>
                                        { items && items.length > 0 && items.map(item =>
                                            <>
                                                {item.title &&
                                                <div className="row">
                                                    <div className="col">
                                                        <h5 className="mb-2">{item.title}</h5>
                                                    </div>
                                                </div>}
                                                {item.description && 
                                                <p className="term mb-4">
                                                    {item.description}
                                                </p>}
                                            </>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                    </>
                )}
            </div>
        )
    }

    return (
        <VSDrawer
            id="term-form"
            timeout={0}
            title={update ? "Editar Atestado ou Termo" : "Novo Atestado ou Termo"}
            cancel={<span>Cancelar {update ? "Edição" : "Emissão" }<span className="d-none d-xl-inline"> de Documento</span></span>}
            submit={isValid ? (<span>Salvar e Visualizar<span className="d-none d-xl-inline"> Documento</span></span>) : <span>Preencha os campos<span className="d-none d-md-inline"> necessários</span></span>}
            errors={errorMsg}
            // preview={canEdit && renderPreview()}
            withVSDrawerInfo
            onSubmit={save}
            onCancel={() => history.goBack()}
            onAfterSave={(object) => {
                if (id_termo) {
                    history.replace(`/documento/atestado_termo/${object.id}`)
                } else {
                    history.goBack()
                }
            }}
            VSDrawerCancelProps={{
                title: update ? "Tem certeza que deseja cancelar as alterações no atestado/termo? " : "Tem certeza que deseja cancelar o atestado/termo?",
                confirm: <>Cancelar { update ? "Edição" : "Emissão" } do Documento</>,
                cancel: "Voltar para Edição"
            }}
        >
            { loading ? (
                <div className="row data-loading">
                    <div className="col">
                        <CircularProgress />
                    </div>
                </div>
            ) : error?.message ? (
                <VSError
                    message={error.message}
                    onClose={() => {
                        switch(error.function) {
                            case "getPatientOrError":
                                getPatientOrError()
                                break
                            case "getTermOrError":
                                getTermOrError()
                                break
                            default:
                        }
                    }}
                />
            ) : canEdit ? (
                <div className="row">
                    <div className="col">
                        <TermDocumentForm
                            ref={documentTermForm}
                            city={city}
                            setCity={setCity}
                            errorCity={errorCity}
                            setErrorCity={setErrorCity}
                            helperCity={helperCity}
                            setHelperCity={setHelperCity}
                            documentType={documentType}
                            setDocumentType={setDocumentType}
                            errorDocumentType={errorDocumentType}
                            setErrorDocumentType={setErrorDocumentType}
                            helperDocumentType={helperDocumentType}
                            setHelperDocumentType={setHelperDocumentType}
                            documentDay={documentDay}
                            setDocumentDay={setDocumentDay}
                            documentMonth={documentMonth}
                            setDocumentMonth={setDocumentMonth}
                            documentYear={documentYear}
                            setDocumentYear={setDocumentYear}
                            documentDate={documentDate}
                            setDocumentDate={setDocumentDate}
                            errorDocumentDate={errorDocumentDate}
                            setErrorDocumentDate={setErrorDocumentDate}
                            helperDocumentDate={helperDocumentDate}
                            setHelperDocumentDate={setHelperDocumentDate}
                        />
                        <hr className="mb-1" />
                        <TermPatientForm
                            ref={patientForm}
                            patient={patient} 
                            breed={breed} 
                            setBreed={setBreed}
                            errorBreed={errorBreed} 
                            setErrorBreed={setErrorBreed}
                            helperBreed={helperBreed} 
                            setHelperBreed={setHelperBreed}
                            gender={gender} 
                            setGender={setGender}
                            errorGender={errorGender} 
                            setErrorGender={setErrorGender}
                            helperGender={helperGender} 
                            setHelperGender={setHelperGender}
                            birthdate={birthdate} 
                            setBirthdate={setBirthdate}
                            errorBirthdate={errorBirthdate} 
                            setErrorBirthdate={setErrorBirthdate}
                            helperBirthdate={helperBirthdate} 
                            setHelperBirthdate={setHelperBirthdate}
                            birthdateText={birthdateText} 
                            setBirthdateText={setBirthdateText}
                            ageYear={ageYear} 
                            setAgeYear={setAgeYear}
                            ageMonth={ageMonth} 
                            setAgeMonth={setAgeMonth}
                        />
                        <hr className="my-1" />
                        <TermPatientInfoForm
                            ref={patientInfoForm}
                            patient={patient}
                            pelage={pelage}
                            setPelage={setPelage}
                            pelages={pelages}
                            setPelages={setPelages}
                            size={size}
                            setSize={setSize}
                            rga={rga}
                            setRga={setRga}
                            microchip={microchip}
                            setMicrochip={setMicrochip}
                            otherInfo={patientOtherInfo}
                            setOtherInfo={setPatientOtherInfo}
                        />
                        <hr className="my-1" />
                        <TermOwnerForm
                            ref={ownerForm}
                            documentType={documentType}
                            owner={owner}
                            cpf={cpf} 
                            setCpf={setCpf}
                            errorCpf={errorCpf} 
                            setErrorCpf={setErrorCpf}
                            helperCpf={helperCpf} 
                            setHelperCpf={setHelperCpf}
                            loadingZipCode={loadingZipCode} 
                            setLoadingZipCode={setLoadingZipCode}
                            zipCode={zipCode} 
                            setZipCode={setZipCode}
                            errorZipCode={errorZipCode} 
                            setErrorZipCode={setErrorZipCode}
                            helperZipCode={helperZipCode} 
                            setHelperZipCode={setHelperZipCode}
                            address={address} 
                            setAddress={setAddress}
                            errorAddress={errorAddress} 
                            setErrorAddress={setErrorAddress}
                            helperAddress={helperAddress} 
                            setHelperAddress={setHelperAddress}
                            addressNumber={addressNumber} 
                            setAddressNumber={setAddressNumber}
                            errorAddressNumber={errorAddressNumber} 
                            setErrorAddressNumber={setErrorAddressNumber}
                            helperAddressNumber={helperAddressNumber} 
                            setHelperAddressNumber={setHelperAddressNumber}
                            addressComplement={addressComplement} 
                            setAddressComplement={setAddressComplement}
                            neighborhood={neighborhood} 
                            setNeighborhood={setNeighborhood}
                            errorNeighborhood={errorNeighborhood} 
                            setErrorNeighborhood={setErrorNeighborhood}
                            helperNeighborhood={helperNeighborhood} 
                            setHelperNeighborhood={setHelperNeighborhood}
                            cityId={cityId} 
                            setCityId={setCityId}
                            ownerCity={ownerCity} 
                            setOwnerCity={setOwnerCity}
                            errorOwnerCity={errorOwnerCity} 
                            setErrorOwnerCity={setErrorOwnerCity}
                            helperOwnerCity={helperOwnerCity} 
                            setHelperOwnerCity={setHelperOwnerCity}
                            state={state} 
                            setState={setState}
                            errorState={errorState} 
                            setErrorState={setErrorState}
                            helperState={helperState} 
                            setHelperState={setHelperState}
                            ownerEmail={ownerEmail}
                            setOwnerEmail={setOwnerEmail}
                            errorOwnerEmail={errorOwnerEmail}
                            setErrorOwnerEmail={setErrorOwnerEmail}
                            helperOwnerEmail={helperOwnerEmail}
                            setHelperOwnerEmail={setHelperOwnerEmail}
                            ownerPhone={ownerPhone}
                            setOwnerPhone={setOwnerPhone}
                            errorOwnerPhone={errorOwnerPhone}
                            setErrorOwnerPhone={setErrorOwnerPhone}
                            helperOwnerPhone={helperOwnerPhone}
                            setHelperOwnerPhone={setHelperOwnerPhone}
                        />
                        <hr className="mt-1" />
                        { documentType == TERMO_OBITO && (
                            <TermDeathForm
                                ref={deathTermForm}
                                authNecropsy={authNecropsy}
                                setAuthNecropsy={setAuthNecropsy}
                                authStudy={authStudy}
                                setAuthStudy={setAuthStudy}
                                authRemoveByMe={authRemoveByMe}
                                setAuthRemoveByMe={setAuthRemoveByMe}
                                authRemoveByMeDays={authRemoveByMeDays}
                                setAuthRemoveByMeDays={setAuthRemoveByMeDays}
                                authRemoveByOther={authRemoveByOther}
                                setAuthRemoveByOther={setAuthRemoveByOther}
                                authRemoveByOtherDays={authRemoveByOtherDays}
                                setAuthRemoveByOtherDays={setAuthRemoveByOtherDays}
                                authRemoveByCityHall={authRemoveByCityHall}
                                setAuthRemoveByCityHall={setAuthRemoveByCityHall}
                                authRemoveByMeImmediately={authRemoveByMeImmediately}
                                setAuthRemoveByMeImmediately={setAuthRemoveByMeImmediately}
                                authRemoveByOtherImmediately={authRemoveByOtherImmediately}
                                setAuthRemoveByOtherImmediately={setAuthRemoveByOtherImmediately}
                                authName={authName}
                                setAuthName={setAuthName}
                                errorAuthName={errorAuthName}
                                setErrorAuthName={setErrorAuthName}
                                helperAuthName={helperAuthName}
                                setHelperAuthName={setHelperAuthName}
                                authCpf={authCpf}
                                setAuthCpf={setAuthCpf}
                                errorAuthCpf={errorAuthCpf}
                                setErrorAuthCpf={setErrorAuthCpf}
                                helperAuthCpf={helperAuthCpf}
                                setHelperAuthCpf={setHelperAuthCpf}
                                eventLocation={eventLocation}
                                setEventLocation={setEventLocation}
                                errorEventLocation={errorEventLocation}
                                setErrorEventLocation={setErrorEventLocation}
                                helperEventLocation={helperEventLocation}
                                setHelperEventLocation={setHelperEventLocation}
                                eventDate={eventDate}
                                setEventDate={setEventDate}
                                errorEventDate={errorEventDate}
                                setErrorEventDate={setErrorEventDate}
                                helperEventDate={helperEventDate}
                                setHelperEventDate={setHelperEventDate}
                                eventTime={eventTime}
                                setEventTime={setEventTime}
                                eventHour={eventHour}
                                setEventHour={setEventHour}
                                eventMinute={eventMinute}
                                setEventMinute={setEventMinute}
                                eventDetail={eventDetail}
                                setEventDetail={setEventDetail}
                                eventNotes={eventNotes}
                                setEventNotes={setEventNotes}
                            />
                        )}
                        { documentType == ATESTADO_SANITARIO && (
                            <TermHealthForm
                                ref={healthTermForm}
                                patient={patient}
                                vaccineNotes={vaccineNotes}
                                setVaccineNotes={setVaccineNotes}
                                vaccines={vaccines}
                                setVaccines={setVaccines}
                                includeVaccines={includeVaccines}
                                setIncludeVaccines={setIncludeVaccines}
                                dewormingNotes={dewormingNotes}
                                setDewormingNotes={setDewormingNotes}
                                dewormings={dewormings}
                                setDewormings={setDewormings}
                                includeDewormings={includeDewormings}
                                setIncludeDewormings={setIncludeDewormings}
                                otherInfo={otherInfo}
                                setOtherInfo={setOtherInfo}
                                notes={notes}
                                setNotes={setNotes}
                            />
                        )}
                        { documentType == ATESTADO_VACINACAO && (
                            <TermVaccineForm
                                ref={vaccineTermForm}
                                patient={patient}
                                setIncludeVaccines={setIncludeVaccines}
                                vaccineNotes={vaccineNotes}
                                setVaccineNotes={setVaccineNotes}
                                vaccines={vaccines}
                                setVaccines={setVaccines}
                            />
                        )}
                        { documentType == CARTA_ENCAMINHAMENTO && (
                            <TermForwardingForm
                                ref={forwardingTermForm}
                                documentSubType={documentSubType}
                                setDocumentSubType={setDocumentSubType}
                                otherInfo={otherInfo}
                                setOtherInfo={setOtherInfo}
                                procedures={procedures}
                                setProcedures={setProcedures}
                                exams={exams}
                                setExams={setExams}
                                disease={disease}
                                setDisease={setDisease}
                                eventDetail={eventDetail}
                                setEventDetail={setEventDetail}
                                eventNotes={eventNotes}
                                setEventNotes={setEventNotes}
                            />
                        )}
                        { documentType == TERMO_AUTORIZACAO_EUTANASIA && (
                            <TermEuthanasiaForm
                                ref={euthanasiaTermForm}
                                vetName={vetName}
                                setVetName={setVetName}
                                vetCrmv={vetCrmv}
                                setVetCrmv={setVetCrmv}
                                vetCrmvStateId={vetCrmvStateId}
                                setVetCrmvStateId={setVetCrmvStateId}
                                notes={notes}
                                setNotes={setNotes}
                            />
                        )}
                        { documentType == TERMO_AUTORIZACAO_EXAMES && (
                            <TermExamForm
                                ref={examTermForm}
                                exams={exams}
                                setExams={setExams}
                                vetName={vetName}
                                setVetName={setVetName}
                                vetCrmv={vetCrmv}
                                setVetCrmv={setVetCrmv}
                                vetCrmvStateId={vetCrmvStateId}
                                setVetCrmvStateId={setVetCrmvStateId}
                                notes={notes}
                                setNotes={setNotes}
                            />
                        )}
                        { documentType == TERMO_INTERNACAO && (
                            <TermHospitalizationForm
                                ref={hospitalizationTermForm}
                                maxDaysAfterDischarge={maxDaysAfterDischarge}
                                setMaxDaysAfterDischarge={setMaxDaysAfterDischarge}
                                errorMaxDaysAfterDischarge={errorMaxDaysAfterDischarge}
                                setErrorMaxDaysAfterDischarge={setErrorMaxDaysAfterDischarge}
                                helperMaxDaysAfterDischarge={helperMaxDaysAfterDischarge}
                                setHelperMaxDaysAfterDischarge={setHelperMaxDaysAfterDischarge}
                                hospitalizationDays={hospitalizationDays}
                                setHospitalizationDays={setHospitalizationDays}
                                hospitalizationServices={hospitalizationServices}
                                setHospitalizationServices={setHospitalizationServices}
                                errorHospitalizationServices={errorHospitalizationServices}
                                setErrorHospitalizationServices={setErrorHospitalizationServices}
                                helperHospitalizationServices={helperHospitalizationServices}
                                setHelperHospitalizationServices={setHelperHospitalizationServices}
                                notes={notes}
                                setNotes={setNotes}
                            />
                        )}
                        { documentType == TERMO_AUTORIZACAO_PROCEDIMENTO_ANESTESICO && (
                            <TermAnesthesiaForm
                                ref={anesthesiaTermForm}
                                procedure={procedure}
                                setProcedure={setProcedure}
                                notes={notes}
                                setNotes={setNotes}
                            />
                        )}
                        { documentType == TERMO_AUTORIZACAO_PROCEDIMENTO_CIRURGICO && (
                            <TermSurgeryForm
                                ref={surgeryTermForm}
                                procedures={procedures}
                                setProcedures={setProcedures}
                                vetName={vetName}
                                setVetName={setVetName}
                                vetCrmv={vetCrmv}
                                setVetCrmv={setVetCrmv}
                                vetCrmvStateId={vetCrmvStateId}
                                setVetCrmvStateId={setVetCrmvStateId}
                                notes={notes}
                                setNotes={setNotes}
                            />
                        )}
                        { documentType == TERMO_AUTORIZACAO_PROCEDIMENTO_TERAPEUTICO && (
                            <TermRiskForm
                                ref={riskTermForm}
                                procedures={procedures}
                                setProcedures={setProcedures}
                                vetName={vetName}
                                setVetName={setVetName}
                                vetCrmv={vetCrmv}
                                setVetCrmv={setVetCrmv}
                                vetCrmvStateId={vetCrmvStateId}
                                setVetCrmvStateId={setVetCrmvStateId}
                                notes={notes}
                                setNotes={setNotes}
                            />
                        )}
                        { documentType == TERMO_LIBERACAO && (
                            <TermLeaveForm
                                ref={leaveTermForm}
                                notes={notes}
                                setNotes={setNotes}
                            />
                        )}
                        { documentType == TERMO_CONSENTIMENTO_TELEMEDICINA && (
                            <TermTelemedicineForm
                                ref={telemedicineTermForm}
                                clinic={clinic}
                                userName={userName}
                                setUserName={setUserName}
                                errorUserName={errorUserName}
                                setErrorUserName={setErrorUserName}
                                helperUserName={helperUserName}
                                setHelperUserName={setHelperUserName}
                                userCrmv={userCrmv}
                                setUserCrmv={setUserCrmv}
                                errorUserCrmv={errorUserCrmv}
                                setErrorUserCrmv={setErrorUserCrmv}
                                helperUserCrmv={helperUserCrmv}
                                setHelperUserCrmv={setHelperUserCrmv}
                                userCrmvStateId={userCrmvStateId}
                                setUserCrmvStateId={setUserCrmvStateId}
                                errorUserCrmvStateId={errorUserCrmvStateId}
                                setErrorUserCrmvStateId={setErrorUserCrmvStateId}
                                helperUserCrmvStateId={helperUserCrmvStateId}
                                setHelperUserCrmvStateId={setHelperUserCrmvStateId}
                                userContactEmail={userContactEmail}
                                setUserContactEmail={setUserContactEmail}
                                errorUserContactEmail={errorUserContactEmail}
                                setErrorUserContactEmail={setErrorUserContactEmail}
                                helperUserContactEmail={helperUserContactEmail}
                                setHelperUserContactEmail={setHelperUserContactEmail}
                                userContactPhone={userContactPhone}
                                setUserContactPhone={setUserContactPhone}
                                errorUserContactPhone={errorUserContactPhone}
                                setErrorUserContactPhone={setErrorUserContactPhone}
                                helperUserContactPhone={helperUserContactPhone}
                                setHelperUserContactPhone={setHelperUserContactPhone}
                                userLoadingZipCode={userLoadingZipCode}
                                setUserLoadingZipCode={setUserLoadingZipCode}
                                userZipCode={userZipCode}
                                setUserZipCode={setUserZipCode}
                                errorUserZipCode={errorUserZipCode}
                                setErrorUserZipCode={setErrorUserZipCode}
                                helperUserZipCode={helperUserZipCode}
                                setHelperUserZipCode={setHelperUserZipCode}
                                userAddressStreet={userAddressStreet}
                                setUserAddressStreet={setUserAddressStreet}
                                errorUserAddressStreet={errorUserAddressStreet}
                                setErrorUserAddressStreet={setErrorUserAddressStreet}
                                helperUserAddressStreet={helperUserAddressStreet}
                                setHelperUserAddressStreet={setHelperUserAddressStreet}
                                userAddressNumber={userAddressNumber}
                                setUserAddressNumber={setUserAddressNumber}
                                errorUserAddressNumber={errorUserAddressNumber}
                                setErrorUserAddressNumber={setErrorUserAddressNumber}
                                helperUserAddressNumber={helperUserAddressNumber}
                                setHelperUserAddressNumber={setHelperUserAddressNumber}
                                userAddressComplement={userAddressComplement}
                                setUserAddressComplement={setUserAddressComplement}
                                userNeighborhood={userNeighborhood}
                                setUserNeighborhood={setUserNeighborhood}
                                errorUserNeighborhood={errorUserNeighborhood}
                                setErrorUserNeighborhood={setErrorUserNeighborhood}
                                helperUserNeighborhood={helperUserNeighborhood}
                                setHelperUserNeighborhood={setHelperUserNeighborhood}
                                userCity={userCity}
                                setUserCity={setUserCity}
                                errorUserCity={errorUserCity}
                                setErrorUserCity={setErrorUserCity}
                                helperUserCity={helperUserCity}
                                setHelperUserCity={setHelperUserCity}
                                userStateId={userStateId}
                                setUserStateId={setUserStateId}
                                errorUserStateId={errorUserStateId}
                                setErrorUserStateId={setErrorUserStateId}
                                helperUserStateId={helperUserStateId}
                                setHelperUserStateId={setHelperUserStateId}
                                clinicCompanyName={clinicCompanyName}
                                setClinicCompanyName={setClinicCompanyName}
                                errorClinicCompanyName={errorClinicCompanyName}
                                setErrorClinicCompanyName={setErrorClinicCompanyName}
                                helperClinicCompanyName={helperClinicCompanyName}
                                setHelperClinicCompanyName={setHelperClinicCompanyName}
                                clinicCnpj={clinicCnpj}
                                setClinicCnpj={setClinicCnpj}
                                errorClinicCnpj={errorClinicCnpj}
                                setErrorClinicCnpj={setErrorClinicCnpj}
                                helperClinicCnpj={helperClinicCnpj}
                                setHelperClinicCnpj={setHelperClinicCnpj}
                                clinicCrmv={clinicCrmv}
                                setClinicCrmv={setClinicCrmv}
                                errorClinicCrmv={errorClinicCrmv}
                                setErrorClinicCrmv={setErrorClinicCrmv}
                                helperClinicCrmv={helperClinicCrmv}
                                setHelperClinicCrmv={setHelperClinicCrmv}
                                clinicCrmvStateId={clinicCrmvStateId}
                                setClinicCrmvStateId={setClinicCrmvStateId}
                                errorClinicCrmvStateId={errorClinicCrmvStateId}
                                setErrorClinicCrmvStateId={setErrorClinicCrmvStateId}
                                helperClinicCrmvStateId={helperClinicCrmvStateId}
                                setHelperClinicCrmvStateId={setHelperClinicCrmvStateId}
                                clinicRegistrationMapa={clinicRegistrationMapa}
                                setClinicRegistrationMapa={setClinicRegistrationMapa}
                                errorClinicRegistrationMapa={errorClinicRegistrationMapa}
                                setErrorClinicRegistrationMapa={setErrorClinicRegistrationMapa}
                                helperClinicRegistrationMapa={helperClinicRegistrationMapa}
                                setHelperClinicRegistrationMapa={setHelperClinicRegistrationMapa}
                                clinicStateRegistrationId={clinicStateRegistrationId}
                                setClinicStateRegistrationId={setClinicStateRegistrationId}
                                clinicMunicipalRegistrationId={clinicMunicipalRegistrationId}
                                setClinicMunicipalRegistrationId={setClinicMunicipalRegistrationId}
                                clinicLoadingZipCode={clinicLoadingZipCode}
                                setClinicLoadingZipCode={setClinicLoadingZipCode}
                                clinicZipCode={clinicZipCode}
                                setClinicZipCode={setClinicZipCode}
                                errorClinicZipCode={errorClinicZipCode}
                                setErrorClinicZipCode={setErrorClinicZipCode}
                                helperClinicZipCode={helperClinicZipCode}
                                setHelperClinicZipCode={setHelperClinicZipCode}
                                clinicAddressStreet={clinicAddressStreet}
                                setClinicAddressStreet={setClinicAddressStreet}
                                errorClinicAddressStreet={errorClinicAddressStreet}
                                setErrorClinicAddressStreet={setErrorClinicAddressStreet}
                                helperClinicAddressStreet={helperClinicAddressStreet}
                                setHelperClinicAddressStreet={setHelperClinicAddressStreet}
                                clinicAddressNumber={clinicAddressNumber}
                                setClinicAddressNumber={setClinicAddressNumber}
                                errorClinicAddressNumber={errorClinicAddressNumber}
                                setErrorClinicAddressNumber={setErrorClinicAddressNumber}
                                helperClinicAddressNumber={helperClinicAddressNumber}
                                setHelperClinicAddressNumber={setHelperClinicAddressNumber}
                                clinicAddressComplement={clinicAddressComplement}
                                setClinicAddressComplement={setClinicAddressComplement}
                                clinicNeighborhood={clinicNeighborhood}
                                setClinicNeighborhood={setClinicNeighborhood}
                                errorClinicNeighborhood={errorClinicNeighborhood}
                                setErrorClinicNeighborhood={setErrorClinicNeighborhood}
                                helperClinicNeighborhood={helperClinicNeighborhood}
                                setHelperClinicNeighborhood={setHelperClinicNeighborhood}
                                clinicCity={clinicCity}
                                setClinicCity={setClinicCity}
                                errorClinicCity={errorClinicCity}
                                setErrorClinicCity={setErrorClinicCity}
                                helperClinicCity={helperClinicCity}
                                setHelperClinicCity={setHelperClinicCity}
                                clinicStateId={clinicStateId}
                                setClinicStateId={setClinicStateId}
                                errorClinicStateId={errorClinicStateId}
                                setErrorClinicStateId={setErrorClinicStateId}
                                helperClinicStateId={helperClinicStateId}
                                setHelperClinicStateId={setHelperClinicStateId}
                                documentSubType={documentSubType}
                                setDocumentSubType={setDocumentSubType}
                                vets={vets}
                                setVets={setVets}
                                notes={notes}
                                setNotes={setNotes}
                            />
                        )}
                        { documentType == TERMO_BRANCO && (
                            <TermBlankForm 
                                ref={blankTermForm}
                                user={user}
                                notes={notes}
                                setNotes={setNotes}
                                documentName={documentName}
                                setDocumentName={setDocumentName}
                                errorDocumentName={errorDocumentName}
                                setErrorDocumentName={setErrorDocumentName}
                                helperDocumentName={helperDocumentName}
                                setHelperDocumentName={setHelperDocumentName}
                                signedBy={signedBy}
                                setSignedBy={setSignedBy}
                                errorSignedBy={errorSignedBy}
                                setErrorSignedBy={setErrorSignedBy}
                                helperSignedBy={helperSignedBy}
                                setHelperSignedBy={setHelperSignedBy}
                                items={items}
                                setItems={setItems}
                            />
                        )}
                        <VSMedicalRecordsAudit parseObject={term} />
                    </div>
                    <div className="col-12 px-0 mt-3">
                        <VSDrawerInfo
                            title="Ver Prévia do Documento"
                        >
                            { renderPreview() }
                        </VSDrawerInfo>
                    </div>
                </div>
            ) : (
                <Blocked />
            )}
        </VSDrawer>
    )
}

const mapStateToProps = state => {
    return {
        user: state.user,
        clinic: state.clinic,
        permissions: state.permissions
    };
};

export default connect(mapStateToProps)(TermForm);