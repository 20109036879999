import React from 'react'

import { dayjs, formattedCurrency } from '../../../../utils'
import { SALE, getPaymentMethod, isQuantityGreaterThanOne } from './Sale'

import VSAccordionSimple from '../../../../components/vsAccordion/VSAccordionSimple'

import './SaleInfoView.scss'
const SaleInfoView = (props) => {

    const {
        object,
        expanded
    } = props

    const isDeleted = object.get("isDeleted")

    const services = object.get("services") || []
    const payments = object.get("payments") || []

    const isSale = object.get("documentType") == SALE

    return !isDeleted && (
        <>
            <div className="vsbox-separator"></div>
            <div className="row">
                <div className="col-12 vsbox--info">
                    <VSAccordionSimple
                        expanded={expanded}
                        header={(expanded) => (
                            `Ver ${ expanded ? "menos" : "mais" } informações`
                        )}
                    >
                        { isSale && (
                            <div className="row">
                                <div className="col-12">
                                    <div className="vsbox-item mb-3">
                                        <div className={`row vsbox-item-text ${ payments.length > 0 ? "mb-2" : "" }`}>
                                            <div className="col-12 col-md text-nowrap">
                                                <b>Valor total: </b> { formattedCurrency(object.get("servicesValue")) }
                                            </div>
                                            <div className="col-12 col-md text-nowrap">
                                                <b>Desconto: </b> { formattedCurrency(object.get("discount")) }
                                            </div>
                                            <div className="col-12 col-md text-nowrap">
                                                <b>Total a Pagar: </b>  { formattedCurrency(object.get("finalValue")) }
                                            </div>
                                            <div className="col-12 col-md-auto text-nowrap">
                                                <b>Valor Restante: </b> { formattedCurrency(object.get("paymentsPending")) }
                                            </div>
                                        </div>
                                        { payments.map((payment, index) => (
                                            <div key={index}>
                                                <div className="vsbox-separator my-1"></div>
                                                <div className={`row vsbox-item-text ${ index == payments.length - 1 ? "mt-2" : "my-2" }`}>
                                                    <div className="col-12 col-md">
                                                        <b>Forma de Pagamento:</b> { getPaymentMethod(payment.type) }
                                                    </div>
                                                    <div className="col-12 col-md">
                                                        <b>Valor Pago:</b> { formattedCurrency(payment.value) }
                                                    </div>
                                                    <div className="col-12 col-md-auto">
                                                        <b>Data do Pagamento:</b> { dayjs(payment.date).format("DD/MM/YYYY") }
                                                    </div>
                                                </div>
                                            </div>
                                        )) }
                                    </div>
                                </div>
                            </div>
                        )}
                        { services.length > 0 ? (
                            <div className="row">
                                <div className="col-12">
                                    { services.map((service, index) => (
                                        <div className="vsbox-item mb-3" key={index}>
                                            <div className="row mb-2">
                                                <div className="col-12">
                                                    { service.objectId ? (
                                                        <>
                                                            <div className="vsbox-title">
                                                                ID { service.serviceId } - { service.serviceName }
                                                            </div>
                                                            <div className="vsbox-info-text">
                                                                Categoria: { service.categoryName || "Não informada" }
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <div className="vsbox-title">{ service.serviceName }</div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="vsbox-separator my-1"></div>
                                            <div className="row vsbox-item-text my-2">
                                                <div className="col">
                                                    <b>Valor do{ isQuantityGreaterThanOne(service) ? "s" : "" } Serviço{ isQuantityGreaterThanOne(service) ? "s" : "" }:</b> { formattedCurrency(service.value) }
                                                    { isQuantityGreaterThanOne(service) && (
                                                        <>&nbsp;({ formattedCurrency(service.quantity, "", 0) }x { formattedCurrency(service.valueUnit) })</>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="vsbox-separator my-1"></div>
                                            <div className="row vsbox-item-text mt-2">
                                                <div className="col-auto pre-line">
                                                    <b>Observações:</b> { service.notes || "Não informado" }
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ) : (
                            <div className="row">
                                <div className="col-12">
                                    <div className="vsbox-item mb-3">
                                        Nenhum serviço adicionado
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="row">
                            <div className="col vsbox-title">
                                Observações
                            </div>
                        </div>
                        <div className="row">
                            <div className="col mb-3 pre-line">
                                { object.get("notes") || "Não informado" }
                            </div>
                        </div>
                    </VSAccordionSimple>
                </div>
            </div>
        </>
    )
}
  
export default SaleInfoView;