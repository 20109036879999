import React, { useEffect } from "react"
import { useParams } from "react-router-dom"
import OwnerList from "../owner/OwnerList"
import PatientList from "../patient/PatientList"

import "./Search.scss"

const Search = (props) => {

    const {
        termo
    } = useParams()

    useEffect(() => {

    }, [ termo ])

    return (
        <div id="search">
            <div className="row">
                <div className="col-12 mb-3">
                    <h2>Resultados para "{ termo }"</h2>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <OwnerList owner={termo} {...props} />
                </div>
                <div className="col-12">
                    <PatientList patient={termo} {...props} />
                </div>
            </div>
        </div>
    )
}

export default Search