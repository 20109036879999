import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

import TextField from '@mui/material/TextField';

import { 
    mandatoryField
} from '../../../../utils'

import { CircularProgress } from '@mui/material';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import VSDrawer from '../../../../components/vsDrawer/VSDrawer';
import VSAccordionSimple from '../../../../components/vsAccordion/VSAccordionSimple';
import VSTextFieldCurrency from '../../../../components/vsTextFieldCurrency/VSTextFieldCurrency';

import './SaleValueForm.scss'
const SaleValueForm = (props) => {

    const {
        id_animal,
        tipo,
        id_documento
    } = useParams()

    const {
        state = {}
    } = useLocation()

    const {
        index,
        service
    } = state

    const {
        history
    } = props

    const update = false

    const [ loading, setLoading ] = useState(false)
    const [ hasSubmited, setHasSubmited ] = useState(false)
    
    const [ name, setName ] = useState(service?.serviceName || "")
    const [ errorName, setErrorName ] = useState(false)
    const [ helperName, setHelperName ] = useState(mandatoryField)
    
    const [ value, setValue ] = useState(service?.valueUnit || 0)
    const [ errorValue, setErrorValue ] = useState(false)
    const [ helperValue, setHelperValue ] = useState(mandatoryField)

    const [ notes, setNotes ] = useState(service?.notes || '')

    const quantity = service?.quantity || 1

    const validateOnTouch = () => {
        if (hasSubmited && (name || value || notes)) {
            validate()
        }
    }

    const validate = () => {
        let isValid = true
        if (loading) {
            isValid = false
        }

        if (name.length) {
            setErrorName(false)
            setHelperName(mandatoryField)
        } else {
            isValid = false
            setErrorName(true)
            setHelperName("O nome para a cobrança de \"Outros Valores\" é obrigatório")
        }

        if (value > 0) {
            setErrorValue(false)
            setHelperValue(mandatoryField)
        } else {
            isValid = false
            setErrorValue(true)
            setHelperValue("O valor precisa ser maior que zero (R$ 0,00)")
        }

        return isValid
    }

    const isValid = () => !loading && !errorName && !errorValue

    const fieldsWithErrorText = () => {
        let errors = []
        if (loading) {
            errors.push("Salvando a consulta")
        }
        if (errorName) {
            errors.push("Nome")
        }
        if (errorValue) {
            errors.push("Valor")
        }
        return errors
    }

    const save = () => {
        return new Promise((resolve, reject) => {
            setHasSubmited(true)
            if (validate()) {
                resolve()
            } else {
                reject()
            }
        })
    }

    useEffect(() => {
        validateOnTouch()
    }, [ name, value, notes ])

    const errors = fieldsWithErrorText()

    return (
        <VSDrawer
            id="sale-value-form" 
            width="60%"
            timeout={0}
            title={update ? "Editar Outros Valores" : "Adicionar Outros Valores"}
            cancel={update ? "Cancelar Alterações" : "Cancelar Registro"}
            submit={isValid ? (update ? "Salvar Alterações" : "Salvar Registro") : <span>Preencha os campos<span className="d-none d-md-inline"> necessários</span></span>}
            errors={errors}
            onSubmit={save}
            onCancel={() => history.replace(`/${tipo}/${id_animal}/${id_documento}`)}
            onAfterSave={_ => {
                const valueUnit = parseFloat(value.toFixed(2))
                history.replace(`/${tipo}/${id_animal}/${id_documento}`, {
                    index: index,
                    service: {
                        serviceName: name,
                        value: valueUnit * quantity,
                        valueUnit: valueUnit,
                        quantity: quantity,
                        notes: notes,
                        supplies: []
                    }
                })
            }}
        >
            { loading ? (
                <div className="row data-loading">
                    <div className="col">
                        <CircularProgress />
                    </div>
                </div>
            ) : (
                <>
                    <div className="row">
                        <div className="col">
                            <VSAccordionSimple
                                expanded={true}
                                header={(_) => (
                                    "Identificação"
                                )}
                            >
                                <div className="row">
                                    <div className="col-12 mb-3">
                                        <TextField
                                            placeholder="Ex: Frete de retirada do animal"
                                            label="Nome"
                                            value={name}
                                            onChange={({ target: { value } }) => setName(value)}
                                            error={errorName}
                                            helperText={helperName}
                                            size="small"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <VSTextFieldCurrency
                                            value={value}
                                            onChangeValue={(event, originalValue, maskedValue) => {
                                                // parseInt prevents 1.00000001 bug
                                                setValue(parseFloat(originalValue)) 
                                            }}
                                            InputElement={(
                                                <TextField
                                                    placeholder="R$ 0,00"
                                                    label="Valor Unitário"
                                                    error={errorValue}
                                                    helperText={helperValue}
                                                    size="small"
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                            </VSAccordionSimple>
                        </div>
                    </div>
                    <hr className="my-2" />
                    <div className="row">
                        <div className="col">
                            <VSAccordionSimple
                                expanded={true}
                                header={(_) => (
                                    "Observações"
                                )}
                            >
                                <div className="row">
                                    <div className="col-12">
                                        <TextField
                                            placeholder="Digite as observações"
                                            label="Observações"
                                            value={notes}
                                            onChange={({ target: { value } }) => setNotes(value)}
                                            helperText={<span>Campo Opcional<br/></span>}
                                            size="small"
                                            variant="outlined"
                                            multiline
                                            fullWidth
                                        />
                                    </div>
                                    <div className="col input-info">
                                        As observações são anotações particulares. <b>Elas nunca serão compartilhadas com o tutor.</b>
                                    </div>
                                </div>
                            </VSAccordionSimple>
                        </div>
                    </div>
                </>
            )}
        </VSDrawer>
    )
}

const mapStateToProps = state => {
    return {
        clinic: state.clinic
    };
};
  
export default connect(mapStateToProps)(SaleValueForm)