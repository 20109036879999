import { ReactComponent as BrandEmpty } from "../../assets/brand_empty.svg"
import { ReactComponent as BrandMastercard } from "../../assets/brand_mastercard.svg"
import { ReactComponent as BrandVisa } from "../../assets/brand_visa.svg"
import { ReactComponent as BrandAmericanExpress } from "../../assets/brand_american_express.svg"
import { ReactComponent as BrandElo } from "../../assets/brand_elo.svg"
import { ReactComponent as BrandJcb } from "../../assets/brand_jcb.svg"
import { ReactComponent as BrandDinersClub } from "../../assets/brand_diners_club.svg"
import { ReactComponent as BrandHipercard } from "../../assets/brand_hipercard.svg"
import VSModal from "../../components/vsModal/VSModal"
import SubscriptionCancelForm from "./SubscriptionCancelForm"
import { dayjs, formattedCurrency, saveOnTracker } from "../../utils"

export const reasonsOptions = [
    { text: "Sem condições financeiras para continuar com a assinatura" },
    { text: "Problemas técnicos" },
    { text: "Razões pessoais" },
    { text: "Insatisfação com o serviço oferecido" },
    { text: "Ausência de Suporte" },
    { 
        text: "Utilizo outro sistema",
        field: {
            label: "Nome do sistema",
            placeholder: "Digite aqui qual outro sistema você utiliza",
            validation: (value) => value?.length > 2
        }
    },
    { 
        text: "Outro motivo",
        field: {
            label: "Motivo",
            placeholder: "Digite aqui seu motivo",
            validation: (value) => value?.length > 2
        }
    }
]

export const BRAND_EMPTY = "empty"
export const BRAND_VISA = "visa"
export const BRAND_MASTERCARD = "mastercard"
export const BRAND_AMERICAN_EXPRESS = "american_express"
export const BRAND_DINERS_CLUB = "diners_club"
export const BRAND_ELO = "elo"
export const BRAND_HIPERCARD = "hipercard"
export const BRAND_JCB = "jcb"

export const PRO_MONTHLY = "prontuario_pro_monthly"
export const PRO_MONTHLY_TITLE = "Prontuario Pro - Mensal"
export const PRO_MONTHLY_VALUE_BY_MONTH = 49.90
export const PRO_MONTHLY_VALUE_BY_YEAR = PRO_MONTHLY_VALUE_BY_MONTH * 12
export const PRO_YEARLY = "prontuario_pro_yearly"
export const PRO_YEARLY_TITLE = "Prontuario Pro - Anual"
export const PRO_YEARLY_BY_MONTH = 39.90
export const PRO_YEARLY_BY_YEAR = PRO_YEARLY_BY_MONTH * 12

export const STATUS_FUTURE = "future"
export const STATUS_ACTIVE = "active"
export const STATUS_PAID = "paid"
export const STATUS_CANCELED = "canceled"
export const STATUS_PENDING = "pending"

export const brandOptions = [
    {
        label: <BrandMastercard width={60} height={40} />,
        value: BRAND_MASTERCARD
    },
    {
        label: <BrandVisa width={60} height={40} />,
        value: BRAND_VISA
    },
    {
        label: <BrandAmericanExpress width={60} height={40} />,
        value: BRAND_AMERICAN_EXPRESS
    },
    {
        label: <BrandElo width={60} height={40} />,
        value: BRAND_ELO
    },
    {
        label: <BrandHipercard width={60} height={40} />,
        value: BRAND_HIPERCARD
    },
    {
        label: <BrandJcb width={60} height={40} />,
        value: BRAND_JCB
    },
    {
        label: <BrandDinersClub width={60} height={40} />,
        value: BRAND_DINERS_CLUB
    },
    {
        label: <BrandEmpty width={60} height={40} />,
        value: BRAND_EMPTY
    }
]

export const getInstallmentsOfProntuarioProYearly = () => {
    return Array.from(Array(13).keys()).map((installment, index) => ({
        label: installment != 0 ? `${ installment }x de ${ formattedCurrency(PRO_YEARLY_BY_YEAR / installment) } sem juros` : "",
        value: installment != 0 ? String(installment) : ""
    }))
}

export const getBrandIcon = (brand, props) => {
    const Icon = brand == BRAND_MASTERCARD ? BrandMastercard
        : brand == BRAND_VISA ? BrandVisa
        : brand == BRAND_AMERICAN_EXPRESS ? BrandAmericanExpress
        : brand == BRAND_ELO ? BrandElo
        : brand == BRAND_HIPERCARD ? BrandHipercard
        : brand == BRAND_JCB ? BrandJcb
        : brand == BRAND_DINERS_CLUB ? BrandDinersClub
        : BrandEmpty
    return <Icon {...props} />
}

export const getBrand = (cardNumberDigits) => {
    // https://gist.github.com/claudiosanches/26d9668f21dbdc787472
    // https://www.cardfellow.com/blog/new-mastercard-bins/
    switch (true) {
        case /^(4011\d{0,12}|431274\d{0,10}|438935\d{0,10}|451416\d{0,10}|457393\d{0,10}|4576\d{0,12}|457631\d{0,10}|457632\d{0,10}|504175\d{0,10}|50(4175|6699|67[0-6][0-9]|677[0-8]|9[0-8][0-9]{2}|99[0-8][0-9]|999[0-9])\d{0,10}|627780\d{0,10}|636297\d{0,10}|636368\d{0,10}|636369\d{0,10}|(6503[1-3])\d{0,11}|(6500(3[5-9]|4[0-9]|5[0-1]))\d{0,10}|(6504(0[5-9]|1[0-9]|2[0-9]|3[0-9]))\d{0,10}|(650(48[5-9]|49[0-9]|50[0-9]|51[1-9]|52[0-9]|53[0-7]))\d{0,10}|(6505(4[0-9]|5[0-9]|6[0-9]|7[0-9]|8[0-9]|9[0-8]))\d{0,10}|(6507(0[0-9]|1[0-8]))\d{0,10}|(6507(2[0-7]))\d{0,10}|(650(90[1-9]|91[0-9]|920))\d{0,10}|(6516(5[2-9]|6[0-9]|7[0-9]))\d{0,10}|(6550(0[0-9]|1[1-9]))\d{0,10}|(6550(2[1-9]|3[0-9]|4[0-9]|5[0-8]))\d{0,10}|(506(699|77[0-8]|7[1-6][0-9]))\d{0,10}|(509([0-9][0-9][0-9]))\d{0,10})$/.test(cardNumberDigits):
            return BRAND_ELO
        case /^(5[1-5]\d{0,4}|2[2-7]\d{0,4}|677189)\d{0,10}$/.test(cardNumberDigits):
            return BRAND_MASTERCARD
        case /^4\d{0,12}(\d{0,3})$/.test(cardNumberDigits):
            return BRAND_VISA
        case /^3[47]\d{0,13}$/.test(cardNumberDigits):
            return BRAND_AMERICAN_EXPRESS
        case /^3(0[0-5]|[68]\d)\d{0,11}$/.test(cardNumberDigits):
            return BRAND_DINERS_CLUB
        case /^(?:2131|1800|35\d{0,3})\d{0,11}$/.test(cardNumberDigits):
            return BRAND_JCB
        case /^(606282\d{0,10}(\d{0,3})?)|(3841\d{0,15})$/.test(cardNumberDigits):
            return BRAND_HIPERCARD
        default:
            return BRAND_EMPTY
    }
}

export const getStatus = (subscription) => {
    switch(subscription?.status) {
        case STATUS_ACTIVE:
            return "Ativa"
        case STATUS_FUTURE:
            const text = subscription?.metadata?.createdFromSubscription ? "Inicia em" : "Grátis até"
            return `${text} ${ dayjs(subscription?.next_billing_at).format("DD/MM/YYYY") }`
        case STATUS_CANCELED:
            return "Cancelada"
        default:
            return subscription?.status
    }
}

export const deleteSubscription = (clinic, subscription, callback) => {
    const clinicName = clinic.get("name")

    Swal.fire({
        // allowOutsideClick: false,
        showConfirmButton: false,
        customClass: {
            popup: 'p-0',
            content: 'p-0',
        },
        html: (
            <SubscriptionCancelForm
                onConfirm={(reasons) => {
                    Swal.close()

                    Swal.fire({
                        showConfirmButton: false,
                        customClass: {
                            popup: 'p-0',
                            content: 'p-0',
                        },
                        html: (
                            <VSModal
                                title="Tem certeza que deseja cancelar a assinatura?"
                                text="Ao cancelar sua assinatura, você perderá acesso às funcionalidades do Prontuário Pro e o acesso aos registros criados a partir delas após o término do período contratado."
                                textConfirm={<>Digite o <b>nome da clínica</b> para cancelar a assinatura</>}
                                placeholderConfirm={`Digite o Nome da Clínica: ${clinicName}`}
                                compareTo={clinicName}
                                options={[
                                    {
                                        type: "cancel",
                                        text: "Cancelar Operação",
                                        action: () => {
                                            Swal.close()
                                        }
                                    },
                                    {
                                        type: "confirm",
                                        text: "Confirmar cancelamento",
                                        action: () => {
                                            Parse.Cloud.run("unsubscribeProntuarioPro", {
                                                clinicId: clinic.id,
                                                comments: reasons,
                                                subscriptionId: subscription?.id
                                            })
                                            .then(_ => {
                                                saveOnTracker("Concluiu", "Assinatura", String(subscription?.id), "Cancelamento da assinatura", null, null, null, reasons.join("; "))
                                                Swal.fire(
                                                    'Excluído!',
                                                    'A assinatura foi cancelada com sucesso.',
                                                    'success'
                                                )

                                                callback && callback()

                                            })
                                            .catch(error => {
                                                console.error(error)
                                                Swal.fire(
                                                    'Desculpe',
                                                    error.code == 141 ? error.message : 'Não foi possível cancelar a assinatura, tente novamente mais tarde',
                                                    'error'
                                                )
                                            })
                                        }
                                    }
                                ]}
                            />
                        )
                    })
                }} 
            />
        )
    })
}

export const getCustomer = (clinicId) => {
    return new Promise((resolve, reject) => {
        Parse.Cloud.run("getVindiCustomer", {
            clinicId: clinicId
        })
        .then(response => {
            resolve(response)
        })
        .catch(error => {
            console.error(error)
            reject('Ocorreu algum erro ao buscar os dados da assinatura, tente novamente mais tarde')
        })
    })
}