import React from 'react'

import { redirectToCheckout, saveOnTracker, saveTrackersProSubscription } from '../../utils';
import { ReactComponent as IconHomeAssistant } from "../../assets/icon-home-assistant.svg"

const HomeAssistant = (props) => {

    const {
        clinic,
        pro,
        history
    } = props

    return (
        <div className="vsbox card-pro-advertising">
            <div className="vsbox-body">
                <div className="row align-items-center mb-2">
                    <div className="col">
                        <div className="vsbox-title mb-1">Assistente Vetsmart <span className="va-beta-text">Beta</span></div>
                    </div>
                    <div className="col-auto">
                        <div className="new-tag">Novo</div>
                    </div>
                </div>
                <div className="row g-3">
                    <div className="col-auto">
                        <IconHomeAssistant style={{borderRadius: 10}} />
                    </div>
                    <div className="col">
                        { pro?.access ? (
                            <div className="mb-2">Você é nosso convidado para testar a nossa mais nova ferramenta, disponível para você até o dia <b>20/10</b>, aproveite!</div>
                        ) : !pro?.trialEndsAt ? (
                            <div className="mb-2">Experimente o PRO e aproveite para testar a nossa mais nova ferramenta. O assistente digital que dará um apoio extra no seu dia-a-dia. Disponível até o dia <b>20/10</b>, aproveite!</div>
                        ) : (
                            <div className="mb-2">Assine o PRO e aproveite para testar a nossa mais nova ferramenta. O assistente digital que dará um apoio extra no seu dia-a-dia. Disponível até o dia <b>20/10</b>, aproveite!</div>
                        )}
                        <a 
                            style={{ 
                                cursor: "pointer",
                                fontSize: "14px",
                                fontWeight: 600
                            }} 
                            onClick={() => {
                                if(pro?.access){
                                    history.push("/assistente")
                                    saveOnTracker("Clicou", "Home Início", "Assistente")
                                } else {
                                    saveTrackersProSubscription(pro)
                                    redirectToCheckout(clinic)
                                }
                            }}
                        >
                            { pro?.access ? "Começar agora!" : 
                            !pro?.trialEndsAt ? "Experimentar o PRO 15 dias grátis" : 
                            "Assinar o Prontuário PRO"}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}
  
export default HomeAssistant;