// utils for Supply like getSupply, deleteSupply, getCategories, etc
import axios from 'axios';
import { APP_DEVICE, APP_NAME, APP_VERSION } from '../../constants';

export const deleteSupply = (supply, callback) => {
    Swal.fire({
        title: 'Tem certeza?',
        text: "Você tem certeza que deseja excluir esse insumo? Essa ação não poderá ser desfeita.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, quero excluir!',
        cancelButtonText: 'Não, manter insumo'
    }).then((result) => {
        if (result.isConfirmed) {
            supply.set("isDeleted", true)
            supply.set("changedAt", new Date())
            supply.set("changedBy", Parse.User.current())
            supply.set("changedApp", APP_NAME)
            supply.set("changedDevice", APP_DEVICE)
            supply.set("changedAppVersion", APP_VERSION)
            supply.save()
            .then(_ => {
                Swal.fire(
                    'Excluído!',
                    'O insumo foi excluído com sucesso.',
                    'success'
                )
                .then(_ => {
                    callback && callback()
                })
            })
            .catch(error => {
                console.error(error)
                Swal.fire(
                    'Desculpe',
                    'Ocorreu algum erro ao tentar excluir o insumo',
                    'error'
                )
                supply.revert()
            })
        }
    })
}

var cachedUnits = []
var loadingUnits = false
export const getUnits = () => {
    return new Promise(resolve => {
        if (cachedUnits.length > 0)
            return resolve(cachedUnits)

        if (!loadingUnits) {
            loadingUnits = true
            axios.get(`${process.env.REACT_APP_API_V2}/json/supplies/units?unordered`)
            .then(({ data }) => {
                cachedUnits = data.length > 0 ? data : []
                return resolve(cachedUnits)
            })
        } else {
            return waitUntilUnitsFilled(resolve)
        }
    })
}

const waitUntilUnitsFilled = (resolver) => {
    if (cachedUnits?.length > 0) {
        return resolver(cachedUnits)
    }
    setTimeout(() => {
        waitUntilUnitsFilled(resolver)
    }, 1000)
}

export const toFloat = (value) => {
    return Math.round(parseFloat(value) * 1000000) / 1000000
}

export const getConvertedQuantity = (quantity, units, currentUnit, convertForUnit) => {
    if (currentUnit !== convertForUnit) {
        const convertUnit = units.find(u => u.id == convertForUnit)?.conversions?.[currentUnit]
        if (currentUnit) {
            return toFloat(toFloat(quantity) * parseFloat(convertUnit))
        }
    }
    return toFloat(quantity)
}

export const getSupplyTransactionObject = (sale, supply, quantity, serviceName, quantityOfService = 1) => {
    const supplyTransaction = new Parse.Object("MRSupplyTransaction")
    supplyTransaction.set("clinic", sale.get("clinic"))
    supplyTransaction.set("transactionDate", new Date())
    supplyTransaction.set("createdBy", Parse.User.current())
    supplyTransaction.set("createdApp", APP_NAME)
    supplyTransaction.set("createdDevice", APP_DEVICE)
    supplyTransaction.set("createdAppVersion", APP_VERSION)
    supplyTransaction.set("supply", supply);
    supplyTransaction.set("quantity", quantity);
    supplyTransaction.set("notes", `${quantity < 0 ? "Saída" : "Entrada"}: ${serviceName}${quantityOfService > 1 ? ` (${quantityOfService}x)` : ""}`);
    supplyTransaction.set("changedAt", new Date())
    supplyTransaction.set("changedBy", Parse.User.current())
    supplyTransaction.set("changedApp", APP_NAME)
    supplyTransaction.set("changedDevice", APP_DEVICE)
    supplyTransaction.set("changedAppVersion", APP_VERSION)
    supplyTransaction.set("sale", sale)
    return supplyTransaction
}

export const getStockSupply = (supplyId, clinicObject) => {
    return new Promise((resolve, reject) => {
        var querySupply = new Parse.Query("VW_MRSupplyTransaction_stock");
        querySupply.equalTo("clinic", clinicObject);
        querySupply.equalTo("isDeleted", false);
        querySupply.equalTo("supply", {
            __type: "Pointer",
            className: "MRSupply",
            objectId: supplyId
        });
        querySupply.include("supply")
        querySupply.include("supply.category")
        querySupply.include("supply.createdBy")
        querySupply.include("supply.changedBy")
        querySupply.first()
        .then(_supply => {
            resolve(_supply)
        })
        .catch(error => {
            console.error(error);
            reject("Ocorreu algum erro ao tentar buscar o insumo")
        })
    })
}

export const supplyActiveOptions = [
    {
        value: true,
        label: "Ativo",
        long: "Insumo ativo"
    },
    {
        value: false,
        label: "Inativo",
        long: "Insumo inativo"
    }
]