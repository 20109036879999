import React, { useState } from 'react'
import { ReactComponent as Logo } from "../../assets/vetsmart.svg"
import { ReactComponent as IconNews } from "../../assets/icon-news.svg"
import { saveOnTracker } from '../../utils'

import "./HomeNews.scss"
const HomeNews = (props) => {
    const {
        history
    } = props

    return (
        <div className="home-news vsbox mb-3 p-3">
            <div className="row align-items-center flex-column flex-md-row">
                <div className="col-12 col-md-5 order-2 order-md-1 mt-3 mt-md-0 mb-5 mb-md-0">
                    <div className="home-news-title">Confira as novas funcionalidades que chegaram no Prontuário</div>
                </div>
                <div className="col-auto col-md-2 order-1 order-md-2">
                    <div className="home-news-vslogo">
                        <Logo width="77px" height="14px" />
                    </div>
                </div>
                <div className="col-3 d-none d-md-block order-3">
                    <div className="home-news-icon">
                        <IconNews />
                    </div>
                </div>
                <div className="col-12 col-md-2 order-4">
                    <button className="vsbox-btn btn-outline-submit btn-square w-100" onClick={() => {
                        saveOnTracker("Clicou", "Home Início", "Saiba mais - Banner Novidades")
                        history.push("/novidades")
                    }}>
                        Saiba mais
                    </button>
                </div>
            </div>
        </div>
    )
}

export default HomeNews;