import { TextField } from "@mui/material"
import { Autocomplete } from "@mui/lab"
import { useEffect, useState } from "react"
import { getVaccineProducts } from "../Vaccine"

const VaccineProductField = (props) => {

    const {
        setVaccineId,
        vaccineProduct,
        setVaccineProduct
    } = props

    const [ vaccineOptions, setVaccineOptions ] = useState([])

    useEffect(() => {
        getVaccineProducts()
        .then(products => {
            setVaccineOptions(products)
        })
    }, [])

    return (
        <div className="row">
            <div className="col-12">
                <div className="input-subtitle mb-1">
                    Nome do Produto
                </div>
            </div>
            <div className="col-12">
                <Autocomplete
                    onChange={(_, newValue) => {
                        setVaccineId((newValue && newValue.itemId) || "")
                        setVaccineProduct((newValue && newValue.itemName) || "")
                    }}
                    onInputChange={(event, newInputValue) => {
                        if (event) {
                            setVaccineId("")
                            setVaccineProduct(newInputValue || "")
                        }
                    }}
                    getOptionLabel={(option) => (option && option.itemName) || option}
                    inputValue={vaccineProduct || ""}
                    options={vaccineOptions}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            placeholder="Selecione a vacina"
                            helperText={<span>Campo Opcional</span>}
                            size="small"
                            variant="outlined"
                            fullWidth
                        />
                    )}
                    openOnFocus
                    freeSolo
                />
            </div>
        </div>
    )
}

export default VaccineProductField