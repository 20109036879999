import React, { useEffect, useRef } from 'react'
import { connect } from 'react-redux'

import { ReactComponent as IconCalendar } from "../../../../assets/icon-calendar.svg"
import { ReactComponent as IconAdd } from "../../../../assets/icon-primary-add-o.svg"

import { ACL, OrderBy_Oldest, OrderBy_Newest } from '../../../../constants'

import CalendarView from './CalendarView'
import VSEmpty from '../../../../components/vsEmpty/VSEmpty'
import VSList from '../../../../components/vsList/VSList'

import './CalendarList.scss'
const CalendarList = (props) => {

    const {
        clinic,
        patient,
        history,
        permissions
    } = props
    
    const rowsPerPage = 5

    const filter = useRef(null)
    const isMounted = useRef(null)

    const getCalendars = (_, dateFrom, dateTo, orderBy, page) => {
        return new Promise((resolve, reject) => {
            var query = new Parse.Query("MRCalendar")
            query.equalTo("clinic", clinic.object);
            query.equalTo("isDeleted", false);
            query.equalTo("patient", patient);
            if (dateFrom) {
                query.greaterThanOrEqualTo("startDate", dateFrom)
            }
            if (dateTo) {
                query.lessThanOrEqualTo("startDate", dateTo)
            }
            switch (orderBy) {
                case OrderBy_Oldest:
                    query.ascending("startDate");
                    break;
                default:
                    query.descending("startDate");
            }
            query.addAscending("objectId")
            query.withCount()
            query.skip((page - 1) * rowsPerPage)
            query.limit(rowsPerPage)
            query.include("createdBy")
            query.include("changedBy")
            query.include("patient")
            query.include("patient.owner")
            query.include("user")
            query.find()
            .then(({ results, count }) => {
                if (isMounted.current) {
                    resolve({
                        items: results || [],
                        total: count
                    })
                }
            })
            .catch(error => {
                console.error(error)
                reject(error)
            })
        })
    }
    
    const onChange = () => {
        if (filter.current) {
            filter.current.onChange()
        }
    }

    useEffect(() => {
        if (permissions && !permissions.check(ACL.CALENDAR, ACL.Rule.VIEW)) {
            permissions.error()
        }
    }, [ permissions ])

    useEffect(() => {
        isMounted.current = true
        document.addEventListener("Calendar__getList", onChange)
        return () => {
            isMounted.current = false
            document.removeEventListener("Calendar__getList", onChange)
        }
    }, [])

    return (
        <div id="patient-calendar">
            <div className="col-12">
                <VSList
                    ref={filter}
                    title={{
                        text: "Agenda"
                    }}
                    button={permissions?.check(ACL.CALENDAR, ACL.Rule.EDIT) && {
                        text: "Novo Evento",
                        onClick: () => history.push(`/agenda/novo`, {
                            id: patient.id
                        })
                    }}
                    interval={{
                        start: {
                            text: "Início",
                            value: null
                        },
                        end: {
                            text: "Término",
                            value: null
                        }
                    }}
                    order={{
                        options: [
                            OrderBy_Newest,
                            OrderBy_Oldest
                        ],
                        value: OrderBy_Newest
                    }}
                    pagination={{
                        rowsPerPage: rowsPerPage
                    }}
                    onChange={({ _, _start, _end, _orderBy, _page }) => {
                        return getCalendars(null, _start, _end, _orderBy, _page)
                    }}
                    renderItem={(item) => (
                        <CalendarView
                            object={item}
                            history={history}
                            noAudit
                        />
                    )}
                    renderEmpty={() => (
                        <VSEmpty
                            icon={<IconCalendar width="32px" height="32px" />}
                            title="Você ainda não possui nenhum registro de evento para este animal"
                            text="Realize o primeiro evento e comece a acompanhar de perto a evolução do seu paciente"
                        >
                            { permissions?.check(ACL.CALENDAR, ACL.Rule.EDIT) && (
                                <button
                                    id="new-calendar-link"
                                    className="vsbox-btn vsbox-btn-primary"
                                    onClick={() => history.push(`/agenda/novo`, {
                                        id: patient.id
                                    })}
                                >
                                    <IconAdd width="14" height="14" className="me-2" />
                                    Cadastrar novo evento
                                </button>
                            )}
                        </VSEmpty>
                    )}
                    renderNotFound={() => (
                        <VSEmpty
                            icon={<IconCalendar width="32px" height="32px" />}
                            title="Não encontramos nenhum resultado para a sua busca"
                            text="Tente refazer sua busca utilizando outro período ou crie um novo cadastro utilizando o botão abaixo"
                        >
                            { permissions?.check(ACL.CALENDAR, ACL.Rule.EDIT) && (
                                <button
                                    id="new-exam-link"
                                    className="vsbox-btn vsbox-btn-primary"
                                    onClick={() => history.push(`/agenda/novo`, {
                                        id: patient.id
                                    })}
                                >
                                    <IconAdd width="14" height="14" className="me-2" />
                                    Cadastrar novo evento
                                </button>
                            )}
                        </VSEmpty>
                    )}
                />
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        clinic: state.clinic,
        permissions: state.permissions
    }
}
  
export default connect(mapStateToProps)(CalendarList)