import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'

import { TableCell, TableRow, CircularProgress } from '@mui/material'

import { showVaccine } from '../patient/tabs/vaccine/Vaccine'
import HomeData from './HomeData'
import { dayjs } from '../../utils'
import VSMenu from '../../components/vsMenu/VSMenu'

import { ReactComponent as IconMenu } from "../../assets/icon-menu-2.svg"
import { ReactComponent as IconUser } from "../../assets/icon-user-menu.svg"
import { ReactComponent as IconPaw } from "../../assets/icon-paw-menu.svg"

import './HomeVaccineRemindersNext.scss'
const HomeVaccineRemindersNext = (props) => {

    const {
        clinic,
        expanded,
        history
    } = props

    const [ loading, setLoading ] = useState(true)
    const [ loadingCount, setLoadingCount ] = useState(true)
    const [ reminders, setReminders ] = useState([])
    const [ headerCount, setHeaderCount ] = useState(null)

    const rowsPerPage = 5

    useEffect(() => {
        setLoadingCount(true)

        const queryVaccine = new Parse.Query("Vaccine");
        queryVaccine.equalTo("isDeleted", false)
        queryVaccine.equalTo("isReminder", true)
        queryVaccine.equalTo("clinic", clinic.object)
        queryVaccine.greaterThanOrEqualTo("documentDate", dayjs().startOf('day').toDate())
        queryVaccine.count()
        .then(totalVaccine => {
            if(totalVaccine){
                setHeaderCount(totalVaccine)
            } else {
                setHeaderCount(0)
            }
            setLoadingCount(false)
        })
    }, [])

    return (
        <div id="home-vaccine-reminders-next" className="col-12 mb-3">
            <HomeData
                expanded={expanded}
                headerIcon={
                    loadingCount ? 
                    <CircularProgress size={20} color="primary" /> :
                    <div className="count-text">{headerCount}</div>
                }
                title={!loading && headerCount == 1 ? "Vacina Programada" : "Vacinas Programadas"}
                emptyText="Não há nenhuma vacina programada registrada"
                loading={loading}
                data={reminders}
                rowsPerPage={rowsPerPage}
                onChange={(page) => {
                    if (clinic && clinic.isLoaded && clinic.object) {
                        setReminders([])
                        setLoading(true)

                        const query = new Parse.Query("Vaccine");
                        query.equalTo("isDeleted", false)
                        query.equalTo("isReminder", true)
                        query.equalTo("clinic", clinic.object)
                        query.greaterThanOrEqualTo("documentDate", new Date())
                        query.ascending("documentDate")
                        query.include("patient")
                        query.include("patient.owner")
                        query.limit(15)
                        query.find()
                        .then(_reminders => {
                            setReminders({
                                count: _reminders?.length || 0,
                                results: _reminders || []
                            })
                            setLoading(false)
                        })
                        .catch(error => {
                            console.error(error)
                            setLoading(false)
                        })
                    }
                }}
                renderItem={(reminder, index) => {
                    const patient = reminder?.get("patient")
                    const owner = patient?.get("owner")
                    return (
                        <TableRow
                            key={index}
                        >
                            <TableCell
                                style={{ width: '86px' }}
                            >
                                <div className="text-truncate">
                                    { dayjs(reminder?.get("documentDate")).format("DD/MM/YYYY") }
                                </div>
                            </TableCell>
                            <TableCell className="px-2">
                                <div className="text-link d-inline" onClick={() => history.push(`/animal/${patient?.id}/vacina/${reminder?.id}/ver`)}>
                                    { showVaccine(reminder, false) }
                                </div> para { patient?.get("name") }, Tutor { owner?.get("name") }
                            </TableCell>
                            <TableCell style={{ width: '24px' }} className="ms-2 flex-shrink-0">
                                <VSMenu
                                    placement="left-start"
                                    openOnHover
                                    style={{background: "#fff"}}
                                    options={[
                                        {
                                            menu: (
                                                <div className="row align-items-center">
                                                    <div className="col-auto">
                                                        <IconPaw width="24" height="24" fill="var(--vsprontuario-primary-color)" />
                                                    </div>
                                                    <div className="col ps-0">
                                                        Ver Animal
                                                    </div>
                                                </div>
                                            ),
                                            onClick: () => history.push(`/animal/${patient.id}`)
                                        },
                                        {
                                            menu: (
                                                <div className="row align-items-center">
                                                    <div className="col-auto">
                                                        <IconUser width="24" height="24" fill="var(--vsprontuario-primary-color)" />
                                                    </div>
                                                    <div className="col ps-0">
                                                        Ver Tutor
                                                    </div>
                                                </div>
                                            ),
                                            onClick: () => history.push(`/tutor/${owner.id}`)
                                        }
                                    ]}
                                >
                                    <IconMenu width="24" height="24" fill="var(--vsprontuario-primary-color)" />
                                </VSMenu>
                            </TableCell>
                        </TableRow>
                    )
                }}
                footer={(
                    <div className="col-12 col-md mt-3 mt-md-0">
                        <div onClick={() => history.push('/vacina?proximas')} className="btn-outline-submit btn-square cursor-pointer">
                            Ver Próximas Vacinas
                        </div>
                    </div>
                )}
            />
        </div>
    )
}

const mapStateToProps = state => {
    return {
        clinic: state.clinic
    };
};
  
export default connect(mapStateToProps)(HomeVaccineRemindersNext);