import React, { useEffect, useState } from "react"

import "./CardPermission.scss"
import VSRadio from "../vsRadio/VSRadio";
const CardPermission = (props) => {
    const {
        title,
        text,
        options,
        disabled,
        value,
        onChange
    } = props

    return (
        <div className={`vsbox${disabled ? " vsbox-disabled" : ""} h-100`}>
            <div className="vsbox-body p-3 mb-0">
                <div className="row">
                    <div className="col-12 mb-2">
                        <div className="input-array">{ title }</div>
                    </div>
                    <div className="col-12">
                        { disabled ? (
                            <p className="text-warning">{ disabled }</p>
                        ) : (
                            <p className="text-secondary">{ text }</p>
                        )}
                    </div>
                    <div className="col-12">
                        <VSRadio
                            className="radio-options"
                            disabled={Boolean(disabled)}
                            options={options}
                            value={value}
                            highlight
                            onChange={(_, value) => {
                                onChange(value)
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CardPermission;