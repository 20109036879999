import React from 'react';

import { Skeleton } from '@mui/lab';

import "./CardFinance.scss"
const CardFinance = (props) => {

    const {
        icon,
        title,
        value,
        valueColor,
        loading
    } = props

    return (
        <div id="card-finance">
            <div className="row align-items-center">
                <div className="col-auto">
                    <div className="card-finance-icon">
                        { icon }
                    </div>
                </div>
                <div className="col">
                    <div className="card-finance-title">
                        { loading ? (
                            <Skeleton variant="text" height={22} style={{ transform: "scale(1, 0.80)" }} />
                        ) : (
                            title
                        )}
                    </div>
                    <div className="card-finance-value" style={{ color: valueColor || "rgba(0, 0, 0, 0.8)" }}>
                        { loading ? (
                            <Skeleton variant="text" height={28} style={{ transform: "scale(1, 0.80)" }} />
                        ) : (
                            value
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CardFinance;