import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

import TextField from '@mui/material/TextField';
import { CircularProgress, InputAdornment } from '@mui/material';

import { ReactComponent as IconAddO } from "../../../../assets/icon-primary-add-o.svg"
import { ReactComponent as IconArrowLeft } from "../../../../assets/icon-arrow-left.svg"
import { ReactComponent as IconCheckO } from "../../../../assets/icon-check-o.svg"
import { ReactComponent as IconSearch } from "../../../../assets/icon-search.svg"
import { ReactComponent as IconUserO } from "../../../../assets/icon-user-o.svg"

import { 
    diacriticSensitive, formattedCurrency
} from '../../../../utils'
import VSEmpty from '../../../../components/vsEmpty/VSEmpty';
import VSDrawer from '../../../../components/vsDrawer/VSDrawer';

var searchBy = ""

import './SaleServiceForm.scss'
const SaleServiceForm = (props) => {

    const {
        id_animal,
        tipo,
        id_documento
    } = useParams()

    const {
        text,
        clinic,
        history
    } = props

    const [ query, setQuery ] = useState("")
    const [ services, setServices ] = useState([])
    const [ hasService, setHasService ] = useState(true)
    const [ loading, setLoading ] = useState(false)

    const findServices = () => {
        return new Promise((resolve, reject) => {
            const queryById = new Parse.Query("MRService")
            queryById.equalTo("clinic", clinic.object)
            queryById.matches("serviceId", diacriticSensitive(query), "im")

            const queryByName = new Parse.Query("MRService")
            queryByName.equalTo("clinic", clinic.object)
            queryByName.matches("serviceName", diacriticSensitive(query), "im")

            const mainQuery = Parse.Query.or(
                queryById,
                queryByName
            )
            mainQuery.equalTo("clinic", clinic.object);
            mainQuery.equalTo("isDeleted", false);
            mainQuery.equalTo("active", true);
            mainQuery.ascending("serviceName");
            mainQuery.include("category");
            mainQuery.include("supplies.object");
            mainQuery.find()
            .then(services => {
                if (searchBy == query) {
                    resolve(services)
                }
            }).catch(error => {
                console.error(error)
                reject(error)
            })
        })
    }

    useEffect(() => {
        setLoading(true)
        setServices([])

        searchBy = query
        if (query) {
            findServices()
            .then(_services => {
                setServices(_services)
                setLoading(false)
            })
        }
    }, [ query ])

    useEffect(() => {
        if (clinic && clinic.object) {
            setLoading(true)
            const serviceTotal = new Parse.Query("MRService");
            serviceTotal.equalTo("clinic", clinic.object);
            serviceTotal.equalTo("isDeleted", false);
            serviceTotal.count()
            .then(_total => {
                setHasService(_total > 0)
                setLoading(false)
            })
            .catch(error => console.error(error))
        }
    }, [ clinic ])

    return (
        <VSDrawer
            id="service-query"
            width="60%"
            title={"Adicionar Serviço"}
            onCancel={() => history.replace(`/${tipo}/${id_animal}/${id_documento}`)}
            VSDrawerFooterProps={{
                show: false
            }}
        >
            <div className="vssearch">
                <div className="vssearch-area">
                    <TextField
                        className="vssearch-input"
                        placeholder="Buscar Serviço"
                        variant="outlined"
                        size="small"
                        value={query}
                        onChange={({ target: { value} }) => setQuery(value)}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">
                                <IconSearch width="20px" height="20px" fill="#888888" />
                            </InputAdornment>,
                        }}
                        fullWidth
                    />
                </div>
                { hasService ? (
                    query ? (
                        <div className="service-search">
                            { !loading && services.length == 0 && (
                                <div className="item item-none">
                                    <div className="row">
                                        <div className="col-12">
                                            Nenhum Serviço Encontrado
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className="item-custom" onClick={() => {
                                history.replace(`/${tipo}/${id_animal}/${id_documento}/valor`, {
                                    // index: index,
                                    service: {
                                        serviceName: query
                                    }
                                })
                            }}>
                                <div className="row align-items-center">
                                    <div className="col">
                                        Adicionar Serviço "{ query }"
                                    </div>
                                    <div className="col-auto">
                                        <IconArrowLeft width="24px" height="24" className="float-right" fill="var(--vsprontuario-primary-color)" />
                                    </div>
                                </div>
                            </div>
                            { loading ? (
                                <div className="item item-none" style={{ borderBottom: "none" }}>
                                    <div className="row">
                                        <div className="col-12 text-center">
                                            <div className="mt-4 data-loading">
                                                <CircularProgress />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <>
                                    { Array.isArray(services) && services.map((service, index) => (
                                        <div className="item" key={index}>
                                            <div className="row" onClick={() => history.replace(`/${tipo}/${id_animal}/${id_documento}`, {
                                                service: {
                                                    object: service,
                                                    objectId: service.id,
                                                    serviceId: service.get("serviceId"),
                                                    serviceName: service.get("serviceName"),
                                                    value: parseFloat(service.get("value").toFixed(2)),
                                                    valueUnit: parseFloat(service.get("value").toFixed(2)),
                                                    quantity: 1,
                                                    categoryName: service.get("category")?.get("categoryName"),
                                                    notes: service.get("notes"),
                                                    supplies: service.get("supplies")?.map(supply => ({
                                                        object: supply.object,
                                                        objectId: supply.objectId,
                                                        quantity: supply.quantity,
                                                        unit: supply.unit
                                                    })) || []
                                                }
                                            })}>
                                                <div className="col mb-3">
                                                    <h4>
                                                        { service.get("serviceName") }
                                                    </h4>
                                                    <h6>{ service.get("category")?.get("categoryName") }</h6>
                                                    <b>Valor do Servico:</b> { formattedCurrency(service.get("value")) }<br/>
                                                    <span>{ service.get("active") ? "Ativo" : "Inativo" }</span>
                                                </div>
                                                <div className="col-auto">
                                                    <button className="item-button">
                                                        <IconCheckO width="32px" height="32px" fill="var(--vsprontuario-primary-color)" />
                                                        <IconCheckO width="32px" height="32px" fill="var(--vsprontuario-primary-color-dark)" />
                                                        SELECIONAR
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </>
                            )}
                        </div>
                    ) : (
                        <div className="vssearch-default">
                            { text }
                        </div>
                    )
                ) : (
                    <VSEmpty
                        icon={<IconUserO width="32px" height="32px" />}
                        title="Você ainda não possui nenhum serviço cadastrado no Prontuário"
                        text="Cadastre o primeiro serviço e comece a aproveitar todos os benefícios de gestão de clientes do Prontuário Vet Smart"
                    >
                        <button
                            id="new-owner-link"
                            className="vsbox-btn vsbox-btn-primary"
                            onClick={() => history.push(`/servico/novo`)}
                        >
                            <IconAddO width="14" height="14" className="me-2" />
                            Cadastrar serviço
                        </button>
                    </VSEmpty>
                )}
            </div>
        </VSDrawer>
    )
}

const mapStateToProps = state => {
    return {
        clinic: state.clinic,
        onboarding: state.onboarding
    };
};
  
export default connect(mapStateToProps)(SaleServiceForm);