import React, { useEffect, useState } from "react"

import { Table, TableBody, TableContainer, TableHead, TableCell, TableRow, CircularProgress } from '@mui/material'
import { ReactComponent as IconStar } from "../../assets/icon-star.svg"

import "./CardDashboardHighlights.scss"
const CardDashboardHighlights = (props) => {

    const {
        datesFormatted,
        isLoading = true,
        services,
        payments,
        supplies
    } = props

    const [ isEmpty, setEmpty ] = useState(true)

    const table = (headerLabels, bodyRows) => {
        if (bodyRows.length > 0) {
            return (
                <TableContainer className="table-highlights show-card-dashboard" elevation={0}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow className="tableRowheader">
                                <TableCell className="tableHead">{ headerLabels[0] }</TableCell>
                                <TableCell className="tableHead d-none d-md-table-cell" style={{ width: "40%" }}>{ headerLabels[1] || "Valor" }</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            { bodyRows.map((row, index) => {
                                const item = Object.values(row)
                                return (
                                    <TableRow
                                        key={index}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell className="tableCell">
                                            { item[0] }
                                            <div className="d-block d-md-none mt-1">{ item[1] }</div>
                                        </TableCell>
                                        <TableCell className="tableCell d-none d-md-table-cell">
                                            { item[1]}
                                        </TableCell>
                                    </TableRow>
                                )
                            }) }
                        </TableBody>
                    </Table>
                </TableContainer>
            )
        } else {
            return <div></div>
        }
    }

    useEffect(() => {
        if (services?.length > 0 || payments?.length > 0 || supplies?.length > 0) {
            setEmpty(false)
        }
    }, [ services, payments, supplies ])

    return (
        <div id="card-dashboard-highlights" className="card-dashboard-element">
            <div className="row align-items-center">
                <div className="col mb-2 mb-md-0">
                    <div className="row align-items-center">
                        <div className="col-auto pe-0">
                            <IconStar width="24" height="24" fill="#B3B3B3" />
                        </div>
                        <div className={`col ps-2`}>
                            <div className="title">Principais Destaques</div>
                        </div>
                    </div>
                </div>
                <div className="col-12 mt-3">
                    <div className="dates">{ datesFormatted }</div>
                </div>
                <div className="col-12 mt-3">
                    { isLoading ? (
                        <div className="container">
                            <div className="d-flex justify-content-center mb-3">
                                <CircularProgress />
                            </div>
                        </div>
                    ) : (
                        isEmpty ? (
                            <div className="empty">Nenhuma informação registrada</div>
                        ) : (
                            <div>
                                <div>
                                    { table(["Formas de Pagamento"], payments) }
                                </div>
                                <div className="mt-3">
                                    { table(["Serviços"], services) }
                                </div>
                                <div className="mt-3">
                                    { table(["Insumos", "Qtd. de saída"], supplies) }
                                </div>
                            </div>
                        )
                    )}
                </div>
            </div>
        </div >
    )
}

export default CardDashboardHighlights;