import React, { useEffect, useState } from "react"
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import "./VSAccordion.scss"
const VSAccordion = (props) => {

    const {
        isControlled,
        toggleExpanded
    } = props

    const [ expanded, setExpanded ] = useState(props.expanded !== undefined ? props.expanded : false);

    useEffect(() => {
        if (props.expanded !== undefined) {
            setExpanded(props.expanded)
        }
    }, [ props.expanded ])

    return (
        <div id="vs-accordion">
            <Accordion
                id="accordion"
                expanded={expanded}
                disabled={props.disabled}
                onChange={() => {
                    if (!isControlled) {
                        setExpanded(!expanded)
                    }
                    toggleExpanded && toggleExpanded(!expanded)
                }}
            >
                <AccordionSummary>
                    { props.header(expanded) }
                </AccordionSummary>
                <AccordionDetails>
                    { props.children }
                </AccordionDetails>
            </Accordion>
        </div>
    )
}

export default VSAccordion