import React, { useEffect, useState } from "react"
import VSAccordionSimple from "../vsAccordion/VSAccordionSimple";
import { ACTION_CREATE, ACTION_DELETE, ACTION_READ, ACTION_UPDATE } from "../../constants";
import { connect } from "react-redux";
import { dayjs } from "../../utils";

const VSMedicalRecordsAudit = (props) => {

    const {
        parseObject,
        clinic
    } = props

    const [ objects, setObjects ] = useState([])
    const [ expanded, setExpanded ] = useState(props.expanded !== undefined ? props.expanded : false);

    const getDescriptionByAction = (action) => {
        switch (action) {
            case ACTION_CREATE: return "Criado"
            case ACTION_UPDATE: return "Alterado"
            case ACTION_DELETE: return "Excluído"
            case ACTION_READ: return "Visualizado"
            default: return action
        }
    }

    useEffect(() => {
        if (props.expanded !== undefined) {
            setExpanded(props.expanded)
        }
    }, [ props.expanded ])

    useEffect(() => {
        if (parseObject) {
            const queryParseObject = new Parse.Query("MedicalRecordsAudit");
            queryParseObject.equalTo("parseClassName", parseObject.className);
            queryParseObject.equalTo("parseClassId", parseObject.id);
            queryParseObject.equalTo("clinic", clinic.object);
            queryParseObject.descending("createdAt");
            queryParseObject.find()
            .then(_objects => {
                setObjects(_objects)
            })
            .catch(error => {
                console.error(error)
            })
        }
    }, [ parseObject ])

    return objects && objects.length > 0 && 
        (objects.length > 1 || objects[0].get("action") != ACTION_CREATE) && (
        <>
            <div className="vsbox-separator"></div>
            <div className="row">
                <div className="col-12 vsbox--info">
                    <VSAccordionSimple
                        expanded={expanded}
                        header={(expanded) => (
                            `${ expanded ? "Ocultar" : "Mostrar" } Histórico de Edições (${objects.length})`
                        )}
                    >
                        <div className="row">
                            <div className="col-12">
                                { objects.map(object => (
                                    <p style={{ color: "var(--vsprontuario-gray-color-dark)" }} key={object.id}>
                                        <b>{ getDescriptionByAction(object.get("action")) }</b> por { object.get("createdByName") } | { dayjs(object.get("createdAt")).format("DD/MM/YYYY [às] HH:mm") }
                                    </p>
                                )) }
                            </div>
                        </div>
                    </VSAccordionSimple>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    return {
        clinic: state.clinic
    };
};

export default connect(mapStateToProps)(VSMedicalRecordsAudit)