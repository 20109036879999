import React, { useEffect, useRef, useState } from "react"
import { connect } from "react-redux"

import { ReactComponent as IconAddO } from "../../assets/icon-primary-add-o.svg"
import { ReactComponent as IconPaw } from "../../assets/icon-paw.svg"
import { ReactComponent as IconQuestionO } from "../../assets/icon-question-o.svg"

import { diacriticSensitive } from "../../utils"
import { OrderBy_Alphabetical, OrderBy_AlphabeticalDescending, OrderBy_Oldest, OrderBy_Newest, OrderBy_Expensive, OrderBy_Cheaper, ACL } from "../../constants"
import { deleteCategory, searchCategories } from "./Service"
import VSList from "../../components/vsList/VSList"
import VSEmpty from "../../components/vsEmpty/VSEmpty"
import CardFeedback from "../../components/cardFeedback/CardFeedback"
import ServiceView from "./ServiceView"
import CategoryForm from "./CategoryForm"

import "./ServiceList.scss"
const ServiceList = (props) => {

    const {
        clinic,
        history,
        permissions
    } = props

    const rowsPerPage = 5

    const filter = useRef(null)

    const [ items, setItems ] = useState([])
    const [ categories, setCategories ] = useState([])
    const [ active, setActive ] = useState("0")

    const getServices = (searchBy, dateFrom, dateTo, orderBy, page) => {
        return new Promise((resolve, reject) => {

            const searchById = new Parse.Query("MRService")
            searchById.equalTo("clinic", clinic.object)
            if (searchBy) {
                searchById.matches("serviceId", diacriticSensitive(searchBy), "im")
            }

            const searchByName = new Parse.Query("MRService")
            searchByName.equalTo("clinic", clinic.object)
            if (searchBy) {
                searchByName.matches("serviceName", diacriticSensitive(searchBy), "im")
            }

            const query = Parse.Query.or(searchByName, searchById)
            query.equalTo("clinic", clinic.object)
            query.equalTo("isDeleted", false)

            if (categories.length > 0) {
                query.containedIn("category", categories.map(({ value }) => value))
            }
            if (active == "1") {
                query.equalTo("active", true)
            } else if (active == "2") {
                query.equalTo("active", false)
            }
            switch (orderBy) {
                case OrderBy_Alphabetical:
                    query.ascending("name");
                    break;
                case OrderBy_AlphabeticalDescending:
                    query.descending("name");
                    break;
                case OrderBy_Oldest:
                    query.ascending("createdAt");
                    break;
                case OrderBy_Expensive:
                    query.descending("value");
                    break;
                case OrderBy_Cheaper:
                    query.ascending("value");
                    break;
                default:
                    query.descending("createdAt");
            }
            query.addAscending("objectId")
            query.withCount()
            query.include("category")
            query.include("supplies.object")
            query.include("createdBy")
            query.include("changedBy")
            query.skip((page - 1) * rowsPerPage)
            query.limit(rowsPerPage)
            query.find()
            .then(_services => {
                setItems(_services?.results)
                resolve({
                    items: _services?.results,
                    total: _services?.count
                })
            })
            .catch(error => {
                console.error(error)
                reject(error)
            })
        })
    }

    const onChange = () => {
        if (filter.current) {
            filter.current.onChange()
        }
    }

    useEffect(() => {
        if (permissions && !permissions.check(ACL.SERVICE, ACL.Rule.VIEW)) {
            permissions.error()
        }
    }, [ permissions ])

    useEffect(() => {
        document.addEventListener("Service__getList", onChange)
        return () => { 
            document.removeEventListener("Service__getList", onChange)
        }
    }, [])

    return (
        <>
            <div id="service-list" className="row flex-grow-1">
                <div className="col-12">
                    <div className="page-content">
                        <VSList
                            ref={filter}
                            title={{
                                text: "Serviços"
                            }}
                            button={permissions?.check(ACL.SERVICE, ACL.Rule.EDIT) && {
                                id: "new-service-link",
                                text: "Criar serviço",
                                onClick: () => history.push(`/servico/novo`)
                            }}
                            search={{
                                text: "Buscar por ID ou nome do serviço"
                            }}
                            order={{
                                options: [
                                    OrderBy_Newest,
                                    OrderBy_Oldest,
                                    OrderBy_Alphabetical,
                                    OrderBy_AlphabeticalDescending,
                                    OrderBy_Expensive,
                                    OrderBy_Cheaper
                                ],
                                value: OrderBy_Newest
                            }}
                            VSFilterProps={{
                                title: "Filtro de Serviços",
                                onClear: () => {
                                    setCategories([])
                                    setActive("0")
                                },
                                onFilter: () => {
                                    onChange()
                                },
                                filters: [
                                    {
                                        title: "Categoria",
                                        defaultValue: [],
                                        value: categories,
                                        onChange: (values) => {
                                            setCategories(values)
                                        },
                                        onSearch: (term) => {
                                            return new Promise((resolve, reject) => {
                                                searchCategories(term, clinic.object)
                                                .then(({ results, count }) => {
                                                    if (results) {
                                                        const options = results.map(category => ({
                                                            value: category,
                                                            label: category.get("categoryName")
                                                        }))
                                                        return resolve({
                                                            count: count,
                                                            options: options
                                                        })
                                                    }
                                                    return resolve([])
                                                })
                                                .catch(error => {
                                                    console.error(error)
                                                    return resolve([])
                                                })
                                            })
                                        },
                                        options: [],
                                        multiple: {
                                            title: "Categorias",
                                            button: "Selecione uma categoria",
                                            placeholder: "Procure pela categoria",
                                            canCreate: () => permissions?.check(ACL.SERVICE, ACL.Rule.EDIT),
                                            canEdit: (object) => object.get("clinic"),
                                            canDelete: (object) => object.get("clinic"),
                                            onDelete: (object, callback) => deleteCategory(object, callback),
                                            FormComponent: CategoryForm
                                        }
                                    },
                                    {
                                        title: "Estado do Serviço",
                                        defaultValue: "0",
                                        value: active,
                                        onChange: (value) => {
                                            setActive(value)
                                        },
                                        options: [
                                            { value: "0", label: "Todos"},
                                            { value: "1", label: "Ativo"},
                                            { value: "2", label: "Inativo"}
                                        ]
                                    }
                                ]
                            }}
                            pagination={{
                                rowsPerPage: rowsPerPage
                            }}
                            onChange={({ _searchBy, _start, _end, _orderBy, _page }) => {
                                return getServices(_searchBy, _start, _end, _orderBy, _page)
                            }}
                            renderItem={(item, expanded) => (
                                <ServiceView object={item} expanded={expanded} {...props} />
                            )}
                            renderEmpty={() => (
                                <VSEmpty
                                    icon={<IconPaw width="32px" height="32px" fill="var(--vsprontuario-primary-color)" />}
                                    title="Nenhum serviço criado"
                                    text="Todas os serviços aparecerão aqui. Cadastre seus serviços realizados em seus atendimentos."
                                >
                                    { permissions?.check(ACL.SERVICE, ACL.Rule.EDIT) && (
                                        <button
                                            className="vsbox-btn vsbox-btn-primary w-auto mb-4"
                                            onClick={() => history.push(`/servico/novo`)}
                                        >
                                            <IconAddO width="14" height="14" className="me-2" />
                                            Cadastrar Novo Serviço
                                        </button>
                                    )}
                                    <div className="row g-3">
                                        <div className="col-12 vspro-info">
                                            <div className="row g-3 justify-content-start justify-content-md-center">
                                                <div className="col-auto pe-0">
                                                    <IconQuestionO width={24} height={24} fill="var(--vsprontuario-primary-color)" />
                                                </div>
                                                <div className="col col-md-auto ps-2 text-start action-text">
                                                    Ficou com dúvidas? Saiba como funciona a parte de <a href="#" target="_blank">Serviços</a>.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </VSEmpty>
                            )}
                            renderNotFound={() => (
                                <VSEmpty
                                    icon={<IconPaw width="32px" height="32px" fill="var(--vsprontuario-primary-color)" />}
                                    title="Não encontramos nenhum serviço"
                                    text="Tente refazer sua busca utilizando mais caracteres ou crie um novo cadastro utilizando o botão abaixo"
                                >
                                    { permissions?.check(ACL.SERVICE, ACL.Rule.EDIT) && (
                                        <button
                                            className="vsbox-btn vsbox-btn-primary w-auto"
                                            onClick={() => history.push(`/servico/novo`)}
                                        >
                                            <IconAddO width="14" height="14" className="me-2" />
                                            Cadastrar Novo Serviço
                                        </button>
                                    )}
                                </VSEmpty>
                            )}
                        />
                    </div>
                </div>
            </div>
            {items && items.length > 0 && (
                <CardFeedback contentDetail={history.location?.pathname || "ServiceList"} />
            )}
        </>
    )
}

const mapStateToProps = state => {
    return {
        clinic: state.clinic,
        permissions: state.permissions
    };
};
  
export default connect(mapStateToProps)(ServiceList);