import { diacriticSensitive, getClinicStoreByClinicUser } from "../../utils"
import { Filter_Limit } from "../../constants"

export const deleteClinicUser = (clinicUser, callback, self = false) => {
    Swal.fire({
        title: 'Tem certeza?',
        text: self ? 
            "Você tem certeza que deseja sair dessa clínica? Essa ação não poderá ser desfeita." :
            "Você tem certeza que deseja excluir esse médico veterinário? Essa ação não poderá ser desfeita.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: self ? 'Sim, quero sair!' : 'Sim, quero excluir!',
        cancelButtonText: self ? 'Não, quero ficar' : 'Não, manter veterinário'
    }).then((result) => {
        if (result.isConfirmed) {
            Parse.Cloud.run("removeUserFromClinic", { clinicUserId: clinicUser?.id })
            .then(_ => {
                Swal.fire(
                    'Excluído!',
                    self ? 'Você foi removido da clínica com sucesso e será redirecionado para vetsmart.com.br' : 'O médico veterinário foi excluído com sucesso.',
                    'success'
                )
                .then(_ => {
                    callback && callback()
                })
            })
            .catch(error => {
                console.error(error)
                Swal.hideLoading()
                Swal.fire(
                    'Desculpe',
                    self ? 'Ocorreu algum erro ao tentar sair da clínica' : 'Ocorreu algum erro ao tentar excluir o médico veterinário',
                    'error'
                )
            })
        }
    })
}

export const getClinicUser = (clinicObject, userObject = null, includes = ["clinic", "profile"]) => {
    return new Promise((resolve, reject) => {
        var queryClinicUser = new Parse.Query("ClinicUser");
        queryClinicUser.equalTo("clinic", clinicObject);
        if (userObject) {
            queryClinicUser.equalTo("user", userObject);
        } else {
            queryClinicUser.equalTo("user", Parse.User.current());
        }
        queryClinicUser.equalTo("isDeleted", false);
        includes.map(className => {
            queryClinicUser.include(className)
        })
        queryClinicUser.first()
        .then(_clinicUser => getClinicStoreByClinicUser(_clinicUser))
        .then(_clinicUser => {
            resolve(_clinicUser)
        })
        .catch(error => {
            console.error(error)
            reject('Ocorreu algum erro ao tentar buscar o convite/usuário')
        })
    })
}

export const getClinicUserById = (clinicUserId, clinicObject, includes = ["clinic", "profile"]) => {
    return new Promise((resolve, reject) => {
        var queryClinicUser = new Parse.Query("ClinicUser");
        queryClinicUser.equalTo("clinic", clinicObject);
        queryClinicUser.equalTo("isDeleted", false);
        includes.map(className => {
            queryClinicUser.include(className)
        })
        queryClinicUser.get(clinicUserId)
        .then(_clinicUser => {
            resolve(_clinicUser)
        })
        .catch(error => {
            console.error(error)
            reject('Ocorreu algum erro ao tentar buscar o convite/usuário')
        })
    })
}

var searchProfilesWithDelay = null;
export const searchProfiles = (profileName, clinicObject) => {
    return new Promise((resolve, reject) => {
        clearInterval(searchProfilesWithDelay);
        searchProfilesWithDelay = setTimeout(() => {
            const query = new Parse.Query("MRProfilePermissions");
            query.equalTo("clinic", clinicObject);
            if (profileName) {
                query.matches("name", diacriticSensitive(profileName), "im");
            }

            query.ascending("name")
            query.limit(Filter_Limit)
            query.withCount()
            query.find()
            .then(profiles => {
                resolve(profiles)
            })
            .catch(error => {
                console.error(error);
                reject("Ocorreu algum erro ao tentar buscar o perfil")
            })
        }, 300);
    })
}

export const getProfilePermissions = (clinic) => {
    return new Promise((resolve, reject) => {
        const query = new Parse.Query("MRProfilePermissions")
        query.equalTo("clinic", clinic)
        query.equalTo("isDeleted", false)
        query.find()
        .then(results => {
            return resolve(results)
        })
        .catch(error => {
            console.error(error)
            return reject("Ocorreu algum erro ao tentar buscar os perfis de usuário")
        })
    })
}

export const getPermissionByOption = (value) => {
    switch(value) {
        case "0":
            return {
                view: "none",
                edit: "none",
                delete: "none"
            }
    }
}

export const Permissions = {
    NO_ACCESS: "0",
    VIEW_ALL: "1",
    VIEW_ALL_EDIT_SELF: "2",
    VIEW_ALL_EDIT_DELETE_SELF: "3",
    VIEW_EDIT_ALL: "4",
    VIEW_EDIT_ALL_DELETE_SELF: "5",
    VIEW_EDIT_DELETE_ALL: "6",
    get: (value) => {
        switch (value) {
            case Permissions.NO_ACCESS:
                return {
                    view: "none",
                    edit: "none",
                    delete: "none"
                }
            case Permissions.VIEW_ALL:
                return {
                    view: "all",
                    edit: "none",
                    delete: "none"
                }
            case Permissions.VIEW_ALL_EDIT_SELF:
                return {
                    view: "all",
                    edit: "self",
                    delete: "none"
                }
            case Permissions.VIEW_ALL_EDIT_DELETE_SELF:
                return {
                    view: "all",
                    edit: "self",
                    delete: "self"
                }
            case Permissions.VIEW_EDIT_ALL:
                return {
                    view: "all",
                    edit: "all",
                    delete: "none"
                }
            case Permissions.VIEW_EDIT_ALL_DELETE_SELF:
                return {
                    view: "all",
                    edit: "all",
                    delete: "self"
                }
            case Permissions.VIEW_EDIT_DELETE_ALL:
                return {
                    view: "all",
                    edit: "all",
                    delete: "all"
                }
        }
    },
    getValueByRule: (rule) => {
        switch (JSON.stringify(rule)) {
            case JSON.stringify(Permissions.get(Permissions.VIEW_ALL)):
                return Permissions.VIEW_ALL
            case JSON.stringify(Permissions.get(Permissions.VIEW_ALL_EDIT_SELF)):
                return Permissions.VIEW_ALL_EDIT_SELF
            case JSON.stringify(Permissions.get(Permissions.VIEW_ALL_EDIT_DELETE_SELF)):
                return Permissions.VIEW_ALL_EDIT_DELETE_SELF
            case JSON.stringify(Permissions.get(Permissions.VIEW_EDIT_ALL)):
                return Permissions.VIEW_EDIT_ALL
            case JSON.stringify(Permissions.get(Permissions.VIEW_EDIT_ALL_DELETE_SELF)):
                return Permissions.VIEW_EDIT_ALL_DELETE_SELF
            case JSON.stringify(Permissions.get(Permissions.VIEW_EDIT_DELETE_ALL)):
                return Permissions.VIEW_EDIT_DELETE_ALL
            default:
                return Permissions.NO_ACCESS
        }
    }
}