import React, { useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone';

import "./VSFileUpload.scss"

import { ReactComponent as IconPdf } from "../../assets/pdf.svg"
import { ReactComponent as IconFile } from "../../assets/file.svg"
import { dayjs } from '../../utils';

export const isImage = (fileType) => {
    const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
    return validImageTypes.includes(fileType)
}

export const isPdf = (fileType) => {
    return fileType == 'application/pdf'
}

export const getFileType = (fileName) => {
    const extension = fileName.split('.').pop()
    switch (extension) {
        case "gif":
            return "image/gif"
        case "jpeg":
        case "jpg":
            return "image/jpeg"
        case "png":
            return "image/png"
        case "pdf":
            return "application/pdf"
        return ""
    }
}

const VSFileUpload = (props) => {

    const {
        value,
        onChange,
        children,
    } = props

    const [ upload, setUpload ] = useState(value || []);

    const {
        getRootProps,
        getInputProps,
        inputRef
    } = useDropzone({
        maxSize: 20971520,
        onDropAccepted: acceptedFiles => {
            const newFiles = acceptedFiles.map(file => {
                var name = file.path.split(".").slice(0, -1).join(".").replace(/[^a-zA-Z0-9]/g, '')
                if (!name) {
                    name = dayjs().format("YmdHis")
                }
                return new Parse.File(name, file)
            })
            const filesUpdated = value.concat(newFiles)
            setUpload(filesUpdated)
            onChange(filesUpdated)
        },
        onDropRejected: fileRejections => {
            const error = fileRejections.map(file => file.errors.map(error => error.code)[0])[0]
            switch (error) {
                case "file-too-large":
                    Swal.fire(
                        'Desculpe',
                        'O arquivo não pode ser maior que 20MB',
                        'error'
                    )
                    break;
                case "file-too-small":
                    Swal.fire(
                        'Desculpe',
                        'O tamanho do arquivo é muito pequeno',
                        'error'
                    )
                    break;
                case "file-invalid-type":
                    Swal.fire(
                        'Desculpe',
                        'O tipo do arquivo não é válido',
                        'error'
                    )
                    break;
                case "file-overflow":
                    Swal.fire(
                        'Desculpe',
                        'Ocorreu algum erro ao importar o arquivo',
                        'error'
                    )
                    break;
                case "file-excessive":
                    Swal.fire(
                        'Desculpe',
                        'Os arquivos excedem o limite disponível',
                        'error'
                    )
                    break;
                default:
                    Swal.fire(
                        'Desculpe',
                        'Não foi possível importar o arquivo, entre em contato conosco',
                        'error'
                    )
                    break;
            }
        }
    });

    const removeFile = (index) => {
        var filesUpdated = value.slice();
        filesUpdated.splice(index, 1);
    
        setUpload(filesUpdated)
        onChange(filesUpdated)
    }

    useEffect(() => {
        if (value) {
            setUpload(value)
        }
    }, [ value ])

    return (
        <div className="file-upload">
            <div className="upload-preview">
                { upload.length > 0 && (
                    <div className="row align-items-center mb-3">
                        { upload.map((file, index) => {
                            const _file = file._source && file._source.file
                            const name = _file ? _file.name : file.name()
                            const url = _file ? window.URL.createObjectURL(_file) : file.url()
                            const type = _file ? _file.type : getFileType(name)
                            return (
                                <div className="col-12 col-md-6 mb-3" key={index}>
                                    <div className="row align-items-center">
                                        <div className="col">
                                            { isImage(type) ? (
                                                <div className="upload-image">
                                                    <div className="upload-name noselect">{ name }</div>
                                                    <img
                                                        height="100%"
                                                        src={url}
                                                        width="100%"
                                                    />
                                                </div>
                                            ) : (
                                                <a className="upload-image" target="_blank" href={url}>
                                                    <div className="upload-name noselect">{ name }</div>
                                                    { isPdf(type) ? (
                                                        <IconPdf
                                                            className="upload-pdf"
                                                            height="100%"
                                                            width="100%"
                                                        />
                                                    ) : (
                                                        <IconFile
                                                            className="upload-file"
                                                            height="100%"
                                                            width="100%"
                                                        />
                                                    )}
                                                </a>
                                            )}
                                        </div>
                                        <div className="col-auto ps-0" style={{ maxWidth: '120px' }}>
                                            <div className="row">
                                                <div className="col-12 mb-3">
                                                    <button 
                                                        className="vsbox-btn vsbox-btn-save"
                                                        onClick={() => {
                                                            if (inputRef && inputRef.current) {
                                                                inputRef.current.click()
                                                                inputRef.current.onchange = ({ target : { value } }) => {
                                                                    if (value) {
                                                                        removeFile(index)
                                                                    }
                                                                }
                                                            }
                                                        }}
                                                    >
                                                        Substituir
                                                    </button>
                                                </div>
                                                <div className="col-12">
                                                    <button 
                                                        className="vsbox-btn vsbox-btn-alert"
                                                        onClick={() => removeFile(index)}
                                                    >
                                                        Excluir    
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                )}
                <div className="row mb-3">
                    <div className="col-12">
                        <div {...getRootProps({className: 'btn-dashed dropzone'})}>
                            <input {...getInputProps()} />
                            { children }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VSFileUpload;