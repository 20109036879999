import React, { useEffect, useRef, useState } from "react"
import { useParams } from 'react-router-dom'
import { connect } from "react-redux"
import axios from "axios";

import TextField from '@mui/material/TextField';
import { Alert, AlertTitle, Autocomplete } from "@mui/lab";
import { CircularProgress } from "@mui/material";

import { ReactComponent as IconExclamationO } from "../../assets/icon-exclamation-o.svg"

import { addEvent, fillValidEmailOnly, stripTags, validateEmail } from "../../utils";
import { ACL, APP_DEVICE, APP_NAME, APP_VERSION } from "../../constants";
import { getClinicUserById, getProfilePermissions } from "./Vet";

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import VSDrawer from "../../components/vsDrawer/VSDrawer";
import VSCheckbox from "../../components/vsCheckbox/VSCheckbox";
import VSError from "../../components/vsError/VSError";
import VetAccessForm from "./tabs/VetAccessForm";
import VetTimeForm from "./tabs/VetTimeForm";

import "./VetProForm.scss"
const VetProForm = (props) => {

    const {
        id_usuario,
        tab = "configuracoes-acesso"
    } = useParams()

    const {
        user,
        clinic,
        history,
        permissions
    } = props

    const update = id_usuario != "novo"

    const accessRef = useRef(null)
    const workHourRef = useRef(null)

    const [ error, setError ] = useState(null)
    const [ loading, setLoading ] = useState(false)
    const [ hasSubmited, setHasSubmited ] = useState(false)
    const [ saveLoading, setSaveLoading ] = useState(false)
    const [ clinicUser, setClinicUser ] = useState(null)

    const [ name, setName ] = useState('')
    const [ errorName, setErrorName ] = useState(false)
    const [ helperName, setHelperName ] = useState(<span>Campo <b>Obrigatório</b></span>)
    const [ email, setEmail ] = useState('')
    const [ errorEmail, setErrorEmail ] = useState(false)
    const [ helperEmail, setHelperEmail ] = useState(<span>Campo <b>Obrigatório</b></span>)
    const [ profile, setProfile ] = useState("")
    const [ errorProfile, setErrorProfile ] = useState(false)
    const [ helperProfile, setHelperProfile ] = useState(<span>Campo <b>Obrigatório</b></span>)
    const [ profileName, setProfileName ] = useState("")
    const [ errorProfileName, setErrorProfileName ] = useState(false)

    const [ profiles, setProfiles ] = useState([])
    const [ withProfile, setWithProfile ] = useState(false)

    const validateOnTouch = () => {
        if (hasSubmited) {
            validate()
        }
    }

    const validate = () => {
        let isValid = true
        if (loading) {
            isValid = false
        }

        if (!update) {
            if (!name) {
                isValid = false
                setErrorName(true)
                setHelperName("O nome do médico veterinário é obrigatório")
            } else {
                setErrorName(false)
                setHelperName(<span>Campo <b>Obrigatório</b></span>)
            }
    
            if (!validateEmail(email)) {
                isValid = false
                setErrorEmail(true)
                setHelperEmail("O e-mail informado não é válido")
            } else if (email == user.email) {
                isValid = false
                setErrorEmail(true)
                setHelperEmail("Não é possível enviar o convite para o próprio e-mail")
            } else {
                setErrorEmail(false)
                setHelperEmail(<span>Campo <b>Obrigatório</b></span>)
            }
        }

        if (!profile.name) {
            isValid = false
            setErrorProfile(true)
            setHelperProfile("O perfil é obrigatório")
        } else {
            setErrorProfile(false)
            setHelperProfile(<span>Campo <b>Obrigatório</b></span>)
        }

        if (withProfile && !profile.id && !profileName) {
            isValid = false
            setErrorProfileName(true)
        } else {
            setErrorProfileName(false)
        }

        if (accessRef.current) {
            isValid = accessRef.current.validate(isValid)
        }

        if (workHourRef.current) {
            isValid = workHourRef.current.validate(isValid)
        }

        return isValid
    }

    const isValid = () => !loading && !errorName && !errorEmail && !errorProfile && !errorProfileName && !saveLoading

    const fieldsWithErrorText = () => {
        let errors = []
        if (errorName) {
            errors.push("nome")
        }
        if (errorEmail) {
            errors.push("Email")
        }
        if (errorProfile) {
            errors.push("Perfil")
        }
        if (errorProfileName) {
            errors.push("Nome do Perfil")
        }
        if (loading) {
            errors.push("Buscando informações")
        }
        if (saveLoading) {
            errors.push("Enviando o convite")
        }
        if (!permissions?.check(ACL.ADMIN)) {
            errors.push("Acesso negado")
        }
        if (accessRef.current) {
            errors = accessRef.current.fieldsWithErrorText(errors)
        }
        if (workHourRef.current) {
            errors = workHourRef.current.fieldsWithErrorText(errors)
        }
        return errors
    }

    const checkIfInviteAlreadyExists = () => {
        return new Promise((resolve, reject) => {
            if (update) {
                return resolve()
            } else {
                const checkIfInviteAlreadyExistsBeforeSend = new Parse.Query("ClinicUser");
                checkIfInviteAlreadyExistsBeforeSend.equalTo("clinic", clinic.object)
                checkIfInviteAlreadyExistsBeforeSend.equalTo("email", email)
                checkIfInviteAlreadyExistsBeforeSend.notEqualTo("accepted", false)
                checkIfInviteAlreadyExistsBeforeSend.equalTo("isDeleted", false)
                checkIfInviteAlreadyExistsBeforeSend.descending("createdAt")
                checkIfInviteAlreadyExistsBeforeSend.first()
                .then(invited => {
                    if (invited) {
                        return reject(invited.get("accepted") == true ? `Um convite já foi enviado para ${email}, que aceitou o convite.` : `Já enviamos um convite para ${email}, peça para que ele(a) acesse o prontuário para responder o convite.`)
                    } else {
                        return resolve()
                    }
                })
            }
        })
    }

    const sendEmailInviteIfNeeded = () => {
        return new Promise((resolve, reject) => {
            if (update) {
                return resolve()
            } else {
                axios.post(`${process.env.REACT_APP_API_V2}/email/invite`, {
                    "user": user.fullName,
                    "user_email": Parse.User.current().getEmail(), 
                    "clinic": clinic.name, 
                    "name": name,
                    "email": email
                })
                .then(({ data }) => {
                    if (data.result) {
                        return resolve()
                    } else {
                        return reject('O convite foi cadastrado, mas não foi possível enviá-lo por e-mail')
                    }
                })
                .catch(error => {
                    return reject('O convite foi cadastrado, mas não foi possível enviá-lo por e-mail, tente novamente mais tarde')
                })
            }
        })
    }

    const saveProfileIfNeeded = () => {
        return new Promise((resolve, reject) => {
            if (withProfile) {
                let object
                if (profile.object) {
                    object = profile.object
                } else {
                    object = new Parse.Object("MRProfilePermissions")
                    object.setACL(clinic.ACL)
                    object.set("clinic", clinic.object)
                    object.set("name", profileName)
                }
                object.set("permissions", accessRef.current?.getPermissions())
                object.save()
                .then(newProfile => {
                    return resolve(newProfile)
                })
                .catch(error => {
                    console.error(error)
                    return reject(`Ocorreu algum erro ao ${ profile.object ? "redefinir" : "criar" } o perfil`)
                })
            } else {
                return resolve(profile.object)
            }
        })
    }

    const save = () => {
        setHasSubmited(true)
        return new Promise((resolve, reject) => {
            if (validate()) {
                setSaveLoading(true)
    
                return checkIfInviteAlreadyExists()
                .then(_ => {
                    return saveProfileIfNeeded()
                    .then(profile => {
                        if (!update) {
                            clinicUser.set("name", name)
                            clinicUser.set("email", email)
                        }
                        clinicUser.set("profile", profile)
                        if (!canNotEditAccessControl) {
                            clinicUser.set("permissions", accessRef.current?.getPermissions())
                        }
                        clinicUser.set("workHours", workHourRef.current?.getTimes())
                        return clinicUser.save()
                        .then(_clinicUser => {
                            return sendEmailInviteIfNeeded()
                            .then(_ => {
                                setSaveLoading(false)
                                addEvent("ClinicUser__getList", {})
                                return resolve(_clinicUser)
                            })
                            .catch(error => {
                                setSaveLoading(false)
                                addEvent("ClinicUser__getList", {})
                                console.error(error)
                                Swal.fire(
                                    'Erro ao enviar o e-mail!',
                                    error,
                                    'error'
                                )
                                return reject()
                            })
                        })
                        .catch(error => {
                            setSaveLoading(false)
                            console.error(error)
                            clinicUser.revert()
                            Swal.fire(
                                'Erro ao cadastrar o convite!',
                                'Não foi possível cadastrar o convite, tente novamente mais tarde',
                                'error'
                            )
                            return reject()
                        })
                    })
                    .catch(error => {
                        console.error(error)
                        setSaveLoading(false)
                        Swal.fire(
                            'Erro ao salvar o perfil!',
                            error,
                            'error'
                        )
                        return reject()
                    })
                })
                .catch(error => {
                    console.error(error)
                    setSaveLoading(false)
                    Swal.fire(
                        'Um convite já foi enviado!',
                        error,
                        'error'
                    )
                    return reject()
                })
            } else {
                return reject()
            }
        })
    }

    const getProfile = (profile, permissions) => {
        if (profile?.id) {
            return {
                id: profile.id,
                name: profile.get("name"),
                isVet: profile.get("isVeterinary"),
                permissions: profile.get("permissions"),
                object: profile
            }
        }
        return {
            id: null,
            name: "Outros",
            isVet: false,
            permissions: permissions || {},
            object: null
        }
    }

    const getProfilesOrError = () => {
        setLoading(true)
        setError(null)

        getProfilePermissions(clinic.object)
        .then(results => {
            const _profiles = results.map(profile => getProfile(profile))
            _profiles.push(getProfile())
            setProfiles(_profiles)
        })
        .catch(error => {
            setLoading(false)
            setError({
                message: error,
                function: "getProfilesOrError"
            })
        })
    }

    const getClinicUserOrError = () => {
        setLoading(true)
        setError(null)

        if (update) {
            getClinicUserById(id_usuario, clinic.object, ["profile"])
            .then(_clinicUser => {
                setClinicUser(_clinicUser)
                setName(_clinicUser.get("name"))
                setEmail(_clinicUser.get("email"))
                setProfile(getProfile(_clinicUser.get("profile"), _clinicUser.get("permissions")))
                setLoading(false)
            })
            .catch(error => {
                Swal.fire(
                    'Desculpe',
                    error,
                    'error'
                )
                .then(_ => {
                    setLoading(false)
                    setError({
                        message: error,
                        function: "getClinicUserOrError"
                    })
                })
            })
        } else {
            let newClinicUser = new Parse.Object("ClinicUser")
            newClinicUser.set("clinic", clinic.object)
            newClinicUser.set("createdBy", Parse.User.current())
            newClinicUser.set("createdApp", APP_NAME)
            newClinicUser.set("createdDevice", APP_DEVICE)
            newClinicUser.set("createdAppVersion", APP_VERSION)
            newClinicUser.set("isDeleted", false)
            setClinicUser(newClinicUser)
            setLoading(false)
        }
    }

    // useEffect(() => {
    //     if (clinicUser && !permissions?.check(ACL.ADMIN)) {
    //         permissions?.error()
    //     }
    // }, [ clinicUser ])

    useEffect(() => {
        if (clinic && clinic.isLoaded && clinic.object) {
            getProfilesOrError()
        }
    }, [ clinic ])

    useEffect(() => {
        if (profiles.length > 0) {
            getClinicUserOrError()
        }
    }, [ profiles ])

    useEffect(() => {
        validateOnTouch()
    }, [ 
        name, email, profile
    ])

    const errors = fieldsWithErrorText()

    const canNotEditAccessControl = !permissions?.check(ACL.ADMIN) || (clinic.isOwner && clinicUser?.get("user")?.id == user?.objectId)

    return (
        <VSDrawer
            id="vet-form"
            title={permissions?.check(ACL.ADMIN) ? (update ? "Editar usuário(a)" : "Convidar novo(a) usuário(a)") : "Horário de trabalho"}
            cancel={permissions?.check(ACL.ADMIN) ? (update ? "Cancelar Alterações" : "Cancelar Convite") : ""}
            submit={permissions?.check(ACL.ADMIN) ? (isValid ? ( update ? "Salvar Alterações" : "Enviar Convite" ) : <span>Preencha os campos<span className="d-none d-md-inline"> necessários</span></span>) : ""}
            width={"85%"}
            errors={errors}
            onSubmit={save}
            onCancel={() => history.goBack()}
            onAfterSave={(object) => history.push("/configuracao-clinica/gerenciar-acessos")}
            VSDrawerSuccessProps={permissions?.check(ACL.ADMIN) ? {
                title: update ? "Alterações salvas com sucesso!" : "Convite enviado com sucesso!" ,
                text: update ? "" : `Um e-mail foi enviado para ${email} com as instruções para finalizar o cadastro.`
            } : undefined} 
            VSDrawerFooterProps={permissions?.check(ACL.ADMIN) ? {
                prepend: (
                    <div className="col-12 col-xl mb-3 mb-xl-0">
                        <div className="row align-items-center gx-3">
                            <div className="col">
                                <VSCheckbox
                                    label={profile?.id ? <span>Redefinir Perfil de <b>{ profile.name }</b> com estas configurações. Essa mudança não afetará usuários
                                    já cadastrados com esse Perfil e só valerá para novos usuários convidados a partir deste momento</span> : "Criar novo Perfil de Usuário com estas configurações"}
                                    checked={withProfile}
                                    onChange={(checked) => setWithProfile(checked)}
                                />
                            </div>
                            { withProfile && !profile?.id && (
                                <div className="col">
                                    <TextField
                                        placeholder="ex:. Especialista Cirurgião"
                                        label="Nome do Perfil"
                                        style={{
                                            background: "White"
                                        }}
                                        value={profileName}
                                        onChange={({ target: { value } }) => setProfileName(value)}
                                        size="medium"
                                        variant="outlined"
                                        fullWidth
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                )
            } : { show: false }}
            VSDrawerCancelProps={permissions?.check(ACL.ADMIN) ? {
                title: update ? "Tem certeza que deseja cancelar as alterações no cadastro do(a) usuário(a)? " : "Tem certeza que deseja cancelar o convite?",
                confirm: update ? "Cancelar  Alterações" : "Cancelar Novo Usuário(a)",
                cancel: update ? "Voltar para Edição do Cadastro" : "Voltar para o Convite"
            } : undefined}
        >
            { loading ? (
                <div className="row data-loading">
                    <div className="col">
                        <CircularProgress />
                    </div>
                </div>
            ) : error?.message ? (
                <VSError
                    message={error.message}
                    onClose={() => {
                        switch(error.function) {
                            case "getProfilesOrError":
                                getProfilesOrError()
                                break
                            case "getClinicUserOrError":
                                getClinicUserOrError()
                                break
                            default:
                        }
                    }}
                />
            ) : (
                <>
                    {permissions?.check(ACL.ADMIN) &&
                    <>
                        <div className="row align-items-center mb-3">
                            <div className="col">
                                <div className="input-title">Dados Básicos</div>
                            </div>
                        </div>
                        <div className="row align-items-center mb-3">
                            <div className="col-12 col-md mb-3 mb-md-0">
                                <TextField
                                    placeholder="Nome do(a) usuário(a)"
                                    label="Nome do(a) usuário(a)"
                                    value={name}
                                    onChange={({ target: { value } }) => setName(stripTags(value))}
                                    disabled={update}
                                    error={errorName}
                                    helperText={helperName}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                />
                            </div>
                            <div className="col-12 col-md mb-3 mb-md-0">
                                <TextField
                                    type="email"
                                    placeholder="exemplo@email.com"
                                    label="E-mail do(a) usuário(a)"
                                    value={email}
                                    onChange={({ target: { value } }) => setEmail(fillValidEmailOnly(value))}
                                    disabled={update}
                                    error={errorEmail}
                                    helperText={helperEmail}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                />
                            </div>
                            <div className="col-12 col-md mb-3 mb-md-0">
                                <Autocomplete
                                    value={profile}
                                    options={profiles}
                                    getOptionLabel={(option) => option?.name || ""}
                                    getOptionSelected={(option) => option?.name === profile?.name}
                                    onChange={(_, selected) => setProfile(selected || "")}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder="Ex: Administrador"
                                            label="Perfil do(a) usuário(a)"
                                            helperText={<span>Campo Opcional<br/></span>}
                                            size="small"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    )}
                                    openOnFocus
                                    clearOnBlur
                                    freeSolo
                                />
                            </div>
                        </div>
                    </>}
                    { profile.name && (
                        <div className="row align-items-center mb-3">
                            <div className="col-12">
                                <Alert icon={<IconExclamationO width={24} height={24} fill="var(--vsprontuario-warning-color)" stroke="var(--vsprontuario-warning-color)" />} severity="warning">
                                    { !permissions?.check(ACL.ADMIN) ? (
                                        <>
                                            <AlertTitle></AlertTitle>
                                            Entre em contato com um <b>administrador da clínica</b> para editar o seu horário de trabalho.
                                        </>
                                    ) : !profile.isVet ? (
                                        <>
                                            <AlertTitle>Bloqueio automático de criação e emissão de documentos médicos</AlertTitle>
                                            Este perfil de usuário não poderá criar e emitir documentos médicos como atestados, prescrições, solicitações de exames e termos, independente das suas configurações de acesso
                                        </>
                                    ) : (
                                        <>
                                            <AlertTitle>Liberação para criação e emissão de documentos médicos</AlertTitle>
                                            Este perfil de usuário poderá criar e emitir documentos médicos como atestados, prescrições, solicitações de exames e termos apenas após efetivamente criar sua conta no Prontuário Pro Vetsmart e cadastrar um CRMV válido e ativo
                                        </>
                                    )}
                                </Alert>
                            </div>
                        </div>
                    )}
                    {permissions?.check(ACL.ADMIN) &&
                    <div className="row align-items-end separator mb-3 setting-tabs">
                        <div className="col">
                            <Tabs
                                className="vstabs"
                                value={canNotEditAccessControl ? "horario-trabalho" : tab}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="scrollable"
                                scrollButtons="auto"
                                allowScrollButtonsMobile
                                TabIndicatorProps={{
                                    style: {
                                        height: '3px'
                                    }
                                }}
                            >
                                { !canNotEditAccessControl && (
                                    <Tab 
                                        value="configuracoes-acesso"
                                        label="Configurações de Acesso"
                                        onClick={() => history.replace(`/configuracao-clinica/gerenciar-acessos/${id_usuario}/configuracoes-acesso`)}
                                    />
                                )}
                                <Tab 
                                    value="horario-trabalho"
                                    label="Horário de Trabalho"
                                    onClick={() => history.replace(`/configuracao-clinica/gerenciar-acessos/${id_usuario}/horario-trabalho`)}
                                />
                            </Tabs>
                        </div>
                    </div>}
                    <div className="row g-3 align-items-center">
                        <div className={`col-12 ${ !canNotEditAccessControl && tab == "configuracoes-acesso" ? "d-block" : "d-none" }`}>
                            <VetAccessForm
                                ref={accessRef}
                                userId={id_usuario}
                                clinicUser={clinicUser}
                                profile={profile}
                                onChange={validateOnTouch}
                            />
                        </div>
                        <div className={`col-12 ${ canNotEditAccessControl || tab == "horario-trabalho" ? "d-block" : "d-none" }`}>
                            <VetTimeForm
                                ref={workHourRef}
                                clinicUser={clinicUser}
                                onChange={validateOnTouch}
                            />
                        </div>
                    </div>
                </>
            )}
        </VSDrawer>
    )
}

const mapStateToProps = state => {
    return {
        clinic: state.clinic,
        user: state.user,
        permissions: state.permissions
    };
};

export default connect(mapStateToProps)(VetProForm)