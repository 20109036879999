import React, { useState } from 'react'
import { connect } from 'react-redux'

import { TableCell, TableRow } from '@mui/material'

import { ReactComponent as IconUserO } from "../../assets/icon-user-o.svg"

import HomeData from './HomeData'
import { dayjs } from '../../utils'

import './HomeLastOwners.scss'
const HomeLastOwners = (props) => {

    const {
        clinic,
        expanded,
        history
    } = props

    const [ loading, setLoading ] = useState(true)
    const [ owners, setOwners ] = useState([])

    const rowsPerPage = 5

    return (
        <div className="col-12 mb-3">
            <HomeData
                expanded={expanded}
                title="Últimos Cadastros de Tutores"
                emptyText="Você ainda não possui cadastros de Tutores cadastrados"
                loading={loading}
                data={owners}
                rowsPerPage={rowsPerPage}
                onChange={(page) => {
                    if (clinic && clinic.isLoaded && clinic.object) {
                        setOwners([])
                        setLoading(true)

                        const query = new Parse.Query("Owner");
                        query.equalTo("isDeleted", false)
                        query.equalTo("clinic", clinic.object)
                        query.descending("createdAt")
                        query.limit(15)
                        query.find()
                        .then(_owners => {
                            setOwners({
                                count: _owners?.length || 0,
                                results: _owners || []
                            })
                            setLoading(false)
                        })
                        .catch(error => {
                            console.error(error)
                            setLoading(false)
                        })
                    }
                }}
                renderItem={(owner, index) => {
                    return (
                        <TableRow
                            key={index}
                        >
                            <TableCell
                                style={{ width: '24px' }}
                            >
                                <IconUserO width="24px" height="24px" fill="#B3B3B3" />
                            </TableCell>
                            <TableCell className="px-2">
                                <div className="text-link d-inline" onClick={() => history.push(`/tutor/${owner?.id}`)}>
                                    { owner?.get("name") }
                                </div>
                            </TableCell>
                            <TableCell
                                style={{ width: '86px' }}
                            >
                                <div className="text-truncate">
                                    { dayjs(owner?.get("createdAt")).format("DD/MM/YYYY") }
                                </div>
                            </TableCell>
                        </TableRow>
                    )
                }}
                footer={(
                    <div className="col">
                        <div onClick={() => history.push('/tutor')} className="btn-outline-submit btn-square cursor-pointer">
                            Ver Cadastros de Tutores
                        </div>
                    </div>
                )}
            />
        </div>
    )
}

const mapStateToProps = state => {
    return {
        clinic: state.clinic
    };
};
  
export default connect(mapStateToProps)(HomeLastOwners);