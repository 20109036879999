import React from 'react';

import { ReactComponent as IconPrimaryEdit } from "../../assets/icon-primary-edit.svg"
import { ReactComponent as IconPrimaryTrash } from "../../assets/icon-primary-trash.svg"

import "./CardPatient.scss"
import PatientPhoto from '../patientPhoto/PatientPhoto';

const CardPatient = (props) => {

    const {
        patient,
        onClick,
        onEdit,
        onDelete,
        styles
    } = props

    return (
        <div id="card-patient" className="row align-items-center" style={styles && styles.root}>
            <div className="col" onClick={() => onClick && onClick(patient.id)}>
                <div className="row align-items-center cursor-pointer">
                    <div className="col-auto mb-md-2 mb-lg-0">
                        <PatientPhoto patient={patient} withBorder />
                    </div>
                    <div className="col px-0 mb-md-2 mb-lg-0">
                        <h5 className="card-title">
                            { patient.get("name") }
                            { onClick && (
                                <div className="d-inline">
                                    &nbsp;-&nbsp;
                                    <span className="card-action">VER PERFIL</span>
                                </div>
                            )}
                        </h5>
                        <b>ID:</b> { patient.get("patientNumberId") || "Não informado" }<br/>
                        <b>Tutor(a):</b> { patient.get("owner").get("name") || "Não informado" }<br/>
                        <b>Raça:</b> { patient.get("breed") || "Não informado" }
                    </div>
                </div>
            </div>
            <div className="col-auto mx-auto">
                { onDelete && (
                    <button className="btn-outline-submit btn-square me-3" onClick={() => onDelete && onDelete(patient.id)}>
                        <IconPrimaryTrash />
                        Excluir
                    </button>
                )}
                { onEdit && (
                    <button className="btn-outline-submit btn-square" onClick={() => onEdit && onEdit(patient.id)}>
                        <IconPrimaryEdit />
                        Editar
                    </button>
                )}
            </div>
        </div>
    )
}

export default CardPatient;