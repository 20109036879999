import React from "react"
import { connect } from "react-redux"

import "./AppointmentInfo.scss"
import { dayjs } from "../../utils"

const AppointmentInfo = (props) => {

    const {
        user
    } = props

    return (
        <div id="appointment-info">
            <div className="appointment-signature">
                <div className="appointment-date">{ dayjs().format('LL') }</div>
                <div className="appointment-info">Assinado eletronicamento por</div>
                <div className="appointment-doctor">{ user.signature || `Dr(a) ${user.fullName}` }</div>
                { user.crmv && user.crmvStateId && (
                    <div className="appointment-crmv">CRMV { user.crmv }/{ user.crmvStateId }</div>
                )}
            </div>
            <div className="appointment-data">
                <div className="appointment-title">Informações da Consulta</div>
                <div className="appointment-subtitle">horário da consulta: { dayjs().format('LT').replace(":", "H") }</div>
                <div className="appointment-text">Animal apresentou bastante fraqueza e dor. Não respondeu a estímulos e apresentou vômito nos últimos 5 dias</div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        user: state.user
    };
};

export default connect(mapStateToProps)(AppointmentInfo)