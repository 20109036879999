import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom'

import { CircularProgress, Switch, TextField, Tooltip } from '@mui/material';
import Autocomplete from '@mui/lab/Autocomplete';

import { ReactComponent as IconInfoO } from "../../assets/icon-primary-info-o.svg"
import { ReactComponent as IconWarning } from "../../assets/icon-warning-o.svg"

import {
    APP_NAME,
    APP_DEVICE,
    APP_VERSION,
    ACL
} from '../../constants'
import { 
    addEvent,
    mandatoryField,
    saveOnTracker
} from '../../utils'
import { getStockSupply, getUnits } from './Supply';
import { getCategories, addCategory } from '../service/Service';

import VSAccordionSimple from '../../components/vsAccordion/VSAccordionSimple';
import VSDrawer from '../../components/vsDrawer/VSDrawer';
import VSError from '../../components/vsError/VSError';
import { VSSimpleTooltip } from '../../components/vsTooltip/VSTooltip';
import VSCheckbox from '../../components/vsCheckbox/VSCheckbox';

import "./SupplyForm.scss"
const SupplyForm = (props) => {

    const {
        id_insumo
    } = useParams()

    const {
        clinic,
        history,
        permissions
    } = props

    const update = id_insumo != "novo"

    const [ loading, setLoading ] = useState(false)
    const [ error, setError ] = useState(null)
    const [ saveLoading, setSaveLoading ] = useState(false)
    const [ supply, setSupply ] = useState(null)
    const [ canNotEditSupply, setCanNotEditSupply ] = useState(true)

    const [ supplyName, setSupplyName ] = useState('')
    const [ errorSupplyName, setErrorSupplyName ] = useState(false)
    const [ helperSupplyName, setHelperSupplyName ] = useState(mandatoryField)

    const [ notes, setNotes ] = useState('')
    const [ supplyId, setSupplyId ] = useState('')
    const [ category, setCategory ] = useState('')
    const [ active, setActive ] = useState(true)
    const [ unit, setUnit ] = useState("")
    const [ errorUnit, setErrorUnit ] = useState(false)
    const [ helperUnit, setHelperUnit ] = useState(mandatoryField)
    const [ totalQuantity, setTotalQuantity ] = useState(null)
    const [ minQuantity, setMinQuantity ] = useState(null)
    const [ maxQuantity, setMaxQuantity ] = useState(null)
    const [ errorQuantity, setErrorQuantity ] = useState(false)
    const [ helperQuantity, setHelperQuantity ] = useState("")

    const [ companyName, setCompanyName ] = useState(null)
    const [ distributor, setDistributor ] = useState(null)
    const [ reminder, setReminder ] = useState(false)

    const [ unitOptions, setUnitOptions ] = useState([]);
    const [ categoryOptions, setCategoryOptions ] = useState([]);

    const [ supplyIds, setSupplyIds ] = useState({})
    const [ supplyIdsLoading, setSupplyIdsLoading ] = useState(false)

    const handleCategory = (selected) => {
        const category = selected
        setCategory(category)
    }

    // TODO: missing min max validations
    const validateOnTouch = () => {
        if (supplyName || supplyId || notes || category || unit || minQuantity || maxQuantity || totalQuantity) {
            validate()
        }
    }

    const validate = () => {
        let isValid = true
        if (loading) {
            isValid = false
        }
        if (saveLoading) {
            isValid = false
        }
        if (error?.message) {
            isValid = false
        }

        if (!supplyName) {
            isValid = false
            setErrorSupplyName(true)
            setHelperSupplyName("O nome do insumo é obrigatório")
        } else {
            setErrorSupplyName(false)
            setHelperSupplyName(mandatoryField)
        }

        if (!unit) {
            isValid = false
            setErrorUnit(true)
            setHelperUnit("A unidade de medida do insumo é obrigatória")
        } else {
            setErrorUnit(false)
            setHelperUnit(mandatoryField)
        }

        const min = parseInt(minQuantity)
        const max = parseInt(maxQuantity)
        const total = parseInt(totalQuantity)
        if (total < 0) {
            isValid = false
            setErrorQuantity(true)
            setHelperQuantity("A quantidade total em estoque não pode ser menor que 0 (zero)")
        } else if (max < 0) {
            isValid = false
            setErrorQuantity(true)
            setHelperQuantity("A quantidade máxima em estoque não pode ser menor que 0 (zero)")
        } else if (min < 0) {
            isValid = false
            setErrorQuantity(true)
            setHelperQuantity("A quantidade mínima em estoque não pode ser menor que 0 (zero)")
        } else if (max && min && max < min) {
            isValid = false
            setErrorQuantity(true)
            setHelperQuantity("A quantidade máxima em estoque não pode ser menor que a a quantidade mínima em estoque")
        } else {
            setErrorQuantity(false)
            setHelperQuantity("")
        }

        return isValid
    }

    const isValid = () => !loading && !saveLoading && !errorSupplyName && !errorQuantity && !errorUnit

    const fieldsWithErrorText = () => {
        let errors = []
        if (loading) {
            errors.push("Estamos procurando o insumo")
        }
        if (saveLoading) {
            errors.push("Salvando o insumo")
        }
        if (error?.message) {
            errors.push(error.message)
        }
        if (errorSupplyName) {
            errors.push("Nome do insumo")
        }
        if (errorUnit) {
            errors.push("Unidade de medida do insumo")
        }
        if (errorQuantity) {
            errors.push("Quantidades em estoque")
        }
        if (!permissions?.check(ACL.SUPPLY, ACL.Rule.EDIT, supply)) {
            errors.push("Acesso negado")
        }
        return errors
    }

    const save = () => {
        return new Promise((resolve, reject) => {
            if (validate()) {
                setSaveLoading(true)
                return Promise.resolve().then(() => {
                    let categoryToSave = categoryOptions.find(_category => _category?.get('categoryName') == category);
                    if(categoryToSave){
                        return categoryToSave
                    } else if (category) {
                        let newCategory = new Parse.Object("MRCategory")
                        newCategory.setACL(clinic.ACL)
                        newCategory.set("isDeleted", false)
                        newCategory.set("clinic", clinic.object)
                        newCategory.set("createdBy", Parse.User.current())
                        newCategory.set("createdApp", APP_NAME)
                        newCategory.set("createdDevice", APP_DEVICE)
                        newCategory.set("createdAppVersion", APP_VERSION)
                        newCategory.set("categoryName", category)
                        newCategory.set("changedAt", new Date())
                        newCategory.set("changedBy", Parse.User.current())
                        newCategory.set("changedApp", APP_NAME)
                        newCategory.set("changedDevice", APP_DEVICE)
                        newCategory.set("changedAppVersion", APP_VERSION)

                        return newCategory.save()
                    } else {
                        return Promise.resolve()
                    }
                }).then(categoryToSave => {
                    addCategory(categoryToSave)
                    supply.set("supplyName", supplyName);
                    supply.set("supplyId", supplyId);
                    if (categoryToSave) {
                        supply.set("category", categoryToSave);
                    }
                    supply.set("notes", notes);
                    supply.set("active", active);
                    supply.set("minQuantity", parseInt(minQuantity));
                    supply.set("maxQuantity", parseInt(maxQuantity));
                    supply.set("companyName", companyName);
                    supply.set("distributor", distributor);
                    supply.set("unit", unit)
                    supply.set("reminder", reminder);
                    supply.set("changedAt", new Date())
                    supply.set("changedBy", Parse.User.current())
                    supply.set("changedApp", APP_NAME)
                    supply.set("changedDevice", APP_DEVICE)
                    supply.set("changedAppVersion", APP_VERSION)
                    return supply.save()
                }).then(_supply => {
                    if (update) {
                        return _supply
                    } else {
                        let supplyTransaction = new Parse.Object("MRSupplyTransaction");
                        supplyTransaction.set("supply", _supply)
                        supplyTransaction.set("quantity", Number(totalQuantity) > 0 ? Number(totalQuantity) : 0)
                        supplyTransaction.set("notes", "Estoque inicial")
                        supplyTransaction.set("transactionDate", new Date());
                        supplyTransaction.set("clinic", clinic.object)
                        supplyTransaction.set("createdBy", Parse.User.current())
                        supplyTransaction.set("createdApp", APP_NAME)
                        supplyTransaction.set("createdDevice", APP_DEVICE)
                        supplyTransaction.set("createdAppVersion", APP_VERSION)
                        supplyTransaction.set("changedAt", new Date())
                        supplyTransaction.set("changedBy", Parse.User.current())
                        supplyTransaction.set("changedApp", APP_NAME)
                        supplyTransaction.set("changedDevice", APP_DEVICE)
                        supplyTransaction.set("changedAppVersion", APP_VERSION)
                        return supplyTransaction.save().then(_supplyTransaction => {
                            return _supply;
                        })
                    }
                }).then(_supply => {
                    saveOnTracker("Concluiu", "Insumo", _supply.id)
                    setSaveLoading(false)
                    resolve(_supply)
                })
                .catch(error => {
                    setSaveLoading(false)
                    console.error(error)
                    Swal.fire(
                        'Desculpe',
                        `Ocorreu algum erro ao tentar ${supply.id ? "editar" : "cadastrar"} o insumo`,
                        'error'
                    )
                    update && supply.revert()
                    reject()
                })
            } else {
                reject()
            }
        })
    }

    const getSupplyOrError = () => {
        if (permissions?.check(ACL.SUPPLY, ACL.Rule.VIEW)) {
            setLoading(true)
            setError(null)

            if (update) {
                getStockSupply(id_insumo, clinic.object)
                .then(_stock => {
                    const _supply = _stock.get("supply")
                    setSupply(_supply)
                    setSupplyName(_supply.get("supplyName"))
                    setSupplyId(_supply.get("supplyId"));
                    if(_supply.get("category")){
                        setCategory(_supply.get("category").get("categoryName"));
                    }
                    setNotes(_supply.get("notes"))
                    setActive(_supply.get("active"))
                    setUnit(_supply.get("unit"))
                    setTotalQuantity(_stock.get("total"))
                    setMinQuantity(_supply.get("minQuantity"))
                    setMaxQuantity(_supply.get("maxQuantity"))
                    setCompanyName(_supply.get("companyName"))
                    setDistributor(_supply.get("distributor"))
                    setReminder(_supply.get("reminder"))
                    setCanNotEditSupply(_supply.get("unit") ? true : false)
                    setLoading(false)
                })
                .catch(error => {
                    Swal.fire(
                        'Desculpe',
                        error,
                        'error'
                    )
                    .then(_ => {
                        setLoading(false)
                        setError({
                            message: error,
                            function: "getSupplyOrError"
                        })
                    })
                })
            } else {
                let newSupply = new Parse.Object("MRSupply")
                newSupply.setACL(clinic.ACL)
                newSupply.set("clinic", clinic.object)
                newSupply.set("createdBy", Parse.User.current())
                newSupply.set("createdApp", APP_NAME)
                newSupply.set("createdDevice", APP_DEVICE)
                newSupply.set("createdAppVersion", APP_VERSION)
                newSupply.set("active", true)
                newSupply.set("isDeleted", false)
                setSupply(newSupply)
                setCanNotEditSupply(false)
                setLoading(false)
            }
        }
    }

    useEffect(() => {
        let preventMultipleRequests
        if (supplyId && supplyIds[supplyId] == undefined) {
            setSupplyIdsLoading(true)
            preventMultipleRequests = setTimeout(() => {
                const query = new Parse.Query("MRSupply")
                query.equalTo("clinic", clinic.object)
                if (supply?.id) {
                    query.notEqualTo("objectId", supply.id)
                }
                query.equalTo("supplyId", supplyId)
                query.equalTo("isDeleted", false)
                query.limit(1000000)
                query.find()
                .then(result => {
                    const newSupplyIds = {...supplyIds}
                    newSupplyIds[supplyId] = result.length > 0 ? result : null
                    setSupplyIds(newSupplyIds)
                    setSupplyIdsLoading(false)
                })
                .catch(error => {
                    console.error(error)
                    setSupplyIdsLoading(false)
                })
            }, 300)
        }
        return () => {
            clearTimeout(preventMultipleRequests)
        }
    }, [ supplyId ])

    useEffect(() => {
        validateOnTouch()
    }, [ supplyName, minQuantity, maxQuantity, totalQuantity, unit ])

    useEffect(() => {
        if (supply && !permissions?.check(ACL.SUPPLY, ACL.Rule.EDIT, supply)) {
            permissions?.error()
        }
    }, [ supply ])

    useEffect(() => {
        if (clinic && clinic.isLoaded && clinic.object) {
            saveOnTracker("Iniciou", "Insumo", id_insumo !== "novo" ? id_insumo : "")
            getUnits().then(_units => setUnitOptions(_units))
            getCategories(clinic.object).then(_categories => setCategoryOptions(_categories));
            if (id_insumo != "selecionar") {
                getSupplyOrError();
            }
        }
    }, [ clinic ])

    const errors = fieldsWithErrorText()

    const usage = supplyIds[supplyId]

    return (
        <VSDrawer
            title={update ? "Editar Cadastro de Insumo" : "Novo Cadastro de Insumo"}
            cancel={update ? "Cancelar Alterações" : <span>Cancelar<span className="d-none d-md-inline"> Novo</span> Cadastro</span>}
            submit={isValid ? ( update ? "Salvar Alterações" : "Salvar Cadastro" ) : <span>Preencha os campos<span className="d-none d-md-inline"> necessários</span></span>}
            errors={errors}
            onSubmit={save}
            onCancel={() => history.goBack()}
            onAfterSave={(object) => {
                history.replace(`/insumo`)
                addEvent("Supply__getList", {})
            }}
            VSDrawerSuccessProps={{
                title: update ? "Alterações salvas com sucesso!" : "Cadastro realizado com sucesso!" ,
                text: update ? "Você será redirecionado(a) automaticamente para a aba de Insumos" : "Escolha uma das opções abaixo para prosseguir",
                VSDrawerFooterProps: {
                    children: !update && (
                        <div className="row">
                            <div className="col pe-2">
                                <button
                                    className="btn btn-success w-100"
                                    onClick={() => history.replace(`/insumo`)}
                                >
                                    Ir para a aba de insumos
                                </button>
                            </div>
                            <div className="col ps-2">
                                <button
                                    className="btn btn-save w-100"
                                    onClick={() => history.replace(`/insumo/novo`)}
                                >
                                    Cadastrar um novo insumo
                                </button>
                            </div>
                        </div>
                    )
                }
            }} 
            VSDrawerCancelProps={{
                title: update ? "Tem certeza que deseja cancelar as alterações no cadastro do insumo? " : "Tem certeza que deseja cancelar o novo cadastro de insumo?",
                confirm: update ? "Cancelar  Alterações" : "Cancelar Novo Cadastro",
                cancel: update ? "Voltar para Edição do Cadastro" : "Voltar para Edição do Novo Cadastro"
            }}
        >
            { loading ? (
                <div className="row data-loading">
                    <div className="col">
                        <CircularProgress />
                    </div>
                </div>
            ) : error?.message ? (
                <VSError
                    message={error.message}
                    onClose={() => {
                        switch(error.function) {
                            case "getSupplyOrError":
                                getSupplyOrError()
                                break
                            default:
                        }
                    }}
                />
            ) : (
                <>
                    { supply && (
                        <>
                            <div className="row align-items-center">
                                <div className="col">
                                    <p className="my-3" style={{ 
                                        textTransform: "uppercase",
                                        fontWeight: 600
                                    }}>
                                        Insumo Está Ativo?
                                    </p>
                                </div>
                                <div className="col-auto">
                                    <Switch
                                        checked={active}
                                        onChange={({ target: { checked } }) => setActive(checked)}
                                        color="primary"
                                    />
                                </div>
                            </div>
                            { !active && active != supply?.get("active") && (
                                <div className="mb-3 vsalert">
                                    <div className="row align-items-center">
                                        <div className="col-auto">
                                            <IconWarning width="24px" height="24px" fill="var(--vsprontuario-secondary-color)" />
                                        </div>
                                        <div className="col ps-0">
                                            <h4 className="vsalert-title">
                                                Você definiu que o insumo não está ativo.
                                            </h4>
                                            <p className="vsalert-text">
                                                Ao salvar essa alteração, este insumo ficará oculto para seleção em outras funcionalidades até você reativa-lo.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <hr/>
                            <div className="row mt-2">
                                <div className="col">
                                    <VSAccordionSimple
                                        expanded={true}
                                        header={(_) => (
                                            "Informações do insumo"
                                        )}
                                    >
                                        <div className="row">
                                            <div className="col-6 col-md-2 mb-3">
                                                <TextField
                                                    placeholder="ID"
                                                    label="ID"
                                                    value={supplyId}
                                                    onChange={({ target: { value } }) => setSupplyId(value)}
                                                    helperText={(
                                                        <>
                                                        { supplyIdsLoading ? (
                                                            <CircularProgress size={14} className="me-1" />
                                                        ) : usage && (
                                                                <VSSimpleTooltip
                                                                    title={
                                                                        <div className="p-3">
                                                                            <p className="mb-1">O ID está em uso no{usage.length == 1 ? "" : "s"} seguinte{usage.length == 1 ? "" : "s"} insumo{usage.length == 1 ? "" : "s"}:</p>
                                                                            <table className="table table-borderless mb-0">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th>#</th>
                                                                                        <th>Nome</th>
                                                                                        <th>Estado</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    { usage.map((supply, index) => (
                                                                                        <tr key={index}>
                                                                                            <td>{ supply.get("supplyId") }</td>
                                                                                            <td>{ supply.get("supplyName") }</td>
                                                                                            <td className={supply.get("active") ? "text-success" : "text-danger"}>{ supply.get("active") ? "Ativo" : "Inativo" }</td>
                                                                                        </tr>
                                                                                    )) }
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    }
                                                                >
                                                                    <IconWarning width={16} height={16} className="me-1" />
                                                                </VSSimpleTooltip>
                                                            )}
                                                            Campo Opcional
                                                        </>
                                                    )}
                                                    size="small"
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                            </div>
                                            <div className="col-12 col-md-10 mb-3">
                                                <TextField
                                                    placeholder="Nome do Insumo"
                                                    label="Nome do Insumo"
                                                    value={supplyName}
                                                    onChange={({ target: { value } }) => setSupplyName(value)}
                                                    error={errorSupplyName}
                                                    helperText={helperSupplyName}
                                                    size="small"
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                            </div>
                                            <div className="col-12 mb-3">
                                                <Autocomplete
                                                    inputValue={category}
                                                    options={categoryOptions}
                                                    getOptionLabel={(option) => option?.id ? option.get('categoryName') : category}
                                                    getOptionSelected={(option) => option?.id && option.get('categoryName') == category}
                                                    onChange={(_, selected) => handleCategory(selected?.id ? selected.get('categoryName') : category)}
                                                    onInputChange={(event, selected) => {
                                                        if (event){
                                                            handleCategory(selected)
                                                        }
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            placeholder="Categoria"
                                                            label="Categoria do insumo"
                                                            helperText={<span>Campo Opcional</span>}
                                                            size="small"
                                                            variant="outlined"
                                                            fullWidth
                                                        />
                                                    )}
                                                    openOnFocus
                                                    freeSolo
                                                />
                                            </div>
                                            <div className="col-12 mb-3">
                                                <TextField
                                                    placeholder="Digite aqui o fabricante"
                                                    label="Digite aqui o fabricante"
                                                    value={companyName}
                                                    onChange={({ target: { value } }) => setCompanyName(value)}
                                                    helperText={<span>Campo Opcional</span>}
                                                    size="small"
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                            </div>
                                            <div className="col-12 mb-3">
                                                <TextField
                                                    placeholder="Digite aqui o fornecedor"
                                                    label="Digite aqui o fornecedor"
                                                    value={distributor}
                                                    onChange={({ target: { value } }) => setDistributor(value)}
                                                    helperText={<span>Campo Opcional</span>}
                                                    size="small"
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                            </div>
                                        </div>
                                    </VSAccordionSimple>
                                </div>
                            </div>
                            <hr className="my-2" />
                            <div className="row">
                                <div className="col">
                                    <VSAccordionSimple
                                        expanded={true}
                                        header={(_) => (
                                            "Informações do estoque"
                                        )}
                                    >
                                        <div className="row g-3 mb-3">
                                            <div className="col-12">
                                                <div className="input-subtitle mb-1">
                                                    Unidade de medida
                                                    { canNotEditSupply && (
                                                        <Tooltip
                                                            arrow
                                                            id="tooltip-info"
                                                            placement="right"
                                                            title="Não é possível realizar mudanças na medida do insumo."
                                                        >
                                                            <IconInfoO className="ms-1" width="16" height="16" fill="var(--vsprontuario-gray-color-dark)" />
                                                        </Tooltip>
                                                    )}
                                                </div>
                                                <Autocomplete
                                                    value={unit}
                                                    disabled={canNotEditSupply}
                                                    options={unitOptions}
                                                    getOptionLabel={(option) => option?.name || unitOptions.find(u => u.id == option)?.name || ""}
                                                    isOptionEqualToValue={(option, value) => option.id == value}
                                                    onChange={(_, selected) => setUnit(selected?.id || "")}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            placeholder="Escolha uma medida"
                                                            error={errorUnit}
                                                            helperText={helperUnit}
                                                            size="small"
                                                            variant="outlined"
                                                            fullWidth
                                                        />
                                                    )}
                                                    openOnFocus
                                                />
                                            </div>
                                            <div className="col-6">
                                                <div className="input-subtitle mb-1">
                                                    Total em estoque (un.)
                                                    { update && (
                                                        <Tooltip
                                                            arrow
                                                            id="tooltip-info"
                                                            placement="right"
                                                            title="Para realizar alterações no total em estoque deste insumo, registre uma entrada ou saída."
                                                        >
                                                            <IconInfoO className="ms-1" width="16" height="16" fill="var(--vsprontuario-gray-color-dark)" />
                                                        </Tooltip>
                                                    )}
                                                </div>
                                                <TextField
                                                    InputProps={{
                                                        inputProps: { min: 0 }
                                                    }}
                                                    className="w-100"
                                                    placeholder="Ex. 30"
                                                    type="tel"
                                                    value={totalQuantity}
                                                    onChange={({ target: { value } }) => setTotalQuantity(value)}
                                                    helperText={<span>Campo Opcional</span>}
                                                    disabled={update || !unit}
                                                    size="small"
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                            </div>
                                            <div className="col-6">
                                                <div className="input-subtitle mb-1">
                                                    Mínimo em estoque (un.)
                                                </div>
                                                <TextField
                                                    InputProps={{
                                                        inputProps: { min: 0 }
                                                    }}
                                                    className="w-100"
                                                    placeholder="Ex. 30"
                                                    type="tel"
                                                    value={minQuantity}
                                                    onChange={({ target: { value } }) => setMinQuantity(value)}
                                                    helperText={<span>Campo Opcional</span>}
                                                    disabled={!unit}
                                                    size="small"
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                            </div>
                                            { errorQuantity && (
                                                <div className="col-12">
                                                    <small className="text-danger">
                                                        { helperQuantity }
                                                    </small>
                                                </div>
                                            )}
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <VSCheckbox
                                                    label="Lembrete de reposição"
                                                    checked={reminder}
                                                    onChange={(checked) => setReminder(checked)}
                                                />
                                            </div>
                                        </div>
                                        {/* 
                                        <hr className="my-3" />
                                        <div className="row g-3 mb-3">
                                            <div className="col-12">
                                                A opção de <b>Envio de Mensagem de Reposição</b> esta <b>DESATIVADA</b> no 
                                                cadastro da clínica. Ao ativa-las, serão enviadas mensagens via WhatsApp 
                                                ou e-mail para que seja notificado quando esse insumo estiver acabando.
                                            </div>
                                            <div className="col-12">
                                                <a href="#" className="text-secondary">Saiba mais sobre a Mensagem de Reposição</a>
                                            </div>
                                        </div>
                                        */}
                                    </VSAccordionSimple>
                                </div>
                            </div>
                            <hr className="my-2" />
                            <div className="row">
                                <div className="col">
                                    <VSAccordionSimple
                                        expanded={true}
                                        header={(_) => (
                                            "Observações do insumo"
                                        )}
                                    >
                                        <div className="row">
                                            <div className="col-12">
                                                <TextField
                                                    placeholder="Digite as observações sobre o insumo"
                                                    label="Digite as observações sobre o insumo"
                                                    value={notes}
                                                    onChange={({ target: { value } }) => setNotes(value)}
                                                    helperText={<span>Campo Opcional<br/></span>}
                                                    size="small"
                                                    variant="outlined"
                                                    multiline
                                                    fullWidth
                                                />
                                            </div>
                                        </div>
                                    </VSAccordionSimple>
                                </div>
                            </div>
                        </>
                    )}
                </>
            )}
        </VSDrawer>
    )
}

const mapStateToProps = state => {
    return {
        clinic: state.clinic,
        permissions: state.permissions
    };
};

export default connect(mapStateToProps)(SupplyForm);