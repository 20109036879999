import React from 'react'
import { connect } from 'react-redux'

import { ReactComponent as IconStore } from "../../assets/icon-store.svg"

import { ACL } from '../../constants'

import './HomeStore.scss'
const HomeStore = (props) => {

    const {
        clinic,
        history,
        permissions
    } = props

    return (
        <div className="col-12 mb-3">
            <div className="box box-action">
                <div className="row box-header">
                    <div className="col-auto">
                        <IconStore width="24px" height="24px" fill="#B3B3B3" />
                    </div>
                    <div className="col px-0">
                        <b>Opção de Compra</b>
                    </div>
                    <div className="col-auto">
                        { clinic.displayStore ? (
                            <b style={{ color: "var(--vsprontuario-secondary-color)" }}>
                                Ativada
                            </b> 
                        ) : (
                            <b style={{ color: "var(--vsprontuario-negative-color)" }}>
                                Desativada
                            </b>
                        )}
                    </div>
                </div>
                <div className="row gx-3 align-items-center">
                    <div className="col-12">
                        <hr />
                    </div>
                    { permissions?.check(ACL.SETTING, ACL.Rule.EDIT) && (
                        <div className="col">
                            <div
                                className="btn-link text-center cursor-pointer"
                                onClick={() => history.push(`/opcao-compra`)}
                            >
                                Editar Opção de Compra
                            </div>
                        </div>
                    )}
                    <div className="col">
                        <a target="_blank" href="https://minhaloja.petlove.com.br/" onClick={() => saveOnTracker("Acessou", "Criação da Loja", "", "", "Clínica", clinic.objectId, "", "")} className="btn-outline-submit btn-square w-100 d-block">
                            Criar minha loja online
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        clinic: state.clinic,
        permissions: state.permissions
    };
};
  
export default connect(mapStateToProps)(HomeStore);