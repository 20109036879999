import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react"
import { RRule, RRuleSet } from "rrule";

import { FormLabel, MenuItem, TextField } from "@mui/material";

import { dayOptions, frequencyOptions, getWeekdayFromNumber, monthOptions, weekOptions, weekPositionOptions } from "./Recurrence";
import { dayjs, validateDate } from "../../utils";

import VSRadio from "../../components/vsRadio/VSRadio";
import DateInput from "../../components/dateInput/DateInput";

import "./RecurrenceForm.scss"
const RecurrenceForm = forwardRef((props, ref) => {

    const {
        documentDate,
        finishDate,
        rrule,
        onChange
    } = props

    const date = dayjs().add(1, "years")

    const [ freq, setFreq ] = useState(frequencyOptions[0].value)
    const [ interval, setInterval ] = useState(1)
    const [ byDay, setByDay ] = useState("")
    const [ byDayCustom, setByDayCustom ] = useState([])
    const [ byMonth, setByMonth ] = useState(1)
    const [ byMonthDay, setByMonthDay ] = useState(1)
    const [ monthOrMonthDay, setMonthOrMonthDay ] = useState("month")
    const [ bySetPos, setBySetPos ] = useState(1)
    const [ ends, setEnds ] = useState("after")
    const [ count, setCount ] = useState(1)

    const [ until, setUntil ] = useState(date.toDate())
    const [ untilText, setUntilText ] = useState(date.format("DD/MM/YYYY"))

    useImperativeHandle(ref, () => ({
        validate: validate,
        rrule: rruleString,
        save: onSave
    }));

    const handleDate = (date) => {
        setUntilText(date)
        const _until = validateDate(date)
        if (_until) {
            setUntil(_until)
        }
    }

    const validate = (errors) => {
        switch(freq) {
            case RRule.DAILY:
                if (interval <= 0)
                    errors.push("O número de dia(s) em que a recorrência será gerada não pode ser igual ou menor que zero")
                break;
            case RRule.WEEKLY:
                if (interval <= 0)
                    errors.push("O número de semana(s) em que a recorrência será gerada não pode ser igual ou menor que zero")
                const selectedByDays = byDayCustom
                if (selectedByDays.length == 0)
                    errors.push("Nenhum dia de semana foi selecionado")
                break;
            case RRule.MONTHLY:
                if (interval <= 0)
                    errors.push("O número de mês(meses) em que a recorrência será gerada não pode ser igual ou menor que zero")
                if (monthOrMonthDay == "monthDay") {
                    if (bySetPos < -2)
                        errors.push("A posição da semana selecionado não é válido")
                    const selectedByDays = byDay
                    if (selectedByDays.length == 0)
                        errors.push("O dia da semana selecionado não é válido")
                } else {
                    if (byMonthDay < 1 || byMonthDay > 31)
                        errors.push("O dia do mês selecionado não é válido")
                }
                break;
            case RRule.YEARLY:
                if (monthOrMonthDay == "monthDay") {
                    if (bySetPos < -2)
                        errors.push("A posição da semana selecionado não é válido")
                    const selectedByDays = byDay
                    if (selectedByDays.length == 0)
                        errors.push("O dia da semana selecionado não é válido")
                    if (byMonth < 1 || byMonth > 12)
                        errors.push("O mês selecionado não é válido")
                } else {
                    if (byMonth < 1 || byMonth > 12)
                        errors.push("O mês selecionado não é válido")
                    if (byMonthDay < 1 || byMonthDay > 31)
                        errors.push("O dia do mês selecionado não é válido")
                }
                break;
            default:
                errors.push("A frequência é obrigatória")
        }

        switch (ends) {
            case "after":
                if (count < 1)
                    errors.push("O numero de execuções não pode ser menor que 1")
                if (count > 100)
                    errors.push("O numero de execuções não pode ser maior que 100")
                break;
            case "onDate":
                if (!until)
                    errors.push("A data de término da recorrência não é válida")
                else {
                    const rule = getRrule()

                    if (!until) {
                        errors.push("A data de término não pode ser menor ou igual a data inicial")
                    }
                    else if (until && dayjs(documentDate).isSameOrAfter(until)) {
                        errors.push("A data de término não pode ser menor ou igual a data inicial")
                    }

                    const all = rule.all()
                    if (all.length > 100) {
                        errors.push(`A data de término não pode ser maior que ${dayjs(all[100]).format("DD/MM/YYYY")} (100 execuções)`)
                    }
                }
                break;
            default: 
                errors.push("É necessário especificar um limite para a recorrência")
        }

        return errors
    }

    const getRrule = () => {

        const hasInterval = freq != RRule.YEARLY ? { interval: interval } : {}
        const hasCount = ends == "after" ? { count: count + 1 } : {}
        const hasUntil = ends == "onDate" ? { until: (until ? dayjs(until) : dayjs()).toDate(), count: 101 } : {}
        const hasByMonth = freq == RRule.YEARLY ? { bymonth: byMonth } : {}
        var hasByWeekday = {}
        if (freq == RRule.WEEKLY) {
            hasByWeekday = { byweekday: byDayCustom }
        }
        var hasBySetPos = {}
        var hasMonthDay = {}
        if (freq == RRule.MONTHLY || freq == RRule.YEARLY) {
            if (monthOrMonthDay == "monthDay") {
                hasBySetPos = { bysetpos: bySetPos }
                hasByWeekday = { byweekday: byDay }
            } else {
                hasMonthDay = { bymonthday: byMonthDay }
            }
        }

        const rruleSet = new RRuleSet()
        rruleSet.rrule(
            new RRule({
                freq: freq,
                dtstart: documentDate,
                ...hasCount,
                ...hasUntil,
                ...hasInterval,
                ...hasByWeekday,
                ...hasByMonth,
                ...hasBySetPos,
                ...hasMonthDay
            })
        )
        return rruleSet
    }

    const rruleString = () => {
        return getRrule()?.toString()
    }

    const toggleByDay = (value) => {
        const newByDay = [...byDayCustom]
        const index = newByDay.indexOf(value)
        if (index == -1) {
            newByDay.push(value)
        } else {
            newByDay.splice(index, 1)
        }
        setByDayCustom(newByDay.filter(n => n))
    }

    const onSave = (_reminderId) => {
        return new Promise((resolve, reject) => {
            resolve()
        })
    }

    useEffect(() => {
        if (rrule?.freq >= 0) {
            setFreq(rrule.freq)
            setInterval(rrule?.interval || 1)

            if (rrule?.count) {
                setEnds("after")
                setCount(rrule.count - 1)
            }
            if (rrule?.until || finishDate) {
                setEnds("onDate")
                setUntil(rrule.until || finishDate)
                setUntilText(dayjs(rrule.until || finishDate).format("DD/MM/YYYY"))
            }

            if (rrule.freq == RRule.YEARLY) {
                setByMonth(rrule?.bymonth || 1)
            }

            if (rrule.freq == RRule.WEEKLY) {
                const _byDayCustom = rrule?.byweekday.map(weekdayNumber => getWeekdayFromNumber(weekdayNumber))
                setByDayCustom(_byDayCustom || [])
            }

            if (rrule.freq == RRule.MONTHLY || rrule.freq == RRule.YEARLY) {
                if (rrule?.bysetpos || rrule?.byweekday) {
                    setMonthOrMonthDay("monthDay")
                    setBySetPos(rrule?.bysetpos || 1)
                    const _byDay = rrule?.byweekday.map(weekdayNumber => getWeekdayFromNumber(weekdayNumber))
                    const byDayWithWeekOptions = weekOptions.filter(options => {
                        const optionsValue = Array.isArray(options.value) ? options.value : [options.value]
                        return JSON.stringify(optionsValue) == JSON.stringify(_byDay)
                    }) || []
                    setByDay(byDayWithWeekOptions[0]?.value || "")
                } else {
                    setMonthOrMonthDay("month")
                    setByMonthDay(rrule?.bymonthday || 1)
                }
            }
        }
    }, [ rrule ])

    useEffect(() => {
        onChange && onChange()
    }, [ 
        freq, interval, byDay, byDayCustom, byMonth, byMonthDay, monthOrMonthDay, bySetPos, ends, count, until, untilText
    ])

    return (
        <div className="row align-items-center">
            <div className="col-12">
                <div className="row align-items-center mb-2">
                    <div className="col-12">
                        <div className="input-title mb-3">
                            Repetir Recorrência
                        </div>
                    </div>
                    <div className="col-12 mb-2">
                        <TextField
                            placeholder="Frequência"
                            label="Frequência"
                            value={freq}
                            onChange={({ target: { value } }) => setFreq(value)}
                            helperText={null}
                            select
                            size="small"
                            variant="outlined"
                            fullWidth
                        >
                            { frequencyOptions.map((freqOption, index) => (
                                <MenuItem key={index} value={freqOption.value}>{ freqOption.text }</MenuItem>
                            )) }
                        </TextField>
                    </div>
                    { (freq == RRule.DAILY || freq == RRule.WEEKLY || freq == RRule.MONTHLY) && (
                        <div className="col-auto">
                            <FormLabel>
                                <div className="input-height">
                                    A cada
                                    <TextField
                                        type="tel"
                                        placeholder="0"
                                        variant="outlined"
                                        size="small"
                                        className="mx-2"
                                        style={{ width: '54px' }}
                                        value={interval}
                                        onChange={({ target: { value }}) => setInterval(parseInt(value) || "")}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                    { freq == RRule.DAILY && `dia${ interval == 1 ? "" : "s" }` }
                                    { freq == RRule.WEEKLY && `semana${ interval == 1 ? "" : "s" }` }
                                    { freq == RRule.MONTHLY && (interval == 1 ? "mês" : "meses") }
                                </div>
                            </FormLabel>
                        </div>
                    )}
                    { freq == RRule.WEEKLY && (
                        <div className="col-12 col-md">
                            <div className="row g-2">
                                <div className="col-3 col-md">
                                    <button
                                        className={`btn-outline-submit btn-select ${ byDayCustom.indexOf(RRule.SU) != -1 ? "selected" : "" }`}
                                        onClick={() => toggleByDay(RRule.SU)}
                                    >
                                        Dom
                                    </button>
                                </div>
                                <div className="col-3 col-md">
                                    <button
                                        className={`btn-outline-submit btn-select ${ byDayCustom.indexOf(RRule.MO) != -1 ? "selected" : "" }`}
                                        onClick={() => toggleByDay(RRule.MO)}
                                    >
                                        Seg
                                    </button>
                                </div>
                                <div className="col-3 col-md">
                                    <button
                                        className={`btn-outline-submit btn-select ${ byDayCustom.indexOf(RRule.TU) != -1 ? "selected" : "" }`}
                                        onClick={() => toggleByDay(RRule.TU)}
                                    >
                                        Ter
                                    </button>
                                </div>
                                <div className="col-3 col-md">
                                    <button
                                        className={`btn-outline-submit btn-select ${ byDayCustom.indexOf(RRule.WE) != -1 ? "selected" : "" }`}
                                        onClick={() => toggleByDay(RRule.WE)}
                                    >
                                        Qua
                                    </button>
                                </div>
                                <div className="col-3 col-md">
                                    <button
                                        className={`btn-outline-submit btn-select ${ byDayCustom.indexOf(RRule.TH) != -1 ? "selected" : "" }`}
                                        onClick={() => toggleByDay(RRule.TH)}
                                    >
                                        Qui
                                    </button>
                                </div>
                                <div className="col-3 col-md">
                                    <button
                                        className={`btn-outline-submit btn-select ${ byDayCustom.indexOf(RRule.FR) != -1 ? "selected" : "" }`}
                                        onClick={() => toggleByDay(RRule.FR)}
                                    >
                                        Sex
                                    </button>
                                </div>
                                <div className="col-3 col-md">
                                    <button
                                        className={`btn-outline-submit btn-select ${ byDayCustom.indexOf(RRule.SA) != -1 ? "selected" : "" }`}
                                        onClick={() => toggleByDay(RRule.SA)}
                                    >
                                        Sáb
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                    { (freq == RRule.MONTHLY || freq == RRule.YEARLY) && (
                        <>
                            <VSRadio
                                className="mb-3"
                                value={monthOrMonthDay}
                                onChange={(_, value) => {
                                    setMonthOrMonthDay(value)
                                }}
                                options={[
                                    {
                                        label: (
                                            <div className="row g-2 align-items-center">
                                                <div className="col-auto">
                                                    <div className="input-height">
                                                        No { freq == RRule.YEARLY ? "Mês" : "Dia" }
                                                    </div>
                                                </div>
                                                { freq == RRule.YEARLY && (
                                                    <div className="col">
                                                        <TextField
                                                            label="Mês"
                                                            value={byMonth}
                                                            onChange={({ target: { value } }) => setByMonth(value)}
                                                            helperText={null}
                                                            disabled={monthOrMonthDay == "monthDay"}
                                                            style={{ width: '150px' }}
                                                            select
                                                            size="small"
                                                            variant="outlined"
                                                            fullWidth
                                                        >
                                                            { monthOptions.map((monthOption, index) => (
                                                                <MenuItem key={index} value={monthOption.value}>{ monthOption.text }</MenuItem>
                                                            )) }
                                                        </TextField>
                                                    </div>
                                                )}
                                                <div className="col">
                                                    <TextField
                                                        label="Dia do Mês"
                                                        value={byMonthDay}
                                                        onChange={({ target: { value } }) => setByMonthDay(value)}
                                                        helperText={null}
                                                        disabled={monthOrMonthDay == "monthDay"}
                                                        style={{ width: '150px' }}
                                                        select
                                                        size="small"
                                                        variant="outlined"
                                                        fullWidth
                                                    >
                                                        { dayOptions.map((dayOption, index) => (
                                                            <MenuItem key={index} value={dayOption.value}>{ dayOption.text }</MenuItem>
                                                        )) }
                                                    </TextField>
                                                </div>
                                            </div>
                                        ),
                                        value: "month"
                                    },
                                    {
                                        label: (
                                            <div className="row g-2 align-items-center">
                                                <div className="col-auto">
                                                    <div className="input-height">
                                                        No(a)
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <TextField
                                                        label="Posição"
                                                        value={bySetPos}
                                                        onChange={({ target: { value } }) => setBySetPos(parseInt(value))}
                                                        helperText={null}
                                                        disabled={monthOrMonthDay == "month"}
                                                        style={{ width: '150px' }}
                                                        select
                                                        size="small"
                                                        variant="outlined"
                                                        fullWidth
                                                    >
                                                        { weekPositionOptions.map((posOption, index) => (
                                                            <MenuItem key={index} value={posOption.value}>{ posOption.text }</MenuItem>
                                                        )) }
                                                    </TextField>
                                                </div>
                                                <div className="col">
                                                    <TextField
                                                        label="Dia da semana"
                                                        value={byDay}
                                                        onChange={({ target: { value } }) => setByDay(value)}
                                                        helperText={null}
                                                        disabled={monthOrMonthDay == "month"}
                                                        style={{ width: '200px' }}
                                                        select
                                                        size="small"
                                                        variant="outlined"
                                                        fullWidth
                                                    >
                                                        { weekOptions.map((weekOption, index) => (
                                                            <MenuItem key={index} value={weekOption.value}>{ weekOption.text }</MenuItem>
                                                        )) }
                                                    </TextField>
                                                </div>
                                                { freq == RRule.YEARLY && (
                                                    <>
                                                        <div className="col-auto">
                                                            <div className="input-height">
                                                                de
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                            <TextField
                                                                label="Mês"
                                                                value={byMonth}
                                                                onChange={({ target: { value } }) => setByMonth(value)}
                                                                helperText={null}
                                                                disabled={monthOrMonthDay == "month"}
                                                                style={{ width: '150px' }}
                                                                select
                                                                size="small"
                                                                variant="outlined"
                                                                fullWidth
                                                            >
                                                                { monthOptions.map((monthOption, index) => (
                                                                    <MenuItem key={index} value={monthOption.value}>{ monthOption.text }</MenuItem>
                                                                )) }
                                                            </TextField>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        ),
                                        value: "monthDay"
                                    }
                                ]}
                            />
                        </>
                    )}
                </div>
                <div className="row align-items-center">
                    <div className="col-12">
                        <div className="input-title mb-3">
                            Terminar Recorrência
                        </div>
                    </div>
                    <div className="col-12">
                        <VSRadio
                            className="mb-3"
                            value={ends}
                            onChange={(_, value) => {
                                setEnds(value)
                            }}
                            options={[
                                {
                                    label: (
                                        <div className="row g-2 align-items-center">
                                            <div className="col-auto">
                                                <div className="input-height">
                                                    Depois de
                                                </div>
                                            </div>
                                            <div className="col">
                                                <TextField
                                                    type="tel"
                                                    placeholder="0"
                                                    variant="outlined"
                                                    size="small"
                                                    className="mx-1"
                                                    style={{ width: '58px' }}
                                                    value={count}
                                                    onChange={({ target: { value }}) => setCount(parseInt(value) || "")}
                                                    disabled={ends != "after"}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </div>
                                            <div className="col">
                                                <div className="input-height">
                                                    execuções
                                                </div>
                                            </div>
                                        </div>
                                    ),
                                    value: "after"
                                },
                                {
                                    label: (
                                        <div className="row g-2 align-items-center">
                                            <div className="col-auto">
                                                <div className="input-height">
                                                    No dia
                                                </div>
                                            </div>
                                            <div className="col">
                                                <DateInput
                                                    className="m-0"
                                                    placeholder="00/00/0000"
                                                    label="Até a data"
                                                    value={untilText || null}
                                                    helperText={null}
                                                    onChange={(value) => handleDate(value)}
                                                    disabled={ends != "onDate"}
                                                    size="small"
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                            </div>
                                        </div>
                                    ),
                                    value: "onDate"
                                }
                            ]}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
})

export default RecurrenceForm;