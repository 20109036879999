import React from 'react'
import { connect } from 'react-redux'

import { ReactComponent as IconEdit } from "../../../../assets/icon-primary-edit.svg"
import { ReactComponent as IconTrash } from "../../../../assets/icon-primary-trash.svg"
import { ReactComponent as IconShare } from "../../../../assets/icon-share.svg"
import { ReactComponent as IconPrescription } from "../../../../assets/icon-patient-prescription.svg"

import { addEvent, dayjs, formattedCurrency, showRegisteredBy } from '../../../../utils'
import { SALE, deleteSale } from './Sale'
import { ACL } from '../../../../constants'

import VSMedicalRecordsAudit from '../../../../components/vsMedicalRecordsAudit/VSMedicalRecordsAudit'
import SaleInfoView from './SaleInfoView'

import './SaleView.scss'
const SaleView = (props) => {

    const {
        object,
        history,
        noDocumentDate,
        noDocumentType,
        noAudit,
        updateList,
        permissions
    } = props

    const patient = object.get("patient")
    const isDeleted = object.get("isDeleted")
    const isSale = object.get("documentType") == SALE
    const saleIsPaid = object.get('paymentsPending') == 0
    const showAction = isSale ? !saleIsPaid : !object.sale

    return (
        <div id="patient-sale-view" className={`vsbox mb-3 ${isDeleted ? "vsbox-disabled" : ""}`}>
            <div className="vsbox-body pb-0">
                <div className="row align-items-center">
                    { !noDocumentDate && (
                        <div className="col-auto mb-3 pe-0">
                            <div className="vsbox-chip">
                                { dayjs(object.get("documentDate")).format("DD/MM/YYYY") }
                            </div>
                        </div>
                    )}
                    { !noDocumentType && (
                        <div className="col-12 col-md">
                            <div className="row align-items-center flex-nowrap">
                                <div className="col-auto mb-3">
                                    <IconPrescription width="24" height="24" fill="var(--vsprontuario-gray-color)" />
                                </div>
                                <div className="col ps-0 mb-3">
                                    <label className="vsbox-info text-nowrap">Tipo de Registro</label>
                                    <div className="vsbox-info-text">
                                        { isSale ? "Venda" : "Orçamento" }
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    { !isDeleted && (
                        <>
                            { isSale ? (
                                <>
                                    <div className="col-auto mb-3">
                                        <label className="vsbox-info">Total a Pagar</label>
                                        <div className="vsbox-info-text">{ formattedCurrency(object.get("finalValue")) }</div>
                                    </div>
                                    <div className="col-auto mb-3">
                                        <label className="vsbox-info">Total Pago</label>
                                        <div className="vsbox-info-text">{ formattedCurrency(object.get("paymentsValue")) }</div>
                                    </div>
                                    <div className="col-auto mb-3">
                                        <label className="vsbox-info">Estado da Venda</label>
                                        <div className={`vsbox-info-text ${ saleIsPaid ? "text-success" : "text-warning" }`}>{ saleIsPaid ? "Faturado" : "Pendente" }</div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="col-auto mb-3">
                                        <label className="vsbox-info">Valor Total</label>
                                        <div className="vsbox-info-text">{ formattedCurrency(object.get("servicesValue")) }</div>
                                    </div>
                                    <div className="col-auto mb-3">
                                        <label className="vsbox-info">Estado do Orçamento</label>
                                        <div className={`vsbox-info-text ${ object.get("childSale") ? "text-success" : "" }`}>{ object.get("childSale") ? "Realizado" : "Não realizado" }</div>
                                    </div>
                                </>
                            )}
                            <div className="col-12 col-lg-auto mb-3">
                                <div className="row align-items-center justify-content-end flex-wrap flex-md-nowrap">
                                    <div className="col col-md-auto order-2 order-md-0">
                                        <button
                                            className={`vsbox-btn ${isDeleted ? "vsbox-btn-disabled" : "vsbox-btn-accent"}`}
                                            onClick={() => history.push(`/compartilhar/${ object.id }`)}
                                            disabled={isDeleted}
                                        >
                                            <IconShare width="24" height="24" fill={isDeleted ? "var(--vsprontuario-gray-color)" : "var(--vsprontuario-primary-color-dark)"} />
                                        </button>
                                    </div>
                                    { permissions?.check(ACL.SALE, ACL.Rule.EDIT, object) && (
                                        <div className="col col-md-auto order-3 order-md-0 ps-0">
                                            <button
                                                className={`vsbox-btn ${isDeleted ? "vsbox-btn-disabled" : "vsbox-btn-dark"}`}
                                                onClick={() => history.push(`/${ isSale ? "venda" : "orcamento" }/${ patient.id }/${ object.id }`)}
                                                disabled={isDeleted}
                                            >
                                                <IconEdit width="24" height="24" fill={isDeleted ? "var(--vsprontuario-gray-color)" : "var(--vsprontuario-primary-color-dark)"} />
                                            </button>
                                        </div>
                                    )}
                                    { permissions?.check(ACL.SALE, ACL.Rule.DELETE, object) && (
                                        <div className="col col-md-auto order-4 order-md-0 ps-0">
                                            <button
                                                className={`vsbox-btn ${isDeleted ? "vsbox-btn-disabled" : "vsbox-btn-alert"}`}
                                                onClick={() => deleteSale(object, () => {
                                                    if(updateList){
                                                        updateList()
                                                    }
                                                    addEvent("History__getList", {})
                                                })}
                                                disabled={isDeleted}
                                            >
                                                <IconTrash width="24" height="24" fill={isDeleted ? "var(--vsprontuario-gray-color)" : "var(--vsprontuario-primary-color)"} />
                                            </button>
                                        </div>
                                    )}
                                    { showAction && permissions?.check(ACL.SALE, ACL.Rule.EDIT, object) && (
                                        <div className="col-12 col-md-auto ps-md-0 order-1 order-md-0 mb-3 mb-md-0">
                                            {  isSale ? (
                                                <button
                                                    className={`vsbox-btn ${isDeleted ? "vsbox-btn-disabled" : "vsbox-btn-primary"}`}
                                                    onClick={() => history.push(`/pagamento/venda/${ object.id }`, { redirectTo: history.location?.pathname })}
                                                    disabled={isDeleted}
                                                >
                                                    Quitar Venda
                                                </button>
                                            ) : (
                                                <button
                                                    className={`vsbox-btn ${isDeleted ? "vsbox-btn-disabled" : "vsbox-btn-primary"}`}
                                                    onClick={() => history.push(`/venda/${ patient.id }/${ object.id }`, { redirectTo: history.location?.pathname })}
                                                    disabled={isDeleted}
                                                >
                                                    Criar Venda
                                                </button>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </>
                    )}
                </div>
                { showRegisteredBy(object) }
                <SaleInfoView {...props} />
                { !noAudit && (
                    <VSMedicalRecordsAudit parseObject={object} />
                )}
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        permissions: state.permissions
    };
};
  
export default connect(mapStateToProps)(SaleView)