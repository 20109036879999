import { RRule } from "rrule"
import VSModal from "../../components/vsModal/VSModal"
import { dayjs, diacriticSensitive } from "../../utils"
import { Filter_Limit } from "../../constants"
import { RECURRENCE_ALL, RECURRENCE_FUTURE, RECURRENCE_THIS } from "../recurrence/Recurrence"

const TAG_DEFAULT = "Padrão"

export const CALENDAR_DEFAULT = "default"
export const CALENDAR_OPEN = "open"
export const CALENDAR_CONFIRMED = "confirmed"
export const CALENDAR_DONE = "done"
export const CALENDAR_NOSHOW = "noshow"
export const CALENDAR_CANCELED = "canceled"
export const CALENDAR_CANCELED_MESSAGE = "canceled_message"
export const CALENDAR_BLANK = "blank"
export const CALENDAR_BLOCK = "block"

export const isDefaultCalendar = (status) => {
    return [CALENDAR_BLANK, CALENDAR_BLOCK].indexOf(status) == -1
}

export const deleteCalendar = (calendar, callback) => {
	const hasRecurrence = calendar.get("recurrence")
    Swal.fire({
        // allowOutsideClick: false,
        showConfirmButton: false,
        customClass: {
            popup: 'p-0',
            content: 'p-0',
        },
        html: (
            <VSModal
                title="Tem certeza que deseja excluir este evento?"
                text="Os dados não poderão ser recuperados e esta operação não poderá ser desfeita"
                selectText={hasRecurrence && <>Selecione o que você deseja deletar</>}
                selectOptions={hasRecurrence && [
                    {
						label: "Esse evento",
						value: RECURRENCE_THIS
                    },
                    {
						label: "Esse e os eventos seguintes",
						value: RECURRENCE_FUTURE
                    },
                    {
						label: "Todos os eventos",
						value: RECURRENCE_ALL
                    }
				]}
                options={[
                    {
                        type: "cancel",
                        text: "Cancelar Operação",
                        action: () => {
                            Swal.close()
                        }
                    },
                    {
                        type: "confirm",
                        text: "Excluir evento",
                        action: (selected) => {
							Parse.Cloud.run("deleteCalendarForClinic", {
								objectId: calendar.id,
								recurrenceType: selected || RECURRENCE_THIS,
								clinicId: calendar.get("clinic")?.id,
								patientId: calendar.get("patient")?.id
							})
							.then(_calendars => {
								Swal.fire(
									'Excluído!',
									_calendars?.length == 1 ? 'O evento foi excluído com sucesso.' : 'Os eventos foram excluídos com sucesso.',
									'success'
								)
								.then(_ => {
									callback && callback()
								})
							})
							.catch(error => {
								console.error(error)
								Swal.fire(
									'Desculpe',
									'Ocorreu algum erro ao tentar excluir o(s) evento(s)',
									'error'
								)
							})
                        }
                    }
                ]}
            />
        )
    })
}

export const deleteTag = (tag, callback) => {
    Swal.fire({
        // allowOutsideClick: false,
        showConfirmButton: false,
        customClass: {
            popup: 'p-0',
            content: 'p-0',
        },
        html: (
            <VSModal
                title="Tem certeza que deseja excluir esta tag?"
                text="Os dados não poderão ser recuperados e esta operação não poderá ser desfeita"
                options={[
                    {
                        type: "cancel",
                        text: "Cancelar Operação",
                        action: () => {
                            Swal.close()
                        }
                    },
                    {
                        type: "confirm",
                        text: "Excluir tag",
                        action: (selected) => {
							tag.set("isDeleted", true)
							tag.save()
							.then(_tag => {
								Swal.fire(
									'Excluído!',
									'A tag foi excluída com sucesso.',
									'success'
								)
								.then(_ => {
									callback && callback()
								})
							})
							.catch(error => {
								console.error(error)
								Swal.fire(
									'Desculpe',
									'Ocorreu algum erro ao tentar excluir a tag',
									'error'
								)
							})
                        }
                    }
                ]}
            />
        )
    })
}

var searchUsersWithDelay = null;
export const searchUsers = (name, clinic) => {
    return new Promise((resolve, reject) => {
        clearInterval(searchUsersWithDelay);
        searchUsersWithDelay = setTimeout(() => {
            const queryUser = new Parse.Query("ClinicUser")
            queryUser.equalTo("clinic", clinic)
            queryUser.equalTo("isDeleted", false)
            queryUser.equalTo("accepted", true)
            if (name) {
                queryUser.matches("user.fullName", diacriticSensitive(name), "im");
            }
            queryUser.ascending("user.fullName")
            queryUser.include("user")
            queryUser.limit(Filter_Limit)
            queryUser.withCount()
            queryUser.find()
            .then(({ results, count }) => {
                resolve({
                    results: results.map(clinicUser => clinicUser.get("user")),
                    count: count
                })
            })
            .catch(error => {
                console.error(error);
                reject("Ocorreu algum erro ao tentar buscar o animal")
            })
        }, 300);
    })
}

var searchTagsWithDelay = null;
export const searchTags = (name, clinic) => {
    return new Promise((resolve, reject) => {
        clearInterval(searchTagsWithDelay);
        searchTagsWithDelay = setTimeout(() => {
            const queryTag = new Parse.Query("MRTag")
            queryTag.equalTo("clinic", clinic)
            queryTag.equalTo("isDeleted", false)
            if (name) {
                queryTag.matches("name", diacriticSensitive(name), "im");
            }
            queryTag.ascending("name")
            queryTag.limit(Filter_Limit)
            queryTag.withCount()
            queryTag.find()
            .then(_tags => {
                resolve(_tags)
            })
            .catch(error => {
                console.error(error);
                reject("Ocorreu algum erro ao tentar buscar a tag")
            })
        }, 300);
    })
}

export const getCalendar = (id, patientOrClinic) => {
    return new Promise((resolve, reject) => {
        const patient = patientOrClinic.has("clinic") ? patientOrClinic : null
        const clinic = patientOrClinic.has("clinic") ? patientOrClinic.get("clinic") : patientOrClinic
        var query = new Parse.Query("MRCalendar")
        query.equalTo("clinic", clinic)
        query.equalTo("isDeleted", false)
        if (patient) {
            query.equalTo("patient", patient)
        }
        query.include("user")
        query.include("tags")
        query.include("sale")
        query.include("patient")
        query.include("patient.owner")
        query.include("messages")
        query.get(id)
        .then(_object => {
            if (_object) {
                resolve(_object)
            } else {
                reject('Não encontramos o evento')
            }
        }).catch(error => {
            console.error(error)
            reject('Ocorreu algum erro ao tentar buscar o evento')
        })
    })
}

export const frequencyOptions = [
  { value: RRule.YEARLY, text: "Anualmente" },
  { value: RRule.MONTHLY, text: "Mensalmente" },
  { value: RRule.WEEKLY, text: "Semanalmente" },
  { value: RRule.DAILY, text: "Diariamente" }
]

export const monthOptions = [
  { value: "1", text: "Janeiro" },
  { value: "2", text: "Fevereiro" },
  { value: "3", text: "Março" },
  { value: "4", text: "Abril" },
  { value: "5", text: "Maio" },
  { value: "6", text: "Junho" },
  { value: "7", text: "Julho" },
  { value: "8", text: "Agosto" },
  { value: "9", text: "Setembro" },
  { value: "10", text: "Outubro" },
  { value: "11", text: "Novembro" },
  { value: "12", text: "Dezembro" },
]

export const dayOptions = [
  { value: "1", text: "01" },
  { value: "2", text: "02" },
  { value: "3", text: "03" },
  { value: "4", text: "04" },
  { value: "5", text: "05" },
  { value: "6", text: "06" },
  { value: "7", text: "07" },
  { value: "8", text: "08" },
  { value: "9", text: "09" },
  { value: "10", text: "10" },
  { value: "11", text: "11" },
  { value: "12", text: "12" },
  { value: "13", text: "13" },
  { value: "14", text: "14" },
  { value: "15", text: "15" },
  { value: "16", text: "16" },
  { value: "17", text: "17" },
  { value: "18", text: "18" },
  { value: "19", text: "19" },
  { value: "20", text: "20" },
  { value: "21", text: "21" },
  { value: "22", text: "22" },
  { value: "23", text: "23" },
  { value: "24", text: "24" },
  { value: "25", text: "25" },
  { value: "26", text: "26" },
  { value: "27", text: "27" },
  { value: "28", text: "28" },
  { value: "29", text: "29" },
  { value: "30", text: "30" },
  { value: "31", text: "31" }
]

export const weekPositionOptions = [
  { value: "1", text: "Primeiro(a)" },
  { value: "2", text: "Segundo(a)" },
  { value: "3", text: "Terceiro(a)" },
  { value: "4", text: "Quarto(a)" },
  { value: "-2", text: "Penúltimo(a)" },
  { value: "-1", text: "Último(a)" }
]

export const weekOptions = [
  { value: RRule.SU, text: "Domingo" },
  { value: RRule.MO, text: "Segunda-feira" },
  { value: RRule.TU, text: "Terça-feira" },
  { value: RRule.WE, text: "Quarta-feira" },
  { value: RRule.TH, text: "Quinta-feira" },
  { value: RRule.FR, text: "Sexta-feira" },
  { value: RRule.SA, text: "Sábado" },
  { value: [ RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR, RRule.SA, RRule.SU ], text: "Dia" },
  { value: [ RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR ], text: "Dia útil" },
  { value: [ RRule.SA, RRule.SU ], text: "Final de semana" }
]

export const getWeekdayFromNumber = (weekdayNumber) => {
	switch (weekdayNumber) {
		case RRule.SU.weekday:
			return RRule.SU
		case RRule.MO.weekday:
			return RRule.MO
		case RRule.TU.weekday:
			return RRule.TU
		case RRule.WE.weekday:
			return RRule.WE
		case RRule.TH.weekday:
			return RRule.TH
		case RRule.FR.weekday:
			return RRule.FR
		case RRule.SA.weekday:
			return RRule.SA
		default:
			return null
	}
}

export const createDefaultTag = (clinicObject) => {
	return new Promise((resolve, reject) => {
		const padrao = new Parse.Object('MRTag');
		padrao.set("clinic", clinicObject);
		padrao.set("name", "Padrão");
		padrao.set("color", "#00C4B4");
		padrao.set("isDeleted",  false);
		padrao.save()
		.then(object => {
			if (object)
				return resolve(object)
			console.error("Saved object was not found")
			reject(null)
		})
		.catch(error => {
			console.error(error)
			return reject(error)
		})
	})
}
export const getDefaultTagFromTags = (tags) => {
	return tags?.filter(tag => tag.get("name") === TAG_DEFAULT)?.[0]
}

export const getAlertFromProfessionalAgenda = async (user, clinic, start, end, id = null) => {
    if (!user?.id)
        return
    if (!clinic?.id)
        return
    start = dayjs(start)
    if (!start.isValid())
        return
    end = dayjs(end)
    if (!end.isValid())
        return

    const queryUser = new Parse.Query("MRCalendar")
    queryUser.equalTo("clinic", clinic)
    queryUser.equalTo("isDeleted", false)
    queryUser.equalTo("user", user)

    const queryAll = new Parse.Query("MRCalendar")
    queryAll.equalTo("clinic", clinic)
    queryAll.equalTo("isDeleted", false)
    queryAll.doesNotExist("user")
    
    const query = Parse.Query.or(queryUser, queryAll)
    query.equalTo("clinic", clinic)
    query.equalTo("isDeleted", false)
    query.lessThan("startDate", end.toDate())
    query.greaterThan("endDate", start.toDate())
    const calendars = await query.find()
    if (!calendars || calendars.length == 0)
        return

    const total = calendars.filter(calendar => {
        const startDate = dayjs(calendar.get("startDate"))
        const endDate = dayjs(calendar.get("endDate"))
        if (calendar.id == id)
            return false
        if (end.isAfter(startDate) && end.isBefore(endDate))
            return true
        if (start.isAfter(startDate) && start.isBefore(endDate))
            return true
        if (start.isSameOrAfter(startDate) && end.isSameOrBefore(endDate))
            return true
        if (start.isBefore(startDate) && end.isAfter(endDate))
            return true
        return false
    })?.length || 0
    if (total == 0)
        return
    return `O profissional possui ${total} evento${ total == 1 ? "" : "s" } para o horário indicado`
}

export const isTimeAvailable = (array, date) => {
    var isAvailable = true
    array?.some(time => {
        const [ startHour, startMinute ] = time.startTime.split(":")
        const [ endHour, endMinute ] = time.endTime.split(":")
        const startDate = dayjs(date).hour(startHour).minute(startMinute).second(0).subtract(1, "minute")
        const endDate = dayjs(date).hour(endHour).minute(endMinute).second(0).subtract(1, "minute")
        if (dayjs(date).isBetween(startDate, endDate)) {
            isAvailable = true
            return true
        } else {
            isAvailable = false
        }
    })
    return isAvailable
}

export const getProfessionals = (clinicObject) => {
	return new Promise((resolve, reject) => {
		const queryUsers = new Parse.Query("ClinicUser")
		queryUsers.equalTo("clinic", clinicObject)
		queryUsers.equalTo("accepted", true)
		queryUsers.equalTo("isDeleted", false)
		queryUsers.exists("user")
		queryUsers.include("user")
		queryUsers.find()
		.then(clinicUsers => {
			if (clinicUsers) {
				return resolve(
                    clinicUsers.map(clinicUser => {
                        const user = clinicUser.get("user")
                        user.clinicUser = clinicUser
                        return user
                    })
                )
			}
			console.error("users not found")
			reject()
		})
		.catch(error => {
			console.error(error)
			reject(error)
		})
	})
}

export const getServices = (clinicObject) => {
	return new Promise((resolve, reject) => {
        const queryServices = new Parse.Query("MRService");
        queryServices.equalTo("clinic", clinicObject);
        queryServices.equalTo("isDeleted", false);
        queryServices.equalTo("active", true);
        queryServices.ascending("name")
        queryServices.include("category")
        queryServices.limit(10000)
        queryServices.find()
        .then(async services => {
            resolve(services || [])
        })
        .catch(error => {
            console.error(error)
            reject('Ocorreu algum erro ao tentar buscar os serviços')
        })
	})
}

export const getTags = (clinicObject, createDefaultTagIfNeeded = true) => {
    return new Promise((resolve, reject) => {
        const queryTags = new Parse.Query("MRTag");
        queryTags.equalTo("clinic", clinicObject);
        queryTags.equalTo("isDeleted", false);
        queryTags.ascending("name")
        queryTags.find()
        .then(async tags => {
			if (!tags) {
				tags = []
				if (createDefaultTagIfNeeded) {
					defaultTag = await createDefaultTag(clinicObject)
					if (defaultTag) {
						tags.push(defaultTag)
					}
				}
			}

            resolve(tags)
        })
        .catch(error => {
            console.error(error)
            reject('Ocorreu algum erro ao tentar buscar as tags')
        })
    })
}

export const getTimeDescriptionByMinutes = (timeInMinutes) => {
	var description = []
	const duration = dayjs.duration({ minutes: timeInMinutes })
	const hours = duration.hours()
	if (hours != 0) {
		description.push(`${hours} hora${hours == 1 ? "" : "s"}`)
	}
	const minutes = duration.minutes()
	if (minutes != 0) {
		description.push(`${minutes} minuto${minutes == 1 ? "" : "s"}`)
	}
	if (description.length)
		return description.join(" e ")
	return "Não informado"
}

export const defaultTimes = [
	{ 
    value: "15",
    label: "15 minutos"
  },
	{ 
    value: "30",
    label: "30 minutos"
  },
	{ 
    value: "45",
    label: "45 minutos"
  },
	{ 
    value: "60",
    label: "1 hora"
  },
	{ 
    value: "75",
    label: "1 hora e 15 minutos"
  },
	{ 
    value: "90",
    label: "1 hora e 30 minutos"
  },
	{ 
    value: "105",
    label: "1 hora e 45 minutos"
  },
	{ 
    value: "120",
    label: "2 horas"
  },
	{ 
    value: "135",
    label: "2 horas e 15 minutos"
  },
	{ 
    value: "150",
    label: "2 horas e 30 minutos"
  },
	{ 
    value: "165",
    label: "2 horas e 45 minutos"
  },
	{ 
    value: "180",
    label: "3 horas"
  },
	{ 
    value: "195",
    label: "3 horas e 15 minutos"
  },
	{ 
    value: "210",
    label: "3 horas e 30 minutos"
  },
	{ 
    value: "225",
    label: "3 horas e 45 minutos"
  },
	{ 
    value: "240",
    label: "4 horas"
  },
	{ 
    value: "255",
    label: "4 horas e 15 minutos"
  },
	{ 
    value: "270",
    label: "4 horas e 30 minutos"
  },
	{ 
    value: "285",
    label: "4 horas e 45 minutos"
  },
	{ 
    value: "300",
    label: "5 horas"
  },
]

export const timeOptions = [
	"00:00",
	"00:30",
	"01:00",
	"01:30",
	"02:00",
	"02:30",
	"03:00",
	"03:30",
	"04:00",
	"04:30",
	"05:00",
	"05:30",
	"06:00",
	"06:30",
	"07:00",
	"07:30",
	"08:00",
	"08:30",
	"09:00",
	"09:30",
	"10:00",
	"10:30",
	"11:00",
	"11:30",
	"12:00",
	"12:30",
	"13:00",
	"13:30",
	"14:00",
	"14:30",
	"15:00",
	"15:30",
	"16:00",
	"16:30",
	"17:00",
	"17:30",
	"18:00",
	"18:30",
	"19:00",
	"19:30",
	"20:00",
	"20:30",
	"21:00",
	"21:30",
	"22:00",
	"22:30",
	"23:00",
	"23:30",
]

export const statusOptions = [
    {
		value: CALENDAR_OPEN,
		label: "Aberto"
	},
    {
		value: CALENDAR_CONFIRMED,
		label: "Confirmado"
	},
    {
		value: CALENDAR_DONE,
		label: "Executado"
	},
    {
		value: CALENDAR_NOSHOW,
		label: "Não compareceu"
	},
    {
		value: CALENDAR_CANCELED,
		label: "Cancelado"
	},
    {
		value: CALENDAR_CANCELED_MESSAGE,
		label: "Cancelado via WhatsApp",
        disabled: true
	}
]

export const allStatusOptions = [
    ...statusOptions,
    {
		value: CALENDAR_BLANK,
		label: "Evento em branco"
	},
    {
		value: CALENDAR_BLOCK,
		label: "Bloqueio"
	}
]

export const getEvents = (clinic, start, end) => {
	return new Promise((resolve, reject) => {
		const query = new Parse.Query("MRCalendar")
		query.equalTo("clinic", clinic)
		query.equalTo("isDeleted", false)
		query.greaterThanOrEqualTo("startDate", start)
		query.lessThanOrEqualTo("endDate", end)
		query.include("tags")
		query.include("user")
		query.include("sale")
		query.include("patient")
		query.include("patient.owner")
		query.include("messages")
		query.limit(100000)
		query.find()
		.then(results => {
			return resolve(
				results.map(result => {
					const ownerName = result.get("patient").get("owner").get("name")
					return {
						id: result.id,
						title: ownerName,
						start: result.get("startDate"),
						end: result.get("endDate"),
						resourceId: result.get("user").id,
						object: result
					}
				})
			)
		})
		.catch(error => {
			console.error(error)
			return reject(error)
		})
	})
}