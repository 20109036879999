import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';

import { ReactComponent as IconEdit } from "../../../assets/icon-primary-edit.svg"
import { ReactComponent as IconVaccineProtocol } from "../../../assets/icon-vaccineprotocol.svg"

import { showRegisteredBy } from '../../../utils';
import { ACL } from "../../../constants"

import VSAccordionSimple from '../../../components/vsAccordion/VSAccordionSimple';
import { defaultIntervals, getOrMountSelectedOptionLabel } from '../../../components/intervalInput/IntervalInput';
import { vaccineProtocolActiveOptions, vaccineProtocolTotalShotsOptions } from './VaccineProtocol';

import "./VaccineProtocolView.scss"
const VaccineProtocolView = props => {

    const {
        object,
        expanded,
        history,
        permissions
    } = props

    const isDeleted = object.get("isDeleted")
    const isActive = object.get("active")
    const currentVaccineProtocolActiveOption = vaccineProtocolActiveOptions.find(vaccineProtocolOption => vaccineProtocolOption.value == isActive)
    const totalShotsText = object.get("totalShots") ? vaccineProtocolTotalShotsOptions.find(option => option.value == object.get("totalShots"))?.label : null
    const getIntervalValue = () => `${object.get("shotInterval")} ${object.get("shotIntervalUnit")}`
    const getIntervalLabel = () => object.get("shotInterval") && object.get("shotIntervalUnit") ? getOrMountSelectedOptionLabel(null, defaultIntervals, getIntervalValue()) : null
    const getNextIntervalValue = () => `${object.get("nextShotInterval")} ${object.get("nextShotIntervalUnit")}`
    const getNextIntervalLabel = () => object.get("nextShotInterval") && object.get("nextShotIntervalUnit") ? getOrMountSelectedOptionLabel(null, defaultIntervals, getNextIntervalValue()) : null

    return (
        <div id="vaccineprotocol-view" className="mb-3">
            <div className={`vsbox d-block w-100 ${ isActive ? "vsbox-active" : "vsbox-inactive" }`}>
                {!isActive &&
                <div className="vsbox-status">
                    {currentVaccineProtocolActiveOption.long}
                </div>}
                <div className="vsbox-body pb-1">
                    <div className="row g-3 mb-3 align-items-center">
                        <div className="col-auto">
                            <div className="icon-round">
                                <IconVaccineProtocol width="24" height="24" fill="var(--vsprontuario-primary-color)" />
                            </div>
                        </div>
                        <div className="col">
                            <label className="vsbox-info mb-0">
                                { object.get("name") }
                            </label>
                            <div className="vsbox-info-text">
                                <b>Espécie:</b> { object.get("specie") || "Não informada" }
                            </div>
                        </div>
                        <div className="col-auto text-right">
                            { !isDeleted && permissions?.check(ACL.SERVICE, ACL.Rule.EDIT, object) && (
                                <div className="col-12 col-md-auto">
                                    <div className="row align-items-center justify-content-center">
                                        <div className="col-12 col-md-auto">
                                            <button
                                                className={`vsbox-btn ${isDeleted ? "vsbox-btn-disabled" : "vsbox-btn-dark"}`}
                                                onClick={() => history.push(`/protocolo/vacina/${ object.id }`)}
                                                disabled={isDeleted}
                                            >
                                                <IconEdit width="24" height="24" fill={isDeleted ? "var(--vsprontuario-gray-color)" : "var(--vsprontuario-primary-color-dark)"} />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    { showRegisteredBy(object) }
                    <div className="vsbox-separator"></div>
                    <div className="row">
                        <div className="col-12">
                            <VSAccordionSimple
                                expanded={expanded}
                                header={(expanded) => (
                                    `${ expanded ? "Ocultar" : "Ver" } mais informações`
                                )}
                            >
                                <div className="vsbox-item mb-3">
                                    <div className="row align-items-center flex-nowrap">
                                        <div className="col">
                                            <div className="row vsbox-item-text">
                                                <div className="col">
                                                    <div className="vsbox-title vstext-ellipsis">
                                                        {object.get("vaccineType")}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row vsbox-item-text mt-2">
                                                <div className="col">
                                                    <span className="me-2"><b>Doses:</b> {totalShotsText}</span>{
                                                    object.get("shotInterval") && object.get("shotIntervalUnit") &&
                                                    <>•<span className="mx-2"><b>Intervalo:</b> {getIntervalLabel()}</span></>}{
                                                    object.get("nextShotInterval") && object.get("nextShotIntervalUnit") &&
                                                    <>•<span className="ms-2"><b>Reforço:</b> {getNextIntervalLabel()}</span></>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col vsbox-title">
                                        Observações:
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col mb-3 pre-line">
                                        { object.get("notes") || "Não informado" }
                                    </div>
                                </div>
                            </VSAccordionSimple>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


const mapStateToProps = state => {
    return {
        permissions: state.permissions
    };
};

export default connect(mapStateToProps)(VaccineProtocolView);