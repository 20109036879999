import { useEffect } from "react"
import TextFieldMask from "../../../../../components/textFieldMask/TextFieldMask"
import { dayjs } from "../../../../../utils"

const ExpirationDateField = (props) => {

    const {
        expirationDate,
        expirationDateText,
        handleExpirationDate,
        errorExpirationDate,
        helperExpirationDate
    } = props

    useEffect(() => {
        if (expirationDate && !expirationDateText) {
            handleExpirationDate(dayjs(expirationDate).format("MM/YYYY"))
        }
    }, [ expirationDate ])

    return (
        <div className="row">
            <div className="col-12">
                <div className="input-subtitle mb-1">
                    Data de Validade
                </div>
            </div>
            <div className="col-12">
                <TextFieldMask
                    type="tel"
                    className="my-0"
                    mask="00/0000"
                    placeholder="00/0000"
                    value={expirationDateText}
                    error={errorExpirationDate}
                    helperText={helperExpirationDate}
                    onAccept={(value) => handleExpirationDate(value)}
                    size="small"
                    variant="outlined"
                    fullWidth
                />
            </div>
        </div>
    )
}

export default ExpirationDateField