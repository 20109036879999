import React from "react"

export const clinicIsNotDeleted = (clinicId) => {
    const queryClinic = new Parse.Query("Clinic");
    queryClinic.equalTo("isDeleted", false);
    if (clinicId) {
        queryClinic.equalTo("objectId", clinicId);
    }
    return queryClinic
}

export const createClinic = (params) => {
    return new Promise((resolve, reject) => {
        const textsForCreation = [
            "Estamos preparando os dados.",
            "Estamos configurando a clínica.",
            "Estamos configurando as permissões.",
            "Estamos configurando informações adicionais.",
        ]

        var counter = 1
        let timerInterval
        Swal.fire({
            title: 'Cadastrando a Clínica',
            html: (
                <div>
                    <span>{ textsForCreation[0] }</span><br/>
                    Isso levará apenas <b>alguns segundos</b>.
                </div>
            ),
            timerProgressBar: true,
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading()
                const span = Swal.getHtmlContainer().querySelector('span')
                timerInterval = setInterval(() => {
                    span.textContent = textsForCreation[counter]
                    if (counter != textsForCreation.length - 1) {
                        counter++
                    }
                }, 2500)

                Parse.Cloud.run("createClinic", params)
                .then(clinic => {
                    Swal.close()
                    return resolve(clinic)
                })
                .catch(error => {
                    console.error(error)
                    return reject('Não foi possível cadastrar a clínica, por favor, tente novamente')
                })
            },
            willClose: () => {
                clearInterval(timerInterval)
            }
        })
    })
}