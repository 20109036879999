import React, { useEffect, useRef, useState } from 'react'

import { ReactComponent as IconVetsmart } from "../../assets/icon-vetsmart.svg"
import { ReactComponent as IconDownload } from "../../assets/icon-download.svg"
import { ReactComponent as IconEye } from "../../assets/icon-primary-eye.svg"
import { ReactComponent as IconUserO } from "../../assets/icon-user-o.svg"
import { connect } from 'react-redux'

import './AuditList.scss'
import VSMultipleSelect from '../../components/vsMultipleSelect/VSMultipleSelect'
import VSDrawer from '../../components/vsDrawer/VSDrawer'
import VSEmpty from '../../components/vsEmpty/VSEmpty'
import VSList from '../../components/vsList/VSList'
import VSAccordionSimple from '../../components/vsAccordion/VSAccordionSimple'
import { CircularProgress, MenuItem, TextField } from '@mui/material'
import { actions, getItemFromParseObject, items } from './Audit'
import { ACL, ACTION_DELETE } from '../../constants'
import axios from 'axios'
import VSTooltip from '../../components/vsTooltip/VSTooltip'
import { dayjs } from '../../utils'

const AuditList = (props) => {

    const {
        clinic,
        history,
        permissions
    } = props
    
    const rowsPerPage = 5
    const defaultDate = dayjs()

    const filter = useRef(null)
    const isMounted = useRef(null)

    const [ downloading, setDownloading ] = useState(false)

    const [ parseObject, setParseObject ] = useState(null)
    const [ view, setView ] = useState(null)

    const [ audits, setAudits ] = useState({})
    const [ users, setUsers ] = useState([])
    const [ filteredUsers, setFilteredUsers ] = useState([])
    const [ filteredActions, setFilteredActions ] = useState(actions)
    const [ filteredItems, setFilteredItems ] = useState(items)
    const [ year, setYear ] = useState(defaultDate.format("YYYY"))
    const [ month, setMonth ] = useState(defaultDate.format("MM"))
    
    const years = Array.from(Array(15).keys()).map((_, index) => {
        return defaultDate.clone().add(index - 10, 'years').format("YYYY")
    })

    const dateFrom = dayjs(`01/${month}/${year}`, "DD/MM/YYYY").toDate()
    const dateTo   = dayjs(dateFrom).endOf("month").toDate()

    const getIntervalToShow = (allHistoriesByCreatedAt, currentPage) => {
        const createdAts = allHistoriesByCreatedAt.map(date => dayjs(date.get("createdAt")).format("DD/MM/YYYY"))
        const uniqueCreatedAts = [...new Set(createdAts)]
        const totalHistoriesGroupedByCreatedAt = uniqueCreatedAts.length

        const maxIndex = totalHistoriesGroupedByCreatedAt - 1
        const firstIndex = (currentPage - 1) * rowsPerPage
        const lastIndex  = (currentPage * rowsPerPage) - 1

        const firstCreatedAt = uniqueCreatedAts[firstIndex]
        const lastCreatedAt  = uniqueCreatedAts[lastIndex >= maxIndex ? maxIndex : lastIndex]

        const firstCreatedAtMoment = firstCreatedAt ? dayjs(firstCreatedAt, "DD/MM/YYYY") : dayjs()
        const lastCreatedAtMoment  = lastCreatedAt  ? dayjs(lastCreatedAt,  "DD/MM/YYYY") : dayjs()

        // Necessary because orderBy can be descending
        const minInterval = firstCreatedAtMoment.isBefore(lastCreatedAtMoment) ? firstCreatedAtMoment : lastCreatedAtMoment
        const maxInterval = firstCreatedAtMoment.isSameOrAfter(lastCreatedAtMoment) ? firstCreatedAtMoment : lastCreatedAtMoment
        maxInterval.hour(23).minute(59).second(59)

        return {
            start: minInterval.toDate(),
            end: maxInterval.toDate(),
            total: totalHistoriesGroupedByCreatedAt
        }
    }

    const findUsers = () => {
        setUsers([])
        setFilteredUsers([])

        const queryUsers = new Parse.Query("ClinicUser");
        queryUsers.equalTo("clinic", clinic.object);
        queryUsers.equalTo("isDeleted", false);
        queryUsers.equalTo("accepted", true);
        queryUsers.include("user.name");
        queryUsers.find()
        .then(clinicUsers => {
            if (clinicUsers) {
                let sanitizedUsers = []
                clinicUsers.map(clinicUser => {
                    const _user = clinicUser.get("user")
                    if (_user) {
                        sanitizedUsers.push(_user)
                    }
                })

                setUsers(sanitizedUsers)
                setFilteredUsers(sanitizedUsers)
            }
        })
        .catch(err => {
            console.error(err)
        })
    }
    
    const findAudits = (page) => {
        return new Promise((resolve, reject) => {
            const query = new Parse.Query("MedicalRecordsAudit");
            query.equalTo("clinic", clinic.object);
            query.containedIn("parseClassName", getFilteredItems())
            query.containedIn("action", filteredActions)
            query.containedIn("createdBy", filteredUsers)
            query.select("createdAt")
            if (dateFrom) {
                query.greaterThanOrEqualTo("createdAt", dateFrom)
            }
            if (dateTo) {
                query.lessThanOrEqualTo("createdAt", dateTo)
            }
            query.descending("createdAt");
            query.limit(1000)
            query.find()
            .then(results => {
                const data = getIntervalToShow(results, page)

                const queryAudit = new Parse.Query("MedicalRecordsAudit");
                queryAudit.equalTo("clinic", clinic.object);
                queryAudit.containedIn("parseClassName", getFilteredItems())
                queryAudit.containedIn("action", filteredActions)
                queryAudit.containedIn("createdBy", filteredUsers)
                queryAudit.greaterThanOrEqualTo("createdAt", data.start)
                queryAudit.lessThanOrEqualTo("createdAt", data.end)
                queryAudit.descending("createdAt");
                queryAudit.find()
                .then(_audits => {
                    let auditsByDate = []
                    let dates = []
                    _audits.map(audit => {
                        const createdAtFormatted = dayjs(audit.get("createdAt")).format("DD/MM/YYYY")
                        const userId = audit.get("createdBy").id
                        if (!auditsByDate[createdAtFormatted]) {
                            dates.push(createdAtFormatted)
                            auditsByDate[createdAtFormatted] = []
                        }
                        if (!auditsByDate[createdAtFormatted][userId]) {
                            auditsByDate[createdAtFormatted][userId] = []
                        }
                        auditsByDate[createdAtFormatted][userId].push(audit)
                    })
                    setAudits(auditsByDate)
                    resolve({
                        items: dates,
                        total: data.total
                    })
                })
                .catch(error => {
                    console.error(error)
                    reject(error)
                })
            })
            .catch(error => {
                console.error(error)
                reject(error)
            })
        })
    }

    const getFilteredItems = () => {
        return filteredItems.map(i => i.value)
    }

    const downloadAudit = () => {
        setDownloading(true)
        const params = {
            _from: dayjs(dateFrom).format("YYYY-MM-DD"),
            _to: dayjs(dateTo).format("YYYY-MM-DD"),
            _users: JSON.stringify(filteredUsers.map(u => u.id)),
            _clinic: clinic.objectId,
            _actions: JSON.stringify(filteredActions),
            _data: JSON.stringify(filteredItems.map(d => d.value)),
            _key: user.id,
            token: user.getSessionToken().substr(2, 16),
            download: true
        }
        const queryString = new URLSearchParams(params).toString()
        axios({
            url: `${process.env.REACT_APP_PDF}/api/v2/audit?${queryString}`,
            method: 'GET',
            responseType: 'blob',
        }).then(response => {
            var url = window.URL.createObjectURL(response.data)
            var a = document.createElement('a')
            a.href = url
            a.download = "Histórico de Edições"
            a.click()
            a.remove()
            setTimeout(() => window.URL.revokeObjectURL(url), 100)
            setDownloading(false)
        }).catch(error => {
            console.error(error)
            setDownloading(false)
            Swal.fire(
                'Não foi possível baixar o histórico...',
                `Tente novamente mais tarde ou entre em contato`,
                'error'
            )
        })
    }
    
    const onChange = () => {
        if (filter.current) {
            filter.current.onChange()
        }
    }

    useEffect(() => {
        if (parseObject) {
            const queryParseObject = new Parse.Query(parseObject.get("parseClassName"));
            queryParseObject.equalTo("objectId", parseObject.get("parseClassId"));
            queryParseObject.first()
            .then(object => {
                const item = getItemFromParseObject(object)
                if (item && item.view) {
                    setView(item)
                }
            })
            .catch(error => {
                console.error(error)
            })
        }
    }, [ parseObject ])

    useEffect(() => {
        if (clinic.isLoaded && filteredUsers.length > 0) {
            onChange()
        }
    }, [ month, year, filteredItems, filteredActions, filteredUsers ])

    useEffect(() => {
        if (clinic.isLoaded) {
            findUsers()
        }
    }, [ clinic ])

    useEffect(() => {
        if (permissions && !permissions.check(ACL.ADMIN)) {
            permissions.error()
        }
    }, [ permissions ])

    useEffect(() => {
        isMounted.current = true
        return () => {
            isMounted.current = false
        }
    }, [])

    const user = Parse.User.current()

    return (
        <div id="audit" className="row">
            <div className="col-12">
                <div className="page-content">
                    <div className="row">
                        <div className="col mb-3">
                            <div className="page-title">
                                Histórico de Edições 
                            </div>
                        </div>
                    </div>
                    <VSList
                        ref={filter}
                        pagination={{
                            rowsPerPage: rowsPerPage
                        }}
                        onChange={({ _0, _1, _2, _3, _page }) => {
                            return findAudits(_page)
                        }}
                        customFilter={(
                            <div className="row g-3">
                                <div className="col-12 col-md" style={{ minWidth: '200px' }}>
                                    <VSMultipleSelect
                                        label="Profissionais"
                                        onChange={(value) => {
                                            if (value.length > 0) {
                                                setFilteredUsers(value)
                                            }
                                        }}
                                        options={users}
                                        getLabel={(option) => option.get("fullName")}
                                        getValue={(option) => option.id}
                                        showInputValue={(optionsSelected) => {
                                            return optionsSelected.length == 1 ? optionsSelected[0].get("fullName") : (optionsSelected.length == filteredUsers.length ? "Todos" : `${optionsSelected.length} selecionados`)
                                        }}
                                        value={filteredUsers}
                                        enabledAllOptions
                                    />
                                </div>
                                <div className="event-type-input col-6 col-md-auto">
                                    <VSMultipleSelect
                                        label="Tipos de Eventos"
                                        onChange={(value) => {
                                            if (value.length > 0) {
                                                setFilteredActions(value)
                                            }
                                        }}
                                        options={actions}
                                        getLabel={(option) => option}
                                        getValue={(option) => option}
                                        showInputValue={(optionsSelected) => {
                                            return optionsSelected.length == 1 ? optionsSelected[0] : (optionsSelected.length == actions.length ? "Todos" : `${optionsSelected.length} selecionados`)
                                        }}
                                        value={filteredActions}
                                        enabledAllOptions
                                    />
                                </div>
                                <div className="register-type-input col-6 col-md-auto">
                                    <VSMultipleSelect
                                        label="Tipos de Registro"
                                        onChange={(value) => {
                                            if (value.length > 0) {
                                                setFilteredItems(value)
                                            }
                                        }}
                                        options={items}
                                        getLabel={(option) => option.label}
                                        getValue={(option) => option.value}
                                        showInputValue={(optionsSelected) => {
                                            return optionsSelected.length == 1 ? optionsSelected[0].label : (optionsSelected.length == items.length ? "Todos" : `${optionsSelected.length} selecionados`)
                                        }}
                                        value={filteredItems}
                                        enabledAllOptions
                                    />
                                </div>
                                <div className="col-6 col-md-auto">
                                    <TextField
                                        label="Ano"
                                        value={year}
                                        onChange={({ target: { value } }) => setYear(value)}
                                        helperText={null}
                                        select
                                        size="small"
                                        variant="outlined"
                                        fullWidth
                                    >
                                        { years.map((_year, index) => (
                                            <MenuItem value={_year} key={index}>{ _year }</MenuItem>
                                        )) }
                                    </TextField>
                                </div>
                                <div className="col-6 col-md-auto">
                                    <TextField
                                        label="Mês"
                                        value={month}
                                        onChange={({ target: { value } }) => setMonth(value)}
                                        helperText={null}
                                        select
                                        size="small"
                                        variant="outlined"
                                        fullWidth
                                    >
                                        <MenuItem value={"01"}>
                                            Janeiro
                                        </MenuItem>
                                        <MenuItem value={"02"}>
                                            Fevereiro
                                        </MenuItem>
                                        <MenuItem value={"03"}>
                                            Março
                                        </MenuItem>
                                        <MenuItem value={"04"}>
                                            Abril
                                        </MenuItem>
                                        <MenuItem value={"05"}>
                                            Maio
                                        </MenuItem>
                                        <MenuItem value={"06"}>
                                            Junho
                                        </MenuItem>
                                        <MenuItem value={"07"}>
                                            Julho
                                        </MenuItem>
                                        <MenuItem value={"08"}>
                                            Agosto
                                        </MenuItem>
                                        <MenuItem value={"09"}>
                                            Setembro
                                        </MenuItem>
                                        <MenuItem value={"10"}>
                                            Outubro
                                        </MenuItem>
                                        <MenuItem value={"11"}>
                                            Novembro
                                        </MenuItem>
                                        <MenuItem value={"12"}>
                                            Dezembro
                                        </MenuItem>
                                    </TextField>
                                </div>
                                <div className="col-12 col-md-auto">
                                    <VSTooltip
                                        placement="top-start"
                                        open={downloading}
                                        title={
                                            <div className="vsdrawer-error">
                                                Isso pode demorar um pouco
                                            </div>
                                        }
                                    >
                                        <button
                                            className="btn-submit btn-square w-100"
                                            onClick={() => !downloading && downloadAudit()}
                                        >
                                            { downloading ? (
                                                <>
                                                    <CircularProgress size={18} className="align-middle me-2" />
                                                    Baixando...
                                                </>
                                            ) : (
                                                <>
                                                    <IconDownload id="add-icon" width="18" height="18" fill="#FFFFFF" />
                                                    Baixar Histórico
                                                </>
                                            )}
                                        </button>
                                    </VSTooltip>
                                </div>
                                { dayjs(dateFrom).isSameOrBefore(dayjs("2022-08-24 15:10:00", "YYYY-MM-DD HH:mm:ss")) && (
                                    <div className="col-12 mt-3">
                                        <div className="vsprompt">
                                            <div className="vsprompt-text">
                                                <b>O histórico de edições está disponível para edições/alterações feitas à partir do dia 24/08/2022 às 15h10</b>
                                            </div>
                                            <div className="vsprompt-text">
                                                Edições feitas antes desta data e horário não estão disponíveis para consulta. Os registros feitos continuam disponíveis normalmente
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                        renderItem={(item) => (
                            <div className="vsbox mb-4">
                                <div className="vsbox-body pb-2">
                                    <div className="row align-items-center">
                                        <div className="col-12 text-center mb-1">
                                            <div className="vsbox-chip w-100">
                                                Registros de { item }
                                            </div>
                                        </div>
                                    </div>
                                    { audits && audits[item] && Object.keys(audits[item]).map((userId, index) => {
                                        const _audits = audits[item][userId]
                                        const createdBy = _audits[0].get("createdBy")
                                        return (
                                            <React.Fragment key={index}>
                                                <div className="vsbox-separator my-2"></div>
                                                <VSAccordionSimple
                                                    expanded={true}
                                                    header={(expanded) => (
                                                        <div className="row align-items-center justify-content-sm-end flex-column flex-sm-row mx-0 w-100">
                                                            <div className="col ps-0">
                                                                <div className="row align-items-center flex-nowrap">
                                                                    <div className="col-auto">
                                                                        <IconUserO width="48px" height="48px" fill="var(--vsprontuario-primary-color)" />
                                                                    </div>
                                                                    <div className="col ps-0">
                                                                        <div className="vsbox-title">
                                                                            { createdBy.get("fullName") }
                                                                        </div>
                                                                        <div className="vsbox-text">
                                                                            Médico Veterinário
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-auto pe-2 vsbox-info align-self-end align-self-sm-center mt-2 mt-sm-0 mb-0">
                                                                { expanded ? "Ocultar" : "Ver" } Registro{ _audits.length == 1 ? "" : "s" } ({ _audits.length })
                                                            </div>
                                                        </div>
                                                    )}
                                                >
                                                    { _audits && _audits.map((data, index) => {
                                                        const item = items.filter(i => i.value === data.get("parseClassName"))[0]
                                                        return (
                                                            <div className="audit-detail row align-items-center py-2 g-3" key={index}>
                                                                <div className="col-12 col-md">
                                                                    <div className="row align-items-center flex-nowrap g-3">
                                                                        <div className="col-auto">
                                                                            { item.icon() }
                                                                        </div>
                                                                        <div className="col ps-0">
                                                                            <label className="vsbox-info text-nowrap">Tipo de Registro</label>
                                                                            <div className="vsbox-info-text">
                                                                                { item.singular }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="event-info col-6 col-md-auto">
                                                                    <label className="vsbox-info">Evento</label>
                                                                    <div className="vsbox-info-text">
                                                                        { data.get("action") }
                                                                    </div>
                                                                </div>
                                                                <div className="time-info col-6 col-md-auto">
                                                                    <label className="vsbox-info">Horário</label>
                                                                    <div className="vsbox-info-text">
                                                                        { dayjs(data.get("createdAt")).format("HH:mm:ss") }
                                                                    </div>
                                                                </div>
                                                                <div className="col col-md-auto">
                                                                    <div className="row align-items-center justify-content-center">
                                                                        <div className="col-auto w-100">
                                                                            <button
                                                                                className="vsbox-btn vsbox-btn-primary"
                                                                                style={{ padding: '6px', visibility: data.get("action") === ACTION_DELETE ? "hidden" : "visible" }}
                                                                                onClick={() => setParseObject(data)}
                                                                            >
                                                                                <IconEye width="24" height="24" />
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }) }
                                                </VSAccordionSimple>
                                            </React.Fragment>
                                        )
                                    }) }
                                </div>
                            </div>
                        )}
                        renderEmpty={() => (
                            items.length !== filteredItems.length ? (
                                <VSEmpty
                                    icon={<IconVetsmart width="32px" height="32px" />}
                                    title="Não encontramos nenhum resultado para sua busca"
                                    text="Tente refazer a busca utilizando outros caracteres ou alterando a data e os tipos de registro"
                                />
                            ) : (
                                <VSEmpty
                                    icon={<IconVetsmart width="32px" height="32px" />}
                                    title="Não foi realizada nenhuma edição de registro nesse período"
                                    text="As edições feitas a partir de 24/08/2022 às 15h10 aparecerão aqui"
                                />
                            )
                        )}
                        renderNotFound={() => (
                            <VSEmpty
                                icon={<IconVetsmart width="32px" height="32px" />}
                                title="Não encontramos nenhum resultado para sua busca"
                                text="Tente refazer a busca utilizando outros caracteres ou alterando a data e os tipos de registro"
                            />
                        )}
                    />
                </div>
            </div>
            <VSDrawer
                id="history-view"
                open={view != null}
                title={view && view.singular}
                onCancel={() => {
                    setParseObject(null)
                    setView(null)
                }}
                VSDrawerFooterProps={{
                    show: false
                }}
            >
                { view && React.createElement(view.view, {
                    object: view.object,
                    history: history
                })}
            </VSDrawer>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        clinic: state.clinic,
        permissions: state.permissions
    };
};
  
export default connect(mapStateToProps)(AuditList)