import React from 'react'

import './Return.scss'
import ReturnList from './ReturnList'

const Return = (props) => {

    const {
        history
    } = props

    return (
        <div id="return" className="row">
            <div className="col-12">
                <div className="page-content">
                    <div className="page-title">
                        Retornos da semana
                    </div>
                </div>
            </div>
            <div className="col-12 mt-3 mt-md-0">
                <div className="page-content">
                    <ReturnList
                        history={history}
                    />
                </div>
            </div>
        </div>
    )
}

export default Return