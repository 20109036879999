import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import { Switch, Route } from "react-router-dom";
import { connect } from 'react-redux';

import './Setting.scss'
import './UserSetting.scss'

import { LinkTabs, TabSimple } from './Setting';
import { CircularProgress } from '@mui/material';
import { withStyles } from '@mui/styles';
import { addEvent, canUserEditVeterinaryDocuments } from '../../utils';

import UserSettingUserSignature from './tabs/usersignature/UserSettingUserSignature'
import UserSettingDigitalCertificate from './tabs/digitalcertificate/UserSettingDigitalCertificate'

import Blocked from '../blocked/Blocked';

const UserSetting = (props) => {

    const {
        tab = "assinatura-do-usuario"
    } = useParams()

    const {
        user,
        clinic,
        history,
        location
    } = props

    const [ loadingForScroll, setLoadingForScroll ] = useState(true)

    useEffect(() => {
        setTimeout(() => {
            setLoadingForScroll(false)
        }, 500);
    })

    if(loadingForScroll || !user?.object || !clinic?.object){
        return (
            <div style={{ textAlign: "center", padding: '36px' }}>
                <CircularProgress size={28} thickness={2} color="primary"/>
            </div>
        )
    }

    return (
        <div className="setting" id="user-setting">
            <div className="row setting-header">
                <div className="col-12 mb-4">
                    <div className="page-title">
                        Configurações do Médico Veterinário
                    </div>
                </div>
                {canUserEditVeterinaryDocuments() &&
                <div className="col-12">
                    <div className="row align-items-end mb-3 setting-tabs">
                        <div className="col">
                            <LinkTabs
                                value={tab}
                                // onChange={(_, selected) => null}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="scrollable"
                                scrollButtons="auto"
                                allowScrollButtonsMobile
                            >
                                <TabSimple
                                    value="assinatura-do-usuario"
                                    label="Assinatura do Usuário"
                                    onClick={() => history.push('/configuracao-medico-veterinario/assinatura-do-usuario')}
                                />
                                <TabSimple
                                    value="certificado-digital"
                                    label="Certificado Digital"
                                    onClick={() => history.push('/configuracao-medico-veterinario/certificado-digital')}
                                />
                            </LinkTabs>
                        </div>
                    </div>
                </div>}
            </div>
            <div className="row">
                <div className="col">
                    {canUserEditVeterinaryDocuments() ? (
                        <Switch {...props}>
                            <Route exact path="/configuracao-medico-veterinario/(assinatura-do-usuario)?" component={UserSettingUserSignature} />
                            <Route exact path="/configuracao-medico-veterinario/certificado-digital" component={UserSettingDigitalCertificate} />
                        </Switch>
                    ) : (
                        <Blocked />
                    )}
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        user: state.user,
        clinic: state.clinic
    };
};
  
export default connect(mapStateToProps)(UserSetting);