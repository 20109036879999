import React, { useRef, useState, useEffect } from "react"
import { connect } from "react-redux"

import { ReactComponent as IconAdd } from "../../assets/icon-primary-add-o.svg"
import { ReactComponent as IconPatientBalance } from '../../assets/icon-patient-weight.svg'
import { ReactComponent as IconSquareMinus } from "../../assets/icon-square-minus.svg"
import { ReactComponent as IconSquarePlus } from "../../assets/icon-square-plus.svg"

import { ACL, OrderBy_Oldest, OrderBy_Newest } from "../../constants"
import { dayjs, diacriticSensitive } from "../../utils"

import VSEmpty from "../../components/vsEmpty/VSEmpty"
import VSList from "../../components/vsList/VSList"
import ExpenseRecurringView from "../expense/ExpenseRecurringView"
import VSAccordionSimple from "../../components/vsAccordion/VSAccordionSimple"
import VSTimeline from "../../components/vsTimeline/VSTimeline"
import CardFeedback from "../../components/cardFeedback/CardFeedback"

import "./AccountsPayableList.scss"
const AccountsPayableList = (props) => {

    const {
        clinic,
        history,
        permissions
    } = props
    
    const rowsPerPage = 5

    const filter = useRef(null)
    const isMounted = useRef(null)

    const [ total, setTotal ] = useState(0)

    const getExpense = (searchTerm, dateFrom, dateTo, orderBy, page = 1) => {
        setTotal(0)
        return new Promise((resolve, reject) => {
            const filters = {
                isDeleted: false,
                paymentsPending: {
                    $gt: 0
                },
                name: { 
                    $regex: diacriticSensitive(searchTerm),
                    $options: 'im'
                }
            }
            Parse.Cloud.run("getPeriodForClass", {
                clinicId: clinic.objectId,
                className: "MRExpense",
                filters: filters,
                dateFrom: dateFrom,
                dateTo: dateTo,
                order: orderBy == OrderBy_Newest ? "newest" : "oldest",
                page: page
            })
            .then(({ total, from, to }) => {
                const query = new Parse.Query("MRExpense")
                query.equalTo("clinic", clinic.object)
                query.equalTo("isDeleted", false)
                query.greaterThan("paymentsPending", 0)
                query.greaterThanOrEqualTo("documentDate", from)
                query.lessThanOrEqualTo("documentDate", to)
                if (searchTerm) {
                    query.matches("name", diacriticSensitive(searchTerm), "im")
                }
                switch (orderBy) {
                    case OrderBy_Newest:
                        query.descending("documentDate")
                        break
                    default:
                        query.ascending("documentDate")
                }
                query.find()
                .then(results => {
                    const groupedByDate = []
                    let currentDate
                    results.map(result => {
                        const date = dayjs(result.get("documentDate")).format("YYYY-MM-DD")
                        if (date == currentDate) {
                            groupedByDate[groupedByDate.length - 1].documents.push(result)
                        } else {
                            currentDate = date
                            groupedByDate.push({
                                date: result.get("documentDate"),
                                documents: [result]
                            })
                        }
                    })
                    setTotal(total)
                    resolve({
                        items: groupedByDate,
                        total: total
                    })
                })
            })
            .catch(error => {
                console.error(error)
                return Promise.reject(error)
            })
        })
    }

    const onChange = () => {
        if (filter.current) {
            filter.current.onChange()
        }
    }

    useEffect(() => {
        if (permissions && !permissions.check(ACL.OUTFLOW, ACL.Rule.VIEW)) {
            permissions.error()
        }
    }, [ permissions ])

    useEffect(() => {
        isMounted.current = true
        return () => { 
            isMounted.current = false
        }
    }, [])

    useEffect(() => {
        document.addEventListener("Expense__getList", onChange)
        return () => { 
            document.removeEventListener("Expense__getList", onChange)
        }
    }, [])

    return (
        <>
            <div id="accounts-payable-list" className="flex-grow-1">
                <div className="col-12">
                    <VSList
                        ref={filter}
                        title={{
                            text: "Contas a Pagar"
                        }}
                        button={permissions?.check(ACL.OUTFLOW, ACL.Rule.EDIT) && {
                            text: "Criar Nova Despesa",
                            onClick: () => history.push(`/despesa/novo`, { from: "pagar" })
                        }}
                        search={{
                            text: "Buscar por Nome da Despesa",
                            value: ""
                        }}
                        interval={{
                            start: {
                                text: "De",
                                value: null
                            },
                            end: {
                                text: "Até",
                                value: null
                            }
                        }}
                        order={{
                            options: [
                                OrderBy_Oldest,
                                OrderBy_Newest
                            ],
                            value: OrderBy_Oldest
                        }}
                        pagination={{
                            rowsPerPage: rowsPerPage
                        }}
                        onChange={({ _searchBy, _start, _end, _orderBy, _page }) => {
                            return getExpense(_searchBy, _start, _end, _orderBy, _page)
                        }}
                        renderItem={(item) => {
                            const date = dayjs(item.date)
                            const expenses = item.documents
                            return (
                                <VSTimeline
                                    day={ date.format("DD") }
                                    month={ date.format("MMM") }
                                    year={ date.format("YYYY") }
                                >
                                    { expenses.map((expense, index) => (
                                        <VSAccordionSimple
                                            key={index}
                                            expanded={true}
                                            className="custom-accordion"
                                            hideIcon
                                            header={(expanded) => {
                                                const late = dayjs().isAfter(expense.get("documentDate"), "day")
                                                return (
                                                    <div className={`vstimeline-item ${late ? "vstimeline-inactive" : "vstimeline-active"} w-100`}>
                                                        <div  className="vsbox">
                                                            <div className="vsbox-body p-2 mb-0">
                                                                <div className="vsbox-profile">
                                                                    <div className="row align-items-center mx-0">
                                                                        <div className="col">
                                                                            <div className="row align-items-center">
                                                                                <div className="col">
                                                                                    <div className="vsbox-name">
                                                                                        { expense.get("name") }
                                                                                    </div>
                                                                                    <div className="vsbox-data-light">
                                                                                        <b>Estado da Despesa:</b> { late ? "Atrasado" : "Em aberto" }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12 col-md-auto text-end vsbox-info">
                                                                            <div className="vsbox-separator d-block d-md-none"></div>
                                                                            <div className="vsbox-info-action">
                                                                                { expanded ? "Ocultar" : "Ver" } Registro
                                                                                { expanded ? (
                                                                                    <IconSquareMinus width="24px" height="24px" className="ms-2" fill="var(--vsprontuario-secondary-color)" />
                                                                                ) : (
                                                                                    <IconSquarePlus width="24px" height="24px" className="ms-2" fill="var(--vsprontuario-secondary-color)" />
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }}
                                        >
                                            <ExpenseRecurringView
                                                history={history} 
                                                updateList={onChange} 
                                                object={expense}
                                            />
                                        </VSAccordionSimple>
                                    ))}
                                </VSTimeline>
                            )
                        }}
                        renderEmpty={() => (
                            <VSEmpty
                                icon={<IconPatientBalance width="32px" height="32px" />}
                                title={`Você ainda não possui nenhuma despesa`}
                                text={`Cadastre a primeira despesa para visulizá-lo aqui`}
                            >
                                <button
                                    id="new-expense-link"
                                    className="vsbox-btn vsbox-btn-primary"
                                    onClick={() => history.push(`/despesa/novo`, { from: "pagar" })}
                                >
                                    <IconAdd width="14" height="14" className="me-2" />
                                    Cadastrar nova despesa
                                </button>
                            </VSEmpty>
                        )}
                        renderNotFound={() => (
                            <VSEmpty
                                icon={<IconPatientBalance width="32px" height="32px" />}
                                title="Não encontramos nenhum resultado para a sua busca"
                                text="Tente refazer sua busca utilizando outro período ou crie um novo cadastro utilizando o botão abaixo"
                            >
                                <button
                                    id="new-expense-link"
                                    className="vsbox-btn vsbox-btn-primary"
                                    onClick={() => history.push(`/despesa/novo`, { from: "pagar" })}
                                >
                                    <IconAdd width="14" height="14" className="me-2" />
                                    Cadastrar nova despesa
                                </button>
                            </VSEmpty>
                        )}
                    />
                </div>
            </div>
            { total > 0 && (
                <CardFeedback contentDetail={history.location?.pathname || "AccountsPayableList"} />
            )}
        </>
    )
}

const mapStateToProps = state => {
    return {
        clinic: state.clinic,
        permissions: state.permissions
    }
}
  
export default connect(mapStateToProps)(AccountsPayableList)