import React, { useState } from 'react'
import { connect } from 'react-redux'

import { TableCell, TableRow } from '@mui/material'

import { showVaccine } from '../patient/tabs/vaccine/Vaccine'
import HomeData from './HomeData'
import { dayjs } from '../../utils'
import VSMenu from '../../components/vsMenu/VSMenu'

import { ReactComponent as IconMenu } from "../../assets/icon-menu-2.svg"
import { ReactComponent as IconUser } from "../../assets/icon-user-menu.svg"
import { ReactComponent as IconPaw } from "../../assets/icon-paw-menu.svg"
import { ReactComponent as IconCalendarEdit } from "../../assets/icon-calendar-edit.svg"

import './HomeReturns.scss'
const HomeReturns = (props) => {

    const {
        clinic,
        expanded,
        history
    } = props

    const [ loading, setLoading ] = useState(true)
    const [ returns, setReturns ] = useState([])

    const rowsPerPage = 5

    return (
        <div className="col-12 mb-3">
            <HomeData
                expanded={expanded}
                headerIcon={<IconCalendarEdit width="24px" height="24px" fill="#B3B3B3" />}
                title="Próximos Retornos"
                emptyText="Você ainda não possui nenhum retorno registrado"
                loading={loading}
                data={returns}
                rowsPerPage={rowsPerPage}
                onChange={(page) => {
                    if (clinic && clinic.isLoaded && clinic.object) {
                        setReturns([])
                        setLoading(true)

                        const query = new Parse.Query("Return");
                        query.equalTo("isDeleted", false)
                        query.equalTo("clinic", clinic.object)
                        query.greaterThanOrEqualTo("returnDate", dayjs().startOf("day").toDate())
                        query.ascending("returnDate")
                        query.include("patient")
                        query.include("patient.owner")
                        query.limit(15)
                        query.find()
                        .then(_returns => {
                            setReturns({
                                count: _returns?.length || 0,
                                results: _returns
                            })
                            setLoading(false)
                        })
                        .catch(error => {
                            console.error(error)
                            setLoading(false)
                        })
                    }
                }}
                renderItem={(_return, index) => {
                    const patient = _return?.get("patient")
                    const owner = patient?.get("owner")
                    return (
                        <TableRow
                            key={index}
                        >
                            <TableCell
                                style={{ width: '86px' }}
                            >
                                <div className="text-truncate">
                                    { dayjs(_return?.get("returnDate")).format("DD/MM/YYYY") }
                                </div>
                            </TableCell>
                            <TableCell className="px-2">
                                <div className="text-link d-inline" onClick={() => history.push(`/animal/${patient?.id}/retorno/${_return?.id}`)}>
                                    Retorno para { patient?.get("name") }
                                </div>
                                &nbsp;de&nbsp;
                                <div className="d-inline" 
                                    // onClick={() => history.push(`/tutor/${owner?.id}`)}
                                >
                                    { owner?.get("name") }
                                </div>
                            </TableCell>
                            <TableCell style={{ width: '24px' }} className="ms-2 flex-shrink-0">
                                <VSMenu
                                    placement="left-start"
                                    openOnHover
                                    style={{background: "#fff"}}
                                    options={[
                                        {
                                            menu: (
                                                <div className="row align-items-center">
                                                    <div className="col-auto">
                                                        <IconPaw width="24" height="24" fill="var(--vsprontuario-primary-color)" />
                                                    </div>
                                                    <div className="col ps-0">
                                                        Ver Animal
                                                    </div>
                                                </div>
                                            ),
                                            onClick: () => history.push(`/animal/${patient.id}`)
                                        },
                                        {
                                            menu: (
                                                <div className="row align-items-center">
                                                    <div className="col-auto">
                                                        <IconUser width="24" height="24" fill="var(--vsprontuario-primary-color)" />
                                                    </div>
                                                    <div className="col ps-0">
                                                        Ver Tutor
                                                    </div>
                                                </div>
                                            ),
                                            onClick: () => history.push(`/tutor/${owner.id}`)
                                        }
                                    ]}
                                >
                                    <IconMenu width="24" height="24" fill="var(--vsprontuario-primary-color)" />
                                </VSMenu>
                            </TableCell>
                        </TableRow>
                    )
                }}
                footer={(
                    <div className="col">
                        <div onClick={() => history.push('/retorno')} className="btn-outline-submit btn-square cursor-pointer">
                            Ver Histórico de Retornos
                        </div>
                    </div>
                )}
            />
        </div>
    )
}

const mapStateToProps = state => {
    return {
        clinic: state.clinic
    };
};
  
export default connect(mapStateToProps)(HomeReturns);