import { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import {
  Switch,
  Route,
  useLocation
} from "react-router-dom";

import SwipeableDrawer from '@mui/material/SwipeableDrawer';

import { ACL, SHOW_NEW_SUPPLY } from './constants';
import { 
	saveOnTracker,
	showProOrUpgradeToProComponent,
	upgradeProAgenda,
	upgradeProProtocolVaccine,
	upgradeProFinancial,
	upgradeProService,
	upgradeProSupply
} from './utils';

import Menu from './components/menu/Menu'
import Header from './components/header/Header'
import Home from './pages/home/Home'
import Search from './pages/search/Search'
import Return from './pages/return/Return'
import Vaccine from './pages/vaccine/Vaccine'
import ReturnComplete from "./pages/return/ReturnComplete";
import Invite from './pages/blocked/Invite';
import Owner from './pages/owner/OwnerList'
import OwnerView from "./pages/owner/OwnerView"
import OwnerForm from './pages/owner/OwnerForm';
import Patient from './pages/patient/PatientList'
import PatientForm from "./pages/patient/PatientForm"
import PatientView from "./pages/patient/PatientView";
import HistoryList from './pages/history/HistoryList'
import AuditList from './pages/audit/AuditList';
import Dashboard from './pages/dashboard/Dashboard';
import HistorySelectPatientOrOwner from './pages/history/HistorySelectPatientOrOwner';
import PatientSelectForm from './pages/patient/PatientSelectForm';
import DisplayStoreForm from './pages/clinic/DisplayStoreForm';
import VaccineAutomaticReminderForm from './pages/patient/tabs/vaccine/VaccineAutomaticReminderForm';
import VaccineAutomaticReminderFormOld from './pages/patient/tabs/vaccine/VaccineAutomaticReminderFormOld';
import ServiceList from './pages/service/ServiceList';
import ServiceForm from './pages/service/ServiceForm';
import VaccineProtocolList from './pages/protocol/vaccine/VaccineProtocolList';
import VaccineProtocolForm from './pages/protocol/vaccine/VaccineProtocolForm';
import SupplyList from './pages/supply/SupplyList';
import SupplyForm from './pages/supply/SupplyForm';
import SupplyTransactionForm from './pages/supply/SupplyTransactionForm';
import AccountsPayableList from './pages/finance/AccountsPayableList';
import AccountsReceivableList from './pages/finance/AccountsReceivableList';
import CashOutflowList from './pages/finance/CashOutflowList';
import CashInflowList from './pages/finance/CashInflowList';
import ExpenseForm from './pages/expense/ExpenseForm';
import SupplyTransactionList from './pages/supply/SupplyTransactionList';
import FinancialStatementDownloadForm from './pages/finance/FinancialStatementDownloadForm';
import VetTrialPro from './pages/vet/VetTrialPro';
import ImportData from './pages/import/ImportData';
import CalendarList from './pages/calendar/CalendarList';
import CalendarForm from './pages/calendar/CalendarForm';
import ClinicSetting from './pages/setting/ClinicSetting'
import UserSetting from './pages/setting/UserSetting'
import VetLimitations from './pages/vet/VetLimitations';
import Limit from './pages/blocked/Limit';
import Responsive from './pages/responsive/Responsive';
import BrowserRouters from './pages/responsive/browsers/BrowserRouters';
import News from './pages/news/News';

import './AppMenu.scss';
import SupplyFormOld from './pages/supply/SupplyFormOld';
import ServiceFormOld from './pages/service/ServiceFormOld';
import SupplyViewDetail from './pages/supply/SupplyViewDetail';
import CalendarEventForm from './pages/calendar/CalendarEventForm';
import VetDocuments from './pages/documents/VetDocuments';
import VirtualAssistantSelectForm from './pages/virtualAssistant/VirtualAssistantSelectForm';
import VirtualAssistantFreeOverlay from './pages/virtualAssistant/VirtualAssistantFreeOverlay';
const AppMenu = (props) => {

	const {
		pro,
		clinic,
		permissions
	} = props

	const location = useLocation()

	const [ menuOpen, setMenuOpen ] = useState(false)

	useEffect(() => {
		saveOnTracker("Acessou", "URL", document.location.href)
	}, [ location ])

	return (
		<div className="container-fluid">
			<div className="row">
				<Menu 
					mobileMenuOpen={menuOpen}
					onMenuChange={() => setMenuOpen(!menuOpen)}
					{...props} />
					
				<div id="children" className="col p-0 text-center">
					<Header 
						mobileMenuOpen={menuOpen}
						onMenuChange={() => setMenuOpen(!menuOpen)} />
						
					<div id="content">
						<VetTrialPro {...props} />
						<Invite />
						<Switch>
							<Route exact path="/importacao" component={ImportData} />
							{ !pro?.access && (
								<Route exact path="/limites" component={Limit} />
							)}
							{ permissions?.check(ACL.CALENDAR, ACL.Rule.VIEW) && (
								<Route exact path="/agenda/:id_agendamento?/:tipo?" render={(routerProps) => (
									showProOrUpgradeToProComponent({
										...props,
										...upgradeProAgenda,
										component: (
											<>
												<CalendarList {...routerProps} />
												{ routerProps.match.params.tipo ? (
													<CalendarEventForm {...props} {...routerProps} />
												) : routerProps.match.params.id_agendamento && (
													<CalendarForm {...props} {...routerProps} />
												)}
											</>
										)
									})
								)} />
							)}
							{/* <Route exact path="/configuracao" component={Setting} /> */}
							<Route exact path="/configuracao-clinica/:tab?/:id?/:content?" component={ClinicSetting} />
							<Route exact path="/configuracao-medico-veterinario/:tab?" component={UserSetting} />
							<Route exact path="/impressos-veterinarios" component={VetDocuments} />
							<Route exact path="/busca/:termo" component={Search} />
							{ permissions?.check(ACL.OWNER, ACL.Rule.VIEW) && (
								<Route exact path="/tutor" component={Owner} />
							)}
							{ permissions?.check(ACL.OWNER, ACL.Rule.VIEW) && (
								<Route exact path="/tutor/:id/(compartilhar)?" component={OwnerView} />
							)}
							{ permissions?.check(ACL.OWNER, ACL.Rule.EDIT) && (
								<Route exact path="/tutor/form/:id" render={(routerProps) => (
									<>
										<Owner {...routerProps} />
										<OwnerForm {...props} {...routerProps} />
									</>
								)} />
							)}
							{ permissions?.check(ACL.PATIENT, ACL.Rule.VIEW) && (
								<Route exact path="/animal" component={Patient} />
							)}
							{ permissions?.check(ACL.PATIENT, ACL.Rule.VIEW) && (
								<Route exact path="/animal/selecionar/:proxima?" component={PatientSelectForm} />
							)}
							{ permissions?.check(ACL.PATIENT, ACL.Rule.VIEW) && (
								<Route exact path="/animal/:id/:tab?/:tab_id?/:ver?" component={PatientView} />
							)}
							{ permissions?.check(ACL.PATIENT, ACL.Rule.EDIT) && (
								<Route exact path="/tutor/:id/animal/:id_animal" render={(routerProps) => (
									<>
										<OwnerView {...routerProps} />
										<SwipeableDrawer
											anchor="right"
											open={true}
											onOpen={() => null}
											onClose={() => routerProps.history.goBack()}
										>
											<PatientForm {...routerProps} />
										</SwipeableDrawer>
									</>
								)} />
							)}
							{ permissions?.check(ACL.HISTORY, ACL.Rule.VIEW) && (
								<Route path="/historico/(selecionar)?" render={(routerProps) => (
									<div>
										<HistoryList {...routerProps} />
										{ routerProps.match.params[0] == "selecionar" && (
											<HistorySelectPatientOrOwner {...props} {...routerProps} />
										)}
									</div>
								)} />
							)}
							{ permissions?.check(ACL.ADMIN) && (
								<Route path="/auditoria" render={(routerProps) => (
									<AuditList {...routerProps} />
								)} />
							)}
							{ permissions?.check(ACL.VACCINE, ACL.Rule.VIEW) && (
								<Route path="/vacina/(lembrete_automatico)?/:id_lembrete?" render={(routerProps) => (
									<>
										<Vaccine {...props} {...routerProps} />
										{ routerProps.match.params.id_lembrete && permissions?.check(ACL.VACCINE, ACL.Rule.EDIT) && (
											<VaccineAutomaticReminderForm {...props} {...routerProps} />
										)}
									</>
								)} />
							)}
							{ permissions?.check(ACL.VACCINE, ACL.Rule.VIEW) && (
								<Route path="/protocolo/vacina/:id_protocolo?" render={(routerProps) => (
									showProOrUpgradeToProComponent({
										...props,
										...upgradeProProtocolVaccine,
										component: (
											<>
												<VaccineProtocolList {...props} {...routerProps} />
												{ routerProps.match.params.id_protocolo && permissions?.check(ACL.VACCINE, ACL.Rule.EDIT) && (
													<VaccineProtocolForm {...props} {...routerProps} />
												)}
											</>
										)
									})
								)} />
							)}
							{ permissions?.check(ACL.RETURN, ACL.Rule.VIEW) && (
								<Route path="/retorno/completo" render={(routerProps) => (
									<ReturnComplete {...routerProps} />
								)} />
							)}
							{ permissions?.check(ACL.RETURN, ACL.Rule.VIEW) && (
								<Route path="/retorno" render={(routerProps) => (
									<Return {...routerProps} />
								)} />
							)}
							{ permissions?.check(ACL.SERVICE, ACL.Rule.VIEW) && (
								<Route path="/servico/:id_servico?" render={(routerProps) => (
									showProOrUpgradeToProComponent({
										...props,
										...upgradeProService,
										component: (
											<>
												<ServiceList {...props} {...routerProps} />
												{ routerProps.match.params.id_servico && permissions?.check(ACL.SERVICE, ACL.Rule.EDIT) && (
													SHOW_NEW_SUPPLY ? (
														<ServiceForm {...props} {...routerProps} />
													) : (
														<ServiceFormOld {...props} {...routerProps} />
													)
												)}
											</>
										)
									})
								)} />
							)}
							{ permissions?.check(ACL.INFLOW, ACL.Rule.VIEW) && (
								<Route path="/financeiro/entrada" render={(routerProps) => (
									showProOrUpgradeToProComponent({
										...props,
										...upgradeProFinancial,
										component: (
											<CashInflowList {...routerProps} />
										)
									})
								)} />
							)}
							{ permissions?.check(ACL.OUTFLOW, ACL.Rule.VIEW) && (
								[
									"/financeiro/saida",
									"/despesa/:id_despesa?/:tipo?"
								].map((path, index) => 
									<Route path={path} key={index} render={(routerProps) => (
										showProOrUpgradeToProComponent({
											...props,
											...upgradeProFinancial,
											component: (
												<>
													<CashOutflowList {...routerProps} />
													{ routerProps.match.params.id_despesa && (
														<ExpenseForm {...props} {...routerProps} />
													)}
												</>
											)
										})
									)} />
								)
							)}
							{ permissions?.check(ACL.INFLOW, ACL.Rule.VIEW) && (
								<Route path="/financeiro/receber" render={(routerProps) => (
									showProOrUpgradeToProComponent({
										...props,
										...upgradeProFinancial,
										component: (
											<AccountsReceivableList {...routerProps} />
										)
									})
								)} />
							)}
							{ permissions?.check(ACL.OUTFLOW, ACL.Rule.VIEW) && (
								<Route path="/financeiro/pagar" render={(routerProps) => (
									showProOrUpgradeToProComponent({
										...props,
										...upgradeProFinancial,
										component: (
											<AccountsPayableList {...routerProps} />
										)
									})
								)} />
							)}
							{ permissions?.check([ ACL.SUPPLY, ACL.SUPPLY_TRANSACTION ], ACL.Rule.VIEW) && (
								<Route path="/insumo/:id_insumo?/:tipo_transacao?" render={(routerProps) => (
									showProOrUpgradeToProComponent({
										...props,
										...upgradeProSupply,
										component: (
											<>
												<SupplyList {...props} {...routerProps} />
												{ routerProps.match.params.tipo_transacao ? (
													routerProps.match.params.tipo_transacao == "ver" ? (
														<SupplyViewDetail {...props} {...routerProps} />
													) : permissions?.check(ACL.SUPPLY_TRANSACTION, ACL.Rule.EDIT) && (
														<SupplyTransactionForm {...props} {...routerProps} />
													)
												) : routerProps.match.params.id_insumo && permissions?.check(ACL.SUPPLY, ACL.Rule.EDIT) && (
													SHOW_NEW_SUPPLY ? (
														<SupplyForm {...props} {...routerProps} />
													) : (
														<SupplyFormOld {...props} {...routerProps} />
													)
												)}
												{/* { routerProps.match.params.tipo_transacao && permissions?.check(ACL.SUPPLY_TRANSACTION, ACL.Rule.EDIT) ? (
													<SupplyTransactionForm {...props} {...routerProps} />
												) : routerProps.match.params.id_insumo && permissions?.check(ACL.SUPPLY, ACL.Rule.EDIT) && (
													SHOW_NEW_SUPPLY ? (
														<SupplyForm {...props} {...routerProps} />
													) : (
														<SupplyFormOld {...props} {...routerProps} />
													)
												)} */}
											</>
										)
									})
								)} />
							)}
							{ permissions?.check(ACL.SUPPLY_TRANSACTION, ACL.Rule.VIEW) && (
								<Route path="/movimentacoes/insumo" render={(routerProps) => (
									showProOrUpgradeToProComponent({
										...props,
										...upgradeProSupply,
										component: (
											<SupplyTransactionList {...props} {...routerProps} />
										)
									})
								)} />
							)}
							{ permissions?.check(ACL.CONTROL_PANEL, ACL.Rule.VIEW) && (
								<Route path="/painel/(balanco-financeiro)?" render={(routerProps) => (
									pro?.access ? 
									showProOrUpgradeToProComponent({
										...props,
										...upgradeProFinancial,
										component: (
											<>
												<Dashboard {...routerProps} />
												{ routerProps.match.params[0] == "balanco-financeiro" && (
													<FinancialStatementDownloadForm {...props} {...routerProps} />
												)}
											</>
										)
									}) : 
									<Dashboard showAsFake {...routerProps} />
								)} />
							)}
							<Route exact path="/responsivo" component={Responsive} />
							<Route exact path="/responsivo/:browser" component={BrowserRouters} />
							<Route exact path="/limitacoes" component={VetLimitations} />
							<Route exact path="/novidades" component={News} />
							<Route exact path="/assistente/:assistant?/:type?/:id?" render={(routerProps) => (
								<>
									<Home {...routerProps} />
									{pro?.access ? 
									<VirtualAssistantSelectForm {...props} {...routerProps} /> : 
									<VirtualAssistantFreeOverlay {...props} {...routerProps} />}
								</>
							)} />
							<Route path="/(opcao-compra)?" render={(routerProps) => (
								<>
									<Home {...routerProps} />
									{ routerProps.match.params[0] == "opcao-compra" && (
										<DisplayStoreForm {...props} {...routerProps} />
									)}
								</>
							)} />
						</Switch>
					</div>
				</div>
			</div>
		</div>
	);
}

const mapStateToProps = state => {
    return {
        clinic: state.clinic,
        permissions: state.permissions,
        pro: state.featuresPro
    };
};

export default connect(mapStateToProps)(AppMenu);
