import React, { useEffect } from "react"

import { ReactComponent as Safari } from "../../assets/browsers/safari.svg"
import { ReactComponent as Chrome } from "../../assets/browsers/chrome.svg"
import { ReactComponent as Firefox } from "../../assets/browsers/firefox.svg"
import { ReactComponent as Edge } from "../../assets/browsers/edge.svg"

import "./Responsive.scss"
const Responsive = (props) => {

    const browsers = [
        {
            name: "Safari",
            icon: <Safari />,
            route: "/responsivo/safari"
        },
        {
            name: "Google Chrome",
            icon: <Chrome />,
            route: "/responsivo/chrome"
        },
        {
            name: "Firefox",
            icon: <Firefox />,
            route: "/responsivo/firefox"
        },
        {
            name: "Microsoft Edge",
            icon: <Edge />,
            route: "/responsivo/edge"
        }
    ]

    const {
        history
    } = props

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 0)
        }, 0)

        const tag = window.document.getElementById("trial-pro")
        if (tag) {
            tag.style.display = "none"
        }
        return () => {
            if (tag) {
                tag.style.display = "block"
            }
        }
    }, [])

    return (
        <div id="responsive">
            <div className="row">
                <div className="col-12">
                    <div className="page-title">
                        Prontuário Mobile
                    </div>
                </div>
                <div className="col-12">
                    <h4>Facilite o acesso ao seu Prontuário mobile</h4>
                    <p>
                        Quer acessar o seu Prontuário rapidamente com apenas um clique? 
                        Crie um atalho na tela inicial do seu celular ou tablet. 
                        Confira abaixo o passo a passo com base no navegador que você utiliza.
                    </p>
                </div>
                { browsers.map((browser, index) => (
                    <div key={index} className="col-12">
                        <div className="vsbox" onClick={() => history.replace(browser.route)}>
                            <div className="vsbox-body">
                                <div className="row">
                                    <div className="col-auto">
                                        <div className="browser-border">
                                            { browser.icon }
                                        </div>
                                    </div>
                                    <div className="col ps-0">
                                        <div className="vsbox-title mb-1">{ browser.name }</div>
                                        <div className="vsbox-text mb-1">
                                            Veja como adicionar uma página do { browser.name } como um 
                                            ícone na Tela de Início.
                                        </div>
                                        <a>Ver passo a passo</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )) }
            </div>
        </div>
    )
}

export default Responsive