import React, { useEffect, useState } from 'react'

import { ReactComponent as IconCashO } from "../../assets/icon-cash-o.svg"
import { ReactComponent as IconGraphBars } from "../../assets/icon-graph-bars.svg"

import { diacriticSensitive, formattedCurrency } from '../../utils'
import { queryForSearchByPatientAndOwner } from '../patient/Patient'

import CardFinance from '../../components/cardFinance/CardFinance'

import './CardFinancialView.scss'
const CardFinancialView = (props) => {

    const {
        clinic,
        searchTerm,
        dateFrom,
        dateTo,
        status,
        paymentTypes,
        services
    } = props

    const [ inflows, setInflows ] = useState(0)
    const [ inflowsLoading, setInflowsLoading ] = useState(true)

    const [ outflows, setOutflows ] = useState(0)
    const [ outflowsLoading, setOutflowsLoading ] = useState(true)

    const getInflows = () => {
        setInflowsLoading(true)

        var query = new Parse.Query("VW_MRSale_payments")
        query.greaterThan("paymentsValue", 0);
        query.equalTo("clinic", clinic.object);
        query.equalTo("isDeleted", false);
        const queryPatient = queryForSearchByPatientAndOwner(searchTerm, clinic.object)
        if (queryPatient) {
            query.matchesQuery("patient", queryPatient)
        }
        if (services.length > 0) {
            const queryServices = services.map(service => service.value)
            query.containedIn("services.objectId", queryServices)
        }
        if (paymentTypes.length > 0) {
            const queryPaymentTypes = paymentTypes.map(payment => payment.value)
            query.containedIn("payment_item_type", queryPaymentTypes)
        }
        if (status == "1") {
            query.equalTo("paymentsPending", 0)
        } else if (status == "2") {
            query.notEqualTo("paymentsPending", 0)
        }
        if (dateFrom) {
            query.greaterThanOrEqualTo("payment_item_date", dateFrom)
        }
        if (dateTo) {
            query.lessThanOrEqualTo("payment_item_date", dateTo)
        }
        query.select("payment_item_value")
        query.descending("payment_item_date")
        query.addDescending("objectId")
        query.limit(100000)
        query.find()
        .then((results) => {
            const total = results.reduce((accumulator, object) => {
                return accumulator + object.get("payment_item_value");
            }, 0)
            setInflows(total)
            setInflowsLoading(false)
        })
        .catch((error) => {
            console.error(error)
            setInflowsLoading(false)
        });
    }

    const getOutflows = () => {
        setOutflowsLoading(true)

        var query = new Parse.Query("VW_MRExpense_payments")
        query.greaterThan("paymentsValue", 0);
        query.equalTo("clinic", clinic.object);
        query.equalTo("isDeleted", false);
        if (searchTerm) {
            query.matches("name", diacriticSensitive(searchTerm), "im")
        }
        if (paymentTypes.length > 0) {
            const queryPaymentTypes = paymentTypes.map(payment => payment.value)
            query.containedIn("payment_item_type", queryPaymentTypes)
        }
        if (status == "1") {
            query.equalTo("recurrenceActive", true)
        } else if (status == "2") {
            query.equalTo("recurrenceActive", false)
        }
        if (dateFrom) {
            query.greaterThanOrEqualTo("payment_item_date", dateFrom)
        }
        if (dateTo) {
            query.lessThanOrEqualTo("payment_item_date", dateTo)
        }
        query.select("payment_item_value")
        query.descending("payment_item_date")
        query.addDescending("objectId")
        query.limit(100000)
        query.find()
        .then((results) => {
            const total = results.reduce((accumulator, object) => {
                return accumulator + object.get("payment_item_value");
            }, 0)
            setOutflows(total)
            setOutflowsLoading(false)
        })
        .catch((error) => {
            console.error(error)
            setOutflowsLoading(false)
        });
    }

    useEffect(() => {
        if (clinic && clinic.isLoaded && clinic.object) {
            getInflows()
            getOutflows()
        }
    }, [ clinic, searchTerm, dateFrom, dateTo, status, paymentTypes, services ])

    return (
        <div className="row g-3 align-items-center">
            <div className="col-12 col-md">
                <CardFinance
                    icon={(<IconGraphBars width={32} height={32} fill="#CC9E00" />)}
                    title="Balanço Financeiro"
                    value={formattedCurrency(inflows - outflows)}
                    loading={inflowsLoading || outflowsLoading}
                />
            </div>
            <div className="col-12 col-md">
                <CardFinance
                    icon={(<IconCashO width={32} height={32} fill="var(--vsprontuario-positive-color)" />)}
                    title="Entradas de caixa"
                    value={formattedCurrency(inflows)}
                    valueColor="var(--vsprontuario-positive-color)"
                    loading={inflowsLoading}
                />
            </div>
            <div className="col-12 col-md">
                <CardFinance
                    icon={(<IconCashO width={32} height={32} fill="var(--vsprontuario-negative-color)" />)}
                    title="Saídas de caixa"
                    value={formattedCurrency(outflows)}
                    valueColor="var(--vsprontuario-negative-color)"
                    loading={outflowsLoading}
                />
            </div>
        </div>
    )
}
  
export default CardFinancialView;