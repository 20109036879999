import React, { useEffect, useRef, useState } from 'react'

import './VSAccordionSetting.scss'
import VSAccordionSimple from "../../components/vsAccordion/VSAccordionSimple";
import { ReactComponent as IconSecondaryEdit } from "../../assets/icon-secondary-edit.svg"
import { ReactComponent as IconSquareMinus } from "../../assets/icon-square-minus.svg"
import { ReactComponent as IconSquarePlus } from "../../assets/icon-square-plus.svg"

const VSAccordionSetting = props => {
    const {
        title,
        children,
        className = ''
    } = props

    const [ open, setOpen ] = useState(true)

    return (
        <VSAccordionSimple
            expanded={open}
            hideIcon
            className={`vs-accordion-setting ${open ? 'open' : ''} ${className}`}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            header={expanded => (
                <div className="vs-accordion-head row mx-2">
                    <div className="col-auto pe-2 vsbox-info mb-0">
                        { expanded ? (
                            <IconSquareMinus width="24px" height="24px" fill="var(--vsprontuario-primary-color)" />
                        ) : (
                            <IconSquarePlus width="24px" height="24px" fill="var(--vsprontuario-primary-color)" />
                        )}
                    </div>
                    <div className="vs-accordion-title col ps-0">
                        {title}
                    </div>
                    {/* {!noEdit && 
                    <div className="col-auto ps-0 vs-accordion-info">
                        <IconSecondaryEdit width="24px" height="24px" className="pe-1 flex-shrink-0" fill="var(--vsprontuario-primary-color)" />
                        Editar
                    </div>} */}
                </div>
            )}
        >
            <div className="vs-accordion-body p-4">
                <div className="row g-2">
                    <div className="col-12">
                        {children}
                    </div>
                </div>
            </div>
        </VSAccordionSimple>
    )
}

export default VSAccordionSetting