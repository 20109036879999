import { cloneElement } from 'react';
import { useState, useEffect, forwardRef } from 'react';
import { maskValues, normalizeValue } from './functions';

const VSTextFieldCurrency = forwardRef((
    {
      InputElement,
      value,
      defaultValue,
      hideSymbol = false,
      currency = 'BRL',
      locale = 'pt-BR',
      max,
      autoSelect,
      autoReset,
      onChangeValue,
      onBlur,
      onFocus,
      onKeyPress,
      ...otherProps
    },
    ref,
  ) => {
    const [maskedValue, setMaskedValue] = useState('0');

    const updateValues = (originalValue) => {
      const [calculatedValue, calculatedMaskedValue] = maskValues(locale, originalValue, currency, hideSymbol);

      if (!max || calculatedValue <= max) {
        setMaskedValue(calculatedMaskedValue);

        return [calculatedValue, calculatedMaskedValue];
      }
      return [normalizeValue(maskedValue), maskedValue];
    };

    const handleChange = (event) => {
      event.preventDefault();

      const [originalValue, maskedValue] = updateValues(event.target.value);

      onChangeValue(event, parseFloat(originalValue), maskedValue);
    };

    const handleBlur = (event) => {
      const [originalValue, maskedValue] = updateValues(event.target.value);

      if (autoReset) {
        maskValues(locale, 0, currency, hideSymbol);
      }

      if (onBlur) {
        onBlur(event, originalValue, maskedValue);
      }
    };

    const handleFocus = (event) => {
      if (autoSelect) {
        event.target.select();
      }

      const [originalValue, maskedValue] = updateValues(event.target.value);

      if (onFocus) {
        onFocus(event, originalValue, maskedValue);
      }
    };

    const handleKeyUp = (event) =>
      onKeyPress && onKeyPress(event, event.key, event.key);

    useEffect(() => {
      const currentValue = value || defaultValue || undefined;
      const [, maskedValue] = maskValues(locale, currentValue, currency, hideSymbol);

      setMaskedValue(maskedValue);
    }, [currency, defaultValue, hideSymbol, value]);

    if (!InputElement) {
      return (
        <input
          {...otherProps}
          ref={ref}
          type="tel"
          value={maskedValue}
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          onKeyUp={handleKeyUp}
        />
      );
    }

    return cloneElement(InputElement, {
      ...otherProps,
      ref,
      value: maskedValue,
      onChange: handleChange,
      onBlur: handleBlur,
      onFocus: handleFocus,
      onKeyUp: handleKeyUp,
    });
  },
);

export default VSTextFieldCurrency