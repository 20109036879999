import React, { useImperativeHandle, forwardRef } from 'react'

import {
    getStateList
} from '../../../../utils'

import { MenuItem, TextField } from '@mui/material'

const TermEuthanasiaForm = forwardRef((props, ref) => {

    const {
        vetName,
        setVetName,
        vetCrmv,
        setVetCrmv,
        vetCrmvStateId,
        setVetCrmvStateId,
        notes,
        setNotes
    } = props

    useImperativeHandle(ref, () => ({
        fieldsWithErrorText(errors) {
            return errors
        },
        isValid() {
            return true
        },
        validate(isValid) {
            return isValid
        }
    }))

    return (
        <div className="row pt-4">
            <div className="col">
                <div className="row mb-2">
                    <div className="col">
                        <div className="input-title input-height">
                            Profissional que realizará o procedimento (opcional)
                        </div>
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-12">
                        <TextField
                            value={vetName}
                            placeholder="Digite Aqui o nome do Profissional"
                            label="Nome do Profissional"
                            onChange={({ target: { value } }) => setVetName(value)}
                            size="small"
                            variant="outlined"
                            fullWidth
                        />
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-6">
                        <TextField
                            type="tel"
                            value={vetCrmv}
                            placeholder="Digite Aqui o CRMV"
                            label="Número CRMV"
                            onChange={({ target: { value } }) => setVetCrmv(value)}
                            size="small"
                            variant="outlined"
                            fullWidth
                        />
                    </div>
                    <div className="col-6">
                        <TextField
                            placeholder="Selecione o Estado"
                            label="Estado do CRMV"
                            value={vetCrmvStateId}
                            onChange={({ target: { value } }) => setVetCrmvStateId(value)}
                            size="small"
                            variant="outlined"
                            select
                            fullWidth
                        >
                            { getStateList().map(stateItem => (
                                <MenuItem key={stateItem.value} value={stateItem.value}>
                                    { stateItem.text }
                                </MenuItem>
                            )) }
                        </TextField>
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-12">
                        <div className="input-title input-height">
                            Observações gerais (opcional)
                        </div>
                    </div>
                    <div className="col-12">
                        <TextField
                            value={notes}
                            placeholder="Escreva aqui as observações gerais sobre o atestado"
                            onChange={({ target: { value } }) => setNotes(value)}
                            size="small"
                            variant="outlined"
                            fullWidth
                            multiline
                        />
                    </div>
                </div>
            </div>
        </div>
    )
})

export default TermEuthanasiaForm;