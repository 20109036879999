import { TextField } from '@mui/material'
import React, { useState, useEffect, useRef } from 'react'

import VSCheckbox from '../vsCheckbox/VSCheckbox'

import './VSMultipleSelect.scss'

const VSMultipleSelect = (props) => {

    const {
        onChange,
        label,
        options,
        getLabel,
        getValue,
        showInputValue,
        enabledAllOptions,
        value,
        style
    } = props

    const multipleSelect = useRef(null)
    const [ open, setOpen ] = useState(false)
    const [ optionsSelected, setOptionsSelected ] = useState(value || [])
    
    const handleClickOutside = e => {
        if (!multipleSelect.current.contains(e.target)) {
            setOpen(false);
        }
    }

    useEffect(() => {
        if (JSON.stringify(value) != JSON.stringify(optionsSelected)) {
            setOptionsSelected(value)
        }
    }, [ value ])

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    });

    return (
        <div id="multiple-select" ref={multipleSelect} style={style}>
            <div className={`dropdown ${open ? 'show' : ''}`}>
                <TextField
                    label={label}
                    className="dropdown-toggle"
                    value={ showInputValue(optionsSelected) }
                    onClick={() => setOpen(!open)}
                    size="small"
                    variant="outlined"
                    fullWidth
                >
                </TextField>
                <div className={`dropdown-menu`}>
                    { enabledAllOptions && (
                        <VSCheckbox
                            label={"Todos"}
                            checked={optionsSelected.length == options.length}
                            onChange={(checked) => {
                                let _options = []
                                if (checked) {
                                    _options = options
                                }
                                setOptionsSelected(_options)
                                onChange(_options)
                            }}
                            className="dropdown-item"
                        />
                    )}
                    { options.map((option, index) => (
                        <VSCheckbox
                            key={index}
                            label={getLabel(option)}
                            checked={optionsSelected.map(opt => getValue(opt)).indexOf(getValue(option)) != -1}
                            onChange={(checked) => {
                                let newOptionsSelected = [...optionsSelected]
                                if (checked) {
                                    newOptionsSelected.push(option)
                                } else {
                                    let index = newOptionsSelected.map(opt => getValue(opt)).indexOf(getValue(option))
                                    newOptionsSelected.splice(index, 1)
                                }
                                setOptionsSelected(newOptionsSelected)
                                onChange(newOptionsSelected)
                            }}
                            className="dropdown-item"
                        />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default VSMultipleSelect
