import React, { useState, useEffect } from 'react'
import VSAccordion from '../../components/vsAccordion/VSAccordion'
import { ReactComponent as IconArrowLeft } from "../../assets/icon-arrow-left.svg"
import { saveOnTracker } from '../../utils'

import './NewsBox.scss'
const NewsBox = (props) => {

    const {
        className,
        object,
        expanded
    } = props

    const [ open, setOpen ] = useState(expanded || false)
    
    useEffect(() => {
        setOpen(expanded)
    }, [ expanded ])

    const hasTagPro = object.get('tags') && object.get('tags').length > 0 && object.get('tags').includes('pro')

    return (
        <div className={`newsbox vsbox ${className}`}>
            <div className="vsbox-body">
                <VSAccordion 
                    expanded={open}
                    toggleExpanded={(expanded) => setOpen(expanded)}
                    header={expanded => (
                        <div className="d-block w-100">
                            <div className="row align-items-center box-header">
                                {/* {headerIcon && 
                                <div className="col-auto pe-0">
                                    {headerIcon}
                                </div>} */}
                                {hasTagPro && 
                                <span className="col-auto ms-2 badge rounded-pill bg-secondary">PRO</span>}
                                <div className="col pe-0">
                                    <b>{ object.get('title') }</b>
                                </div>
                                <div className="col-auto text-center me-2">
                                    {/* { expanded ? (
                                        <IconSquareMinus width="24px" height="24px" fill="var(--vsprontuario-primary-color)" />
                                    ) : (
                                        <IconSquarePlus width="24px" height="24px" fill="var(--vsprontuario-primary-color)" />
                                    )} */}
                                    <IconArrowLeft
                                        width="12px"
                                        height="12px"
                                        fill="var(--vsprontuario-primary-color)"
                                        style={{ 
                                            transform: expanded ? 'rotate(90deg)' : 'rotate(270deg)',
                                            transition: 'all .3s'
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                >
                    <div className="row mt-3">
                        <div className={object.get('imageURL') ? `col-12 col-md-6` : `col-12`}>
                            <div 
                                dangerouslySetInnerHTML={{
                                    __html: object.get('mainText')
                                }}
                            />
                            {object.get('contentURL') && object.get('contentURLLabel') && 
                            <a 
                                className="newsbox-link"
                                href={object.get('contentURL')}
                                target={object.get('displayAsExternalContent') ? '_blank' : undefined}
                                onClick={() => {
                                    saveOnTracker('Clicou', 'Novidades Prontuário', object.id, object.get('title'), null, null, null, object.get('contentURLLabel'))
                                }}
                            >{object.get('contentURLLabel')}</a>}
                        </div>
                        {object.get('imageURL') && (
                        <div className="col-12 col-md-6 mt-3 mt-md-0">
                            <div className="img-wrap">
                                <img src={object.get('imageURL')} />
                            </div>
                        </div>)}
                    </div>
                </VSAccordion>
            </div>
        </div>
    )
}

export default NewsBox