import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'

import { ReactComponent as IconAdd } from "../../../../assets/icon-primary-add-o.svg"
import { ReactComponent as IconUpload } from "../../../../assets/icon-upload.svg"
import { ReactComponent as IconFilter } from "../../../../assets/icon-filter.svg"
import { ReactComponent as IconPrimaryVaccine } from "../../../../assets/icon-primary-vaccine.svg"

import { ACL } from '../../../../constants'

import VaccineListView from './VaccineListView'
import VSEmpty from '../../../../components/vsEmpty/VSEmpty'
import VSList from '../../../../components/vsList/VSList'
import DateInput from '../../../../components/dateInput/DateInput'
import ShareVaccineForm from './ShareVaccineForm'
import VaccineAppendFilter from './VaccineAppendFilter'
import { queryForSearchByPatientAndOwner } from '../../Patient'
import { CircularProgress } from '@mui/material'

import { addEvent, dayjs, validateDate } from "../../../../utils"

import './VaccineList.scss'

export const VACCINE_TYPE_ALL      = "all"
export const VACCINE_TYPE_VACCINE  = "vaccine"
export const VACCINE_TYPE_REMINDER = "reminder"
export const VACCINE_TYPE_EXPIRED  = "expired"

export const VACCINE_STATE_ALL       = "all"
export const VACCINE_STATE_SCHEDULED = "scheduled"
export const VACCINE_STATE_LATE      = "late"
export const VACCINE_STATE_APPLIED   = "applied"

export const vaccineStateOptions = [
    { value: VACCINE_STATE_ALL,       label: "Todas as vacinas"},
    { value: VACCINE_STATE_SCHEDULED, label: "Vacinas programadas"},
    { value: VACCINE_STATE_LATE,      label: "Vacinas atrasadas"},
    { value: VACCINE_STATE_APPLIED,   label: "Vacinas aplicadas"},
]

export const VACCINE_PERIOD_ALL           = "0"
export const VACCINE_PERIOD_YESTERDAY     = "1"
export const VACCINE_PERIOD_TODAY         = "2"
export const VACCINE_PERIOD_TOMORROW      = "3"
export const VACCINE_PERIOD_THISWEEK      = "4"
export const VACCINE_PERIOD_THISMONTH     = "5"
export const VACCINE_PERIOD_PREVIOUSMONTH = "6"
export const VACCINE_PERIOD_SPECIFICDATE  = "7"

export const vaccinePeriodOptions = append => [
    { value: VACCINE_PERIOD_ALL,           label: "Todos os períodos" },
    { value: VACCINE_PERIOD_YESTERDAY,     label: "Ontem" },
    { value: VACCINE_PERIOD_TODAY,         label: "Hoje" },
    { value: VACCINE_PERIOD_TOMORROW,      label: "Amanhã" },
    { value: VACCINE_PERIOD_THISWEEK,      label: "Próximos 7 dias" },
    { value: VACCINE_PERIOD_THISMONTH,     label: "Este mês" },
    { value: VACCINE_PERIOD_PREVIOUSMONTH, label: "Mês anterior" },
    { value: VACCINE_PERIOD_SPECIFICDATE,  label: "Data específica", append: append },
]

export const VACCINE_SORT_RECENT = "recent"
export const VACCINE_SORT_OLD    = "old"

export const vaccineSortOptions = [
    { value: VACCINE_SORT_RECENT, label: "Mais recentes"},
    { value: VACCINE_SORT_OLD,    label: "Mais antigos"},
]

export const specificDateAppend = (vaccineSpecificDateFrom, vaccineSpecificDateTo, onChangeFrom, onChangeTo) => (
    <div className="row align-items-center py-2">
        <div className="radio-option-append-text col-auto pe-0">
            DE
        </div>
        <div className="col-auto px-2">
            <DateInput
                placeholder="00/00/0000"
                value={vaccineSpecificDateFrom}
                onChange={onChangeFrom}
                size="small"
                variant="outlined"
                fullWidth
            />
        </div>
        <div className="radio-option-append-text col-auto px-0">
            ATÉ
        </div>
        <div className="col-auto px-2">
            <DateInput
                placeholder="00/00/0000"
                value={vaccineSpecificDateTo}
                onChange={onChangeTo}
                size="small"
                variant="outlined"
                fullWidth
            />
        </div>
    </div>
)

const VaccineList = props => {

    const {
        clinic,
        patient,
        history,
        permissions
    } = props

    const rowsPerPage = 5
    const filter = useRef(null)

    const [ vaccines, setVaccines ] = useState([])
    const [ vaccineStates, setVaccineStates ] = useState([vaccineStateOptions.find(opt => opt.value == VACCINE_STATE_ALL)])
    const [ vaccinePeriod, setVaccinePeriod ] = useState(VACCINE_PERIOD_ALL)
    const [ vaccineSort, setVaccineSort ] = useState(VACCINE_SORT_RECENT)
    const [ showShareVaccineForm, setShowShareVaccineForm ] = useState(false)

    const [ appliedAmount, setAppliedAmount ] = useState(<CircularProgress size="12px" />)
    const [ lateAmount, setLateAmount ] = useState(<CircularProgress size="12px" />)
    const [ scheduledAmount, setScheduledAmount ] = useState(<CircularProgress size="12px" />)

    const appendDisabledDefault = {
        applied: false,
        late: false,
        scheduled: false
    }
    const [ appendDisabled, setAppendDisabled ] = useState(appendDisabledDefault)

    const [ vaccineSpecificDateFrom, setVaccineSpecificDateFrom ] = useState(dayjs().format("DD/MM/YYYY"))
    const [ vaccineSpecificDateTo, setVaccineSpecificDateTo ] = useState(dayjs().format("DD/MM/YYYY"))

    const getVaccine = (searchTerm, dateFrom, dateTo, orderBy, page = 1) => {
        setVaccines([])

        var dateStart = dayjs().startOf('day')
        var dateEnd   = dayjs().endOf('day')

		var query = new Parse.Query("Vaccine")
        const vaccineStatesValues = vaccineStates?.map(vaccineState => vaccineState.value)
        if(vaccineStatesValues && vaccineStatesValues.length > 0 && !vaccineStatesValues.includes(VACCINE_STATE_ALL)){
            const queriesVaccineStates = []
            if(vaccineStatesValues.includes(VACCINE_STATE_APPLIED)){
                const queryVaccineStateApplied = new Parse.Query("Vaccine")
                queryVaccineStateApplied.equalTo("isReminder", false)
                queriesVaccineStates.push(queryVaccineStateApplied)
            }
            if(vaccineStatesValues.includes(VACCINE_STATE_SCHEDULED)){
                const queryVaccineStateScheduled = new Parse.Query("Vaccine")
                queryVaccineStateScheduled.equalTo("isReminder", true)
                queryVaccineStateScheduled.greaterThanOrEqualTo("documentDate", dateStart.toDate())
                queriesVaccineStates.push(queryVaccineStateScheduled)
            }
            if(vaccineStatesValues.includes(VACCINE_STATE_LATE)){
                const queryVaccineStateLate = new Parse.Query("Vaccine")
                queryVaccineStateLate.equalTo("isReminder", true)
                queryVaccineStateLate.lessThan("documentDate", dateStart.toDate())
                queriesVaccineStates.push(queryVaccineStateLate)
            }

            query = Parse.Query.or(...queriesVaccineStates)
        }

        switch(vaccinePeriod){
            // case VACCINE_PERIOD_ALL:
            //     query.greaterThanOrEqualTo("documentDate", dateFrom)
            //     query.lessThan("documentDate", dateTo)
            case VACCINE_PERIOD_YESTERDAY:
                dateStart = dayjs().subtract(1, 'days').startOf("day")
                dateEnd = dayjs().subtract(1, 'days').endOf("day")
                break;
            case VACCINE_PERIOD_TODAY:
                dateStart = dayjs().startOf("day")
                dateEnd = dayjs().endOf("day")
                break;
            case VACCINE_PERIOD_TOMORROW:
                dateStart = dayjs().add(1, 'days').startOf("day")
                dateEnd = dayjs().add(1, 'days').endOf("day")
                break;
            case VACCINE_PERIOD_THISWEEK:
                dateStart = dayjs().startOf("day")
                dateEnd = dayjs().add(7, "days").endOf("day")
                break;
            case VACCINE_PERIOD_THISMONTH:
                dateStart = dayjs().startOf('month').startOf("day")
                dateEnd = dayjs().endOf('month').endOf("day")
                break;
            case VACCINE_PERIOD_PREVIOUSMONTH:
                dateStart = dayjs().subtract(1, 'months').startOf('month')
                dateEnd = dayjs().subtract(1, 'months').endOf('month')
                break;
            case VACCINE_PERIOD_SPECIFICDATE:
                dateStart = dayjs(validateDate(vaccineSpecificDateFrom)).startOf('day')
                dateEnd = dayjs(validateDate(vaccineSpecificDateTo)).endOf('day')
        }
        if(vaccinePeriod && ![VACCINE_PERIOD_ALL].includes(vaccinePeriod)){
            query.greaterThanOrEqualTo("documentDate", dateStart.toDate())
            query.lessThan("documentDate", dateEnd.toDate())
        }
        
        query.equalTo("clinic", clinic.object)
		query.equalTo("isDeleted", false)
        if (patient) {
            query.equalTo("patient", patient)
        } else {
            const queryPatient = queryForSearchByPatientAndOwner(searchTerm, clinic.object)
            if (queryPatient) {
                query.matchesQuery("patient", queryPatient)
            }
        }
        if (dateFrom != null) {
            query.greaterThanOrEqualTo("documentDate", dateFrom)
        }
        if (dateTo != null) {
            query.lessThan("documentDate", dateTo)
        }

        return query.count()
        .then(_total => {
            query.include('messages')
            query.include('attachment')
            query.include('patient')
            query.include('patient.owner')

            query.include('parentVaccine')
            query.include('parentVaccine.messages')
            query.include('parentVaccine.attachment')
            query.include('parentVaccine.patient')
            query.include('parentVaccine.patient.owner')

            query.include('parentVaccine.parentVaccine')
            query.include('parentVaccine.parentVaccine.messages')
            query.include('parentVaccine.parentVaccine.attachment')
            query.include('parentVaccine.parentVaccine.patient')
            query.include('parentVaccine.parentVaccine.patient.owner')

            query.include('parentVaccine.parentVaccine.parentVaccine')
            query.include('parentVaccine.parentVaccine.parentVaccine.messages')
            query.include('parentVaccine.parentVaccine.parentVaccine.attachment')
            query.include('parentVaccine.parentVaccine.parentVaccine.patient')
            query.include('parentVaccine.parentVaccine.parentVaccine.patient.owner')

            query.include('parentVaccine.parentVaccine.parentVaccine.parentVaccine')
            query.include('parentVaccine.parentVaccine.parentVaccine.parentVaccine.messages')
            query.include('parentVaccine.parentVaccine.parentVaccine.parentVaccine.attachment')
            query.include('parentVaccine.parentVaccine.parentVaccine.parentVaccine.patient')
            query.include('parentVaccine.parentVaccine.parentVaccine.parentVaccine.patient.owner')

            query.include('createdBy')
            query.skip((page - 1) * rowsPerPage)
            query.limit(rowsPerPage)
            if(vaccineSort == VACCINE_SORT_OLD){
                query.ascending("documentDate")
            } else {
                query.descending("documentDate")
            }
            query.addAscending("objectId")
            return query.find()
            .then(_vaccines => {
                addEvent("TourVaccine__vaccineTabLoad", {})
                setVaccines(_vaccines || [])

                return {
                    items: _vaccines,
                    total: _total
                }
            })
            .catch(err => {
                console.error(err)
            })
		})
        .catch(err => {
            console.error(err)
		})
    }

    const onClearFilter = () => {
        setVaccineStates([vaccineStateOptions[0]])
        setVaccinePeriod(VACCINE_PERIOD_ALL)
        setVaccineSort(VACCINE_SORT_RECENT)
    }

    const onChange = () => {
        if (filter.current) {
            filter.current.onChange()
            checkCountForFilters()
        }
    }

    const appendFilterOnClick = vaccineState => () => {
        setVaccinePeriod("0")
      
        if(JSON.stringify(vaccineStates) == JSON.stringify([vaccineStateOptions.find(opt => opt.value == vaccineState)])){
            setVaccineStates([vaccineStateOptions.find(opt => opt.value == VACCINE_STATE_ALL)])
            setAppendDisabled(appendDisabledDefault)
            setVaccineSort(VACCINE_SORT_RECENT)
        } else {
            setVaccineStates([vaccineStateOptions.find(opt => opt.value == vaccineState)])
            setAppendDisabled(Object.assign(appendDisabledDefault, {
                [vaccineState]: true
            }))
            if(vaccineState == VACCINE_STATE_SCHEDULED){
                setVaccineSort(VACCINE_SORT_OLD)
            } else if(vaccineState == VACCINE_STATE_LATE){
                setVaccineSort(VACCINE_SORT_RECENT)
            }
        }

        setTimeout(() => {
            onChange()
        });
    }

    const checkCountForFilters = () => {
        setAppliedAmount(<CircularProgress size="12px" />)
        setLateAmount(<CircularProgress size="12px" />)
        setScheduledAmount(<CircularProgress size="12px" />)

        var dateStart = dayjs().startOf('day')

        var queryCountApplied = new Parse.Query("Vaccine")
        queryCountApplied.equalTo("clinic", clinic.object)
        queryCountApplied.equalTo("isDeleted", false)
        if(patient){
            queryCountApplied.equalTo("patient", patient)
        }
        queryCountApplied.equalTo("isReminder", false)

        var queryCountLate = new Parse.Query("Vaccine")
        queryCountLate.equalTo("clinic", clinic.object)
        queryCountLate.equalTo("isDeleted", false)
        if(patient){
            queryCountLate.equalTo("patient", patient)
        }
        queryCountLate.equalTo("isReminder", true)
        queryCountLate.lessThan("documentDate", dateStart.toDate())

        var queryCountScheduled = new Parse.Query("Vaccine")
        queryCountScheduled.equalTo("clinic", clinic.object)
        queryCountScheduled.equalTo("isDeleted", false)
        if(patient){
            queryCountScheduled.equalTo("patient", patient)
        }
        queryCountScheduled.equalTo("isReminder", true)
        queryCountScheduled.greaterThanOrEqualTo("documentDate", dateStart.toDate())

        Promise.all([
            queryCountApplied.count(),
            queryCountLate.count(),
            queryCountScheduled.count()
        ]).then(([totalApplied, totalLate, totalScheduled]) => {
            setAppliedAmount(totalApplied)
            setLateAmount(totalLate)
            setScheduledAmount(totalScheduled)
        })
    }

    useEffect(() => {
        if (permissions && !permissions.check(ACL.VACCINE, ACL.Rule.VIEW)) {
            permissions.error()
            return
        }

        checkCountForFilters()
    }, [ permissions ])
    
    useEffect(() => {
        document.addEventListener("Vaccine__getList", onChange)
        return () => { 
            document.removeEventListener("Vaccine__getList", onChange)
        }
    }, [])

    return (
        <div id="patient-vaccine">
            <div className="col-12">
                <VSList
                    ref={filter}
                    title={{
                        text: "Vacinas"
                    }}
                    customFilter={permissions?.check(ACL.VACCINE, ACL.Rule.EDIT) && (
                        <div className="row gx-3">
                            <div className="col-12 col-sm">
                                <button
                                    className="btn-outline-submit btn-submit btn-square vstext-ellipsis w-100 justify-content-center"
                                    onClick={() => setShowShareVaccineForm(true)}
                                >
                                    <IconUpload width="14" height="14" fill="var(--vsprontuario-primary-color)" className="me-2" />
                                    Compartilhar histórico
                                </button>
                            </div>
                            <div className="col-12 col-sm mt-3 mt-sm-0">
                                <button
                                    id="add-vaccine-button"
                                    className="btn-main-mobile btn-submit btn-square w-100"
                                    onClick={() => history.push(`/animal/${patient.id}/vacina/novo`)}
                                >
                                    <IconAdd id="add-icon" width="14" height="14" fill="#FFFFFF" />
                                    NOVA VACINA
                                </button>
                            </div>
                        </div>
                    )}
                    VSFilterProps={{
                        title: `Filtro de Vacinas`,
                        onClear: onClearFilter,
                        onFilter: () => {
                            onChange()
                            setAppendDisabled(appendDisabledDefault)
                        },
                        filters: [
                            {
                                title: "Estado da Vacina",
                                defaultValue: [vaccineStateOptions.find(opt => opt.value == VACCINE_STATE_ALL)],
                                value: vaccineStates,
                                componentProps: {
                                    horizontal: false
                                },
                                onChange: (value, option) => {
                                    setVaccineStates(option.value == VACCINE_STATE_ALL ? [vaccineStateOptions.find(opt => opt.value == VACCINE_STATE_ALL)] : value.filter(_value => _value.value != VACCINE_STATE_ALL))
                                },
                                multiple: true,
                                options: vaccineStateOptions
                            },
                            {
                                title: "Período da Vacina",
                                defaultValue: VACCINE_PERIOD_ALL,
                                value: vaccinePeriod,
                                onChange: (value) => {
                                    setVaccinePeriod(value)
                                },
                                options: vaccinePeriodOptions(
                                    vaccinePeriod == VACCINE_PERIOD_SPECIFICDATE ? 
                                    specificDateAppend(
                                        vaccineSpecificDateFrom, vaccineSpecificDateTo,
                                        value => setVaccineSpecificDateFrom(value),
                                        value => setVaccineSpecificDateTo(value)
                                    ) : undefined
                                )
                            },
                            {
                                title: "Ordenar por",
                                defaultValue: VACCINE_SORT_RECENT,
                                value: vaccineSort,
                                onChange: (value) => {
                                    setVaccineSort(value)
                                },
                                options: vaccineSortOptions
                            }
                        ].filter(val => val)
                    }}
                    appendFilter={(
                        <VaccineAppendFilter
                            disabled={appendDisabled}
                            amount={{
                                applied: appliedAmount,
                                late: lateAmount,
                                scheduled: scheduledAmount
                            }}
                            onClick={{
                                applied: appendFilterOnClick(VACCINE_STATE_APPLIED),
                                late: appendFilterOnClick(VACCINE_STATE_LATE),
                                scheduled: appendFilterOnClick(VACCINE_STATE_SCHEDULED)
                            }}
                        />
                    )}
                    pagination={{
                        rowsPerPage: rowsPerPage,
                        expand: true
                    }}
                    onChange={({ _searchBy, _start, _end, _orderBy, _page }) => {
                        return getVaccine(_searchBy, _start, _end, _orderBy, _page)
                    }}
                    renderItem={(item, expanded, index) => {
                        return (
                            <VaccineListView 
                                object={item} 
                                expanded={expanded}
                                history={history}
                                updateList={() => onChange()}
                                fromPatient
                            />
                        )
                    }}
                    renderEmpty={() => (
                        <VSEmpty
                            icon={<IconPrimaryVaccine width="32px" height="32px" />}
                            title="O animal não possui nenhuma vacina ou programação de vacina cadastradas"
                            text="Registre a vacina ou programação de vacina do animal utilizando um dos botões abaixo"
                        >
                            <div className="row justify-content-center">
                                <div className="col-auto">
                                    <button
                                        id="new-vaccine-button"
                                        className="vsbox-btn vsbox-btn-primary"
                                        onClick={() => history.push(patient ? `/animal/${patient.id}/vacina/novo` : '/animal/selecionar/vacina')}
                                    >
                                        <IconAdd width="14" height="14" className="me-2" />
                                        Cadastrar nova vacina
                                    </button>
                                </div>
                            </div>
                        </VSEmpty>
                    )}
                    renderNotFound={() => (
                        <VSEmpty
                            icon={<IconPrimaryVaccine width="32px" height="32px" />}
                            title="Não encontramos nenhum resultado para a sua busca"
                            text="Tente refazer sua busca utilizando mais caracteres ou crie um novo cadastro utilizando o botão abaixo"
                        >
                            <div className="row justify-content-center">
                                <div className="col-auto">
                                    <button
                                        id="new-vaccine-button"
                                        className="vsbox-btn vsbox-btn-primary"
                                        onClick={() => history.push(patient ? `/animal/${patient.id}/vacina/novo` : '/animal/selecionar/vacina')}
                                    >
                                        <IconAdd width="14" height="14" className="me-2" />
                                        Cadastrar nova vacina
                                    </button>
                                </div>
                            </div>
                        </VSEmpty>
                    )}
                />
                {showShareVaccineForm && (
                    <ShareVaccineForm
                        patient={patient}
                        onCancel={() => setShowShareVaccineForm(false)}
                        onCloseDrawer={() => setShowShareVaccineForm(false)}
                        onCloseMessage={() => setShowShareVaccineForm(false)}
                    />
                )}
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        clinic: state.clinic,
        permissions: state.permissions
    }
}
  
export default connect(mapStateToProps)(VaccineList)