import React from 'react'
import { connect } from 'react-redux'

import { ReactComponent as IconEdit } from "../../../../assets/icon-primary-edit.svg"
import { ReactComponent as IconTrash } from "../../../../assets/icon-primary-trash.svg"
import { ReactComponent as IconExam } from "../../../../assets/icon-patient-exam.svg"

import { deleteExam } from './Exam'
import { addEvent, dayjs, showRegisteredBy, stringifyArray } from '../../../../utils'
import { ACL } from '../../../../constants'

import VSAccordionSimple from '../../../../components/vsAccordion/VSAccordionSimple'
import VSMedicalRecordsAudit from '../../../../components/vsMedicalRecordsAudit/VSMedicalRecordsAudit'

import './ExamView.scss'
const ExamView = (props) => {

    const {
        object,
        history,
        noDocumentDate,
        noAudit,
        permissions
    } = props

    const patient = object.get("patient")
    const isDeleted = object.get("isDeleted")

    return (
        <div id="patient-exam-view" className={`vsbox mb-3 ${isDeleted ? "vsbox-disabled" : ""}`}>
            <div className="vsbox-body pb-0">
                <div className="row g-3 mb-3 align-items-center">
                    { !noDocumentDate && (
                        <div className="col-auto pe-0">
                            <div className="vsbox-chip">
                                { dayjs(object.get("documentDate")).format("DD/MM/YYYY") }
                            </div>
                        </div>
                    )}
                    <div className="col-12 col-lg">
                        <div className="row align-items-center g-3">
                            <div className="col-auto">
                                <IconExam width="24" height="24" fill="var(--vsprontuario-gray-color)" />
                            </div>
                            <div className="col ps-0">
                                <label className="vsbox-info">Tipo de Registro</label>
                                <div className="vsbox-info-text">
                                    Solicitação de Exames
                                </div>
                            </div>
                        </div>
                    </div>
                    { !isDeleted && (
                        <div className="col-12 col-md-auto">
                            <div className="row g-3 align-items-center justify-content-center">
                                <div className="col-12 col-md-auto">
                                    <button
                                        className={`vsbox-btn ${isDeleted ? "vsbox-btn-disabled" : "vsbox-btn-primary"}`}
                                        onClick={() => history.push(`/documento/exame/${ object.id }`)}
                                        disabled={isDeleted}
                                    >
                                        Reenviar Solicitação
                                    </button>
                                </div>
                                { permissions?.check(ACL.EXAM_REQUEST, ACL.Rule.EDIT, object) && (
                                    <div className="col col-md-auto">
                                        <button
                                            className={`vsbox-btn ${isDeleted ? "vsbox-btn-disabled" : "vsbox-btn-dark"}`}
                                            onClick={() => history.push(`/animal/${ patient.id }/solicitacao/${ object.id }`)}
                                            disabled={isDeleted}
                                        >
                                            <IconEdit width="24" height="24" fill={isDeleted ? "var(--vsprontuario-gray-color)" : "var(--vsprontuario-primary-color-dark)"} />
                                        </button>
                                    </div>
                                )}
                                { permissions?.check(ACL.EXAM_REQUEST, ACL.Rule.DELETE, object) && (
                                    <div className="col col-md-auto">
                                        <button
                                            className={`vsbox-btn ${isDeleted ? "vsbox-btn-disabled" : "vsbox-btn-alert"}`}
                                            onClick={() => deleteExam(object, () => addEvent(["Exam__getList", "History__getList"], {}))}
                                            disabled={isDeleted}
                                        >
                                            <IconTrash width="24" height="24" fill={isDeleted ? "var(--vsprontuario-gray-color)" : "var(--vsprontuario-primary-color)"} />
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
                { showRegisteredBy(object) }
                { !isDeleted && (
                    <>
                        <div className="vsbox-separator"></div>
                        <div className="row">
                            <div className="col-12 vsbox--info">
                                <VSAccordionSimple
                                    expanded={true}
                                    header={(expanded) => (
                                        `Ver ${ expanded ? "menos" : "mais" } informações`
                                    )}
                                >
                                    { object.get("exams") && object.get("exams").length > 0 ? (
                                        <div className="row">
                                            <div className="col-12">
                                                { object.get("exams").map((exam, index) => (
                                                    <div className="vsbox-item mb-3" key={index}>
                                                        <div className="row mb-2">
                                                            <div className="col-12">
                                                                <div className="vsbox-title">
                                                                    { exam.exam }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="vsbox-separator my-1"></div>
                                                        <div className="row vsbox-item-text my-2">
                                                            <div className="col-auto pre-line">
                                                                <b>Observações sobre o Exame:</b> { exam.notes || "Não informado" }
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="vsbox-item mb-3">
                                                    Nenhum exame solicitado
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    <div className="row">
                                        <div className="col vsbox-title">
                                            { object.get("diseases")?.length ? "Motivos/Suspeitas" : "Motivo/Suspeita" }
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col mb-3">
                                            { stringifyArray(object.get("diseases")?.map(disease => disease?.disease)) || "Não informado" }
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col vsbox-title">
                                            Instruções/Observações Gerais da Solicitação
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col mb-3 pre-line">
                                            { object.get("notes") || "Não informado" }
                                        </div>
                                    </div>
                                </VSAccordionSimple>
                            </div>
                        </div>
                    </>
                )}
                { !noAudit && (
                    <VSMedicalRecordsAudit parseObject={object} />
                )}
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        permissions: state.permissions
    };
};
  
export default connect(mapStateToProps)(ExamView)