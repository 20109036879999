import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux';

import './Vaccine.scss'

import { ReactComponent as IconPrimaryVaccine } from '../../assets/icon-primary-vaccine.svg'
import { ReactComponent as IconAdd } from "../../assets/icon-primary-add-o.svg"

import { CircularProgress } from '@mui/material';
import VSEmpty from '../../components/vsEmpty/VSEmpty';
import VSList from '../../components/vsList/VSList';
import VSTimeline from '../../components/vsTimeline/VSTimeline';
import VSAccordionSimple from '../../components/vsAccordion/VSAccordionSimple';
import VSBoxPatient from '../../components/cardPatient/VSBoxPatient';
import VaccineListView from '../patient/tabs/vaccine/VaccineListView';
import { queryForSearchByPatientAndOwner } from '../patient/Patient';
import { ACL } from '../../constants';
import { dayjs, diacriticSensitive, validateDate } from '../../utils';


import { VACCINE_TYPE_REMINDER, 
        VACCINE_STATE_ALL, VACCINE_STATE_SCHEDULED, VACCINE_STATE_LATE, VACCINE_STATE_APPLIED,
        VACCINE_PERIOD_ALL, VACCINE_PERIOD_YESTERDAY, VACCINE_PERIOD_TODAY, VACCINE_PERIOD_TOMORROW, VACCINE_PERIOD_THISWEEK, VACCINE_PERIOD_THISMONTH, VACCINE_PERIOD_PREVIOUSMONTH, VACCINE_PERIOD_SPECIFICDATE,
        VACCINE_SORT_RECENT, VACCINE_SORT_OLD,
        vaccineSortOptions, vaccineStateOptions, vaccinePeriodOptions, specificDateAppend } from '../patient/tabs/vaccine/VaccineList';
import VaccineAppendFilter from '../patient/tabs/vaccine/VaccineAppendFilter';

const Vaccine = (props) => {

    const {
        clinic,
        history,
        permissions
    } = props

    const rowsPerPage = 5
    const filter = useRef(null)

    const [ loading, setLoading ] = useState(false)
    const [ term, setTerm ] = useState("")
    const [ hasOwner, setHasOwner ] = useState(false)
    const [ hasPatient, setHasPatient ] = useState(false)
    const [ hasVaccine, setHasVaccine ] = useState(false)
    const [ filters, setFilters ] = useState({
        dateFrom: dayjs().startOf('day').toDate(),
        dateTo: null,
        orderByReminderDate: true,
        orderByVaccine: null,
        orderByPatient: null,
        orderByDocumentDate: true,
        orderByCreatedAt: null
    })

    const [ vaccines, setVaccines ] = useState([])
    const [ vaccineStates, setVaccineStates ] = useState([vaccineStateOptions.find(opt => opt.value == VACCINE_STATE_ALL)])
    const [ vaccinePeriod, setVaccinePeriod ] = useState(VACCINE_PERIOD_ALL)
    const [ vaccineSort, setVaccineSort ] = useState(VACCINE_SORT_RECENT)

    const [ appliedAmount, setAppliedAmount ] = useState(<CircularProgress size="12px" />)
    const [ lateAmount, setLateAmount ] = useState(<CircularProgress size="12px" />)
    const [ scheduledAmount, setScheduledAmount ] = useState(<CircularProgress size="12px" />)

    const appendDisabledDefault = {
        applied: false,
        late: false,
        scheduled: false
    }
    const [ appendDisabled, setAppendDisabled ] = useState(appendDisabledDefault)

    const [ vaccineSpecificDateFrom, setVaccineSpecificDateFrom ] = useState(dayjs().format("DD/MM/YYYY"))
    const [ vaccineSpecificDateTo, setVaccineSpecificDateTo ] = useState(dayjs().format("DD/MM/YYYY"))

    const getVaccine = (searchTerm = "", dateFrom, dateTo, orderBy, page = 1) => {
        return new Promise((resolve, reject) => {

            setVaccines([])
            const filters = {}
            
            var dateStart = dayjs().startOf('day')
            var dateEnd   = dayjs().endOf('day')     
            
            const vaccineStatesValues = vaccineStates?.map(vaccineState => vaccineState.value)
            if(vaccineStatesValues && vaccineStatesValues.length > 0 && !vaccineStatesValues.includes(VACCINE_STATE_ALL)){
                filters.$or = []
                if(vaccineStatesValues.includes(VACCINE_STATE_APPLIED)) {
                    filters.$or.push({
                        isReminder: false
                    })
                }
                if(vaccineStatesValues.includes(VACCINE_STATE_SCHEDULED)) {
                    filters.$or.push({
                        isReminder: true,
                        documentDate: {
                            $gte: dateStart.toDate()
                        }
                    })
                }
                if(vaccineStatesValues.includes(VACCINE_STATE_LATE)) {
                    filters.$or.push({
                        isReminder: true,
                        documentDate: {
                            $lt: dateStart.toDate()
                        }
                    })
                }
                if (filters.$or.length == 0) {
                    delete filters.$or
                }
            }
    
            switch(vaccinePeriod) {
                case VACCINE_PERIOD_YESTERDAY:
                    dateStart = dayjs().subtract(1, 'days').startOf("day")
                    dateEnd = dayjs().subtract(1, 'days').endOf("day")
                    break;
                case VACCINE_PERIOD_TODAY:
                    dateStart = dayjs().startOf("day")
                    dateEnd = dayjs().endOf("day")
                case VACCINE_PERIOD_TOMORROW:
                    dateStart = dayjs().add(1, 'days').startOf("day")
                    dateEnd = dayjs().add(1, 'days').endOf("day")
                    break;
                case VACCINE_PERIOD_THISWEEK:
                    dateStart = dayjs().startOf("day")
                    dateEnd = dayjs().add(7, "days").endOf("day")
                    break;
                case VACCINE_PERIOD_THISMONTH:
                    dateStart = dayjs().startOf('month')
                    dateEnd = dayjs().endOf('month')
                    break;
                case VACCINE_PERIOD_PREVIOUSMONTH:
                    dateStart = dayjs().subtract(1, 'months').startOf('month')
                    dateEnd = dayjs().subtract(1, 'months').endOf('month')
                    break;
                case VACCINE_PERIOD_SPECIFICDATE:
                    dateStart = dayjs(validateDate(vaccineSpecificDateFrom)).startOf('day')
                    dateEnd = dayjs(validateDate(vaccineSpecificDateTo)).endOf('day')
            }
    
            const filterByDate = vaccinePeriod && ![VACCINE_PERIOD_ALL].includes(vaccinePeriod)
    
            Parse.Cloud.run("getPeriodForClass", {
                clinicId: clinic.objectId,
                className: "VW_Vaccine_full",
                filters: filters,
                searchByPatientAndOwner: diacriticSensitive(searchTerm),
                dateFrom: filterByDate ? dateStart.toDate() : null,
                dateTo: filterByDate ? dateEnd.toDate() : null,
                orderBy: "documentDate",
                order: vaccineSort == VACCINE_SORT_OLD ? "oldest" : "newest",
                page: page
            })
            .then(({ total, from, to }) => {
                var query = new Parse.Query("Vaccine");
                const vaccineStatesValues = vaccineStates?.map(vaccineState => vaccineState.value)
                if(vaccineStatesValues && vaccineStatesValues.length > 0 && !vaccineStatesValues.includes(VACCINE_STATE_ALL)){
                    const queriesVaccineStates = []
                    if(vaccineStatesValues.includes(VACCINE_STATE_APPLIED)){
                        const queryVaccineStateApplied = new Parse.Query("Vaccine")
                        queryVaccineStateApplied.equalTo("isReminder", false)
                        queriesVaccineStates.push(queryVaccineStateApplied)
                    }
                    if(vaccineStatesValues.includes(VACCINE_STATE_SCHEDULED)){
                        const queryVaccineStateScheduled = new Parse.Query("Vaccine")
                        queryVaccineStateScheduled.equalTo("isReminder", true)
                        queryVaccineStateScheduled.greaterThanOrEqualTo("documentDate", dayjs().startOf('day').toDate())
                        queriesVaccineStates.push(queryVaccineStateScheduled)
                    }
                    if(vaccineStatesValues.includes(VACCINE_STATE_LATE)){
                        const queryVaccineStateLate = new Parse.Query("Vaccine")
                        queryVaccineStateLate.equalTo("isReminder", true)
                        queryVaccineStateLate.lessThan("documentDate", dayjs().startOf('day').toDate())
                        queriesVaccineStates.push(queryVaccineStateLate)
                    }
    
                    query = Parse.Query.or(...queriesVaccineStates)
                }
                
                if(vaccinePeriod && ![VACCINE_PERIOD_ALL].includes(vaccinePeriod)){
                    query.greaterThanOrEqualTo("documentDate", dateStart.toDate())
                    query.lessThan("documentDate", dateEnd.toDate())
                }
    
                query.equalTo("clinic", clinic.object)
                query.equalTo("isDeleted", false)
                const queryPatient = queryForSearchByPatientAndOwner(searchTerm, clinic.object)
                if (queryPatient) {
                    query.matchesQuery("patient", queryPatient)
                }
                
                query.greaterThanOrEqualTo("documentDate", from);
                query.lessThan("documentDate", to);
                query.include('messages')
                query.include('attachment')
                query.include('patient')
                query.include('patient.owner')
    
                query.include('parentVaccine')
                query.include('parentVaccine.messages')
                query.include('parentVaccine.attachment')
                query.include('parentVaccine.patient')
                query.include('parentVaccine.patient.owner')
    
                query.include('parentVaccine.parentVaccine')
                query.include('parentVaccine.parentVaccine.messages')
                query.include('parentVaccine.parentVaccine.attachment')
                query.include('parentVaccine.parentVaccine.patient')
                query.include('parentVaccine.parentVaccine.patient.owner')
    
                query.include('parentVaccine.parentVaccine.parentVaccine')
                query.include('parentVaccine.parentVaccine.parentVaccine.messages')
                query.include('parentVaccine.parentVaccine.parentVaccine.attachment')
                query.include('parentVaccine.parentVaccine.parentVaccine.patient')
                query.include('parentVaccine.parentVaccine.parentVaccine.patient.owner')
    
                query.include('parentVaccine.parentVaccine.parentVaccine.parentVaccine')
                query.include('parentVaccine.parentVaccine.parentVaccine.parentVaccine.messages')
                query.include('parentVaccine.parentVaccine.parentVaccine.parentVaccine.attachment')
                query.include('parentVaccine.parentVaccine.parentVaccine.parentVaccine.patient')
                query.include('parentVaccine.parentVaccine.parentVaccine.parentVaccine.patient.owner')
    
                query.include('createdBy')
                if(vaccineSort == VACCINE_SORT_OLD){
                    query.ascending("documentDate")
                } else {
                    query.descending("documentDate")
                }
                query.addAscending("objectId")
                return query.find()
                .then(_vaccines => {
                    let vaccinesByDate = []
                    let dates = []
                    _vaccines.map(vaccine => {
                        const vaccineDateFormatted = dayjs(vaccine.get("documentDate")).format("DD/MM/YYYY")
                        if(vaccine.get("patient")){
                            const patientId = vaccine.get("patient").id
                            if(!vaccinesByDate[vaccineDateFormatted]){
                                dates.push(vaccineDateFormatted)
                                vaccinesByDate[vaccineDateFormatted] = []
                            }
                            if(!vaccinesByDate[vaccineDateFormatted][patientId]){
                                vaccinesByDate[vaccineDateFormatted][patientId] = []
                            }
                            vaccinesByDate[vaccineDateFormatted][patientId].push(vaccine)
                        }
                    })
                    setVaccines(vaccinesByDate || [])
                    resolve({
                        items: dates,
                        total: total
                    })
                })
                .catch(err => {
                    console.error(err)
                })
            })
        })
    }

    const onClearFilter = () => {
        setVaccineStates([vaccineStateOptions[0]])
        setVaccinePeriod(VACCINE_PERIOD_ALL)
        setVaccineSort(VACCINE_SORT_RECENT)
    }

    const onChange = () => {
        if (filter.current) {
            filter.current.onChange()
            checkCountForFilters()
        }
    }

    const appendFilterOnClick = vaccineState => () => {
        setVaccinePeriod("0")

        if(JSON.stringify(vaccineStates) == JSON.stringify([vaccineStateOptions.find(opt => opt.value == vaccineState)])){
            setVaccineStates([vaccineStateOptions.find(opt => opt.value == VACCINE_STATE_ALL)])
            setAppendDisabled(appendDisabledDefault)
        } else {
            setVaccineStates([vaccineStateOptions.find(opt => opt.value == vaccineState)])
            setAppendDisabled(Object.assign(appendDisabledDefault, {
                [vaccineState]: true
            }))
            if(vaccineState == VACCINE_STATE_SCHEDULED){
                setVaccineSort(VACCINE_SORT_OLD)
            } else if(vaccineState == VACCINE_STATE_LATE){
                setVaccineSort(VACCINE_SORT_RECENT)
            }
        }

        setTimeout(() => {
            onChange()
        });
    }

    const checkCountForFilters = () => {
        setAppliedAmount(<CircularProgress size="12px" />)
        setLateAmount(<CircularProgress size="12px" />)
        setScheduledAmount(<CircularProgress size="12px" />)

        var dateStart = dayjs().startOf('day')

        var queryCountApplied = new Parse.Query("Vaccine")
        queryCountApplied.equalTo("clinic", clinic.object)
        queryCountApplied.equalTo("isDeleted", false)
        queryCountApplied.equalTo("isReminder", false)

        var queryCountLate = new Parse.Query("Vaccine")
        queryCountLate.equalTo("clinic", clinic.object)
        queryCountLate.equalTo("isDeleted", false)
        queryCountLate.equalTo("isReminder", true)
        queryCountLate.lessThan("documentDate", dateStart.toDate())

        var queryCountScheduled = new Parse.Query("Vaccine")
        queryCountScheduled.equalTo("clinic", clinic.object)
        queryCountScheduled.equalTo("isDeleted", false)
        queryCountScheduled.equalTo("isReminder", true)
        queryCountScheduled.greaterThanOrEqualTo("documentDate", dateStart.toDate())

        Promise.all([
            queryCountApplied.count(),
            queryCountLate.count(),
            queryCountScheduled.count()
        ]).then(([totalApplied, totalLate, totalScheduled]) => {
            setAppliedAmount(totalApplied)
            setLateAmount(totalLate)
            setScheduledAmount(totalScheduled)
        })
    }

    useEffect(() => {
        setLoading(true)
        if (clinic && clinic.object) {
            var ownerIsNotDeleted = new Parse.Query("Owner")
            ownerIsNotDeleted.equalTo("clinic", clinic.object)
            ownerIsNotDeleted.equalTo("isDeleted", false)
            ownerIsNotDeleted.limit(1)
            ownerIsNotDeleted.count()
            .then(total => {
                setHasOwner(total > 0)
            })
    
            var patientIsNotDeleted = new Parse.Query("Patient")
            patientIsNotDeleted.equalTo("clinic", clinic.object)
            patientIsNotDeleted.equalTo("isDeleted", false)
            patientIsNotDeleted.limit(1)
            patientIsNotDeleted.count()
            .then(total => {
                setHasPatient(total > 0)
            })
    
            var vaccineIsNotDeleted = new Parse.Query("Vaccine")
            vaccineIsNotDeleted.equalTo("clinic", clinic.object)
            vaccineIsNotDeleted.equalTo("isDeleted", false)
            vaccineIsNotDeleted.limit(1)
            vaccineIsNotDeleted.count()
            .then(total => {
                setHasVaccine(total > 0)
                setLoading(false)
            })

            checkCountForFilters()
        }
    }, [ clinic ])

    useEffect(() => {
        const params = new URLSearchParams(props.location.search)
        var defaultFilters = {}
        if (params.get('lembretes') !== null) {
            defaultFilters = {
                ...filters,
                dateFrom: null,
                orderByReminderDate: null,
                orderByDocumentDate: null,
                orderByCreatedAt: false,
                show: VACCINE_TYPE_REMINDER,
            }
        } else if (params.get('atrasadas') !== null) {
            defaultFilters = {
                ...filters,
                dateFrom: null,
                dateTo: dayjs().endOf('day').toDate(),
                show: VACCINE_TYPE_REMINDER,
            }
        } else if (params.get('proximas') !== null) {
            defaultFilters = {
                ...filters,
                show: VACCINE_TYPE_REMINDER
            }
        } else {
            defaultFilters = { ...filters }
        }
        setFilters(defaultFilters)
    }, [])

    useEffect(() => {
        if (permissions && !permissions.check(ACL.VACCINE, ACL.Rule.VIEW)) {
            permissions.error()
        }
    }, [ permissions ])
    
    return (
        <div id="vaccine" className="page-content">
            <div className="col-12">
                { loading ? (
                    <div className="data-loading">
                        <CircularProgress />
                    </div>
                ) : (
                    hasVaccine ? (
                        <VSList
                            ref={filter}
                            title={{
                                text: "Vacinas"
                            }}
                            button={permissions?.check(ACL.SALE, ACL.Rule.EDIT) && {
                                text: "NOVA VACINA",
                                onClick: () => history.push(`/animal/selecionar/vacina`)
                            }}
                            VSFilterProps={{
                                title: `Filtrar Resultados`,
                                onClear: onClearFilter,
                                onFilter: () => {
                                    onChange()
                                    setAppendDisabled(appendDisabledDefault)
                                },
                                filters: [
                                    {
                                        title: "Estado da Vacina",
                                        defaultValue: [vaccineStateOptions.find(opt => opt.value == VACCINE_STATE_ALL)],
                                        value: vaccineStates,
                                        componentProps: {
                                            horizontal: false
                                        },
                                        onChange: (value, option) => {
                                            setVaccineStates(option.value == VACCINE_STATE_ALL ? [vaccineStateOptions.find(opt => opt.value == VACCINE_STATE_ALL)] : value.filter(_value => _value.value != VACCINE_STATE_ALL))
                                        },
                                        multiple: true,
                                        options: vaccineStateOptions
                                    },
                                    {
                                        title: "Período da Vacina",
                                        defaultValue: VACCINE_PERIOD_ALL,
                                        value: vaccinePeriod,
                                        onChange: (value) => {
                                            setVaccinePeriod(value)
                                        },
                                        options: vaccinePeriodOptions(
                                            vaccinePeriod == VACCINE_PERIOD_SPECIFICDATE ? 
                                            specificDateAppend(
                                                vaccineSpecificDateFrom, vaccineSpecificDateTo,
                                                value => setVaccineSpecificDateFrom(value),
                                                value => setVaccineSpecificDateTo(value)
                                            ) : undefined
                                        )
                                    },
                                    {
                                        title: "Ordenar por",
                                        defaultValue: VACCINE_SORT_RECENT,
                                        value: vaccineSort,
                                        onChange: (value) => {
                                            setVaccineSort(value)
                                        },
                                        options: vaccineSortOptions
                                    }
                                ].filter(val => val)
                            }}
                            appendFilter={(
                                <VaccineAppendFilter
                                    disabled={appendDisabled}
                                    amount={{
                                        applied: appliedAmount,
                                        late: lateAmount,
                                        scheduled: scheduledAmount
                                    }}
                                    onClick={{
                                        applied: appendFilterOnClick(VACCINE_STATE_APPLIED),
                                        late: appendFilterOnClick(VACCINE_STATE_LATE),
                                        scheduled: appendFilterOnClick(VACCINE_STATE_SCHEDULED)
                                    }}
                                />
                            )}
                            pagination={{
                                rowsPerPage: rowsPerPage,
                                expand: true
                            }}
                            onChange={({ _searchBy, _start, _end, _orderBy, _page }) => {
                                // setTerm(_searchBy)
                                // setDateFrom(_start)
                                // setDateTo(_end)
                                return getVaccine(_searchBy, _start, _end, _orderBy, _page)
                            }}
                            renderItem={(item, expanded, index) => {
                                const date = dayjs(item, "DD/MM/YYYY")
                                
                                return (
                                    <VSTimeline
                                        day={ date.format("DD") }
                                        month={ date.format("MMM") }
                                        year={ date.format("YYYY") }
                                    >
                                        {vaccines && vaccines[item] && Object.keys(vaccines[item]).map((dataId, index2) => {
                                            const _vaccines = vaccines[item][dataId]
                                            if(!_vaccines || !_vaccines.length){
                                                return
                                            }
                                            const patient = _vaccines[0].get("patient")
                                            const key = `${item.replace(/[^0-9]/g, '')}${index}${index2}`

                                            return (
                                                <React.Fragment key={key}>
                                                    <VSAccordionSimple
                                                        expanded={true}
                                                        className="custom-accordion"
                                                        hideIcon
                                                        header={(expanded) => (
                                                            <div className={`vstimeline-item vstimeline-active w-100`}>
                                                                <VSBoxPatient
                                                                    count={_vaccines.length}
                                                                    patient={patient}
                                                                    expanded={expanded}
                                                                />
                                                            </div>
                                                        )}
                                                    >
                                                        {_vaccines && _vaccines.map((data, index) => {
                                                            if(!data){
                                                                return
                                                            }

                                                            return (
                                                                <VaccineListView 
                                                                    object={data} 
                                                                    expanded={expanded}
                                                                    history={history}
                                                                    updateList={() => onChange()}
                                                                />
                                                            )
                                                        })}
                                                    </VSAccordionSimple>
                                                </React.Fragment>
                                            )
                                        })}
                                    </VSTimeline>
                                )
                            }}
                            renderEmpty={() => (
                                <VSEmpty
                                    icon={<IconPrimaryVaccine width="32px" height="32px" />}
                                    title="O animal não possui nenhuma vacina ou programação de vacina cadastrados"
                                    text="Registre a vacina ou programação de vacina do animal utilizando um dos botões abaixo"
                                >
                                    <div className="row justify-content-center">
                                        <div className="col-auto">
                                            <button
                                                id="new-vaccine-button"
                                                className="vsbox-btn vsbox-btn-primary"
                                                onClick={() => history.push('/animal/selecionar/vacina')}
                                            >
                                                <IconAdd width="14" height="14" className="me-2" />
                                                Cadastrar nova vacina
                                            </button>
                                        </div>
                                    </div>
                                </VSEmpty>
                            )}
                            renderNotFound={() => (
                                <VSEmpty
                                    icon={<IconPrimaryVaccine width="32px" height="32px" />}
                                    title="Não encontramos nenhum resultado para a sua busca"
                                    text="Tente refazer sua busca utilizando mais caracteres ou crie um novo cadastro utilizando o botão abaixo"
                                >
                                    <div className="row justify-content-center">
                                        <div className="col-auto">
                                            <button
                                                id="new-owner-button"
                                                className="vsbox-btn vsbox-btn-primary"
                                                onClick={() => history.push(`/tutor/form/novo`)}
                                            >
                                                <IconAdd width="14" height="14" className="me-2" />
                                                Cadastrar novo(a) tutor(a)
                                            </button>
                                        </div>
                                        <div className="col-auto">
                                            <button
                                                id="new-patient-button"
                                                className="vsbox-btn vsbox-btn-primary"
                                                onClick={() => history.push(`/tutor/selecionar/animal/novo`)}
                                            >
                                                <IconAdd width="14" height="14" className="me-2" />
                                                Cadastrar novo animal
                                            </button>
                                        </div>
                                    </div>
                                </VSEmpty>
                            )}
                        />
                    ) : (
                        hasPatient ? (
                            <VSEmpty
                                icon={<IconPrimaryVaccine width="32px" height="32px" />}
                                title="Você ainda não possui nenhuma vacina ou programação cadastrados no Prontuário"
                                text="Utilize um dos botões abaixo para selecionar o animal e registrar sua primeira vacina ou programação de vacina"
                            >
                                { permissions?.check(ACL.VACCINE, ACL.Rule.EDIT) && (
                                    <div className="row justify-content-center">
                                        <div className="col-auto">
                                            <button
                                                id="new-vaccine-link"
                                                className="vsbox-btn vsbox-btn-primary"
                                                onClick={() => history.push(`/animal/selecionar/vacina`)}
                                            >
                                                <IconAdd width="14" height="14" className="me-2" />
                                                Cadastrar nova vacina
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </VSEmpty>
                        ) : (
                            hasOwner ? (
                                <VSEmpty
                                    icon={<IconPrimaryVaccine width="32px" height="32px" />}
                                    title="Você ainda não possui nenhum animal cadastrado no Prontuário"
                                    text="Cadastre o primeiro animal e comece a aproveitar todos os benefícios de gestão de atendimentos do Prontuário Vet Smart"
                                >
                                    { permissions?.check(ACL.PATIENT, ACL.Rule.EDIT) && (
                                        <button
                                            id="new-patient-link"
                                            className="vsbox-btn vsbox-btn-primary"
                                            onClick={() => history.push(`/tutor/selecionar/animal/novo`)}
                                        >
                                            <IconAdd width="14" height="14" className="me-2" />
                                            Cadastrar novo animal
                                        </button>
                                    )}
                                </VSEmpty>
                            ) : (
                                <VSEmpty
                                    icon={<IconPrimaryVaccine width="32px" height="32px" />}
                                    title="Você ainda não possui nenhuma(a) tutor(a) cadastrado(a) no Prontuário"
                                    text="Cadastre o(a) primeiro(a) tutor(a) e comece a aproveitar todos os benefícios de gestão de clientes do Prontuário Vet Smart"
                                >
                                    { permissions?.check(ACL.OWNER, ACL.Rule.EDIT) && (
                                        <button
                                            id="new-owner-link"
                                            className="vsbox-btn vsbox-btn-primary"
                                            onClick={() => history.push(`/tutor/form/novo`)}
                                        >
                                            <IconAdd width="14" height="14" className="me-2" />
                                            Cadastrar novo(a) tutor(a)
                                        </button>
                                    )}
                                </VSEmpty>
                            )
                        )
                    )
                )}
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        clinic: state.clinic,
        permissions: state.permissions
    };
};
  
export default connect(mapStateToProps)(Vaccine);