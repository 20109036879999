import React, { useEffect, useRef, useState } from "react"
import Reactour from 'reactour'

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import "./Tour.scss"
import { getOnboarding } from "../../store/actions";
import { parseState } from "../../store";
import { STEP_APPOINTMENT, STEP_OWNER, STEP_PATIENT, STEP_PRESCRIPTION, STEP_SETTINGS, STEP_SHARE, STEP_SIGNATURE, STEP_VACCINE, TOTAL_STEPS } from "../firstSteps/FirstSteps";
import { addEvent } from "../../utils";

const Tour = (props) => {

    const {
        user,
        clinic,
        onboarding,
        location: { pathname },
        history
    } = props

    const reactour = useRef(null)

    const [ showTour, setShowTour ] = useState(false)
    const [ tourStep, setTourStep ] = useState([])

    const [ medicalRecordsOnboarding, setMedicalRecordsOnboarding ] = useState(onboarding.object)
    const [ hasOwner, setHasOwner ] = useState(onboarding.hasOwner)
    const [ hasPatient, setHasPatient ] = useState(onboarding.hasPatient)
    const [ hasAppointment, setHasAppointment ] = useState(onboarding.hasAppointment)
    const [ hasVaccine, setHasVaccine ] = useState(onboarding.hasVaccine)
    const [ hasPrescription, setHasPrescription ] = useState(onboarding.hasPrescription)
    const [ prescription, setPrescription ] = useState(onboarding.lastPrescription)
    const [ afterTotalData, setAfterTotalData ] = useState(false)

	const getOnboardingStep = () => {
        if (user.object) {
            setAfterTotalData(false)

            const queryOnboarding = new Parse.Query("MedicalRecordsOnboarding");
            queryOnboarding.equalTo("user", user.object);
            queryOnboarding.first()
            .then(onboarding => {
                if (onboarding) {
                    setMedicalRecordsOnboarding(onboarding)
                } else {
                    let newOnboarding = new Parse.Object("MedicalRecordsOnboarding")
                    newOnboarding.set("user", user.object)
                    newOnboarding.set("hasSetupClinicPrescription", false)
                    newOnboarding.set("hasCreatedOwner", false)
                    newOnboarding.set("hasCreatedPatient", false)
                    newOnboarding.set("hasCreatedAppointment", false)
                    newOnboarding.set("hasCreatedVaccine", false)
                    newOnboarding.set("hasCreatedPrescription", false)
                    newOnboarding.set("hasKnowMoreAboutDigitalSignature", false)
                    newOnboarding.set("hasCreatedPrescriptionShare", false)
                    newOnboarding.set("hasCompleted", false)
                    newOnboarding.save()
                    .then(onboarding => {
                        if (onboarding) {
                            setMedicalRecordsOnboarding(onboarding)
                        }
                    })
                    .catch(error => {
                        console.error(error)
                        newOnboarding.revert()
                    })
                }
            })
            .catch(error => {
                console.error(error)
            })
        }
	}

    const ownerIsNotDeleted = new Parse.Query("Owner");
    ownerIsNotDeleted.equalTo("clinic", clinic.object);
    ownerIsNotDeleted.equalTo("isDeleted", false)
    
    const patientIsNotDeleted = new Parse.Query("Patient");
    patientIsNotDeleted.equalTo("isDeleted", false)
    patientIsNotDeleted.equalTo("clinic", clinic.object)

    const getTotalData = () => {
        const queryOwner = ownerIsNotDeleted
        queryOwner.count()
        .then(totalOwner => {
            if (totalOwner) {
                setHasOwner(1)

                const queryPatient = patientIsNotDeleted
                queryPatient.descending("createdAt")
                queryPatient.limit(1)
                queryPatient.first()
                .then(patient => {
                    if (patient) {
                        setHasPatient(1)

                        let findDocuments = []
                        findDocuments.push(
                            new Promise((resolve, reject) => {
                                const queryAppointment = new Parse.Query("Appointment");
                                queryAppointment.equalTo("isDeleted", false)
                                queryAppointment.equalTo("clinic", clinic.object)
                                queryAppointment.equalTo("patient", patient)
                                queryAppointment.count()
                                .then(totalAppointment => {
                                    setHasAppointment(totalAppointment ? 1 : 0)
                                    return resolve()
                                })
                                .catch(error => {
                                    console.error(error)
                                    return resolve()
                                })
                            })
                        )
                        findDocuments.push(
                            new Promise((resolve, reject) => {
                                const queryVaccine = new Parse.Query("Vaccine");
                                queryVaccine.equalTo("isDeleted", false)
                                queryVaccine.equalTo("clinic", clinic.object)
                                queryVaccine.equalTo("patient", patient)
                                queryVaccine.count()
                                .then(totalVaccine => {
                                    setHasVaccine(totalVaccine ? 1 : 0)
                                    return resolve()
                                })
                                .catch(error => {
                                    console.error(error)
                                    return resolve()
                                })
                            })
                        )
                        findDocuments.push(
                            getLastPrescription(patient)
                        )

                        Promise.all(findDocuments).then(_ => {
                            setAfterTotalData(true)
                        })
                    } else {
                        setHasPatient(0)
                        setHasAppointment(0)
                        setHasVaccine(0)
                        setHasPrescription(0)
                        setPrescription(null)
                        setAfterTotalData(true)
                    }
                })
                .catch(error => {
                    console.error(error)
                })
            } else {
                setHasOwner(0)
                setHasPatient(0)
                setHasAppointment(0)
                setHasVaccine(0)
                setHasPrescription(0)
                setPrescription(null)
                setAfterTotalData(true)
            }
        })
        .catch(error => {
            console.error(error)
        })
    }

    const getLastPrescription = (patient) => {
        return new Promise((resolve, reject) => {
            const queryLastPrescription = new Parse.Query("Prescription");
            queryLastPrescription.equalTo("isDeleted", false)
            queryLastPrescription.equalTo("clinic", clinic.object)
            if (patient) {
                queryLastPrescription.equalTo("patient", patient)
            }
            queryLastPrescription.descending("createdAt")
            queryLastPrescription.limit(1)
            queryLastPrescription.include("patient")
            queryLastPrescription.include("patient.owner")
            queryLastPrescription.first()
            .then(prescription => {
                setHasPrescription(prescription ? 1 : 0)
                setPrescription(prescription || null)
                return resolve()
            })
            .catch(error => {
                console.error(error)
                return resolve()
            })
        })
    }

    const customizeMask = (options) => {
        const elMask = document.querySelector("#mask-main rect:nth-child(2)")
        const elMain = document.getElementsByClassName("reactour__mask--disable-interaction")[0]
        if (elMask && elMain) {
            Object.keys(options).map(attribute => {
                elMask.style[attribute] = options[attribute]
                elMain.style[attribute] = options[attribute]
            })
        }
    }

    const startTourFromStep = (stepIndex) => {
        const setStyleForElement = id => {
            const element = document.getElementById(id)
            const elementRect = element.getBoundingClientRect()
            const tabsScroller = document.getElementsByClassName("MuiTabs-scroller")?.[0]
            const tabsScrollerRect = tabsScroller.getBoundingClientRect()
            const hasScroll = tabsScroller.scrollWidth > tabsScroller.offsetWidth
            var scrollButtonAdjust = 0
            if (hasScroll) {
                tabsScroller.scrollTo(elementRect.left - tabsScrollerRect.left, 0)
                scrollButtonAdjust = 40
            }

            const newElementRect = element.getBoundingClientRect()

            const tourTabElementPosition = document.getElementById('tour-tab-element-position')
            tourTabElementPosition.style.position = 'fixed'
            tourTabElementPosition.style.top = newElementRect.top + 'px'
            tourTabElementPosition.style.left = (newElementRect.left + scrollButtonAdjust) + 'px'
            tourTabElementPosition.style.width = newElementRect.width + 'px'
            tourTabElementPosition.style.height = newElementRect.height + 'px'
        }

        let step
        if (stepIndex == STEP_OWNER) {
            const onOwnerMenuClick = () => {
                history.push('/tutor')
                setShowTour(false)
                const showOwnerPage = (e) => {
                    setShowTour(true)
                    reactour.current && reactour.current.nextStep()
                    document.removeEventListener("TourOwner__showOwnerPage", showOwnerPage)
                }
                document.addEventListener("TourOwner__showOwnerPage", showOwnerPage)
            }
            const onClickNewOwnerButton = () => {
                history.push('/tutor/form/novo')
                setShowTour(false)
                reactour.current && reactour.current.gotoStep(0)
            }
            step = [
                {
                    selector: "#menu-owner-link",
                    position: "right",
                    content: (
                        <div className="tour tour-right">
                            Você está aqui
                            <button className="tour-action" onClick={() => onOwnerMenuClick()}>
                                Ok, entendi
                            </button>
                        </div>
                    ),
                    action: (node) => {
                        const el = document.getElementsByClassName("reactour__mask--disable-interaction")[0]
                        if(!el){
                            return
                        }
                        el.style.cursor = "pointer"
                        el.onclick = () => onOwnerMenuClick()
                    }
                },
                {
                    selector: "#new-owner-link",
                    position: "left",
                    content: (
                        <div className={`tour tour-left`}>
                            Cadastre um tutor
                        </div>
                    ),
                    action: (node) => {
                        const el = document.getElementsByClassName("reactour__mask--disable-interaction")[0]
                        if(!el){
                            return
                        }
                        el.style.cursor = "pointer"
                        el.onclick = () => onClickNewOwnerButton()
                    }
                }
            ]
        } else if (stepIndex == STEP_PATIENT) {
            let ownerId = ""
            const onOwnerMenuClick = () => {
                history.push('/tutor')
                setShowTour(false)
                const showFirstOwnerToSelect = (e) => {
                    ownerId = e.detail.ownerId
                    setShowTour(true)
                    reactour.current && reactour.current.nextStep()
                    document.removeEventListener("TourPatient__showFirstOwnerToSelect", showFirstOwnerToSelect)
                }
                document.addEventListener("TourPatient__showFirstOwnerToSelect", showFirstOwnerToSelect)
            }
            const onSelectOwnerToView = () => {
                history.push(`/tutor/${ownerId}`)
                setShowTour(false)
                const viewOwner = (e) => {
                    setShowTour(true)
                    reactour.current && reactour.current.nextStep()
                    document.removeEventListener("TourPatient__viewOwner", viewOwner)
                }
                document.addEventListener("TourPatient__viewOwner", viewOwner)
            }
            const onCreateNewPatientClick = () => {
                history.push(`/tutor/${ownerId}/animal/novo`)
                setShowTour(false)
                reactour.current && reactour.current.gotoStep(0)
            }
            step = [
                {
                    selector: "#menu-owner-link",
                    position: "right",
                    content: (
                        <div className="tour tour-right">
                            Você está aqui
                            <button className="tour-action" onClick={() => onOwnerMenuClick()}>
                                Ok, entendi
                            </button>
                        </div>
                    ),
                    action: (node) => {
                        const el = document.getElementsByClassName("reactour__mask--disable-interaction")[0]
                        if(!el){
                            return
                        }
                        el.style.cursor = "pointer"
                        el.onclick = () => onOwnerMenuClick()
                    }
                },
                {
                    selector: "#card-owner, #box-owner",
                    position: "top",
                    content: (
                        <div className="tour tour-top">
                            Selecione um tutor
                        </div>
                    ),
                    action: (node) => {
                        const el = document.getElementsByClassName("reactour__mask--disable-interaction")[0]
                        if(!el){
                            return
                        }
                        el.style.cursor = "pointer"
                        el.onclick = () => onSelectOwnerToView()
                        customizeMask({
                            stroke: "var(--vsprontuario-primary-color)",
                            rx: 5
                        })
                    }
                },
                {
                    selector: "#new-patient-link",
                    position: "bottom",
                    content: (
                        <div className="tour tour-bottom">
                            Cadastre um animal
                        </div>
                    ),
                    action: (node) => {
                        const el = document.getElementsByClassName("reactour__mask--disable-interaction")[0]
                        if(!el){
                            return
                        }
                        el.style.cursor = "pointer"
                        el.onclick = () => onCreateNewPatientClick()
                        customizeMask({
                            stroke: "var(--vsprontuario-primary-color)",
                            rx: 5
                        })
                    }
                }
            ]
        } else if (stepIndex == STEP_APPOINTMENT) {
            let patientId = ""
            const onPatientMenuClick = () => {
                history.push('/animal')
                setShowTour(false)
                const showFirstPatientToSelect = (e) => {
                    patientId = e.detail.patientId
                    setShowTour(true)
                    reactour.current && reactour.current.nextStep()
                    document.removeEventListener("TourAppointment__showFirstPatientToSelect", showFirstPatientToSelect)
                }
                document.addEventListener("TourAppointment__showFirstPatientToSelect", showFirstPatientToSelect)
            }
            const onSelectPatientToView = () => {
                history.push(`/animal/${patientId}`)
                setShowTour(false)
                const viewPatient = (e) => {
                    setShowTour(true)
                    setStyleForElement('new-appointment-link')
                    reactour.current && reactour.current.nextStep()
                    document.removeEventListener("TourAppointment__viewPatient", viewPatient)
                }
                document.addEventListener("TourAppointment__viewPatient", viewPatient)
            }
            const onClickAppointmentTab = () => {
                history.push(`/animal/${patientId}/consulta`)
                setShowTour(false)
                const appointmentTabLoad = (e) => {
                    setShowTour(true)
                    reactour.current && reactour.current.nextStep()
                    document.removeEventListener("TourAppointment__appointmentTabLoad", appointmentTabLoad)
                }
                document.addEventListener("TourAppointment__appointmentTabLoad", appointmentTabLoad)
            }
            const onCreateNewAppointmentClick = () => {
                history.push(`/animal/${patientId}/consulta/novo`)
                setShowTour(false)
                reactour.current && reactour.current.gotoStep(0)
            }
            step = [
                {
                    selector: "#menu-patient-link",
                    position: "right",
                    content: (
                        <div className="tour tour-right">
                            Você está aqui
                            <button className="tour-action" onClick={() => onPatientMenuClick()}>
                                Ok, entendi
                            </button>
                        </div>
                    ),
                    action: (node) => {
                        const el = document.getElementsByClassName("reactour__mask--disable-interaction")[0]
                        if(!el){
                            return
                        }
                        el.style.cursor = "pointer"
                        el.onclick = () => onPatientMenuClick()
                    }
                },
                {
                    selector: "#box-patient",
                    position: "top",
                    content: (
                        <div className="tour tour-top">
                            Selecione um animal
                        </div>
                    ),
                    action: (node) => {
                        const el = document.getElementsByClassName("reactour__mask--disable-interaction")[0]
                        if(!el){
                            return
                        }
                        el.style.cursor = "pointer"
                        el.onclick = () => onSelectPatientToView()
                        customizeMask({
                            stroke: "var(--vsprontuario-primary-color)",
                            rx: 5
                        })
                    }
                },
                {
                    selector: "#tour-tab-element-position",
                    position: "top",
                    content: (
                        <div className="tour tour-top">
                            Clique em consultas
                        </div>
                    ),
                    action: (node) => {
                        const el = document.getElementsByClassName("reactour__mask--disable-interaction")[0]
                        if(!el){
                            return
                        }

                        el.style.cursor = "pointer"
                        el.onclick = () => onClickAppointmentTab()
                        customizeMask({
                            stroke: "var(--vsprontuario-primary-color)",
                            rx: 5
                        })
                    }
                },
                {
                    selector: hasAppointment == 0 ? "#new-appointment-button" : "#add-appointment-button",
                    position: "top",
                    content: (
                        <div className="tour tour-top">
                            Adicione uma consulta
                        </div>
                    ),
                    action: (node) => {
                        const el = document.getElementsByClassName("reactour__mask--disable-interaction")[0]
                        if(!el){
                            return
                        }
                        el.style.cursor = "pointer"
                        el.onclick = () => onCreateNewAppointmentClick()
                    }
                }
            ]
        } else if (stepIndex == STEP_VACCINE) {
            let patientId = ""
            const onPatientMenuClick = () => {
                history.push('/animal')
                setShowTour(false)
                const showFirstPatientToSelect = (e) => {
                    patientId = e.detail.patientId
                    setShowTour(true)
                    reactour.current && reactour.current.nextStep()
                    document.removeEventListener("TourVaccine__showFirstPatientToSelect", showFirstPatientToSelect)
                }
                document.addEventListener("TourVaccine__showFirstPatientToSelect", showFirstPatientToSelect)
            }
            const onSelectPatientToView = () => {
                history.push(`/animal/${patientId}`)
                setShowTour(false)
                const viewPatient = (e) => {
                    setShowTour(true)
                    setStyleForElement('new-vaccine-link')
                    reactour.current && reactour.current.nextStep()
                    document.removeEventListener("TourVaccine__viewPatient", viewPatient)
                }
                document.addEventListener("TourVaccine__viewPatient", viewPatient)
            }
            const onClickVaccineTab = () => {
                history.push(`/animal/${patientId}/vacina`)
                setShowTour(false)
                const vaccineTabLoad = (e) => {
                    setShowTour(true)
                    reactour.current && reactour.current.nextStep()
                    document.removeEventListener("TourVaccine__vaccineTabLoad", vaccineTabLoad)
                }
                document.addEventListener("TourVaccine__vaccineTabLoad", vaccineTabLoad)
            }
            const onCreateNewVaccineClick = () => {
                history.push(`/animal/${patientId}/vacina/novo`)
                setShowTour(false)
                reactour.current && reactour.current.gotoStep(0)
            }
            step = [
                {
                    selector: "#menu-patient-link",
                    position: "right",
                    content: (
                        <div className="tour tour-right">
                            Você está aqui
                            <button className="tour-action" onClick={() => onPatientMenuClick()}>
                                Ok, entendi
                            </button>
                        </div>
                    ),
                    action: (node) => {
                        const el = document.getElementsByClassName("reactour__mask--disable-interaction")[0]
                        if(!el){
                            return
                        }
                        el.style.cursor = "pointer"
                        el.onclick = () => onPatientMenuClick()
                    }
                },
                {
                    selector: "#box-patient",
                    position: "top",
                    content: (
                        <div className="tour tour-top">
                            Selecione um animal
                        </div>
                    ),
                    action: (node) => {
                        const el = document.getElementsByClassName("reactour__mask--disable-interaction")[0]
                        if(!el){
                            return
                        }
                        el.style.cursor = "pointer"
                        el.onclick = () => onSelectPatientToView()
                        customizeMask({
                            stroke: "var(--vsprontuario-primary-color)",
                            rx: 5
                        })
                    }
                },
                {
                    selector: "#tour-tab-element-position",
                    position: "top",
                    content: (
                        <div className="tour tour-top">
                            Clique em vacinas
                        </div>
                    ),
                    action: (node) => {
                        const el = document.getElementsByClassName("reactour__mask--disable-interaction")[0]
                        if(!el){
                            return
                        }
                        el.style.cursor = "pointer"
                        el.onclick = () => onClickVaccineTab()
                        customizeMask({
                            stroke: "var(--vsprontuario-primary-color)",
                            rx: 5
                        })
                    }
                },
                {
                    selector: hasVaccine == 0 ? "#new-vaccine-button" : "#add-vaccine-button",
                    position: "top",
                    content: (
                        <div className="tour tour-top">
                            Adicione uma vacina
                        </div>
                    ),
                    action: (node) => {
                        const el = document.getElementsByClassName("reactour__mask--disable-interaction")[0]
                        if(!el){
                            return
                        }
                        el.style.cursor = "pointer"
                        el.onclick = () => onCreateNewVaccineClick()
                    }
                }
            ]
        } else if (stepIndex == STEP_SETTINGS) {
            const onSettingsMenuClick = () => {
                history.push('/configuracao?tab')
                setShowTour(false)
                const showSettingsPage = (e) => {
                    setShowTour(true)
                    reactour.current && reactour.current.nextStep()
                    document.removeEventListener("TourSettings__showSettingsPage", showSettingsPage)
                }
                document.addEventListener("TourSettings__showSettingsPage", showSettingsPage)
            }
            const onClickSettingsButton = () => {
                history.push('/clinica')
                setShowTour(false)
                reactour.current && reactour.current.gotoStep(0)
            }
            step = [
                {
                    selector: "#menu-settings-link",
                    position: "right",
                    content: (
                        <div className="tour tour-right">
                            Você está aqui
                            <button className="tour-action" onClick={() => onSettingsMenuClick()}>
                                Ok, entendi
                            </button>
                        </div>
                    ),
                    action: (node) => {
                        const el = document.getElementsByClassName("reactour__mask--disable-interaction")[0]
                        if(!el){
                            return
                        }
                        el.style.cursor = "pointer"
                        el.onclick = () => onSettingsMenuClick()
                    }
                },
                {
                    selector: "#settings-button",
                    position: "bottom",
                    content: (
                        <div className="tour tour-bottom">
                            Configure seu receituário
                        </div>
                    ),
                    action: (node) => {
                        const el = document.getElementsByClassName("reactour__mask--disable-interaction")[0]
                        if(!el){
                            return
                        }
                        el.style.cursor = "pointer"
                        el.onclick = () => onClickSettingsButton()
                        customizeMask({
                            rx: 20
                        })
                    }
                }
            ]
        } else if (stepIndex == STEP_PRESCRIPTION) {
            let patientId = ""
            const onPatientMenuClick = () => {
                history.push('/animal')
                setShowTour(false)
                const showFirstPatientToSelect = (e) => {
                    patientId = e.detail.patientId
                    setShowTour(true)
                    reactour.current && reactour.current.nextStep()
                    document.removeEventListener("TourPrescription__showFirstPatientToSelect", showFirstPatientToSelect)
                }
                document.addEventListener("TourPrescription__showFirstPatientToSelect", showFirstPatientToSelect)
            }
            const onSelectPatientToView = () => {
                history.push(`/animal/${patientId}`)
                setShowTour(false)
                const viewPatient = (e) => {
                    setShowTour(true)
                    setStyleForElement('new-prescription-link')
                    reactour.current && reactour.current.nextStep()
                    document.removeEventListener("TourPrescription__viewPatient", viewPatient)
                }
                document.addEventListener("TourPrescription__viewPatient", viewPatient)
            }
            const onClickPrescriptionTab = () => {
                history.push(`/animal/${patientId}/prescricao`)
                setShowTour(false)
                const prescriptionTabLoad = (e) => {
                    setShowTour(true)
                    reactour.current && reactour.current.nextStep()
                    document.removeEventListener("TourPrescription__prescriptionTabLoad", prescriptionTabLoad)
                }
                document.addEventListener("TourPrescription__prescriptionTabLoad", prescriptionTabLoad)
            }
            const onCreateNewPrescriptionClick = () => {
                history.push(`/prescricao/${patientId}/novo`)
                setShowTour(false)
                reactour.current && reactour.current.gotoStep(0)
            }
            step = [
                {
                    selector: "#menu-patient-link",
                    position: "right",
                    content: (
                        <div className="tour tour-right">
                            Você está aqui
                            <button className="tour-action" onClick={() => onPatientMenuClick()}>
                                Ok, entendi
                            </button>
                        </div>
                    ),
                    action: (node) => {
                        const el = document.getElementsByClassName("reactour__mask--disable-interaction")[0]
                        if(!el){
                            return
                        }
                        el.style.cursor = "pointer"
                        el.onclick = () => onPatientMenuClick()
                    }
                },
                {
                    selector: "#box-patient",
                    position: "top",
                    content: (
                        <div className="tour tour-top">
                            Selecione um animal
                        </div>
                    ),
                    action: (node) => {
                        const el = document.getElementsByClassName("reactour__mask--disable-interaction")[0]
                        if(!el){
                            return
                        }
                        el.style.cursor = "pointer"
                        el.onclick = () => onSelectPatientToView()
                        customizeMask({
                            stroke: "var(--vsprontuario-primary-color)",
                            rx: 5
                        })
                    }
                },
                {
                    selector: "#tour-tab-element-position",
                    position: "top",
                    content: (
                        <div className="tour tour-top">
                            Clique em prescrições
                        </div>
                    ),
                    action: (node) => {
                        const el = document.getElementsByClassName("reactour__mask--disable-interaction")[0]
                        if(!el){
                            return
                        }
                        el.style.cursor = "pointer"
                        el.onclick = () => onClickPrescriptionTab()
                        customizeMask({
                            stroke: "var(--vsprontuario-primary-color)",
                            rx: 5
                        })
                    }
                },
                {
                    selector: hasPrescription == 0 ? "#new-prescription-button" : "#add-prescription-button",
                    position: "top",
                    content: (
                        <div className="tour tour-top">
                            Adicione uma prescrição
                        </div>
                    ),
                    action: (node) => {
                        const el = document.getElementsByClassName("reactour__mask--disable-interaction")[0]
                        if(!el){
                            return
                        }
                        el.style.cursor = "pointer"
                        el.onclick = () => onCreateNewPrescriptionClick()
                    }
                }
            ]
        }
        if (step) {
            setTourStep(step)
            setShowTour(true)
        }
    }

    const getCurrentStep = (onboardingObject) => {
        if (!onboardingObject.get("hasCreatedOwner")) {
            return STEP_OWNER
        } else if (!onboardingObject.get("hasCreatedPatient")) {
            return STEP_PATIENT
        } else if (!onboardingObject.get("hasCreatedAppointment")) {
            return STEP_APPOINTMENT
        } else if (!onboardingObject.get("hasCreatedVaccine")) {
            return STEP_VACCINE
        } else if (!onboardingObject.get("hasSetupClinicPrescription")) {
            return STEP_SETTINGS
        } else if (!onboardingObject.get("hasCreatedPrescription")) {
            return STEP_PRESCRIPTION
        } else if (!onboardingObject.get("hasKnowMoreAboutDigitalSignature")) {
            return STEP_SIGNATURE
        } else if (!onboardingObject.get("hasCreatedPrescriptionShare")) {
            return STEP_SHARE
        } else {
            return 0
        }
    }

    const getTotalFinishedSteps = (onboardingObject) => {
        return  1 + // crie uma clínica
                (onboardingObject.get("hasCreatedOwner") ? 1 : 0) + 
                (onboardingObject.get("hasCreatedPatient") ? 1 : 0) + 
                (onboardingObject.get("hasCreatedAppointment") ? 1 : 0) + 
                (onboardingObject.get("hasCreatedVaccine") ? 1 : 0) + 
                (onboardingObject.get("hasSetupClinicPrescription") || !clinic.isOwner ? 1 : 0) + 
                (onboardingObject.get("hasCreatedPrescription") ? 1 : 0) + 
                (onboardingObject.get("hasKnowMoreAboutDigitalSignature") ? 1 : 0) + 
                (onboardingObject.get("hasCreatedPrescriptionShare") ? 1 : 0)        
    }

    const saveOnboardingStore = (_onboarding) => {
        const currentStep = getCurrentStep(_onboarding)
        const totalFinishedSteps = getTotalFinishedSteps(_onboarding)
        const onboardingStore = {
            object: _onboarding,
            hasCreatedOwner: _onboarding.get("hasCreatedOwner"),
            hasCreatedPatient: _onboarding.get("hasCreatedPatient"),
            hasCreatedAppointment: _onboarding.get("hasCreatedAppointment"),
            hasCreatedVaccine: _onboarding.get("hasCreatedVaccine"),
            hasSetupClinicPrescription: _onboarding.get("hasSetupClinicPrescription"),
            hasCreatedPrescription: _onboarding.get("hasCreatedPrescription"),
            hasKnowMoreAboutDigitalSignature: _onboarding.get("hasKnowMoreAboutDigitalSignature"),
            hasCreatedPrescriptionShare: _onboarding.get("hasCreatedPrescriptionShare"),
            hasCompleted: _onboarding.get("hasCompleted"),
            hasOwner: hasOwner,
            hasPatient: hasPatient,
            hasAppointment: hasAppointment,
            hasVaccine: hasVaccine,
            hasPrescription: hasPrescription,
            lastPrescription: prescription,
            currentStep: currentStep,
            progressStep: !currentStep ? 100 : (totalFinishedSteps == 0 ? 1 : (Math.floor(100 / TOTAL_STEPS * totalFinishedSteps)))
        }

        props.dispatch(getOnboarding(onboardingStore))
    }

    const onboardingForFirstClinic = () => {
        const onboardingForFirstClinicEnded = () => {
            setShowTour(false)
            reactour.current && reactour.current.gotoStep(0)
        }
        setTourStep([
            {
                selector: "#clinic-view",
                position: "right",
                content: (
                    <div className="tour tour-right">
                        Crie ou troque de clínica aqui
                        <button className="tour-action" onClick={() => onboardingForFirstClinicEnded()}>
                            Ok, entendi
                        </button>
                    </div>
                ),
                action: (node) => {
                    const el = document.getElementsByClassName("reactour__mask--disable-interaction")[0]
                    if(!el){
                        return
                    }
                    if (node) {
                        el.style.cursor = "pointer"
                        el.onclick = () => onboardingForFirstClinicEnded()
                        customizeMask({
                            stroke: "var(--vsprontuario-primary-color)"
                        })
                    } else {
                        el.style.display = "none"
                        onboardingForFirstClinicEnded()
                    }
                }
            }
        ])
        setShowTour(true)
    }

    useEffect(() => {
        if (afterTotalData) {
            saveOnboardingStore(medicalRecordsOnboarding)
        }
    }, [ afterTotalData ])

    useEffect(() => {
        if (medicalRecordsOnboarding) {
            getTotalData()
        }
    }, [ medicalRecordsOnboarding ])

    useEffect(() => {
        if (clinic && clinic.object && clinic.isLoaded) {
            props.dispatch(getOnboarding(parseState.onboarding))
            getOnboardingStep()
        }
    }, [ clinic ])

    useEffect(() => {
        if (onboarding && onboarding.object && !onboarding.hasCompleted) {
            const Tour__startTour = (e) => {
                startTourFromStep(e.detail.step)
            }
            const TourOwner__registerYourFirstOwner = (e) => {
                setAfterTotalData(false)
                setHasOwner(1)
                const onboardingObj = onboarding.object
                onboardingObj.set("hasCreatedOwner", true)
                onboardingObj.save()
                .then(newOnboarding => {
                    setMedicalRecordsOnboarding(newOnboarding)
                    setAfterTotalData(true)
                })
                .catch(error => {
                    console.error(error)
                    onboardingObj.revert()
                })
                document.removeEventListener("TourOwner__registerYourFirstOwner", TourOwner__registerYourFirstOwner)
            }
            const TourPatient__registerYourFirstPatient = (e) => {
                setAfterTotalData(false)
                setHasPatient(1)
                const onboardingObj = onboarding.object
                onboardingObj.set("hasCreatedPatient", true)
                onboardingObj.save()
                .then(newOnboarding => {
                    setMedicalRecordsOnboarding(newOnboarding)
                    setAfterTotalData(true)
                })
                .catch(error => {
                    console.error(error)
                    onboardingObj.revert()
                })
                document.removeEventListener("TourPatient__registerYourFirstPatient", TourPatient__registerYourFirstPatient)
            }
            const TourAppointment__registerYourFirstAppointment = (e) => {
                setAfterTotalData(false)
                setHasAppointment(1)
                const onboardingObj = onboarding.object
                onboardingObj.set("hasCreatedAppointment", true)
                onboardingObj.save()
                .then(newOnboarding => {
                    setMedicalRecordsOnboarding(newOnboarding)
                    setAfterTotalData(true)
                })
                .catch(error => {
                    console.error(error)
                    onboardingObj.revert()
                })
                document.removeEventListener("TourAppointment__registerYourFirstAppointment", TourAppointment__registerYourFirstAppointment)
            }
            const TourVaccine__registerYourFirstVaccine = (e) => {
                setAfterTotalData(false)
                setHasVaccine(1)
                const onboardingObj = onboarding.object
                onboardingObj.set("hasCreatedVaccine", true)
                onboardingObj.save()
                .then(newOnboarding => {
                    setMedicalRecordsOnboarding(newOnboarding)
                    setAfterTotalData(true)
                })
                .catch(error => {
                    console.error(error)
                    onboardingObj.revert()
                })
                document.removeEventListener("TourVaccine__registerYourFirstVaccine", TourVaccine__registerYourFirstVaccine)
            }
            const TourSettings__onChangeSettings = (e) => {
                setAfterTotalData(false)
                const onboardingObj = onboarding.object
                onboardingObj.set("hasSetupClinicPrescription", true)
                onboardingObj.save()
                .then(newOnboarding => {
                    setMedicalRecordsOnboarding(newOnboarding)
                    setAfterTotalData(true)
                })
                .catch(error => {
                    console.error(error)
                    onboardingObj.revert()
                })
                document.removeEventListener("TourSettings__onChangeSettings", TourSettings__onChangeSettings)
            }
            const TourPrescription__registerYourFirstPrescription = (e) => {
                if (e.detail.patient) {
                    var patient = {
                        __type: 'Pointer',
                        className: 'Patient',
                        objectId: e.detail.patient
                    }
                    setAfterTotalData(false)
                    getLastPrescription(patient)
                    .then(_ => {
                        const onboardingObj = onboarding.object
                        onboardingObj.set("hasCreatedPrescription", true)
                        onboardingObj.save()
                        .then(newOnboarding => {
                            setMedicalRecordsOnboarding(newOnboarding)
                            setAfterTotalData(true)
                        })
                        .catch(error => {
                            console.error(error)
                            onboardingObj.revert()
                        })
                        document.removeEventListener("TourPrescription__registerYourFirstPrescription", TourPrescription__registerYourFirstPrescription)
                    })
                }
            }
            const TourDigitalSignature__knowMore = (e) => {
                const onboardingObj = onboarding.object
                onboardingObj.set("hasKnowMoreAboutDigitalSignature", true)
                onboardingObj.save()
                .then(newOnboarding => {
                    setMedicalRecordsOnboarding(newOnboarding)
                    setAfterTotalData(true)
                })
                .catch(error => {
                    console.error(error)
                    onboardingObj.revert()
                })
                document.removeEventListener("TourDigitalSignature__knowMore", TourDigitalSignature__knowMore)
            }
            const TourShare__sendYourFirstDocumentByWhatsapp = (e) => {
                setAfterTotalData(false)
                const onboardingObj = onboarding.object
                onboardingObj.set("hasCreatedPrescriptionShare", true)
                onboardingObj.save()
                .then(newOnboarding => {
                    setMedicalRecordsOnboarding(newOnboarding)
                    setAfterTotalData(true)
                })
                .catch(error => {
                    console.error(error)
                    onboardingObj.revert()
                })
                document.removeEventListener("TourShare__sendYourFirstDocumentByWhatsapp", TourShare__sendYourFirstDocumentByWhatsapp)
            }
            const Tour__hasCompleted = (e) => {
                setAfterTotalData(false)
                const onboardingObj = onboarding.object
                onboardingObj.set("hasCompleted", true)
                onboardingObj.save()
                .then(newOnboarding => {
                    setMedicalRecordsOnboarding(newOnboarding)
                    setAfterTotalData(true)
                })
                .catch(error => {
                    console.error(error)
                    onboardingObj.revert()
                })
                document.removeEventListener("Tour__hasCompleted", Tour__hasCompleted)
            }
    
            document.addEventListener("Tour__startTour", Tour__startTour)
            document.addEventListener("TourOwner__registerYourFirstOwner", TourOwner__registerYourFirstOwner)
            document.addEventListener("TourPatient__registerYourFirstPatient", TourPatient__registerYourFirstPatient)
            document.addEventListener("TourAppointment__registerYourFirstAppointment", TourAppointment__registerYourFirstAppointment)
            document.addEventListener("TourVaccine__registerYourFirstVaccine", TourVaccine__registerYourFirstVaccine)
            document.addEventListener("TourSettings__onChangeSettings", TourSettings__onChangeSettings)
            document.addEventListener("TourPrescription__registerYourFirstPrescription", TourPrescription__registerYourFirstPrescription)
            document.addEventListener("TourDigitalSignature__knowMore", TourDigitalSignature__knowMore)
            document.addEventListener("TourShare__sendYourFirstDocumentByWhatsapp", TourShare__sendYourFirstDocumentByWhatsapp)
            document.addEventListener("Tour__hasCompleted", Tour__hasCompleted)
            document.addEventListener("Tour__onboardingForFirstClinic", onboardingForFirstClinic)
            return () => {
                document.removeEventListener("Tour__startTour", Tour__startTour)
                document.removeEventListener("TourOwner__registerYourFirstOwner", TourOwner__registerYourFirstOwner)
                document.removeEventListener("TourPatient__registerYourFirstPatient", TourPatient__registerYourFirstPatient)
                document.removeEventListener("TourAppointment__registerYourFirstAppointment", TourAppointment__registerYourFirstAppointment)
                document.removeEventListener("TourVaccine__registerYourFirstVaccine", TourVaccine__registerYourFirstVaccine)
                document.removeEventListener("TourSettings__onChangeSettings", TourSettings__onChangeSettings)
                document.removeEventListener("TourPrescription__registerYourFirstPrescription", TourPrescription__registerYourFirstPrescription)
                document.removeEventListener("TourDigitalSignature__knowMore", TourDigitalSignature__knowMore)
                document.removeEventListener("TourShare__sendYourFirstDocumentByWhatsapp", TourShare__sendYourFirstDocumentByWhatsapp)
                document.removeEventListener("Tour__hasCompleted", Tour__hasCompleted)
                document.removeEventListener("Tour__onboardingForFirstClinic", onboardingForFirstClinic)
            }
        }
    }, [ onboarding ])

    useEffect(() => {
        // MOBILE FIX NOTE: https://github.com/elrumordelaluz/reactour/issues/252
        if(showTour){
            // document.documentElement.style.overflowX = 'inherit'
            document.documentElement.style.scrollBehavior = 'inherit'
        } else {
            // document.documentElement.style.overflowX = 'hidden'
            document.documentElement.style.scrollBehavior = 'smooth'
        }

    }, [ showTour ])

    return (
        <Reactour
            ref={reactour}
            steps={tourStep}
            isOpen={showTour}
            showNavigation={false}
            showCloseButton={false}
            showButtons={false}
            showNumber={false}
            onRequestClose={() => {
                setShowTour(false)
                reactour.current && reactour.current.gotoStep(0)
            }}
            onAfterOpen={_ => (document.body.style.overflowY = 'hidden')}
            onBeforeClose={_ => (document.body.style.overflowY = 'auto')}
            update={pathname}
            disableInteraction={true}
            maskSpace={3}
            className="reactour"
        />
    );
}

const mapStateToProps = state => {
    return {
		user: state.user,
		clinic: state.clinic,
		onboarding: state.onboarding
    };
};

export default connect(mapStateToProps)(withRouter(Tour));