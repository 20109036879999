import React, { useEffect, useState } from "react"
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import PropTypes from 'prop-types';

import { ReactComponent as IconArrowLeft } from "../../assets/icon-arrow-left.svg"

const VSAccordionSimple = (props) => {

    const {
        header,
        children,
        backgroundColor,
        color,
        hideIcon,
        onOpen,
        onClose,
        onChange,
        className
    } = props

    const [ expanded, setExpanded ] = useState(props.expanded !== undefined ? props.expanded : false);

    useEffect(() => {
        if (props.expanded !== undefined) {
            setExpanded(props.expanded)
        }
    }, [ props.expanded ])

    return (
        <div className={`vs-accordion-simple ${className || ""}`}>
            <Accordion
                expanded={expanded}
                onChange={() => {
                    if (!expanded) {
                        onOpen && onOpen()
                    } else {
                        onClose && onClose()
                    }
                    onChange && onChange(!expanded)
                    setExpanded(!expanded)
                }}
            >
                <AccordionSummary
                    expandIcon={hideIcon ? null :
                        <IconArrowLeft
                            width="8px"
                            height="12px"
                            fill={color}
                            style={{ transform: 'rotate(270deg)' }}
                        />
                    }
                    style={{
                        fontWeight: 600,
                        backgroundColor: backgroundColor,
                        color: color
                    }}
                >
                    { header(expanded) }
                </AccordionSummary>
                <AccordionDetails>
                    { children }
                </AccordionDetails>
            </Accordion>
        </div>
    )
}

VSAccordionSimple.propTypes = {
    expanded: PropTypes.bool,
    header: PropTypes.func,
    children: PropTypes.node,
    backgroundColor: PropTypes.string,
    color: PropTypes.string,
    onOpen: PropTypes.func,
    onClose: PropTypes.func
};

VSAccordionSimple.defaultProps = {
    // expanded: false, // isso quebra o "expandir tudo" do VSFilter
    header: (_) => null,
    children: null,
    backgroundColor: "transparent",
    color: "var(--vsprontuario-secondary-color)",
    onOpen: () => null,
    onClose: () => null
}

export default VSAccordionSimple