import { TextField } from "@mui/material"

const ShotsField = (props) => {

    const {
        currentShot,
        setCurrentShot,
        totalShots,
        setTotalShots,
        errorTotalShots,
        helperTotalShots
    } = props

    return (
        <div className="row align-items-start">
            <div className="col-6">
                <div className="row">
                    <div className="col-12">
                        <div className="input-subtitle mb-1">
                            Dose atual
                        </div>
                    </div>
                    <div className="col-12">
                        <TextField
                            placeholder="0"
                            type="tel"
                            value={currentShot}
                            onChange={({ target: { value } }) => setCurrentShot(parseInt(value) || undefined)}
                            helperText={<span>Campo Opcional</span>}
                            size="small"
                            variant="outlined"
                            fullWidth
                        />
                    </div>
                </div>
            </div>
            <div className="col-6">
                <div className="row">
                    <div className="col-12">
                        <div className="input-subtitle mb-1">
                            Total de doses
                        </div>
                    </div>
                    <div className="col-12">
                        <TextField
                            placeholder="0"
                            type="tel"
                            value={totalShots}
                            onChange={({ target: { value } }) => setTotalShots(parseInt(value) || undefined)}
                            error={errorTotalShots}
                            helperText={helperTotalShots}
                            size="small"
                            variant="outlined"
                            fullWidth
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ShotsField