import axios from "axios"
import VSModal from "../../../../components/vsModal/VSModal"
import { APP_DEVICE, APP_NAME, APP_VERSION } from "../../../../constants"
import { dayjs } from "../../../../utils"

export const deleteVaccine = (vaccine, callback) => {
    Swal.fire({
        showConfirmButton: false,
        customClass: {
            popup: 'p-0',
            content: 'p-0',
        },
        html: (
            <VSModal
                title={`Tem certeza que deseja excluir esta ${vaccine.get('isReminder') ? 'programação de vacina' : 'aplicação de vacina'}?`}
                text={`Todo o cadastro da ${vaccine.get('isReminder') ? 'programação de vacina' : 'aplicação de vacina'} será excluído. Os dados não poderão ser recuperados e esta operação não poderá ser desfeita.`}
                options={[
                    {
                        type: "cancel",
                        text: "Cancelar Operação",
                        action: () => {
                            Swal.close()
                        }
                    },
                    {
                        type: "confirm",
                        text: "Excluir Vacinação",
                        action: () => {
                            return Parse.Cloud.run("saveVaccineForPatient", {
                                data: {
                                    objectId: vaccine.id,
                                    isDeleted: true
                                },
                                clinicId: vaccine.get('clinic').id,
                                patientId: vaccine.get('patient').id,
                                appVersionName: APP_VERSION,
                                deviceType: APP_DEVICE,
                                appContentType: APP_NAME
                            })
                            .then(_ => {
                                Swal.fire(
                                    'Excluído!',
                                    `A ${vaccine.get('isReminder') ? 'programação de vacina' : 'aplicação de vacina'} foi excluída com sucesso.`,
                                    'success'
                                )
                                .then(_ => {
                                    callback && callback()
                                })
                            })
                            .catch(error => {
                                console.error(error)
                                Swal.fire(
                                    'Desculpe',
                                    `Ocorreu algum erro ao tentar excluir a ${vaccine.get('isReminder') ? 'programação de vacina' : 'aplicação de vacina'}`,
                                    'error'
                                )
                            })
                        }
                    }
                ]}
            />
        )
    })
}

// deprecated
export const deleteReminder = (vaccine, callback) => {
    Swal.fire({
        showConfirmButton: false,
        customClass: {
            popup: 'p-0',
            content: 'p-0',
        },
        html: (
            <VSModal
                title="Tem certeza que deseja excluir esta lembrete de vacinação?"
                text="O lembrete da vacinação será excluído. Os dados não poderão ser recuperados e esta operação não poderá ser desfeita."
                options={[
                    {
                        type: "cancel",
                        text: "Cancelar Operação",
                        action: () => {
                            Swal.close()
                        }
                    },
                    {
                        type: "confirm",
                        text: "Excluir Lembrete",
                        action: () => {
                            vaccine.set("isDeleted", true)
                            vaccine.set("changedAt", new Date())
                            vaccine.set("changedBy", Parse.User.current())
                            vaccine.set("changedApp", APP_NAME)
                            vaccine.set("changedDevice", APP_DEVICE)
                            vaccine.set("changedAppVersion", APP_VERSION)
                            vaccine.save()
                            .then(_ => {
                                Swal.fire(
                                    'Excluído!',
                                    'O lembrete foi excluído com sucesso.',
                                    'success'
                                )
                                .then(_ => {
                                    callback && callback()
                                })
                            })
                            .catch(error => {
                                console.error(error)
                                Swal.fire(
                                    'Desculpe',
                                    'Ocorreu algum erro ao tentar excluir o lembrete',
                                    'error'
                                )
                                vaccine.revert()
                            })
                        }
                    }
                ]}
            />
        )
    })
}

// deprecated
export const cancelVaccine = (vaccine, callback) => {
    Swal.fire({
        showConfirmButton: false,
        customClass: {
            popup: 'p-0',
            content: 'p-0',
        },
        html: (
            <VSModal
                title="Tem certeza que deseja DEsfazer a vacina aplicada?"
                text="Ao desfazer a vacina aplicada, ela voltará a ser um lembrete de vacina."
                options={[
                    {
                        type: "cancel",
                        text: "Cancelar Operação",
                        action: () => {
                            Swal.close()
                        }
                    },
                    {
                        type: "confirm",
                        text: "Excluir Vacinação",
                        action: () => {
                            vaccine.set("isReminder", true)
                            vaccine.set("changedAt", new Date())
                            vaccine.set("changedBy", Parse.User.current())
                            vaccine.set("changedApp", APP_NAME)
                            vaccine.set("changedDevice", APP_DEVICE)
                            vaccine.set("changedAppVersion", APP_VERSION)
                            vaccine.save()
                            .then(_ => {
                                Swal.fire(
                                    'Excluído!',
                                    'A vacinação foi cancelada com sucesso, voltou a ser um lembrete.',
                                    'success'
                                )
                                .then(_ => {
                                    callback && callback()
                                })
                            })
                            .catch(error => {
                                console.error(error)
                                Swal.fire(
                                    'Desculpe',
                                    'Ocorreu algum erro ao tentar desfazer a vacinação',
                                    'error'
                                )
                                vaccine.revert()
                            })
                        }
                    }
                ]}
            />
        )
    })
}

// deprecated
export const cancelAutomaticReminder = (automaticReminders, callback) => {
    Swal.fire({
        showConfirmButton: false,
        customClass: {
            popup: 'p-0',
            content: 'p-0',
        },
        html: (
            <VSModal
                title="Tem certeza que deseja desativar o lembrete automático?"
                text="Ao desativar o Lembrete Automático, o tutor não receberá o aviso da próxima vacina."
                options={[
                    {
                        type: "cancel",
                        text: "Cancelar Operação",
                        action: () => {
                            Swal.close()
                        }
                    },
                    {
                        type: "confirm",
                        text: "Excluir Lembrete",
                        action: () => {
                            let promises = []
                            automaticReminders.map(automaticReminder => {
                                promises.push(
                                    new Promise((resolve, reject) => {
                                        axios.delete(`${process.env.REACT_APP_API_V2}/schedule/${automaticReminder.objectId}`)
                                        .then(_ => resolve())
                                        .catch(error => {
                                            console.error(error)
                                            reject(error)
                                        })
                                    })
                                )
                            })
        
                            Promise.all(promises)
                            .then(_ => {
                                Swal.fire(
                                    'Desativado!',
                                    'O lembrete automático foi desativado com sucesso',
                                    'success'
                                )
                                .then(_ => {
                                    callback && callback()
                                })
                            })
                            .catch(error => {
                                console.error(error)
                                Swal.fire(
                                    'Desculpe',
                                    'Ocorreu algum erro ao tentar desativar o lembrete automático',
                                    'error'
                                )
                            })
                        }
                    }
                ]}
            />
        )
    })
}

export const getOrCreateAttachment = (vaccine) => {
    const attachment = vaccine.get("attachment")
    if (!attachment || attachment.get("isDeleted")) {
        const patient = vaccine.get("patient")
        const clinic  = patient.get("clinic")
        let newAttachment = new Parse.Object("Attachment")
        newAttachment.setACL(clinic.getACL())
        newAttachment.set("clinic", clinic)
        newAttachment.set("createdBy", Parse.User.current())
        newAttachment.set("createdApp", APP_NAME)
        newAttachment.set("createdDevice", APP_DEVICE)
        newAttachment.set("createdAppVersion", APP_VERSION)
        newAttachment.set("isDeleted", false)
        newAttachment.set("patient", patient)
        return newAttachment
    }
    return attachment
}

export const getOrCreateReminder = (vaccine, clinic) => {
    return new Promise((resolve, reject) => {
        if (vaccine.id) {
            var query = new Parse.Query("Vaccine");
            query.equalTo("isDeleted", false);
            query.equalTo("isReminder", true);
            query.equalTo("parentVaccine", vaccine)
            query.equalTo("clinic", {
                __type: "Pointer",
                className: "Clinic",
                objectId: clinic.id || clinic.objectId
            })
            query.include("messages")
            query.first()
            .then(_parentVaccine => {
                if (_parentVaccine) {
                    resolve(_parentVaccine)
                } else {
                    let newReminder = createVaccine(vaccine.get("patient"))
                    newReminder.set("isReminder", true)
                    resolve(newReminder)
                }
            }).catch(error => {
                console.error(error)
                reject('Ocorreu algum erro ao tentar buscar a vacina programada')
            })
        } else {
            let newReminder = createVaccine(vaccine.get("patient"))
            newReminder.set("isReminder", true)
            resolve(newReminder)
        }
    })
}

export const getOrCreateAutomaticReminders = (reminder) => {
    const clinic  = reminder.get("clinic")
    const patient = reminder.get("patient")
    const owner   = patient.get("owner")

    const defaultReminder = {
        "user": Parse.User.current().id,
        "clinic": clinic.id,
        "owner": owner.id,
        "patient": patient.id,
        "createdFromClass": "Vaccine",
        "createdFromId": reminder.id,
        "scheduledAt": null,
    }

    const defaultSmsReminder = Object.assign(
        { ...defaultReminder },
        {
            "messageTemplateId": "SMS_VACCINE_REMINDER",
            "messageTypeId": "SMS",
            "sentTo": "phoneOwner"
        }
    )

    const defaultWhatsappReminder = Object.assign(
        { ...defaultReminder },
        {
            "messageTemplateId": "WHATSAPP_VACCINE_REMINDER",
            "messageTypeId": "WHATSAPP",
            "sentTo": "phoneOwner"
        }
    )

    const defaultEmailReminder = Object.assign(
        { ...defaultReminder },
        {
            "messageTemplateId": "EMAIL_VACCINE_REMINDER",
            "messageTypeId": "EMAIL",
            "sentTo": "emailOwner"
        }
    )

    return new Promise((resolve, reject) => {
        const params = new URLSearchParams({
            createdFromClass: "Vaccine",
            createdFromId: reminder.id,
            patient: reminder.get("patient").id
        }).toString()
        axios.get(`${process.env.REACT_APP_API_V2}/schedule?${params}`)
        .then(({ data }) => {
            if (data.count) {
                const reminders = data.data
                let smsReminder = reminders.filter(r => r.messageTemplateId == "SMS_VACCINE_REMINDER" && r.messageTypeId == "SMS")[0]
                if (smsReminder) {
                    smsReminder.scheduledAt = dayjs(smsReminder.scheduledAt.date).format("DD/MM/YYYY")
                }
                let whatsappReminder = reminders.filter(r => r.messageTemplateId == "WHATSAPP_VACCINE_REMINDER" && r.messageTypeId == "WHATSAPP")[0]
                if (whatsappReminder) {
                    whatsappReminder.scheduledAt = dayjs(whatsappReminder.scheduledAt.date).format("DD/MM/YYYY")
                }
                let emailReminder = reminders.filter(r => r.messageTemplateId == "EMAIL_VACCINE_REMINDER" && r.messageTypeId == "EMAIL")[0]
                if (emailReminder) {
                    emailReminder.scheduledAt = dayjs(emailReminder.scheduledAt.date).format("DD/MM/YYYY")
                }
                resolve({
                    smsReminder: smsReminder || defaultSmsReminder,
                    whatsappReminder: whatsappReminder || defaultWhatsappReminder,
                    emailReminder: emailReminder || defaultEmailReminder
                })
            } else {
                resolve({
                    smsReminder: defaultSmsReminder,
                    whatsappReminder: defaultWhatsappReminder,
                    emailReminder: defaultEmailReminder
                })
            }
        })
        .catch(error => {
            if (error.response.status !== 404) {
                console.error(error)
            }
            resolve({
                smsReminder: defaultSmsReminder,
                whatsappReminder: defaultWhatsappReminder,
                emailReminder: defaultEmailReminder
            })
        })
    })
}

export const getVaccine = (vaccineId, patient, withParents = false) => {
    return new Promise((resolve, reject) => {
        var query = new Parse.Query("Vaccine");
        query.equalTo("clinic", patient.get("clinic"));
        query.equalTo("isDeleted", false);
        query.equalTo("patient", patient)
        query.equalTo("objectId", vaccineId)
        query.include("attachment")
        query.include("patient")
        query.include('patient.owner')
        query.include("messages")
        query.include('createdBy')
        if(withParents){
            query.include('parentVaccine')
            query.include('parentVaccine.messages')
            query.include('parentVaccine.attachment')
            query.include('parentVaccine.patient')
            query.include('parentVaccine.patient.owner')

            query.include('parentVaccine.parentVaccine')
            query.include('parentVaccine.parentVaccine.messages')
            query.include('parentVaccine.parentVaccine.attachment')
            query.include('parentVaccine.parentVaccine.patient')
            query.include('parentVaccine.parentVaccine.patient.owner')

            query.include('parentVaccine.parentVaccine.parentVaccine')
            query.include('parentVaccine.parentVaccine.parentVaccine.messages')
            query.include('parentVaccine.parentVaccine.parentVaccine.attachment')
            query.include('parentVaccine.parentVaccine.parentVaccine.patient')
            query.include('parentVaccine.parentVaccine.parentVaccine.patient.owner')

            query.include('parentVaccine.parentVaccine.parentVaccine.parentVaccine')
            query.include('parentVaccine.parentVaccine.parentVaccine.parentVaccine.messages')
            query.include('parentVaccine.parentVaccine.parentVaccine.parentVaccine.attachment')
            query.include('parentVaccine.parentVaccine.parentVaccine.parentVaccine.patient')
            query.include('parentVaccine.parentVaccine.parentVaccine.parentVaccine.patient.owner')
        }
        query.first()
        .then(_vaccine => {
            if (_vaccine) {
                resolve(_vaccine)
            } else {
                resolve(null)
            }
        }).catch(error => {
            console.error(error)
            reject('Ocorreu algum erro ao tentar buscar a vacinação')
        })
    })
}

export const getReminder = (vaccine, patient) => {
    return new Promise((resolve, reject) => {
        var query = new Parse.Query("Vaccine");
        query.equalTo("clinic", patient.get("clinic"));
        query.equalTo("isDeleted", false);
        query.equalTo("isReminder", true)
        query.equalTo("patient", patient)
        query.equalTo("parentVaccine", vaccine)
        query.ascending("documentDate")
        query.first()
        .then(_reminder => {
            if (_reminder) {
                resolve(_reminder)
            } else {
                resolve()
            }
        }).catch(error => {
            console.error(error)
            reject('Ocorreu algum erro ao tentar buscar o lembrete de vacina')
        })
    })
}

export const createVaccine = (patient) => {
    const clinic = patient.get("clinic")
    let newVaccine = new Parse.Object("Vaccine")
    newVaccine.setACL(clinic.getACL())
    newVaccine.set("clinic", clinic)
    newVaccine.set("createdBy", Parse.User.current())
    newVaccine.set("createdApp", APP_NAME)
    newVaccine.set("createdDevice", APP_DEVICE)
    newVaccine.set("createdAppVersion", APP_VERSION)
    newVaccine.set("isDeleted", false)
    newVaccine.set("patient", patient)
    return newVaccine
}

export const showVaccine = (vaccine, withPrefix = true) => {
    let vaccineText = vaccine.get("vaccine") || vaccine.get("vaccineType") || "Vacina"
    if (withPrefix && !vaccineText.toUpperCase().startsWith("VACINA")) {
        vaccineText = "Vacina " + vaccineText;
    }
    return vaccineText
}

export const isReminderLate = (vaccine) => {
    return dayjs(vaccine.get("documentDate")).isBefore(dayjs().startOf('day'))
}

export const getType = (vaccine) => {
    // if (vaccine.get("isReminder")) {
    //     return "Lembrete de Vacina"
    // }
    return "Vacina"
}

export const vaccineTypeOptions = [
    "Antirrábica",
    "Antitetânica",
    "Dermatofitose",
    "Específica",
    "FeLV",
    "Giárdia",
    "Leishmaniose Visceral",
    "Leptospirose",
    "Múltipla",
    "Tosse dos Canis",
    "V3",
    "V4",
    "V5",
    "V6",
    "V8",
    "V10",
    "V11",
    "V12"
]

let products = null
export const getVaccineProducts = () => {
    return new Promise((resolve, reject) => {
        if (products) {
            return resolve(products)
        } else {
            axios.get(`${process.env.REACT_APP_API_V2}/vaccines`)
            .then(({ data }) => {
                products = data && data.length > 0 ? data : []
                return resolve(products)
            })
            .catch(error => {
                console.error(error)
                return resolve([])
            })
        }
    })
}

export const getVaccineProtocols = (clinic) => {
    const query = new Parse.Query("MRProtocolVaccine")
    query.equalTo("clinic", clinic);
    query.equalTo("isDeleted", false);
    query.equalTo("active", true);
    query.ascending("name");
    query.addAscending("objectId")
    query.limit(10000000)
    return query.find()
}

export const defaultProtocolVaccine = {
    id: null,
    name: "",
    nameError: false,
    nameHelper: <span>Campo <b>Obrigatório</b></span>,
    vaccines: [],
}

export const defaultVaccine = (patient) => ({
    documentDate: dayjs().toDate(),
    isReminder: '0',
    errorDocumentDate: false,
    helperDocumentDate: <span>Campo <b>Obrigatório</b></span>,
    companyName: "",
    manufacturingBatchPart: "",
    manufacturingDate: null,
    manufacturingDateText: "",
    errorManufacturingDate: false,
    helperManufacturingDate: <span>Campo Opcional</span>,
    expirationDate: null,
    expirationDateText: "",
    errorExpirationDate: false,
    helperExpirationDate: <span>Campo Opcional</span>,
    attachments: [],
    vaccine: "",
    vaccineId: "",
    notes: "",
    isNextShot: false,
    sendAutomaticReminderIsActive: false,
    object: createVaccine(patient)
})
