import React, { useEffect, useState } from "react"

import { SwipeableDrawer } from "@mui/material"

import { ReactComponent as IconArrowLeft } from "../../assets/icon-arrow-left.svg"

import { useWindowSize } from "../../utils"

import VSPage from "../vsPage/VSPage"
import VSDrawer from "../vsDrawer/VSDrawer"
import Blocked from "../../pages/blocked/Blocked"

import "./VSShare.scss"
const VSShare = (props) => {

    const {
        tag,
        title,
        subtitle,
        loading,
        summary,
        preview,
        message,
        close,
        onClose,
        blocked
    } = props

    const {
        width
     } = useWindowSize()

    const [ openPreview, setOpenPreview ] = useState(false)
    const [ openMessage, setOpenMessage ] = useState(false)

    useEffect(() => {
        if (width >= 768) {
            if (openPreview)
                setOpenPreview(false)
            if (openMessage)
                setOpenMessage(false)
        }
    }, [ width ])

    return (
        <div id="vsshare">
            <VSPage
                loading={loading}
                title={title}
                subtitle={subtitle}
                cancel={close}
                onCancel={() => onClose()}
            >
                { tag }
                <div className="vsshare-body">
                    { blocked ? (
                        <Blocked />
                    ) : (
                        <div className="row g-3 gx-3 gx-lg-5">
                            <div className="col">
                                <div className="vsshare-summary">
                                    { summary }
                                </div>
                                <div className="vsshare-preview d-none d-md-block">
                                    { preview }
                                </div>
                            </div>
                            <div className="col-auto d-none d-md-block">
                                <div className="vsshare-message">
                                    { message }
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className={`vsshare-footer d-block d-md-none ${ blocked ? "disabled" : "" }`}>
                    <div className="row g-3 align-items-center">
                        <div className="col text-center" onClick={() => !blocked && setOpenPreview(true)}>
                            <div className="vsshare-footer-preview">
                                Ver prévia
                            </div>
                        </div>
                        <div className="col-auto">
                            <div className="vsshare-footer-action" onClick={() => !blocked && setOpenMessage(true)}>
                                Emitir documento
                            </div>
                        </div>
                    </div>
                    <VSDrawer
                        open={openMessage}
                        width="100%"
                        title="Emissão de Documento"
                        id="vsdrawer-share"
                        onClose={() => setOpenMessage(false)}
                        VSDrawerFooterProps={{
                            show: false
                        }}
                    >
                        <div className="vsshare-message">
                            { message }
                        </div>
                    </VSDrawer>
                    <SwipeableDrawer
                        anchor="bottom"
                        open={openPreview}
                        className="vsdrawer-bottomsheet"
                        onOpen={() => null}
                        onClose={() => setOpenPreview(false)}
                    >
                        <div className="vsdrawer-info-header" onClick={() => setOpenPreview(false)}>
                            <div className="row g-3 align-items-center">
                                <div className="col-auto pe-0">
                                    <IconArrowLeft width={18} height={18} />
                                </div>
                                <div className="col">
                                    Voltar
                                </div>
                            </div>
                        </div>
                        <div className="vsdrawer-info-body">
                            <div className="vsshare-preview">
                                { preview }
                            </div>
                        </div>
                    </SwipeableDrawer>
                </div>
            </VSPage>
        </div>
    )
}

export default VSShare