import React from 'react'
import { connect } from 'react-redux'

import { ReactComponent as IconEdit } from "../../../../assets/icon-primary-edit.svg"
import { ReactComponent as IconTrash } from "../../../../assets/icon-primary-trash.svg"
import { ReactComponent as IconAppointment } from '../../../../assets/icon-patient-appointment.svg'
import { ReactComponent as IconGeneratedDiffDiagnosis } from '../../../../assets/icon-generated-diff-diagnosis.svg'

import { addEvent, dayjs, getSelectedOptionLabel, showRegisteredBy } from '../../../../utils'
import { ACL } from '../../../../constants'

import VSAccordionSimple from '../../../../components/vsAccordion/VSAccordionSimple'
import VSMedicalRecordsAudit from '../../../../components/vsMedicalRecordsAudit/VSMedicalRecordsAudit'
import { appointmentTypeOptions, CONSULTA_PADRAO, CONSULTA_DETALHADA, deleteAppointment } from './Appointment'

import './AppointmentView.scss'
const BaseAppointmentView = (props) => {

    const {
        object,
        history,
        noDocumentDate,
        noAudit,
        permissions,
        viewActions,
        expanded
    } = props

    const patient = object.get("patient")
    const isDeleted = object.get("isDeleted")

    return (
        <div id="patient-appointment-view" className={`vsbox mb-3 ${isDeleted ? "vsbox-disabled" : ""}`}>
            <div className="vsbox-body pb-0">
                <div className="row align-items-center g-3 mb-3">
                    {!noDocumentDate && (
                        <div className="col-auto pe-0">
                            <div className="vsbox-chip">
                                {dayjs(object.get("documentDate")).format("DD/MM/YYYY")}
                            </div>
                        </div>
                    )}
                    <div className="col-12 col-md">
                        <div className="row align-items-center g-3">
                            <div className="col-auto">
                                <IconAppointment width="24" height="24" fill="var(--vsprontuario-gray-color)" />
                            </div>
                            <div className="col ps-0">
                                <label className="vsbox-info">Tipo de Registro</label>
                                <div className="vsbox-info-text">
                                    {getSelectedOptionLabel(null, appointmentTypeOptions, object.get("appointmentType") ? object.get("appointmentType") : CONSULTA_PADRAO, "long")}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-4 col-md-auto">
                        <label className="vsbox-info">É Retorno?</label>
                        <div className="vsbox-info-text">
                            {object.get("return") ? "Sim" : "Não"}
                        </div>
                    </div>
                    {viewActions}
                </div>
                {showRegisteredBy(object, object.get("differentialDiagnosis") && object.get("differentialDiagnosis").length > 0 && (
                    <div className="col-auto mb-3 d-inline-flex align-items-center justify-content-center">
                        <IconGeneratedDiffDiagnosis 
                            className="me-2"
                            fill="var(--vsprontuario-primary-color)"
                            width={16} 
                            height={16} 
                        />
                        Diagnóstico diferencial gerado
                    </div>
                ))}
                {!isDeleted && (
                    <>
                        <div className="vsbox-separator"></div>
                        <div className="row">
                            <div className="col-12 vsbox--info">
                                <VSAccordionSimple
                                    expanded={expanded !== undefined ? expanded : true}
                                    header={(expanded) => (
                                        `Ver ${expanded ? "menos" : "mais"} informações`
                                    )}
                                >
                                    <div className="row">
                                        <div className="col vsbox-title">
                                            Motivo / Anamnese
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col mb-3 pre-line">
                                            {object.get("anamnesis") || "Não informado"}
                                        </div>
                                    </div>
                                    {object.get("appointmentType") == CONSULTA_DETALHADA && (
                                        <>
                                            <div className="row">
                                                <div className="col vsbox-title">
                                                    Exame Físico
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col mb-3 pre-line">
                                                    {object.get("physicalExam") || "Não informado"}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col vsbox-title">
                                                    Diagnóstico
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col mb-3 pre-line">
                                                    {object.get("diagnosis") || "Não informado"}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col vsbox-title">
                                                    Tratamento
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col mb-3 pre-line">
                                                    {object.get("treatment") || "Não informado"}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col vsbox-title">
                                                    Próximos Passos
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col mb-3 pre-line">
                                                    {object.get("nextStep") || "Não informado"}
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    <div className="row">
                                        <div className="col vsbox-title">
                                            Observações Internas
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col mb-3 pre-line">
                                            {object.get("notes") || "Não informado"}
                                        </div>
                                    </div>
                                </VSAccordionSimple>
                            </div>
                        </div>
                    </>
                )}
                {!noAudit && (
                    <VSMedicalRecordsAudit parseObject={object} />
                )}
            </div>
        </div>
    )
}

const AppointmentView = (props) => {

    const {
        object,
        history,
        noDocumentDate,
        noAudit,
        permissions,
        viewActions
    } = props

    const patient = object.get("patient")
    const isDeleted = object.get("isDeleted")

    return (
        <BaseAppointmentView
            {...props}
            viewActions={
                !isDeleted && (
                    <div className="col-12 col-md-auto">
                        <div className="row g-3 align-items-center justify-content-center">
                            {permissions?.check(ACL.APPOINTMENT, ACL.Rule.EDIT, object) && (
                                <div className="col col-md-auto">
                                    <button
                                        className={`vsbox-btn ${isDeleted ? "vsbox-btn-disabled" : "vsbox-btn-dark"}`}
                                        onClick={() => history.push(`/animal/${patient.id}/consulta/${object.id}`)}
                                        disabled={isDeleted}
                                    >
                                        <IconEdit width="24" height="24" fill={isDeleted ? "var(--vsprontuario-gray-color)" : "var(--vsprontuario-primary-color-dark)"} />
                                    </button>
                                </div>
                            )}
                            {permissions?.check(ACL.APPOINTMENT, ACL.Rule.DELETE, object) && (
                                <div className="col col-md-auto">
                                    <button
                                        className={`vsbox-btn ${isDeleted ? "vsbox-btn-disabled" : "vsbox-btn-alert"}`}
                                        onClick={() => deleteAppointment(object, () => addEvent(["Appointment__getList", "History__getList"], {}))}
                                        disabled={isDeleted}
                                    >
                                        <IconTrash width="24" height="24" fill={isDeleted ? "var(--vsprontuario-gray-color)" : "var(--vsprontuario-primary-color)"} />
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                )
            }
        />
    )
}

const _AIAppointmentView = (props) => {

    const {
        object,
        history,
        noDocumentDate,
        noAudit,
        permissions,
        viewActions,
        onClick
    } = props

    const patient = object.get("patient")
    const isDeleted = object.get("isDeleted")

    return (
        <BaseAppointmentView
            {...props}
            viewActions={
                !isDeleted && (
                    <div className="col-12 col-md-auto">
                        <div className="row g-3 align-items-center justify-content-center">
                            {permissions?.check(ACL.APPOINTMENT, ACL.Rule.EDIT, object) && (
                                <div className="col col-md-auto">
                                    <button
                                        className={`vsbox-btn ${isDeleted ? "vsbox-btn-disabled" : "vsbox-btn-primary"}`}
                                        onClick={onClick ? onClick : () => history.push(`/animal/${patient.id}/consulta/${object.id}`)}
                                        disabled={isDeleted}
                                    >
                                        SELECIONAR CONSULTA
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                )
            }
        />
    )
}

const mapStateToProps = state => {
    return {
        permissions: state.permissions
    };
};

export const AIAppointmentView = connect(mapStateToProps)(_AIAppointmentView)
export default connect(mapStateToProps)(AppointmentView)