import React from 'react';

import "./CardDashboardClinicInfo.scss"
import { ReactComponent as IconPrimaryEdit } from "../../assets/icon-primary-edit.svg"
import { ReactComponent as IconClinic } from "../../assets/icon-store.svg"

const CardDashboardClinicInfo = (props) => {

    const {
        history,
        isLoading = true,
        isCurrentUserClinicOwner = false
    } = props

    function Image({ clinicImage }) {
        const hasImage = clinicImage;
        if (hasImage) {
            return <img crossOrigin="anonymous" src={props.clinicImage} className="img-thumbnail rounded clinicImage mx-auto p-0" />;
        }
        return <div className="icon-clinic">
            <div className="position-absolute top-50 start-50 translate-middle">
                <div className="circle position-absolute top-50 start-50 translate-middle"></div>
                <IconClinic fill="#ccc" width="65px" className="position-absolute top-50 start-50 translate-middle" />
            </div>
        </div>
    }
    function layoutLoading() {
        return <div className="shimmer">
            <div className="container-fluid">
                <div className="row d-flex justify-content-center">
                    <div className="col-auto">
                        <div className="clinicImageLoading animate"></div>
                    </div>
                </div>
            </div>
            <div className="container-fluid clinicNameLoading mt-3 animate">
            </div>
            <div className="container-fluid clinicCRMVLoading mt-2 mb-1 animate">
            </div>
        </div>
    }
    function layoutCard() {
        return <div className="animateFadeIn">
             <div className="container-fluid">
                <div className="d-flex justify-content-end">
                    
                    {isCurrentUserClinicOwner ? (
                    <div className="btnEdit" onClick={() => history.push(`/clinica`)}>
                        <IconPrimaryEdit fill="#ccc" />
                    </div>
                    ) : (
                        <div></div>
                    )}
                   
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col">
                        <Image clinicImage={props.clinicImage} />
                    </div>

                </div>
            </div>
            <div className="container-fluid clinicName pt-3">
                {props.clinicName}
            </div>
            <div className="container-fluid clinicCRMV">
                {props.clinicCRMV}
            </div>
        </div>
    }
    return (
        <div id="card-dashboard-clinic-info">

            {isLoading ? (
                layoutLoading()
            ) : (
                layoutCard()
            )}

           
        </div >
    )
}

export default CardDashboardClinicInfo;