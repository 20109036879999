import React from 'react'
import PropTypes from 'prop-types';

import './VSDrawerCancel.scss'

import VetCancelImage from "../../assets/vet-cancel.png"
import VSDrawerHeader from './VSDrawerHeader'
import VSDrawerFooter from './VSDrawerFooter';

const VSDrawerCancel = (props) => {

    const {
        title,
        text,
        confirm,
        onConfirm,
        cancel,
        onCancel,
        VSDrawerHeaderProps
    } = props

    return (
        <div id="vsdrawer-cancel">
            <VSDrawerHeader
                {...VSDrawerHeaderProps}
                onClose={() => onConfirm && onConfirm() }
            />
            <div className="vsdrawer-content row align-items-center">
                <div className="col text-center">
                    <img src={VetCancelImage} />
                    { title && (<h1>{ title }</h1>) }
                    { text && (<p>{ text }</p>) }
                </div>
            </div>
            <VSDrawerFooter>
                <div className="row">
                    { cancel && (
                        <div className="col pe-2">
                            <button
                                className="btn btn-save w-100"
                                onClick={() => onCancel && onCancel()}
                            >
                                { cancel }
                            </button>
                        </div>
                    )}
                    { confirm && (
                        <div className="col ps-2">
                            <button
                                className="btn btn-cancel w-100"
                                onClick={() => onConfirm && onConfirm()}
                            >
                                { confirm }
                            </button>
                        </div>
                    )}
                </div>
            </VSDrawerFooter>
        </div>
    )
}

VSDrawerCancel.propTypes = {
    title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node
    ]),
    text: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node
    ]),
    confirm: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node
    ]),
    cancel: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node
    ]),
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
};

VSDrawerCancel.defaultProps = {
    title: "Tem certeza?",
    text: "Os dados digitados não serão salvos e esta operação não poderá ser desfeita",
    confirm: "Cancelar",
    cancel: "Voltar para edição",
    onConfirm: () => null,
    onCancel: () => null
}

export default VSDrawerCancel;