import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/lab/Autocomplete';
import { CircularProgress } from '@mui/material';

import { ReactComponent as IconAdd } from "../../../../assets/icon-add.svg"

import { saveFilesOnParse, saveOnTracker, mandatoryField, optionalField, addEvent, dayjs } from '../../../../utils'
import { getPatient } from '../../Patient'
import { getExams } from '../exam/Exam';
import { ACL, APP_DEVICE, APP_NAME, APP_VERSION } from '../../../../constants';
import { attachmentTypeOptions, getAttachment } from './Attachment';

import DateInput from '../../../../components/dateInput/DateInput';
import VSDrawer from '../../../../components/vsDrawer/VSDrawer';
import VSFileUpload from '../../../../components/vsFileUpload/VSFileUpload';
import VSMedicalRecordsAudit from '../../../../components/vsMedicalRecordsAudit/VSMedicalRecordsAudit';
import VSError from '../../../../components/vsError/VSError';

import './AttachmentForm.scss'
const AttachmentForm = (props) => {

    const {
        id,
        id_anexo
    } = useParams()

    const {
        clinic,
        history,
        permissions
    } = props

    const update = id_anexo !== "novo"

    const [ loading, setLoading ] = useState(false)
    const [ error, setError ] = useState(null)
    const [ hasSubmited, setHasSubmited ] = useState(false)
    
    const [ patient, setPatient ] = useState(null)
    const [ attachment, setAttachment ] = useState(null)

    const [ attachmentType, setAttachmentType ] = useState("1")

    const [ descriptionOptions, setDescriptionOptions ] = useState([])
    const [ description, setDescription ] = useState('')
    const [ errorDescription, setErrorDescription ] = useState(false)
    const [ helperDescription, setHelperDescription ] = useState(mandatoryField)

    const defaultDate = dayjs()
    const [ documentDate, setDocumentDate ] = useState(defaultDate.toDate())
    const [ documentDateText, setDocumentDateText ] = useState(defaultDate.format("DD/MM/YYYY"))
    const [ errorDocumentDate, setErrorDocumentDate ] = useState(false)
    const [ helperDocumentDate, setHelperDocumentDate ] = useState(mandatoryField)
    const [ notes, setNotes ] = useState('')
    const [ attachments, setAttachments ] = useState([])

    const handleDocumentDate = (_date) => {
        setDocumentDateText(_date)

        if (_date.match(/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/g)) {
            let newDate = dayjs(_date, "DD/MM/YYYY")
            if (newDate.isValid()) {
                setDocumentDate(newDate.toDate())
                return
            }
        }
        setDocumentDate(null)
    }

    const validateOnTouch = () => {
        if (hasSubmited && (documentDate || documentDateText || attachmentType || description || notes)) {
            validate()
        }
    }

    const validate = () => {
        let isValid = true
        if (loading) {
            isValid = false
        }

        if (error?.message) {
            isValid = false
        }

        const _documentDate = dayjs(documentDateText, "DD/MM/YYYY")
        if (_documentDate.isValid()) {
            if (_documentDate.format("YYYY") < 1971) {
                isValid = false
                setErrorDocumentDate(true)
                setHelperDocumentDate("A data não pode ser menor que 1971")
            } else if (_documentDate.diff(dayjs(), "years") > 5) {
                isValid = false
                setErrorDocumentDate(true)
                setHelperDocumentDate("A data não pode exceder 5 anos")
            } else {
                setErrorDocumentDate(false)
                setHelperDocumentDate(mandatoryField)
            }
        } else {
            isValid = false
            setErrorDocumentDate(true)
            setHelperDocumentDate("A data informada não é válida")
        }

        if (attachmentType == "1" && !description) {
            isValid = false
            setErrorDescription(true)
            setHelperDescription("O exame é obrigatório")
        } else {
            setErrorDescription(false)
            if (attachmentType == "1") {
                setHelperDescription(mandatoryField)
            } else {
                setHelperDescription(optionalField)
            }
        }

        return isValid
    }

    const isValid = () => !loading && !errorDocumentDate && !errorDescription

    const fieldsWithErrorText = () => {
        let errors = []
        if (loading) {
            errors.push("Salvando o anexo")
        }
        if (error?.message) {
            errors.push(error.message)
        }
        if (errorDocumentDate) {
            errors.push("Data da consulta")
        }
        if (errorDescription) {
            errors.push("Exame")
        }
        if (!permissions?.check(ACL.ATTACHMENT, ACL.Rule.EDIT, attachment)) {
            errors.push("Acesso negado")
        }
        return errors
    }

    const getPatientOrError = () => {
        setLoading(true)
        setError(null)

        getPatient(id, clinic.object)
        .then(_patient => {
            setPatient(_patient)
        })
        .catch(error => {
            Swal.fire(
                'Desculpe',
                error,
                'error'
            )
            .then(_ => {
                setLoading(false)
                setError({
                    message: error,
                    function: "getPatientOrError"
                })
            })
        })
    }

    const getAttachmentOrError = () => {
        if (permissions?.check(ACL.ATTACHMENT, ACL.Rule.VIEW)) {
            saveOnTracker("Iniciou", "Anexo", update ? id_anexo : "")
            if (update) {
                setLoading(true)
                setError(null)
    
                getAttachment(id_anexo, patient)
                .then(_attachment => {
                    setAttachment(_attachment)
                    setDocumentDate(_attachment.get("documentDate"))
                    setDocumentDateText(dayjs(_attachment.get("documentDate")).format("DD/MM/YYYY"))
                    setAttachmentType(_attachment.get("attachmentType"))
                    setAttachments(_attachment.get("attachments"))
                    setDescription(_attachment.get("description"))
                    setNotes(_attachment.get("notes"))
                    setLoading(false)
                })
                .catch(error => {
                    Swal.fire(
                        'Desculpe',
                        error,
                        'error'
                    )
                    .then(_ => {
                        setLoading(false)
                        setError({
                            message: error,
                            function: "getAttachmentOrError"
                        })
                    })
                })
            } else {
                let newAttachment = new Parse.Object("Attachment")
                newAttachment.setACL(clinic.ACL)
                newAttachment.set("clinic", clinic.object)
                newAttachment.set("createdBy", Parse.User.current())
                newAttachment.set("createdApp", APP_NAME)
                newAttachment.set("createdDevice", APP_DEVICE)
                newAttachment.set("createdAppVersion", APP_VERSION)
                newAttachment.set("isDeleted", false)
                newAttachment.set("patient", patient)
                setAttachment(newAttachment)
                setLoading(false)
            }
        }
    }

    const save = () => {
        return new Promise((resolve, reject) => {
            setHasSubmited(true)
            if (validate()) {
                setLoading(true)
                saveFilesOnParse(attachments).then(_attachments => {
                    attachment.set('documentDate', documentDate);
                    attachment.set('attachmentType', attachmentType);
                    attachment.set('attachments', attachments);
                    attachment.set('description', description);
                    attachment.set("changedAt", new Date())
                    attachment.set("changedBy", Parse.User.current())
                    attachment.set("changedApp", APP_NAME)
                    attachment.set("changedDevice", APP_DEVICE)
                    attachment.set("changedAppVersion", APP_VERSION)
                    attachment.set('notes', notes);
                    attachment.save()
                    .then(_attachment => {
                        saveOnTracker("Concluiu", "Anexo", _attachment.id)
                        setLoading(false)
                        resolve(_attachment)
                    })
                    .catch(error => {
                        setLoading(false)
                        console.error(error)
                        Swal.fire(
                            'Desculpe',
                            `Ocorreu algum erro ao tentar ${attachment.id ? "editar" : "cadastrar"} o anexo do animal`,
                            'error'
                        )
                        update && attachment.revert()
                        reject()
                    })
                })
            } else {
                reject()
            }
        })
    }

    useEffect(() => {
        validateOnTouch()
    }, [ documentDate, documentDateText, attachmentType, description, notes ])

    useEffect(() => {
        if (attachment && !permissions?.check(ACL.ATTACHMENT, ACL.Rule.EDIT, attachment)) {
            permissions?.error()
        }
    }, [ attachment ])

    useEffect(() => {
        if (patient) {
            getExams()
            .then(_exams => {
                setDescriptionOptions(_exams.length > 0 ? _exams : [])
            })

            getAttachmentOrError()
        }
    }, [ patient ])
    
    useEffect(() => {
        if (id) {
            getPatientOrError()
        }
    }, [])

    const errors = fieldsWithErrorText()

    return (
        <VSDrawer
            id="attachment-form"
            width="60%"
            title={update ? "Editar Anexo" : "Novo Anexo"}
            cancel={update ? <span>Cancelar Alteração<span className="d-none d-md-inline"> no Anexo</span></span> : <span>Cancelar<span className="d-none d-md-inline"> Novo</span> Anexo</span>}
            submit={isValid ? "Salvar Anexo" : <span>Preencha os campos<span className="d-none d-md-inline"> necessários</span></span>}
            errors={errors} 
            onSubmit={save}
            onCancel={() => history.goBack()}
            onAfterSave={(object) => {
                history.replace(`/animal/${patient.id}/anexo`)
                addEvent("Attachment__getList", {})
            }}
            VSDrawerSuccessProps={{
                title: "Anexo salvo com Sucesso",
                text: ""
            }}
            VSDrawerCancelProps={{
                title: "Tem certeza que deseja cancelar o registro de anexo?",
                confirm: update ? "Cancelar Alteração no Anexo" : "Cancelar Novo Anexo",
                cancel: "Voltar para Edição"
            }}
        >
            { loading ? (
                <div className="row data-loading">
                    <div className="col">
                        <CircularProgress />
                    </div>
                </div>
            ) : error?.message ? (
                <VSError
                    message={error.message}
                    onClose={() => {
                        switch(error.function) {
                            case "getPatientOrError":
                                getPatientOrError()
                                break
                            case "getAttachmentOrError":
                                getAttachmentOrError()
                                break
                            default:
                        }
                    }}
                />
            ) : (
                <div className="row">
                    <div className="col">
                        <div className="row align-items-center mb-3">
                            <div className="col-auto">
                                <div className="input-title">
                                    Data do anexo
                                </div>
                            </div>
                            <div className="col-auto" style={{ width: '200px' }}>
                                <DateInput
                                    className="m-0"
                                    placeholder="00/00/0000"
                                    value={documentDateText || null}
                                    error={errorDocumentDate}
                                    helperText={null}
                                    onChange={(value) => handleDocumentDate(value)}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                />
                            </div>
                            { errorDocumentDate && (
                                <div className="col-12">
                                    <small className="text-danger">{ helperDocumentDate }</small>
                                </div>
                            )}
                        </div>
                        <div className="row align-items-center">
                            <div className="col-12 mb-2">
                                <div className="input-title">
                                    Tipo do Anexo (obrigatório)
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="row" style={{ padding: '0 7px' }}>
                                    { attachmentTypeOptions.map((type, index) => (
                                        <div className="col-4 col-md px-2 mb-3" key={index}>
                                            <button
                                                className={`btn-type ${ type.value == attachmentType ? "selected" : "" }`}
                                                onClick={() => setAttachmentType(type.value)}
                                            >
                                                <div className="row mb-1">
                                                    <div className="col-12">
                                                        { type.image }
                                                    </div>
                                                </div>
                                                <div className="row align-items-center text">
                                                    <div className="col-12">
                                                        { type.label }
                                                    </div>
                                                </div>
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        { attachmentType == "1" ? (
                            <div className="row mb-4">
                                <div className="col-12">
                                    <div className="input-title input-height">
                                        Nome do Exame (Obrigatório)
                                    </div>
                                </div>
                                <div className="col-12">
                                    <Autocomplete
                                        inputValue={description}
                                        options={descriptionOptions}
                                        getOptionLabel={(option) => option.examName || description}
                                        getOptionSelected={(option) => option.examName == description}
                                        onChange={(_, selected) => setDescription((selected && selected.examName) || "")}
                                        onInputChange={(event, selected) => event && setDescription(selected || "")}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                placeholder="Digite ou selecione o exame"
                                                error={errorDescription}
                                                helperText={null}
                                                size="small"
                                                variant="outlined"
                                                fullWidth
                                            />
                                        )}
                                        openOnFocus
                                        freeSolo
                                    />
                                </div>
                                { errorDescription && (
                                    <div className="col-12">
                                        <span className="text-danger">{ helperDescription }</span>
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div className="row mb-4">
                                <div className="col-12">
                                    <div className="input-title input-height">
                                        Nome do Anexo (opcional)
                                    </div>
                                </div>
                                <div className="col-12">
                                    <TextField
                                        value={description}
                                        placeholder="Dê um nome para o anexo"
                                        onChange={({ target: { value } }) => setDescription(value)}
                                        size="small"
                                        variant="outlined"
                                        fullWidth
                                        multiline
                                    />
                                </div>
                            </div>
                        )}
                        <div className="row">
                            <div className="col-12">
                                <div className="input-title input-height">
                                    Observações Internas (opcional)
                                </div>
                            </div>
                            <div className="col-12">
                                <TextField
                                    value={notes}
                                    placeholder="Escreva suas observações aqui"
                                    onChange={({ target: { value } }) => setNotes(value)}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                />
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="col input-info">
                                As observações são anotações particulares. <b>Elas nunca serão compartilhadas com o tutor</b>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-12">
                                <VSFileUpload
                                    onChange={(attachments) => setAttachments(attachments)}
                                    value={attachments}
                                >
                                    <IconAdd width="24" height="24" />
                                    Adicionar Arquivo do Anexo
                                </VSFileUpload>
                            </div>
                        </div>
                        <VSMedicalRecordsAudit parseObject={attachment} />
                    </div>
                </div>
            )}
        </VSDrawer>
    )
}

const mapStateToProps = state => {
    return {
        clinic: state.clinic,
        permissions: state.permissions
    };
};
  
export default connect(mapStateToProps)(AttachmentForm)