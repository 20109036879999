import React, { useState } from 'react'
import { connect } from 'react-redux';

import { CircularProgress } from '@mui/material';

import { ReactComponent as IconExclamationO } from "../../assets/icon-danger-exclamation-o.svg"
import { ReactComponent as IconPrimaryEdit } from "../../assets/icon-primary-edit.svg"

import { PRO_MONTHLY_VALUE_BY_MONTH, PRO_YEARLY, PRO_YEARLY_BY_YEAR, STATUS_CANCELED, STATUS_FUTURE, deleteSubscription, getCustomer, getStatus } from './Payment';
import { formattedAddress } from '../owner/Owner';
import { dayjs, formattedCurrency, redirectToCheckout } from '../../utils';

import VSAccordionSimple from '../../components/vsAccordion/VSAccordionSimple';
import SubscriptionBillsView from './SubscriptionBillsView';

import './SubscriptionView.scss'
const SubscriptionView = (props) => {

    const {
        subscription,
        history,
        clinic
    } = props

    const [ searched, setSearched ] = useState(false)
    const [ loading, setLoading ] = useState(false)
    const [ customer, setCustomer ] = useState(null)

    const getCustomerOrError = () => {
        setLoading(true)

        getCustomer(clinic.objectId)
        .then(customer => {
            setSearched(true)
            setCustomer(customer)
            setLoading(false)
        })
        .catch(error => {
            setLoading(false)
            Swal.fire(
                'Desculpe',
                error,
                'error'
            )
        })
    }

    const isCNPJ = customer && customer.registry_code?.length == 14
    const address = customer && customer.address

    return (
        <div id="box-subscription" className="vsbox">
            <div className="vsbox-body pb-1">
                <div className="row g-3 align-items-md-center mb-3 flex-column-reverse flex-md-row">
                    <div className="col">
                        <div className="vsbox-profile">
                            <div className="row g-3 align-items-center">
                                <div className="col mb-md-2 mb-lg-0">
                                    <div className="vsbox-name vsbox-link">
                                        { subscription.plan.name } { subscription?.id && <small style={{ color: "#666666" }}>({ subscription.id })</small> }
                                        { !subscription?.id && (
                                            <small className="text-danger">
                                                - Evite que a sua assinatura expire, <a className="text-danger" onClick={() => redirectToCheckout(clinic)}>finalize aqui</a> o cadastro de pagamento
                                            </small>
                                        )}
                                    </div>
                                    <div className="vsbox-data-light">
                                        { subscription.status == STATUS_CANCELED && (
                                            <div><b>Cancelada em:</b> { dayjs(subscription.cancel_at).format("DD/MM/YYYY") }</div>
                                        )}
                                        { subscription.current_period?.start_at && (
                                            <div><b>Período atual:</b> { dayjs(subscription.current_period.start_at).format("DD/MM/YYYY") } - { dayjs(subscription.current_period.end_at).format("DD/MM/YYYY") }</div>
                                        )}
                                        { subscription.next_billing_at && (
                                            <div><b>Próxima cobrança:</b> { dayjs(subscription.next_billing_at).format("DD/MM/YYYY") }</div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-auto">
                        { subscription.status == STATUS_CANCELED ? (
                            <span className="badge rounded-pill bg-danger p-2">Cancelada</span>
                        ) : (
                            <span className="badge rounded-pill bg-success py-2 px-3">{ getStatus(subscription) }</span>
                        )}
                    </div>
                </div>
                { subscription?.id && (
                    <>
                        { subscription.status == STATUS_CANCELED && dayjs(subscription.valid_at).isAfter() && (
                            <div className="pro-error mb-3">
                                <div className="row">
                                    <div className="col-auto pe-0">
                                        <IconExclamationO width={24} height={24} />
                                    </div>
                                    <div className="col">
                                        Plano válido até o dia <b>{dayjs(subscription.valid_at).add(1, "day").format("DD/MM/YYYY")}</b>
                                    </div>
                                </div>
                            </div>
                        )}
                        { subscription.metadata?.createdFromSubscription && subscription.status == STATUS_FUTURE && (
                            <div className="pro-alert mb-3">
                                <div className="row">
                                    <div className="col-auto pe-0">
                                        <IconExclamationO width={24} height={24} />
                                    </div>
                                    <div className="col">
                                        Plano alterado dia <b>{ dayjs(subscription.created_at).format("DD/MM/YYYY") }</b>. 
                                        Alteração prevista para dia <b>{ dayjs(subscription.next_billing_at).format("DD/MM/YYYY") }</b>, quando seu plano passará para o { subscription.plan?.code == PRO_YEARLY ? "Anual" : "Mensal" } e será cobrado o valor de <b>{ formattedCurrency(subscription.plan?.code == PRO_YEARLY ? PRO_YEARLY_BY_YEAR : PRO_MONTHLY_VALUE_BY_MONTH) }</b>.
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="vsbox-separator"></div>
                        <div className="row">
                            <div className="col-12">
                                <VSAccordionSimple
                                    header={(expanded) => (
                                        `${ expanded ? "Ocultar" : "Ver" } Informações do Assinante`
                                    )}
                                    onOpen={() => {
                                        if (!searched) {
                                            getCustomerOrError()
                                        }
                                    }}
                                >
                                    { loading ? (
                                        <div className="data-loading">
                                            <CircularProgress />
                                        </div>
                                    ) : customer ? (
                                        <>
                                            <div className="row">
                                                <div className="col order-1 order-lg-0">
                                                    <div className="row g-3 align-items-center">
                                                        <div className="col">
                                                            <div className="input-title">Contratante</div>
                                                        </div>
                                                    </div>
                                                    <div className="row g-3 mb-3 align-items-center flex-column-reverse flex-md-row">
                                                        <div className="col-12 col-md">
                                                            <label className="vsbox-info">
                                                                { isCNPJ ? "CNPJ" : "CPF" }
                                                            </label>
                                                            <div className="vsbox-info-text">
                                                                { customer.registry_code.replace(isCNPJ ? /([0-9]{2})([0-9]{3})([0-9]{3})([0-9]{4})([0-9]{2})/g : /([0-9]{3})([0-9]{3})([0-9]{3})([0-9]{2})/g, isCNPJ ? "$1.$2.$3/$4-$5" : "$1.$2.$3-$4") }
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md">
                                                            <label className="vsbox-info">
                                                            { isCNPJ ? "Razão Social" : "Nome" }
                                                            </label>
                                                            <div className="vsbox-info-text">
                                                                { customer.name }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row g-3 align-items-center">
                                                        <div className="col">
                                                            <div className="input-title">Contato</div>
                                                        </div>
                                                    </div>
                                                    <div className="row g-3 mb-3 align-items-center">
                                                        <div className="col-12 col-md">
                                                            <label className="vsbox-info">
                                                            E-mail
                                                            </label>
                                                            <div className="vsbox-info-text">
                                                                { customer.email }
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md">
                                                            <label className="vsbox-info">
                                                                Telefone
                                                            </label>
                                                            <div className="vsbox-info-text">
                                                                { customer.phones[0].number.replace(/^[0-9]{2}([0-9]{2})([0-9]*)([0-9]{4})$/g, "($1) $2-$3") }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    { address && (
                                                        <>
                                                            <div className="row g-3 align-items-center">
                                                                <div className="col">
                                                                    <div className="input-title">Endereço</div>
                                                                </div>
                                                            </div>
                                                            <div className="row g-3 mb-3 align-items-center">
                                                                <div className="col">
                                                                    <div className="vsbox-info-text">
                                                                        { formattedAddress({ 
                                                                            address: address.street, 
                                                                            number: address.number, 
                                                                            complement: address.additional_details, 
                                                                            neighborhood: address.neighborhood, 
                                                                            city: address.city, 
                                                                            state: address.state, 
                                                                            zipCode: address.zipcode
                                                                        }) }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                                { subscription.status != STATUS_CANCELED && (
                                                    <div className="col-12 col-lg-auto order-0 order-lg-1">
                                                        <div className="row g-3 mb-3 align-items-center justify-content-lg-end">
                                                            <div className="col col-lg-auto text-end pt-lg-2">
                                                                <button className="vsbox-btn vsbox-btn-dark mt-lg-4" onClick={() => history.push("/configuracao-clinica/plano-e-cobranca/cliente", {
                                                                    customer: customer
                                                                })}>
                                                                    <IconPrimaryEdit width="24" height="24" />
                                                                    Editar informações
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </>
                                    ) : (
                                        <div className="row g-3">
                                            <div className="col">
                                                Não foi possível encontrar as informações do assinante, por favor, entre em contato conosco.
                                            </div>
                                        </div>
                                    )}
                                </VSAccordionSimple>
                            </div>
                        </div>
                        <div className="vsbox-separator"></div>
                        <div className="row">
                            <div className="col-12 owner-info">
                                <SubscriptionBillsView {...props} />
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        clinic: state.clinic,
        permissions: state.permissions
    };
};
  
export default connect(mapStateToProps)(SubscriptionView);