import React from "react"
import { connect } from "react-redux"

import { ReactComponent as IconCategory } from "../../assets/icon-category.svg"
import { ReactComponent as IconReminder } from "../../assets/icon-reminder.svg"
import { ReactComponent as IconEdit } from "../../assets/icon-primary-edit.svg"
import { ReactComponent as IconChemical } from "../../assets/icon-chemical.svg"
import { ReactComponent as IconSquareMinus } from "../../assets/icon-square-minus.svg"
import { ReactComponent as IconSquarePlus } from "../../assets/icon-square-plus.svg"
import { ReactComponent as IconTrash } from "../../assets/icon-primary-trash.svg"

import { addEvent, showRegisteredBy } from "../../utils"
import { deleteSupply } from "./Supply"
import { ACL } from "../../constants"

import VSAccordion from "../../components/vsAccordion/VSAccordion"

import "./SupplyViewOld.scss"
const SupplyViewOld = (props) => {

    const {
        object,
        total,
        expanded,
        history,
        permissions
    } = props

    const category = object.get("category")

    const isDeleted = object.get("isDeleted")
    const isActive = object.get("active")
    const showReminder = object.get("reminder") ? total <= object.get("minQuantity") : false
    
    const getClassOfSupplyStatus = () => {
        if (isDeleted || !isActive)
            return "vsbox-disabled"
        if (showReminder)
            return "vsbox-reminder"
        return ""
    }

    return (
        <div id="supply-view" className="mb-3">
            <VSAccordion
                expanded={expanded}
                header={(expanded) => {
                    return (
                        <div className={`vsbox d-block w-100 ${getClassOfSupplyStatus()}`}>
                            <div className="row align-items-center box-header mx-0 py-3">
                                <div className="col-auto order-1">
                                    <div className="icon-round">
                                        <IconChemical width="24" height="24" fill={showReminder && isActive ? '#FFA133' : "var(--vsprontuario-primary-color)"} />
                                    </div>
                                </div>
                                <div className="col vsbox-profile px-0 mb-md-2 mb-lg-0 order-2">
                                    <div className="vsbox-name vsbox-link">
                                        { object.get("supplyId") && (
                                            <b>ID { object.get("supplyId") } - </b>
                                        )}
                                        { object.get("supplyName") }
                                    </div>
                                    <div className="vsbox-data-light">
                                        <div className="row align-items-center">
                                            <div className="col-auto">Saldo: { total }</div>
                                            <div className="col-auto px-0">•</div>
                                            <div className="col-auto">Mínimo: { object.get("minQuantity") || "Não especificado" }</div>
                                            <div className="col-auto px-0">•</div>
                                            <div className="col-auto">Máximo: { object.get("maxQuantity") || "Não especificado" }</div>
                                        </div>
                                    </div>
                                </div>
                                { !isActive ? (
                                    <div className="col-12 col-md-auto order-0 order-md-3 mb-3 mb-md-0">
                                        <div className="vsbox-chip px-3 px-md-5">Insumo Inativo</div>
                                    </div>
                                ) : showReminder && (
                                    <div className="col-12 col-md-auto order-0 order-md-3 mb-3 mb-md-0">
                                        <div className="vsbox-chip px-3 px-md-5">Reposição de Produto</div>
                                    </div>
                                )}
                                <div className="col-auto text-right order-4">
                                    { expanded ? (
                                        <IconSquareMinus width="24px" height="24px" fill="var(--vsprontuario-primary-color)" />
                                    ) : (
                                        <IconSquarePlus width="24px" height="24px" fill="var(--vsprontuario-primary-color)" />
                                    )}
                                </div>
                            </div>
                        </div>
                    )
                }}
            >
                <div className={`vsbox mt-1 ${!isActive && "opacity-75"} ${getClassOfSupplyStatus()}`}>
                    <div className="vsbox-body pb-0 mb-0">
                        <div className="row align-items-center">
                            <div className="col-12 col-md-auto">
                                <div className="row align-items-center">
                                    <div className="col-auto mb-3">
                                        <IconCategory width="24px" height="24px" fill="var(--vsprontuario-gray-color)" />
                                    </div>
                                    <div className="col-auto ps-0 mb-3">
                                        <label className="vsbox-info text-nowrap">Categoria</label>
                                        <div className="vsbox-info-text">
                                            { category?.get("categoryName") || "Não informada" }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-auto">
                                <div className="row align-items-center">
                                    <div className="col-auto mb-3">
                                        <IconReminder width="24" height="24" fill="var(--vsprontuario-gray-color)" />
                                    </div>
                                    <div className="col-auto ps-0 mb-3">
                                        <label className="vsbox-info text-nowrap">Lembrete de reposição</label>
                                        <div className="vsbox-info-text">
                                            { object.get("reminder") ? "Ativo" : "Desativado" }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md mb-3">
                                <label className="vsbox-info text-nowrap">Estado do Insumo</label>
                                <div className="vsbox-info-text">
                                    { isActive ? "Ativo" : "Inativo" }
                                </div>
                            </div>
                            { !isDeleted && (
                                <div className="col-12 col-md-auto mb-3">
                                    <div className="row align-items-center justify-content-center">
                                        { permissions?.check(ACL.SUPPLY, ACL.Rule.EDIT, object) && (
                                            <div className="col col-md-auto">
                                                <button
                                                    className={`vsbox-btn ${isDeleted ? "vsbox-btn-disabled" : "vsbox-btn-dark"}`}
                                                    onClick={() => history.push(`/insumo/${ object.id }`)}
                                                    disabled={isDeleted}
                                                >
                                                    <IconEdit width="24" height="24" fill={isDeleted ? "var(--vsprontuario-gray-color)" : "var(--vsprontuario-primary-color-dark)"} />
                                                </button>
                                            </div>
                                        )}
                                        { permissions?.check(ACL.SUPPLY, ACL.Rule.DELETE, object) && (
                                            <div className="col col-md-auto ps-0">
                                                <button
                                                    className={`vsbox-btn ${isDeleted ? "vsbox-btn-disabled" : "vsbox-btn-alert"}`}
                                                    onClick={() => deleteSupply(object, () => addEvent("Supply__getList", {}))}
                                                    disabled={isDeleted}
                                                >
                                                    <IconTrash width="24" height="24" fill={isDeleted ? "var(--vsprontuario-gray-color)" : "var(--vsprontuario-primary-color)"} />
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                        { showRegisteredBy(object) }
                        { !isDeleted && (
                            <>
                                <div className="vsbox-separator mb-3"></div>
                                <div className="row">
                                    <div className="col-12 vsbox--info">
                                        <div className="row">
                                            <div className="col vsbox-title">
                                                Observações
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col mb-3 pre-line">
                                                { object.get("notes") || "Não informado" }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </VSAccordion>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        permissions: state.permissions
    }
}

export default connect(mapStateToProps)(SupplyViewOld)