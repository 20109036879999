import axios from "axios"
import { APP_DEVICE, APP_NAME, APP_VERSION } from "../../../../constants"

export const deletePrescription = (prescription, callback) => {
    Swal.fire({
        title: 'Tem certeza?',
        text: "Você tem certeza que deseja excluir essa prescrição? Essa ação não poderá ser desfeita (o tutor perderá o acesso à prescrição digital).",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, quero excluir!',
        cancelButtonText: 'Não, manter a prescrição'
    }).then((result) => {
        if (result.isConfirmed) {
            prescription.set("isDeleted", true)
            prescription.set("changedAt", new Date())
            prescription.set("changedBy", Parse.User.current())
            prescription.set("changedApp", APP_NAME)
            prescription.set("changedDevice", APP_DEVICE)
            prescription.set("changedAppVersion", APP_VERSION)
            prescription.save()
            .then(_ => {
                Swal.fire(
                    'Excluído!',
                    'A prescrição foi excluída com sucesso.',
                    'success'
                )
                .then(_ => {
                    callback && callback()
                })
            })
            .catch(error => {
                console.error(error)
                Swal.fire(
                    'Desculpe',
                    'Ocorreu algum erro ao tentar excluir a prescrição',
                    'error'
                )
                prescription.revert()
            })
        }
    })
}

export const showPrescription = (prescription) => {
    return prescription.get("prescriptionType") == "0" ? "Receita Simples" : "Receita de Controle Especial"
}

export const getDrug = (drug) => {
    if (!drug.drugId)
        return Promise.resolve(drug)

    return new Promise((resolve, reject) => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_V2}/item/${drug.drugId}?with=prescriptionType`
        })
        .then(({ data }) => {
            if (data) {
                let drugWithExtraData = Object.assign(drug, {
                    isFood: data.isFood,
                    formattedClassifications: data.formattedClassifications,
                    prescriptionType: Object.assign({
                        prescriptionTypeId: "SMP",
                        prescriptionTypeName: "Receita Simples"
                    }, data.prescriptionType || {})
                })
                return resolve(drugWithExtraData)
            }
            return reject(null)
        })
        .catch(_ => {
            return reject(null)
        })
    })
}

export const showQuantities = (drug) => {
    let quantity = ""
    if (drug && !isNaN(parseInt(drug.quantity))) {
        let drugQuantity = parseInt(drug.quantity)
        switch (drugQuantity) {
            case 0: 
                quantity = "Uso contínuo"
                break
            case 1:
                quantity = "1 unidade"
                break
            default:
                quantity = `${drugQuantity} unidades`
        }
    }
    return quantity
}

export const showPrescriptionType = (drug) => {
    if (drug.isFood && drug.isFood == "1")
        return "Alimento"

    if (drug.compounds && drug.compounds.length > 0)
        return "Fórmula Manipulada"

    const prescriptionType = drug.prescriptionType
    if (!prescriptionType || (prescriptionType && (prescriptionType.prescriptionTypeId == "NSA" || !prescriptionType.prescriptionTypeName))) {
        return "Receita Não Informada"
    }

    if (prescriptionType.prescriptionTypeId == "SMP") {
        return "Receita Simples"
    }

    if (["ESV", "ESP", "AZL", "AMA"].indexOf(prescriptionType.prescriptionTypeId) !== -1) {
        return "Controle Especial "
    }

    return prescriptionType.prescriptionTypeName
}