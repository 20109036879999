// Powered by https://github.com/leoreisdias/react-currency-mask

export const MAXIMUM_FRACTION_DIGITS = 2

export const formatCurrency = (locale = 'pt-BR', value, currencyType = 'BRL', hideSymbol = false) => {
    const formatter = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currencyType,
      currencyDisplay: 'symbol',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    const formattedValue = formatter.format(value);
  
    return formattedValue.slice(hideSymbol ? 3 : 0);
};

export const clearNumber = (number) => {
    if (typeof number === 'number') {
      return number;
    }
  
    // strips everything that is not a number (positive or negative)
    return Number(number.toString().replace(/[^0-9-]/g, ''));
};

export const normalizeValue = (number) => {
    let safeNumber = number;

    if (typeof number === 'string') {
        safeNumber = clearNumber(number);

        if (safeNumber % 1 !== 0) {
        safeNumber = safeNumber.toFixed(MAXIMUM_FRACTION_DIGITS);
        }
    } else {
        // all input numbers must be a float point (for the cents portion). This is a fallback in case of integer ones.
        safeNumber = Number.isInteger(number)
        ? Number(number) * 10 ** MAXIMUM_FRACTION_DIGITS
        : number.toFixed(MAXIMUM_FRACTION_DIGITS);
    }
    // divide it by 10 power the maximum fraction digits.
    return clearNumber(safeNumber) / 10 ** MAXIMUM_FRACTION_DIGITS;
};

export const maskValues = (
    locale,
    inputFieldValue,
    currency,
    shouldCutSymbol,
) => {
    if (!inputFieldValue) return [0, ''];

    const value = normalizeValue(inputFieldValue);
    const maskedValue = formatCurrency(locale, value, currency, shouldCutSymbol);

    return [value, maskedValue];
};