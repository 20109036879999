import React from 'react';
import { makeStyles } from '@mui/styles';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles((theme) => ({
    root: {
        position: "relative",
        maxHeight: "112px",
        textAlign: "center"
    },
    bottom: {
        color: "#E6E6E6"
    },
    top: {
        color: "#1A90FF",
        animationDuration: "550ms",
        position: "absolute",
        left: 0,
        right: 0,
        margin: "0 auto"
    },
    circle: {
        strokeLinecap: "round",
    },
}));

const VSProgress = (props) => {
    const classes = useStyles();

    const {
        value,
        children,
        ...otherProps
    } = props
  
    return (
        <div className={classes.root}>
            <CircularProgress
                variant="determinate"
                className={classes.bottom}
                size={112}
                thickness={2}
                value={100}
                {...otherProps}
            />
            <CircularProgress
                variant="determinate"
                disableShrink
                className={classes.top}
                classes={{
                    circle: classes.circle,
                }}
                size={112}
                thickness={2}
                value={value}
                {...otherProps}
            />
            <div
                style={{
                   position: "absolute",
                   left: 0,
                   top: 0,
                   right: 0,
                   bottom: 0,
                   display: "flex",
                   alignItems: "center",
                   justifyContent: "center"
                }}
            >
                { children }
            </div>
        </div>
    )
}

export default VSProgress