import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import "./ColorPicker.scss"

const ColorPicker = (props) => {

    const {
        colors,
        color,
        className,
        onChange
    } = props

    const [ selectedColor, setSelectedColor ] = useState(color || colors[0])

    const onSelect = (color) => {
        setSelectedColor(color)
        onChange && onChange(color)
    }

    useEffect(() => {
        setSelectedColor(color)
    }, [ color ])

    return (
        <div id="color-picker" className={className}>
            { colors.length && colors.map((color, index) => (
                <div key={index} className={`color ${selectedColor == color ? 'color-selected' : ''}`}>
                    <div
                        style={{backgroundColor: color}}
                        onClick={() => onSelect(color)}
                    ></div>
                </div>
            )) }
        </div>
    )
}

ColorPicker.propTypes = {
    colors: PropTypes.array,
    color: PropTypes.string,
    className: PropTypes.string
};

ColorPicker.defaultProps = {
    colors: [],
    color: "",
    className: ""
}

export default ColorPicker;