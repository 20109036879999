import React from 'react';
import VSAccordion from '../vsAccordion/VSAccordion';
import PropTypes from 'prop-types';

import "./BoxDropdown.scss"

import { ReactComponent as IconArrowLeft } from "../../assets/icon-arrow-left.svg"
import { Switch } from '@mui/material';

const BoxDropdown = (props) => {

    const {
        children,
        checked,
        title,
        onChecked,
        onToggle,
        disabled
    } = props

    return (
        <div id="box-dropdown" className={`box ${ onChecked ? "box-switch" : ""}`}>
            <VSAccordion
                expanded={checked}
                disabled={disabled}
                header={(expanded) => (
                    <div
                        className={`box-head ${expanded ? "box-expanded" : ""} w-100`}
                        onClick={() => {
                            onChecked && onChecked(!checked)
                            onToggle && onToggle(!checked)
                        }}
                    >
                        <div className="row">
                            <div className="col pe-1">
                                { title }
                            </div>
                            <div className="col-auto ps-0 box-icon">
                                { onChecked ? (
                                    <Switch
                                        checked={checked}
                                        // onChange={({ target: { checked } }) => onChecked(checked)}
                                        color="primary"
                                    />
                                ) : (
                                    <IconArrowLeft width="10px" height="16px" style={{ transform: `rotate(${expanded ? "90deg" : "270deg"})` }} />
                                )}
                            </div>
                        </div>
                    </div>
                )}
            >
                { children && (
                    <div className="box-body">
                        { children }
                    </div>
                )}
            </VSAccordion>
        </div>
    )
}

BoxDropdown.propTypes = {
    title: PropTypes.string,
    checked: PropTypes.bool,
    onChecked: PropTypes.func,
};

BoxDropdown.defaultProps = {
    title: "",
    checked: false,
    onChecked: undefined
}

export default BoxDropdown;