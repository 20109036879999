import { Button } from "@mui/material"
import { Alert, AlertTitle } from "@mui/lab"

import "./VSError.scss"
const VSError = (props) => {

    const {
        message,
        onClose
    } = props

    return (
        <Alert
            action={
                <Button color="inherit" size="small" onClick={() => onClose && onClose()}>
                    Tentar novamente
                </Button>
            }
            severity="error"
            className="vsalert"
        >
            <AlertTitle>{ message }</AlertTitle>
            Se o erro persistir, aguarde alguns minutos e tente novamente ou <a href="https://pl-vetsmart.zendesk.com/hc/pt-br/sections/4410355292699-Configura%C3%A7%C3%B5es" target="_blank">entre em contato conosco</a>.
        </Alert>
    )
}

export default VSError