import React, { forwardRef, useEffect, useState } from 'react'
import Autocomplete from '@mui/lab/Autocomplete';

import { TextField } from '@mui/material';
import { getPelages, sizeOptions } from '../../Patient';
import VSAccordionSimple from '../../../../components/vsAccordion/VSAccordionSimple';
import { optionalField } from '../../../../utils';

const TermPatientInfoForm = forwardRef((props, ref) => {

    const {
        patient,
        pelage,
        setPelage,
        pelages,
        setPelages,
        size,
        setSize,
        rga,
        setRga,
        microchip,
        setMicrochip,
        otherInfo,
        setOtherInfo,
    } = props

    // Patient Info
    const [ showPatientInfo, setShowPatientInfo ] = useState(false)

    useEffect(() => {
        if (patient && patient.get) {
            setPelage(patient.get("pelage") || '')
            setSize(patient.get("size") || '')
            setRga(patient.get("rga") || '')
            setMicrochip(patient.get("microchip") || '')
            setOtherInfo(patient.get("otherInfo") || '')
            getPelages().then(_pelages => setPelages(_pelages))
        }
    }, [ patient ])

    return patient && (
        <div className="row">
            <div className="col">
                <VSAccordionSimple
                    expanded={showPatientInfo}
                    header={(_) => (
                        "Identificação Complementar do Animal"
                    )}
                    onOpen={() => setShowPatientInfo(true)}
                    onClose={() => setShowPatientInfo(true)}
                >
                    <div className="row">
                        <div className="col">
                            <div className="row mb-4">
                                <div className="col-12 col-xl-8">
                                    <Autocomplete
                                        inputValue={pelage}
                                        options={pelages}
                                        onChange={(_, selected) => setPelage(selected || '')}
                                        onInputChange={(event, selected) => event && setPelage(selected || '')}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                size="small"
                                                variant="outlined"
                                                className="w-100"
                                                placeholder="Pelagem do animal"
                                                label="Pelagem"
                                                helperText={optionalField}
                                            />
                                        )}
                                        openOnFocus
                                        freeSolo
                                    />
                                </div>
                                <div className="col-12 col-xl-4 mt-3 mt-xl-0">
                                    <Autocomplete
                                        value={size || null}
                                        options={sizeOptions}
                                        onChange={(_, selected) => setSize(selected || '')}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                size="small"
                                                variant="outlined"
                                                className="w-100"
                                                placeholder="Porte do animal"
                                                label="Porte"
                                                helperText={optionalField}
                                            />
                                        )}
                                        openOnFocus
                                    />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-6">
                                    <TextField
                                        placeholder="0.000.000"
                                        label="RGA"
                                        value={rga}
                                        onChange={({ target: { value } }) => setRga(value)}
                                        helperText={<span>Campo Opcional</span>}
                                        size="small"
                                        variant="outlined"
                                        fullWidth
                                    />
                                </div>
                                <div className="col-6">
                                    <TextField
                                        placeholder="000000000000000"
                                        label="Microchip"
                                        value={microchip}
                                        onChange={({ target: { value } }) => setMicrochip(value)}
                                        helperText={<span>Campo Opcional</span>}
                                        size="small"
                                        variant="outlined"
                                        fullWidth
                                    />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col">
                                    <TextField
                                        size="small"
                                        variant="outlined"
                                        className="w-100"
                                        placeholder="Digite aqui outros elementos de identificação do animal"
                                        label="Outras informações"
                                        value={otherInfo}
                                        onChange={({ target: { value } }) => setOtherInfo(value)}
                                        helperText={optionalField}
                                        multiline
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </VSAccordionSimple>
            </div>
        </div>
    )
})

export default TermPatientInfoForm;