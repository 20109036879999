import { RRule, rrulestr } from "rrule"

export const RECURRENCE_THIS = "this"
export const RECURRENCE_FUTURE = "future"
export const RECURRENCE_ALL = "all"

export const RRULE_DAILY = "DAILY"
export const RRULE_WEEKLY = "WEEKLY"
export const RRULE_MONTHLY = "MONTHLY"
export const RRULE_YEARLY = "YEARLY"

export const getRecurrenceDescription = (recurrence) => {
    if (recurrence) {
        const {
            options: {
                freq,
                interval
            }
        } = rrulestr(recurrence)
        if (interval < 1) {
            interval = 1
        }
        const isPlural = interval == 1
        switch (freq) {
            case RRule.DAILY:
                return `${interval} dia${isPlural ? "" : "s"}`
            case RRule.WEEKLY:
                return `${interval} semana${isPlural ? "" : "s"}`
            case RRule.MONTHLY:
                return `${interval} ${isPlural ? "mês" : "meses"}`
            case RRule.YEARLY:
                return `${interval} ano${isPlural ? "" : "s"}`
        }
    }
    return ""
}

export const frequencyOptions = [
    { value: RRule.YEARLY, text: "Anualmente" },
    { value: RRule.MONTHLY, text: "Mensalmente" },
    { value: RRule.WEEKLY, text: "Semanalmente" },
    { value: RRule.DAILY, text: "Diariamente" }
]

export const monthOptions = [
    { value: "1", text: "Janeiro" },
    { value: "2", text: "Fevereiro" },
    { value: "3", text: "Março" },
    { value: "4", text: "Abril" },
    { value: "5", text: "Maio" },
    { value: "6", text: "Junho" },
    { value: "7", text: "Julho" },
    { value: "8", text: "Agosto" },
    { value: "9", text: "Setembro" },
    { value: "10", text: "Outubro" },
    { value: "11", text: "Novembro" },
    { value: "12", text: "Dezembro" },
]

export const dayOptions = [
    { value: "1", text: "01" },
    { value: "2", text: "02" },
    { value: "3", text: "03" },
    { value: "4", text: "04" },
    { value: "5", text: "05" },
    { value: "6", text: "06" },
    { value: "7", text: "07" },
    { value: "8", text: "08" },
    { value: "9", text: "09" },
    { value: "10", text: "10" },
    { value: "11", text: "11" },
    { value: "12", text: "12" },
    { value: "13", text: "13" },
    { value: "14", text: "14" },
    { value: "15", text: "15" },
    { value: "16", text: "16" },
    { value: "17", text: "17" },
    { value: "18", text: "18" },
    { value: "19", text: "19" },
    { value: "20", text: "20" },
    { value: "21", text: "21" },
    { value: "22", text: "22" },
    { value: "23", text: "23" },
    { value: "24", text: "24" },
    { value: "25", text: "25" },
    { value: "26", text: "26" },
    { value: "27", text: "27" },
    { value: "28", text: "28" },
    { value: "29", text: "29" },
    { value: "30", text: "30" },
    { value: "31", text: "31" }
]

export const weekPositionOptions = [
    { value: "1", text: "Primeiro(a)" },
    { value: "2", text: "Segundo(a)" },
    { value: "3", text: "Terceiro(a)" },
    { value: "4", text: "Quarto(a)" },
    { value: "-2", text: "Penúltimo(a)" },
    { value: "-1", text: "Último(a)" }
]

export const weekOptions = [
    { value: RRule.SU, text: "Domingo" },
    { value: RRule.MO, text: "Segunda-feira" },
    { value: RRule.TU, text: "Terça-feira" },
    { value: RRule.WE, text: "Quarta-feira" },
    { value: RRule.TH, text: "Quinta-feira" },
    { value: RRule.FR, text: "Sexta-feira" },
    { value: RRule.SA, text: "Sábado" },
    { value: [ RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR, RRule.SA, RRule.SU ], text: "Dia" },
    { value: [ RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR ], text: "Dia útil" },
    { value: [ RRule.SA, RRule.SU ], text: "Final de semana" }
]

export const getWeekdayFromNumber = (weekdayNumber) => {
    switch (weekdayNumber) {
        case RRule.SU.weekday:
            return RRule.SU
        case RRule.MO.weekday:
            return RRule.MO
        case RRule.TU.weekday:
            return RRule.TU
        case RRule.WE.weekday:
            return RRule.WE
        case RRule.TH.weekday:
            return RRule.TH
        case RRule.FR.weekday:
            return RRule.FR
        case RRule.SA.weekday:
            return RRule.SA
        default:
            return null
    }
}