import { forwardRef, useEffect, useImperativeHandle, useState } from "react"

import { Switch } from "@mui/material"

import { ReactComponent as IconCalendarCheck } from "../../../assets/icon-calendar-check.svg"
import { ReactComponent as IconExclamationO } from "../../../assets/icon-exclamation-o.svg"
import { ReactComponent as IconSquareMinus } from "../../../assets/icon-square-minus.svg"
import { ReactComponent as IconSquarePlus } from "../../../assets/icon-square-plus.svg"

import { ACL } from "../../../constants"

import VSAccordionSimple from "../../../components/vsAccordion/VSAccordionSimple"
import CardPermission from "../../../components/cardPermission/CardPermission"
import BoxDropdown from "../../../components/boxDropdown/BoxDropdown"
import VSRadio from "../../../components/vsRadio/VSRadio"

import "./VetAccessForm.scss"
const VetAccessForm = forwardRef((props, ref) => {

    const {
        clinicUser,
        profile,
        userId,
        onChange
    } = props

    // prevents override while component load
    const [ loading, setLoading ] = useState(true)

    const [ errorPermissions, setErrorPermissions ] = useState(false)
    const [ helperPermissions, setHelperPermissions ] = useState(false)

    const [ admin, setAdmin ] = useState(false)

    const [ owners, setOwners ] = useState(ACL.Access.NO_ACCESS)
    const [ patients, setPatients ] = useState(ACL.Access.NO_ACCESS)
    const [ supplies, setSupplies ] = useState(ACL.Access.NO_ACCESS)
    const [ services, setServices ] = useState(ACL.Access.NO_ACCESS)

    const [ groupDocuments, setGroupDocuments ] = useState(false)
    const [ documents, setDocuments ] = useState(ACL.Access.NO_ACCESS)
    const [ appointments, setAppointments ] = useState(ACL.Access.NO_ACCESS)
    const [ weights, setWeights ] = useState(ACL.Access.NO_ACCESS)
    const [ vaccines, setVaccines ] = useState(ACL.Access.NO_ACCESS)
    const [ prescriptions, setPrescriptions ] = useState(ACL.Access.NO_ACCESS)
    const [ examRequests, setExamRequests ] = useState(ACL.Access.NO_ACCESS)
    const [ returns, setReturns ] = useState(ACL.Access.NO_ACCESS)
    const [ terms, setTerms ] = useState(ACL.Access.NO_ACCESS)
    const [ appointmentDrugs, setAppointmentDrugs ] = useState(ACL.Access.NO_ACCESS)
    const [ surgeries, setSurgeries ] = useState(ACL.Access.NO_ACCESS)
    const [ attachments, setAttachments ] = useState(ACL.Access.NO_ACCESS)

    const [ groupFinancials, setGroupFinancials ] = useState(false)
    const [ financials, setFinancials ] = useState(ACL.Access.NO_ACCESS)
    const [ sales, setSales ] = useState(ACL.Access.NO_ACCESS)
    const [ inflows, setInflows ] = useState(ACL.Access.NO_ACCESS)
    const [ outflows, setOutflows ] = useState(ACL.Access.NO_ACCESS)

    const [ calendars, setCalendars ] = useState(ACL.Access.NO_ACCESS)

    const [ settings, setSettings ] = useState(ACL.Access.NO_ACCESS)
    const [ controlPanels, setControlPanels ] = useState(ACL.Access.NO_ACCESS)
    const [ supplyTransactions, setSupplyTransactions ] = useState(ACL.Access.NO_ACCESS)

    const isDisabledDocument = patients == ACL.Access.NO_ACCESS
    const disabledDocumentMessage = isDisabledDocument ? "Sem acesso ao Cadastro de Animais não é possível ter acesso aos Registros de Prontuário" : ""

    useImperativeHandle(ref, () => ({
        validate: validate,
        fieldsWithErrorText: fieldsWithErrorText,
        getPermissions: getPermissions,
     }));

    const validate = (isValid) => {
        if (
            !admin &&
            owners == ACL.Access.NO_ACCESS &&
            patients == ACL.Access.NO_ACCESS &&
            supplies == ACL.Access.NO_ACCESS &&
            services == ACL.Access.NO_ACCESS &&
            appointments == ACL.Access.NO_ACCESS &&
            weights == ACL.Access.NO_ACCESS &&
            vaccines == ACL.Access.NO_ACCESS &&
            prescriptions == ACL.Access.NO_ACCESS &&
            examRequests == ACL.Access.NO_ACCESS &&
            returns == ACL.Access.NO_ACCESS &&
            terms == ACL.Access.NO_ACCESS &&
            appointmentDrugs == ACL.Access.NO_ACCESS &&
            surgeries == ACL.Access.NO_ACCESS &&
            attachments == ACL.Access.NO_ACCESS &&
            sales == ACL.Access.NO_ACCESS &&
            inflows == ACL.Access.NO_ACCESS &&
            calendars == ACL.Access.NO_ACCESS &&
            outflows == ACL.Access.NO_ACCESS &&
            settings == ACL.Access.NO_ACCESS &&
            controlPanels == ACL.Access.NO_ACCESS &&
            supplyTransactions == ACL.Access.NO_ACCESS
        ) {
            isValid = false
            setErrorPermissions(true)
            setHelperPermissions("Nenhuma permissão foi concedida")
        } else {
            setErrorPermissions(false)
            setHelperPermissions("")
        }
        return isValid
    }

    const fieldsWithErrorText = (errors) => {
        if (errorPermissions) {
            errors.push("Acesso")
        }
        return errors
    }

    const getPermissions = () => {
        return {
            [ACL.ADMIN]: admin,
            [ACL.OWNER]: ACL.Access.get(owners),
            [ACL.PATIENT]: ACL.Access.get(patients),
            [ACL.SUPPLY]: ACL.Access.get(supplies),
            [ACL.SERVICE]: ACL.Access.get(services),
            [ACL.APPOINTMENT]: ACL.Access.get(groupDocuments ? documents : appointments),
            [ACL.WEIGHT]: ACL.Access.get(groupDocuments ? documents : weights),
            [ACL.VACCINE]: ACL.Access.get(groupDocuments ? documents : vaccines),
            [ACL.PRESCRIPTION]: ACL.Access.get(groupDocuments ? documents : prescriptions),
            [ACL.EXAM_REQUEST]: ACL.Access.get(groupDocuments ? documents : examRequests),
            [ACL.RETURN]: ACL.Access.get(groupDocuments ? documents : returns),
            [ACL.TERM]: ACL.Access.get(groupDocuments ? documents : terms),
            [ACL.APPOINTMENT_DRUG]: ACL.Access.get(groupDocuments ? documents : appointmentDrugs),
            [ACL.SURGERY]: ACL.Access.get(groupDocuments ? documents : surgeries),
            [ACL.ATTACHMENT]: ACL.Access.get(groupDocuments ? documents : attachments),
            [ACL.SALE]: ACL.Access.get(groupFinancials ? financials : sales),
            [ACL.INFLOW]: ACL.Access.get(groupFinancials ? financials : inflows),
            [ACL.OUTFLOW]: ACL.Access.get(groupFinancials ? financials : outflows),
            [ACL.CALENDAR]: ACL.Access.get(calendars),
            [ACL.SETTING]: ACL.Access.get(settings),
            [ACL.CONTROL_PANEL]: ACL.Access.get(controlPanels),
            [ACL.SUPPLY_TRANSACTION]: ACL.Access.get(supplyTransactions),
        }
    }

    const setPermissions = (permissions) => {
        setAdmin(permissions?.[ACL.ADMIN])

        const _owners = ACL.Access.getValueByRule(permissions?.[ACL.OWNER])
        const _patients = ACL.Access.getValueByRule(permissions?.[ACL.PATIENT])
        const _supplies = ACL.Access.getValueByRule(permissions?.[ACL.SUPPLY])
        const _services = ACL.Access.getValueByRule(permissions?.[ACL.SERVICE])
        setOwners(_owners)
        setPatients(_patients)
        setSupplies(_supplies)
        setServices(_services)

        const _appointments = ACL.Access.getValueByRule(permissions?.[ACL.APPOINTMENT])
        const _weights = ACL.Access.getValueByRule(permissions?.[ACL.WEIGHT])
        const _vaccines = ACL.Access.getValueByRule(permissions?.[ACL.VACCINE])
        const _prescriptions = ACL.Access.getValueByRule(permissions?.[ACL.PRESCRIPTION])
        const _examRequests = ACL.Access.getValueByRule(permissions?.[ACL.EXAM_REQUEST])
        const _returns = ACL.Access.getValueByRule(permissions?.[ACL.RETURN])
        const _terms = ACL.Access.getValueByRule(permissions?.[ACL.TERM])
        const _appointmentDrugs = ACL.Access.getValueByRule(permissions?.[ACL.APPOINTMENT_DRUG])
        const _surgeries = ACL.Access.getValueByRule(permissions?.[ACL.SURGERY])
        const _attachments = ACL.Access.getValueByRule(permissions?.[ACL.ATTACHMENT])
        if (
            _appointments == _weights &&
            _weights == _vaccines &&
            _vaccines == _prescriptions &&
            _prescriptions == _examRequests &&
            _examRequests == _returns &&
            _returns == _terms &&
            _terms == _appointmentDrugs &&
            _appointmentDrugs == _surgeries &&
            _surgeries == _attachments
        ) {
            setGroupDocuments(true)
            setDocuments(_appointments)
        } else {
            setGroupDocuments(false)
        }
        setAppointments(_appointments)
        setWeights(_weights)
        setVaccines(_vaccines)
        setPrescriptions(_prescriptions)
        setExamRequests(_examRequests)
        setReturns(_returns)
        setTerms(_terms)
        setAppointmentDrugs(_appointmentDrugs)
        setSurgeries(_surgeries)
        setAttachments(_attachments)

        const _sales = ACL.Access.getValueByRule(permissions?.[ACL.SALE])
        const _inflows = ACL.Access.getValueByRule(permissions?.[ACL.INFLOW])
        const _outflows = ACL.Access.getValueByRule(permissions?.[ACL.OUTFLOW])
        if (
            _sales == _inflows &&
            _inflows == _outflows
        ) {
            setGroupFinancials(true)
            setFinancials(_sales)
        } else {
            setGroupFinancials(false)
        }
        setSales(_sales)
        setInflows(_inflows)
        setOutflows(_outflows)

        const _calendars = ACL.Access.getValueByRule(permissions?.[ACL.CALENDAR])
        setCalendars(_calendars)

        const _settings = ACL.Access.getValueByRule(permissions?.[ACL.SETTING])
        const _controlPanels = ACL.Access.getValueByRule(permissions?.[ACL.CONTROL_PANEL])
        const _supplyTransactions = ACL.Access.getValueByRule(permissions?.[ACL.SUPPLY_TRANSACTION])
        setSettings(_settings)
        setControlPanels(_controlPanels)
        setSupplyTransactions(_supplyTransactions)

        setLoading(false)
    }

    useEffect(() => {
        if (clinicUser?.get("permissions")) {
            setPermissions(clinicUser.get("permissions"))
        } else if (profile && profile.permissions) {
            if (!userId) {
                setPermissions(profile.permissions)
            } else {
                setLoading(false)
            }
        }
    }, [ clinicUser ])

    useEffect(() => {
        if (profile && profile?.permissions && clinicUser?.get('profile')?.id != profile?.id) {
            setPermissions(profile.permissions)
        }
    }, [ profile ])

    useEffect(() => {
        if (!loading && sales === ACL.Access.NO_ACCESS) {
            setInflows(ACL.Access.NO_ACCESS)
        }
    }, [ sales ])

    useEffect(() => {
        if (!loading && owners === ACL.Access.NO_ACCESS) {
            setPatients(ACL.Access.NO_ACCESS)
        }
    }, [ owners ])

    useEffect(() => {
        if (!loading && patients === ACL.Access.NO_ACCESS) {
            setDocuments(ACL.Access.NO_ACCESS)
            setAppointments(ACL.Access.NO_ACCESS)
            setWeights(ACL.Access.NO_ACCESS)
            setVaccines(ACL.Access.NO_ACCESS)
            setPrescriptions(ACL.Access.NO_ACCESS)
            setExamRequests(ACL.Access.NO_ACCESS)
            setReturns(ACL.Access.NO_ACCESS)
            setTerms(ACL.Access.NO_ACCESS)
            setAppointmentDrugs(ACL.Access.NO_ACCESS)
            setSurgeries(ACL.Access.NO_ACCESS)
            setAttachments(ACL.Access.NO_ACCESS)
            setSales(ACL.Access.NO_ACCESS)
            setCalendars(ACL.Access.NO_ACCESS)
        }
    }, [ patients ])

    useEffect(() => {
        if (!loading) {
            onChange?.()
        }
    }, [ 
        admin, profile, clinicUser, errorPermissions,
        owners, patients, supplies, services,
        groupDocuments, documents, appointments, weights, vaccines, prescriptions, examRequests, returns, terms, appointmentDrugs, surgeries, attachments, 
        groupFinancials, financials, sales, inflows, outflows, 
        calendars,
        settings, controlPanels, supplyTransactions
    ])

    return (
        <>
            <div className="row align-items-md-center flex-column flex-md-row mb-3">
                <div className="col-auto">
                    <div className="input-title">Configurações de Acesso</div>
                </div>
                <div className="col-auto col-md">
                    <div className="row align-items-center">
                        <div className="col-auto col-md text-md-end">
                            <div className="input-prefix">Acesso de Administrador</div>
                        </div>
                        <div className="col-auto">
                            <Switch
                                checked={admin}
                                onChange={({ target: { checked } }) => setAdmin(checked)}
                                color="primary"
                            />
                        </div>
                    </div>
                </div>
            </div>
            {  admin ? (
                <div className="vsinfo-simple">
                    <div className="row">
                        <div className="col-12 col-md mb-3 mb-md-0">
                            Ao dar acesso de Administrador, <b>este(a) usuário(a) terá acesso total a todos os dados, registros e 
                            funcionalidades.</b> Este acesso permite que ele(a) visualize, edite e exclua qualquer tipo de registro, inclusive podendo solicitar a exclusão 
                            total dos dados e encerramento da conta no Prontuário Vet Smart. Apenas administradores podem convidar novos(as) usuários(as) 
                            e editar configurações de acesso 
                        </div>
                        <div className="col-12 col-md">
                            <div className="vsinfo">
                                <div className="row">
                                    <div className="col-auto">
                                        <IconExclamationO  width="24" height="24" fill="var(--vsprontuario-secondary-color)" stroke="var(--vsprontuario-secondary-color)" />
                                    </div>
                                    <div className="col ps-0">
                                        <div className="vsinfo-title">
                                            Bloqueio automático de emissão de documentos médicos
                                        </div>
                                        <div className="vsinfo-text">
                                            Mesmo com acesso de administrador, usuários(as) que não sejam convidados(as) com perfil de Médico(a) 
                                            Veterinário(a) e não tenham CRMV válido e ativo cadastrado na conta não poderão criar e emitir documentos 
                                            médicos como atestados, prescrições, solicitações de exames e termos
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="row align-items-center">
                    <div className="col-12">
                        <VSAccordionSimple
                            expanded={true}
                            className="custom-accordion"
                            hideIcon
                            header={(expanded) => (
                                <div  className="vsbox w-100">
                                    <div className="vsbox-body p-2 mb-0">
                                        <div className="row align-items-center justify-content-end mx-0">
                                            <div className="col ps-0">
                                                <div className="row align-items-center flex-nowrap">
                                                    <div className="col-auto">
                                                        <svg width="52" height="52" viewBox="0 0 52 52" xmlns="http://www.w3.org/2000/svg">
                                                            <mask id="mask0_303_1807" style={{ maskType:"alpha"}} maskUnits="userSpaceOnUse" x="0" y="0" width="52" height="52">
                                                                <rect x="1" y="1" width="50" height="50" rx="25" fill="white"/>
                                                                <rect x="1" y="1" width="50" height="50" rx="25" fill="#8AA317" fillOpacity="0.15"/>
                                                                <rect x="1" y="1" width="50" height="50" rx="25" stroke="#8AA317" strokeWidth="2"/>
                                                            </mask>
                                                            <g mask="url(#mask0_303_1807)">
                                                                <rect width="52" height="52" rx="26" fill="#934CA1"/>
                                                            </g>
                                                            <g clipPath="url(#clip0_303_1807)">
                                                                <path d="M26 22.6667H30.4444C31.0556 22.6667 31.5556 22.1667 31.5556 21.5556C31.5556 20.9444 31.0556 20.4444 30.4444 20.4444H26C25.3889 20.4444 24.8889 20.9444 24.8889 21.5556C24.8889 22.1667 25.3889 22.6667 26 22.6667ZM26 27.1111H30.4444C31.0556 27.1111 31.5556 26.6111 31.5556 26C31.5556 25.3889 31.0556 24.8889 30.4444 24.8889H26C25.3889 24.8889 24.8889 25.3889 24.8889 26C24.8889 26.6111 25.3889 27.1111 26 27.1111ZM26 31.5556H30.4444C31.0556 31.5556 31.5556 31.0556 31.5556 30.4444C31.5556 29.8333 31.0556 29.3333 30.4444 29.3333H26C25.3889 29.3333 24.8889 29.8333 24.8889 30.4444C24.8889 31.0556 25.3889 31.5556 26 31.5556ZM20.4444 20.4444H22.6667V22.6667H20.4444V20.4444ZM20.4444 24.8889H22.6667V27.1111H20.4444V24.8889ZM20.4444 29.3333H22.6667V31.5556H20.4444V29.3333ZM34.8889 16H17.1111C16.5 16 16 16.5 16 17.1111V34.8889C16 35.5 16.5 36 17.1111 36H34.8889C35.5 36 36 35.5 36 34.8889V17.1111C36 16.5 35.5 16 34.8889 16ZM33.7778 33.7778H18.2222V18.2222H33.7778V33.7778Z" fill="white"/>
                                                            </g>
                                                            <defs>
                                                                <clipPath id="clip0_303_1807">
                                                                    <rect width="24" height="24" fill="white" transform="translate(14 14)"/>
                                                                </clipPath>
                                                            </defs>
                                                        </svg>
                                                    </div>
                                                    <div className="col ps-0">
                                                        <div className="vsbox-title">
                                                            Cadastros
                                                        </div>
                                                        <div className="vsbox-text">
                                                            Cadastro de Animais, Tutores, Insumos e Serviços
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-auto pe-2 vsbox-info mb-0">
                                                { expanded ? "Ocultar" : "Ver" } Configurações (4)
                                                { expanded ? (
                                                    <IconSquareMinus width="24px" height="24px" className="ms-2" fill="var(--vsprontuario-secondary-color)" />
                                                ) : (
                                                    <IconSquarePlus width="24px" height="24px" className="ms-2" fill="var(--vsprontuario-secondary-color)" />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        >
                            <div className="row g-2">
                                <div className="col-12 col-md-6 col-xl-3">
                                    <CardPermission
                                        title="Cadastro de Tutores"
                                        text="Refere-se aos daos de cadastros de tutores, como Nome, CPF, celular, etc."
                                        value={owners}
                                        onChange={(value) => setOwners(value)}
                                        options={[
                                            {
                                                value: ACL.Access.NO_ACCESS,
                                                label: "Sem Acesso"
                                            },
                                            {
                                                value: ACL.Access.VIEW_ALL,
                                                label: "Apenas visualizar"
                                            },
                                            {
                                                value: ACL.Access.VIEW_EDIT_ALL,
                                                label: "Visualizar, criar e editar cadastros"
                                            },
                                            {
                                                value: ACL.Access.VIEW_EDIT_DELETE_ALL,
                                                label: "Acesso total (criar, visualizar, editar e excluir qualquer registro de Cadastro de Tutores)"
                                            }
                                        ]}
                                    />
                                </div>
                                <div className="col-12 col-md-6 col-xl-3">
                                    <CardPermission
                                        title="Cadastro de Animais"
                                        text="Refere-se aos dados de cadastros dos animais, como Nome, Raça, Idade, etc."
                                        disabled={owners == ACL.Access.NO_ACCESS && "Sem acesso ao Cadastro de Tutores não é possível ter acesso ao Cadastro de Animais"}
                                        value={patients}
                                        onChange={(value) => setPatients(value)}
                                        options={[
                                            {
                                                value: ACL.Access.NO_ACCESS,
                                                label: "Sem Acesso"
                                            },
                                            {
                                                value: ACL.Access.VIEW_ALL,
                                                label: "Apenas visualizar"
                                            },
                                            {
                                                value: ACL.Access.VIEW_EDIT_ALL,
                                                label: "Visualizar, criar e editar cadastros"
                                            },
                                            {
                                                value: ACL.Access.VIEW_EDIT_DELETE_ALL,
                                                label: "Acesso total (criar, visualizar, editar e excluir qualquer registro de Cadastro de Animais)"
                                            }
                                        ]}
                                    />
                                </div>
                                <div className="col-12 col-md-6 col-xl-3">
                                    <CardPermission
                                        title="Cadastro de Insumos"
                                        text="Refere-se ao cadastro dos insumos que são utilizados, como seringas, medicamentos, etc."
                                        value={supplies}
                                        onChange={(value) => setSupplies(value)}
                                        options={[
                                            {
                                                value: ACL.Access.NO_ACCESS,
                                                label: "Sem Acesso"
                                            },
                                            {
                                                value: ACL.Access.VIEW_ALL,
                                                label: "Apenas visualizar"
                                            },
                                            {
                                                value: ACL.Access.VIEW_EDIT_DELETE_ALL,
                                                label: "Acesso total (criar, visualizar, editar e excluir qualquer registro de Cadastro de Insumo)"
                                            }
                                        ]}
                                    />
                                </div>
                                <div className="col-12 col-md-6 col-xl-3">
                                    <CardPermission
                                        title="Cadastro de Serviços"
                                        text="Refere-se ao cadastro dos tipos de serviços oferecidos, como consultas, exames, etc"
                                        value={services}
                                        onChange={(value) => setServices(value)}
                                        options={[
                                            {
                                                value: ACL.Access.NO_ACCESS,
                                                label: "Sem Acesso"
                                            },
                                            {
                                                value: ACL.Access.VIEW_ALL,
                                                label: "Apenas visualizar"
                                            },
                                            {
                                                value: ACL.Access.VIEW_EDIT_DELETE_ALL,
                                                label: "Acesso total (criar, visualizar, editar e excluir qualquer registro de Cadastro de Serviço)"
                                            }
                                        ]}
                                    />
                                </div>
                            </div>
                        </VSAccordionSimple>
                    </div>
                    <div className="col-12">
                        <VSAccordionSimple
                            expanded={true}
                            className="custom-accordion"
                            hideIcon
                            header={(expanded) => (
                                <div  className="vsbox w-100">
                                    <div className="vsbox-body p-2 mb-0">
                                        <div className="row align-items-center justify-content-end mx-0">
                                            <div className="col ps-0">
                                                <div className="row align-items-center flex-nowrap">
                                                    <div className="col-auto">
                                                        <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <mask id="mask0_304_1953" style={{ maskType:"alpha"}} maskUnits="userSpaceOnUse" x="0" y="0" width="52" height="52">
                                                                <rect x="1" y="1" width="50" height="50" rx="25" fill="white"/>
                                                                <rect x="1" y="1" width="50" height="50" rx="25" fill="#8AA317" fillOpacity="0.15"/>
                                                                <rect x="1" y="1" width="50" height="50" rx="25" stroke="#8AA317" strokeWidth="2"/>
                                                            </mask>
                                                            <g mask="url(#mask0_304_1953)">
                                                                <rect width="52" height="52" rx="26" fill="#00C4B4"/>
                                                            </g>
                                                            <path d="M27.256 24.5189V21.9307H25.9619H24.6678V24.5189H21.9619V25.9307V27.2248H24.6678V29.9307H25.9619H27.256V27.2248H29.9619V25.9307V24.5189H27.256Z" fill="white"/>
                                                            <path d="M34.2355 16H17.8703C16.8183 16 16 16.814 16 17.8605V21.2326V22.3953V34.1395C16 35.186 16.8183 36 17.8703 36H34.1186C35.1706 36 35.9889 35.186 35.9889 34.1395V17.8605C36.1058 16.814 35.2875 16 34.2355 16ZM34.8199 34.1395C34.8199 34.3721 34.5861 34.6047 34.3523 34.6047H17.7534C17.5196 34.6047 17.2858 34.3721 17.2858 34.1395V22.3953V21.2326V17.8605C17.2858 17.6279 17.5196 17.3953 17.7534 17.3953H34.3523C34.5861 17.3953 34.8199 17.6279 34.8199 17.8605V34.1395Z" fill="white"/>
                                                        </svg>
                                                    </div>
                                                    <div className="col ps-0">
                                                        <div className="vsbox-title">
                                                            Prontuário do Animal
                                                        </div>
                                                        <div className="vsbox-text">
                                                            Consulta, Peso, Vacinas, Prescrições, Exames, Retornos, Atestados, Termos, Medicamentos, Cirurgias e Anexos
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-auto pe-2 vsbox-info mb-0">
                                                { expanded ? "Ocultar" : "Ver" } Configurações (10)
                                                { expanded ? (
                                                    <IconSquareMinus width="24px" height="24px" className="ms-2" fill="var(--vsprontuario-secondary-color)" />
                                                ) : (
                                                    <IconSquarePlus width="24px" height="24px" className="ms-2" fill="var(--vsprontuario-secondary-color)" />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        >
                            <div className="row g-2">
                                <div className="col-12">
                                    <BoxDropdown
                                        title="Aplicar mesma regra de acesso para todos os tipos de registros do Prontuário do Animal"
                                        checked={groupDocuments}
                                        onChecked={(checked) => setGroupDocuments(checked)}
                                    />
                                </div>
                                { groupDocuments ? (
                                    <div className="vsradio-custom">
                                        <VSRadio
                                            value={documents}
                                            onChange={(_, value) => setDocuments(value)}
                                            className="vsradio-custom-option"
                                            disabled={isDisabledDocument}
                                            highlight
                                            options={[
                                                {
                                                    value: ACL.Access.NO_ACCESS,
                                                    label: (
                                                        <>
                                                            Sem acesso
                                                            { isDisabledDocument && <div className="text-warning">{ disabledDocumentMessage }</div> }
                                                        </>
                                                    )
                                                },
                                                {
                                                    value: ACL.Access.VIEW_ALL,
                                                    label: "Apenas visualizar (todos os registros de todos os animais)"
                                                },
                                                {
                                                    value: ACL.Access.VIEW_ALL_EDIT_SELF,
                                                    label: "Visualizar (todos), criar e apenas poder editar registros próprios",
                                                    disabled: !profile.isVet
                                                },
                                                {
                                                    value: ACL.Access.VIEW_ALL_EDIT_DELETE_SELF,
                                                    label: "Visualizar (todos), criar, apenas poder editar e excluir registros próprios",
                                                    disabled: !profile.isVet
                                                },
                                                {
                                                    value: ACL.Access.VIEW_EDIT_DELETE_ALL,
                                                    label: "Acesso total (visualizar, criar, editar e excluir registros de qualquer animal feito por qualquer usuário)",
                                                    disabled: !profile.isVet
                                                },
                                            ]}
                                        />
                                    </div>
                                ) : (
                                    <>
                                        <div className="col-12 col-md-6 col-xl-3">
                                            <CardPermission
                                                title="Consulta"
                                                text="Refere-se ao registro médico de consultas, como anamnese e observações"
                                                disabled={isDisabledDocument}
                                                value={appointments}
                                                onChange={(value) => setAppointments(value)}
                                                options={[
                                                    {
                                                        value: ACL.Access.NO_ACCESS,
                                                        label: "Sem Acesso"
                                                    },
                                                    {
                                                        value: ACL.Access.VIEW_ALL,
                                                        label: "Apenas visualizar (todos os registros de todos os animais)"
                                                    },
                                                    {
                                                        value: ACL.Access.VIEW_ALL_EDIT_SELF,
                                                        label: "Visualizar (todos), criar e apenas poder editar registros próprios"
                                                    },
                                                    {
                                                        value: ACL.Access.VIEW_ALL_EDIT_DELETE_SELF,
                                                        label: "Visualizar (todos), criar, apenas poder editar e excluir registros próprios"
                                                    },
                                                    {
                                                        value: ACL.Access.VIEW_EDIT_DELETE_ALL,
                                                        label: "Acesso total (visualizar, criar, editar e excluir registros de qualquer animal feito por qualquer usuário)"
                                                    }
                                                ]}
                                            />
                                        </div>
                                        <div className="col-12 col-md-6 col-xl-3">
                                            <CardPermission
                                                title="Peso"
                                                text="Refere-se ao registro médico de peso, como registro de peso e escore corporal"
                                                disabled={isDisabledDocument}
                                                value={weights}
                                                onChange={(value) => setWeights(value)}
                                                options={[
                                                    {
                                                        value: ACL.Access.NO_ACCESS,
                                                        label: "Sem Acesso"
                                                    },
                                                    {
                                                        value: ACL.Access.VIEW_ALL,
                                                        label: "Apenas visualizar (todos os registros de todos os animais)"
                                                    },
                                                    {
                                                        value: ACL.Access.VIEW_ALL_EDIT_SELF,
                                                        label: "Visualizar (todos), criar e apenas poder editar registros próprios"
                                                    },
                                                    {
                                                        value: ACL.Access.VIEW_ALL_EDIT_DELETE_SELF,
                                                        label: "Visualizar (todos), criar, apenas poder editar e excluir registros próprios"
                                                    },
                                                    {
                                                        value: ACL.Access.VIEW_EDIT_DELETE_ALL,
                                                        label: "Acesso total (visualizar, criar, editar e excluir registros de qualquer animal feito por qualquer usuário)"
                                                    }
                                                ]}
                                            />
                                        </div>
                                        <div className="col-12 col-md-6 col-xl-3">
                                            <CardPermission
                                                title="Vacina"
                                                text="Refere-se ao registro médico de vacina, como aplicações e próximas doses"
                                                disabled={isDisabledDocument}
                                                value={vaccines}
                                                onChange={(value) => setVaccines(value)}
                                                options={[
                                                    {
                                                        value: ACL.Access.NO_ACCESS,
                                                        label: "Sem Acesso"
                                                    },
                                                    {
                                                        value: ACL.Access.VIEW_ALL,
                                                        label: "Apenas visualizar (todos os registros de todos os animais)"
                                                    },
                                                    {
                                                        value: ACL.Access.VIEW_ALL_EDIT_SELF,
                                                        label: "Visualizar (todos), criar e apenas poder editar registros próprios",
                                                        disabled: !profile.isVet
                                                    },
                                                    {
                                                        value: ACL.Access.VIEW_ALL_EDIT_DELETE_SELF,
                                                        label: "Visualizar (todos), criar, apenas poder editar e excluir registros próprios",
                                                        disabled: !profile.isVet
                                                    },
                                                    {
                                                        value: ACL.Access.VIEW_EDIT_DELETE_ALL,
                                                        label: "Acesso total (visualizar, criar, editar e excluir registros de qualquer animal feito por qualquer usuário)",
                                                        disabled: !profile.isVet
                                                    }
                                                ]}
                                            />
                                        </div>
                                        <div className="col-12 col-md-6 col-xl-3">
                                            <CardPermission
                                                title="Prescrição"
                                                text="Refere-se aos registro médico das prescrições emitidas (simples e controle especial)"
                                                disabled={isDisabledDocument}
                                                value={prescriptions}
                                                onChange={(value) => setPrescriptions(value)}
                                                options={[
                                                    {
                                                        value: ACL.Access.NO_ACCESS,
                                                        label: "Sem Acesso"
                                                    },
                                                    {
                                                        value: ACL.Access.VIEW_ALL,
                                                        label: "Apenas visualizar (todos os registros de todos os animais)"
                                                    },
                                                    {
                                                        value: ACL.Access.VIEW_ALL_EDIT_SELF,
                                                        label: "Visualizar (todos), criar e apenas poder editar registros próprios",
                                                        disabled: !profile.isVet
                                                    },
                                                    {
                                                        value: ACL.Access.VIEW_ALL_EDIT_DELETE_SELF,
                                                        label: "Visualizar (todos), criar, apenas poder editar e excluir registros próprios",
                                                        disabled: !profile.isVet
                                                    },
                                                    {
                                                        value: ACL.Access.VIEW_EDIT_DELETE_ALL,
                                                        label: "Acesso total (visualizar, criar, editar e excluir registros de qualquer animal feito por qualquer usuário)",
                                                        disabled: !profile.isVet
                                                    }
                                                ]}
                                            />
                                        </div>
                                        <div className="col-12 col-md-6 col-xl-3">
                                            <CardPermission
                                                title="Solicitação de Exame"
                                                text="Refere-se ao registro médico das guias de exames médicos solicitados emitidas"
                                                disabled={isDisabledDocument}
                                                value={examRequests}
                                                onChange={(value) => setExamRequests(value)}
                                                options={[
                                                    {
                                                        value: ACL.Access.NO_ACCESS,
                                                        label: "Sem Acesso"
                                                    },
                                                    {
                                                        value: ACL.Access.VIEW_ALL,
                                                        label: "Apenas visualizar (todos os registros de todos os animais)"
                                                    },
                                                    {
                                                        value: ACL.Access.VIEW_ALL_EDIT_SELF,
                                                        label: "Visualizar (todos), criar e apenas poder editar registros próprios",
                                                        disabled: !profile.isVet
                                                    },
                                                    {
                                                        value: ACL.Access.VIEW_ALL_EDIT_DELETE_SELF,
                                                        label: "Visualizar (todos), criar, apenas poder editar e excluir registros próprios",
                                                        disabled: !profile.isVet
                                                    },
                                                    {
                                                        value: ACL.Access.VIEW_EDIT_DELETE_ALL,
                                                        label: "Acesso total (visualizar, criar, editar e excluir registros de qualquer animal feito por qualquer usuário)",
                                                        disabled: !profile.isVet
                                                    }
                                                ]}
                                            />
                                        </div>
                                        <div className="col-12 col-md-6 col-xl-3">
                                            <CardPermission
                                                title="Retorno"
                                                text="Refere-se ao registro dos retornos e consultas marcadas para o animal"
                                                disabled={isDisabledDocument}
                                                value={returns}
                                                onChange={(value) => setReturns(value)}
                                                options={[
                                                    {
                                                        value: ACL.Access.NO_ACCESS,
                                                        label: "Sem Acesso"
                                                    },
                                                    {
                                                        value: ACL.Access.VIEW_ALL,
                                                        label: "Apenas visualizar (todos os registros de todos os animais)"
                                                    },
                                                    {
                                                        value: ACL.Access.VIEW_ALL_EDIT_SELF,
                                                        label: "Visualizar (todos), criar e apenas poder editar registros próprios"
                                                    },
                                                    {
                                                        value: ACL.Access.VIEW_ALL_EDIT_DELETE_SELF,
                                                        label: "Visualizar (todos), criar, apenas poder editar e excluir registros próprios"
                                                    },
                                                    {
                                                        value: ACL.Access.VIEW_EDIT_DELETE_ALL,
                                                        label: "Acesso total (visualizar, criar, editar e excluir registros de qualquer animal feito por qualquer usuário)"
                                                    }
                                                ]}
                                            />
                                        </div>
                                        <div className="col-12 col-md-6 col-xl-3">
                                            <CardPermission
                                                title="Atestados e Termos"
                                                text="Refere-se ao registro médico dos atestados e termos médicos emitidos para o animal"
                                                disabled={isDisabledDocument}
                                                value={terms}
                                                onChange={(value) => setTerms(value)}
                                                options={[
                                                    {
                                                        value: ACL.Access.NO_ACCESS,
                                                        label: "Sem Acesso"
                                                    },
                                                    {
                                                        value: ACL.Access.VIEW_ALL,
                                                        label: "Apenas visualizar (todos os registros de todos os animais)"
                                                    },
                                                    {
                                                        value: ACL.Access.VIEW_ALL_EDIT_SELF,
                                                        label: "Visualizar (todos), criar e apenas poder editar registros próprios",
                                                        disabled: !profile.isVet
                                                    },
                                                    {
                                                        value: ACL.Access.VIEW_ALL_EDIT_DELETE_SELF,
                                                        label: "Visualizar (todos), criar, apenas poder editar e excluir registros próprios",
                                                        disabled: !profile.isVet
                                                    },
                                                    {
                                                        value: ACL.Access.VIEW_EDIT_DELETE_ALL,
                                                        label: "Acesso total (visualizar, criar, editar e excluir registros de qualquer animal feito por qualquer usuário)",
                                                        disabled: !profile.isVet
                                                    }
                                                ]}
                                            />
                                        </div>
                                        <div className="col-12 col-md-6 col-xl-3">
                                            <CardPermission
                                                title="Medicamentos"
                                                text="Refere-se aos registro médico dos medicamentos que já foram aplicados no animal"
                                                disabled={isDisabledDocument}
                                                value={appointmentDrugs}
                                                onChange={(value) => setAppointmentDrugs(value)}
                                                options={[
                                                    {
                                                        value: ACL.Access.NO_ACCESS,
                                                        label: "Sem Acesso"
                                                    },
                                                    {
                                                        value: ACL.Access.VIEW_ALL,
                                                        label: "Apenas visualizar (todos os registros de todos os animais)"
                                                    },
                                                    {
                                                        value: ACL.Access.VIEW_ALL_EDIT_SELF,
                                                        label: "Visualizar (todos), criar e apenas poder editar registros próprios"
                                                    },
                                                    {
                                                        value: ACL.Access.VIEW_ALL_EDIT_DELETE_SELF,
                                                        label: "Visualizar (todos), criar, apenas poder editar e excluir registros próprios"
                                                    },
                                                    {
                                                        value: ACL.Access.VIEW_EDIT_DELETE_ALL,
                                                        label: "Acesso total (visualizar, criar, editar e excluir registros de qualquer animal feito por qualquer usuário)"
                                                    }
                                                ]}
                                            />
                                        </div>
                                        <div className="col-12 col-md-6 col-xl-3">
                                            <CardPermission
                                                title="Cirurgia"
                                                text="Refere-se ao registro médico dos procedimentos cirúrgicos já realizados no animal"
                                                disabled={isDisabledDocument}
                                                value={surgeries}
                                                onChange={(value) => setSurgeries(value)}
                                                options={[
                                                    {
                                                        value: ACL.Access.NO_ACCESS,
                                                        label: "Sem Acesso"
                                                    },
                                                    {
                                                        value: ACL.Access.VIEW_ALL,
                                                        label: "Apenas visualizar (todos os registros de todos os animais)"
                                                    },
                                                    {
                                                        value: ACL.Access.VIEW_ALL_EDIT_SELF,
                                                        label: "Visualizar (todos), criar e apenas poder editar registros próprios"
                                                    },
                                                    {
                                                        value: ACL.Access.VIEW_ALL_EDIT_DELETE_SELF,
                                                        label: "Visualizar (todos), criar, apenas poder editar e excluir registros próprios"
                                                    },
                                                    {
                                                        value: ACL.Access.VIEW_EDIT_DELETE_ALL,
                                                        label: "Acesso total (visualizar, criar, editar e excluir registros de qualquer animal feito por qualquer usuário)"
                                                    }
                                                ]}
                                            />
                                        </div>
                                        <div className="col-12 col-md-6 col-xl-3">
                                            <CardPermission
                                                title="Anexos"
                                                text="Refere-se ao armazenamento de documentos como resultado de exames, fotos, etc.."
                                                disabled={isDisabledDocument}
                                                value={attachments}
                                                onChange={(value) => setAttachments(value)}
                                                options={[
                                                    {
                                                        value: ACL.Access.NO_ACCESS,
                                                        label: "Sem Acesso"
                                                    },
                                                    {
                                                        value: ACL.Access.VIEW_ALL,
                                                        label: "Apenas visualizar (todos os registros de todos os animais)"
                                                    },
                                                    {
                                                        value: ACL.Access.VIEW_ALL_EDIT_SELF,
                                                        label: "Visualizar (todos), criar e apenas poder editar registros próprios"
                                                    },
                                                    {
                                                        value: ACL.Access.VIEW_ALL_EDIT_DELETE_SELF,
                                                        label: "Visualizar (todos), criar, apenas poder editar e excluir registros próprios"
                                                    },
                                                    {
                                                        value: ACL.Access.VIEW_EDIT_DELETE_ALL,
                                                        label: "Acesso total (visualizar, criar, editar e excluir registros de qualquer animal feito por qualquer usuário)"
                                                    }
                                                ]}
                                            />
                                        </div>
                                    </>
                                )}
                            </div>
                        </VSAccordionSimple>
                    </div>
                    <div className="col-12">
                        <VSAccordionSimple
                            expanded={true}
                            className="custom-accordion"
                            hideIcon
                            header={(expanded) => (
                                <div  className="vsbox w-100">
                                    <div className="vsbox-body p-2 mb-0">
                                        <div className="row align-items-center justify-content-end mx-0">
                                            <div className="col ps-0">
                                                <div className="row align-items-center flex-nowrap">
                                                    <div className="col-auto">
                                                        <div style={{
                                                            width: '52px',
                                                            height: '52px',
                                                            padding: '14px',
                                                            borderRadius: '50%',
                                                            backgroundColor: 'var(--vsprontuario-secondary-color)'
                                                        }}>
                                                            <IconCalendarCheck width={24} height={24} fill="white" />
                                                        </div>
                                                    </div>
                                                    <div className="col ps-0">
                                                        <div className="vsbox-title">
                                                            Agenda
                                                        </div>
                                                        <div className="vsbox-text">
                                                            Dados da Agenda, Eventos e Envios de Lembrete de Evento
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-auto pe-2 vsbox-info mb-0">
                                                { expanded ? "Ocultar" : "Ver" } Configurações (1)
                                                { expanded ? (
                                                    <IconSquareMinus width="24px" height="24px" className="ms-2" fill="var(--vsprontuario-secondary-color)" />
                                                ) : (
                                                    <IconSquarePlus width="24px" height="24px" className="ms-2" fill="var(--vsprontuario-secondary-color)" />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        >
                            <div className="row g-2">
                                <div className="col-12 col-md-6 col-xl-3">
                                    <CardPermission
                                        title="Configuração da agenda"
                                        text="Refere-se a agenda da clínica e aos eventos de serviços"
                                        disabled={isDisabledDocument}
                                        value={calendars}
                                        onChange={(value) => setCalendars(value)}
                                        options={[
                                            {
                                                value: ACL.Access.NO_ACCESS,
                                                label: "Sem Acesso"
                                            },
                                            {
                                                value: ACL.Access.VIEW_ALL,
                                                label: "Apenas visualizar"
                                            },
                                            {
                                                value: ACL.Access.VIEW_EDIT_ALL,
                                                label: "Visualizar, criar e editar cadastros"
                                            },
                                            {
                                                value: ACL.Access.VIEW_EDIT_DELETE_ALL,
                                                label: "Acesso total (criar, visualizar, editar e excluir qualquer registro de Evento)"
                                            }
                                        ]}
                                    />
                                </div>
                            </div>
                        </VSAccordionSimple>
                    </div>
                    <div className="col-12">
                        <VSAccordionSimple
                            expanded={true}
                            className="custom-accordion"
                            hideIcon
                            header={(expanded) => (
                                <div  className="vsbox w-100">
                                    <div className="vsbox-body p-2 mb-0">
                                        <div className="row align-items-center justify-content-end mx-0">
                                            <div className="col ps-0">
                                                <div className="row align-items-center flex-nowrap">
                                                    <div className="col-auto">
                                                        <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <mask id="mask0_304_1959" style={{ maskType:"alpha"}} maskUnits="userSpaceOnUse" x="0" y="0" width="52" height="52">
                                                                <rect x="1" y="1" width="50" height="50" rx="25" fill="white"/>
                                                                <rect x="1" y="1" width="50" height="50" rx="25" fill="#8AA317" fillOpacity="0.15"/>
                                                                <rect x="1" y="1" width="50" height="50" rx="25" stroke="#8AA317" strokeWidth="2"/>
                                                            </mask>
                                                            <g mask="url(#mask0_304_1959)">
                                                                <rect width="52" height="52" rx="26" fill="#86BB71"/>
                                                            </g>
                                                            <g clipPath="url(#clip0_304_1959)">
                                                                <path d="M25.8889 24.7778C23.3667 24.1222 22.5556 23.4444 22.5556 22.3889C22.5556 21.1778 23.6778 20.3333 25.5556 20.3333C27.1333 20.3333 27.9222 20.9333 28.2111 21.8889C28.3444 22.3333 28.7111 22.6667 29.1778 22.6667H29.5111C30.2444 22.6667 30.7667 21.9444 30.5111 21.2556C30.0444 19.9444 28.9556 18.8556 27.2222 18.4333V17.6667C27.2222 16.7444 26.4778 16 25.5556 16C24.6333 16 23.8889 16.7444 23.8889 17.6667V18.4C21.7333 18.8667 20 20.2667 20 22.4111C20 24.9778 22.1222 26.2556 25.2222 27C28 27.6667 28.5556 28.6444 28.5556 29.6778C28.5556 30.4444 28.0111 31.6667 25.5556 31.6667C23.7222 31.6667 22.7778 31.0111 22.4111 30.0778C22.2444 29.6444 21.8667 29.3333 21.4111 29.3333H21.1C20.3556 29.3333 19.8333 30.0889 20.1111 30.7778C20.7444 32.3222 22.2222 33.2333 23.8889 33.5889V34.3333C23.8889 35.2556 24.6333 36 25.5556 36C26.4778 36 27.2222 35.2556 27.2222 34.3333V33.6111C29.3889 33.2 31.1111 31.9444 31.1111 29.6667C31.1111 26.5111 28.4111 25.4333 25.8889 24.7778Z" fill="white"/>
                                                            </g>
                                                            <defs>
                                                                <clipPath id="clip0_304_1959">
                                                                    <rect width="24" height="24" fill="white" transform="translate(14 14)"/>
                                                                </clipPath>
                                                            </defs>
                                                        </svg>

                                                    </div>
                                                    <div className="col ps-0">
                                                        <div className="vsbox-title">
                                                            Controle Financeiro
                                                        </div>
                                                        <div className="vsbox-text">
                                                            Orçamentos, Venda, Contas a Pagar, Contas a Receber, Entrada de Caixa e Saída de Caixa
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-auto pe-2 vsbox-info mb-0">
                                                { expanded ? "Ocultar" : "Ver" } Configurações (3)
                                                { expanded ? (
                                                    <IconSquareMinus width="24px" height="24px" className="ms-2" fill="var(--vsprontuario-secondary-color)" />
                                                ) : (
                                                    <IconSquarePlus width="24px" height="24px" className="ms-2" fill="var(--vsprontuario-secondary-color)" />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        >
                            <div className="row g-2">
                                <div className="col-12">
                                    <BoxDropdown
                                        title="Aplicar mesma regra de acesso para todos os tipos de registros de Controle Financeiro"
                                        checked={groupFinancials}
                                        onChecked={(checked) => setGroupFinancials(checked)}
                                    />
                                </div>
                                { groupFinancials ? (
                                    <div className="vsradio-custom">
                                        <VSRadio
                                            value={financials}
                                            onChange={(_, value) => setFinancials(value)}
                                            className="vsradio-custom-option"
                                            disabled={isDisabledDocument}
                                            highlight
                                            options={[
                                                {
                                                    value: ACL.Access.NO_ACCESS,
                                                    label: (
                                                        <>
                                                            Sem acesso
                                                            { isDisabledDocument && <div className="text-warning">{ disabledDocumentMessage }</div> }
                                                        </>
                                                    )
                                                },
                                                {
                                                    value: ACL.Access.VIEW_ALL,
                                                    label: "Apenas visualizar (todos os registros)"
                                                },
                                                {
                                                    value: ACL.Access.VIEW_ALL_EDIT_SELF,
                                                    label: "Visualizar (todos), criar e apenas poder editar registros próprios"
                                                },
                                                {
                                                    value: ACL.Access.VIEW_ALL_EDIT_DELETE_SELF,
                                                    label: "Visualizar (todos), criar, apenas poder editar e excluir registros próprios"
                                                },
                                                {
                                                    value: ACL.Access.VIEW_EDIT_DELETE_ALL,
                                                    label: "Acesso total (visualizar, criar, editar e excluir registros feitos por qualquer usuário)"
                                                },
                                            ]}
                                        />
                                    </div>
                                ) : (
                                    <>
                                        <div className="col-12 col-md-6 col-xl-3">
                                            <CardPermission
                                                title="Vendas e Orçamentos"
                                                text="Refere-se aos dados de registro dos orçamentos e serviços prestados para clientes / animais"
                                                disabled={isDisabledDocument}
                                                value={sales}
                                                onChange={(value) => setSales(value)}
                                                options={[
                                                    {
                                                        value: ACL.Access.NO_ACCESS,
                                                        label: "Sem Acesso"
                                                    },
                                                    {
                                                        value: ACL.Access.VIEW_ALL,
                                                        label: "Apenas visualizar (todos os registros de todos os animais)"
                                                    },
                                                    {
                                                        value: ACL.Access.VIEW_ALL_EDIT_SELF,
                                                        label: "Visualizar (todos), criar e apenas poder editar registros próprios"
                                                    },
                                                    {
                                                        value: ACL.Access.VIEW_ALL_EDIT_DELETE_SELF,
                                                        label: "Visualizar (todos), criar, apenas poder editar e excluir registros próprios"
                                                    },
                                                    {
                                                        value: ACL.Access.VIEW_EDIT_ALL,
                                                        label: "Visualizar (todos), criar e editar de qualquer animal feito por qualquer usuário"
                                                    },
                                                    {
                                                        value: ACL.Access.VIEW_EDIT_DELETE_ALL,
                                                        label: "Acesso total (visualizar, criar, editar e excluir registros de qualquer animal feito por qualquer usuário)"
                                                    }
                                                ]}
                                            />
                                        </div>
                                        <div className="col-12 col-md-6 col-xl-3">
                                            <CardPermission
                                                title="Contas a pagar e saída de caixa"
                                                text="Refere-se ao registro e acompanhamento de despesas"
                                                value={outflows}
                                                onChange={(value) => setOutflows(value)}
                                                options={[
                                                    {
                                                        value: ACL.Access.NO_ACCESS,
                                                        label: "Sem Acesso"
                                                    },
                                                    {
                                                        value: ACL.Access.VIEW_ALL,
                                                        label: "Apenas visualizar (todos os registros de todas as despesas)"
                                                    },
                                                    {
                                                        value: ACL.Access.VIEW_ALL_EDIT_SELF,
                                                        label: "Visualizar (todos), criar e apenas poder editar registros próprios"
                                                    },
                                                    {
                                                        value: ACL.Access.VIEW_ALL_EDIT_DELETE_SELF,
                                                        label: "Visualizar (todos), criar, apenas poder editar e excluir registros próprios"
                                                    },
                                                    {
                                                        value: ACL.Access.VIEW_EDIT_DELETE_ALL,
                                                        label: "Acesso total (visualizar, criar, editar e excluir registros de qualquer despesa feito por qualquer usuário)"
                                                    }
                                                ]}
                                            />
                                        </div>
                                        <div className="col-12 col-md-6 col-xl-3">
                                            <CardPermission
                                                title="Contas a receber e entrada de caixa"
                                                text="Refere-se ao registro e acompanhamento de pagamentos"
                                                disabled={sales == ACL.Access.NO_ACCESS && "Sem acesso ao Cadastro de Vendas e Orçamentos não é possível ter acesso aos Registros de Contas e Receber e Entrada de Caixa"}
                                                value={inflows}
                                                onChange={(value) => setInflows(value)}
                                                options={[
                                                    {
                                                        value: ACL.Access.NO_ACCESS,
                                                        label: "Sem Acesso"
                                                    },
                                                    {
                                                        value: ACL.Access.VIEW_ALL,
                                                        label: "Apenas visualizar (todos os registros de todas as vendas)"
                                                    },
                                                    {
                                                        value: ACL.Access.VIEW_ALL_EDIT_SELF,
                                                        label: "Visualizar (todos), criar e apenas poder editar registros próprios"
                                                    },
                                                    {
                                                        value: ACL.Access.VIEW_ALL_EDIT_DELETE_SELF,
                                                        label: "Visualizar (todos), criar, apenas poder editar e excluir registros próprios"
                                                    },
                                                    {
                                                        value: ACL.Access.VIEW_EDIT_DELETE_ALL,
                                                        label: "Acesso total (visualizar, criar, editar e excluir registros de qualquer venda feita por qualquer usuário)"
                                                    }
                                                ]}
                                            />
                                        </div>
                                    </>
                                )}
                            </div>
                        </VSAccordionSimple>
                    </div>
                    <div className="col-12">
                        <VSAccordionSimple
                            expanded={true}
                            className="custom-accordion"
                            hideIcon
                            header={(expanded) => (
                                <div  className="vsbox w-100">
                                    <div className="vsbox-body p-2 mb-0">
                                        <div className="row align-items-center justify-content-end mx-0">
                                            <div className="col ps-0">
                                                <div className="row align-items-center flex-nowrap">
                                                    <div className="col-auto">
                                                        <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <mask id="mask0_304_1981" style={{ maskType:"alpha"}} maskUnits="userSpaceOnUse" x="0" y="0" width="52" height="52">
                                                                <rect x="1" y="1" width="50" height="50" rx="25" fill="white"/>
                                                                <rect x="1" y="1" width="50" height="50" rx="25" fill="#8AA317" fillOpacity="0.15"/>
                                                                <rect x="1" y="1" width="50" height="50" rx="25" stroke="#8AA317" strokeWidth="2"/>
                                                            </mask>
                                                            <g mask="url(#mask0_304_1981)">
                                                                <rect width="52" height="52" rx="26" fill="#999999"/>
                                                            </g>
                                                            <g clipPath="url(#clip0_304_1981)">
                                                                <path d="M32.1126 26.784C32.1455 26.528 32.1701 26.272 32.1701 26C32.1701 25.728 32.1455 25.472 32.1126 25.216L33.8479 23.896C34.0042 23.776 34.0453 23.56 33.9466 23.384L32.3017 20.616C32.203 20.44 31.981 20.376 31.8 20.44L29.7522 21.24C29.3245 20.92 28.8639 20.656 28.3622 20.456L28.0497 18.336C28.025 18.144 27.8523 18 27.6467 18H24.3569C24.1513 18 23.9786 18.144 23.9539 18.336L23.6414 20.456C23.1397 20.656 22.6791 20.928 22.2515 21.24L20.2036 20.44C20.0144 20.368 19.8006 20.44 19.7019 20.616L18.057 23.384C17.9501 23.56 17.9995 23.776 18.1557 23.896L19.8911 25.216C19.8582 25.472 19.8335 25.736 19.8335 26C19.8335 26.264 19.8582 26.528 19.8911 26.784L18.1557 28.104C17.9995 28.224 17.9583 28.44 18.057 28.616L19.7019 31.384C19.8006 31.56 20.0227 31.624 20.2036 31.56L22.2515 30.76C22.6791 31.08 23.1397 31.344 23.6414 31.544L23.9539 33.664C23.9786 33.856 24.1513 34 24.3569 34H27.6467C27.8523 34 28.025 33.856 28.0497 33.664L28.3622 31.544C28.8639 31.344 29.3245 31.072 29.7522 30.76L31.8 31.56C31.9892 31.632 32.203 31.56 32.3017 31.384L33.9466 28.616C34.0453 28.44 34.0042 28.224 33.8479 28.104L32.1126 26.784V26.784ZM26.0018 28.8C24.4145 28.8 23.1233 27.544 23.1233 26C23.1233 24.456 24.4145 23.2 26.0018 23.2C27.5891 23.2 28.8804 24.456 28.8804 26C28.8804 27.544 27.5891 28.8 26.0018 28.8Z" fill="white"/>
                                                            </g>
                                                            <defs>
                                                                <clipPath id="clip0_304_1981">
                                                                    <rect width="24" height="24" fill="white" transform="translate(14 14)"/>
                                                                </clipPath>
                                                            </defs>
                                                        </svg>
                                                    </div>
                                                    <div className="col ps-0">
                                                        <div className="vsbox-title">
                                                            Controle Administrativo
                                                        </div>
                                                        <div className="vsbox-text">
                                                            Dados da Clínica, Entrada de Insumos, Saída de Insumos e Painel de Controle
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-auto pe-2 vsbox-info mb-0">
                                                { expanded ? "Ocultar" : "Ver" } Configurações (3)
                                                { expanded ? (
                                                    <IconSquareMinus width="24px" height="24px" className="ms-2" fill="var(--vsprontuario-secondary-color)" />
                                                ) : (
                                                    <IconSquarePlus width="24px" height="24px" className="ms-2" fill="var(--vsprontuario-secondary-color)" />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        >
                            <div className="row g-2">
                                <div className="col-12 col-md-6 col-xl-3">
                                    <CardPermission
                                        title="Configuração da clínica"
                                        text="Refere-se aos dados da clínica como Nome, Configuração dos Documentos, etc."
                                        value={settings}
                                        onChange={(value) => setSettings(value)}
                                        options={[
                                            {
                                                value: ACL.Access.NO_ACCESS,
                                                label: "Sem Acesso"
                                            },
                                            {
                                                value: ACL.Access.VIEW_ALL,
                                                label: "Apenas visualizar"
                                            },
                                            {
                                                value: ACL.Access.VIEW_EDIT_ALL,
                                                label: "Visualizar e editar dados"
                                            }
                                        ]}
                                    />
                                </div>
                                <div className="col-12 col-md-6 col-xl-3">
                                    <CardPermission
                                        title="Painel de Controle"
                                        text="Refere-se aos dados gerais e relatórios na área de Painel de Controle"
                                        value={controlPanels}
                                        onChange={(value) => setControlPanels(value)}
                                        options={[
                                            {
                                                value: ACL.Access.NO_ACCESS,
                                                label: "Sem Acesso"
                                            },
                                            {
                                                value: ACL.Access.VIEW_ALL,
                                                label: "Visualizar (todos os dados disponíveis no painel)"
                                            }
                                        ]}
                                    />
                                </div>
                                <div className="col-12 col-md-6 col-xl-3">
                                    <CardPermission
                                        title="Entrada e Saída de Insumos"
                                        text="Refere-se ao controle e gestão dos insumos utilizados nos atendimentos realizados"
                                        value={supplyTransactions}
                                        onChange={(value) => setSupplyTransactions(value)}
                                        options={[
                                            {
                                                value: ACL.Access.NO_ACCESS,
                                                label: "Sem Acesso"
                                            },
                                            {
                                                value: ACL.Access.VIEW_ALL,
                                                label: "Apenas visualizar (todos os registros)"
                                            },
                                            {
                                                value: ACL.Access.VIEW_ALL_EDIT_SELF,
                                                label: "Visualizar (todos) e criar registros próprios"
                                            }
                                        ]}
                                    />
                                </div>
                            </div>
                        </VSAccordionSimple>
                    </div>
                </div>
            )}
        </>
    )
})

export default VetAccessForm