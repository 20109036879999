import React, { useState } from 'react'
import { connect } from 'react-redux'

import { TableCell, TableRow } from '@mui/material'

import { ReactComponent as IconHomePatientOwner } from "../../assets/icon-home-patient-owner.svg"

import HomeData from './HomeData'
import { dayjs } from '../../utils'

import './HomeLastPatientsOwners.scss'
const HomeLastPatientsOwners = (props) => {

    const {
        clinic,
        expanded,
        history
    } = props

    const [ loading, setLoading ] = useState(true)
    // const [ owners, setOwners ] = useState([])
    // const [ patients, setPatients ] = useState([])
    const [ data, setData ] = useState([])

    const rowsPerPage = 5

    return (
        <div className="col-12 mb-3">
            <HomeData
                expanded={expanded}
                headerIcon={<IconHomePatientOwner width="24px" height="24px" fill="#B3B3B3" />}
                title="Últimos Cadastros"
                emptyText="Você ainda não possui cadastros"
                loading={loading}
                data={data}
                rowsPerPage={rowsPerPage}
                onChange={(page) => {
                    if (clinic && clinic.isLoaded && clinic.object) {
                        setData([])
                        setLoading(true)

                        const queryOwner = new Parse.Query("Owner");
                        queryOwner.equalTo("isDeleted", false)
                        queryOwner.equalTo("clinic", clinic.object)
                        queryOwner.descending("createdAt")
                        queryOwner.limit(15)

                        const queryPatient = new Parse.Query("Patient");
                        queryPatient.equalTo("isDeleted", false)
                        queryPatient.equalTo("clinic", clinic.object)
                        queryPatient.descending("createdAt")
                        queryPatient.include("owner")
                        queryPatient.limit(15)

                        Promise.all([queryOwner.find(), queryPatient.find()]).then(([_owners, _patients]) => {
                            const _data = _owners.concat(_patients).sort((a, b) => a?.get("createdAt") < b?.get("createdAt") ? 1 : -1)
                            setData({
                                count: _data?.length || 0,
                                results: _data || []
                            })
                            setLoading(false)
                        })
                        .catch(error => {
                            console.error(error)
                            setLoading(false)
                        })
                    }
                }}
                renderItem={(element, index) => {
                    return (
                        <TableRow
                            key={index}
                        >
                            <TableCell
                                style={{ width: '86px' }}
                            >
                                <div className="text-truncate">
                                    { dayjs(element?.get("createdAt")).format("DD/MM/YYYY") }
                                </div>
                            </TableCell>
                            <TableCell className="px-2">
                                <div className="text-link d-inline" onClick={() => element?.className == "Owner" ?
                                                                                    history.push(`/tutor/${element?.id}`) : 
                                                                                    history.push(`/animal/${element?.id}`)}>

                                    Cadastro {element?.className == "Owner" ? "Tutor" : "Animal"} { element?.get("name") }
                                </div>
                            </TableCell>
                        </TableRow>
                    )
                }}
                // footer={(
                //     <div className="col">
                //         <div onClick={() => history.push('/tutor')} className="btn-outline-submit btn-square cursor-pointer">
                //             Ver Cadastros de Tutores
                //         </div>
                //     </div>
                // )}
            />
        </div>
    )
}

const mapStateToProps = state => {
    return {
        clinic: state.clinic
    };
};
  
export default connect(mapStateToProps)(HomeLastPatientsOwners);