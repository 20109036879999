import React, { useEffect, useState } from 'react'
import { connect } from "react-redux"
import { CircularProgress } from '@mui/material'
import ContextMenu from '../../components/contextMenu/ContextMenu'

import './ReturnList.scss'
import PatientPhoto from '../../components/patientPhoto/PatientPhoto'
import { dayjs, stringifyArray } from '../../utils'
import { ACL } from '../../constants'

var checkIsCurrentDateFrom = null

const ReturnList = (props) => {

    const {
        history,
        patient,
        dateFrom,
        dateTo,
        limit,
        clinic,
        permissions
    } = props

    const router = history

    const [ returns, setReturns ] = useState([])
    const [ loading, setLoading ] = useState(false)

    const findHistories = () => {
        setLoading(true)
        setReturns([])

        checkIsCurrentDateFrom = dateFrom

        var query = new Parse.Query("Return")
        query.equalTo("clinic", clinic.object);
        query.equalTo("isDeleted", false);
        if (dateFrom) {
            query.greaterThanOrEqualTo("returnDate", dateFrom);
        } else {
            query.greaterThanOrEqualTo("returnDate", dayjs().startOf('week').toDate())
        }
        if (dateTo) {
            query.lessThanOrEqualTo("returnDate", dateTo);
        } else {
            query.lessThanOrEqualTo("returnDate", dayjs().endOf('week').toDate());
        }
        if (limit) {
            query.limit(limit);
        } else {
            query.limit(1000);
        }
        if (patient) {
            query.equalTo("patient", patient);
        }
        query.descending("returnDate");
        query.include("patient");
        query.include("patient.owner");
        query.find()
        .then(_returns => {
            let returnsByDate = []
            _returns.map(_return => {
                const returnDateFormatted = dayjs(_return.get("returnDate")).format("LL")
                if (!returnsByDate[returnDateFormatted]) {
                    returnsByDate[returnDateFormatted] = []
                }
                returnsByDate[returnDateFormatted].push(_return)
            })
            if (checkIsCurrentDateFrom == dateFrom) {
                setLoading(false)
                setReturns(returnsByDate)
            }
        })
        .catch(error => {
            console.error(error)
            setLoading(false)
        })
    }

    const showReturnType = (_return) => {
        let returnType = []
        if (_return.get("examRequest"))
            returnType.push("Exame")
        if (_return.get("surgery"))
            returnType.push("Cirurgia")
        if (_return.get("vaccine"))
            returnType.push("Vacina")
        if (_return.get("prescription"))
            returnType.push("Prescrição")
        if (_return.get("appointment"))
            returnType.push("Consulta")
        if (_return.get("appointmentDrug"))
            returnType.push("Medicamento")

        if (returnType.length)
            return stringifyArray(returnType)
        else
            return "Outros"
    }

    useEffect(() => {
        findHistories()
    }, [ clinic, dateFrom, dateTo, limit, patient ])

    useEffect(() => {
        if (permissions && !permissions.check(ACL.RETURN, ACL.Rule.VIEW)) {
            permissions.error()
        }
    }, [ permissions ])

    return (
        <div id="return-list" className="row">
            <div className="col-12">
                { loading ? (
                    <div className="data-loading">
                        <CircularProgress />
                    </div>
                ) : (
                    Object.keys(returns).length == 0 ? (
                        <div className="row mx-auto text-center empty">
                            <div className="col-12 mb-3 mt-5">
                                <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M42.0748 9.675H40.2748V4.5H35.7748V9.675H17.9998V4.5H13.4998V9.675H11.9248C9.44981 9.675 7.4248 11.7 7.4248 14.175V18V22.5V27V31.5V36V40.5V44.325C7.4248 46.8 9.44981 48.825 11.9248 48.825H42.0748C44.5498 48.825 46.5748 46.8 46.5748 44.325V14.175C46.5748 11.7 44.5498 9.675 42.0748 9.675ZM11.9248 11.925H42.0748C43.4248 11.925 44.3248 12.825 44.3248 14.175V19.125H9.67481V14.175C9.67481 12.825 10.5748 11.925 11.9248 11.925ZM42.0748 46.8H11.9248C10.5748 46.8 9.67481 45.9 9.67481 44.55V21.375H44.3248V44.55C44.3248 45.9 43.4248 46.8 42.0748 46.8Z" fill="var(--vsprontuario-gray-color-dark)"/>
                                    <path d="M17.7744 33.525L20.9244 30.375L25.1994 34.875L33.0744 27L36.2244 30.15L25.1994 41.4L17.7744 33.525Z" fill="#00CFC2"/>
                                </svg>
                            </div>
                            <div className="col">
                                <h4 className="empty-title">Retornos</h4>
                                <p className="empty-text">
                                    Você não tem nenhum retorno agendado para { !dateFrom && !dateTo ? "esta semana" : "este período" }.
                                </p>
                            </div>
                        </div>
                    ) : (
                        Object.keys(returns).map((key, indexKey) => (
                            <div className="return" key={indexKey}>
                                <h3 className="return-title">{ key }</h3>
                                { returns[key].map((_return, index) => {
                                    const patientOfReturn = _return.get("patient")
                                    return (
                                        <div className="row align-items-center return-item" key={index}>
                                            <div className="col cursor-pointer" onClick={() => router.push(`/animal/${patientOfReturn.id}/retorno`)}>
                                                <div className="row align-items-center">
                                                    <div className="col-auto">
                                                        <PatientPhoto patient={patientOfReturn} />
                                                    </div>
                                                    <div className="col px-0 return-data">
                                                        <h5 className="patient-name">
                                                            { patientOfReturn.get("name") }
                                                            <div className="d-inline">
                                                                &nbsp;-&nbsp;
                                                                <span className="card-action">VER PERFIL</span>
                                                            </div>
                                                        </h5>
                                                        <div className="return-type">
                                                            <b>Tipo de retorno:</b> { showReturnType(_return) }
                                                        </div>
                                                        <b>Anotações:</b> { _return.get("notes") || "Não informado" }
                                                    </div>
                                                    <div className="col-auto px-0">
                                                        <span className="return-createdBy">
                                                            { _return.get("createdBy").get("fullName") }
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-auto ps-0">
                                                <ContextMenu
                                                    options={[
                                                        {
                                                            menu: "Visualizar",
                                                            onClick: () => router.push(`/animal/${patientOfReturn.id}/retorno/${_return.id}/ver`)
                                                        },
                                                        {
                                                            menu: "Editar",
                                                            onClick: () => router.push(`/animal/${patientOfReturn.id}/retorno/${_return.id}`)
                                                        },
                                                        {
                                                            menu: "Excluir",
                                                            onClick: () => deleteReturn(_return, () => router.go(0))
                                                        }
                                                    ]}
                                                />
                                            </div>
                                        </div>
                                    )
                                }) }
                            </div>
                        ))
                    )
                )}
                <div className="row mt-3">
                    <div className="col-12 text-center">
                        { (Object.keys(returns).length != 0 || (!dateFrom && !dateTo)) && (
                            <button
                                className="btn-submit mx-auto"
                                onClick={() => history.push(`/retorno/completo`)}
                            >
                                ver todos os retornos
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        clinic: state.clinic,
        permissions: state.permissions
    };
};
  
export default connect(mapStateToProps)(ReturnList)