import React, { useState } from 'react'
import VSAccordionSimple from '../../components/vsAccordion/VSAccordionSimple'
import { ReactComponent as Clinic } from "../../assets/clinic.svg"
import { ReactComponent as IconOwner } from "../../assets/icon-clinic-select-owner.svg"
import { ReactComponent as IconInvite } from "../../assets/icon-clinic-select-invite.svg"
import { CircularProgress } from '@mui/material'
import "./BoxSelectClinic.scss"

const BoxSelectClinic = props => {
    const {
		user,
        clinicUser,
        onClickSelectClinic,
        onInviteDeclineConfirmed
    } = props

    const [ open, setOpen ] = useState(false)
    const [ loadingInvite, setLoadingInvite ] = useState(false)

	const onAccept = e => {
        e.stopPropagation()
        setLoadingInvite(true)

		clinicUser.set("email", user.email)
		clinicUser.set("name", user.fullName)
		clinicUser.set("user", user.object)
		clinicUser.set("accepted", true)
		clinicUser.save()
		.then(_ => {
            Swal.fire({
                title: 'Convite Aceito!',
                icon: 'success',
                confirmButtonText: 'Fechar',
            })

			onClickSelectClinic(clinicUser)
            setLoadingInvite(false)
		})
		.catch(error => {
            console.error(error)
            Swal.fire(
                'Oops...',
                `Não foi possível aceitar o convite`,
                'error'
            )
            clinicUser.revert()
            setLoadingInvite(false)
		})
	}

	const onDecline = e => {
        e.stopPropagation()
        setLoadingInvite(true)

        Swal.fire({
            title: "Tem certeza que deseja recusar o convite?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Recusar Convite!',
            cancelButtonText: 'Fechar'
        }).then((result) => {
            if (result.isConfirmed) {
                clinicUser.set("email", user.email)
                clinicUser.set("name", user.fullName)
                clinicUser.set("user", user.object)
                clinicUser.set("accepted", false)
                clinicUser.save()
                .then(object => {
                    setLoadingInvite(false)
                    onInviteDeclineConfirmed()
                })
                .catch(error => {
                    console.error(error)
                    Swal.fire(
                        'Oops...',
                        `Não foi possível recusar o convite`,
                        'error'
                    )
                    clinicUser.revert()
                    setLoadingInvite(false)
                })
            } else {
                setLoadingInvite(false)
            }
        })
	}

    return (
        <div className={`clinic-user-element flex-grow-1 d-flex flex-column ${clinicUser.get('accepted') === undefined ? 'pending' : ''}`} onClick={() => {
            if(clinicUser.get('accepted') === undefined){
                setOpen(!open)
                return
            }
            onClickSelectClinic(clinicUser)
        }}>
            <div className="row align-items-center mx-0 flex-nowrap p-3" style={{minWidth:0}}>
                <div className="select-clinic-icon col-auto">
                    <Clinic fill={clinicUser.get('accepted') === undefined ?
                                `var(--vsprontuario-gray-color-light)` : 
                                clinicUser.get('clinic').get('isPro') ?
                                `var(--vsprontuario-secondary-color)` : 
                                `var(--vsprontuario-primary-color)`} width="42px" height="47px" />
                </div>
                <div className="col d-flex flex-column ps-1" style={{minWidth:0}}>
                    <div className="clinic-user-title">Clínica {
                        clinicUser.get('clinic').get('isPro') && <span className={`badge rounded-pill ${clinicUser.get('accepted') === undefined ? 'bg-gray' : 'bg-secondary'}`}>PRO</span>
                    }</div>
                    <div className="clinic-user-name">{clinicUser.get('clinic').get('name')}</div>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div className={`clinic-user-info ${user?.objectId == clinicUser.get('clinic').get('createdBy').id ? (
                                                            clinicUser.get('clinic').get('isPro') ? 'blue' : 'green'
                                                        ) : 
                                                        clinicUser.get('accepted') === true ? 'gray' : 'orange'}`}>
                    {user?.objectId == clinicUser.get('clinic').get('createdBy').id ? 
                    <IconOwner className="me-2" /> : <IconInvite className="me-2" />}
                    {user?.objectId == clinicUser.get('clinic').get('createdBy').id ? 'Minha Clínica' : 
                    clinicUser.get('accepted') === true ? 'Convite Aceito' :
                    clinicUser.get('accepted') === false ? 'Convite Recusado' : 'Convite Pendente' /* No App.js está filtrando para ser apenas accepted = true, esses 2 é apenas por precaução */
                    }</div>
                </div>
            </div>
            {clinicUser.get('accepted') === undefined && 
            <>
                <div className={`row px-3 ${open ? 'pb-3' : 'pb-0'}`} style={{transition: 'padding .3s'}}>
                    <div className="col">
                        {loadingInvite ? 
						<div className="data-loading pt-5">
							<CircularProgress />
						</div> : 
                        <VSAccordionSimple
                            className="select-clinic-pos"
                            expanded={open}>
                            <div className="row py-2">
                                <div className="col-12">
                                    <div className="select-clinic-invite-text">Você foi convidado(a) para participar da Clínica <b>{clinicUser.get('clinic').get('name')}</b> por <b>{clinicUser.get('clinic').get('createdBy').get('fullName')}</b>.</div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="select-clinic-actions mt-2">
                                    <button className="btn-decline" onClick={e => onDecline(e)}>
                                        Recusar Convite
                                    </button>
                                    <button className="btn-accept" onClick={e => onAccept(e)}>
                                        Aceitar Convite
                                    </button>
                                </div>
                            </div>
                        </VSAccordionSimple>}
                    </div>
                </div>
            </>}
        </div>
    )
}

export default BoxSelectClinic;