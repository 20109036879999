// utils for Service like getService, deleteService, getCategories, etc
import VSModal from '../../components/vsModal/VSModal';
import { Filter_Limit } from '../../constants';
import { diacriticSensitive } from '../../utils';

export const convertMinutesInHoursWithStartPad = (minutes, pad = 2) => {
    return String(Math.floor(minutes / 60)).padStart(pad, "0")
}

export const minutesWithStartPad = (minutes, pad = 2) => {
    return String(minutes % 60).padStart(pad, "0")
}

export const showMinutesAsTimeFormatted = (minutes) => {
    const hour = Math.floor(minutes / 60)
    const minute = minutes % 60
    const formatted = []
    if (hour > 0)
        formatted.push(`${hour} hora${hour > 1 ? "s" : ""}`)
    if (minute > 0)
        formatted.push(`${minute} minuto${minute > 1 ? "s" : ""}`)
    return formatted.length ? formatted.join(" e ") : "Não informado"
}

export const getService = (serviceId, clinicObject) => {
    return new Promise((resolve, reject) => {
        var queryService = new Parse.Query("MRService");
        queryService.equalTo("clinic", clinicObject);
        queryService.equalTo("isDeleted", false);
        queryService.include("supplies.object")
        queryService.get(serviceId)
        .then(_service => {
            resolve(_service)
        })
        .catch(error => {
            console.error(error);
            reject("Ocorreu algum erro ao tentar buscar o serviço")
        })
    })
}

let categories = [];
export const addCategory = (category) => {
    if(!categories || categories.length <= 0 || categories.includes(category)){
        return;
    }
    categories.push(category);
}
export const getCategories = (clinicObject) => {
    return new Promise((resolve, reject) => {
        if(categories && categories.length > 0){
            resolve(categories);
            return;
        }

        var queryDefaultCategories = new Parse.Query("MRCategory");
        queryDefaultCategories.doesNotExist("clinic");

        var queryClinicCategories = new Parse.Query("MRCategory");
        queryClinicCategories.equalTo("clinic", clinicObject);
        queryClinicCategories.equalTo("isDeleted", false);
        
        var queryCategories = Parse.Query.or(
            queryDefaultCategories,
            queryClinicCategories
        );
        queryCategories.ascending('categoryName')

        queryCategories.find()
        .then(_categories => {
            categories = _categories;
            resolve(_categories)
        })
        .catch(error => {
            console.error(error);
            reject("Ocorreu algum erro ao tentar buscar a categoria")
        })
    })
}

var searchCategoriesWithDelay = null;
export const searchCategories = (categoryName, clinicObject) => {
    return new Promise((resolve, reject) => {
        clearInterval(searchCategoriesWithDelay);
        searchCategoriesWithDelay = setTimeout(() => {
            var queryDefaultCategories = new Parse.Query("MRCategory");
            queryDefaultCategories.doesNotExist("clinic");

            var queryClinicCategories = new Parse.Query("MRCategory");
            queryClinicCategories.equalTo("clinic", clinicObject);
            queryClinicCategories.equalTo("isDeleted", false);
            
            const queryCategories = Parse.Query.or(
                queryDefaultCategories,
                queryClinicCategories
            )
            if (categoryName) {
                queryCategories.matches("categoryName", diacriticSensitive(categoryName), "im");
            }
            queryCategories.ascending("categoryName")
            queryCategories.limit(Filter_Limit)
            queryCategories.withCount()
            queryCategories.find()
            .then(_categories => {
                categories = _categories;
                resolve(_categories)
            })
            .catch(error => {
                console.error(error);
                reject("Ocorreu algum erro ao tentar buscar a categoria")
            })
        }, 300);
    })
}

let services = [];
var searchServicesWithDelay = null;
export const searchServices = (serviceName, clinicObject) => {
    return new Promise((resolve, reject) => {
        clearInterval(searchServicesWithDelay);
        searchServicesWithDelay = setTimeout(() => {
            var queryServices = new Parse.Query("MRService");
            queryServices.equalTo("clinic", clinicObject);
            if (serviceName) {
                queryServices.matches("serviceName", diacriticSensitive(serviceName), "im");
            }
            queryServices.ascending("serviceName")
            queryServices.limit(Filter_Limit)
            queryServices.withCount()
            queryServices.find()
            .then(_services => {
                services = _services;
                resolve(_services)
            })
            .catch(error => {
                console.error(error);
                reject("Ocorreu algum erro ao tentar buscar o serviço")
            })
        }, 300);
    })
}

export const serviceActiveOptions = [
    {
        value: true,
        label: "Ativo",
        long: "Serviço Ativo"
    },
    {
        value: false,
        label: "Inativo",
        long: "Serviço Inativo"
    }
]

export const deleteCategory = (category, callback) => {
    Swal.fire({
        // allowOutsideClick: false,
        showConfirmButton: false,
        customClass: {
            popup: 'p-0',
            content: 'p-0',
        },
        html: (
            <VSModal
                title="Tem certeza que deseja excluir esta categoria?"
                text="Os dados não poderão ser recuperados e esta operação não poderá ser desfeita"
                options={[
                    {
                        type: "cancel",
                        text: "Cancelar Operação",
                        action: () => {
                            Swal.close()
                        }
                    },
                    {
                        type: "confirm",
                        text: "Excluir categoria",
                        action: (selected) => {
							category.set("isDeleted", true)
							category.save()
							.then(_category => {
								Swal.fire(
									'Excluído!',
									'A categoria foi excluída com sucesso.',
									'success'
								)
								.then(_ => {
									callback && callback()
								})
							})
							.catch(error => {
								console.error(error)
								Swal.fire(
									'Desculpe',
									'Ocorreu algum erro ao tentar excluir a categoria',
									'error'
								)
							})
                        }
                    }
                ]}
            />
        )
    })
}