import React from 'react';

import { ReactComponent as IconPrimaryUserO } from "../../assets/icon-primary-user-o.svg"
import { ReactComponent as IconPrimaryEdit } from "../../assets/icon-primary-edit.svg"
import { ReactComponent as IconPrimaryTrash } from "../../assets/icon-primary-trash.svg"

import "./CardOwner.scss"

const CardOwner = (props) => {

    const {
        owner,
        onClick,
        onEdit,
        onDelete,
        styles
    } = props

    return (
        <div id="card-owner" className="row align-items-center" style={styles && styles.root}>
            <div className="col" onClick={() => onClick && onClick(owner.id)}>
                <div className="row align-items-center cursor-pointer">
                    <div className="col-auto mb-md-2 mb-lg-0">
                        <div className="card-icon">
                            <IconPrimaryUserO width="40" height="40" />
                        </div>
                    </div>
                    <div className="col px-0 mb-md-2 mb-lg-0">
                        <h5 className="card-title">
                            { owner.get("name") }
                            { onClick && (
                                <div className="d-inline">
                                    &nbsp;-&nbsp;
                                    <span className="card-action">VER PERFIL</span>
                                </div>
                            )}
                        </h5>
                        <b>Telefone:</b> { owner.get("phone") || "Não informado" }<br/>
                        <b>CPF:</b> { owner.get("cpf") || "Não informado" }
                    </div>
                </div>
            </div>
            <div className="col-auto mx-auto">
                { onDelete && (
                    <button className="btn-outline-submit btn-square me-3" onClick={() => onDelete && onDelete(owner.id)}>
                        <IconPrimaryTrash />
                        Excluir
                    </button>
                )}
                { onEdit && (
                    <button className="btn-outline-submit btn-square" onClick={() => onEdit && onEdit(owner.id)}>
                        <IconPrimaryEdit />
                        Editar
                    </button>
                )}
            </div>
        </div>
    )
}

export default CardOwner;