import { TextField } from "@mui/material"

const ManufacturingBatchPartField = (props) => {

    const {
        manufacturingBatchPart,
        setManufacturingBatchPart
    } = props

    return (
        <div className="row">
            <div className="col-12">
                <div className="input-subtitle mb-1">
                    Número de Partida
                </div>
            </div>
            <div className="col-12">
                <TextField
                    placeholder="000/00"
                    value={manufacturingBatchPart}
                    onChange={({ target: { value } }) => setManufacturingBatchPart(value || "")}
                    helperText={<span>Campo Opcional</span>}
                    size="small"
                    variant="outlined"
                    fullWidth
                />
            </div>
        </div>
    )
}

export default ManufacturingBatchPartField