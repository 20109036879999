import React, { useEffect, useState } from "react";

import { ReactComponent as IconFilter } from "../../../../assets/icon-filter.svg"
import { ReactComponent as IconFilterDisabled } from "../../../../assets/icon-filter-disabled.svg"
import { ReactComponent as IconVaccine } from "../../../../assets/icon-vaccine.svg"

import './VaccineAppendFilter.scss'
import {
    VACCINE_STATE_APPLIED, VACCINE_STATE_SCHEDULED, VACCINE_STATE_LATE,
    vaccineStateOptions
} from "./VaccineList";

const VaccineAppendFilter = props => {

    const {
        disabled,
        amount,
        onClick
    } = props


    // useEffect(() => {
    //     if(appliedDisabled){
    //         setLateDisabled(false)
    //         setScheduledDisabled(false)
    //     }
    // }, [ appliedDisabled ])

    // useEffect(() => {
    //     if(lateDisabled){
    //         setAppliedDisabled(false)
    //         setScheduledDisabled(false)
    //     }
    // }, [ lateDisabled ])

    // useEffect(() => {
    //     if(scheduledDisabled){
    //         setAppliedDisabled(false)
    //         setLateDisabled(false)
    //     }
    // }, [ scheduledDisabled ])

    return (
        <div className="vaccine-append-filter row mt-3 flex-direction-column flex-direction-md-row">
            <div className="col-12 d-flex flex-column col-md pe-md-1">
                <div 
                    onClick={onClick.applied} 
                    className={`card-vaccine-filter flex-grow-1 d-flex align-items-center ${disabled.applied ? 'selected' : ''}`}>
                    <div className="row align-items-center gx-0 w-100 flex-nowrap">
                        <div className="col-auto me-2">
                            <IconVaccine width="32" height="32" fill="var(--vsprontuario-primary-color-dark)" />
                        </div>
                        <div className="col">
                            <span className="card-vaccine-amount me-1">{amount.applied}</span> <span className="card-vaccine-text vstext-ellipsis">Aplicadas</span>
                        </div>
                        <div className="col-auto">
                            {disabled.applied ?
                            <IconFilterDisabled width="32" height="32" fill="var(--vsprontuario-primary-color)" /> :
                            <IconFilter width="32" height="32" fill="var(--vsprontuario-gray-color-light)" />}
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12 d-flex flex-column col-md mt-3 mt-md-0 pe-md-1">
                <div 
                    onClick={onClick.late} 
                    className={`card-vaccine-filter flex-grow-1 d-flex align-items-center ${disabled.late ? 'selected' : ''}`}>
                    <div className="row align-items-center gx-0 w-100 flex-nowrap">
                        <div className="col-auto me-2">
                            <IconVaccine width="32" height="32" fill="var(--vsprontuario-negative-color)" />
                        </div>
                        <div className="col">
                            <span className="card-vaccine-amount me-1">{amount.late}</span> <span className="card-vaccine-text vstext-ellipsis">Atrasadas</span>
                        </div>
                        <div className="col-auto">
                            {disabled.late ?
                            <IconFilterDisabled width="32" height="32" fill="var(--vsprontuario-primary-color)" /> :
                            <IconFilter width="32" height="32" fill="var(--vsprontuario-gray-color-light)" />}
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12 d-flex flex-column col-md mt-3 mt-md-0">
                <div 
                    onClick={onClick.scheduled} 
                    className={`card-vaccine-filter flex-grow-1 d-flex align-items-center ${disabled.scheduled ? 'selected' : ''}`}>
                    <div className="row align-items-center gx-0 w-100 flex-nowrap">
                        <div className="col-auto me-2">
                            <IconVaccine width="32" height="32" fill="var(--vsprontuario-secondary-color)" />
                        </div>
                        <div className="col">
                            <span className="card-vaccine-amount me-1">{amount.scheduled}</span> <span className="card-vaccine-text vstext-ellipsis">Programadas</span>
                        </div>
                        <div className="col-auto">
                            {disabled.scheduled ?
                            <IconFilterDisabled width="32" height="32" fill="var(--vsprontuario-primary-color)" /> :
                            <IconFilter width="32" height="32" fill="var(--vsprontuario-gray-color-light)" />}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VaccineAppendFilter