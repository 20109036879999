import React from "react"

import { ReactComponent as IconAppointment } from '../../assets/icon-patient-appointment.svg'
import { ReactComponent as IconAttachment } from '../../assets/icon-patient-attachment.svg'
import { ReactComponent as IconDrug } from '../../assets/icon-patient-drug.svg'
import { ReactComponent as IconExam } from '../../assets/icon-patient-exam.svg'
import { ReactComponent as IconPrescription } from '../../assets/icon-patient-prescription.svg'
import { ReactComponent as IconReturn } from '../../assets/icon-return.svg'
import { ReactComponent as IconSurgery } from '../../assets/icon-patient-surgery.svg'
import { ReactComponent as IconTerm } from '../../assets/icon-patient-term.svg'
import { ReactComponent as IconVaccine } from '../../assets/icon-patient-vaccine.svg'
import { ReactComponent as IconWeight } from '../../assets/icon-patient-weight.svg'

import AppointmentView from '../patient/tabs/appointment/AppointmentView'
import AppointmentDrugView from '../patient/tabs/appointmentDrug/AppointmentDrugView'
import AttachmentView from '../patient/tabs/attachment/AttachmentView'
import ExamView from '../patient/tabs/exam/ExamView'
import PrescriptionView from '../patient/tabs/prescription/PrescriptionView'
import ReturnView from '../patient/tabs/return/ReturnView'
import SurgeryView from '../patient/tabs/surgery/SurgeryView'
import TermView from '../patient/tabs/term/TermView'
import VaccineListView from '../patient/tabs/vaccine/VaccineListView'
import WeightView from '../patient/tabs/weight/WeightView'
import { ACTION_CREATE, ACTION_DELETE, ACTION_UPDATE } from "../../constants"

export const actions = [
    ACTION_CREATE,
    ACTION_UPDATE,
    ACTION_DELETE
]

export const items = [
    {
        value: "Attachment",
        label: "Anexos",
        singular: "Anexo",
        icon: (color = "var(--vsprontuario-gray-color)") => <IconAttachment width="24px" height="24px" fill={color} />,
        view: AttachmentView
    },
    {
        value: "Term",
        label: "Atestados/Termos",
        singular: "Atestado/Termo",
        icon: (color = "var(--vsprontuario-gray-color)") => <IconTerm width="24px" height="24px" fill={color} />,
        view: TermView
    },
    {
        value: "Surgery",
        label: "Cirurgias",
        singular: "Cirurgia",
        icon: (color = "var(--vsprontuario-gray-color)") => <IconSurgery width="24px" height="24px" fill={color} />,
        view: SurgeryView
    },
    {
        value: "Appointment",
        label: "Consultas",
        singular: "Consulta",
        icon: (color = "var(--vsprontuario-gray-color)") => <IconAppointment width="24px" height="24px" fill={color} />,
        view: AppointmentView
    },
    {
        value: "ExamRequest",
        label: "Exames",
        singular: "Solicitação de Exame",
        icon: (color = "var(--vsprontuario-gray-color)") => <IconExam width="24px" height="24px" fill={color} />,
        view: ExamView
    },
    {
        value: "ExamResult",
        label: "Resultado de Exames",
        singular: "Resultado de Exame",
        icon: (color = "var(--vsprontuario-gray-color)") => <IconExam width="24px" height="24px" fill={color} />,
        view: ExamView
    },
    {
        value: "AppointmentDrug",
        label: "Medicamentos",
        singular: "Medicamento",
        icon: (color = "var(--vsprontuario-gray-color)") => <IconDrug width="24px" height="24px" fill={color} />,
        view: AppointmentDrugView
    },
    {
        value: "Weight",
        label: "Pesos",
        singular: "Peso",
        icon: (color = "var(--vsprontuario-gray-color)") => <IconWeight width="24px" height="24px" fill={color} />,
        view: WeightView
    },
    {
        value: "Prescription",
        label: "Prescrições",
        singular: "Prescrição",
        icon: (color = "var(--vsprontuario-gray-color)") => <IconPrescription width="24px" height="24px" fill={color} />,
        view: PrescriptionView
    },
    {
        value: "Return",
        label: "Retornos",
        singular: "Retorno",
        icon: (color = "var(--vsprontuario-gray-color)") => <IconReturn width="24px" height="24px" fill={color} />,
        view: ReturnView
    },
    {
        value: "Vaccine",
        label: "Vacinas",
        singular: "Vacina",
        icon: (color = "var(--vsprontuario-gray-color)") => <IconVaccine width="24px" height="24px" fill={color} />,
        view: VaccineListView
    },
]

export const getItemFromParseObject = (parseObject) => {
    let item = items.filter(item => item.value === parseObject.className)[0]
    if (item) {
        item.object = parseObject
    }
    return item
}