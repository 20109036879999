import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux';
import TextField from '@mui/material/TextField';

import './HistorySelectPatientOrOwner.scss'
import { CircularProgress, InputAdornment } from '@mui/material';
import VSDrawer from '../../components/vsDrawer/VSDrawer';

import { ReactComponent as IconAddO } from "../../assets/icon-primary-add-o.svg"
import { ReactComponent as IconArrowLeft } from "../../assets/icon-arrow-left.svg"
import { ReactComponent as IconSearch } from "../../assets/icon-search.svg"
import { ReactComponent as IconUserO } from "../../assets/icon-user-o.svg"
import { 
    diacriticSensitive
} from '../../utils'

import BoxOwner from '../owner/BoxOwner';
import VSEmpty from '../../components/vsEmpty/VSEmpty';
import BoxPatient from '../patient/BoxPatient';
import VSAccordionSimple from '../../components/vsAccordion/VSAccordionSimple';

var searchBy = ""

const HistorySelectPatientOrOwner = (props) => {

    const {
        text,
        clinic,
        history
    } = props

    const searchWithDelay = useRef(null)

    const [ query, setQuery ] = useState("")
    const [ owners, setOwners ] = useState([])
    const [ patients, setPatients ] = useState([])
    const [ hasPatient, setHasPatient ] = useState(true)
    const [ loading, setLoading ] = useState(false)

    const findOwners = () => {
        return new Promise((resolve, reject) => {
            let queryByName = new Parse.Query("Owner")
            queryByName.matches("name", diacriticSensitive(query), "im")

            let queryByCpf = new Parse.Query("Owner")
            queryByCpf.matches("cpf", diacriticSensitive(query), "im")

            let queryByPhone = new Parse.Query("Owner")
            queryByPhone.matches("phone", diacriticSensitive(query), "im")

            const mainQuery = Parse.Query.or(
                queryByName,
                queryByCpf,
                queryByPhone
            )
            mainQuery.equalTo("clinic", clinic.object);
            mainQuery.equalTo("isDeleted", false);
            mainQuery.ascending("name");
            mainQuery.find()
            .then(owners => {
                if (searchBy == query) {
                    resolve(owners)
                }
            }).catch(error => {
                console.error(error)
                reject(error)
            })
        })
    }

    const findPatients = () => {
        return new Promise((resolve, reject) => {
            const mainQuery = new Parse.Query("Patient")
            mainQuery.equalTo("clinic", clinic.object);
            mainQuery.equalTo("isDeleted", false);
            mainQuery.matches("name", diacriticSensitive(query), "im")
            mainQuery.ascending("name");
            mainQuery.find()
            .then(patients => {
                if (searchBy == query) {
                    resolve(patients)
                }
            }).catch(error => {
                console.error(error)
                reject(error)
            })
        })
    }

    useEffect(() => {
        searchWithDelay.current = setTimeout(() => {
            setLoading(true)
            setOwners([])
            setPatients([])
    
            searchBy = query
            if (query) {
                Promise.all([ findOwners(), findPatients() ])
                .then(([ _owners, _patients ]) => {
                    // let _results = _owners.concat(_patients)
                    // _results.sort((a, b) => {
                    //     return a.get("name").localeCompare(b.get("name"))
                    // })
                    setOwners(_owners)
                    setPatients(_patients)
                    setLoading(false)
                })
            }
        }, 300)
        return () => {
            clearInterval(searchWithDelay.current)
        }
    }, [ query ])

    useEffect(() => {
        if (clinic && clinic.object) {
            setLoading(true)
            const patientTotal = new Parse.Query("Patient");
            patientTotal.equalTo("clinic", clinic.object);
            patientTotal.equalTo("isDeleted", false);
            patientTotal.count()
            .then(_total => {
                setHasPatient(_total > 0)
                setLoading(false)
            })
            .catch(error => console.error(error))
        }
    }, [ clinic ])

    return (
        <VSDrawer
            id="history-select-patient-or-owner"
            width="75%"
            title={"Compartilhar Histórico"}
            onCancel={() => history.goBack()}
            onClose={() => history.goBack()}
            VSDrawerFooterProps={{
                show: false
            }}
        >
            <div className="vssearch">
                <div className="vssearch-area">
                    <TextField
                        className="vssearch-input"
                        placeholder="Buscar por Animal ou um(a) Tutor(a) pelo Nome, CPF ou Telefone"
                        variant="outlined"
                        size="small"
                        value={query}
                        onChange={({ target: { value} }) => setQuery(value)}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">
                                <IconSearch width="20px" height="20px" fill="#888888" />
                            </InputAdornment>,
                        }}
                        fullWidth
                    />
                </div>
                { hasPatient ? (
                    query ? (
                        loading ? (
                            <div className="mt-4 data-loading">
                                <CircularProgress />
                            </div>
                        ) : (
                            <div className="vssearch-list">
                                <div className="row pt-2">
                                    <div className="col-12">
                                        <VSAccordionSimple
                                            expanded={true}
                                            header={expanded => (
                                                `Tutores (${owners.length})`
                                            )}
                                        >
                                            <div className="row">
                                                <div className="col-12">
                                                    { owners && owners.length > 0 ? (
                                                        owners.map((owner, index) => (
                                                            <BoxOwner
                                                                key={index}
                                                                owner={owner}
                                                                history={history}
                                                                boxPatientProps={{
                                                                    noAction: true
                                                                }}
                                                                actions={(
                                                                    <div className="col-auto">
                                                                        <button className="vsbox-btn vsbox-btn-primary" onClick={() => history.replace(`/tutor/${owner.id}/compartilhar`)}>
                                                                            SELECIONAR TUTOR(A)
                                                                        </button>
                                                                    </div>
                                                                )}
                                                            />
                                                        ))
                                                    ) : (
                                                        <div className="vssearch-empty">
                                                            Nenhum(a) Tutor(a) Encontrado(a)
                                                        </div>
                                                    )}
                                                    <hr/>
                                                    <button
                                                        className="vssearch-new"
                                                        onClick={() => history.push('/tutor/form/novo')}
                                                        style={{ marginBottom: '-8px' }}
                                                    >
                                                        Cadastrar um(a) Novo(a) Tutor(a)
                                                        <IconArrowLeft width="16px" height="16px" className="mt-1" />
                                                    </button>
                                                </div>
                                            </div>
                                        </VSAccordionSimple>
                                    </div>
                                </div>
                                <hr />
                                <div className="row">
                                    <div className="col-12">
                                        <VSAccordionSimple
                                            expanded={true}
                                            header={expanded => (
                                                `Animais (${ patients.length })`
                                            )}
                                        >
                                            <div className="row">
                                                <div className="col-12">
                                                    { patients && patients.length > 0 ? (
                                                        patients.map((patient, index) => (
                                                            <BoxPatient
                                                                key={index}
                                                                patient={patient}
                                                                history={history}
                                                                actions={(
                                                                    <div className="col-auto">
                                                                        <button className="vsbox-btn vsbox-btn-primary" onClick={() => history.replace(`/animal/${patient.id}/historico/compartilhar`)}>
                                                                            SELECIONAR ANIMAL
                                                                        </button>
                                                                    </div>
                                                                )}
                                                            />
                                                        ))
                                                    ) : (
                                                        <div className="vssearch-empty">
                                                            Nenhum Animal Encontrado
                                                        </div>
                                                    )}
                                                    <hr/>
                                                    <button
                                                        className="vssearch-new"
                                                        onClick={() => history.push('/tutor/selecionar/animal/novo')}
                                                    >
                                                        Cadastrar Novo Animal
                                                        <IconArrowLeft width="16px" height="16px" className="mt-1" />
                                                    </button>
                                                </div>
                                            </div>
                                        </VSAccordionSimple>
                                    </div>
                                </div>
                            </div>
                        )
                    ) : (
                        <div className="vssearch-default">
                            { text }
                        </div>
                    )
                ) : (
                    <VSEmpty
                        icon={<IconUserO width="32px" height="32px" />}
                        title="Você ainda não possui nenhum animal cadastrado no Prontuário"
                        text="Cadastre o primeiro animal e comece a aproveitar todos os benefícios de gestão de clientes do Prontuário Vet Smart"
                    >
                        <button
                            id="new-owner-link"
                            className="vsbox-btn vsbox-btn-primary"
                            onClick={() => history.push(`/tutor/selecionar/animal/novo`)}
                        >
                            <IconAddO width="14" height="14" className="me-2" />
                            Cadastrar novo animal
                        </button>
                    </VSEmpty>
                )}
            </div>
        </VSDrawer>
    )
}

const mapStateToProps = state => {
    return {
        clinic: state.clinic,
        onboarding: state.onboarding
    };
};
  
export default connect(mapStateToProps)(HistorySelectPatientOrOwner);