import React, { useEffect, useRef } from 'react'
import { connect } from 'react-redux'

import { ReactComponent as IconAttachment } from '../../../../assets/icon-patient-attachment.svg'
import { ReactComponent as IconAdd } from "../../../../assets/icon-primary-add-o.svg"

import { ACL, OrderBy_Oldest, OrderBy_Newest } from '../../../../constants'

import AttachmentView from './AttachmentView'
import VSList from '../../../../components/vsList/VSList'
import VSEmpty from '../../../../components/vsEmpty/VSEmpty'

import './AttachmentList.scss'
const AttachmentList = (props) => {

    const {
        clinic,
        patient,
        history,
        permissions
    } = props

    const rowsPerPage = 5

    const filter = useRef(null)
    const isMounted = useRef(null)

    const getAttachment = (_, dateFrom, dateTo, orderBy, page) => {
        return new Promise((resolve, reject) => {
            var query = new Parse.Query("Attachment")
            query.equalTo("clinic", clinic.object);
            query.equalTo("isDeleted", false);
            query.equalTo("patient", patient);
            if (dateFrom) {
                query.greaterThanOrEqualTo("documentDate", dateFrom)
            }
            if (dateTo) {
                query.lessThanOrEqualTo("documentDate", dateTo)
            }
            switch (orderBy) {
                case OrderBy_Oldest:
                    query.ascending("documentDate");
                    break;
                default:
                    query.descending("documentDate");
            }
            query.addAscending("objectId")
            query.count()
            .then(_total => {
                query.skip((page - 1) * rowsPerPage)
                query.limit(rowsPerPage)
                query.include("createdBy")
                query.include("changedBy")
                query.find()
                .then(_attachments => {
                    if (isMounted.current) {
                        resolve({
                            items: _attachments,
                            total: _total
                        })
                    }
                })
                .catch(error => {
                    console.error(error)
                    reject(error)
                })
            })
            .catch(error => {
                console.error(error)
                reject(error)
            })
        })
    }
    
    const onChange = () => {
        if (filter.current) {
            filter.current.onChange()
        }
    }

    useEffect(() => {
        if (permissions && !permissions.check(ACL.ATTACHMENT, ACL.Rule.VIEW)) {
            permissions.error()
        }
    }, [ permissions ])

    useEffect(() => {
        isMounted.current = true
        document.addEventListener("Attachment__getList", onChange)
        return () => { 
            isMounted.current = false
            document.removeEventListener("Attachment__getList", onChange)
        }
    }, [])

    return (
        <div id="patient-attachment">
            <div className="col-12">
                <VSList
                    ref={filter}
                    title={{
                        text: "Anexos Registrados"
                    }}
                    button={permissions?.check(ACL.ATTACHMENT, ACL.Rule.EDIT) && {
                        text: "Novo anexo",
                        onClick: () => history.push(`/animal/${ patient.id }/anexo/novo`)
                    }}
                    interval={{
                        start: {
                            text: "Início",
                            value: null
                        },
                        end: {
                            text: "Término",
                            value: null
                        }
                    }}
                    order={{
                        options: [
                            OrderBy_Newest,
                            OrderBy_Oldest
                        ],
                        value: OrderBy_Newest
                    }}
                    pagination={{
                        rowsPerPage: rowsPerPage
                    }}
                    onChange={({ _, _start, _end, _orderBy, _page }) => {
                        return getAttachment(null, _start, _end, _orderBy, _page)
                    }}
                    renderItem={(item) => (
                        <AttachmentView
                            object={item}
                            history={history}
                        />
                    )}
                    renderEmpty={() => (
                        <VSEmpty
                            icon={<IconAttachment width="32px" height="32px" />}
                            title="Você ainda não possui nenhum registro de anexo para este animal"
                            text="Anexe o primeiro documento e comece a acompanhar de perto a evolução do seu paciente"
                        >
                            { permissions?.check(ACL.ATTACHMENT, ACL.Rule.EDIT) && (
                                <button
                                    id="new-attachment-link"
                                    className="vsbox-btn vsbox-btn-primary"
                                    onClick={() => history.push(`/animal/${ patient.id }/anexo/novo`)}
                                >
                                    <IconAdd width="14" height="14" className="me-2" />
                                    Cadastrar novo anexo
                                </button>
                            )}
                        </VSEmpty>
                    )}
                    renderNotFound={() => (
                        <VSEmpty
                            icon={<IconAttachment width="32px" height="32px" />}
                            title="Não encontramos nenhum resultado para a sua busca"
                            text="Tente refazer sua busca utilizando outro período ou crie um novo cadastro utilizando o botão abaixo"
                        >
                            { permissions?.check(ACL.ATTACHMENT, ACL.Rule.EDIT) && (
                                <button
                                    id="new-attachment-link"
                                    className="vsbox-btn vsbox-btn-primary"
                                    onClick={() => history.push(`/animal/${ patient.id }/anexo/novo`)}
                                >
                                    <IconAdd width="14" height="14" className="me-2" />
                                    Cadastrar novo anexo
                                </button>
                            )}
                        </VSEmpty>
                    )}
                />
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        clinic: state.clinic,
        permissions: state.permissions
    };
};
  
export default connect(mapStateToProps)(AttachmentList)