import React, { useEffect, useRef, useState } from "react"
import { connect } from "react-redux"
import { useParams } from "react-router"

import { CircularProgress, SwipeableDrawer } from "@mui/material"

import { ReactComponent as IconArrowLeft } from "../../assets/icon-arrow-left.svg"

import { APP_NAME, APP_DEVICE, APP_VERSION, ACL } from '../../constants'
import { addEvent, canUserEditVeterinaryDocuments } from "../../utils"

import SettingSignatureForm from "../setting/SettingSignatureForm"
import Blocked from "../blocked/Blocked"
import VSPage from "../../components/vsPage/VSPage"

import "./Prescription.scss"
const Prescription = (props) => {

    const {
        id_animal,
        id_prescricao
    } = useParams()

    const {
        user,
        clinic,
        history,
        permissions
    } = props

    const _widgetInitialized = useRef(null)

    const [ loading, setLoading ] = useState(false)
    const [ showSignatureForm, setShowSignatureForm ] = useState(false)
    const [ loadingWidget, setLoadingWidget ] = useState(true)
    const [ loadingSave, setLoadingSave ] = useState(false)
    const [ patient, setPatient ] = useState(null)
    const [ prescription, setPrescription ] = useState(null)

    const canEdit = canUserEditVeterinaryDocuments()
    const isValid = () => !loading && !loadingWidget && !loadingSave && canEdit

    const fieldsWithErrorText = () => {
        let errors = []
        if (loading) {
            errors.push(`Buscando a prescrição...`)
        }
        if (loading) {
            errors.push(`Inicializando a prescrição...`)
        }
        if (loadingSave) {
            errors.push(`Salvando a prescrição...`)
        }
        if (!canEdit) {
            errors.push("Somente Médicos Veterinários com CRMV podem usar a prescrição.")
        }
        if (!permissions?.check(ACL.PRESCRIPTION, ACL.Rule.EDIT, prescription)) {
            errors.push("Acesso negado")
        }
        return errors
    }

    const loadPrescriptionWidget = () => {
        if (canEdit) {
            const primaryColor = {
                light: "#F3FFF4",
                main: "#00C4B4",
                dark: "#00786E",
                contrastText: "#FFFFFF"
            }
            const secondaryColor = {
                light: "#F2FAFF",
                main: "#1993F7",
                dark: "#017EB2",
                contrastText: "#FFFFFF"
            }
            const mainButton = null

            let script = document.createElement("script")
            script.src = process.env.REACT_APP_WIDGET_PRESCRIPTION
            script.onload = function () {
                VSPrescriptionLoader.load().then(_ => {
                    _widgetInitialized.current = true
                    let params = {
                        primaryColor: primaryColor,
                        secondaryColor: secondaryColor,
                        mainButton: mainButton,
                        sessionToken: Parse.User.current().getSessionToken(),
                        platform: "web",
                        patient: {
                            objectId: patient.id
                        },
                        owner: {
                            objectId: patient.get("owner").id
                        },
                        clinic: {
                            objectId: clinic.objectId
                        },
                        store: clinic.store,
                        prescriptionId: prescription.id,
                        debug: false,
                        showDrugSidebarFullscreen: true,
                        hideSubmit: true,
                        events: {
                            onSubmit: (prescription) => {
                                setLoadingSave(false)
                                addEvent("TourPrescription__registerYourFirstPrescription", { patient: patient.id })
                                history.replace(`/prescricao/${prescription.id}`)
                            },
                            onSubmitError: (_) => {
                                setLoadingSave(false)
                            },
                            onRegistrationEditButtonClick: () => {
                                setShowSignatureForm(true)
                            }
                        }
                    }
                    VSPrescription.init("vs-prescription", params)
                    .then(_ => {
                        setLoadingWidget(false)
                    })
                    .catch(error => {
                        let message
                        switch (error) {
                            case "Timeout":
                                message = "Não foi possível carregar a prescrição, tente novamente mais tarde."
                                break;
                            case "Clinic is not equal":
                                message = "A prescrição não pertence à clínica selecionada."
                                break;
                            default:
                                message = "Acesso negado, por favor, tente recarregar a página."
                                break;
                        }
                        Swal.fire(
                            'Ooops...',
                            message,
                            'error'
                        )
                    })
                })
            }
            document.body.appendChild(script)
        }
    }

    useEffect(() => {
        if (clinic && clinic.isLoaded && id_animal) {
            setLoading(true)
            var queryPatient = new Parse.Query("Patient");
            queryPatient.equalTo("clinic", clinic.object);
            queryPatient.equalTo("isDeleted", false);
            queryPatient.get(id_animal)
            .then(_patient => {
                setPatient(_patient)
                if (id_prescricao !== "novo") {
                    var query = new Parse.Query("Prescription");
                    query.equalTo("clinic", clinic.object);
                    query.equalTo("isDeleted", false);
                    query.equalTo("patient", _patient)
                    query.get(id_prescricao)
                    .then(_prescription => {
                        if (_prescription) {
                            setPrescription(_prescription)
                        } else {
                            console.error(_prescription)
                            Swal.fire(
                                'Desculpe',
                                'Não encontramos a prescrição informada',
                                'error'
                            )
                        }
                        setLoading(false)
                    }).catch(error => {
                        console.error(error)
                        Swal.fire(
                            'Desculpe',
                            'Ocorreu algum erro ao tentar buscar a prescrição',
                            'error'
                        )
                        setLoading(false)
                    })
                } else {
                    let newPrescricao = new Parse.Object("Prescription")
                    newPrescricao.setACL(clinic.ACL)
                    newPrescricao.set("clinic", clinic.object)
                    newPrescricao.set("createdBy", Parse.User.current())
                    newPrescricao.set("createdApp", APP_NAME)
                    newPrescricao.set("createdDevice", APP_DEVICE)
                    newPrescricao.set("createdAppVersion", APP_VERSION)
                    newPrescricao.set("isDeleted", false)
                    newPrescricao.set("patient", _patient)
                    setPrescription(newPrescricao)
                    setLoading(false)
                }
            })
            .catch(error => {
                console.error(error)
                Swal.fire(
                    'Desculpe',
                    'Ocorreu algum erro ao tentar buscar o animal 1..',
                    'error'
                )
                setLoading(false)
            })
        }
    }, [ clinic ])

    useEffect(() => {
        if (prescription) {
            if (!permissions?.check(ACL.PRESCRIPTION, ACL.Rule.EDIT, prescription)) {
                permissions?.error()
                return
            }
            loadPrescriptionWidget()
        }
    }, [ prescription ])

    useEffect(() => {
        return () => {
            if (_widgetInitialized.current) {
                VSPrescription.destroy()
            }
        }
    }, [])

    const errors = fieldsWithErrorText()

    return (
        <div id="prescription">
            <VSPage
                loading={loading}
                title={`${ prescription && prescription.id ? "Editar" : "Nova" } prescrição`}
                subtitle={(
                    <div className="row align-items-center">
                        <div className="col-auto px-0">
                            <IconArrowLeft width="8px" height="12px" className="title-separator" />
                        </div>
                        <div className="col-auto">
                            <div className="subtitle">Salvar e visualizar a prescrição</div>
                        </div>
                        <div className="col"></div>
                    </div>
                )}
                cancel="Cancelar"
                onCancel={() => history.go(-1)}
                disabled={!isValid()}
                errors={errors}
                submit={ !canEdit ? (
                    "Somente Médicos Veterinários com CRMV podem usar a prescrição." 
                ) : loading || loadingWidget ? (
                    "Carregando a prescrição..."
                ) : loadingSave ? (
                    "Salvando a prescrição..."
                ) : (
                    "Salvar e visualizar informações"
                )} 
                onSubmit={() => {
                    setLoadingSave(true)
                    VSPrescription.trigger("submit")
                }}
            >
                <div className="row">
                    <div className="col-12 text-center">
                        { canEdit ? (
                            <>
                                { loading && (
                                    <CircularProgress />
                                )}
                                <div id="vs-prescription"></div>
                            </>
                        ) : (
                            <Blocked />
                        )}
                    </div>
                </div>
            </VSPage>
            <SwipeableDrawer
                anchor="right"
                className="signature-form-wrap"
                open={showSignatureForm}
                onOpen={() => null}
                onClose={() => setShowSignatureForm(false)}
            >
                <SettingSignatureForm history={{ goBack: () => {
                    setShowSignatureForm(false) 
                    const registrationMAPA = Parse.User.current().get("registrationMAPA")
                    if (registrationMAPA) {
                        VSPrescription.trigger("registrationMAPA", registrationMAPA)
                    }
                }}} />
            </SwipeableDrawer>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        user: state.user,
        clinic: state.clinic,
        permissions: state.permissions,
    };
};

export default connect(mapStateToProps)(Prescription)