import React, { useEffect, useRef, useState } from "react"
import { useParams } from "react-router"
import { connect } from "react-redux"

import { ReactComponent as IconArrowLeft } from "../../assets/icon-arrow-left.svg"
import { CircularProgress } from "@mui/material"

import { addEvent, canUserEditVeterinaryDocuments, redirectToCheckout, saveTrackersProSubscription } from "../../utils"
import { ACL } from "../../constants"

import { DrawerSignatureForm } from "../setting/SettingSignatureForm"
import Blocked from "../blocked/Blocked"
import VSPage from "../../components/vsPage/VSPage"

import { APP_DEVICE, APP_NAME, APP_VERSION } from '../../constants';

import "./Preview.scss"
const Preview = (props) => {

    const {
        id_prescricao
    } = useParams()

    const {
        pro,
        clinic,
        history,
        permissions
    } = props

    const _widgetInitialized = useRef(null)

    const canShare = canUserEditVeterinaryDocuments()

    const [ loading, setLoading ] = useState(false)
    const [ showSignatureForm, setShowSignatureForm ] = useState(false)
    const [ prescription, setPrescription ] = useState(null)

    if (!permissions?.check(ACL.PRESCRIPTION, ACL.Rule.VIEW)) {
        permissions?.error()
    }

    const loadPreviewWidget = () => {
        if (canShare) {
            let script = document.createElement("script")
            script.src = process.env.REACT_APP_WIDGET_PREVIEW
            script.onload = function () {
                VSPreviewLoader.load().then(_ => {
                    _widgetInitialized.current = true
                    VSPreview.init("vs-preview", {
                        sessionToken: Parse.User.current().getSessionToken(),
                        platform: "web",
                        primaryColor: "#00C4B4",
                        secondaryColor: "#1993F7",
                        prescriptionId: prescription.id,
                        clinicId: clinic.objectId,
                        store: clinic.store,
                        linkToPhoneContact: permissions?.check(ACL.SETTING, ACL.Rule.EDIT, clinic.object) ? `${process.env.REACT_APP_URL}/configuracao-clinica/informacoes-de-documentos#numero-contato` : "",
                        debug: false,
                        events: {
                            onFinished: function (data) {
                                if (data && data.sendToWhatsappEnabled) {
                                    addEvent("TourShare__sendYourFirstDocumentByWhatsapp", {})
                                }
                            },
                            onPrescriptionEditButtonClick: function (prescription) {
                                history.push(`/prescricao/${prescription.patient.id}/${prescription.id}`)
                            },
                            onSettingsButtonClick: function (clinic) {
                                history.push('/clinica')
                            },
                            onSignatureButtonClick: function (user) {
                                setShowSignatureForm(true)
                            },
                            onProUpgrade: function() {
                                saveTrackersProSubscription(pro)
                                redirectToCheckout(clinic)
                            },
                            onPhoneUpdated: function (newPhone) {
                                return new Promise((resolve, reject) => {
                                    const owner = prescription.get("patient").get("owner")
                                    Parse.Cloud.run("saveOwnerForClinic", {
                                        data: {
                                            objectId: owner.id,
                                            phone: newPhone,
                                        },
                                        clinicId: clinic.id || clinic.objectId,
                                        appVersionName: APP_VERSION,
                                        deviceType: APP_DEVICE,
                                        appContentType: APP_NAME
                                    })
                                    // owner.save()
                                    .then(_ => {
                                        resolve()
                                    })
                                    .catch(error => {
                                        console.error(error)
                                        reject()
                                    })
                                })
                            },
                            onEmailUpdated: function (newEmail) {
                                return new Promise((resolve, reject) => {
                                    const owner = prescription.get("patient").get("owner")
                                    Parse.Cloud.run("saveOwnerForClinic", {
                                        data: {
                                            objectId: owner.id,
                                            email: newEmail,
                                        },
                                        clinicId: clinic.id || clinic.objectId,
                                        appVersionName: APP_VERSION,
                                        deviceType: APP_DEVICE,
                                        appContentType: APP_NAME
                                    })
                                    // owner.set("email", newEmail)
                                    // owner.save()
                                    .then(_ => {
                                        resolve()
                                    })
                                    .catch(error => {
                                        console.error(error)
                                        reject()
                                    })
                                })
                            }
                        }
                    })
                })
            }
            document.body.appendChild(script)
        }
    }

    useEffect(() => {
        if (clinic && clinic.isLoaded && id_prescricao) {
            setLoading(true)

            var query = new Parse.Query("Prescription");
            query.equalTo("clinic", clinic.object);
            query.equalTo("isDeleted", false);
            query.include("patient.owner");
            query.get(id_prescricao)
            .then(_prescription => {
                if (_prescription) {
                    setPrescription(_prescription)
                } else {
                    console.error(_prescription)
                    Swal.fire(
                        'Desculpe',
                        'Não encontramos a prescrição informada',
                        'error'
                    )
                }
                setLoading(false)
            }).catch(error => {
                console.error(error)
                Swal.fire(
                    'Desculpe',
                    'Ocorreu algum erro ao tentar buscar a prescrição',
                    'error'
                )
                setLoading(false)
            })
        }
    }, [ clinic ])

    useEffect(() => {
        if (prescription) {
            if (!permissions?.check(ACL.PRESCRIPTION, ACL.Rule.VIEW)) {
                permissions?.error()
                return
            }
            loadPreviewWidget()
        }
    }, [ prescription ])

    useEffect(() => {
        return () => {
            if (_widgetInitialized.current) {
                VSPreview.destroy()
            }
        }
    }, [])

    return (
        <div id="prescription_preview">
            <VSPage
                loading={loading}
                title={`${ prescription && prescription.id ? "Editar" : "Nova" } prescrição`}
                subtitle={(
                    <div className="row align-items-center">
                        <div className="col-auto px-0">
                            <IconArrowLeft width="8px" height="12px" className="title-separator" />
                        </div>
                        <div className="col-auto">
                            <div className="subtitle">Salvar e visualizar a prescrição</div>
                        </div>
                        <div className="col"></div>
                    </div>
                )}
                cancel="Fechar"
                onCancel={() => history.goBack()}
            >
                <div className="row">
                    <div className="col-12 px-0 text-center">
                        { canShare ? (
                            <>
                                { loading && (
                                    <CircularProgress />
                                )}
                                <div id="vs-preview"></div>
                            </>
                        ) : (
                            <Blocked />
                        )}
                    </div>
                </div>
            </VSPage>
            { showSignatureForm && (
                <DrawerSignatureForm history={{ goBack: () => {
                    setShowSignatureForm(false)
                    const user = Parse.User.current()
                    VSPreview.trigger("user", {
                        fullName: user.get("fullName"),
                        email: user.get("email"),
                        crmv: user.get("crmv"),
                        crmvStatedId: user.get("crmvStatedId"),
                        registrationMAPA: user.get("registrationMAPA")
                    })
                } }} />
            )}
        </div>
    )
}

const mapStateToProps = state => {
    return {
        pro: state.featuresPro,
        clinic: state.clinic,
        permissions: state.permissions
    };
};

export default connect(mapStateToProps)(Preview)