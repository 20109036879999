import React, { useImperativeHandle, forwardRef, useEffect } from 'react'

import {
    dayjs,
    mandatoryField,
    mandatoryFields,
    validateCPF,
    validateDate
} from '../../../../utils'

import { Checkbox, FormControlLabel, TextField } from '@mui/material';
import DateInput from '../../../../components/dateInput/DateInput';
import TextFieldMask from '../../../../components/textFieldMask/TextFieldMask';

const TermDeathForm = forwardRef((props, ref) => {

    const {
        authNecropsy,
        setAuthNecropsy,
        authStudy,
        setAuthStudy,
        authRemoveByMe,
        setAuthRemoveByMe,
        authRemoveByMeDays,
        setAuthRemoveByMeDays,
        authRemoveByOther,
        setAuthRemoveByOther,
        authRemoveByOtherDays,
        setAuthRemoveByOtherDays,
        authRemoveByCityHall,
        setAuthRemoveByCityHall,
        authRemoveByMeImmediately,
        setAuthRemoveByMeImmediately,
        authRemoveByOtherImmediately,
        setAuthRemoveByOtherImmediately,
        authName,
        setAuthName,
        errorAuthName,
        setErrorAuthName,
        helperAuthName,
        setHelperAuthName,
        authCpf,
        setAuthCpf,
        errorAuthCpf,
        setErrorAuthCpf,
        helperAuthCpf,
        setHelperAuthCpf,
        eventLocation,
        setEventLocation,
        errorEventLocation,
        setErrorEventLocation,
        helperEventLocation,
        setHelperEventLocation,
        eventDate,
        setEventDate,
        errorEventDate,
        setErrorEventDate,
        helperEventDate,
        setHelperEventDate,
        eventTime,
        setEventTime,
        eventHour,
        setEventHour,
        eventMinute,
        setEventMinute,
        eventDetail,
        setEventDetail,
        eventNotes,
        setEventNotes,
    } = props

    const handleEventTime = (_hour, _minute) => {
        const time = dayjs(`${_hour}:${_minute}`, "HH:mm")
        setEventHour(_hour)
        setEventMinute(_minute)
        if (time.isValid()) {
            setEventTime(time.format("HH:mm"))
        } else {
            setEventTime(null)
        }
    }

    useImperativeHandle(ref, () => ({
        fieldsWithErrorText(errors) {
            if (errorEventLocation) {
                errors.push("Cidade")
            }
            if (errorEventDate) {
                errors.push("Data")
            }
            if (errorAuthCpf) {
                errors.push("CPF")
            }
            if (errorAuthName) {
                errors.push("Nome")
            }
            return errors
        },
        isValid() {
            return !errorEventLocation && !errorEventDate && !errorAuthCpf && !errorAuthName
        },
        validate(isValid) {
            if (eventLocation) {
                setErrorEventLocation(false)
                setHelperEventLocation(mandatoryField)
            } else {
                isValid = false
                setErrorEventLocation(true)
                setHelperEventLocation("A cidade do óbito é obrigatória")
            }
    
            if (validateDate(eventDate)) {
                if (eventTime) {
                    setErrorEventDate(false)
                    setHelperEventDate(mandatoryFields)
                } else {
                    isValid = false
                    setErrorEventDate(true)
                    setHelperEventDate("O horário do óbito é obrigatório")
                }
            } else {
                isValid = false
                setErrorEventDate(true)
                setHelperEventDate("A data do óbito é obrigatória")
            }

            if (authRemoveByOther || authRemoveByOtherImmediately) {
                if (validateCPF(authCpf)) {
                    setErrorAuthCpf(false)
                    setHelperAuthCpf(mandatoryField)
                } else {
                    isValid = false
                    setErrorAuthCpf(true)
                    setHelperAuthCpf("O CPF informado não é válido")
                }

                if (authName && authName.length > 3) {
                    setErrorAuthName(false)
                    setHelperAuthName(mandatoryField)
                } else {
                    isValid = false
                    setErrorAuthName(true)
                    setHelperAuthName("O nome informado não é válido")
                }
            } else {
                setErrorAuthCpf(false)
                setHelperAuthCpf(mandatoryField)
                setErrorAuthName(false)
                setHelperAuthName(mandatoryField)
            }
    
            return isValid
        }
    }))

    useEffect(() => {
        if (eventTime) {
            const _time = dayjs(eventTime, "HH:mm")
            handleEventTime(_time.format("HH"), _time.format("mm"))
        }
    }, [])

    const authPerson = (
        <div className="row">
            <div className="col-12 col-md-8 mb-3 mb-md-0">
                <TextField
                    size="small"
                    variant="outlined"
                    className="w-100"
                    placeholder="Digite o Nome Aqui"
                    label="Nome do(a) Responsável"
                    value={authName}
                    onChange={({ target: { value } }) => setAuthName(value)}
                    error={errorAuthName}
                    helperText={helperAuthName}
                />
            </div>
            <div className="col-12 col-md-4">
                <TextFieldMask
                    type="tel"
                    mask='000.000.000-00'
                    size="small"
                    variant="outlined"
                    value={authCpf}
                    onAccept={(value) => setAuthCpf(value)}
                    className="w-100"
                    placeholder="000.000.000-00"
                    label="CPF do(a) Responsável"
                    error={errorAuthCpf}
                    helperText={helperAuthCpf}
                />
            </div>
        </div>
    )

    return (
        <div className="row pt-4">
            <div className="col">
                <div className="row mb-4">
                    <div className="col-12">
                        <div className="input-title input-height">Dados do óbito</div>
                    </div>
                    <div className="col-12 col-xl pe-3 mb-3 mb-xl-0">
                        <TextField
                            label="Cidade"
                            value={eventLocation}
                            onChange={({ target: { value } }) => setEventLocation(value)}
                            size="small"
                            variant="outlined"
                            error={errorEventLocation}
                            helperText={helperEventLocation}
                            fullWidth
                        />
                    </div>
                    <div className="col col-xl-auto pe-2" style={{ width: '200px' }}>
                        <DateInput
                            className="w-100 my-0"
                            placeholder="00/00/0000"
                            label="Data"
                            size="small"
                            variant="outlined"
                            value={eventDate || null}
                            error={errorEventDate}
                            helperText={helperEventDate}
                            onChange={(value) => {
                                setEventDate(value)
                            }}
                        />
                    </div>
                    <div className="col-auto px-0">
                        <div className="text-separator input-height">ÀS</div>
                    </div>
                    <div className="col-auto px-2" style={{ width: '80px' }}>
                        <TextFieldMask
                            type="tel"
                            size="small"
                            variant="outlined"
                            mask="00"
                            value={eventHour}
                            onChange={({ target: { value } }) => handleEventTime(value, eventMinute)}
                            className="w-100"
                            placeholder="00"
                            label="Hora"
                            error={errorEventDate}
                        />
                    </div>
                    <div className="col-auto px-0">
                        <div className="text-separator input-height">:</div>
                    </div>
                    <div className="col-auto ps-2" style={{ width: '80px' }}>
                        <TextFieldMask
                            type="tel"
                            size="small"
                            variant="outlined"
                            mask="00"
                            value={eventMinute}
                            onChange={({ target: { value } }) => handleEventTime(eventHour, value)}
                            className="w-100"
                            placeholder="00"
                            label="Min"
                            error={errorEventDate}
                        />
                    </div>
                </div>
                <div className="row mb-1">
                    <div className="col">
                        <div className="input-title input-height">Provável causa mortis (opcional)</div>
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col">
                        <TextField
                            size="small"
                            variant="outlined"
                            className="w-100"
                            placeholder="Provável causa da morte"
                            label="Causa Mortis"
                            value={eventDetail}
                            onChange={({ target: { value } }) => setEventDetail(value)}
                        />
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col">
                        <TextField
                            size="small"
                            variant="outlined"
                            className="w-100"
                            placeholder="Observações e informações complementares sobre a causa mortis"
                            label="Observações sobre a causa mortis"
                            value={eventNotes}
                            onChange={({ target: { value } }) => setEventNotes(value)}
                            multiline
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="input-title input-height">Orientações para destinação do corpo do animal (opcional)</div>
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className="col-12">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={authNecropsy}
                                    onChange={({ target: { checked }}) => setAuthNecropsy(checked)}
                                    color="primary"
                                />
                            }
                            label="Necropsia"
                        />
                    </div>
                    <div className="col-12">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={authStudy}
                                    onChange={({ target: { checked }}) => setAuthStudy(checked)}
                                    color="primary"
                                />
                            }
                            label="Doação para fins de ensino e pesquisa"
                        />
                    </div>
                    <div className="col-12">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={authRemoveByCityHall}
                                    onChange={({ target: { checked }}) => setAuthRemoveByCityHall(checked)}
                                    color="primary"
                                />
                            }
                            label="Remoção pela prefeitura"
                        />
                    </div>
                    <div className="col-12">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={authRemoveByMeImmediately}
                                    onChange={({ target: { checked }}) => setAuthRemoveByMeImmediately(checked)}
                                    color="primary"
                                />
                            }
                            label="Remoção imediata pela minha pessoa"
                        />
                    </div>
                    <div className="col-12">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={authRemoveByMe}
                                    onChange={({ target: { checked }}) => setAuthRemoveByMe(checked)}
                                    color="primary"
                                />
                            }
                            label={
                                <div className="input-height">
                                    Remoção pela minha pessoa em 
                                    <TextField
                                        type="tel"
                                        placeholder="0"
                                        variant="outlined"
                                        size="small"
                                        className="mx-1"
                                        style={{ width: '54px' }}
                                        value={authRemoveByMeDays}
                                        onChange={({ target: { value }}) => setAuthRemoveByMeDays(parseInt(value) || "")}
                                        disabled={!authRemoveByMe}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                    dias
                                </div>
                            }
                        />
                    </div>
                    <div className="col-12">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={authRemoveByOtherImmediately}
                                    onChange={({ target: { checked }}) => setAuthRemoveByOtherImmediately(checked)}
                                    color="primary"
                                />
                            }
                            label="Remoção imediata por pessoa de minha indicação"
                        />
                    </div>
                    { authRemoveByOtherImmediately && authPerson }
                    <div className="col-12">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={authRemoveByOther}
                                    onChange={({ target: { checked }}) => setAuthRemoveByOther(checked)}
                                    color="primary"
                                />
                            }
                            label={
                                <div className="input-height">
                                    Remoção pela pessoa de minha indicação em 
                                    <TextField
                                        type="tel"
                                        placeholder="0"
                                        variant="outlined"
                                        size="small"
                                        className="mx-1"
                                        style={{ width: '54px' }}
                                        value={authRemoveByOtherDays}
                                        onChange={({ target: { value }}) => setAuthRemoveByOtherDays(parseInt(value) || "")}
                                        disabled={!authRemoveByOther}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                    dias
                                </div>
                            }
                        />
                    </div>
                    { authRemoveByOther && authPerson }
                </div>
            </div>
        </div>
    )
})

export default TermDeathForm;