import React, { useEffect, useState } from "react"
import { connect } from "react-redux"

import { CircularProgress, TextField } from "@mui/material"

import { mandatoryField } from "../../utils";
import { APP_DEVICE, APP_NAME, APP_VERSION } from "../../constants";

import VSTooltip from "../../components/vsTooltip/VSTooltip";

import "./CategoryForm.scss"
const CategoryForm = (props) => {

    const {
        object,
        defaultName = "",
        clinic,
        onSave,
        onCancel
    } = props

    const update = object?.id ? true : false

    const [ saveLoading, setSaveLoading ] = useState(false)
    const [ hasSubmited, setHasSubmited ] = useState(false)
    const [ showTooltip, setShowTooltip ] = useState(false)

    const [ name, setName ] = useState(object?.get("categoryName") || defaultName)
    const [ errorName, setErrorName ] = useState(false)
    const [ helperName, setHelperName ] = useState(mandatoryField)

    const validateOnTouch = () => {
        if (hasSubmited) {
            validate()
        }
    }

    const validate = () => {
        let isValid = true

        if (!name) {
            isValid = false
            setErrorName(true)
            setHelperName("O nome da categoria é obrigatório")
        } else {
            setErrorName(false)
            setHelperName(mandatoryField)
        }

        return isValid
    }

    const fieldsWithErrorText = () => {
        let errors = []
        if (saveLoading) {
            errors.push("Salvando a categoria")
        }
        if (errorName) {
            errors.push("Nome")
        }
        return errors
    }

    const save = () => {
        setHasSubmited(true)
        if (validate()) {
            setSaveLoading(true)
            let category = object
            if (!category?.id) {
                category = new Parse.Object("MRCategory")
                category.setACL(clinic.ACL)
                category.set("clinic", clinic.object)
                category.set("isDeleted", false)
                category.set("createdBy", Parse.User.current())
                category.set("createdApp", APP_NAME)
                category.set("createdDevice", APP_DEVICE)
                category.set("createdAppVersion", APP_VERSION)
            }
            category.set("categoryName", name)
            category.set("changedAt", new Date())
            category.set("changedBy", Parse.User.current())
            category.set("changedApp", APP_NAME)
            category.set("changedDevice", APP_DEVICE)
            category.set("changedAppVersion", APP_VERSION)
            category.save()
            .then(_category => {
                setSaveLoading(false)
                onSave(_category, _category.get("categoryName"))
            })
            .catch(error => {
                setSaveLoading(false)
                console.error(error)
                Swal.fire(
                    'Desculpe',
                    `Ocorreu algum erro ao tentar ${object?.id ? "editar" : "cadastrar"} a categoria`,
                    'error'
                )
                update && object.revert()
            })
        }
    }

    useEffect(() => {
        validateOnTouch()
    }, [ name, hasSubmited ])

    const errors = fieldsWithErrorText()
    const isValid = errors.length == 0
    const isSingular = errors.length == 1

    return (
        <div className="vsbox border">
            <div className="vsbox-body mb-0">
                <div className="row g-3">
                    <div className="col-12">
                        <div className="vsbox-title">{ object?.id ? "Editar Categoria" : "Nova Categoria" }</div>
                    </div>
                    <div className="col-12">
                        <TextField
                            placeholder="Ex: Consulta"
                            label="Nome da Categoria"
                            value={name}
                            onChange={({ target: { value } }) => setName(value)}
                            error={errorName}
                            helperText={helperName}
                            size="small"
                            variant="outlined"
                            fullWidth
                        />
                    </div>
                    <div className="col">
                        <button className="vsbox-btn vsbox-btn-alert w-100" onClick={() => onCancel()}>
                            Cancelar
                        </button>
                    </div>
                    <VSTooltip
                        placement="top-start"
                        open={showTooltip}
                        onOpen={() => {
                            if (!isValid) {
                                setShowTooltip(true)
                            }
                        }}
                        onClose={() => setShowTooltip(false)}
                        title={
                            <div className="vsdrawer-error">
                                O{ isSingular ? "" : "s" } campo{ isSingular ? "" : "s" } abaixo 
                                est{ isSingular ? "á" : "ão" } com erro no preenchimento:
                                <ul>
                                    { errors.map((error, index) => (
                                        <li key={index}>{ error }</li>
                                    ))}
                                </ul>
                            </div>
                        }
                    >
                        <div className="col">
                            <button className="btn-submit btn-square w-100" disabled={!isValid} onClick={() => isValid && save()}>
                                { saveLoading ? (
                                    <CircularProgress size="20px" />
                                ) : (
                                    "Salvar"
                                )}
                            </button>
                        </div>
                    </VSTooltip>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        clinic: state.clinic
    }
}

export default connect(mapStateToProps)(CategoryForm)