import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react'

import {
    getStateList,
    validateCPF,
    validatePostalCode,
    mandatoryField,
    optionalField,
    validateEmail,
    validatePhone,
    getAddressByZipCode
} from '../../../../utils'

import { CircularProgress, InputAdornment, MenuItem, TextField } from '@mui/material';
import TextFieldMask from '../../../../components/textFieldMask/TextFieldMask';
import VSAccordionSimple from '../../../../components/vsAccordion/VSAccordionSimple';
import { TERMO_CONSENTIMENTO_TELEMEDICINA } from './Term';

const TermOwnerForm = forwardRef((props, ref) => {

    const {
        owner,
        documentType,
        cpf, 
        setCpf,
        errorCpf, 
        setErrorCpf,
        helperCpf, 
        setHelperCpf,
        loadingZipCode, 
        setLoadingZipCode,
        zipCode, 
        setZipCode,
        errorZipCode, 
        setErrorZipCode,
        helperZipCode, 
        setHelperZipCode,
        address, 
        setAddress,
        errorAddress, 
        setErrorAddress,
        helperAddress, 
        setHelperAddress,
        addressNumber, 
        setAddressNumber,
        errorAddressNumber, 
        setErrorAddressNumber,
        helperAddressNumber, 
        setHelperAddressNumber,
        addressComplement, 
        setAddressComplement,
        neighborhood, 
        setNeighborhood,
        errorNeighborhood, 
        setErrorNeighborhood,
        helperNeighborhood, 
        setHelperNeighborhood,
        cityId, 
        setCityId,
        ownerCity, 
        setOwnerCity,
        errorOwnerCity, 
        setErrorOwnerCity,
        helperOwnerCity, 
        setHelperOwnerCity,
        state, 
        setState,
        errorState, 
        setErrorState,
        helperState, 
        setHelperState,
        ownerEmail,
        setOwnerEmail,
        errorOwnerEmail,
        setErrorOwnerEmail,
        helperOwnerEmail,
        setHelperOwnerEmail,
        ownerPhone,
        setOwnerPhone,
        errorOwnerPhone,
        setErrorOwnerPhone,
        helperOwnerPhone,
        setHelperOwnerPhone
    } = props

    const isTelemedicineTerm = documentType === TERMO_CONSENTIMENTO_TELEMEDICINA

    // Owner
    const [ showOwner, setShowOwner ] = useState(false)

    useImperativeHandle(ref, () => ({
        fieldsWithErrorText(errors) {
            if (errorCpf) {
                errors.push("CPF")
            }
            if (errorZipCode) {
                errors.push("CEP")
            }
            if (errorAddress) {
                errors.push("Endereço")
            }
            if (errorAddressNumber) {
                errors.push("Número do endereço")
            }
            if (errorNeighborhood) {
                errors.push("Bairro")
            }
            if (errorOwnerCity) {
                errors.push("Cidade do tutor")
            }
            if (errorState) {
                errors.push("Estado")
            }
            if (errorOwnerEmail) {
                errors.push("E-mail")
            }
            if (errorOwnerPhone) {
                errors.push("Celular")
            }
            return errors
        },
        isValid() {
            return !errorCpf && !errorZipCode && !errorAddress && !errorAddressNumber && !errorNeighborhood && !errorOwnerCity && !errorState && !errorOwnerEmail && !errorOwnerPhone
        },
        validate(isValid) {
            if (validateCPF(cpf)) {
                setErrorCpf(false)
                setHelperCpf(mandatoryField)
            } else {
                isValid = false
                setErrorCpf(true)
                setHelperCpf("O CPF informado não é válido")
            }
    
            if (validatePostalCode(zipCode)) {
                setErrorZipCode(false)
                setHelperZipCode(mandatoryField)
            } else {
                isValid = false
                setErrorZipCode(true)
                setHelperZipCode("O CEP informado não é válido")
            }
    
            if (address) {
                setErrorAddress(false)
                setHelperAddress(mandatoryField)
            } else {
                isValid = false
                setErrorAddress(true)
                setHelperAddress("O endereço é obrigatório")
            }
    
            if (addressNumber) {
                setErrorAddressNumber(false)
                setHelperAddressNumber(mandatoryField)
            } else {
                isValid = false
                setErrorAddressNumber(true)
                setHelperAddressNumber("O número do endereço é obrigatório")
            }
    
            if (neighborhood) {
                setErrorNeighborhood(false)
                setHelperNeighborhood(mandatoryField)
            } else {
                isValid = false
                setErrorNeighborhood(true)
                setHelperNeighborhood("O bairro é obrigatório")
            }
    
            if (ownerCity) {
                setErrorOwnerCity(false)
                setHelperOwnerCity(mandatoryField)
            } else {
                isValid = false
                setErrorOwnerCity(true)
                setHelperOwnerCity("A cidade do tutor é obrigatória")
            }
    
            if (state) {
                setErrorState(false)
                setHelperState(mandatoryField)
            } else {
                isValid = false
                setErrorState(true)
                setHelperState("O estado do endereço é obrigatório")
            }

            if (isTelemedicineTerm) {
                if (validateEmail(ownerEmail)) {
                    setErrorOwnerEmail(false)
                    setHelperOwnerEmail(mandatoryField)
                } else {
                    isValid = false
                    setErrorOwnerEmail(true)
                    setHelperOwnerEmail("O e-mail informado não é válido")
                }

                if (validatePhone(ownerPhone)) {
                    setErrorOwnerPhone(false)
                    setHelperOwnerPhone(mandatoryField)
                } else {
                    isValid = false
                    setErrorOwnerPhone(true)
                    setHelperOwnerPhone("O número de celular informado não é válido")
                }
            } else {
                if (!ownerEmail || validateEmail(ownerEmail)) {
                    setErrorOwnerEmail(false)
                    setHelperOwnerEmail(optionalField)
                } else {
                    isValid = false
                    setErrorOwnerEmail(true)
                    setHelperOwnerEmail("O e-mail informado não é válido")
                }

                if (!ownerPhone || validatePhone(ownerPhone)) {
                    setErrorOwnerPhone(false)
                    setHelperOwnerPhone(optionalField)
                } else {
                    isValid = false
                    setErrorOwnerPhone(true)
                    setHelperOwnerPhone("O número de celular informado não é válido")
                }
            }
    
            return isValid
        }
    }))

    useEffect(() => {
        setLoadingZipCode(true)
        getAddressByZipCode(zipCode)
        .then(data => {
            setLoadingZipCode(false)
            setAddress(data.address)
            setNeighborhood(data.neighborhood)
            setOwnerCity(data.city)
            setCityId(data.city_id)
            setState(data.state_id)
        })
        .catch(_ => {
            setLoadingZipCode(false)
        })
    }, [ zipCode ])

    useEffect(() => {
        if (owner && owner.get) {
            setCpf(owner.get("cpf"))
            setZipCode(owner.get("zipCode"))
            setAddress(owner.get("addressStreet"))
            setAddressNumber(owner.get("addressNumber"))
            setAddressComplement(owner.get("addressComplement"))
            setNeighborhood(owner.get("neighborhood"))
            setOwnerCity(owner.get("city"))
            setState(owner.get("stateId"))
            setOwnerEmail(owner.get("email"))
            setOwnerPhone(owner.get("phone"))
        }
    }, [ owner ])

    return owner && (
        <div className="row">
            <div className="col">
                <VSAccordionSimple
                    expanded={showOwner}
                    header={(_) => (
                        "Identificação do(a) Tutor(a)"
                    )}
                    onOpen={() => setShowOwner(true)}
                    onClose={() => setShowOwner(true)}
                >
                    <div className="row">
                        <div className="col">
                            <h4 className="mb-4">{ owner.get("name") }</h4>
                            <div className="row mb-4">
                                <div className="col-6">
                                    <TextFieldMask
                                        type="tel"
                                        mask='000.000.000-00'
                                        size="small"
                                        variant="outlined"
                                        value={cpf}
                                        onAccept={(value) => setCpf(value)}
                                        className="w-100"
                                        placeholder="000.000.000-00"
                                        label="CPF"
                                        error={errorCpf}
                                        helperText={helperCpf}
                                    />
                                </div>
                                <div className="col-6">
                                    <TextFieldMask
                                        type="tel"
                                        size="small"
                                        variant="outlined"
                                        mask="00000-000"
                                        value={zipCode}
                                        onAccept={(value) => setZipCode(value)}
                                        className="w-100"
                                        placeholder="00000-000"
                                        label="CEP"
                                        error={errorZipCode}
                                        helperText={helperZipCode}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    { loadingZipCode && (
                                                        <CircularProgress size={16} />
                                                    )}
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row mb-4">
                                <div className="col pe-0">
                                    <TextField
                                        size="small"
                                        variant="outlined"
                                        className="w-100"
                                        placeholder="Rua, avenida, alameda, etc."
                                        label="Endereço"
                                        value={address}
                                        onChange={({ target: { value } }) => setAddress(value)}
                                        error={errorAddress}
                                        helperText={helperAddress}
                                    />
                                </div>
                                <div className="col-3">
                                    <TextField
                                        size="small"
                                        variant="outlined"
                                        className="w-100"
                                        placeholder="000"
                                        label="Número"
                                        value={addressNumber}
                                        onChange={({ target: { value } }) => setAddressNumber(value)}
                                        error={errorAddressNumber}
                                        helperText={helperAddressNumber}
                                    />
                                </div>
                            </div>
                            <div className="row mb-4">
                                <div className="col-12 col-md pe-md-0 mb-3 mb-md-0">
                                    <TextField
                                        size="small"
                                        variant="outlined"
                                        className="w-100"
                                        placeholder="Torre Oceania, Apartamento 12"
                                        label="Complemento"
                                        value={addressComplement}
                                        onChange={({ target: { value } }) => setAddressComplement(value)}
                                        helperText={optionalField}
                                    />
                                </div>
                                <div className="col-12 col-md-6">
                                    <TextField
                                        size="small"
                                        variant="outlined"
                                        className="w-100"
                                        placeholder="Jardim das Flores"
                                        label="Bairro"
                                        value={neighborhood}
                                        onChange={({ target: { value } }) => setNeighborhood(value)}
                                        error={errorNeighborhood}
                                        helperText={helperNeighborhood}
                                    />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col pe-0">
                                    <TextField
                                        size="small"
                                        variant="outlined"
                                        className="w-100"
                                        placeholder="São Paulo"
                                        label="Cidade"
                                        value={ownerCity}
                                        onChange={({ target: { value } }) => setOwnerCity(value)}
                                        error={errorOwnerCity}
                                        helperText={helperOwnerCity}
                                    />
                                </div>
                                <div className="col-6">
                                    <TextField
                                        size="small"
                                        variant="outlined"
                                        className="w-100"
                                        placeholder="São Paulo"
                                        label="Estado"
                                        value={state}
                                        onChange={({ target: { value } }) => setState(value)}
                                        error={errorState}
                                        helperText={helperState}
                                        select
                                    >
                                        { getStateList().map(stateItem => (
                                            <MenuItem key={stateItem.value} value={stateItem.value}>
                                                { stateItem.text }
                                            </MenuItem>
                                        )) }
                                    </TextField>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-12 col-md-6 col-xl-8 mb-3">
                                    <TextField
                                        type="email"
                                        placeholder="exemplo@email.com"
                                        label="E-mail"
                                        onChange={({ target: { value } }) => setOwnerEmail(value)}
                                        value={ownerEmail}
                                        error={errorOwnerEmail}
                                        helperText={helperOwnerEmail}
                                        size="small"
                                        variant="outlined"
                                        fullWidth
                                    />
                                </div>
                                <div className="col-12 col-md-6 col-xl-4 mb-3">
                                    <TextFieldMask
                                        type="tel"
                                        placeholder="(00) 00000-0000"
                                        label="Celular"
                                        mask={ownerPhone?.length <= 14 ? '(00) 0000-0000[0]' : '(00) 00000-0000'}
                                        onAccept={(value) => setOwnerPhone(value)}
                                        value={ownerPhone}
                                        error={errorOwnerPhone}
                                        helperText={helperOwnerPhone}
                                        size="small"
                                        variant="outlined"
                                        fullWidth
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </VSAccordionSimple>
            </div>
        </div>
    )
})

export default TermOwnerForm;