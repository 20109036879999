import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom'
import { useLocation } from 'react-router-dom/cjs/react-router-dom';

import { CircularProgress, Switch } from '@mui/material';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/lab/Autocomplete';

import { ReactComponent as IconAdd } from "../../assets/icon-add.svg"
import { ReactComponent as IconToggle } from "../../assets/icon-toggle.svg"
import { ReactComponent as IconWarning } from "../../assets/icon-warning.svg"

import { APP_NAME, APP_DEVICE, APP_VERSION, ACL } from '../../constants'
import { getSelectedOptionLabel, addEvent, saveOnTracker, useQuery, dayjs } from '../../utils'
import { getBreeds, getGenders, getPatient, getPelages, getSpecies, getTemperaments, months, sizeOptions, years } from './Patient';
import { getOwner } from '../owner/Owner';

import DateInput from '../../components/dateInput/DateInput';
import VSDrawer from '../../components/vsDrawer/VSDrawer';
import VSAccordionSimple from '../../components/vsAccordion/VSAccordionSimple';
import VSImage from '../../components/vsImage/VSImage';
import OwnerQuery from '../owner/OwnerQuery';
import VSError from '../../components/vsError/VSError';

import './PatientForm.scss'
const PatientForm = (props) => {

    const {
        id,
        id_animal
    } = useParams()

    const {
        state
    } = useLocation()

    const {
        clinic,
        history,
        onboarding,
        permissions
    } = props

    const proxima = useQuery().get("proxima")
    const preFilledName = useQuery().get("name")

    const update = id_animal != "novo"

    const [ loading, setLoading ] = useState(false)
    const [ error, setError ] = useState(null)
    const [ saveLoading, setSaveLoading ] = useState(false)
    const [ patient, setPatient ] = useState(null)
    const [ owner, setOwner ] = useState(null)

    const [ name, setName ] = useState(preFilledName || '')
    const [ errorName, setErrorName ] = useState(false)
    const [ helperName, setHelperName ] = useState(<span>Campo <b>Obrigatório</b></span>)

    const [ specie, setSpecie ] = useState('')
    const [ species, setSpecies ] = useState([])
    const [ errorSpecie, setErrorSpecie ] = useState(false)
    const [ helperSpecie, setHelperSpecie ] = useState(<span>Campo <b>Obrigatório</b></span>)

    const [ breed, setBreed ] = useState('')
    const [ breeds, setBreeds ] = useState([])

    const [ gender, setGender ] = useState('')
    const [ genders, setGenders ] = useState([])

    const [ pelage, setPelage ] = useState('')
    const [ pelages, setPelages ] = useState([])

    const [ temperament, setTemperament ] = useState('')
    const [ temperaments, setTemperaments ] = useState([])

    const [ castrated, setCastrated ] = useState('')
    const castratedOptions = [
        {
            value: "0",
            label: "Não"
        },
        {
            value: "1",
            label: "Sim"
        },
        {
            value: "2",
            label: "Indefinido"
        }
    ]

    const [ deceased, setDeceased ] = useState("0")

    const [ rga, setRga ] = useState('')
    const [ microchip, setMicrochip ] = useState('')

    const [ birthdate, setBirthdate ] = useState(null)
    const [ errorBirthdate, setErrorBirthdate ] = useState(false)
    const [ helperBirthdate, setHelperBirthdate ] = useState(<span>Campo Opcional</span>)
    const [ birthdateText, setBirthdateText ] = useState('')
    const [ ageYear, setAgeYear ] = useState('')
    const [ ageMonth, setAgeMonth ] = useState('')
    const [ size, setSize ] = useState('')
    const [ otherInfo, setOtherInfo ] = useState('')
    const [ notes, setNotes ] = useState('')
    const [ image, setImage ] = useState(null)

    const handleSpecie = (selected) => {
        const specie = selected
        setSpecie(specie)
    }

    const handleBirthdate = (_date) => {
        setBirthdateText(_date)

        if (_date.match(/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/g)) {
            let newDate = dayjs(_date, "DD/MM/YYYY")
            if (newDate.isValid()) {
                setBirthdate(newDate.toDate())
                const age = dayjs.duration(dayjs().diff(newDate))
                if (age.years() > 0 || age.months() > 0) {
                    setAgeYear(String(age.years()))
                    setAgeMonth(String(age.months()))
                }
                return
            }
        }
        setBirthdate(null)
        setAgeMonth('')
        setAgeYear('')
    }

    const handleAge = (_years, _months) => {
        const years = parseInt(_years) || 0
        const months = parseInt(_months) || 0
        const date = dayjs().subtract(years, 'years').subtract(months, 'months')
        setAgeYear(String(years))
        setAgeMonth(String(months))
        if (date.isValid()) {
            setBirthdate(date.toDate())
            setBirthdateText(date.format("DD/MM/YYYY"))
        } else {
            setBirthdate(null)
            setBirthdateText("")
        }
    }

    const validateOnTouch = () => {
        if (name || specie || birthdate || birthdateText || ageYear || ageMonth || gender || pelage || temperament || castrated || notes) {
            validate()
        }
    }

    const validate = () => {
        let isValid = true
        if (loading) {
            isValid = false
        }
        if (saveLoading) {
            isValid = false
        }
        if (error?.message) {
            isValid = false
        }

        if (!name) {
            isValid = false
            setErrorName(true)
            setHelperName("O nome do animal é obrigatório")
        } else {
            setErrorName(false)
            setHelperName(<span>Campo <b>Obrigatório</b></span>)
        }

        if (!specie) {
            isValid = false
            setErrorSpecie(true)
            setHelperSpecie("A espécie do animal é obrigatória")
        } else {
            setErrorSpecie(false)
            setHelperSpecie(<span>Campo <b>Obrigatório</b></span>)
        }

        const _birthdate = dayjs(birthdate)
        if (birthdate || birthdateText.replace(/[^0-9]/g, '').length > 0 || ageYear.length > 0 || ageMonth.length > 0) {
            if (_birthdate.isValid()) {
                setErrorBirthdate(false)
                setHelperBirthdate(<span>Campo Opcional</span>)
            } else {
                isValid = false
                setErrorBirthdate(true)
                setHelperBirthdate("A data informada não é válida")
            }
        } else {
            setErrorBirthdate(false)
            setHelperBirthdate(<span>Campo Opcional</span>)
        }

        return isValid
    }

    const isValid = () => !loading && !saveLoading && !errorName && !errorSpecie && !errorBirthdate

    const fieldsWithErrorText = () => {
        let errors = []
        if (loading) {
            errors.push("Estamos procurando o animal")
        }
        if (saveLoading) {
            errors.push("Salvando o animal")
        }
        if (error?.message) {
            errors.push(error.message)
        }
        if (errorName) {
            errors.push("Nome")
        }
        if (errorSpecie) {
            errors.push("Espécie")
        }
        if (errorBirthdate) {
            errors.push("Data de nascimento")
        }
        if (!permissions?.check(ACL.PATIENT, ACL.Rule.EDIT, patient)) {
            errors.push("Acesso negado")
        }
        return errors
    }

    const save = () => {
        return new Promise((resolve, reject) => {
            if (validate()) {
                setSaveLoading(true)
                // patient.set('owner', owner)
                // patient.set('name', name);
                // patient.set('specie', specie);
                // patient.set('breed', breed);
                // if (birthdate) {
                //     patient.set('birthdate', birthdate);
                // } else {
                //     patient.unset('birthdate');
                // }
                // patient.set('gender', gender);
                // patient.set('pelage', pelage);
                // patient.set('temperament', temperament);
                // patient.set('castrated', castrated);
                // patient.set('deceased', deceased);
                // patient.set('rga', rga);
                // patient.set('microchip', microchip);
                // patient.set('size', size);
                // patient.set('otherInfo', otherInfo);
                // patient.set('notes', notes);
                // patient.set('image', image);
                // patient.set("changedAt", new Date())
                // patient.set("changedBy", Parse.User.current())
                // patient.set("changedApp", APP_NAME)
                // patient.set("changedDevice", APP_DEVICE)
                // patient.set("changedAppVersion", APP_VERSION)
                Parse.Cloud.run("savePatientForOwner", {
                    data: {
                        objectId: update ? id_animal : null,
                        name: name,
                        specie: specie,
                        breed: breed,
                        birthdate: birthdate,
                        gender: gender,
                        pelage: pelage,
                        temperament: temperament,
                        castrated: castrated,
                        deceased: deceased,
                        rga: rga,
                        microchip: microchip,
                        size: size,
                        otherInfo: otherInfo,
                        notes: notes,
                        image: image
                    },
                    clinicId: clinic.objectId,
                    ownerId: owner.id,
                    appVersionName: APP_VERSION,
                    deviceType: APP_DEVICE,
                    appContentType: APP_NAME
                })
                // patient.save()
                .then(_patient => {
                    saveOnTracker("Concluiu", "Animal", _patient.id)
                    setSaveLoading(false)
                    addEvent("TourPatient__registerYourFirstPatient", {})
                    addEvent("Patient__getList", {})
                    resolve(_patient)
                })
                .catch(error => {
                    setSaveLoading(false)
                    console.error(error)
                    Swal.fire(
                        'Desculpe',
                        `Ocorreu algum erro ao tentar ${update ? "editar" : "cadastrar"} o animal`,
                        'error'
                    )
                    reject()
                })
            } else {
                reject()
            }
        })
    }

    const nextStep = (patientId) => {
        if (proxima) {
            switch(proxima) {
                case "vacina":
                    return history.replace(`/animal/${patientId}/vacina/novo?selecionado`)
                case "lembrete":
                    return history.replace(`/animal/${patientId}/vacina/novo?selecionado`)
                case "consulta":
                    return history.replace(`/animal/${patientId}/consulta/novo?selecionado`)
                case "prescricao":
                    return history.replace(`/prescricao/${patientId}/novo?selecionado`)
                case "agenda":
                    return history.replace(`/agenda/novo?selecionado`, {
                        ...state,
                        id: patientId
                    })
                default:
                    return history.replace(`/animal/${patientId}?selecionado`)
            }
        }
        history.push(`/animal/${patientId}`)
    }

    const nextStepText = () => {
        const defaultText = "Você será redirecionado(a) automaticamente para "
        if (proxima) {
            switch(proxima) {
                case "vacina":
                    return `${defaultText} o Cadastro de Vacina`
                case "lembrete":
                    return `${defaultText} o Cadastro de Vacina`
                case "consulta":
                    return `${defaultText} o Cadastro de Consulta`
                case "prescricao":
                    return `${defaultText} o Cadastro da Prescrição`
            }
        }
        return `${defaultText} o Cadastro do Animal`
    }

    const getOwnerOrError = () => {
        setLoading(true)
        setError(null)
        
        getOwner(id, clinic.object, [])
        .then(_owner => {
            setOwner(_owner)
        })
        .catch(error => {
            Swal.fire(
                'Desculpe',
                error,
                'error'
            )
            .then(_ => {
                setLoading(false)
                setError({
                    message: error,
                    function: "getOwnerOrError"
                })
            })
        })
    }

    const getPatientOrError = () => {
        if (permissions?.check(ACL.PATIENT, ACL.Rule.VIEW)) {
            setLoading(true)
            setError(null)
    
            if (update) {
                getPatient(id_animal, clinic.object)
                .then(_patient => {
                    setPatient(_patient)
                    setName(_patient.get("name"))
                    handleSpecie(_patient.get("specie"))
                    setBreed(_patient.get("breed"))
                    if (_patient.get("birthdate")) {
                        const date = dayjs(_patient.get("birthdate"))
                        handleBirthdate(date.format("DD/MM/YYYY"))
                    }
                    setGender(_patient.get("gender"))
                    setPelage(_patient.get("pelage"))
                    setTemperament(_patient.get("temperament"))
                    setCastrated(_patient.get("castrated"))
                    setDeceased(_patient.get("deceased"))
                    setRga(_patient.get("rga"))
                    setMicrochip(_patient.get("microchip"))
                    setSize(_patient.get("size"))
                    setOtherInfo(_patient.get("otherInfo"))
                    setNotes(_patient.get("notes"))
                    setImage(_patient.get("image"))
                    setLoading(false)
                })
                .catch(error => {
                    Swal.fire(
                        'Desculpe',
                        error,
                        'error'
                    )
                    .then(_ => {
                        setLoading(false)
                        setError({
                            message: error,
                            function: "getPatientOrError"
                        })
                    })
                })
            } else {
                // let newPatient = new Parse.Object("Patient")
                // newPatient.setACL(clinic.ACL)
                // newPatient.set("clinic", clinic.object)
                // newPatient.set("createdBy", Parse.User.current())
                // newPatient.set("createdApp", APP_NAME)
                // newPatient.set("createdDevice", APP_DEVICE)
                // newPatient.set("createdAppVersion", APP_VERSION)
                // newPatient.set("isDeleted", false)
                // newPatient.set("owner", owner)
                // setPatient(newPatient)
                setLoading(false)
            }
        }
    }

    useEffect(() => {
        if (specie) {
            getBreeds(specie).then(_breeds => setBreeds(_breeds))
        }
    }, [ specie ])

    useEffect(() => {
        validateOnTouch()
    }, [ name, specie, birthdate, birthdateText, ageYear, ageMonth ])

    useEffect(() => {
        if (patient && !permissions?.check(ACL.PATIENT, ACL.Rule.EDIT, patient)) {
            permissions?.error()
        }
    }, [ patient ])

    useEffect(() => {
        if (owner) {
            getPatientOrError()
        }
    }, [ owner ])

    useEffect(() => {
        if (id != "selecionar") {
            getOwnerOrError()
        }
    }, [ id ])

    useEffect(() => {
        if (clinic && clinic.isLoaded && clinic.object) {
            saveOnTracker("Iniciou", "Animal", id !== "novo" ? id : "")
            if (id != "selecionar") {
                getOwnerOrError()
            }

            getSpecies().then(_species => setSpecies(_species))
            getGenders().then(_genders => setGenders(_genders))
            getPelages().then(_pelages => setPelages(_pelages))
            getTemperaments().then(_temperaments => setTemperaments(_temperaments))
        }
    }, [ clinic ])

    const errors = fieldsWithErrorText()

    return (
        <VSDrawer
            title={update ? "Editar Cadastro de Animal" : "Novo Cadastro de Animal"}
            cancel={update ? "Cancelar Alterações" : <span>Cancelar<span className="d-none d-md-inline"> Novo</span> Cadastro</span>}
            submit={isValid ? ( update ? "Salvar Alterações" : "Salvar Cadastro" ) : <span>Preencha os campos<span className="d-none d-md-inline"> necessários</span></span>}
            errors={errors}
            onSubmit={save}
            onCancel={() => history.goBack()}
            onAfterSave={(object) => nextStep(object.id)}
            VSDrawerSuccessProps={{
                title: update ? "Alterações salvas com sucesso!" : "Cadastro realizado com sucesso!" ,
                text: nextStepText()
            }} 
            VSDrawerCancelProps={{
                title: update ? "Tem certeza que deseja cancelar as alterações no cadastro do animal? " : "Tem certeza que deseja cancelar o novo cadastro de animal?",
                confirm: update ? "Cancelar  Alterações" : "Cancelar Novo Cadastro",
                cancel: update ? "Voltar para Edição do Cadastro" : "Voltar para Edição do Novo Cadastro"
            }}
            VSDrawerFooterProps={{
                show: owner ? true : false
            }}
        >
            { loading ? (
                <div className="row data-loading">
                    <div className="col">
                        <CircularProgress />
                    </div>
                </div>
            ) : error?.message ? (
                <VSError
                    message={error.message}
                    onClose={() => {
                        switch(error.function) {
                            case "getOwnerOrError":
                                getOwnerOrError()
                                break
                            case "getPatientOrError":
                                getPatientOrError()
                                break
                            default:
                        }
                    }}
                />
            ) : (
                !owner ? (
                    <OwnerQuery
                        history={history}
                        onSelected={(_owner) => setOwner(_owner)}
                        onCreate={() => history.push(`/tutor/form/novo`)}
                        text={`Para ${ update ? "editar" : "cadastrar" } um animal, é preciso antes selecionar o(a) tutor(a) a quem este animal ficará vinculado`}
                    />
                ) : (
                    <>
                        <div className="vssearch">
                            <div className="vssearch-area">
                                <div className="row flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                    <div className="col">
                                        <div className="vssearch-text">
                                            { owner.get("name") }
                                        </div>
                                        <div className="vssearch-result">
                                            CPF: { owner.get("cpf") || "Não informado" }
                                            { " - " }
                                            RG: { owner.get("rg") || "Não informado" }
                                        </div>
                                    </div>
                                    <div className="col-auto d-flex d-sm-block justify-content-center mt-3 mt-sm-0">
                                        <button
                                            className="vssearch-toggle d-flex d-sm-inline-block"
                                            onClick={() => {
                                                history.push(`/tutor/selecionar/animal/${id_animal}`)
                                                setOwner(null)
                                            }}
                                        >
                                            <IconToggle className="me-2 me-sm-auto" width="24px" height="24px" />
                                            Trocar Tutor(a)
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        { (!update || patient) && (
                            <>
                                <div className="row align-items-center">
                                    <div className="col">
                                        <p className="my-3" style={{ 
                                            textTransform: "uppercase",
                                            fontWeight: 600
                                        }}>
                                            Animal está vivo?
                                        </p>
                                    </div>
                                    <div className="col-auto">
                                        <Switch
                                            checked={deceased != "1"}
                                            onChange={({ target: { checked } }) => setDeceased(checked ? "0" : "1")}
                                            color="primary"
                                        />
                                    </div>
                                </div>
                                { deceased == "1" && deceased != patient?.get("deceased") && (
                                    <div className="vsalert vsalert-warning mb-3">
                                        <div className="row align-items-center">
                                            <div className="col-auto">
                                                <IconWarning width="24px" height="24px" />
                                            </div>
                                            <div className="col ps-0">
                                                <h4 className="vsalert-title">Você definiu que o animal não esta vivo.</h4>
                                                <p className="vsalert-text">Ao salvar essa alteração, todas as programações de vacinas e as próximas vacinas vinculadas a este animal serão excluídas. Elas não poderão ser recuperadas e esta operação não poderá ser desfeita.</p>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <hr/>
                                <div className="row mt-2">
                                    <div className="col">
                                        <VSAccordionSimple
                                            expanded={true}
                                            header={(_) => (
                                                "Identificação"
                                            )}
                                        >
                                            <div className="row flex-nowrap flex-column flex-md-row">
                                                <div className="col-12 col-md-auto flex-shrink-0 mb-3">
                                                    <VSImage
                                                        className="w-100"
                                                        getImage={(image) => setImage(image)}
                                                        image={image}
                                                        cropperOptions={{
                                                            aspectRatio: 1 / 1
                                                        }}
                                                        style={{
                                                            image: {
                                                                width: '82px',
                                                                height: '82px',
                                                                borderRadius: '50%'
                                                            },
                                                            buttons: {
                                                                width: 'calc(100% - 168px)',
                                                                float: 'right',
                                                                height: '148px',
                                                                alignItems: 'center',
                                                                changeButton: {
                                                                    paddingLeft: 0
                                                                },
                                                                deleteButton: {
                                                                    paddingLeft: 0
                                                                }
                                                            }
                                                        }}
                                                    >
                                                        <IconAdd width="24" height="24" />
                                                        Adicionar<br/> 
                                                        Foto de Perfil<br/> 
                                                        (opcional)
                                                    </VSImage>
                                                </div>
                                                <div className="col-12 col-md-auto flex-shrink-1">
                                                    <div className="row">
                                                        <div className="col-12 col-xl-5 mb-3">
                                                            <TextField
                                                                placeholder="Nome do animal"
                                                                label="Nome do animal"
                                                                value={name}
                                                                onChange={({ target: { value } }) => setName(value)}
                                                                error={errorName}
                                                                helperText={helperName}
                                                                size="small"
                                                                variant="outlined"
                                                                fullWidth
                                                            />
                                                        </div>
                                                        <div className="col-6 col-md-4 col-xl-3 mb-3">
                                                            <Autocomplete
                                                                inputValue={specie}
                                                                options={species}
                                                                onChange={(_, selected) => handleSpecie(selected || "")}
                                                                onInputChange={(event, selected) => {
                                                                    if (event){
                                                                        handleSpecie(selected)
                                                                    }
                                                                }}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        placeholder="Espécie do animal"
                                                                        label="Espécie"
                                                                        error={errorSpecie}
                                                                        helperText={helperSpecie}
                                                                        size="small"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                    />
                                                                )}
                                                                openOnFocus
                                                                freeSolo
                                                            />
                                                        </div>
                                                        <div className="col-6 col-md-5 col-xl-4">
                                                            <Autocomplete
                                                                inputValue={breed}
                                                                options={breeds}
                                                                onChange={(_, selected) => setBreed(selected || '')}
                                                                onInputChange={(event, selected) => event && setBreed(selected || '')}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        placeholder="Raça do animal"
                                                                        label="Raça"
                                                                        helperText={<span>Campo Opcional<br/></span>}
                                                                        size="small"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                    />
                                                                )}
                                                                openOnFocus
                                                                freeSolo
                                                            />
                                                        </div>
                                                        <div className="col-12 col-md-3 mb-3">
                                                            <Autocomplete
                                                                value={gender}
                                                                options={genders}
                                                                onChange={(_, selected) => setGender(selected || '')}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        placeholder="Sexo do animal"
                                                                        label="Sexo"
                                                                        helperText={<span>Campo Opcional<br/></span>}
                                                                        size="small"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                    />
                                                                )}
                                                                openOnFocus
                                                            />
                                                        </div>
                                                        <div className="col-12 col-md-4 mb-3">
                                                            <DateInput
                                                                className="my-0"
                                                                placeholder="00/00/0000"
                                                                label="Data de nascimento"
                                                                value={birthdateText || null}
                                                                error={errorBirthdate}
                                                                helperText={helperBirthdate}
                                                                onChange={(value) => handleBirthdate(value)}
                                                                size="small"
                                                                variant="outlined"
                                                                fullWidth
                                                            />
                                                        </div>
                                                        <div className="col-2 col-md mb-3 text-center px-0">
                                                            <div className="separator-text">OU</div>
                                                        </div>
                                                        <div className="col-5 col-md-3 col-xl-2 pe-1 mb-3">
                                                            <Autocomplete
                                                                value={ageYear}
                                                                options={years}
                                                                onChange={(_, selected) => handleAge(selected, ageMonth)}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        type="tel"
                                                                        placeholder="0"
                                                                        label="Anos"
                                                                        size="small"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                    />
                                                                )}
                                                                openOnFocus
                                                            />
                                                        </div>
                                                        <div className="col-5 col-md-3 col-xl-2 ps-1 mb-3">
                                                            <Autocomplete
                                                                value={ageMonth}
                                                                options={months}
                                                                onChange={(_, selected) => handleAge(ageYear, selected)}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        type="tel"
                                                                        placeholder="0"
                                                                        label="Meses"
                                                                        size="small"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                    />
                                                                )}
                                                                openOnFocus
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </VSAccordionSimple>
                                    </div>
                                </div>
                                <hr className="my-2" />
                                <div className="row">
                                    <div className="col">
                                        <VSAccordionSimple
                                            expanded={true}
                                            header={(_) => (
                                                "Dados Complementares"
                                            )}
                                        >
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="row">
                                                        <div className="col-6 col-md-4 mb-3">
                                                            <Autocomplete
                                                                inputValue={temperament}
                                                                options={temperaments}
                                                                onChange={(_, selected) => setTemperament(selected || '')}
                                                                onInputChange={(event, selected) => event && setTemperament(selected || '')}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        placeholder="Temperamento do animal"
                                                                        label="Temperamento"
                                                                        helperText={<span>Campo Opcional<br/></span>}
                                                                        size="small"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                    />
                                                                )}
                                                                openOnFocus
                                                                freeSolo
                                                            />
                                                        </div>
                                                        <div className="col-6 col-md-4 mb-3">
                                                            <Autocomplete
                                                                inputValue={pelage}
                                                                options={pelages}
                                                                onChange={(_, selected) => setPelage(selected || '')}
                                                                onInputChange={(event, selected) => event && setPelage(selected || '')}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        placeholder="Pelagem do animal"
                                                                        label="Pelagem"
                                                                        helperText={<span>Campo Opcional<br/></span>}
                                                                        size="small"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                    />
                                                                )}
                                                                openOnFocus
                                                                freeSolo
                                                            />
                                                        </div>
                                                        <div className="col-6 col-md-4 mb-3">
                                                            <Autocomplete
                                                                value={size || null}
                                                                options={sizeOptions}
                                                                onChange={(_, selected) => setSize(selected || '')}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        placeholder="Porte do animal"
                                                                        label="Porte"
                                                                        helperText={<span>Campo Opcional</span>}
                                                                        size="small"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                    />
                                                                )}
                                                                openOnFocus
                                                            />
                                                        </div>
                                                        <div className="col-6 col-md-4 mb-3">
                                                            <Autocomplete
                                                                value={castrated}
                                                                options={castratedOptions}
                                                                getOptionLabel={(option) => getSelectedOptionLabel(option, castratedOptions, castrated)}
                                                                getOptionSelected={(option) => option.value === castrated}
                                                                onChange={(_, selected) => setCastrated(selected?.value || '')}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        placeholder="O animal foi castrado?"
                                                                        label="Castrado"
                                                                        helperText={<span>Campo Opcional<br/></span>}
                                                                        size="small"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                    />
                                                                )}
                                                                openOnFocus
                                                            />
                                                        </div>
                                                        <div className="col-6 col-md-4">
                                                            <TextField
                                                                placeholder="0.000.000"
                                                                label="RGA"
                                                                value={rga}
                                                                onChange={({ target: { value } }) => setRga(value)}
                                                                helperText={<span>Campo Opcional</span>}
                                                                size="small"
                                                                variant="outlined"
                                                                fullWidth
                                                            />
                                                        </div>
                                                        <div className="col-6 col-md-4">
                                                            <TextField
                                                                placeholder="000000000000000"
                                                                label="Microchip"
                                                                value={microchip}
                                                                onChange={({ target: { value } }) => setMicrochip(value)}
                                                                helperText={<span>Campo Opcional</span>}
                                                                size="small"
                                                                variant="outlined"
                                                                fullWidth
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 mt-3 mt-md-0 mb-3">
                                                    <TextField
                                                        placeholder="Outros elementos de identificação do animal"
                                                        label="Outras informações"
                                                        value={otherInfo}
                                                        onChange={({ target: { value } }) => setOtherInfo(value)}
                                                        helperText={<span>Campo Opcional</span>}
                                                        size="small"
                                                        variant="outlined"
                                                        fullWidth
                                                        multiline
                                                    />
                                                </div>
                                            </div>
                                        </VSAccordionSimple>
                                    </div>
                                </div>
                                <hr className="my-2" />
                                <div className="row">
                                    <div className="col">
                                        <VSAccordionSimple
                                            expanded={true}
                                            header={(_) => (
                                                "Observações Internas"
                                            )}
                                        >
                                            <div className="row">
                                                <div className="col-12">
                                                    <TextField
                                                        placeholder="Anotações particulares (temperamento, característica, etc.)"
                                                        label="Observações do animal"
                                                        value={notes}
                                                        onChange={({ target: { value } }) => setNotes(value)}
                                                        helperText={<span>Campo Opcional<br/></span>}
                                                        size="small"
                                                        variant="outlined"
                                                        multiline
                                                        fullWidth
                                                    />
                                                </div>
                                                <div className="col input-info">
                                                    As observações são anotações particulares suas sobre o animal,
                                                    como temperamento, alguma característica específica do animal, etc. <b>Elas nunca serão compartilhadas com o tutor!</b>
                                                </div>
                                            </div>
                                        </VSAccordionSimple>
                                    </div>
                                </div>
                            </>
                        )}
                    </>
                )
            )}
        </VSDrawer>
    )
}

const mapStateToProps = state => {
    return {
        clinic: state.clinic,
        onboarding: state.onboarding,
        permissions: state.permissions
    };
};
  
export default connect(mapStateToProps)(PatientForm);