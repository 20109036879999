import React, { forwardRef, useImperativeHandle } from 'react'

import { TextField } from '@mui/material';

const TermAnesthesiaForm = forwardRef((props, ref) => {

    const {
        procedure,
        setProcedure,
        notes,
        setNotes
    } = props

    useImperativeHandle(ref, () => ({
        fieldsWithErrorText(errors) {
            return errors
        },
        isValid() {
            return true
        },
        validate(isValid) {
            return isValid
        }
    }))

    return (
        <div className="row pt-4">
            <div className="col">
                <div className="row mb-4">
                    <div className="col-12">
                        <div className="input-title input-height">
                            Tipo de procedimento anestésico indicado (Opcional)
                        </div>
                    </div>
                    <div className="col-12">
                        <TextField
                            value={procedure}
                            placeholder="Digite aqui o procedimento indicado"
                            onChange={({ target: { value } }) => setProcedure(value)}
                            size="small"
                            variant="outlined"
                            fullWidth
                        />
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-12">
                        <div className="input-title input-height">
                            Observações gerais (opcional)
                        </div>
                    </div>
                    <div className="col-12">
                        <TextField
                            value={notes}
                            placeholder="Escreva aqui as observações gerais sobre o atestado"
                            onChange={({ target: { value } }) => setNotes(value)}
                            size="small"
                            variant="outlined"
                            fullWidth
                            multiline
                        />
                    </div>
                </div>
            </div>
        </div>
    )
})

export default TermAnesthesiaForm;