import React, { useEffect } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom";

import { ReactComponent as IconArrowRight } from "../../../assets/icon-arrow-right.svg"

import BrowserSafari from "./BrowserSafari";
import BrowserChrome from "./BrowserChrome";
import BrowserFirefox from "./BrowserFirefox";
import BrowserEdge from "./BrowserEdge";

import "./BrowserRouters.scss"
const BrowserRouters = (props) => {

    const {
        browser
    } = useParams()

    const {
        history
    } = props

    useEffect(() => {
        const tag = window.document.getElementById("trial-pro")
        if (tag) {
            tag.style.display = "none"
        }
        return () => {
            if (tag) {
                tag.style.display = "block"
            }
        }
    }, [])

    return (
        <div id="responsive-browser">
            <div id="subheader" onClick={() => history.replace("/responsivo")}>
                <IconArrowRight width={16} height={16} fill="var(--vsprontuario-primary-color)" style={{ transform: 'rotate(180deg)' }} />
                <span>VOLTAR PARA TELA ANTERIOR</span>
            </div>
            <div id="browser-content">
                { browser == "safari" ? (
                    <BrowserSafari {...props} />
                ) : browser == "firefox" ? (
                    <BrowserFirefox {...props} />
                ) : browser == "edge" ? (
                    <BrowserEdge {...props} />
                ) : (
                    <BrowserChrome {...props} />
                )}
                <p>
                    Um ícone vai aparecer na Tela de Início do seu dispositivo 
                    ou entre seus aplicativos, como um atalho direto, rápido e 
                    fácil para a página do seu prontuário.
                </p>
                <hr />
                <p>
                    Para apagar o atalho, basta manter o dedo pressionado sobre o ícone. 
                    Em seguida, toque em “Remover”. Você também pode pressionar e arrastar 
                    para mudar a posição do botão entre as páginas da tela.
                </p>
            </div>
        </div>
    );
}
  
export default BrowserRouters