import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react'

import {
    getStateList,
    showArrayIndex,
    mandatoryField
} from '../../../../utils'

import { ReactComponent as IconAddO } from "../../../../assets/icon-add-o.svg"
import { ReactComponent as IconCloseO } from "../../../../assets/icon-close-o.svg"

import { MenuItem, TextField } from '@mui/material'

const TermSurgeryForm = forwardRef((props, ref) => {

    const {
        procedures,
        setProcedures,
        vetName,
        setVetName,
        vetCrmv,
        setVetCrmv,
        vetCrmvStateId,
        setVetCrmvStateId,
        notes,
        setNotes
    } = props

    const defaultSurgeryWithValidation = {
        surgery: '',
        errorSurgery: false,
        helperSurgery: mandatoryField,
        notes: ''
    }

    const [ surgeryRequests, setSurgeryRequests ] = useState([])

    const setProcedure = (index, field, value) => {
        let newSurgeries = [...surgeryRequests]
        newSurgeries[index][field] = value
        setSurgeryRequests(newSurgeries)
    }

    const removeProcedure = (index) => {
        let newSurgeries = [...surgeryRequests]
        newSurgeries.splice(index, 1)
        setSurgeryRequests(newSurgeries)
    }

    useImperativeHandle(ref, () => ({
        fieldsWithErrorText(errors) {
            if (surgeryRequests.length == 0) {
                errors.push("A cirurgia é obrigatória para o Termo para Realização de Cirurgias")
            }
            surgeryRequests.map((surgery, index) => {
                if (surgery.errorProcedure) {
                    errors.push(`A cirurgia ${showArrayIndex(index + 1)} não foi preenchida`)
                }
            })
            return errors
        },
        isValid() {
            return surgeryRequests.length > 0 && surgeryRequests.filter(surgery => surgery.errorProcedure).length == 0
        },
        validate(isValid) {
            if (surgeryRequests.length == 0) {
                isValid = false
            } else {
                surgeryRequests.map((surgery, index) => {
                    if (surgery.surgery) {
                        setProcedure(index, "errorProcedure", false)
                        setProcedure(index, "helperProcedure", mandatoryField)
                    } else {
                        isValid = false
                        setProcedure(index, "errorProcedure", true)
                        setProcedure(index, "helperProcedure", "O nome do cirurgia é obrigatório")
                    }
                })
            }
            return isValid
        }
    }))

    useEffect(() => {
        setSurgeryRequests(procedures.map(surgery => Object.assign({}, { ...defaultSurgeryWithValidation }, surgery)))
    }, [])

    useEffect(() => {
        setProcedures(surgeryRequests.map(surgery => ({
            surgery: surgery.surgery,
            notes: surgery.notes
        })))
    }, [ JSON.stringify(surgeryRequests.map(procedure => ({ surgery: procedure.surgery, notes: procedure.notes }))) ])

    return (
        <div className="row pt-4">
            <div className="col">
                <div className="row mb-1">
                    <div className="col-12">
                        <div className="input-title input-height">
                            Cirurgia{ surgeryRequests.length == 1 ? "" : "s" } a Ser{ surgeryRequests.length == 1 ? "" : "em" } Realizada{ surgeryRequests.length == 1 ? "" : "s" }
                        </div>
                    </div>
                </div>
                { surgeryRequests.map((surgery, index) => (
                    <React.Fragment key={index}>
                        <div className="row align-items-center mb-3">
                            <div className="col">
                                <div className="input-array py-1">
                                    Cirurgia { showArrayIndex(index + 1) }
                                </div>
                            </div>
                            { surgeryRequests.length > 1 && (
                                <div className="col-auto">
                                    <button
                                        className="btn-array px-0"
                                        onClick={() => removeProcedure(index)}
                                    >
                                        <IconCloseO width="24" height="24" />
                                        Remover Cirurgia
                                    </button>
                                </div>
                            )}
                        </div>
                        <div className="row align-items-center mb-4">
                            <div className="col-12">
                                <TextField
                                    placeholder={`Digite o nome da cirurgia ${ showArrayIndex(index + 1) }`}
                                    label={`Nome da cirurgia ${ showArrayIndex(index + 1) }`}
                                    onChange={({ target: { value } }) => setProcedure(index, "surgery", value)}
                                    value={surgery.surgery}
                                    error={surgery.errorProcedure}
                                    helperText={surgery.helperProcedure}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                />
                            </div>
                        </div>
                        <div className="row align-items-center mb-4">
                            <div className="col-12">
                                <TextField
                                    label={`Observações da Cirurgia ${ showArrayIndex(index + 1) }`}
                                    placeholder="Digite as observações"
                                    value={surgery.notes}
                                    onChange={({ target: { value } }) => setProcedure(index, "notes", value)}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                />
                            </div>
                        </div>
                    </React.Fragment>
                ))}
                <div className="row align-items-center mb-4">
                    <div className="col-12">
                        <button
                            className="btn-add" 
                            onClick={() => setSurgeryRequests(surgeryRequests.concat({ ...defaultSurgeryWithValidation }))}
                        >
                            <IconAddO width="20" height="20" />
                            Adicionar Cirurgia { showArrayIndex(surgeryRequests.length + 1) }
                        </button>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col">
                        <div className="input-title input-height">
                            Profissional que realizará o{ surgeryRequests.length == 1 ? "" : "s" } procedimento{ surgeryRequests.length == 1 ? "" : "s" } (opcional)
                        </div>
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-12">
                        <TextField
                            value={vetName}
                            placeholder="Digite Aqui o nome do Profissional"
                            label="Nome do Profissional"
                            onChange={({ target: { value } }) => setVetName(value)}
                            size="small"
                            variant="outlined"
                            fullWidth
                        />
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-6">
                        <TextField
                            type="tel"
                            value={vetCrmv}
                            placeholder="Digite Aqui o CRMV"
                            label="Número CRMV"
                            onChange={({ target: { value } }) => setVetCrmv(value)}
                            size="small"
                            variant="outlined"
                            fullWidth
                        />
                    </div>
                    <div className="col-6">
                        <TextField
                            placeholder="Selecione o Estado"
                            label="Estado do CRMV"
                            value={vetCrmvStateId}
                            onChange={({ target: { value } }) => setVetCrmvStateId(value)}
                            size="small"
                            variant="outlined"
                            select
                            fullWidth
                        >
                            { getStateList().map(stateItem => (
                                <MenuItem key={stateItem.value} value={stateItem.value}>
                                    { stateItem.text }
                                </MenuItem>
                            )) }
                        </TextField>
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-12">
                        <div className="input-title input-height">
                            Observações gerais (opcional)
                        </div>
                    </div>
                    <div className="col-12">
                        <TextField
                            value={notes}
                            placeholder="Escreva aqui as observações gerais sobre o atestado"
                            onChange={({ target: { value } }) => setNotes(value)}
                            size="small"
                            variant="outlined"
                            fullWidth
                            multiline
                        />
                    </div>
                </div>
            </div>
        </div>
    )
})

export default TermSurgeryForm;