import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import Autocomplete from '@mui/lab/Autocomplete';
import { CircularProgress, FormControl, MenuItem, Select, TextField } from '@mui/material';

import { ReactComponent as IconAddO } from "../../../../assets/icon-add-o.svg"
import { ReactComponent as IconCloseO } from "../../../../assets/icon-close-o.svg"

import { canUserEditVeterinaryDocuments, dayjs, saveOnTracker, showArrayIndex } from '../../../../utils'
import { getPatient } from '../../Patient'
import { ACL, APP_DEVICE, APP_NAME, APP_VERSION } from '../../../../constants';
import { getDiseases, getExamRequest, getExams } from './Exam';

import VSDrawer from '../../../../components/vsDrawer/VSDrawer';
import VSMedicalRecordsAudit from '../../../../components/vsMedicalRecordsAudit/VSMedicalRecordsAudit';
import VSError from '../../../../components/vsError/VSError';
import Blocked from '../../../blocked/Blocked';
import VSDrawerInfo from '../../../../components/vsDrawer/VSDrawerInfo';

import './ExamForm.scss'
const ExamForm = (props) => {

    const {
        id,
        id_solicitacao,
        id_documento,
        ver
    } = useParams()

    const {
        user,
        clinic,
        history,
        permissions
    } = props

    const update = !(id_solicitacao == "novo" || id_documento == "novo")
    const date = dayjs()

    const canEdit = canUserEditVeterinaryDocuments()

    const [ loading, setLoading ] = useState(true)
    const [ error, setError ] = useState(null)
    const [ examRequest, setExamRequest ] = useState(null)
    const [ patient, setPatient ] = useState(null)
    const [ hasSubmited, setHasSubmited ] = useState(false)
    const [ documentDay, setDocumentDay ] = useState(date.format("DD"))
    const [ documentMonth, setDocumentMonth ] = useState(date.format("MM"))
    const [ documentYear, setDocumentYear ] = useState(date.format("YYYY"))
    const [ notes, setNotes ] = useState("")

    const defaultExam = {
        examId: '',
        exam: '',
        notes: '',
        errorExam: false,
        helperExam: <span>Campo <b>Obrigatório</b></span>
    }
    const [ examOptions, setExamOptions ] = useState([])
    const [ exams, setExams ] = useState([defaultExam])

    const defaultDisease = {
        diseaseId: '',
        disease: ''
    }
    const [ diseaseOptions, setDiseaseOptions ] = useState([])
    const [ diseases, setDiseases ] = useState([defaultDisease])

    const defaultDate = dayjs()
    const [ documentDate, setDocumentDate ] = useState(defaultDate.toDate())
    const [ errorDocumentDate, setErrorDocumentDate ] = useState(false)
    const [ helperDocumentDate, setHelperDocumentDate ] = useState(<span>Campo <b>Obrigatório</b></span>)

    const handleDocumentDate = (year, month, day) => {
        setDocumentYear(year)
        setDocumentMonth(month)
        setDocumentDay(day)
        const documentDate = dayjs(`${year}-${month}-${day} 00:00:00`, "YYYY-MM-DD HH:mm:ss")
        if (day && month && year && documentDate.isValid()) {
            const next5Years = dayjs().add(5, 'years')
            if (parseInt(year) < 1971) {
                setErrorDocumentDate(true)
                setHelperDocumentDate("O ano não pode ser menor que 1971")
            } else if (documentDate > next5Years) {
                setErrorDocumentDate(true)
                setHelperDocumentDate("A data da prescrição não pode exceder 5 anos")
            } else {
                setErrorDocumentDate(false)
                setHelperDocumentDate(<span>Campo <b>Obrigatório</b></span>)
                setDocumentDate(documentDate.toDate())
            }
        } else {
            setErrorDocumentDate(true)
            setHelperDocumentDate("A data informada não é válida")
        }
    }

    const validateOnTouch = () => {
        if (hasSubmited && (documentDate || documentYear || documentMonth || documentDay || exams.filter(exam => exam.exam || exam.notes).length)) {
            validate()
        }
    }

    const validate = () => {
        let isValid = true
        if (loading) {
            isValid = false
        }

        if (error?.message) {
            isValid = false
        }

        const _documentDate = dayjs(documentDate)
        if (_documentDate.isValid()) {
            if (_documentDate.format("YYYY") < 1971) {
                isValid = false
                setErrorDocumentDate(true)
                setHelperDocumentDate("A data não pode ser menor que 1971")
            } else if (_documentDate.diff(dayjs(), "years") > 5) {
                isValid = false
                setErrorDocumentDate(true)
                setHelperDocumentDate("A data não pode exceder 5 anos")
            } else {
                setErrorDocumentDate(false)
                setHelperDocumentDate(<span>Campo <b>Obrigatório</b></span>)
            }
        } else {
            isValid = false
            setErrorDocumentDate(true)
            setHelperDocumentDate("A data informada não é válida")
        }

        exams.map((exam, index) => {
            if (exam.exam) {
                setExam(index, "errorExam", false)
                setExam(index, "helperExam", <span>Campo <b>Obrigatório</b></span>)
            } else {
                isValid = false
                setExam(index, "errorExam", true)
                setExam(index, "helperExam", "O nome do exame é obrigatório")
            }
        })

        return isValid
    }

    const isValid = () => !loading && !errorDocumentDate && exams.filter(exam => !exam.errorExam).length

    const fieldsWithErrorText = () => {
        let errors = []
        if (loading) {
            errors.push("Salvando a solicitação de exame")
        }
        if (error?.message) {
            errors.push(error.message)
        }
        if (errorDocumentDate) {
            errors.push("Data da consulta")
        }
        if (exams.filter(exam => exam.errorExam).length) {
            errors.push("Nome do exame")
        }
        if (!permissions?.check(ACL.EXAM_REQUEST, ACL.Rule.EDIT, examRequest)) {
            errors.push("Acesso negado")
        }
        if (!canEdit) {
            errors = ["Somente Médicos Veterinários com CRMV podem registrar solicitações de exame."]
        }
        return errors
    }

    const getPatientOrError = () => {
        setLoading(true)
        setError(null)

        getPatient(id, clinic.object)
        .then(_patient => {
            setPatient(_patient)
        })
        .catch(error => {
            Swal.fire(
                'Desculpe',
                error,
                'error'
            )
            .then(_ => {
                setLoading(false)
                setError({
                    message: error,
                    function: "getPatientOrError"
                })
            })
        })
    }

    const getExamRequestOrError = () => {
        if (permissions?.check(ACL.EXAM_REQUEST, ACL.Rule.VIEW)) {
            saveOnTracker("Iniciou", "Solicitação de Exame", update ? (id_solicitacao || id_documento) : "")
            if (update) {
                setLoading(true)
                setError(null)
    
                getExamRequest(id_solicitacao || id_documento, patient)
                .then(_exam => {
                    const _exams = _exam.get("exams") ? (
                        _exam.get("exams").map(exam => Object.assign({ ...defaultExam }, exam))
                    ) : []
                    const _documentDate = dayjs(_exam.get("documentDate"))
                    setExamRequest(_exam)
                    setExams(_exams)
                    setDiseases(_exam.get("diseases"))
                    setNotes(_exam.get("notes"))
                    setDocumentDate(_exam.get("documentDate"))
                    setDocumentYear(_documentDate.format("YYYY"))
                    setDocumentMonth(_documentDate.format("MM"))
                    setDocumentDay(_documentDate.format("DD"))
                    setLoading(false)
                })
                .catch(error => {
                    Swal.fire(
                        'Desculpe',
                        error,
                        'error'
                    )
                    .then(_ => {
                        setLoading(false)
                        setError({
                            message: error,
                            function: "getExamRequestOrError"
                        })
                    })
                })
            } else {
                let newExamRequest = new Parse.Object("ExamRequest")
                newExamRequest.setACL(clinic.ACL)
                newExamRequest.set("clinic", clinic.object)
                newExamRequest.set("createdBy", Parse.User.current())
                newExamRequest.set("createdApp", APP_NAME)
                newExamRequest.set("createdDevice", APP_DEVICE)
                newExamRequest.set("createdAppVersion", APP_VERSION)
                newExamRequest.set("isDeleted", false)
                newExamRequest.set("patient", patient)
                setExamRequest(newExamRequest)
                setLoading(false)
            }
        }
    }

    const save = () => {
        return new Promise((resolve, reject) => {
            setHasSubmited(true)
            if (validate()) {
                setLoading(true)
                let newExams = [...exams].map(exam => ({
                    "examId": exam.examId,
                    "exam": exam.exam,
                    "notes": exam.notes
                }))
                examRequest.set('documentDate', documentDate);
                examRequest.set('exams', newExams);
                examRequest.set('diseases', [...diseases]);
                examRequest.set('notes', notes);
                examRequest.set("changedAt", new Date())
                examRequest.set("changedBy", Parse.User.current())
                examRequest.set("changedApp", APP_NAME)
                examRequest.set("changedDevice", APP_DEVICE)
                examRequest.set("changedAppVersion", APP_VERSION)
                examRequest.save()
                .then(_exam => {
                    saveOnTracker("Concluiu", "Solicitação de Exame", _exam.id)
                    setLoading(false)
                    resolve(_exam)
                })
                .catch(error => {
                    setLoading(false)
                    console.error(error)
                    Swal.fire(
                        'Desculpe',
                        `Ocorreu algum erro ao tentar ${update ? "editar" : "cadastrar"} a solicitação de exame do animal`,
                        'error'
                    )
                    update && examRequest.revert()
                    reject()
                })
            } else {
                reject()
            }
        })
    }

    const setExam = (index, field, value) => {
        let newExams = [...exams]
        newExams[index][field] = value
        setExams(newExams)
    }

    const removeExam = (index) => {
        let newExams = [...exams]
        newExams.splice(index, 1)
        setExams(newExams)
    }

    const setDisease = (index, field, value) => {
        let newDiseases = [...diseases]
        newDiseases[index][field] = value
        setDiseases(newDiseases)
    }

    const removeDisease = (index) => {
        let newDiseases = [...diseases]
        newDiseases.splice(index, 1)
        setDiseases(newDiseases)
    }

    const renderPreview = () => {
        return (
            <div className="col-12">
                <div className="row">
                    <div className="col">
                        <h4 className="mb-3">Prévia da Solicitação</h4>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <h5 className="mb-2">Exames Solicitados</h5>
                    </div>
                </div>
                { exams && exams.length > 0 && exams.filter(exam => exam.exam && exam.exam.length > 0).length > 0 ? (
                    exams.map((exam, index) => (
                        <React.Fragment key={index}>
                            <p>
                                <b>{ exam.exam }</b><br/>
                                { exam.notes }
                            </p>
                        </React.Fragment>
                    ))
                ) : (
                    <p className="text-red">
                        Não informado (dados obrigatórios, preencha ao lado)
                    </p>
                )}
                { diseases && diseases.length > 0 && diseases.filter(disease => disease.disease && disease.disease.length > 0).length > 0 && (
                    <React.Fragment>
                        <div className="row">
                            <div className="col">
                                <h5 className="mb-2">Motivos/Suspeitas</h5>
                            </div>
                        </div>
                        <p>
                            { diseases.map((disease, index) => (
                                <React.Fragment key={index}>
                                    - { disease.disease }<br/>
                                </React.Fragment>
                            ))}
                        </p>
                    </React.Fragment>
                )}
                { notes && notes.length > 0 && (
                    <div className="row">
                        <div className="col-12">
                            <h5 className="mb-2">Instruções/Observações Gerais da Solicitação</h5>
                        </div>
                        <div className="col-12">
                            <p className="mb-2">{ notes }</p>
                        </div>
                    </div>
                )}
            </div>
        )
    }

    useEffect(() => {
        validateOnTouch()
    }, [ documentDate, documentYear, documentMonth, documentDay, JSON.stringify(exams.map(exam => ({ examId: exam.examId, exam: exam.exam }))) ])

    useEffect(() => {
        if (examRequest && !permissions?.check(ACL.EXAM_REQUEST, ACL.Rule.EDIT, examRequest)) {
            permissions?.error()
        }
    }, [ examRequest ])

    useEffect(() => {
        if (patient) {
            getExamRequestOrError()
        }
    }, [ patient ])

    useEffect(() => {
        getExams().then(_exams => setExamOptions(_exams))
        getDiseases().then(_diseases => setDiseaseOptions(_diseases))
        if (id) {
            getPatientOrError()
        }
    }, [])

    const errors = fieldsWithErrorText()

    return (
        <VSDrawer
            id="exam-form"
            timeout={0}
            title={update ? "Editar Solicitação de Exame" : "Adicionar Nova Solicitação"}
            cancel={<span>Cancelar Solicitação<span className="d-none d-md-inline"> de Exame</span></span>}
            submit={isValid ? (<span>Salvar e Visualizar<span className="d-none d-xl-inline"> Solicitação</span></span>) : <span>Preencha os campos<span className="d-none d-md-inline"> necessários</span></span>}
            errors={errors}
            onSubmit={save}
            onCancel={() => history.goBack()}
            withVSDrawerInfo
            onAfterSave={(object) => {
                if (id_solicitacao) {
                    history.replace(`/documento/exame/${object.id}`)
                } else {
                    history.goBack()
                }
            }}
            VSDrawerCancelProps={{
                title: "Tem certeza que deseja cancelar a solicitação de exame?",
                confirm: "Cancelar Solicitação de Exame",
                cancel: "Voltar para Edição"
            }}
        >
            { loading ? (
                <div className="row data-loading">
                    <div className="col">
                        <CircularProgress />
                    </div>
                </div>
            ) : error?.message ? (
                <VSError
                    message={error.message}
                    onClose={() => {
                        switch(error.function) {
                            case "getPatientOrError":
                                getPatientOrError()
                                break
                            case "getExamRequestOrError":
                                getExamRequestOrError()
                                break
                            default:
                        }
                    }}
                />
            ) : canEdit ? (
                <div className="row g-3 align-items-center">
                    <div className="col-12 col-md">
                        <div className="input-title">
                            DATA DA SOLICITAÇÃO DE EXAME
                        </div>
                    </div>
                    <div className="col-12 col-md-auto mt-1 mt-md-0 pe-md-0" style={{ width: '280px' }}>
                        <div className="row">
                            <div className="col-3 pe-0">
                                <TextField
                                    type="tel"
                                    name="documentDay"
                                    value={documentDay}
                                    onChange={({ target: { value }}) => handleDocumentDate(documentYear, documentMonth, value)}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                /> 
                            </div>
                            <div className="col-5 px-2">
                                <FormControl variant="outlined" size="small" fullWidth>
                                    <Select
                                        name="documentMonth"
                                        value={documentMonth}
                                        onChange={({ target: { value }}) => handleDocumentDate(documentYear, value, documentDay)}
                                    >
                                        <MenuItem value="01">Janeiro</MenuItem>
                                        <MenuItem value="02">Fevereiro</MenuItem>
                                        <MenuItem value="03">Março</MenuItem>
                                        <MenuItem value="04">Abril</MenuItem>
                                        <MenuItem value="05">Maio</MenuItem>
                                        <MenuItem value="06">Junho</MenuItem>
                                        <MenuItem value="07">Julho</MenuItem>
                                        <MenuItem value="08">Agosto</MenuItem>
                                        <MenuItem value="09">Setembro</MenuItem>
                                        <MenuItem value="10">Outubro</MenuItem>
                                        <MenuItem value="11">Novembro</MenuItem>
                                        <MenuItem value="12">Dezembro</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="col-4 ps-0">
                                <TextField
                                    type="tel"
                                    name="documentYear"
                                    value={documentYear}
                                    onChange={({ target: { value }}) => handleDocumentDate(value, documentMonth, documentDay)}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                /> 
                            </div>
                            { errorDocumentDate && (
                                <div className="col-12">
                                    <small className="text-danger">{ helperDocumentDate }</small>
                                </div>
                            )}
                        </div>
                    </div>
                    { exams.map((exam, index) => (
                        <React.Fragment key={index}>
                            <div className="col-12">
                                <div className="row">
                                    <div className="col">
                                        <div className="input-array py-1">
                                            Exame { showArrayIndex(index + 1) }
                                        </div>
                                    </div>
                                    { exams.length > 1 && (
                                        <div className="col-auto">
                                            <button className="btn-array" onClick={() => removeExam(index)}>
                                                <IconCloseO width="24" height="24" />
                                                Remover exame
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-12 mb-1">
                                        <div className="input-subtitle">
                                            Nome do Exame { showArrayIndex(index + 1) }
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <Autocomplete
                                            inputValue={exam.exam}
                                            options={examOptions}
                                            getOptionLabel={(option) => option.examName || exam.exam}
                                            getOptionSelected={(option) => option.examName == exam.exam}
                                            onChange={(_, selected) => {
                                                setExam(index, "exam", (selected && selected.examName) || "")
                                                setExam(index, "examId", (selected && selected.examId) || "")
                                            }}
                                            onInputChange={(event, selected) => {
                                                if (event) {
                                                    setExam(index, "exam", selected || "")
                                                    setExam(index, "examId", "")
                                                }
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    className="w-100"
                                                    placeholder={`Digite ou selecione o exame ${ showArrayIndex(index + 1) }`}
                                                    size="small"
                                                    variant="outlined"
                                                    error={exam.errorExam}
                                                    helperText={exam.helperExam}
                                                    fullWidth
                                                />
                                            )}
                                            openOnFocus
                                            freeSolo
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-12 mb-1">
                                        <div className="input-subtitle">
                                            Observações do Exame { showArrayIndex(index + 1) }
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <TextField
                                            className="w-100"
                                            placeholder={`Observações sobre o exame ${ showArrayIndex(index + 1) }`}
                                            value={exam.notes}
                                            size="small"
                                            variant="outlined"
                                            onChange={({ target: { value } }) => setExam(index, "notes", value)}
                                            helperText={<span>Campo Opcional<br/></span>}
                                            minRows={2}
                                            multiline
                                        />
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    )) }
                    <div className="col-12">
                        <button className="btn-add" onClick={() => setExams(exams.concat(defaultExam))}>
                            <IconAddO width="20" height="20" />
                            Adicionar Exame { showArrayIndex(exams.length + 1) }
                        </button>
                    </div>
                    <div className="col-12">
                        <div className="input-title">
                            Motivos/Suspeitas
                        </div>
                    </div>
                    { diseases.map((disease, index) => (
                        <React.Fragment key={index}>
                            <div className="col-12">
                                <div className="row">
                                    <div className="col">
                                        <div className="input-array py-1">
                                            Motivo/Suspeita { showArrayIndex(index + 1) }
                                        </div>
                                    </div>
                                    { diseases.length > 1 && (
                                        <div className="col-auto">
                                            <button className="btn-array p-0" onClick={() => removeDisease(index)}>
                                                <IconCloseO width="24" height="24" />
                                                Remover Motivo/Suspeita
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-12 mb-1">
                                        <div className="input-subtitle">
                                            Nome do Motivo/Suspeita { showArrayIndex(index + 1) }
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <Autocomplete
                                            inputValue={disease.disease}
                                            options={diseaseOptions}
                                            getOptionLabel={(option) => option.diseaseName || disease.disease}
                                            getOptionSelected={(option) => option.diseaseName == disease.disease}
                                            onChange={(_, selected) => {
                                                setDisease(index, "disease", (selected && selected.diseaseName) || "")
                                                setDisease(index, "diseaseId", (selected && selected.diseaseId) || "")
                                            }}
                                            onInputChange={(event, selected) => {
                                                if (event) {
                                                    setDisease(index, "disease", selected || "")
                                                    setDisease(index, "diseaseId", "")
                                                }
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    className="w-100"
                                                    placeholder="Digite ou selecione a doença"
                                                    size="small"
                                                    variant="outlined"
                                                    helperText={<span>Campo opcional</span> }
                                                    color="secondary"
                                                    fullWidth
                                                />
                                            )}
                                            openOnFocus
                                            freeSolo
                                        />
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    )) }
                    <div className="col-12">
                        <button className="btn-add" onClick={() => setDiseases(diseases.concat(defaultDisease))}>
                            <IconAddO width="20" height="20" />
                            Adicionar Motivo/Suspeita { showArrayIndex(diseases.length + 1) }
                        </button>
                    </div>
                    <div className="col-12">
                        <div className="row">
                            <div className="col-12">
                                <div className="input-title input-height">
                                    Instruções/Observações Gerais da Solicitação (Opcional)
                                </div>
                            </div>
                            <div className="col-12">
                                <TextField
                                    value={notes}
                                    placeholder="Digite aqui as instruções e observações sobre a solicitação"
                                    onChange={({ target: { value } }) => setNotes(value)}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                />
                            </div>
                        </div>
                    </div>
                    <VSDrawerInfo
                        title="Ver Prévia da Solicitação de Exame"
                    >
                        { renderPreview() }
                    </VSDrawerInfo>
                    <VSMedicalRecordsAudit parseObject={examRequest} />
                </div>
            ) : (
                <Blocked />
            )}
        </VSDrawer>
    )
}

const mapStateToProps = state => {
    return {
        user: state.user,
        clinic: state.clinic,
        permissions: state.permissions
    };
};
  
export default connect(mapStateToProps)(ExamForm);