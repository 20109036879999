import React from 'react'
import PropTypes from 'prop-types';

import './VSDrawerSuccess.scss'

import VetSuccessImage from "../../assets/vet-success.png"
import VSDrawerHeader from './VSDrawerHeader';
import VSDrawerFooter from './VSDrawerFooter';

const VSDrawerSuccess = (props) => {

    const {
        title,
        text,
        children,
        onClose,
        VSDrawerHeaderProps,
        VSDrawerFooterProps
    } = props

    return (
        <div id="vsdrawer-success">
            <VSDrawerHeader
                {...VSDrawerHeaderProps}
                onClose={() => onClose && onClose()}
            />
            <div className="vsdrawer-content row align-items-center">
                <div className="col text-center">
                    <img src={VetSuccessImage} />
                    { title && (<h1>{ title }</h1>) }
                    { text && (<p>{ text }</p>) }
                    { children }
                </div>
            </div>
            { VSDrawerFooterProps && (
                <VSDrawerFooter {...VSDrawerFooterProps} />
            )}
        </div>
    )
}

VSDrawerSuccess.propTypes = {
    title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node
    ]),
    text: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node
    ]),
    onClose: PropTypes.func
};

VSDrawerSuccess.defaultProps = {
    title: "Cadastro realizado com sucesso!",
    text: "Você será redirecionado(a) automaticamente",
    onClose: () => null
}

export default VSDrawerSuccess;