import React from 'react';

import "./CardDashboard.scss"
import { Alert } from '@mui/lab';

const CardDashboard = (props) => {

    const {
        isLoading = true,
        mobileAlert
    } = props

    function layoutLoading() {
        return <div className="container-fluid shimmer">
            <div className="wrapper">
                <div className="row  mt-3">
                    <div className="col-auto icon-loading me-2 animate">
                    </div>
                    <div className="col gx-0 d-flex">
                        <div className="title-loading animate" ></div>
                    </div>
                </div>
                <div className="row pt-4">
                    <div className="col line1-loading animate">
                    </div>
                </div>
                <div className="row pt-2">
                    <div className="col line2-loading animate">
                    </div>
                </div>
            </div>
        </div>
    }
    function layoutCard() {
        return (
            <div className="animateFadeIn">
                <div className="row">
                    <div className="col-auto pe-2">
                        {props.icon}
                    </div>
                    <div className="col-auto gx-0">
                        <div className="card-title">
                            {props.title}
                        </div>
                    </div>
                </div>
                <div className="show-card-dashboard d-flex flex-wrap justify-content-between card-text pt-4">
                    <div className="flex-shrink-0 text-nowrap me-2">
                        {props.labelFirst}
                    </div>
                    <div className="card-value flex-shrink-0 text-nowrap">
                        {props.valueFirst}
                    </div>
                </div>
                <div className="show-card-dashboard d-flex flex-wrap justify-content-between card-text pt-2">
                    <div className="flex-shrink-0 text-nowrap me-2">
                        {props.labelSecond}
                    </div>
                    <div className="card-value  flex-shrink-0 text-nowrap">
                        {props.valueSecond}
                    </div>
                </div>
                { mobileAlert && (
                    <Alert
                        icon={false}
                        severity="info"
                        className="d-block d-md-none mt-2 mb-0"
                    >
                        { mobileAlert }
                    </Alert>
                )}
            </div>
        )
    }
    return (

        <div id="card-dashboard" className="card-dashboard-element">
            {isLoading ? (
                layoutLoading()
            ) : (
                layoutCard()
            )}

        </div >
    )
}

export default CardDashboard;