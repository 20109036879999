import { APP_DEVICE, APP_NAME, APP_VERSION } from "../../../../constants"
import { mandatoryField, stringifyArray } from "../../../../utils"


export const deleteSurgery = (surgery, callback) => {
    Swal.fire({
        title: 'Tem certeza?',
        text: "Você tem certeza que deseja excluir essa cirurgia? Essa ação não poderá ser desfeita.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, quero excluir!',
        cancelButtonText: 'Não, manter cirurgia'
    }).then((result) => {
        if (result.isConfirmed) {
            surgery.set("isDeleted", true)
            surgery.set("changedAt", new Date())
            surgery.set("changedBy", Parse.User.current())
            surgery.set("changedApp", APP_NAME)
            surgery.set("changedDevice", APP_DEVICE)
            surgery.set("changedAppVersion", APP_VERSION)
            surgery.save()
            .then(_ => {
                Swal.fire(
                    'Excluído!',
                    'A cirurgia foi excluído com sucesso.',
                    'success'
                )
                .then(_ => {
                    callback && callback()
                })
            })
            .catch(error => {
                console.error(error)
                Swal.fire(
                    'Desculpe',
                    'Ocorreu algum erro ao tentar excluir a cirurgia',
                    'error'
                )
                surgery.revert()
            })
        }
    })
}

export const getSurgery = (id, patient) => {
    return new Promise((resolve, reject) => {
        var query = new Parse.Query("Surgery");
        query.equalTo("clinic", patient.get("clinic"));
        query.equalTo("isDeleted", false);
        query.equalTo("patient", patient)
        query.get(id)
        .then(_object => {
            if (_object) {
                resolve(_object)
            } else {
                reject('Não encontramos a cirurgia informada')
            }
        }).catch(error => {
            console.error(error)
            reject('Ocorreu algum erro ao tentar buscar a cirurgia')
        })
    })
}

export const showSurgery = (surgery) => {
    const surgeries = surgery.get("surgeries")
    if (surgeries) {
        return stringifyArray(surgeries.map(surgery => surgery.surgery))
    }
    let surgeryText = surgery.get("drug") || "Cirurgia"
    if (!surgeryText.toUpperCase().startsWith("CIRURGIA")) {
        surgeryText = "Cirurgia " + surgeryText;
    }
    return surgeryText
}

export const defaultSurgery = {
    surgery: "",
    materials: "",
    complications: ""
}

export const defaultSurgeryWithValidation = Object.assign(
    {},
    { ...defaultSurgery },
    {
        errorSurgery: false,
        helperSurgery: mandatoryField,
    }
)