import { dayjs } from "../../utils";

export const tables = [
    {
        className: "owner",
        name: "Tutores",
        depends: [],
        disabled: false,
        error: null,
        columns: [
            {
                name: "externalId",
                description: "Identificador do(a) tutor(a) no sistema anterior (geralmente um valor numérico ou uma chave com alguns caracteres - usaremos essa informação para identificar o(s) animal(is) pertencente(s) ao tutor)",
                example: "20000",
                mandatory: true
            },
            {
                name: "name",
                description: "Nome completo do(a) tutor(a)",
                example: "João da Silva",
                mandatory: true
            },
            {
                name: "email",
                description: "E-mail do(a) tutor(a)",
                example: "joaodasilva@email.com",
                mandatory: false
            },
            {
                name: "cpf",
                description: "CPF do(a) tutor(a)",
                example: "000.000.000-00",
                mandatory: false
            },
            {
                name: "rg",
                description: "RG do(a) tutor(a)",
                example: "00.000.000-0",
                mandatory: false
            },
            {
                name: "phone",
                description: "Celular do(a) tutor(a)",
                example: "(00) 00000-0000",
                mandatory: false
            },
            {
                name: "landlinePhone",
                description: "Telefone fixo do(a) tutor(a)",
                example: "(00) 0000-0000",
                mandatory: false
            },
            {
                name: "zipCode",
                description: "Código postal (CEP) do endereço do(a) tutor(a)",
                example: "00000-000",
                mandatory: false
            },
            {
                name: "addressStreet",
                description: "Logradouro do endereço do(a) tutor(a)",
                example: "Rua do Brasil",
                mandatory: false
            },
            {
                name: "addressNumber",
                description: "Número do endereço do(a) tutor(a)",
                example: "100",
                mandatory: false
            },
            {
                name: "addressComplement",
                description: "Complemento do endereço do(a) tutor(a)",
                example: "Bloco 02 Apartamento 20",
                mandatory: false
            },
            {
                name: "neighboorhod",
                description: "Bairro do endereço do(a) tutor(a)",
                example: "Centro",
                mandatory: false
            },
            {
                name: "city",
                description: "Cidade do endereço do(a) tutor(a)",
                example: "São Paulo",
                mandatory: false
            },
            {
                name: "stateId",
                description: "Estado (UF) do endereço do(a) tutor(a)",
                example: "SP",
                mandatory: false
            },
            {
                name: "notes",
                description: "Observações internas sobre o(a) tutor(a)",
                example: "Tutor ficou devendo R$ 100 da última consulta",
                mandatory: false
            }
        ]
    },
    {
        className: "patient",
        name: "Animais",
        depends: ["Owner"],
        disabled: false,
        error: "É necessário fazer uma importação de tutor(es) antes de importar animais",
        columns: [
            {
                name: "externalId",
                description: "Identificador do animal no sistema anterior (geralmente um valor numérico ou uma chave com alguns caracteres - usaremos essa informação para identificar o(s) documento(s) gerado(s) para o animal - importante para quando for possível importar consultas, exames, prescrições, etc.)",
                example: "30000",
                mandatory: true
            },
            {
                name: "owner",
                description: "Identificador do(a) tutor(a) (externalId)",
                example: "20000",
                mandatory: true
            },
            {
                name: "name",
                description: "Nome do animal",
                example: "Mel",
                mandatory: true
            },
            {
                name: "specie",
                description: "Espécie do animal",
                example: "Canino",
                mandatory: true
            },
            {
                name: "breed",
                description: "Raça do animal ou SRD (sem raça definida)",
                example: "Pastor Alemão",
                mandatory: true
            },
            {
                name: "gender",
                description: "Sexo do animal",
                example: "Fêmea",
                mandatory: false
            },
            {
                name: "pelage",
                description: "Pelagem do animal",
                example: "Castanho e Preto",
                mandatory: false
            },
            {
                name: "temperament",
                description: "Temperamento do animal",
                example: "Alerta",
                mandatory: false
            },
            {
                name: "castrated",
                description: "O animal é castrado (1), não (0) ou indefinido (2)",
                example: "0",
                mandatory: false
            },
            {
                name: "deceased",
                description: "O animal está morto (1) ou não (0)",
                example: "0",
                mandatory: false
            },
            {
                name: "microchip",
                description: "Código do microchip (caso o animal possua)",
                example: "9876000012340000",
                mandatory: false
            },
            {
                name: "rga",
                description: "RG do Animal (RGA)",
                example: "9000123",
                mandatory: false
            },
            {
                name: "size",
                description: "Porte (tamanho) do animal",
                example: "Grande",
                mandatory: false
            },
            {
                name: "birthdate",
                description: "Data de nascimento do animal",
                example: dayjs().subtract(1, "year").format("YYYY-MM-DD HH:mm:ss"),
                mandatory: false
            },
            {
                name: "notes",
                description: "Observações internas sobre o animal",
                example: "Animal mordeu a recepcionista na última pesagem",
                mandatory: false
            }
        ]
    }
]