import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react'
import Autocomplete from '@mui/lab/Autocomplete';

import {
    getStateList,
    showArrayIndex,
    mandatoryField
} from '../../../../utils'

import { ReactComponent as IconAddO } from "../../../../assets/icon-add-o.svg"
import { ReactComponent as IconCloseO } from "../../../../assets/icon-close-o.svg"

import { MenuItem, TextField } from '@mui/material'
import { getExams } from '../exam/Exam';

const TermExamForm = forwardRef((props, ref) => {

    const {
        exams,
        setExams,
        vetName,
        setVetName,
        vetCrmv,
        setVetCrmv,
        vetCrmvStateId,
        setVetCrmvStateId,
        notes,
        setNotes
    } = props

    const defaultExamWithValidation = {
        examId: '',
        exam: '',
        errorExam: false,
        helperExam: mandatoryField,
        notes: ''
    }

    const [ examOptions, setExamOptions ] = useState([])
    const [ examRequests, setExamRequests ] = useState([])

    const setExam = (index, field, value) => {
        let newExams = [...examRequests]
        newExams[index][field] = value
        setExamRequests(newExams)
    }

    const removeExam = (index) => {
        let newExams = [...examRequests]
        newExams.splice(index, 1)
        setExamRequests(newExams)
    }

    useImperativeHandle(ref, () => ({
        fieldsWithErrorText(errors) {
            if (examRequests.length == 0) {
                errors.push("O exame é obrigatório para o Termo para Realização de Exames")
            }
            examRequests.map((exam, index) => {
                if (exam.errorExam) {
                    errors.push(`O exame ${showArrayIndex(index + 1)} não foi preenchido`)
                }
            })
            return errors
        },
        isValid() {
            return examRequests.length > 0 && examRequests.filter(exam => exam.errorExam).length == 0
        },
        validate(isValid) {
            if (examRequests.length == 0) {
                isValid = false
            } else {
                examRequests.map((exam, index) => {
                    if (exam.exam) {
                        setExam(index, "errorExam", false)
                        setExam(index, "helperExam", mandatoryField)
                    } else {
                        isValid = false
                        setExam(index, "errorExam", true)
                        setExam(index, "helperExam", "O nome do exame é obrigatório")
                    }
                })
            }
            return isValid
        }
    }))

    useEffect(() => {
        getExams()
        .then(data => {
            setExamOptions(data.length > 0 ? data : [])
        })

        setExamRequests(exams.map(exam => Object.assign({}, { ...defaultExamWithValidation }, exam)))
    }, [])

    useEffect(() => {
        setExams(examRequests.map(exam => ({
            examId: exam.examId,
            exam: exam.exam,
            notes: exam.notes
        })))
    }, [ JSON.stringify(examRequests.map(exam => ({ examId: exam.examId, exam: exam.exam, notes: exam.notes }))) ])

    return (
        <div className="row pt-4">
            <div className="col">
                <div className="row mb-1">
                    <div className="col-12">
                        <div className="input-title input-height">
                            Exame{ examRequests.length == 1 ? "" : "s" } Solicitado{ examRequests.length == 1 ? "" : "s" }
                        </div>
                    </div>
                </div>
                { examRequests.map((exam, index) => (
                    <React.Fragment key={index}>
                        <div className="row align-items-center mb-3">
                            <div className="col">
                                <div className="input-array py-1">
                                    Exame { showArrayIndex(index + 1) }
                                </div>
                            </div>
                            { examRequests.length > 1 && (
                                <div className="col-auto">
                                    <button
                                        className="btn-array px-0"
                                        onClick={() => removeExam(index)}
                                    >
                                        <IconCloseO width="24" height="24" />
                                        Remover Exame
                                    </button>
                                </div>
                            )}
                        </div>
                        <div className="row align-items-center mb-4">
                            <div className="col-12">
                                <Autocomplete
                                    inputValue={exam.exam}
                                    options={examOptions}
                                    getOptionLabel={(option) => option.examName || exam.exam}
                                    getOptionSelected={(option) => option.examName == exam.exam}
                                    onChange={(_, selected) => {
                                        setExam(index, "exam", (selected && selected.examName) || "")
                                        setExam(index, "examId", (selected && selected.examId) || "")
                                    }}
                                    onInputChange={(event, selected) => {
                                        if (event) {
                                            setExam(index, "exam", selected || "")
                                            setExam(index, "examId", "")
                                        }
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            className="w-100"
                                            placeholder={`Digite ou selecione o exame ${ showArrayIndex(index + 1) }`}
                                            error={exam.errorExam}
                                            helperText={exam.helperExam}
                                            size="small"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    )}
                                    openOnFocus
                                    freeSolo
                                />
                            </div>
                        </div>
                        <div className="row align-items-center mb-4">
                            <div className="col-12">
                                <TextField
                                    label={`Observações do Exame ${ showArrayIndex(index + 1) }`}
                                    placeholder="Digite as observações"
                                    value={exam.notes}
                                    onChange={({ target: { value } }) => setExam(index, "notes", value)}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                />
                            </div>
                        </div>
                    </React.Fragment>
                ))}
                <div className="row align-items-center mb-4">
                    <div className="col-12">
                        <button
                            className="btn-add" 
                            onClick={() => setExamRequests(examRequests.concat({ ...defaultExamWithValidation }))}
                        >
                            <IconAddO width="20" height="20" />
                            Adicionar Exame { showArrayIndex(examRequests.length + 1) }
                        </button>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col">
                        <div className="input-title input-height">
                            Profissional que realizará o{ examRequests.length == 1 ? "" : "s" } procedimento{ examRequests.length == 1 ? "" : "s" } (opcional)
                        </div>
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-12">
                        <TextField
                            value={vetName}
                            placeholder="Digite Aqui o nome do Profissional"
                            label="Nome do Profissional"
                            onChange={({ target: { value } }) => setVetName(value)}
                            size="small"
                            variant="outlined"
                            fullWidth
                        />
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-6">
                        <TextField
                            type="tel"
                            value={vetCrmv}
                            placeholder="Digite Aqui o CRMV"
                            label="Número CRMV"
                            onChange={({ target: { value } }) => setVetCrmv(value)}
                            size="small"
                            variant="outlined"
                            fullWidth
                        />
                    </div>
                    <div className="col-6">
                        <TextField
                            placeholder="Selecione o Estado"
                            label="Estado do CRMV"
                            value={vetCrmvStateId}
                            onChange={({ target: { value } }) => setVetCrmvStateId(value)}
                            size="small"
                            variant="outlined"
                            select
                            fullWidth
                        >
                            { getStateList().map(stateItem => (
                                <MenuItem key={stateItem.value} value={stateItem.value}>
                                    { stateItem.text }
                                </MenuItem>
                            )) }
                        </TextField>
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-12">
                        <div className="input-title input-height">
                            Observações gerais (opcional)
                        </div>
                    </div>
                    <div className="col-12">
                        <TextField
                            value={notes}
                            placeholder="Escreva aqui as observações gerais sobre o atestado"
                            onChange={({ target: { value } }) => setNotes(value)}
                            size="small"
                            variant="outlined"
                            fullWidth
                            multiline
                        />
                    </div>
                </div>
            </div>
        </div>
    )
})

export default TermExamForm;