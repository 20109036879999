import React from "react";

import { isReminderLate } from "./Vaccine";
import { getShots, getTitleForVaccine, getFirstScheduledAtFromArray, getFirstSentAtFromArray } from "./VaccineListView";
import { showDate } from "../../../../utils";

import './BoxVaccineForView.scss'

const BoxVaccineForView = props => {
    const {
        vaccine,
        className
    } = props

    const dateToShow = showDate(vaccine.get("documentDate")) || "-"
    return (
        <div className={`vsbox-vaccine-for-view vsbox-item ${className} pb-0 overflow-hidden`}>
            <div className="row vsbox-item-text">
                <div className="col">
                    <div className="vsbox-title">
                        {getTitleForVaccine(vaccine)}
                    </div>
                </div>
            </div>
            <div className="row vsbox-item-text mt-1">
                <div className="col">
                    {getShots(vaccine) && <span className="me-3"><span className="me-3">{getShots(vaccine)}</span>•</span>}
                    <span>{vaccine.get("isReminder") ? "Programada para o " : "Aplicada "} dia {dateToShow}</span>
                </div>
            </div>
            <div className="row vsbox-item-text mt-1">
                <div className="col">
                    <hr className="my-3" />
                </div>
            </div>
            <div className="row vsbox-item-text mb-3">
                <div className="col-12">
                    <b>Observações:</b> { vaccine?.get("notes") || "Não informado" }
                </div>
            </div>
        </div>
    )
}

export default BoxVaccineForView