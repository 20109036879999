import React, { useState } from 'react'
import { connect } from 'react-redux'

import { ReactComponent as IconEdit } from "../../../../assets/icon-primary-edit.svg"
import { ReactComponent as IconTrash } from "../../../../assets/icon-primary-trash.svg"
import { ReactComponent as IconReturn } from '../../../../assets/icon-return.svg'
import { ReactComponent as IconNotification } from "../../../../assets/icon-notification.svg"
import { ReactComponent as IconNotificationOn } from "../../../../assets/icon-notification-on.svg"
import { ReactComponent as IconNotificationOff } from "../../../../assets/icon-notification-off.svg"
import { ReactComponent as IconMenu } from "../../../../assets/icon-menu-2.svg"
import { ReactComponent as IconEye } from "../../../../assets/icon-primary-eye.svg"
import { ReactComponent as IconResend } from "../../../../assets/icon-resend.svg"
import { ReactComponent as IconVaccine } from '../../../../assets/icon-patient-vaccine.svg'

import { addEvent, showRegisteredBy, showDate, validateDate } from '../../../../utils'
import { ACL } from '../../../../constants'
import { isReminderLate, cancelVaccine, deleteVaccine } from './Vaccine'

import VSAccordionSimple from '../../../../components/vsAccordion/VSAccordionSimple'
import VSMenu from '../../../../components/vsMenu/VSMenu'
import VSDrawer from '../../../../components/vsDrawer/VSDrawer'
import BoxVaccine from './BoxVaccine'
import BoxVaccineWithReminder from './BoxVaccineWithReminder'

import './VaccineListView.scss'
import { CircularProgress } from '@mui/material'

export const getShots = _vaccine => _vaccine.get("currentShot") && _vaccine.get("totalShots") ? `Dose: ${_vaccine.get("currentShot")} de ${_vaccine.get("totalShots")}` : ""
export const getTitleForVaccine = _vaccine => (
    _vaccine.get("vaccineType") && _vaccine.get("vaccine") ? (
        _vaccine.get("vaccineType") + ' - ' + _vaccine.get("vaccine")
    ) : _vaccine.get("vaccineType") ? 
        _vaccine.get("vaccineType") : 
        _vaccine.get("vaccine") ? 
        _vaccine.get("vaccine") : "Vacina não informada"
)
export const getFirstSentAtFromArray = messages => {
    if(messages && messages.length > 0){
        var sentMessages = messages.filter(message => message && message.get('sentAt'))
        if(sentMessages.length == messages.length){
            return sentMessages[0].get('sentAt')
        }
    }
}
export const getFirstScheduledAtFromArray = messages => messages && messages.length > 0 ? messages.find(message => message && message.get('scheduledAt'))?.get('scheduledAt') : undefined

const VaccineListView = props => {
    const {
        clinic,
        object,
        history,
        expanded,
        updateList,
        fromPatient
    } = props

    const vaccine   = object
    const patient   = vaccine.get("patient")
    const owner     = patient.get("owner")
    const isDeleted = vaccine.get("isDeleted")
    const dateToShow = showDate(vaccine.get("documentDate")) || "-"

    const renderOtherDoses = () => {
        const allOtherDoses = []
        var currentParent = vaccine.get('parentVaccine')
        // var currentIndex = 0
        while(currentParent){
            ((currentParent) => {
                allOtherDoses.push((
                    <BoxVaccine 
                        key={vaccine.id}
                        vaccine={currentParent}
                        className="mb-3"
                        buttons={
                            <div className="col-auto">
                                <div 
                                    className="vsbox-btn vsbox-btn-dark btn-menu cursor-pointer"
                                    onClick={() => history.push(`/animal/${patient.id}/vacina/${currentParent.id}/ver`, { fromPatient: fromPatient })}>
                                    <IconEye width="24" height="24" fill="var(--vsprontuario-primary-color-dark)" />
                                </div>
                            </div>
                        }
                    />
                ))
            })(currentParent)
            currentParent = currentParent.get('parentVaccine')
            // currentIndex++
        }

        return allOtherDoses
    }

    const viewActions = (
        <div className="row align-items-center justify-content-end">
            { vaccine.get("isReminder") && (
                <div className="col-auto flex-grow-1 flex-sm-grow-0 ps-sm-2 pe-sm-1">
                    <button
                        className="vsbox-btn vsbox-btn-primary w-100"
                        onClick={() => history.push(`/animal/${patient.id}/vacina/${vaccine.id}`, {
                            changeVaccineToApplied: true
                        })}
                    >
                        Aplicar
                    </button>
                </div>
            )}
            <div className={`col-auto flex-shrink-0 ${vaccine.get("isReminder") ? 'ps-1' : 'flex-grow-1 flex-sm-grow-0'}`}>
                <VSMenu
                    id="menu-vaccine"
                    placement="bottom-end"
                    openOnHover
                    options={[
                        {
                            menu: (
                                <div className="row">
                                    <div className="col-auto">
                                        <IconTrash width="24" height="24" fill="#FF8A00" />
                                    </div>
                                    <div className="col ps-0">
                                        Excluir
                                    </div>
                                </div>
                            ),
                            onClick: () => deleteVaccine(vaccine, () => {
                                if(updateList){
                                    updateList()
                                }
                                addEvent("History__getList", {})
                            })
                        },
                        {
                            menu: (
                                <div className="row">
                                    <div className="col-auto">
                                        <IconEdit width="24" height="24" fill="var(--vsprontuario-primary-color-dark)" />
                                    </div>
                                    <div className="col ps-0">
                                        Editar
                                    </div>
                                </div>
                            ),
                            onClick: () => history.push(`/animal/${patient.id}/vacina/${vaccine.id}`)
                        },
                        {
                            menu: (
                                <div className="row">
                                    <div className="col-auto">
                                        <IconEye width="24" height="24" fill="var(--vsprontuario-primary-color)" />
                                    </div>
                                    <div className="col ps-0">
                                        Ver Detalhes
                                    </div>
                                </div>
                            ),
                            onClick: () => history.push(`/animal/${patient.id}/vacina/${vaccine.id}/ver`, { fromPatient: fromPatient })
                        }
                    ]}
                >
                    <div className="vsbox-btn vsbox-btn-dark btn-menu">
                        <IconMenu width="24" height="24" fill="var(--vsprontuario-primary-color-dark)" />{vaccine.get("isReminder") ? '' : <span className="d-sm-none"> VER OPÇÕES</span>}
                    </div>
                </VSMenu>
            </div>
        </div>
    )

    return (
        <div className={`vaccine-list-view vsbox mb-3 ${isDeleted ? "vsbox-disabled" : ""}`}>
            <div className="vsbox-body pb-0">
                <div className="row align-items-center">
                    {!isDeleted && 
                    <div className="col-12 col-sm-auto mb-3 pe-sm-0">
                        <div className={`vsbox-chip text-center w-100 ${vaccine.get("isReminder") && isReminderLate(vaccine) ? 'late' : ''}`}>
                            Data: { dateToShow }
                        </div>
                    </div>}
                    {!isDeleted ? (
                        <div className="col-12 col-sm-auto mb-3">
                            <label className="vsbox-info">Estado da Vacina:</label>
                            <div className={`vsbox-info-text ${
                                vaccine.get("isReminder") ? (
                                    isReminderLate(vaccine) ? "late" : "scheduled"
                                ) : 'applied'
                            }`}>{
                                vaccine.get("isReminder") ? (
                                    isReminderLate(vaccine) ? "Atrasada" : "Programada"
                                ) : 'Aplicada'
                            }</div>
                        </div>
                    ) : (
                        <div className="col-12 col-md mb-3">
                            <div className="row g-3 align-items-center">
                                <div className="col-auto">
                                    <IconVaccine width="24" height="24" fill="var(--vsprontuario-gray-color)" />
                                </div>
                                <div className="col ps-0">
                                    <label className="vsbox-info">Tipo de Registro</label>
                                    <div className="vsbox-info-text">
                                        Vacina {vaccine.get("isReminder") ? (
                                            isReminderLate(vaccine) ? "Atrasada" : "Programada"
                                        ) : 'Aplicada'}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {!isDeleted && 
                    <div className="col mb-3 d-none d-sm-block">
                        {viewActions}
                    </div>}
                </div>
                {!isDeleted && 
                <div className="row">
                    <div className="col">
                        <BoxVaccineWithReminder 
                            vaccine={vaccine}
                            history={history}
                            className="mb-3"
                        />
                    </div>
                </div>}
                {!isDeleted && 
                <div className="row d-sm-none mb-3">
                    <div className="col-12">
                        {viewActions}
                    </div>
                </div>}
                <div className="row">
                    <div className="col">
                        { showRegisteredBy(vaccine) }
                    </div>
                </div>
                {!isDeleted && (vaccine.get('parentVaccine') || vaccine.get("notes")) &&
                <>
                    <div className="vsbox-separator"></div>
                    <VSAccordionSimple
                        expanded={expanded}
                        header={expanded => (
                            `${ expanded ? "Ocultar" : "Mostrar" } mais informações`
                        )}
                    >
                        <div className="row mb-3">
                            <div className="col">
                                Outras doses da vacina:
                            </div>
                        </div>
                        {renderOtherDoses()}
                        <div className="row">
                            <div className="col vsbox-title">
                                Observações:
                            </div>
                        </div>
                        <div className="row">
                            <div className="col mb-3 pre-line">
                                { vaccine.get("notes") || "Não informado" }
                            </div>
                        </div>
                    </VSAccordionSimple>
                </>}
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        clinic: state.clinic
    };
};
  
export default connect(mapStateToProps)(VaccineListView)