import React from 'react'

import { ReactComponent as IconCashO } from "../../assets/icon-cash-o.svg"
import { ReactComponent as IconEdit } from "../../assets/icon-primary-edit.svg"
import { ReactComponent as IconTrash } from "../../assets/icon-primary-trash.svg"

import { dayjs, formattedCurrency, showRegisteredBy } from '../../utils'
import { getPaymentMethod } from '../patient/tabs/sale/Sale'
import { deleteExpense, deletePaymentOfExpense } from '../expense/Expense'

import VSMedicalRecordsAudit from '../../components/vsMedicalRecordsAudit/VSMedicalRecordsAudit'
import ExpenseInfoView from '../expense/ExpenseInfoView'

import './CashOutflowView.scss'
import { connect } from 'react-redux'
import { ACL } from '../../constants'
const CashOutflowView = (props) => {

    const {
        object,
        history,
        noDocumentDate,
        updateList,
        noAudit,
        permissions
    } = props

    const expense = object.get("originalMRExpense")
    const isDeleted = object.get("isDeleted")
    const expenseIsPaid = object.get('paymentsPending') == 0

    return (
        <div id="patient-expense-view" className={`vsbox mb-3 ${isDeleted ? "vsbox-disabled" : ""}`}>
            <div className="vsbox-body pb-0">
                <div className="row align-items-center">
                    { !noDocumentDate && (
                        <div className="col-auto mb-3 pe-0">
                            <div className="vsbox-chip">
                                { dayjs(object.get("documentDate")).format("DD/MM/YYYY") }
                            </div>
                        </div>
                    )}
                    { !isDeleted && (
                        <>
                            <div className="col-12 col-md-auto order-0 mb-3">
                                <label className="vsbox-info">
                                    <IconCashO width={20} height={20} fill="var(--vsprontuario-gray-color)" className="me-1" />
                                    Valor da Saída
                                </label>
                                <div className="vsbox-main-text text-danger">{ formattedCurrency(object.get("payment_item_value")) }</div>
                            </div>
                            <div className="col-6 col-md-auto order-1 mb-3">
                                <label className="vsbox-info">Pagamento</label>
                                <div className={`vsbox-info-text ${ expenseIsPaid ? "text-success" : "text-warning" }`}>{ expenseIsPaid ? "TOTAL" : "PARCIAL" }</div>
                            </div>
                            <div className="col-6 col-md-auto order-3 order-md-2 mb-3">
                                <label className="vsbox-info">Total da Despesa</label>
                                <div className="vsbox-info-text">{ formattedCurrency(object.get("value")) }</div>
                            </div>
                            <div className="col-6 col-md-auto order-4 order-md-3 mb-3">
                                <label className="vsbox-info">Total Pago</label>
                                <div className="vsbox-info-text">{ formattedCurrency(object.get("paymentsValue")) }</div>
                            </div>
                            <div className="col-6 col-md-auto order-2 order-md-4 mb-3">
                                <label className="vsbox-info">Se Repete?</label>
                                <div className="vsbox-info-text">{ object.get("recurrenceActive") ? "Sim" : "Não" }</div>
                            </div>
                            <div className="col order-5 mb-3">
                                <div className="row g-3 align-items-center justify-content-end">
                                    { permissions?.check(ACL.OUTFLOW, ACL.Rule.EDIT, expense) && (
                                        <div className="col-6 col-md-auto">
                                            <button
                                                className={`vsbox-btn ${isDeleted ? "vsbox-btn-disabled" : "vsbox-btn-dark"}`}
                                                onClick={() => history.push(`/despesa/${ expense.id }`)}
                                                disabled={isDeleted}
                                            >
                                                <IconEdit width="24" height="24" fill={isDeleted ? "var(--vsprontuario-gray-color)" : "var(--vsprontuario-primary-color-dark)"} />
                                            </button>
                                        </div>
                                    )}
                                    { permissions?.check(ACL.OUTFLOW, ACL.Rule.DELETE, expense) && (
                                        <div className="col-6 col-md-auto">
                                            <button
                                                className={`vsbox-btn ${isDeleted ? "vsbox-btn-disabled" : "vsbox-btn-alert"}`}
                                                onClick={() => deleteExpense(expense, updateList)}
                                                disabled={isDeleted}
                                            >
                                                <IconTrash width="24" height="24" fill={isDeleted ? "var(--vsprontuario-gray-color)" : "var(--vsprontuario-primary-color)"} />
                                            </button>
                                        </div>
                                    )}
                                    { permissions?.check(ACL.OUTFLOW, ACL.Rule.DELETE, expense) && (
                                        <div className="col-12 col-md-auto">
                                            <button
                                                className={`vsbox-btn ${isDeleted ? "vsbox-btn-disabled" : "vsbox-btn-primary"}`}
                                                onClick={() => deletePaymentOfExpense(expense, object.get("payment_item_index"), updateList)}
                                                disabled={isDeleted}
                                            >
                                                Remover Pagamento
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </>
                    )}
                </div>
                <div className="row mb-2">
                    <div className="col-12">
                        <div className="vsbox-item">
                            <div>
                                <div className={`row vsbox-item-text`}>
                                    <div className="col">
                                        <b>Forma de pagamento:</b> { getPaymentMethod(object.get("payment_item_type")) }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                { showRegisteredBy(expense) }
                <ExpenseInfoView object={expense} {...props} />
                { !noAudit && (
                    <VSMedicalRecordsAudit parseObject={expense} />
                )}
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        permissions: state.permissions
    }
}
  
export default connect(mapStateToProps)(CashOutflowView)