import packageInfo from "../package.json"
const { version, appId, appName, appDevice, appContent } = packageInfo

// Common
export const BASE_URL = window.location.origin

export const SHOW_NEW_SUPPLY = true

// API
export const API_LOGIN = "API_LOGIN"
export const API_LOGOUT = "API_LOGOUT"
export const API_USER = "API_USER"
export const API_CLINIC_USER = "API_CLINIC_USER"
export const API_STORE = "API_STORE"
export const API_ONBOARDING = "API_ONBOARDING"
export const API_PRO = "API_PRO"
export const API_TAG = "API_TAG"

// ACL
export const ACL = {
    ADMIN: "admin",
    OWNER: "owners",
    PATIENT: "patients",
    SUPPLY: "supplies",
    SERVICE: "services",
    APPOINTMENT: "appointments",
    WEIGHT: "weights",
    VACCINE: "vaccines",
    PRESCRIPTION: "prescriptions",
    EXAM_REQUEST: "examRequests",
    RETURN: "returns",
    TERM: "terms",
    APPOINTMENT_DRUG: "appointmentDrugs",
    SURGERY: "surgeries",
    ATTACHMENT: "attachments",
    SALE: "sales",
    INFLOW: "inflows",
    OUTFLOW: "outflows",
    CALENDAR: "calendars",
    SETTING: "settings",
    CONTROL_PANEL: "controlPanes",
    SUPPLY_TRANSACTION: "supplyTransactions",
    get HISTORY() {
        return [
            this.APPOINTMENT, 
            this.WEIGHT, 
            this.VACCINE,
            this.PRESCRIPTION,
            this.EXAM_REQUEST,
            this.RETURN,
            this.TERM,
            this.APPOINTMENT_DRUG,
            this.SURGERY,
            this.ATTACHMENT,
            this.SALE
        ]
    },
    get FINANCIAL() {
        return [
            this.INFLOW,
            this.OUTFLOW
        ]
    },
    Rule: {
        VIEW: "view",
        EDIT: "edit",
        DELETE: "delete"
    },
    Value: {
        NONE: "none",
        SELF: "self",
        ALL: "all"
    },
    Access: {
        NO_ACCESS: "0",
        VIEW_ALL: "1",
        VIEW_ALL_EDIT_SELF: "2",
        VIEW_ALL_EDIT_DELETE_SELF: "3",
        VIEW_EDIT_ALL: "4",
        VIEW_EDIT_ALL_DELETE_SELF: "5",
        VIEW_EDIT_DELETE_ALL: "6",
        get: (value) => {
            switch (value) {
                case ACL.Access.NO_ACCESS:
                    return {
                        view: ACL.Value.NONE,
                        edit: ACL.Value.NONE,
                        delete: ACL.Value.NONE
                    }
                case ACL.Access.VIEW_ALL:
                    return {
                        view: ACL.Value.ALL,
                        edit: ACL.Value.NONE,
                        delete: ACL.Value.NONE
                    }
                case ACL.Access.VIEW_ALL_EDIT_SELF:
                    return {
                        view: ACL.Value.ALL,
                        edit: ACL.Value.SELF,
                        delete: ACL.Value.NONE
                    }
                case ACL.Access.VIEW_ALL_EDIT_DELETE_SELF:
                    return {
                        view: ACL.Value.ALL,
                        edit: ACL.Value.SELF,
                        delete: ACL.Value.SELF
                    }
                case ACL.Access.VIEW_EDIT_ALL:
                    return {
                        view: ACL.Value.ALL,
                        edit: ACL.Value.ALL,
                        delete: ACL.Value.NONE
                    }
                case ACL.Access.VIEW_EDIT_ALL_DELETE_SELF:
                    return {
                        view: ACL.Value.ALL,
                        edit: ACL.Value.ALL,
                        delete: ACL.Value.SELF
                    }
                case ACL.Access.VIEW_EDIT_DELETE_ALL:
                    return {
                        view: ACL.Value.ALL,
                        edit: ACL.Value.ALL,
                        delete: ACL.Value.ALL
                    }
            }
        },
        getValueByRule: (rule) => {
            switch (JSON.stringify(rule)) {
                case JSON.stringify(ACL.Access.get(ACL.Access.VIEW_ALL)):
                    return ACL.Access.VIEW_ALL
                case JSON.stringify(ACL.Access.get(ACL.Access.VIEW_ALL_EDIT_SELF)):
                    return ACL.Access.VIEW_ALL_EDIT_SELF
                case JSON.stringify(ACL.Access.get(ACL.Access.VIEW_ALL_EDIT_DELETE_SELF)):
                    return ACL.Access.VIEW_ALL_EDIT_DELETE_SELF
                case JSON.stringify(ACL.Access.get(ACL.Access.VIEW_EDIT_ALL)):
                    return ACL.Access.VIEW_EDIT_ALL
                case JSON.stringify(ACL.Access.get(ACL.Access.VIEW_EDIT_ALL_DELETE_SELF)):
                    return ACL.Access.VIEW_EDIT_ALL_DELETE_SELF
                case JSON.stringify(ACL.Access.get(ACL.Access.VIEW_EDIT_DELETE_ALL)):
                    return ACL.Access.VIEW_EDIT_DELETE_ALL
                default:
                    return ACL.Access.NO_ACCESS
            }
        }
    }
}

var device = appDevice || "web";
if (navigator.userAgent.indexOf("Android") != -1) device = "android";
else if (navigator.userAgent.indexOf("like Mac") != -1) device = "ios";

// APP
export const APP_ID = appId
export const APP_NAME = appName
export const APP_DEVICE = device
export const APP_VERSION = version
export const APP_CONTENT = appContent

// Data
export const OrderBy_Oldest = "Mais antigos"
export const OrderBy_Newest = "Mais recentes"
export const OrderBy_Alphabetical = "Ordem Alfabética (A - Z)"
export const OrderBy_AlphabeticalDescending = "Ordem Alfabética (Z - A)"
export const OrderBy_Expensive = "Mais caro"
export const OrderBy_Cheaper = "Mais barato"
export const Filter_Limit = 20

export const ACTION_CREATE = "Criação"
export const ACTION_READ   = "Visualização"
export const ACTION_UPDATE = "Edição"
export const ACTION_DELETE = "Exclusão"

export const HOME_PAGE = "prontuario-home"