import React from 'react'
import PropTypes from 'prop-types';

import { ReactComponent as IconClose } from "../../assets/icon-close.svg"

import './VSDrawerHeader.scss'

const VSDrawerHeader = (props) => {

    const {
        title,
        width,
        disabled,
        onClose
    } = props

    return (
        <div className={`vsdrawer-header ${ !title ? "vsdrawer-close" : "" }`}>
            <button
                style={{
                    right: `calc(${ width } + 12px)`
                }}
                className="vsdrawer-close"
                disabled={disabled}
                onClick={() => onClose && onClose()}
            >
                <IconClose fill="var(--vsprontuario-primary-color)" width="20" height="20" />
                <span>FECHAR</span>
            </button>
            { title && (
                <div className="row align-items-center">
                    <div className="col">
                        { title }
                    </div>
                </div>
            )}
        </div>
    )
}

VSDrawerHeader.propTypes = {
    title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node
    ]),
    width: PropTypes.string,
    disabled: PropTypes.bool,
    onClose: PropTypes.func
};

VSDrawerHeader.defaultProps = {
    title: "",
    width: "80%",
    disabled: false,
    onClose: () => null
}

export default VSDrawerHeader;