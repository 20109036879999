import React, { useState, useEffect } from 'react'

import { connect } from 'react-redux'
import "./CardDashboardInvitedVets.scss"
import { ReactComponent as IconPrimaryEdit } from "../../assets/icon-primary-edit.svg"
import { ReactComponent as IconUser } from "../../assets/icon-user.svg"
import VSAccordionSimple from "../../components/vsAccordion/VSAccordionSimple"


import { ReactComponent as IconVetsThumb } from "../../assets/icon-vets-thumb.svg"

const CardDashboardInvitedVets = (props) => {

    const [open, setOpen] = useState(false)
    const {
        isCurrentUserClinicOwner,
        history,
        isLoading,
        vets,
        loadClinicUserDetail,
        clinicUserDetail
    } = props


    function ShowVets(props) {
        const hasVets = vets && vets.length > 0
        if (hasVets) {
            return (
                <div className="show-card-dashboard">
                    <div className="box-description description mt-3">
                        Você possui <b>{ vets.length }</b> veterinário{ vets.length != 1 && "s" } convidado{ vets.length != 1 && "s" } em sua clínica
                    </div>
                    <div className="container-fluid">
                        {vets.map((clinicUser, index) => (
                            <div key={index} className="mt-3">
                                <VSAccordionSimple
                                    expanded={clinicUser.isOpen}
                                    className="container-info-vets"
                                    header={(expanded) => (
                                        <div className="d-flex align-items-center">
                                            <div className="me-3"><IconVetsThumb /></div>
                                            <div className="align-items-start">
                                                <div className="vetName">{clinicUser.name}</div>
                                                <div className="vetCRMV">CRMV: {clinicUser.crmv}</div>
                                            </div>
                                        </div>
                                    )}
                                    onOpen={
                                        () => { clinicUser.isOpen = true; loadClinicUserDetail({ userId: clinicUser.userId }) }
                                    }
                                    onClose={
                                        () => { clinicUser.isOpen = false }
                                    }
                                >
                                    {clinicUser.detail ? (
                                        <div >
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="row-label">Total de Consultas:</div>
                                                <div className="row-value">{clinicUser.detail.appointments}</div>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center mt-2">
                                                <div className="row-label">Total de Vacinas:</div>
                                                <div className="row-value">{clinicUser.detail.vaccines}</div>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center mt-2">
                                                <div className="row-label">Total de Exames:</div>
                                                <div className="row-value">{clinicUser.detail.examRequests}</div>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center mt-2">
                                                <div className="row-label">Total de Prescrições:</div>
                                                <div className="row-value">{clinicUser.detail.prescriptions}</div>
                                            </div>
                                        </div>
                                    ) : <div>carregando</div>}
                                </VSAccordionSimple>
                            </div>
                        ))}
                    </div>
                    <hr className="solid mt-3 mb-3" />
                    <button className="btn-outline-submit btn-square w-100" onClick={() => history.push("/configuracao-clinica/gerenciar-acessos/novo")}>
                        Convidar Veterinário
                    </button>
                </div>
            )
        } else {
            return <div className="empty mt-4">Nenhuma informação registrada</div>
        }
    }

    function layoutLoading() {
        return <div className="container-fluid shimmer">
            <div className="wrapper">
                <div className="row  mt-3">
                    <div className="col-auto icon-loading me-2 animate">
                    </div>
                    <div className="col gx-0 d-flex">
                        <div className="title-loading animate" ></div>
                    </div>
                </div>
                <div className="row pt-4">
                    <div className="col-auto icon-loading2 me-2 animate">
                    </div>
                    <div className="col">
                        <div className="row">
                            <div className="col gx-0">
                                <div className="col line1-loading animate  mt-1"></div>
                                <div className="col line2-loading animate mt-2"></div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    }

    function layoutCard() {
        return (
            <div className="animateFadeIn">
                <div className="d-flex justify-content-between align-self-center">
                    <div className="d-flex align-self-center">
                        <div className='me-2'><IconUser width="24" height="24" fill="#B3B3B3" /></div>
                        <div className='title'>Veterinários convidados</div>
                    </div>
                    {isCurrentUserClinicOwner ? (
                        <div className="btnEdit" onClick={() => history.push(`/configuracao-clinica/gerenciar-acessos`)}>
                            <IconPrimaryEdit fill="#ccc" />
                        </div>
                    ) : (
                        <div></div>
                    )}

                </div>
                <ShowVets vets={vets} />
            </div>
        )
    }


    return (

        <div id="card-dashboard-invited-vets" className="card-dashboard-element">

            {isLoading ? (

                layoutLoading()
            ) : (
                layoutCard()
            )}
        </div >
    )
}

const mapStateToProps = state => {
    return {
        clinic: state.clinic
    };
};

export default connect(mapStateToProps)(CardDashboardInvitedVets);