import Tooltip from '@mui/material/Tooltip';
import { withStyles } from '@mui/styles';

const VSTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        width: '100%',
        maxWidth: '400px',
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}))(Tooltip);

export const VSSimpleTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#FFFFFF',
        color: 'rgba(0, 0, 0, 0.87)',
        width: '100%',
        maxWidth: '400px',
        fontSize: theme.typography.pxToRem(12),
        padding: 0,
        border: '1px solid #dadde9',
    },
}))(Tooltip);

export default VSTooltip