import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types';

import { Autocomplete, TextField } from '@mui/material';
import { ReactComponent as IconCalendarFilter } from "../../assets/icon-calendar-filter.svg"
import { dayjs, getSelectedOptionLabel } from '../../utils';

import './IntervalInput.scss'

export const defaultIntervals = [
    {
        value: "21 day",
        label: "21 dias"
    },
    {
        value: "30 day",
        label: "30 dias"
    },
    {
        value: "45 day",
        label: "45 dias"
    },
    {
        value: "6 month",
        label: "6 meses"
    },
    {
        value: "1 year",
        label: "1 ano"
    }
]

const autocompleteIntervalUnits = [
    {
        value: "day",
        label: "Dia",
        labelPlural: "Dias"
    },
    {
        value: "week",
        label: "Semana",
        labelPlural: "Semanas"
    },
    {
        value: "month",
        label: "Mês",
        labelPlural: "Meses"
    },
    {
        value: "year",
        label: "Ano",
        labelPlural: "Anos"
    }
]

export const getOrMountSelectedOptionLabel = (selected, options, value, field = "label") => {
    if (selected && selected[field])
        return selected[field]

    if (!options)
        options = []

    let optionSelected = options.filter(option => option.value === value)
    if (optionSelected.length == 1) {
        return optionSelected[0][field]
    }

    let _numberValue = Number(value.split(" ")[0])
    let _stringValue = value.split(" ")[1]
    return `${_numberValue} ${getSelectedOptionLabel(null, autocompleteIntervalUnits, _stringValue, _numberValue === 1 ? "label" : "labelPlural").toLowerCase()}`
}

const IntervalInput = (props) => {

    const {
        label,
        className,
        interval,
        setInterval,
        intervalUnit,
        setIntervalUnit,
        errorInterval,
        helperInterval
    } = props

    const getValue = () => interval && intervalUnit ? `${interval} ${intervalUnit}` : null
    const setValue = (value) => {
        if(!value){
            setInterval(null)
            setIntervalUnit(null)
            return
        }

        setInterval(Number(value.split(" ")[0]))
        setIntervalUnit(value.split(" ")[1])
    }

    return (
        <div className={`col-12 ${className}`}>
            <div className="row g-3">
                <div className="col col-sm">
                    <Autocomplete
                        value={getValue()}
                        options={defaultIntervals}
                        getOptionLabel={(option) => getOrMountSelectedOptionLabel(option, defaultIntervals, getValue())}
                        getOptionSelected={(option) => option.value === getValue()}
                        onChange={(_, selected) => setValue(selected?.value || '')}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder="Intervalo"
                                label={label || "Intervalo"}
                                error={errorInterval}
                                helperText={helperInterval}
                                size="small"
                                variant="outlined"
                                fullWidth
                            />
                        )}
                        openOnFocus
                    />
                </div>
                <div className="col-auto">
                    <div className="separator-text">
                        OU
                    </div>
                </div>
                <div className="col-6 col-sm-3 col-md-3 col-xxl-2">
                    <TextField
                        placeholder="Ex.: 5"
                        value={interval}
                        onChange={({ target: { value } }) => setInterval(Number(String(value).replace(/[^0-9]/g, "")))}
                        size="small"
                        variant="outlined"
                        fullWidth
                    />
                </div>
                <div className="col-6 col-sm-3 col-md-3 col-xxl-2">
                    <Autocomplete
                        value={intervalUnit}
                        options={autocompleteIntervalUnits}
                        getOptionLabel={(option) => getSelectedOptionLabel(option, autocompleteIntervalUnits, intervalUnit)}
                        getOptionSelected={(option) => option.value === intervalUnit}
                        onChange={(_, selected) => setIntervalUnit(selected?.value || '')}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder="Ex.: Dia"
                                label="Frequência"
                                size="small"
                                variant="outlined"
                                fullWidth
                            />
                        )}
                        openOnFocus
                    />
                </div>
            </div>
        </div>
    )
}

export default IntervalInput;