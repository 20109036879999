import dayjs from "dayjs"
import { getTextFromRRule } from "../../utils"

const RecurrenceView = (props) => {

    const {
        object
    } = props

    return (
        <div className="row vsbox-item-text">
            <div className="col-12 col-md">
                <b>Repetir a cada:</b> { getTextFromRRule(object.get("recurrence")) || "Não informado" }
            </div>
            <div className="col-12 col-md">
                <b>Terminar:</b> { dayjs(object.get("finishDate")).format("DD/MM/YYYY") || "Não informado" }
            </div>
            <div className="col-12 col-md-auto">
                <b>Próxima Recorrência:</b> { dayjs(object.get("nextDate")).format("DD/MM/YYYY") || "Não informado" }
            </div>
        </div>
    )
}

export default RecurrenceView