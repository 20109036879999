import React from 'react'
import { connect } from 'react-redux'

import { ReactComponent as IconEdit } from "../../../../assets/icon-primary-edit.svg"
import { ReactComponent as IconTrash } from "../../../../assets/icon-primary-trash.svg"

import { dayjs, formattedCurrency, showRegisteredBy } from '../../../../utils'
import { ACL } from '../../../../constants'
import { deleteCalendar, statusOptions } from '../../../calendar/Calendar'

import VSAccordionSimple from '../../../../components/vsAccordion/VSAccordionSimple'
import VSMedicalRecordsAudit from '../../../../components/vsMedicalRecordsAudit/VSMedicalRecordsAudit'
import RecurrenceView from '../../../recurrence/RecurrenceView'

import './CalendarView.scss'
const CalendarView = (props) => {

    const {
        object,
        history,
        noDocumentDate,
        noAudit,
        permissions
    } = props

    const isDeleted = object.get("isDeleted")
    const services = object.get("services")
    const patient = object.get("patient")
    const user = object.get("user")

    const hasEstimateOrSale = object?.get("sale")
    const documentType = hasEstimateOrSale?.get("documentType") == "sale" ? "venda" : "orcamento"

    return (
        <div id="patient-calendar-view" className={`vsbox mb-3 ${isDeleted ? "vsbox-disabled" : ""}`}>
            <div className="vsbox-body pb-0">
                <div className="row g-3 mb-3 align-items-center">
                    { !noDocumentDate && (
                        <div className="col-auto">
                            <div className="vsbox-chip">
                                { object.get("startDate") != object.get("endDate") ? (
                                    `${dayjs(object.get("startDate")).format("DD/MM/YYYY [das] HH:mm [às] ")}${dayjs(object.get("endDate")).format("HH:mm")}`
                                ) : (
                                    dayjs(object.get("startDate").format("DD/MM/YYYY [às] HH:mm"))
                                )}
                            </div>
                        </div>
                    )}
                    <div className="col-12 col-lg">
                        <label className="vsbox-info">Responsável</label>
                        <div className="vsbox-info-text">
                            { user.get("fullName") }
                        </div>
                    </div>
                    <div className="col-12 col-lg-auto">
                        <label className="vsbox-info">Status</label>
                        <div className={`vsbox-info-text ${object.get("status")}`}>
                            { statusOptions.find(status => status.value == object.get("status"))?.label || "Não informado" }
                        </div>
                    </div>
                    { !isDeleted && (
                        <div className="col-12 col-md-auto">
                            <div className="row g-3 align-items-center justify-content-center">
                                { permissions?.check(ACL.SALE, ACL.Rule.EDIT) && (
                                    <div className="col-12 col-md-auto">
                                        <button
                                            className={`vsbox-btn ${isDeleted ? "vsbox-btn-disabled" : "vsbox-btn-primary"}`}
                                            onClick={() => history.replace(hasEstimateOrSale ? `/compartilhar/${hasEstimateOrSale?.id}` : `/${documentType}/${ patient.id }/novo/agenda/${object.id}`, {
                                                redirectTo: "/agenda"
                                            })}
                                            disabled={isDeleted}
                                        >
                                            { hasEstimateOrSale ? (
                                                `Ver ${hasEstimateOrSale == "sale" ? "venda" : "orçamento"}`
                                            ) : (
                                                "Gerar orçamento"
                                            )}
                                        </button>
                                    </div>
                                )}
                                { permissions?.check(ACL.CALENDAR, ACL.Rule.EDIT, object) && (
                                    <div className="col col-md-auto">
                                        <button
                                            className={`vsbox-btn ${isDeleted ? "vsbox-btn-disabled" : "vsbox-btn-dark"}`}
                                            onClick={() => history.push(`/agenda/${ object.id }`, {
                                                id: patient.id,
                                                date: object.get("startDate")
                                            })}
                                            disabled={isDeleted}
                                        >
                                            <IconEdit width="24" height="24" fill={isDeleted ? "var(--vsprontuario-gray-color)" : "var(--vsprontuario-primary-color-dark)"} />
                                        </button>
                                    </div>
                                )}
                                { permissions?.check(ACL.CALENDAR, ACL.Rule.DELETE, object) && (
                                    <div className="col col-md-auto">
                                        <button
                                            className={`vsbox-btn ${isDeleted ? "vsbox-btn-disabled" : "vsbox-btn-alert"}`}
                                            onClick={() => deleteCalendar(object, () => addEvent("Calendar__getList", {}))}
                                            disabled={isDeleted}
                                        >
                                            <IconTrash width="24" height="24" fill={isDeleted ? "var(--vsprontuario-gray-color)" : "var(--vsprontuario-primary-color)"} />
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
                { showRegisteredBy(object) }
                { !isDeleted && (
                    <>
                        <div className="vsbox-separator"></div>
                        <div className="row">
                            <div className="col-12 vsbox--info">
                                <VSAccordionSimple
                                    expanded={true}
                                    header={(expanded) => (
                                        `Ver ${ expanded ? "menos" : "mais" } informações`
                                    )}
                                >
                                    <div className="vsbox-item mb-3">
                                        { services && services.length > 0 ? (
                                            <>
                                                <div className="row vsbox-item-text my-2">
                                                    <div className="col-12 col-md">
                                                        <b>Qtd de serviços: </b> { services.length } serviço{services.length == 1 ? "" : "s"}
                                                    </div>
                                                    <div className="col-12 col-md">
                                                        <b>Valor total: </b> { formattedCurrency(object.get("servicesValue")) }
                                                    </div>
                                                </div>
                                                <div className="vsbox-separator my-3"></div>
                                                <RecurrenceView object={object} />
                                            </>
                                        ) : (
                                            "Nenhum serviço agendado"
                                        )}
                                    </div>
                                </VSAccordionSimple>
                            </div>
                            { services.length > 0 && ( 
                                <div className="col-12 vsbox--info">
                                    <VSAccordionSimple
                                        expanded={true}
                                        header={(expanded) => (
                                            `Ver ${ expanded ? "menos" : "mais" } informações`
                                        )}
                                    >
                                        { services.map((service, index) => (
                                            <div className="vsbox-item mb-3" key={index}>
                                                <div className="row mb-2">
                                                    <div className="col-12">
                                                        <div className="vsbox-title">
                                                            { service.serviceName }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="vsbox-separator my-1"></div>
                                                <div className="row vsbox-item-text my-2">
                                                    <div className="col-12 col-md">
                                                        <b>Tempo do serviço:</b> { "Não informado" }
                                                    </div>
                                                    <div className="col-12 col-md">
                                                        <b>Valor do serviço:</b> { formattedCurrency(service.value || 0) }
                                                    </div>
                                                </div>
                                                <div className="vsbox-separator my-1"></div>
                                                <div className="row vsbox-item-text mt-2">
                                                    <div className="col-auto pre-line">
                                                        <b>Observações:</b> { service.notes || "Não informado" }
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </VSAccordionSimple>
                                </div>
                            )}
                        </div>
                        <div className="row">
                            <div className="col vsbox-title">
                                Observações
                            </div>
                        </div>
                        <div className="row">
                            <div className="col mb-3 pre-line">
                                { object.get("notes") || "Não informado" }
                            </div>
                        </div>
                    </>
                )}
                { !noAudit && (
                    <VSMedicalRecordsAudit parseObject={object} />
                )}
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        permissions: state.permissions
    };
};
  
export default connect(mapStateToProps)(CalendarView)