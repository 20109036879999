import { getSelectedOptionLabel } from "../../../../utils"
import { ReactComponent as IconAttachment } from "../../../../assets/icon-patient-attachment.svg"
import { ReactComponent as IconExamResult } from "../../../../assets/icon-exam-result.svg"
import { ReactComponent as IconPhoto } from "../../../../assets/icon-photo.svg"
import { ReactComponent as IconSurgery } from "../../../../assets/icon-patient-surgery.svg"
import { ReactComponent as IconTerm } from "../../../../assets/icon-patient-term.svg"
import { APP_DEVICE, APP_NAME, APP_VERSION } from "../../../../constants"

export const deleteAttachment = (attachment, callback) => {
    Swal.fire({
        title: 'Tem certeza?',
        text: "Você tem certeza que deseja excluir esse anexo? Essa ação não poderá ser desfeita.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, quero excluir!',
        cancelButtonText: 'Não, manter anexo'
    }).then((result) => {
        if (result.isConfirmed) {
            attachment.set("isDeleted", true)
            attachment.set("changedAt", new Date())
            attachment.set("changedBy", Parse.User.current())
            attachment.set("changedApp", APP_NAME)
            attachment.set("changedDevice", APP_DEVICE)
            attachment.set("changedAppVersion", APP_VERSION)
            attachment.save()
            .then(_ => {
                Swal.fire(
                    'Excluído!',
                    'O anexo foi excluído com sucesso.',
                    'success'
                )
                .then(_ => {
                    callback && callback()
                })
            })
            .catch(error => {
                console.error(error)
                Swal.fire(
                    'Desculpe',
                    'Ocorreu algum erro ao tentar excluir o anexo',
                    'error'
                )
                attachment.revert()
            })
        }
    })
}

export const getAttachment = (id, patient) => {
    return new Promise((resolve, reject) => {
        var query = new Parse.Query("Attachment");
        query.equalTo("clinic", patient.get("clinic"));
        query.equalTo("isDeleted", false);
        query.equalTo("patient", patient)
        query.get(id)
        .then(_object => {
            if (_object) {
                resolve(_object)
            } else {
                reject('Não encontramos o anexo informado')
            }
        }).catch(error => {
            console.error(error)
            reject('Ocorreu algum erro ao tentar buscar o anexo')
        })
    })
}

export const showAttachment = (attachment) => {
    return getSelectedOptionLabel({ value: attachment.get("attachmentType") }, attachmentTypeOptions, attachment.get("attachmentType"))
}

export const attachmentTypeOptions = [
    {
        value: "1",
        label: "Resultado de Exame",
        image: <IconExamResult width="32px" height="32px" fill="var(--vsprontuario-primary-color)" />
    },
    {
        value: "2",
        label: "Documento Cirúrgico",
        image: <IconSurgery width="32px" height="32px" fill="var(--vsprontuario-primary-color)" />
    },
    {
        value: "3",
        label: "Etiqueta de Vacina",
        image: <IconTerm width="32px" height="32px" fill="var(--vsprontuario-primary-color)" />
    },
    {
        value: "4",
        label: "Foto",
        image: <IconPhoto width="32px" height="32px" fill="var(--vsprontuario-primary-color)" />
    },
    {
        value: "5",
        label: "Atestados e Termos",
        image: <IconTerm width="32px" height="32px" fill="var(--vsprontuario-primary-color)" />
    },
    {
        value: "99",
        label: "Outros",
        image: <IconAttachment width="32px" height="32px" fill="var(--vsprontuario-primary-color)" />
    },
]

export const ATTACHMENT_TYPE_EXAM_RESULT    = "1"
export const ATTACHMENT_TYPE_SURGERY        = "2"
export const ATTACHMENT_TYPE_VACCINE        = "3"
export const ATTACHMENT_TYPE_PHOTO          = "4"
export const ATTACHMENT_TYPE_TERM           = "5"
export const ATTACHMENT_TYPE_OTHERS         = "99"