import React from 'react'
import PropTypes from 'prop-types';
import { showRegisteredBy } from '../../utils';

import './VSBoxView.scss'
const VSBoxView = (props) => {

    const {
        status,
        statusText,
        icon,
        prependTitle,
        title,
        text,
        appendTitle,
        actions,
        object,
        children
    } = props

    return (
        <div id="vsbox-view">
            <div className={`vsbox d-block w-100 ${ status == "active" ? "vsbox-active" : (status == "inactive" ? "vsbox-inactive" : "vsbox-warning") }`}>
                { statusText && (
                    <div className="vsbox-status">
                        { statusText }
                    </div>
                )}
                <div className="vsbox-body pb-1">
                    <div className="row g-3 mb-3 align-items-center">
                        { icon && (
                            <div className="col-auto order-0">
                                <div className="icon-round">
                                    { icon }
                                </div>
                            </div>
                        )}
                        { prependTitle && (
                            prependTitle
                        )}
                        <div className="col order-1">
                            <label className="vsbox-title mb-0">
                                { title }
                            </label>
                            <div className="vsbox-subtitle">
                                { text }
                            </div>
                        </div>
                        { appendTitle && (
                            appendTitle
                        )}
                        { actions && (
                            <div className="col-auto text-right order-2 order-md-3 vsbox-actions">
                                { actions }
                            </div>
                        )}
                    </div>
                    { showRegisteredBy(object) }
                    { children && (
                        <>
                            <div className="vsbox-separator"></div>
                            <div className="row">
                                <div className="col-12">
                                    { children }
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}

VSBoxView.propTypes = {
    status: PropTypes.oneOf(["active", "inactive", "warning"]),
    statusText: PropTypes.string,
    icon: PropTypes.any,
    prependTitle: PropTypes.any,
    title: PropTypes.any,
    text: PropTypes.any,
    appendTitle: PropTypes.any,
    actions: PropTypes.any,
    object: PropTypes.any,
    data: PropTypes.any
};

VSBoxView.defaultProps = {
    status: "active",
    statusText: null,
    icon: null,
    prependTitle: null,
    title: null,
    text: null,
    appendTitle: null,
    actions: null,
    object: null,
    data: null
}
  
export default VSBoxView;