import React, { useEffect, useState } from "react"

import "./CardDashboardFinancialSummary.scss"
import { ReactComponent as IconFinancial } from "../../assets/icon-financial-2.svg"
import CardEmptyDashboard from "../cardEmptyDashboard/CardEmptyDashboard"

const CardDashboardFinancialSummary = (props) => {

    const {
        isLoading,
        inflowsAndOutflows,
        datesFormatted,
        history
    } = props

    function ShowInflowsAndOutflows(props) {
        const hasInflowsAndOutflows = props.inflowsAndOutflows.inflows != 0 || props.inflowsAndOutflows.toReceive != 0
        if (hasInflowsAndOutflows) {
            return (
                <div className="show-card-dashboard">
                    <div className="row g-1 g-md-3 mt-3">
                        <div className="col-6 col-md mt-1 labels">
                            Entradas:
                        </div>
                        <div className="col-6 col-md mt-1 values in">
                            {props.inflowsAndOutflows.inflows}
                        </div>
                        <div className="col-6 col-md mt-1 labels">
                            Saídas:
                        </div>
                        <div className="col-6 col-md mt-1 values out">
                            {props.inflowsAndOutflows.outflows }
                        </div>
                    </div>
                    <div className="row g-1 g-md-3 mt-1">
                        <div className="col-6 col-md mt-1 labels">
                            A Receber:
                        </div>
                        <div className="col-6 col-md mt-1 values">
                            { props.inflowsAndOutflows.toReceive }
                        </div>
                        <div className="col-6 col-md mt-1 labels">
                            A Pagar:
                        </div>
                        <div className="col-6 col-md mt-1 values">
                            { props.inflowsAndOutflows.toPay }
                        </div>
                    </div>
                    <hr className="solid mt-3 mb-3" />
                    <button className="btn-outline-submit btn-square w-100" onClick={() => history.push("/painel/balanco-financeiro")}>
                        Baixar Balanço Financeiro
                    </button>
                </div>
            )
        } else {
            return <CardEmptyDashboard
                title="Você ainda não possui movimentações financeiras"
                description="Utilize o seu prontuário para que os dados sejam disponibilizados." />
        }
    }

    function layoutCard() {
        return (
            <div className="row align-items-center">
                <div className="col mb-2 mb-md-0">
                    <div className="row align-items-center">
                        <div className="col-auto pe-0">
                            <IconFinancial width="24" height="24" fill="#B3B3B3" />
                        </div>
                        <div className="col ps-2">
                            <div className="title">Entradas e Saídas</div>
                        </div>
                    </div>
                </div>
                <div className="col-12 mt-3">
                    <div className="dates">{datesFormatted}</div>
                </div>
                <div className="col-12">
                    <ShowInflowsAndOutflows inflowsAndOutflows={inflowsAndOutflows} />
                </div>
            </div>
        )
    }
    function layoutLoading() {
        return <div className="container-fluid shimmer">
            <div className="wrapper">
                <div className="row  mt-3">
                    <div className="col-auto icon-loading me-2 animate">
                    </div>
                    <div className="col gx-0 d-flex">
                        <div className="title-loading animate" ></div>
                    </div>
                </div>
                <div className="row pt-4">
                        <div className="col line1-loading animate  mt-1"></div>
                        <div className="col line2-loading animate mt-2"></div>
                    
                </div>

            </div>
        </div>
    }
    return (
        <div id="card-dashboard-inflows-outflows" className="card-dashboard-element">

            {isLoading ? (

                layoutLoading()
            ) : (
                layoutCard()
            )}

        </div >
    )
}

export default CardDashboardFinancialSummary;