import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux';

import './PatientShareHistory.scss'
import { CircularProgress } from '@mui/material';
import { Skeleton } from '@mui/lab';
import { saveOwnerContactInfo } from '../owner/Owner';
import VSDrawer from '../../components/vsDrawer/VSDrawer';
import TextFieldMask from '../../components/textFieldMask/TextFieldMask';

import { optionalField, saveOnTracker, validateCPF } from '../../utils';
import { getAgeFromPatient } from './Patient';
import PatientPhoto from '../../components/patientPhoto/PatientPhoto';
import VSMessage, { saveOnTrackerMessage } from '../../components/vsMessage/VSMessage';

const PatientShareHistory = (props) => {

    const {
        history,
        clinic,
        patient,
        owner
    } = props

    const MessageForm = useRef(null)

    const [ objOwner, setObjOwner ] = useState(owner || patient?.get("owner"))
    const [ patients, setPatients ] = useState(patient ? [patient] : [])

    const _messageTemplate = owner ? "OWNER_HISTORY" : "PATIENT_HISTORY"

    const [ loading, setLoading ] = useState(true)
    const [ sendLoading, setSendLoading ] = useState(false)
    const [ saveOwnerLoading, setSaveOwnerLoading ] = useState(false)

    const [ editCpf, setEditCpf ] = useState(false)
    const [ cpfOwner, setCpfOwner ] = useState(objOwner.get("cpf") || "")
    const [ errorCpfOwner, setErrorCpfOwner ] = useState(false)
    const [ helperCpfOwner, setHelperCpfOwner ] = useState(optionalField)

    const [ errors, setErrors ] = useState([])

    const validate = () => {
        let _errors = []

        if (MessageForm.current) {
            _errors = MessageForm.current?.validate()
        }

        if (!validateCPF(objOwner.get("cpf"))) {
            _errors.push("CPF")
            setErrorCpfOwner(true)
            setHelperCpfOwner("O CPF é obrigatório porque os 4 primeiros dígitos serão a senha para o(a) tutor(a) acessar o histórico")
        } else {
            setErrorCpfOwner(false)
            setHelperCpfOwner(optionalField)
        }

        setErrors(_errors)
        return _errors
    }

    const save = () => {
        return new Promise((resolve, reject) => {
            if (errors.length == 0 && !sendLoading) {
                setSendLoading(true)

                const values = MessageForm.current?.save()

                saveOnTrackerMessage("Histórico do Animal", patient.id, values)

                resolve()
            } else {
                reject()
            }
        })
    }

    const saveOwner = () => {
        setSaveOwnerLoading(true)

        saveOwnerContactInfo(objOwner, objOwner.get("phone"), objOwner.get("email"), cpfOwner)
        .then(_newObjOwner => {
            setObjOwner(_newObjOwner)
            setEditCpf(false)
            setSaveOwnerLoading(false)
        })
        .catch(_ => {
            setSaveOwnerLoading(false)
        })
    }

    const getObjectId = () => { 
        return owner ? owner.id : patient.id
    }

    useEffect(() => {
        if (owner) {
            setLoading(true)
            setPatients([])
            var query = new Parse.Query("Patient");
            query.equalTo("clinic", clinic.object);
            query.equalTo("isDeleted", false);
            query.equalTo("owner", owner);
            query.find()
            .then(_patients => {
                if (_patients) {
                    setPatients(_patients)
                }
    
                setLoading(false)
            }).catch(error => {
                history.push('/')
                console.error(error)
                setLoading(false)
            })
        }
    }, [ owner ])

    useEffect(() => {
        if (patient) {
            setLoading(false)
        }
    }, [ patient ])

    useEffect(() => {
        validate()
    }, [ 
        saveOwnerLoading, cpfOwner
    ])

    const messageParams = {
        user: Parse.User.current().id,
        clinic: clinic.objectId,
        owner: objOwner.id,
        patient: patients[0] && patients[0].id,
        createdFromClass: owner ? "Owner" : "Patient",
        createdFromId: getObjectId(),
        toHTML: 1
    }

    const link = `${process.env.REACT_APP_PRESCRIPTION}/h/${ owner ? "o" : "p" }${owner?.id || patient?.id}`

    return (
        <VSDrawer
            id="share-history-form"
            width="50%"
            title="Compartilhar Histórico"
            cancel="Cancelar Compartilhamento"
            submit={errors.length == 0 ? <span>Compartilhar Histórico<span className="d-none d-md-inline"> do Animal</span></span> : <span>Preencha os campos<span className="d-none d-md-inline"> necessários</span></span>}
            errors={errors}
            timeout={0}
            onSubmit={save}
            onCancel={() => history.goBack()}
            onClose={() => history.goBack()}
            VSDrawerCancelProps={{
                title: "Tem certeza que deseja cancelar o Compartilhamento do Histórico?",
                confirm: "Cancelar Compartilhamento",
                cancel: "Voltar para Compartilhar"
            }}
        >
            { loading ? (
                <div className="data-loading">
                    <CircularProgress />
                </div>
            ) : (
                <>
                    <div className="mb-4">
                        Compartilhe com o tutor <b>{ objOwner.get("name") }</b> uma cópia do histórico de atendimentos dos animais dele. 
                        Essa mensagem não pode ser editada e será enviada diretamente pelo Vet Smart.
                    </div>
                    <div className="row g-3 mb-3">
                        <div className="col-12">
                            <h4 className="mb-0">Animais incluídos no histórico:</h4>
                        </div>
                        { patients.map((patient, index) => (
                            <div className="col-12 col-md-6 col-xxl-4 d-flex" key={index}>
                                <div className="patient-item flex-grow-1 d-flex align-items-center">
                                    <div className="row align-items-center">
                                        <div className="col-auto pe-0">
                                            <PatientPhoto patient={patient} withBorder />
                                        </div>
                                        <div className="col">
                                            <h4>{ patient.get("name") }</h4>
                                            <p><b>Raça:</b> { patient.get("breed") || "Não informado" }</p>
                                            <p><b>Idade:</b> { getAgeFromPatient(patient, true) || "Não informado" }</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <VSMessage
                        ref={MessageForm}
                        clinic={clinic.object}
                        owner={objOwner}
                        ownerSeparator={false}
                        link={{
                            url: link,
                            urlToPDF: `${link}/pdf`
                        }}
                        onShare={() => {
                            saveOnTracker("Compartilhou", "Histórico do Animal", patient.id, "", "Histórico do Animal", patient.id, "", "WhatsApp Web")
                        }}
                        onChange={() => {
                            validate()
                        }}
                        onResend={() => {
                            setSendLoading(false)
                        }}
                        onClose={() => {
                            history.goBack()
                        }}
                        appendOwnerChild={(
                            <div className="row align-items-center mb-2">
                                <div className="col-12">
                                    <div className="color-title mb-0">
                                        CPF:
                                    </div>
                                </div>
                                { editCpf ? (
                                    <>
                                        <div className="col">
                                            <TextFieldMask
                                                type="tel"
                                                mask="000.000.000-00"
                                                placeholder="000.000.000-00"
                                                value={cpfOwner}
                                                onChange={({ target: { value } }) => setCpfOwner(value)}
                                                error={errorCpfOwner}
                                                size="small"
                                                variant="outlined"
                                                fullWidth
                                            />
                                        </div>
                                        <div className="col-auto">
                                            <button
                                                className="btn-submit btn-square"
                                                disabled={saveOwnerLoading}
                                                onClick={() => saveOwner()}
                                            >
                                                { saveOwnerLoading ? (
                                                    <CircularProgress size="12px" />
                                                ) : (
                                                    "Salvar"
                                                )}
                                            </button>
                                        </div>
                                        <div className="col-auto ps-0">
                                            <button
                                                className="vsbox-btn vsbox-btn-primary"
                                                onClick={() => {
                                                    setCpfOwner(objOwner.get("cpf"))
                                                    setEditCpf(false)
                                                }}
                                            >
                                                Cancelar
                                            </button>
                                        </div>
                                    </>
                                ) : (
                                    loading ? (
                                        <div className="col-12">
                                            <Skeleton variant="text" height={32} />
                                        </div>
                                    ) : (
                                        <>
                                            <div className="col text-black">
                                                { cpfOwner || "Não informado" }
                                            </div>
                                            <div className="col-auto">
                                                <div
                                                    className="input-title text-primary cursor-pointer"
                                                    onClick={() => setEditCpf(true)}
                                                >
                                                    EDITAR
                                                </div>
                                            </div>
                                        </>
                                    )
                                )}
                                { errorCpfOwner && (
                                    <div className="col-12">
                                        <small className="text-danger">{ helperCpfOwner }</small>
                                    </div>
                                )}
                            </div>
                        )}
                        messages={[
                            {
                                ...messageParams,
                                title: "Enviar Cópia Digital",
                                checked: true,
                                messageTypeId: "SEND",
                                messages: [
                                    {
                                        ...messageParams,
                                        title: "Compartilhar Histórico por WhatsApp",
                                        messageTypeId: "WHATSAPP",
                                        messageTemplateId: `WHATSAPP_${_messageTemplate}`,
                                        withSeparator: true
                                    },
                                    {
                                        ...messageParams,
                                        title: "Compartilhar Histórico por SMS",
                                        messageTypeId: "SMS",
                                        messageTemplateId: `SMS_${_messageTemplate}`,
                                        withSeparator: true
                                    },
                                    {
                                        ...messageParams,
                                        title: "Compartilhar Histórico por E-mail",
                                        messageTypeId: "EMAIL",
                                        messageTemplateId: `EMAIL_${_messageTemplate}`,
                                        withSeparator: false
                                    }
                                ]
                            }
                        ]}
                    />
                </>
            )}
        </VSDrawer>
    )
}

const mapStateToProps = state => {
    return {
        user: state.user,
        clinic: state.clinic
    };
};

export default connect(mapStateToProps)(PatientShareHistory)