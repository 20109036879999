import { APP_DEVICE, APP_NAME, APP_VERSION } from "../../../../constants"
import { getSelectedOptionLabel } from "../../../../utils"

export const deleteAppointment = (appointment, callback) => {
    Swal.fire({
        title: 'Tem certeza?',
        text: "Você tem certeza que deseja excluir essa consulta? Essa ação não poderá ser desfeita.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, quero excluir!',
        cancelButtonText: 'Não, manter consulta'
    }).then((result) => {
        if (result.isConfirmed) {
            appointment.set("isDeleted", true)
            appointment.set("changedAt", new Date())
            appointment.set("changedBy", Parse.User.current())
            appointment.set("changedApp", APP_NAME)
            appointment.set("changedDevice", APP_DEVICE)
            appointment.set("changedAppVersion", APP_VERSION)
            appointment.save()
            .then(_ => {
                Swal.fire(
                    'Excluído!',
                    'A consulta foi excluída com sucesso.',
                    'success'
                )
                .then(_ => {
                    callback && callback()
                })
            })
            .catch(error => {
                console.error(error)
                Swal.fire(
                    'Desculpe',
                    'Ocorreu algum erro ao tentar excluir a consulta',
                    'error'
                )
                appointment.revert()
            })
        }
    })
}

export const getAppointment = (id, patient, includeAI) => {
    return new Promise((resolve, reject) => {
        var query = new Parse.Query("Appointment");
        query.equalTo("clinic", patient.get("clinic"));
        query.equalTo("isDeleted", false);
        query.equalTo("patient", patient)
        if(includeAI){
            query.include("differentialDiagnosis")
        }
        query.get(id)
        .then(_object => {
            if (_object) {
                resolve(_object)
            } else {
                reject('Não encontramos a medicação informada')
            }
        }).catch(error => {
            console.error(error)
            reject('Ocorreu algum erro ao tentar buscar a medicação')
        })
    })
}

export const showAppointment = (appointment) => {
    return getSelectedOptionLabel(null, appointmentTypeOptions, appointment.get("appointmentType") ? appointment.get("appointmentType") : CONSULTA_PADRAO, "long") || "Consulta padrão"
}

export const appointmentTypeOptions = [
    {
        value: "10",
        label: "Padrão",
        long: "Consulta padrão"
    },
    {
        value: "1",
        label: "Detalhada",
        long: "Consulta detalhada"
    }
]

export const CONSULTA_PADRAO = "10"
export const CONSULTA_DETALHADA = "1"