import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from "react-router";

import { ReactComponent as LogoMin } from "../../assets/vetsmart-logo.svg"

import { ReactComponent as IconAdd } from "../../assets/icon-primary-add-o.svg"
import { ReactComponent as IconArrowLeft } from "../../assets/icon-arrow-left.svg"
import { ReactComponent as IconConfig } from "../../assets/icon-config.svg"
import { ReactComponent as IconPrimaryLogout } from "../../assets/icon-primary-logout.svg"
import { ReactComponent as IconVetsmart } from "../../assets/icon-vetsmart.svg"
import { ReactComponent as IconUserO } from "../../assets/icon-user-o.svg"
import { ReactComponent as IconPaw } from "../../assets/icon-paw.svg"
import { ReactComponent as IconClinicConfig } from "../../assets/icon-clinic-config.svg"
import { ReactComponent as IconPrimaryUserCog } from "../../assets/icon-primary-user-cog.svg"
import { ReactComponent as IconNotification } from "../../assets/icon-notification.svg"
import { ReactComponent as IconHeaderIA } from "../../assets/icon-header-ia.svg"
import { ReactComponent as Logo } from "../../assets/vetsmart.svg"

import SearchByOwnerOrPatient from '../searchByOwnerOrPatient/SearchByOwnerOrPatient';
import { logOut } from '../../store/actions';
import { firstName, saveOnTracker, useWindowSize, getClinic } from '../../utils';
import { ACL } from '../../constants';

import "./Header.scss"
const Header = (props) => {

    const {
        pro,
        clinic,
        user,
        history,
        permissions,
        onMenuChange,
        mobileMenuOpen
    } = props

    const {
        width
    } = useWindowSize()

    const searchRef = useRef(null)
    const isMenuMobile = width <= 768
	const hasSelectedClinic = getClinic() != null

    const [ search, setSearch ] = useState(false)
    const [ toggleMobileAddButtons, setToggleMobileAddButtons ] = useState(false)
    const [ userMobileInitials, setUserMobileInitials ] = useState('')
    const [ userMenuOpen, setUserMenuOpen ] = useState(false)

    const showCrmvIfExists = () => {
        if (user.crmv && user.crmvStateId)
            return `CRMV ${user.crmv} ${user.crmvStateId}`
        return !User.getOccupationDescription() || "-"
    }
    
    var lastClicked;
    const handleClickOutside = e => {
        if (searchRef.current && !searchRef.current.contains(e.target)) {
            setSearch(false)
        }
        if(e.target != lastClicked && e.target.nodeName != 'BUTTON'){
            setToggleMobileAddButtons(false)
        }
        lastClicked = e.target;
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [])

    useEffect(() => {
        if(!user || !user.fullName){
            return;
        }

        var allNames = user.fullName.trim().split(" ");
        var firstName = allNames && allNames[0] ? allNames[0].trim() : '';
        var secondName = [''];
        if(allNames.length > 1){
            secondName = allNames && allNames[1] ? allNames[1].trim() : [''];
        }

        if(!firstName[0]){
            return;
        }
        
        const initials = firstName[0] + secondName[0]
        setUserMobileInitials(initials.toUpperCase());
    }, [ user ])

    if(!user || !user.object){
        return <div></div>
    }
    
    const getProfileText = () => user?.objectId == clinic?.owner ? "Dono(a) da clínica" : 
                                    user.crmv && user.crmvStateId ? showCrmvIfExists() : user?.profileName
    
    return (
        <div id="header" className={`${search ? "full-search" : ""}`}>
            <div className={`row w-100 h-100 align-items-center justify-content-end  flex-grow-1 ${search ? 'gx-0' : 'gx-3'}`}>
                {!hasSelectedClinic ? 
                <div className="col-auto ps-0 flex-grow-1 d-flex justify-content-start order-2">
                    <Logo />
                </div> : 
                isMenuMobile && !search && (
                    <>
                        <div className="col-auto ps-0 order-1">
                            <div onClick={onMenuChange} id="header-mobile-open-menu" className={mobileMenuOpen ? 'selected' : ''}>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                        <div className="col-auto ps-0 pe-1 order-2">
                            <LogoMin width={40} height={40} />
                        </div>
                    </>
                )}
                { hasSelectedClinic && permissions?.check(ACL.OWNER, ACL.Rule.VIEW) && (
                    <div ref={searchRef} className="col-12 col-md text-right area-search order-5 order-md-3">
                        <SearchByOwnerOrPatient
                            clinic={clinic}
                            onlyOwners={!permissions?.check(ACL.PATIENT, ACL.Rule.VIEW)}
                            disabled={!clinic?.object}
                            onClick={(element) => {
                                if (window.innerWidth >= 768 && element.offsetWidth <= 300) {
                                    setSearch(true)
                                }
                            }}
                            onClose={search ? 
                                () => {
                                    setSearch(false)
                                } : null
                            }
                            onSelect={(selected, setSearch) => {
                                if (typeof selected !== "string") {
                                    setSearch('')
                                    if (selected.patient) {
                                        history.push(`/animal/${selected.patient.id}`)
                                    } else {
                                        history.push(`/tutor/${selected.owner.id}`)
                                    }
                                } else {
                                    history.push(`/busca/${selected}`)
                                }
                            }}
                            showMoreResults
                        />
                    </div>
                )}
                {hasSelectedClinic && 
                <div className="col col-md-auto order-3 order-md-4 header-actions">
                    <div className="row justify-content-end">
                        <div className="col-auto area-action position-relative me-0">
                            <div className="d-flex">
                                <button
                                    className={`btn-outline-submit with-arrow ${toggleMobileAddButtons ? "btn-select" : ""} btn-linear-padding d-flex align-items-center justify-content-center text-capitalize ps-lg-3 pe-lg-5`}
                                    onClick={e => {
                                        if(e.target != lastClicked){
                                            setToggleMobileAddButtons(!toggleMobileAddButtons)
                                            setUserMenuOpen(false)
                                        }
                                        lastClicked = e.target;
                                    }}
                                >
                                    <IconAdd className="me-0 d-lg-none" width="14px" height="14px" fill={`${toggleMobileAddButtons ? "#ffffff" : "var(--vsprontuario-primary-color)"}`} />
                                    <span className="d-none d-lg-inline">Adicionar Cadastro</span>
                                </button>
                            </div>
                            <div id="header-add" className={`row align-items-center ${toggleMobileAddButtons ? "" : "hidden"}`}>
                                { permissions?.check(ACL.OWNER, ACL.Rule.EDIT) && (
                                    <div className="col-auto ps-1">
                                        <button
                                            className={`${toggleMobileAddButtons ? "btn-simple" : "btn-outline-submit"} d-flex align-items-center`}
                                            onClick={() => {
                                                saveOnTracker("Clicou", "Header Prontuário", "Adicionar Tutor(a)")
                                                history.push(`/tutor/form/novo`)
                                            }}
                                        >
                                            <IconUserO className="me-2" width="30px" height="30px" fill="var(--vsprontuario-primary-color)" />
                                            Adicionar Tutor(a)
                                        </button>
                                    </div>
                                )}
                                { permissions?.check(ACL.PATIENT, ACL.Rule.EDIT) && (
                                    <div className="col-auto ps-1">
                                        <button
                                            className={`${toggleMobileAddButtons ? "btn-simple" : "btn-submit"} d-flex align-items-center`}
                                            onClick={() => {
                                                saveOnTracker("Clicou", "Header Prontuário", "Adicionar Animal")
                                                history.push(`/tutor/selecionar/animal/novo`)
                                            }}
                                        >
                                            <IconPaw className="me-2" width="30px" height="30px" fill={`${toggleMobileAddButtons ? "var(--vsprontuario-primary-color)" : "#FFFFFF"}`} />
                                            Adicionar Animal
                                        </button>
                                    </div>
                                )}
                                {/* <div className="col-auto ps-1">
                                    <IconNotification 
                                        width="24px" 
                                        height="24px" 
                                        fill="#808080" 
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => history.push(`/novidades`)} 
                                    />
                                </div> */}
                            </div>
                        </div>
                        {/* {pro?.access &&  */}
                        <div className="col-auto area-action ps-0">
                            <button
                                id="prontuario-ai"
                                className={`btn-outline-submit btn-linear-padding d-flex align-items-center justify-content-center text-capitalize px-lg-3`}
                                onClick={_ => {
                                    setUserMenuOpen(false)
                                    saveOnTracker("Clicou", "Header Prontuário", "Assistente")
                                    history.push(`/assistente`)
                                }}
                            >
                                <IconHeaderIA className="me-0 me-lg-2" width="14px" height="14px" fill="var(--vsprontuario-primary-color)" />
                                <span className="d-none d-lg-inline">Assistente Vetsmart</span> <span className="btn-beta-text">Beta</span>
                            </button>
                        </div>
                        {/* } */}
                    </div>
                </div>}
                <div className="col-auto user-wrap order-4 order-md-5">
                    <div className={`user area-user position-relative ${userMenuOpen ? 'open' : ''}`}
                        onMouseEnter={() => !isMenuMobile && setUserMenuOpen(true)}
                        onMouseLeave={() => !isMenuMobile && setUserMenuOpen(false)}
                        onClick={() => setUserMenuOpen(!userMenuOpen)}
                        >
                        <div className="row align-items-center">
                            <div className="col-auto pe-1">
                                {/* <IconUserO width="30px" height="30px" fill="#808080" className="d-none d-lg-inline"/> */}
                                <div className="user-mobile-initials">{userMobileInitials ? userMobileInitials : ''}</div>
                            </div>
                            <div className="col text-start px-1 user-info d-none d-xl-block">
                                <div className="user-name text-truncate">{ user.fullName }</div>
                                <div className="text-truncate">{getProfileText()}</div>
                            </div>
                            <div className="col-auto ps-2 d-block">
                                <IconArrowLeft width="8" height="12" className="user-more" />
                            </div>
                        </div>
                        <div className="user-options">
                            <div className="user-modal">
                                <div className="user-welcome">
                                    Olá, { firstName(user.fullName) }!
                                    <div className="user-welcome-subtitle">{getProfileText()}</div>
                                </div>
                                {/*
                                    <div className="user-notify">
                                        <div className="notify">
                                            <div className="notify-title">
                                                Novo Lembrete de Vacina
                                            </div>
                                            <div className="notify-text">
                                                Todos seus lembretes de vacina num só lugar!
                                            </div>
                                            <div className="notify-icon">
                                                <IconArrowLeft width="8" height="12" />
                                            </div>
                                        </div>
                                    </div>
                                */}
                                <ul className="user-menu">
                                    <a className={`d-block user-item pb-2`} 
                                        onClick={() => {
                                            saveOnTracker("Clicou", "Menu Usuário", "Gerenciar minha conta")
                                        }}
                                        href={`${process.env.REACT_APP_ACCOUNT}`} 
                                        target="_blank">
                                        <IconConfig width="24" height="24" fill="var(--vsprontuario-primary-color)" />
                                        Gerenciar minha conta
                                    </a>
                                    <li className="d-block user-item pt-2 pb-2" 
                                        onClick={() => {
                                            history.push('/configuracao-medico-veterinario')
                                            saveOnTracker("Clicou", "Menu Usuário", "Configurações do Médico Veterinário")
                                        }}>
                                        <IconPrimaryUserCog width="24" height="24" fill="var(--vsprontuario-primary-color)" />
                                        Configurações do Médico Veterinário
                                    </li>
                                    {(permissions?.check(ACL.ADMIN, ACL.Rule.VIEW) || clinic.isOwner) &&
                                    <li className="d-flex user-item pt-2 pb-2" 
                                        onClick={() => {
                                            history.push('/configuracao-clinica')
                                            saveOnTracker("Clicou", "Menu Usuário", "Configurações da Clínica")
                                        }}>
                                        <IconClinicConfig className="flex-shrink-0" width="24" height="24" fill="var(--vsprontuario-primary-color)" />
                                        <div style={{ minWidth: 0 }}>
                                            Configurações da Clínica
                                            {clinic?.object?.get('name') && 
                                            <div className="user-item-subtitle">{clinic?.object?.get('name')}</div>}
                                        </div>
                                    </li>}
                                    <a className="d-block user-item pt-2 pb-2" 
                                        onClick={() => {
                                            saveOnTracker("Clicou", "Menu Usuário", "Ir para o site vetsmart.com.br")
                                        }}
                                        href={`${process.env.REACT_APP_SITE}`} 
                                        target="_blank">
                                        <IconVetsmart width="24" height="24" />
                                        Ir para o site vetsmart.com.br
                                    </a>
                                    <li className="user-item pt-2" onClick={() => {
                                        props.dispatch(logOut(user))
                                        saveOnTracker("Clicou", "Menu Usuário", "Sair da conta")
                                    }}>
                                        <IconPrimaryLogout width="24" height="24" />
                                        Sair da conta
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
  return {
    user: state.user,
    clinic: state.clinic,
    permissions: state.permissions,
    pro: state.featuresPro
  };
};

export default withRouter(connect(mapStateToProps)(Header));