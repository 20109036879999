import Canine01 from "../../../../assets/canine/01.png"
import Canine02 from "../../../../assets/canine/02.png"
import Canine03 from "../../../../assets/canine/03.png"
import Canine04 from "../../../../assets/canine/04.png"
import Canine05 from "../../../../assets/canine/05.png"
import Canine06 from "../../../../assets/canine/06.png"
import Canine07 from "../../../../assets/canine/07.png"
import Canine08 from "../../../../assets/canine/08.png"
import Canine09 from "../../../../assets/canine/09.png"
import Feline01 from "../../../../assets/feline/01.png"
import Feline02 from "../../../../assets/feline/02.png"
import Feline03 from "../../../../assets/feline/03.png"
import Feline04 from "../../../../assets/feline/04.png"
import Feline05 from "../../../../assets/feline/05.png"
import Feline06 from "../../../../assets/feline/06.png"
import Feline07 from "../../../../assets/feline/07.png"
import Feline08 from "../../../../assets/feline/08.png"
import Feline09 from "../../../../assets/feline/09.png"
import { APP_DEVICE, APP_NAME, APP_VERSION } from "../../../../constants"

export const deleteWeight = (weight, callback) => {
    Swal.fire({
        title: 'Tem certeza?',
        text: "Você tem certeza que deseja excluir essa pesagem? Essa ação não poderá ser desfeita.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, quero excluir!',
        cancelButtonText: 'Não, manter pesagem'
    }).then((result) => {
        if (result.isConfirmed) {
            weight.set("isDeleted", true)
            weight.set("changedAt", new Date())
            weight.set("changedBy", Parse.User.current())
            weight.set("changedApp", APP_NAME)
            weight.set("changedDevice", APP_DEVICE)
            weight.set("changedAppVersion", APP_VERSION)
            weight.save()
            .then(_ => {
                Swal.fire(
                    'Excluído!',
                    'A pesagem foi excluída com sucesso.',
                    'success'
                )
                .then(_ => {
                    callback && callback()
                })
            })
            .catch(error => {
                console.error(error)
                Swal.fire(
                    'Desculpe',
                    'Ocorreu algum erro ao tentar excluir a pesagem',
                    'error'
                )
                weight.revert()
            })
        }
    })
}

export const getWeight = (id, patient) => {
    return new Promise((resolve, reject) => {
        var query = new Parse.Query("Weight");
        query.equalTo("clinic", patient.get("clinic"));
        query.equalTo("isDeleted", false);
        query.equalTo("patient", patient)
        query.get(id)
        .then(_object => {
            if (_object) {
                resolve(_object)
            } else {
                reject('Não encontramos a pesagem informada')
            }
        }).catch(error => {
            console.error(error)
            reject('Ocorreu algum erro ao tentar buscar a pesagem')
        })
    })
}

export const showWeight = (weight) => {
    return `Peso `
}

export const weightScoreOptions = [
    {
        value: "01",
        label: "Muito Magro",
        color: "#F44336",
        canine: {
            image: Canine01,
            info: (
                <ul>
                    <li>Costelas, vértebras lombares, ossos pélvicos e todas as saliências ósseas visíveis à distância</li>
                    <li>Não há gordura corporal aparente</li>
                    <li>Perda evidente de massa muscular</li>
                </ul>
            )
        },
        feline: {
            image: Feline01,
            info: (
                <ul>
                    <li>Costelas visíveis nos gatos de pelo curto</li>
                    <li>Nenhuma gordura palpável</li>
                    <li>Acentuada reentrância abdominal</li>
                    <li>Vértebras lombares e asa do ilíaco facilmente palpáveis</li>
                </ul>
            )
        }
    },
    {
        value: "02",
        label: "Muito Magro",
        color: "#FF8A00",
        canine: {
            image: Canine02,
            info: (
                <ul>
                    <li>Costelas, vértebras lombares e ossos facilmente visíveis</li>
                    <li>Não há gordura palpável</li>
                    <li>Algumas outras saliências ósseas podem estar visíveis</li>
                    <li>Perda mínima de massa muscular</li>
                </ul>
            )
        },
        feline: {
            image: Feline02,
            info: (
                <ul>
                    <li>Costelas visíveis nos gatos de pelo curto</li>
                    <li>Vértebras lombares são observadas com mínima massa muscular</li>
                    <li>Reentrânciaa bdominal</li>
                    <li>Não há presença de gorgura palpável</li>
                </ul>
            )
        }
    },
    {
        value: "03",
        label: "Muito Magro",
        color: "#DCAB00",
        canine: {
            image: Canine03,
            info: (
                <ul>
                    <li>Costelas facilmente palpáveis podem estar visíveis sem gordura palpável</li>
                    <li>Visível topo das vértebras lombares</li>
                    <li>Os ossos pélvicos começam a ficar visíveis</li>
                    <li>Cintura e reentrância abdominal evidentes</li>
                </ul>
            )
        },
        feline: {
            image: Feline03,
            info: (
                <ul>
                    <li>Costelas facilmente palpáveis com uma minima cobertura de gordura</li>
                    <li>Vértebras lombares são visíveis</li>
                    <li>Cintura evidente depois das costelas</li>
                    <li>Mínimo de gordura abdominal</li>
                </ul>
            )
        }
    },
    {
        value: "04",
        label: "Ideal",
        color: "#026C39",
        canine: {
            image: Canine04,
            info: (
                <ul>
                    <li>Costelas facilmente palpáveis com mínima cobertura de gordura</li>
                    <li>Vista de cima, a cintura é facilmente observada</li>
                    <li>Reentrância abdominal evidente</li>
                </ul>
            )
        },
        feline: {
            image: Feline04,
            info: (
                <ul>
                    <li>Costelas palpáveis com uma minima cobertura de gordura</li>
                    <li>Cintura perceptível atrás das costelas</li>
                    <li>Mínimo de gordura abdominal</li>
                </ul>
            )
        }
    },
    {
        value: "05",
        label: "Ideal",
        color: "#03A356",
        canine: {
            image: Canine05,
            info: (
                <ul>
                    <li>Costelas facilmente palpáveis sem excessiva cobertura de gordura</li>
                    <li>Cintura observada por trás das costelas, quando vista de cima</li>
                    <li>Abdômen retraído quando visto de lado</li>
                </ul>
            )
        },
        feline: {
            image: Feline05,
            info: (
                <ul>
                    <li>Bem proporcionado</li>
                    <li>Cintura visível depois das costelas</li>
                    <li>Costelas palpáveis com pequena cobertura de gordura</li>
                    <li>Panículo adiposo abdominal mínimo</li>
                </ul>
            )
        }
    },
    {
        value: "06",
        label: "Acima do Ideal",
        color: "#1880FA",
        canine: {
            image: Canine06,
            info: (
                <ul>
                    <li>Costelas palpáveis com leve excesso de cobertura de gordura</li>
                    <li>A cintura é visível quando observada de cima, mas não é acentuada</li>
                    <li>Reentrância abdominal aparente</li>
                </ul>
            )
        },
        feline: {
            image: Feline06,
            info: (
                <ul>
                    <li>Costelas palpáveis com mínima cobertura de gordura</li>
                    <li>Cintura e gordura abdominal visíveis, mas não óbvios</li>
                </ul>
            )
        }
    },
    {
        value: "07",
        label: "Sobrepeso",
        color: "#0056BA",
        canine: {
            image: Canine07,
            info: (
                <ul>
                    <li>Costelas palpáveis com dificuldade</li>
                    <li>Pesada cobertura de gordura</li>
                    <li>Depósitos de gordura evidentes sobre a área lombar e base da calda</li>
                    <li>Ausência de cintura ou apenas visível</li>
                    <li>A reentrância abdominal pode estar presente</li>
                </ul>
            )
        },
        feline: {
            image: Feline07,
            info: (
                <ul>
                    <li>Dificuldade para palpar as costelas que têm moderada cobertura de gordura</li>
                    <li>Cintura não é muito evidente</li>
                    <li>Arredondamento óbvio do abdômen</li>
                    <li>Moderado panículo adiposo abdominal</li>
                </ul>
            )
        }
    },
    {
        value: "08",
        label: "Obeso",
        color: "#740090",
        canine: {
            image: Canine08,
            info: (
                <ul>
                    <li>Impossível palpar as costelas situadas sob cobertura de gordura muito densa ou palpáveis somente com pressão acentuada</li>
                    <li>Pesados depósitos de gordura sobre a área lombar e base da cauda</li>
                    <li>Cintura Inexistente</li>
                    <li>Não há reentrância abdominal</li>
                    <li>Poderá existir distensão abdominal evidente</li>
                </ul>
            )
        },
        feline: {
            image: Feline08,
            info: (
                <ul>
                    <li>Costelas não palpáveis, com excesso de cobertura de gordura</li>
                    <li>Cintura ausente</li>
                    <li>Arredondamento abdominal e presença de gordura vis;ivel</li>
                    <li>Presença de depósitos de gordura lombar</li>
                </ul>
            )
        }
    },
    {
        value: "09",
        label: "Obeso",
        color: "#410060",
        canine: {
            image: Canine09,
            info: (
                <ul>
                    <li>Maciços depósitos de gordura sobre o tóraz, espinha e base da cauda</li>
                    <li>Depósitos de gordura no pescoço e membros</li>
                    <li>Distensão abdominal evidente</li>
                </ul>
            )
        },
        feline: {
            image: Feline09,
            info: (
                <ul>
                    <li>Impossível palpar as costelas que se encontram sob espessa cobertura de gordura. Pesados depósitos de gordura na área lombar, face e membros</li>
                    <li>Distensão do abdômen e ausência de cintura</li>
                    <li>Amplos depósitos abdominais de gordura</li>
                </ul>
            )
        }
    }
]

