import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { useParams } from "react-router-dom/cjs/react-router-dom"

import { ReactComponent as IconChemical } from "../../assets/icon-chemical.svg"
import { ReactComponent as IconEdit } from "../../assets/icon-primary-edit.svg"

import { getStockSupply } from "./Supply"
import { ACL } from "../../constants"
import { showRegisteredBy } from "../../utils"

import { CircularProgress } from "@mui/material"
import VSError from "../../components/vsError/VSError"
import VSDrawer from "../../components/vsDrawer/VSDrawer"
import VSBoxView from "../../components/vsBoxView/VSBoxView"
import VSAccordionSimple from "../../components/vsAccordion/VSAccordionSimple"

import "./SupplyViewDetail.scss"
import VSMedicalRecordsAudit from "../../components/vsMedicalRecordsAudit/VSMedicalRecordsAudit"
import SupplyUnitView from "./SupplyUnitView"
const SupplyViewDetail = (props) => {

    const {
        id_insumo
    } = useParams()

    const {
        clinic,
        history,
        permissions
    } = props

    const [ stock, setStock ] = useState(null)
    const [ supply, setSupply ] = useState(null)
    const [ category, setCategory ] = useState(null)
    const [ transactions, setTransactions ] = useState([])
    const [ loading, setLoading ] = useState(true)
    const [ error, setError ] = useState(null)

    const getSupplyTransactionOrError = () => {
        setTransactions([])

        const query = new Parse.Query("VW_MRSupplyTransaction_timeline")
        query.equalTo("supply", supply)
        query.equalTo("clinic", clinic.object)
        query.equalTo("isDeleted", false)
        query.descending("transactionDate")
        query.include("createdBy")
        query.include("changedBy")
        query.find()
        .then(results => {
            setTransactions(results)
            setLoading(false)
        })
        .catch(error => {
            Swal.fire(
                'Desculpe',
                error,
                'error'
            )
            .then(_ => {
                setLoading(false)
                setError({
                    message: error,
                    function: "getSupplyTransactionOrError"
                })
            })
        })
    }

    const getSupplyOrError = async () => {
        setLoading(true)
        setError(null)

        getStockSupply(id_insumo, clinic.object)
        .then(_stock => {
            setStock(_stock)
            setSupply(_stock?.get("supply"))
            setCategory(_stock?.get("supply")?.get("category"))
        })
        .catch(error => {
            Swal.fire(
                'Desculpe',
                error,
                'error'
            )
            .then(_ => {
                setLoading(false)
                setError({
                    message: error,
                    function: "getSupplyOrError"
                })
            })
        })
    }

    useEffect(() => {
        if (supply) {
            getSupplyTransactionOrError()
        }
    }, [ supply ])

    useEffect(() => {
        if (clinic && clinic.isLoaded && clinic.object) {
            getSupplyOrError()
        }
    }, [ clinic ])

    const isDeleted = supply?.get("isDeleted")

    return (
        <VSDrawer
            id="supply-view-detail"
            width="50%"
            title="Ver detalhes do insumo"
            onCancel={() => history.goBack()}
            onClose={() => history.goBack()}
            VSDrawerFooterProps={{
                show: false
            }}
        >
            { loading ? (
                <div className="row data-loading">
                    <div className="col">
                        <CircularProgress />
                    </div>
                </div>
            ) : error?.message ? (
                <VSError
                    message={error.message}
                    onClose={() => {
                        switch(error.function) {
                            case "getSupplyOrError":
                                getSupplyOrError()
                                break
                            case "getSupplyTransactionOrError":
                                getSupplyTransactionOrError()
                                break
                            default:
                        }
                    }}
                />
            ) : (
                <VSBoxView
                    status={supply.get("active") ? "active" : "inactive"} // TODO: implement warning to reposition
                    icon={<IconChemical width="24" height="24" />}
                    title={(
                        <>
                            { supply.get("supplyId") && (
                                <b>ID { supply.get("supplyId") } - </b>
                            )}
                            { supply.get("supplyName") }
                        </>
                    )}
                    text={(
                        <>
                            <b>Categoria:</b> { supply?.get("category")?.get("categoryName") || "Não informada" }
                        </>
                    )}
                    actions={(
                        <div className="row align-items-center justify-content-center">
                            { permissions?.check(ACL.SUPPLY, ACL.Rule.EDIT, supply) && (
                                <div className="col col-md-auto">
                                    <button
                                        className={`vsbox-btn ${isDeleted ? "vsbox-btn-disabled" : "vsbox-btn-dark"}`}
                                        onClick={() => history.push(`/insumo/${ supply.id }`)}
                                        disabled={isDeleted}
                                    >
                                        <IconEdit width="24" height="24" fill={isDeleted ? "var(--vsprontuario-gray-color)" : "var(--vsprontuario-primary-color-dark)"} />
                                    </button>
                                </div>
                            )}
                        </div>
                    )}
                    object={supply}
                >
                    <div className="row">
                        <div className="col-12 vsbox--info">
                            <VSAccordionSimple
                                expanded={true}
                                header={(_) => "Informações do insumo"}
                            >
                                <div className="row g-3 mb-3">
                                    <div className="col-12">
                                        <div className="vsbox-item">
                                            <div className="row vsbox-item-text my-2">
                                                <div className="col-12 col-md">
                                                    <b>Fabricante:</b> { supply.get("companyName") || "Não informado" }
                                                </div>
                                                <div className="col-12 col-md">
                                                    <b>Fornecedor:</b> { supply.get("distributor") || "Não informado" }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </VSAccordionSimple>
                        </div>
                    </div>
                    <div className="vsbox-separator"></div>
                    <div className="row">
                        <div className="col-12 vsbox--info">
                            <VSAccordionSimple
                                expanded={true}
                                header={(_) => "Informações do estoque"}
                            >
                                <div className="row g-3 mb-3">
                                    <div className="col-12">
                                        <div className="vsbox-item">
                                            <div className="row vsbox-item-text my-2">
                                                <div className="col-12">
                                                    <b>Unidade de medida:</b> <SupplyUnitView unit={supply?.get("unit")} />
                                                </div>
                                                <div className="col-12 my-3">
                                                    <div className="vsbox-separator"></div>
                                                </div>
                                                <div className="col-12 col-md">
                                                    <b>Estoque Mínimo:</b> <SupplyUnitView unit={supply?.get("unit")} quantity={supply?.get("minQuantity")} />
                                                </div>
                                                <div className="col-12 col-md">
                                                    <b>Total em estoque:</b> <SupplyUnitView unit={supply?.get("unit")} quantity={stock?.get("total")} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </VSAccordionSimple>
                        </div>
                    </div>
                    <div className="vsbox-separator"></div>
                    <div className="row">
                        <div className="col-12 vsbox--info">
                            <VSAccordionSimple
                                expanded={true}
                                header={(_) => "Outras informações"}
                            >
                                <div className="row g-3 mb-3">
                                    <div className="col-12">
                                        <div className="vsbox-item">
                                            <div className="row vsbox-item-text my-2">
                                                <div className="col-12">
                                                    <b>Lembrete de Reposição:</b> { supply?.get("reminder") ? "Ativado" : "Desativado"}
                                                </div>
                                                <div className="col-12 my-3">
                                                    <div className="vsbox-separator"></div>
                                                </div>
                                                <div className="col-12">
                                                    <b>Observações:</b> { supply?.get("notes") || "Não informado" }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </VSAccordionSimple>
                        </div>
                    </div>
                    <div className="vsbox-separator"></div>
                    <div className="row">
                        <div className="col-12 vsbox--info">
                            <VSAccordionSimple
                                expanded={true}
                                header={(_) => "Histórico de Entrada e Saída"}
                            >
                                <div className="row g-3 mb-3">
                                    { transactions.map((transaction, index) => {
                                        const transactionType = transaction.get("quantity") >= 0 ? "input" : "output"
                                        const transactionTypeDescription = transactionType == "input" ? "Entrada" : "Saída"
                                        return (
                                            <div className={`col-12 transaction transaction-${transactionType}`} key={index}>
                                                <div className={`transaction-bullet`}></div>
                                                <div className="vsbox-item">
                                                    <div className="row g-3">
                                                        <div className="col-12">
                                                            <div className={`transaction-title`}>
                                                                { transactionTypeDescription }
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                            <label className="vsbox-info text-nowrap">Qtd. Anterior</label>
                                                            <div className="vsbox-info-text">
                                                                <SupplyUnitView unit={supply?.get("unit")} quantity={transaction.get("lastTotal")} />
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                            <label className="vsbox-info text-nowrap">Qtd. Movimentada</label>
                                                            <div className="vsbox-info-text">
                                                                <SupplyUnitView unit={supply?.get("unit")} quantity={transaction.get("quantity")} />
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                            <label className="vsbox-info text-nowrap">Origem da { transactionTypeDescription }</label>
                                                            <div className="vsbox-info-text">
                                                                { transaction.get("sale") ? "Venda" : "Ajuste de estoque" }
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            { showRegisteredBy(transaction) }
                                                        </div>
                                                        { transaction.get("notes") && (
                                                            <div className="col-12 mt-0">
                                                                <div className="vsbox-separator"></div>
                                                                <div className="row">
                                                                    <div className="col-12 vsbox--info">
                                                                        <VSAccordionSimple
                                                                            header={(expanded) => (
                                                                                `${ expanded ? "Ocultar" : "Ver" } mais informações`
                                                                            )}
                                                                        >
                                                                            <div className="row g-3 mb-3">
                                                                                <div className="col-12 vsbox-title">
                                                                                    Observações
                                                                                </div>
                                                                                <div className="col-12 mt-2 pre-line">
                                                                                    { transaction.get("notes") || "Não informado" }
                                                                                </div>
                                                                            </div>
                                                                        </VSAccordionSimple>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    )}
                                </div>
                            </VSAccordionSimple>
                        </div>
                    </div>
                    <VSMedicalRecordsAudit parseObject={supply} />
                </VSBoxView>
            )}
        </VSDrawer>
    )
}

const mapStateToProps = state => {
    return {
        clinic: state.clinic,
        permissions: state.permissions
    }
}

export default connect(mapStateToProps)(SupplyViewDetail)