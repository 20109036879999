import { CircularProgress, InputAdornment, TextField } from '@mui/material'
import React, { useState, useEffect, useRef } from 'react'

import { ReactComponent as IconAddO } from "../../assets/icon-add-o.svg"
import { ReactComponent as IconArrow } from "../../assets/icon-arrow-left.svg"
import { ReactComponent as IconClose } from "../../assets/icon-close.svg"
import { ReactComponent as IconEdit } from "../../assets/icon-primary-edit.svg"
import { ReactComponent as IconMenu } from "../../assets/icon-menu.svg"
import { ReactComponent as IconPlus } from "../../assets/icon-plus.svg"
import { ReactComponent as IconSearch } from "../../assets/icon-search.svg"
import { ReactComponent as IconTrash } from "../../assets/icon-primary-trash.svg"


import VSDrawer from '../vsDrawer/VSDrawer'
import VSCheckbox from '../vsCheckbox/VSCheckbox'
import VSMenu from '../vsMenu/VSMenu'

import './VSMultipleSelectDrawer.scss'
const VSMultipleSelectDrawer = (props) => {

    const {
        title,
        text,
        addText,
        searchTitle,
        searchText,
        hideAllOption,
        value,
        onChange,
        onSearch,
        onDelete,
        onClose,
        FormComponent,
        canCreate,
        canEdit,
        canDelete
    } = props

    const [ isOpen, setIsOpen ] = useState(false)
    const [ loading, setLoading ] = useState(false)
    const [ options, setOptions ] = useState([])
    const [ count, setCount ] = useState(0)
    const [ searchTerm, setSearchTerm ] = useState("")
    const [ optionsSelected, setOptionsSelected ] = useState(value || [])

    const [ showForm, setShowForm ] = useState(false)
    const [ objectForm, setObjectForm ] = useState(null)

    const search = () => {
        setLoading(true)
        setOptions([])
        setCount(0)

        onSearch(searchTerm)
        .then(({ count, options }) => {
            setOptions(options)
            setCount(count)
            setLoading(false)
        })
    }

    useEffect(() => {
        if (JSON.stringify(value) != JSON.stringify(optionsSelected)) {
            setOptionsSelected(value)
        }
    }, [ value ])

    useEffect(() => {
        if (isOpen) {
            search()
        }
    }, [ isOpen, searchTerm ])

    const errors = optionsSelected?.length > 0 ? [] : ["Nenhuma opção selecionada"]

    return (
        <div id="multiple-select-drawer">
            <div className="row align-items-center">
                <div className="col-12">
                    <div className="input-title input-height">
                        { title }
                    </div>
                </div>
                { text && (
                    <div className="col-12">
                        <p className="input-text">{ text }</p>
                    </div>
                )}
            </div>
            { optionsSelected && optionsSelected.length > 0 && (
                <div className="row align-items-center multiple-selected-list">
                    { optionsSelected.map((selected, index) => (
                        <div className="col-auto px-1 mb-2" key={index}>
                            <div className="multiple-selected">
                                { selected.label }
                                <IconClose
                                    width={24}
                                    height={24}
                                    fill="var(--vsprontuario-primary-color)"
                                    onClick={() => {
                                        var newValue = [...value]
                                        let index = newValue.map(opt => opt.value).indexOf(selected.value)
                                        newValue.splice(index, 1)
                                        onChange(newValue)
                                    }} 
                                />
                            </div>
                        </div>
                    ))}
                </div>
            )}
            <div className="row align-items-center">
                <div className="col-auto">
                    <div className="btn-link text-uppercase cursor-pointer px-0" onClick={() => setIsOpen(true)}>
                        <IconAddO width={24} height={24} fill="var(--vsprontuario-primary-color)" />
                        { addText }
                    </div>
                </div>
            </div>
            <VSDrawer
                id="multiple-select-form" 
                open={isOpen}
                width="40%"
                timeout={0}
                transitionDuration={0}
                title={(
                    <div className="row g-3">
                        <div className="col-auto cursor-pointer" onClick={() => setIsOpen(false)}>
                            <IconArrow width={12} height={16} fill="#FFFFFF" className="me-2" />
                        </div>
                        <div className="col ps-0">
                            { searchTitle }
                        </div>
                    </div>
                )}
                cancel={"Cancelar"}
                submit={"Aplicar"}
                errors={errors}
                onSubmit={() => {
                    onChange(optionsSelected || [])
                    return Promise.resolve()
                }}
                onClose={() => {
                    setIsOpen(false)
                    onClose?.()
                }}
                onCancel={() => {
                    setOptionsSelected(value || [])
                    setIsOpen(false)
                }}
                onAfterSave={_ => {
                    setIsOpen(false)
                }}
            >
                <div className="row mb-3">
                    <div className="col">
                        <TextField
                            placeholder={searchText}
                            size="small"
                            variant="outlined"
                            className="input-search"
                            fullWidth
                            value={searchTerm}
                            onChange={({ target: { value }}) => {
                                setSearchTerm(value)
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <IconSearch width="15px" height="20px" fill="#DDDDDD" />
                                    </InputAdornment>
                                )
                            }}
                        />
                    </div>
                    { canCreate?.() && (
                        <div className="col-auto ps-0">
                            <IconPlus
                                width={36}
                                height={36}
                                className="multiple-options-add"
                                fill="var(--vsprontuario-primary-color)"
                                onClick={() => {
                                    setShowForm(true)
                                    setObjectForm(null)
                                }}
                            />
                        </div>
                    )}
                </div>
                { showForm && !objectForm && (
                    <FormComponent
                        object={objectForm}
                        onSave={(_, label) => {
                            setSearchTerm(label)
                            setShowForm(false)
                        }}
                        onCancel={() => setShowForm(false)}
                    />
                )}
                <div className="row align-items-center">
                    <div className="col">
                        { loading ? "Procurando..." : options?.length > 0 ? (options?.length == count ? `Exibindo todos ${ searchTerm ? `os resultados para "${ searchTerm }"` : "" } (${ count })` : `Exibindo ${ options?.length } de ${ count }`) : "Nenhum resultado" }
                    </div>
                    <div className="col-auto">
                        <div
                            className="btn-link text-uppercase text-center cursor-pointer" onClick={() => {
                                setOptionsSelected([])
                                setSearchTerm("")
                            }}
                        >
                            Limpar filtro
                        </div>
                    </div>
                    <div className="col-12">
                        <hr className="my-3" />
                    </div>
                </div>
                { options?.length > 0 ? (
                    <div className="multiple-options-list">
                        { !hideAllOption && (
                            <div className="multiple-options-item">
                                <div className="row align-items-center">
                                    <div className="col">
                                        <VSCheckbox
                                            label={`${optionsSelected?.length == options?.length ? "Desmarcar" : "Marcar"} todos os resultados`}
                                            checked={optionsSelected?.length == options?.length}
                                            onChange={(checked) => {
                                                const allSelected = optionsSelected.length != options.length
                                                let newOptionsSelected = []
                                                if (allSelected) {
                                                    options.map(option => {
                                                        newOptionsSelected.push(option)
                                                    })
                                                }
                                                setOptionsSelected(newOptionsSelected)
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                        { options.map((option, index) => {
                            const canEditOption = canEdit?.(option.value)
                            const canDeleteOption = canDelete?.(option.value)
                            return showForm && objectForm == option.value ? (
                                <FormComponent
                                    key={index}
                                    object={objectForm}
                                    onSave={(_, label) => {
                                        setSearchTerm(label)
                                        setShowForm(false)
                                    }}
                                    onCancel={() => setShowForm(false)}
                                />
                            ) : (
                                <div key={index} className="multiple-options-item">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <VSCheckbox
                                                label={option.label}
                                                position="end"
                                                checked={optionsSelected?.length ? optionsSelected.map(opt => opt.value).indexOf(option.value) != -1 : false}
                                                onChange={(checked) => {
                                                    let newOptionsSelected = [...optionsSelected]
                                                    if (checked) {
                                                        newOptionsSelected.push(option)
                                                    } else {
                                                        let index = newOptionsSelected.map(opt => opt.value).indexOf(option.value)
                                                        newOptionsSelected.splice(index, 1)
                                                    }
                                                    setOptionsSelected(newOptionsSelected)
                                                }}
                                            />
                                        </div>
                                        { (canEditOption || canDeleteOption) && (
                                            <div className="col-auto">
                                                { canEditOption && canDelete ? (
                                                    <VSMenu
                                                        placement="bottom-end"
                                                        openOnHover
                                                        options={[
                                                            {
                                                                menu: (
                                                                    <div className="row">
                                                                        <div className="col-auto">
                                                                            <IconEdit width="24" height="24" fill="var(--vsprontuario-primary-color-dark)" />
                                                                        </div>
                                                                        <div className="col ps-0">
                                                                            Editar
                                                                        </div>
                                                                    </div>
                                                                ),
                                                                onClick: () => {
                                                                    setShowForm(true)
                                                                    setObjectForm(option.value)
                                                                }
                                                            },
                                                            {
                                                                menu: (
                                                                    <div className="row">
                                                                        <div className="col-auto">
                                                                            <IconTrash width="24" height="24" fill="#FF8A00" />
                                                                        </div>
                                                                        <div className="col ps-0">
                                                                            Excluir
                                                                        </div>
                                                                    </div>
                                                                ),
                                                                onClick: () => onDelete(option.value, () => search())
                                                            }
                                                        ]}
                                                    >
                                                        <IconMenu width="24" height="24" fill="var(--vsprontuario-gray-color-light)" />
                                                    </VSMenu>
                                                ) : canEditOption ? (
                                                    <IconEdit
                                                        width="24"
                                                        height="24"
                                                        fill="var(--vsprontuario-primary-color-dark)"
                                                        onClick={() => {
                                                            setShowForm(true)
                                                            setObjectForm(option.value)
                                                        }}
                                                    />
                                                ) : (
                                                    <IconTrash
                                                        width="24"
                                                        height="24"
                                                        fill="#FF8A00"
                                                        onClick={() => {
                                                            onDelete(option.value, () => search())
                                                        }}
                                                    />
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                ) : loading ? (
                    <div className="data-loading">
                        <CircularProgress color="primary" size={48} />
                    </div>
                ) : (
                    <div>Nenhum resultado encontrado</div>
                )}
            </VSDrawer>
        </div>
    )
}

export default VSMultipleSelectDrawer
