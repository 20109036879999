// utils for Supply like getSupply, deleteSupply, getSupplies, etc
import { Filter_Limit } from '../../constants';
import { diacriticSensitive } from '../../utils';

export const getSupplyTransaction = (supplyTransactionId, clinicObject) => {
    return new Promise((resolve, reject) => {
        var querySupplyTransaction = new Parse.Query("MRSupplyTransaction");
        querySupplyTransaction.equalTo("clinic", clinicObject);
        querySupplyTransaction.equalTo("isDeleted", false);
        querySupplyTransaction.get(supplyTransactionId)
        .then(_supply => {
            resolve(_supply)
        })
        .catch(error => {
            console.error(error);
            reject("Ocorreu algum erro ao tentar buscar o insumo")
        })
    })
}

var searchSuppliesWithDelay = null
export const searchSupplies = (supplyName, clinicObject) => {
    return new Promise((resolve, reject) => {
        clearInterval(searchSuppliesWithDelay);
        searchSuppliesWithDelay = setTimeout(() => {
            var querySupplies = new Parse.Query("MRSupply");
            querySupplies.equalTo("clinic", clinicObject);
            querySupplies.equalTo("isDeleted", false);
            querySupplies.matches("supplyName", diacriticSensitive(supplyName), "im");
            querySupplies.limit(100000)

            querySupplies.find()
            .then(_supplies => {
                resolve(_supplies)
            })
            .catch(error => {
                console.error(error);
                reject("Ocorreu algum erro ao tentar buscar a categoria")
            })
        }, 300);
    })
}

let cachedSupplies = [];
export const addSupply = (supply) => {
    if(!cachedSupplies || cachedSupplies.length <= 0){
        return;
    }
    cachedSupplies.push(supply);
}

export const getSupplies = (clinicObject, cached = true) => {
    return new Promise((resolve, reject) => {
        var querySupplies = new Parse.Query("VW_MRSupplyTransaction_stock");
        querySupplies.equalTo("clinic", clinicObject);
        querySupplies.equalTo("active", true);
        querySupplies.equalTo("isDeleted", false);
        querySupplies.include("supply");
        querySupplies.limit(10000000)
        querySupplies.find()
        .then(_stocks => {
            cachedSupplies = []
            _stocks.map((stock, index) => {
                cachedSupplies[index] = stock.get("supply")
                cachedSupplies[index].total = stock.get("total")
            })
            resolve(cachedSupplies)
        })
        .catch(error => {
            console.error(error);
            reject("Ocorreu algum erro ao tentar buscar a categoria")
        })
    })
}

var searchSuppliesByCompanyWithDelay = null;
export const searchSuppliesByCompany = (companyName, clinicObject) => {
    return new Promise((resolve, reject) => {
        clearInterval(searchSuppliesByCompanyWithDelay);
        searchSuppliesByCompanyWithDelay = setTimeout(() => {
            const query = new Parse.Query("VW_MRSupply_companies")
            query.equalTo("clinic", clinicObject)
            query.equalTo("isDeleted", false)
            if (companyName) {
                query.matches("companyName", diacriticSensitive(companyName), "im");
            }
            query.ascending("companyName")
            query.limit(Filter_Limit)
            query.withCount()
            query.find()
            .then(companies => {
                resolve(companies)
            })
            .catch(error => {
                console.error(error);
                reject("Ocorreu algum erro ao tentar buscar o insumo por fabricante")
            })
        }, 300);
    })
}

var searchSuppliesByDistributorWithDelay = null;
export const searchSuppliesByDistributor = (distributor, clinicObject) => {
    return new Promise((resolve, reject) => {
        clearInterval(searchSuppliesByDistributorWithDelay);
        searchSuppliesByDistributorWithDelay = setTimeout(() => {
            const query = new Parse.Query("VW_MRSupply_distributors")
            query.equalTo("clinic", clinicObject)
            query.equalTo("isDeleted", false)
            if (distributor) {
                query.matches("distributor", diacriticSensitive(distributor), "im");
            }
            query.ascending("distributor")
            query.limit(Filter_Limit)
            query.withCount()
            query.find()
            .then(companies => {
                resolve(companies)
            })
            .catch(error => {
                console.error(error);
                reject("Ocorreu algum erro ao tentar buscar o insumo por fabricante")
            })
        }, 300);
    })
}