import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react'

import {
    getStateList,
    showArrayIndex,
    mandatoryField
} from '../../../../utils'

import { ReactComponent as IconAddO } from "../../../../assets/icon-add-o.svg"
import { ReactComponent as IconCloseO } from "../../../../assets/icon-close-o.svg"

import { MenuItem, TextField } from '@mui/material'

const TermRiskForm = forwardRef((props, ref) => {

    const {
        procedures,
        setProcedures,
        vetName,
        setVetName,
        vetCrmv,
        setVetCrmv,
        vetCrmvStateId,
        setVetCrmvStateId,
        notes,
        setNotes
    } = props

    const defaultProcedureWithValidation = {
        procedure: '',
        errorProcedure: false,
        helperProcedure: mandatoryField,
        notes: ''
    }

    const [ procedureRequests, setProcedureRequests ] = useState([])

    const setProcedure = (index, field, value) => {
        let newProcedures = [...procedureRequests]
        newProcedures[index][field] = value
        setProcedureRequests(newProcedures)
    }

    const removeProcedure = (index) => {
        let newProcedures = [...procedureRequests]
        newProcedures.splice(index, 1)
        setProcedureRequests(newProcedures)
    }

    useImperativeHandle(ref, () => ({
        fieldsWithErrorText(errors) {
            if (procedureRequests.length == 0) {
                errors.push("O procedimento é obrigatório para o Termo de Terapia de Risco")
            }
            procedureRequests.map((procedure, index) => {
                if (procedure.errorProcedure) {
                    errors.push(`O procedimento ${showArrayIndex(index + 1)} não foi preenchido`)
                }
            })
            return errors
        },
        isValid() {
            return procedureRequests.length > 0 && procedureRequests.filter(procedure => procedure.errorProcedure).length == 0
        },
        validate(isValid) {
            if (procedureRequests.length == 0) {
                isValid = false
            } else {
                procedureRequests.map((procedure, index) => {
                    if (procedure.procedure) {
                        setProcedure(index, "errorProcedure", false)
                        setProcedure(index, "helperProcedure", mandatoryField)
                    } else {
                        isValid = false
                        setProcedure(index, "errorProcedure", true)
                        setProcedure(index, "helperProcedure", "O nome da terapia é obrigatório")
                    }
                })
            }
            return isValid
        }
    }))

    useEffect(() => {
        setProcedureRequests(procedures.map(procedure => Object.assign({}, { ...defaultProcedureWithValidation }, procedure)))
    }, [])

    useEffect(() => {
        setProcedures(procedureRequests.map(procedure => ({
            procedure: procedure.procedure,
            notes: procedure.notes
        })))
    }, [ JSON.stringify(procedureRequests.map(procedure => ({ procedure: procedure.procedure, notes: procedure.notes }))) ])

    return (
        <div className="row pt-4">
            <div className="col">
                <div className="row mb-1">
                    <div className="col-12">
                        <div className="input-title input-height">
                            Procedimento{ procedureRequests.length == 1 ? "" : "s" } a ser{ procedureRequests.length == 1 ? "" : "em" } realizado{ procedureRequests.length == 1 ? "" : "s" }
                        </div>
                    </div>
                </div>
                { procedureRequests.map((procedure, index) => (
                    <React.Fragment key={index}>
                        <div className="row align-items-center mb-3">
                            <div className="col">
                                <div className="input-array py-1">
                                    Procedimento { showArrayIndex(index + 1) }
                                </div>
                            </div>
                            { procedureRequests.length > 1 && (
                                <div className="col-auto">
                                    <button
                                        className="btn-array px-0"
                                        onClick={() => removeProcedure(index)}
                                    >
                                        <IconCloseO width="24" height="24" />
                                        Remover Procedimento
                                    </button>
                                </div>
                            )}
                        </div>
                        <div className="row align-items-center mb-4">
                            <div className="col-12">
                                <TextField
                                    placeholder={`Digite o nome do Procedimento ${ showArrayIndex(index + 1) }`}
                                    label={`Nome do Procedimento ${ showArrayIndex(index + 1) }`}
                                    onChange={({ target: { value } }) => setProcedure(index, "procedure", value)}
                                    value={procedure.procedure}
                                    error={procedure.errorProcedure}
                                    helperText={procedure.helperProcedure}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                />
                            </div>
                        </div>
                        <div className="row align-items-center mb-4">
                            <div className="col-12">
                                <TextField
                                    label={`Observações do Procedimento ${ showArrayIndex(index + 1) }`}
                                    placeholder="Digite as observações"
                                    value={procedure.notes}
                                    onChange={({ target: { value } }) => setProcedure(index, "notes", value)}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                />
                            </div>
                        </div>
                    </React.Fragment>
                ))}
                <div className="row align-items-center mb-4">
                    <div className="col-12">
                        <button
                            className="btn-add" 
                            onClick={() => setProcedureRequests(procedureRequests.concat({ ...defaultProcedureWithValidation }))}
                        >
                            <IconAddO width="20" height="20" />
                            Adicionar Procedimento { showArrayIndex(procedureRequests.length + 1) }
                        </button>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col">
                        <div className="input-title input-height">
                            Profissional que realizará o{ procedureRequests.length == 1 ? "" : "s" } procedimento{ procedureRequests.length == 1 ? "" : "s" } (opcional)
                        </div>
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-12">
                        <TextField
                            value={vetName}
                            placeholder="Digite Aqui o nome do Profissional"
                            label="Nome do Profissional"
                            onChange={({ target: { value } }) => setVetName(value)}
                            size="small"
                            variant="outlined"
                            fullWidth
                        />
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-6">
                        <TextField
                            type="tel"
                            value={vetCrmv}
                            placeholder="Digite Aqui o CRMV"
                            label="Número CRMV"
                            onChange={({ target: { value } }) => setVetCrmv(value)}
                            size="small"
                            variant="outlined"
                            fullWidth
                        />
                    </div>
                    <div className="col-6">
                        <TextField
                            placeholder="Selecione o Estado"
                            label="Estado do CRMV"
                            value={vetCrmvStateId}
                            onChange={({ target: { value } }) => setVetCrmvStateId(value)}
                            size="small"
                            variant="outlined"
                            select
                            fullWidth
                        >
                            { getStateList().map(stateItem => (
                                <MenuItem key={stateItem.value} value={stateItem.value}>
                                    { stateItem.text }
                                </MenuItem>
                            )) }
                        </TextField>
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-12">
                        <div className="input-title input-height">
                            Observações gerais (opcional)
                        </div>
                    </div>
                    <div className="col-12">
                        <TextField
                            value={notes}
                            placeholder="Escreva aqui as observações gerais sobre o atestado"
                            onChange={({ target: { value } }) => setNotes(value)}
                            size="small"
                            variant="outlined"
                            fullWidth
                            multiline
                        />
                    </div>
                </div>
            </div>
        </div>
    )
})

export default TermRiskForm;