import { TextField } from "@mui/material"

const NotesField = (props) => {

    const {
        notes,
        setNotes
    } = props

    return (
        <div className="row mb-2">
            <div className="col-12">
                <div className="input-subtitle mb-1">
                    Escreva suas observações aqui
                </div>
            </div>
            <div className="col-12">
                <TextField
                    placeholder="Anotações internas sobre a vacinação"
                    value={notes}
                    onChange={({ target: { value } }) => setNotes(value)}
                    size="small"
                    variant="outlined"
                    fullWidth
                    multiline
                />
            </div>
            <div className="col-12 input-info mt-1">
                Campo Opcional. As observações são anotações particulares. <b>Elas nunca serão compartilhadas com o tutor!</b>
            </div>
        </div>
    )
}

export default NotesField