import { connect } from 'react-redux'
import { ReactComponent as IconAddClinic } from "../../assets/icon-add-clinic.svg"
import React, { useEffect, useState } from 'react'

import "./SelectClinic.scss"
import { CircularProgress } from '@mui/material'
// import { getClinic, setClinic, getStore } from '../../utils'
import { createClinic } from '../clinic/Clinic';
import * as utils from '../../utils'
import * as actions from "../../store/actions"
import * as constants from "../../constants"
import Header from '../../components/header/Header'
import BoxSelectClinic from './BoxSelectClinic'

import {
    useLocation
  } from "react-router-dom"

const SelectClinic = (props) => {
	const {
		user,
        clinic,
        history
	} = props

    const hasSelectedClinic = utils.getClinic() != null

	const { state } = useLocation()
	const disableAuto = state?.disableAuto

	const [ loading, setLoading ] = useState(true)
	const [ clinicUsersToSelect, setClinicUsersToSelect ] = useState(null)

	useEffect(() => {
		if (user && user.object && !clinic?.object) {
            // setLoading(false)
            getClinicUserByLocal()
            .then(clinic => {
            	getClinicSettings(clinic)
            })
            .catch(error => {
                getClinicByUser()
            	.then(clinic => {
            		getClinicSettings(clinic)
            	})
            	.catch(error => {
            		console.error(error)
            	})
            })
		} else if(!user || !user.object){
			history.replace(state?.fromURL || "/inicio")
		}
	}, [ user ])

	const getClinicUserByLocal = () => {
		return new Promise((resolve, reject) => {
            let clinic = utils.getClinic()
			if (clinic && clinic.objectId) {
				var query = new Parse.Query("ClinicUser")
				query.equalTo("clinic", clinic)
				query.equalTo("user", Parse.User.current())
				query.equalTo("isDeleted", false)
				query.include("clinic")
				query.include("profile")
				query.include("user")
				query.find()
				.then(clinicUsers => {
					if(!clinicUsers || clinicUsers.length <= 0)
						return reject("not_found")

					const clinicUser = clinicUsers.find(clinicUser => !clinicUser.get('clinic').get('isDeleted'))
					if (clinicUser)
						return resolve(clinicUser)
					
					return reject("not_found")
				})
				.catch(error => {
					console.error("error")
					return reject(error)
				})
			} else {
				console.error("reject")
				return reject({})
			}
		})
	}

	const getClinicSettings = clinicUser => {
		return new Promise((resolve, reject) => {
			if (!clinicUser) {
				var query = new Parse.Query("ClinicUser")
				query.equalTo("email", User.getEmail())
				query.equalTo("isDeleted", false)
				query.notEqualTo("accepted", true)
				query.include("clinic")
				query.include("profile")
				query.include("user")
				return query.find()
				.then(clinicUsers => {
					var hasPendingInvite
					if(clinicUsers && clinicUsers.length > 0)
						hasPendingInvite = clinicUsers.find(clinicUser => !clinicUser.get('clinic').get('isDeleted'))

					// Só cria a clínica se não tem nenhum convite pendente
					if (!hasPendingInvite) {
						const saveUser = Parse.User.current()
						const params =  {
							"user": saveUser.id,
							"createdApp": constants.APP_NAME,
							"createdAppVersion": constants.APP_VERSION,
							"createdDevice": constants.APP_DEVICE,
							"withSampleData": true,
							"clinic": {
								"name": saveUser.get("fullName") || "Minha Clínica"
							}
						}
						return createClinic(params)
						.then(clinic => {
							utils.saveOnTracker("Concluiu", "Cadastro Prontuário", clinic.id)
	
							return getClinicByUser()
							.then(clinic => {
								return getClinicSettings(clinic)
							})
							.catch(error => {
								console.error(error)
								reject("getClinicByUser")
							})
						})
						.catch(message => {
							Swal.fire(
								'Oops...',
								message,
								'error'
							)
							reject("createClinic")
						})
					} else {
						props.dispatch(actions.getSelectedClinicUser(null))
						setLoading(false)
						resolve(null)
						// return getClinicByUser(null)
					}
				})
				.catch(error => {
					console.error(error)
					Swal.fire(
						'Oops...',
						`Não foi possível verificar a clínica`,
						'error'
					)
					return reject("first")
				})
			} else {
				return onClickSelectClinic(clinicUser)
			}
		})
	}

	const getClinicByUser = () => {
		return new Promise((resolve, reject) => {
			var queryUser = new Parse.Query("ClinicUser")
			queryUser.equalTo("user", Parse.User.current())
			
			var queryInvite = new Parse.Query("ClinicUser")
			queryInvite.equalTo("email", user.email)

			var query = Parse.Query.or(queryUser, queryInvite)
			query.notEqualTo("accepted", false)
			query.equalTo("isDeleted", false)
			query.descending("updatedAt")
			query.include("clinic")
			query.include("profile")
			query.include("user")
			query.include("createdBy")
			query.limit(1000)
			query.find()
			.then(clinicUsers => {
				let _clinicUsers = clinicUsers.filter(c => c.get('clinic') && !c.get('clinic').get("isDeleted") && 
															(!c.get('user') || c.get('user').id == Parse.User.current().id))

				_clinicUsers.sort((a, b) => {
                    const aClinic = a.get("clinic")
                    const bClinic = b.get("clinic")
					if(aClinic.get('createdBy').id != bClinic.get('createdBy').id){
						return aClinic.get('createdBy').id == user?.objectId ? -1 :
								bClinic.get('createdBy').id == user?.objectId ? 1 : 0
					}
					if(a.get('accepted') != b.get('accepted')){
						return a.get('accepted') ? -1 : 1
					}
                    return (aClinic.get("name") > bClinic.get("name")) ? 1 : ((bClinic.get("name") > aClinic.get("name")) ? -1 : 0)
                })

				setClinicUsersToSelect(_clinicUsers)
				
				var acceptedClinicUsers = _clinicUsers.filter(clinicUser => clinicUser?.get('accepted'))
				
				if(acceptedClinicUsers.length == 0){
					return resolve(null)
				}

				if(!disableAuto && acceptedClinicUsers.length == 1){
					const clinicUser = acceptedClinicUsers[0]
					const clinic = clinicUser.get("clinic")
	
					utils.setClinic(clinic)
					return resolve(clinicUser)
				}

				setLoading(false)
			})
			.catch(error => {
				return reject(error)
			})
		})
	}

    const onClickSelectClinic = (clinicUser) => {
		utils.getClinicStoreByClinicUser(clinicUser)
		.then(clinicUser => {
            props.dispatch(actions.getSelectedClinicUser(clinicUser))
		})
    }

	const onInviteDeclineConfirmed = () => {
		setClinicUsersToSelect(clinicUsersToSelect.filter(clinicUser => clinicUser.get('accepted') !== false))
	}

	useEffect(() => {
		if (clinic && clinic?.isLoaded && clinic?.object) {
			history.replace(state?.fromURL || "/inicio", {
				...state
			})
		}
	}, [ clinic ])

    return (
		<div id="select-clinic" className="container-fluid">
			<div className="row">
				<div id="children" className="col p-0 text-center">
					<div className="header-container-wrapper">
						<div className="container">
							<Header 
								mobileMenuOpen={false}
								onMenuChange={() => {}} />
						</div>
					</div>
					<div className="container">
						{ loading ? (
							<div className="data-loading pt-5">
								<CircularProgress />
							</div>
						) : 
						!hasSelectedClinic && (
							<div className="row pt-5 mx-0">
								<div className="col text-center">
									<div className="page-title">Em qual clínica você deseja acessar?</div>
									<div className="clinic-users-wrap row mx-auto mt-4">
										{clinicUsersToSelect && clinicUsersToSelect.length > 0 && clinicUsersToSelect.map((clinicUser, index) => (
											<div className={`col-12 col-sm-6 col-lg-4 d-flex flex-column pb-3 ps-2 pe-2`} key={index}>
												<BoxSelectClinic 
													clinicUser={clinicUser}
													onClickSelectClinic={onClickSelectClinic}
													onInviteDeclineConfirmed={onInviteDeclineConfirmed}
													{...props} />
											</div>
										))}
										<div className={`col-12 col-sm-6 col-lg-4 d-flex flex-column pb-3 ps-2 pe-2 align-self-stretch`}>
											<div className="clinic-user-element flex-grow-1 p-2 d-flex" onClick={() => history.push('/clinica/nova')}>
												<div className="create-clinic-wrap">
													<IconAddClinic width="24" height="24" />
													<div className="mt-1">Criar nova clínica</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
    )
}

const mapStateToProps = state => {
    return {
        user: state.user,
        clinic: state.clinic
    }
}
  
export default connect(mapStateToProps)(SelectClinic)