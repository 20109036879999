import React, { useEffect, useRef, useState } from "react"
import { connect } from "react-redux"
import { RRule } from "rrule";
import { useParams, useLocation } from 'react-router-dom';

import { CircularProgress, TextField } from "@mui/material"
import { Autocomplete } from "@mui/lab";

import { ReactComponent as IconAddO } from "../../assets/icon-add-o.svg"
import { ReactComponent as IconCloseO } from "../../assets/icon-close-o.svg"

import { getExpense } from "./Expense"
import { addEvent, dayjs, getSelectedOptionLabel, saveFilesOnParse, saveOnTracker, showArrayIndex } from "../../utils";
import { PAYMENT_BOLETO, paymentOptions } from "../patient/tabs/sale/Sale";
import { ACL, APP_DEVICE, APP_NAME, APP_VERSION } from "../../constants"
import { RECURRENCE_THIS } from "../recurrence/Recurrence";
import { STATUS_FUTURE } from "../pro/Payment";

import VSError from "../../components/vsError/VSError"
import DateInput from "../../components/dateInput/DateInput"
import VSAccordionSimple from "../../components/vsAccordion/VSAccordionSimple"
import VSTextFieldCurrency from "../../components/vsTextFieldCurrency/VSTextFieldCurrency"
import VSDrawer from "../../components/vsDrawer/VSDrawer"
import BoxDropdown from "../../components/boxDropdown/BoxDropdown";
import VSRadio from "../../components/vsRadio/VSRadio";
import VSFileUpload from "../../components/vsFileUpload/VSFileUpload";
import RecurrenceForm from "../recurrence/RecurrenceForm";

import "./ExpenseForm.scss"
const ExpenseForm = (props) => {

    const {
        id_despesa,
        tipo
    } = useParams()

    const {
        state
    } = useLocation()

    const {
        clinic,
        history,
        permissions
    } = props

    const expensePaymentOptions = [
        ...paymentOptions,
        { value: PAYMENT_BOLETO, label: "Boleto" }
    ]

    const isUpdateRecurrence = tipo == "recorrente"
    const isPaymentRecorrency = tipo == "pagar"

    const update = id_despesa != "novo"

    const recurrenceForm = useRef(null)

    const [ loading, setLoading ] = useState(true)
    const [ error, setError ] = useState(null)
    const [ saveLoading, setSaveLoading ] = useState(false)
    const [ expense, setExpense ] = useState(null)
    const [ hasSubmited, setHasSubmited ] = useState(true)

    const index = tipo && state?.index ? state.index : 0

    const date = dayjs().hour(0).minute(0).second(0)
    const [ documentDate, setDocumentDate ] = useState(date.toDate())
    const [ documentDateText, setDocumentDateText ] = useState(date.format("DD/MM/YYYY"))
    const [ errorDocumentDate, setErrorDocumentDate ] = useState(false)
    const [ helperDocumentDate, setHelperDocumentDate ] = useState(<span>Campo <b>Obrigatório</b></span>)

    const [ name, setName ] = useState('')
    const [ errorName, setErrorName ] = useState(false)
    const [ helperName, setHelperName ] = useState(<span>Campo <b>Obrigatório</b></span>)

    const [ value, setValue ] = useState('')
    const [ errorValue, setErrorValue ] = useState(false)
    const [ helperValue, setHelperValue ] = useState(<span>Campo <b>Obrigatório</b></span>)

    const [ activeRecurrence, setActiveRecurrence ] = useState(false)
    const [ errorRecurrence, setErrorRecurrence ] = useState(false)
    const [ helpersRecurrence, setHelpersRecurrence ] = useState([])

    const [ rrule, setRrule ] = useState({})
    const [ recurrenceType, setRecurrenceType ] = useState(RECURRENCE_THIS)

    const [ attachments, setAttachments ] = useState([])

    const [ notes, setNotes ] = useState('')

    const defaultDate = {
        date: date.toDate(),
        dateText: date.format("DD/MM/YYYY")
    }

    const defaultPayment = {
        ...defaultDate,
        type: '',
        value: ''
    }
    const [ payments, setPayments ] = useState([defaultPayment])
    const [ errorPayment, setErrorPayment ] = useState(false)
    const [ helperPayment, setHelperPayment ] = useState("")

    const toFixedFloat = number => number ? parseFloat(Number(number).toFixed(2)) : 0

    const handleDocumentDate = (_date) => {
        setDocumentDateText(_date)

        if (_date.match(/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/g)) {
            let newDate = dayjs(`${_date} 00:00:00`, "DD/MM/YYYY HH:mm:ss")
            if (newDate.isValid()) {
                setDocumentDate(newDate.toDate())
                return
            }
        }
        setDocumentDate(null)
    }

    const setPayment = (index, field, value) => {
        let newPayments = [...payments]
        newPayments[index][field] = value
        if (field == "dateText") {
            const _date = dayjs(`${value} 00:00:00`, "DD/MM/YYYY HH:mm:ss")
            if (value && value.length == 10 && _date.isValid()) {
                newPayments[index]["date"] = _date.toDate()
            } else {
                newPayments[index]["date"] = null
            }
        }
        setPayments(newPayments)
    }

    const removePayment = (index) => {
        let newPayments = [...payments]
        newPayments.splice(index, 1)
        setPayments(newPayments)
    }

    const validateOnTouch = () => {
        if (hasSubmited) {
            validate()
        }
    }

    const validate = () => {
        let isValid = true
        if (loading) {
            isValid = false
        }
        if (saveLoading) {
            isValid = false
        }
        if (error?.message) {
            isValid = false
        }

        const recurrenceFormErrors = recurrenceForm.current?.validate([])
        if (activeRecurrence && recurrenceFormErrors?.length > 0) {
            isValid = false
            setErrorRecurrence(true)
            setHelpersRecurrence(recurrenceFormErrors)
        } else {
            setErrorRecurrence(false)
            setHelpersRecurrence([])
        }

        const _documentDate = dayjs(documentDateText, "DD/MM/YYYY")
        if (_documentDate.isValid()) {
            if (_documentDate.format("YYYY") < 1971) {
                isValid = false
                setErrorDocumentDate(true)
                setHelperDocumentDate("A data não pode ser menor que 1971")
            } else if (_documentDate.diff(dayjs(), "years") > 5) {
                isValid = false
                setErrorDocumentDate(true)
                setHelperDocumentDate("A data não pode exceder 5 anos")
            } else {
                setErrorDocumentDate(false)
                setHelperDocumentDate(<span>Campo <b>Obrigatório</b></span>)
            }
        } else {
            isValid = false
            setErrorDocumentDate(true)
            setHelperDocumentDate("A data informada não é válida")
        }

        if (!name) {
            isValid = false
            setErrorName(true)
            setHelperName("O nome da despesa é obrigatório")
        } else {
            setErrorName(false)
            setHelperName(<span>Campo <b>Obrigatório</b></span>)
        }

        if (!value || value < 0.01) {
            isValid = false
            setErrorValue(true)
            setHelperValue("O valor da despesa é obrigatório")
        } else {
            setErrorValue(false)
            setHelperValue(<span>Campo <b>Obrigatório</b></span>)
        }

        var _errorPaymentMessage = ""
        payments.map((payment, index) => {
            if (payment.value > 0) {
                if (!payment.dateText || payment.dateText.length != 10)
                    _errorPaymentMessage = `A data da Forma de Pagamento ${ showArrayIndex(index + 1) } não é válida`
                else {
                    const _date = dayjs(`${payment.dateText} 00:00:00`, "DD/MM/YYYY HH:mm:ss")
                    if (_date.isValid()) {
                        const minDate = dayjs().subtract(10, 'years')
                        const maxDate = dayjs().add(10, 'years')
                        if (_date < minDate) {
                            _errorPaymentMessage = `A data da Forma de Pagamento ${ showArrayIndex(index + 1) } não pode ser há mais de 10 anos`
                        } else if (_date > maxDate) {
                            _errorPaymentMessage = `A data da Forma de Pagamento ${ showArrayIndex(index + 1) } não pode ser a mais de 10 anos`
                        }
                    }
                }
                if (!payment.type)
                    _errorPaymentMessage = `A Forma de Pagamento ${ showArrayIndex(index + 1) } não é válida`
            }
        })

        if (_errorPaymentMessage) {
            isValid = false
            setErrorPayment(true)
            setHelperPayment(_errorPaymentMessage)
        } else {
            const paid = payments.reduce((total, payment) => total + (payment.value || 0), 0)
            if (paid <= (value || 0)) {
                setErrorPayment(false)
                setHelperPayment("")
            } else {
                isValid = false
                setErrorPayment(true)
                setHelperPayment("O valor pago excede o valor da despesa")
            }
        }

        return isValid
    }

    const isValid = () => !loading && !saveLoading && !errorDocumentDate && !errorName && !errorValue && !errorPayment && !errorRecurrence

    const fieldsWithErrorText = () => {
        let errors = []
        if (loading) {
            errors.push("Estamos procurando a despesa")
        }
        if (saveLoading) {
            errors.push("Salvando a despesa")
        }
        if (error?.message) {
            errors.push(error.message)
        }
        if (errorDocumentDate) {
            errors.push("Data da despesa")
        }
        if (errorName) {
            errors.push("Nome")
        }
        if (errorValue) {
            errors.push("Valor")
        }
        if (errorPayment) {
            errors.push("Formas de pagamento")
        }
        if (errorRecurrence) {
            errors.push("Recorrência da despesa")
        }
        if (!permissions?.check(ACL.OUTFLOW, ACL.Rule.EDIT, expense)) {
            errors.push("Acesso negado")
        }
        return errors
    }

    const sanitizedPayments = payments.map(
        payment => ({
            date: payment.date,
            type: payment.type,
            value: payment.value
        })
    ).filter(p => p.value > 0)

    const save = () => {
        setHasSubmited(true)
        return new Promise(async (resolve, reject) => {
            if (validate()) {
                const recurrence = activeRecurrence ? recurrenceForm.current?.rrule() : undefined
                setSaveLoading(true)

                let attachmentsParse = []
                if(attachments && attachments.length > 0){
                    attachmentsParse = await saveFilesOnParse(attachments)
                }

                Parse.Cloud.run("saveExpenseForClinic", {
                    data: {
                        objectId: update ? expense.id : null,
                        documentDate: documentDate,
                        name: name,
                        value: value,
                        notes: notes,
                        payments: sanitizedPayments,
                        attachments: attachmentsParse,
                        recurrence: recurrence
                    },
                    recurrenceType: recurrenceType,
                    clinicId: clinic.objectId,
                    appVersionName: APP_VERSION,
                    deviceType: APP_DEVICE,
                    appContentType: APP_NAME
                })
                .then(expenses => {
                    saveOnTracker("Concluiu", "Despesa", expenses[0].id)
                    setExpense(expenses[0])
                    addEvent("Expense__getList")
                    resolve(expenses)
                })
                .catch(error => {
                    setSaveLoading(false)
                    console.error(error)
                    Swal.fire(
                        'Desculpe',
                        `Ocorreu algum erro ao tentar ${update ? "editar" : "cadastrar"} a despesa`,
                        'error'
                    )
                    reject()
                })
            } else {
                reject()
            }
        })
    }

    const getExpenseOrError = () => {
        if (permissions?.check(ACL.OUTFLOW, ACL.Rule.VIEW)) {
            if (update) {
                setLoading(true)
                setError(null)

                getExpense(id_despesa, clinic.object)
                .then(_expense => {
                    setExpense(_expense)
                    const date = tipo && state?.documentDate ? state.documentDate : _expense.get("documentDate")
                    handleDocumentDate(dayjs(date).format("DD/MM/YYYY"))
                    setName(tipo && state?.name ? state.name : _expense.get("name"))
                    var _value = tipo && state?.value ? toFixedFloat(state.value) : _expense.get("value")
                    setValue(_value)
                    setNotes(tipo && state?.notes ? state.notes : _expense.get("notes"))
                    setAttachments(tipo && state?.name ? [] : _expense.get("attachments"))
                    if (!isUpdateRecurrence) {
                        const _payments = _expense.get("payments").map(payment => {
                            const date = dayjs(payment.date)
                            return {
                                date: date.toDate(),
                                dateText: date.format("DD/MM/YYYY"),
                                type: payment.type,
                                value: payment.value
                            }
                        })
                        
                        setPayments(_payments)
                    }
                    
                    if (_expense.get("recurrence")) {
                        try {
                            var activeRecurrence = true
                            const rule = RRule.fromString(_expense.get("recurrence"))
                            const allDates = rule.all((_, i) => i <= 100)
                            if (rule?.options?.count) {
                                const currentCount = allDates.findIndex(date => date?.getTime() == _expense.get("documentDate")?.getTime())
                                if (currentCount != -1) {
                                    rule.options.count -= currentCount
                                }
                                if (rule.options.count == 1)
                                    activeRecurrence = false // não exibe recorrência com 0 execuções
                            }
                            if (activeRecurrence) {
                                setRrule(rule?.options)
                                setActiveRecurrence(true)
                            }
                        } catch(error){
                            console.error(error)
                        }
                    }
                    setLoading(false)
                })
                .catch(error => {
                    console.error(error)
                    Swal.fire(
                        'Desculpe',
                        error,
                        'error'
                    )
                    .then(_ => {
                        setLoading(false)
                        setError({
                            message: error,
                            function: "getExpenseOrError"
                        })
                    })
                })
            } else {
                let newExpense = new Parse.Object("MRExpense")
                newExpense.setACL(clinic.ACL)
                newExpense.set("clinic", clinic.object)
                newExpense.set("createdBy", Parse.User.current())
                newExpense.set("createdApp", APP_NAME)
                newExpense.set("createdDevice", APP_DEVICE)
                newExpense.set("createdAppVersion", APP_VERSION)
                newExpense.set("isDeleted", false)
                setExpense(newExpense)
                setLoading(false)
            }
        }
    }

    const getTitle = () => {
        if (isPaymentRecorrency)
            return "Efetuar Pagamento da despesa"
        if (isUpdateRecurrence) 
            return "Editar Despesa Recorrente"
        if (update)
            return "Editar Despesa"
        return "Nova despesa"
    }

    useEffect(() => {
        validateOnTouch()
    }, [ 
        documentDate, documentDateText, name, value, 
        JSON.stringify(payments.map(p => ({ dateText: p.dateText, type: p.type, value: p.value }))) 
    ])

    useEffect(() => {
        if (expense && !permissions?.check(ACL.OUTFLOW, ACL.Rule.EDIT, expense)) {
            permissions?.error()
        }
    }, [ expense ])

    useEffect(() => {
        if (clinic && clinic.object && clinic.isLoaded) {
            getExpenseOrError()
        }
    }, [ clinic ])

    const errors = fieldsWithErrorText()

    return (
        <VSDrawer
            title={getTitle()}
            cancel={update ? "Cancelar Alterações" : <span>Cancelar<span className="d-none d-md-inline"> Nova</span> despesa</span>}
            submit={isValid ? ( update ? "Salvar Alterações" : <span>Criar<span className="d-none d-md-inline"> Nova</span> despesa</span> ) : <span>Preencha os campos<span className="d-none d-md-inline"> necessários</span></span>}
            errors={errors}
            onSubmit={save}
            onCancel={() => history.goBack()}
            VSDrawerSuccessProps={{
                title: update ? "Alterações salvas com sucesso!" : "Despesa registrado com sucesso!" ,
                text: "Escolha uma das opções abaixo para prosseguir",
                VSDrawerFooterProps: {
                    children: (
                        <div className="row">
                            <div className="col pe-2">
                                <button
                                    className="btn btn-success w-100"
                                    onClick={() => history.replace(state?.from == "pagar" ? `/financeiro/pagar` : `/financeiro/saida`)}
                                >
                                    Ir para a aba de despesas
                                </button>
                            </div>
                            <div className="col ps-2">
                                <button
                                    className="btn btn-save w-100"
                                    onClick={() => history.go(0)}
                                >
                                    Cadastrar um nova despesa
                                </button>
                            </div>
                        </div>
                    )
                }
            }}
            VSDrawerCancelProps={{
                title: update ? "Tem certeza que deseja cancelar as alterações na despesa? " : "Tem certeza que deseja cancelar o nova despesa?",
                confirm: update ? "Cancelar  Alterações" : "Cancelar Nova despesa",
                cancel: update ? "Voltar para Edição da despesa" : "Voltar para Cadastro da despesa"
            }}
        >
            { loading ? (
                <div className="row data-loading">
                    <div className="col">
                        <CircularProgress />
                    </div>
                </div>
            ) : error?.message ? (
                <VSError
                    message={error.message}
                    onClose={() => {
                        switch(error.function) {
                            case "getExpenseOrError":
                                getExpenseOrError()
                                break
                            default:
                        }
                    }}
                />
            ) : (
                <>
                    <div className="row">
                        <div className="col-auto pe-0">
                            <div className="input-title input-height">
                                Data da despesa
                            </div>
                        </div>
                        <div className="col-auto" style={{ width: '182px' }}>
                            <DateInput
                                className="m-0"
                                placeholder="00/00/0000"
                                value={documentDateText || null}
                                error={errorDocumentDate}
                                helperText={null}
                                onChange={(value) => handleDocumentDate(value)}
                                size="small"
                                variant="outlined"
                                fullWidth
                            />
                        </div>
                        { errorDocumentDate && (
                            <div className="col-12">
                                <small className="text-danger">{ helperDocumentDate }</small>
                            </div>
                        )}
                    </div>
                    <div className="row mt-2">
                        <div className="col">
                            <VSAccordionSimple
                                expanded={true}
                                header={(_) => (
                                    "Dados da despesa"
                                )}
                            >
                                <div className="row g-3 align-items-center">
                                    <div className="col-12 col-sm-7 col-md-8">
                                        <TextField
                                            placeholder="Nome da despesa"
                                            label="Nome da despesa"
                                            value={name}
                                            onChange={({ target: { value } }) => setName(value)}
                                            error={errorName}
                                            helperText={helperName}
                                            size="small"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </div>
                                    <div className="col-12 col-sm-5 col-md-4">
                                        <VSTextFieldCurrency
                                            value={(value || 0).toFixed(2)}
                                            onChangeValue={(event, originalValue, maskedValue) => {
                                                // parseInt prevents 1.00000001 bug
                                                setValue(toFixedFloat(originalValue)) 
                                            }}
                                            InputElement={(
                                                <TextField
                                                    InputProps={{
                                                        inputProps: { min: 0 }
                                                    }}
                                                    placeholder="R$ 0,00"
                                                    label="Valor da despesa"
                                                    error={errorValue}
                                                    helperText={helperValue}
                                                    size="small"
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                            </VSAccordionSimple>
                        </div>
                    </div>
                    { !isUpdateRecurrence && (
                        <>
                            <hr className="my-2" />
                            <div className="row mt-2">
                                <div className="col">
                                    <VSAccordionSimple
                                        expanded={true}
                                        header={(_) => (
                                            "Formas de Pagamento"
                                        )}
                                    >
                                        { payments.map((payment, index) => (
                                            <React.Fragment key={index}>
                                                <div className="row align-items-center mb-3">
                                                    <div className="col">
                                                        <div className="input-array py-1">
                                                            Pagamento { showArrayIndex(index + 1) }
                                                        </div>
                                                    </div>
                                                    { payments.length > 1 && (
                                                        <div className="col-auto">
                                                            <button className="btn-array" onClick={() => removePayment(index)}>
                                                                <IconCloseO width="24" height="24" />
                                                                Remover pagamento
                                                            </button>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="row g-3 align-items-center mb-3">
                                                    <div className="col-12 order-0">
                                                        <div className="input-subtitle mb-1">
                                                            Informações do Pagamento { showArrayIndex(index + 1) }
                                                        </div>
                                                    </div>
                                                    <div className="col-auto order-1">
                                                        <div className="row align-items-center" style={{ maxWidth: '180px' }}>
                                                            <div className="col-auto">
                                                                <DateInput
                                                                    placeholder="00/00/0000"
                                                                    value={payment.dateText || null}
                                                                    minDate={null}
                                                                    maxDate={null}
                                                                    onChange={(value) => {
                                                                        setPayment(index, "dateText", value)
                                                                    }}
                                                                    size="small"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6 order-3 order-md-2">
                                                        <Autocomplete
                                                            value={payment.type}
                                                            options={expensePaymentOptions}
                                                            getOptionLabel={(option) => getSelectedOptionLabel(option, expensePaymentOptions, payment.type)}
                                                            onChange={(_, selected) => setPayment(index, "type", selected?.value || '')}
                                                            onInputChange={(_, selected) => setPayment(index, "type", selected || '')}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    className="w-100"
                                                                    placeholder={`Selecione ou Digite a Forma de Pagamento ${ showArrayIndex(index + 1) }`}
                                                                    size="small"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                />
                                                            )}
                                                            openOnFocus
                                                            freeSolo
                                                        />
                                                    </div>
                                                    <div className="col order-2 order-md-3">
                                                        <VSTextFieldCurrency
                                                            value={payment.value}
                                                            onChangeValue={(event, originalValue, maskedValue) => {
                                                                setPayment(index, "value", originalValue)
                                                            }}
                                                            InputElement={(
                                                                <TextField
                                                                    placeholder="R$ 0,00"
                                                                    label="Valor Pago"
                                                                    size="small"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                />
                                                            )}
                                                        />
                                                    </div>
                                                    
                                                </div>
                                            </React.Fragment>
                                        )) }
                                        { errorPayment && (
                                            <p className="text-danger">
                                                { helperPayment }
                                            </p>
                                        )}
                                        <div className="row align-items-center mb-4">
                                            <div className="col-12">
                                                <button className="btn-add" onClick={() => setPayments(payments.concat(defaultPayment))}>
                                                    <IconAddO width="20" height="20" />
                                                    Adicionar Pagamento { showArrayIndex(payments.length + 1) }
                                                </button>
                                            </div>
                                        </div>
                                    </VSAccordionSimple>
                                </div>
                            </div>
                        </>
                    )}
                    { !isPaymentRecorrency && (
                        <>
                            <hr className="my-2" />
                            <div className="row mb-2">
                                <div className="col-12">
                                    <VSAccordionSimple
                                        expanded={true}
                                        header={(expanded) => "Recorrência da despesa"}
                                    >
                                        <div className="row align-items-center mb-2">
                                            <div className="col-12">
                                                <p className="mb-4">
                                                    Configure uma recorrência para essa despesa. Ele será lançado automaticamente em seu saldo de 
                                                    acordo com a frequência que você escolher.
                                                </p>
                                            </div>
                                            { rrule.freq >= 0 && (
                                                <>
                                                    <div className="col-12">
                                                        <div className="input-title input-height">
                                                            Essa despesa possui lançamentos futuros
                                                        </div>
                                                    </div>
                                                    <div className="col-12 mb-3">
                                                        <VSRadio
                                                            value={recurrenceType}
                                                            onChange={(_, value) => {
                                                                setRecurrenceType(value)
                                                            }}
                                                            options={[
                                                                {
                                                                    label: "Desejo alterar apenas esse lançamento",
                                                                    value: RECURRENCE_THIS
                                                                },
                                                                {
                                                                    label: "Desejo alterar esse e os lançamentos futuros",
                                                                    value: STATUS_FUTURE
                                                                }
                                                            ]}
                                                        />
                                                    </div>
                                                </>
                                            )}
                                            { rrule.freq >= 0 && recurrenceType == RECURRENCE_THIS ? (
                                                <div className="col-12">
                                                    <p className="text-warning">Não é possível alterar a recorrência de um único lançamento</p>
                                                </div>
                                            ) : (
                                                <div className="col-12 mb-3">
                                                    <BoxDropdown
                                                        title="Definir recorrência para essa despesa"
                                                        checked={activeRecurrence}
                                                        onChecked={(checked) => setActiveRecurrence(checked)}
                                                    >
                                                        <RecurrenceForm
                                                            ref={recurrenceForm}
                                                            documentDate={documentDate}
                                                            finishDate={expense.get("finishDate")}
                                                            rrule={rrule}
                                                            onChange={validate}
                                                        />
                                                        { errorRecurrence && (
                                                            <p className="text-error">
                                                                { helpersRecurrence.map((helper, index) => <span key={index}>{ index !== 0 ? <br/> : "" }{ helper }</span>) }
                                                            </p>
                                                        )}
                                                    </BoxDropdown>
                                                </div>
                                            )}
                                        </div>
                                    </VSAccordionSimple>
                                </div>
                            </div>
                        </>
                    )}
                    <hr className="my-2" />
                    <div className="row">
                        <div className="col">
                            <VSAccordionSimple
                                expanded={true}
                                header={(_) => (
                                    "Dados complementares"
                                )}
                            >
                                <div className="row mb-3">
                                    <div className="col-12">
                                        <TextField
                                            placeholder="Digite as observações sobre a despesa"
                                            value={notes}
                                            onChange={({ target: { value } }) => setNotes(value)}
                                            helperText={<span>Campo Opcional<br/></span>}
                                            size="small"
                                            variant="outlined"
                                            multiline
                                            fullWidth
                                        />
                                    </div>
                                    <div className="col input-info">
                                        As observações são anotações particulares.
                                    </div>
                                </div>
                                { !isUpdateRecurrence && (
                                    <div className="row">
                                        <div className="col-12 col-photo">
                                            <VSFileUpload
                                                onChange={(attachments) => setAttachments(attachments)}
                                                value={attachments}
                                            >
                                                Adicionar comprovante(s) da<br/>
                                                despesa ou de pagamento
                                            </VSFileUpload>
                                        </div>
                                    </div>
                                )}
                            </VSAccordionSimple>
                        </div>
                    </div>
                </>
            )}
        </VSDrawer>
    )
}

const mapStateToProps = state => {
    return {
        clinic: state.clinic,
        permissions: state.permissions
    }
}
  
export default connect(mapStateToProps)(ExpenseForm)