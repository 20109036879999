import React, { useState } from 'react'
import { connect } from 'react-redux'

import { TableCell, TableRow } from '@mui/material'

import { getSpecieIcon } from '../patient/Patient'
import HomeData from './HomeData'
import { dayjs } from '../../utils'

import './HomeLastPatients.scss'
const HomeLastPatients = (props) => {

    const {
        clinic,
        expanded,
        history
    } = props

    const [ loading, setLoading ] = useState(true)
    const [ patients, setPatients ] = useState([])

    const rowsPerPage = 5

    return (
        <div className="col-12 mb-3">
            <HomeData
                expanded={expanded}
                title="Últimos Cadastros de Animais"
                emptyText="Você ainda não possui cadastros de Animais registrados"
                loading={loading}
                data={patients}
                rowsPerPage={rowsPerPage}
                onChange={(page) => {
                    if (clinic && clinic.isLoaded && clinic.object) {
                        setPatients([])
                        setLoading(true)

                        const query = new Parse.Query("Patient");
                        query.equalTo("isDeleted", false)
                        query.equalTo("clinic", clinic.object)
                        query.descending("createdAt")
                        query.include("owner")
                        query.limit(15)
                        query.find()
                        .then(_patients => {
                            setPatients({
                                count: _patients?.length || 0,
                                results: _patients || []
                            })
                            setLoading(false)
                        })
                        .catch(error => {
                            console.error(error)
                            setLoading(false)
                        })
                    }
                }}
                renderItem={(patient, index) => {
                    const owner = patient?.get("owner")
                    return (
                        <TableRow
                            key={index}
                        >
                            <TableCell
                                style={{ width: '24px' }}
                            >
                                { getSpecieIcon(patient?.get("specie"), { width: "24px", height: "24px", fill: "#B3B3B3" }) }
                            </TableCell>
                            <TableCell className="px-2">
                                <div className="text-link d-inline" onClick={() => history.push(`/animal/${patient?.id}`)}>
                                    { patient?.get("name") }
                                </div>
                                &nbsp;para&nbsp;
                                <div className="text-link d-inline" onClick={() => history.push(`/tutor/${owner?.id}`)}>
                                    { owner?.get("name") }
                                </div>
                            </TableCell>
                            <TableCell
                                style={{ width: '86px' }}
                            >
                                <div className="text-truncate">
                                    { dayjs(patient?.get("createdAt")).format("DD/MM/YYYY") }
                                </div>
                            </TableCell>
                        </TableRow>
                    )
                }}
                footer={(
                    <div className="col">
                        <div onClick={() => history.push('/animal')} className="btn-outline-submit btn-square cursor-pointer">
                            Ver Cadastros de Animais
                        </div>
                    </div>
                )}
            />
        </div>
    )
}

const mapStateToProps = state => {
    return {
        clinic: state.clinic
    };
};
  
export default connect(mapStateToProps)(HomeLastPatients);