import React, { useRef, useState, useEffect } from "react"
import { connect } from "react-redux"

import { ReactComponent as IconAdd } from "../../assets/icon-primary-add-o.svg"
import { ReactComponent as IconPatientBalance } from '../../assets/icon-patient-weight.svg'

import { ACL, OrderBy_Oldest, OrderBy_Newest } from "../../constants"
import { paymentOptions } from "../patient/tabs/sale/Sale"
import { searchServices } from "../service/Service"
import { queryForSearchByPatientAndOwner } from "../patient/Patient"
import { dayjs, diacriticSensitive } from "../../utils"

import VSEmpty from "../../components/vsEmpty/VSEmpty"
import VSList from "../../components/vsList/VSList"
import VSAccordionSimple from "../../components/vsAccordion/VSAccordionSimple"
import CashInflowView from "./CashInflowView"
import CardFinancialView from "./CardFinancialView"
import VSTimeline from "../../components/vsTimeline/VSTimeline"
import VSBoxPatient from "../../components/cardPatient/VSBoxPatient"
import CardFeedback from "../../components/cardFeedback/CardFeedback"

import "./CashInflowList.scss"
const CashInflowList = (props) => {

    const {
        clinic,
        history,
        permissions
    } = props
    
    const rowsPerPage = 5

    const filter = useRef(null)
    const isMounted = useRef(null)

    const [ paymentsByPatient, setPaymentsByPatient ] = useState({})

    const [ term, setTerm ] = useState("")
    const [ dateFrom, setDateFrom ] = useState(null)
    const [ dateTo, setDateTo ] = useState(null)
    const [ statusSale, setStatusSale ] = useState("0")
    const [ paymentTypes, setPaymentTypes ] = useState([])
    const [ services, setServices ] = useState([])

    const getSale = (searchTerm, dateFrom, dateTo, orderBy, page = 1) => {
        return new Promise((resolve, reject) => {
            let filters = {
                isDeleted: false,
                paymentsValue: {
                    $gt: 0
                }
            }
            if (services.length > 0) {
                filters["services.objectId"] = {
                    $in: services.map(service => service.value)
                }
            }
            if (paymentTypes.length > 0) {
                filters.payment_item_type = {
                    $in: paymentTypes.map(payment => payment.value)
                }
            }
            if (statusSale == "1") {
                filters.paymentsPending = 0
            } else if (statusSale == "2") {
                filters.paymentsPending = {
                    $gt: 0
                }
            }
            Parse.Cloud.run("getPeriodForClass", {
                clinicId: clinic.objectId,
                className: "VW_MRSale_payments",
                filters: filters,
                searchByPatientAndOwner: diacriticSensitive(searchTerm),
                dateFrom: dateFrom,
                dateTo: dateTo,
                orderBy: "payment_item_date",
                order: orderBy == OrderBy_Newest ? "newest" : "oldest",
                page: page
            })
            .then(({ total, from, to }) => {
                const query = new Parse.Query("VW_MRSale_payments")
                query.greaterThan("paymentsValue", 0);
                query.equalTo("clinic", clinic.object);
                query.equalTo("isDeleted", false);
                const queryPatient = queryForSearchByPatientAndOwner(searchTerm, clinic.object)
                if (queryPatient) {
                    query.matchesQuery("patient", queryPatient)
                }
                if (services.length > 0) {
                    const queryServices = services.map(service => service.value)
                    query.containedIn("services.objectId", queryServices)
                }
                if (paymentTypes.length > 0) {
                    const queryPaymentTypes = paymentTypes.map(payment => payment.value)
                    query.containedIn("payment_item_type", queryPaymentTypes)
                }
                if (statusSale == "1") {
                    query.equalTo("paymentsPending", 0)
                } else if (statusSale == "2") {
                    query.notEqualTo("paymentsPending", 0)
                }
                query.greaterThanOrEqualTo("payment_item_date", from)
                query.lessThanOrEqualTo("payment_item_date", to)
                switch (orderBy) {
                    case OrderBy_Oldest:
                        query.ascending("payment_item_date");
                        break;
                    default:
                        query.descending("payment_item_date");
                }
                query.include("patient")
                query.include("patient.owner")
                query.include("originalMRSale")
                query.include("createdBy")
                query.include("changedBy")
                query.find()
                .then(results => {
                    if (results && isMounted.current) {
                        let paymentsByDate = {}
                        let dates = []
                        results.map((payment, index) => {
                            const date = dayjs(payment.get("payment_item_date")).format("DD/MM/YYYY")
                            const patient = payment.get("patient")
                            if (!paymentsByDate[date]) {
                                dates.push(date)
                                paymentsByDate[date] = []
                            }
                            if (!paymentsByDate[date][patient.id]) {
                                paymentsByDate[date][patient.id] = {
                                    patient: patient,
                                    payments: [payment]
                                }
                            } else {
                                paymentsByDate[date][patient.id].payments.push(payment)
                            }
                        })
                        setPaymentsByPatient(paymentsByDate)
                        resolve({
                            items: dates,
                            total: total
                        })
                    }
                })
                .catch(error => {
                    console.error(error)
                    return Promise.reject(error)
                })
            })
        })
    }

    const onChange = () => {
        if (filter.current) {
            filter.current.onChange()
        }
    }

    useEffect(() => {
        if (permissions && !permissions.check(ACL.INFLOW, ACL.Rule.VIEW)) {
            permissions.error()
        }
    }, [ permissions ])

    useEffect(() => {
        isMounted.current = true
        return () => { 
            isMounted.current = false
        }
    }, [])

    return (
        <>
            <div id="cash-inflow-list" className="flex-grow-1">
                <div className="col-12">
                    <VSList
                        ref={filter}
                        title={{
                            text: "Entrada de Caixa"
                        }}
                        button={permissions?.check(ACL.SALE, ACL.Rule.EDIT) && {
                            text: "Criar Nova Venda",
                            onClick: () => history.push(`/animal/selecionar/venda`, { redirectTo: history.location?.pathname })
                        }}
                        search={{
                            text: "Buscar por Nome do Animal ou Tutor(a)",
                            value: ""
                        }}
                        interval={{
                            start: {
                                text: "De"
                            },
                            end: {
                                text: "Até"
                            }
                        }}
                        order={{
                            options: [
                                OrderBy_Newest,
                                OrderBy_Oldest
                            ],
                            value: OrderBy_Newest
                        }}
                        VSFilterProps={{
                            title: `Filtrar Resultados`,
                            onClear: () => {
                                
                            },
                            onFilter: () => {
                                onChange()
                            },
                            filters: [
                                {
                                    title: "Estado da Venda",
                                    defaultValue: "0",
                                    value: statusSale,
                                    onChange: (value) => {
                                        setStatusSale(value)
                                    },
                                    horizontal: true,
                                    options: [
                                        { value: "0", label: "Todos" },
                                        { value: "1", label: "Pagamento total" },
                                        { value: "2", label: "Pagamento parcial" },
                                    ]
                                },
                                {
                                    title: "Serviços",
                                    defaultValue: [],
                                    value: services,
                                    onChange: (values) => {
                                        setServices(values)
                                    },
                                    onSearch: (term) => {
                                        return new Promise((resolve, reject) => {
                                            searchServices(term, clinic.object)
                                            .then(({ results, count }) => {
                                                if (results) {
                                                    const options = results.map(service => {
                                                        const id = service.get("serviceId") ? `ID ${ service.get("serviceId") } - ` : ""
                                                        return {
                                                            value: service.id,
                                                            label: `${ id }${service.get("serviceName")}`
                                                        }
                                                    })
                                                    return resolve({
                                                        count: count,
                                                        options: options
                                                    })
                                                }
                                                return resolve([])
                                            })
                                            .catch(error => {
                                                console.error(error)
                                                return resolve([])
                                            })
                                        })
                                    },
                                    options: [],
                                    multiple: {
                                        title: "Serviços",
                                        button: "Selecione um serviço",
                                        placeholder: "Procure pelo serviço"
                                    }
                                },
                                {
                                    title: "Formas de pagamento",
                                    defaultValue: [],
                                    value: paymentTypes,
                                    onChange: (values) => {
                                        setPaymentTypes(values)
                                    },
                                    options: paymentOptions,
                                    multiple: true
                                }
                            ].filter(val => val)
                        }}
                        appendFilter={(
                            <div className="mt-3">
                                <CardFinancialView 
                                    clinic={clinic}
                                    searchTerm={term}
                                    dateFrom={dateFrom}
                                    dateTo={dateTo}
                                    status={statusSale}
                                    paymentTypes={paymentTypes}
                                    services={services}
                                />
                            </div>
                        )}
                        pagination={{
                            rowsPerPage: rowsPerPage
                        }}
                        onChange={({ _searchBy, _start, _end, _orderBy, _page }) => {
                            setTerm(_searchBy)
                            setDateFrom(_start)
                            setDateTo(_end)
                            return getSale(_searchBy, _start, _end, _orderBy, _page)
                        }}
                        renderItem={(item, expanded) => {
                            const date = dayjs(item, "DD/MM/YYYY")
                            return (
                                <VSTimeline
                                    day={ date.format("DD") }
                                    month={ date.format("MMM") }
                                    year={ date.format("YYYY") }
                                >
                                    { paymentsByPatient && paymentsByPatient[item] && Object.keys(paymentsByPatient[item]).map((key, index) => {
                                        const data = paymentsByPatient[item][key]
                                        const patient = data.patient
                                        const payments = data.payments
                                        return (
                                            <React.Fragment key={index}>
                                                <VSAccordionSimple
                                                    expanded={true}
                                                    className="custom-accordion"
                                                    hideIcon
                                                    header={(expanded) => (
                                                        <div className={`vstimeline-item vstimeline-active w-100`}>
                                                            <VSBoxPatient
                                                                count={payments.length}
                                                                patient={patient}
                                                                expanded={expanded}
                                                            />
                                                        </div>
                                                    )}
                                                >
                                                    { payments && payments.map((data, index) => {
                                                        return (
                                                            <div key={index} className={`${data.get("isDeleted") ? "vstimeline-item vstimeline-inactive" : ""}`}>
                                                                <CashInflowView
                                                                    object={data}
                                                                    expanded={expanded}
                                                                    history={history}
                                                                    updateList={onChange}
                                                                    noDocumentDate
                                                                    noDocumentType
                                                                    noAudit
                                                                />
                                                            </div>
                                                        )
                                                    }) }
                                                </VSAccordionSimple>
                                            </React.Fragment>
                                        )
                                    }) }
                                </VSTimeline>
                            )
                        }}
                        renderEmpty={() => (
                            <VSEmpty
                                icon={<IconPatientBalance width="32px" height="32px" />}
                                title={`Você ainda não possui nenhuma venda`}
                                text={`Cadastre a primeira venda e adicione um pagamento para visulizá-lo aqui`}
                            >
                                <button
                                    id="new-sale-link"
                                    className="vsbox-btn vsbox-btn-primary"
                                    onClick={() => history.push(`/animal/selecionar/venda`, { redirectTo: history.location?.pathname })}
                                >
                                    <IconAdd width="14" height="14" className="me-2" />
                                    Cadastrar nova venda
                                </button>
                            </VSEmpty>
                        )}
                        renderNotFound={() => (
                            <VSEmpty
                                icon={<IconPatientBalance width="32px" height="32px" />}
                                title="Não encontramos nenhum resultado para a sua busca"
                                text="Tente refazer sua busca utilizando outro período ou crie um novo cadastro utilizando o botão abaixo"
                            >
                                <button
                                    id="new-sale-link"
                                    className="vsbox-btn vsbox-btn-primary"
                                    onClick={() => history.push(`/animal/selecionar/venda`, { redirectTo: history.location?.pathname })}
                                >
                                    <IconAdd width="14" height="14" className="me-2" />
                                    Cadastrar nova venda
                                </button>
                            </VSEmpty>
                        )}
                    />
                </div>
            </div>
            {paymentsByPatient && Object.keys(paymentsByPatient).length > 0 && (
                <CardFeedback contentDetail={history.location?.pathname || "CashInflowList"} />
            )}
        </>
    )
}

const mapStateToProps = state => {
    return {
        clinic: state.clinic,
        permissions: state.permissions
    }
}
  
export default connect(mapStateToProps)(CashInflowList)