import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'

import { Switch, TextField } from '@mui/material';
import TermVaccineForm from './TermVaccineForm';
import { showArrayIndex, validateDate, mandatoryField, dayjs } from '../../../../utils';

import { ReactComponent as IconAddO } from "../../../../assets/icon-add-o.svg"
import { ReactComponent as IconCloseO } from "../../../../assets/icon-close-o.svg"
import DateInput from '../../../../components/dateInput/DateInput';
import { defaultDeworming } from './Term';

const TermHealthForm = forwardRef((props, ref) => {

    const {
        patient,
        vaccineNotes,
        setVaccineNotes,
        vaccines,
        setVaccines,
        includeVaccines,
        setIncludeVaccines,
        dewormingNotes,
        setDewormingNotes,
        dewormings,
        setDewormings,
        includeDewormings,
        setIncludeDewormings,
        otherInfo,
        setOtherInfo,
        notes,
        setNotes
    } = props

    const defaultDewormingWithValidations = Object.assign(defaultDeworming, {
        errorProductName: false,
        helperProductName: mandatoryField,
        errorDocumentDate: false,
        helperDocumentDate: mandatoryField,
    })

    const vaccineRef = useRef(null)

    const [ dewormingsForm, setDewormingsForm ] = useState([{ ...defaultDewormingWithValidations }])

    const setDeworming = (index, field, value) => {
        let newDewormings = [...dewormingsForm]
        newDewormings[index][field] = value
        setDewormingsForm(newDewormings)
    }

    const removeDeworming = (index) => {
        let newDewormings = [...dewormingsForm]
        newDewormings.splice(index, 1)
        setDewormingsForm(newDewormings)
    }

    useImperativeHandle(ref, () => ({
        fieldsWithErrorText(errors) {
            if (includeVaccines) {
                errors = vaccineRef.current ? vaccineRef.current.fieldsWithErrorText(errors) : errors
            }
            if (includeDewormings) {
                dewormingsForm.map((deworming, index) => {
                    if (deworming.errorProductName || deworming.errorDocumentDate) {
                        errors.push(`A everminação ${showArrayIndex(index + 1)} possui campos com erro de preenchimento`)
                    }
                })
            }
            return errors
        },
        isValid() {
            if (includeVaccines) {
                return vaccineRef.current ? vaccineRef.current.isValid() : true
            }
            if (includeDewormings) {
                return dewormingsForm.filter(deworming => deworming.errorProductName || deworming.errorDocumentDate).length == 0
            }
            return true
        },
        validate(isValid) {
            if (includeVaccines) {
                isValid = vaccineRef.current ? vaccineRef.current.validate(isValid) : isValid
            }
            if (includeDewormings) {
                dewormingsForm.map((deworming, index) => {
                    if (deworming.productName) {
                        setDeworming(index, "errorProductName", false)
                        setDeworming(index, "helperProductName", mandatoryField)
                    } else {
                        isValid = false
                        setDeworming(index, "errorProductName", true)
                        setDeworming(index, "helperProductName", "O nome do produto da everminação é obrigatório")
                    }
                    if (deworming.documentDate) {
                        setDeworming(index, "errorDocumentDate", false)
                        setDeworming(index, "helperDocumentDate", mandatoryField)
                    } else {
                        isValid = false
                        setDeworming(index, "errorDocumentDate", true)
                        setDeworming(index, "helperDocumentDate", "A data de aplicação da everminação é obrigatória")
                    }
                })
            }
            return isValid
        }
    }))

    useEffect(() => {
        if ((vaccines && vaccines.filter(v => v.vaccine).length > 0) || vaccineNotes) {
            setIncludeVaccines(true)
        }
        if (dewormings && dewormings.filter(d => d.productName).length > 0) {
            setIncludeDewormings(true)
            setDewormingsForm(
                dewormings.map(deworming => (
                    Object.assign(
                        {},
                        { defaultDewormingWithValidations },
                        {
                            productName: deworming.productName,
                            documentDate: deworming.documentDate,
                            documentDateText: deworming.documentDate ? dayjs(deworming.documentDate).format("DD/MM/YYYY") : "",
                        }
                    )
                ))
            )
        }
        return () => {
            setIncludeDewormings(false)
            setIncludeVaccines(false)
        }
    }, [])

    useEffect(() => {
        setDewormings(
            dewormingsForm.map(deworming => ({
                productName: deworming.productName,
                documentDate: deworming.documentDate,
            }))
        )
    }, [ 
        includeDewormings, 
        JSON.stringify(dewormingsForm.map(d => ({ productName: d.productName, documentDate: d.documentDate, errorDocumentDate: d.errorDocumentDate })))
    ])

    return (
        <div className="row pt-4">
            <div className="col">
                <div className="row mb-1">
                    <div className="col">
                        <div className="input-title input-height">
                            Incluir informações sobre vacinação (opcional)
                        </div>
                    </div>
                    <div className="col-auto">
                        <Switch
                            checked={includeVaccines}
                            onChange={({ target: { checked } }) => setIncludeVaccines(checked)}
                            color="primary"
                        />
                    </div>
                </div>
                { includeVaccines && (
                    <div className="row">
                        <div className="col">
                            <TermVaccineForm
                                ref={vaccineRef}
                                patient={patient}
                                setIncludeVaccines={setIncludeVaccines}
                                vaccineNotes={vaccineNotes}
                                setVaccineNotes={setVaccineNotes}
                                vaccines={vaccines}
                                setVaccines={setVaccines}
                                healthTerm
                            />
                        </div>
                    </div>
                )}
                <div className="row mb-1">
                    <div className="col">
                        <div className="input-title input-height">
                            Incluir informações sobre everminações (opcional)
                        </div>
                    </div>
                    <div className="col-auto">
                        <Switch
                            checked={includeDewormings}
                            onChange={({ target: { checked } }) => setIncludeDewormings(checked)}
                            color="primary"
                        />
                    </div>
                </div>
                { includeDewormings && (
                    <>
                        { dewormingsForm.map((deworming, index) => (
                            <React.Fragment key={index}>
                                <div className="row align-items-center mb-3">
                                    <div className="col">
                                        <div className="input-array py-1">
                                            Dados da everminação { showArrayIndex(index + 1) }
                                        </div>
                                    </div>
                                    { dewormings.length > 1 && (
                                        <div className="col-auto px-0">
                                            <button
                                                className="btn-array px-0"
                                                onClick={() => removeDeworming(index)}
                                            >
                                                <IconCloseO width="24" height="24" />
                                                Remover Everminação
                                            </button>
                                        </div>
                                    )}
                                </div>
                                <div className="row align-items-center mb-4">
                                    <div className="col-12 col-lg-6">
                                        <TextField
                                            label={`Nome do Produto ${ showArrayIndex(index + 1) }`}
                                            value={deworming.productName}
                                            onChange={({ target: { value } }) => setDeworming(index, "productName", value)}
                                            error={deworming.errorProductName}
                                            helperText={deworming.helperProductName}
                                            size="small"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </div>
                                    <div className="col-12 col-lg-6">
                                        <DateInput
                                            className="w-100 my-0"
                                            placeholder="00/00/0000"
                                            label="Data de Aplicação"
                                            size="small"
                                            variant="outlined"
                                            value={deworming.documentDateText || null}
                                            error={deworming.errorDocumentDate}
                                            helperText={deworming.helperDocumentDate}
                                            onChange={(value) => {
                                                setDeworming(index, "documentDate", validateDate(value))
                                                setDeworming(index, "documentDateText", value)
                                            }}
                                        />
                                    </div>
                                </div>
                            </React.Fragment>
                        ))}
                        <div className="row align-items-center mb-4">
                            <div className="col-12">
                                <button
                                    className="btn-add" 
                                    onClick={() => setDewormingsForm(dewormingsForm.concat({ ...defaultDewormingWithValidations }))}
                                >
                                    <IconAddO width="20" height="20" />
                                    Adicionar Everminação { showArrayIndex(dewormings.length + 1) }
                                </button>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="col-12">
                                <div className="input-array input-height">
                                    Observações Sobre a Everminação (opcional)
                                </div>
                            </div>
                            <div className="col-12">
                                <TextField
                                    value={dewormingNotes}
                                    placeholder={`Escreva aqui as observações gerais sobre a everminação`}
                                    onChange={({ target: { value } }) => setDewormingNotes(value)}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                />
                            </div>
                        </div>
                    </>
                )}
                <div className="row mb-4">
                    <div className="col-12">
                        <div className="input-title input-height">
                            Informações clínico-preventivas (opcional)
                        </div>
                    </div>
                    <div className="col-12">
                        <TextField
                            value={otherInfo}
                            placeholder="Escreva aqui as observações complementares"
                            onChange={({ target: { value } }) => setOtherInfo(value)}
                            size="small"
                            variant="outlined"
                            fullWidth
                            multiline
                        />
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-12">
                        <div className="input-title input-height">
                            Observações gerais (opcional)
                        </div>
                    </div>
                    <div className="col-12">
                        <TextField
                            value={notes}
                            placeholder="Escreva aqui as observações gerais sobre o atestado"
                            onChange={({ target: { value } }) => setNotes(value)}
                            size="small"
                            variant="outlined"
                            fullWidth
                            multiline
                        />
                    </div>
                </div>
            </div>
        </div>
    )
})

export default TermHealthForm;