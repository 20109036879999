import React from 'react'

import { formattedCurrency } from '../../utils'

import VSAccordionSimple from '../../components/vsAccordion/VSAccordionSimple'
import RecurrenceView from '../recurrence/RecurrenceView'

import './ExpenseInfoView.scss'
const ExpenseInfoView = (props) => {

    const {
        object,
        expanded
    } = props

    const isDeleted = object.get("isDeleted")

    return !isDeleted && (
        <>
            <div className="vsbox-separator"></div>
            <div className="row">
                <div className="col-12 vsbox--info">
                    <VSAccordionSimple
                        expanded={expanded}
                        header={(expanded) => (
                            `Ver ${ expanded ? "menos" : "mais" } informações`
                        )}
                    >
                        <div className="row">
                            <div className="col-12">
                                <div className="vsbox-item mb-3">
                                    <div className="row vsbox-item-text">
                                        <div className="col-12 col-md">
                                            <b>Valor Total:</b> { formattedCurrency(object.get("value")) }
                                        </div>
                                        <div className="col-12 col-md-auto">
                                            <b>Valor Restante:</b> { formattedCurrency(object.get("paymentsPending")) }
                                        </div>
                                    </div>
                                    <div className="vsbox-separator my-3"></div>
                                    <RecurrenceView object={object} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col vsbox-title">
                                Observações
                            </div>
                        </div>
                        <div className="row">
                            <div className="col mb-3 pre-line">
                                { object.get("notes") || "Não informado" }
                            </div>
                        </div>
                    </VSAccordionSimple>
                </div>
            </div>
        </>
    )
}
  
export default ExpenseInfoView;