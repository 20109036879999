import React from "react";
import { Switch, Route } from "react-router-dom";
import { connect } from "react-redux";

import { ACL } from "../../../constants";

import PatientHistory from "./history/PatientHistory";
import TermList from "./term/TermList";
import TermForm from "./term/TermForm";
import ExamList from "./exam/ExamList";
import ExamForm from "./exam/ExamForm";
import ReturnList from "./return/ReturnList";
import ReturnForm from "./return/ReturnForm";
import AppointmentList from "./appointment/AppointmentList";
import AppointmentForm from "./appointment/AppointmentForm";
import SurgeryList from "./surgery/SurgeryList";
import SurgeryForm from "./surgery/SurgeryForm";
import AppointmentDrugList from "./appointmentDrug/AppointmentDrugList";
import AppointmentDrugForm from "./appointmentDrug/AppointmentDrugForm";
import AttachmentList from "./attachment/AttachmentList";
import AttachmentForm from "./attachment/AttachmentForm";
import WeightList from "./weight/WeightList";
import WeightForm from "./weight/WeightForm";
import VaccineList from "./vaccine/VaccineList";
import VaccineForm from "./vaccine/VaccineForm";
import VaccineView from "./vaccine/VaccineView";
import PrescriptionList from "./prescription/PrescriptionList";
import PatientShareHistory from "../PatientShareHistory";
import VaccineAutomaticReminderForm from "./vaccine/VaccineAutomaticReminderForm";
import VaccineAutomaticReminderFormOld from "./vaccine/VaccineAutomaticReminderFormOld";
import SaleList from "./sale/SaleList";
import CalendarList from "./calendar/CalendarList";
import CalendarForm from "../../calendar/CalendarForm";
import {
    showProOrUpgradeToProComponent,
    upgradeProAgenda,
    upgradeProEstimate,
    upgradeProSale
} from "../../../utils";

const PatientProfileRouters = (props) => {

    const {
        pro,
        permissions
    } = props

    return (
        <Switch {...props}>
            <Route exact path="/animal/:id/(historico)?/(compartilhar)?" {...props} render={(routerProps) => (
                <div>
                    <PatientHistory {...props} {...routerProps} />
                    { routerProps.match.params[1] == "compartilhar" && (
                        <PatientShareHistory {...props} {...routerProps} />
                    )}
                </div>
            )} />
            { permissions?.check(ACL.WEIGHT, ACL.Rule.VIEW) && (
                <Route exact path="/animal/:id/peso/:id_peso?/:ver?" render={(routerProps) => (
                    <div>
                        <WeightList {...props} {...routerProps} />
                        { routerProps.match.params.id_peso && permissions?.check(ACL.WEIGHT, ACL.Rule.EDIT) && (
                            <WeightForm {...props} {...routerProps} />
                        )}
                    </div>
                )} />
            )}
            { permissions?.check(ACL.APPOINTMENT, ACL.Rule.VIEW) && (
                <Route exact path="/animal/:id/consulta/:id_consulta?/:ver?" render={(routerProps) => (
                    <div>
                        <AppointmentList {...props} {...routerProps} />
                        { routerProps.match.params.id_consulta && permissions?.check(ACL.APPOINTMENT, ACL.Rule.EDIT) && (
                            <AppointmentForm {...props} {...routerProps} />
                        )}
                    </div>
                )} />
            )}
            { permissions?.check(ACL.VACCINE, ACL.Rule.VIEW) && (
                <Route exact path="/animal/:id/vacina/:id_vacina?/:action?" render={(routerProps) => (
                    <div>
                        <VaccineList {...props} {...routerProps} />
                        { routerProps.match.params.id_vacina && permissions?.check(ACL.VACCINE, ACL.Rule.EDIT) && (
                            routerProps.match.params.action == 'ver' ?
                            <VaccineView {...props} {...routerProps} /> :
                            <VaccineForm {...props} {...routerProps} />
                        )}
                    </div>
                )} />
            )}
            { permissions?.check(ACL.VACCINE, ACL.Rule.VIEW) && (
                <Route exact path="/animal/:id/lembrete_automatico/:id_lembrete?" render={(routerProps) => (
                    <div>
                        <VaccineList {...props} {...routerProps} />
                        { routerProps.match.params.id_lembrete && permissions?.check(ACL.VACCINE, ACL.Rule.EDIT) && (
                            <VaccineAutomaticReminderForm {...props} {...routerProps} />
                        )}
                    </div>
                )} />
            )}
            { permissions?.check(ACL.PRESCRIPTION, ACL.Rule.VIEW) && (
                <Route exact path="/animal/:id/prescricao/:id_prescricao?" render={(routerProps) => (
                    <div>
                        <PrescriptionList {...props} {...routerProps} />
                    </div>
                )} />
            )}
            { permissions?.check(ACL.EXAM_REQUEST, ACL.Rule.VIEW) && (
                <Route exact path="/animal/:id/solicitacao/:id_solicitacao?/:ver?" render={(routerProps) => (
                    <div>
                        <ExamList {...props} {...routerProps} />
                        { routerProps.match.params.id_solicitacao && permissions?.check(ACL.EXAM_REQUEST, ACL.Rule.EDIT) && (
                            <ExamForm {...props} {...routerProps} />
                        )}
                    </div>
                )} />
            )}
            { permissions?.check(ACL.CALENDAR, ACL.Rule.VIEW) && (
                <Route exact path="/animal/:id/agenda/:id_agenda?" render={(routerProps) => (
                    showProOrUpgradeToProComponent({
                        ...props,
                        ...upgradeProAgenda,
                        component: (
                            <>
                                <CalendarList {...props} {...routerProps} />
                                { routerProps.match.params.id_agenda && permissions?.check(ACL.CALENDAR, ACL.Rule.EDIT) && (
                                    <CalendarForm {...props} {...routerProps} />
                                )}
                            </>
                        )

                    })
                )} />
            )}
            { permissions?.check(ACL.RETURN, ACL.Rule.VIEW) && (
                <Route exact path="/animal/:id/retorno/:id_retorno?/:ver?" render={(routerProps) => (
                    <div>
                        <ReturnList {...props} {...routerProps} />
                        { routerProps.match.params.id_retorno && permissions?.check(ACL.RETURN, ACL.Rule.EDIT) && (
                            <ReturnForm {...props} {...routerProps} />
                        )}
                    </div>
                )} />
            )}
            { permissions?.check(ACL.TERM, ACL.Rule.VIEW) && (
                <Route exact path="/animal/:id/atestado_termo/:id_termo?/:ver?" render={(routerProps) => (
                    <div>
                        <TermList {...props} {...routerProps} />
                        { routerProps.match.params.id_termo && permissions?.check(ACL.TERM, ACL.Rule.EDIT) && (
                            <TermForm {...props} {...routerProps} />
                        )}
                    </div>
                )} />
            )}
            { permissions?.check(ACL.APPOINTMENT_DRUG, ACL.Rule.VIEW) && (
                <Route exact path="/animal/:id/medicamento/:id_medicamento?/:ver?" render={(routerProps) => (
                    <div>
                        <AppointmentDrugList {...props} {...routerProps} />
                        { routerProps.match.params.id_medicamento && permissions?.check(ACL.APPOINTMENT_DRUG, ACL.Rule.EDIT) && (
                            <AppointmentDrugForm {...props} {...routerProps} />
                        )}
                    </div>
                )} />
            )}
            { permissions?.check(ACL.SURGERY, ACL.Rule.VIEW) && (
                <Route exact path="/animal/:id/cirurgia/:id_cirurgia?/:ver?" render={(routerProps) => (
                    <div>
                        <SurgeryList {...props} {...routerProps} />
                        { routerProps.match.params.id_cirurgia && permissions?.check(ACL.SURGERY, ACL.Rule.EDIT) && (
                            <SurgeryForm {...props} {...routerProps} />
                        )}
                    </div>
                )} />
            )}
            { permissions?.check(ACL.ATTACHMENT, ACL.Rule.VIEW) && (
                <Route exact path="/animal/:id/anexo/:id_anexo?/:ver?" render={(routerProps) => (
                    <div>
                        <AttachmentList {...props} {...routerProps} />
                        { routerProps.match.params.id_anexo && permissions?.check(ACL.ATTACHMENT, ACL.Rule.EDIT) && (
                            <AttachmentForm {...props} {...routerProps} />
                        )}
                    </div>
                )} />
            )}
            { permissions?.check(ACL.SALE, ACL.Rule.VIEW) && (
                <Route exact path="/animal/:id/:tipo(venda|orcamento)" render={(routerProps) => (
                    showProOrUpgradeToProComponent({
                        ...props,
                        ...routerProps.match.params.tipo == "orcamento" ? upgradeProEstimate : upgradeProSale,
                        component: (
                            <SaleList {...props} {...routerProps} />
                        )
                    })
                )} />
            )}
        </Switch>
    );
}

const mapStateToProps = state => {
    return {
        pro: state.featuresPro,
        permissions: state.permissions
    }
}
  
export default connect(mapStateToProps)(PatientProfileRouters)