import React, { forwardRef, useEffect, useState, useImperativeHandle } from 'react'
import Autocomplete from '@mui/lab/Autocomplete';

import {
    dayjs,
    getSelectedOptionLabel,
    mandatoryField
} from '../../../../utils'

import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { TERMO_BRANCO, documentTypeOptions } from './Term';

import { ReactComponent as IconTermModel } from "../../../../assets/icon-term-model.svg"
import { ReactComponent as IconTermBlank } from "../../../../assets/icon-term-blank.svg"

const TermDocumentForm = forwardRef((props, ref) => {

    const {
        city,
        setCity,
        errorCity,
        setErrorCity,
        helperCity,
        setHelperCity,
        documentType,
        setDocumentType,
        errorDocumentType,
        setErrorDocumentType,
        helperDocumentType,
        setHelperDocumentType,
        documentDay,
        setDocumentDay,
        documentMonth,
        setDocumentMonth,
        documentYear,
        setDocumentYear,
        documentDate,
        setDocumentDate,
        errorDocumentDate,
        setErrorDocumentDate,
        helperDocumentDate,
        setHelperDocumentDate,
    } = props

    const [isSelected, setIsSelected] = useState(false)

    const handleDocumentDate = (year, month, day) => {
        setDocumentYear(year)
        setDocumentMonth(month)
        setDocumentDay(day)
        const documentDate = dayjs(`${year}-${month}-${day} 00:00:00`, "YYYY-MM-DD HH:mm:ss")
        if (day && month && year && documentDate.isValid()) {
            setDocumentDate(documentDate.toDate())
        } else {
            setDocumentDate(null)
        }
    }

    useImperativeHandle(ref, () => ({
        fieldsWithErrorText(errors) {
            if (errorDocumentDate) {
                errors.push("Data do documento")
            }
            if (errorCity) {
                errors.push("Cidade do documento")
            }
            if (errorDocumentType) {
                errors.push("Tipo do documento")
            }
            return errors
        },
        isValid() {
            return !errorDocumentDate && !errorCity && !errorDocumentType
        },
        validate(isValid) {
            const libDocumentDate = dayjs(documentDate)
            if (documentDate && libDocumentDate.isValid()) {
                const next5Years = dayjs().add(5, 'years')
                if (parseInt(libDocumentDate.format("YYYY")) < 1971) {
                    setErrorDocumentDate(true)
                    setHelperDocumentDate("O ano não pode ser menor que 1971")
                } else if (documentDate > next5Years) {
                    setErrorDocumentDate(true)
                    setHelperDocumentDate("A data da prescrição não pode exceder 5 anos")
                } else {
                    setErrorDocumentDate(false)
                    setHelperDocumentDate(mandatoryField)
                }
            } else {
                setErrorDocumentDate(true)
                setHelperDocumentDate("A data informada não é válida")
            }
    
            if (city && city.length > 2) {
                setErrorCity(false)
                setHelperCity(mandatoryField)
            } else {
                isValid = false
                setErrorCity(true)
                setHelperCity("Digite mais que 2 caracteres")
            }
    
            if (documentType) {
                setErrorDocumentType(false)
                setHelperDocumentType(mandatoryField)
            } else {
                isValid = false
                setErrorDocumentType(true)
                setHelperDocumentType("O tipo de atestado ou termo não é válido")
            }

            return isValid
        }
    }))

    useEffect(() => {
        if (documentDate && !documentDay) {
            const _documentDate = dayjs(documentDate)
            handleDocumentDate(_documentDate.format("YYYY"), _documentDate.format("MM"), _documentDate.format("DD"))
        }
    }, [ documentDate ])

    return (
        <>
            <div className="row align-items-center mb-4">
                <div className="col-12">
                    <div className="input-title mb-2">
                        Emissão do Documento
                    </div>
                </div>
                <div className="col-xl col-12">
                    <TextField
                        label="Cidade"
                        value={city}
                        onChange={({ target: { value } }) => setCity(value)}
                        size="small"
                        variant="outlined"
                        error={errorCity}
                        helperText={helperCity}
                        fullWidth
                    />
                </div>
                <div className="col-auto col-xl-6 col-lg-8 mt-3 mt-xl-0">
                    <div className="row">
                        <div className="col-3 pe-0">
                            <TextField
                                type="tel"
                                label="Dia"
                                value={documentDay}
                                onChange={({ target: { value } }) => handleDocumentDate(documentYear, documentMonth, value)}
                                error={errorDocumentDate}
                                size="small"
                                variant="outlined"
                                fullWidth
                            />
                        </div>
                        <div className="col-5 px-2">
                            <FormControl variant="outlined" size="small" fullWidth error={errorDocumentDate}>
                                <InputLabel htmlFor="outlined-age-native-simple">Mês</InputLabel>
                                <Select
                                    label="Mês"
                                    value={documentMonth}
                                    error={errorDocumentDate}
                                    onChange={({ target: { value } }) => handleDocumentDate(documentYear, value, documentDay)}
                                >
                                    <MenuItem value="01">Janeiro</MenuItem>
                                    <MenuItem value="02">Fevereiro</MenuItem>
                                    <MenuItem value="03">Março</MenuItem>
                                    <MenuItem value="04">Abril</MenuItem>
                                    <MenuItem value="05">Maio</MenuItem>
                                    <MenuItem value="06">Junho</MenuItem>
                                    <MenuItem value="07">Julho</MenuItem>
                                    <MenuItem value="08">Agosto</MenuItem>
                                    <MenuItem value="09">Setembro</MenuItem>
                                    <MenuItem value="10">Outubro</MenuItem>
                                    <MenuItem value="11">Novembro</MenuItem>
                                    <MenuItem value="12">Dezembro</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div className="col-4 ps-0">
                            <TextField
                                type="tel"
                                label="Ano"
                                value={documentYear}
                                onChange={({ target: { value } }) => handleDocumentDate(value, documentMonth, documentDay)}
                                error={errorDocumentDate}
                                size="small"
                                variant="outlined"
                                fullWidth
                            />
                        </div>
                        <div className="col-12">
                            <small className={errorDocumentDate ? "text-danger" : ""}>{helperDocumentDate}</small>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mb-4">
                <div className="col-12">
                    <div className="input-title mb-2">
                        TIPO DE DOCUMENTO (OBRIGATÓRIO)
                    </div>
                </div>
                <div className="col-12 mb-4">
                    <div className="row">
                        <div className="col-6 d-flex align-items-stretch">
                            <div className={`btn-document-type-selection text-center w-100 ${isSelected && documentType != TERMO_BRANCO ? 'selected' : ''}`} onClick={() => {
                                setDocumentType('')
                                setIsSelected(true)
                            }}>
                                <IconTermModel />
                                <div className="mt-2">Modelo pronto</div>
                            </div>
                        </div>
                        <div className="col-6 d-flex align-items-stretch">
                            <div className={`btn-document-type-selection text-center w-100 ${documentType == TERMO_BRANCO ? 'selected' : ''}`} onClick={() => {
                                setDocumentType(TERMO_BRANCO)
                                setIsSelected(true)
                            }}>
                                <IconTermBlank />
                                <div className="mt-2">Documento em branco</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col">
                    {documentType != TERMO_BRANCO && isSelected ? (
                        <Autocomplete
                            value={documentType || null}
                            options={documentTypeOptions}
                            getOptionLabel={(option) => getSelectedOptionLabel(option, documentTypeOptions, documentType)}
                            getOptionSelected={(option) => option.value === documentType}
                            onChange={(_, selected) => setDocumentType(selected.value || '')}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    className="w-100"
                                    placeholder="Selecione o tipo do documento"
                                    label="Tipo do Documento"
                                    size="small"
                                    variant="outlined"
                                    error={errorDocumentType}
                                    helperText={helperDocumentType}
                                />
                            )}
                            disableClearable
                            openOnFocus
                        />
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        </>
    )
})

export default TermDocumentForm;