import React from "react"
import { connect } from "react-redux"

import { ReactComponent as IconEdit } from "../../assets/icon-primary-edit.svg"
import { ReactComponent as IconChemical } from "../../assets/icon-chemical.svg"
import { ReactComponent as IconEye } from "../../assets/icon-primary-eye.svg"

import { ACL } from "../../constants"

import VSBoxView from "../../components/vsBoxView/VSBoxView"
import VSAccordionSimple from "../../components/vsAccordion/VSAccordionSimple"
import SupplyUnitView from "./SupplyUnitView"

import "./SupplyView.scss"
const SupplyView = (props) => {

    const {
        object,
        stock,
        expanded,
        history,
        permissions
    } = props

    const category = object.get("category")
    const isDeleted = object.get("isDeleted")
    const status = stock.get("totalIsBelowMin") ? "warning" : object.get("active") ? "active" : "inactive"

    return (
        <div id="supply-view" className="mb-3">
            <VSBoxView
                status={status}
                statusText={status == "warning" ? "Reposição do insumo" : status != "active" ? "Insumo inativo" : ""}
                icon={<IconChemical width="24" height="24" />}
                title={(
                    <>
                        { object.get("supplyId") && (
                            <b>ID { object.get("supplyId") } - </b>
                        )}
                        { object.get("supplyName") }
                    </>
                )}
                text={(
                    <>
                        <b>Categoria:</b> { category?.get("categoryName") || "Não informada" }
                    </>
                )}
                actions={(
                    <div className="row align-items-center justify-content-center">
                        { permissions?.check(ACL.SUPPLY, ACL.Rule.EDIT, object) && (
                            <div className="col col-md-auto">
                                <button
                                    className={`vsbox-btn ${isDeleted ? "vsbox-btn-disabled" : "vsbox-btn-dark"}`}
                                    onClick={() => history.push(`/insumo/${ object.id }`)}
                                    disabled={isDeleted}
                                >
                                    <IconEdit width="24" height="24" fill={isDeleted ? "var(--vsprontuario-gray-color)" : "var(--vsprontuario-primary-color-dark)"} />
                                </button>
                            </div>
                        )}
                        { permissions?.check(ACL.SUPPLY, ACL.Rule.VIEW, object) && (
                            <div className="col col-md-auto ps-0">
                                <button
                                    className={`vsbox-btn ${isDeleted ? "vsbox-btn-disabled" : "vsbox-btn-primary"}`}
                                    onClick={() => history.push(`/insumo/${ object.id }/ver`)}
                                    disabled={isDeleted}
                                >
                                    <IconEye width="24" height="24" fill={isDeleted ? "var(--vsprontuario-gray-color)" : "var(--vsprontuario-primary-color)"} />
                                </button>
                            </div>
                        )}
                    </div>
                )}
                object={object}
            >
                <div className="row g-3 mt-0 mb-3">
                    <div className="col">
                        <label className="vsbox-info text-nowrap">Unidade de medida</label>
                        <div className="vsbox-info-text">
                            <SupplyUnitView unit={object.get("unit")} />
                        </div>
                    </div>
                    <div className="col">
                        <label className="vsbox-info text-nowrap">Total em estoque</label>
                        <div className="vsbox-info-text">
                            <SupplyUnitView unit={object.get("unit")} quantity={stock.get("total")} />
                        </div>
                    </div>
                    <div className="col">
                        <label className="vsbox-info text-nowrap">Estoque mínimo</label>
                        <div className="vsbox-info-text">
                            <SupplyUnitView unit={object.get("unit")} quantity={stock.get("minQuantity")} />
                        </div>
                    </div>
                </div>
                <div className="vsbox-separator"></div>
                <div className="row">
                    <div className="col-12 vsbox--info">
                        <VSAccordionSimple
                            expanded={expanded}
                            header={(expanded) => (
                                `${ expanded ? "Ocultar" : "Ver" } mais informações`
                            )}
                        >
                            <div className="row g-3 mb-3">
                                <div className="col-12">
                                    Informações do insumo:
                                </div>
                                <div className="col-12">
                                    <div className="vsbox-item">
                                        <div className="row vsbox-item-text my-2">
                                            <div className="col-12 col-md">
                                                <b>Fabricante:</b> { object.get("companyName") || "Não informado" }
                                            </div>
                                            <div className="col-12 col-md">
                                                <b>Fornecedor:</b> { object.get("distributor") || "Não informado" }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 vsbox-title">
                                    Observações
                                </div>
                                <div className="col-12 mt-2 pre-line">
                                    { object.get("notes") || "Não informado" }
                                </div>
                            </div>
                        </VSAccordionSimple>
                    </div>
                </div>
            </VSBoxView>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        permissions: state.permissions
    }
}

export default connect(mapStateToProps)(SupplyView)