import React from 'react'

import { ReactComponent as IconCashO } from "../../assets/icon-cash-o.svg"

import { dayjs, formattedCurrency, showRegisteredBy } from '../../utils'

import VSMedicalRecordsAudit from '../../components/vsMedicalRecordsAudit/VSMedicalRecordsAudit'
import SaleInfoView from '../patient/tabs/sale/SaleInfoView'

import './AccountReceivableView.scss'
import { connect } from 'react-redux'
import { ACL } from '../../constants'
const AccountReceivableView = (props) => {

    const {
        object,
        history,
        noDocumentDate,
        noAudit,
        permissions
    } = props

    const isDeleted = object.get("isDeleted")

    return (
        <div id="patient-sale-view" className={`vsbox mb-3 ${isDeleted ? "vsbox-disabled" : ""}`}>
            <div className="vsbox-body pb-0">
                <div className="row align-items-center">
                    { !noDocumentDate && (
                        <div className="col-auto mb-3 pe-0">
                            <div className="vsbox-chip">
                                { dayjs(object.get("documentDate")).format("DD/MM/YYYY") }
                            </div>
                        </div>
                    )}
                    { !isDeleted && (
                        <>
                            <div className="col-12 col-md-auto mb-3">
                                <label className="vsbox-info">
                                    <IconCashO width={20} height={20} fill="var(--vsprontuario-gray-color)" className="me-1" />
                                    Valor a Receber
                                </label>
                                <div className="vsbox-main-text text-warning">{ formattedCurrency(object.get("paymentsPending")) }</div>
                            </div>
                            <div className="col-6 col-md-auto mb-3">
                                <label className="vsbox-info">Total da Venda</label>
                                <div className="vsbox-info-text">{ formattedCurrency(object.get("finalValue")) }</div>
                            </div>
                            <div className="col-6 col-md-auto mb-3">
                                <label className="vsbox-info">Total Pago</label>
                                <div className="vsbox-info-text">{ formattedCurrency(object.get("paymentsValue")) }</div>
                            </div>
                            { permissions?.check(ACL.INFLOW, ACL.Rule.EDIT, object) && (
                                <div className="col-12 col-md-auto mb-3">
                                    <button
                                        className={`vsbox-btn ${isDeleted ? "vsbox-btn-disabled" : "vsbox-btn-primary"}`}
                                        onClick={() => history.push(`/pagamento/venda/${ object.id }`, { redirectTo: history.location?.pathname })}
                                        disabled={isDeleted}
                                    >
                                        Quitar Valor a Receber
                                    </button>
                                </div>
                            )}
                        </>
                    )}
                </div>
                { showRegisteredBy(object) }
                <SaleInfoView {...props} />
                { !noAudit && (
                    <VSMedicalRecordsAudit parseObject={object} />
                )}
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        permissions: state.permissions
    }
}
  
export default connect(mapStateToProps)(AccountReceivableView)