import React, { useState } from 'react'
import axios from 'axios'
import { connect } from 'react-redux'

import { dayjs, validateDate } from '../../utils'

import VSDrawer from '../../components/vsDrawer/VSDrawer'
import VSRadio from '../../components/vsRadio/VSRadio'
import DateInput from '../../components/dateInput/DateInput'

import './FinancialStatementDownloadForm.scss'
const FinancialStatementDownloadForm = (props) => {

    const {
        history,
        clinic
    } = props

    const defaultFrom = dayjs().subtract(30, 'days')
    const [ dateFrom, setDateFrom ] = useState(defaultFrom)
    const [ dateFromText, setDateFromText ] = useState(defaultFrom.format("DD/MM/YYYY"))

    const defaultTo = dayjs()
    const [ dateTo, setDateTo ] = useState(defaultTo)
    const [ dateToText, setDateToText ] = useState(defaultTo.format("DD/MM/YYYY"))

    const defaultRange = `${dateFrom.format("YYYY-MM-DD")}_${dateTo.format("YYYY-MM-DD")}`
    const [ range, setRange ] = useState(defaultRange)

    const setDate = (value, field) => {
        const date = validateDate(value)
        switch (field) {
            case "dateFrom":
                setDateFromText(value)
                date && setDateFrom(dayjs(date))
                break;
            case "dateTo":
                setDateToText(value)
                date && setDateTo(dayjs(date))
                break;
        }
    }

    const getRange = (value) => {
        if (value) {
            const [
                start,
                end
            ] = value.split("_")
            
            const _start = dayjs(start, "YYYY-MM-DD")
            setDateFrom(_start)
            setDateFromText(_start.format("DD/MM/YYYY"))

            const _end = dayjs(end, "YYYY-MM-DD")
            setDateTo(_end)
            setDateToText(_end.format("DD/MM/YYYY"))
        }

        setRange(value)
    }

    const getError = () => {
        if (!range) {
            if (!validateDate(dateFromText)) {
                return "A data inicial não é válida"
            }
            if (!validateDate(dateToText)) {
                return "A data final não é válida"
            }
            if (dateTo.isBefore(dateFrom)) {
                return "A data final não pode ser menor que a data inicial"
            }
            if (dateFrom.diff(dateTo, "year") != 0) {
                return "Por favor, informe uma data menor ou até um ano"
            }
        }
        return ""
    }

    const download = () => {
        return new Promise((resolve, reject) => {
            if (!getError()) {
                axios({
                    method: "POST",
                    url: `${process.env.REACT_APP_PDF}/api/v2/balanco/${clinic.objectId}?download`,
                    responseType: 'blob',
                    data: {
                        token: Parse.User.current().getSessionToken(),
                        from: dateFrom.format("YYYY-MM-DD"),
                        to: dateTo.format("YYYY-MM-DD")
                    }
                })
                .then(({ data }) => {
                    var url = window.URL.createObjectURL(data)
                    var a = document.createElement('a')
                    a.href = url
                    a.download = "Balanço Financeiro"
                    a.click()
                    a.remove()
                    setTimeout(() => window.URL.revokeObjectURL(url), 100)
                    return resolve()
                })
                .catch(error => {
                    console.error(error)
                    return reject()
                })
            }
        })
    }

    const errorRange = getError()

    return (
        <VSDrawer
            id="financial-statement-form"
            width="40%"
            timeout={0}
            title="Balanço Financeiro"
            cancel={"Cancelar"}
            submit={"Baixar Balanço Financeiro"}
            errors={errorRange ? [errorRange] : []}
            onSubmit={download}
            onClose={() => history.goBack()}
            onCancel={() => history.goBack()}
        >
            <div className="row align-items-center">
                <div className="col">
                    Informe o período ou uma data especifica para filtrar o seu Balanço Financeiro.
                </div>
            </div>
            <div className="row align-items-center">
                <div className="col-12 mb-3">
                    <VSRadio
                        options={[
                            {
                                label: "Hoje",
                                value: `${dayjs().format("YYYY-MM-DD")}_${dayjs().format("YYYY-MM-DD")}`
                            },
                            {
                                label: "Essa semana",
                                value: `${dayjs().startOf("week").format("YYYY-MM-DD")}_${dayjs().endOf("week").format("YYYY-MM-DD")}`
                            },
                            {
                                label: "Última semana",
                                value: `${dayjs().subtract(7, "days").format("YYYY-MM-DD")}_${dayjs().format("YYYY-MM-DD")}`
                            },
                            {
                                label: "Esse mês",
                                value: `${dayjs().startOf("month").format("YYYY-MM-DD")}_${dayjs().endOf("month").format("YYYY-MM-DD")}`
                            },
                            {
                                label: "Últimos 30 dias",
                                value: `${dayjs().subtract(30, "days").format("YYYY-MM-DD")}_${dayjs().format("YYYY-MM-DD")}`
                            },
                            {
                                label: "Últimos 3 meses",
                                value: `${dayjs().subtract(3, "months").format("YYYY-MM-DD")}_${dayjs().format("YYYY-MM-DD")}`
                            },
                            {
                                label: "Últimos 6 meses",
                                value: `${dayjs().subtract(6, "months").format("YYYY-MM-DD")}_${dayjs().format("YYYY-MM-DD")}`
                            },
                            {
                                label: "Personalizado",
                                value: ""
                            },
                        ]}
                        value={range}
                        onChange={(_, value) => {
                            getRange(value)
                        }}
                    />
                </div>
            </div>
            <div className="row align-items-center">
                { !range && (
                    <>
                        <div className="col-auto">
                            <p className="prepend-input">DE</p>
                        </div>
                        <div className="col">
                            <DateInput
                                className="m-0"
                                placeholder="00/00/0000"
                                value={dateFromText}
                                onChange={(value) => setDate(value, "dateFrom")}
                                size="small"
                                variant="outlined"
                                fullWidth
                            />
                        </div>
                        <div className="col-auto">
                            <p className="prepend-input">ATÉ</p>
                        </div>
                        <div className="col">
                            <DateInput
                                className="m-0"
                                placeholder="00/00/0000"
                                value={dateToText}
                                onChange={(value) => setDate(value, "dateTo")}
                                size="small"
                                variant="outlined"
                                fullWidth
                            />
                        </div>
                    </>
                )}
                { errorRange && (
                    <p className="text-danger mt-2">{ errorRange }</p>
                )}
            </div>
        </VSDrawer>
    )
}

const mapStateToProps = state => {
    return {
        clinic: state.clinic
    };
};
  
export default connect(mapStateToProps)(FinancialStatementDownloadForm)