import React, { useEffect, useRef, useState } from "react"
import { connect } from "react-redux"

import { ReactComponent as IconAdd } from "../../assets/icon-add.svg"
import { ReactComponent as IconAddO } from "../../assets/icon-primary-add-o.svg"
import { ReactComponent as IconMinusO } from "../../assets/icon-minus-o.svg"
import { ReactComponent as IconPaw } from "../../assets/icon-paw.svg"
import { ReactComponent as IconQuestionO } from "../../assets/icon-question-o.svg"
import { ReactComponent as IconSquareMinus } from "../../assets/icon-square-minus.svg"
import { ReactComponent as IconSquarePlus } from "../../assets/icon-square-plus.svg"

import { dayjs, diacriticSensitive } from "../../utils"
import { ACL, OrderBy_Newest, OrderBy_Oldest } from "../../constants"
import { searchSuppliesByCompany, searchSuppliesByDistributor } from './SupplyTransaction';
import { deleteCategory, searchCategories } from "../service/Service"

import VSList from "../../components/vsList/VSList"
import VSEmpty from "../../components/vsEmpty/VSEmpty"
import VSAccordionSimple from "../../components/vsAccordion/VSAccordionSimple"
import SupplyTransactionView from "./SupplyTransactionView"
import VSTimeline from "../../components/vsTimeline/VSTimeline"
import CardFeedback from "../../components/cardFeedback/CardFeedback"
import CategoryForm from "../service/CategoryForm"

import "./SupplyTransactionList.scss"
import SupplyUnitView from "./SupplyUnitView"
const SupplyTransactionList = (props) => {

    const {
        clinic,
        history,
        permissions
    } = props

    const rowsPerPage = 5

    const filter = useRef(null)
    const _isMounted = useRef(false)

    const [ transactionsByDate, setTransactionsByDate ] = useState({})

    const [ hasSupply, setHasSupply ] = useState(false)
    const [ categories, setCategories ] = useState([])
    const [ companyNames, setCompanyNames ] = useState([])
    const [ distributors, setDistributors ] = useState([])
    const [ transactionType, setTransactionType ] = useState("0")
    const [ active, setActive ] = useState("0")
    const [ reminder, setReminder ] = useState("0")

    const getSupplies = (searchTerm, dateFrom, dateTo, orderBy, page) => {
        return new Promise((resolve, reject) => {
            let filters = {
                supplyObject: {}
            }
            if (searchTerm) {
                filters.$or = [
                    {
                        "supplyObject.supplyName": { 
                            $regex: diacriticSensitive(searchTerm),
                            $options: 'im'
                        }
                    },
                    {
                        "supplyObject.supplyId": { 
                            $regex: diacriticSensitive(searchTerm),
                            $options: 'im'
                        }
                    }
                ]
            }
            if (categories.length > 0) {
                filters = {
                    ...filters,
                    "supplyObject._p_category": {
                        $in: categories.map(({ value }) => `MRCategory$${value.id}`)
                    }
                }
            }
            if (companyNames && companyNames.length > 0) {
                filters = {
                    ...filters,
                    "supplyObject.companyName": {
                        $in: companyNames.map(companyName => companyName.value)
                    }
                }
            }
            if (distributors && distributors.length > 0) {
                filters ={
                    ...filters,
                    "supplyObject.distributor": {
                        $in: distributors.map(distributor => distributor.value)
                    }
                }
            }
            if (active != "0") {
                filters = {
                    ...filters,
                    "supplyObject.active": active == "1"
                }
            }
            if(reminder != "0"){
                filters ={
                    ...filters,
                    "supplyObject.reminder": reminder == "1"
                }
            }
            if (transactionType == "1") {
                filters.quantity = {
                    $gte: 0
                }
            } else if (transactionType == "2") {
                filters.quantity = {
                    $lt: 0
                }
            }

            if (Object.keys(filters.supplyObject).length == 0) {
                delete filters.supplyObject
            }
            Parse.Cloud.run("getPeriodForClass", {
                clinicId: clinic.objectId,
                className: "VW_MRSupplyTransaction_timeline",
                filters: filters,
                dateFrom: dateFrom,
                dateTo: dateTo,
                orderBy: "transactionDate",
                order: "newest",
                page: page
            })
            .then(({ total, from, to }) => {
                let querySupply
                if (searchTerm) {
                    const queryByName = new Parse.Query("MRSupply")
                    queryByName.equalTo("clinic", clinic.object);
                    queryByName.matches("supplyName", diacriticSensitive(searchTerm), "im")

                    const queryById = new Parse.Query("MRSupply")
                    queryById.equalTo("clinic", clinic.object);
                    queryById.matches("supplyId", diacriticSensitive(searchTerm), "im")

                    querySupply = new Parse.Query.or(
                        queryByName,
                        queryById
                    )
                } else {
                    querySupply = new Parse.Query("MRSupply");
                    querySupply.equalTo("clinic", clinic.object);
                }
                querySupply.equalTo("clinic", clinic.object);
                querySupply.equalTo("isDeleted", false);
                if (categories.length > 0) {
                    querySupply.containedIn("category", categories.map(({ value }) => value))
                }
                if (companyNames && companyNames.length > 0){
                    querySupply.containedIn("companyName", companyNames.map(companyName => companyName.value));
                }
                if (distributors && distributors.length > 0){
                    querySupply.containedIn("distributor", distributors.map(distributor => distributor.value));
                }
                if (active != "0") {
                    querySupply.equalTo("active", active == "1")
                }
                if(reminder != "0"){
                    querySupply.equalTo("reminder", reminder == "1")
                }

                const query = new Parse.Query("VW_MRSupplyTransaction_timeline");
                query.equalTo("clinic", clinic.object);
                query.greaterThanOrEqualTo("transactionDate", from)
                query.lessThanOrEqualTo("transactionDate", to)
                query.matchesQuery("supply", querySupply)
                if (transactionType == "1") {
                    query.greaterThanOrEqualTo("quantity", 0)
                } else if (transactionType == "2") {
                    query.lessThan("quantity", 0)
                }
                query.descending("transactionDate")
                query.addAscending("objectId")
                querySupply.addAscending("objectId")
                query.include("supply");
                query.include("supply.category");
                query.find()
                .then(results => {
                    let _transactionsByDate = {}
                    let dates = []
                    if (_isMounted.current) {
                        results.map(transaction => {
                            const date = dayjs(transaction.get("transactionDate")).format("DD/MM/YYYY")
                            const supply = transaction.get("supply")
                            if (!_transactionsByDate[date]) {
                                dates.push(date)
                                _transactionsByDate[date] = []
                            }
                            if (!_transactionsByDate[date][supply.id]) {
                                _transactionsByDate[date][supply.id] = {
                                    supply: supply,
                                    transactions: [transaction]
                                }
                            } else {
                                _transactionsByDate[date][supply.id].transactions.push(transaction)
                            }
                        })
                        setTransactionsByDate(_transactionsByDate)
                    }
                    resolve({
                        items: dates,
                        total: total
                    })
                })
                .catch(error => {
                    console.error(error)
                    reject(error)
                })
            })
        })
    }

    const getHasSupply = () => {
        const query = new Parse.Query("MRSupply")
        query.equalTo("clinic", clinic.object)
        query.equalTo("isDeleted", false)
        query.first()
        .then(_supply => {
            setHasSupply(_supply ? true : false)
        })
        .catch(error => {
            
        })
    }

    const onChange = () => {
        if (filter.current) {
            filter.current.onChange()
        }
    }

    useEffect(() => {
        if (permissions && !permissions.check(ACL.SUPPLY_TRANSACTION, ACL.Rule.VIEW)) {
            permissions.error()
        }
    }, [ permissions ])

    useEffect(() => {
        document.addEventListener("Supply__getList", onChange)
        getHasSupply()
        _isMounted.current = true
        return () => { 
            document.removeEventListener("Supply__getList", onChange)
            _isMounted.current = false
        }
    }, [])

    return (
        <>
            <div id="supply-list" className="row flex-grow-1">
                <div className="col-12">
                    <div className="page-content">
                        <VSList
                            ref={filter}
                            title={{
                                text: "Histórico de Movimentações de Insumos"
                            }}
                            search={{
                                text: "Buscar por ID ou nome do insumo"
                            }}
                            VSFilterProps={{
                                title: "Filtro de Insumos",
                                onClear: () => {
                                    setCategories([])
                                    setCompanyNames([])
                                    setDistributors([])
                                    setActive(true)
                                    setReminder("all")
                                },
                                onFilter: () => {
                                    onChange()
                                },
                                filters: [
                                    {
                                        title: "Categoria",
                                        defaultValue: [],
                                        value: categories,
                                        onChange: (values) => {
                                            setCategories(values)
                                        },
                                        onSearch: (term) => {
                                            return new Promise((resolve, reject) => {
                                                searchCategories(term, clinic.object)
                                                .then(({ results, count }) => {
                                                    if (results) {
                                                        const options = results.map(category => ({
                                                            value: category,
                                                            label: category.get("categoryName")
                                                        }))
                                                        return resolve({
                                                            count: count,
                                                            options: options
                                                        })
                                                    }
                                                    return resolve([])
                                                })
                                                .catch(error => {
                                                    console.error(error)
                                                    return resolve([])
                                                })
                                            })
                                        },
                                        options: [],
                                        multiple: {
                                            title: "Categorias",
                                            button: "Selecione uma categoria",
                                            placeholder: "Procure pela categoria",
                                            canCreate: () => permissions?.check(ACL.SERVICE, ACL.Rule.EDIT),
                                            canEdit: (object) => object.get("clinic"),
                                            canDelete: (object) => object.get("clinic"),
                                            onDelete: (object, callback) => deleteCategory(object, callback),
                                            FormComponent: CategoryForm
                                        }
                                    },
                                    {
                                        title: "Fabricante",
                                        defaultValue: [],
                                        value: companyNames,
                                        onChange: (values) => {
                                            setCompanyNames(values)
                                        },
                                        onSearch: (term) => {
                                            return new Promise((resolve, reject) => {
                                                searchSuppliesByCompany(term, clinic.object)
                                                .then(({ results, count }) => {
                                                    if (results) {
                                                        const options = results.map(supply => ({
                                                            value: supply.get("companyName"),
                                                            label: supply.get("companyName")
                                                        }))
                                                        return resolve({
                                                            count: count,
                                                            options: options
                                                        })
                                                    }
                                                    return resolve([])
                                                })
                                                .catch(error => {
                                                    console.error(error)
                                                    return resolve([])
                                                })
                                            })
                                        },
                                        options: [],
                                        multiple: {
                                            title: "Fabricante",
                                            button: "Selecione um fabricante",
                                            placeholder: "Procure pelo fabricante"
                                        }
                                    },
                                    {
                                        title: "Fornecedor",
                                        defaultValue: [],
                                        value: distributors,
                                        onChange: (values) => {
                                            setDistributors(values)
                                        },
                                        onSearch: (term) => {
                                            return new Promise((resolve, reject) => {
                                                searchSuppliesByDistributor(term, clinic.object)
                                                .then(({ results, count }) => {
                                                    if (results) {
                                                        const options = results.map(supply => ({
                                                            value: supply.get("distributor"),
                                                            label: supply.get("distributor")
                                                        }))
                                                        return resolve({
                                                            count: count,
                                                            options: options
                                                        })
                                                    }
                                                    return resolve([])
                                                })
                                                .catch(error => {
                                                    console.error(error)
                                                    return resolve([])
                                                })
                                            })
                                        },
                                        options: [],
                                        multiple: {
                                            title: "Fornecedor",
                                            button: "Selecione um fornecedor",
                                            placeholder: "Procure pelo fornecedor"
                                        }
                                    },
                                    {
                                        title: "Tipo de Movimentação",
                                        horizontal: true,
                                        defaultValue: "0",
                                        value: transactionType,
                                        onChange: (value) => {
                                            setTransactionType(value)
                                        },
                                        options: [
                                            { value: "0", label: "Todos"},
                                            { value: "1", label: "Entrada"},
                                            { value: "2", label: "Saída"}
                                        ]
                                    },
                                    {
                                        title: "Estado do insumo",
                                        horizontal: true,
                                        defaultValue: "0",
                                        value: active,
                                        onChange: (value) => {
                                            setActive(value)
                                        },
                                        options: [
                                            { value: "0", label: "Todos"},
                                            { value: "1", label: "Ativo"},
                                            { value: "2", label: "Inativo"}
                                        ]
                                    },
                                    {
                                        title: "Lembrete de reposição",
                                        horizontal: true,
                                        defaultValue: "0",
                                        value: reminder,
                                        onChange: (value) => {
                                            setReminder(value)
                                        },
                                        options: [
                                            { value: "0", label: "Todos"},
                                            { value: "1", label: "Ativo"},
                                            { value: "2", label: "Desativado"}
                                        ]
                                    }
                                ]
                            }}
                            pagination={{
                                rowsPerPage: rowsPerPage
                            }}
                            onChange={({ _searchBy, _start, _end, _orderBy, _page }) => {
                                return getSupplies(_searchBy, _start, _end, _orderBy, _page)
                            }}
                            customFilter={
                                <div className="row g-3">
                                    { permissions?.check(ACL.SUPPLY_TRANSACTION, ACL.Rule.EDIT) && (
                                        <>
                                            <div className="col-12 col-sm-auto">
                                                <button
                                                    id="new-supply-entry"
                                                    className="btn-outline-submit btn-square vstext-ellipsis w-100 justify-content-center"
                                                    onClick={() => history.push(`/insumo/movimentacao/entrada`)}
                                                >
                                                    <IconAdd id="add-icon" width="14" height="14" fill="var(--vsprontuario-primary-color)" />
                                                    Entrada de insumo
                                                </button>
                                            </div>
                                            <div className="col-12 col-sm-auto">
                                                <button
                                                    id="new-supply-exit"
                                                    className="btn-outline-submit btn-square vstext-ellipsis w-100 justify-content-center"
                                                    onClick={() => history.push(`/insumo/movimentacao/saida`)}
                                                >
                                                    <IconMinusO id="add-icon" width="14" height="14" fill="var(--vsprontuario-primary-color)" />
                                                    Saída de insumo
                                                </button>
                                            </div>
                                        </>
                                    )}
                                </div>
                            }
                            renderItem={(item, expanded) => {
                                const date = dayjs(item, "DD/MM/YYYY")
                                return (
                                    <VSTimeline
                                        day={ date.format("DD") }
                                        month={ date.format("MMM") }
                                        year={ date.format("YYYY") }
                                    >
                                        { transactionsByDate && transactionsByDate[item] && Object.keys(transactionsByDate[item]).map((key, index) => {
                                            const data = transactionsByDate[item][key]
                                            const supply = data.supply
                                            const transactions = data.transactions
                                            const lastTransaction = transactions?.[0]
                                            return (
                                                <React.Fragment key={index}>
                                                    <VSAccordionSimple
                                                        expanded={true}
                                                        className="custom-accordion"
                                                        hideIcon
                                                        header={(expanded) => (
                                                            <div className={`vstimeline-item vstimeline-active w-100`}>
                                                                <div  className="vsbox mt-2">
                                                                    <div className="vsbox-body p-2 mb-0">
                                                                        <div className="row align-items-center mx-0">
                                                                            <div className="col-12 col-md">
                                                                                <div className="row align-items-center">
                                                                                    <div className="col">
                                                                                        <div className="vsbox-title">
                                                                                            { supply.get("supplyId") && (
                                                                                                <b>ID { supply.get("supplyId") } - </b>
                                                                                            )}
                                                                                            { supply.get("supplyName") }
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-12 col-md-auto">
                                                                                <b>Qtd total: <SupplyUnitView unit={lastTransaction.get("unit")} quantity={lastTransaction.get("currentTotal")} /></b>
                                                                            </div>
                                                                            <hr className="mt-3 d-block d-md-none" />
                                                                            <div className="col-auto pe-2 vsbox-info mb-0">
                                                                                { expanded ? "Ocultar" : "Ver" } Registro{ transactions.length == 1 ? "" : "s" } ({ transactions.length })
                                                                                { expanded ? (
                                                                                    <IconSquareMinus width="24px" height="24px" className="ms-2" fill="var(--vsprontuario-secondary-color)" />
                                                                                ) : (
                                                                                    <IconSquarePlus width="24px" height="24px" className="ms-2" fill="var(--vsprontuario-secondary-color)" />
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    >
                                                        { transactions && transactions.map((transaction, index) => (
                                                            <SupplyTransactionView
                                                                key={index}
                                                                object={transaction}
                                                                expanded={expanded}
                                                                {...props}
                                                            />
                                                        )) }
                                                    </VSAccordionSimple>
                                                </React.Fragment>
                                            )
                                        }) }
                                    </VSTimeline>
                                )
                            }}
                            renderEmpty={() => (
                                <VSEmpty
                                    icon={<IconPaw width="32px" height="32px" fill="var(--vsprontuario-primary-color)" />}
                                    title="Nenhum movimentação realizada"
                                    text="Todas as movimentações de entrada e saída dos seus insumos aparecerão aqui. "
                                >
                                    { permissions?.check(ACL.SUPPLY_TRANSACTION, ACL.Rule.EDIT) && (
                                        <button
                                            className="vsbox-btn vsbox-btn-primary w-auto mb-4"
                                            onClick={() => history.push(`/insumo/novo`)}
                                        >
                                            <IconAddO width="14" height="14" className="me-2" />
                                            Cadastrar Novo Insumo
                                        </button>
                                    )}
                                    <div className="row g-3">
                                        <div className="col-12 vspro-info">
                                            <div className="row g-3 justify-content-start justify-content-md-center">
                                                <div className="col-auto pe-0">
                                                    <IconQuestionO width={24} height={24} fill="var(--vsprontuario-primary-color)" />
                                                </div>
                                                <div className="col col-md-auto ps-2 text-start action-text">
                                                    Ficou com dúvidas? Saiba como funciona a parte de <a href="#" target="_blank">Movimentações de Insumos</a>.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </VSEmpty>
                            )}
                            renderNotFound={() => (
                                <VSEmpty
                                    icon={<IconPaw width="32px" height="32px" fill="var(--vsprontuario-primary-color)" />}
                                    title="Não encontramos nenhuma movimentação"
                                    text="Tente refazer sua busca utilizando mais caracteres ou crie um novo cadastro utilizando os botões abaixo"
                                >
                                    { hasSupply ? (
                                        <div className="row gx-3 justify-content-center">
                                            { permissions?.check(ACL.SUPPLY_TRANSACTION, ACL.Rule.EDIT) && (
                                                <>
                                                    <div className="col-12 col-sm-auto">
                                                        <button
                                                            id="new-supply-entry"
                                                            className="btn-outline-submit btn-square vstext-ellipsis w-100 justify-content-center"
                                                            onClick={() => history.push(`/insumo/movimentacao/entrada`)}
                                                        >
                                                            <IconAdd id="add-icon" width="14" height="14" fill="var(--vsprontuario-primary-color)" />
                                                            Entrada de insumo
                                                        </button>
                                                    </div>
                                                    <div className="col-12 col-sm-auto">
                                                        <button
                                                            id="new-supply-exit"
                                                            className="btn-outline-submit btn-square vstext-ellipsis w-100 justify-content-center"
                                                            onClick={() => history.push(`/insumo/movimentacao/saida`)}
                                                        >
                                                            <IconMinusO id="add-icon" width="14" height="14" fill="var(--vsprontuario-primary-color)" />
                                                            Saída de insumo
                                                        </button>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    ) : (
                                        permissions?.check(ACL.SUPPLY, ACL.Rule.EDIT) && (
                                            <button
                                                className="vsbox-btn vsbox-btn-primary w-auto mb-4"
                                                onClick={() => history.push(`/insumo/novo`)}
                                            >
                                                <IconAddO width="14" height="14" className="me-2" />
                                                Cadastrar Novo Insumo
                                            </button>
                                        )
                                    )}
                                </VSEmpty>
                            )}
                        />
                    </div>
                </div>
            </div>
            {transactionsByDate && Object.keys(transactionsByDate).length > 0 && (
                <CardFeedback contentDetail={history.location?.pathname || "SupplyTransactionList"} />
            )}
        </>
    )
}

const mapStateToProps = state => {
    return {
        clinic: state.clinic,
        permissions: state.permissions
    };
};
  
export default connect(mapStateToProps)(SupplyTransactionList);