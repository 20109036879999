import React from 'react';

import "./CardEmptyDashboard.scss"

import { ReactComponent as IconNotFound } from "../../assets/icon-not-found.svg"

const CardEmptyDashboard = (props) => {

    return (
        <div id="card-empty" >
            <div className="row p-2 mt-2">
                <div className="col-auto">
                    <IconNotFound width="94px" height="94px" />
                </div>
                <div className="col d-flex align-items-center">
                    <div className="d-flex  flex-column ">
                        <div className="card-title">
                            {props.title}
                        </div>
                        <div className="card-text mt-2">
                            {props.description}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CardEmptyDashboard;