import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import TextField from '@mui/material/TextField';

import { ReactComponent as IconAddO } from "../../assets/icon-primary-add-o.svg"
import { ReactComponent as IconSearch } from "../../assets/icon-search.svg"
import { ReactComponent as IconUserO } from "../../assets/icon-user-o.svg"
import { 
    diacriticSensitive
} from '../../utils'

import './OwnerQuery.scss'
import { CircularProgress, InputAdornment } from '@mui/material';
import BoxOwner from './BoxOwner';
import VSEmpty from '../../components/vsEmpty/VSEmpty';

var searchBy = ""

const OwnerQuery = (props) => {

    const {
        text,
        clinic,
        history,
        onCreate,
        onSelected
    } = props

    const [ query, setQuery ] = useState("")
    const [ owners, setOwners ] = useState([])
    const [ hasOwner, setHasOwner ] = useState(true)
    const [ loading, setLoading ] = useState(false)

    useEffect(() => {
        setLoading(true)
        setOwners([])

        searchBy = query

        if (query) {
            let queryByName = new Parse.Query("Owner")
            queryByName.matches("name", diacriticSensitive(query), "im")

            let queryByCpf = new Parse.Query("Owner")
            queryByCpf.matches("cpf", query, "im")

            let queryByPhone = new Parse.Query("Owner")
            queryByPhone.matches("phone", query, "im")

            const mainQuery = Parse.Query.or(
                queryByName,
                queryByCpf,
                queryByPhone
            )
            mainQuery.equalTo("clinic", clinic.object);
            mainQuery.equalTo("isDeleted", false);
            mainQuery.ascending("name");
            mainQuery.find()
            .then(owners => {
                if (searchBy == query) {
                    if (owners) {
                        setOwners(owners)
                    }
                    setLoading(false)
                }
            }).catch(error => {
                console.error(error)
                setLoading(false)
            })
        }
    }, [ query ])

    useEffect(() => {
        if (clinic && clinic.object) {
            setLoading(true)
            const ownerTotal = new Parse.Query("Owner");
            ownerTotal.equalTo("clinic", clinic.object);
            ownerTotal.equalTo("isDeleted", false);
            ownerTotal.count()
            .then(_total => {
                setHasOwner(_total > 0)
                setLoading(false)
            })
            .catch(error => console.error(error))
        }
    }, [ clinic ])

    return (
        <div className="vssearch">
            <div className="vssearch-area">
                <TextField
                    className="vssearch-input"
                    placeholder="Buscar por um(a) Tutor(a) pelo Nome, CPF ou Telefone"
                    variant="outlined"
                    size="small"
                    value={query}
                    onChange={({ target: { value} }) => setQuery(value)}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">
                            <IconSearch width="20px" height="20px" fill="#888888" />
                        </InputAdornment>,
                    }}
                    fullWidth
                />
            </div>
            { hasOwner ? (
                query ? (
                    loading ? (
                        <div className="mt-4 data-loading">
                            <CircularProgress />
                        </div>
                    ) : (
                        owners && owners.length > 0 ? (
                            <div className="vssearch-list">
                                { owners.map((owner, index) => (
                                    <BoxOwner
                                        key={index}
                                        owner={owner}
                                        history={history}
                                        hideIcon
                                        actions={(
                                            <div className="col-sm-auto col-12">
                                                <button
                                                    className="vsbox-btn vsbox-btn-primary w-100"
                                                    onClick={() => onSelected && onSelected(owner)}
                                                >
                                                    SELECIONAR TUTOR(A)
                                                </button>
                                            </div>
                                        )}
                                    />
                                ))}
                            </div>
                        ) : (
                            <VSEmpty
                                icon={<IconUserO width="32px" height="32px" />}
                                title="Não encontramos nenhum resultado para a sua busca"
                                text="Tente refazer sua busca utilizando mais caracteres ou crie um novo cadastro utilizando o botão abaixo"
                            >
                                <button
                                    id="new-owner-link"
                                    className="vsbox-btn vsbox-btn-primary"
                                    onClick={() => history.replace(`/tutor/form/novo`)}
                                >
                                    <IconAddO width="14" height="14" className="me-2" />
                                    Cadastrar novo tutor(a)
                                </button>
                            </VSEmpty>
                        )
                    )
                ) : (
                    <div className="vssearch-default">
                        { text }
                    </div>
                )
            ) : (
                <VSEmpty
                    icon={<IconUserO width="32px" height="32px" />}
                    title="Você ainda não possui nenhuma(a) tutor(a) cadastrado(a) no Prontuário"
                    text="Cadastre o(a) primeiro(a) tutor(a) e comece a aproveitar todos os benefícios de gestão de clientes do Prontuário Vet Smart"
                >
                    <button
                        id="new-owner-link"
                        className="vsbox-btn vsbox-btn-primary"
                        onClick={() => history.replace(`/tutor/form/novo`)}
                    >
                        <IconAddO width="14" height="14" className="me-2" />
                        Cadastrar novo tutor(a)
                    </button>
                </VSEmpty>
            )}
        </div>
    )
}

const mapStateToProps = state => {
    return {
        clinic: state.clinic,
        onboarding: state.onboarding
    };
};
  
export default connect(mapStateToProps)(OwnerQuery);