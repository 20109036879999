import React, { useEffect, useState } from "react"
import { redirectToCheckout, saveTrackersProSubscription } from "../../utils"

import "./CardProAdvertising.scss"
const CardProAdvertising = (props) => {

    const {
        clinic,
        pro,
        history,
        big
    } = props

    return (
        <div className="vsbox card-pro-advertising">
            <div className="vsbox-body">
                <div className="row g-3">
                    <div className={`${big ? 'col-12 col-md-auto' : 'col-12'}`}>
                        <svg width="74" height="74" viewBox="0 0 74 74" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <mask id="mask0_68_10976" style={{maskType:"alpha"}} maskUnits="userSpaceOnUse" x="0" y="0" width="74" height="74">
                                <rect width="74" height="74" rx="16" fill="#D8ECFE"/>
                            </mask>
                            <g mask="url(#mask0_68_10976)">
                                <rect width="92" height="92" transform="matrix(-1 0 0 1 84.0762 -7.96582)" fill="#D8ECFE"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M38.3619 55.6972C38.5321 55.6972 38.8724 56.0398 38.8724 56.4966C39.0425 57.0104 39.0425 57.4671 38.8724 58.1522L35.7529 63.5761C35.5827 63.9187 35.2424 64.2613 34.7887 64.3754C34.2782 64.5467 33.8244 64.5467 33.4841 64.3754L30.0243 62.891L29.8541 63.0623C29.3436 63.4049 28.8899 63.7474 28.2093 64.0329L27.5286 67.8581C27.1883 68.372 26.5644 69 25.7137 69H19.4746C18.3402 69 17.8297 68.2007 17.6596 67.8581L17.4895 67.5156L16.979 63.8616C16.8655 63.7474 16.6953 63.6903 16.5252 63.5761C16.1282 63.3477 15.6744 63.0623 15.3341 62.7197L11.8743 64.2042C11.534 64.3755 11.0802 64.3755 10.5697 64.2042C10.0593 64.0329 9.77569 63.6903 9.60553 63.4049L6.486 57.981C6.31585 57.6384 6.31582 57.1817 6.31582 56.8391C6.31582 56.3253 6.65617 55.8685 6.99649 55.6972L9.43535 53.699V52.5571C9.43535 52.3858 9.49212 52.2145 9.49212 52.0432C9.54884 51.872 9.54883 51.7007 9.54883 51.5294V51.4152L6.7696 49.1315C5.80538 48.1609 5.97556 47.3045 6.089 46.8478L9.37865 41.4239C10.0593 40.4533 10.8534 40.4533 11.1937 40.4533H11.534L14.9938 41.9377C15.6744 41.4239 16.2983 41.1384 16.979 40.9671L17.3193 37.1419C17.6596 36.628 18.2835 36 19.1343 36H25.5435C26.5077 36 27.3585 36.6851 27.3585 37.6557L27.8689 40.9671C28.3794 41.3097 29.0033 41.6523 29.8541 41.9377L33.314 40.4533C33.6543 40.282 34.108 40.282 34.6185 40.4533C35.1289 40.6246 35.4126 40.9671 35.5827 41.2526L38.7023 46.6765C39.0426 47.3616 39.0426 48.5035 38.1918 48.9602L35.4126 51.1298V52.2716C35.4126 52.4429 35.3559 52.6142 35.3559 52.7855C35.2991 52.9568 35.2991 53.128 35.2991 53.2993V53.4706L38.3619 55.6972ZM22.424 59.0086C26.054 59.0086 29.0033 56.0398 29.0033 52.3858C29.0033 48.7318 26.054 45.763 22.424 45.763C18.794 45.763 15.8446 48.7318 15.8446 52.3858C15.8446 56.0969 18.794 59.0086 22.424 59.0086Z" fill="white"/>
                                <path d="M39.9962 51.2879C39.9962 51.2879 35.0983 47.2588 15.7401 82.5132C15.7401 82.5132 4.46723 68.5664 -2.72408 63.6463C-2.72408 63.6463 -4.20122 66.0483 -7 66.8231C-7 66.8231 -2.76294 82.5132 10.0259 100.683C10.0259 100.683 12.9413 104.363 15.9733 102.891C16.7508 102.542 17.7614 101.806 18.8887 100.876C23.4367 97.2348 27.0907 92.7409 29.8506 87.7045L35.5259 77.2057L39.9962 51.2879Z" fill="#E6E6E6"/>
                                <path d="M26.6621 150.038L30.2772 77.1665C30.2772 77.1665 30.5493 57.641 42.4441 51.2874C42.5219 51.2487 42.6385 51.3262 42.6385 51.4424L39.6842 88.5951L37.624 119.278L26.6621 150.038Z" fill="#D6E5F8"/>
                                <path d="M41.0461 51.7916L42.9897 40.7891L53.1742 41.0603C53.1742 41.0603 54.8846 49.3509 56.8671 51.5204L45.1665 57.6416C45.1665 57.6416 39.2579 57.1767 41.0461 51.7916Z" fill="#FFC500"/>
                                <path d="M53.6387 43.036C53.6387 43.036 53.7941 44.9343 55.2713 43.5009C56.7873 42.1062 55.6989 41.0214 60.7134 37.186L60.4413 30.29L56.3597 33.4668L53.6387 43.036Z" fill="#081647"/>
                                <path d="M40.0736 19.0166C53.64 20.4888 57.2551 26.6487 57.2551 26.6487C58.0715 28.9731 58.3047 31.3364 58.0326 33.6221C59.432 33.0023 61.6866 32.3436 61.7643 34.242C61.8809 37.0701 59.0433 39.5108 55.5836 40.5568C53.4457 43.9661 50.2581 46.3293 46.9929 46.6005C40.0347 47.1816 37.3525 46.0194 35.8365 36.6439C34.3205 27.2298 40.0736 19.0166 40.0736 19.0166Z" fill="#FFC500"/>
                                <path d="M36.0664 22.9682L53.7921 22.0771L57.5627 28.237L55.5803 31.4138C55.5803 31.4138 56.8242 28.1595 54.6473 25.7188C54.6473 25.7188 52.9369 23.5493 46.3286 23.7818C44.3462 23.8592 37.8157 25.2927 36.0664 22.9682Z" fill="#CC9E00"/>
                                <path d="M58.1485 34.6678C57.2933 35.1714 56.0494 36.3336 55.0387 37.6896C54.6111 38.2707 53.7559 37.8833 53.9114 37.186C54.028 36.6823 54.1446 36.1012 54.3001 35.4426C54.6889 33.9317 55.1942 32.4207 55.7384 30.9486C56.5159 28.8953 57.2933 24.6725 50.996 23.5102C42.6385 21.9606 41.861 25.8347 34.2421 21.147C34.2421 21.147 32.6872 19.1324 34.4753 17.3891C34.4753 17.3891 33.0759 13.36 37.0409 13.3212C37.0409 13.3212 37.6239 6.30904 43.1827 12.6626C43.1827 12.6626 46.7589 16.0719 51.6957 15.2971C56.6325 14.5222 62.3856 17.3116 61.958 23.0841C61.958 23.0841 68.4496 25.0211 64.6013 30.8324C58.1874 40.5177 55.5052 41.4088 55.5052 41.4088" fill="#081647"/>
                                <path d="M37.6242 119.239L35.3696 63.4904C35.3696 63.4904 31.3269 61.1273 40.5007 50.9383C40.112 51.8681 43.5717 57.6406 46.5648 57.8343C49.6357 58.028 55.5442 50.8996 57.1768 49.2725C57.1768 49.2725 59.0427 50.8609 59.5091 54.0376C59.9756 57.2144 81.4718 114.551 81.4718 114.551C81.4718 114.551 51.346 124.159 37.6242 119.239Z" fill="#0778D4"/>
                                <path d="M41.4743 49.312C41.4743 49.312 41.2799 53.1086 47.3051 56.5566C47.3051 56.5566 55.2739 51.3265 55.3128 48.3047C55.3128 48.3047 59.3554 49.6994 60.4827 53.7672C60.4827 53.7672 58.3447 83.133 49.9873 91.0749C41.6298 99.0168 39.2975 97.9321 39.2975 97.9321L32.6504 75.191L39.6085 51.7139C39.6085 51.7139 40.6969 50.0481 41.4743 49.312Z" fill="#1993F7"/>
                                <path opacity="0.25" d="M39.141 119.704C36.381 81.0789 41.6676 50.5122 41.6676 50.5122L41.4733 49.2725L37.003 70.4638L36.4199 90.2993L37.5861 119.2L39.141 119.704Z" fill="#00786E"/>
                                <path d="M37.3131 111.569C37.352 112.848 29.6554 87.0848 28.6836 73.0218C28.6836 73.0218 30.1996 71.1234 33.1539 69.9612C33.2705 69.9225 33.2316 69.7675 33.115 69.7675L29.4221 69.845C29.4221 69.845 30.0441 59.811 41.2781 49.196C41.3558 49.1185 41.4724 49.2347 41.4724 49.3509C39.3345 61.8643 36.0304 82.5908 37.3131 111.569Z" fill="#FFF5F6"/>
                                <path d="M60.2494 110.328V166.154C60.2494 166.154 81.7845 168.556 85.1274 158.329C85.1274 158.329 81.7456 105.408 81.4346 95.4519V58.5703C81.4346 58.5703 74.1267 51.8294 57.334 49.2725L60.3271 53.1466L61.7265 83.2872L60.2494 110.328Z" fill="#E6E6E6"/>
                                <path d="M55.3125 48.4209C55.3125 48.4209 61.0656 48.5758 63.0092 54.697C65.497 62.6777 66.7798 68.4114 66.7798 68.4114L61.9985 67.7916L60.2104 56.4403L55.3125 48.4209Z" fill="#94C2ED"/>
                                <path d="M62.0356 68.1396L66.778 72.3625L60.5195 110.29L62.0356 68.1396Z" fill="#94C2ED"/>
                                <path d="M55.3119 48.4202C55.2341 48.3039 55.3507 48.149 55.5062 48.1877C56.4391 48.3427 61.0649 50.241 62.5809 54.735C64.4079 60.2749 66.2349 67.7907 66.2349 67.7907H62.1922C61.9978 67.7907 61.9201 67.9844 62.0367 68.1394L66.196 72.7883C66.196 72.7883 61.6479 101.224 60.5207 110.251C60.5207 110.406 60.2874 110.406 60.2486 110.251L59.3545 62.9093C59.3545 62.9481 57.1777 52.1781 55.3119 48.4202Z" fill="#FFF5F6"/>
                                <path opacity="0.25" d="M47.343 56.5955C46.9542 56.363 47.343 56.5955 47.343 56.5955C47.343 56.5955 44.5052 59.5011 42.2507 60.6633C42.2507 60.6633 41.6287 60.5471 39.9961 58.5326C39.9961 58.5326 40.4626 59.6561 42.1341 61.1282C42.1341 61.1282 45.0106 59.9273 47.343 56.5955Z" fill="#00786E"/>
                                <path opacity="0.25" d="M51.1918 59.5389L49.2871 62.367L49.7147 67.8295C49.7147 67.8295 50.1811 62.7544 50.8031 61.7471C51.4639 60.7399 52.2025 61.0885 52.2025 61.0885L53.4853 62.0958C53.4853 62.0958 55.8565 61.1273 58.3055 58.3379C58.3055 58.3379 56.1286 60.275 53.7574 61.4372C53.7574 61.4372 52.669 60.7011 51.3473 59.6551" fill="#00786E"/>
                                <path d="M51.3852 59.694L47.3426 56.5947C46.1375 58.3768 44.3105 59.8102 44.3105 59.8102C44.3105 59.8102 44.5826 59.9652 45.4767 61.786C46.3708 63.6069 44.8936 65.9313 43.9218 67.5197C42.95 69.1081 41.8227 78.9096 41.0064 85.8443C40.1901 92.7789 41.9782 106.532 41.9782 106.532C41.9782 106.532 45.0491 110.561 47.3426 111.723C47.3426 111.723 49.8304 107.733 50.7244 104.401C50.1025 100.101 49.2862 85.8442 49.7526 80.1493C50.1802 74.4156 49.7915 65.6989 49.7138 63.5681C49.5971 61.3599 51.3852 59.694 51.3852 59.694Z" fill="#94C2ED"/>
                                <path opacity="0.5" d="M45.752 62.6385C45.752 62.6385 46.7627 61.67 47.6178 61.3988C47.6178 61.3988 48.4342 61.5538 49.7558 62.716C49.7558 62.716 48.784 61.6313 47.5401 60.9727C47.5012 60.9727 46.8015 61.4763 45.752 62.6385Z" fill="#0778D4"/>
                                <path d="M55.5859 40.5174C59.0067 39.4714 61.8444 37.0307 61.7666 34.2026C61.7278 33.1178 60.9892 32.8853 60.134 32.9628C58.7346 33.1178 57.5296 34.0089 56.9076 35.2486C56.3245 36.3721 55.7803 38.0767 55.5859 40.5174Z" fill="#FFC500"/>
                                <path d="M41.8633 46.4454C41.8633 46.4454 46.5668 48.8861 53.6416 43.0361L54.6523 46.6778C54.6523 46.6778 46.528 50.242 41.8633 46.4454Z" fill="#CC9E00"/>
                            </g>
                            <path d="M18.105 32.2099C25.8949 32.2099 32.21 25.8949 32.21 18.105C32.21 10.315 25.8949 4 18.105 4C10.315 4 4 10.315 4 18.105C4 25.8949 10.315 32.2099 18.105 32.2099Z" fill="#1993F7"/>
                            <path d="M16.1422 24.3231L11.3496 19.5305L12.9861 17.8551L16.1422 21.0112L23.7402 13.4521L25.3767 15.0886L16.1422 24.3231Z" fill="white"/>
                        </svg>
                    </div>
                    <div className={`${big ? 'col-12 col-md' : 'col-12'}`}>
                        <div className="vsbox-title mb-1">Otimize sua rotina de atendimentos</div>
                        { clinic.isOwner && (
                            <>
                                { !pro?.trialEndsAt ? (
                                    <div>Experimente o PRO para ter acesso completo às funcionalidades, sem limitações.</div>
                                ) : (
                                    <div>Assine o PRO para ter acesso completo às funcionalidades, sem limitações.</div>
                                )}
                                <div className="mt-3">
                                    <a 
                                        style={{ 
                                            cursor: "pointer",
                                            fontSize: "14px",
                                            fontWeight: 600
                                        }} 
                                        onClick={() => {
                                            saveTrackersProSubscription(pro)
                                            redirectToCheckout(clinic)
                                        }}
                                    >
                                        { !pro?.trialEndsAt ? "Experimentar o PRO 15 dias grátis" : "Assinar o Prontuário PRO" }
                                    </a>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CardProAdvertising;