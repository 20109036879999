import React from "react";

import { isReminderLate } from "./Vaccine";
import { getShots, getTitleForVaccine, getFirstScheduledAtFromArray, getFirstSentAtFromArray } from "./VaccineListView";
import { showDate } from "../../../../utils";

import { ReactComponent as IconResend } from "../../../../assets/icon-resend.svg"
import { ReactComponent as IconNotification } from "../../../../assets/icon-notification.svg"
import { ReactComponent as IconNotificationOn } from "../../../../assets/icon-notification-on.svg"
import { ReactComponent as IconNotificationOff } from "../../../../assets/icon-notification-off.svg"

import './BoxVaccineWithReminder.scss'

const BoxVaccineWithReminder = props => {
    const {
        vaccine,
        footerAppend,
        className,
        history
    } = props

    const dateToShow = showDate(vaccine.get("documentDate")) || "-"
    return (
        <div className={`vsbox-vaccine-with-reminder vsbox-item ${className} pb-0 overflow-hidden`}>
            <div className="row vsbox-item-text">
                <div className="col">
                    <div className="vsbox-title">
                        {getTitleForVaccine(vaccine)}
                    </div>
                </div>
            </div>
            <div className="row vsbox-item-text mt-1">
                <div className="col">
                    {getShots(vaccine) && <span className="me-3"><span className="me-3">{getShots(vaccine)}</span>•</span>}
                    <span>{vaccine.get("isReminder") ? "Programada para o " : "Aplicada "} dia {dateToShow}</span>
                </div>
            </div>
            <div className="row align-items-start align-items-md-center flex-column flex-md-row vsbox-footer mt-3 gx-5">
                <div className={`col-auto pt-2 pb-sm-2 ${vaccine.get("isReminder") ? '' : 'pb-2'}`}>
                    <div className="row align-items-start align-items-sm-center flex-column flex-sm-row">
                        <div className="col-auto vsbox-item-text pe-2">
                            <b>Lembrete para o Tutor:</b>
                        </div>
                        <div 
                            className="col-auto px-sm-0 mt-2 mt-sm-0"
                            style={!getFirstSentAtFromArray(vaccine.get('messages')) && 
                            getFirstScheduledAtFromArray(vaccine.get('messages')) ? {
                                color: "var(--vsprontuario-secondary-color)"
                            } : undefined}
                        >{
                            getFirstSentAtFromArray(vaccine.get('messages')) ? (
                                <>
                                    <IconNotificationOn className="me-1" width="20" height="20" fill="var(--vsprontuario-primary-color)" />
                                    Enviado dia { showDate(getFirstSentAtFromArray(vaccine.get('messages'))) }
                                </>
                            ) : getFirstScheduledAtFromArray(vaccine.get('messages')) ? (
                                <>
                                    <IconNotification className="me-1" width="20" height="20" fill="var(--vsprontuario-secondary-color)" />
                                    Ativado (Envio programado para { showDate(getFirstScheduledAtFromArray(vaccine.get('messages'))) })
                                </>
                            ) : (
                                <>
                                    <IconNotificationOff className="me-1" width="20" height="20" fill="var(--vsprontuario-gray-color-dark)" />
                                    Desativado
                                </>
                            )
                        }</div>
                    </div>
                    {footerAppend && 
                    <div className="row align-items-center mt-1">
                        {footerAppend}
                    </div>}
                </div>
                {vaccine.get("isReminder") &&
                <div className="col">
                    <div className="row align-items-center justify-content-md-end">
                        <div className="col-auto ps-0 ps-md-3">
                            {!vaccine.get('messages') || !vaccine.get('messages').length ? (
                                <button
                                    className="btn-link"
                                    onClick={() => history.push(`/animal/${vaccine.get('patient').id}/lembrete_automatico/${vaccine.id}`)}
                                >
                                    Ativar Lembrete
                                </button>
                            // ) : vaccine.get('messages')[0].get('sentAt') ? (
                            //     <button
                            //         className="btn-link"
                            //         onClick={() => () => history.push(`/animal/${vaccine.get('patient').id}/lembrete_automatico/${vaccine.id}`)}
                            //     >
                            //         Reenviar Lembrete
                            //         <IconResend width="20" height="20" fill="var(--vsprontuario-secondary-color)" />
                            //     </button>
                            ) : (
                                <button
                                    className="btn-link"
                                    onClick={() => history.push(`/animal/${vaccine.get('patient').id}/lembrete_automatico/${vaccine.id}`)}
                                >
                                    Editar Lembrete
                                </button>
                            )}
                        </div>
                    </div>
                </div>}
            </div>
        </div>
    )
}

export default BoxVaccineWithReminder