import React, { useEffect } from "react"
import { 
	FormControlLabel,
	Radio,
	RadioGroup
} from '@mui/material';

export const IconRadioChecked = (disabled = false) => (
    <svg viewBox="0 0 20 20" width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle fill="transparent" cx="10" cy="10" r="9.5" stroke={ disabled ? "var(--vsprontuario-gray-color-dark)" : "var(--vsprontuario-primary-color)" }/>
        <circle fill={ disabled ? "var(--vsprontuario-gray-color-dark)" : "var(--vsprontuario-primary-color)" } cx="10" cy="10" r="5"/>
    </svg>
)

export const IconRadioUnchecked = (disabled = false) => (
    <svg viewBox="0 0 20 20" width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle fill="transparent" cx="10" cy="10" r="9.5" stroke={ disabled ? "var(--vsprontuario-gray-color-dark)" : "var(--vsprontuario-primary-color)" }/>
    </svg>
)

import "./VSRadio.scss"
const VSRadio = (props) => {

    const {
        value,
        options,
        onChange,
        disabled,
        horizontal,
        highlight,
        className
    } = props
    
    return (
        <RadioGroup
            id="vsradio"
            value={value !== undefined && value !== null ? value : options[0].value}
            onChange={({ target: { name, value } }) => onChange(name, value)}
            row={horizontal}
        >
            { options.map((option, index) => (
                <React.Fragment key={index}>
                    <FormControlLabel
                        label={option.label}
                        value={option.value}
                        className={`col-auto m-0 ${className} ${ highlight ? "radio-highlight" : "" } ${index == 0 ? "ps-0" : "px-0"}`}
                        control={
                            <Radio 
                                color="primary"
                                checkedIcon={IconRadioChecked(disabled || option.disabled)}
                                icon={IconRadioUnchecked(disabled || option.disabled)}
                                disabled={disabled || option.disabled}
                                className={index == 0 || !horizontal ? "ps-0" : ""}
                            />
                        }
                    />
                    {option.append}
                </React.Fragment>
            ))}
        </RadioGroup>
    )
}

export default VSRadio