import React from 'react'
import { connect } from 'react-redux'

import { ReactComponent as IconCashO } from "../../assets/icon-cash-o.svg"
import { ReactComponent as IconEdit } from "../../assets/icon-primary-edit.svg"
import { ReactComponent as IconTrash } from "../../assets/icon-primary-trash.svg"

import { formattedCurrency, showRegisteredBy } from '../../utils'
import { deleteExpense } from './Expense'
import { ACL } from '../../constants'

import ExpenseInfoView from './ExpenseInfoView'

import './ExpenseRecurringView.scss'
const ExpenseRecurringView = (props) => {

    const {
        object,
        notes,
        parent,
        history,
        updateList,
        permissions
    } = props

    return (
        <div id="expense-recurring-view" className={`vsbox mb-3`}>
            <div className="vsbox-body pb-0">
                <div className="row align-items-center">
                    <div className="col-6 col-md order-0 mb-3">
                        <label className="vsbox-info">
                            <IconCashO width={20} height={20} fill="var(--vsprontuario-gray-color)" className="me-1" />
                            Valor a Pagar
                        </label>
                        <div className="vsbox-main-text" style={{ color: "var(--vsprontuario-gray-color-dark)" }}>{ formattedCurrency(object.get("paymentsPending")) }</div>
                    </div>
                    <div className="col-6 col-md order-2 order-md-1 mb-3">
                        <label className="vsbox-info">Total da Despesa</label>
                        <div className="vsbox-info-text">
                            { formattedCurrency(object.get("value")) }
                        </div>
                    </div>
                    <div className="col-6 col-md order-3 order-md-2 mb-3">
                        <label className="vsbox-info">Total Pago</label>
                        <div className="vsbox-info-text">
                            { formattedCurrency(object.get("paymentsValue")) }
                        </div>
                    </div>
                    <div className="col-6 col-md order-1 order-md-3 mb-3">
                        <label className="vsbox-info">Se Repete?</label>
                        <div className="vsbox-info-text">
                            { object.get("recurrence") ? "Sim" : "Não" }
                        </div>
                    </div>
                    <div className="col-12 col-md-auto order-4 mb-3">
                        <div className="row g-3 align-items-center justify-content-center">
                            { permissions?.check(ACL.OUTFLOW, ACL.Rule.EDIT, parent) && (
                                <div className="col-6 col-md-auto">
                                    <button
                                        className={`vsbox-btn vsbox-btn-dark`}
                                        onClick={() => history.push(`/despesa/${ object.id }${ object.get("recurrence") ? "/recorrente" : "" }`)}
                                    >
                                        <IconEdit width="24" height="24" fill={"var(--vsprontuario-primary-color-dark)"} />
                                    </button>
                                </div>
                            )}
                            { permissions?.check(ACL.OUTFLOW, ACL.Rule.DELETE, parent) && (
                                <div className="col-6 col-md-auto">
                                    <button
                                        className={`vsbox-btn vsbox-btn-alert`}
                                        onClick={() => deleteExpense(object, updateList) }
                                    >
                                        <IconTrash width="24" height="24" fill={"var(--vsprontuario-primary-color)"} />
                                    </button>
                                </div>
                            )}
                            { permissions?.check(ACL.OUTFLOW, ACL.Rule.EDIT, parent) && (
                                <div className="col-12 col-md-auto">
                                    <button
                                        className={`vsbox-btn vsbox-btn-primary`}
                                        onClick={() => history.push(`/despesa/${ object.id }${ object.get("recurrence") ? "/pagar" : "" }`)}
                                    >
                                        Pagar Conta
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                { showRegisteredBy(object) }
                <ExpenseInfoView object={object} />
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        permissions: state.permissions
    }
}
  
export default connect(mapStateToProps)(ExpenseRecurringView)