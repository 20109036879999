import axios from "axios"
import { APP_DEVICE, APP_NAME, APP_VERSION } from "../../../../constants"
import { mandatoryField } from "../../../../utils"

export const deleteAppointmentDrug = (drug, callback) => {
    Swal.fire({
        title: 'Tem certeza?',
        text: "Você tem certeza que deseja excluir esse medicamento? Essa ação não poderá ser desfeita.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, quero excluir!',
        cancelButtonText: 'Não, manter medicamento'
    }).then((result) => {
        if (result.isConfirmed) {
            drug.set("isDeleted", true)
            drug.set("changedAt", new Date())
            drug.set("changedBy", Parse.User.current())
            drug.set("changedApp", APP_NAME)
            drug.set("changedDevice", APP_DEVICE)
            drug.set("changedAppVersion", APP_VERSION)
            drug.save()
            .then(_ => {
                Swal.fire(
                    'Excluído!',
                    'O medicamento foi excluído com sucesso.',
                    'success'
                )
                .then(_ => {
                    callback && callback()
                })
            })
            .catch(error => {
                console.error(error)
                Swal.fire(
                    'Desculpe',
                    'Ocorreu algum erro ao tentar excluir o medicamento',
                    'error'
                )
                drug.revert()
            })
        }
    })
}

export const getAppointmentDrug = (id, patient) => {
    return new Promise((resolve, reject) => {
        var query = new Parse.Query("AppointmentDrug");
        query.equalTo("clinic", patient.get("clinic"));
        query.equalTo("isDeleted", false);
        query.equalTo("patient", patient)
        query.get(id)
        .then(_object => {
            if (_object) {
                resolve(_object)
            } else {
                reject('Não encontramos a medicação informada')
            }
        }).catch(error => {
            console.error(error)
            reject('Ocorreu algum erro ao tentar buscar a medicação')
        })
    })
}

export const showAppointmentDrug = (appointmentDrug) => {
    let drugs = appointmentDrug.get("drugs")
    if (drugs && drugs.length > 0) {
        return <ul>
            { drugs.map(drug => {
                return <li>{ drug.drug }</li>
            })}
        </ul>
    }
    let appointmentDrugText = appointmentDrug.get("drug") || "Medicamento"
    if (!appointmentDrugText.toUpperCase().startsWith("MEDICAMENTO")) {
        appointmentDrugText = "Medicamento " + appointmentDrugText;
    }
    return appointmentDrugText
}

var searchDrugWithDelay = null
let dosageForms = []
export const searchDrug = (drug) => {
    return new Promise((resolve, reject) => {
        if (dosageForms[drug]) {
            resolve(dosageForms[drug])
        } else {
            clearInterval(searchDrugWithDelay)
            searchDrugWithDelay = setTimeout(() => {
                axios.get(`${process.env.REACT_APP_API_V2}/dosageForms/${drug}`)
                .then(({ data }) => {
                    dosageForms[drug] = data.length > 0 ? data : []
                    resolve(dosageForms[drug])
                })
                .catch(_ => {
                    reject()
                })
            }, 300)
        }
    })
}

export const defaultDrug = {
    drugId: "",
    drug: "",
    dosage: "",
    type: ""
}

export const defaultDrugWithValidation = Object.assign(
    {},
    { ...defaultDrug },
    {
        options: [],
        errorDrug: false,
        helperDrug: mandatoryField,
        drugOptions: []
    }
)