import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux';

import './UserSettingDigitalCertificate.scss'
import VSAccordionSetting from "../../VSAccordionSetting";

import { ReactComponent as DigitalCertificateTutorial1 } from "../../../../assets/digital-certificate-tutorial-1.svg"
import { ReactComponent as DigitalCertificateTutorial2 } from "../../../../assets/digital-certificate-tutorial-2.svg"
import { ReactComponent as DigitalCertificateTutorial3 } from "../../../../assets/digital-certificate-tutorial-3.svg"
import { ReactComponent as DigitalCertificateTutorial3b } from "../../../../assets/digital-certificate-tutorial-3b.svg"
import { ReactComponent as DigitalCertificateTutorial4 } from "../../../../assets/digital-certificate-tutorial-4.svg"

const UserSettingDigitalCertificate = props => {
    const {
        user,
        clinic
    } = props

    return (
        <div id="user-setting-digital-certificate">
            <div className="row setting-content">
                <div className="col-12 col-md-5 col-xl-4 mb-3 mb-md-0">
                    <VSAccordionSetting
                        title="O que é Certificado Digital?">

                        <div className="vs-accordion-text-secondary">
                            Prescrições digitais de controle especial ou de substâncias controladas para serem <b>aceitas digitalmente</b> precisam de uma assinatura digital.
                        </div>
                        <div className="vs-accordion-text-secondary mt-4">
                            O Certificado Digital é um <b>documento eletrônico</b> equivalente ao CPF para pessoa física ou CNPJ para pessoa jurídica, sendo conhecido como <b>e-CPF ou e-CNPJ</b>, respectivamente. Para obter esta certificação, o portador precisará encontrar uma <b>Autoridade de Certificado equivalente a órgãos como o SSP</b>. A entidade irá conferir a documentação necessária e confirmar a identidade do indivíduo, realizando a emissão do documento se tudo estiver de acordo.
                        </div>
                        <div className="vs-accordion-text-secondary mt-4">
                            No Prontuário Vet Smart você consegue assinar digitalmente os documentos como prescrições, termos, atestados e solicitações de exames. Para assinar, pode-se utilizar qualquer certificado válido pelo <b>ICP-Brasil</b> e que possa ser lido em seu computador ou celular.
                        </div>
                        <div className="vs-accordion-text-secondary mt-4">
                            Utilize seu certificado digital para assinar suas prescrições e enviar apenas a via digital por SMS, e-mail ou WhatsApp ao tutor(a), sem precisar imprimir.
                        </div>
                        <div className="vs-accordion-text-secondary mt-4">
                            Você pode conferir mais informações a respeito de certificados digitais diretamente no <b><a href="https://www.gov.br/pt-br/servicos/obter-certificacao-digital" target="_blank">site da ICP-Brasil.</a></b>
                        </div>
                        <div className="vs-accordion-text-highlight mt-4">
                            Ainda ficou com dúvidas?<br/><a href="https://pl-vetsmart.zendesk.com/hc/pt-br/articles/12475963873051-Assinatura-e-certificado-digital" target="_blank">Saiba mais sobre Assinatura Digital</a>
                        </div>
                    </VSAccordionSetting>
                </div>
                <div className="col-12 col-md-7 col-xl-8">
                    <div className="digital-certificate-tutorial">
                        <div className="tutorial-title">Como utilizar o Certificado Digital?</div>
                        <div className="row mt-4 ms-0">
                            <div className="col-auto tutorial-index">1</div>
                            <div className="col">
                                <div className="tutorial-text">Sempre que finalizar um documento (prescrição, termo, etc) ative a opção Assinatura Digital.</div>
                                <DigitalCertificateTutorial1 className="mt-3" />
                            </div>
                        </div>
                        <div className="row mt-4 ms-0">
                            <div className="col-auto tutorial-index">2</div>
                            <div className="col">
                                <div className="tutorial-text">Será necessário selecionar o tipo de Certificado da sua escolha.<br/>O certificado em nuvem é a identificação eletrônica, (emitido por uma Autoridade Certificadora) que ao invés de ser armazenado em um computador, smartphone, cartão inteligente ou token, ele é armazenado em um servidor HSM (Hardware Security Module) em um ambiente de nuvem.<br/>Possibilitando que o usuário possa acessá-lo de qualquer lugar, basta ter acesso a internet.</div>
                                <DigitalCertificateTutorial2 className="mt-3" />
                            </div>
                        </div>
                        <div className="row mt-4 ms-0">
                            <div className="col-auto tutorial-index">3</div>
                            <div className="col">
                                <div className="tutorial-text">Feita essa escolha, será necessário seguir os passos de configuração e instalação de cada opção:</div>
                                <div className="tutorial-subtitle mb-2 mt-4">Certificado Instalado</div>
                                <div className="tutorial-text">Só será necessário instalar a extensão Web PKI uma única vez, após esse procedimento as assinaturas estarão listadas e aparecerão automaticamente.</div>
                                <DigitalCertificateTutorial3 className="mt-3" />
                                <div className="tutorial-subtitle mb-2 mt-4">Certificado em Nuvem</div>
                                <div className="tutorial-text">Caso você tenha selecionado o certificado digital do tipo Nuvem, basta inserir um CPF válido que já tenha sido cadastrado com um certificado. Selecione qual assinatura você deseja assinar o documento e finalize.</div>
                                <DigitalCertificateTutorial3b className="mt-3" />
                            </div>
                        </div>
                        <div className="row mt-4 ms-0">
                            <div className="col-auto tutorial-index">4</div>
                            <div className="col">
                                <div className="tutorial-text">Após esse procedimento o documento será gerado com a assinatura Digital.</div>
                                <DigitalCertificateTutorial4 className="mt-3" />
                            </div>
                        </div>
                        <div className="tutorial-text-highlight mt-4">
                            <b>Ainda precisa de ajuda?</b><br/>Veja o <a href="https://pl-vetsmart.zendesk.com/hc/pt-br/articles/12476571256475/" target="_blank">passo a passo</a> da instalação e configuração do Certificado Digital.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        user: state.user,
        clinic: state.clinic
    }
}

export default connect(mapStateToProps)(UserSettingDigitalCertificate)