import React from 'react'
import PropTypes from 'prop-types';

import { ReactComponent as IconSearch } from "../../assets/icon-search.svg"

import './VSEmpty.scss'

const VSEmpty = (props) => {

    const {
        icon,
        absoluteIcon,
        title,
        text,
        children,
        className
    } = props

    return (
        <div id="vsempty" className={className}>
            {absoluteIcon && (
                <div className="vsempty-absoluteicon">
                    { absoluteIcon }
                </div>
            )}
            { icon && (
                <div className="vsempty-icon">
                    <div className="vsempty-icon-inside">
                        { icon }
                    </div>
                    <IconSearch width="82px" height="111px" />
                </div>
            )}
            { title && (
                <div className="vsempty-title">
                    { title }
                </div>
            )}
            { text && (
                <div className="vsempty-text">
                    { text }
                </div>
            )}
            { children }
        </div>
    )
}

VSEmpty.propTypes = {
    icon: PropTypes.node,
    title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node
    ]),
    text: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node
    ])
};

VSEmpty.defaultProps = {
    icon: null,
    title: "",
    text: ""
}

export default VSEmpty;